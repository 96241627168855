import React from 'react'
import { Box, Stack, Collapse, Typography, Modal, Backdrop } from '@mui/material'
import widgetClasses from 'pages/credit-check/styles/creditcheck-widgit-frame'
import classes from 'pages/credit-check/styles/norwegian-payment-remarks'
import { Show } from 'components'
import { isComponentRendered } from 'configs'
import { useCreditCheck } from 'pages/credit-check/context'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import ModalView from './ModalView'
import IntlTypography from 'components-new/elements/IntlTypography'
import { link1 } from 'styles-new/global/typography'
import { NorwegianPaymentRemarksT } from './types'
import { Mixpanel } from 'services/helpers/mixpanel'
import { LocalId } from 'globalTypes'
import { LoadingSkeleton } from 'components-new'
import NorwegianPaymentRemarksSkeleton from './NorwegianPaymentRemarks.skeleton'
import { WidgetWrapper } from '../WidgetWrapper/WidgetWrapper'

function NorwegianPaymentRemarks({
  isReArrange,
  data,
  isLoading,
  widgetNameDND,
  isActive = true,
  setIsActive,
}: NorwegianPaymentRemarksT) {
  const { localId } = useCreditCheck() as { localId: LocalId }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    Mixpanel.track('User clicked Payment Remarks', {
      company: localId?.id,
      country: localId?.country,
    })
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  const remarksCounter = data?.total_unsettled

  return (
    <Show
      when={Boolean(
        isComponentRendered('norwegianPaymentRemarks', localId?.country) &&
          remarksCounter > 0
      )}
    >
      <LoadingSkeleton
        SkeletonComponent={NorwegianPaymentRemarksSkeleton}
        isLoading={isLoading}
      >
        <Box data-cy="payment-remarks">
          <WidgetWrapper
            isReArrange={isReArrange}
            widgetName={widgetNameDND}
            isActive={!!isActive}
            setIsActive={setIsActive}
          >
            <Box sx={{ margin: 2, padding: 2 }}>
              <IntlTypography
                variant="h4"
                labelKey="widget-title"
                group="paymentRemarks"
              />
              <Collapse in={!isReArrange}>
                <Stack
                  mt={2}
                  sx={{ ...classes.main, ...widgetClasses.paymentRemarksContentStyle }}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <PriorityHighOutlinedIcon
                    sx={classes.shieldWarningIcon}
                    color="error"
                  />
                  <Stack spacing={1}>
                    <Typography>
                      <IntlTypography
                        labelKey="widget-text"
                        group="paymentRemarks"
                        values={{
                          count: remarksCounter,
                        }}
                      />
                    </Typography>
                    <Box>
                      <Typography
                        color="error"
                        onClick={handleOpen}
                        component="span"
                        sx={link1}
                      >
                        <IntlTypography
                          labelKey="widget-open-modal"
                          group="paymentRemarks"
                        />
                      </Typography>
                    </Box>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                    >
                      <ModalView data={data} closeFn={handleClose} />
                    </Modal>
                  </Stack>
                </Stack>
              </Collapse>
            </Box>
          </WidgetWrapper>
        </Box>
      </LoadingSkeleton>
    </Show>
  )
}

export default NorwegianPaymentRemarks
