import { ReactNode, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { navigation } from 'services/navigation'
import useTotangoTracking from 'utils/totangoTracking'
import { ReduxRootState } from 'store_deprecated/types'

type AuthenticationProps = {
  children?: ReactNode
}

const Authentication = ({ children }: AuthenticationProps) => {
  const { trackEvent } = useTotangoTracking()
  const isAuthenticated = useSelector((state: ReduxRootState) =>
    Boolean(state?.auth?.login?.authenticated)
  )

  const { pathname, search } = useLocation()
  const history = useHistory()
  const routeToMatch = navigation.auth.prefix
  const redirectTo = `${navigation.auth.login()}${search}`

  useEffect(() => {
    if (isAuthenticated) trackEvent('System', 'User Is Authenticated')
  }, [trackEvent, isAuthenticated])

  if (!isAuthenticated && routeToMatch !== pathname.substr(0, routeToMatch.length)) {
    if (pathname !== '/') {
      sessionStorage.setItem('redirect:intended', pathname)
    }
    history.push(redirectTo)
  }

  return children
}

export default Authentication
