import { Autocomplete, Stack, Box, Typography, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Show from 'components/Show'
import { CurrencyObj } from 'services/types'
import { availableCountriesAndCurrencies } from './CurrencyAutocomplete.model'

type Props = {
  selectedCurrency: CurrencyObj
  handleChange: (value: CurrencyObj) => void
  handleClose: (x: any) => void
}

function CurrencyAutocomplete({ selectedCurrency, handleChange, handleClose }: Props) {
  const theme = useTheme()
  return (
    <Show when={!!selectedCurrency}>
      <Autocomplete
        // open={true}
        disableClearable
        options={availableCountriesAndCurrencies}
        defaultValue={selectedCurrency}
        onChange={(_, val) => handleChange(val)}
        onClose={handleClose}
        autoHighlight
        getOptionLabel={(option) => option.currency}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Stack spacing={1} direction="row" width={1} pl={0}>
              <img
                loading="lazy"
                width="35"
                src={`https://flagcdn.com/w20/${option.country.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.country.toLowerCase()}.png`}
                alt=""
              />
              <Typography variant="h6">{option.currency}</Typography>
            </Stack>
          </Box>
        )}
        renderInput={({ inputProps, ...params }) => {
          return (
            <TextField
              inputProps={{
                ...inputProps,
                style: { ...theme.typography.body1 },
              }}
              sx={{
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  fontSize: '1.3rem',
                },
              }}
              {...params}
            >
              {selectedCurrency.currency}
            </TextField>
          )
        }}
      />
    </Show>
  )
}
export default CurrencyAutocomplete
