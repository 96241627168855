import { LocalId } from 'globalTypes'
import { useQuery } from 'react-query'
import registerPersonVisit from 'services/api/endpoints/risikaAPI/registerPersonVisit'
import registerCompanyVisit from 'services/api/endpoints/risikaAPI/registerCompanyVisit'

type RegisterVisitParams = {
  type: 'person' | 'company'
  data: LocalId
}

export function useRegisterVisit({ type, data }: RegisterVisitParams) {
  return useQuery(
    ['useRegisterVisit', type, JSON.stringify(data)],
    () => (type === 'company' ? registerCompanyVisit(data) : registerPersonVisit(data)),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )
}
