import { useState, useRef, useEffect, useCallback, UIEvent } from 'react'
import { useParams } from 'react-router-dom'

import { Stack, Box } from '@mui/material'
import { HistoryHeader } from 'components'
import KeyFiguresLineChartBox from 'widgets/charts/KeyFiguresLineChartBox'

import { createComparisonList } from './CompanyComparison.controller'
import { addCompanyToTheList } from './CompanyComparison.controller'
import { removeCompanyFromTheList } from './CompanyComparison.controller'
import { getLocalStorageList } from './CompanyComparison.controller'
import { setLocalStorage } from './CompanyComparison.controller'

import { validateLocalId } from 'services/company'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

import useTotangoTracking from 'utils/totangoTracking'
import { AvailableCountries, LocalId } from 'globalTypes'
import DialogSimple from 'components-new/inputs/DialogSimple'
import { localStorageName, scrollThrottleTime } from './CompanyComparison.model'
import StickyHeader from './widgets/StickyHeader'
import styles from './CompanyComparison.styles'
import CompanyInfoColumns from './widgets/CompanyInfoColumns'

const { pageContainer, headerContainer, columnsContainer, keyFiguresChartContainer } =
  styles

const CompanyComparisonPage = () => {
  const { messages } = useIntl()

  const [showHeader, setShowHeader] = useState(true)
  const [_, setScrollPosition] = useState(0)
  const [companies, setCompanies] = useState<LocalId[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const { id, country } = useParams<{ id: string; country: AvailableCountries }>() ?? {}

  const pageContainerRef = useRef<HTMLDivElement>(null)
  const mainContainerRef = useRef<HTMLDivElement>(null)

  const { trackEvent } = useTotangoTracking()

  const autoSelectCompaniesAction = (company: LocalId) => {
    if (validateLocalId(company)) {
      const newList = addCompanyToTheList(company, companies)
      setAndScroll(newList)
    } else {
      handleDialogOpen()
    }
  }

  const removeCompany = (companyToRemove: LocalId) => {
    trackEvent('Comparison', 'Company Closed')
    const newList = removeCompanyFromTheList(companyToRemove, companies)
    setLocalStorage(localStorageName, newList)
    setCompanies(newList)
  }

  const setAndScroll = useCallback((newList: LocalId[]) => {
    setLocalStorage(localStorageName, newList)
    setCompanies(newList)
    scrollComparisonListToTheLeft()
  }, [])

  const scrollComparisonListToTheLeft = () => {
    setTimeout(function () {
      pageContainerRef?.current?.scrollBy({
        left: 5000,
        behavior: 'smooth',
      })
    }, 500)
  }

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    trackEvent('Comparison', 'Visit Comparison Page')
  }, [trackEvent])

  useEffect(() => {
    if (id || !isNaN(Number(id)) || country) {
      if (validateLocalId({ id, country })) {
        const newCompany = { id, country: country.toLowerCase() }
        const currentList = getLocalStorageList(localStorageName)
        const newList = createComparisonList(newCompany, currentList)
        setAndScroll(newList)
      } else {
        const newList = getLocalStorageList(localStorageName)
        setAndScroll(newList)
        handleDialogOpen()
      }
    } else {
      const newList = getLocalStorageList(localStorageName)
      setAndScroll(newList)
    }
  }, [country, id, setAndScroll])

  const handleScroll = (() => {
    let inThrottle: boolean
    return (e: UIEvent<HTMLElement>) => {
      if (!inThrottle) {
        setTimeout(() => {
          // @ts-ignore There is e.currentTarget.scrollTop which satisfies TypeScript, but it does not work
          const currentScrollPosition = Math.round(e.target.scrollTop)
          setScrollPosition((previousScrollPosition) => {
            setShowHeader(
              currentScrollPosition < 100 &&
                previousScrollPosition > currentScrollPosition
            )
            return currentScrollPosition
          })
          inThrottle = false
        }, scrollThrottleTime)
        inThrottle = true
      }
    }
  })()

  return (
    <Stack ref={pageContainerRef} sx={pageContainer} spacing={'6px'}>
      {/* Header */}
      <Box sx={{ ...headerContainer(showHeader) }}>
        <HistoryHeader
          title={messages[intl.companyComparison('title')] as string}
          historyKey={'company-comparison'}
          location="company-comparison"
          titleExtraProps={{ paddingY: '8px' }}
        ></HistoryHeader>
      </Box>

      {/* Company Name */}
      <StickyHeader
        pageWidth={pageContainerRef.current?.clientWidth ?? 0}
        companies={companies}
        showHeader={showHeader}
        removeCompany={removeCompany}
        autoSelectCompaniesAction={autoSelectCompaniesAction}
      />

      {/* Content */}
      <Stack
        ref={mainContainerRef}
        onScroll={handleScroll}
        sx={{ ...columnsContainer(companies.length, showHeader) }}
      >
        <CompanyInfoColumns companies={companies} />
        {companies.length > 0 && (
          <Box sx={{ ...keyFiguresChartContainer(companies.length) }}>
            <KeyFiguresLineChartBox companies={companies} />
          </Box>
        )}
      </Stack>

      {/* Dialog */}
      <DialogSimple
        open={dialogOpen}
        onClose={handleDialogClose}
        title={
          <FormattedMessage id={intl.companyComparison('dialog-cannot-add-title')} />
        }
        content={
          <FormattedMessage id={intl.companyComparison('dialog-cannot-add-content')} />
        }
      />
    </Stack>
  )
}

export default CompanyComparisonPage
