import risikaAPI from 'services/api/helpers/risikaAPI'
import { PolicyNodesAndEdges } from 'types/queries'

export type SavePolicyProps = {
  description: string
  name: string
  policy: PolicyNodesAndEdges
  country: string
  enabled: boolean
}

export type SavePolicyResponseData = {
  policy_id: number
  status: 'ok'
}

const postCreditPolicy = async (
  policy: SavePolicyProps
): Promise<SavePolicyResponseData> => {
  const { data } = await risikaAPI.post(
    '/enterprise_credit_policies/create',
    { ...policy },
    {
      microservice: 'account',
    }
  )
  return data
}

export default postCreditPolicy
