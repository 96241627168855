import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const content: SxProps = {
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: '1.6rem',
  backgroundColor: theme.palette.grey[100],
}

const numberInput: SxProps = {
  ...content,
  width: '30%',
  '& input': {
    fontSize: '1.6rem',
    border: 'none',
  },
  '& fieldset': {
    border: 'none',
  },
}

const frequencySelect: SxProps = {
  ...content,
  flexGrow: 1,
  border: 'none',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}

const radioGroup: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const formControlLabel: SxProps = {
  flexDirection: 'column',
  backgroundColor: theme.palette.grey[100],
  '& .MuiFormControlLabel-label': {
    fontSize: '1.5rem',
  },
  margin: 0,
  px: 0.5,
}

const datePickerButton: SxProps = {
  mt: 1,
  padding: '14px 0',
  fontSize: '1.4rem',
  background: theme.palette.grey[100],
  gap: 1,
  width: 1,
  px: 2,
  textTransform: 'capitalize',
  borderColor: theme.palette.grey[100],
  '&:hover': {
    borderColor: theme.palette.grey[100],
  },
  '& > span:last-of-type': {
    flex: 1,
    justifyContent: 'end',
  },
}

const datePickerButtonOpened: SxProps = {
  borderBottom: 'unset',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderColor: 'rgba(0, 0, 0, 0.12)',
  '&:hover': {
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderBottomWidth: 0,
  },
}

const datePickerPaper: SxProps = {
  width: 1,
  zIndex: 9,
  borderTop: 'unset',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  '& .MuiPickersDay-today': {
    border: 'unset !important', // Removes the circle border of today's date
  },
}

const selectItemContent: SxProps = {
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.6rem',
  fontWeight: 500,
}

export const styles = {
  content,
  numberInput,
  frequencySelect,
  radioGroup,
  formControlLabel,
  datePickerButton,
  datePickerButtonOpened,
  datePickerPaper,
  selectItemContent,
}
