import {
  ComplianceHistoryDataType,
  ChartDataType,
  DateValueType,
} from './ComplianceHistory.types'
import moment from 'moment'

/**
 * Using API data of @type {ComplianceHistoryDataType[]} and converts it to BartChartStackedBySign fitted data
 * @param {ComplianceHistoryDataType[]} data
 */
export const fitDataToChart = (
  data: ComplianceHistoryDataType[],
  userLanguage: string
): ChartDataType[] => {
  let compliantValues: DateValueType[] = []
  let nonCompliantValues: DateValueType[] = []

  data.forEach((item) => {
    if (item.group_code === 'COMPLIANT') {
      compliantValues = item.values.slice(-6)
    } else {
      nonCompliantValues = item.values.slice(-6)
    }
  })

  const formattedChartData = compliantValues.map((item: DateValueType, index: number) => {
    return {
      name: moment(item.date).locale(userLanguage).format('MMM YY'),
      low: item.value,
      high: -nonCompliantValues[index].value,
    }
  })

  const filteredZeroValues = formattedChartData.filter((score) => score.low || score.high)

  return filteredZeroValues
}
