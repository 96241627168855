import React, { Children, forwardRef, RefObject } from 'react'
import { Stack } from '@mui/material'
import HeaderButtonHandler from 'components/ui/HeaderButtonHandler'
import { ListItem, ListItemButton, ListItemText } from '@mui/material'

type PropsType = {
  children: JSX.Element[]
  shownButtons: number
  headerId: string
  secondaryActions: string[]
}

const transformButtonsToListElements = (children: JSX.Element[]) => {
  return children.map((child) => {
    if (!child) return null
    if (child?.props?.complexComponent) {
      // If the component explicitly says it is a complex component (Maybe it is rendering another popup or something of the sort)
      // Then we make it so it is responsible of returning itself as a list item when it is in the drawer
      return React.cloneElement(child, { listView: true })
    }
    return (
      <ListItem sx={{ paddingX: 0 }} key={child.key} {...child.props}>
        <ListItemButton>
          <ListItemText primary={child.props.text ?? child.props.children} />
        </ListItemButton>
      </ListItem>
    )
  })
}

const ResizableButtonContainer = forwardRef<
  HTMLDivElement,
  PropsType & { ref: RefObject<HTMLDivElement> }
>(({ shownButtons, children, headerId, secondaryActions }: PropsType, ref) => {
  const primaryButtons = children?.length
    ? children?.filter((child) => !secondaryActions?.includes(child?.key ?? ''))
    : null
  const secondaryButtons = children?.length
    ? children?.filter((child) => secondaryActions?.includes(child?.key ?? ''))
    : null
  const pbLength = primaryButtons?.length ?? 0
  return (
    <Stack ref={ref} component={'div'} alignItems="center" justifyContent="center">
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        {Children.map(primaryButtons, (child, i) => {
          if (i >= pbLength - shownButtons) {
            return child
          }
        })}
        {(pbLength && pbLength > shownButtons) || secondaryButtons?.length ? (
          // @ts-expect-error - The component is JS
          <HeaderButtonHandler headerId={headerId} shownButtons={shownButtons}>
            {transformButtonsToListElements([
              ...(primaryButtons?.slice(0, pbLength - shownButtons) ?? []),
              ...(secondaryButtons ?? []),
            ])}
          </HeaderButtonHandler>
        ) : null}
      </Stack>
    </Stack>
  )
})

export default ResizableButtonContainer
