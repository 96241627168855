import { useQuery } from 'react-query'
import { getCompanyBulkInformation } from 'services/api'
import { GetCompanyBulkInformationParams } from 'services/api/endpoints/risikaAPI/getCompanyBulkInformation'

export function useCompanyBulkInformation(data: GetCompanyBulkInformationParams) {
  return useQuery(
    'useCompanyBulkInformation' + data.localIdList.join(','),
    () => getCompanyBulkInformation(data),
    {
      refetchOnWindowFocus: false,
    }
  )
}
