import * as React from 'react'
import { Stack, Typography, Divider } from '@mui/material'
import RulesList from './widgets/RuleList/RuleList'
import styles from './ActionPanelRuleList.styles'
import { ActionPanelRuleListProps } from './ActionPanelRuleList.types'
import { queryFind } from 'services/queries'
import { ECPDataType } from 'services/api/endpoints/risikaAPI/getECPDataStructure'
import { useAvailableActions } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.hooks'
import { ErrorPageSimple } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { actionPanelIconsByKeys } from './ActionPanelRuleList.model'

const { mainContainer, secondaryContainer, divider } = styles

const ActionPanelRuleList = ({
  title,
  filterText,
  onRuleSelect,
}: ActionPanelRuleListProps) => {
  const dataStructure = queryFind('useECPDataStructure') as ECPDataType
  const availableActions = useAvailableActions(dataStructure, filterText)
  if (!dataStructure) {
    return (
      <ErrorPageSimple
        content={
          <FormattedMessage
            id={intl.creditPolicyNew('error-loading-ecp-builder-actions')}
          />
        }
      />
    )
  }

  return (
    <Stack sx={mainContainer}>
      <Typography variant="subtitle2">{title}</Typography>
      <Stack data-cy="actions-container" sx={secondaryContainer}>
        {availableActions.map((item) => (
          <Stack key={item.variable_category_key}>
            <Typography mb={3} ml={5} variant="subtitle1">
              {item.variable_category}
            </Typography>
            <Divider sx={divider} />
            <RulesList
              rulesList={item.rules}
              category={item.variable_category_key as keyof typeof actionPanelIconsByKeys}
              onSelect={onRuleSelect}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
export default ActionPanelRuleList
