import { useQuery, useQueryClient } from 'react-query'
import { customerSettingsField } from 'services/api'
import { CustomerSettingsFieldNameT } from 'types/queries'

export function useCustomerSettingsField(field: CustomerSettingsFieldNameT) {
  const queryClient = useQueryClient()

  return useQuery(['customerSettingsField', field], () => customerSettingsField(field), {
    onSuccess: () => {
      queryClient.removeQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'search' && query.queryKey[1] === 'company'
        },
      })
    },
  })
}
