import { UserTableHeaderProps } from '../UserTable.types'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { ButtonSecondary, InputField } from 'components-new'
import SearchIcon from '@mui/icons-material/Search'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const UserTableHeader = ({
  search,
  setSearch,
  addUser,
  canAddUser,
  disableInput,
}: UserTableHeaderProps) => {
  const isMediumScreen = useMediaQuery('(max-width: 715px)')
  const isSmallScreen = useMediaQuery('(max-width: 475px)')
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      paddingX={5}
      paddingY={1}
      alignItems={isMediumScreen ? 'flex-end' : 'center'}
      height={isMediumScreen ? 135 : 75}
      sx={{ backgroundColor: 'grey.50' }}
    >
      <Typography variant="h4">
        <FormattedMessage id={intl.settings('all-users')} />
      </Typography>
      <Stack
        direction={isMediumScreen ? 'column-reverse' : 'row'}
        gap={2}
        alignItems={isMediumScreen ? 'flex-end' : 'center'}
      >
        <Box>
          <InputField
            value={search}
            handleChange={setSearch}
            disabled={disableInput}
            placeholder="Search"
            startAdornment={<SearchIcon />}
            extraStyles={{ width: isSmallScreen ? '200px' : '300px' }}
          />
        </Box>
        <Box>
          <ButtonSecondary disabled={!canAddUser} onClick={addUser}>
            <FormattedMessage id={intl.settings('add-user-button')} />
          </ButtonSecondary>
        </Box>
      </Stack>
    </Stack>
  )
}

export default UserTableHeader
