import { colors } from 'configs'

export const chartProperties = {
  width: 340,
  height: 200,
  margin: { top: 30, bottom: 20 },
  cartesianGrid: false,
  XAxis: {
    dy: 20,
    tickLine: false,
    interval: 0,
    axisLine: false,
  },
  YAxis: false,
  tooltip: true,
  barSize: 10,
  referenceLine: true,
  referenceLineStroke: '#222222',
  referenceLineStrokeWidth: 0.1,
  radius: [5, 5, 0, 0],
  label: true,
  colors: [colors.riskIntelligence.highRisk, colors.riskIntelligence.lowRisk],
  labelType: 'PERCENT',
}

export const parameters = {
  list_id: 0,
  score_model: {
    DK: 'NEWTON ',
    NO: 'BOHR',
    SE: 'BOHR',
  },
  categories: [{ category: 'CREDIT_COMPLIANCE', function: 'COUNT' }],
  sample_type: 'END_OF_MONTH_INCOMPLETE',
  historic_monitor: true,
}
