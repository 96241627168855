import * as React from 'react'
import { Dialog, DialogTitle, Stack, Typography } from '@mui/material'
import { useDisclosure } from 'services/hooks'

import updateNote_dangerous from 'services/api/endpoints/risikaAPI/notes/resources/updateNote_dangerous'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { updateStaleData } from 'store_deprecated/credit-check/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonLink2, ButtonSecondary, ButtonTertiary, InputField } from 'components-new'
import { LocalId } from 'globalTypes'
import { ReduxRootState } from 'store_deprecated/types'

type AddInternalIdProps = {
  internalId: string
  localId: LocalId
}

const AddInternalId = ({ internalId, localId }: AddInternalIdProps) => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userLanguage = useSelector(
    (state: ReduxRootState) => state.auth?.user?.local?.lang
  )
  const [internalIdValue, setInternalIdValue] = React.useState('')

  async function handleSave() {
    const note = {
      data: {
        _internal_id: internalIdValue === '' ? null : internalIdValue,
      },
      localId,
    }

    if (note) {
      await updateNote_dangerous(note)

      dispatch(
        updateStaleData({
          localID: localId,
          language: userLanguage,
        })
      )
    }

    onClose()
  }

  return (
    <>
      <ButtonLink2 onClick={onOpen}>
        <FormattedMessage id={intl.companyInfo('add-internal-id-title')} />
      </ButtonLink2>
      <Dialog
        PaperProps={{ sx: { width: 368 } }}
        open={isOpen as boolean}
        onClose={onClose}
        aria-labelledby="add-internal-id"
      >
        <DialogTitle
          sx={{
            backgroundColor: 'primary.main',
            paddingX: 5,
            paddingY: 4,
            color: 'common.white',
          }}
          id="add-internal-id"
        >
          <Typography variant="h3" color="inherit">
            <FormattedMessage id={intl.companyInfo('add-internal-id-title')} />
          </Typography>
        </DialogTitle>
        <Stack paddingX={5} paddingY={4} spacing={5}>
          <InputField
            label={<FormattedMessage id={intl.companyInfo('add-internal-id-label')} />}
            value={internalIdValue}
            handleChange={setInternalIdValue}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={4}>
            <ButtonTertiary onClick={onClose}>
              <FormattedMessage id={intl.generic('cancel')} />
            </ButtonTertiary>
            <ButtonSecondary onClick={handleSave}>
              <FormattedMessage id={intl.generic('add')} />
            </ButtonSecondary>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

export default AddInternalId
