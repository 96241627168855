export const riskIntelligenceApiKeys = {
  highlights: 'highlights',
  violations: 'credit_policy.violations',
  creditScore: 'risk_assessment.code',
  compliance: 'credit_policy.allowed',
  compliant: 'COMPLIANT',
  nonCompliant: 'NONCOMPLIANT',
  freeSearch: 'free_search',
  employeesNumber: 'employees_number',
  profitLoss: 'profit_loss',
  equity: 'equity',
  creditAutomatorResult: 'credit_automator_result',
}
