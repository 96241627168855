import { useQuery } from 'react-query'
import { creditPolicyGet } from 'services/api'
import { CreditPolicyT } from 'services/api/endpoints/risikaAPI/creditPolicyGet'
import { RisikaApiError } from 'types/general'

export function useSinglePolicy(country: string) {
  return useQuery<CreditPolicyT, RisikaApiError>(
    ['singlePolicy', country],
    () => creditPolicyGet(country),
    {
      refetchOnWindowFocus: false,
    }
  )
}
