const edge = ({ targetX, targetY }: { targetX: number; targetY: number }) => ({
  position: 'absolute',
  transform: `translate(${targetX - 85}px,${targetY - 17}px)`,
  // backgroundColor: 'grey.300',
  pointerEvents: 'all',
})

const textBorderStyles = {
  paddingY: 1,
  paddingX: 3,
  borderRadius: '20px',
  borderWidth: 2,
  textTransform: 'uppercase',
}

const policyBuilderEdgeStyles = {
  Yes: {
    color: 'success.900',
    backgroundColor: 'success.100',
    borderColor: 'success.900',
    ...textBorderStyles,
  },
  No: {
    color: 'error.900',
    backgroundColor: 'error.100',
    borderColor: 'error.900',
    ...textBorderStyles,
  },
}

const plusStyle = {
  zIndex: 100,
  backgroundColor: 'grey.600',
  width: 16,
  height: 16,
  padding: 0,
  left: 9,
  borderStyle: 'solid',
  borderRadius: '20px',
  cursor: 'pointer',
  fontSize: 12,
  color: 'common.white',
  boxShadow: 1,
  '&:hover': {
    backgroundColor: 'primary.dark',
    boxShadow: 2,
  },
  '&:active': {
    boxShadow: 'none',
    borderWidth: '2px',
    borderColor: 'primary.500',
  },
}

export const customEdgeStyle = {
  edge,
  policyBuilderEdgeStyles,
  plusStyle,
}
