import axios from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { isNil } from 'ramda'

import { store } from 'context/store'
import { navigation } from 'services/navigation'
import { forceUserPasswordChange } from 'store_deprecated'
import history from 'utils/history'

import {
  RISIKA_API_ACCESS_TOKEN,
  AUTH_REFRESH_TOKEN,
  AUTH_ACCESS_TOKEN,
  BACKEND_API_BASE_URL,
} from './config'

/**
 * Authenticate the user using Single Sign-On
 * and set the API tokens.
 */
export const authLoginUsingSso = async (accessToken) => {
  const { data } = await axios({
    method: 'get',
    url: `${BACKEND_API_BASE_URL}/auth/sso`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })

  setAPITokens(data.auth)

  return {
    auth: data.auth,
    user: data.user,
    settings: data.settings,
  }
}

export const authSsoCheckEmail = async (email) => {
  const { data } = await axios({
    method: 'post',
    url: `${BACKEND_API_BASE_URL}/auth/sso/check`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      email,
    },
  })

  return data.sso === true
}

/**
 * Authenticate the user and set the API tokens.
 */
export const authLogin = async (email, password) => {
  const { data } = await axios({
    method: 'post',
    url: `${BACKEND_API_BASE_URL}/auth/login`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      email,
      password,
    },
  })

  setAPITokens(data.auth)

  return {
    auth: data.auth,
    user: data.user,
    settings: data.settings,
  }
}

/**
 * Register a new comapny with a user.
 */
export const authRegister = async ({
  email,
  name,
  localId,
  subscription_plan = 'TRIAL',
}) => {
  return axios({
    method: 'post',
    url: `${BACKEND_API_BASE_URL}/auth/register`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      email: email,
      user_name: name,
      local_organization_id: localId,
      subscription_plan,
    },
  })
}
/**
 * Register a new comapny with a user.
 */
export const authFrictionlessRegister = async ({
  // Not used anymore by the marketing team
  email,
  firstName,
  localId,
  password,
  confirmPassword,
}) => {
  return axios({
    method: 'post',
    url: `${BACKEND_API_BASE_URL}/auth/frictionless/register`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      email: email,
      first_name: firstName,
      local_id: localId,
      password: password,
      confirmPassword: confirmPassword,
    },
  })
}

/***********
 * Helpers *
 ***********/

/**
 * Get the auth refresh token from Local Storage.
 *
 * @returns {string} The auth refresh token.
 */
const getAuthRefreshToken = () => {
  return localStorage.getItem(AUTH_REFRESH_TOKEN)
}

/**
 * Get the auth access token from Local Storage.
 *
 * @returns {string} The auth access token.
 */
const getAuthAccessToken = () => {
  return localStorage.getItem(AUTH_ACCESS_TOKEN)
}

/**
 * Get the Risika API access token from Local Storage.
 *
 * @returns {string} The Risika API access token.
 */
const getRisikaAPIAccessToken = () => {
  return localStorage.getItem(RISIKA_API_ACCESS_TOKEN)
}

/**
 * Check if the given token has expired.
 *
 * @param {string} token The JWT to check.
 * @param {object} options Override the default options:
 * - expireEarly: 10,000 ms (proactively fetch a new token before it has expired)
 *
 * @returns {boolean} Whether the token has expired.
 */
const hasExpired = (token, options = {}) => {
  const { expireEarly = 10000 } = options

  return moment.unix(jwtDecode(token).exp).diff(moment.now()) < expireEarly
}

/**
 * Check if the token expired and renew it if that is the case.
 */
const renewTokensIfExpired = async () => {
  const authAccessToken = getAuthAccessToken()
  const risikaAPIAccessToken = getRisikaAPIAccessToken()

  // Guard against missing tokens (user not logged in)
  if (isNil(authAccessToken)) {
    return
  }

  if (hasExpired(authAccessToken) || hasExpired(risikaAPIAccessToken?.substr(4))) {
    await renewTokens()
  }
}

/**
 * Handle when the session expires.
 */
const sessionExpired = () => {
  history.push(navigation.auth.logout(), {
    email: store.getState().auth.user.data.email,
    sessionExpired: true,
  })
}

/**
 * Keep track of the current renew request to avoid doing multiple requests.
 */
let _renewRequest = null

/**
 * Renew the token and store them in Local Storage.
 */
export const renewTokens = () => {
  if (_renewRequest) {
    return _renewRequest
  }

  _renewRequest = axios({
    method: 'post',
    url: `${BACKEND_API_BASE_URL}/auth/refresh`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      refresh_token: getAuthRefreshToken(),
    },
  })
    .then(({ data: { auth, user } }) => {
      setAPITokens(auth)

      if (user.should_change_password === true) {
        store.dispatch(forceUserPasswordChange())
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        sessionExpired()
      }
    })
    .finally(() => {
      _renewRequest = null
    })

  return _renewRequest
}

export const setAPITokens = (auth) => {
  localStorage.setItem(RISIKA_API_ACCESS_TOKEN, auth.api_token)
  localStorage.setItem(AUTH_REFRESH_TOKEN, auth.refreshToken)
  localStorage.setItem(AUTH_ACCESS_TOKEN, auth.token)
}

/********************************************
 * Risika API and Backend API Access Tokens *
 ********************************************/

/**
 * Get the auth access token from Local Storage.
 *
 * @returns {string} The auth access token.
 */
export const fetchAuthAccessToken = async () => {
  await renewTokensIfExpired()
  return getAuthAccessToken()
}

/**
 * Get the Risika API access token from Local Storage.
 *
 * @returns {string} The Risika API access token.
 */
export const fetchRisikaAPIAccessToken = async () => {
  await renewTokensIfExpired()
  return getRisikaAPIAccessToken()
}
