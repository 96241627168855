import { LoadingSkeletonProps } from './LoadingSkeleton.types'

const LoadingSkeleton = ({
  children,
  SkeletonComponent,
  isLoading,
}: LoadingSkeletonProps) => {
  if (isLoading) {
    return <SkeletonComponent />
  }
  return children
}

export default LoadingSkeleton
