import { createContext, useContext } from 'react'

interface SupportContextProps {
  isSupportPromptDisplay: boolean
  setSupportDisplay: (value: boolean) => void
}

const SupportContext = createContext<SupportContextProps | undefined>(undefined)

export const useSupportContext = () => {
  const context = useContext(SupportContext)
  if (!context) {
    throw new Error('useSupportContext must be used within a SupportProvider')
  }
  return context
}

export default SupportContext
