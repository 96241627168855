import {
  cancelNode,
  deleteNodeLine,
  figureOutIfWeAreOnNegativeOrPositiveEdge,
  getLayoutedElements,
} from '../PolicyBuilder.controller'
import {
  BuilderAction,
  BuilderNode,
  BuilderState,
  NodeData,
  NodeVariations,
} from '../PolicyBuilder.types'
import {
  ADD_FIRST_NODE,
  ON_EDGES_CHANGE,
  ACTION_PANEL_TOGGLE,
  SET_ACTION_PANEL_CONTENT,
  SET_NODE_API_DATA,
  ADD_NODE_API_DATA,
  CANCEL_NODE,
  ADD_NODES_AND_EDGES,
  ON_NODES_CHANGE,
  CREATE_EMPTY_NODE,
  ALLOW_ONLY_ACTIVE_COMPANIES,
  DELETE_NODE,
  ADD_UPDATE_HIGHLIGHTED_NODE,
  DELETE_HIGHLIGHTED_NODE,
  UPDATE_CATEGORY,
  CREATE_NODE_BETWEEN_NODES,
} from './constants'
import {
  addPositiveAndNegativeEdge,
  edgeWithData,
  getKeyBasedOnNodeType,
  getNodeType,
  handleHighlightedNodesFromActionPanel,
  nodeWithData,
  updateNodeInState,
  findVariableFromLabel,
  getPreservedValues,
  findTheRestOfTheArray,
  updateNodeInNodes,
  handleAddRestOfNodes,
  makeSureYesNodesHaveLowerIdsThanNoNodesOnTheSameLine,
  findTheActiveNodeId,
  updateNodeBuilderDataByEdges,
} from './BuilderContext.controller'
import { typeGuard } from 'utils/general'

export function builderReducer(
  state: BuilderState,
  { type, payload }: BuilderAction
): BuilderState {
  switch (type) {
    case ADD_NODES_AND_EDGES: {
      const { nodes, edges } = payload
      const currHighestId = nodes.reduce((acc, node) => {
        const id = Number(node.id)
        return id > acc ? id : acc
      }, 0)
      return {
        ...state,
        nodes: [
          ...state.nodes,
          ...nodes.reduce((acc: BuilderNode[], node) => {
            return nodeWithData({ nodes: acc, node, edges })
          }, []),
        ],
        edges: [
          ...state.edges,
          ...edges.map((edge) => {
            return edgeWithData(edge)
          }),
        ],
        currHighestId,
      }
    }
    case ON_NODES_CHANGE: {
      if (!payload) return state
      if (payload.length <= 1) {
        // Here we handle the initial state change where we add the first node
        const payloadWithNewPosition = payload.map((node) => {
          if (node.id === '1') {
            return {
              ...node,
              position: {
                x: 0,
                y: 0,
              },
            }
          }
          return node
        })
        return {
          ...state,
          nodes: payloadWithNewPosition,
        }
      }
      const { nodes, edges } = getLayoutedElements({
        nodes: payload,
        edges: state.edges,
      })

      const filteredEdges = edges.filter((edge) => {
        const sourceNode = nodes.find((node) => node.id === edge.source)
        const targetNode = nodes.find((node) => node.id === edge.target)
        return !!sourceNode || !!targetNode
      })
      return {
        ...state,
        nodes,
        edges: filteredEdges,
      }
    }

    case ON_EDGES_CHANGE: {
      return {
        ...state,
        edges: payload,
      }
    }

    case ACTION_PANEL_TOGGLE: {
      return {
        ...state,
        actionPanelData: {
          open: payload.open,
          nodeId: payload.open ? payload.nodeId ?? '' : '',
        },
        actionPanelContent: !payload.open ? '' : state.actionPanelContent,
        highlightedNodes: handleHighlightedNodesFromActionPanel({
          actionPanelData: payload,
          state,
        }),
      }
    }
    case SET_ACTION_PANEL_CONTENT: {
      return {
        ...state,
        actionPanelContent: payload,
      }
    }

    case CANCEL_NODE: {
      const nodeId = state.actionPanelData.nodeId
      if (!nodeId) return state // NodeId should be required not optional TODO: Fix TS
      return {
        ...state,
        nodes: cancelNode({ nodes: state.nodes, nodeId }),
        actionPanelData: {
          open: false,
          nodeId: '',
        },
        actionPanelContent: '',
        highlightedNodes: handleHighlightedNodesFromActionPanel({
          actionPanelData: { open: false },
          state,
        }),
      }
    }
    case DELETE_NODE: {
      const nodeId = state.actionPanelData.nodeId
      if (!nodeId) return state // NodeId should be required not optional TODO: Fix TS
      const highlightedNodes = handleHighlightedNodesFromActionPanel({
        actionPanelData: { open: false },
        state,
      })
      const node = state.nodes.find((node) => node.id === nodeId)
      if (node?.data.builderData.parentId === null) {
        // In case we are trying to delete the first node
        return {
          ...state,
          nodes: [
            {
              ...node,
              data: {
                ...node.data,
                apiData: {},
                builderData: {
                  ...node.data.builderData,
                  childrenIds: [],
                },
              },
            },
          ],
          highlightedNodes,
          edges: [],
          actionPanelData: {
            open: false,
            nodeId: '',
          },
          actionPanelContent: '',
        }
      }
      // Replaces the selected node with a + and deletes all connected nodes
      const newNodes = deleteNodeLine({
        nodes: state.nodes,
        nodeId,
        shouldCancelNode: true,
      })
      // Filter out edges that have a target node that no longer exists
      const newEdges = state.edges.filter((edge) => {
        const targetNode = newNodes.find((node) => node.id === edge.target)
        return !!targetNode
      })
      const newState = {
        ...state,
        nodes: newNodes,
        edges: newEdges,
        actionPanelData: {
          open: false,
          nodeId: '',
        },
        highlightedNodes,
        actionPanelContent: '',
      } as BuilderState
      payload.savePolicy(newState)
      return newState
    }

    case ADD_FIRST_NODE: {
      const nodeType = payload
      const type = getNodeType(nodeType)
      return {
        ...state,
        nodes: [
          {
            position: {
              x: 0,
              y: 0,
            },
            id: '1',
            type: 'rule',
            data: {
              label: '',
              [getKeyBasedOnNodeType(type)]: nodeType,
              builderData: {
                parentId: '',
                childrenIds: [],
              },
              apiData: {},
            },
          },
        ],
        currHighestId: 1,
      }
    }
    case CREATE_EMPTY_NODE: {
      const { nodeData, nodeId } = payload
      const selectedNode = state.nodes.find((node) => node.id === nodeId) as BuilderNode
      const variable = findVariableFromLabel(nodeData.label)
      if (!variable) return state

      let newState = state
      const nodeType = variable?.data?.type as NodeVariations | undefined
      // const isChangingTypes =
      //   (selectedNode.type === 'rule' && nodeType === 'action') ||
      //   (selectedNode.type === 'action' && nodeType === 'rule')
      const shouldDeleteLine =
        variable?.data?.type === 'action' &&
        selectedNode.data.builderData.childrenIds.length > 0
      if (shouldDeleteLine) {
        // We are editing a rule into action or the opposite
        newState = {
          ...newState,
          nodes: deleteNodeLine({
            nodes: newState.nodes,
            nodeId: selectedNode.id,
            shouldCancelNode: true,
          }),
          edges: newState.edges.filter((edge) => edge.source !== selectedNode.id),
        }
      }
      const newData = {
        ...selectedNode.data,
        label: nodeData.label,
        builderData: {
          ...selectedNode.data.builderData,
          childrenIds: shouldDeleteLine ? [] : selectedNode.data.builderData.childrenIds,
          variant: nodeData.variant,
        },
        apiData: {
          [getKeyBasedOnNodeType(nodeType)]: nodeData.apiKey,
        },
      } as NodeData

      return updateNodeInState({
        state: newState,
        nodeId: payload.nodeId,
        newData,
        type: nodeType,
      }) as BuilderState
    }

    case ADD_NODE_API_DATA: {
      const nodeId = payload.nodeId
      const additionalData = payload.data
      const selectedNode = state.nodes.find((x) => x.id === nodeId)

      return {
        ...state,
        nodes: state.nodes.map((node) => {
          if (node.id === selectedNode?.id) {
            return {
              ...node,
              data: {
                ...node.data,
                apiData: {
                  ...node.data.apiData,
                  ...additionalData,
                },
              },
            }
          }
          return node
        }),
      }
    }

    case SET_NODE_API_DATA: {
      const selectedNode = state.nodes.find((x) => x.id === payload.nodeId)
      if (!selectedNode) return state
      const type = selectedNode.type as NodeVariations
      const keyBasedOnType = getKeyBasedOnNodeType(type)
      const apiCategoryOrOutcomeValue = selectedNode.data.apiData
        ? selectedNode.data?.apiData?.[keyBasedOnType as keyof NodeData['apiData']]
        : null
      const newData = {
        ...selectedNode.data,
        apiData: {
          [keyBasedOnType]: apiCategoryOrOutcomeValue,
          ...payload.data,
        },
        // ...evaluateCategory(payload.data), // This does nothing i think
      } as NodeData
      // We make sure the action panel is closed
      const stateWithUpdatedNode = {
        ...state,
        actionPanelData: {
          open: false,
          nodeId: '',
          parentId: '',
        },
        actionPanelContent: '',
        nodes: updateNodeInState({
          state,
          nodeId: payload.nodeId,
          newData,
          returnWholeState: false,
        }),
      } as BuilderState

      const selectedNodeWithUpdatedData = stateWithUpdatedNode.nodes.find(
        (x) => x.id === payload.nodeId
      )

      if (!selectedNodeWithUpdatedData) return stateWithUpdatedNode
      if (selectedNodeWithUpdatedData.type === 'rule') {
        const stateWithEdges = addPositiveAndNegativeEdge({
          state: stateWithUpdatedNode,
          selectedNode: selectedNodeWithUpdatedData,
        })
        if (payload.savePolicy) payload.savePolicy(stateWithEdges)
        return stateWithEdges
      }
      if (payload.savePolicy) payload.savePolicy(stateWithUpdatedNode)

      return stateWithUpdatedNode
    }
    case UPDATE_CATEGORY: {
      const selectedNode = state.nodes.find((x) => x.id === state.actionPanelData.nodeId)
      if (!selectedNode) return state
      const type = selectedNode.type as NodeVariations
      const keyBasedOnType = getKeyBasedOnNodeType(type)

      const newData = {
        ...selectedNode.data,
        apiData: {
          [keyBasedOnType]: payload.apiKey,
          ...getPreservedValues({ apiKey: payload.apiKey, selectedNode }),
        },
        builderData: {
          ...selectedNode.data.builderData,
          variant:
            payload.variant !== null
              ? payload.variant
              : typeGuard('variant', selectedNode.data.builderData),
        },
      } as NodeData

      return updateNodeInState({
        state,
        nodeId: selectedNode.id,
        newData,
      }) as BuilderState
    }
    case ALLOW_ONLY_ACTIVE_COMPANIES: {
      return {
        ...state,
      }
    }
    case ADD_UPDATE_HIGHLIGHTED_NODE: {
      return {
        ...state,
        highlightedNodes: {
          ...state.highlightedNodes,
          [payload.nodeId]: { type: payload.type },
        },
      }
    }
    case DELETE_HIGHLIGHTED_NODE: {
      const { [Number(payload)]: _, ...newHighlightedNodes } = state.highlightedNodes
      return {
        ...state,
        highlightedNodes: newHighlightedNodes,
      }
    }
    case CREATE_NODE_BETWEEN_NODES: {
      const { parentNodeId, childNodeId, nodeLine } = payload
      /**
       * 1. Add a Plus node between the parent and child
       * 2. Append the rest of the three to the selected line
       * 3. Add Plus to the non selected line
       * 4. Make it so the selected node is that plus node
       * 5. Open the Side panel
       */
      const parentNode = state.nodes.find((node) => node.id === parentNodeId)

      const childNode = state.nodes.find((node) => node.id === childNodeId)
      /**
       * 1. Store the remainder of the array in a variable V
       * 2. Delete it from the original V
       * 3. Add the Plus nodes with the already built function V
       * 4. Append the already stored rest of the array
       */

      if (!parentNode || !childNode) return state

      // We make a copy of the branch that we append the new node to
      const restOfNodes = findTheRestOfTheArray({
        parentId: parentNode.id,
        childId: childNode.id,
        allNodes: state.nodes,
        accNodes: [],
      })

      // We remove those same nodes from the original three
      const nodesWithRemovedNodes = deleteNodeLine({
        nodeId: childNodeId,
        nodes: state.nodes,
        shouldCancelNode: false,
      })

      // Here we remove the node id of the removed nodes from the children ids array of the parent
      const nodesWithUpdatedParentNode = updateNodeInNodes({
        nodes: nodesWithRemovedNodes,
        nodeId: parentNodeId,
        newData: {
          ...parentNode.data,
          builderData: {
            ...parentNode.data.builderData,
            childrenIds: parentNode.data.builderData.childrenIds.filter(
              (id) => id !== childNodeId
            ),
          },
        },
      })

      // Here we add a plus node to the parent. This will be the active node at the end
      const stateWithAddedEdge = addPositiveAndNegativeEdge({
        state: {
          ...state,
          nodes: nodesWithUpdatedParentNode,
        },
        selectedNode:
          nodesWithUpdatedParentNode.find((node) => node.id === parentNodeId) ??
          parentNode, // This never happens
        // Here we figure out of the user selected Yes or No and we add the Plus to that side
        addOnlyOneEdge: figureOutIfWeAreOnNegativeOrPositiveEdge({
          edges: state.edges,
          parentNodeId,
          childNodeId,
        }),
      })

      // Find the node with the highest id
      const nodeWithHighestId = stateWithAddedEdge.nodes.reduce((acc, node) => {
        const id = Number(node.id)
        return id > acc ? id : acc
      }, 0)
      const newParentNode = stateWithAddedEdge.nodes.find(
        (node) => node.id === nodeWithHighestId.toString()
      )
      // Here the parent node is the plus node that we added earlier
      if (!newParentNode) return state

      // And now we can append the rest of the nodes back to the three
      const stateWithAddedPlusNodeAndRestOfNodes = handleAddRestOfNodes({
        state: stateWithAddedEdge,
        restOfNodes,
        parentNode: newParentNode,
        nodeLine,
      })
      // We have some unnecessary edges at this point so we filter them out
      // Filter out the edges that have a source the parent node and a source the child node
      const newEdges = stateWithAddedPlusNodeAndRestOfNodes.edges.filter((edge) => {
        if (edge.source === parentNode.id && edge.target === childNode.id) {
          return false
        }
        return true
      })

      /**
       * At this point our job is done. However dagre (the library that we use for the layout) requires to get the nodeIds in particular order
       * otherwise it will put the No edge on top of the No one.
       * So we need to make sure the Yes nodes in a particular column have lower ids than the No nodes in the same column
       * Just an advice: If you don't need to fix something, don't click on the function bellow :)
       */
      const { nodes: nodesWithFlippedIds, edges: edgesWithFlippedIds } =
        makeSureYesNodesHaveLowerIdsThanNoNodesOnTheSameLine({
          nodes: updateNodeBuilderDataByEdges({
            nodes: stateWithAddedPlusNodeAndRestOfNodes.nodes,
            edges: newEdges,
          }),
          edges: newEdges,
        })
      const stateWithUpdatedEdgesNodesWithSwappedIds = {
        ...stateWithAddedPlusNodeAndRestOfNodes,
        nodes: nodesWithFlippedIds,
        edges: edgesWithFlippedIds,
      } as BuilderState
      const activeNodeId = findTheActiveNodeId({
        parentNodeId: parentNodeId,
        childNodeId,
        nodes: stateWithUpdatedEdgesNodesWithSwappedIds.nodes,
      })
      return {
        ...stateWithUpdatedEdgesNodesWithSwappedIds,
        actionPanelData: {
          open: !!activeNodeId,
          nodeId: activeNodeId ? activeNodeId.toString() : '',
        },
      }
    }
    default: {
      throw new Error(`Unhandled type: ${type}`)
    }
  }
}
