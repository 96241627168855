import Typography from '@mui/material/Typography'
import { isNil } from 'ramda'
import { useIntl } from 'react-intl'
export const TableRowValue = ({ children }) => {
  const reactIntl = useIntl()

  return (
    <td style={{ textAlign: 'right' }}>
      <Typography variant="body2">
        {!isNil(children) ? reactIntl.formatNumber(children) : '-'}
      </Typography>
    </td>
  )
}
