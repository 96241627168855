// @flow

import { AvailableCountries, LocalId, ModelsT } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { createFilters } from './services/filters'
import { Address } from 'types/general'
import { Mixpanel } from 'services/helpers/mixpanel'

export type SearchResultsType = {
  local_organization_id: LocalId
  active: boolean
  company_name: string
  score?: number
  company_type: string
  webpage?: string
  employees_interval?: string
  advertisement_protected: boolean
  address: Address
  date_of_incorporation?: string
  secondary_names: {
    valid_to: string
    valid_from: string
    company_name: string
  }[]
  historical_names: {
    valid_to: string
    valid_from: string
    company_name: string
  }[]
  credit_policy: {
    allowed: boolean
    conflicts: {
      code: string
      text: string
    }[]
  }
}

export type CompanySearchResponse = {
  count: number
  rows: {
    from: number
    to: number
  }
  from: number
  to: number
  search_result: Array<SearchResultsType>
}

type ResponseData = {
  data: CompanySearchResponse
}

type Search = {
  model?: ModelsT
  searchText: string
  companyType?: []
  employees?: []
  equity?: {
    value: number
    operator: string
  }
  grades?: []
  industryGroup?: []
  subIndustry?: []
  specificIndustry?: []
  regions?: []
  municipalities?: []
  showInactive?: boolean
  hideNoToAdvertisement?: boolean
  searchCountry: AvailableCountries
  active?: boolean
}
/**
 * Search for a company.
 *
 * @returns {object} The search results.
 * @param search
 * @param options
 */
async function searchCompany(
  search: Search,
  options?: { currency?: string }
): Promise<CompanySearchResponse> {
  const { searchCountry: country, model } = search
  const currency = options?.currency ?? 'DKK'
  const filters = createFilters(search)
  const requestData = {
    product: model,
    mode: 'full',
    from: 0,
    to: 1000,
    filters,
    currency,
  }

  Mixpanel.track('User searched', { type: 'company', query: search.searchText, country })

  const { data }: ResponseData = await risikaAPI.post('/search/company', requestData, {
    microservice: 'data',
    country,
    appendScoringModel: true,
  })
  return data
}

export default searchCompany
