import { authRoles } from 'components/auth'
import AdvancedFiltersPage from './AdvancedFiltersPage'
import { navigation } from 'services/navigation'
export const AdvancedFiltersPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: navigation.filtering(),
      component: AdvancedFiltersPage,
    },
  ],
}
