import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import { default as cx } from 'classnames'
import { Paper, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import PieChart from '../../scenes/PieChart'
import ListRole from 'pages/credit-check/scenes/ListRole'
import { navigation } from 'services/navigation'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ConditionallyRender } from 'components'
import CardItem from '../CardItem'
import { useStyles } from './OwnersSimpleView.styles'
import { OwnersSimpleViewProps } from './OwnersSimpleView.types'
import { Owner } from 'types/general'
import BusinessIcon from '@mui/icons-material/Business'

const OwnersSimpleView = ({
  legalOwners,
  realOwners,
  country,
  stripFunctionsForOwners,
  subsidiaries,
  hasHierarchy,
  localId,
  ownerData,
}: OwnersSimpleViewProps) => {
  const classes = useStyles()
  const history = useHistory()
  const [strippedLegalOwners, setStrippedLegalOwners] = React.useState<Array<Owner>>([])
  const [strippedRealOwners, setStrippedRealOwners] = React.useState<Array<Owner>>([])
  const isDenmark = country && country.toUpperCase() === 'DK'
  const isMediumScreen = useMediaQuery('(max-width:1270px)')
  React.useEffect(() => {
    setStrippedLegalOwners(stripFunctionsForOwners(legalOwners, ['LEGAL OWNER']))
  }, [legalOwners, stripFunctionsForOwners])

  React.useEffect(() => {
    setStrippedRealOwners(stripFunctionsForOwners(realOwners, ['BENEFICIAL OWNER']))
  }, [realOwners, stripFunctionsForOwners])

  const handleClick = (e: React.SyntheticEvent, to: string) => {
    e.preventDefault()
    history.push(to)
  }

  const legalOwnersLength = ownerData.filter((x) =>
    ['Legal Owner', 'Legal ejer', 'Juridisk eier', 'Juridisk ägare'].includes(x.position)
  )?.length

  const realOwnersLength = ownerData.filter((x) =>
    ['Beneficial Owner', 'Reel ejer', 'Reel eier', 'Verkliga ägare'].includes(x.position)
  )?.length

  return (
    <motion.div
      className={
        legalOwnersLength && realOwnersLength
          ? classes.container
          : classes.containerNoData
      }
      animate={{ opacity: 1 }}
    >
      <div
        className={
          legalOwnersLength && realOwnersLength
            ? classes.pieContainer
            : classes.pieContainerNoData
        }
      >
        <div style={{ position: 'relative', maxHeight: '24rem', marginLeft: 8 }}>
          <ConditionallyRender
            condition={legalOwnersLength > 0}
            when={<PieChart data={strippedLegalOwners} />}
          />
        </div>
        <div className={cx(classes.col)} style={{ marginTop: isMediumScreen ? 48 : 0 }}>
          <div>
            <ListRole
              isLabel
              header={intl.companyRoles('legal-owners')}
              data={strippedLegalOwners.filter((x) => x.functions.length)}
              handleClick={handleClick}
              message={
                <FormattedMessage
                  id={intl.companyRoles(`${isDenmark ? 'no-owners' : 'no-information'}`)}
                />
              }
            />
          </div>
          <div
            className={
              legalOwnersLength && realOwnersLength
                ? classes.listRoleContainer
                : classes.listRoleContainerNoData
            }
          >
            <ListRole
              isLabel
              header={intl.companyRoles('real-owners')}
              data={strippedRealOwners.filter((x) => x.functions.length)}
              handleClick={handleClick}
              message={
                <FormattedMessage
                  id={intl.companyRoles(`${isDenmark ? 'no-owners' : 'no-information'}`)}
                />
              }
            />
          </div>
        </div>
      </div>
      <div className={classes.typographyContainer}>
        <div className={classes.cardItemContainer} style={{ display: 'flex' }}>
          <ConditionallyRender
            condition={legalOwnersLength > 0}
            when={
              <CardItem
                count={legalOwnersLength}
                title={<FormattedMessage id={intl.companyRoles('legal-owners')} />}
              />
            }
          />
          <ConditionallyRender
            condition={realOwnersLength > 0}
            when={
              <CardItem
                count={realOwnersLength}
                title={<FormattedMessage id={intl.companyRoles('real-owners')} />}
              />
            }
          />
        </div>

        <div className={classes.cardItemContainer} style={{ display: 'flex' }}>
          <ConditionallyRender
            condition={subsidiaries > 0}
            when={
              <CardItem
                count={subsidiaries}
                title={<FormattedMessage id={intl.companyRoles('subsidiaries')} />}
              />
            }
          />
          <ConditionallyRender
            condition={hasHierarchy}
            when={
              <Paper
                onClick={() => history.push(navigation.companyHierarchy(localId))}
                className={classes.paperCardHierarchy}
              >
                <Typography variant="body1" sx={{ color: 'common.white' }}>
                  <FormattedMessage id={intl.companyHierarchy('header')} />
                </Typography>
                <BusinessIcon sx={{ color: 'secondary.300', fontSize: 72 }} />
                <Typography variant="body1" sx={{ color: 'common.white' }}>
                  <FormattedMessage id={intl.companyRoles('get-overview')} />
                </Typography>
              </Paper>
            }
          />
        </div>
      </div>
    </motion.div>
  )
}

export default OwnersSimpleView
