import { Stack, Chip, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import styles from './ChipStack.styles'
import { ChipStackPropsType } from './ChipStack.types'
import ChipStackLoader from './ChipStackLoader'
import { Key } from 'react'

const { chipStyles } = styles

const ChipStack = (props: ChipStackPropsType) => {
  const { chipData, handleChipDelete, isLoading = false } = props
  if (!chipData.length) return null
  if (isLoading) return <ChipStackLoader />
  return (
    <Stack
      gap={2}
      mt={2}
      flexWrap="wrap"
      width={1}
      direction="row"
      justifyContent="start"
    >
      {chipData.map((chip) => (
        <Chip
          sx={chipStyles}
          key={chip.value as Key}
          deleteIcon={<ClearIcon />}
          label={
            <Typography variant="body1" color="inherit">
              {chip.label as string}
            </Typography>
          }
          onDelete={() => handleChipDelete(chip.value?.toString())}
          {...props}
        />
      ))}
    </Stack>
  )
}

export default ChipStack
