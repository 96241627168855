import SearchesList from '../SearchesList'
import { CompanyVisitsList } from 'services/api/endpoints/risikaAPI/companyVisitsList'
import { columns } from './CompanyVisits.model'
const companyVisitsList = [
  {
    local_organization_id: {
      country: 'DK',
      id: '12356789',
    },
    risika_score: 5,
    risk_assessment: 'LOW',
    status: 'ACTIVE',
    name: 'Test Company',
    city: 'Copenhagen',
    company_type: 'ApS',
    count: 1,
    last_visit: '2021-01-01',
  },
  {
    local_organization_id: {
      country: 'DK',
      id: '12356789',
    },
    risika_score: 5,
    risk_assessment: 'LOW',
    status: 'ACTIVE',
    name: 'Test Company 2',
    city: 'Copenhagen',
    company_type: 'ApS',
    count: 1,
    last_visit: '2021-01-01',
  },
  {
    local_organization_id: {
      country: 'DK',
      id: '12356789',
    },
    risika_score: 5,
    risk_assessment: 'LOW',
    status: 'ACTIVE',
    name: 'Test Company 2',
    city: 'Copenhagen',
    company_type: 'ApS',
    count: 1,
    last_visit: '2021-01-01',
  },
  {
    local_organization_id: {
      country: 'DK',
      id: '12356789',
    },
    risika_score: 5,
    risk_assessment: 'LOW',
    status: 'ACTIVE',
    name: 'Test Company 2',
    city: 'Copenhagen',
    company_type: 'ApS',
    count: 1,
    last_visit: '2021-01-01',
  },
  {
    local_organization_id: {
      country: 'DK',
      id: '12356789',
    },
    risika_score: 5,
    risk_assessment: 'LOW',
    status: 'ACTIVE',
    name: 'Test Company 2',
    city: 'Copenhagen',
    company_type: 'ApS',
    count: 1,
    last_visit: '2021-01-01',
  },
] as CompanyVisitsList

function CompanyVisitsLoading() {
  return <SearchesList isLoading={true} searches={companyVisitsList} columns={columns} />
}

export default CompanyVisitsLoading
