import { RolesOptions, User } from './UserTable.types'

export const roleOptions: RolesOptions = [
  { value: 'user', label: 'User' },
  { value: 'admin', label: 'Admin' },
]

export const newEmptyUser: User = {
  userId: 0,
  lastLogin: '',
  name: '',
  email: '',
  phoneNumber: '',
  isVerified: false,
  roles: [
    {
      value: 'user',
      label: 'User',
    },
  ],
}
