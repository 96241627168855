import {
  SET_FILTER_SUGGESTIONS_FOR_COUNTRY_REQUEST,
  SET_FILTER_SUGGESTIONS_FOR_COUNTRY_SUCCESS,
} from './constants'

export const getFilterSuggestionsRequest = (country) => ({
  type: SET_FILTER_SUGGESTIONS_FOR_COUNTRY_REQUEST,
  payload: {
    country,
  },
  status: 'LOADING',
})

export const getFilterSuggestionsSuccess = (suggestions, country) => ({
  type: SET_FILTER_SUGGESTIONS_FOR_COUNTRY_SUCCESS,
  payload: { suggestions, country },
  status: 'SUCCESS',
})
