import React from 'react'
import { makeStyles } from '@mui/styles'
import { default as cx } from 'classnames'
import moment from 'moment'
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { Sidescroll, ConditionallyRender } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { theme } from 'styles-new/mui5-transition/theme'

const useStyles = makeStyles((theme) => ({
  tableRow: {
    borderBottom: 'none',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

// type Props = {
//   data: Array<any>,
//   tableHeaders: Array<any>,
//   title: string,
//   isShowAll: boolean,
//   footer?: any, // React element
// }

const RolesTable = ({ data, tableHeaders, isShowAll, footer = null }) => {
  const classes = useStyles()

  const [orderBy, setOrderBy] = React.useState('to')
  const [order, setOrder] = React.useState('desc')
  const [tableData, setTableData] = React.useState(data)

  const createSortHandler = (event, property) => {
    // const isAsc = orderBy === property && order === 'asc'
    setOrder(order === 'asc' ? 'desc' : 'asc')
    setOrderBy(property)
  }

  React.useEffect(() => {
    setTableData(handleSort(tableData, getComparator(order, orderBy)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order])

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const compareNames = (a, b, orderBy) => {
    if (
      b[orderBy].props.children.props.children < a[orderBy].props.children.props.children
    ) {
      return -1
    }
    if (
      b[orderBy].props.children.props.children > a[orderBy].props.children.props.children
    ) {
      return 1
    }
    return 0
  }

  const comparePositions = (a, b, orderBy) => {
    if (b[orderBy].props.children < a[orderBy].props.children) {
      return -1
    }
    if (b[orderBy].props.children > a[orderBy].props.children) {
      return 1
    }
    return 0
  }

  const compareSeniority = (a, b, orderBy) => {
    if (
      moment().diff(b.seniorityOrder, 'months') <
      moment().diff(a.seniorityOrder, 'months')
    ) {
      return -1
    }
    if (
      moment().diff(b.seniorityOrder, 'months') >
      moment().diff(a.seniorityOrder, 'months')
    ) {
      return 1
    }
    return 0
  }

  const compareDates = (a, b, item) => {
    if (a?.[item]) {
      if (
        moment(a[item].props.children, 'DD-MM-YYYY') <
        moment(b[item].props.children, 'DD-MM-YYYY')
      ) {
        return 1
      }
      if (
        moment(b[item].props.children, 'DD-MM-YYYY') <
        moment(a[item].props.children, 'DD-MM-YYYY')
      ) {
        return -1
      }
      if (a?.[item]?.props) return 1
      if (b?.[item]?.props) return -1
    }
    return 0
  }
  const compareOtherPositions = (a, b, item) => {
    if (a.otherPositionsOrder < b.otherPositionsOrder) return 1
    if (b.otherPositionsOrder < a.otherPositionsOrder) return -1
    return 0
  }
  const compareHighlights = (a, b, item) => {
    if (a.highlightsOrder < b.highlightsOrder) return 1
    if (b.highlightsOrder < a.highlightsOrder) return -1
    return 0
  }

  const getComparator = (order, orderBy) => {
    if (orderBy === 'to' || orderBy === 'from') {
      return order === 'desc'
        ? (a, b) => compareDates(a, b, orderBy)
        : (a, b) => -compareDates(a, b, orderBy)
    }
    if (orderBy === 'name') {
      return order === 'desc'
        ? (a, b) => compareNames(a, b, orderBy)
        : (a, b) => -compareNames(a, b, orderBy)
    }
    if (orderBy === 'position') {
      return order === 'desc'
        ? (a, b) => comparePositions(a, b, orderBy)
        : (a, b) => -comparePositions(a, b, orderBy)
    }
    if (orderBy === 'otherPosition') {
      return order === 'desc'
        ? (a, b) => compareOtherPositions(a, b, orderBy)
        : (a, b) => -compareOtherPositions(a, b, orderBy)
    }
    if (orderBy === 'highlights') {
      return order === 'desc'
        ? (a, b) => compareHighlights(a, b, orderBy)
        : (a, b) => -compareHighlights(a, b, orderBy)
    }
    if (orderBy === 'seniority') {
      return order === 'desc'
        ? (a, b) => compareSeniority(a, b, orderBy)
        : (a, b) => -compareSeniority(a, b, orderBy)
    } else {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
    }
  }

  function handleSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  return (
    <Sidescroll fixMaxContentForApple conditionalySidescroll maxWidth={'1000px'}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell style={{ borderBottom: 'none' }} key={header.text}>
                {header.text !== 'specialRights' ? (
                  <TableSortLabel
                    active={orderBy === header.text}
                    direction={orderBy === header.text ? order : 'asc'}
                    onClick={(e) => createSortHandler(e, header.text)}
                  >
                    <Box style={{ width: 'max-content' }}>
                      <Typography variant="subtitle2">
                        <FormattedMessage id={intl.creditCheck(header.text)} />
                      </Typography>
                    </Box>
                    {header.text === orderBy && (
                      <span className={classes.visuallyHidden}>
                        {header.order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    )}
                  </TableSortLabel>
                ) : (
                  <Typography variant="subtitle2">
                    <FormattedMessage id={intl.creditCheck(header.text)} />
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length &&
            tableData.map(
              (person, i) =>
                (isShowAll || i <= 10) && (
                  <TableRow
                    className={cx(classes.tableRow)}
                    style={
                      i % 2 !== 0
                        ? { backgroundColor: theme.palette.grey[50] }
                        : { backgroundColor: theme.palette.common.white }
                    }
                    key={i}
                  >
                    {tableHeaders.map((header, y) => (
                      <TableCell
                        style={{
                          padding:
                            header.text === 'name'
                              ? '6px 16px 6px 24px'
                              : '6px 16px 6px 16px',
                        }}
                        sortDirection={'desc'}
                        key={y}
                      >
                        <Typography variant="body2">
                          {header.text !== 'otherPosition' ? (
                            person[header.text]
                          ) : (
                            <Box className={classes.otherPositionCell}>
                              {person.otherPosition}
                            </Box>
                          )}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                )
            )}
          <ConditionallyRender condition={footer} when={footer} />
        </TableBody>
      </Table>
    </Sidescroll>
  )
}

export default RolesTable
