import { Box, Stack, Typography, Skeleton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
// @ts-ignore
import HubspotForm from 'react-hubspot-form'
import intl from 'localization/components'
import { FormattedMessage, useIntl } from 'react-intl'

const main = {
  borderRadius: '6px',
  display: 'flex',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  overflow: 'hidden',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  backgroundColor: 'white',
}

const sidePane = {
  backgroundColor: 'primary.900',
  position: 'relative',
  flex: '0 0 176px',
  img: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70px',
  },
}

const list = {
  marginBottom: 32,
  listStyle: 'none',
  paddingLeft: '16px',
}

const listItem = {
  backgroundImage: 'url("/risika/circleTick.svg")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 3px',
  paddingLeft: '24px',
}

const mainPane = {
  padding: '80px 32px 32px',
}

const closeBtn = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  '&:hover': {
    cursor: 'pointer',
  },
}

type Props = {
  closeFn: () => void
}

const PlanRestrictionModalCanvas = ({ closeFn }: Props) => {
  // const [scriptTag, setScriptTag] = useState<HTMLScriptElement>()
  // useEffect(() => {
  //   const head = document.querySelector('head')
  //   const script = document.createElement('script')

  //   script.setAttribute('src', '//js.hsforms.net/forms/embed/v2.js')
  //   setScriptTag(script)
  //   head?.appendChild(script)
  //   return () => {
  //     head?.removeChild(script)
  //   }
  // }, [])

  return (
    <Box sx={main}>
      <Box sx={sidePane}>
        <img src={'/risika/logoshield-blue.svg'} alt="" />
      </Box>
      <Box sx={mainPane}>
        <CloseIcon onClick={closeFn} sx={closeBtn} />
        <Typography variant="h5" marginBottom={3}>
          <FormattedMessage id={intl.creditPolicy('restriction-modal-title')} />
        </Typography>
        <ul style={list}>
          <li style={listItem}>
            <Typography variant="body2">
              <FormattedMessage id={intl.creditPolicy('restriction-modal-list-1')} />
            </Typography>
          </li>
          <li style={listItem}>
            <Typography variant="body2">
              <FormattedMessage id={intl.creditPolicy('restriction-modal-list-2')} />
            </Typography>
          </li>
          <li style={listItem}>
            <Typography variant="body2">
              <FormattedMessage id={intl.creditPolicy('restriction-modal-list-3')} />
            </Typography>
          </li>
        </ul>
        <Typography marginBottom={3} variant="body1">
          <FormattedMessage id={intl.creditPolicy('restriction-modal-paragraph')} />
        </Typography>

        <HubspotForm
          portalId="5137178"
          formId="5fc61564-9a74-4d58-b012-7bffe4f48724"
          onSubmit={() => console.log('Submit!')}
          onReady={() => console.log('Form ready!')}
          loading={
            <Stack spacing={5} marginTop={2}>
              <Stack spacing={2}>
                <Skeleton variant="rounded" width="20%" height={20} animation="wave" />
                <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
              </Stack>
              <Stack spacing={2}>
                <Skeleton variant="rounded" width="40%" height={20} animation="wave" />
                <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
              </Stack>
              <Skeleton variant="rounded" width="50%" height={40} animation="wave" />
            </Stack>
          }
        />
      </Box>
    </Box>
  )
}

export default PlanRestrictionModalCanvas
