import { Typography, Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { classes } from 'pages/risk-monitoring/styles/company-name-tooltip-bullets'

const CompanyNameTooltipBullets = ({ data }) => {
  return (
    <Box>
      <Typography variant="caption" sx={classes.text}>
        {
          <FormattedMessage
            id={intl.creditPolicy('company-does-not-comply-with-policy')}
          />
        }
      </Typography>
      {data?.map(({ text }) => (
        <Box key={text} sx={classes.bulletWrapper}>
          <Typography variant="caption" sx={classes.bullet}>
            • {text}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default CompanyNameTooltipBullets
