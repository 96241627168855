import * as React from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { navigation } from 'services/navigation'

import Showcase from './scenes/Showcase'
import Login from './scenes/Login'
import ChangePassword from './scenes/ChangePassword'
import ForgotPassword from './scenes/ForgotPassword'
import Register from './scenes/Register'
import VerifyAccount from './scenes/VerifyAccount'
import FrictionlessAccount from './scenes/FrictionlessAccount'
import Logout from './scenes/Logout'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { animations } from 'utils/animate'
import AccountDeactivated from './scenes/AccountDeactivated'
import SingleSignOn from './scenes/SingleSignOn'
import { useStyles } from './styles/auth-page'
import { Box } from '@mui/material'
import SupportBubbleLogin from './scenes/SupportBubbleLogin'

const PageContent = () => {
  const classes = useStyles()

  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()

  // @ts-ignore: Unreachable code error
  const isAuthenticated = useSelector((state) => state?.auth?.login?.authenticated)
  React.useEffect(() => {
    if (isAuthenticated && location.pathname !== navigation.auth.logout()) {
      const redirectIntended = sessionStorage.getItem('redirect:intended')
      sessionStorage.removeItem('redirect:intended')

      if (redirectIntended != null) {
        history.push(redirectIntended)
      } else {
        history.push(navigation.home())
      }
    }
  }, [isAuthenticated, location.pathname, history])

  const slideFromBottom = animations.slideIn('bottom', 30)

  return (
    <Box className={classes.root}>
      <motion.div
        {...slideFromBottom}
        style={{ position: 'relative' }}
        className={classes.leftContainer}
      >
        <Showcase />
      </motion.div>
      <Box className={classes.rightContainer}>
        <Box className={classes.overflowContainer}>
          <motion.div {...slideFromBottom} style={{ position: 'relative' }}>
            <Switch>
              <Route path={path} exact>
                <Redirect to={navigation.auth.login()} />
              </Route>
              <Route path={navigation.auth.forgotPassword(':token')}>
                <ChangePassword />
              </Route>
              <Route path={navigation.auth.forgotPassword()}>
                <ForgotPassword />
              </Route>
              <Route path={navigation.auth.login()}>
                <Login />
              </Route>
              <Route path={navigation.auth.register()}>
                <Register />
              </Route>
              <Route path={navigation.auth.verifyAccount(':token')}>
                <VerifyAccount />
              </Route>
              <Route path={navigation.auth.verifyAccount2(':token')}>
                <VerifyAccount />
              </Route>
              <Route path={navigation.auth.frictionlessRegister(':email')}>
                <FrictionlessAccount />
              </Route>
              <Route path={navigation.auth.accountDeactivated()}>
                <AccountDeactivated />
              </Route>
              <Route path={navigation.auth.logout()}>
                <Logout />
              </Route>
              <Route path={navigation.auth.sso()}>
                <SingleSignOn />
              </Route>
            </Switch>
          </motion.div>
        </Box>
      </Box>
      <SupportBubbleLogin />
    </Box>
  )
}

export default PageContent
