import moment from 'moment-timezone'

const OFFICE_OPEN = '09:00:00'
const OFFICE_CLOSE = '17:00:00'
const TIME_FORMAT = 'HH:mm:ss'

export const useLocalTimes = (timezone?: 'string') => {
  const startTime = moment(OFFICE_OPEN, TIME_FORMAT)
  const endTime = moment(OFFICE_CLOSE, TIME_FORMAT)
  // @ts-expect-error There is some issue with documentation i think
  const currentDateInSelectedTimezone = moment().tz(timezone || 'Europe/Copenhagen')._d
  const currentTimeInSelectedTimezone = moment(currentDateInSelectedTimezone, TIME_FORMAT)

  const isOnline =
    currentTimeInSelectedTimezone.isAfter(startTime) &&
    currentTimeInSelectedTimezone.isBefore(endTime)

  return { isOnline, currentTimeInSelectedTimezone }
}
