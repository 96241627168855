import { forwardRef, ForwardedRef } from 'react'
import Button from '@mui/material/Button'
import { buttonPrimaryStyles } from './Button.styles'
import { ButtonProps } from './Button.types'
import { SxProps } from '@mui/system'

const ButtonPrimary = forwardRef(
  (
    {
      children,
      size = 'large',
      disabled = false,
      loading = false,
      sx = {},
      ...extraProps
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Button
        ref={ref}
        sx={{ ...buttonPrimaryStyles, ...sx } as SxProps}
        variant="contained"
        color="primary"
        size={size}
        {...extraProps}
        disabled={disabled || loading}
        disableRipple={true}
      >
        {children}
      </Button>
    )
  }
)

export default ButtonPrimary
