import { formatData } from 'pages/risk-monitoring/controller'
import { Table } from 'components-new'
import { ColumnsDefinition } from '../Table/Table.types'
export const columnsDefinitionMonitoring = [
  {
    key: 'checkbox',
    sub_key: null,
    type: 'checkbox',
    width: 0,
  },
  {
    key: 'asdfasdfa',
    sub_key: null,
    width: 352,
    minWidth: '50px',
    type: 'loading',
  },
  // Company Name
  {
    key: '23423',
    sub_key: null,
    width: 98,
    justifyContent: 'loading',
    type: 'loading',
  },
  // Local ID
  {
    key: 'adfa',
    sub_key: 'id',
    width: 125,
    justifyContent: 'loading',
    type: 'loading',
  },
  // Policy outcome
  {
    key: 'adsf34',
    sub_key: null,
    width: 100,
    justifyContent: 'loading',
    type: 'loading',
  },
  // Policy Credit limit
  {
    key: 'rthet',
    sub_key: 'credit_policy_credit_max',
    width: 130,
    justifyContent: 'center',
    type: 'loading',
  },
  // Risika recommendation
  {
    key: 'sfg',
    sub_key: null,
    width: 170,
    minWidth: '200px',

    type: 'loading',
  },
  // Score
  {
    key: 'afgsf',
    sub_key: null,
    width: 60,
    minWidth: '100px',
    justifyContent: 'center',
    type: 'score',
  },
  // Country
  {
    key: 'retsr',
    sub_key: 'local_organization_id',
    width: 75,
    minWidth: '120px',
    justifyContent: 'center',
    type: 'loading',
  },
  // Status
  {
    key: '345243tts',
    sub_key: null,
    width: 105,
    minWidth: '200px',
    justifyContent: 'center',
    type: 'loading',
  },
  // Profit and loss
  {
    key: 'arat',
    sub_key: 'financial_key_figures',
    width: 125,
    minWidth: '200px',
    type: 'loading',
  },
  // Equity
  {
    key: 'jhgjf',
    sub_key: 'financial_key_figures',
    width: 125,
    minWidth: '200px',
    type: 'loading',
  },
  // Employees interval
  {
    key: 'adsf35',
    sub_key: null,
    width: 95,
    minWidth: '150px',

    type: 'loading',
  },
  // Currency
  {
    key: '43qfa',
    sub_key: 'financial_key_figures',
    width: 95,
    minWidth: '150px',
    type: 'loading',
  },
  // Company type
  {
    key: 'a4efrae',
    sub_key: null,
    width: 95,
    minWidth: '150px',
    type: 'loading',
  },
  // Solidity
  {
    key: 'art43a',
    sub_key: 'financial_key_figures',
    width: 95,
    minWidth: '150px',
    type: 'loading',
  },
  // Date of incorporation
  {
    key: '768urythdg',
    sub_key: null,
    width: 95,
    minWidth: '150px',
    type: 'loading',
  },
  // Internal ID
  {
    key: '232y6wtgf',
    sub_key: null,
    width: 95,
    minWidth: '150px',
    type: 'loading',
  },
  // Capital
  {
    key: 'sfhjeds',
    sub_key: null,
    width: 95,
    minWidth: '150px',
    type: 'loading',
  },
  // Liquidity
  {
    key: 'sfgsf',
    sub_key: 'financial_key_figures',
    width: 95,
    minWidth: '150px',
    type: 'loading',
  },
]
const tableData = {
  data: [
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'NO',
        id: '883409442',
      },
      company_name: 'REMA 1000 AS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 10,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'NOK',
      },
      credit_automator_result: 'reject',
      credit_max: 37375000,
      address: {
        city: 'Oslo',
        coname: null,
        number: '2',
        street: 'Gladengveien',
        country: 'NO',
        zipcode: '0661',
        coordinates: {
          lat: 59.914964,
          lng: 10.788008,
        },
        local_region: 'Oslo',
        municipality: 'Oslo',
        postdistrict: 'Oslo',
      },
      financial_key_figures: {
        currency: 'NOK',
        equity: 874000000,
        profit_loss: 1653000000,
        solidity: 0.10323647531301677,
        liquidity: 0.5659448818897638,
        short_term_debt: 3048000000,
        long_term_debt: 4544000000,
        ebit: 1842000000,
        ebitda: 1841000000,
        ordinary_profit: 1821000000,
        short_term_trade_payables: 0,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '77400',
      industries: {
        main_industry: {
          code: '77400',
          isic: {
            code: '7700',
            section: 'N',
            description: 'Rental and leasing activities',
          },
          nace: {
            code: '7740',
            section: 'N',
            description:
              'Leasing of intellectual property and similar products, except copyrighted works',
          },
          section: 'N',
          description:
            'Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker',
        },
        secondary_industries: null,
      },
      employees_number: 0,
      employee_interval: '0',
      company_type: 'AS',
      date_of_incorporation: '2001-04-25',
      email: 'info@rema1000.no',
      phone_number: '+4724098500',
      webpage: 'www.rema.no',
      capital: 1000000,
      changes: [
        {
          new: {
            value: {
              amount: 283,
              creditor: 'DHL EXPRESS (NORWAY) AS',
              total_unsettled: 1,
              unique_creditors: 1,
              total_unsettled_amount: 283,
            },
          },
          date: '2023-03-29',
          type: 'payment_remark',
          weight: 10000,
          registration_date: '2023-03-29',
          sentence: 'Change in payment remarks',
        },
        {
          new: {
            value: {
              name: 'Terje Størdal',
              function: 'Board of Directors',
              entity_id: '5000185982',
            },
            valid_to: null,
            valid_from: '2023-06-01',
          },
          type: 'new_member',
          weight: 6000,
          registration_date: '2023-06-01',
          sentence: 'New Board Member',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-01',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-01',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00005,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-07-01',
          },
          old: {
            value: {
              score: 10,
              risk_assessment: 'Low',
              probability_of_distress: 0.00014,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-06-30',
            valid_from: '2023-04-25',
          },
          date: '2023-07-01',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-01',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          change_in_revenue: 'INCREASE IN REVENUE',
        },
        {
          three_years_profit_loss: 'TRIPLE POSITIVE',
        },
      ],
      equity_trend: 'NEUTRAL',
      profit_loss_trend: 'DOWN',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'SE',
        id: '5590189543',
        hash: 'f3de4c9be8a0be9d915476c527650fb9',
      },
      company_name: 'Maha Energy AB',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 8,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 0,
        policy_currency: 'SEK',
      },
      credit_automator_result: 'reject',
      credit_max: 39075000,
      address: {
        city: 'Stockholm',
        coname: null,
        number: '10',
        street: 'Eriksbergsgatan',
        country: 'SE',
        zipcode: '114 30',
        coordinates: {
          lat: 59.34025618,
          lng: 18.06884868,
        },
        local_region: 'Stockholms Län',
        municipality: 'Stockholms',
        post_address: null,
        postdistrict: null,
      },
      financial_key_figures: {
        currency: 'SEK',
        equity: 223819000,
        profit_loss: -126461000,
        solidity: 0.3023490173140232,
        liquidity: 0.8524989370337444,
        short_term_debt: 103484000,
        long_term_debt: 412964000,
        ebit: 22704000,
        ebitda: 22704000,
        ordinary_profit: -126461000,
        short_term_trade_payables: 1406000,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '70100',
      industries: {
        main_industry: {
          code: '70100',
          isic: {
            code: '7010',
            section: 'M',
            description: 'Activities of head offices',
          },
          nace: {
            code: '7010',
            section: 'M',
            description: 'Activities of head offices',
          },
          section: 'M',
          description: 'Verksamheter som utövas av huvudkontor',
        },
        secondary_industries: null,
      },
      employees_number: null,
      employee_interval: '1-4',
      company_type: 'AB',
      date_of_incorporation: '2015-07-01',
      email: null,
      phone_number: null,
      webpage: 'www.mahaenergy.com',
      capital: 1316000,
      changes: [
        {
          new: {
            valid_to: '2016-12-31',
            valid_from: '2015-07-01',
          },
          date: '2022-08-26T21:35:18.605945',
          type: 'new_report',
          weight: 8000,
          registration_date: '2022-08-26',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: '1117000 SEK',
            valid_to: '2022-10-26',
            valid_from: '2022-08-26',
          },
          old: {
            value: '1113000 SEK',
            valid_to: '2022-08-25',
            valid_from: '2021-09-16',
          },
          type: 'capital',
          weight: 2000,
          registration_date: '2022-08-26',
          sentence: 'Change in Capital',
        },
        {
          new: {
            valid_to: '2020-12-31',
            valid_from: '2020-01-01',
          },
          date: '2022-10-27T00:31:31.908777',
          type: 'new_report',
          weight: 8000,
          registration_date: '2022-10-27',
          sentence: 'New Annual Report',
        },
        {
          new: {
            valid_to: '2021-12-31',
            valid_from: '2021-01-01',
          },
          date: '2022-10-27T00:31:31.908777',
          type: 'new_report',
          weight: 8000,
          registration_date: '2022-10-27',
          sentence: 'New Annual Report',
        },
        {
          new: {
            valid_to: '2019-12-31',
            valid_from: '2019-01-01',
          },
          date: '2022-10-27T00:31:31.908777',
          type: 'new_report',
          weight: 8000,
          registration_date: '2022-10-27',
          sentence: 'New Annual Report',
        },
        {
          new: {
            valid_to: '2017-12-31',
            valid_from: '2017-01-01',
          },
          date: '2022-10-27T00:31:31.908777',
          type: 'new_report',
          weight: 8000,
          registration_date: '2022-10-27',
          sentence: 'New Annual Report',
        },
        {
          new: {
            valid_to: '2018-12-31',
            valid_from: '2018-01-01',
          },
          date: '2022-10-27T00:31:31.908777',
          type: 'new_report',
          weight: 8000,
          registration_date: '2022-10-27',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              name: 'Karl Jonas Lindvall',
              function: 'Chief Executive Officer',
              entity_id: '1279280d57a575a3541a1685f50c16664034f1',
            },
            valid_to: '2022-12-13',
            valid_from: '2020-07-05',
          },
          type: 'management_stepdown',
          weight: 6450,
          registration_date: '2022-10-27',
          sentence: 'Management Stepdown',
        },
        {
          new: {
            value: '1316000 SEK',
            valid_to: null,
            valid_from: '2022-10-27',
          },
          old: {
            value: '1117000 SEK',
            valid_to: '2022-10-26',
            valid_from: '2022-08-26',
          },
          type: 'capital',
          weight: 2000,
          registration_date: '2022-10-27',
          sentence: 'Change in Capital',
        },
        {
          new: {
            value: {
              name: 'Sven Harald Knut Jansson Pousette',
              function: 'Chairman',
              entity_id: '650720de5bf619ded19f2052ec01c5530b0d67',
            },
            valid_to: '2022-12-13',
            valid_from: '2022-07-03',
          },
          old: {
            value: {
              name: 'Per Anders Bertil Ehrenblad',
              function: 'Chairman',
              entity_id: '6506016dcf92b53353b4f430cdb3343b19e5e0',
            },
            valid_to: '2022-07-02',
            valid_from: '2020-07-05',
          },
          type: 'board_member_replacement',
          weight: 6100,
          registration_date: '2022-10-27',
          sentence: 'Change in Board of Directors',
        },
        {
          new: {
            value: 7,
            valid_to: '2023-04-12',
            valid_from: '2022-10-27',
          },
          old: {
            value: null,
            valid_to: null,
            valid_from: null,
          },
          type: 'board_change',
          weight: 6010,
          registration_date: '2022-10-27',
          sentence: 'There has been an change in the size of the board',
        },
        {
          new: {
            value: 6,
            valid_to: '2023-04-13',
            valid_from: '2023-04-13',
          },
          old: {
            value: 6,
            valid_to: '2023-04-13',
            valid_from: '2023-04-13',
          },
          type: 'board_change',
          weight: 6010,
          registration_date: '2023-04-13',
          sentence: 'There has been an change in the size of the board',
        },
        {
          new: {
            value: {
              name: 'Paulo Thiago Arantes de Mendonca',
              function: 'Chief Executive Officer',
              entity_id: '88042038459cd226492daa81ecba3e84b5403d',
            },
            valid_to: null,
            valid_from: '2022-12-14',
          },
          old: {
            value: {
              name: 'Karl Jonas Lindvall',
              function: 'Chief Executive Officer',
              entity_id: '1279280d57a575a3541a1685f50c16664034f1',
            },
            valid_to: '2022-12-13',
            valid_from: '2020-07-05',
          },
          type: 'management_replacement',
          weight: 6500,
          registration_date: '2023-04-14',
          sentence: 'Change in Management',
        },
        {
          new: {
            value: {
              name: 'Fabio Vassel',
              function: 'Chairman',
              entity_id: '761111a5736f8927a9b1e911222277ac17a456',
            },
            valid_to: null,
            valid_from: '2022-12-14',
          },
          old: {
            value: {
              name: 'Sven Harald Knut Jansson Pousette',
              function: 'Chairman',
              entity_id: '650720de5bf619ded19f2052ec01c5530b0d67',
            },
            valid_to: '2022-12-13',
            valid_from: '2022-07-03',
          },
          type: 'board_member_replacement',
          weight: 6100,
          registration_date: '2023-04-14',
          sentence: 'Change in Board of Directors',
        },
        {
          new: {
            value: 7,
            valid_to: null,
            valid_from: '2023-04-14',
          },
          old: {
            value: null,
            valid_to: null,
            valid_from: null,
          },
          type: 'board_change',
          weight: 6010,
          registration_date: '2023-04-14',
          sentence: 'There has been an change in the size of the board',
        },
        {
          new: {
            value: 'Eriksbergsgatan 10, 114 30 Stockholm',
            valid_to: null,
            valid_from: '2023-05-30',
          },
          old: {
            value: 'Strandvägen 5A, 114 51 Stockholm',
            valid_to: '2023-05-29',
            valid_from: '2022-10-27',
          },
          type: 'address',
          weight: 1500,
          registration_date: '2023-05-30',
          sentence: 'New Address',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          address_changes: 'ADDRESS CHANGES',
        },
        {
          management_changes: 'MANY MANAGEMENT CHANGES',
        },
        {
          powers_to_bind_changes: 'POWERS TO BIND CHANGES',
        },
        {
          three_years_profit_loss: 'TRIPLE NEGATIVE',
        },
        {
          profit_loss: 'INCREASE',
        },
        {
          vat: 'IS_NOT_BUT_SHOULD',
        },
        {
          return_on_assets: 'WEAK',
        },
      ],
      equity_trend: 'UP',
      profit_loss_trend: 'UP',
      employment_trend: 'NEUTRAL',
    },
    {
      local_organization_id: {
        country: 'DK',
        id: '39276208',
      },
      company_name: 'TNT Holding DK ApS',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 8,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 5000,
        policy_currency: 'DKK',
      },
      credit_automator_result: 'approved',
      credit_max: 5000,
      address: {
        city: 'København Ø',
        coname: null,
        number: '55',
        street: 'Sortedam Dossering',
        country: 'DK',
        zipcode: 2100,
        coordinates: {
          lat: 55.69295056,
          lng: 12.5700916,
        },
        local_region: 'Region Hovedstaden',
        municipality: 'København',
        postdistrict: 'København Ø',
      },
      financial_key_figures: {
        currency: 'DKK',
        equity: 181296,
        profit_loss: -54690,
        solidity: 0.1057975784483302,
        liquidity: 0.00009658582172345652,
        short_term_debt: 1532316,
        long_term_debt: 0,
        ebit: 35302,
        ebitda: 35302,
        ordinary_profit: -54690,
        short_term_trade_payables: 6250,
        short_term_receivables_from_sales_and_services: 0,
      },
      industry_code: '642020',
      industries: {
        main_industry: {
          code: '642020',
          isic: {
            code: '6420',
            section: 'K',
            description: 'Activities of holding companies',
          },
          nace: {
            code: '6420',
            section: 'K',
            description: 'Activities of holding companies',
          },
          section: 'K',
          description: 'Ikke-finansielle holdingselskaber',
        },
        secondary_industries: null,
      },
      employees_number: null,
      employee_interval: null,
      company_type: 'APS',
      date_of_incorporation: '2018-01-23',
      email: null,
      phone_number: null,
      webpage: null,
      capital: 100000,
      changes: [
        {
          new: {
            value: {
              name: 'Timm Jeppesen',
              function: 'Beneficial Owner',
              entity_id: '4006873173',
            },
            valid_to: null,
            valid_from: '2018-01-23',
          },
          type: 'new_owner',
          weight: 6200,
          registration_date: '2023-03-15',
          sentence: 'New Owner',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-06-12',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-06-12',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 6,
              risk_assessment: 'Medium',
              probability_of_distress: 0.00617,
              risk_assessment_code: 'MEDIUM',
            },
            valid_to: null,
            valid_from: '2023-06-12',
          },
          old: {
            value: {
              score: 6,
              risk_assessment: 'Medium',
              probability_of_distress: 0.00777,
              risk_assessment_code: 'MEDIUM',
            },
            valid_to: '2023-06-11',
            valid_from: '2023-01-23',
          },
          date: '2023-06-12',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-06-12',
          sentence: 'Change in credit score (NEWTON)',
        },
        {
          new: {
            value: {
              score: 6,
              risk_assessment: 'Medium',
              probability_of_distress: 0.00716,
              risk_assessment_code: 'MEDIUM',
            },
            valid_to: null,
            valid_from: '2023-06-30',
          },
          old: {
            value: {
              score: 6,
              risk_assessment: 'Medium',
              probability_of_distress: 0.00617,
              risk_assessment_code: 'MEDIUM',
            },
            valid_to: '2023-06-29',
            valid_from: '2023-06-12',
          },
          date: '2023-06-30',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-06-30',
          sentence: 'Change in credit score (NEWTON)',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          industry: 'HOLDING',
        },
        {
          type_of_auditor_assistance: "AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)",
        },
        {
          address_changes: 'ADDRESS CHANGES',
        },
        {
          industry_risk: 'LOWER RISK',
        },
        {
          gas_and_electricity_exposure: 'LIQUIDITY',
        },
        {
          current_ratio: 'WEAK',
        },
      ],
      equity_trend: 'DOWN',
      profit_loss_trend: 'DOWN',
      employment_trend: 'UNKNOWN',
    },
    {
      local_organization_id: {
        country: 'DK',
        id: '37677892',
      },
      company_name: 'RISIKA A/S',
      status: 'Active',
      status_code: 'ACTIVE',
      score: 7,
      risk_assessment: 'Low',
      risk_assessment_code: 'LOW',
      credit_policy_credit_max: {
        value: 5000,
        policy_currency: 'DKK',
      },
      credit_automator_result: 'approved',
      credit_max: 4070000,
      address: {
        city: 'København Ø',
        coname: null,
        number: '55',
        street: 'Sortedam Dossering',
        country: 'DK',
        zipcode: 2100,
        coordinates: {
          lat: 55.69295056,
          lng: 12.5700916,
        },
        local_region: 'Region Hovedstaden',
        municipality: 'København',
        postdistrict: 'København Ø',
      },
      financial_key_figures: {
        currency: 'DKK',
        equity: 23231251,
        profit_loss: -17789319,
        solidity: 0.630014498231322,
        liquidity: 2.3377053002904473,
        short_term_debt: 6347105,
        long_term_debt: 7295797,
        ebit: -17388039,
        ebitda: -20152590,
        ordinary_profit: -20705829,
        short_term_trade_payables: 834701,
        short_term_receivables_from_sales_and_services: 729649,
      },
      industry_code: '829100',
      industries: {
        main_industry: {
          code: '829100',
          isic: {
            code: '8291',
            section: 'N',
            description: 'Activities of collection agencies and credit bureaus',
          },
          nace: {
            code: '8291',
            section: 'N',
            description: 'Activities of collection agencies and credit bureaus',
          },
          section: 'N',
          description: 'Inkassovirksomhed og kreditoplysning',
        },
        secondary_industries: [
          {
            isic: {
              code: '6202',
              section: 'J',
              description:
                'Computer consultancy and computer facilities management activities',
            },
            nace: {
              code: '6202',
              section: 'J',
              description: 'Computer consultancy activities',
            },
            section: 'J',
            priority: 2,
            group_name:
              'Computerprogrammering, konsulentbistand vedrørende informationsteknologi og lignende aktiviteter',
            industry_code: '620200',
            industry_description: 'Konsulentbistand vedrørende informationsteknologi',
          },
          {
            isic: {
              code: '6311',
              section: 'J',
              description: 'Data processing, hosting and related activities',
            },
            nace: {
              code: '6311',
              section: 'J',
              description: 'Data processing, hosting and related activities',
            },
            section: 'J',
            priority: 1,
            group_name:
              'Databehandling, webhosting og lignende serviceydelser; webportaler',
            industry_code: '631100',
            industry_description: 'Databehandling, webhosting og lignende serviceydelser',
          },
        ],
      },
      employees_number: 39,
      employee_interval: '20-49',
      company_type: 'A/S',
      date_of_incorporation: '2016-04-30',
      email: null,
      phone_number: '+4542905757',
      webpage: null,
      capital: 886891,
      changes: [
        {
          new: {
            value: 44,
            valid_to: '2022-08-31',
            valid_from: '2022-08-01',
          },
          old: {
            value: 38,
            valid_to: '2022-07-31',
            valid_from: '2022-07-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2022-10-23',
          sentence: 'Change in number of employees',
        },
        {
          new: {
            value: 43,
            valid_to: '2022-09-30',
            valid_from: '2022-09-01',
          },
          old: {
            value: 44,
            valid_to: '2022-08-31',
            valid_from: '2022-08-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2022-11-22',
          sentence: 'Change in number of employees',
        },
        {
          new: {
            value: 44,
            valid_to: '2022-11-30',
            valid_from: '2022-11-01',
          },
          old: {
            value: 43,
            valid_to: '2022-10-31',
            valid_from: '2022-10-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2023-01-23',
          sentence: 'Change in number of employees',
        },
        {
          new: {
            value: 38,
            valid_to: '2022-12-31',
            valid_from: '2022-12-01',
          },
          old: {
            value: 44,
            valid_to: '2022-11-30',
            valid_from: '2022-11-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2023-02-25',
          sentence: 'Change in number of employees',
        },
        {
          new: {
            value: 41,
            valid_to: '2023-01-31',
            valid_from: '2023-01-01',
          },
          old: {
            value: 38,
            valid_to: '2022-12-31',
            valid_from: '2022-12-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2023-03-25',
          sentence: 'Change in number of employees',
        },
        {
          new: {
            value: {
              score: 8,
              risk_assessment: 'Low',
              probability_of_distress: 0.00121,
              risk_assessment_code: 'LOW',
            },
            valid_to: null,
            valid_from: '2023-04-30',
          },
          old: {
            value: {
              score: 9,
              risk_assessment: 'Low',
              probability_of_distress: 0.0003,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-04-29',
            valid_from: '2022-09-05',
          },
          date: '2023-04-30',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-04-30',
          sentence: 'Change in credit score (NEWTON)',
        },
        {
          new: {
            value: 42,
            valid_to: '2023-02-28',
            valid_from: '2023-02-01',
          },
          old: {
            value: 41,
            valid_to: '2023-01-31',
            valid_from: '2023-01-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2023-05-06',
          sentence: 'Change in number of employees',
        },
        {
          new: {
            value: 41,
            valid_to: '2023-04-30',
            valid_from: '2023-04-01',
          },
          old: {
            value: 42,
            valid_to: '2023-03-31',
            valid_from: '2023-03-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2023-06-25',
          sentence: 'Change in number of employees',
        },
        {
          new: {
            valid_to: '2022-12-31',
            valid_from: '2022-01-01',
          },
          date: '2023-07-03',
          type: 'new_report',
          weight: 8000,
          registration_date: '2023-07-03',
          sentence: 'New Annual Report',
        },
        {
          new: {
            value: {
              score: 4,
              risk_assessment: 'Medium',
              probability_of_distress: 0.02701,
              risk_assessment_code: 'MEDIUM',
            },
            valid_to: null,
            valid_from: '2023-07-03',
          },
          old: {
            value: {
              score: 8,
              risk_assessment: 'Low',
              probability_of_distress: 0.00121,
              risk_assessment_code: 'LOW',
            },
            valid_to: '2023-07-02',
            valid_from: '2023-04-30',
          },
          date: '2023-07-03',
          type: 'newton',
          weight: 9200,
          registration_date: '2023-07-03',
          sentence: 'Change in credit score (NEWTON)',
        },
        {
          new: {
            value: 39,
            valid_to: '2023-05-31',
            valid_from: '2023-05-01',
          },
          old: {
            value: 41,
            valid_to: '2023-04-30',
            valid_from: '2023-04-01',
          },
          type: 'employees_interval',
          weight: 3000,
          registration_date: '2023-07-26',
          sentence: 'Change in number of employees',
        },
      ],
      credit_policy: null,
      internal_id: null,
      highlights: [
        {
          type_of_auditor_assistance: "AUDITOR'S REPORT",
        },
        {
          address_changes: 'ADDRESS CHANGES',
        },
        {
          intangible_assets: 'MANY INTANGIBLE ASSETS',
        },
        {
          three_years_profit_loss: 'TRIPLE NEGATIVE',
        },
        {
          industry_risk: 'INCREASED RISK',
        },
        {
          data: [
            {
              local_id: ['25921542'],
              personal_id: '4000456851',
            },
            {
              local_id: ['32365590', '33370490', '37324051', '33251017'],
              personal_id: '4004012838',
            },
          ],
          connected_bankruptcies: '>=4',
        },
        {
          politically_exposed_person: 'HAS_PEP',
        },
        {
          return_on_assets: 'WEAK',
        },
      ],
      equity_trend: 'DOWN',
      profit_loss_trend: 'DOWN',
      employment_trend: 'UNKNOWN',
    },
  ],
  page_count: 1,
  count: 4,
  credit_policy_used: 'credit_automator',
}
function TableLoading() {
  return (
    <Table
      // @ts-ignore // Another loader
      tableData={tableData}
      formatData={formatData}
      columnsDefinition={columnsDefinitionMonitoring as ColumnsDefinition}
      page={1}
      perPageLimit={50}
      // @ts-ignore // Another loader
      setSortBy={'company_name'}
      setPage={() => {}}
      getSelected={() => {}}
      expandable={false}
      isTableLoading={true}
    />
  )
}

export default TableLoading
