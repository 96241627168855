import { Skeleton } from '@mui/material'
type ComponentStateHandlerProps = {
  isLoading?: boolean
  isError?: boolean
  loading?: React.ReactNode
  error?: React.ReactNode
  isEmptyState?: boolean
  emptyState?: React.ReactNode
  children: React.ReactNode
}

function ComponentStateHandler({
  isLoading,
  isError,
  loading,
  error,
  isEmptyState,
  emptyState,
  children,
}: ComponentStateHandlerProps) {
  if (isLoading) {
    return (
      loading ?? <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
    )
  }
  if (isError) {
    return error ?? 'Error...'
  }
  if (isEmptyState) {
    return emptyState ?? null
  }
  return children
}

export default ComponentStateHandler
