// styledComponents.js

import { styled } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { Card, Typography, CardContent, Button, FormControlLabel } from '@mui/material'
import { Link } from 'react-router-dom'

export const Root = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  width: '100%',
  maxWidth: 400,
  boxShadow: theme.shadows[1],
}))

export const CardStyled = styled(Card)({})

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
})) as typeof Typography

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
})

export const FormElement = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(4),
}))

export const FormElementRow = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(4),
}))

export const ForgotPassword = styled('a')({
  alignSelf: 'flex-end',
  textDecoration: 'none',
  color: '#3A7DDA',
  '&:hover, &:focus': {
    textDecoration: 'underline',
  },
})

export const RegisterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 500,
  margin: 0,
  marginTop: theme.spacing(6),
  textTransform: 'none',
  padding: theme.spacing(2, 4),
})) as typeof Button

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
}))

export const ActionCard = styled(Card)(({ theme }) => ({
  marginTop: `-${theme.spacing(4)}`,
  paddingTop: theme.spacing(4),
  backgroundColor: '#ddd',
})) as typeof Card

export const ActionCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}) as typeof CardContent

export const ActionTitle = styled(Typography)({
  fontWeight: 500,
}) as typeof Typography

export const ActionSubtitle = styled(Typography)({
  fontSize: '1.6rem',
  fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  fontWeight: 400,
  lineHeight: 1.5,
  letterSpacing: '0.00938em',
}) as typeof Typography

export const HeadlessLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
}) as typeof Link

export const StrongLink = styled('a')({
  fontWeight: 700,
})

export const SmallText = styled('p')({
  fontSize: '1.3rem',
  fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  letterSpacing: '0.00938em',
  lineHeight: 1.5,
})
