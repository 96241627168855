import { useQuery } from 'react-query'
import { getAxiosData } from './useAccountStatus'
import { getPolicyViolations } from 'services/api/endpoints/risikaAPI/filters/policyViolations'

const POLICY_VIOLATIONS = 'policyViolations'

export function usePolicyViolations() {
  return useQuery([POLICY_VIOLATIONS], () => getAxiosData(getPolicyViolations()), {
    enabled: true,
    refetchOnWindowFocus: false,
  })
}
