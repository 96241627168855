import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import StackedBarChart from 'components-new/charts/StackedBarChart'
import SelectIndustry from './elements/SelectIndustry'
import BreadCrumbs from './elements/BreadCrumbs'
import { convertToNameAndValues } from 'utils-new/api-to-chart-data-converters'
import { adjustParameters } from './ListCreditScoreByIndustryBox.controller'
import { scoreByIndustryData } from './ListCreditScoreByIndustryBox.model'
import { useSelector } from 'react-redux'
import { useMonitoringStatistic } from 'services/queries'
import { LanguageStateType } from './ListCreditScoreByIndustryBox.types'
import './ListCreditScoreByIndustryBox.css'
import { MonitoringValuesQueryDataType } from 'types/queries'

const ListCreditScoreByIndustryBox = ({ listId }: { listId: string }) => {
  const [levelData, setLevelData] = useState([{ group: '', group_code: '' }])
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: scoreByIndustryData.rootLevelLabel, level: 1, groupCode: '' },
  ])
  scoreByIndustryData.parameters.list_id = Number(listId)
  scoreByIndustryData.chartProperties.tooltipWithPercents = true
  const userLanguage = useSelector(
    (state: LanguageStateType) => state.auth?.user?.local?.lang
  )

  const handleIndustryChange = (groupName: string) => {
    if (breadcrumbs.length === scoreByIndustryData.levelLimit || levelData.length === 0) {
      return
    }
    const groupCode = levelData.find((x) => x.group === groupName)!.group_code
    setBreadcrumbs((state) => {
      return [...state, { label: groupName, level: state.length + 1, groupCode }]
    })
  }

  const handleBreadcrumb = (crumbLevel: number) => {
    setBreadcrumbs((state) => {
      return state.slice(0, crumbLevel)
    })
  }

  const scoreByIndustryQuery = useMonitoringStatistic({
    parameters: adjustParameters(scoreByIndustryData.parameters, breadcrumbs),
    endpoint: scoreByIndustryData.endPoint,
    cacheTime: 0,
  })
  const scoreByIndustryQueryData =
    scoreByIndustryQuery.data as MonitoringValuesQueryDataType[]

  useEffect(() => {
    if (scoreByIndustryQuery.isSuccess) {
      setLevelData(scoreByIndustryQueryData)
    }
  }, [scoreByIndustryQuery, scoreByIndustryQueryData])

  const boxData = {
    title: scoreByIndustryData.chartProperties.title,
    headerElementRight: (
      <SelectIndustry
        industries={levelData}
        handleIndustryChange={handleIndustryChange}
        level={breadcrumbs.length}
        levelLimit={scoreByIndustryData.levelLimit}
      />
    ),
    status: {
      isLoading: scoreByIndustryQuery.isLoading,
      isError:
        scoreByIndustryQuery.isError ||
        (scoreByIndustryQueryData &&
          !scoreByIndustryQueryData?.length &&
          !scoreByIndustryQuery.isLoading),
      isIdle: scoreByIndustryQuery.isIdle,
      isNoData: !scoreByIndustryQueryData?.length,
    },
    height: scoreByIndustryData.chartProperties.height,
    skeletonType: 'HORIZONTAL_BAR',
  }

  return (
    <BoxContainer boxData={boxData}>
      <Box
        data-cy="list-credit-score-by-industry-chart"
        m={2}
        pt={1}
        className={
          breadcrumbs.length < scoreByIndustryData.levelLimit
            ? 'ListCreditScoreByIndustryBoxPointer'
            : 'ListCreditScoreByIndustryBox'
        }
      >
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <>
            <BreadCrumbs breadcrumbs={breadcrumbs} handleBreadcrumb={handleBreadcrumb} />
            <StackedBarChart
              data={convertToNameAndValues(scoreByIndustryQueryData, userLanguage)}
              chartProperties={scoreByIndustryData.chartProperties}
              handleBarClick={(event: { name: string }) =>
                handleIndustryChange(event.name)
              }
            />
          </>
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListCreditScoreByIndustryBox
