import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Post to API *
 ******************/

const monitorUnsubscribe = (list_id, ...companies) => {
  const data = {
    list_id: Number(list_id),
    unsubscribe: companies.map(({ id, hash, country }) => ({
      local_organization_id: { id: hash ?? id.toString(), country },
    })),
  }
  return risikaAPI
    .post(`/remove`, data, {
      microservice: 'monitor',
    })
    .then(({ data }) => {
      return data
    })
}

export default monitorUnsubscribe
