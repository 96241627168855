import colours from './colors'

const height = 400
const mapHeight = 600
const colors = [
  colours.riskIntelligence.highRisk,
  colours.riskIntelligence.mediumRisk,
  colours.riskIntelligence.lowRisk,
  colours.riskIntelligence.noScore,
]
const margin = { top: 20, right: 20, bottom: 5, left: -20 }
const strokeDasharray = '3 3'
const barCategoryGap = '30%'

export const defaultDoughnutChartProperties = {
  title: '',
  height,
  colors: {
    HIGH: colours.riskIntelligence.highRisk,
    MEDIUM: colours.riskIntelligence.mediumRisk,
    LOW: colours.riskIntelligence.lowRisk,
    NO_RISK_ASSESMENT: colours.riskIntelligence.noScore,
  },
  pie: {
    dataKey: 'value',
    innerRadius: 85,
    outerRadius: 125,
    paddingAngle: 0,
    legend: true,
    tooltip: true,
    tooltipWithPercent: false,
    label: false,
  },
}

export const defaultStackedBarChartProperties = {
  title: '',
  colors,
  height,
  margin,
  barCategoryGap,
  strokeDasharray,
  cartesianGrid: true,
  xAxis: true,
  yAxis: true,
  tooltip: true,
  tooltipWithPercents: false,
  legend: true,
  dataKey: 'name',
  bar: {
    stackId: 'a',
    radius: null,
    label: true,
    labelEmployees: false,
    maxBarSize: 100,
  },
  valueType: 'RAW',
}

export const defaultStackedAreaChartProperties = {
  title: '',
  colors,
  width: 1920,
  height,
  margin,
  strokeDasharray,
  cartesianGrid: true,
  xAxis: true,
  yAxis: true,
  tooltip: true,
  tooltipWithPercents: false,
  legend: true,
  dataKey: 'name',
  area: { type: 'monotone', stackId: '1' },
}

export const defaultSimpleBarChartProperties = {
  title: '',
  colors,
  height,
  margin,
  strokeDasharray,
  cartesianGrid: true,
  dataKey: 'name',
  xAxis: true,
  yAxis: true,
  tooltip: true,
  legend: true,
  bar: { label: { position: 'top' } }, // label: null if you do not want the label on top of the bar chart
}

export const defaultVerticalBarChartProperties = {
  title: '',
  colors: [
    colours.riskIntelligence.highRisk,
    colours.riskIntelligence.mediumRisk,
    colours.riskIntelligence.lowRisk,
    colours.riskIntelligence.highRisk,
    colours.riskIntelligence.mediumRisk,
    colours.riskIntelligence.lowRisk,
    colours.riskIntelligence.highRisk,
    colours.riskIntelligence.mediumRisk,
    colours.riskIntelligence.lowRisk,
    colours.riskIntelligence.noScore,
  ],
  height,
  tooltip: true,
  tooltipWithPercent: false,
  tooltipProgressBar: false,
  margin: { top: 0, right: 20, left: 20, bottom: 5 },
  xAxis: {
    hide: true,
    dataKey: '',
    type: 'number',
    width: 0,
    axisLine: false,
    tickLine: false,
  },
  yAxis: {
    hide: false,
    dataKey: 'name',
    type: 'category',
    width: 10,
    axisLine: false,
    tickLine: false,
  },
  bar: { label: { position: 'right' }, barSize: 10, radius: [5, 5, 5, 5] }, // label: null if you do not want the label on the right of the bar chart
}
export const defaultSimpleLineChartProperties = {
  name: '',
  title: '',
  colors: {
    strokes: [
      '#3b7bf4',
      '#458b00',
      '#ed7f2f',
      '#04466e',
      '#aadbee',
      '#ff3030',
      '#dbadda',
      '#cb2b46',
      '#4f039a',
      '#c7ff00',
      '#EE6055',
      '#F9A96E',
      '#19C8C5',
    ],
  },
  height,
  chartType: 'ratio',
  lineType: 'monotone',
  cartesianGrid: false,
  xAxis: true,
  yAxis: true,
  tooltip: true,
  legend: true,
  domain: ['auto', 'auto'],
  strokeWidth: 3,
  margin,
}

export const defaultMapChartProperties = {
  title: '',
  colors,
  width: 1920,
  height: mapHeight,
  // mapStyle: 'https://api.maptiler.com/maps/pastel/style.json?key=RDw80j5JWjtmrm49e6X4',
  mapStyle: 'mapbox://styles/mapbox/light-v9',
  mapboxApiAccessToken:
    'pk.eyJ1IjoicmlzaWthIiwiYSI6ImNsNG41ZW11NDE1ZjkzZW8xdjM1ZmRiMWgifQ.fHbOas3F36V3P4Sr5hHBwg',
  initialLatitude: 55.568673,
  initialLongitude: 11.935435,
  initialZoom: 4,
  pie: { width: 110, height: 110, innerRadius: 25 },
}
