import React, { useState } from 'react'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableFooter,
  Stack,
} from '@mui/material'
import classes from 'pages/credit-check/styles/nor-pr-modal'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import IntlTypography from 'components-new/elements/IntlTypography'
import RemarkCard from './RemarkCard'
import { NORWEGIAN_PAYMENT_REMARK_COLLECTOR } from 'configs/constants/system'
import { Remark } from 'types/general'
import CloseIcon from '@mui/icons-material/Close'
import { Sidescroll } from 'components'
import { ModalViewT } from './types'
import { formatToUniversalDate } from 'utils/dateUtils'

// Guess that this will be changed due to No
const changeOfSubjectType = 'Change of subject'

const setUnknownAmountInitially = (remarks: Remark[]): boolean => {
  let flag = false
  remarks.forEach((remark) => {
    if (remark.amount === null) return (flag = true)
  })
  return flag
}

const setTypeChangeOfSubjectInitially = (remarks: Remark[]): boolean => {
  let flag = false
  remarks.forEach((remark) => {
    if (remark.type === changeOfSubjectType) return (flag = true)
  })
  return flag
}

const ModalView = React.forwardRef(({ data, closeFn }: ModalViewT, ref) => {
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const [isUnknownAmount, setIsUnknownAmount] = useState<boolean>(
    setUnknownAmountInitially(data.remarks)
  )
  const [isTypeChangeOfSubject, setIsTypeChangeOfSubject] = useState<boolean>(
    setTypeChangeOfSubjectInitially(data.remarks)
  )
  const providers = new Set(data.remarks.map((remark) => remark.provider))
  return (
    <Box className="MUI5" sx={classes.main} ref={ref} data-cy="payment-remarks-modal">
      <IntlTypography
        variant="h5"
        group="paymentRemarks"
        labelKey="widget-title"
        align="center"
        sx={classes.title}
      />
      <CloseIcon onClick={closeFn} sx={classes.closeIcon} />

      <Box sx={classes.cardContainer}>
        <RemarkCard
          title={`${reactIntl.formatNumber(Math.round(data.total_unsettled_amount))} NOK`}
          text={messages[intl.paymentRemarks('total-unsettled-amount')] as string}
          isBlue={false}
          url="/risika/norwegian-payment-remarks/files-and-coin.svg"
        />
        <RemarkCard
          title={`${messages[intl.paymentRemarks('data-from')]} ${providers?.size}`}
          text={messages[intl.paymentRemarks('debt-collection-agencies')] as string}
          isBlue={false}
          url="/risika/norwegian-payment-remarks/debt-collection-icon.svg"
        />

        <RemarkCard
          title={`${data.unique_creditors} ${
            messages[intl.paymentRemarks('active-creditors')] as string
          }`}
          text={messages[intl.paymentRemarks('unique-creditors')] as string}
          url="/risika/norwegian-payment-remarks/human-network.svg"
        />
        <RemarkCard
          title={`${data.avg_days_unsettled} ${messages[intl.generic('days')] as string}`}
          text={messages[intl.paymentRemarks('avg-unsettled-time')] as string}
          url="/risika/norwegian-payment-remarks/time-coin-cycle.svg"
        />
      </Box>

      <Sidescroll className="" conditionalySidescroll={true} maxWidth={null}>
        <TableContainer component={Paper} sx={classes.tableContainer}>
          <Table stickyHeader={true} sx={classes.table}>
            <TableHead sx={classes.tableHead}>
              <TableRow>
                <TableCell>
                  <IntlTypography group="paymentRemarks" labelKey="creditor" />
                </TableCell>
                <TableCell>
                  <IntlTypography group="paymentRemarks" labelKey="type" />
                </TableCell>
                <TableCell align="center">
                  <IntlTypography group="paymentRemarks" labelKey="case-id" />
                </TableCell>
                <TableCell align="center">
                  <IntlTypography group="paymentRemarks" labelKey="amount" />
                </TableCell>
                <TableCell align="center">
                  <IntlTypography group="paymentRemarks" labelKey="registered" />
                </TableCell>
                <TableCell align="center">
                  <IntlTypography group="paymentRemarks" labelKey="data-source" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={classes.tableBody}>
              {data?.remarks?.map((remark) => (
                <TableRow key={remark.case_id}>
                  <TableCell>
                    <Typography variant="body2">{remark.creditor}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {`${remark.type}${remark.type === changeOfSubjectType ? '**' : ''}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{remark.case_id}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {remark.amount
                        ? `${reactIntl.formatNumber(remark.amount)} NOK`
                        : `${messages[intl.generic('unknown')] as string}*`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {formatToUniversalDate(remark.registered)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{remark?.provider ?? ''}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <IntlTypography
                sx={classes.tableFooter}
                group="paymentRemarks"
                labelKey="total-remarks"
                values={{ count: data?.total_unsettled }}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </Sidescroll>

      <Stack spacing={2} sx={classes.asterisk}>
        {isUnknownAmount && (
          <IntlTypography
            variant="subtitle1"
            group="paymentRemarks"
            labelKey="asterisk-1"
          />
        )}
        {isTypeChangeOfSubject && (
          <IntlTypography
            variant="subtitle1"
            group="paymentRemarks"
            labelKey="asterisk-2"
          />
        )}
        <IntlTypography
          variant="subtitle1"
          group="paymentRemarks"
          labelKey="legal-collector"
          values={{ collector: NORWEGIAN_PAYMENT_REMARK_COLLECTOR }}
        />
      </Stack>
    </Box>
  )
})
export default ModalView
