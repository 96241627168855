import risikaAPI from 'services/api/helpers/risikaAPI'
import { AvailableLanguages } from 'globalTypes'

/******************
 * Fetch from API *
 ******************/

export type SubscriptionType = 'MONITOR' | 'CREDIT_COMPLIANCE'

export type Scheduler = {
  days: string[]
  mode: string
  starting_date: string | null
  frequency: number
}

export type EmailSettings = {
  status: boolean
  score: boolean
  annual_reports: boolean
  company_type: boolean
  relations: boolean
  name: boolean
  address: boolean
  email: boolean
  phone_number: boolean
  industry: boolean
  employees: boolean
  gazette: boolean
  payment_remarks: boolean
  policy_outcome: boolean
}

export type Subscription = {
  list_id: number
  type: SubscriptionType
  emails: string[]
  scheduler: Scheduler
  disabled: boolean
  email_settings: EmailSettings
  user_settings: {
    language: AvailableLanguages
  }
}
const subscriptionUpdate = (data?: Subscription) => {
  return risikaAPI.post(`/upsert/${data?.list_id}`, data, {
    microservice: 'subscription',
  })
}

export default subscriptionUpdate
