import React from 'react'
import { useSnackbar } from 'notistack'
import { colors } from 'configs'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useStyles } from '../styles/monitor-all-button'
import { useMonitorListPost, useMonitorShowList, useUserSelf } from 'services/queries'
import { RISIKA_PRICING_PAGE } from 'configs/constants/system'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useUpdateLists } from 'services/queries/lists/useUpdateLists'
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Divider,
  Box,
} from '@mui/material'
import {
  Button,
  withRestrictions,
  ConditionallyRender,
  ViewSwitch,
  RestrictedComponent,
} from 'components'
import { MonitoringList } from 'types/queries'
import NotificationsIcon from '@mui/icons-material/Notifications'

type MonitorAllButtonProps = {
  companies: any
  ready: boolean
  ButtonComponent: React.ReactNode
}

const MonitorAllButton = ({
  companies,
  ready = true,
  ButtonComponent,
}: MonitorAllButtonProps) => {
  const { data: lists, isLoading: isListsLoading } = useMonitorShowList()
  const { mutate: createList } = useMonitorListPost()
  const { data: currentUser } = useUserSelf()
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [isAddingNewList, setIsAddingNewList] = React.useState(false)
  const [listName, setListName] = React.useState('New list')
  const [isPublic, setIsPublic] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const onCompanyAddSuccess = () => {
    return enqueueSnackbar(<FormattedMessage id={intl.snackbar('companies-added')} />, {
      variant: 'success',
      className: 'snackbar-container snackbar__success',
    })
  }
  const onCompanyAddError = () => {
    return enqueueSnackbar(
      <FormattedMessage id={intl.snackbar('report-error-something-wrong')} />,
      {
        variant: 'error',
        className: 'snackbar-container snackbar__success',
      }
    )
  }

  const { updateLists } = useUpdateLists({
    onError: onCompanyAddError,
    onSuccess: onCompanyAddSuccess,
  })

  const handleAddToMonitoringList = (listId: number) => {
    updateLists({ list_id: listId, local_ids: companies })
    setOpen(false)
  }

  const RestrictedButton = withRestrictions('allow_monitoring', {
    title: <FormattedMessage id={intl.restriction('risk-monitoring-title')} />,
    content: <FormattedMessage id={intl.restriction('risk-monitoring-description')} />,
    button: {
      text: <FormattedMessage id={intl.restriction('risk-monitoring-button')} />,
      link: { RISIKA_PRICING_PAGE },
    },
  })(ButtonComponent)

  const handleCreateNewList = () => {
    if (!listName) {
      enqueueSnackbar(
        <FormattedMessage id={intl.riskMonitoringNew('add-list-name-snackbar')} />
      )
    } else {
      createList({
        title: listName,
        ownerApiId: currentUser?.api_user_id || 0,
        users: isPublic ? null : [currentUser?.api_user_id as number],
        isPublic,
      })
      setListName('')
      setIsAddingNewList(false)
    }
  }

  const handleEditModeKeyEvents = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        setIsAddingNewList(false)
        break
      case 'Enter':
        handleCreateNewList()
        break
      default:
        // just to remove warning in console
        break
    }
  }

  if (isListsLoading) {
    return null
  }

  return (
    <Box>
      <RestrictedButton
        icon={<NotificationsIcon sx={{ fontSize: '15px !important' }} />}
        text={<FormattedMessage id={intl.companyHierarchy('monitor-all')} />}
        disabled={!ready}
        onClick={() => setOpen(true)}
      />
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle style={{ backgroundColor: colors?.risika, color: 'white' }}>
          <FormattedMessage id={intl.companyHierarchy('select-list')} />
        </DialogTitle>
        <List>
          {lists?.map((item: MonitoringList) => (
            <ListItem style={{ display: 'flex' }} button key={item.list_id}>
              <ListItemText style={{ marginRight: '1rem' }}>{item.name}</ListItemText>
              <Button onClick={() => handleAddToMonitoringList(item.list_id)}>
                <FormattedMessage id={intl.generic('add')} />
              </Button>
            </ListItem>
          ))}
        </List>
        <Divider />
        <div className={classes.addNewList}>
          <ConditionallyRender
            condition={isAddingNewList}
            when={
              <>
                <div className={classes.newListField}>
                  <TextField
                    type="text"
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    autoFocus
                    fullWidth
                    label={<FormattedMessage id={intl.companyHierarchy('name')} />}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    onKeyUp={handleEditModeKeyEvents}
                    margin="normal"
                  />
                  <IconButton
                    onClick={() => handleCreateNewList()}
                    className={classes.addRemoveButtons}
                  >
                    <FontAwesomeIcon icon={faCheck as IconProp} />
                  </IconButton>
                  <IconButton
                    onClick={() => setIsAddingNewList(false)}
                    className={classes.addRemoveButtons}
                  >
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                  </IconButton>
                </div>
                <div className={classes.labelPlacement}>
                  {/* @ts-expect-error - The component is JS */}
                  <ViewSwitch
                    actionChange={() => setIsPublic(!isPublic)}
                    checkedVar={isPublic}
                    textOff={
                      <FormattedMessage id={intl.riskMonitoringNew('new-list-private')} />
                    }
                    textOn={
                      <FormattedMessage id={intl.riskMonitoringNew('new-list-public')} />
                    }
                  />
                </div>
              </>
            }
            otherwise={
              <RestrictedComponent component="create-new-list">
                <IconButton
                  className={classes.iconButton}
                  onClick={() => setIsAddingNewList(true)}
                >
                  <FontAwesomeIcon icon={faPlus as IconProp} />
                </IconButton>
              </RestrictedComponent>
            }
          />
        </div>
      </Dialog>
    </Box>
  )
}

export default React.forwardRef(MonitorAllButton)
