import { useRef } from 'react'

const useQueryInvalidation = () => {
  const query = useRef(null)

  function queryInvalidation(promise) {
    const promiseQuery = Date.now()
    query.current = promiseQuery

    return promise.then((data) => {
      if (promiseQuery !== query.current) {
        return [true, null]
      }

      return [false, data]
    })
  }

  return queryInvalidation
}

export default useQueryInvalidation
