import { Typography } from '@mui/material'

const DefaultCell = ({ cell, sx, ...props }) => {
  return (
    <Typography variant="subtitle1" sx={sx} {...props}>
      {cell.value}
    </Typography>
  )
}

export default DefaultCell
