import { AvailableCountriesCapital } from 'globalTypes'
import callBackendAPI from 'services/api/helpers/backendAPI'

/******************
 * Fetch from API *
 ******************/

type UserModel = {
  [K in AvailableCountriesCapital]?: 'BOHR' | 'NEWTON'
}

type UpdateCompany = {
  field: 'user_model' // Enum
  data: {
    user_model: UserModel
  }
}

type ResponseData = {
  data: {
    id: number
    customer_id: number
    user_model: UserModel
    created_at: Date
    updated_at: Date
  }
}

const updateCustomerSettings = ({
  field,
  data,
}: UpdateCompany): Promise<ResponseData> => {
  return callBackendAPI(`/customer/settings/${field}`, {
    method: 'put',
    data: {
      ...data[field],
    },
  }).then(({ data }) => {
    return data
  })
}

export default updateCustomerSettings
