import { SxProps } from '@mui/system'
import { colors } from 'configs'
import { theme } from 'styles-new/mui5-transition/theme'

const main: SxProps = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: theme.palette.common.white,
  borderRadius: theme.shape,
  p: 4,
}

const closeIcon: SxProps = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  '&:hover': {
    cursor: 'pointer',
  },
}

const title = {
  paddingBottom: theme.spacing(2),
}

const cardContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  columnGap: '1%',
  rowGap: theme.spacing(3),
  my: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    rowGap: theme.spacing(2),
  },
}

const tableContainer: SxProps = {
  maxHeight: '40vh',
}

const table: SxProps = {
  minWidth: 650,
  // down 2 lines fix tedious bug (chart body peeks over the header when scrolling)
  position: 'relative',
  top: '-7px',
}

const tableHead: SxProps = {
  '&  .MuiTableCell-root': {
    bgcolor: theme.palette.grey[100],
    borderRight: '1px solid',
    borderRightColor: theme.palette.grey[400],
  },
}

const tableBody: SxProps = {
  '&  .MuiTableCell-root': {
    color: colors.risikaSecondary,
  },
}

const tableFooter: SxProps = {
  px: theme.spacing(2),
  py: theme.spacing(3),
  width: 'max-content',
}
const asterisk: SxProps = {
  pt: theme.spacing(3),
  width: '50%',
  [theme.breakpoints.down('lg')]: {
    width: '70%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}

export default {
  main,
  title,
  tableContainer,
  table,
  tableHead,
  tableBody,
  tableFooter,
  asterisk,
  cardContainer,
  closeIcon,
}
