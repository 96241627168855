import * as d3 from 'd3'
import intl from 'localization/components'
import { riskColors } from 'configs/constants/theme'

const width = 220
const height = 150
const animationSpeed = 1000

export default class D3RisikaScore {
  constructor(element, data, messages) {
    const vis = this
    vis.svg = d3
      .select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(30, 30)`)

    vis.arc = d3.arc()

    /**
     * Arcs
     */

    // Arc Three
    vis.arcThree = d3
      .arc()
      .innerRadius(90)
      .outerRadius(100)
      .startAngle(Math.PI * 0.6)
      .endAngle(Math.PI)
      .cornerRadius(25)
    vis.svg
      .append('path')
      .attr('d', vis.arcThree)
      .style('transform', 'translate(80px, 80px) rotate(-90deg)')
      // .style("stroke-width", "1px")
      .style(
        'stroke',
        data?.risikaScore ? riskColors.low : vis.getScoreColor(data?.riskAssessmentCode)
      )
      .style(
        'fill',
        data?.risikaScore ? riskColors.low : vis.getScoreColor(data?.riskAssessmentCode)
      )

    vis.g = vis.svg.append('g').style('transform', 'translate(80px, 80px) rotate(-90deg)')

    // Arc Two
    vis.arcTwo = d3
      .arc()
      .innerRadius(90)
      .outerRadius(100)
      .startAngle(0)
      .endAngle(Math.PI * 0.675)
      .cornerRadius(25)

    vis.svg
      .append('path')
      .attr('d', vis.arcTwo)
      .style('transform', 'translate(80px, 80px) rotate(-90deg)')
      .style('stroke-width', '2px')
      .style(
        'stroke',
        // data?.risikaScore ? colors?.rating_5 : vis.getScoreColor(data?.riskAssessmentCode)
        data?.risikaScore
          ? riskColors.medium
          : vis.getScoreColor(data?.riskAssessmentCode)
      )
      .style(
        'fill',
        // data?.risikaScore ? colors?.rating_5 : vis.getScoreColor(data?.riskAssessmentCode)
        data?.risikaScore
          ? riskColors.medium
          : vis.getScoreColor(data?.riskAssessmentCode)
      )

    // Arc One
    vis.arcOne = d3
      .arc()
      .innerRadius(90)
      .outerRadius(100)
      .startAngle(0)
      .endAngle(Math.PI * 0.33)
      .cornerRadius(25)

    vis.svg
      .append('path')
      .attr('d', vis.arcOne)
      .style('transform', 'translate(80px, 80px) rotate(-90deg)')
      .style('stroke-width', '1px')
      .style(
        'stroke',
        data?.risikaScore ? riskColors.high : vis.getScoreColor(data?.riskAssessmentCode)
      )
      .style(
        'fill',
        data?.risikaScore ? riskColors.high : vis.getScoreColor(data?.riskAssessmentCode)
      )

    // The path for the circle
    vis.circlePath = vis.svg
      .append('path')
      .style('transform', 'translate(80px, 80px) rotate(-90deg)')
      .attr('class', 'outherPath')
      .attr(
        'd',
        vis.arc({
          innerRadius: 95,
          outerRadius: 95,
          startAngle: 0,
          endAngle: Math.PI,
        })
      )
      .style('fill', 'transparent')
      .style('stroke', 'transparent')

    // The path for the triangle
    vis.trianglePath = vis.svg
      .append('path')
      .style('transform', 'translate(80px, 80px) rotate(-90deg)')
      .attr('class', 'trianglePath')
      .attr(
        'd',
        vis.arc({
          innerRadius: 72,
          outerRadius: 72,
          startAngle: 0,
          endAngle: Math.PI,
        })
      )
      .style('fill', 'transparent')
      .style('stroke', 'transparent')

    /**
     * Animations
     */
    // Text Data
    vis.tenText = vis.svg.selectAll('.tenText').data([data.risikaScore])
    vis.riskText = vis.svg.selectAll('.riskText').data([data.risikaScore])
    vis.scoreText = vis.svg.selectAll('.scoreText').data([data.risikaScore])

    // Circle Data
    vis.circle = vis.svg.selectAll('.circle').data([data.risikaScore])

    // Triangle Data
    vis.triangle = vis.g.selectAll('.triangle').data([data.risikaScore])

    vis.update(messages, data)
  } // Constructor

  getRiskLevelTranslationKey = (riskAssessmentCode) => {
    // Old version of the API uses e.g. "HIGH RISK", new uses e.g. "HIGH"
    switch (riskAssessmentCode?.replace(' RISK', '')) {
      case 'HIGH':
        return 'risk-level-high'
      case 'MEDIUM':
        return 'risk-level-medium'
      case 'LOW':
        return 'risk-level-low'
      default:
        return 'risk-level-unknown'
    }
  }

  getScoreColor = (riskAssessmentCode) => {
    // Old version of the API uses e.g. "HIGH RISK", new uses e.g. "HIGH"
    switch (riskAssessmentCode?.replace(' RISK', '')) {
      case 'HIGH':
        return riskColors.high
      case 'MEDIUM':
        return riskColors.medium
      case 'LOW':
        return riskColors.low
      default:
        return riskColors.undefined
    }
  }

  divisionHelper(length, score) {
    if (score >= 7) {
      return length / 33
    }
    if (score === 5) {
      return length / 36.5
    }

    return length / 35
  }

  divisionHelperTriangle(length, score) {
    if (score === 2) {
      return length / 28
    }
    if (score === 3) {
      return length / 26
    }
    if (score === 4) {
      return length / 26.5
    }
    if (score === 5) {
      return length / 27.5
    }
    if (score === 6) {
      return length / 26
    }
    if (score >= 7) {
      return length / 25
    }
    return length / 30
  }

  rotorHelper(score) {
    if (score === 1) {
      return 40
    }
    if (score === 2) {
      return 120
    }
    if (score === 3 || score === 4) {
      return 160
    }
    if (score === 7 || score === 8) {
      return 170
    }
    return 180
  }

  circlePathTween(path, vis, d) {
    var length = path.node().getTotalLength() // Get the length of the path
    const scale = d3
      .scaleLinear()
      .domain([0, vis.divisionHelper(length, d)])
      .range([1, d])
    var r = d3.interpolate(0, scale(length)) // Set up interpolation from 0 to the path length

    return function (t) {
      var point = path.node().getPointAtLength(r(t) ?? 0) // Get the next point along the path
      d
        ? d3
            .select(this) // Select the circle
            .attr('cx', point.x) // Set the cx
            .attr('cy', point.y) // Set the cy
        : d3
            .select(this) // Select the circle
            .attr('cx', 99999999999999) // Set the cx
            .attr('cy', 99999999999999) // Set the cy
    }
  }

  trianglePathTween(path, vis, d) {
    var length = path.node().getTotalLength() // Get the length of the path
    const scale = d3
      .scaleLinear()
      .domain([0, vis.divisionHelperTriangle(length, d)])
      .range([1, d])
    const scaleRotate = d3
      .scaleLinear()
      .domain([0, 10])
      .range([0, vis.rotorHelper(d)])
    const scaleT = d3.scaleLinear().domain([0, 1]).range([1, d])

    var r = d3.interpolate(0, scale(length)) // Set up interpolation from 0 to the path length
    return function (t) {
      var point = path.node().getPointAtLength(r(t) ?? 0) // Get the next point along the path
      // - 120 to 60
      d
        ? d3
            .select(this) // Select the circle
            .style(
              'transform',
              `translate(${point.x}px, ${point.y}px) rotate(${scaleRotate(scaleT(t))}deg)`
            )
        : d3
            .select(this) // Select the circle
            .style('transform', `translate(99999999999px, 99999999999px)`)
    }
  }

  update = (messages, data) => {
    const vis = this
    vis.scoreText.exit().remove()
    vis.tenText.exit().remove()
    vis.riskText.exit().remove()

    vis.circle.exit().remove()
    vis.triangle.exit().remove()

    /**
     * Circle animations
     */

    // Inner small circle
    vis.circle
      .enter()
      .append('circle')
      .attr('cx', 0.9999806880950928) // Starting x
      .attr('cy', -94.99475860595703) // Starting y
      .attr('r', 8)
      .style('stroke-width', '4px')
      .style('fill', () => vis.getScoreColor(data?.riskAssessmentCode))
      .attr('stroke', 'white')
      .style('transform', 'translate(80px, 80px) rotate(-90deg)')
      .transition()
      .delay(1000)
      // .ease(d3.easeQuadOut)
      .duration(animationSpeed)
      .tween('circlePathTween', (d) => vis.circlePathTween(vis.circlePath, vis, d))

    // // Inner small triangle
    vis.triangle
      .enter()
      .append('path')
      .attr('d', function (d) {
        return d3.symbol().type(d3.symbolTriangle).size('100')()
      })
      .style('stroke-width', '0px')
      .style('fill', () => vis.getScoreColor(data?.riskAssessmentCode))
      .attr('stroke', 'white')
      .style(
        'transform',
        // 'translate(0.9999675750732422px, -71.99311828613281px) rotate(4deg)',
        'translate(999999.9999675750732422px, -71999999.99311828613281px) rotate(4deg)'
      )
      .transition()
      .delay(1000)
      // .ease(d3.easeLinear)
      .duration(animationSpeed)
      .tween('trianglePathTween', (d) => vis.trianglePathTween(vis.trianglePath, vis, d))

    /**
     * Text animations
     */
    vis.tenText
      .enter()
      .append('text')
      .attr('dx', (d) => {
        if (d === 10) {
          return 105
        }
        return 100
      })
      .attr('dy', (d) => 72)
      .style('text-anchor', 'middle')
      .style('cursor', 'default')
      .style('font-size', '1.7rem')
      .style('font-weight', '700')
      .text('/10')
      .style('fill', (d) =>
        d ? vis.getScoreColor(data?.riskAssessmentCode) : 'transparent'
      )

    vis.riskText
      .enter()
      .append('text')
      .attr('dx', 84)
      .attr('dy', (d) => (d ? 98 : 75))
      .style('text-anchor', 'middle')
      .style('cursor', 'default')
      .style('font-size', '1.6rem')
      .style('font-weight', '600')
      .text((d) => {
        return messages[
          intl.mainRecommendation(vis.getRiskLevelTranslationKey(data.riskAssessmentCode))
        ]
      })
      .style('fill', () => vis.getScoreColor(data?.riskAssessmentCode))

    vis.scoreText
      .enter()
      .append('text')
      .attr('class', 'scoreText')
      .attr('dx', (d, i) => 70)
      .attr('dy', (d, i) => 72)
      .style('text-anchor', 'middle')
      .style('cursor', 'default')
      .style('font-size', '5rem')
      .style('font-weight', '600')
      .style('fill', (d) =>
        d ? vis.getScoreColor(data?.riskAssessmentCode) : 'transparent'
      )
      .text('1')
      .transition()
      .delay(1000)
      // .ease(d3.easeLinear)
      .duration(animationSpeed)
      .tween('text', function (d) {
        const textScale = d3.scaleLinear().domain([0, 1]).range([1, d])
        return function (t) {
          this.textContent = d ? parseInt(textScale(t)) : 0
        }
      })
  }
}
