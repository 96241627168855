import { useState, useEffect, useMemo } from 'react'
import { Stack, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { SearchMultiSelect } from 'components-new'

const SearchableSelect = ({ suggestions, value, placeholder, onChange, onRemove }) => {
  const initialSelectedValues = useMemo(
    () => (value ? value.map((val) => val.value) : []),
    [value]
  )
  const [searchText, setSearchText] = useState('')
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues)

  useEffect(() => {
    const newValues = selectedValues.map((val) =>
      suggestions.find((s) => s.value === val)
    )
    onChange(newValues)
  }, [selectedValues]) // This is correct

  return (
    <Stack
      direction="column"
      gap={5}
      // sx={{ border: 'red solid 1px' }}
    >
      <SearchMultiSelect
        options={suggestions}
        searchText={searchText}
        setSearchText={setSearchText}
        values={selectedValues}
        setValues={setSelectedValues}
        inputProps={{ sx: { width: 320 } }}
        containerStyles={{ width: 350 }}
        endAddon={
          <IconButton onClick={onRemove}>
            <DeleteIcon color="primary" sx={{ fontSize: '21.5px' }} />
          </IconButton>
        }
      />
    </Stack>
  )
}

export default SearchableSelect
