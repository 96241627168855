// @flow

import { useEffect, useMemo, useState } from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { AsyncLoader, Stack } from 'components'
import PhoneNumber from 'awesome-phonenumber'
import { responsibleUser } from 'services/api'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { profilePictures } from './MonitorOverview/profilePictures'
import CircleIcon from '@mui/icons-material/Circle'
import { useLocalTimes } from '../../../services/hooks/useLocalTimes'

const DEFAULT_CONTACT = {
  name: 'Kristoffer',
  phoneNumber: '+4531358567',
  email: 'kg@risika.com',
  imageUrl: 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/kristoffer.jpg',
}

const ContactResponsible = () => {
  const [ready, setReady] = useState(false)
  const [{ name, phoneNumber, email, imageUrl }, setContact] = useState(DEFAULT_CONTACT)
  const { isOnline } = useLocalTimes()
  const onlineIndicatorColour = isOnline ? '#4CAF50' : '#DC182F'

  useEffect(() => {
    responsibleUser()
      .then(({ data }) => {
        if (data?.name && data?.email) {
          setContact({
            name: data.name,
            phoneNumber: data?.phone_number ?? '',
            email: data.email,
            imageUrl: profilePictures[data.email] ?? profilePictures.dummy_blue,
          })
        }
      })
      .catch(() => {})
      .finally(() => {
        setReady(true)
      })
  }, [])

  const phoneNumberFormat = useMemo(() => {
    return new PhoneNumber(phoneNumber, 'dk').getNumber('international')
  }, [phoneNumber])

  return (
    <Card>
      <CardContent>
        <AsyncLoader ready={ready}>
          <Stack direction="column" items="center">
            <Box sx={{ marginBottom: '16px !important', paddingTop: '4px' }}>
              <Box
                display="flex"
                margin="0 auto"
                overflow="hidden"
                width="128px"
                height="128px"
                justifyContent="center"
                borderRadius="100%"
              >
                <img src={imageUrl} alt=""></img>
              </Box>
              <Box
                flexDirection="row"
                display="flex"
                justifyContent="center"
                color={onlineIndicatorColour}
              >
                <Typography variant="body1">{name}</Typography>
                <CircleIcon fontSize="small" />
              </Box>
            </Box>
            <Stack justify="space-around" style={{ width: '100%' }}>
              {phoneNumberFormat && (
                <Box textAlign="center">
                  <Typography variant="subtitle1">
                    <FormattedMessage id={intl.generic('phone')} />
                  </Typography>
                  <Typography variant="body1">{phoneNumberFormat}</Typography>
                </Box>
              )}
              <Box textAlign="center">
                <Typography variant="subtitle1">
                  <FormattedMessage id={intl.generic('email')} />
                </Typography>
                <Typography variant="body1">{email}</Typography>
              </Box>
            </Stack>
          </Stack>
        </AsyncLoader>
      </CardContent>
    </Card>
  )
}

export default ContactResponsible
