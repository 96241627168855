import { DOMAIN as _ } from 'configs/constants/system'

/****************
 * Action Types *
 ****************/

export const CHANGE_SELECTED_LIST = `${_} CHANGE_SELECTED_LIST`

/*****************
 * Initial State *
 *****************/

export const initialStateRiskMonitoring = {
  selectedList: 0,
}

/*******************
 * Action Creators *
 *******************/

export const changeSelectedList = (listId) => ({
  type: CHANGE_SELECTED_LIST,
  listId,
})

/**
 * Handle dispatched actions.
 *
 * @param {*} state Initial state
 * @param {*} action Actions
 */
export const welcome = (state = initialStateRiskMonitoring, action = {}) => {
  switch (action.type) {
    case CHANGE_SELECTED_LIST:
      return {
        selectedList: action.listId,
      }
    default:
      return state
  }
}

export default welcome
