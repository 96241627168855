import { AvailableCountries, AvailableCountriesCapital } from 'globalTypes'
import {
  AccountStatusResponse,
  Rights,
  ScoreModel,
} from 'services/api/endpoints/risikaAPI/accountStatus'
import { ModelObject } from '.'
import { queryClient } from 'index'
import customerSettingsField from 'services/api/endpoints/backendAPI/customerSettingsField'
import customerSettingsFieldUpdate from 'services/api/endpoints/backendAPI/customerSettingsFieldUpdate'

type AvailableModel = { [key in AvailableCountriesCapital]: ScoreModel[] }

/**
 * Takes only the countries allowed by the risikaAPI where the each customer can use either model (BOHR | NEWTON)
 *
 * @param rights Rights object from Company Status endpoint
 * @returns {
 * {
 * DK: ScoreModel[],
 * SE: ScoreModel[],
 * NO: ScoreModel[],
 * UK: ScoreModel[],
 * FI: ScoreModel[]
 * }
 */

export const getAvailableModelsInCountry = (rights: Rights): {} | AvailableModel => {
  let availableModelsInCountry = {}

  rights?.allow_countries?.forEach((country) => {
    const selectedCountryFromRights =
      rights?.[
        country.toUpperCase() as keyof Pick<
          Rights,
          Exclude<AvailableCountriesCapital, 'DE'> // Removing DE for now until theAPI starts supporting it
        >
      ]?.score_models

    if (selectedCountryFromRights) {
      availableModelsInCountry = {
        ...availableModelsInCountry,
        [country?.toUpperCase()]: selectedCountryFromRights.map(
          (x: ScoreModel) => x.model
        ),
      }
    }
  })
  return availableModelsInCountry
}

type AppendModel = {
  country?: AvailableCountries
  method?: string
  availableModelsInCountry: {} | AvailableModel
  modelObject: ModelObject
  model: string
}

/**
 * Infers the model in case the user has not used the new system yet. Should be deprecated once the new webAPI goes live.
 */
function inferModelLegacy(
  availableModelsInCountry: {} | AvailableModel,
  model: string,
  country?: AvailableCountries | ''
) {
  const inferredModel = Object.prototype.hasOwnProperty.call(
    availableModelsInCountry,
    country?.toUpperCase() ?? 'DK'
  )
    ? model
    : 'BOHR'

  return inferredModel
}

/**
 * Returns the model selected by the user (BOHR | NEWTON) for the particular country
 *
 * @param country One of the Allowed countries
 * @param availableModelsInCountry Basically all the supported countries by the customer. The returned value of getAvailableModelsInCountry.
 * @param modelObject Either BOHR or NEWTON for each country supported by the customer.
 * @param model This is also either BOHR or NEWTON, but in the legacy format and it should be phased out at some point.
 */

export const getModel = ({
  country,
  availableModelsInCountry,
  modelObject,
  model,
}: AppendModel) => {
  if (
    Object.prototype.hasOwnProperty.call(
      modelObject ?? {},
      country?.toUpperCase() ?? 'DK'
    )
  ) {
    return modelObject?.[country?.toUpperCase() as keyof ModelObject] ?? 'BOHR'
  }
  return inferModelLegacy(availableModelsInCountry, model, country)
}
export const getScoringModel = async (status: AccountStatusResponse) => {
  const modelObjectData = await queryClient.fetchQuery({
    queryKey: 'customerSettingsField',
    queryFn: () => customerSettingsField('user_model'),
  })

  const allowedCountriesWithModel = status?.rights?.allow_countries?.filter((country) => {
    const modelForCountry = status?.rights?.[country?.toUpperCase() as keyof Rights] as {
      score_models: ScoreModel[]
    }
    return modelForCountry?.score_models?.length > 0
  })

  const userModel = (modelObjectData?.user_model ?? {}) as {
    [key in AvailableCountriesCapital]?: 'BOHR' | 'NEWTON'
  }
  if (Object.keys(userModel).length !== allowedCountriesWithModel?.length) {
    // In case the customer does not have a model set. We set it and return the inferred values which by default is BOHR
    let inferredModel = {}
    allowedCountriesWithModel?.forEach((country) => {
      inferredModel = {
        ...inferredModel,
        [country?.toUpperCase()]: 'BOHR',
      }
    })
    customerSettingsFieldUpdate({ field: 'user_model', data: inferredModel })
    return inferredModel
  }
  let newUserModel = {}

  allowedCountriesWithModel?.forEach((country) => {
    newUserModel = {
      ...newUserModel,
      [country?.toUpperCase()]:
        userModel?.[country?.toUpperCase() as keyof typeof userModel],
    }
  })
  return newUserModel
}
