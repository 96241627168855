import risikaAPI from 'services/api/helpers/risikaAPI'
import {
  LocalId,
  AllowedModel,
  AvailableCountriesCapital,
  AvailableCurrencies,
} from 'globalTypes'

export type Action =
  | 'approve'
  | 'approve_with_value'
  | 'adjust_credit_max'
  | 'manual_check'
  | 'reject'
  | 'default' // comes from FE
  | 'undefined'

export type Step = {
  title: string
  truth_value: string
  short_description: string
}

export type EnterpriseEvaluation = {
  local_id?: string
  credit_policy_action: Action
  credit_policy_limit?: number
  credit_rules_applied?: string[]
  currency?: AvailableCurrencies
  details?: {
    outcome_name: string
    outcome_description: string
    policy_name: string
    steps: Step[]
    policy_id: number
  }
}
export type ResponseData = {
  data: EnterpriseEvaluation[]
}

type Args = {
  localIdList: LocalId[]
  country: AvailableCountriesCapital
  model: AllowedModel
}

const evaluationPolicy = async ({
  localIdList,
  country,
  model,
}: Args): Promise<ResponseData> => {
  const { data } = await risikaAPI.post(
    'enterprise_credit_policies/evaluate',
    {
      local_organization_ids: localIdList,
      score_model: { [country]: model },
      country,
    },
    { microservice: 'account', appendCurrencyData: true }
  )
  const res = data.data[0]
  return {
    ...res,
    details: {
      ...res.details,
      steps: res.details.steps,
    },
    currency: data.currency,
  }
}

export default evaluationPolicy
