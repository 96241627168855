import { useQuery } from 'react-query'
import { financialStats } from 'services/api'
import { isCallAllowed } from 'configs'
import { AvailableCountries, LocalId } from 'globalTypes'

export function useFinancialStatsConsolidated(localId: LocalId) {
  return useQuery(
    ['financialStatsConsolidated', localId.id, localId.country],
    () => financialStats(localId, { consolidated: true }),
    {
      enabled: !!isCallAllowed(
        'financialStatsConsolidated',
        localId.country as AvailableCountries
      ),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
