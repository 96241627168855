import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import {
  ACTION_TEMPLATE,
  BOOLEAN,
  DROPDOWN_MULTI_SELECT,
  MULTI_SELECT,
  TOLERANCE_DROPDOWN,
  TOLERANCE_INPUT,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import * as React from 'react'
import { SubmitRef } from '..'
import {
  ActionTemplateAPIData,
  MultiSelectAPIData,
  SelectAPIData,
  ToleranceAPIData,
} from '../Actions/Actions.types'
import ActionTemplate from '../Actions/ActionTemplate'
import Boolean from '../Actions/Boolean'
import DropdownMultiSelect from '../Actions/DropdownMultiSelect'
import MultiSelect from '../Actions/MultiSelect'
import ToleranceInput from '../Actions/ToleranceInput'
import ToleranceDropdown from '../Actions/ToleranceDropdown'
import { getDataFromSelectedNode } from '../DetailedComponentView.controller'
import { InputData, TemplateType } from 'types/queries'
import { Option } from 'types/general'

type VariableRendererProps = {
  template: TemplateType
  setIsConfirmEnabled: any
}

const VariableHandler = React.forwardRef(
  (
    { template, setIsConfirmEnabled }: VariableRendererProps,
    ref: React.ForwardedRef<SubmitRef>
  ) => {
    const templateOptions = (template?.options ?? []) as Option<string | number>[]
    const { state } = useBuilder()
    switch (template?.template_data?.variant) {
      case DROPDOWN_MULTI_SELECT:
        return (
          <DropdownMultiSelect
            label={template.title}
            setIsConfirmEnabled={setIsConfirmEnabled}
            apiData={getDataFromSelectedNode(state) as SelectAPIData}
            ref={ref}
            data={templateOptions}
          />
        )
      case MULTI_SELECT:
        return (
          <MultiSelect
            setIsConfirmEnabled={setIsConfirmEnabled}
            apiData={getDataFromSelectedNode(state) as MultiSelectAPIData}
            data={templateOptions}
            ref={ref}
          />
        )
      case TOLERANCE_INPUT:
        return (
          <ToleranceInput
            setIsConfirmEnabled={setIsConfirmEnabled}
            apiData={getDataFromSelectedNode(state) as ToleranceAPIData}
            ref={ref}
            inputData={template.template_data.input_data as InputData}
            label={template.title}
          />
        )
      case TOLERANCE_DROPDOWN:
        return (
          <ToleranceDropdown
            setIsConfirmEnabled={setIsConfirmEnabled}
            apiData={getDataFromSelectedNode(state) as ToleranceAPIData}
            ref={ref}
            data={templateOptions}
            label={template.title}
          />
        )
      case BOOLEAN:
        return <Boolean setIsConfirmEnabled={setIsConfirmEnabled} ref={ref} />
      case ACTION_TEMPLATE:
        return (
          <ActionTemplate
            setIsConfirmEnabled={setIsConfirmEnabled}
            apiData={getDataFromSelectedNode(state) as ActionTemplateAPIData}
            ref={ref}
            label={template.title}
          />
        )

      default:
        return null
    }
  }
)
export default VariableHandler
