import * as React from 'react'
import { Box, Collapse, Stack, Typography, Link, TypographyVariant } from '@mui/material'
import { EnterpriseEvaluation } from 'services/api/endpoints/risikaAPI/evaluationPolicy'
import { CreditPolicyModels } from './EnterpriseCreditPolicy.model'
import { Action } from 'services/api/endpoints/risikaAPI/evaluationPolicy'
import { ToggleMenu, CardWithColorAndIcon } from 'components-new'
import styles from './EnterpriseCreditPolicy.styles'
import DetailStep from './DetailStep'
import { v4 as uuidv4 } from 'uuid'
import { navigation } from 'services/navigation'
import { Step } from 'services/api/endpoints/risikaAPI/evaluationPolicy'
import { useQueryClient } from 'react-query'
import { useCreditPolicyGetAll } from 'services/queries'
import EnterpriseSkeleton from './EnterpriseCreditPolicy.skeleton'
import { Policy } from 'types/general'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { CreditCheckParams } from 'pages/credit-check/CreditCheck.types'
type Props = {
  enterpriseEvaluation: EnterpriseEvaluation
}

const EnterpriseCreditPolicy = ({ enterpriseEvaluation }: Props) => {
  const history = useHistory()
  const params = useParams() as CreditCheckParams
  const [isOpen, setIsOpen] = React.useState(false)
  const { credit_policy_action, details } = enterpriseEvaluation ?? {}
  const name = details?.policy_name ?? null
  const steps: Step[] | null = details?.steps ?? null
  const reversedSteps = [...(steps ?? [])].reverse()
  const reactIntl = useIntl()
  const {
    color,
    icon,
    variant: policyVariant,
  } = CreditPolicyModels.find((model) => model.name === credit_policy_action) ??
  CreditPolicyModels.find((model) => model.name === ('default' as Action))!
  const {
    isLoading,
    isError: isPolicyAllError,
    data: allPolicies,
    error: policyError,
    isSuccess: isPolicySuccess,
  } = useCreditPolicyGetAll()
  const queryClient = useQueryClient()
  const {
    selectedCountry,
    selectedCurrency: { currency },
  } = queryClient.getQueryData('getUserSettings') as {
    [key: string]: any
  }
  const selectedPolicy: Policy | undefined = allPolicies?.policies?.find(
    (policy) => policy.name === name
  )

  if (!allPolicies) {
    return null
  }
  const handlePolicyLink = () => {
    history.push(
      navigation.creditPolicyBuilder({
        country: params?.country ?? selectedCountry,
        name,
        createActiveRule: true,
        policyId: details?.policy_id ?? selectedPolicy?.id,
      })
    )
  }
  return (
    <Box>
      {isLoading && <EnterpriseSkeleton />}
      {isPolicyAllError && (
        <Typography color="error"> {policyError as string}</Typography>
      )}
      {isPolicySuccess && (
        <>
          <CardWithColorAndIcon icon={icon} color={color} sx={styles.card}>
            <Stack>
              <Typography variant="subtitle1">
                {enterpriseEvaluation?.details?.outcome_name ?? 'No policy applied'}
              </Typography>
              <Typography variant="body2">
                {enterpriseEvaluation?.details?.outcome_description ??
                  "You don't have any active policy or this company isn't targeted by any of your policy."}
              </Typography>
              {policyVariant === 'positive' && (
                <Typography variant="body2" sx={styles.creditMax}>
                  Credit max:{' '}
                  <b>
                    {Number.isInteger(enterpriseEvaluation?.credit_policy_limit) &&
                      `${reactIntl.formatNumber(
                        Math.floor(enterpriseEvaluation?.credit_policy_limit || 0)
                      )} ${currency}`}
                  </b>
                </Typography>
              )}
              {name && credit_policy_action !== 'undefined' ? (
                <Link onClick={handlePolicyLink} sx={styles.policyLink}>
                  <Typography variant={'link2' as TypographyVariant}>{name}</Typography>
                </Link>
              ) : null}
            </Stack>
          </CardWithColorAndIcon>
          {reversedSteps?.length && credit_policy_action !== 'undefined' ? (
            <>
              <Box data-cy="toggle-steps">
                <ToggleMenu
                  isMenuExpanded={isOpen}
                  setIsMenuExpanded={setIsOpen}
                  firstLabelGenericKey="view-details"
                  secondLabelGenericKey="hide-details"
                  sx={styles.toggle}
                />
              </Box>
              <Collapse in={isOpen}>
                <Stack>
                  {(reversedSteps || [])?.map(
                    ({ title, short_description, truth_value }, i) => (
                      <DetailStep
                        key={uuidv4()}
                        title={title}
                        desc={short_description}
                        value={truth_value}
                        variant={i === 0 ? policyVariant : 'neutral'}
                      />
                    )
                  )}
                </Stack>
              </Collapse>
            </>
          ) : null}
        </>
      )}
    </Box>
  )
}

export default EnterpriseCreditPolicy
