import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { CountryPolicyContextType } from 'pages/advanced-credit-policy/types'

type PrepaidPhoneInputProps = {
  policyInject: () => CountryPolicyContextType
  isAdvanced?: boolean
}

const PrepaidPhoneInput = ({
  policyInject,
  isAdvanced = true,
}: PrepaidPhoneInputProps) => {
  const { policy, setPolicy, isInferiorPlan } = policyInject()
  const reactIntl = useIntl()

  const [val, setVal] = React.useState<boolean>(!policy.prepaid_phone_number)

  // this is needed to set the initial value after it's been null
  useEffect(() => {
    if (typeof policy.prepaid_phone_number === 'boolean') {
      setVal(!policy.prepaid_phone_number)
    }
  }, [policy.prepaid_phone_number])
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={val}
          onChange={function (e: React.ChangeEvent<HTMLInputElement>): void {
            setVal(!val)
            if (isAdvanced && !isInferiorPlan) {
              setPolicy({ ...policy, prepaid_phone_number: val })
            }
          }}
          sx={{ margin: 3, padding: 0 }}
          disabled={isAdvanced && isInferiorPlan}
        />
      }
      label={reactIntl.formatMessage({
        id: intl.creditPolicyNew('reject_prepaid_phone'),
      })}
    />
  )
}

export default PrepaidPhoneInput
