import risikaAPI from 'services/api/helpers/risikaAPI'
/**
 * Search for a company.
 *
 * @returns {object} The search results.
 */

const handleAddCurrency = (filters, currency) => {
  let newFilters = {}

  Object.keys(filters ?? {}).forEach((filter) => {
    const currentFilter = filters[filter]
    newFilters = {
      ...newFilters,
      [filter]: currentFilter?.currency
        ? {
          ...currentFilter,
          currency: currency ?? 'DKK',
        }
        : currentFilter,
    }
  })
  return newFilters
}

const filterCompany = (filters, country, { currency, pagination }) => {
  const filtersWithAddedCurrency = handleAddCurrency(filters, currency)
  const { sortBy, ..._filters } = filtersWithAddedCurrency
  const { from, to } = pagination
  const data = {
    sort_by: sortBy,
    mode: 'full',
    from,
    to,
    filters: _filters,
    currency: currency ?? 'DKK',
  }
  // If we don't have anything to sort by, get rid of it, the API doesn't like null here.
  if (data.sort_by == null) {
    delete data.sort_by
  }

  return risikaAPI
    .post('/search/company', data, {
      microservice: 'data',
      country,
      appendScoringModel: true,
    })
    .then(({ data }) => {
      return data
    })
}

export default filterCompany
