import { Button, Divider, Stack, Typography } from '@mui/material'
import * as React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import styles from './NavButton.styles'

const {
  containerMain,
  containerLeft,
  containerRight,
  actionIcon,
  divider,
  buttonStyles,
} = styles

export type NavButtonProps = {
  content: JSX.Element | string
  onClick: () => void
  icon: JSX.Element
}

const NavButton = (props: NavButtonProps) => {
  const { content, onClick, icon } = props
  return (
    <Button data-cy={content} disableRipple onClick={onClick} sx={buttonStyles}>
      <Stack sx={containerMain}>
        <Stack sx={containerLeft}>
          {icon}
          <Typography variant="body1" color="inherit">
            {content}
          </Typography>
        </Stack>
        <Stack sx={containerRight}>
          <ChevronRightIcon color="inherit" sx={actionIcon} />
        </Stack>
      </Stack>
      <Divider sx={divider} />
    </Button>
  )
}

export default NavButton
