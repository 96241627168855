import { IconButton } from '@mui/material'
import { default as cx } from 'classnames'
import { useSelector } from 'react-redux'

import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

import { Stack, Box, Typography, TextField, useMediaQuery } from '@mui/material'

import Options from './Options'
import SearchableSelect from './SearchableSelect'
import { inputType } from '../models/filtersModel'
import { loadSuggestions, getInputLabelByFilter } from '../controller/general'
import DateRangeInput from './DateRangeInput'
import DeleteIcon from '@mui/icons-material/Delete'
import { equals } from 'ramda'
import NestedIndustrySelect from './NestedSelect/NestedIndustrySelect'
import AdvancedZipcodeInput from './AdvancedZipcodeInput'

import { useStyles } from '../styles/filter'
import { InputField, InputRange } from 'components-new'

const getSelectedOption = (options) => {
  const [selected] = options.filter((option) => option.selected)

  return selected
}

const getSuggestion = (value, suggestions) => {
  const [suggestion] = suggestions.filter((x) => equals(x.value, value))

  if (suggestion) {
    return suggestion
  }

  return { value, label: value }
}

const FilterInput = ({ filter, onChange, onRemove, value }) => {
  const filterCountry = useSelector((state) => state.risika.advancedFilters.filterCountry)
  const { messages } = useIntl()

  const filterSuggestions = useSelector(
    (state) => state.risika.filterSuggestions[filterCountry]
  )

  const getSuggestions = loadSuggestions(filterSuggestions)

  const selectValue = (value, suggestions) => {
    if (value === null) {
      return value
    }

    return value.map((x) => ({
      value: x,
      label: getSuggestion(x, suggestions).label,
    }))
  }

  const suggestions = getSuggestions(filter.filter)
  const inputLabel = getInputLabelByFilter(filter)
  let inputComponent = null
  switch (getSelectedOption(filter.options).input) {
    case inputType.TREE_SELECT:
      inputComponent = (
        <NestedIndustrySelect
          country={filterCountry}
          onChange={onChange(inputType.RANGE)}
          filter={filter}
        />
      )
      break
    case inputType.SELECT:
      inputComponent = (
        <Box>
          <FormattedMessage id={intl.advancedSearch('search-filter')}>
            {(placeholder) => (
              <SearchableSelect
                placeholder={placeholder}
                suggestions={suggestions}
                value={selectValue(value, suggestions)}
                onChange={onChange(inputType.SELECT)}
                onRemove={onRemove}
              />
            )}
          </FormattedMessage>
        </Box>
      )
      break
    case inputType.NUMBER:
      inputComponent = (
        <Box>
          <FormattedMessage
            id={
              inputLabel
                ? intl.advancedSearch(inputLabel)
                : intl.advancedSearch('enter-value')
            }
          >
            {(placeholder) => (
              <InputField
                value={value}
                handleChange={onChange(inputType.NUMBER)}
                placeholder={placeholder}
                inputMode="numeric"
                extraStyles={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              />
            )}
          </FormattedMessage>
        </Box>
      )
      break
    case inputType.RANGE:
      inputComponent = (
        <Box width={300}>
          <InputRange
            value={{ a: value?.from ?? '', b: value?.to ?? '' }}
            setValue={({ a, b }) => onChange(inputType.RANGE)({ from: a, to: b })}
            skipValidation
            singleInputRangeField
            placeholderA={messages[intl.advancedSearch('range-from')]}
            placeholderB={messages[intl.advancedSearch('range-to')]}
          />
        </Box>
      )
      break
    case inputType.RANGE_DATE:
      inputComponent = (
        <DateRangeInput value={value} onChange={onChange(inputType.RANGE_DATE)} />
      )
      break
    case inputType.TEXT:
      inputComponent = (
        <FormattedMessage id={intl.advancedSearch(`placeholder-${filter.filter}`)}>
          {(placeholder) => (
            <TextField
              variant="standard"
              InputProps={{
                style: { width: 300 },
                disableUnderline: true,
              }}
              value={value}
              onChange={onChange(inputType.TEXT)}
              placeholder={placeholder}
              size="small"
            />
          )}
        </FormattedMessage>
      )
      break
    case inputType.ADVANCED_ZIPCODE:
      inputComponent = (
        <AdvancedZipcodeInput
          value={value?.value ?? []}
          onChange={onChange(inputType.ADVANCED_ZIPCODE)}
          onRemove={onRemove}
        />
      )
      break
    default:
      return null
  }

  return inputComponent
}

const Filter = ({ filter, className, onRemove, onChange }) => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(900))

  const handleChange = (change) => (updatedValue) => {
    const [prevType] = filter.options.filter(({ value }) => value === filter.type)
    const [nextType] = filter.options.filter(({ value }) => value === updatedValue)
    switch (change) {
      case 'option':
        prevType.input === nextType.input
          ? onChange({ ...filter, type: updatedValue })
          : onChange({ ...filter, type: updatedValue, value: null })
        break
      case inputType.NUMBER:
        onChange({
          ...filter,
          value: updatedValue === '' ? null : updatedValue,
        })
        break
      case inputType.RANGE:
        onChange({ ...filter, value: updatedValue })
        break
      case inputType.RANGE_DATE:
        onChange({ ...filter, value: updatedValue })
        break
      case inputType.TEXT:
        updatedValue.target.value === ''
          ? onChange({ ...filter, value: null })
          : onChange({ ...filter, value: updatedValue.target.value })
        break
      case inputType.SELECT:
        onChange({
          ...filter,
          value:
            !updatedValue?.length || updatedValue === null
              ? null
              : updatedValue.map(({ value }) => value),
        })
        break
      case inputType.ADVANCED_ZIPCODE:
        onChange({
          ...filter,
          value: updatedValue,
        })
        break
      default:
        return null
    }
  }

  if (isSmallScreen) {
    return (
      <Box className={classes.wrapperSmall}>
        <Box className={cx(classes.containerSmall, className, 'ABC')}>
          <Box className={classes.wrapper}>
            <Box className={classes.typeSmall}>
              <Typography variant="body2">
                {<FormattedMessage id={intl.advancedSearch(filter.filter)} />}
              </Typography>
            </Box>
            <Box className={classes.options}>
              <Options options={filter.options} onChange={handleChange('option')} />
            </Box>
            <IconButton
              className={classes.delete}
              onClick={(event) => onRemove(filter.filter)}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Box>
          <Box className={classes.wrapper}>
            <Box
              className={classes.valuesSmall}
              data-cy="filter-input-wrapper"
              id="filter-input-wrapper"
            >
              <FilterInput filter={filter} value={filter.value} onChange={handleChange} />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const shouldRenderRemoveIcon = (filter) => {
    const filtersToExclude = [
      'zipcode',
      'region',
      'industry_group',
      'employees',
      'company_status',
      'company_type',
      'municipality',
    ]
    return !filtersToExclude.includes(filter.filter)
  }

  return (
    <Stack
      flexWrap
      sx={{
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'flex-start',
        borderRadius: 1,
      }}
    >
      <Stack
        sx={{
          height: 44,
          padding: 3,
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid',
          borderColor: 'grey.200',
          backgroundColor: 'common.white',
          flexShrink: 0,
        }}
      >
        <Typography variant="body2">
          {<FormattedMessage id={intl.advancedSearch(filter.filter)} />}
        </Typography>
      </Stack>
      <Box height={44}>
        <Options options={filter.options} onChange={handleChange('option')} />
      </Box>
      <Box data-cy="filter-input-wrapper" id="filter-input-wrapper">
        <FilterInput
          filter={filter}
          value={filter.value}
          onChange={handleChange}
          onRemove={() => onRemove(filter.filter)}
        />
      </Box>
      {shouldRenderRemoveIcon(filter) && (
        <IconButton onClick={(event) => onRemove(filter.filter)}>
          <DeleteIcon color="primary" sx={{ fontSize: '21.5px' }} />
        </IconButton>
      )}
    </Stack>
  )
}

export default Filter
