import * as React from 'react'
import * as Sentry from '@sentry/browser'
import {
  CardHeader,
  CardContent,
  CardActionArea,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { authRegister } from 'services/api/helpers/authService'
import Input from './Input'
import CompanySearch from './CompanySearch'
import { useSnackbar } from 'notistack'

import { createReducer } from 'store_deprecated/reducer'
import { validate, validationFormats, pipeValidators } from 'utils/validate'

import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

import {
  Root,
  CardStyled,
  Title,
  Form,
  FormElement,
  RegisterButton,
  ActionCard,
  ActionCardContent,
  ActionTitle,
  ActionSubtitle,
  HeadlessLink,
  StrongLink,
  SmallText,
} from '../styles/register'

const registerForm = createReducer({
  email: '',
  name: '',
  cvr: '',
  country: '',
})

const registerFormError = createReducer({
  email: false,
  name: false,
  cvr: false,
  country: false,
})

const allValid = (fields) => {
  return !Object.values(fields).filter((x) => x).length
}

const Register = () => {
  const reactIntl = useIntl()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const accountHasBeenRegistered = false
  const [formState, formDispatch] = React.useReducer(...registerForm)
  const [formErrorState, formErrorDispatch] = React.useReducer(...registerFormError)
  const [acceptConditions, setAcceptConditions] = React.useState(false)
  const [formValidated, setFormValidated] = React.useState(false)

  /**
   * Represents the overall validity of the form.
   */
  React.useEffect(() => {
    const isValid = allValid(formErrorState) && acceptConditions
    if (isValid !== formValidated) {
      setFormValidated(isValid)
    }
  }, [formErrorState, acceptConditions, formValidated])

  const validateField = (field) => () => {
    switch (field) {
      case 'email':
        formErrorDispatch({
          field,
          value: !validate(
            formState.email,
            pipeValidators(validationFormats.email, validationFormats.notEmpty)
          ),
        })
        break
      case 'name':
        formErrorDispatch({
          field,
          value: !validate(formState.name, validationFormats.notEmpty),
        })
        break
      case 'cvr':
        formErrorDispatch({
          field,
          value: !validate(formState.cvr, validationFormats.notEmpty),
        })
        break
      default:
        break
    }
  }

  const onChange = (field) => (event) => {
    let value = event.target.value
    if (field === 'cvr') {
      formDispatch({ field: 'country', value: value.country?.toUpperCase() })
      value = value.id
    }

    formDispatch({ field, value })
  }

  const handleDefaultValidationErrors = (validationErrors) => {
    if (validationErrors != null) {
      const { message, name } = validationErrors?.error
      Sentry.captureException(message, {
        error: 'Unhandled error on register form',
        errorName: name,
        message,
      })
      enqueueSnackbar(<FormattedMessage id={intl.generic('error-reach-us-on-chat')} />, {
        variant: 'error',
      })
    }
  }

  const handleRegister = (event) => {
    event.preventDefault()

    const registerUser = {
      email: formState.email,
      name: formState.name,
      subscription_plan: 'TRIAL_V2',
      localId: {
        id: formState.cvr,
        country: formState.country,
      },
    }
    authRegister(registerUser)
      .then((data) => {
        enqueueSnackbar(
          <FormattedMessage id={intl.auth('forgot-password-email-title')} />,
          { variant: 'success' }
        )
        return history.push(navigation.auth.login())
      })
      .catch((error) => {
        switch (error?.response?.data?.code) {
          case 'company_already_exists':
            formErrorDispatch({ field: 'cvr', value: true })
            enqueueSnackbar(
              <FormattedMessage id={intl.auth('company-already-exists')} />,
              { variant: 'error' }
            )
            break
          case 'email_domain_not_allowed':
            formErrorDispatch({ field: 'email', value: true })
            enqueueSnackbar(<FormattedMessage id={intl.auth('please-use-work-mail')} />, {
              variant: 'error',
            })
            break
          case 'invalid_local_id':
            formErrorDispatch({ field: 'cvr', value: true })
            enqueueSnackbar(<FormattedMessage id={intl.auth('invalid-local-id')} />, {
              variant: 'error',
            })
            break
          default:
            console.log('default')
            handleDefaultValidationErrors(error?.response?.data)
        }
      })
  }

  if (accountHasBeenRegistered) {
    return (
      <Root>
        <CardStyled elevation={0}>
          <CardHeader
            title={
              <Title variant="h4">
                <FormattedMessage id={intl.auth('user-registered-title')} />
              </Title>
            }
            subheader={
              <Typography variant="body1">
                <FormattedMessage id={intl.auth('user-registered-subtitle')} />
              </Typography>
            }
          />
          <CardContent>
            <Link to={navigation.auth.login()}>
              <FormattedMessage id={intl.auth('go-back-to-log-in')} />
            </Link>
          </CardContent>
        </CardStyled>
      </Root>
    )
  }

  return (
    <Root>
      <ActionCard elevation={0}>
        <HeadlessLink to={navigation.auth.login()}>
          <CardActionArea>
            <ActionCardContent>
              <ActionTitle variant="h6">
                <FormattedMessage id={intl.auth('already-have-an-account')} />
              </ActionTitle>
              <ActionSubtitle>
                <FormattedMessage id={intl.auth('login-to-your-account')} />
              </ActionSubtitle>
            </ActionCardContent>
          </CardActionArea>
        </HeadlessLink>
      </ActionCard>
      <CardStyled elevation={0}>
        <CardHeader
          title={
            <Title variant="h4">
              <FormattedMessage id={intl.auth('register-header')} />
            </Title>
          }
        />
        <CardContent>
          <Form onSubmit={handleRegister}>
            <FormElement>
              <Input
                id="email"
                onChange={onChange('email')}
                onBlur={validateField('email')}
                value={formState.email}
                placeholder={reactIntl.formatMessage({
                  id: intl.auth('email-placeholder'),
                })}
                type="email"
                error={formErrorState.email}
                label={<FormattedMessage id={intl.auth('work-email-label')} />}
                autoFocus
              />
            </FormElement>
            <FormElement>
              <Input
                id="name"
                onChange={onChange('name')}
                onBlur={validateField('name')}
                value={formState.name}
                placeholder={reactIntl.formatMessage({
                  id: intl.auth('first-name-placeholder'),
                })}
                type="text"
                error={formErrorState.name}
                label={<FormattedMessage id={intl.companyHierarchy('name')} />}
              />
            </FormElement>
            <FormElement>
              <CompanySearch
                onChange={onChange('cvr')}
                onBlur={validateField('cvr')}
                error={formErrorState.cvr}
                id="cvr"
                label={<FormattedMessage id={intl.auth('company-label')} />}
                placeholder={<FormattedMessage id={intl.auth('company-placeholder')} />}
              />
            </FormElement>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptConditions}
                    onChange={() => {
                      setAcceptConditions((prevState) => !prevState)
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <SmallText>
                    <FormattedMessage id={intl.auth('understand-and-accept')} />{' '}
                    <StrongLink
                      href="https://risika.com/da/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id={intl.auth('terms-of-service')} />
                    </StrongLink>
                  </SmallText>
                }
              />
            </div>
            <RegisterButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={!formValidated}
            >
              <FormattedMessage id={intl.auth('register-account')} />
            </RegisterButton>
          </Form>
        </CardContent>
      </CardStyled>
    </Root>
  )
}

export default Register
