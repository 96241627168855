import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { classDialogTitle } from 'styles-new/global/components'
import {
  DialogSimplePropTypes,
  DialogSimpleButtonsTypes,
} from './DialogSimple.types'
/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'

const DialogSimple = ({
  open,
  onClose,
  title,
  content,
  buttons,
}: DialogSimplePropTypes) => {
  buttons = buttons ?? []

  return (
    <Dialog className="MUI5" onClose={onClose} open={open}>
      <DialogTitle sx={classDialogTitle}>{title}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" gutterBottom>
          {content}
        </Typography>
      </DialogContent>
      {buttons.length > 0 && (
        <DialogActions>
          {buttons.map((button: DialogSimpleButtonsTypes) => {
            if (!button.color) button.color = 'primary'
            if (!button.variant) button.variant = 'outlined'
            return (
              <Button
                key={button.caption}
                onClick={button.action}
                color={button.color}
                variant={button.variant}
              >
                {button.caption}
              </Button>
            )
          })}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default DialogSimple
