import { SxProps } from '@mui/system'

const menuStyles: SxProps = {
  ul: { maxHeight: 524 },
  '.MuiList-root': { paddingBottom: 0, position: 'relative' },
  '.MuiMenu-paper': { paddingBottom: '0 !important' },
}

const buttonContainer: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: 4,
  paddingRight: 4,
  paddingY: 3,
  backgroundColor: 'grey.50',
  borderTop: '1px solid',
  borderTopColor: 'grey.200',
  borderBottomLeftRadius: 1,
  borderBottomRightRadius: 1,
  position: 'sticky',
  bottom: 0,
  width: '100%',
  minWidth: 200,
}

const menuItem = { paddingX: 4, paddingY: 2 }
const listItem = { marginLeft: 2 }

export default { menuStyles, buttonContainer, menuItem, listItem }
