import * as React from 'react'
import { default as cx } from 'classnames'
import { useStyles } from '../styles/stack'

type StackProps = {
  children: any
  spacing?: number
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  className?: any
  style?: Object
  flex?: string
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  items?: 'normal' | 'stretch' | 'center' | 'flex-start' | 'flex-end'
  justify?:
    | 'normal'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
}

const Stack = React.forwardRef(
  (
    {
      className,
      style,
      children,
      direction = 'row',
      spacing = 0,
      flex = '0 1 auto',
      wrap = 'nowrap',
      items = 'normal',
      justify = 'normal',
      ...props
    }: StackProps,
    ref
  ) => {
    const classes = useStyles({
      spacing,
      direction,
      items,
      justify,
      flex,
      wrap,
    })

    return (
      // @ts-expect-error Phasing this out
      <div ref={ref} className={cx(classes.root, className)} style={style} {...props}>
        {children}
      </div>
    )
  }
)

export default Stack
