import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const main = (color: string): SxProps => {
  return {
    border: `1px solid`,
    borderLeft: `30px solid`,
    borderColor: color,
    borderRadius: theme.shape,
    padding: theme.spacing(2),
    position: 'relative',
  }
}

const middleIcon: SxProps = {
  color: theme.palette.common.white,
  width: theme.spacing(5),
  height: theme.spacing(5),
  position: 'absolute',
  top: '50%',
  left: '-15px',
  transform: 'translate(-50%, -50%)',
}

export default {
  main,
  middleIcon,
}
