import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'

import { TableRowTitle } from './TableRowTitle'
import { TableRowValue } from './TableRowValue'
import { animations } from 'utils/animate'

// type Props = {
//   classes: Object,
//   data: any,
//   label: any,
//   delay?: number,
//   transition?: string,
//   isDash?: boolean,
//   isSpace?: boolean,
// }

export const AnimatedTableRow = ({
  classes,
  data,
  label,
  delay = 200,
  transition = 'transition.fadeIn',
  isDash,
  isSpace,
}) => {
  const fadeIn = animations.fadeIn()

  return (
    <motion.tr {...fadeIn}>
      <TableRowTitle
        variant={isDash ? 'body2' : 'subtitle2'}
        isDash={isDash}
        isSpace={isSpace}
      >
        {!isSpace && label}
      </TableRowTitle>
      {isSpace
        ? data.map((x, i) => (
            <td style={{ textAlign: 'center' }} key={i}>
              <Typography
                style={{
                  fontFamily: 'Helvetica, Roboto, Arial, Sans-Serif',
                  letterSpacing: '0.1rem',
                }}
                variant="body2"
              >
                {' '}
                &zwnj;&zwnj;&zwnj;&zwnj;{' '}
              </Typography>
            </td>
          ))
        : data.map((val, i) => <TableRowValue key={i}>{val}</TableRowValue>)}
    </motion.tr>
  )
}
