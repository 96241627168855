import risikaAPI from 'services/api/helpers/risikaAPI'
import moment from 'moment'
import { Person } from 'risika-api-response-types/search'

/******************
 * Fetch from API *
 ******************/
export type PersonVisitsListParams = {
  from?: string
  to?: string
  sortBy?: 'count' | 'last_ts'
  min?: number
}

const response = {
  search_result: [
    {
      personal_id: '4008562483',
      country: 'DK',
      name: 'Tsvetomir Batinov',
      aliases: ['Tsvetomir Batinov'],
      functions: [
        {
          active: true,
          function: 'FOUNDER',
          title: 'Founder',
        },
        {
          active: false,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: ['RIEV ApS'],
      active_relations: [
        {
          name: 'RIEV ApS',
          local_organization_id: {
            id: '42832812',
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4007579683',
      country: 'DK',
      name: 'Tsvetomira Stefanova Kulicheva-Hansen',
      aliases: ['Tsvetomira Stefanova Kulicheva-Hansen'],
      functions: [
        {
          active: true,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: ['MK Bryllup'],
      active_relations: [
        {
          name: 'MK Bryllup',
          local_organization_id: {
            id: '39443325',
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4000674072',
      country: 'DK',
      name: 'Tsvetomir Stoychev Boyanov',
      aliases: ['Tsvetomir Stoychev Boyanov'],
      functions: [
        {
          active: false,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: [],
      active_relations: [
        {
          name: null,
          local_organization_id: {
            id: null,
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4000762576',
      country: 'DK',
      name: 'Tsvetomil Stefanov Mladenov',
      aliases: ['Tsvetomil Stefanov Mladenov'],
      functions: [
        {
          active: false,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: [],
      active_relations: [
        {
          name: null,
          local_organization_id: {
            id: null,
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4004174412',
      country: 'DK',
      name: 'Ilona Tsvetomirova Bonkovski',
      aliases: ['Ilona Tsvetomirova Bonkovski'],
      functions: [
        {
          active: false,
          function: 'FOUNDER',
          title: 'Founder',
        },
        {
          active: false,
          function: 'MANAGEMENT',
          title: 'Management',
        },
      ],
      active_company_relations: [],
      active_relations: [
        {
          name: null,
          local_organization_id: {
            id: null,
            country: 'DK',
          },
        },
      ],
    },
  ],
  count: 5,
}
export type PeopleVisitsListResponse = Person['search_result']
export type PeopleVisitResult = Person['search_result'][0]

async function peopleVisitsList({
  from = moment().subtract(30, 'days').format('YYYY-MM-DD'),
  to = moment().format('YYYY-MM-DD'),
  sortBy = 'last_ts',
  min = 5,
}: PersonVisitsListParams): Promise<PeopleVisitsListResponse> {
  const { data } = await risikaAPI.post(
    '/statistics/person_visits/list',
    {
      date_from: from,
      date_to: to,
      sort_by: sortBy,
      min_count: min,
    },
    {
      microservice: 'data',
    }
  )
  return data
}

export default peopleVisitsList
