import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Typography,
  Grid,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  useMediaQuery,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import InsightsIcon from '@mui/icons-material/Insights'
import Companies from './widgets/Companies'
import PortfolioInsights from 'widgets/integrations/PortfolioInsights'
import { HistoryHeader } from 'components'
import ListCreditScoreDoughnutChartBox from 'widgets/charts/ListCreditScoreDoughnutChartBox'
import ListCreditScoreByCompanyTypeBox from 'widgets/charts/ListCreditScoreByCompanyTypeBox'
import ListCreditScoreDistributionBox from 'widgets/charts/ListCreditScoreDistributionBox'
import ListCreditScoreByEmployeesBox from 'widgets/charts/ListCreditScoreByEmployeesBox'
import ListCreditScoreGeographicallyBox from 'widgets/charts/ListCreditScoreGeographicallyBox'
import ListCreditScoreByIndustryBox from 'widgets/charts/ListCreditScoreByIndustryBox'
import ListCreditScoreOverTimeBox from 'widgets/charts/ListCreditScoreOverTimeBox'
import ListAveragesBox from 'widgets/info/ListAveragesBox'
import ListKeyFiguresByCompanyTypeBox from 'widgets/charts/ListKeyFiguresByCompanyTypeBox'
import ListKeyFiguresByIndustryBox from 'widgets/charts/ListKeyFiguresByIndustryBox'
import DrawerTriggerButton from 'components-new/layouts/SideDrawer/elements/DrawerTriggerButton'
import { useAccountStatus, useMonitorShowList } from 'services/queries'
import { useMonitoringStatistic } from 'services/queries'
import {
  automatorComplianceInitialFilters,
  initialFilters,
  simpleComplianceInitialFilters,
  tabsDefinition,
} from './ObservationalListsPage.model'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { classes } from './styles'
import { useTheme } from '@mui/material/styles'
import ListKeyFiguresDevelopmentBox from 'widgets/charts/ListKeyFiguresDevelopmentBox'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import lodash from 'lodash'
import {
  createInitialFilters,
  countActiveFilters,
  adjustFilter,
  resetFilter,
} from './ObservationalListsPage.controller'
import { CreditAutomatorTableProvider } from 'pages/risk-monitoring/widgets/CreditAutomatorMonitoringTable/context'
import { Monitor } from 'risika-api-response-types'
import { TabContext, TabPanel } from '@mui/lab'
import { usePrevious } from 'services/hooks/usePrevious'
import { usePolicyViolations } from 'services/queries/usePolicyViolations'

const CREDIT_POLICY = 'credit_policy'
const CREDIT_AUTOMATOR = 'credit_automator'

const ObservationalListsPage = () => {
  const { listId } = useParams<{ listId?: string }>() ?? null
  const reactIntl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [drawerIsOpen, setDrawerIsOpen] = useState(isMobile)
  const [tabValue, setTabValue] = useState('0')
  const [listName, setListName] = useState('')
  const [tableFilters, setTableFilters] = useState({})

  const monitorShowListQuery = useMonitorShowList()
  const accountStatusQuery = useAccountStatus()
  const pointsOfAttentionQuery =
    useMonitoringStatistic<Monitor.ListStatisticsAggregatedHighlights>({
      parameters: { list_id: Number(listId) },
      endpoint: 'aggregated_highlights',
      scoreModel: false,
    })
  const pointsOfAttention = pointsOfAttentionQuery?.data
  const { data: violations } = usePolicyViolations()

  const monitoringStatisticQuery =
    useMonitoringStatistic<Monitor.ListStatisticsCompliance>({
      parameters: { list_id: Number(listId) },
      endpoint: 'compliance',
      staleTime: 6000,
    })

  const prevMonitorStatisticQuery = usePrevious({
    isSuccess: monitoringStatisticQuery.isSuccess,
  })

  const creditPolicy = accountStatusQuery?.data?.rights?.global?.enterprise_credit_policy
    ? CREDIT_AUTOMATOR
    : CREDIT_POLICY

  useEffect(() => {
    if (!monitorShowListQuery.isLoading && !monitorShowListQuery.isError) {
      const name = monitorShowListQuery.data?.reduce(
        (accumulator: string, dataItem: { list_id: number; name: string }) => {
          if (dataItem.list_id === Number(listId)) {
            return dataItem.name
          }
          return accumulator
        },
        ''
      )
      setListName(' - ' + name)
    }
  }, [
    listId,
    monitorShowListQuery.data,
    monitorShowListQuery.isError,
    monitorShowListQuery.isLoading,
  ])

  useEffect(() => {
    if (pointsOfAttentionQuery?.isSuccess && !!creditPolicy) {
      if (
        lodash.isEmpty(tableFilters) ||
        prevMonitorStatisticQuery?.isSuccess !== monitoringStatisticQuery.isSuccess // prevents infinite loop
      ) {
        // credit policy may be loading so filters need to be reset

        const initialFilters = createInitialFilters(
          violations?.policy_violations,
          pointsOfAttention,
          creditPolicy === CREDIT_POLICY && monitoringStatisticQuery.isSuccess
        )

        if (creditPolicy === CREDIT_POLICY) {
          setTableFilters({
            ...initialFilters,
            [apiKeys.compliance]: simpleComplianceInitialFilters,
          })
        } else {
          setTableFilters({
            ...initialFilters,
            [apiKeys.creditAutomatorResult]: automatorComplianceInitialFilters,
          })
        }
      }
    }
  }, [
    creditPolicy,
    monitoringStatisticQuery.isSuccess,
    tableFilters,
    prevMonitorStatisticQuery?.isSuccess,
    pointsOfAttention,
    violations?.policy_violations,
    pointsOfAttentionQuery?.isSuccess,
  ])

  useEffect(() => {
    if (isMobile && !drawerIsOpen) setDrawerIsOpen(true)
  }, [drawerIsOpen, isMobile])

  const handleTabChange = (newTabValue: string) => {
    setTabValue(newTabValue)
  }

  const handleSearch = (searchTerm: string) => {
    const nextFilterState: any = lodash.cloneDeep(tableFilters)
    nextFilterState[apiKeys.freeSearch] = searchTerm
    setTableFilters(nextFilterState)
  }

  const handleFilters = (category: string, key: string) => {
    const nextFilterState: any = lodash.cloneDeep(tableFilters)
    // checkedValue is opposite of field current value
    let checkedValue = !nextFilterState[category][key].checked
    // But if there is only one checked value, user is not allowed to uncheck it
    if (
      (category === apiKeys.compliance || category === apiKeys.creditScore) &&
      countActiveFilters(nextFilterState[category]) <= 1
    ) {
      checkedValue = true
    }
    nextFilterState[category][key].checked = checkedValue
    if (creditPolicy === CREDIT_POLICY) {
      setTableFilters(adjustFilter(nextFilterState, category))
    } else {
      setTableFilters(nextFilterState)
    }
  }

  const handlePortfolioInsightsFilters = (
    category: string,
    key: string | [] | boolean
  ) => {
    if (typeof key === 'boolean') {
      key = key === true ? apiKeys.compliant : apiKeys.nonCompliant
    }
    const nextFilterState: any = lodash.cloneDeep(tableFilters)
    // Reset all filters [START]
    const filterKeys = Object.keys(initialFilters)
    filterKeys.forEach((filterKey) => {
      let filterValue = false
      if (filterKey === apiKeys.compliance || filterKey === apiKeys.creditScore) {
        filterValue = category !== filterKey
      }
      nextFilterState[filterKey] =
        filterKey === apiKeys.freeSearch
          ? ''
          : resetFilter(nextFilterState[filterKey], filterValue)
    })
    // Reset all filters [END]
    nextFilterState[category][key as string].checked = true
    if (category === apiKeys.violations) {
      nextFilterState[apiKeys.compliance][apiKeys.compliant].checked = false
    }
    setTabValue('2')
    setTableFilters(nextFilterState)
  }

  if (!listId) {
    return (
      <Box sx={classes.observationalListNoIdError}>
        <InfoIcon sx={{ fontSize: '6rem' }} />
        <Typography variant="h5">
          <FormattedMessage id={intl.observationalLists('list-id-error')} />
        </Typography>
      </Box>
    )
  }

  return (
    <CreditAutomatorTableProvider>
      <Box sx={classes.observationalLists}>
        {/*
        This box is to hide the whole page, except the Portfolio Insights side drawer,
        when opened on mobile
      */}
        <Box style={{ display: isMobile ? 'none' : 'block' }}>
          <DrawerTriggerButton
            visible={!drawerIsOpen}
            onClick={() => setDrawerIsOpen(!drawerIsOpen)}
            variant="default"
            title={
              reactIntl.messages[
                intl.portfolioInsights('drawer-trigger-button')
              ] as string
            }
            icon={(style) => <InsightsIcon sx={style} />}
          />
          <Box mb={1}>
            <HistoryHeader
              title={`${
                reactIntl.messages[intl.observationalLists('title')]
              } ${listName}`}
              historyKey={'observational-lists'}
              location="observational-lists"
            />
          </Box>
          <TabContext value={tabValue}>
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static" sx={classes.observationalListsTabs}>
                <Toolbar style={{ minHeight: 'auto' }}>
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={classes.observationalListsTabContainer}
                  >
                    {tabsDefinition.map((tab) => {
                      return (
                        <Tab
                          data-cy={`ri-tab-${tab.value}`}
                          icon={<tab.Icon />}
                          iconPosition="start"
                          key={tab.value}
                          label={<FormattedMessage id={tab.title} />}
                          onClick={() => handleTabChange(tab.value.toString())}
                          sx={{ textTransform: 'none' }}
                          style={{
                            borderBottom: +tabValue === tab.value ? '2px solid' : '',
                          }}
                        />
                      )
                    })}
                  </Tabs>
                </Toolbar>
              </AppBar>
            </Box>
            <TabPanel value={'0'}>
              <Box m={4}>
                <Grid container spacing={3}>
                  <Grid item xs={6} lg={4}>
                    <ListCreditScoreDoughnutChartBox listId={listId} />
                  </Grid>
                  <Grid item xs={6} lg={8}>
                    <Box sx={{ display: { xs: 'none', lg: 'block' }, height: '100%' }}>
                      <ListCreditScoreByCompanyTypeBox listId={listId} />
                    </Box>
                    <Box sx={{ display: { xs: 'block', lg: 'none' }, height: '100%' }}>
                      <ListCreditScoreDistributionBox listId={listId} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                      <ListCreditScoreByCompanyTypeBox listId={listId} />
                    </Box>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                      <ListCreditScoreDistributionBox listId={listId} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <ListCreditScoreByEmployeesBox listId={listId} />
                  </Grid>
                  <Grid item xs={12}>
                    <ListCreditScoreGeographicallyBox listId={listId} />
                  </Grid>
                  <Grid item xs={12}>
                    <ListCreditScoreByIndustryBox listId={listId} />
                  </Grid>
                  <Grid item xs={12}>
                    <ListCreditScoreOverTimeBox listId={listId} />
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={'1'}>
              <Box m={4}>
                <Grid container spacing={3}>
                  <ListAveragesBox listId={listId} />
                  <Grid item xs={12}>
                    <ListKeyFiguresByCompanyTypeBox listId={listId} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <ListKeyFiguresByIndustryBox listId={listId} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ListKeyFiguresDevelopmentBox listId={listId} />
                  </Grid>
                  <Grid item xs={12}>
                    {/* temporarily removed until we can render accurate information within this graph */}
                    {/* <ListPolicyComplianceDevelopmentBox listId={listId} /> */}
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={'2'}>
              <Box m={4}>
                <Companies
                  listId={listId}
                  tableFilters={tableFilters}
                  isLoading={monitoringStatisticQuery.isLoading}
                  handleFilters={handleFilters}
                  handleSearch={handleSearch}
                  creditPolicy={creditPolicy}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        <PortfolioInsights
          listId={listId}
          drawerState={{ drawerIsOpen, setDrawerIsOpen }}
          handlePortfolioInsightsFilters={handlePortfolioInsightsFilters}
        />
      </Box>
    </CreditAutomatorTableProvider>
  )
}

export default ObservationalListsPage
