import React, { ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionDialog } from 'components'
import { setText, submitFetchListEntriesById } from 'store_deprecated'
import createOrUpdateNotes from 'services/api/endpoints/risikaAPI/notes/resources/createOrUpdateNotes'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import ImportCompaniesFromFile, { Company } from './ImportCompaniesFromFile'
import useTotangoTracking from 'utils/totangoTracking'
import { ReduxRootState } from 'store_deprecated/types'
import { useUpdateLists } from 'services/queries/lists/useUpdateLists'
import { useSnackbar } from 'notistack'
import { useMonitorShowList } from 'services/queries'

type ImportCompaniesProps = {
  children: (open: boolean) => ReactNode
  refetch: () => void
}

const ImportCompanies = React.forwardRef(
  ({ children, refetch }: ImportCompaniesProps) => {
    const dispatch = useDispatch()
    const { trackEvent } = useTotangoTracking()
    const { enqueueSnackbar } = useSnackbar()
    const [internalIds, setInternalIds] = useState<
      { internalId: string; localId: string }[]
    >([])

    const selectedListId = useSelector(
      (state: ReduxRootState) => state.risika.newRiskMonitoring.selectedList
    )

    const { data: listsData } = useMonitorShowList()

    const selectedList = listsData?.find((list) => list.list_id === selectedListId)

    const onCompanyAddError = () => {
      return enqueueSnackbar(
        <FormattedMessage id={intl.snackbar('report-error-something-wrong')} />,
        {
          variant: 'error',
        }
      )
    }

    const onCompanyAddSuccess = () => {
      dispatch(submitFetchListEntriesById(selectedListId))
      refetch?.()
      enqueueSnackbar(<FormattedMessage id={intl.snackbar('companies-added')} />, {
        variant: 'success',
      })
    }

    const { updateLists } = useUpdateLists({
      onError: onCompanyAddError,
      onSuccess: onCompanyAddSuccess,
    })

    const [importValues, setImportValues] = useState<Company[]>([])

    const setValues = (
      importIdList: Company[],
      internalIdList: { internalId: string; localId: string }[]
    ) => {
      setInternalIds(internalIdList)
      setImportValues(importIdList)
    }

    function importToList() {
      trackEvent('Monitoring', 'Import List')
      updateLists({ list_id: selectedListId, local_ids: importValues })

      if (internalIds.length > 0) {
        const notesOfInternalIds = internalIds
          .filter(({ internalId }) => internalId != null)
          // .filter((item) => item.localId?.id)
          .map(({ localId, internalId }) => ({
            data: {
              _internal_id: internalId === '' ? null : internalId,
            },
            localId,
          }))

        createOrUpdateNotes(notesOfInternalIds)
      }

      setImportValues([])
      dispatch(setText(''))
    }

    const handleOnClose = () => {
      setInternalIds([])
      setImportValues([])
    }
    return (
      <ActionDialog
        disabled={!importValues.length}
        title={
          <FormattedMessage id={intl.riskMonitoringNew('add-to-monitoring-list-title')} />
        }
        content={() => (
          <ImportCompaniesFromFile
            listName={selectedList?.name}
            onSuccess={setValues}
            importValues={importValues}
            setImportValues={setImportValues}
          />
        )}
        action={importToList}
        maxWidth="sm"
        fullWidth={true}
        render={(open: boolean) => children?.(open)}
        agreeText={<FormattedMessage id={intl.riskMonitoringNew('add')} />}
        onClose={handleOnClose}
      />
    )
  }
)

export default ImportCompanies
