import { colors } from 'configs'

type ProvidedProps = Record<string, unknown>

export const customStyles = {
  control: (provided: ProvidedProps, state: { isFocused: boolean }) => {
    return {
      ...provided,
      borderColor: 'gray',
      // minHeight: '3rem',
      fontSize: '1.5rem',
      color: 'black',
      padding: '10px',
      boxShadow: state.isFocused ? '0 0 0 1px black' : null,
      '&:hover': {
        boxShadow: '0 0 0 0.3px black',
        borderColor: 'black',
      },
    }
  },
  indicatorSeparator: (provided: ProvidedProps) => ({
    ...provided,
    display: 'none',
  }),
  option: (provided: ProvidedProps, state: Record<string, Record<string, string>>) => {
    return {
      ...provided,
      borderBottom: '1px dotted gray',
      color: colors?.risika,
      backgroundColor: state.isFocused ? colors?.risikaContrast : 'white',
      '&:active': {
        backgroundColor: '#CCA36D',
      },
    }
  },
  menu: (provided: ProvidedProps) => {
    return {
      ...provided,
      zIndex: '999',
      top: '4.5rem',
    }
  },
  menuList: (provided: ProvidedProps) => ({
    ...provided,
    paddingTop: 0,
  }),
  valueContainer: (
    provided: ProvidedProps,
    state: Record<string, Record<string, string>>
  ) => {
    return {
      ...provided,
      maxHeight: '20.5rem',
      padding: 0,
      overflowY: state?.selectProps?.value?.length > 10 ? 'scroll' : 'none',
    }
  },
  multiValue: (provided: ProvidedProps) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors?.risikaContrast,
    borderRadius: '99rem',
    fontSize: '1.5rem',
    'div:last-child': {
      borderRadius: '99rem',
      borderBottomLeftRadius: '0rem',
      borderTopLeftRadius: '0rem',
      '&:hover': {
        backgroundColor: '#CCA36D',
      },
    },
    svg: {
      width: '2rem',
      backgroundColor: 'gray',
      height: '2rem',
      margin: '0.5rem',
      border: '3px solid gray',
      borderRadius: '99rem',
      color: 'white',
    },
  }),
}
