import React, { useMemo, useState } from 'react'
import { Company } from './ImportCompaniesFromFile'
import { ButtonSecondary, DropdownSelect } from 'components-new'
import SelectCompanyDropDown from './SelectCompanyDropDown'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import classes from './ImportCompanies.styles'

type SelectCountryForCompanyProps = {
  data: Record<number, Company[]>
  tempCompanyOptions: Record<number, string>
  setTempCompanyOptions: React.Dispatch<React.SetStateAction<Record<number, string>>>
  onConfirmSelection: () => void
}

const OPTIONS = [
  { label: 'All Finland', value: 'Finland', flag: 'risika/flags/fi_FI.png' },
  { label: 'All Denmark', value: 'Denmark', flag: 'risika/flags/da_DK@2x.png' },
]

const getCountryFromLabel = (label: string) => {
  const country = label === 'Finland' ? 'FI' : 'DK'
  return country
}

const SelectCountryForCompany = ({
  data,
  tempCompanyOptions,
  setTempCompanyOptions,
  onConfirmSelection,
}: SelectCountryForCompanyProps) => {
  const [selectAllOption] = useState<{
    label: string
    value: string
  }>()
  const handleDropdownOptions = (label: string, companyId: number) => {
    const country = getCountryFromLabel(label)
    const updatedOptions = { ...tempCompanyOptions, [companyId]: country }
    setTempCompanyOptions(updatedOptions)
  }

  const memoisedData = useMemo(() => {
    return data
  }, [data])

  const companyKeys = Object.keys(memoisedData)
  const handleSelectAll = (value: string) => {
    const country = getCountryFromLabel(value)
    const updatedData = Object.keys(tempCompanyOptions).reduce((acc, curr) => {
      acc = { ...acc, [curr]: country }
      return acc
    }, {} as Record<number, string>)
    setTempCompanyOptions(updatedData)
  }

  // divs used here to avoid MUI Box issues
  // https://github.com/mui/material-ui/issues/21797

  return (
    <div style={classes.selectContainer}>
      <div style={classes.primaryDropdown}>
        <DropdownSelect<any>
          options={OPTIONS}
          value={selectAllOption?.label}
          setValue={(value) => handleSelectAll(value)}
        />
      </div>

      {companyKeys.map((companyId, index) => {
        const companyPair = memoisedData[+companyId]
        const tempCompanyOption = tempCompanyOptions[+companyId]

        return (
          <div key={`${companyId}-${index}`}>
            <SelectCompanyDropDown
              companyPair={companyPair}
              companyId={+companyId}
              selectedOption={tempCompanyOption}
              setValue={handleDropdownOptions}
              selectAllOption={selectAllOption}
            />
          </div>
        )
      })}
      <div>
        {!!Object.keys(data).length && (
          <ButtonSecondary
            onClick={onConfirmSelection}
            data-cy="confirm-selection-button"
          >
            <FormattedMessage id={intl.riskMonitoringNew('confirm-selection')} />
          </ButtonSecondary>
        )}
      </div>
    </div>
  )
}

export default SelectCountryForCompany
