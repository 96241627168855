import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import IntlTypography from 'components-new/elements/IntlTypography'
import { AvailableLanguages } from 'globalTypes'

type Props = {
  subscriptionLang: AvailableLanguages
  setSubscriptionLang: (lang: AvailableLanguages) => void
}

const LanguageRadioButton = ({ subscriptionLang, setSubscriptionLang }: Props) => {
  return (
    <FormControl>
      <IntlTypography
        variant="subtitle1"
        group="riskMonitoringNew"
        labelKey="language-choice"
      />
      <RadioGroup
        value={subscriptionLang}
        onChange={(e) => setSubscriptionLang(e.target.value as AvailableLanguages)}
        data-cy="lang-radio-group"
      >
        <FormControlLabel
          value="da_DK"
          control={<Radio />}
          label={<IntlTypography labelKey="Danish" />}
        />
        <FormControlLabel
          value="en_UK"
          control={<Radio />}
          label={<IntlTypography labelKey="English" />}
        />
        <FormControlLabel
          value="nb_NO"
          control={<Radio />}
          label={<IntlTypography labelKey="Norwegian" />}
        />
        <FormControlLabel
          value="sv_SE"
          control={<Radio />}
          label={<IntlTypography labelKey="swedish" />}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default LanguageRadioButton
