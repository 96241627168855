import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Rectangle,
} from 'recharts'
import { theme } from 'styles-new/mui5-transition/theme'
import { SimpleChartTooltipRaw, SimpleChartTooltipCreditScore } from '../customizations'
import { VerticalBarChartPropsType } from '../types'

const VerticalBarChart = ({ data, chartProperties }: VerticalBarChartPropsType) => {
  if (!data || !data.length) return null

  const barKey = Object.keys(data[0])
    .filter((key) => key !== 'name')
    .toString()

  const totalSum = data.reduce(
    (accumulator: number, item: { value: number }) => accumulator + item.value,
    0
  )

  const ProgressBarOverlay = (props: any) => {
    const { width, points } = props
    return (
      <Rectangle
        radius={[5, 5, 5, 5]}
        fill={theme.palette.grey[200]}
        x={points[0].x}
        y={points[0].y - 6}
        width={width}
        height={chartProperties.bar.barSize + 0.5}
      />
    )
  }

  // This should prevent ReCharts bug of not displaying top labels
  // by preventing the animation of the chart
  // https://github.com/recharts/recharts/issues/829
  const animationActive = !chartProperties.bar.label
  chartProperties.tooltipWithPercent = true
  return (
    <ResponsiveContainer height={chartProperties.height} width="100%">
      <ComposedChart
        layout="vertical"
        height={chartProperties.height}
        data={data}
        margin={chartProperties.margin}
      >
        <XAxis
          hide={chartProperties.xAxis.hide}
          dataKey={chartProperties.xAxis.dataKey}
          type={chartProperties.xAxis.type}
          width={chartProperties.xAxis.width}
          axisLine={chartProperties.xAxis.axisLine}
          tickLine={chartProperties.xAxis.tickLine}
        />
        <YAxis
          hide={chartProperties.yAxis.hide}
          dataKey={chartProperties.yAxis.dataKey}
          type={chartProperties.yAxis.type}
          width={chartProperties.yAxis.width + 2}
          axisLine={chartProperties.yAxis.axisLine}
          tickLine={chartProperties.yAxis.tickLine}
        />
        {chartProperties.tooltipWithPercent &&
          chartProperties.valueType === 'CREDIT_SCORE' && (
            <Tooltip
              cursor={chartProperties.tooltipProgressBar && <ProgressBarOverlay />}
              content={<SimpleChartTooltipCreditScore totalSum={totalSum} />}
            />
          )}
        {chartProperties.tooltipWithPercent &&
          chartProperties.valueType !== 'CREDIT_SCORE' && (
            <Tooltip
              cursor={chartProperties.tooltipProgressBar && <ProgressBarOverlay />}
              content={<SimpleChartTooltipRaw totalSum={totalSum} />}
            />
          )}
        {chartProperties.tooltip && !chartProperties.tooltipWithPercent ? (
          <Tooltip />
        ) : null}
        <Bar
          dataKey={barKey}
          label={chartProperties.bar.label}
          barSize={chartProperties.bar.barSize}
          radius={chartProperties.bar.radius}
          isAnimationActive={animationActive /* ReCharts bug prevention */}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={chartProperties.colors[index]} />
          ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default VerticalBarChart
