import { SxProps } from '@mui/system'

export const risikaBoxShadow: SxProps = {
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
}

export const tabButtons = {
  fontSize: '1.4rem',
  fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: '0.00938em',
}
