import { APIPolicyEdge, APIPolicyNode } from 'types/queries'
import { CustomEdge } from './Edges'
import { Plus, Rule, Action } from './Nodes'
import { ApiVariablesOutcomes, ApiVariablesRules } from './PolicyBuilder.types'

// ** actions
export const REJECT = 'reject'
// export const MANUAL_CHECK = 'manual_check'
export const APPROVE_CREDIT_MAX = 'approve_credit_max'
export const ADJUST_CREDIT_MAX = 'adjust_credit_max'
export const APPROVE_WITH_VALUE = 'approve_with_value'

// helper
export const DELETE_RULE = 'delete-rule'

// Categories
export const COMPANY_INFORMATION = 'company_information'
export const RISK_INDICATORS = 'risk_indicators'
export const FRAUD_INDICATORS = 'fraud_indicators'
export const PEOPLE_AND_COMPANY_SEARCH = 'people_and_company_search'
export const FINANCIAL_INDICATORS = 'financial_indicators'
export const OUTCOME = 'outcome'
export const COMPANY_VAT = 'company_vat'

// Rules
// Company information
export const COMPANY_TYPE = 'company_type'
export const AGE = 'age'
export const COMPANY_LIST = 'local_id_in_monitoring_list'
export const NEW_MONITORING_LIST = 'new_monitoring_list'
export const NUMBER_OF_EMPLOYEES = 'employees_number'
export const INDUSTRY_TYPE = 'industry_code'
// Risk indicators
export const RISIKA_SCORE = 'risika_score'
export const AUDIT = 'audit_selected'
export const COMPANY_STATUS = 'status'

// Fraud indicators
export const DISTANCE_TO_CEO = 'distance_to_ceo'
export const PREPAID_PHONE = 'prepaid_phone'

// People and company search
export const PERSON_SEARCH = 'entity_id'
export const COMPANY_SEARCH = 'local_id'

// Financial indicators
export const EQUITY = 'equity'
export const CONNECTED_BANKRUPTCIES_IN_THE_LAST_10_YEARS = 'bankrupt_connections'
export const CONNECTED_BANKRUPTCIES_IN_THE_LAST_50_YEARS = 'bankrupt_connections_long'
export const EQUITY_WITHOUT_DIVIDEND = 'equity_without_dividend'
export const EBITDA = 'ebitda'
export const EBIT = 'ebit'

// Payment history
export const PAYMENT_REMARKS = 'payment_remarks'
export const PAYMENT_REMARKS_TOTAL_AMOUNT = 'payment_remark_total_amounts'
export const INVOICE_DATA = 'invoice_data'

// Advanced
export const DEBT_TO_EQUITY_RATIO = 'debt_to_equity_ratio'
export const SOLIDITY_RATIO = 'solidity_ratio'
export const RETURN_ON_ASSETS = 'return_on_assets'
export const RETURN_TO_EQUITY = 'return_on_equity'
export const ASSET_TURNOVER = 'asset_turnover'

// Outcome
export const APPROVE_CREDIT = 'approve'
export const MANUAL_CHECK = 'manual_check'
export const REJECT_CREDIT = 'reject'

// Misc
export const INDUSTRY_CODE = 'industry_code'
export const AUDIT_SELECTED = 'audit_selected'
export const EMPLOYEES_INTERVAl = 'employees_interval'
export const LOCAL_ID_IN_MONITORING_LIST = 'local_id_in_monitoring_list'
export const LOCAL_ID = 'local_id'
export const RISK_ASSESSMENT = 'risk_assessment'
export const CREDIT_RATING = 'credit_rating'
export const nodeTypes = {
  plus: Plus,
  rule: Rule,
  action: Action,
}

export const edgeTypes = {
  // Possibly in the future we can have different edges ef necessary
  if_true: CustomEdge,
  if_false: CustomEdge,
}

// Variants
export const DROPDOWN_MULTI_SELECT = 'dropdown_multi_select'
export const TOLERANCE_INPUT = 'tolerance_input'
export const RADIO_BUTTON = 'radio_button'
export const MULTI_SELECT = 'multi_select'
export const TOLERANCE_DROPDOWN = 'tolerance_dropdown'
export const ACTION_TEMPLATE = 'action_template'
export const BOOLEAN = 'boolean'

export const COMPANY_INFORMATION_CATEGORY = 'Company Information'
export const RISK_INDICATORS_CATEGORY = 'Risk Indicators'
export const FRAUD_INDICATORS_CATEGORY = 'Fraud Indicators'
export const FINANCIAL_INDICATORS_CATEGORY = 'Financial Indicators'
export const PEOPLE_AND_COMPANY_SEARCH_CATEGORY = 'People And Company Search'
export const OUTCOME_CATEGORY = 'Outcome'

export const onlyActiveCompaniesNodes = [
  {
    id: '1',
    node_type: 'rule',
    condition: {
      category: 'is_not_active',
      equals: true,
    },
  },
  {
    id: '2',
    node_type: 'action',
    action: {
      outcome: REJECT_CREDIT,
    },
  },
  {
    id: '3',
    node_type: 'plus',
    action: {},
  },
] as APIPolicyNode[]

export const onlyActiveCompaniesEdges = [
  {
    relation_type: 'if_true',
    source_id: '1',
    target_id: '2',
  },
  {
    relation_type: 'if_false',
    source_id: '1',
    target_id: '3',
  },
] as APIPolicyEdge[]

export const onlyActiveCompaniesStructure = {
  nodes: onlyActiveCompaniesNodes,
  edges: onlyActiveCompaniesEdges,
}

export const availableVariables = [
  {
    name: COMPANY_TYPE,
    type: 'rule',
    category: COMPANY_INFORMATION,
    api_variable: [COMPANY_TYPE],
  },
  {
    name: INDUSTRY_TYPE,
    type: 'rule',
    category: COMPANY_INFORMATION,
    api_variable: [INDUSTRY_TYPE],
  },
  {
    name: NUMBER_OF_EMPLOYEES,
    type: 'rule',
    category: COMPANY_INFORMATION,
    api_variable: [NUMBER_OF_EMPLOYEES],
  },
  {
    name: CREDIT_RATING,
    type: 'rule',
    category: RISK_INDICATORS,
    api_variable: [RISIKA_SCORE, RISK_ASSESSMENT],
  },
  {
    name: AUDIT,
    type: 'rule',
    category: RISK_INDICATORS,
    api_variable: [AUDIT],
  },
  {
    name: COMPANY_STATUS,
    type: 'rule',
    category: RISK_INDICATORS,
    api_variable: [COMPANY_STATUS],
  },
  {
    name: DISTANCE_TO_CEO,
    type: 'rule',
    category: FRAUD_INDICATORS,
    api_variable: [DISTANCE_TO_CEO],
  },
  {
    name: PREPAID_PHONE,
    type: 'rule',
    category: FRAUD_INDICATORS,
    api_variable: [PREPAID_PHONE],
  },
  {
    name: EQUITY,
    type: 'rule',
    category: FINANCIAL_INDICATORS,
    api_variable: [EQUITY, EQUITY_WITHOUT_DIVIDEND],
  },
  {
    name: COMPANY_LIST,
    type: 'rule',
    category: PEOPLE_AND_COMPANY_SEARCH,
    api_variable: [COMPANY_LIST],
  },
  {
    name: APPROVE_CREDIT,
    type: 'outcome',
    category: OUTCOME,
    api_variable: [APPROVE_WITH_VALUE, ADJUST_CREDIT_MAX, APPROVE_CREDIT_MAX],
  },
  {
    name: MANUAL_CHECK,
    type: 'outcome',
    category: OUTCOME,
    api_variable: [MANUAL_CHECK],
  },
  {
    name: REJECT_CREDIT,
    type: 'outcome',
    category: OUTCOME,
    api_variable: [REJECT_CREDIT],
  },
] as const

export const apiRules = availableVariables.reduce<ApiVariablesRules[]>((acc, curr) => {
  const { type, api_variable } = curr
  if (type === 'rule') {
    return [...acc, ...api_variable] as ApiVariablesRules[]
  }
  return acc
}, [])

export const apiOutcomes = availableVariables.reduce<ApiVariablesOutcomes[]>(
  (acc, curr) => {
    const { type, api_variable } = curr
    if (type === 'outcome') {
      return [...acc, ...api_variable] as ApiVariablesOutcomes[]
    }
    return acc
  },
  []
)
export const apiVariables = [...apiRules, ...apiOutcomes]

export const sidePanelVariables = availableVariables.map((x) => x.name)

export const actionPanelCategoryOrder = [
  COMPANY_INFORMATION,
  RISK_INDICATORS,
  FRAUD_INDICATORS,
  FINANCIAL_INDICATORS,
  PEOPLE_AND_COMPANY_SEARCH,
  // PAYMENT_HISTORY,
  // ADVANCED,
  OUTCOME,
] as const

export const actionPanelVariablesWithCategories = actionPanelCategoryOrder.map(
  (category) => ({
    category,
    rules: availableVariables
      .filter((x) => x.category === category)
      .map((x) => x.name)
      .sort((a, b) => a.localeCompare(b)),
  })
)
export const setOfRules = new Set([
  ...availableVariables.filter((x) => x.type === 'rule').map((x) => x.name),
  ...apiRules,
])
export const allRules = Array.from(setOfRules)
export const setOfOutcomes = new Set([
  ...availableVariables.filter((x) => x.type === 'outcome').map((x) => x.name),
  ...apiOutcomes,
])
export const allOutcomes = Array.from(setOfOutcomes)
