import { Box } from '@mui/material'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'

export const ErrorOccurred = () => {
  return (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <GppMaybeIcon sx={{ fontSize: '6rem' }} color="warning" />
    </Box>
  )
}

export default ErrorOccurred
