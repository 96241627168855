import { useState, useEffect } from 'react'

import { Box, Stack, Paper, Typography, Grid } from '@mui/material'

import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import SimpleLineChart from 'components-new/charts/SimpleLineChart'

import { areQueriesLoading } from './KeyFiguresLineChartBox.controller'
import { setUpChartData } from './KeyFiguresLineChartBox.controller'
import { normalizeScoresData } from './KeyFiguresLineChartBox.controller'
import { getCompanyNames } from './KeyFiguresLineChartBox.controller'
import { reactQueries } from './KeyFiguresLineChartBox.model'
import { queryParameters } from './KeyFiguresLineChartBox.model'
import { chartPropsDefinition } from './KeyFiguresLineChartBox.model'
import { lineChartButtons } from './KeyFiguresLineChartBox.model'

import { useQueries } from 'react-query'

import { useGlobalStyles } from 'styles/global.js'
import { ButtonTertiary, ButtonSecondary } from 'components-new'

export const buttonStyles = {
  '&:focus': {
    border: 'none',
  },
  '&:active': {
    border: 'none',
  },
}

const KeyFiguresLineChartBox = (props) => {
  const reactIntl = useIntl()
  const [chartData, setChartData] = useState([])
  const [chartProperties, setChartProperties] = useState(chartPropsDefinition)
  const [companyNames, setCompanyNames] = useState([])
  const [query, setQuery] = useState(queryParameters.solidityRatio)
  const globalClasses = useGlobalStyles()

  const buttons = lineChartButtons.map((button) => {
    return {
      ...button,
      label: reactIntl.messages[button.label],
      key: queryParameters[button.queryParameter].key,
    }
  })

  const queries = ({ type, action }) => {
    return props.companies.map((localId) => {
      return {
        queryKey: [type, localId.id, localId.country],
        queryFn: () => action(localId),
        staleTime: 60000,
      }
    })
  }

  const queriesResult = useQueries(queries(query))
  const queriesResultCompanyBasics = useQueries(
    queries(reactQueries.companyBasics, { staleTime: 60000 })
  )

  const setNewQuery = (newQuery) => {
    const newChartProperties = JSON.parse(JSON.stringify(chartProperties))
    newChartProperties.domain = newQuery.domain
    newChartProperties.chartType = newQuery.chartType
    setChartProperties(newChartProperties)
    setQuery(queryParameters[newQuery.queryParameter])
  }

  useEffect(() => {
    if (
      !areQueriesLoading(queriesResult) &&
      !areQueriesLoading(queriesResultCompanyBasics)
    ) {
      let result = queriesResult
      if (query.key === 'score') {
        result = normalizeScoresData(queriesResult)
      }
      const newChartData = setUpChartData({
        result,
        key: query.key,
        chartProperties,
        companyNames,
      })
      if (JSON.stringify(chartData) !== JSON.stringify(newChartData)) {
        setChartData(newChartData)
        setCompanyNames(getCompanyNames(queriesResultCompanyBasics))
      }
    }
  }, [
    chartData,
    chartProperties,
    companyNames,
    queriesResult,
    queriesResultCompanyBasics,
    query.key,
  ])

  if (chartData.length === 0) {
    return <Box />
  }

  return (
    <Paper
      p={0}
      data-cy="key-figures-line-chart"
      component={Stack}
      flex={1}
      direction="column"
      elevation={2}
    >
      <Stack
        p={4}
        justifyContent="space-between"
        sx={{ borderBottom: '1px solid', borderColor: 'grey.100' }}
      >
        <Typography variant="h4" className={globalClasses.defaultBoxTitle}>
          <FormattedMessage id={intl.keyFiguresDevelopment('title')} />
        </Typography>
      </Stack>
      <Box ml={'-21px'} px={0} pt={5} sx={{ position: 'relative' }}>
        <SimpleLineChart data={chartData} chartProperties={chartProperties} />
      </Box>
      <Grid container gap={2} pb={5} px={4}>
        {buttons.map((button) => {
          return (
            <Grid item key={button.label}>
              {query.key === button.key ? (
                <ButtonSecondary sx={buttonStyles} onClick={() => setNewQuery(button)}>
                  {button.label}
                </ButtonSecondary>
              ) : (
                <ButtonTertiary sx={buttonStyles} onClick={() => setNewQuery(button)}>
                  {button.label}
                </ButtonTertiary>
              )}
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  )
}

export default KeyFiguresLineChartBox
