import { Box, Skeleton, Stack } from '@mui/material'
import { listViewTableHeaders } from '../HierarchyListView.model'

function ListSkeleton() {
  const numbers = new Array(15).fill(0)

  const elements = numbers.map(() => (
    // Key here does not matter
    <Box key={Math.random()} sx={{ width: '100%' }}>
      <Skeleton
        sx={{ marginBottom: '1px' }}
        variant="rectangular"
        width={'100%'}
        height={1}
      />
      <Stack flexDirection="row">
        {listViewTableHeaders.map((header) => (
          <Skeleton
            key={header.componentName}
            variant="rectangular"
            width={`${header.width + 100}px`}
            sx={{ marginRight: '1px' }}
            height={52}
          />
        ))}
      </Stack>
      <Skeleton
        sx={{ marginBottom: '1px' }}
        variant="rectangular"
        width={'100%'}
        height={1}
      />
    </Box>
  ))

  return <>{elements}</>
}

export default ListSkeleton
