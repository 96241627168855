import { useQuery } from 'react-query'
import peopleVisitsList, {
  PersonVisitsListParams,
} from 'services/api/endpoints/risikaAPI/peopleVisitsList'

export function usePeopleVisitsList(data: PersonVisitsListParams) {
  return useQuery(
    ['peopleVisitsList', Object.values(data)],
    () => peopleVisitsList(data),
    {
      refetchOnWindowFocus: false,
    }
  )
}
