import React, { useState } from 'react'
import { ButtonTertiary } from 'components-new'
import { Menu } from '@mui/material'

export const SimpleMenu = React.forwardRef(function SimpleMenu(props, ref) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (fn) => () => {
    fn()
    handleClose()
  }

  /**
   * Registers a new onClick handler using the
   * children's original onClick handler.
   *
   * @param {MenuItem} children The component's children.
   */
  const childrenWithOnClickHandler = (children) =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        onClick: handleMenuItemClick(child.props.onClick),
      })
    )

  const {
    text,
    disabled = false,
    loading = false,
    icon = '',
    children,
    className,
    locked = false,
  } = props

  // The warning thrown here can not be fixed. It is caused by deep internal failures
  return (
    <React.Fragment ref={ref}>
      <ButtonTertiary
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={icon}
        disabled={disabled}
        loading={loading}
        className={className}
        locked={locked === false ? 'false' : 'true'}
      >
        {text}
      </ButtonTertiary>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {childrenWithOnClickHandler(children)}
      </Menu>
    </React.Fragment>
  )
})

export default SimpleMenu
