import { Component } from 'react'
import ReactGA from 'react-ga'
import { keys } from 'configs'

export const withGA = (WrappedComponent) => {
  class WithGoogleAnalytics extends Component {
    componentDidMount = () => {
      this.initializeGA()
      this.runGA()
    }

    componentDidUpdate = () => this.runGA()

    initializeGA = () => {
      if (process.env.NODE_ENV === 'production') {
        ReactGA.initialize(keys.GOOGLE_TRACKING_ID)
      }
    }

    runGA = () => {
      if (process.env.NODE_ENV === 'production') {
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const getDisplayName = (WrappedComponent) =>
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  WithGoogleAnalytics.displayName = `WithGoogleAnalytics(${getDisplayName(
    WrappedComponent
  )})`

  return WithGoogleAnalytics
}
