import callBackendAPI from 'services/api/helpers/backendAPI'

/******************
 * Post to API *
 ******************/

type ResponseData = {
  data: {
    message: string
    status: number
    code: string
  }
}
const resendMail = (email: string): Promise<ResponseData> => {
  return callBackendAPI(`/auth/send/activation/mail/${email}`, {
    method: 'get',
  })
}

export default resendMail
