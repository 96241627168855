import risikaAPI from 'services/api/helpers/risikaAPI'
import { TableDataAPIType } from 'components-new/data-display/Table/Table.types'

const paginatedMonitoringList = (
  listId: number,
  parameters: any
): Promise<TableDataAPIType> => {
  return risikaAPI
    .post(`/listpaged/${listId}`, parameters, {
      microservice: 'monitor',
      timeout: 90000,
    })
    .then(({ data }) => {
      return data
    })
}

export default paginatedMonitoringList
