import { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Stack,
  TextField,
  ClickAwayListener,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { withSnackbar } from 'notistack'
import { setFilters, fetchSavedFilters, setFilterCountry } from 'store_deprecated'
import { deleteFilter, saveFilters } from 'services/api'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { classes } from '../styles/load-filters'
import { Mixpanel } from 'services/helpers/mixpanel'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import { CountryFlagIcon } from 'components-new'

const FilterListItem = withSnackbar(({ filter, close, enqueueSnackbar }) => {
  const dispatch = useDispatch()
  const { savedFilters } = useSelector((state) => state.risika.advancedFilters)
  const [editMode, setEditMode] = useState(false)
  const [name, setName] = useState(filter.name)

  const handleNameChange = (event) => {
    setName(event.currentTarget.value)
  }

  const handleSetFilters = () => {
    dispatch(setFilterCountry(filter.country))
    dispatch(setFilters(filter.filters))
    close()
  }

  const handleDelete = () => {
    deleteFilter({ filterToBeDeleted: filter, savedFilters })
      .then(() => {
        dispatch(fetchSavedFilters())
        enqueueSnackbar(
          <FormattedMessage id={intl.advancedSearch('snackbar-delete-filters-success')} />
        )
      })
      .catch((error) => {
        enqueueSnackbar(
          <FormattedMessage id={intl.advancedSearch('snackbar-delete-filters-failed')} />
        )
      })
  }

  const handleUpdateFilterName = () => {
    const newFilters = savedFilters.filter(
      (x) => JSON.stringify(x) !== JSON.stringify(filter)
    )
    saveFilters({
      name,
      filters: filter.filters,
      country: filter.country,
      savedFilters: newFilters,
    })
      .then(() => {
        dispatch(fetchSavedFilters())
        setEditMode(false)
        enqueueSnackbar(
          <FormattedMessage id={intl.advancedSearch('snackbar-update-filters-success')} />
        )
      })
      .catch((error) => {
        setEditMode(false)
        setName('')
        enqueueSnackbar(
          <FormattedMessage id={intl.advancedSearch('snackbar-update-filters-failed')} />
        )
      })
  }

  const toggleEdit = () => setEditMode(!editMode)

  const handleFocus = (event) => event.target.select()

  const handleKeDown = (event) => {
    if (event.key === 'Enter') {
      handleUpdateFilterName()
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setEditMode(false)}>
      <Stack
        data-cy={`filter-${filter.name}`}
        direction="row"
        justifyContent="space-between"
      >
        {editMode ? (
          <Box flexGrow={1}>
            <TextField
              variant="standard"
              fullWidth
              autoFocus
              value={name}
              onChange={handleNameChange}
              onKeyDown={handleKeDown}
              onFocus={handleFocus}
              sx={classes.textField}
            />
          </Box>
        ) : (
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={handleSetFilters}
          >
            <Typography variant="body1">{name}</Typography>
            <CountryFlagIcon country={filter.country} styles={classes.flagIcon} />
          </Stack>
        )}
        <Stack direction="row" spacing={1}>
          <IconButton sx={classes.iconButton} onClick={toggleEdit}>
            <CreateIcon />
          </IconButton>
          <IconButton
            data-cy="remove-saved-filter"
            sx={classes.iconButton}
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>
    </ClickAwayListener>
  )
})

const NoFilters = () => {
  return (
    <Box px={2} py={1}>
      <Typography variant="body1">
        <FormattedMessage id={intl.advancedSearch('no-saved-filters')} />
      </Typography>
    </Box>
  )
}

const FilterList = ({ filters, close }) => {
  return filters.length ? (
    <Stack gap={2} sx={classes.root}>
      {filters.map((filter) => (
        <FilterListItem key={filter.id} filter={filter} close={close} />
      ))}
    </Stack>
  ) : (
    <NoFilters />
  )
}

const LoadFilters = ({ title, render, filters }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    Mixpanel.track('User clicked load filters')
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      {render(handleOpen)}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
          <Typography variant="h5" color="inherit">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent sx={classes.dialogContent}>
          <FilterList filters={filters} close={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LoadFilters
