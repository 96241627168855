import { SxProps } from '@mui/system'

const container: SxProps = {
  paddingLeft: 1,
  paddingRight: 1,
  display: 'flex',
  justifyContent: 'space-between',
  verticalAlign: 'middle',
}

const icon: SxProps = {
  marginTop: 'auto',
  marginBottom: 'auto',
  marginRight: '8px',
}

const message: SxProps = {
  margin: 'auto',
}

export default {
  container,
  icon,
  message,
}
