type ListItem = {
  key: string
  visible: boolean
}

export const dndReorder = (list: ListItem[], startIndex: number, endIndex: number) => {
  const newList = list
  const [removed] = newList.splice(startIndex, 1)
  newList.splice(endIndex, 0, removed)

  return newList
}
