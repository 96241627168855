import { Box, Stack, Typography } from '@mui/material'
import { ButtonSecondary, ButtonTertiary } from 'components-new'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

const main = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  backgroundColor: 'white',
  px: 6,
  pt: 8,
  pb: 6,
}

const closeBtn = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  '&:hover': {
    cursor: 'pointer',
  },
}

const buttons = {
  marginTop: 6,
}

type Props = {
  title: string
  text: string
  primaryBtnTitle: string
  secondaryBtnTitle: string
  primaryBtnFunc: () => void
  secondaryBtnFunc: () => void
  closeFn: () => void
}

const ModalCanvas = ({
  title,
  text,
  primaryBtnTitle,
  secondaryBtnTitle,
  primaryBtnFunc,
  secondaryBtnFunc,
  closeFn,
}: Props) => {
  return (
    <Box sx={main}>
      <Typography variant="h5">{title}</Typography>
      <Typography>{text}</Typography>
      <Stack direction="row" spacing={4} sx={buttons}>
        <ButtonSecondary onClick={primaryBtnFunc}>{primaryBtnTitle}</ButtonSecondary>
        <ButtonTertiary onClick={secondaryBtnFunc}>{secondaryBtnTitle}</ButtonTertiary>
      </Stack>
      <CloseIcon onClick={closeFn} sx={closeBtn} />
    </Box>
  )
}

export default ModalCanvas
