import { useQuery } from 'react-query'
import { evaluationPolicy } from 'services/api'
import { LocalId, AvailableCountriesCapital, AllowedModel } from 'globalTypes'
import {
  EnterpriseEvaluation,
  ResponseData,
} from 'services/api/endpoints/risikaAPI/evaluationPolicy'
import { AxiosError } from 'axios'
import { useAccountStatus } from './useAccountStatus'

export const useEvaluationPolicySingle = (localId: LocalId, model?: AllowedModel) => {
  const { id, country } = localId
  const { data } = useAccountStatus()
  const ecp = data?.rights?.global?.enterprise_credit_policy
  return useQuery<ResponseData, AxiosError, EnterpriseEvaluation>(
    [`evaluationPolicy`, id, country],
    () =>
      evaluationPolicy({
        localIdList: [localId],
        country: country as AvailableCountriesCapital,
        model: model ?? 'BOHR',
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!ecp,
    }
  )
}
