import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'

/*********
 * Types *
 *********/

type Function = {
  function: string
  title: string
  valid_from: string
  valid_to: string
  shares?: number
  shares_interval?: number
  share_of_ownership_percentage?: number
}

type Relation = {
  name: string
  type: string
  country: string
  functions: Array<Function>
  label: string
}

export type PoliticallyExposedPersons = {
  date_added_to_pep?: string
  description?: string
  description_code?: string
  duties: {
    function?: string
    duties: any
  }[]
}

type Owner = {
  name: string
  type: string
  country: string
  functions: Array<Function>
  label: string
  shares: string | number | undefined
  personal_id: number | string
  inactiveFunctions?: Function[]
  special_ownership: {
    description: any
    title?: string
    valid_from?: string
    valid_to?: string
  }[]
  politically_exposed_persons?: PoliticallyExposedPersons
  active_company_relations: []
}

type RelationResponse = {
  active_company_relations: []
  country: string
  functions: Function[]
  inactiveFunctions?: Function[]
  name: string
  personal_id: number | string
  politically_exposed_persons?: PoliticallyExposedPersons
  special_ownership: {
    description: any
    title?: string
    valid_from?: string
    valid_to?: string
  }[]
  type: string
}

type CompanyRelationsData = {
  realOwners: Array<Owner>
  legalOwners: Array<Owner>
  boardOfDirectors: Array<Relation>
  management: Array<Relation>
  auditors: Array<Relation>
  founders: Array<Relation>
}

type ResponseData = {
  data: {
    relations: RelationResponse[]
  }
}

/*************
 * Relations *
 *************/

const REAL_OWNER = 'BENEFICIAL OWNER'
const LEGAL_OWNER = 'LEGAL OWNER'

const CHAIRMAN = 'CHAIRMAN'
const VICE_CHAIR = 'DEPUTY CHAIRMAN'
const BOARD_MEMBER = 'BOARD OF DIRECTORS'
const SUBSTITUTE = 'DEPUTY'

const CEO = 'CHIEF EXECUTIVE OFFICER'
// const CHIEF_EXECUTIVE_OFFICER = 'CHIEF EXECUTIVE OFFICER'

const MANAGEMENT = 'MANAGEMENT'
const STAKEHOLDER = 'STAKEHOLDER'
const BRANCH_MANAGER = 'BRANCH MANAGER'

const AUDITOR = 'AUDIT'

const FOUNDER = 'FOUNDER'

// const ACCOUNTANT = 'ACCOUNTANT'
// const AUDIT = 'AUDIT'
// const BOARD_OF_DIRECTORS = 'BOARD OF DIRECTORS'
// const OTHER = 'OTHER'
// const CONTACT_PERSON = 'CONTACT PERSON'
// const BANKRUPTCY_DEBTOR = 'BANKRUPTCY DEBTOR'
// const PARTICIPANT_WITH_JOINT_AND_SEVERAL_LIABILITY_FULLY_RESPONSIBLE =
//   'PARTICIPANT WITH JOINT AND SEVERAL LIABILITY (FULLY RESPONSIBLE)'
// const TRUSTEE = 'TRUSTEE'
// const DEPUTY_MEMBER = 'DEPUTY MEMBER'
// const INFORMATION_ABOUT_THE_COMPANY_IN_THE_HOME_COUNTRY =
//   'INFORMATION ABOUT THE COMPANY IN THE HOME COUNTRY'
// const OWNER = 'OWNER'
// const PARTICIPANT_WITH_PRO_RATA_RESPONSIBILITY_SHARED_RESPONSIBILITY =
//   'PARTICIPANT WITH PRO RATA RESPONSIBILITY (SHARED RESPONSIBILITY)'
// const DEPUTY_CHAIRMAN = 'DEPUTY CHAIRMAN'
// const NORWEGIAN_REPRESENTATIVE_FOR_FOREIGN_ENTITY =
//   'NORWEGIAN REPRESENTATIVE FOR FOREIGN ENTITY'
// const OBSERVER = 'OBSERVER'
// const BUSINESS_MANAGER = 'BUSINESS MANAGER'

/*******************
 * Other constants *
 *******************/

const DECIMALS = 2

/***********
 * Helpers *
 ***********/

/**
 * Removes all the inactive functions.
 *
 * @param {object} relation The relations filter to only active functions.
 * @returns {object} The relation with active functions only.
 */
function activeFunctions(relation: RelationResponse): RelationResponse {
  return {
    ...relation,
    functions: relation.functions.filter((fn) => fn.valid_to === null),
    inactiveFunctions: relation.functions.filter((fn) => fn.valid_to !== null),
  }
}

/**
 * Checks if the relation has one of the given roles.
 *
 * @param  {...string} roles The roles to check if the relation has.
 * @returns {boolean} Whether the relation contains one of the roles.
 */
function hasRoles(...roles: string[]) {
  return function (relation: RelationResponse): boolean {
    return (
      !!relation.functions.filter((fn) => roles.includes(fn.function)).length ||
      !!relation.inactiveFunctions?.filter((fn) => roles.includes(fn.function)).length
    )
  }
}

// const hasRoles =
//   (...roles: string[]) =>
//     (relation: RelationResponse): boolean => {
//       return (
//         !!relation.functions.filter((fn) => roles.includes(fn.function)).length ||
//         !!relation.inactiveFunctions?.filter((fn) => roles.includes(fn.function))
//           .length
//       )
//     }

/**
 * Gets the _shares_ or _shares interval_ for a given relation.
 *
 * @param {object} relation The relation to get the shares for.
 * @returns {string} The _shares_ or _shares range_.
 */
function getSharesForRole(role: string) {
  return function (relation: RelationResponse) {
    const [shares] = activeFunctions(relation)
      .functions.filter((fn) => fn.function === role)
      .map((fn) => {
        if (fn.shares) {
          return `${fn.shares.toFixed(DECIMALS)}%`
        }
        if (fn.share_of_ownership_percentage) {
          return fn.share_of_ownership_percentage
        }
        if (fn.shares_interval) {
          return fn.shares_interval
        }
        return ''
      })

    return shares
  }
}
// const getSharesForRole = (role: string) => (relation: RelationResponse) => {
//   const [shares] = activeFunctions(relation)
//     .functions.filter((fn) => fn.function === role)
//     .map(
//       (fn) =>
//         (fn.shares && `${fn.shares.toFixed(DECIMALS)}%`) || fn.shares_interval,
//     )
//   return shares
// }

/*********************
 * Extract relations *
 *********************/

/**
 * Gets all the relations that are _real owners_.
 *
 * @param {object[]>} relations The relations.
 * @returns {object[]} The relations that are _real owners_.
 */
function getRealOwners(relations: Array<RelationResponse>): Array<Owner> {
  const isRealOwner = hasRoles(REAL_OWNER)
  const getShares = getSharesForRole(REAL_OWNER)
  return relations
    .map(activeFunctions)
    .filter(isRealOwner)
    .map((relation) => ({
      ...relation,
      label: 'real_owner',
      shares: getShares(relation),
    }))
}

/**
 * Gets all the relations that are _legal owners_.
 *
 * @param {object[]} relations The relations.
 * @returns {object[]} The relations that are _legal owners_.
 */
function getLegalOwners(relations: Array<RelationResponse>): Array<Owner> {
  const isLegalOwner = hasRoles(LEGAL_OWNER)
  const getShares = getSharesForRole(LEGAL_OWNER)

  return relations
    .map(activeFunctions)
    .filter(isLegalOwner)
    .map((relation) => ({
      ...relation,
      label: 'legal_owner',
      shares: getShares(relation),
    }))
}

/**
 * Gets all the _board members_ ordered by rank.
 *
 * @param {object[]} relations The relations.
 * @returns {object[]} The relations that are part of the _board_.
 */
function getBoardOfDirectors(relations: Array<RelationResponse>): Array<Relation> {
  const isChairman = hasRoles(CHAIRMAN)
  const isViceChair = hasRoles(VICE_CHAIR)
  const isBoardMember = hasRoles(BOARD_MEMBER)
  const isSubstitute = hasRoles(SUBSTITUTE)

  const activeRelations = relations.map(activeFunctions)

  return [
    ...activeRelations
      .filter(isChairman)
      .map((relation) => ({ ...relation, label: 'president' })),
    ...activeRelations
      .filter(isViceChair)
      .map((relation) => ({ ...relation, label: 'vice_president' })),
    ...activeRelations
      .filter(isBoardMember)
      .map((relation) => ({ ...relation, label: 'board_member' })),
    ...activeRelations
      .filter(isSubstitute)
      .map((relation) => ({ ...relation, label: 'substitute' })),
  ]
}

/**
 * Gets all the _managers_ ordered by rank.
 *
 * @param {object[]} relations The relations.
 * @returns {object[]} The relations that are part of the _management_.
 */
function getManagement(relations: Array<RelationResponse>): Array<Relation> {
  const isCEO = hasRoles(CEO)
  const isManagement = hasRoles(MANAGEMENT)
  const isStakeholder = hasRoles(STAKEHOLDER)
  const isBranchManager = hasRoles(BRANCH_MANAGER)

  const activeRelations = relations.map(activeFunctions)

  return [
    ...activeRelations.filter(isCEO).map((relation) => ({ ...relation, label: 'ceo' })),
    ...activeRelations
      .filter(isManagement)
      .map((relation) => ({ ...relation, label: 'management' })),
    ...activeRelations
      .filter(isStakeholder)
      .map((relation) => ({ ...relation, label: 'stakeholder' })),
    ...activeRelations
      .filter(isBranchManager)
      .map((relation) => ({ ...relation, label: 'branch_manager' })),
  ]
}

/**
 * Gets all the _auditors_.
 *
 * @param {object[]} relations The relations.
 * @returns {object[]} The relations that are _auditors_.
 */
function getAuditor(relations: Array<RelationResponse>): Array<Relation> {
  const isAuditor = hasRoles(AUDITOR)

  return relations
    .map(activeFunctions)
    .filter(isAuditor)
    .map((relation) => ({ ...relation, label: 'auditor' }))
}

/**
 * Gets all the _founders_.
 *
 * @param {object[]} relations The relations.
 * @returns {object[]} The relations that are _founders_.
 */
function getFounders(relations: Array<RelationResponse>): Array<Relation> {
  const isFounder = hasRoles(FOUNDER)

  return relations
    .map(activeFunctions)
    .filter(isFounder)
    .map((relation) => ({ ...relation, label: 'founder' }))
}

/******************
 * Fetch from API *
 ******************/

/**
 * Gets all the relations for a given company.
 *
 * @param {object} LocalId _Local ID_ and _Country Code_ of the company.
 * @returns {object} All the different relations associated with the company.
 */
async function companyRelations({ id, country }: LocalId): Promise<CompanyRelationsData> {
  const { data }: ResponseData = await risikaAPI.get(`/company/relations/${id}`, {
    microservice: 'data',
    country,
  })
  const { relations } = data
  return {
    realOwners: getRealOwners(relations),
    legalOwners: getLegalOwners(relations),
    boardOfDirectors: getBoardOfDirectors(relations),
    management: getManagement(relations),
    auditors: getAuditor(relations),
    founders: getFounders(relations),
  }
}
export default companyRelations
