const downloadJson = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]))
  const link = document.createElement('a')
  link.href = url
  link.download = `${fileName}.json`
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export default downloadJson
