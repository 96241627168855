import {
  ADD_MONITORING_LIST_TO_REDUX,
  CLEAR_ERROR,
  RENAME_MONITORING_LIST,
  SAVE_ALL_MONITORING_LISTS_SUCCESS,
  SAVE_DATA_FOR_LIST_SUCCESS,
  SAVE_ENTRIES_FOR_LIST_FAILED,
  SAVE_ENTRIES_FOR_LIST_SUCCESS,
  SAVE_RELEVANT_CHANGES_FOR_LIST_SUCCESS,
  SERVER_ERROR,
  SET_LIST_LOADING,
  SET_LIST_STATUS,
  SET_LOADING,
  SET_SELECTED_LIST,
  SET_SUBSCRIPTIONS_SUCCESS,
  SET_TEXT,
  SUBMIT_FETCH_LIST_ENTRIES_BY_ID,
  UPDATE_RISK_ASSESMENT_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FREQUENCY,
} from './constants'

// type NewRiskMonitoringInitialState = {
//   selectedList: number,
//   persistCompanyID: number | null,
//   lists: Object,
//   massRemovedEntries: Array<any>,
//   loading: boolean,
//   text: string,
//   subscriptions: Array<any>,
//   isError: boolean,
//   error: Object | null,
// }

const setLoading = (state, action) => {
  return {
    ...state,
    // loading: action.payload.loading,
  }
}

const renameList = (state, action) => {
  const { payload } = action
  const { listId, listName } = payload

  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        name: listName,
      },
    },
  }
}

const setText = (state, action) => {
  const { payload } = action
  return {
    ...state,
    text: payload?.text,
  }
}

const setSelectedList = (state, action) => {
  const { payload } = action
  return {
    ...state,
    selectedList: payload,
  }
}
const saveAllMonitoringLists = (state, action) => {
  const { payload } = action

  const lists = {}

  payload?.forEach((x) => {
    Object.assign(lists, {
      [x.list_id]: {
        name: x.name,
        ownerId: x?.owner_id ?? null,
        listId: x.list_id,
        riskAssessment: x.risk_assessment,
        listLoading: true,
        entries: [],
        status: 'IDLE',
        relevantChanges: [],
        isPublic: Boolean(x?.owner_id),
        selectedUsers: [],
      },
    })
  })
  return {
    ...state,
    lists,
  }
}

const saveRelevantChanges = (state, action) => {
  const { payload } = action
  const { listId, changes } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        relevantChanges: changes,
      },
    },
  }
}

const addMonitoringList = (state, action) => {
  const { payload } = action

  const {
    name,
    ownerId,
    listId,
    listLoading,
    entries,
    status,
    relevantChanges,
    isPublic,
    selectedUsers,
  } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        name,
        ownerId,
        listId,
        listLoading,
        entries,
        status,
        relevantChanges,
        isPublic,
        selectedUsers,
      },
    },
  }
}

const saveDataToList = (state, action) => {
  const { payload, status } = action
  const { listId, entries, count, changes } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        status,
        entries,
        relevantChanges: changes,
        count,
      },
    },
  }
}

const saveEntriesToList = (state, action) => {
  const { payload, status } = action
  const { listId, entries, count } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        status,
        entries,
        count,
      },
    },
  }
}

const saveEntriesToListFailed = (state, action) => {
  const { payload, status } = action
  const { listId } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        status,
      },
    },
  }
}

const updateListStatus = (state, action) => {
  const { payload } = action
  const { listId, status } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        status,
      },
    },
  }
}

const handleListStatusAutomatically = (state, action) => {
  const { payload, status } = action
  const { listId } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        status,
      },
    },
  }
}

const setSubscriptions = (state, action) => {
  const { payload } = action
  return {
    ...state,
    subscriptions: payload,
  }
}

const setListLoading = (state, action) => {
  const { payload } = action
  const { listId, isLoading } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        listLoading: isLoading,
      },
    },
  }
}

const updateRiskAssesment = (state, action) => {
  const { payload } = action
  const { listId, data } = payload
  return {
    ...state,
    lists: {
      ...state.lists,
      [listId]: {
        ...state.lists[listId],
        riskAssessment: data?.[0]?.risk_assessment,
      },
    },
  }
}

const updateSubscription = (state, action) => {
  return {
    ...state,
    subscriptions: state.subscriptions.map((subscription) => {
      return subscription.list_id === action.id
        ? { ...subscription, ...action.options }
        : subscription
    }),
  }
}

const updateSubscriptionFrequency = (state, action) => {
  return {
    ...state,
    subscriptions: state.subscriptions.map((subscription) => {
      return subscription.list_id === action.id
        ? { ...subscription, frequency: action.frequency }
        : subscription
    }),
  }
}

export const initialStateRiskMonitoring = {
  selectedList: 0,
  persistCompanyID: null,
  lists: {},
  massRemovedEntries: [],
  loading: false,
  subscriptions: [],
  text: '',
  isError: false,
  error: null,
}

const hasServerError = (state, action) => {
  const { payload } = action
  return {
    ...state,
    isError: true,
    error: payload,
  }
}

const clearError = (state, action) => {
  return {
    ...state,
    isError: false,
    error: null,
  }
}

export const riskMonitoring = (
  state = initialStateRiskMonitoring,
  action = { type: '' }
) => {
  switch (action.type) {
    case SET_LOADING:
      return setLoading(state, action)
    case SAVE_ALL_MONITORING_LISTS_SUCCESS:
      return saveAllMonitoringLists(state, action)
    case SAVE_RELEVANT_CHANGES_FOR_LIST_SUCCESS:
      return saveRelevantChanges(state, action)
    case SAVE_DATA_FOR_LIST_SUCCESS:
      return saveDataToList(state, action)
    case SAVE_ENTRIES_FOR_LIST_SUCCESS:
      return saveEntriesToList(state, action)
    case SAVE_ENTRIES_FOR_LIST_FAILED:
      return saveEntriesToListFailed(state, action)
    case SET_LIST_STATUS:
      return updateListStatus(state, action)
    case SUBMIT_FETCH_LIST_ENTRIES_BY_ID:
      return handleListStatusAutomatically(state, action)
    case SET_SUBSCRIPTIONS_SUCCESS:
      return setSubscriptions(state, action)
    case SET_LIST_LOADING:
      return setListLoading(state, action)
    case ADD_MONITORING_LIST_TO_REDUX:
      return addMonitoringList(state, action)
    case SET_SELECTED_LIST:
      return setSelectedList(state, action)
    case SET_TEXT:
      return setText(state, action)
    case UPDATE_RISK_ASSESMENT_SUCCESS:
      return updateRiskAssesment(state, action)
    case RENAME_MONITORING_LIST:
      return renameList(state, action)
    case UPDATE_SUBSCRIPTION:
      return updateSubscription(state, action)
    case UPDATE_SUBSCRIPTION_FREQUENCY:
      return updateSubscriptionFrequency(state, action)
    case SERVER_ERROR:
      return hasServerError(state, action)
    case CLEAR_ERROR:
      return clearError(state, action)
    default:
      return state
  }
}

export default riskMonitoring
