import { useQuery } from 'react-query'
import { ratingCredit } from 'services/api'
import { isCallAllowed } from 'configs'
import { useUserSettings } from './useUserSettings'
import { LocalId } from 'globalTypes'

export function useRatingCredit(localId: LocalId, staleTime = 0) {
  const userSettingsQuery = useUserSettings()
  return useQuery(
    ['ratingCredit', localId.id, localId.country],
    () =>
      ratingCredit(localId, {
        currency: userSettingsQuery?.data?.selectedCurrency?.currency,
      }),
    {
      staleTime,
      enabled: isCallAllowed('ratingCredit', localId.country)
        ? !userSettingsQuery.isLoading || userSettingsQuery.isError
        : false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
