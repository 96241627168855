import callBackendAPI from 'services/api/helpers/backendAPI'
import { ResponseDataGetUser } from './getUser'

/******************
 * Fetch from API *
 ******************/
const userChangePassword = (
  password: string,
  confirmPassword: string
): Promise<ResponseDataGetUser> => {
  return callBackendAPI('/user', {
    method: 'put',
    data: {
      password,
      confirm_password: confirmPassword,
    },
  })
}

export default userChangePassword
