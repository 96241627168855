import { ForwardedRef, forwardRef } from 'react'
import { Divider, Stack } from '@mui/material'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { ReactSetState } from 'types/general'
import { NullRef, SubmitRef } from './index'

import ProTips from '../ProTips/ProTips'
import CustomizationHandler from './widgets/CustomizationHandler'

import VariableHandler from './widgets/VariableHandler'
import ExtraPanelContent from './widgets/ExtraPanelContent'
import NullHandler from './widgets/NullHandler'

import { extraPanelKeys } from './DetailedComponentView.model'
import { RefChildren } from './widgets/CustomizationHandler/CustomizationHandler.types'
import { useQueryClient } from 'react-query'
import { ECPDataType } from 'services/api/endpoints/risikaAPI/getECPDataStructure'

type ViewContentProps = {
  setIsConfirmEnabled: ReactSetState<boolean>
  refs: {
    nullRef: ForwardedRef<NullRef>
    submitRef: ForwardedRef<SubmitRef>
  }
}

const ViewContent = forwardRef(
  ({ setIsConfirmEnabled, refs }: ViewContentProps, ref: ForwardedRef<any>) => {
    const queryClient = useQueryClient()
    const dataStructure = queryClient.getQueryData('useECPDataStructure') as ECPDataType
    const { state } = useBuilder()
    const { actionPanelContent: panelKey } = state

    if (!dataStructure || !dataStructure.length) return null

    const variable = dataStructure.find((item) => item.label === panelKey)
    if (extraPanelKeys.includes(panelKey) && panelKey !== '') {
      // Here we render the cases where we have a panel that is not in the api
      return (
        <ExtraPanelContent
          ref={refs.submitRef}
          setIsConfirmEnabled={setIsConfirmEnabled}
          panelKey={panelKey}
        />
      )
    }

    if (!variable || panelKey === '') return null
    return (
      <Stack
        className="VIEW_CONTENT_CONTAINER"
        sx={{ paddingBottom: '120px' }}
        overflow="scroll"
        p={5}
        paddingTop={6}
        height={1}
      >
        <Stack
          direction="column"
          gap={variable?.template?.[0]?.template_data?.variant === 'boolean' ? 0 : 6}
        >
          <Stack flex={1}>
            <CustomizationHandler selectedVariable={variable}>
              {/* Here we handle all the custom cases */}
              {
                variable?.template?.map((item) => (
                  // Here are all the other cases
                  <VariableHandler
                    key={item?.template_data?.api_key}
                    setIsConfirmEnabled={setIsConfirmEnabled}
                    template={item}
                    ref={refs.submitRef}
                    // titleOverride={variable.label}
                  />
                )) as RefChildren[]
              }
            </CustomizationHandler>
          </Stack>
          <Stack sx={{ marginTop: '0 !important' }} spacing={6}>
            <ProTips tip={variable.pro_tips ?? ''} />
            {variable?.data?.type === 'rule' && (
              <>
                <Divider />
                <NullHandler ref={refs.nullRef} />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    )
  }
)

export default ViewContent
