import { ConditionallyRender } from 'components'
import { Stack, Typography, Grid } from '@mui/material'
import FinancialRatioChart from './scenes/FinancialRatioChart'
import { useCreditCheck } from 'pages/credit-check/context'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import RelevantFinancialRatiosSkeleton from './RelevantFinancialRatios.skeleton'

const RelevantFinancialRatios = ({
  isShowMore,
  setIsRendering,
  financialRatiosQuery,
  financialPerformanceQuery,
}) => {
  // Variable that would be used to change the selected item when data is available
  // const [selectedItem, setSelectedItem] = React.useState(0)

  // Remove this one when the api is ready for the one above
  const selectedItem = 0

  const { localId } = useCreditCheck()
  const yearsToShow = 4
  const financialData = {}
  const financialRatioTypes = [
    'solidity_ratio',
    'current_ratio',
    'debt_to_equity_ratio',
    'return_on_assets',
    'return_on_equity',
    'operating_margin',
    'asset_turnover',
    'fixed_assets_to_long_term_liabilities_ratio',
    'interest_rate_on_debt_with_average',
    'cash_conversion_rate',
    'capacity_ratio',
    'gross_margin',
    'interest_margin',
    'current_assets_to_equity',
    'cash_ratio',
  ]

  const isLoading = financialRatiosQuery.isLoading || financialPerformanceQuery.isLoading
  if (isLoading) {
    return <RelevantFinancialRatiosSkeleton />
  }

  if (!financialRatiosQuery.data?.length) {
    setIsRendering(false)
    return null
  }

  financialRatioTypes.forEach((key) => {
    const formattedData = []

    for (let index = 0; index < yearsToShow; index++) {
      const ratio = financialRatiosQuery.data[index]

      if (!ratio) break

      const performance = financialPerformanceQuery.data?.[key]?.performance
        ? financialPerformanceQuery.data?.[key].performance
        : null

      formattedData.push({
        value: ratio[key] === 0 ? 0.001 : ratio[key],
        period: ratio.period,
        performance,
      })
    }

    financialData[key] = formattedData
  })

  const handleCondition = (i, arrayLength) => {
    if (isShowMore || (!isShowMore && i < 5)) {
      return arrayLength
    }
    return false
  }

  return (
    <Stack>
      <ConditionallyRender
        // We need to take care of this when rendering consolidated statement data
        condition={false && localId.country.toLowerCase() === 'dk'}
        when={
          <Typography sx={{ fontStyle: 'italic' }}>
            <FormattedMessage
              id={intl.relevantFinancialRatios('based-on-parent-statement')}
            />
          </Typography>
        }
      />

      {/* <Grid
        container
        spacing={3}
        columns={10}
        justifyContent="space-evenly"
        sx={{ paddingTop: 2 }}
      >
        {Object.keys(financialData).map((key, i) => {
          const condition = handleCondition(i, financialData[key]?.length)
          const listKey = `${localId.id}_${key}`

          if (condition) {
            return (
              <Grid item xs={2} sx={{ minWidth: '240px' }} key={listKey}>
                <FinancialRatioChart
                  data={financialData[key]}
                  performances={financialPerformanceQuery.data}
                  selectedItem={selectedItem}
                  fieldKey={key}
                />
              </Grid>
            )
          }

          return null
        })}
      </Grid> */}
      <Grid
        container
        gap="36px"
        justifyContent="space-between"
        sx={{
          height: isShowMore ? 'auto' : '38rem',
          overflow: 'hidden',
          padding: '0 43.5px 0 43.5px',
        }}
      >
        {Object.keys(financialData).map((key, i) => {
          // This is here, to make sure the animation still goes trough
          const condition = handleCondition(i, financialData[key]?.length)

          const listKey = `${localId.id}_${key}`
          if (condition) {
            return (
              <Grid
                item
                xs={2}
                sx={{
                  minWidth: '240px',
                  padding: '24px 0 12px 0',
                }}
                key={listKey}
              >
                <FinancialRatioChart
                  data={financialData[key]}
                  performances={financialPerformanceQuery.data}
                  selectedItem={selectedItem}
                  fieldKey={key}
                />
              </Grid>
            )
          }
          return null
        })}
      </Grid>
    </Stack>
  )
}

export default RelevantFinancialRatios
