import { SxProps } from '@mui/system'

const inputStyles: SxProps = {
  borderColor: 'grey.200',
  input: {
    padding: '12px 6px',
  },
  width: '100%',
}

const checkboxStyles = {
  svg: {
    height: '16px',
    width: '16px',
  },
}

const labelStyles: SxProps = {
  padding: 0,
  paddingLeft: 2,
}
const footerStyles: SxProps = {
  paddingY: 3,
  paddingX: 4,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderTop: '1px solid',
  borderColor: 'grey.200',
  backgroundColor: 'grey.50',
  a: {
    textDecoration: 'underline',
  },
}

export { inputStyles, checkboxStyles, labelStyles, footerStyles }
