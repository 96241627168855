import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const container: SxProps = {
  display: 'inline-flex',
  '&:hover': {
    cursor: 'pointer',
  },
}

const iconFrame: SxProps = {
  ml: theme.spacing(1),
  border: 'solid 1px',
  borderColor: theme.palette.grey[500],
  borderRadius: '25%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
}

export default {
  iconFrame,
  container,
}
