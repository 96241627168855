import { Box, Stack, Typography } from '@mui/material'
import { Trend } from 'services/api/endpoints/risikaAPI/companyVisitsSummary'
import { ArrowOutward } from '@mui/icons-material'
import numeral from 'numeral'
import { iconSize } from './VisitsSummary.style'

type SummaryItemProps = {
  direction: Trend
  value: number
  text: string
}
const ValueWithIcon = ({ value, direction }: { value: number; direction: Trend }) => {
  const Icon = () => {
    if (direction === 'up') {
      return (
        <ArrowOutward
          sx={{
            ...iconSize,
          }}
          color="success"
        />
      )
    }
    if (direction === 'down') {
      return (
        <ArrowOutward
          sx={{
            ...iconSize,
            transform: 'rotate(90deg)',
          }}
          color="error"
        />
      )
    }
    // Return dash
    return <Box sx={{ ...iconSize, color: 'grey.400' }}>-</Box>
  }

  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="h2">{numeral(value).format('0,0')}</Typography>
      <Icon />
    </Stack>
  )
}

function SummaryItem({ direction, value, text }: SummaryItemProps) {
  return (
    <Stack
      alignItems={{ xs: 'left', sm: 'left', md: 'inherit' }}
      py={4}
      px={5}
      pr={0}
      width={1}
    >
      <Stack py={5} px={6} pr={0}>
        <Box className="summary-item-box">
          <ValueWithIcon value={value} direction={direction} />
          <Typography variant="body1">{text}</Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

export default SummaryItem
