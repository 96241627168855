import {
  HANDLE_ADD_HISTORY_ENTRY,
  HANDLE_REMOVE_LAST_HISTORY_ENTRY,
  HANDLE_CLEAR_HISTORY,
} from './constants'

const handleAddHistoryEntry = (state, action) => {
  const { payload } = action
  const { historyKey, location, country, title } = payload
  if (
    state?.[state.length - 1].historyKey === historyKey &&
    state?.[state.length - 1].location === location
  ) {
    return state
  }
  return [
    ...state.filter(
      (x) => `${x.historyKey}${x.location}` !== `${historyKey}${location}` // Please Javascript put ur shit together...
    ), // Remove previous dublicates
    { historyKey, location, country, title }, // Add the latest search on the front
  ]
}

const handleHistoryLocation = (history) => {
  switch (history.location) {
    case 'home':
      return '/home'
    case 'monitoring':
      return '/riskmonitoring'
    case 'advancedfilters':
      return '/advancedfilters'
    case 'creditpolicy':
      return '/creditpolicy'
    case 'company-comparison':
      return '/company-comparison'
    case 'observational-lists':
      return '/observational-lists'
    case 'companyhierarchy':
      return `/${history?.location}/${history?.country}/${history?.historyKey}`
    case 'creditcheck':
      return `/${history?.location}/${history?.country}/${history?.historyKey}`
    case 'enterprise-credit-policy':
      return `/${history.location}`
    case 'relationoverview':
      return `/${history?.location}/${history?.country}/${history?.historyKey}`
    case 'settings':
      return '/settings'
    case 'faq':
      return '/faq'
    default:
      return '/404'
  }
}
const handleRemoveLastHistoryEntry = (state, action) => {
  if (state?.length === 1) {
    return state
  }
  const currentHistory = state?.[state?.length - 2]
  action.history.push(handleHistoryLocation(currentHistory))
  return state.filter((x) => x.historyKey !== state?.[state?.length - 1]?.historyKey)
}
const handleClearHistory = (state, action) => {
  if (action?.location) {
    return [...initialState, { historyKey: action?.location, location: action?.location }]
  } else {
    return initialState
  }
}

export const initialState = [{ historyKey: 'home', location: 'welcome' }]

export const history = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case HANDLE_ADD_HISTORY_ENTRY:
      return handleAddHistoryEntry(state, action)
    case HANDLE_REMOVE_LAST_HISTORY_ENTRY:
      return handleRemoveLastHistoryEntry(state, action)
    case HANDLE_CLEAR_HISTORY:
      return handleClearHistory(state, action)
    default:
      return state
  }
}

export default history
