import { forwardRef, ForwardedRef } from 'react'
import Button from '@mui/material/Button'
import { buttonSecondaryStyles } from './Button.styles'
import { ButtonProps } from './Button.types'
import { SxProps } from '@mui/system'

// TODO: Find a way to make icon's width 12px
// export function withExtraStyles<P>( WrappedComponent: React.ComponentType<P> | null) {
//   if (!WrappedComponent) return null
//   return (props: P) => {
//     return <WrappedComponent {...props} style={{width: 12}}/>;
//   };
// }

const ButtonSecondary = forwardRef(
  (
    {
      children,
      size = 'large',
      disabled = false,
      loading = false,
      sx = {},
      ...extraProps
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    // const [newIcon, setNewIcon] = useState(null)
    // useEffect(() => setNewIcon(withExtraStyles(startIcon)), [startIcon])
    // useEffect(() => console.log(newIcon), [newIcon])
    return (
      <Button
        ref={ref}
        sx={{ ...buttonSecondaryStyles, ...sx } as SxProps}
        variant="contained"
        color="primary"
        size={size}
        {...extraProps}
        disabled={disabled || loading}
        disableRipple={true}
      >
        {children}
      </Button>
    )
  }
)

export default ButtonSecondary
