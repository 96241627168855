import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

interface PropertiesForCompany {
  building_number?: string
  change_ts?: string
  district?: string
  floor?: string
  land_value?: number
  owners: {
    company: any
    person: any
  }
  property_id?: string
  property_type: any
  property_value?: number
  side_door?: string
  street?: string
  suite?: string
  valuation_date?: string
  zipcode?: string
}

interface ReturnData extends PropertiesForCompany {
  number?: string
  postdistrict?: string
}

type ResponseData = {
  data: PropertiesForCompany[]
}

async function getPropertiesForCompany({
  id,
  country,
}: LocalId): Promise<ReturnData[]> {
  const { data }: ResponseData = await risikaAPI.get(
    `/registration/properties?local_id=${id}`,
    {
      microservice: 'data',
      country,
    },
  )
  return data.map((x) => ({
    ...x,
    number: x.building_number,
    postdistrict: x.district,
  }))
}
export default getPropertiesForCompany
