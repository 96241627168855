import axios from 'axios'
import { BACKEND_API_BASE_URL } from './config'
import { setAPITokens } from './authService'
import callBackendAPI from './backendAPI'

/**
 * Verify the account associated with the token.
 */
export const verifyAccount = (token) => {
  return axios({
    url: `${BACKEND_API_BASE_URL}/auth/account/verify`,
    method: 'PUT',
    data: { verify_token: token },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(({ data }) => {
    setAPITokens(data.auth)
    console.log('verifyAccount', data)
    return data
  })
}

/**
 * Activate the user's account and set the password.
 */
export const activateAccount = ({ password, confirmPassword, verifyToken }) => {
  return callBackendAPI('/auth/account/activate', {
    method: 'put',
    data: {
      password,
      confirm_password: confirmPassword,
      verify_token: verifyToken,
    },
  }).then(({ data }) => data)
}

export const resendAccountActivation = (email) => {
  return axios({
    url: `${BACKEND_API_BASE_URL}/auth/send/activation/mail/${email}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
