import { Stack } from '@mui/material'
import { ConditionallyRender, Show } from 'components'
import numeral from 'numeral'
import { FinancialNumberProps } from '../../HierarchyListView.types'
import BlankDashField from '../BlankDashField'
import { Typography } from '@mui/material/'
import { GreenTrendArrow, RedTrendArrow } from 'components-new'

function FinancialNumber({ row, numberKey, align }: FinancialNumberProps) {
  const trendObject = row?.financial_trend?.[numberKey]

  const TrendArrow = (trend: 'UP' | 'DOWN') =>
    trend === 'UP' ? <GreenTrendArrow /> : <RedTrendArrow />

  if (row?.type !== 'COMPANY') {
    return <BlankDashField align={align} />
  }

  return (
    <ConditionallyRender
      condition={!!trendObject?.current}
      when={
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">
            {numeral(trendObject?.current).format('0,0')} {row.currency}
          </Typography>
          <Show when={!!trendObject?.trend}>{TrendArrow(trendObject?.trend)}</Show>
        </Stack>
      }
      otherwise={
        <Stack direction="row" justifyContent="flex-start">
          -
        </Stack>
      }
    />
  )
}
export default FinancialNumber
