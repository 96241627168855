import { Box, Paper, Skeleton, Stack } from '@mui/material'
import TitleMainRecommendation from '../../elements/TitleMainRecommendation'

const NorwegianPaymentRemarksSkeleton = () => {
  return (
    <Paper elevation={2} component={Stack} m={4} p={4} gap={6}>
      <TitleMainRecommendation labelKey="norwegian-payment-remarks" />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        position="relative"
        width="100%"
        gap={3}
      >
        <Box flexGrow={1}>
          <Skeleton variant="circular" width={80} height={80} />
        </Box>
        <Stack width="100%" flexGrow={0}>
          <Skeleton variant="text" width="100%" height={22} />
          <Skeleton variant="text" width="25%" height={22} />
        </Stack>
      </Stack>
    </Paper>
  )
}

export default NorwegianPaymentRemarksSkeleton
