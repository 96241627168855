import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { CountryPolicyContextType } from 'pages/advanced-credit-policy/types'

type AuditorIdInputType = {
  policyInject: () => CountryPolicyContextType
  isAdvanced?: boolean
}

const AuditorIdInput = ({ policyInject, isAdvanced = true }: AuditorIdInputType) => {
  const { policy, setPolicy, isInferiorPlan } = policyInject()

  const [val, setVal] = React.useState<boolean>(policy.auditor_has_id ?? false)

  // this is needed to set the initial value
  useEffect(() => {
    if (typeof policy.auditor_has_id === 'boolean') {
      setVal(policy.auditor_has_id)
    }
  }, [policy.auditor_has_id])

  useEffect(() => {
    if (val !== policy.auditor_has_id) {
      if (isAdvanced && !isInferiorPlan) {
        setPolicy({ ...policy, auditor_has_id: val })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPolicy, val])

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={val}
          onChange={function (e: React.ChangeEvent<HTMLInputElement>): void {
            setVal(e.target.checked)
          }}
          disabled={isAdvanced && isInferiorPlan}
          sx={{ margin: 3, padding: 0 }}
        />
      }
      label={
        <Typography color="grey.800" variant="body1">
          <FormattedMessage id={intl.creditPolicyNew('auditor-id-label')} />
        </Typography>
      }
    />
  )
}

export default AuditorIdInput
