import { Stack, Typography } from '@mui/material'
import styles from 'pages/credit-check/styles/card-item'

type Props = {
  count: number
  title: JSX.Element
}

const CardItem = ({ count, title }: Props) => {
  return (
    <Stack sx={styles.card} justifyContent="center" alignItems="center" spacing={4}>
      <Typography variant="h2">{count}</Typography>
      <Typography variant="body1" align="center">
        {title}
      </Typography>
    </Stack>
  )
}

export default CardItem
