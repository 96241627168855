import intl from 'localization/components'
import {
  ADJUST_CREDIT_MAX,
  APPROVE_CREDIT_MAX,
  APPROVE_WITH_VALUE,
  DELETE_RULE,
  NEW_MONITORING_LIST,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import { ToleranceOptions } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'
import { FormattedMessage } from 'react-intl'
import { Method } from './DetailedComponentView.types'

const SINGLE_SELECT = 'single-select'
const NUMBER_INPUT = 'number-input'

export const DEFINE_CREDIT_MAX = 'define_credit_max'
export const DEFINE_PAYMENT_TERMS = 'define_payment_terms'
export const USE_RISIKA_RECOMMENDATION = 'use_risika_recommendation'
export const SET_YOUR_OWN = 'set_your_own'
export const SET_YOUR_OWN_VALUE = 'set_your_own_value'
export const PERCENTAGE_OF_RISIKA_SCORE = 'percentage_of_risika_score'
export const VALUE = 'value'
export const NOT_LOWER_THAN = 'not_lower_than'
export const NOT_HIGHER_THAN = 'not_greater_than'

export const tolerance = {
  title: <FormattedMessage id={intl.creditPolicyNew('tolerance')} />,
  placeholder: <FormattedMessage id={intl.creditPolicyNew('select')} />,
  component: SINGLE_SELECT,
  options: [
    {
      value: 'greater_than',
      label: <FormattedMessage id={intl.creditPolicyNew('greater_than')} />,
    },
    {
      value: 'equals',
      label: <FormattedMessage id={intl.creditPolicyNew('equals')} />,
    },
    {
      value: 'less_than',
      label: <FormattedMessage id={intl.creditPolicyNew('less_than')} />,
    },
    {
      value: 'between',
      label: <FormattedMessage id={intl.creditPolicyNew('between')} />,
    },
  ],
}
export const equity = {
  type: {
    title: 'type',
    placeholder: 'select',
    component: SINGLE_SELECT,
    options: ['equity', 'equity_without_dividend'],
  },
  tolerance: {
    title: 'tolerance',
    placeholder: 'select',
    component: SINGLE_SELECT,
    options: ['greater_than', 'equals', 'less_than', 'between'] as ToleranceOptions[],
  },
  value: {
    title: 'value',
    component: NUMBER_INPUT,
  },
}
export const risikaScore = {
  tolerance: {
    title: 'tolerance',
    placeholder: 'select',
    component: SINGLE_SELECT,
    options: ['greater-than', 'equal-to', 'less-than', 'between'] as ToleranceOptions[],
  },
  risikaScore: {
    title: 'risika-score',
    placeholder: 'select',
    component: SINGLE_SELECT,
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
}

export const approveCredit = {
  method: {
    title: <FormattedMessage id={intl.creditPolicyNew('method')} />,
    component: SINGLE_SELECT,
    options: [
      {
        value: USE_RISIKA_RECOMMENDATION,
        label: <FormattedMessage id={intl.creditPolicyNew(USE_RISIKA_RECOMMENDATION)} />,
      },
      {
        value: SET_YOUR_OWN_VALUE,
        label: <FormattedMessage id={intl.creditPolicyNew(SET_YOUR_OWN_VALUE)} />,
      },
      {
        value: PERCENTAGE_OF_RISIKA_SCORE,
        label: <FormattedMessage id={intl.creditPolicyNew(PERCENTAGE_OF_RISIKA_SCORE)} />,
      },
    ],
  },
  creditDefinition: {
    title: <FormattedMessage id={intl.creditPolicyNew('credit-definition')} />,
    component: SINGLE_SELECT,
    options: [
      { value: VALUE, label: <FormattedMessage id={intl.creditPolicyNew(VALUE)} /> },
    ],
  },
  creditDefinitionPercentageOfRisikaScore: {
    title: <FormattedMessage id={intl.creditPolicyNew('percentage_of_risika_score')} />,
    component: NUMBER_INPUT,
  },
  creditDefinitionSetLimit: {
    title: <FormattedMessage id={intl.creditPolicyNew('set-limit')} />,
    component: SINGLE_SELECT,
    options: [
      {
        value: NOT_LOWER_THAN,
        label: <FormattedMessage id={intl.creditPolicyNew(NOT_LOWER_THAN)} />,
      },
      {
        value: NOT_HIGHER_THAN,
        label: <FormattedMessage id={intl.creditPolicyNew(NOT_HIGHER_THAN)} />,
      },
    ],
  },
  creditDefinitionValue: {
    title: <FormattedMessage id={intl.creditPolicyNew('value')} />,
    component: NUMBER_INPUT,
  },
}
export const extraPanelKeys = [DELETE_RULE, NEW_MONITORING_LIST]
export const approveValuesDefaultState = {
  value: null,
  percentage: null,
  lower_limit: null,
  upper_limit: null,
}
export const approveDropdownMethod = {
  title: <FormattedMessage id={intl.creditPolicyNew('method')} />,
  options: [
    {
      value: APPROVE_CREDIT_MAX,
      label: <FormattedMessage id={intl.creditPolicyNew(USE_RISIKA_RECOMMENDATION)} />,
    },
    {
      value: APPROVE_WITH_VALUE,
      label: <FormattedMessage id={intl.creditPolicyNew(SET_YOUR_OWN_VALUE)} />,
    },
    {
      value: ADJUST_CREDIT_MAX,
      label: <FormattedMessage id={intl.creditPolicyNew(PERCENTAGE_OF_RISIKA_SCORE)} />,
    },
  ],
} as {
  title: JSX.Element
  options: { value: Method; label: JSX.Element }[]
}
