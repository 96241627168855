import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { classes } from './styles'
import CloseIcon from '@mui/icons-material/Close'
import { useSupportContext } from './SupportContext'
import { profilePictures } from 'pages/home/scenes/MonitorOverview/profilePictures'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useResponsibleUser } from 'services/queries/useResponsibleUser'

const InitialChatPrompt = () => {
  const { setSupportDisplay } = useSupportContext()

  const [contact, setContact] = useState<Record<string, string>>({})
  const { data: responsibleUser } = useResponsibleUser()

  useEffect(() => {
    if (responsibleUser?.data?.name && responsibleUser?.data?.email) {
      setContact({
        imageUrl:
          profilePictures?.[
            responsibleUser?.data.email as keyof typeof profilePictures
          ] ?? profilePictures.dummy_blue,
      })
    }
  }, [responsibleUser])

  return (
    <Box sx={classes.parentPromptContainer}>
      <img src={contact.imageUrl} style={classes.contactPopup} alt=""></img>
      <Box sx={classes.contentContainer}>
        <Box sx={classes.textContainer}>
          <Typography variant="body1">
            <FormattedMessage id={intl.faq('prompt-desc')} />
          </Typography>
        </Box>
        <CloseIcon
          sx={classes.closeIcon}
          onClick={() => setSupportDisplay(false)}
          data-cy="prompt-close-icon"
        />
      </Box>
    </Box>
  )
}

export default InitialChatPrompt
