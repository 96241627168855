import * as React from 'react'
import {
  Checkbox,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from '@mui/material'
import intl from 'localization/components'
import { useIntl } from 'react-intl'
import { styles } from './styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { classes } from '../../Table.styles'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'

const FilterMultiSelect = ({
  id,
  filterData,
  handleFilters,
  tag,
  dataCy,
}: {
  id: string
  filterData: any
  handleFilters: any
  tag: string
  dataCy?: string
}) => {
  const { messages: translate, formatMessage } = useIntl()
  /* Besides the fact that Select is a controlled component (through MenuItem OnClick),
  MUI requires that Select 'value' prop must be set with correct value */
  const [firstKey] = Object.keys(filterData)
  if (!firstKey) return null
  const selectValue = filterData?.[firstKey]?.value

  if (!selectValue) {
    return null
  }

  return (
    <FormControl sx={{ width: 240, ...classes.table.greyField }} data-cy={dataCy}>
      <Select
        size="small"
        sx={styles.select}
        value={selectValue}
        renderValue={() => (
          <InputLabel sx={styles.inputLabel}>
            {formatMessage({ id: intl.observationalLists(tag) })}
          </InputLabel>
        )}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon {...props} sx={styles.dropdownArrow} />
        )}
      >
        {Object.keys(filterData).map((filterItem: string, index: number) => {
          let itemLabel = translate[filterData[filterItem].label]
          if (id === apiKeys.violations || id === apiKeys.highlights) {
            itemLabel = filterData[filterItem].label
          }

          return (
            <MenuItem
              key={filterItem}
              value={filterData[filterItem]?.value}
              onClick={() => handleFilters(tag, filterItem)}
              data-testid={`dropdown-option-${index}`}
            >
              <Checkbox checked={filterData[filterItem].checked} />
              <ListItemText
                primary={itemLabel as string}
                sx={
                  filterData[filterItem]?.color && {
                    color: filterData[filterItem].color ?? '#000000',
                  }
                }
              />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default FilterMultiSelect
