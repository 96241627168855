import * as React from 'react'
import { Stack } from '@mui/material'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { SearchMultiSelect } from 'components-new'
import { DropdownMultiSelectProps } from './Actions.types'
import { OptionsType } from 'components-new/inputs/SearchMultiSelectOld/SearchMultiSelect.types'
import { Option } from 'types/general'
import {
  OptionOption,
  OptionsOption,
} from 'components-new/inputs/SearchMultiSelect/SearchMultiSelect.types'
const DropdownMultiSelect = (
  { label, setIsConfirmEnabled, apiData, data }: DropdownMultiSelectProps,
  ref: React.ForwardedRef<SubmitRef>
) => {
  const [searchText, setSearchText] = React.useState('')
  const [companyTypeList, setCompanyTypeList] = React.useState<OptionsOption[]>([])
  const { state, actions } = useBuilder()
  React.useEffect(() => {
    if (apiData?.include) {
      const include = apiData?.include as string[]
      const updatedList = include.reduce(
        (acc: (string | number)[], item: string | number) => {
          const found = data.find((dataItem) => dataItem.value === item)
          if (found) {
            acc.push(found.value)
          }
          return acc
        },
        []
      )

      setCompanyTypeList(updatedList)
    }
  }, [apiData, data])

  React.useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        if (state.actionPanelData.nodeId) {
          actions.setNodeApiData({
            nodeId: state.actionPanelData.nodeId,
            data: { include: companyTypeList as string[] },
          })
        }
      },
    }),
    [actions, state.actionPanelData.nodeId, companyTypeList]
  )
  return (
    <Stack data-cy="company-type-multi-select" spacing={5} height={1}>
      <SearchMultiSelect
        title={label}
        searchText={searchText}
        setSearchText={setSearchText}
        setIsValid={setIsConfirmEnabled}
        options={data as OptionsType[] | Option<OptionOption>[]}
        values={companyTypeList.sort((a, b) => (a > b ? 1 : -1)) as string[]}
        setValues={setCompanyTypeList}
        // selectAll={true}
      />
    </Stack>
  )
}

export default React.forwardRef(DropdownMultiSelect)
