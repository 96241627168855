const tableStyle = {
  borderTop: 'none',
  tableLayout: 'fixed',
  width: '100%',
  overflow: 'hidden',
}
const tableRow = {
  backgroundColor: 'grey.100',
  height: '5rem',
}

export const classes = { tableStyle, tableRow }
