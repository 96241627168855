import * as React from 'react'
import { Collapse } from '@mui/material'
import { Show } from 'components'
import { BodyCells } from './BodyCells'
import { TableListView } from 'components-new'
import { RecursiveTableProps, StateInjectorProps } from '../HierarchyListView.types'

function StateInjector({
  row,
  key,
  isLastChild,
  collapseAllCounter,
  increaseDepth,
  depth,
}: StateInjectorProps) {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (collapseAllCounter < 0) {
      setOpen(false)
    }
  }, [collapseAllCounter])

  return (
    <React.Fragment key={key}>
      <TableListView
        tableSize="small"
        bodyCells={
          <BodyCells
            row={row}
            open={open}
            setOpen={setOpen}
            isLastChild={isLastChild}
            increaseDepth={increaseDepth}
            depth={depth + 1}
          />
        }
      />
      <Show when={!!row?.children?.length}>
        <Collapse in={open} unmountOnExit>
          <RecursiveTable
            collapseAllCounter={collapseAllCounter}
            data={row.children!}
            increaseDepth={increaseDepth}
            depth={depth + 1}
          />
        </Collapse>
      </Show>
    </React.Fragment>
  )
}

function RecursiveTable({
  data,
  collapseAllCounter,
  increaseDepth,
  depth,
}: RecursiveTableProps) {
  return (
    <>
      {data?.map((row, i) => (
        <StateInjector
          key={row?.local_id?.id ?? '' + row?.local_id?.country}
          row={row}
          isLastChild={data?.length - 1 === i}
          collapseAllCounter={collapseAllCounter}
          increaseDepth={increaseDepth}
          depth={depth}
        />
      ))}
    </>
  )
}

export default RecursiveTable
