import { default as cx } from 'classnames'
import { useStyles } from '../styles/input'
//
// type InputProps = {
//   className?: any,
//   id?: string,
//   label?: any,
//   helperText?: any,
//   error?: boolean,
// }

const Input = ({ className, id, label, helperText, error, ...props }) => {
  const classes = useStyles({
    error: error ?? false,
  })

  return (
    <>
      {label ? (
        <label className={classes.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <input {...props} id={id} className={cx(classes.input, className)} />
      <p className={classes.helperText}>{helperText}</p>
    </>
  )
}

export default Input
