import { DOMAIN as _ } from 'configs/constants/system'

/**************
 * Saga calls *
 **************/
export const SUBMIT_FETCH_COMPANY_INFORMATION = `${_} SUBMIT_FETCH_COMPANY_INFORMATION`
export const SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION = `${_} SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION`
export const SUBMIT_FETCH_COMPANY_HIGHLIGHT_INFORMATION = `${_} SUBMIT_FETCH_COMPANY_HIGHLIGHT_INFORMATION`
export const SUBMIT_UPDATE_STALE_DATA = `${_} SUBMIT_UPDATE_STALE_DATA`

/****************
 * Action calls *
 ****************/
export const STORE_CREDIT_CHECK_INFORMATION = `${_} STORE_CREDIT_CHECK_INFORMATION`
export const STORE_CREDIT_CHECK_INFORMATION_FAILED = `${_} STORE_CREDIT_CHECK_INFORMATION_FAILED`
export const STORE_CREDIT_INFORMATION = `${_} STORE_CREDIT_INFORMATION`
export const UPDATE_HIGHLIGHT_INFORMATION = `${_} UPDATE_HIGHLIGHT_INFORMATION`
export const HANDLE_UPDATE_STALE_DATA_SUCCESS = `${_} HANDLE_UPDATE_STALE_DATA_SUCCESS`
export const HANDLE_FLUSH_CACHED_PAGES = `${_} HANDLE_FLUSH_CACHED_PAGES`
