import { takeEvery, put } from 'redux-saga/effects'
import { FETCH_ACCOUNT_STATUS } from './constants'
import { updateAccountStatusSuccess } from './actions'
import { getAccountStatusData } from 'utils/getAccountStatusData'

/***************
 * Saga Worker *
 ***************/

function* fetchAccountStatus() {
  try {
    const permissions = getAccountStatusData()
    yield put(updateAccountStatusSuccess(permissions))
  } catch (err) {
    console.log('failed to fetch company data "permissions" ', err)
  }
}

/****************
 * Saga Watcher *
 ****************/

function* permissionsSaga() {
  yield takeEvery(FETCH_ACCOUNT_STATUS, fetchAccountStatus)
}

export default permissionsSaga
