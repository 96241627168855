import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { ButtonDestructive, ButtonSecondary, ButtonQuaternary } from 'components-new'

import { Stack } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from '../../localization/components'

const ActionDialog = (props) => {
  const [open, setOpen] = React.useState(false)

  const {
    title,
    content,
    action,
    render,
    removeActions,
    disabled = false,
    cancelText = <FormattedMessage id={intl.confirmAction('cancel')} />,
    agreeText = <FormattedMessage id={intl.confirmAction('agree')} />,
    maxWidth,
    fullWidth = false,
    onClose,
    danger = false,
  } = props

  const styles = {
    title: {
      backgroundColor: 'primary.dark',
      mb: 2,
    },
    titleText: { color: 'common.white' },
    actions: {
      pr: 5,
      pb: 4,
    },
  }

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const handleAction = () => {
    handleClose()

    if (typeof action === 'function') {
      action()
    }
  }
  return (
    <>
      {render(handleClickOpen)}
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={styles.title} id="alert-dialog-title">
          <Typography variant="h3" sx={styles.titleText}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {typeof content === 'function' ? content(handleClose) : content}
        </DialogContent>
        {!removeActions ? (
          <DialogActions sx={styles.actions}>
            <Stack spacing={1}>
              <ButtonQuaternary data-cy="action-dialog-cancel" onClick={handleClose}>
                {cancelText}
              </ButtonQuaternary>
              {danger ? (
                <ButtonDestructive
                  data-cy="action-dialog-agree"
                  onClick={handleAction}
                  autoFocus
                  disabled={disabled}
                >
                  {agreeText}
                </ButtonDestructive>
              ) : (
                <ButtonSecondary
                  data-cy="action-dialog-agree"
                  onClick={handleAction}
                  autoFocus
                  disabled={disabled}
                >
                  {agreeText}
                </ButtonSecondary>
              )}
            </Stack>
          </DialogActions>
        ) : (
          ''
        )}
      </Dialog>
    </>
  )
}

export default ActionDialog
