import { authRoles } from 'components/auth'
import ObservationalListsPage from './ObservationalListsPage'
import { navigation } from 'services/navigation'

export const ObservationalListsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: `${navigation.observationalLists()}/:listId/:tableFilterId/:tableFilterCategory/:tableFilterValue`,
      component: ObservationalListsPage,
    },
    {
      path: `${navigation.observationalLists()}/:listId/:tableFilterId/:tableFilterCategory`,
      component: ObservationalListsPage,
    },
    {
      path: `${navigation.observationalLists()}/:listId/:tableFilterId`,
      component: ObservationalListsPage,
    },
    {
      path: `${navigation.observationalLists()}/:listId`,
      component: ObservationalListsPage,
    },
    {
      path: navigation.observationalLists(),
      component: ObservationalListsPage,
    },
  ],
}
