import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

async function registerPersonVisit(localId: LocalId): Promise<void> {
  return risikaAPI.post(
    '/statistics/person_visits/register',
    { local_person_id: localId },
    {
      microservice: 'data',
    }
  )
}
export default registerPersonVisit
