import { RefObject } from 'react'
import ReactTable from 'react-table'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import intl from 'localization/components'
import TablePaginationToolbar from 'components-new/data-display/Table/elements/TablePaginationToolbar'
import { defaultPageLimits } from 'components-new/data-display/Table/Table.model'

type PaginatedProps = {
  page: number
  pages: number
  pageSize: number
  onPageChange: (number: number) => void
  onPageSizeChange: (number: number) => void
  pageSizeOptions?: number[]
}

type PaginationType = {
  from: number
  to: number
  currentPage: number
  pageSize: number
}

interface IntlReactTableProps extends WrappedComponentProps<'intl'> {
  companyCount: number
  boxRef: RefObject<HTMLDivElement>
  loadNextResults: (payload: PaginationType) => void
  pagination: PaginationType
}

const PaginatedIntlReactTable = (props: IntlReactTableProps) => {
  const {
    intl: { messages },
    companyCount,
    boxRef,
    loadNextResults,
    pagination,
  } = props
  const { pageSize, currentPage } = pagination
  const intlProps = {
    previousText: messages[intl.table('previous')],
    nextText: messages[intl.table('next')],
    loadingText: messages[intl.table('loading')],
    noDataText: messages[intl.table('no-rows')],
    pageText: messages[intl.table('page')],
    ofText: messages[intl.table('of')],
    rowsText: messages[intl.table('rows')],
  }

  const handlePagination = (paginationProps: PaginatedProps) => {
    const handlePageChange = (newPage: number) => {
      const nextFrom = (newPage - 1) * pageSize
      const nextTo = nextFrom + pageSize
      if (boxRef.current) {
        boxRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
      loadNextResults({
        from: nextFrom,
        to: companyCount >= 0 && companyCount < nextTo ? companyCount : nextTo,
        currentPage: newPage,
        pageSize,
      })
    }

    const handlePageLimitSelection = (limit: number) => {
      const nextFrom = 0
      const nextTo = limit
      if (boxRef.current) {
        boxRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
      loadNextResults({
        from: nextFrom,
        to: nextTo,
        currentPage: 1,
        pageSize: limit,
      })
    }

    return (
      <TablePaginationToolbar
        page={currentPage}
        totalPages={Math.ceil(companyCount / pageSize)}
        handlePage={handlePageChange}
        perPageLimit={pageSize}
        handlePageLimit={handlePageLimitSelection}
        pageLimits={defaultPageLimits}
      />
    )
  }

  // @ts-ignore: need to update the way this table is called (CHORE)
  // should now be making use of the useTable hook rather than calling directly
  // may be worth overhauling completely to new table model anyway
  return <ReactTable PaginationComponent={handlePagination} {...props} {...intlProps} />
}

export default injectIntl(PaginatedIntlReactTable)
