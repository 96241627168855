import intl from 'localization/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConnectedBankruptciesCategory } from 'types/queries'
import numeral from 'numeral'
import { handleUnit } from '../Rule'
import { CONNECTED_BANKRUPTCIES_IN_THE_LAST_50_YEARS } from '../../PolicyBuilder.model'
import { Template } from 'risika-api-response-types/account/enterprise_credit_policies/support/variables'

type ConnectedBankruptciesData = {
  apiData: ConnectedBankruptciesCategory
  template?: Template['template'][0]
}
function ConnectedBankruptciesContent({ apiData, template }: ConnectedBankruptciesData) {
  const { formatMessage } = useIntl()

  if (!template || !apiData.tolerance) {
    return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }
  return (
    <FormattedMessage
      id={intl.creditPolicyNew('connected-bankruptcies-node-content')}
      values={{
        category: Number(
          apiData.category === CONNECTED_BANKRUPTCIES_IN_THE_LAST_50_YEARS
        ),
        tolerance: formatMessage({
          id: intl.creditPolicyNew(apiData.tolerance),
        }).toLowerCase(),
        min: handleUnit(numeral(apiData.value_a).format('0,0'), template),
        max: handleUnit(numeral(apiData.value_b).format('0,0'), template),
      }}
    />
  )
}

export default ConnectedBankruptciesContent
