import { Box, Skeleton, Stack } from '@mui/material'

const EstimationBoxLoading = () => (
  <Stack py={2} spacing={2}>
    <Stack p={4} boxShadow={2} width={0.98} mb={2} mr={2} ml="2px" spacing={0}>
      <Skeleton width={'30%'} height={58} />
      <Box
        borderRadius={'50%'}
        height={130}
        overflow={'hidden'}
        width={0.5}
        margin={'auto'}
      >
        <Skeleton sx={{ marginTop: '-165px' }} height={300} />
      </Box>
    </Stack>
    <Stack p={4} boxShadow={2} width={0.98} mb={2} mr={2} ml="2px" spacing={0}>
      <Skeleton width={'30%'} height={58} />
      <Skeleton height={75} />
      <Skeleton height={75} />
    </Stack>
  </Stack>
)

export default EstimationBoxLoading
