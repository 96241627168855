import React, { useRef } from 'react'
import { withSnackbar } from 'notistack'
import { Stack, Box, Typography, useMediaQuery } from '@mui/material'
import { HistoryHeader } from 'components'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import CompanyTable from './scenes/CompanyTable'
import { useSelector, useDispatch } from 'react-redux'
import FilterList from './scenes/FilterList'
import CountryPicker from './scenes/CountryPicker/index'
import { getFilterSuggestionsRequest } from 'store_deprecated/filter-suggestions/actions'
import FetchFilterData from './scenes/FetchFilterData'
import Loader from './scenes/Loader'
import ButtonRequestExport from './scenes/ButtonRequestExport'
import { transformFilters } from './controller/general'
import SelectFilters from './scenes/SelectFilters'
import LoadFilters from './scenes/LoadFilters'
import FilterOptions from './scenes/FilterOptions'
import { useAccountStatus } from 'services/queries'
import { ButtonPrimary, ButtonSecondary } from 'components-new'
import AddIcon from '@mui/icons-material/Add'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

import { classes } from './styles/advanced-filters-page'
import { ReduxRootState } from 'store_deprecated/types'
import { Theme } from '@mui/material/styles'
import { AvailableCountries, AvailableCountriesLower } from 'globalTypes'
import { SupportedCountry } from 'types/general'
import {
  updateFilter,
  removeFilter,
  resetFilters,
  updateFilterOption,
  updatePagination,
  setFilterCountry,
  fetchSavedFilters,
} from 'store_deprecated/advanced-filters'
import { SaveFilters } from './widgets'
import { isEqual } from 'lodash'

const buttonStyles = { height: 44 }

const NoFilterList = () => {
  return (
    <Stack
      spacing={1}
      p="20px"
      alignItems="center"
      sx={{ border: '1px dashed', borderColor: 'grey.800', fontSize: 24 }}
    >
      <FilterAltIcon fontSize="inherit" />
      <Typography variant="body1">
        <FormattedMessage id={intl.advancedSearch('no-filters')} />
      </Typography>
    </Stack>
  )
}

const AdvancedFiltersPage = () => {
  const boxRef = useRef<HTMLDivElement>(null)
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const dispatch = useDispatch()
  const accountStatusQuery = useAccountStatus()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  const [sortBy, setSort] = React.useState(null)

  const allow_countries = accountStatusQuery.data?.rights.allow_countries

  const {
    filterCountry,
    companyData,
    companyCount,
    selectedFilters,
    filterOptions,
    savedFilters,
    pagination,
  } = useSelector(
    (state: ReduxRootState) => ({
      filterCountry: state.risika.advancedFilters.filterCountry.toLowerCase(),
      companyData: state.risika.advancedFilters.companyResults,
      companyCount: state.risika.advancedFilters.companyCount,
      selectedFilters: state.risika.advancedFilters.filters,
      filterOptions: state.risika.advancedFilters.filterOptions,
      savedFilters: state.risika.advancedFilters.savedFilters,
      pagination: state.risika.advancedFilters.pagination,
    }),
    // shallowEqual
    (prev, next) => isEqual(prev, next) // This checks for deep equality, because shallowEqual causes too many re-renders
  )

  React.useEffect(() => {
    if (
      allow_countries?.length &&
      !allow_countries?.includes(filterCountry as AvailableCountries)
    ) {
      dispatch(setFilterCountry(allow_countries?.[0]))
    }
  }, [allow_countries, dispatch, filterCountry])

  React.useEffect(() => {
    dispatch(fetchSavedFilters())
  }, [dispatch])

  React.useEffect(() => {
    if (
      allow_countries?.length &&
      !allow_countries?.includes(filterCountry as AvailableCountries)
    ) {
      dispatch(getFilterSuggestionsRequest(allow_countries?.[0]))
    } else {
      dispatch(getFilterSuggestionsRequest(filterCountry))
    }
  }, [filterCountry, dispatch, allow_countries])

  const handleRemove = React.useCallback(
    (filter: Record<string, unknown>) => {
      dispatch(removeFilter(filter))
    },
    [dispatch]
  )

  const handleChange = React.useCallback(
    (filter: Record<string, unknown>) => {
      dispatch(updateFilter(filter))
    },
    [dispatch]
  )

  const handleFilterOptionsChange = React.useCallback(
    (option: Record<string, unknown>) => {
      dispatch(updateFilterOption(option))
    },
    [dispatch]
  )

  const handleChangeCountry = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setFilterCountry(event.target.value))
    },
    [dispatch]
  )

  const handleLoadNextResults = React.useCallback(
    (pagination: Record<string, number>) => dispatch(updatePagination(pagination)),
    [dispatch]
  )

  const handleResetFilters = React.useCallback(() => dispatch(resetFilters()), [dispatch])
  return (
    <Box sx={{ height: 'calc(100VH - 64px)' }} overflow="hidden">
      <HistoryHeader
        title={messages[intl.advancedFilters('title')] as string}
        location="advancedfilters"
        historyKey="advancedfilters"
        titleExtraProps={{ paddingY: '8px' }}
      />
      <Box
        id="main-content-custom"
        overflow="scroll"
        sx={{ height: 'calc(100% - 60px)' }}
      >
        <Stack sx={{ marginTop: 6, paddingX: 6, gap: 6 }}>
          <Stack gap={4} flexWrap="wrap" direction={isSmallScreen ? 'column' : 'row'}>
            {/* @ts-expect-error - The component is JS */}
            <CountryPicker
              allowedCountries={allow_countries}
              value={filterCountry as SupportedCountry}
              onChange={handleChangeCountry}
              style={classes.countryPicker}
            />
            <SelectFilters
              render={(open: () => void) => (
                <ButtonPrimary
                  startIcon={<AddIcon />}
                  onClick={open}
                  data-cy="add-filters"
                  sx={buttonStyles}
                >
                  <FormattedMessage id={intl.advancedSearch('add-filters')} />
                </ButtonPrimary>
              )}
            />
            <LoadFilters
              filters={savedFilters}
              title={<FormattedMessage id={intl.advancedSearch('load-filters-title')} />}
              render={(open: () => void) => (
                <ButtonSecondary
                  data-cy="load-saved-filters"
                  startIcon={<FileUploadIcon />}
                  onClick={open}
                  sx={buttonStyles}
                >
                  <FormattedMessage id={intl.advancedSearch('load-filters')} />
                </ButtonSecondary>
              )}
            />
            {selectedFilters?.length ? (
              <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={6}>
                <SaveFilters
                  filters={selectedFilters}
                  country={filterCountry as AvailableCountriesLower}
                />
                <ButtonSecondary
                  data-cy="reset-filters"
                  onClick={handleResetFilters}
                  sx={buttonStyles}
                >
                  <FormattedMessage id={intl.advancedSearch('reset')} />
                </ButtonSecondary>
              </Stack>
            ) : null}
          </Stack>
          <Box>
            {selectedFilters?.length ? (
              <FilterList
                onChange={handleChange}
                onRemove={handleRemove}
                filters={selectedFilters}
              />
            ) : (
              <NoFilterList />
            )}
          </Box>
        </Stack>
        <Box ref={boxRef} />
        <Stack gap={4} sx={classes.exportBar}>
          <FilterOptions onChange={handleFilterOptionsChange} options={filterOptions} />
          <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
            <Typography sx={classes.exportBarText} variant="body2">
              {companyCount && (
                <FormattedMessage
                  id={intl.exportButton('description')}
                  values={{ count: companyCount }}
                />
              )}
            </Typography>
            {/* @ts-expect-error - The component is JS */}
            <ButtonRequestExport
              companyResultsLength={companyData.length}
              disabled={companyCount === 0}
              filters={{
                // @ts-expect-error - The component is JS
                filters: transformFilters(selectedFilters, filterOptions),
                country: filterCountry,
              }}
            />
          </Stack>
        </Stack>
        <Box position="relative">
          <FetchFilterData
            filterOptions={filterOptions}
            filters={selectedFilters}
            onError={(error: Error) => console.log(error)}
            placeholder={<Loader />}
            sortBy={sortBy}
            country={filterCountry}
            pagination={pagination}
          />
          <CompanyTable
            boxRef={boxRef}
            companyCount={companyCount}
            loadNextResults={handleLoadNextResults}
            data={companyData}
            setSort={setSort}
            sortBy={sortBy}
            pagination={pagination}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default withSnackbar(AdvancedFiltersPage)
