import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'

/******************
 * Fetch from API *
 ******************/
export interface CompanyBulkResponse {
  local_id: LocalId['id']
  country: LocalId['country']
  name: string
}

export type GetCompanyBulkInformationParams = {
  localIdList: LocalId['id'][]
  country: LocalId['country']
}

const getCompanyBulkInformation = async ({
  localIdList,
  country,
}: GetCompanyBulkInformationParams): Promise<CompanyBulkResponse[]> => {
  const local_organization_ids = localIdList
    .map((x) => ({
      local_organization_id: { id: x, country: country?.toUpperCase() },
    }))
    .filter((x) => x.local_organization_id.id !== '')
  const { data } = (await risikaAPI.post(
    `/company/basics_list`,
    // TODO: Update this later
    {
      local_organization_ids,
      fields: ['company_name', 'local_organization_id'],
    },
    {
      microservice: 'data',
    }
  )) as {
    data: {
      company_name: string
      local_organization_id: {
        id: LocalId['id']
        country: LocalId['country']
      }
    }[]
  }

  return data.map((company) => ({
    local_id: company.local_organization_id.id,
    country: company.local_organization_id.country,
    name: company.company_name,
  }))
}

export default getCompanyBulkInformation
