const editButtonStyles = {
  borderRadius: '50%',
  backgroundColor: 'grey.100',
  padding: 3,
  fontSize: 15,
  color: 'primary.700',
}

const inputBoxStyles = {
  width: 200,
  backgroundColor: 'common.white',
  top: 64,
  position: 'absolute',
  zIndex: 2,
  padding: 2,
}

export default {
  editButtonStyles,
  inputBoxStyles,
}
