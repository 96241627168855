import { Box, IconButton, AppBar as MUIAppBar, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { StyledNavbarWrapper, StyledImage } from 'styles/components/sidebar.js'
import { navbarCloseMobile } from 'store_deprecated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserMenu from './UserMenu'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useUserSelf } from 'services/queries'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Show from 'components/Show'

function AppBar() {
  const dispatch = useDispatch()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const { data } = useUserSelf()

  return (
    <MUIAppBar sx={{ backgroundColor: 'primary.dark' }} position="static" elevation={0}>
      <StyledNavbarWrapper>
        <Link to={'/'}>
          <StyledImage src={'/risika/logo-blue-white.svg'} alt="" />
        </Link>
        <Show when={!smUp}>
          <IconButton onClick={() => dispatch(navbarCloseMobile())} color="inherit">
            <FontAwesomeIcon icon={faBars as IconProp} />
          </IconButton>
        </Show>
      </StyledNavbarWrapper>
      <Show when={!smUp}>
        <Box mx="auto" mb={2}>
          <UserMenu name={data?.name} role={data?.role} />
        </Box>
      </Show>{' '}
    </MUIAppBar>
  )
}

export default AppBar
