import risikaAPI from 'services/api/helpers/risikaAPI'
import { Subscription, SubscriptionType } from './subscriptionUpdate'
import { AxiosPromise } from 'axios'

/******************
 * Fetch from API *
 ******************/

type FrequencyType =
  | 'daily'
  | 'monthly'
  | 'weekly:monday'
  | 'weekly:tuesday'
  | 'weekly:wednesday'
  | 'weekly:thursday'
  | 'weekly:friday'
  | 'weekly:saturday'
  | 'weekly:sunday'

export type MonitorSubscriptionFields = {
  data: {
    status?: boolean
    score?: boolean
    annual_reports?: boolean
    company_type?: boolean
    relations?: boolean
    name?: boolean
    address?: boolean
    email?: boolean
    phone_number?: boolean
    industry?: boolean
    employees?: boolean
    list_id?: number
    frequency?: FrequencyType
    gazette?: boolean
    policy_outcome?: boolean
  }
}

export type TypeData = {
  type: SubscriptionType
}
// TODO - change return type to Array
const subscriptionStatus = (): AxiosPromise<Subscription[]> => {
  return risikaAPI.get(`/show/all?type=MONITOR`, {
    microservice: 'subscription',
  })
}

export default subscriptionStatus
