import { useQuery } from 'react-query'
import { financialPerformance } from 'services/api'
import { isCallAllowed } from 'configs'
import { AvailableCountries, LocalId } from 'globalTypes'

export function useFinancialPerformance(
  localId: LocalId,
  staleTime?: number,
  cacheTime?: number
) {
  return useQuery(
    ['financialPerformance', localId.id, localId.country],
    () => financialPerformance(localId),
    {
      staleTime,
      enabled: !!isCallAllowed(
        'financialPerformance',
        localId.country as AvailableCountries
      ),
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime,
      onError: () => console.log('error boo boo'),
    }
  )
}
