import { useState } from 'react'
import ChangePassword from './ChangePassword'
import ChooseLanguageAndCurrency from './ChooseLanguageAndCurrency'

const ChangePasswordFlow = () => {
  const [isLangAndCurrencyOpen, setIsLangAndCurrencyOpen] = useState<boolean>(true)
  const [isPasswordOpen, setIsPasswordOpen] = useState<boolean>(false)

  const nextStep = () => {
    setIsLangAndCurrencyOpen(false)
    setIsPasswordOpen(true)
  }

  return (
    <>
      <ChooseLanguageAndCurrency
        isOpen={isLangAndCurrencyOpen}
        handleNextStep={nextStep}
      />
      <ChangePassword isOpen={isPasswordOpen} />
    </>
  )
}

export default ChangePasswordFlow
