import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import ErrorOccurred from 'components-new/elements/ErrorOccurred'
import { FittedStackedBarChartPropsType } from './FittedStackedBarChart.types'
import { AbbreviatedNumbersAxis } from 'components-new/charts/customizations'
import { classTooltip } from 'styles-new/global/components'
import { Stack, Box } from '@mui/material'
import { theme } from 'styles-new/mui5-transition/theme'

const FittedStackedBarChart = ({
  data,
  chartProperties,
}: FittedStackedBarChartPropsType) => {
  if (data.length === 0 || !data) {
    return (
      <>
        <ErrorOccurred />
      </>
    )
  }
  const barKeys = Object.keys(data[0]).filter((key) => key !== 'name')

  // This should prevent ReCharts bug of not displaying top labels
  // by preventing the animation of the chart
  // https://github.com/recharts/recharts/issues/829
  const animationActive = !chartProperties.bar.label

  /*
  Unfortunately, props types here must be any, because
  props are injected by ReCharts
  */
  const CustomLabelList = (props: any) => {
    return (
      <text
        x={props.x - 5}
        y={props.y - 10}
        fill={props.fill}
        height={props.height}
        width={props.width}
        stroke={props.stroke}
        orientation={props.orientation}
        textAnchor={props.textAnchor}
      >
        {data[props.index].Low}
      </text>
    )
  }

  const CustomTooltip = (props: any) => {
    if (!props.payload.length) return <></>

    const item = props.payload

    // we are using reversed payload
    // to match bars color order with tooltip
    const reversed = [...item].reverse()

    return (
      <Box sx={{ ...classTooltip, fontWeight: 700 }}>
        {props.label}
        {reversed.map((entry: any) => (
          <Box key={entry.name} sx={{ color: entry.color, fontWeight: 400 }}>
            {entry.name}: {entry.value}%
          </Box>
        ))}
      </Box>
    )
  }

  const CustomLegend = (props: any) => {
    if (!props.payload.length) return <></>

    const item = props.payload

    // we are using reversed payload
    // to match bars color order with legend
    const reversed = [...item].reverse()

    return (
      <Stack alignItems="center" justifyContent="center" direction="row">
        {reversed.map((entry: any) => (
          <Stack
            key={`item-${entry.value}`}
            alignItems="center"
            justifyContent="center"
            direction="row"
            width={'100%'}
          >
            <Box
              sx={{
                width: theme.spacing(4),
                height: theme.spacing(4),
                mr: '0.75rem',
                background: entry.color,
                color: entry.color,
              }}
            />
            {entry.value}
          </Stack>
        ))}
      </Stack>
    )
  }

  return (
    <ResponsiveContainer height={chartProperties.height}>
      <BarChart
        data={data}
        barCategoryGap={chartProperties.barCategoryGap}
        margin={chartProperties.margin}
      >
        {chartProperties.cartesianGrid && (
          <CartesianGrid strokeDasharray={chartProperties.strokeDasharray} />
        )}
        {chartProperties.xAxis && <XAxis dataKey={chartProperties.dataKey} />}
        {chartProperties.yAxis && <YAxis tick={<AbbreviatedNumbersAxis />} />}
        {chartProperties.tooltip && <Tooltip content={<CustomTooltip />} />}
        {chartProperties.legend && <Legend content={<CustomLegend />} />}
        {barKeys.map((barKey, index) => {
          let barRadius = chartProperties.bar.radius
          if (index === barKeys.length - 1) {
            barRadius = [5, 5, 0, 0]
          }
          return (
            <Bar
              key={barKey}
              dataKey={barKey}
              stackId={chartProperties.bar.stackId}
              fill={chartProperties.colors[index]}
              radius={barRadius}
              barSize={chartProperties.bar.barSize}
              isAnimationActive={animationActive /* ReCharts bug prevention */}
            >
              {chartProperties.bar.label && index === barKeys.length - 1 ? (
                <LabelList position="top" content={<CustomLabelList />} />
              ) : null}
            </Bar>
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default FittedStackedBarChart
