import { ReactNode } from 'react'

type HideProps = {
  children: ReactNode
  when: boolean
}

const Hide = (props: HideProps) => {
  const { children, when } = props
  if (when) {
    return null
  }

  return children
}

export default Hide
