import { KeyboardEvent } from 'react'
import { InputField } from 'components-new'
import { Typography } from '@mui/material'
import { NameCellProps } from '../../UserTable.types'

const NameCell = ({
  user,
  selectedUser,
  isSelected,
  handleUpdateUserName,
  handleSaveChanges,
  isAddingNewUser,
}: NameCellProps) => {
  const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isAddingNewUser) return
    if (e.key === 'Enter') handleSaveChanges()
  }
  if (isSelected && selectedUser) {
    return (
      <InputField
        autoFocus
        onKeyDown={handleEnterPress}
        value={selectedUser.name}
        handleChange={handleUpdateUserName}
      />
    )
  }

  return <Typography variant="body2">{user.name}</Typography>
}

export default NameCell
