import { takeEvery, call, put, all } from 'redux-saga/effects'
import history from 'utils/history'
import { handleComponentRenderRestrictions } from 'services/helpers'
import moment from 'moment'
import intl from 'localization/components'
import {
  companyBasics,
  companyHistory,
  companyRelations,
  financialNumbers,
  financialRatios,
  financialStats,
  financialPerformance,
  ratingCredit,
  ratingScores,
  eanNumber,
  highlights as allHighlights,
  getStatstidende,
  getDetailedNumberOfEmployees,
  getProductionUnits,
} from 'services/api'

import {
  SUBMIT_FETCH_COMPANY_INFORMATION,
  SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION,
  SUBMIT_FETCH_COMPANY_HIGHLIGHT_INFORMATION,
  SUBMIT_UPDATE_STALE_DATA,
} from './constants'

import {
  storeCreditCheckInformationSuccess,
  storeCreditCheckInformationFailure,
  storeCreditInformationSuccess,
  updateHighlightInformationSuccess,
  updateStaleDataSuccess,
} from './actions'

/**********
 * Watcher *
 **********/
export function* creditCheckSaga() {
  yield takeEvery(SUBMIT_FETCH_COMPANY_INFORMATION, fetchCompanyInfo)
  yield takeEvery(SUBMIT_FETCH_COMPANY_HIGHLIGHT_INFORMATION, fetchHighlightInfo)
  yield takeEvery(SUBMIT_UPDATE_STALE_DATA, fetchUpdateStaleData)
  yield takeEvery(SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION, fetchCompanyCreditInfo)
}

/**********
 * Worker *
 **********/
function* fetchCompanyInfo({ payload }) {
  const { localID, language } = payload
  try {
    const result = yield all({
      company: call(companyBasics, localID), // If this one fails we need to show error page!
      history: safe(call(companyHistory, localID)),
      ratingCredit: handleComponentRenderRestrictions('rating-credit')
        ? safe(call(ratingCredit, localID))
        : null,
      ratingScores: handleComponentRenderRestrictions('rating-credit')
        ? safe(call(ratingScores, localID))
        : null,
      highlights: safe(call(allHighlights, localID), language),
      financialRatios: safe(call(financialRatios, localID)),
      financialNumbers: safe(call(financialNumbers, localID)),
      financialNumbersConsolidated: safe(
        call(financialNumbers, localID, {
          consolidated: true,
        })
      ),
      relations: safe(call(companyRelations, localID)),
      financialStats: safe(call(financialStats, localID)),
      financialStatsConsolidated: safe(
        call(financialStats, localID, {
          consolidated: true,
        })
      ),
      ean: safe(call(eanNumber, localID)),
      financialPerformance: safe(call(financialPerformance, localID, language)),
      detailedNumberOfEmployees: safe(call(getDetailedNumberOfEmployees, localID.id)),
      productionUnits: safe(call(getProductionUnits, localID.id)),
      statstidende:
        localID.country === 'dk' ? safe(call(getStatstidende, localID)) : null,
      // expiresAt: moment(new Date(), 'DD-MM-YYYY').add(1, 'days'),
      stored: moment(),
      status: 'SUCCESS',
    })
    const updatedResult = {
      ...result,
      highlights: {
        ...result.highlights,
        language,
      },
    }
    yield all([
      put(
        storeCreditCheckInformationSuccess({
          result: updatedResult,
          localID,
        })
      ),
    ])
  } catch (err) {
    // The catch only handles failed 'Company Basics' requests
    if (!err.response) {
      history.push('/404')
    }
    const status = err?.response?.status
    if (status === 402) {
      history.push('/402')
    }
    if (status.toString().startsWith('5')) {
      history.push('/503')
    }
    if (status === 500) {
      history.push('/500')
    }
    yield put(storeCreditCheckInformationFailure())
  }
}

function* fetchCompanyCreditInfo({ payload }) {
  const { localID, enqueueSnackbar, messages } = payload
  try {
    const result = yield all({
      ratingCredit: call(ratingCredit, localID),
      ratingScores: call(ratingScores, localID),
      localID,
    })
    yield all([put(storeCreditInformationSuccess(result))])
    enqueueSnackbar(messages[intl.creditCheck('check-use-success')])
  } catch (err) {
    enqueueSnackbar(messages[intl.creditCheck('check-use-fail')])
  }
}
function* fetchHighlightInfo({ payload }) {
  const { localID, language } = payload
  try {
    const result = yield call(allHighlights, localID)
    yield all([
      put(updateHighlightInformationSuccess(result?.highlights, language, localID)),
    ])
  } catch (err) {
    console.log('failed to fetch highlight information', err)
  }
}
function* fetchUpdateStaleData({ payload }) {
  const { localID, language } = payload
  console.log('language in saga', language, 'local id', localID?.id)
  try {
    const result = yield all({
      company: call(companyBasics, localID), // If this one fails we need to show error page!
      highlights: safe(call(allHighlights, localID), language),
      financialRatios: safe(call(financialRatios, localID)),
      financialNumbers: safe(call(financialNumbers, localID)),
      financialNumbersConsolidated: safe(
        call(financialNumbers, localID, {
          consolidated: true,
        })
      ),
      financialStats: safe(call(financialStats, localID)),
      financialStatsConsolidated: safe(
        call(financialStats, localID, {
          consolidated: true,
        })
      ),
      financialPerformance: safe(call(financialPerformance, localID, language)),
    })
    yield all([
      put(
        updateStaleDataSuccess({
          highlights: result?.highlights.highlights,
          company: result?.company,
          financialRatios: result?.financialRatios,
          financialNumbers: result?.financialNumbers,
          financialNumbersConsolidated: result?.financialNumbersConsolidated,
          financialStats: result?.financialStats,
          financialStatsConsolidated: result?.financialStatsConsolidated,
          financialPerformance: result?.financialPerformance,
          language,
          localID,
        })
      ),
    ])
  } catch (err) {
    console.log('failed to refresh company data', err)
  }
}

// Handles error for non crucial endpoints
function* safe(effect) {
  try {
    return yield effect
  } catch (err) {
    return null
  }
}
