import { AvailableCurrencies, LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

export type RatingCredit = {
  upfront: null
  credit_days: number
  credit_max: number
  currency: string
}
async function ratingCredit(
  { id, country }: LocalId,
  { currency }: { currency?: AvailableCurrencies }
): Promise<RatingCredit> {
  let endpoint = `/rating/credit/${id}`
  if (currency) {
    endpoint += `?currency=${currency}`
  }

  return risikaAPI
    .get(endpoint, {
      microservice: 'data',
      country,
      appendScoringModel: true,
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return new Error(err)
    })
}

export default ratingCredit
