const chipStyles = {
  marginLeft: '0 !important',
  paddingX: 3,
  paddingY: 1,
  backgroundColor: 'primary.main',
  color: 'common.white',
  '& .MuiChip-deleteIcon': {
    color: 'inherit',
    '&:hover': { backgroundColor: 'primary.900', borderRadius: '50%', color: 'inherit' },
  },
}

export default { chipStyles }
