import Box from '@mui/material/Box'
import { RadialBarChart, RadialBar } from 'recharts'
import { RadialPercentChartProps } from './RadialPercentChart.types'
import { classCenterDiv } from 'styles-new/global/helpers'
import { colors } from 'configs'

export default function RadialPercentChart({
  value,
  fill = colors.riskIntelligence.lowRisk,
  outerRadius = 50,
  barSize = 5,
  cornerRadius = 0,
}: RadialPercentChartProps) {
  const data = [
    {
      name: 'model',
      value: 100,
      fill: 'white',
    },
    {
      name: 'target',
      value,
      fill,
    },
  ]

  /*
  PLEASE NOTE: Those two strange lines:
    innerRadius={6}
    outerRadius={outerRadius - 6}
  Instead od normal:
    innerRadius={0}
    outerRadius={outerRadius}
  are there to prevent Recharts bug: Error: <path> attribute d: Expected number,
  */

  return (
    <Box
      sx={{
        display: 'block',
        position: 'relative',
        width: `${outerRadius + barSize}px`,
      }}
    >
      <RadialBarChart
        width={outerRadius + barSize}
        height={outerRadius + barSize}
        innerRadius={6}
        outerRadius={outerRadius - 6}
        barSize={barSize}
        data={data}
      >
        <RadialBar background dataKey="value" cornerRadius={cornerRadius} />
      </RadialBarChart>
      <Box sx={{ ...classCenterDiv, color: fill, fontWeight: 'bold' }}>
        {value < 1 ? '<1%' : `${Math.round(value)}%`}
      </Box>
    </Box>
  )
}
