import { useSelector } from 'react-redux'
import { useAppShell } from 'components/AppShell/context'
import { useWindowSize } from 'react-use'
import CompanyTable from './CompanyTable'
import PeopleTable from './PeopleTable'
import { ReduxRootState } from 'store_deprecated/types'

export const SearchResults = () => {
  const appShell = useAppShell()
  const companyResults =
    useSelector((state: ReduxRootState) => state.risika.searchBusiness.companyResults) ??
    []

  const peopleResults =
    useSelector((state: ReduxRootState) => state.risika.searchBusiness.peopleResults) ??
    []

  const { height } = useWindowSize()

  const calculatesTableRows = () => {
    const rows = (+height - 64) / 65 - 2
    return rows > 20 ? rows : 20
  }

  return (
    <div style={{ backgroundColor: 'white' }} data-cy="search-results">
      {appShell.resultType === 'company' ? (
        <CompanyTable
          handleSelect={appShell.setSearchActive}
          data={companyResults}
          rowCount={calculatesTableRows()}
        />
      ) : (
        <PeopleTable
          handleSelect={appShell.setSearchActive}
          data={peopleResults}
          rowCount={calculatesTableRows()}
        />
      )}
    </div>
  )
}

export default SearchResults
