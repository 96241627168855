import * as React from 'react'
import { Option, ReactSetState } from 'types/general'
import { SelectAPIData } from './Actions.types'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { AsyncSearchMultiSelect } from 'components-new'
import { usePersonBulkInformation } from 'services/queries'
import { PolicyBuilderParams } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'
import { useActionPanelStateValidator, useDebounceText } from 'services/hooks'
import { stateValidators } from '../DetailedComponentView.controller'
import { PersonBulkResponse } from 'services/api/endpoints/risikaAPI/getPersonBulkInformation'
import { usePersonSearch } from 'services/queries/usePersonSearch'
import { PersonSearch as PersonSearchType } from 'services/api/endpoints/risikaAPI/searchPerson'
import { OptionsType } from 'components-new/inputs/AsyncSearchMultiSelect/AsyncSearchMultiSelect.types'
import intl from 'localization/components'
import { useIntl } from 'react-intl'
import { SubmitRef } from '..'
import { useBuilderParams } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.hooks'
import { OptionOption } from 'components-new/inputs/SearchMultiSelect/SearchMultiSelect.types'

type PersonSearchProps = {
  apiData: SelectAPIData
  setIsConfirmEnabled: ReactSetState<boolean>
}
function PersonSearch(
  { apiData, setIsConfirmEnabled }: PersonSearchProps,
  ref: React.ForwardedRef<SubmitRef>
) {
  const { state, actions } = useBuilder()
  const params = useBuilderParams() as PolicyBuilderParams
  const [searchText, debounceValue, setSearchText] = useDebounceText(200)
  const [personsList, setPersonsList] = React.useState<string[]>(apiData.include ?? [])
  const [options, setOptions] = React.useState<OptionsType[]>([])
  const { data: personBulkData, isLoading: useBulkInfoLoading } =
    usePersonBulkInformation({
      personIdList: personsList,
      country: params.country,
    })
  const {
    data: searchData,
    isLoading: searchLoading,
    isError: searchError,
  } = usePersonSearch(debounceValue as string, params.country)

  const messages = useIntl().messages

  React.useEffect(() => {
    function formatSearchData(
      data: PersonSearchType['search_result'],
      existingOptions: Option<string>[]
    ) {
      if (!data) return []
      const newOptions = data
        .map((person) => ({
          value: person.personal_id,
          label: person.name,
          secondaryLabel:
            person.active_company_relations.length > 0
              ? `(${person.active_company_relations.slice(0, 3).join(', ')} ${
                  person.active_company_relations.length > 3 ? '...' : ''
                }) `
              : '',
        }))
        .filter((option) => {
          // Check if option with the same value already exists in existingOptions
          return !existingOptions.some(
            (existingOption) => existingOption.value === option.value
          )
        })

      return newOptions
    }

    if (searchData?.search_result) {
      setOptions((prevState) => [
        ...prevState,
        ...(formatSearchData(searchData.search_result, prevState) as OptionsType[]),
      ])
    }
  }, [searchData])

  useActionPanelStateValidator(
    setPersonsList,
    stateValidators.companyTypeValidator,
    setIsConfirmEnabled
  )
  React.useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        if (state.actionPanelData.nodeId) {
          actions.setNodeApiData({
            nodeId: state.actionPanelData.nodeId,
            data: { include: personsList },
          })
        }
      },
    }),
    [actions, personsList, state.actionPanelData.nodeId]
  )

  function handleSetCompaniesList(value: string[]) {
    setPersonsList(value)
    setSearchText('')
    setOptions([])
  }

  const formatBulkData = (data: PersonBulkResponse | undefined) => {
    if (!data) return []
    return data
      .map((person) => ({
        value: person.entity_id.toString(),
        label: person.name,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  }
  return (
    <AsyncSearchMultiSelect
      placeholder={messages[intl.creditPolicyNew('search-people-placeholder')] as string}
      searchText={searchText}
      setSearchText={setSearchText as ReactSetState<string | number>}
      options={options}
      chipData={formatBulkData(personBulkData)}
      isLoading={useBulkInfoLoading || searchLoading}
      values={personsList}
      setValues={handleSetCompaniesList as ReactSetState<(string | OptionOption)[]>}
      inputProps={{
        error: searchError,
      }}
    />
  )
}

export default React.forwardRef(PersonSearch)
