import intl from 'localization/components'
import { financialRatios } from 'services/api'
import { financialNumbers } from 'services/api'
import { ratingScores } from 'services/api'
import { companyBasics } from 'services/api'
import { theme } from 'styles-new/mui5-transition/theme'

const {
  palette: { primary, warning, grey, info, success, error },
} = theme

export const reactQueries = {
  financialNumbers: { type: 'financialNumbers', action: financialNumbers },
  financialRatios: { type: 'financialRatios', action: financialRatios },
  ratingScores: { type: 'ratingScores', action: ratingScores },
  companyBasics: { type: 'companyBasics', action: companyBasics },
}

export const queryParameters = {
  solidityRatio: { ...reactQueries.financialRatios, key: 'solidity_ratio' },
  liquidityRatio: { ...reactQueries.financialRatios, key: 'current_ratio' },
  returnOnAssets: { ...reactQueries.financialRatios, key: 'return_on_assets' },
  results: { ...reactQueries.financialNumbers, key: 'profit_loss' },
  score: { ...reactQueries.ratingScores, key: 'score' },
}

export const lineChartButtons = [
  {
    chartType: 'ratio',
    label: intl.relevantFinancialRatios('solidity_ratio'),
    queryParameter: 'solidityRatio',
    domain: ['auto', 'auto'],
  },
  {
    chartType: 'ratio',
    label: intl.keyFiguresDevelopment('liquidity-ratio'),
    queryParameter: 'liquidityRatio',
    domain: ['auto', 'auto'],
  },
  {
    chartType: 'ratio',
    label: intl.advancedSearch('return_on_assets'),
    queryParameter: 'returnOnAssets',
    domain: ['auto', 'auto'],
  },
  {
    chartType: 'financial-number',
    label: intl.companyHierarchy('profit_loss'),
    queryParameter: 'results',
    domain: ['auto', 'auto'],
  },
  {
    chartType: 'score',
    label: intl.advancedFilters('risika-score'),
    queryParameter: 'score',
    domain: [1, 10],
  },
]

export const chartPropsDefinition = {
  name: 'Key figures development',
  chartType: 'ratio',
  lineType: 'monotone',
  cartesianGrid: false,
  xAxis: true,
  yAxis: true,
  tooltip: true,
  legend: true,
  domain: ['auto', 'auto'],
  strokeWidth: 3,
  margin: { top: 5, right: 30, left: 0, bottom: 5 },
  colors: {
    strokes: [
      primary[700],
      primary[500],
      warning[500],
      warning[700],
      grey[700],
      info[500],
      success[500],
      error[500],
      error[900],
      info[900],
    ],
  },
}
