import { Divider, Paper, Stack, Typography } from '@mui/material'

type PaperContainerProps = {
  title: React.ReactNode
  children: React.ReactNode
  customHeaderComponent?: React.ReactNode
  minHeight?: number
  minWidth?: number
}

function PaperContainer({
  title,
  children,
  customHeaderComponent,
  minHeight,
  minWidth,
}: PaperContainerProps) {
  return (
    <Paper
      sx={{ minHeight: minHeight ?? 'auto', minWidth: minWidth ?? 'auto' }}
      component={Stack}
    >
      <Stack
        sx={{ height: '58px' }}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        p={4}
      >
        <Typography variant="h5">{title}</Typography>
        {customHeaderComponent}
      </Stack>
      <Divider />
      {children}
    </Paper>
  )
}

export default PaperContainer
