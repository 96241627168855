import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

// type Region = {
//   region: string,
//   value: number[],
// }

const regionList = (country) => {
  return risikaAPI
    .get(`/list/regions`, {
      microservice: 'data',
      country,
    })
    .then(({ data }) => {
      const { regions } = data
      const returnData = Object.entries(regions).map(([region, value]) => ({
        region,
        value,
      }))
      return returnData
    })
}

export default regionList
