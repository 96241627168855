import { mergeRight } from 'ramda'

/**
 * Compose multiple validators.
 *
 * Some filters could have conflicting definitions,
 * this is solved by the order, meaning, the last added
 * validator will overwrite previously specified validators.
 *
 * Makes it easier to have small primitive validators
 * and the compose them all.
 *
 * Example: compose "phone number" and "not empty"
 *          to get a "phone number required" filter.
 *
 * @param validators The validators to compose together.
 */
export const pipeValidators = (...validators) => {
  return validators.reduce((acc, validator) => mergeRight(acc, validator))
}
