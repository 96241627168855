import { theme } from 'styles-new/mui5-transition/theme'

const main = {
  pt: '2px',
  pl: theme.spacing(1),
  '&:hover': {
    cursor: 'pointer',
  },
}

const disabledArrow = {
  color: theme.palette.grey[300],
  '&:hover': {
    cursor: 'default',
  },
}

export default {
  main,
  disabledArrow,
}
