import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import SingleSelect from 'components-new/inputs/SingleSelect'
import PaperContainer from 'pages/home/widgets/PaperContainer'
import { ShowListsType } from 'types/queries'
import PortfolioRiskDistribution from 'widgets/integrations/PortfolioInsights/widgets/Summary/widgets/PortfolioRiskDistribution'
import { singleSelect } from './PortfolioRiskHome.styles'
import useWindowDimensions from 'components-new/hooks/userWindowDimensions'
import { homePageSingleColumnWidth } from 'components-new/RecentChangesTable/utils'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

type PortfolioRiskProps = {
  data: ShowListsType | undefined
  isShowListsLoading: boolean
}

export const PortfolioRiskHome = (props: PortfolioRiskProps) => {
  const { data: showListsData, isShowListsLoading } = props

  const listNames = useMemo(() => {
    return showListsData?.map((list) => list.name) || []
  }, [showListsData])

  const listIds = useMemo(() => {
    return showListsData?.map((list) => list.list_id.toString()) || []
  }, [showListsData])

  const { width } = useWindowDimensions()

  const [dropdownValue, setDropdownValue] = useState(listNames[0])
  const [listValue, setListValue] = useState(listIds[0])

  const handleDropdownChange = (value: string) => {
    const selectedCompanyIndex = showListsData?.findIndex((list) => list.name === value)
    setListValue(listIds[selectedCompanyIndex!])
    setDropdownValue(value)
  }

  useEffect(() => {
    if (!listValue && listIds.length) {
      setListValue(listIds[0])
    }
  }, [listIds, listValue])

  useEffect(() => {
    if (!dropdownValue && listNames.length) {
      setDropdownValue(listNames[0])
    }
  }, [dropdownValue, listNames])

  return (
    <>
      <Box width={width > homePageSingleColumnWidth ? '29%' : '100%'}>
        <PaperContainer
          title={
            <FormattedMessage
              id={intl.portfolioInsights('portfolio-risk-distribution')}
            />
          }
          minHeight={550}
        >
          <SingleSelect
            options={listNames}
            value={dropdownValue}
            handleChange={handleDropdownChange}
            sx={singleSelect}
            optionIsRawString
            dataCy="portfolio-risk"
            isLoading={isShowListsLoading || !dropdownValue}
          />
          <PortfolioRiskDistribution
            listId={listValue}
            withTitle={false}
            showListsData={showListsData}
            isLoading={isShowListsLoading}
          />
        </PaperContainer>
      </Box>
    </>
  )
}
