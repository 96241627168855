import { Stack, Paper, Typography } from '@mui/material'
import { Show } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { CustomTooltipPropTypes } from './CustomTooltip.types'
import styles from './CustomTooltip.styles'
import { formatDate } from './CustomTooltip.controller'

const CustomTooltip = ({ payload }: CustomTooltipPropTypes) => {
  return (
    <Paper>
      {payload?.map(({ payload: data }) => (
        <Stack key={(data.from, data.to, data.name)} spacing={1} sx={styles.container}>
          <Typography variant="subtitle1">
            {<FormattedMessage id={intl.generic('risika-score')} />}: {data.score}
          </Typography>
          <Typography variant="body2">
            {<FormattedMessage id={intl.advancedSearch('period')} />}:{' '}
            {formatDate(data.from, data.to)}
          </Typography>
          <Show when={Boolean(data.probability_of_distress)}>
            <Typography variant="body2">
              {<FormattedMessage id={intl.financial('distress')} />}:{' '}
              {data?.probability_of_distress?.toFixed(2)}%
            </Typography>
          </Show>
        </Stack>
      ))}
    </Paper>
  )
}
export default CustomTooltip
