const container = {
  display: 'flex',
  flexDirection: 'column',
}
const wrapper = {
  mt: 4,
}
const label = {
  width: '49%',
}
const divider = {
  marginTop: '0.5rem',
  marginBottom: '1rem',
}

const main = {
  mt: 4,
}

const sectionTitle = {
  pb: 2,
}

export const classes = {
  container,
  wrapper,
  label,
  divider,
  main,
  sectionTitle,
}
