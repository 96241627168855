import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { logoutUser } from 'store_deprecated'
import { persistor } from 'context/store'
import { navigation } from 'services/navigation'
import { useAuth0 } from '@auth0/auth0-react'
import { isEmail } from 'utils-new/is-email'
import { authSsoCheckEmail } from 'services/api/helpers/authService'

export const PageContent = () => {
  const dispatch = useDispatch()
  const { logout } = useAuth0()
  const { authenticated } = useSelector((state) => state.auth.login)
  const email = useSelector((state) => state.auth?.user?.data?.email)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (!authenticated) {
      history.push(navigation.auth.login(), location.state)
    }
  }, [authenticated, history, location.state])

  useEffect(() => {
    persistor
      .flush()
      .then(persistor.purge)
      .then(() => {
        localStorage.clear()
      })
      .then(() => {
        dispatch(logoutUser())
      })
      .then(() => {
        if (isEmail(email)) {
          authSsoCheckEmail(email).then((isSso) => {
            if (isSso) {
              logout()
            }
          })
        }
      })
  }, [dispatch, logout, email])

  return null
}

export default PageContent
