import { useQuery } from 'react-query'
import { getPersonBulkInformation } from 'services/api'
import { GetPersonBulkInformation } from 'services/api/endpoints/risikaAPI/getPersonBulkInformation'

export function usePersonBulkInformation(data: GetPersonBulkInformation) {
  return useQuery(
    'getPersonBulkInformation' + data.personIdList.join(','),
    () => getPersonBulkInformation(data),
    {
      refetchOnWindowFocus: false,
    }
  )
}
