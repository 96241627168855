// this version is slowly being deprecated as we move away from redux
import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Post to API *
 ******************/

const monitorShowList = () => {
  return risikaAPI
    .get(`/list/show`, {
      microservice: 'monitor',
    })
    .then(({ data }) => {
      return data
    })
}

export default monitorShowList
