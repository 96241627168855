import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { ECPDataType } from 'services/api/endpoints/risikaAPI/getECPDataStructure'
import IndustryType from '../../../Actions/IndustryType'
import { getDataFromSelectedNode } from '../../../DetailedComponentView.controller'
import {
  APPROVE_CREDIT,
  COMPANY_SEARCH,
  INDUSTRY_CODE,
  LOCAL_ID_IN_MONITORING_LIST,
  PERSON_SEARCH,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import CompanySearch from '../../../Actions/CompanySearch'
import PersonSearch from '../../../Actions/PersonSearch'
import ApproveCredit from '../../../Actions/ApproveCredit'
import CompanyList from '../../../Actions/CompanyList'
import {
  ApproveCreditMaxOutcome,
  CompanyTypeCategory,
  IndustryCodeCategory,
  LocalIdInMonitoringListCategory,
  PersonSearchCategory,
} from 'types/queries'
import { RefChildren } from '../CustomizationHandler.types'
type CustomVariableHandlerProps = {
  selectedVariable: ECPDataType[0]
  children: RefChildren[]
}

function CustomVariableHandler({
  selectedVariable,
  children,
}: CustomVariableHandlerProps) {
  const { state } = useBuilder()
  const child = children[0]
  const apiData = getDataFromSelectedNode(state)
  switch (selectedVariable.template?.[0]?.template_data?.api_key) {
    case INDUSTRY_CODE:
      return (
        <IndustryType
          apiData={apiData as IndustryCodeCategory}
          setIsConfirmEnabled={child.props.setIsConfirmEnabled}
          ref={child.ref}
        />
      )
    case COMPANY_SEARCH:
      return (
        <CompanySearch
          apiData={apiData as CompanyTypeCategory}
          setIsConfirmEnabled={child.props.setIsConfirmEnabled}
          ref={child.ref}
        />
      )
    case PERSON_SEARCH:
      return (
        <PersonSearch
          apiData={apiData as PersonSearchCategory}
          setIsConfirmEnabled={child.props.setIsConfirmEnabled}
          ref={child.ref}
        />
      )
    case APPROVE_CREDIT:
      return (
        <ApproveCredit
          apiData={apiData as ApproveCreditMaxOutcome}
          setIsConfirmEnabled={child.props.setIsConfirmEnabled}
          ref={child.ref}
        />
      )
    case LOCAL_ID_IN_MONITORING_LIST:
      return (
        <CompanyList
          apiData={apiData as LocalIdInMonitoringListCategory}
          setIsConfirmEnabled={child.props.setIsConfirmEnabled}
          ref={child.ref}
        />
      )
    default:
      return null
  }
}
export default CustomVariableHandler
