import { useState, useEffect } from 'react'

const useActionPanelStateValidator = (
  state: any,
  validatorFunction: Function,
  callback: Function
) => {
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsValid(validatorFunction(state))
  }, [state, validatorFunction])

  useEffect(() => {
    callback(isValid)
  }, [callback, isValid])

  return [isValid]
}

export default useActionPanelStateValidator
