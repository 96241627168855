import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'
import { Address } from 'types/general'

/******************
 * Fetch from API *
 ******************/

type AddressType = {
  data: Address
  valid_from: string
  valid_to?: string
}

type Capital = {
  data: Object
  valid_from: string
  valid_to?: string
}

type Name = {
  data: string
  valid_from: string
  valid_to?: string
}

type CompanyHistory = {
  name: Name[]
  capital: Capital[]
  address: AddressType[]
}

type ResponseData = {
  data: CompanyHistory
}

async function companyHistory({
  id,
  country
}: LocalId): Promise<CompanyHistory> {
  const { data }: ResponseData = await risikaAPI.get(`/company/history/${id}`, {
    microservice: 'data',
    country
  })
  return data
}

export default companyHistory
