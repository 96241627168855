import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import BoxContainerSmall from 'components-new/layouts/BoxContainerSmall'
import { classOutlineBoxTarget } from 'styles-new/global/components'
import { subLabel } from 'styles-new/global/typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { theme } from 'styles-new/mui5-transition/theme'
import { useMonitoringStatistic } from 'services/queries'
import { PointsOfAttentionType } from './PointsOfAttention.types'
import intl from 'localization/components'
import { Monitor } from 'risika-api-response-types'

const PointsOfAttention = ({
  listId,
  handlePortfolioInsightsFilters,
}: {
  listId: string
  handlePortfolioInsightsFilters: (category: string, key: string | any) => void
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const pointsOfAttentionQuery =
    useMonitoringStatistic<Monitor.ListStatisticsAggregatedHighlights>({
      parameters: { list_id: Number(listId) },
      endpoint: 'aggregated_highlights',
      scoreModel: false,
    })

  const boxData = {
    title: intl.portfolioInsights('points-of-attention'),
    status: {
      isLoading: pointsOfAttentionQuery.isLoading,
      isError: pointsOfAttentionQuery.isError,
      isIdle: pointsOfAttentionQuery.isIdle,
    },
  }
  return (
    <Box sx={{ mt: 4 }}>
      <BoxContainerSmall boxData={boxData}>
        {!boxData.status.isLoading && !boxData.status.isIdle && !boxData.status.isError
          ? pointsOfAttentionQuery?.data?.map(
              (entry: PointsOfAttentionType, index: number) => {
                // const filterKey = { category: entry.category, value: entry.value }
                return (
                  <Box
                    key={entry.title + index}
                    onClick={() =>
                      handlePortfolioInsightsFilters(
                        'highlights',
                        `${entry.category}-${index}`
                      )
                    }
                  >
                    <Stack
                      className="MUI5"
                      direction="row"
                      mb={2}
                      sx={{ ...classOutlineBoxTarget, mb: 2 }}
                    >
                      <Stack m={1} flex={1}>
                        <Typography component="div">
                          <Box sx={{ ...subLabel }}>{entry.title}</Box>
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="subtitle1">{entry.message}</Typography>
                        </Box>
                      </Stack>

                      {!isMobile && (
                        <Stack mr={1} justifyContent="center" alignItems="center">
                          <ArrowForwardIosIcon color="disabled" />
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                )
              }
            )
          : null}
      </BoxContainerSmall>
    </Box>
  )
}

export default PointsOfAttention
