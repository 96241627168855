import { theme } from 'styles-new/mui5-transition/theme'

const tableHead = {
  backgroundColor: 'grey.200',
}
const tableHeadCell = {
  padding: '1.5rem',
}

const header = {
  display: 'flex',
  flexDirection: 'row',
}

const row = (isHovered: boolean) => {
  return {
    minHeight: '70px',
    backgroundColor: isHovered ? `${theme.palette.grey[50]}!important` : 'inherit',
  }
}

const cell = {
  padding: '0.75rem 1.5rem',
  borderRight: '1px solid',
  borderColor: 'grey.200',
}

const sortArrow = {
  position: 'relative',
  top: '3px',
  '& *': {
    color: 'grey.600',
  },
}

const paginationRoot = {
  display: 'flex',
  justifyContent: 'center',
}

const expandedRow = { bgcolor: 'grey.200' }

export const classes = {
  tableHead,
  tableHeadCell,
  header,
  row,
  cell,
  sortArrow,
  paginationRoot,
  expandedRow,
}
