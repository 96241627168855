import { useState } from 'react'
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Checkbox,
  ListItemText,
  Typography,
} from '@mui/material'
import { ButtonLink, ButtonSecondary, InputField } from '../../index'
import { ChipStack } from 'components-new'
import intl from '../../../localization/components'
import { FormattedMessage } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { DropdownMultiSelectPropsType } from './DropdownMultiSelect.types'
import styles from './DropdownMultiSelect.styles'

const { menuStyles, buttonContainer, menuItem, listItem } = styles

const DropdownMultiSelect = (props: DropdownMultiSelectPropsType) => {
  const { values, setValues, options, inputProps, endAddon, containerStyles = {} } = props

  const [open, setOpen] = useState(false)
  const [chipData, setChipData] = useState<{ value: string; label: string }[]>(
    options.filter((o) => values.indexOf(o.value) > -1)
  )

  const handleApply = () => {
    setChipData(options.filter((o) => values.indexOf(o.value) > -1))
    setOpen(false)
  }

  const handleClearAll = () => {
    setValues([])
    setChipData([])
  }

  const handleClose = () => {
    const prevValues = chipData.map((val) => val.value)
    setValues(prevValues)
    setOpen(false)
  }

  const handleChange = (values: string[]) => {
    setValues(values)
  }

  const handleChipDelete = (value: string) => {
    setChipData(chipData.filter((c) => c.value !== value))
    setValues(values.filter((v) => v !== value))
  }

  const renderValue = (selected: string[]) => {
    return selected.length ? (
      <Typography variant="body1">{selected.length} selected</Typography>
    ) : (
      <Typography sx={{ color: 'grey.600' }} variant="body1">
        Please select
      </Typography>
    )
  }
  return (
    <Stack>
      <Stack direction="row" alignItems="flex-start" sx={containerStyles}>
        <FormControl component={Stack} spacing={1} width={1}>
          <Select
            onClose={handleClose}
            input={<InputField value={''} handleChange={() => {}} {...inputProps} />}
            disableUnderline
            value={values}
            displayEmpty
            renderValue={renderValue}
            onChange={(e) => handleChange(e.target.value as string[])}
            variant="standard"
            IconComponent={KeyboardArrowDownIcon}
            multiple
            MenuProps={{ sx: menuStyles }}
            open={open}
            onOpen={() => setOpen(true)}
          >
            {options.map((option, i) => (
              <MenuItem sx={menuItem} key={option.value} value={option.value}>
                <Checkbox size="small" checked={values.indexOf(option.value) > -1} />
                <ListItemText sx={listItem} primary={option.label} />
              </MenuItem>
            ))}
            <Stack sx={buttonContainer}>
              <ButtonLink onClick={handleClearAll}>
                <FormattedMessage
                  id={intl.creditPolicyNew('search-multi-select-clear-all')}
                />
              </ButtonLink>
              <ButtonSecondary
                data-cy="dropdown-multi-select-apply"
                disabled={!values.length && !chipData.length}
                onClick={handleApply}
              >
                <FormattedMessage
                  id={intl.creditPolicyNew('search-multi-select-apply')}
                />
              </ButtonSecondary>
            </Stack>
          </Select>
        </FormControl>
        {endAddon ?? null}
      </Stack>
      <ChipStack
        chipData={chipData}
        setValues={setValues}
        handleChipDelete={handleChipDelete}
      />
    </Stack>
  )
}

export default DropdownMultiSelect
