import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { Box, Stack, TextField } from '@mui/material'
import intl from '../../../../../../../../localization/components'
import {
  searchIconStyle,
  tableGreyField,
} from '../../../../../../../../components-new/data-display/Table/elements/TableToolbar.styles'
import SearchIcon from '@mui/icons-material/Search'
import { riskIntelligenceApiKeys as apiKeys } from '../../../../../../../../configs/constants/api'
import FilterMultiSelect from '../../../../../../../../components-new/data-display/Table/elements/inputs/FilterMultiSelect'
import { MonitoringTableFiltersProps } from './MonitoringTableFilters.types'
import { useDebounceText } from 'services/hooks'
import MonitoringTableFiltersLoading from './MonitoringTableFilters.loading'

const MonitoringTableFilters = ({
  handleSearch,
  tableFilters,
  handleFilters,
  isLoading,
}: MonitoringTableFiltersProps) => {
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const [searchText, debounceValue, setSearchText] = useDebounceText(300)

  useEffect(() => {
    handleSearch(debounceValue.trim())
  }, [debounceValue])

  if (isLoading) return <MonitoringTableFiltersLoading />
  return (
    <Stack direction="row" flexWrap="wrap">
      <Box>
        <TextField
          onChange={(event) => setSearchText(event.target.value)}
          placeholder={messages[intl.searchBusiness('search-field')] as string}
          value={searchText}
          size="small"
          variant="outlined"
          sx={{
            maxWidth: 400,
            ...tableGreyField,
          }}
          InputProps={{
            endAdornment: <SearchIcon fontSize="large" sx={searchIconStyle} />,
          }}
        />
      </Box>
      {Object.keys(tableFilters).map((filterCategory: any, index: number) => {
        if (filterCategory !== apiKeys.freeSearch) {
          return (
            <FilterMultiSelect
              key={filterCategory}
              id={filterCategory}
              filterData={tableFilters[filterCategory]}
              handleFilters={handleFilters}
              tag={filterCategory}
            />
          )
        }
      })}
    </Stack>
  )
}

export default MonitoringTableFilters
