import { Typography, Stack, Divider } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { classes } from 'pages/credit-check/styles/statstidende'
import RedWarningTriangle from 'pages/credit-check/scenes/MainRecommendation/elements/RedWarningTriangle'
import { StatusInfoType } from './D3RisikaScore.types'
import { formatToUniversalDate } from 'utils/dateUtils'

function StatusWarning({ statusInfo }: StatusInfoType) {
  return (
    <Stack direction="column" alignItems="center">
      <RedWarningTriangle />
      <Divider flexItem />
      <Stack
        direction="column"
        alignItems="flex-start"
        spacing={1}
        sx={classes.subSection}
        data-cy="status-warning"
      >
        <Typography>
          <b>
            <FormattedMessage id={intl.mainRecommendation('status-label')} />
          </b>
          {statusInfo.text}
        </Typography>
        <Typography>
          <b>
            <FormattedMessage id={intl.mainRecommendation('valid-from')} />
          </b>
          {formatToUniversalDate(statusInfo.validFrom)}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default StatusWarning
