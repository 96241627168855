import risikaAPI from 'services/api/helpers/risikaAPI'

type ResponseData = {
  data: {
    status: 'ok'
  }
}

const deleteCreditPolicy = async (policyId: number): Promise<ResponseData> => {
  const { data } = await risikaAPI.post(
    'enterprise_credit_policies/remove',
    { policy_id: policyId },
    { microservice: 'account' }
  )
  return data
}

export default deleteCreditPolicy
