import { equals } from 'ramda'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

// type IndustryTree = {
//   [key: string | number]: {
//     group: IndustryTree,
//     title: string,
//     level: number,
//   },
// }

// type Industry = {
//   code: string,
//   description: string,
//   level: number,
// }

function flattenIndustryTree(
  industryTree,
  flatIndustry = []
  // industryTree: IndustryTree,
  // flatIndustry: Industry[] = []
) {
  for (const code in industryTree) {
    const { group, title, level } = industryTree[code]

    if (title != null) {
      flatIndustry.push({
        code,
        description: title,
        level,
      })
    }

    if (!equals(group, {})) {
      flattenIndustryTree(group, flatIndustry)
    }
  }

  return flatIndustry
}

const industryList = (country) => {
  return risikaAPI
    .get(`/list/industries`, {
      microservice: 'data',
      country,
    })
    .then(({ data }) => {
      const { industries } = data

      const allIndustries = flattenIndustryTree(industries)
      const industryGroup = allIndustries.filter((x) => x.level === 1)
      const specificIndustry = allIndustries.filter((x) => x.level !== 1)

      return {
        industryGroup,
        specificIndustry,
      }
    })
}

export default industryList
