import { ButtonPrimary } from 'components-new'
import { Link, useLocation } from 'react-router-dom'
import { Stack, Box, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const Showcase = () => {
  const location = useLocation()

  return (
    <Stack width={1}>
      <Box width={200} mb={7}>
        <img src="/risika/logo-blue-white.svg" alt=""></img>
      </Box>
      <Box mb={5} maxWidth={530}>
        <Typography variant="h1" sx={{ color: 'common.white' }}>
          <FormattedMessage id={intl.auth('showcase-title')} />
        </Typography>
      </Box>
      <Box mb={6} maxWidth={650}>
        <Typography variant="h4" sx={{ color: 'common.white' }}>
          <FormattedMessage id={intl.auth('showcase-subtitle')} />
        </Typography>
      </Box>

      {!location.pathname.includes('/auth/frictionless') && (
        <Box alignSelf="start">
          <ButtonPrimary
            component={Link}
            to={{ pathname: 'https://risika.com/free-trial' }}
            target="_blank"
          >
            <FormattedMessage id={intl.auth('get-started-free')} />
          </ButtonPrimary>
        </Box>
      )}
      {/*  <img src="/risika/product-showcase-without-computer.png" alt="" /> */}
    </Stack>
  )
}

export default Showcase
