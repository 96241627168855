import { LocalId } from 'globalTypes'
import moment from 'moment'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

export type FinancialRatios = {
  period: {
    start: string
    end: string
  }
  gross_margin?: number
  operating_margin?: number
  profit_margin?: number
  return_on_equity?: number
  return_on_assets?: number
  return_on_net_assets?: number
  basic_earning_power?: number
  current_ratio?: number
  current_assets_to_equity?: number
  cash_ratio?: number
  capacity_ratio?: number
  asset_turnover?: number
  inventory_conversion_ratio?: number
  debt_ratio?: number
  debt_to_equity_ratio?: number
  income_to_debt_ratio?: number
  ebitda_to_debt_ratio?: number
  interest_coverage?: number
  solidity_ratio?: number
  liabilities_to_equity_ratio?: number
  one_year_change_in_equity?: number
  cash_conversion_rate?: number
  revenue_per_employee?: number
  gross_profit_per_employee?: number
  net_income_per_employee?: number
  asset_turnover_with_average?: number
  basic_earning_power_with_average?: number
  return_on_assets_with_average?: number
  return_on_net_assets_with_average?: number
  return_on_equity_with_average?: number
  quick_ratio?: number
  income_to_debt_ratio_with_average?: number
  ebitda_to_debt_ratio_with_average?: number
  interest_rate_on_debt_with_average?: number
  interest_margin?: number
  fixed_assets_to_long_term_liabilities_ratio?: number
  equity_to_contributed_capital_ratio?: number
  one_year_change_in_debt?: number
}
type ResponseData = { data: FinancialRatios[] }

/***********
 * Helpers *
 ***********/

/**
 * Sort financial statements by newest.
 */
function sortByNewest(a: FinancialRatios, b: FinancialRatios) {
  return moment(b.period.end).diff(a.period.end)
}

/**
 * Gets all the _financial numbers_ for a given company.
 *
 * @param {object} id _Local ID_ and _Country Code_ of the company.
 * @returns {object} All the different _financial numbers_ associated with the company.
 */
async function financialRatios({ id, country }: LocalId) {
  const { data }: ResponseData = await risikaAPI.get(`/financial/ratios/${id}`, {
    microservice: 'data',
    country,
  })
  return data.sort(sortByNewest).map((x) => ({
    period: x.period,
    gross_margin: x.gross_margin,
    operating_margin: x.operating_margin,
    profit_margin: x.profit_margin,
    return_on_equity: x.return_on_equity,
    return_on_assets: x.return_on_assets,
    return_on_net_assets: x.return_on_net_assets,
    basic_earning_power: x.basic_earning_power,
    current_ratio: x.current_ratio,
    current_assets_to_equity: x.current_assets_to_equity,
    cash_ratio: x.cash_ratio,
    capacity_ratio: x.capacity_ratio,
    asset_turnover: x.asset_turnover,
    inventory_conversion_ratio: x.inventory_conversion_ratio,
    debt_ratio: x.debt_ratio,
    debt_to_equity_ratio: x.debt_to_equity_ratio,
    income_to_debt_ratio: x.income_to_debt_ratio,
    ebitda_to_debt_ratio: x.ebitda_to_debt_ratio,
    interest_coverage: x.interest_coverage,
    solidity_ratio: x.solidity_ratio,
    liabilities_to_equity_ratio: x.liabilities_to_equity_ratio,
    one_year_change_in_equity: x.one_year_change_in_equity,
    cash_conversion_rate: x.cash_conversion_rate,
    revenue_per_employee: x.revenue_per_employee,
    gross_profit_per_employee: x.gross_profit_per_employee,
    net_income_per_employee: x.net_income_per_employee,
    asset_turnover_with_average: x.asset_turnover_with_average,
    basic_earning_power_with_average: x.basic_earning_power_with_average,
    return_on_assets_with_average: x.return_on_assets_with_average,
    return_on_net_assets_with_average: x.return_on_net_assets_with_average,
    return_on_equity_with_average: x.return_on_equity_with_average,
    quick_ratio: x.quick_ratio,
    income_to_debt_ratio_with_average: x.income_to_debt_ratio_with_average,
    ebitda_to_debt_ratio_with_average: x.ebitda_to_debt_ratio_with_average,
    interest_rate_on_debt_with_average: x.interest_rate_on_debt_with_average,
    interest_margin: x.interest_margin,
    fixed_assets_to_long_term_liabilities_ratio:
      x.fixed_assets_to_long_term_liabilities_ratio,
    equity_to_contributed_capital_ratio: x.equity_to_contributed_capital_ratio,
    one_year_change_in_debt: x.one_year_change_in_debt,
  }))
}

export default financialRatios
