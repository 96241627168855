import { RISIKA_API_VERSION } from '../../services/api/helpers/config'
import { ReactSetState } from '../../types/general'
import { AxiosResponse } from 'axios'
import hash from 'hash.js'

const getHashFromPayload = (payload: string) => {
  return hash.sha256().update(payload).digest('hex').slice(0, 15)
}

// Remove special characters from string and replace with underscore.
// Wherever there is more than one underscore, replace with single underscore.
const formatFileName = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9]/g, '_')
    .replace(/_{2,}/g, '_')
    .replace(`${RISIKA_API_VERSION}_`, '')
}

// Downloads a formatted JSON file
const downloadJSON = (filename: any, data: any) => {
  const json = JSON.stringify(data, null, 2)
  const blob = new Blob([json], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()
  URL.revokeObjectURL(url)
}

const pause = (msec: any) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, msec || 1000)
  })
}

export const downloadAll = async (elements: any) => {
  let count = 0
  for (const e in elements) {
    downloadJSON(elements[e].fileName, elements[e].data)
    if (++count >= 10) {
      await pause(1000)
      count = 0
    }
  }
}

export const axiosInterceptorsArguments = (setState: ReactSetState<any>) => {
  const responseFn = (res: AxiosResponse) => {
    const url = res.request.responseURL
    const baseUrl = res.config.baseURL as string
    const data = res.data
    const requestType = res.config.method?.toUpperCase() || null
    const payload = res.config.data
    const name = formatFileName(
      url.replace(baseUrl || 'https://thornwebapi.risika.com/', '')
    ).replace(/currency_[^ ]+/g, 'currency_ANY')
    const formattedUrl = url
      .replace(RISIKA_API_VERSION, '**')
      .replace(/(?<=currency=)[A-Z]{3}/g, '**')

    const getFileName = (name: string, payload: string, requestType: string | null) => {
      if (requestType !== 'GET' && payload) {
        const payloadObject = JSON.parse(payload)
        if (payloadObject.local_organization_ids) {
          const id = payloadObject.local_organization_ids[0].id
          return `${name}_${id}`
        }
        if (payloadObject.accountId) {
          return `${name}_${payloadObject.accountId}`
        }
        if (payloadObject.filters?.free_search) {
          return `${name}_${payloadObject.filters.free_search}`
        }
        if (payloadObject.query) {
          return `${name}_${payloadObject.query}`
        }
        if (requestType === 'PUT') {
          return `PUT_${name}_${getHashFromPayload(payload)}`
        }
        if (requestType === 'POST') {
          return `${name}_page_${getHashFromPayload(payload)}`
        }
      }
      return name
    }

    const fileName = getFileName(name, payload, requestType)

    const current = {
      data,
      fileName,
      url: formattedUrl,
      status: res.status,
      requestType,
      payload: res.config.data || '',
      path: `/${fileName}`,
    }

    // This is necessary to avoid the key duplication in the state
    const key = (() => {
      switch (requestType) {
        case 'PUT':
          return `_${url}`
        case 'POST':
          return `__${url}`
        case 'DELETE':
          return `___${url}`
        default:
          return url
      }
    })()

    setState((prev: any) => {
      return {
        ...prev,
        [key]: current,
      }
    })
    return res
  }
  const errorFn = (err: any) => Promise.reject(err)

  return [responseFn, errorFn]
}
