import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { env } from 'services/env'
import { Dialog, DialogTitle, Stepper, Step, StepLabel, StepContent } from '@mui/material'
import SelectRecipient from './SelectRecipient'
import { Button } from 'components'
import CustomText from './CutomText'
import { colors } from 'configs'
import axios from 'axios/index'
import { fetchRisikaAPIAccessToken } from 'services/api/helpers/authService'
import { users, trackActivity } from 'services/api'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import useTotangoTracking from 'utils/totangoTracking'
import { Mixpanel } from 'services/helpers/mixpanel'
const ShareDialog = ({ open, onClose, loading, localId }) => {
  const { enqueueSnackbar } = useSnackbar()
  const userName = useSelector((state) => state.auth.user.data.name)
  const userEmail = useSelector((state) => state.auth.user.data.email)
  const [emailValue, setEmailValue] = useState('')
  const [messageValue, setMessageValue] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [coworkers, setCoworkers] = useState([])
  const { trackEvent } = useTotangoTracking()

  const steps = [
    <FormattedMessage key="recipient" id={intl.shareReport('select-recipient')} />,
    <FormattedMessage
      key="custome message"
      id={intl.shareReport('add-custom-message')}
    />,
  ]

  useEffect(() => {
    users().then((data) => {
      setCoworkers(data)
    })
  }, [])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SelectRecipient
            emailValue={emailValue}
            setEmailValue={setEmailValue}
            handleShareReport={handleNext}
            coworkers={coworkers}
          />
        )
      case 1:
        return (
          <CustomText messageValue={messageValue} setMessageValue={setMessageValue} />
        )
      default:
        return 'Something went wrong, please refresh the page!'
    }
  }

  const handleNext = (email) => {
    if (email) {
      setEmailValue(email)
    }

    if (activeStep === steps.length - 1) {
      handleShareReport()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setEmailValue('')
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClose = () => {
    setEmailValue('')
    setMessageValue('')
    setActiveStep(0)
    return onClose()
  }

  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleShareReport = async () => {
    trackEvent('Credit Check', 'Share Report')
    Mixpanel.track('User shared report')
    handleClose()
    const name =
      userName.first && userName.last ? `${userName.first} ${userName.last}` : userEmail
    const accessToken = await fetchRisikaAPIAccessToken()

    loading(true)

    if (validateEmail(emailValue)) {
      axios
        .get(`${env.PROFF_REPORT_API_BASE_URL}/api/share`, {
          timeout: 30000,
          responseType: 'blob',
          headers: {
            Authorization: accessToken,
          },
          params: {
            cvr: localId.id,
            email: emailValue,
            name: name,
            country: localId.country.toLowerCase(),
            message: messageValue,
          },
        })
        .then(() => {
          trackActivity({
            action: 'report_share',
            data: {
              local_organization_id: {
                id: localId.id.toString(),
                country: localId.country.toLowerCase(),
              },
              success: true,
              emailValue,
            },
          })
          enqueueSnackbar(<FormattedMessage id={intl.snackbar('shared-succes')} />)
        })
        .catch(() => {
          trackActivity({
            action: 'report_share',
            data: {
              local_organization_id: {
                id: localId.id.toString(),
                country: localId.country.toLowerCase(),
              },
              success: false,
              emailValue,
            },
          })
          enqueueSnackbar(
            <FormattedMessage id={intl.snackbar('report-error-something-wrong')} />
          )
        })
        .finally(() => {
          loading(false)
        })
    } else {
      enqueueSnackbar(<FormattedMessage id={intl.snackbar('invalid-email')} />)
    }
    setEmailValue('')
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        style={{ backgroundColor: colors?.risika, color: 'white' }}
        id="form-dialog-title"
      >
        {<FormattedMessage id={intl.creditCheck('share-report')} />}
      </DialogTitle>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
              <div>
                <div>
                  {activeStep === steps.length - 1 && (
                    <Button onClick={handleBack}>
                      <FormattedMessage id={intl.generic('back')} />
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      <FormattedMessage id={intl.generic('send')} />
                    </Button>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Dialog>
  )
}

export default ShareDialog
