import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

export interface Highlight {
  classification: 'positive' | 'negative' | 'neutral'
  data: any
  description: string
  message: string
  title: string
  weight: number
}

export interface HighlightsResponse {
  highlights: {
    [key: string]: Highlight
  }
}

type ResponseData = {
  data: {
    highlights: HighlightsResponse
  }
}

async function highlights({ id, country }: LocalId): Promise<HighlightsResponse> {
  const { data }: ResponseData = await risikaAPI.get(`/highlights/${id}`, {
    microservice: 'data',
    country,
  })

  return data.highlights
}

export default highlights
