import { withRouter } from 'react-router-dom'
import { ProviderContext, withSnackbar } from 'notistack'
import { ComponentType } from 'react'

type LoaderProps = {
  history?: any
  redirect?: boolean
  url?: string
  timeout?: number
}

export const CustomLoader = ({
  history,
  redirect,
  url = '/',
  timeout = 2000,
}: LoaderProps) => {
  if (redirect) {
    setTimeout(() => history?.push(url), timeout)
  }

  return (
    <div id="risika-loading-screen">
      <div className="center">
        <div className="logo">
          <img width="128" src="risika/logo-blue-white.svg" alt="logo" />
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap"></div>
              <div className="left">
                <div className="half-circle"></div>
              </div>
              <div className="right">
                <div className="half-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  withSnackbar(CustomLoader as ComponentType<ProviderContext>) as any
)
