import { authRoles } from 'components/auth'
import RiskMonitoringPage from './RiskMonitoringPage'
import { navigation } from 'services/navigation'

export const RiskMonitoringPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: navigation.riskMonitoring(),
      component: RiskMonitoringPage,
    },
  ],
}
