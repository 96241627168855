import * as React from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { ActionDialog } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import UpdateExistingSubscriptions from './UpdateExistingSubscriptions'
import AddNewListToSubscription from './AddNewListToSubscription'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import useTotangoTracking from 'utils/totangoTracking'
import { useMonitorShowList, useUserSettings } from 'services/queries'
import { defSubscription } from '../../model'
import { ButtonTertiary } from 'components-new'
import { AvailableLanguages } from 'globalTypes'
import {
  Scheduler,
  Subscription,
  SubscriptionType,
} from 'services/api/endpoints/risikaAPI/new_subscription'
import { ReduxRootState, RiskMonitorListObject } from 'store_deprecated/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { getDateOfNextWeekDay } from 'utils/dateUtils'
import {
  usePostSubscription,
  useDeleteSubscription,
  useSubscriptions,
} from 'services/queries/subscription'
import { Mixpanel } from 'services/helpers/mixpanel'
import { appendOldSubscriptioWithNewKey } from './AppendOldSubscriptions'
import _ from 'lodash'

type ListCheckBoxes = {
  name: string
  listId: number
  checked: boolean
}[]

const DEFAULT_FREQUENCY_DATA = {
  days: ['monday'],
  mode: 'weekly',
  frequency: 1,
  starting_date: getDateOfNextWeekDay('monday'),
}
const TYPE = 'MONITOR' as SubscriptionType

const SubscriptionControlPanel = () => {
  // responses for post and delete calls
  const successPostResponse = () => {
    enqueueSnackbar(
      <FormattedMessage
        id={intl.riskMonitoringNew(
          isAddingNewSubscription
            ? 'subscription-add-success'
            : 'subscription-updated-success'
        )}
      />
    )
  }
  const failedPostResponse = () => {
    enqueueSnackbar(
      <FormattedMessage id={intl.riskMonitoringNew('subscription-add-failed')} />
    )
  }
  const successDeleteResponse = () => {
    enqueueSnackbar(
      <FormattedMessage id={intl.riskMonitoringNew('subscription-delete-success')} />
    )
  }
  const failedDeleteResponse = () => {
    enqueueSnackbar(
      <FormattedMessage id={intl.riskMonitoringNew('subscription-delete-failed')} />
    )
  }

  const { data: listsData, isLoading, isError } = useMonitorShowList()

  const lists = React.useMemo(
    () =>
      listsData
        ? listsData.reduce((acc, curr) => {
            acc[curr.list_id] = curr
            return acc
          }, {} as RiskMonitorListObject)
        : {},
    [listsData]
  )
  const { enqueueSnackbar } = useSnackbar()
  const { data: userSettingsData, isSuccess: isUserSettingsSuccess } = useUserSettings()
  const { data: subscriptions } = useSubscriptions()
  const { deleteSubscription } = useDeleteSubscription({
    onSuccess: successDeleteResponse,
    onError: failedDeleteResponse,
  })
  const { postSubscription } = usePostSubscription({
    onSuccess: successPostResponse,
    onError: failedPostResponse,
  })

  const userEmail = useSelector((state: ReduxRootState) => state.auth.user.data.email)
  const [isAddingNewSubscription, setIsAddingNewSubscription] = React.useState(false)
  const [listCheckBoxes, setListCheckBoxes] = React.useState<ListCheckBoxes>([])
  const [canSubscribe, setCanSubscribe] = React.useState(false)
  const [selectedItems, setSelectedItems] = React.useState(defSubscription)
  const [frequency, setFrequency] = React.useState<Scheduler>(DEFAULT_FREQUENCY_DATA)
  const [localSubscriptions, setLocalSubscriptions] = React.useState(subscriptions)
  const [subscriptionLang, setSubscriptionLang] = React.useState<
    AvailableLanguages | undefined
  >()

  const { trackEvent } = useTotangoTracking()

  const subscriptionIsEdited = (subscription: Subscription, index: number) => {
    const isEqual =
      _.isEqual(subscription.email_settings, subscriptions?.[index].email_settings) &&
      _.isEqual(subscription.scheduler, subscriptions?.[index].scheduler)

    return !isEqual
  }

  React.useEffect(() => {
    setLocalSubscriptions(subscriptions)
  }, [subscriptions])

  React.useEffect(() => {
    if (isUserSettingsSuccess) setSubscriptionLang(userSettingsData?.language)
  }, [userSettingsData, isUserSettingsSuccess, setSubscriptionLang])

  const handlePostSubscription = () => {
    trackEvent('Monitoring', 'Updated Notifications')

    if (isAddingNewSubscription) {
      const checkedBoxes = listCheckBoxes.filter((list) => list.checked)
      checkedBoxes?.forEach((list: any) => {
        const subscription = {
          disabled: false,
          list_id: list.listId,
          type: TYPE,
          emails: [userEmail],
          scheduler: frequency,
          email_settings: selectedItems.email_settings,
          user_settings: {
            language: subscriptionLang || 'en_UK',
          },
        }

        postSubscription(subscription)
      })

      Mixpanel.track('User added subscription', { lists: checkedBoxes })
    } else {
      localSubscriptions?.forEach((subscription: Subscription, index: number) => {
        if (!subscription || !subscriptionIsEdited(subscription, index)) {
          return
        }
        const updatedSubscription = appendOldSubscriptioWithNewKey(subscription)
        postSubscription(updatedSubscription)
      })
    }
  }

  const handleDeleteSubscription = (list_id: number) => {
    deleteSubscription({ type: TYPE, list_id })
  }

  const cleanup = () => {
    setIsAddingNewSubscription(false)
  }
  return (
    <ActionDialog
      fullWidth
      title={
        <FormattedMessage id={intl.riskMonitoringNew('update-subscriptions-title')} />
      }
      onClose={cleanup}
      disabled={isAddingNewSubscription && !canSubscribe}
      content={
        isAddingNewSubscription ? (
          <AddNewListToSubscription
            setIsAddingNewSubscription={setIsAddingNewSubscription}
            lists={lists}
            subscriptions={subscriptions}
            listCheckBoxes={listCheckBoxes}
            setListCheckBoxes={setListCheckBoxes}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setCanSubscribe={setCanSubscribe}
            frequency={frequency}
            setFrequency={setFrequency}
            subscriptionLang={subscriptionLang}
            setSubscriptionLang={setSubscriptionLang}
          />
        ) : (
          <UpdateExistingSubscriptions
            lists={lists}
            subscriptions={localSubscriptions}
            setSubscriptions={setLocalSubscriptions}
            setIsAddingNewSubscription={setIsAddingNewSubscription}
            deleteSubscription={handleDeleteSubscription}
            deleteButtonLoading={false}
          />
        )
      }
      action={handlePostSubscription}
      render={(open: React.MouseEventHandler<HTMLButtonElement>) => (
        <ButtonTertiary
          loading={isLoading}
          disabled={isError}
          onClick={open}
          startIcon={
            <FontAwesomeIcon
              icon={faBell as IconDefinition}
              style={{
                fontSize: '1.5rem',
                marginRight: '0.5rem',
              }}
            />
          }
          data-cy="your-notifications-btn"
        >
          <FormattedMessage id={intl.riskMonitoringNew('update-subscriptions')} />
        </ButtonTertiary>
      )}
      agreeText={
        <FormattedMessage
          id={intl.riskMonitoringNew(
            `${isAddingNewSubscription ? 'create-subscription' : 'save-changes'}`
          )}
        />
      }
    />
  )
}

export default SubscriptionControlPanel
