import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Typography, Stack, Box } from '@mui/material'
import { max, min } from 'lodash'
import moment from 'moment'
import { navigation } from 'services/navigation'
import { isNil } from 'ramda'
import Table from './scenes/Table'
import { HistoryHeader, CopyItem, Loader, Button, ErrorBoundary } from 'components'
import { storeSetting, setSelectedCompany } from 'store_deprecated'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { personRelations } from 'services/api'
import { removeDuplicates, handleAddressField } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { useQueryClient } from 'react-query'
import { styles } from './styles/relation-overview'
import Tabs from './widgets/Tabs'
import { useRegisterVisit } from 'services/queries/useRegisterVisit'

const { root } = styles

const defaultState = {
  ready: false,
  name: '',
  relations: [],
  type: '', // type is currently not used
  localId: null,
}

const RelationOverviewPage = () => {
  const queryClient = useQueryClient()

  const dispatch = useDispatch()

  const {
    persist_relation_overview_id: persistedId,
    // persist_relation_overview_country: persistedCountry,
  } = useSelector((state) => state.risika.settings)

  const history = useHistory()
  const params = useParams()

  const [state, setState] = React.useState(defaultState)
  const [selected, setSelected] = React.useState('total')
  const [filterValue, setFilterValue] = React.useState('')

  const id = params?.id ?? persistedId

  const userSettings = queryClient.getQueryData('getUserSettings')
  const country = params.country ?? userSettings?.selectedCountry
  useRegisterVisit({
    type: 'person',
    data: { id, country: country?.toUpperCase() },
  })

  React.useEffect(() => {
    // Fallback for old route format
    const handleNavigateBack = () => {
      history.push(navigation.relationOverview({ country, id }))
    }
    if (userSettings && !country) {
      handleNavigateBack()
    }
    if (id) {
      personRelations({ person_id: id, country })
        .then((response) => {
          if (response) {
            const { name, relations, type, local_organization_id, address } = response
            dispatch(storeSetting('persist_relation_overview_id', id))
            dispatch(storeSetting('persist_relation_overview_country', country))
            setState({
              ready: true,
              name,
              relations,
              type,
              localId: local_organization_id,
              address: address ? handleAddressField(address) : null,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, country, history, dispatch])
  const { ready, name, relations, localId, type, address } = state

  const data = relations
    .map((relation) => ({
      ...relation,
      functions: removeDuplicates(
        relation?.functions.sort(
          (a, b) => moment(b.valid_from).valueOf() - moment(a.valid_from).valueOf()
        ),
        'function'
      ),
    }))
    .map((entry) => ({
      ...entry,
      valid_from: min([
        ...entry.functions.map(({ valid_from }) => moment(valid_from).valueOf()),
      ]),
      valid_to: entry?.functions?.length
        ? entry.functions
            .map(({ valid_to }) => (valid_to ? moment(valid_to).valueOf() : 0))
            .reduce((acc, valid_to) => {
              if (valid_to === 0) return 0
              if (acc === 0) return acc
              return max([valid_to, acc])
            }, 1)
        : null,
    }))
  const actives = data.filter((row) => !row.valid_to).length
  const inactives = data.filter((row) => row.valid_to).length
  const total = actives + inactives

  const redirect = !id

  const filter = (data) => {
    const filtered = data.filter((row) =>
      row.company_name.toLowerCase().includes(filterValue.toLowerCase())
    )
    switch (selected) {
      case 'active':
        return filtered.filter((row) => !row.valid_to)
      case 'inactive':
        return filtered.filter((row) => row.valid_to)
      default:
        return filtered
    }
  }
  const handleCompanyHierarchy = () => {
    dispatch(setSelectedCompany(localId, name))
    history.push(navigation.companyHierarchy(localId))
  }

  const tabs = useMemo(
    () => [
      {
        onClick: setSelected,
        number: total,
        category: 'total',
      },
      {
        onClick: setSelected,
        number: actives,
        category: 'active',
      },
      {
        onClick: setSelected,
        number: inactives,
        category: 'inactive',
      },
    ],
    [actives, inactives, total]
  )

  return (
    <ErrorBoundary page="relation-overview">
      <Stack sx={root}>
        {ready ? (
          <>
            <HistoryHeader
              titleExtraProps={{ direction: 'row', spacing: 4 }}
              title={name}
              subheader={
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    borderLeft: 'solid 1px',
                    borderColor: 'grey.400',
                    paddingLeft: 4,
                    paddingY: '10px',
                  }}
                >
                  <Typography variant="body1">{address}</Typography>
                  <CopyItem
                    iconStyle={{ height: '1.5rem', marginLeft: '0.8rem' }}
                    item={address}
                  />
                </Stack>
              }
              historyKey={id}
              location="relationoverview"
              country={country}
            >
              <Button
                disabled={type === 'PERSON' || isNil(localId)}
                icon={<FontAwesomeIcon icon={faBuilding} />}
                onClick={handleCompanyHierarchy}
              >
                <FormattedMessage id={intl.companyHierarchy('header')} />
              </Button>
            </HistoryHeader>

            <Tabs
              tabs={tabs}
              selected={selected}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          </>
        ) : null}

        <Box sx={{ marginTop: '16px !important' }} backgroundColor="common.white">
          {ready ? (
            <Table data={filter(data)} />
          ) : (
            <Loader
              redirect={redirect}
              message={
                <FormattedMessage id={intl.relationOverview('no-entity-redirect')} />
              }
            />
          )}
        </Box>
      </Stack>
    </ErrorBoundary>
  )
}

export default RelationOverviewPage
