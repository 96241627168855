import risikaAPI from 'services/api/helpers/risikaAPI'
import { AllowedModel, AvailableCountriesCapital } from 'globalTypes'
import { convertToSetObject } from 'pages/advanced-credit-policy/widgets/controller'

export type ExcludeCompaniesSet = {
  local_organization_id: {
    country?: string
    id: string
    name?: string
  }
}

export type ExcludePersonsSet = {
  local_person_id: {
    country: string
    id: string
    name?: string
  }
}

export type AdvancedPolicySetT = {
  exclude_risika_scores: number[] | null
  exclude_company_types: string[] | null
  exclude_level_of_auditor_assistances: string[] | null
  exclude_industries: string[] | null
  exclude_companies: ExcludeCompaniesSet[] | null
  exclude_persons: ExcludePersonsSet[] | null
  exclude_vat: string[] | null
  bankruptcy_limit: number | null
  bankruptcy_limit_long: number | null
  age_limit: number | null
  negative_equity_limit: number | null
  prepaid_phone_number: boolean | null
  phone_number_registry: boolean | null
  distance_to_ceo: number | null
  payment_remarks_count: number | null
  payment_remarks_creditors: number | null
  payment_remarks_total_amount: number | null
  payment_remarks_max_repayment_time: number | null
  debt: number | null
  short_term_debt: number | null
  profit_loss: number | null
  auditor_has_id: boolean | null
  number_of_employees: number | null
}

export type SetSinglePolicyPayloadT = {
  product?: AllowedModel
  country: AvailableCountriesCapital
  enabled: boolean
  policy: AdvancedPolicySetT
}
const setSingleCreditPolicy = (payload: SetSinglePolicyPayloadT): any => {
  return risikaAPI
    .post(`/credit_policy/set`, convertToSetObject(payload), {
      microservice: 'account',
    })
    .then(({ data }) => {
      return {
        data,
      }
    })
}

export default setSingleCreditPolicy
