import { Stack, Skeleton } from '@mui/material'
import SimpleEntry from './components/SimpleEntry'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import * as React from 'react'

const defaultProps = {
  classNameTitle: '',
  classNameContent: '',
  setIsShowAllHistory: () => {},
  dataCy: '',
}

const skeletonData = [
  {
    key: 'company-name',
    isHistory: true,
  },
  {
    key: 'company-id',
    isHistory: false,
  },
  {
    key: 'phone',
    isHistory: false,
  },
  {
    key: 'address',
    isHistory: true,
  },
  {
    key: 'ad-protection',
    isHistory: false,
  },
  {
    key: 'registered-for-vat',
    isHistory: false,
  },
]

const GeneralCompanyInformationSkeleton = () => {
  return (
    <Stack width="100%" p={'20px'} pb={0} mb={1}>
      {skeletonData.map(({ key, isHistory }) => (
        <SimpleEntry
          key={key}
          title={<FormattedMessage id={intl.companyInfo(key)} />}
          content={[<Skeleton key="name" variant="text" width={200} />]}
          {...defaultProps}
          isHistory={isHistory}
          historyData={[1, 2, 3]}
          infoLink={false}
        />
      ))}
    </Stack>
  )
}

export default GeneralCompanyInformationSkeleton
