import { Box, IconButton } from '@mui/material'
import { Handle, NodeProps, Position } from 'reactflow'
import { Add } from '@mui/icons-material'
import { useBuilder } from '../BuilderContext'
import { classes } from './Node.styles'

function Plus(props: NodeProps) {
  const { actions, state } = useBuilder()

  const openDrawer = () => {
    actions.actionPanelToggle({ open: true, nodeId: props.id })
    // actions.addOrUpdateHighlight({ nodeId: props.id, type: 'info' })
  }
  return (
    <Box data-cy="plus-node" onClick={openDrawer}>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
      <>
        <IconButton
          sx={classes.plusStyle(state.highlightedNodes?.[Number(props.id)]?.type)}
        >
          <Add color="inherit" fontSize="inherit" />
        </IconButton>
      </>
    </Box>
  )
}

export default Plus
