import risikaApi from 'services/api/helpers/risikaAPI'
import { PolicyNodesAndEdges } from 'types/queries'

export type RequestData = {
  description?: string
  name?: string
  policy?: PolicyNodesAndEdges
  country: string
  enabled?: boolean
}

export type UpdatePolicyProps = {
  id: number
  policyData: RequestData
}

export type UpdateCreditPolicyError = {
  response: {
    data: {
      errors?: {
        error_code: string
        node_id: string
        error_message: string
      }[]
    }
  }
}

export type UpdateCreditPolicyResponse = {
  data?: { status: 'ok' }
  error?: UpdateCreditPolicyError
}

const updateCreditPolicy = async ({
  id,
  policyData,
}: UpdatePolicyProps): Promise<UpdateCreditPolicyResponse> => {
  return risikaApi
    .post(
      `enterprise_credit_policies/update/${id}`,
      { ...policyData },
      { microservice: 'account' }
    )
    .then(({ data }) => {
      return data
    })
}

export default updateCreditPolicy
