import * as React from 'react'

import { ConditionallyRender, ShowHideAllWrapper } from 'components'
import SimpleEntry from './SimpleEntry'

import { handleAddressField } from 'utils'

import moment from 'moment'

import { FormattedMessage, FormattedNumber } from 'react-intl'
import intl from 'localization/components'
import { Tooltip, Typography } from '@mui/material'

const Tinglysning = ({ properties }) => {
  const [isShowAllProperties, setIsShowAllProperties] = React.useState(false)
  return (
    <ConditionallyRender
      condition={Boolean(properties?.length)}
      when={
        <SimpleEntry
          key="property"
          title={<FormattedMessage id={intl.companyInfo('tinglysning')} />}
          //
          content={[
            properties?.map((property, i) => (
              <ShowHideAllWrapper
                setIsShowAll={setIsShowAllProperties}
                isShowAll={isShowAllProperties}
                key={i}
                amountOfShowedItems={3}
                iteration={i}
                dataLength={properties?.length}
              >
                <div style={{ display: 'flex' }}>
                  <Tooltip
                    title={
                      <>
                        {property.property_type ? (
                          <Typography>
                            <b>
                              <FormattedMessage id={intl.companyInfo('property_type')} />
                              {': '}
                            </b>
                            {property.property_type}
                          </Typography>
                        ) : null}
                        {property.property_value ? (
                          <Typography>
                            <b>
                              {
                                <FormattedMessage
                                  id={intl.companyInfo('property_value')}
                                />
                              }
                              {': '}
                            </b>
                            <FormattedNumber value={property.property_value} /> DKK
                          </Typography>
                        ) : null}
                        {property.land_value ? (
                          <Typography>
                            <b>
                              {<FormattedMessage id={intl.companyInfo('land_value')} />}
                              {': '}
                            </b>
                            <FormattedNumber value={property.land_value} /> DKK
                          </Typography>
                        ) : null}
                        {property.valuation_date ? (
                          <Typography>
                            <b>
                              {
                                <FormattedMessage
                                  id={intl.companyInfo('valuation_date')}
                                />
                              }
                              {': '}
                            </b>
                            {`${moment(property.valuation_date).format('DD.MM.YYYY')}` ??
                              '-'}
                          </Typography>
                        ) : null}
                        {property.property_id ? (
                          <Typography>
                            <b>
                              {<FormattedMessage id={intl.companyInfo('property_id')} />}
                              {': '}
                            </b>
                            {property.property_id}
                          </Typography>
                        ) : null}
                        <Typography>
                          <b>
                            {<FormattedMessage id={intl.companyInfo('source')} />}
                            {': '}
                          </b>
                          Tinglysning
                        </Typography>
                      </>
                    }
                    placement="top"
                  >
                    <div style={{ cursor: 'default' }}>
                      {handleAddressField(property)}
                    </div>
                  </Tooltip>
                </div>
              </ShowHideAllWrapper>
            )),
          ]}
        />
      }
    />
  )
}

export default Tinglysning
