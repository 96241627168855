import React from 'react'
import { Stack } from '@mui/material'
import Conflict from './Conflict'
import { ConflictT } from './CreditPolicy.types'

type Props = {
  conflicts: ConflictT[]
}

const SimpleCreditPolicy = ({ conflicts }: Props) => {
  return (
    <Stack direction="column" spacing={2} mt={3}>
      {conflicts?.length > 0 ? (
        conflicts.map((conflict, i) => (
          <Conflict key={i} text={conflict.text} code={conflict.code} />
        ))
      ) : (
        <Conflict isNoConflicts key={'No conflicts'} text={''} code="no-conflicts" />
      )}
    </Stack>
  )
}

export default SimpleCreditPolicy
