import callBackendAPI from 'services/api/helpers/backendAPI'

/******************
 * Fetch from API *
 ******************/

const trackActivity = ({ action, data }) => {
  return callBackendAPI('/tracking', {
    method: 'post',
    data: {
      action,
      data,
    },
  })
}

export default trackActivity
