import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { subscriptionRemove } from 'services/api'
import { SubscriptionType } from 'services/api/endpoints/risikaAPI/new_subscription'
import { SUBSCRIPTIONS } from './constants'

type DeleteSubscriptionParams = {
  list_id: number
  type: SubscriptionType
}

type DeleteSubscriptionMutationParams = {
  onSuccess?: (data?: string, variables?: DeleteSubscriptionParams) => void
  onError?: (error?: AxiosError, variables?: DeleteSubscriptionParams) => void
}

const deleteSubscriptionById = async ({ type, list_id }: DeleteSubscriptionParams) => {
  await subscriptionRemove(type, list_id)
}

const useDeleteSubscription = ({
  onSuccess,
  onError,
}: DeleteSubscriptionMutationParams) => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (params: DeleteSubscriptionParams) => deleteSubscriptionById(params),
    {
      onSuccess: () => {
        queryClient.refetchQueries(SUBSCRIPTIONS)
        onSuccess?.()
      },
      onError: () => {
        onError?.()
      },
    }
  )
  return { deleteSubscription: mutation.mutate, ...mutation }
}

export { useDeleteSubscription }
