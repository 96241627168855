import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { ConditionallyRender } from 'components'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import styles from '../../styles'
import { FinancialRatioChartDataProps } from './FinancialRatioChartData.types'
import { convertFinancialRatioData } from './FinancialRatioChartData.controller'

const componentWidth = 150

const FinancialRatioChartData = ({
  data,
  fieldKey,
  performances,
}: FinancialRatioChartDataProps) => {
  const { messages } = useIntl()

  const convertedData = useMemo(
    () => convertFinancialRatioData(data, fieldKey, messages),
    [data, fieldKey, messages]
  )

  return (
    <Stack
      direction="column"
      width={componentWidth}
      justifyContent="center"
      alignItems="center"
      spacing="10px"
    >
      <ConditionallyRender
        condition={!!performances?.[fieldKey]?.explanation}
        when={
          <Tooltip
            enterTouchDelay={200}
            placement="top"
            title={performances?.[fieldKey]?.explanation}
          >
            <InfoOutlinedIcon sx={styles.iconButton} />
          </Tooltip>
        }
      />
      <Box>
        {convertedData.map((item, i) => (
          <Stack
            key={item.year}
            direction="row"
            width={componentWidth}
            justifyContent="space-between"
            alignItems="center"
            color={!i ? 'primary.500' : 'grey.800'}
          >
            <Typography color="inherit" variant="body2">
              {item.year}:
            </Typography>
            <Typography color="inherit" variant="body2">
              {item.performance as string | number}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Stack>
  )
}

export default FinancialRatioChartData
