import { Typography } from '@mui/material'
import { useIntl, FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { RestrictedComponent } from 'components'

const CreditMaxCell = ({ cell, localId, currency }) => {
  const reactIntl = useIntl()

  const text = {
    whiteSpace: 'nowrap',
  }

  return (
    <RestrictedComponent local_id={localId} component="risika-score">
      <Typography variant="body2" sx={text}>
        {cell.value === 0 ? (
          <FormattedMessage id={intl.riskMonitoringNew('no-credit')} />
        ) : (
          `${reactIntl.formatNumber(cell.value)} ${currency}`
        )}
      </Typography>
    </RestrictedComponent>
  )
}

export default CreditMaxCell
