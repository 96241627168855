import { SxProps } from '@mui/system'

export const tabButtons = {
  fontSize: '1.4rem',
  fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: '0.00938em',
}

export const classTabButtons: SxProps = {
  ...tabButtons,
}
