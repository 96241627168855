import moment from 'moment'
import { useQuery } from 'react-query'
import companyVisitsSummary from 'services/api/endpoints/risikaAPI/companyVisitsSummary'

export function useCompanyVisitsSummary() {
  // Use moment to figure out the year and then the number of the month
  const year = moment().year()
  const month = moment().month() + 1
  return useQuery(
    ['companyVisitsSummary', year, month],
    () =>
      companyVisitsSummary({
        year,
        month,
      }),
    {
      refetchOnWindowFocus: false,
    }
  )
}
