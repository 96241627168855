import React, { useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import styles from '../../styles/toggle-owner'
import { OwnersToggleProps } from 'pages/company-hierarchy/types'

const OwnersToggle = ({
  nodeDatum,
  isNodeToggled,
  toggleNode,
  onOpenParents,
  direction,
  selectDepth,
  shouldUpdateDepth,
}: OwnersToggleProps) => {
  useEffect(() => {
    selectDepth({ type: direction })
  }, [direction, selectDepth])

  const handleOnClick = () => {
    toggleNode()
    if (shouldUpdateDepth) {
      onOpenParents?.()
    }
  }
  return (
    <Stack direction="column" alignItems="center" sx={styles.main}>
      {!!nodeDatum?.children?.length && (
        <Stack
          data-cy={`${nodeDatum.name}-owners-toggle`}
          alignItems="center"
          justifyContent="center"
          sx={styles.ownersBtn(!isNodeToggled)}
          onClick={handleOnClick}
        >
          <Typography variant="subtitle2">
            <FormattedMessage
              id={intl.companyHierarchy('owners')}
              values={{
                number: nodeDatum?.children?.length,
                toggled: !isNodeToggled ? '+' : 'x',
              }}
            />
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default OwnersToggle
