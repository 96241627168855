const root = {
  backgroundColor: 'common.white',
  padding: 4,
  boxShadow: 1,
  borderRadius: 1,
}
const change = {
  display: 'flex',
}

const changeContent = {
  padding: '0.25rem 0.5rem',
  backgroundColor: 'grey.200',
  borderRadius: 1,
}
const changeArrow = {
  margin: '0.25rem 0.5rem',
}

const paddingAfterDays = {
  pb: 2,
}
const probability = {
  py: 2,
}

export const classes = {
  root,
  change,
  changeContent,
  changeArrow,
  paddingAfterDays,
  probability,
}
