import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)

const GreenTrendArrow = () => {
  if (isSafari) {
    return (
      <ArrowUpwardIcon
        fontSize="inherit"
        data-cy="green-trend-arrow"
        sx={{
          color: 'success.main',
          stroke: 'none', // Must be due to the case of using the arrow in <svg>
        }}
      />
    )
  } else {
    return (
      <ArrowForwardIcon
        fontSize="inherit"
        data-cy="green-trend-arrow"
        sx={{
          color: 'success.main',
          transform: 'rotate(-45deg)',
          position: 'relative',
          top: '3px',
          stroke: 'none', // Must be due to the case of using the arrow in <svg>
        }}
      />
    )
  }
}
export default GreenTrendArrow
