import callBackendAPI from 'services/api/helpers/backendAPI'

/******************
 * Fetch from API *
 ******************/

export type RemoveUserData = { id: number }

const removeUser = ({ id }: RemoveUserData) => {
  return callBackendAPI(`/user/${id}`, {
    method: 'delete',
  }).then(({ data }) => {
    return data
  })
}

export default removeUser
