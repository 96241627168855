import * as React from 'react'
import { Stack, Chip, Typography, Skeleton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import styles from './ChipStack.styles'

const { chipStyles } = styles

const ChipStackLoader = ({ chipAmount = 5 }) => {
  const chipData = React.useMemo(() => {
    const data = []
    for (let i = 0; i < chipAmount; i++) {
      data.push({
        value: i,
        label: `Chip ${i}`,
      })
    }
    return data
  }, [chipAmount])
  return (
    <Stack gap={4} flexWrap="wrap" width={1} direction="row" justifyContent="start">
      {chipData.map((chip) => (
        <Skeleton key={chip.value}>
          <Chip
            sx={chipStyles}
            deleteIcon={<ClearIcon style={{ color: 'inherit' }} />}
            label={
              <Typography variant="body1" color="inherit">
                {chip.label}
              </Typography>
            }
          />
        </Skeleton>
      ))}
    </Stack>
  )
}

export default ChipStackLoader
