import { useQuery } from 'react-query'
import { personRelations } from 'services/api'
import { AxiosError } from 'axios'
import { QUERY_KEYS } from './constants'
import { AvailableCountries } from 'globalTypes'
import {
  PersonRelations,
  Relation,
} from 'services/api/endpoints/risikaAPI/personRelations'

interface Params {
  bankruptcies: Data[]
  country: AvailableCountries
  enabled: boolean
}

interface Data {
  local_id: string[]
  personal_id: string
}

type Bankruptcy = { person: string; companies: string[] }

type BankruptcyDetails = {
  person: {
    id: string
    name: string
  }
  companies: Relation[]
}[]

const bankruptciesNewToNext = (data: Data[]): Bankruptcy[] =>
  data.map((item) => {
    return {
      person: item.personal_id,
      companies: item.local_id,
    }
  })

const fetchBankruptcies = (
  bankruptcies: Bankruptcy[],
  country: AvailableCountries
): Promise<BankruptcyDetails> => {
  const findBankruptCompanies =
    (bankruptCompanyIds: string[]) =>
    ({ relations }: PersonRelations) => {
      return relations.filter(
        (x) =>
          x?.local_organization_id?.id &&
          bankruptCompanyIds.includes(x.local_organization_id.id)
      )
    }

  const promises = bankruptcies.map(async ({ person, companies }) => {
    const x = await personRelations({ person_id: person, country })
    return {
      person: { id: person, name: x?.name || '' },
      companies: x ? findBankruptCompanies(companies)(x) : [],
    }
  })

  return Promise.all(promises) as Promise<BankruptcyDetails>
}

const handleFetchBankruptcies = async (
  bankruptcies: Bankruptcy[],
  country: AvailableCountries
): Promise<BankruptcyDetails> => {
  const data = await fetchBankruptcies(bankruptcies, country)
  const sortedData = data?.map((x) => ({
    ...x,
    companies: x.companies.map((company) => ({
      ...company,
      functions: company.functions.sort((a, b) => {
        const validToA = a.valid_to ? new Date(a.valid_to).getTime() : 0
        const validToB = b.valid_to ? new Date(b.valid_to).getTime() : 0

        return validToA < validToB ? 1 : -1
      }),
    })),
  }))
  return sortedData
}

const useBankruptcyDetails = (params: Params) => {
  const { bankruptcies: bankruptciesData, country, enabled } = params
  const bankruptcies = bankruptciesNewToNext(bankruptciesData)

  const details = useQuery<BankruptcyDetails, AxiosError>(
    QUERY_KEYS.BANKRUPTCY_DETAILS,
    () => handleFetchBankruptcies(bankruptcies, country),
    {
      keepPreviousData: true,
      enabled,
      refetchOnWindowFocus: false,
    }
  )
  return { ...details }
}

export { useBankruptcyDetails }
