import { Box, Stack, Typography } from '@mui/material'
import styles from './FilterSection.styles'
import { FilterCheckboxOption } from '../index'
import { FilterSectionProps } from './FilterSection.types'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const FilterSection = ({ title, filters, onChange }: FilterSectionProps) => {
  return (
    <Stack gap={1}>
      <Box ml={2}>
        <Typography variant="subtitle1" sx={{ marginRight: '11px' }}>
          <FormattedMessage id={intl.advancedSearch(title)} />
        </Typography>
      </Box>
      <Box sx={styles.checkboxContainer}>
        {filters?.map((filter) => (
          <FilterCheckboxOption
            key={filter.label}
            filter={filter}
            checked={filter.checked}
            onChange={() => onChange(filter.label)}
          />
        ))}
      </Box>
    </Stack>
  )
}

export default FilterSection
