import {
  NOT_HIGHER_THAN,
  NOT_LOWER_THAN,
  PERCENTAGE_OF_RISIKA_SCORE,
  SET_YOUR_OWN,
  USE_RISIKA_RECOMMENDATION,
  VALUE,
} from '../DetailedComponentView.model'
import { ApproveCreditState, CompanyTypeState } from './Actions.types'
import { CompanyTypeCategory } from 'types/queries'
import {
  APPROVE_CREDIT_MAX,
  ADJUST_CREDIT_MAX,
  APPROVE_WITH_VALUE,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import { Option } from 'types/general'
import { MessageFormatElement } from 'react-intl'
import intl from 'localization/components'

// export const convertStateToApiData = (
//   state: MinMaxStateType
// ): ConvertedApiDataFromMinMaxState => {
//   const { tolerance, valueMin, valueMax } = state
//   let min = null
//   let max = null
//   let min_strict = null
//   let max_strict = null
//   switch (tolerance) {
//     case 'greater-than':
//       min_strict = Number(valueMin)
//       break
//     case 'equal-to':
//       min = Number(valueMin)
//       max = Number(valueMin)
//       break
//     case 'less-than':
//       max_strict = Number(valueMin)
//       break
//     case 'between':
//       min = Number(valueMin)
//       max = Number(valueMax)
//       break
//     default:
//       break
//   }
//   // if (min || max) return { min, max }
//   // return { min_strict, max_strict }
//   return { min, max, min_strict, max_strict }
// }

// export const convertApiDataToState = (data: any): MinMaxStateType => {
//   let tolerance = ''
//   let valueMin = ''
//   let valueMax = ''
//   if (data.min_strict) {
//     tolerance = 'greater-than'
//     valueMin = data.min_strict
//   }
//   if (data.max_strict) {
//     tolerance = 'less-than'
//     valueMin = data.max_strict
//   }
//   if (data.min && data.max && +data.min < +data.max) {
//     tolerance = 'between'
//     valueMin = data.min
//     valueMax = data.max
//   }
//   if (data.min && data.max && +data.min === +data.max) {
//     tolerance = 'equal-to'
//     valueMin = data.min
//   }
//   if (!data.type) {
//     return {
//       tolerance,
//       valueMin,
//       valueMax,
//     } as MinMaxStateType
//   }
//   return {
//     type: data.type,
//     tolerance,
//     valueMin,
//     valueMax,
//   } as MinMaxStateType
// }

export const convertCompanyTypeStateToApiData = (
  values: CompanyTypeState
): Omit<CompanyTypeCategory, 'category' | 'default_on_null'> => ({
  include: values.map((val: any) => val.value),
})

export const convertApiDataToCompanyTypeState = (
  apiData: CompanyTypeCategory,
  fullCompanyList: [],
  returnContent = false
): CompanyTypeState | string => {
  if (!apiData || !fullCompanyList || !apiData.include?.length) return []
  const convertedData = Object.fromEntries(
    fullCompanyList
      .map((item: { code: string; title: string }) => ({
        value: item.code,
        label: item.title,
      }))
      .map((company: any) => [company.value, company])
  )
  if (returnContent) {
    return apiData.include
      .map((item: any) => convertedData[item])
      .map((company: { value: string; label: string }) => company.value)
      .join(', ')
  }
  return apiData.include.map((item: any) => convertedData[item])
}

export const convertApiDataToStateApproveCredit = (
  apiData: any,
  defaultState: ApproveCreditState
): ApproveCreditState => {
  if (apiData) {
    switch (apiData.outcome) {
      case APPROVE_CREDIT_MAX:
        return {
          ...defaultState,
          method: USE_RISIKA_RECOMMENDATION,
        }
      case ADJUST_CREDIT_MAX:
        return {
          ...defaultState,
          method: SET_YOUR_OWN,
          creditDefinition: PERCENTAGE_OF_RISIKA_SCORE,
          percentageOfRisikaScore: apiData.percentage,
          percentageOfRisikaScoreLimit:
            !apiData.lower_limit && !apiData.upper_limit
              ? ''
              : apiData.lower_limit
              ? NOT_LOWER_THAN
              : NOT_HIGHER_THAN,
          percentageOfRisikaScoreValue: apiData.lower_limit ?? apiData.upper_limit,
        }
      case APPROVE_WITH_VALUE:
        return {
          ...defaultState,
          method: SET_YOUR_OWN,
          creditDefinition: VALUE,
          value: apiData.value,
        }
      default:
        return defaultState
    }
  }
  return defaultState
}

// export const getEquityState = (
//   apiData: EquityApiData,
//   defaultState: EquityStateType
// ): EquityStateType => {
//   if (apiData) {
//     if (Object.keys(apiData).length && apiData.category) {
//       return convertApiDataToState({ ...apiData, type: apiData.category })
//     }
//   }
//   return defaultState
// }

export const addSelectAllToIndustryTypeData = (
  data: any,
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
) => {
  const roots = Object.keys(data).filter((key) => isNaN(+key))
  const ALL = 'ALL'
  const SELECT_ALL = {
    children: [...roots],
    count: 0,
    id: ALL,
    isExpanded: false,
    isHidden: false,
    isLeaf: false,
    isRoot: true,
    // label: 'Select all',
    label: messages[intl.advancedSearch('select-all')],
    level: 0,
    parent: null,
    state: 'unchecked',
    value: ALL,
  }
  const modified = { ALL: { ...SELECT_ALL }, ...data }

  roots.forEach((root) => {
    modified[root].parent = SELECT_ALL.value
  })
  modified[ALL] = SELECT_ALL
  return modified
}
