import { Box, Paper, Skeleton, Stack } from '@mui/material'
import React from 'react'
import IntlTypography from 'components-new/elements/IntlTypography'

const entries = [35, 45, 30, 50, 35]

const styles = {
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  entryOuterContainer: {
    borderLeft: '8px solid',
    borderLeftColor: 'grey.600',
    backgroundColor: 'grey.50',
    width: '100%',
  },
  entryInnerContainer: {
    border: '1px solid',
    borderColor: 'grey.200',
    borderLeft: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    paddingX: 2,
  },
}

const NewScorecardExplanationSkeleton = () => {
  return (
    <Paper elevation={2} component={Stack} m={4} p={0}>
      <Box p={4}>
        <IntlTypography group="newScorecard" labelKey="title" variant="h4" />
      </Box>
      <Stack sx={styles.container}>
        {entries.map((entry, index) => (
          <Stack key={index} sx={styles.entryOuterContainer}>
            <Stack sx={styles.entryInnerContainer}>
              <Skeleton variant="text" width={`${entry}%`} height={22} />
              <Skeleton variant="text" width="10%" height={22} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Paper>
  )
}

export default NewScorecardExplanationSkeleton
