import { useQuery } from 'react-query'
import { getStatstidende } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useStatstidende(localId: LocalId, staleTime = 0) {
  return useQuery(
    ['getStatstidende', localId.id, localId.country],
    () => getStatstidende(localId),
    {
      staleTime,
      enabled: !!isCallAllowed('getStatstidende', localId.country),
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
