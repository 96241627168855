import { InputField } from 'components-new'
import { Typography } from '@mui/material'
import { PhoneNumberCellProps } from '../../UserTable.types'
import { KeyboardEvent } from 'react'

const PhoneNumberCell = ({
  user,
  selectedUser,
  isAddingNewUser,
  isSelected,
  handleSaveChanges,
  handleUpdateUserPhone,
}: PhoneNumberCellProps) => {
  const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isAddingNewUser) return
    if (e.key === 'Enter') handleSaveChanges()
  }

  if (isSelected && selectedUser) {
    return (
      <InputField
        onKeyDown={handleEnterPress}
        value={selectedUser.phoneNumber || ''}
        handleChange={handleUpdateUserPhone}
      />
    )
  }

  return <Typography variant="body2">{user.phoneNumber || '-'}</Typography>
}

export default PhoneNumberCell
