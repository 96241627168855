import { Scheduler } from 'types/queries'
export const mapFrequencyDataFromRequestToState = (scheduler: Scheduler) => {
  const { frequency, days, mode, starting_date } = scheduler
  return {
    frequency: frequency,
    frequencyPeriod: mode,
    dayOfMonth: mode !== 'weekly' ? days : ['first_day_month'],
    weekDays: mode === 'weekly' ? days : ['monday'],
    selectedDate: new Date(starting_date),
  }
}
