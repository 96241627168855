import { colors } from 'configs'
import { Tooltip, Typography } from '@mui/material'

const TooltipBulets = ({ data }) => {
  return (
    <>
      {data.map((text) => (
        <div style={{ marginBottom: '0.7rem' }} key={text.title}>
          <Typography
            style={{
              margin: '0 auto',
              width: '100%',
              marginBottom: '0.7rem',
            }}
          >
            {' '}
            {text.title}{' '}
          </Typography>
          {text.description.map((desc) =>
            desc ? (
              <Typography key={desc} style={{ fontStyle: 'italic' }}>
                • {desc}
              </Typography>
            ) : null
          )}
        </div>
      ))}
    </>
  )
}

const getColor = (description) => {
  if (description === 'strong') return colors?.bg_rating_10
  if (description === 'manual') return colors?.bg_rating_10
  if (description === 'weak') return colors?.bg_rating_3
}

const SpecialRemarks = ({ ownership, pep }) => {
  return ownership?.length || pep ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {pep ? (
        <>
          <Tooltip title={pep?.description} placement="top">
            <Typography
              variant="body2"
              style={{
                color: getColor(pep.description_code),
                cursor: 'default',
                margin: '0 auto',
              }}
            >
              PEP
            </Typography>
          </Tooltip>
        </>
      ) : null}

      <Tooltip
        title={
          ownership?.length ? (
            <TooltipBulets
              data={ownership
                ?.filter((x) => !x.valid_to)
                ?.map((y) => ({
                  title: y.title,
                  description: y.description,
                }))}
            />
          ) : null
        }
        placement="top"
      >
        <Typography variant="body2" style={{ fontStyle: 'italic', cursor: 'default' }}>
          {ownership?.length
            ? `${ownership
                ?.filter((x) => !x.valid_to)
                ?.map((y) => y.title)?.[0]
                ?.substring(0, 12)}...`
            : null}
        </Typography>
      </Tooltip>
    </div>
  ) : (
    <Typography variant="body2" style={{ marginLeft: '1rem' }}>
      -
    </Typography>
  )
}

export default SpecialRemarks
