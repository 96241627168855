import callBackendAPI from 'services/api/helpers/backendAPI'
import { Role } from 'types/general'
import { AvailableCountriesCapital } from 'globalTypes'

/******************
 * Fetch from API *
 ******************/

export type CustomerData = {
  id: number
  name: string
  country: AvailableCountriesCapital
  local_id: string
  is_revoked: boolean
  expires_at: Date
  responsible_user_id: number
  domain: string
  is_removed: boolean
}

export type ResponseDataGetUser = {
  data: {
    id: number
    role: Role
    email: string
    name: string
    phone_number: string
    api_user_id: number
    is_verified: boolean
    last_login: Date
    customer_id: number
    should_change_password: boolean
    created_at: Date
    customer: CustomerData
  }
}

const getUser = (id: number): Promise<ResponseDataGetUser> => {
  return callBackendAPI(`/user/${id}`)
}

export default getUser
