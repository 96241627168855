import { AvailableCountries } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Fetch from API *
 ******************/

export type DetailedNumberOfEmployees = {
  date: string
  employees_interval: string
  employees_number: number
  fte_interval: string
  fte_number: number
}

type ResponseData = {
  data: DetailedNumberOfEmployees[]
}

async function getDetailedNumberOfEmployees(
  id: string,
  country: AvailableCountries = 'DK'
): Promise<DetailedNumberOfEmployees[]> {
  const { data }: ResponseData = await risikaAPI.get(`/company/employees/${id}`, {
    microservice: 'data',
    country: country ?? 'DK',
  })
  return data
}

export default getDetailedNumberOfEmployees
