import risikaAPI from 'services/api/helpers/risikaAPI'
import { SubscriptionList } from 'types/queries'

async function subscriptionShow(listId: string, type: 'CREDIT_COMPLIANCE' | 'MONITOR') {
  const { data }: { data: SubscriptionList } = await risikaAPI.get(
    `/show/${listId}?type=${type}`,
    {
      microservice: 'subscription',
    }
  )

  return data
}

export default subscriptionShow
