import { MenuItem, Select, Skeleton, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { SingleSelectPropsType } from './SingleSelect.types'
import Show from 'components/Show'

const SingleSelect = ({
  title = null,
  placeholder,
  options,
  value,
  disablePlaceholderClick = true,
  handleChange,
  isDisabled = false,
  sx,
  optionIsRawString = false,
  dataCy,
  isLoading = false,
}: SingleSelectPropsType) => {
  return (
    <Stack width={1}>
      {isLoading ? (
        <Skeleton sx={sx} />
      ) : (
        <>
          <Typography variant="subtitle2">
            {title ? <FormattedMessage id={intl.creditPolicyNew(title)} /> : ''}
          </Typography>
          <Select
            sx={sx}
            displayEmpty
            value={value}
            onChange={(e) => handleChange(e.target.value as string)}
            disabled={isDisabled}
            data-cy={`${dataCy}-single-select-menu-list`}
          >
            <Show when={!!placeholder}>
              <MenuItem disabled={disablePlaceholderClick} value="">
                <FormattedMessage id={intl.creditPolicyNew(placeholder ?? '')} />
              </MenuItem>
            </Show>
            {options.map((option) => (
              <MenuItem key={option} value={option} data-cy={`${dataCy}-${option}`}>
                {optionIsRawString ? (
                  option
                ) : (
                  <FormattedMessage id={intl.creditPolicyNew(option)} />
                )}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </Stack>
  )
}
export default SingleSelect
