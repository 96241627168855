import downloadCSV from './csv'
import downloadExcel from './excel'
import downloadJson from './json'

/**
 * The available download formats.
 */
// const downloadFormat: { [*]: SupportedFormats } = {
const downloadFormat = {
  CSV: 'csv',
  EXCEL: 'excel',
  JSON: 'json',
}

/**
 * Get a download function for a given document type.
 */
const downloadAs = (format) => {
  if (format === downloadFormat.CSV) {
    return downloadCSV
  }

  if (format === downloadFormat.EXCEL) {
    return downloadExcel
  }

  if (format === downloadFormat.JSON) {
    return downloadJson
  }
}

export { downloadAs, downloadFormat, downloadCSV, downloadExcel }
