import { Moment } from 'moment'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'

const dateFormat = (date: Moment, format: 'relative' | 'duration' | string) => {
  if (format === 'relative') {
    return date.fromNow()
  }

  if (format === 'duration') {
    return date.fromNow(true)
  }

  return date.format(format)
}
/**
 * A localized date.
 *
 * @param {moment} date A moment date object.
 * @param {string} format The format to use. Defaults to `relative`.
 * @param {function} children A function that receives the formatted date as a string.
 */

type HumanDateProps = {
  children?: (date: string) => JSX.Element
  date: Moment
  format?: 'relative' | 'duration' | string
}

const HumanDate = ({ children, date, format = 'relative' }: HumanDateProps) => {
  const intl = useIntl()
  date.locale(intl?.locale)

  if (children && typeof children === 'function') {
    return children(dateFormat(date, format))
  }

  return <Typography variant="body2">{dateFormat(date, format)}</Typography>
}

export default HumanDate
