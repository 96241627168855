import { useMutation, useQueryClient } from 'react-query'
import { postCreditPolicy } from 'services/api'

export const useCreditPolicyCreate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: postCreditPolicy,
    onMutate: async () => {
      await queryClient.cancelQueries('creditPolicyGetAll')
    },
    onSuccess: async () => {
      await queryClient.refetchQueries('creditPolicyGetAll')
    },
  })
}
