import 'babel-polyfill'
import 'typeface-montserrat'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { unregister } from './registerServiceWorker'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'context/store'
import 'utils/react-table-defaults'
import './styles/index.css'
import App from './App'
import * as Sentry from '@sentry/browser'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/da'
import { keys } from 'configs'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Mixpanel } from 'services/helpers/mixpanel'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Auth0Provider } from '@auth0/auth0-react'
import { HubspotConversationsProvider } from 'services/hubSpot/HubspotConversationsProvider'
import { theme } from 'styles-new/mui5-transition/theme'
import { ThemeProvider } from '@mui/material/styles'
Sentry.init({
  dsn: keys.SENTRY_DSN,
})

Mixpanel.init(keys.MIXPANEL)

export const queryClient = new QueryClient()
queryClient.setDefaultOptions({
  queries: {
    retry: false,
  },
})

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="risika.eu.auth0.com"
      clientId="lFfB0HZTFG3EOej7pl5Nfof0qyk17VCn"
      redirectUri={window.location.origin}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <HubspotConversationsProvider>
                <ReactQueryDevtools />
                <App />
              </HubspotConversationsProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

unregister()
