import risikaAPI from 'services/api/helpers/risikaAPI'

type ValidateLocalIdArgs = {
  localIds: string[]
  allowedCountries?: string[]
}

function sliceIntoChunks(arr: string[], chunkSize: number) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

const validateLocalIds = async ({
  localIds,
  allowedCountries = ['DK'],
}: ValidateLocalIdArgs) => {
  const validatableCountries = ['DK', 'SE', 'NO', 'FI']
  const chunks = sliceIntoChunks(localIds, 999)
  const res = await Promise.all(
    chunks.map(async (chunk) =>
      risikaAPI.post(
        `/validate/local_id`,
        {
          countries: allowedCountries
            .map((x) => x.toUpperCase())
            .filter((x) => validatableCountries.includes(x)),
          local_ids: chunk,
        },
        {
          microservice: 'data',
        }
      )
    )
  )
  let data = {} as Record<string, unknown[]>
  res.forEach((x) => {
    data = {
      ...data,
      ...x.data.matches,
    }
  })
  return { data }
}
export default validateLocalIds
