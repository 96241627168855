import React from 'react'
import { useDebounce } from 'react-use'

const getUniqueOptions = (rows, id) => {
  const options = new Set()

  rows.forEach((row) => {
    options.add(row.values[id])
  })

  return [...options.values()]
}

/**
 * Filter by searching the specified column.
 *
 * The `InputComponent` gets fed a `data` prop containing
 * the current count of the table.
 *
 * @param {React.Component<{value, onchange, data?}>} InputComponent The InputComponent to use as input.
 * @param {string} filterValue The value that is being filtered by.
 * @param {array} preFilteredRows The rows before the filtering was applied.marked
 * @param {callback} setFilter Callback that sets the filter.
 */
const SelectFilter = ({
  InputComponent,
  debounceTime = 300,
  column: { preFilteredRows, setFilter, id },
}) => {
  const count = preFilteredRows.length
  const [debounceValue, setDebounceValue] = React.useState('')

  useDebounce(
    () => {
      setFilter(debounceValue)
    },
    debounceTime,
    [debounceValue]
  )

  const options = React.useMemo(
    () => getUniqueOptions(preFilteredRows, id),
    [id, preFilteredRows]
  )

  return (
    <InputComponent
      data={{ count }}
      value={debounceValue ?? ''}
      values={options}
      onChange={(e) => {
        setDebounceValue(e.target.value ?? '')
      }}
    />
  )
}

export default SelectFilter
