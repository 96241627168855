import { SxProps } from '@mui/system'

const titleChartBoxVerticalContainer: SxProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'baseline',
}

const titleChartBoxContainer: SxProps = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'space-between',
}

const titleErrorBoxContainer: SxProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

export const classes = {
  titleChartBoxVerticalContainer,
  titleChartBoxContainer,
  titleErrorBoxContainer,
}
