import { useQuery } from 'react-query'
import { hierarchySummary } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useHierarchySummary(localId: LocalId) {
  return useQuery(
    ['hierarchySummary', localId.id, localId.country],
    () => hierarchySummary(localId),
    {
      enabled: !!isCallAllowed('hierarchySummary', localId.country),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
