import { useQuery } from 'react-query'
import { financialNumbers } from 'services/api'
import { isCallAllowed } from 'configs'
import { useUserSettings } from './useUserSettings'
import { AvailableCountries, LocalId } from 'globalTypes'

type Options = {
  consolidated?: boolean
  selectedCurrency?: string
  currency?: string
}

export function useFinancialNumbers(
  localId: LocalId,
  options: Options,
  appendCurrencyURL?: boolean,
  staleTime = 0
) {
  const userSettingsQuery = useUserSettings()

  return useQuery(
    ['financialNumbers', localId.id, localId.country, appendCurrencyURL],
    () =>
      financialNumbers(
        localId,
        {
          ...options,
          currency: userSettingsQuery?.data?.selectedCurrency?.currency,
        },
        appendCurrencyURL
      ),
    {
      staleTime,
      enabled: isCallAllowed('financialNumbers', localId.country as AvailableCountries)
        ? !userSettingsQuery.isLoading || userSettingsQuery.isError
        : false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
