import { ColorLevelT } from 'types/general'
import { SxProps } from '@mui/material/styles'
import { theme } from 'styles-new/mui5-transition/theme'
import { riskColors } from 'configs/constants/theme'

const outerFrame = (colorStr: ColorLevelT, isGrey: boolean): SxProps => ({
  minWidth: '40%',
  border: `3px solid ${isGrey ? riskColors.unknown : colorStr}`,
  padding: theme.spacing(4),
  borderRadius: 3,
})

const coloredText = (colorStr: ColorLevelT, isGrey: boolean): SxProps => {
  return isGrey ? { color: riskColors.unknown } : { color: colorStr }
}

export default {
  outerFrame,
  coloredText,
}
