import { useState, createContext, useContext } from 'react'

const chDisplaySettingsDefault = {
  risikaScore: true,
  localId: true,
  role: true,
  profitLoss: true,
  equity: true,
}
export const HierarchyContext = createContext()

export const HierarchySceneContext = ({ children }) => {
  const [displayState, setDisplayState] = useState(chDisplaySettingsDefault)

  const toggleProperty = (e, item) => {
    const newDisplayState = {
      ...displayState,
      [item]: e.target.checked,
    }
    setDisplayState(newDisplayState)
  }

  const value = {
    displayState,
    toggleProperty,
  }
  return <HierarchyContext.Provider value={value}>{children}</HierarchyContext.Provider>
}

export const useHierarchySceneContext = () => {
  const ctx = useContext(HierarchyContext)

  if (ctx != null) {
    return ctx
  }
  throw new Error(
    '`useHierarchySceneContext` must be used inside of a `HierarchySceneContext`'
  )
}
