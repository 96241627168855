import { Stack, Typography } from '@mui/material'
import { padding } from './columns.styles'
import { PeopleVisitResult } from 'services/api/endpoints/risikaAPI/peopleVisitsList'

type Props = {
  item: PeopleVisitResult
}

function Companies({ item }: Props) {
  return (
    <Typography
      sx={{
        maxWidth: 400,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...padding,
        paddingRight: 0,
      }}
      color="grey.800"
      variant="body2"
    >
      {item.active_company_relations.map((company) => company).join(', ')}
    </Typography>
  )
}

export default Companies
