import intl from 'localization/components'
import ShieldIcon from '@mui/icons-material/Shield'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import Equalizer from '@mui/icons-material/Equalizer'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import colours from 'configs/colors'

export const tabsDefinition = [
  {
    title: intl.observationalLists('tab-risika-credit-score'),
    value: 0,
    Icon: ShieldIcon,
  },
  {
    title: intl.observationalLists('tab-financial-performance'),
    value: 1,
    Icon: Equalizer,
  },
  {
    title: intl.observationalLists('tab-companies'),
    value: 2,
    Icon: FormatListBulletedIcon,
  },
]

export const riskInitialFilters = {
  LOW: {
    label: intl.mainRecommendation('risk-level-low'),
    color: colours.riskIntelligence.lowRisk,
    value: 'LOW',
    checked: true,
  },
  MEDIUM: {
    label: intl.mainRecommendation('risk-level-medium'),
    color: colours.riskIntelligence.mediumRisk,
    value: 'MEDIUM',
    checked: true,
  },
  HIGH: {
    label: intl.mainRecommendation('risk-level-high'),
    color: colours.riskIntelligence.highRisk,
    value: 'HIGH',
    checked: true,
  },
  UNKNOWN: {
    label: intl.mainRecommendation('risk-level-unknown'),
    color: colours.risikaBlack,
    value: 'UNKNOWN',
    checked: true,
  },
}

export const automatorComplianceInitialFilters = {
  approved: {
    label: intl.portfolioInsights('approved'),
    color: colours.riskIntelligence.lowRisk,
    value: true,
    checked: true,
  },
  reject: {
    label: intl.portfolioInsights('rejected'),
    color: colours.riskIntelligence.highRisk,
    value: true,
    checked: true,
  },
  manual_check: {
    label: intl.portfolioInsights('manual-check'),
    color: colours.riskIntelligence.mediumRisk,
    value: true,
    checked: true,
  },
  no_policy_set: {
    label: intl.portfolioInsights('no-policy-set'),
    color: '#878E95',
    value: true,
    checked: true,
  },
}

export const simpleComplianceInitialFilters = {
  COMPLIANT: {
    label: intl.portfolioInsights('compliant-label'),
    color: colours.riskIntelligence.lowRisk,
    value: true,
    checked: true,
  },
  NONCOMPLIANT: {
    label: intl.portfolioInsights('non-compliant-label'),
    color: colours.riskIntelligence.highRisk,
    value: false,
    checked: true,
  },
}

export const initialFilters = {
  [apiKeys.freeSearch]: '',
  [apiKeys.creditScore]: riskInitialFilters,
}
