import { withRouter } from 'react-router-dom'
import { Typography, Button } from '@mui/material'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { RISIKA_PRICING_PAGE } from 'configs/constants/system'
import { colors } from 'configs'

const StyledMainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  '@media screen and (min-width: 600px)': {
    width: '64rem',
    margin: '0 auto',
  },
  '@media screen and (max-width: 600px)': {
    padding: '2rem',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: colors?.risikaContrast,
  textAlign: 'center',
  marginRight: '2rem',
  padding: '1.5rem 0',
  '&:hover': {
    backgroundColor: colors?.risikaContrast,
  },
  '@media screen and (min-width: 600px)': {
    margin: '1rem',
    marginTop: '3.5rem',
    height: '6rem',
    width: '30rem',
    borderRadius: '0.25rem',
    fontSize: '1.125rem',
  },
  '@media screen and (max-width: 600px)': {
    marginTop: '2rem',
    width: '30rem',
    height: '5.5rem',
  },
}))

const StyledLogo = styled('img')(({ theme }) => ({
  '@media screen and (min-width: 600px)': {
    width: '29rem',
    marginLeft: '5rem',
  },
  '@media screen and (max-width: 600px)': {
    marginTop: '2rem',
    marginBottom: '3rem',
  },
}))

const StyledErrorText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '6rem',
}))

const StyledErrorMessage = styled(Typography)(({ theme }) => ({
  maxWidth: '36rem',
  fontSize: '1.5rem',
  textAlign: 'center',
}))

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1.5rem',
  '@media screen and (min-width: 600px)': {
    marginBottom: '5rem',
  },
  '@media screen and (max-width: 600px)': {
    display: 'contents',
  },
}))

const ButtonComponent = ({ errorCode }) => {
  switch (errorCode) {
    case '402':
      return (
        <>
          <StyledButton href="/home">
            <FormattedMessage id={intl.notFound('not-found-button-dashboard')} />
          </StyledButton>
          <StyledButton target="_blank" href={RISIKA_PRICING_PAGE}>
            <FormattedMessage id={intl.notFound('pricing-button')} />
          </StyledButton>
        </>
      )
    case '500':
    case '503':
    default:
      return (
        <StyledButton href="/home">
          <FormattedMessage id={intl.notFound('not-found-button-dashboard')} />
        </StyledButton>
      )
  }
}

const NotFoundPage = (props) => {
  const errorList = ['402', '500', '503']
  const errorCode = errorList.includes(props.match.params.token)
    ? props.match.params.token
    : '404'

  return (
    <StyledMainContainer className="bg-risika">
      <StyledContainer>
        <StyledErrorText variant="h1">{errorCode}</StyledErrorText>
        <StyledLogo
          src="https://dashboard.risika.dk/risika/logo-black.svg"
          alt="Risika Logo"
        />
      </StyledContainer>
      <StyledErrorMessage>
        <FormattedMessage id={intl.notFound(`not-found-${errorCode}`)} />
      </StyledErrorMessage>
      <ButtonComponent errorCode={errorCode} />
    </StyledMainContainer>
  )
}

export default withRouter(NotFoundPage)
