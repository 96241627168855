import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { subscriptionUpdate } from 'services/api'
import { Subscription } from 'services/api/endpoints/risikaAPI/new_subscription'
import { SUBSCRIPTIONS } from './constants'

type PostSubscriptionMutationParams = {
  onSuccess?: (data?: Subscription) => void
  onError?: (error?: AxiosError, data?: Subscription) => void
}

const usePostSubscription = ({ onSuccess, onError }: PostSubscriptionMutationParams) => {
  const queryClient = useQueryClient()
  const mutation = useMutation((params: Subscription) => subscriptionUpdate(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(SUBSCRIPTIONS)
      onSuccess?.()
    },
    onError: (error: AxiosError, params) => {
      onError?.(error, params)
    },
  })
  return { postSubscription: mutation.mutate, ...mutation }
}

export { usePostSubscription }
