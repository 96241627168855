import { useState } from 'react'
import { Popper, ClickAwayListener, Box, IconButton, List, Paper } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useStyles } from '../styles/header-button-handler'
import { EDIT_VIEW_BUTTON } from 'pages/credit-check/CreditCheckPage.model'

const childrenToCloseThePopper = [EDIT_VIEW_BUTTON]

export const HeaderButtonHandler = ({ children, headerId = 'simple-popper' }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenClose = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? headerId : undefined

  // Add a wrapper to the children to close the popper when the button is clicked
  const renderChildren = (children) => {
    if (Array.isArray(children)) {
      return children.map((child) => {
        return childrenToCloseThePopper.includes(child?.key || '') ? (
          <Box onClick={handleOpenClose}>{child}</Box>
        ) : (
          child
        )
      })
    } else {
      return children
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        aria-describedby={id}
        sx={{ fontSize: 24, color: 'grey.600' }}
        onClick={(e) => handleOpenClose(e)}
        className={classes.iconButton}
      >
        <MoreVertIcon color="inherit" fontSize="inherit" />
      </IconButton>
      <Popper placement="bottom-end" open={open} id={id} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={(e) => handleOpenClose(e)}>
          <Paper>
            <List disablePadding sx={{ width: '100%' }}>
              {renderChildren(children)}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default HeaderButtonHandler
