import { ECPAvailableCountries, CreditPolicyCurrency } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { CREDIT_POLICY_CURRENCIES } from 'globalVariables'
import { Account } from 'risika-api-response-types'

export type ECPDataType = Awaited<ReturnType<typeof getECPDataStructure>>

const getECPDataStructure = async (country: ECPAvailableCountries) => {
  const { data }: { data: Account.EnterpriseCreditPoliciesSupportVariables } =
    await risikaAPI.post(
      `/enterprise_credit_policies/support/variables`,
      {
        country: country.toUpperCase(),
        currency:
          CREDIT_POLICY_CURRENCIES[
            `${country.toUpperCase()}` as keyof CreditPolicyCurrency
          ],
      },
      {
        microservice: 'account',
      }
    )
  const apiData = Object.keys(data)
    .map((key) => data[key as keyof Account.EnterpriseCreditPoliciesSupportVariables])
    .map((item) => {
      return {
        ...item,
        template: item?.template,
      }
    })
    .filter((x) => x?.label ?? '')

  return apiData
}

export default getECPDataStructure
