import React from 'react'
import {
  FormLabel,
  FormControl,
  FormGroup,
  Divider,
  Typography,
  Stack,
} from '@mui/material'
import { defSubscription, monitorOptions } from '../../model'
import { buildOptions, optionsToArray, fromOptionsArray } from '../../controller'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Option } from 'components'
import { EmailSettings } from 'services/api/endpoints/risikaAPI/new_subscription'
import { getAccountStatusData } from 'utils/getAccountStatusData'

type AddSubscriptionOptionsProps = {
  selectedOptions: Record<string, any>
  onError: (err: unknown) => void
  onChange: (
    options: Record<keyof typeof defSubscription, Record<keyof EmailSettings, boolean>>
  ) => void
}

type OptionProps = {
  key: string
  value: boolean
  important?: boolean
}

const AddSubscriptionOptions = ({
  selectedOptions,
  onError,
  onChange,
}: AddSubscriptionOptionsProps) => {
  const [error, setError] = React.useState(false)
  const [options, setOptions] = React.useState(
    selectedOptions
      ? (buildOptions(selectedOptions?.email_settings) as Record<string, OptionProps>)
      : (monitorOptions as Record<string, OptionProps>)
  )

  const status = getAccountStatusData()
  const isECPUser = status.rights.global?.enterprise_credit_policy

  const important: OptionProps[] = optionsToArray(options).filter(
    // @ts-expect-error
    (option: OptionProps) => option.important
  )
  const regular: OptionProps[] = optionsToArray(options).filter(
    // @ts-expect-error
    (option: OptionProps) => !option.important
  )

  const sanitisedRegularOptions = isECPUser
    ? regular
    : regular.filter((option) => {
        return option.key !== 'policy_outcome'
      })

  React.useEffect(() => {
    const hasError = !optionsToArray(options).filter(
      // @ts-expect-error
      (option: OptionProps) => option.value
    ).length

    if (error !== hasError) {
      setError(hasError)
      onError(hasError)
    }
  }, [options, error, onError])

  const handleChangeCheckedMultiple = (update: OptionProps[]) => {
    const buildUpdate = update.reduce(
      (acc, cur) => {
        return {
          ...acc,
          [cur.key]: {
            ...options[cur.key],
            value: cur.value,
          },
        }
      },
      { ...options }
    )

    setOptions(buildUpdate)
    // @ts-expect-error
    onChange({ email_settings: fromOptionsArray(optionsToArray(buildUpdate)) })
  }

  const handleChangeChecked = (key: string, checked: boolean) => {
    const newOptions = {
      ...options,
      [key]: {
        ...options[key],
        value: checked,
      },
    }

    setOptions(newOptions)

    // @ts-expect-error
    onChange({ email_settings: fromOptionsArray(optionsToArray(newOptions)) })
  }

  const handleOption = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    handleChangeChecked(key, event.target.checked)

  const handleAllOptions = (options: OptionProps[]) => () => {
    const shouldUnselect = isIndeterminate(options) || isAllChecked(options)

    shouldUnselect
      ? handleChangeCheckedMultiple(
          options.map((option) => ({ key: option.key, value: false }))
        )
      : handleChangeCheckedMultiple(
          options.map((option) => ({ key: option.key, value: true }))
        )
  }

  const isIndeterminate = (options: OptionProps[]) => {
    const selected = options.filter((x) => x.value).length
    const all = options.length

    return selected !== 0 && selected !== all
  }

  const isAllChecked = (options: OptionProps[]) =>
    options.filter((x) => x.value).length === options.length
  return (
    <Stack direction="column" spacing={2}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <Typography variant="subtitle1" mb={4}>
            <FormattedMessage id={intl.riskMonitoringSubscription('important-changes')} />
          </Typography>
        </FormLabel>
        <FormGroup>
          <Option
            indeterminate={isIndeterminate(important)}
            checked={isAllChecked(important)}
            onChange={handleAllOptions(important)}
            value="important-select-all"
            className=""
            label={
              <FormattedMessage
                id={intl.riskMonitoringSubscription('all-important-changes')}
              />
            }
          />
          <Divider />
          {important.map((option: OptionProps) => (
            <Option
              key={option.key}
              indeterminate={false}
              checked={option.value}
              onChange={handleOption(option.key)}
              value={option.key}
              className=""
              label={
                <FormattedMessage id={intl.riskMonitoringSubscription(option.key)} />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <Typography variant="subtitle1" mb={4}>
            <FormattedMessage id={intl.riskMonitoringSubscription('regular-changes')} />
          </Typography>
        </FormLabel>
        <FormGroup>
          <Option
            indeterminate={isIndeterminate(regular)}
            checked={isAllChecked(regular)}
            onChange={handleAllOptions(regular)}
            value="regular-select-all"
            className=""
            label={
              <FormattedMessage
                id={intl.riskMonitoringSubscription('all-regular-changes')}
              />
            }
          />
          <Divider />
          {sanitisedRegularOptions.map((option: OptionProps) => (
            <Option
              key={option.key}
              checked={option.value}
              onChange={handleOption(option.key)}
              value={option.key}
              indeterminate={false}
              className=""
              label={
                <FormattedMessage id={intl.riskMonitoringSubscription(option.key)} />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </Stack>
  )
}

export default AddSubscriptionOptions
