import { Box } from '@mui/material'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import React from 'react'

const main = (isRestricted: boolean) => {
  return {
    '& *': {
      color: isRestricted ? `grey.600` : 'inherit',
      cursor: 'pointer',
    },
  }
}

type Props = {
  children: React.ReactNode
  isAdvanced?: boolean
}

const FieldWrapper = ({ children, isAdvanced = false }: Props) => {
  const { isInferiorPlan, setIsRestrictionModalOpen } = useCountryPolicy()
  const isFieldRestricted = isInferiorPlan && isAdvanced
  const handleClick = (e: React.MouseEvent) => {
    if (isFieldRestricted) {
      setIsRestrictionModalOpen(true)
    }
  }

  return (
    <Box sx={main(isFieldRestricted)} onClick={handleClick}>
      {children}
    </Box>
  )
}

export default FieldWrapper
