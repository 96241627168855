import { TableDataAPIType } from 'components-new/data-display/Table/Table.types'
import intl from 'localization/components'
import { getFormattedDateForScheduler, getTodayDifferentYear } from 'localization/utils'

export const apiParameters = {
  page: 1,
  limit: 20,
  dates: {
    from: getFormattedDateForScheduler(getTodayDifferentYear(-1)),
    to: getFormattedDateForScheduler(new Date()),
  },
  sort_by: {},
  filter_on: {},
}

export const columnsDefinition = [
  {
    key: 'id',
    sub_key: 'local_organization_id',
    type: 'number',
    width: 0,
  },
  {
    key: 'checkbox',
    sub_key: null,
    width: 1,
    minWidth: '50px',
    justifyContent: 'center',
    type: 'checkbox',
  },
  {
    key: 'company_name',
    sub_key: null,
    label: intl.generic('company-name'),
    width: 3,
    minWidth: '200px',
    justifyContent: 'flex-start',
    type: 'text',
  },
  {
    key: 'country',
    sub_key: 'local_organization_id',
    label: intl.generic('country'),
    width: 1,
    minWidth: '120px',
    justifyContent: 'center',
    type: 'text',
  },
  {
    key: 'score',
    sub_key: null,
    label: intl.riskMonitoring('score'),
    width: 1,
    minWidth: '100px',
    justifyContent: 'center',
    type: 'score',
  },
  {
    key: 'credit_automator_result',
    sub_key: null,
    label: intl.portfolioInsights('tab-compliance-label'),
    width: 1,
    minWidth: '150px',
    justifyContent: 'center',
    type: 'text',
  },
  {
    key: 'allowed',
    sub_key: 'credit_policy',
    label: intl.portfolioInsights('original-tab-compliance-label'),
    width: 1,
    minWidth: '150px',
    justifyContent: 'center',
    type: 'text',
  },
  {
    key: 'value',
    sub_key: 'credit_policy_credit_max',
    label: intl.generic('policy_credit_limit'),
    width: 2,
    minWidth: '200px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  {
    key: 'credit_max',
    sub_key: null,
    label: intl.generic('credit-max'),
    width: 3,
    minWidth: '170px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  {
    key: 'equity',
    sub_key: 'financial_key_figures',
    label: intl.generic('equity'),
    width: 3,
    minWidth: '200px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  {
    key: 'profit_loss',
    sub_key: 'financial_key_figures',
    label: intl.generic('profit-loss'),
    width: 3,
    minWidth: '200px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  {
    key: 'employees_number',
    sub_key: null,
    label: intl.searchBusiness('employees'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'number',
  },
  {
    key: 'status_code',
    sub_key: null,
    label: intl.generic('status'),
    width: 2,
    minWidth: '200px',
    justifyContent: 'center',
    type: 'text_status',
  },
]
export const blankTableAPIData: TableDataAPIType = {
  credit_policy_used: 'credit_policy',
  data: [],
  count: 0,
  page_count: 0,
}
