import { useEffect } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Typography,
  Stack,
  Box,
  IconButton,
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { classes } from 'pages/risk-monitoring/styles/subscription-details'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PopConfirmation } from 'components'
import { LanguageRadioButton } from 'components-new'
import SubscriptionOptions from './AddSubscriptionOptions'
import showFrequency from './SubscriptionControlPanel.controller'
import { useDispatch } from 'react-redux'
import { updateSubscription } from 'store_deprecated'
import SelectFrequency from './SelectFrequency'
import { faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  EmailSettings,
  Scheduler,
  Subscription,
} from 'services/api/endpoints/risikaAPI/new_subscription'
import { Icon } from '@fortawesome/fontawesome-svg-core'
import { defSubscription } from 'pages/risk-monitoring/model'

type EditSubscriptionOptionsProps = {
  subscription: Subscription
  index: number
  onChange: (data: Subscription, index: number) => void
  name: string
  deleteSubscription: (list_id: number) => void
  deleteButtonLoading: boolean
}

const EditSubscriptionOptions = ({
  subscription,
  index,
  onChange,
  name,
  deleteSubscription,
  deleteButtonLoading,
}: EditSubscriptionOptionsProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  // @ts-ignore
  // this is currently being added by the BE
  const [subscriptionLang, setSubscriptionLang] = useState(subscription?.language)

  useEffect(() => {
    dispatch(
      updateSubscription(
        {
          language: subscriptionLang,
        },
        subscription.list_id
      )
    )
  }, [dispatch, subscription.list_id, subscriptionLang])
  const handleUpdateSubscription = (
    data: Record<keyof typeof defSubscription, Record<keyof EmailSettings, boolean>>
  ) => {
    const updatedSubscription = { ...subscription, email_settings: data.email_settings }
    onChange(updatedSubscription, index)
  }

  const handleUpdateFrequency = (data: Scheduler) => {
    const updatedSubscription = { ...subscription, scheduler: data }
    onChange(updatedSubscription, index)
  }
  return (
    <Box sx={classes.main}>
      <Accordion key={subscription.list_id}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown as Icon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between">
            <Typography variant="h5">{name}</Typography>
            <Typography>
              {showFrequency({
                frequency: `${subscription.scheduler.mode}:${subscription.scheduler.days}`,
              })}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" spacing={2}>
            <SubscriptionOptions
              selectedOptions={subscription}
              onError={(err) => console.log(err)}
              onChange={(options) => handleUpdateSubscription(options)}
            />
            <LanguageRadioButton
              subscriptionLang={subscriptionLang}
              setSubscriptionLang={setSubscriptionLang}
            />
            <SelectFrequency
              value={subscription.scheduler}
              onChange={(nextFrequency) => handleUpdateFrequency(nextFrequency)}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
      {/*  to replace with modal */}
      <Box sx={classes.trashIcon}>
        {/* @ts-expect-error - The component is JS */}
        <PopConfirmation
          confirmColor="error"
          confirmIcon={<FontAwesomeIcon icon={faTrash as Icon} />}
          confirmText={<FormattedMessage id={intl.generic('remove')} />}
          onConfirm={() => deleteSubscription(subscription.list_id)}
        >
          {(toggleOpen: any) => (
            <IconButton
              onClick={toggleOpen}
              sx={{ color: theme.palette.primary.main }}
              data-cy="trash-button"
            >
              {!deleteButtonLoading ? (
                <FontAwesomeIcon icon={faTrash as Icon} />
              ) : (
                <CircularProgress size={24} />
              )}
            </IconButton>
          )}
        </PopConfirmation>
      </Box>
    </Box>
  )
}

export default EditSubscriptionOptions
