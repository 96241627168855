import { Tab, Tabs } from '@mui/material'
import { IntlTypography } from 'components-new'
import { countryMap } from '../../models/DefPolicyModel'
import { useCountryPolicy } from '../../CountryPolicyProvider'
import { useAccountStatus } from 'services/queries'
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { SupportedCountry } from 'types/general'

const AVAILABLE_TABS = ['DK', 'SE', 'NO', 'FI'] as const

const tabStyles = {
  ' .MuiTabs-scroller': { overflow: 'scroll !important' },
}

const AdvancedPolicyTabs = () => {
  const {
    selectedCountry,
    setNewCountry,
    setIsSaveModalOpen,
    hasPolicyChanged,
    setSelectedCountry,
  } = useCountryPolicy()

  const { data: accountStatus } = useAccountStatus()

  const availableCountries = useMemo(() => {
    return accountStatus?.legacy_rights?.allow_countries || ['DK']
  }, [accountStatus?.legacy_rights?.allow_countries])

  const firstAvailableListIndex = AVAILABLE_TABS.findIndex(
    (element: string) => element === availableCountries[0]
  )

  const handleTabChange = useCallback(
    (_: ChangeEvent<{}>, newVal: SupportedCountry) => {
      if (hasPolicyChanged) {
        setNewCountry(newVal)
        setIsSaveModalOpen(true)
      } else {
        setSelectedCountry(newVal)
      }
    },
    [hasPolicyChanged, setIsSaveModalOpen, setNewCountry, setSelectedCountry]
  )

  // sets the initial state of the first available country list
  useEffect(() => {
    setSelectedCountry(
      AVAILABLE_TABS[firstAvailableListIndex].toLowerCase() as SupportedCountry
    )
  }, [firstAvailableListIndex, setSelectedCountry])

  const checkIfUserHasCountry = (country: string) => {
    if (accountStatus) {
      return availableCountries.includes(country.toUpperCase())
    }
    return false
  }

  return (
    <Tabs
      sx={tabStyles}
      value={selectedCountry}
      onChange={handleTabChange}
      data-cy="country-selector"
    >
      {AVAILABLE_TABS.map((countryList) =>
        checkIfUserHasCountry(countryList.toLowerCase()) ? (
          <Tab
            label={
              <IntlTypography variant="subtitle2" labelKey={countryMap[countryList]} />
            }
            value={countryList.toLowerCase()}
            key={countryList}
          />
        ) : null
      )}
    </Tabs>
  )
}

export default AdvancedPolicyTabs
