import { Divider, Paper, Skeleton, Stack, Box, useMediaQuery } from '@mui/material'
import TitleMainRecommendation from 'pages/credit-check/scenes/MainRecommendation/elements/TitleMainRecommendation'

const bars = [42, 42, 75, 85, 59]

const HistoricalScoresBoxSkeleton = () => {
  const isMediumScreen = useMediaQuery('(max-width: 1534px) and (min-width: 960px)')
  const isSmallScreen = useMediaQuery('(max-width: 420px)')

  const skeletonWidth = isMediumScreen || isSmallScreen ? '35px' : '60px'
  return (
    <Paper elevation={2} component={Stack} m={4}>
      <Box p={4}>
        <TitleMainRecommendation labelKey="performance" />
      </Box>
      <Divider />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        gap={4}
        height={191}
        pb={7}
      >
        {bars.map((bar, i) => (
          <Skeleton key={i} variant="rectangular" width={skeletonWidth} height={bar} />
        ))}
      </Stack>
    </Paper>
  )
}

export default HistoricalScoresBoxSkeleton
