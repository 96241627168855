import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useBuilder } from '../../../../../../BuilderContext'
import { DetailedComponentViewHeaderPropsType } from '../../../../ActionPanel.types'
import { extraPanelKeys } from '../../DetailedComponentView.model'
import SidePanelHeader from '../../../SidePanelHeader'

function DetailedComponentViewHeader({
  handleBack,
}: DetailedComponentViewHeaderPropsType) {
  const { state, actions } = useBuilder()
  const { actionPanelContent } = state

  const handleClose = () => {
    actions.cancelNode()
    actions.actionPanelToggle({ open: false })
  }

  return (
    <SidePanelHeader
      title={
        extraPanelKeys.includes(actionPanelContent) ? (
          <FormattedMessage id={intl.creditPolicyNew(actionPanelContent)} />
        ) : (
          actionPanelContent
        )
      }
      onClose={handleClose}
      onBack={handleBack}
    />
  )
}

export default DetailedComponentViewHeader
