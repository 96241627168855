import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  iconChecked: {
    color: 'lightgreen',
  },
  iconNotChecked: {
    cursor: 'pointer',
  },
}))
