import { SxProps } from '@mui/system'

const menuContainer: SxProps = {
  backgroundColor: 'common.white',
  boxShadow: 3,
  position: 'absolute',
  top: 39 + 20 + 17, // iconHeight + iconDistanceFromParentTop + iconToMenuDistance
  right: 20,
  zIndex: 1,
  padding: 0,
}

const menuItem: SxProps = {
  padding: 0,
}

export const styles = { menuContainer, menuItem }
