import * as React from 'react'
import { ECPDataType } from 'services/api/endpoints/risikaAPI/getECPDataStructure'
import { RefChildren } from './CustomizationHandler.types'

import CustomVariableHandler from './widgets/CustomVariableHandler'
import DropdownTemplates from './widgets/DropdownTemplates'
import RadioButtonForm from './widgets/RadioButtonForm'

type CustomizationHandlerProps = {
  children: RefChildren[]
  selectedVariable: ECPDataType[0]
}

function CustomizationHandler({ selectedVariable, children }: CustomizationHandlerProps) {
  switch (selectedVariable?.data?.customization) {
    case 'radio_button_templates':
      return <RadioButtonForm>{children}</RadioButtonForm>
    case 'dropdown_templates':
      return (
        <DropdownTemplates selectedVariable={selectedVariable}>
          {children}
        </DropdownTemplates>
      )
    case 'custom':
      return (
        <CustomVariableHandler selectedVariable={selectedVariable}>
          {children}
        </CustomVariableHandler>
      )

    default:
      return <React.Fragment>{children}</React.Fragment>
  }
}

export default CustomizationHandler
