import colours from 'configs/colors'

export const getCorrectColor = (groupCode: string) => {
  const colors = {
    LOW: colours.riskIntelligence.lowRisk,
    MEDIUM: colours.riskIntelligence.mediumRisk,
    HIGH: colours.riskIntelligence.highRisk,
    NO_RISK_ASSESMENT: colours.riskIntelligence.noScore,
  }

  return colors[groupCode as keyof typeof colors]
}
