import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import SimpleLineChart from 'components-new/charts/SimpleLineChart'
import { convertToDevelopmentKeyFigures } from 'utils-new/api-to-chart-data-converters'
import {
  developmentInKeyFiguresData,
  developmentInKeyFiguresScoreData,
} from './ListKeyFiguresDevelopmentBox.model'
import { useMonitoringStatistic } from 'services/queries'
import { DevelopmentInKeyFiguresQueryType } from 'types/queries'

// this chart needs to call the same endpoint two times
// because the score information is stored in different database
// due to this there is some code repetition
const ListKeyFiguresDevelopmentBox = ({ listId }: { listId: string }) => {
  developmentInKeyFiguresData.parameters.list_id = Number(listId)
  developmentInKeyFiguresScoreData.parameters.list_id = Number(listId)

  const developmentInKeyFiguresQuery = useMonitoringStatistic({
    parameters: developmentInKeyFiguresData.parameters,
    endpoint: developmentInKeyFiguresData.endPoint,
  })

  const developmentInKeyFiguresQueryData =
    developmentInKeyFiguresQuery.data as DevelopmentInKeyFiguresQueryType[]

  const developmentInKeyFiguresScoreQuery = useMonitoringStatistic({
    parameters: developmentInKeyFiguresScoreData.parameters,
    endpoint: developmentInKeyFiguresScoreData.endPoint,
  })

  const developmentInKeyFiguresScoreQueryData =
    developmentInKeyFiguresScoreQuery.data as DevelopmentInKeyFiguresQueryType[]

  const isLoading =
    developmentInKeyFiguresQuery.isLoading || developmentInKeyFiguresScoreQuery.isLoading
  const isError =
    developmentInKeyFiguresQuery.isError || developmentInKeyFiguresScoreQuery.isError
  const isIdle =
    developmentInKeyFiguresQuery.isIdle || developmentInKeyFiguresScoreQuery.isIdle
  const isNoData =
    !developmentInKeyFiguresQueryData?.length ||
    !developmentInKeyFiguresScoreQueryData?.length

  const boxData = {
    title: developmentInKeyFiguresData.chartProperties.title,
    status: {
      isLoading,
      isError,
      isIdle,
      isNoData,
    },
    height: developmentInKeyFiguresData.chartProperties.height,
    skeletonType: 'HORIZONTAL_BAR',
  }

  return (
    <BoxContainer boxData={boxData}>
      <Box m={4} pt={2}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <SimpleLineChart
            data={convertToDevelopmentKeyFigures(
              developmentInKeyFiguresScoreQueryData,
              developmentInKeyFiguresQueryData
            )}
            chartProperties={developmentInKeyFiguresData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListKeyFiguresDevelopmentBox
