import { Divider, Grid, Box, Stack } from '@mui/material'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { useMonitoringStatistic } from 'services/queries'
import BoxContainerSmall from 'components-new/layouts/BoxContainerSmall'
import { classOutlineBox } from 'styles-new/global/components'
import { setCreditPolicyComplianceData } from './CreditPolicyCompliance.controller'
import { classes } from './CreditPolicyCompliance.styles'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'
import { Monitor } from 'risika-api-response-types'

const CreditPolicyCompliance = ({
  listId,
  handlePortfolioInsightsFilters,
}: {
  listId: string
  handlePortfolioInsightsFilters: (category: string, key: string | boolean) => void
}) => {
  const monitoringStatisticQuery =
    useMonitoringStatistic<Monitor.ListStatisticsCompliance>({
      parameters: { list_id: Number(listId) },
      endpoint: 'compliance',
      staleTime: 60000,
    })
  const boxData = {
    title: intl.portfolioInsights('credit-policy-compliance'),
    status: {
      isLoading: monitoringStatisticQuery.isLoading,
      isError: monitoringStatisticQuery.isError,
      isIdle: monitoringStatisticQuery.isIdle,
    },
    height: 200,
    outlineBox: false,
    skeletonType: 'BIG_NUMBERS',
  }
  if (!monitoringStatisticQuery.data) {
    return null
  }

  const formattedData = setCreditPolicyComplianceData(monitoringStatisticQuery.data)
  return (
    <BoxContainerSmall boxData={boxData}>
      {!boxData.status.isLoading && !boxData.status.isIdle && !boxData.status.isError ? (
        <Grid
          container
          sx={{
            ...classes.creditPolicyCompliance.container,
            ...classOutlineBox,
          }}
        >
          {formattedData.map((item, index) => {
            const isCompliant = !item.label.includes('non-compliant')
            return (
              <Grid key={item.value} item xs={6}>
                <Stack direction="row" flex="1" key={item.value}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={classes.creditPolicyCompliance.target}
                    onClick={() =>
                      handlePortfolioInsightsFilters(apiKeys.compliance, isCompliant)
                    }
                  >
                    <Box
                      sx={{ color: item.color, ...classes.creditPolicyCompliance.value }}
                    >
                      {item.value ? <span>{item.value}</span> : '0'}
                    </Box>
                    {/* <Box sx={classes.creditPolicyCompliance.text}> */}
                    <Box sx={classes.creditPolicyCompliance.text}>
                      <FormattedMessage id={item.label} />
                    </Box>
                  </Stack>
                  {index % 2 === 0 && (
                    <Divider
                      sx={classes.creditPolicyCompliance.divider}
                      variant="middle"
                      orientation="vertical"
                      flexItem
                    />
                  )}
                </Stack>
              </Grid>
            )
          })}
        </Grid>
      ) : null}
    </BoxContainerSmall>
  )
}

export default CreditPolicyCompliance
