import { LocalId } from 'globalTypes'
import { TreeLinkDatum } from 'react-d3-tree'
import { CompanyHierarchy } from 'types/general'

export const getLinePath = (linkDatum: TreeLinkDatum, mock: boolean) => {
  const { source, target } = linkDatum

  const sourceCoords = {
    x: source.x,
    y: source.y,
  }

  const targetCoords = {
    x: target.x,
    y: target.y,
  }

  const x = sourceCoords.x
  const y = sourceCoords.y
  const ex = targetCoords.x
  const ey = targetCoords.y
  const xrvs = ex - x < 0 ? -1 : 1
  const yrvs = ey - y < 0 ? -1 : 1
  const rdef = 45
  const rInitial = Math.abs(ex - x) / 2 < rdef ? Math.abs(ex - x) / 2 : rdef
  const r = Math.abs(ey - y) / 2 < rInitial ? Math.abs(ey - y) / 2 : rInitial
  const h = Math.abs(ey - y) / 2 - r
  const w = Math.abs(ex - x) - r * 2

  if (mock) {
    return `
      M ${x} ${y + 138}
      C ${x} ${y + h * yrvs + r * yrvs}
        ${x} ${y + h * yrvs + r * yrvs}
        ${x}
        ${y + h * yrvs + r * yrvs}
      L ${x + w * xrvs + r * xrvs} ${y + h * yrvs + r * yrvs}
      C ${ex} ${y + h * yrvs + r * yrvs}
        ${ex} ${y + h * yrvs + r * yrvs}
        ${ex} ${ey - h * yrvs}
      L ${ex} ${ey - 138}
    `
  }

  const res = `
  M ${x} ${y}
  C ${x} ${y + h * yrvs + r * yrvs}
    ${x} ${y + h * yrvs + r * yrvs}
    ${x}
    ${y + h * yrvs + r * yrvs}
  L ${x + w * xrvs + r * xrvs} ${y + h * yrvs + r * yrvs}
  C ${ex} ${y + h * yrvs + r * yrvs}
    ${ex} ${y + h * yrvs + r * yrvs}
    ${ex} ${ey - h * yrvs}
  L ${ex} ${ey}
`

  return res
}

export const flattenRecursiveStructure = (data: CompanyHierarchy) => {
  const tempData: Array<LocalId> = []

  const appendValues = (data: Array<CompanyHierarchy>) => {
    data?.forEach((x: CompanyHierarchy) => {
      tempData.push(x.local_id)

      if (x.children?.length) appendValues(x.children)
    })
  }

  appendValues([data])

  return tempData
}

export const formatWalkupData = (company: CompanyHierarchy) => {
  const formatParentsToChildren = (parents: Array<CompanyHierarchy>) => {
    const children = parents.map((item: CompanyHierarchy) => {
      if (item?.parents?.length) {
        const { parents, ...itemWithoutParents } = item

        const formattedCompany: CompanyHierarchy = {
          ...itemWithoutParents,
          children: formatParentsToChildren(parents),
        }

        return formattedCompany
      } else {
        return item
      }
    })

    return children
  }

  if (company.parents) {
    const { parents, ...itemWithoutParents } = company

    const formattedCompany: CompanyHierarchy = {
      ...itemWithoutParents,
      children: formatParentsToChildren(company.parents),
    }

    return formattedCompany
  }

  return company
}

export const getMockDataForSkeleton = () => {
  return {
    name: '',
    children: [
      {
        name: '',
      },
      {
        name: '',
      },
      {
        name: '',
      },
    ],
  }
}
