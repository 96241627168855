import { Link } from 'react-router-dom'

import { navigation } from 'services/navigation'

import moment from 'moment'
import { removeDuplicates } from 'utils'

import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

// Removed the unneeded function for the opperation
const stripFunctions = (array, roles) => {
  return array
    ?.filter((x) => x.inactiveFunctions.length)
    .map((person) => ({
      ...person,
      inactiveFunctions: person.inactiveFunctions.filter((x) =>
        roles.includes(x.function)
      ),
    }))
}

const sortArrayOfOBjects = (arr, item) => {
  return arr.sort((a, b) => compare(a, b, item))
}

const compare = (a, b, item) => {
  if (moment(a[item], 'DD-MM-YYYY') < moment(b[item], 'DD-MM-YYYY')) return 1
  if (moment(b[item], 'DD-MM-YYYY') < moment(a[item], 'DD-MM-YYYY')) return -1
  return 0
}

export const setHistoricalManagementData = (managementRoles, data, classes, country) => {
  const returnValue = []

  // eslint-disable-next-line no-unused-expressions
  stripFunctions(removeDuplicates(data, 'name'), managementRoles)?.forEach((person) => {
    person?.inactiveFunctions?.forEach((func) => {
      returnValue.push({
        name: (
          <Link
            to={navigation.relationOverview({
              id: person.personal_id,
              country: country,
            })}
          >
            <Typography variant="link2">{person?.name}</Typography>
          </Link>
        ),
        position: <Typography variant="body2">{func?.title}</Typography>,
        from:
          moment(func?.valid_from).format('DD.MM.YYYY') === '01.01.0001' ? (
            <Typography variant="body2">
              <FormattedMessage id={intl.companyRoles('before-1987')} />
            </Typography>
          ) : (
            <Typography variant="body2">
              {moment(func?.valid_from).format('DD.MM.YYYY')}
            </Typography>
          ),
        to: (
          <Typography variant="body2">
            {moment(func?.valid_to).format('DD.MM.YYYY')}
          </Typography>
        ),
        otherPosition:
          person?.active_company_relations?.length - 1 > 0 ? (
            <Link
              to={navigation.relationOverview({
                id: person.personal_id,
                country,
              })}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="link2">
                  {person.active_company_relations.length}
                  <FormattedMessage id={intl.companyRoles('other-positions')} />
                </Typography>
              </Stack>
            </Link>
          ) : (
            <Box textAlign="start">-</Box>
          ),
        otherPositionsOrder: person?.active_company_relations?.length
          ? person?.active_company_relations?.length
          : 0,
      })
    })
  })
  return sortArrayOfOBjects(returnValue, 'to')
}
