import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { AbbreviatedNumbersAxis } from '../customizations'
import { StackedChartTooltipWithPercent } from '../customizations'
import { StackedChartTooltipWithRiskPercent } from '../customizations'
import { CustomLegendWithPadding } from '../customizations'
import { CustomLegendWithPaddingRisk } from '../customizations'
import { StackedAreaChartPropsType } from '../types'
import { formatToUniversalDate } from 'utils/dateUtils'

const StackedAreaChart = ({ data, chartProperties }: StackedAreaChartPropsType) => {
  if (!data?.length) {
    return null
  }
  const formattedData = data.map((coordinate) => {
    return { ...coordinate, name: formatToUniversalDate(coordinate.name) }
  })

  const barKeys = Object.keys(data[0]).filter((key) => key !== 'name')

  return (
    <ResponsiveContainer height={chartProperties.height} width="100%">
      <AreaChart
        width={chartProperties.width}
        height={chartProperties.height}
        data={formattedData}
        margin={chartProperties.margin}
      >
        {chartProperties.cartesianGrid && (
          <CartesianGrid strokeDasharray={chartProperties.strokeDasharray} />
        )}
        {chartProperties.xAxis && <XAxis dataKey={chartProperties.dataKey} />}
        {chartProperties.yAxis && <YAxis tick={<AbbreviatedNumbersAxis />} />}
        {chartProperties.tooltipWithPercents && chartProperties.valueType === 'RISK' ? (
          <Tooltip content={<StackedChartTooltipWithRiskPercent />} />
        ) : null}
        {chartProperties.tooltipWithPercents && chartProperties.valueType !== 'RISK' ? (
          <Tooltip content={<StackedChartTooltipWithPercent />} />
        ) : null}
        {chartProperties.tooltip && !chartProperties.tooltipWithPercents ? (
          <Tooltip />
        ) : null}
        {chartProperties.legend && chartProperties.valueType === 'RISK' ? (
          <Legend content={CustomLegendWithPaddingRisk} />
        ) : null}
        {chartProperties.legend && chartProperties.valueType !== 'RISK' ? (
          <Legend content={CustomLegendWithPadding} />
        ) : null}
        {barKeys.map((barKey, index) => {
          return (
            <Area
              type={chartProperties.area.type}
              key={barKey}
              dataKey={barKey}
              stackId={chartProperties.area.stackId}
              stroke={chartProperties.colors[index]}
              fill={chartProperties.colors[index]}
            />
          )
        })}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default StackedAreaChart
