import * as React from 'react'
import { useDebounce } from 'react-use'
type UseDebounceTextReturnType = [
  val: string,
  debouncedValue: string,
  setVal: React.Dispatch<React.SetStateAction<string>>
]
const useDebounceText = (debounceTime?: number): UseDebounceTextReturnType => {
  const [val, setVal] = React.useState('')
  const [debouncedValue, setDebouncedValue] = React.useState('')

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(val)
    },
    debounceTime ?? 500,
    [val]
  )

  return [val, debouncedValue, setVal]
}
export default useDebounceText
