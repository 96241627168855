import { isNil } from 'ramda'
import { ChangeHistoryType, ChangeType, FunctionChange } from './Change.types'
import moment from 'moment'
// Not my code. Just copy pasted it from Jonas
export const extractChange = (change?: ChangeHistoryType) => {
  if (isNil(change)) {
    return null
  }

  const { value } = change

  if (typeof value === 'object') {
    const newValue = value as FunctionChange
    const name = newValue?.name
    const role = newValue?.function
    return { ...newValue, name: `${name} (${role})` }
  }

  return value
}
export const getRestructureChanges = (changes?: ChangeType[]) => {
  return changes
    ?.map((change) => {
      const { new: _new, old } = change

      const from = extractChange(old)
      const to = extractChange(_new)
      const date = change.registration_date

      return {
        ...change,
        from,
        to,
        date,
      }
    })
    ?.filter((change) => {
      const date = moment(change.date)
      const now = moment()
      const diff = now.diff(date, 'days')
      return diff <= 7
    })
}
