import {
  ApproveCreditCreditDefinition,
  ApproveCreditMethod,
  CREDIT_DEFINITION_SET_LIMIT,
  ToleranceOptions,
} from '../../../../../PolicyBuilder.types'
import { DistanceToCeoState, EquityStateType } from './Actions.types'

export const SELECT_ALL = 'all'

export const approveCreditDefaultState = {
  method: '' as ApproveCreditMethod,
  creditDefinition: '' as ApproveCreditCreditDefinition,
  percentageOfRisikaScore: '',
  percentageOfRisikaScoreLimit: '' as CREDIT_DEFINITION_SET_LIMIT,
  percentageOfRisikaScoreValue: '',
  value: '',
}

export const equityDefaultState = {
  type: 'equity',
  tolerance: '',
  valueMin: '',
  valueMax: '',
} as EquityStateType

export type ToleranceDefaultState = {
  tolerance: ToleranceOptions
  value_a: string | number
  value_b: string | number
}

export const distanceToCeoDefaultState = {
  tolerance: '',
  valueMin: '',
  valueMax: '',
} as DistanceToCeoState

export const numberOfEmployeesInitialState = {
  tolerance: '' as ToleranceOptions,
  valueMin: '',
  valueMax: '',
}

export const RISIKA_SCORE_MENU = 'risikaScore'
export const RISK_LEVEL_MENU = 'riskLevel'
