import { Skeleton, Paper, Stack, Box } from '@mui/material'
import React from 'react'
import TitleMainRecommendation from '../../../../elements/TitleMainRecommendation'

const RiskEstimationSkeleton = () => {
  return (
    <Paper elevation={2} component={Stack} m={4} p={4} gap={6}>
      <TitleMainRecommendation labelKey="risika-estimation" />
      <Stack alignItems="center" justifyContent="center" position="relative" width="100%">
        <Box width={220} height={131} overflow="hidden">
          <Stack alignItems="center" justifyContent="center" width={220} height={220}>
            <Skeleton variant="circular" width={220} height={220} />
          </Stack>
        </Box>
      </Stack>
    </Paper>
  )
}

export default RiskEstimationSkeleton
