import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'
/******************
 * Fetch from API *
 ******************/

// Check if types are correct

type EAN = {
  keytype: string
  key: string
}

type ResponseData = EAN[]

function getEan(data: ResponseData) {
  return data
    .filter((ean: EAN) => ean.keytype === 'GLN')
    .map((x) => {
      return { name: x.key }
    })
}

async function eanNumber({ id }: LocalId) {
  const { data } = await risikaAPI.get(`/company/ean?cvr=${id}`, {
    microservice: 'data',
  })
  return {
    ean: getEan(data),
  }
}

export default eanNumber
