import { useState, KeyboardEvent, ChangeEvent } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxRootState } from 'store_deprecated/types'
import { useSnackbar } from 'notistack'
import { saveFilters } from 'services/api'
import { fetchSavedFilters } from 'store_deprecated'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ButtonSecondary, ButtonTertiary } from 'components-new'
import SaveIcon from '@mui/icons-material/Save'
import styles from './SaveFilters.styles'
import { SaveFiltersProps } from './SaveFilters.types'

const { dialogTitle, dialogContent, textField, inputLabel } = styles

const SaveFilters = ({ filters, country, disabled }: SaveFiltersProps) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { savedFilters } = useSelector(
    (state: ReduxRootState) => state.risika.advancedFilters
  )
  const [name, setName] = useState('')
  const [open, setOpen] = useState(false)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.currentTarget.value)

  const handleSaveFilters = () => {
    saveFilters({ name, filters, country, savedFilters })
      .then(() => {
        dispatch(fetchSavedFilters())
        setName('')
        enqueueSnackbar(
          <FormattedMessage id={intl.advancedSearch('snackbar-saved-filters-success')} />
        )
        setOpen(false)
      })
      .catch((error) => {
        enqueueSnackbar(
          <FormattedMessage id={intl.advancedSearch('snackbar-saved-filters-failed')} />
        )
      })
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!name) return
      handleSaveFilters()
    }
  }

  return (
    <>
      <ButtonSecondary
        data-cy="save-filters"
        disabled={disabled}
        startIcon={<SaveIcon />}
        onClick={handleOpen}
        sx={{ height: 44 }}
      >
        <FormattedMessage id={intl.advancedSearch('save-filters')} />
      </ButtonSecondary>
      <Dialog fullWidth open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle sx={dialogTitle}>
          <Typography variant="h5" color="inherit">
            <FormattedMessage id={intl.advancedSearch('save-filter-title')} />
          </Typography>
        </DialogTitle>
        <DialogContent sx={dialogContent}>
          <Stack direction="column" spacing={4}>
            <TextField
              autoFocus
              fullWidth
              data-cy="save-filter-input"
              type="text"
              margin="normal"
              variant="outlined"
              value={name}
              onChange={handleNameChange}
              onKeyDown={handleKeyPress}
              onFocus={(event) => {
                event.target.select()
              }}
              label={<FormattedMessage id={intl.advancedSearch('save-filter-label')} />}
              InputLabelProps={{ style: inputLabel }}
              sx={textField}
            />
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <ButtonTertiary onClick={handleClose}>
                <FormattedMessage id={intl.confirmAction('cancel')} />
              </ButtonTertiary>
              <ButtonSecondary
                data-cy="action-dialog-agree"
                disabled={!name.length}
                onClick={handleSaveFilters}
              >
                <FormattedMessage id={intl.confirmAction('save')} />
              </ButtonSecondary>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SaveFilters
