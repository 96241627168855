import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Fetch from API *
 ******************/

// export type MostRelevant = {
//   date: string,
//   local_organization_id: LocalId,
//   name: string,
//   weight: number,
//   changes: {
//     date: string,
//     new: {
//       valid_from: string,
//       valid_to: string,
//     },
//     sentence: string,
//     type: string,
//     weight: number,
//   }[],
// }
const mostRelevantList = (listId) => {
  return risikaAPI
    .get(`/list/most_relevant/${listId}`, {
      microservice: 'monitor',
    })
    .then(({ data }) => {
      return data
    })
}

export default mostRelevantList
