import React from 'react'
import { SyntheticEvent } from 'react'

import { Typography, Stack, Box } from '@mui/material'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { MiniFlag } from 'components'
import { useDispatch } from 'react-redux'
import { useUpdateUserSettingsField, useUserSettingsField } from 'services/queries'
import { useQueryClient } from 'react-query'
import { submitShowAllMonitoringLists } from 'store_deprecated'
import { AvailableLanguages } from 'globalTypes'

const FlagInput = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const { data: language, isError } = useUserSettingsField<AvailableLanguages>('language')
  const { mutate: updateUserLanguage } = useUpdateUserSettingsField<AvailableLanguages>(
    {}
  )
  const windowUrl = window.location.href

  const refreshOnChange =
    windowUrl.search('credit-policy-builder') > -1 ||
    windowUrl.search('enterprise-credit-policy') > -1

  const handleChangeLanguage = (event: SyntheticEvent, language: AvailableLanguages) => {
    event.preventDefault()
    // New flow
    updateUserLanguage({ field: 'language', data: language })

    // This should be removed at some point
    dispatch(submitShowAllMonitoringLists())
    queryClient.invalidateQueries('compliance')

    if (refreshOnChange) {
      setTimeout(() => {
        window.location.reload()
      }, 150)
    }
  }

  if (isError && !language) {
    return (
      <Box>
        <Typography>
          Something went wrong with the language setup. We have set your language to
          English. To change this setting, please contact customer our customer success
          team.
        </Typography>
      </Box>
    )
  }
  return (
    <Box>
      <Stack spacing={1}>
        <Box>
          <Typography variant="subtitle1">
            <FormattedMessage id={intl.generic('language')} />
          </Typography>
        </Box>
        <Stack direction="row" spacing={'6px'} data-cy="user-menu-lang-images">
          <MiniFlag
            handleChangeLanguage={handleChangeLanguage}
            lang="en_UK"
            selectedLanguage={language!}
          />
          <MiniFlag
            handleChangeLanguage={handleChangeLanguage}
            lang="da_DK"
            selectedLanguage={language!}
          />
          <MiniFlag
            handleChangeLanguage={handleChangeLanguage}
            lang="nb_NO"
            selectedLanguage={language!}
          />
          <MiniFlag
            handleChangeLanguage={handleChangeLanguage}
            lang="sv_SE"
            selectedLanguage={language!}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default FlagInput
