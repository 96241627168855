import { useState } from 'react'
import { Box, Button, ButtonGroup, Stack, useMediaQuery } from '@mui/material'
import Summary from './widgets/Summary'
import Compliance from './widgets/Compliance'
import Scheduler from './widgets/Scheduler'
import SideDrawer from 'components-new/layouts/SideDrawer'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { tabsDefinition } from './PortfolioInsights.model'
import { styles } from './PortfolioInsights.styles'
import { PropsType } from './PortfolioInsights.types'
import DrawerTriggerButton from 'components-new/layouts/SideDrawer/elements/DrawerTriggerButton'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

const PortfolioInsights = ({
  listId,
  drawerState,
  handlePortfolioInsightsFilters,
}: PropsType) => {
  const [tabIndex, setTabIndex] = useState(0)
  const theme = useTheme()
  const routerHistory = useHistory()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ReactIntl = useIntl()
  const messages = ReactIntl.messages

  const handleMobileClose = () => {
    routerHistory.push('/riskmonitoring')
  }

  return (
    <SideDrawer drawerState={drawerState} side="right">
      <DrawerTriggerButton
        visible={!isMobile}
        onClick={() => drawerState.setDrawerIsOpen(!drawerState.drawerIsOpen)}
        variant="sideDrawer"
        title={messages[intl.portfolioInsights('drawer-trigger-button')] as string}
        icon={(style) => <CloseIcon sx={style} />}
      />

      {/* This Box with overflow hidden is to prevent the box-shadow
        of the Button Tabs from "leaking out" of the Portfolio Insights drawer */}
      <Box sx={styles.portfolioInsights}>
        <Box sx={styles.tabButtonsContainer}>
          {isMobile && (
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <Button
                size="large"
                endIcon={<CloseIcon />}
                sx={styles.mobileCloseButton}
                onClick={handleMobileClose}
              >
                <FormattedMessage id={intl.generic('close')} />
              </Button>
            </Stack>
          )}

          <ButtonGroup variant="outlined" size="large" sx={styles.buttonGroup}>
            {tabsDefinition.map((tab) => (
              <Button
                key={tab.id}
                sx={{
                  ...styles.tabButton,
                  ...(tabIndex === tab.id && styles.activeTabButton),
                }}
                onClick={() => setTabIndex(tab.id)}
              >
                <FormattedMessage id={tab.title} />
              </Button>
            ))}
          </ButtonGroup>
        </Box>

        <Box sx={styles.tabContentContainer}>
          {tabIndex === 0 && (
            <Summary
              listId={listId}
              handlePortfolioInsightsFilters={handlePortfolioInsightsFilters}
            />
          )}
          {tabIndex === 1 && (
            <Compliance
              listId={listId}
              handlePortfolioInsightsFilters={handlePortfolioInsightsFilters}
            />
          )}
          {tabIndex === 2 && <Scheduler listId={listId} />}
        </Box>
      </Box>
    </SideDrawer>
  )
}

export default PortfolioInsights
