import { Paper, Skeleton, Stack } from '@mui/material'
import TitleMainRecommendation from 'pages/credit-check/scenes/MainRecommendation/elements/TitleMainRecommendation'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CardWithColorAndIcon } from 'components-new'

const CreditPolicySkeleton = () => {
  return (
    <Paper elevation={2} component={Stack} m={4} p={4}>
      <TitleMainRecommendation labelKey="credit-policy" />
      <CardWithColorAndIcon
        icon={<CheckCircleIcon />}
        color={'grey.600'}
        sx={{ mt: 5, mb: 4 }}
      >
        <Skeleton variant="text" height={24} width="50%" />
        <Skeleton variant="text" height={22} />
        <Skeleton variant="text" height={22} width="75%" sx={{ marginTop: 2 }} />
        <Skeleton variant="text" height={17} width="50%" sx={{ marginTop: 3 }} />
      </CardWithColorAndIcon>
    </Paper>
  )
}

export default CreditPolicySkeleton
