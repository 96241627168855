import { takeEvery, call, put } from 'redux-saga/effects'
// import { getCompanyDataByUserId } from 'services/api'
import { users as getUsers } from 'services/api'
import { SUBMIT_GET_COMPANY_INFORMATION, SUBMIT_GET_ALL_USERS } from './constants'
import { setUsersSuccess, setUsersFailed } from './actions'

/**********
 * Watcher *
 **********/
export function* settingsSaga() {
  yield takeEvery(SUBMIT_GET_COMPANY_INFORMATION, updateCompanyInformation)
  yield takeEvery(SUBMIT_GET_ALL_USERS, submitGetUsers)
}

/**********
 * Worker *
 **********/
function* updateCompanyInformation({ payload }) {
  // const { userId } = payload
  // const { company } = yield call(getCompanyDataByUserId, userId)
  try {
    // yield put(updateCompanyInformationSuccess(company))
  } catch (error) {
    console.log(error)
  }
}
function* submitGetUsers() {
  const data = yield call(getUsers)
  try {
    yield put(setUsersSuccess(data))
  } catch (error) {
    yield put(setUsersFailed())
  }
}
