/***********
 * Helpers *
 ***********/

function fromDuration(duration) {
  const { seconds, minutes, hours, days } = duration
  let total = 0
  if (seconds) total += seconds
  if (minutes) total += minutes * 60
  if (hours) total += hours * 60 * 60
  if (days) total += days * 60 * 60 * 24
  return total
}

/***********************
 * Timestamp Utilities *
 ***********************/

/**
 * Generate a timestamp (based in seconds).
 */
export function generateTimestamp() {
  return Math.ceil(Date.now() / 1000)
}

/**
 * Figure out if the given timestamp has expired.
 */
export function hasExpired(timestamp, expiration) {
  if (timestamp == null) {
    return true
  }

  const timeSinceGenerated = generateTimestamp() - timestamp

  expiration = typeof expiration === 'number' ? expiration : fromDuration(expiration)

  if (timeSinceGenerated > expiration) {
    return true
  }

  return false
}

/**
 * Create a duration based expiration policy for the timestamps.
 *
 * @returns A function to determine if a timestamp has expired.
 */

export function expirationPolicy(expiration) {
  return function (timestamp) {
    return hasExpired(timestamp, expiration)
  }
}
