import { theme } from 'styles-new/mui5-transition/theme'

const main = {
  position: 'relative',
  pr: 7,
}

const trashIcon = {
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}

export const classes = {
  main,
  trashIcon,
}
