import moment from 'moment/moment'

export const formatDate = (from: string, to?: string) => {
  if (from && to) {
    return `${moment(from).format('DD/MM/YYYY')} - ${moment(to).format('DD/MM/YYYY')}`
  }
  if (from) {
    return moment(from).format('DD/MM/YYYY')
  }
  return ''
}
