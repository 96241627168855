import { SxProps } from '@mui/system'

const buttonHierarchy = () => {
  return {
    color: 'black',
    background: 'secondary.main',
    position: 'initial',
    margin: 'auto',
    padding: '2px 20px',
    textTransform: 'lowercase',
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 'unset',
    minWidth: '200px',
    '&:hover': {
      backgroundColor: 'orange',
    },
  }
}

const typography: SxProps = {
  fontFamily: '"Montserrat"',
  fontWeight: 500,
}

const chipSharesInterval: SxProps = {
  width: 'fit-content',
  bgcolor: '#e6e6e6',
  margin: 'auto',
  fontSize: '1.4rem',
  height: '24px',
  '& > .MuiChip-label': {
    padding: '0 8px',
  },
}

const cardHeader: SxProps = {
  padding: 0,
  '& > .MuiCardHeader-content': {
    width: '100%',
  },
}

const subheaderStack: SxProps = {
  background: '#245157',
  height: '60px',
  color: 'white',
}

const companyNameStack: SxProps = {
  overflow: 'hidden',
  mx: 2,
}

export default {
  buttonHierarchy,
  typography,
  chipSharesInterval,
  cardHeader,
  subheaderStack,
  companyNameStack,
}
