import mixpanel from 'mixpanel-browser'
const envCheck = true // process.env.NODE_ENV === 'production'

const actions = {
  init: (key: string) => {
    if (envCheck) {
      mixpanel.init(key, {
        track_pageview: true,
        persistence: 'localStorage',
      })
    }
  },
  identify: (id: string) => {
    if (envCheck) mixpanel.identify(id)
  },
  track: (name: string, options?: { [key: string]: unknown | undefined }) => {
    if (envCheck) mixpanel.track(name, options)
  },
  pageview: (name: string) => {
    if (envCheck) mixpanel.track_pageview({ page: name })
  },
  people: {
    set: (props: {
      apiUserID: number | undefined
      role: 'admin' | 'user' | undefined
      name: string
      $email: string | undefined
      customerID: number | undefined
      subscription:
        | 'FREEMIUM'
        | 'BASIC'
        | 'STANDARD'
        | 'PRO'
        | 'ENTERPRISE'
        | 'TRIAL'
        | undefined
      email: string
    }) => {
      if (envCheck) mixpanel.people.set(props)
    },
  },
}

export const Mixpanel = actions
