import { authRoles } from 'components/auth'
import SettingsPage from './SettingsPage'
import { navigation } from 'services/navigation'
export const SettingsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: `${navigation.settings()}/:id/:requestId`,
      component: SettingsPage,
    },
    {
      path: `${navigation.settings()}/:id`,
      component: SettingsPage,
    },
    {
      path: `${navigation.settings()}`,
      component: SettingsPage,
    },
  ],
}
