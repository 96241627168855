import { takeEvery, call, put } from 'redux-saga/effects'
import { creditPolicyGet } from 'services/api'

import { SUBMIT_FETCH_POLICY_VALUES } from './constants'

import { setPolicyValuesSuccess, setPolicyValuesFailure } from './actions'

/**********
 * Watcher *
 **********/
export function* creditPolicySaga() {
  yield takeEvery(SUBMIT_FETCH_POLICY_VALUES, fetchPolicyValues)
}

/**********
 * Worker *
 **********/
function* fetchPolicyValues({ payload, status }) {
  const { country, model } = payload
  try {
    const result = yield call(creditPolicyGet, country)
    const policy = result?.policy
    const { enabled } = result
    const exclude_companies = policy?.exclude_companies?.map((x) => ({
      id: x?.local_organization_id?.id,
      name: x?.local_organization_id?.name,
      country: x?.local_organization_id?.country,
    }))
    const exclude_persons = policy?.exclude_persons?.map((x) => ({
      id: x?.local_person_id?.id,
      name: x?.local_person_id.name,
      country: x?.local_person_id.country,
    }))
    const exclude_risika_scores = policy?.exclude_risika_scores?.[model] ?? []
    const exclude_level_of_auditor_assistances =
      policy?.exclude_level_of_auditor_assistances ?? []

    yield put(
      setPolicyValuesSuccess(
        {
          ...policy,
          exclude_companies,
          exclude_persons,
          exclude_risika_scores,
          exclude_level_of_auditor_assistances,
        },
        country,
        enabled
      )
    )
  } catch (error) {
    console.log('error in gettings policy values', error)
    // if (error?.response?.data?.error === 'No credit policy is set') {
    const emptyResult = {
      exclude_risika_scores: [],
      exclude_company_types: [],
      negative_equity_limit: null,
      age_limit: null,
      exclude_level_of_auditor_assistances: [],
      bankruptcy_limit: null,
      exclude_companies: [],
      exclude_persons: [],
      exclude_industries: [],
    }
    yield put(setPolicyValuesFailure(emptyResult, country))
    // } else {
    // yield put(setPolicyValuesFailure({}))
    // }
  }
}
