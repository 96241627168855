import Box from '@mui/material/Box'

import BoxContainer from 'components-new/layouts/BoxContainer'
import SimpleBarChart from 'components-new/charts/SimpleBarChart'

import {
  OuterCategory,
  convertToNameAndValuesSimple,
} from 'utils-new/api-to-chart-data-converters'
import { keyFiguresByCompanyTypeData } from './ListKeyFiguresByCompanyTypeBox.model'

import { useMonitoringStatistic } from 'services/queries'
import { Monitor } from 'risika-api-response-types'

const ListKeyFiguresByCompanyTypeBox = ({ listId }: { listId: string }) => {
  keyFiguresByCompanyTypeData.parameters.list_id = Number(listId)
  const keyFiguresByCompanyTypeQuery =
    useMonitoringStatistic<Monitor.ListStatisticsCategory>({
      parameters: keyFiguresByCompanyTypeData.parameters,
      endpoint: keyFiguresByCompanyTypeData.endPoint,
      cacheTime: 0,
    })
  if (!keyFiguresByCompanyTypeQuery.isSuccess || !keyFiguresByCompanyTypeQuery.data) {
    return null
  }

  const keyFiguresByCompanyTypeDataValue = keyFiguresByCompanyTypeQuery.data

  const boxData = {
    title: keyFiguresByCompanyTypeData.chartProperties.title,
    status: {
      isLoading: keyFiguresByCompanyTypeQuery.isLoading,
      isError:
        keyFiguresByCompanyTypeQuery.isError ||
        (keyFiguresByCompanyTypeQuery.data &&
          !keyFiguresByCompanyTypeQuery.data?.length &&
          !keyFiguresByCompanyTypeQuery.isLoading),
      isIdle: keyFiguresByCompanyTypeQuery.isIdle,
      isNoData: !!(
        keyFiguresByCompanyTypeQuery.data?.length &&
        !convertToNameAndValuesSimple(keyFiguresByCompanyTypeDataValue as OuterCategory[])
          .length
      ),
    },
    height: keyFiguresByCompanyTypeData.chartProperties.height,
    skeletonType: 'HORIZONTAL_BAR',
  }
  return (
    <BoxContainer boxData={boxData} data-cy="figures-by-company-type">
      <Box m={4} pt={2}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <SimpleBarChart
            data={
              convertToNameAndValuesSimple(
                keyFiguresByCompanyTypeDataValue as OuterCategory[]
              ) as { name: number }[]
            }
            chartProperties={keyFiguresByCompanyTypeData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListKeyFiguresByCompanyTypeBox
