import { Box, Stack, Collapse } from '@mui/material'
import { Show } from 'components'
import { isComponentRendered } from 'configs'
import { useCreditCheck } from '../../../../context'
import { ExplanationPropsType } from './Explanation.types'
import IntlTypography from 'components-new/elements/IntlTypography'
import CustomTooltip from './CustomTooltip'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import scorecardModel from './ModelNewScorecard'
import FactorGroup from './FactorGroup'
import { theme } from 'styles-new/mui5-transition/theme'
import { ScoreInformation } from 'services/api/endpoints/risikaAPI/ratingScores'
import { LocalId } from 'globalTypes'
import { LoadingSkeleton } from 'components-new'
import NewScorecardExplanationSkeleton from './NewScorecardExplanation.skeleton'
import { WidgetWrapper } from '../WidgetWrapper/WidgetWrapper'

function NewScorecardExplanation({
  widgetNameDND,
  isReArrange,
  isDragging = false,
  isGazzete,
  plan,
  scoresInfo,
  isLoading,
  isActive,
  setIsActive,
}: ExplanationPropsType) {
  const { localId } = useCreditCheck() as { localId: LocalId }
  const reactIntl = useIntl()

  return (
    <LoadingSkeleton
      SkeletonComponent={NewScorecardExplanationSkeleton}
      isLoading={isLoading}
    >
      <Show
        when={
          Boolean(isComponentRendered('risikasEstimation', localId?.country)) &&
          !['FREEMIUM', 'BASIC'].includes(plan) &&
          Boolean(
            scoresInfo &&
              typeof scoresInfo === 'object' &&
              Object.keys(scoresInfo as Object).length
          ) &&
          Boolean(!isGazzete)
        }
      >
        <WidgetWrapper
          isReArrange={isReArrange}
          widgetName={widgetNameDND}
          isActive={!!isActive}
          setIsActive={setIsActive}
        >
          <Box>
            <Stack
              m={4}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <IntlTypography group="newScorecard" labelKey="title" variant="h4" />
              <Collapse in={!isReArrange}>
                <CustomTooltip
                  text={reactIntl.messages[intl.newScorecard('title-tooltip')] as string}
                  sx={{ pt: theme.spacing(2) }}
                />
              </Collapse>
            </Stack>
            <Collapse in={!isReArrange}>
              <Box>
                {scorecardModel?.map(({ name, color }) => (
                  <FactorGroup
                    name={name}
                    factors={scoresInfo?.[name as keyof ScoreInformation]!}
                    color={color}
                    key={name}
                  />
                ))}
              </Box>
            </Collapse>
          </Box>
        </WidgetWrapper>
      </Show>
    </LoadingSkeleton>
  )
}

export default NewScorecardExplanation
