import { styles } from '../styles/mini-flag'
import { MiniFlagProps } from './types'

export const MiniFlag = ({
  lang,
  selectedLanguage,
  handleChangeLanguage,
}: MiniFlagProps) => {
  return (
    <a href="http://#" onClick={(e) => handleChangeLanguage(e, lang)}>
      <img
        alt={`language ${lang}`}
        style={
          selectedLanguage === lang
            ? { ...styles.root, ...styles.flagActive }
            : { ...styles.root }
        }
        src={`risika/flags/${lang}@2x.png`}
      />
    </a>
  )
}

export default MiniFlag
