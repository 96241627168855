import { Theme } from '@mui/material/styles'

const header = {
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: 'grey.200',
  backgroundColor: 'grey.50',
  height: (theme: Theme) => theme.spacing(7),
  px: 3,
  marginLeft: 2,
}

const item = {
  borderBottom: '1px solid',
  borderColor: 'grey.200',
  px: 4,
  py: 1,
  '&:last-child': {
    borderBottom: 'none',
  },
}

const minorImpactTooltip = {
  pl: 1,
}

const leftBorder = (color: string) => ({
  display: 'inline',
  float: 'left',
  position: 'relative',
  width: (theme: Theme) => theme.spacing(2),
  height: (theme: Theme) => theme.spacing(7),
  backgroundColor: color,
})

export default {
  leftBorder,
  header,
  item,
  minorImpactTooltip,
}
