import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Post to API *
 ******************/

//  "users": null  --> list is public
//  "users": [5]  --> list is private. Only user 5 can see it
//  "users": [5,6,7]  --> list is private. List of users can see it

export type MonitorVisibilityUpdateType = {
  listId: number
  users: number[] | null
}

const monitorVisibilityUpdate = async ({
  listId,
  users,
}: MonitorVisibilityUpdateType): Promise<Response> => {
  const { data } = await risikaAPI.post(
    `/list/${listId}/visibility`,
    { users },
    {
      microservice: 'monitor',
    }
  )
  return data
}

export default monitorVisibilityUpdate
