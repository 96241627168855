import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const selectContainer: SxProps = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  backgroundColor: theme.palette.grey[100],
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(2),
  },
}

const emailChip: SxProps = {
  fontFamily: '"Montserrat",sans-serif',
  fontSize: '1.4rem',
  borderRadius: 1,
}

const invalidEmailChip: SxProps = {
  borderColor: theme.palette.error.main,
  borderWidth: '2px',
}

const selectItem: SxProps = {
  display: 'flex',
  justifyContent: 'start',
  gap: theme.spacing(1),
  alignItems: 'center',
}

const selectItemContent: SxProps = {
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.6rem',
  fontWeight: 500,
}

export const styles = {
  selectContainer,
  emailChip,
  invalidEmailChip,
  selectItem,
  selectItemContent,
}
