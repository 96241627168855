import { useQuery } from 'react-query'
import { financialRatios } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useFinancialRatios(
  localId: LocalId,
  cacheTime?: number,
  staleTime?: number
) {
  return useQuery(
    ['financialRatios', localId.id, localId.country],
    () => financialRatios(localId),
    {
      staleTime,
      enabled: !!isCallAllowed('financialRatios', localId.country),
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime,
    }
  )
}
