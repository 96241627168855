import { columnWidth, containerWidth, horizontalPadding } from './CompanyComparison.model'

const movePositionStyles = (showHeader: boolean) => ({
  transform: `translateY(${showHeader ? 0 : '-60px'})`,
  transition: 'transform 0.3s ease-out',
})

const pageContainer = {
  position: 'relative',
  height: 'calc(100vh - 64px)',
  overflow: 'scroll',
}

const headerContainer = (showHeader: boolean) => ({
  position: 'fixed',
  top: 64,
  left: 99,
  right: 0,
  ...movePositionStyles(showHeader),
})

const columnsContainer = (numberOfCompanies: number, showHeader: boolean) => ({
  flexDirection: 'column',
  gap: 2,
  overflowY: 'scroll',
  overflowX: 'visible',
  width: containerWidth(numberOfCompanies),
  marginTop: showHeader ? '75px !important' : '58px !important',
  paddingX: horizontalPadding,
  ...movePositionStyles(showHeader),
})

const keyFiguresChartContainer = (numberOfCompanies: number) => ({
  width: numberOfCompanies * columnWidth,
  paddingX: 1,
  paddingBottom: 2,
})

export default {
  movePositionStyles,
  pageContainer,
  headerContainer,
  columnsContainer,
  keyFiguresChartContainer,
}
