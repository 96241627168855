import React from 'react'

class DoubleScrollbar extends React.Component {
  constructor(props) {
    super(props)
    this.outerDiv = React.createRef()
    this.innerDiv = React.createRef()
    this.childrenWrapper = React.createRef()
    this.state = {
      width: 'auto',
    }

    this.boundCalculateWidth = this.calculateWidth.bind(this)
  }

  componentDidMount() {
    const outerDiv = this.outerDiv.current
    const childWrapper = this.childrenWrapper.current

    // Set initial width
    this.calculateWidth()

    // Update width when window size changes
    window.addEventListener('resize', this.boundCalculateWidth)

    // assoc the scrolls
    outerDiv.onscroll = function () {
      childWrapper.scrollLeft = outerDiv.scrollLeft
    }

    childWrapper.onscroll = function () {
      outerDiv.scrollLeft = childWrapper.scrollLeft
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.boundCalculateWidth)
  }

  componentDidUpdate() {
    this.calculateWidth()
  }

  calculateWidth() {
    let width = this.getChildWrapperWidth()

    if (width == null) {
      width = 'auto'
    }

    // Set the width of the inner div to the first child's
    if (width !== this.state.width) {
      this.setState({
        width: width,
      })
    }
  }

  getChildWrapperWidth() {
    let width = null
    if (this.childrenWrapper.current && this.childrenWrapper.current.scrollWidth) {
      width = this.childrenWrapper.current.scrollWidth + 'px'
    }
    return width
  }

  render() {
    const outerDivStyle = { overflowX: 'auto', overflowY: 'hidden' }
    const innerDivStyle = { paddingTop: '1px', width: this.state.width }
    const childDivStyle = { overflow: 'auto', overflowY: 'hidden' }

    return (
      <div>
        <div ref={this.outerDiv} style={outerDivStyle}>
          <div ref={this.innerDiv} style={innerDivStyle}>
            &nbsp;
          </div>
        </div>
        <div ref={this.childrenWrapper} style={childDivStyle}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default DoubleScrollbar
