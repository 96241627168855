import { AvailableCountriesCapital } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

export type Policy = {
  country: AvailableCountriesCapital
  created: Date
  description: string
  enabled: boolean
  id: number
  name: string
  owner_id: number
  updated: Date
}
export type GetAllCreditPoliciesResponse = {
  policy_count: number
  policies: Policy[]
}

async function getCreditPolicies(): Promise<GetAllCreditPoliciesResponse | null> {
  const { data } = await risikaAPI.get('/enterprise_credit_policies', {
    microservice: 'account',
  })

  // This is just making sure we have all the countries as capital as agreed with the backend (i just don't believe them :D)
  return {
    ...data,
    policies: data.policies.map((policy: Policy) => ({
      ...policy,
      country: policy.country.toUpperCase() as AvailableCountriesCapital,
    })),
  }
}

export default getCreditPolicies
