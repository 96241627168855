const header = {
  bgcolor: 'primary.dark',
  color: 'common.white',
  '& > h3': {
    color: 'common.white',
  },
}

export const classes = {
  header,
}
