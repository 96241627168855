import React from 'react'
import { makeStyles } from '@mui/styles'

import { ShowHideAllWrapper } from 'components'
import SimpleEntry from './SimpleEntry'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  invalid: {
    color: 'gray',
  },
  space: {
    marginLeft: '0.4rem',
  },
  text: {
    // cursor: 'pointer',
    // '&:hover': {
    //   textDecoration: 'underline',
    // },
  },
}))

const Ean = ({ data }) => {
  const classes = useStyles()
  const [isShowAll, setIsShowAll] = React.useState(false)
  return (
    <div style={{ marginBottom: data.length > 1 ? '1rem' : '' }}>
      <SimpleEntry
        title={<FormattedMessage id={intl.companyInfo('ean')} />}
        elementType="div"
        content={[
          data?.map((entry, i) => (
            <ShowHideAllWrapper
              setIsShowAll={setIsShowAll}
              isShowAll={isShowAll}
              key={i}
              amountOfShowedItems={3}
              iteration={i}
              dataLength={data?.length}
            >
              <div className={classes.text} style={{ display: 'flex' }}>
                {entry.name}
              </div>
            </ShowHideAllWrapper>
          )),
        ]}
      />
    </div>
  )
}

export default Ean
