import { head, take, takeLast } from 'ramda'

const validators = {
  DK: new RegExp(/^(?:DK|DK-|GL|GL-)?(\d{8})$/),
  NO: new RegExp(/^(?:NO|NO-)?(\d{9})(?:-MVA|MVA)?$/),
  SE: new RegExp(/^(?:SE|SE-)?(\d{10,14}|[\da-z]{32})$/),
  UK: new RegExp(/^([\da-z]{8})$/),
  FI: new RegExp(/^(\d{7}-?\d)$/),
  // eslint-disable-next-line no-useless-escape
  DE: new RegExp(/^(([A-Zn]{2,3}\d+[A-ZÖ]*;[\w\-]+)|RISIKA\d+)$/),
}
const validateDanishLocalId = ({ id }) => {
  return validators.DK.test(id)
}

/**
 * References:
 * [1] https://www.wikiwand.com/sv/Organisationsnummer
 * [2] https://www.wikiwand.com/en/Luhn_algorithm
 */
const validateSwedishLocalId = ({ id }) => {
  if (!id) {
    return 0
  }

  const strId = id?.toString()

  if (strId.length !== 10 && strId.length !== 12) {
    return false
  }

  // If it has a length of 12, then it must start with one of those four combinations (reference 1)
  if (strId.length === 12 && !new Set(['16', '18', '19', '20']).has(take(2, strId))) {
    return false
  }

  // If everything above succeeded, then we need to validate it with the luhn algorithm (reference 2)

  const weightedSum = takeLast(10, strId)
    .split('')
    .flatMap((digit, index) => {
      if (index % 2 === 0) {
        const doubled = (Number(digit) * 2)?.toString()

        if (doubled.length > 1) {
          return doubled.split('')
        }

        return doubled
      }

      return digit
    })
    .map(Number)
    .reduce((a, b) => a + b)

  return weightedSum % 10 === 0
}

const validateNorwegianLocalId = ({ id }) => {
  if (id?.toString().length !== 9) {
    return false
  }

  // The weights used for org.nr. modulus 11 validation
  const weights = [3, 2, 7, 6, 5, 4, 3, 2, 1]

  const weightedSum = id
    ?.toString()
    .split('')
    .map((digit, index) => Number(digit) * weights[index])
    .reduce((a, b) => a + b)

  return weightedSum % 11 === 0
}

/**
 * Validate a Local Id from any of the countries that we support.
 */
export const validateLocalId = (localId) => {
  const country = localId?.country?.toLowerCase()
  switch (country) {
    case 'dk':
      return validateDanishLocalId(localId)
    case 'se':
      return validateSwedishLocalId(localId)
    case 'no':
      return validateNorwegianLocalId(localId)
    case 'fi':
      return validators.FI.test(localId?.id)

    default:
      return false
  }
}

/**
 * Examples: DK37677892, 37677892DK
 */
const _extractLocalIdWithCountry = (maybeId, countries) => {
  const countryMatch = countries
    .map((country) => {
      if (
        maybeId?.toLowerCase().startsWith(country) ||
        maybeId?.toLowerCase().endsWith(country)
      ) {
        return { id: maybeId.toLowerCase().replace(country, ''), country }
      }

      return null
    })
    .filter((x) => x != null)

  if (countryMatch.length === 1) {
    return head(countryMatch)
  }

  return null
}

/**
 * Will try and figure out which Local ID it represents.
 */
export const inferLocalId = (maybeId) => {
  const countries = ['dk', 'se', 'no', 'fi']
  const maybeLocalId = _extractLocalIdWithCountry(maybeId, countries)
  if (maybeLocalId != null && validateLocalId(maybeLocalId)) {
    return maybeLocalId
  }

  const validLocalIds = countries
    .map((country) => ({ id: maybeId, country }))
    .filter(validateLocalId)
  if (validLocalIds.length === 1) {
    return head(validLocalIds)
  }

  return head(validLocalIds) ?? null
}
