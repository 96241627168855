import { useState } from 'react'
import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import MapChart from 'components-new/charts/MapChart'
import BreadCrumbs from './elements/BreadCrumbs'
import { convertToGeographicalValue } from 'utils-new/api-to-chart-data-converters'
import { geographicalRisikaScoreData } from './ListCreditScoreGeographicallyBox.model'
import { mapLevelLimit } from './ListCreditScoreGeographicallyBox.model'
import { useMonitoringStatistic } from 'services/queries'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Monitor } from 'risika-api-response-types'
import { GeographicalRisikaScoreQuery } from 'types/queries'

const ListCreditScoreGeographicallyBox = ({ listId }: { listId: string }) => {
  geographicalRisikaScoreData.parameters.list_id = Number(listId)
  geographicalRisikaScoreData.percentageParameters.list_id = Number(listId)
  const [scoreDataParams, setScoreDataParams] = useState(
    geographicalRisikaScoreData.parameters
  )
  const [percentageDataParams, setPercentageDataParams] = useState(
    geographicalRisikaScoreData.percentageParameters
  )
  const [breadcrumbs, setBreadcrumbs] = useState([
    { level: 1, groupCode: '', groupName: '' },
  ])

  const geographicalRisikaScoreQuery =
    useMonitoringStatistic<Monitor.ListStatisticsCategory>({
      parameters: scoreDataParams,
      endpoint: geographicalRisikaScoreData.endPoint,
    })

  const geographicalRisikaPercentagesQuery = useMonitoringStatistic({
    parameters: percentageDataParams,
    endpoint: geographicalRisikaScoreData.endPoint,
  })

  const isLoading =
    geographicalRisikaScoreQuery.isLoading || geographicalRisikaPercentagesQuery.isLoading
  const isError =
    geographicalRisikaScoreQuery.isError || geographicalRisikaPercentagesQuery.isError
  const isIdle =
    geographicalRisikaScoreQuery.isIdle || geographicalRisikaPercentagesQuery.isIdle
  const isNoData =
    !geographicalRisikaScoreQuery.data?.length || !geographicalRisikaPercentagesQuery.data

  const boxData = {
    title: geographicalRisikaScoreData.chartProperties.title,
    status: {
      isLoading,
      isError,
      isIdle,
      isNoData,
    },
    height: geographicalRisikaScoreData.chartProperties.height,
    skeletonType: 'PIC_TEXT',
  }

  const handleMapClick = (countryCode: string, countryName: string) => {
    setBreadcrumbs((state) => {
      return [
        ...state,
        { level: state.length + 1, groupCode: countryCode, groupName: countryName },
      ]
    })
    setScoreDataParams((state) => {
      return {
        ...state,
        filter_on: { category: 'COUNTRY', value: countryCode },
        group_by: [{ category: 'REGION' }, { category: 'RISK_ASSESSMENT' }],
      }
    })
    setPercentageDataParams((state) => {
      return {
        ...state,
        filter_on: { category: 'COUNTRY', value: countryCode },
        group_by: [{ category: 'REGION' }],
      }
    })
  }

  const handleBreadcrumbs = (crumbLevel: number) => {
    if (crumbLevel >= mapLevelLimit) return
    setBreadcrumbs((state) => {
      return state.slice(0, crumbLevel)
    })
    setScoreDataParams(geographicalRisikaScoreData.parameters)
    setPercentageDataParams(geographicalRisikaScoreData.percentageParameters)
  }
  if (!geographicalRisikaScoreQuery.data) {
    return (
      <Box
        data-cy="list-credit-score-geographically-chart"
        m={2}
        justifyContent="center"
        display="flex"
      >
        <MapChart
          forceRender={Math.random()} // force rerender every time
          data={[]}
          chartProperties={geographicalRisikaScoreData.chartProperties}
          handleMapClick={handleMapClick}
          mapLevel={breadcrumbs.length}
          mapLevelLimit={mapLevelLimit}
        />
      </Box>
    )
  }
  return (
    <BoxContainer boxData={boxData}>
      {!boxData.status.isLoading && !boxData.status.isIdle && !boxData.status.isError ? (
        <>
          <BreadCrumbs breadcrumbs={breadcrumbs} handleBreadcrumb={handleBreadcrumbs} />
          <Box
            data-cy="list-credit-score-geographically-chart"
            m={2}
            justifyContent="center"
            display="flex"
          >
            <MapChart
              forceRender={Math.random()} // force rerender every time
              data={convertToGeographicalValue(
                geographicalRisikaScoreQuery.data as GeographicalRisikaScoreQuery[],
                geographicalRisikaPercentagesQuery.data as {
                  values: [{ value: number }]
                }[]
              )}
              chartProperties={geographicalRisikaScoreData.chartProperties}
              handleMapClick={handleMapClick}
              mapLevel={breadcrumbs.length}
              mapLevelLimit={mapLevelLimit}
            />
          </Box>
        </>
      ) : (
        <Box
          data-cy="list-credit-score-geographically-chart"
          m={2}
          justifyContent="center"
          display="flex"
        >
          <MapChart
            forceRender={Math.random()} // force rerender every time
            data={[]}
            chartProperties={geographicalRisikaScoreData.chartProperties}
            handleMapClick={handleMapClick}
            mapLevel={breadcrumbs.length}
            mapLevelLimit={mapLevelLimit}
          />
        </Box>
      )}
    </BoxContainer>
  )
}

export default ListCreditScoreGeographicallyBox
