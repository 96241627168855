import CompanyHierarchyPage from './CompanyHierarchyPage'
import { navigation } from 'services/navigation'

export const CompanyHierarchyConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${navigation.companyHierarchy()}/:country/:id`,
      component: CompanyHierarchyPage,
    },
    {
      path: `${navigation.companyHierarchy()}/:id/`,
      component: CompanyHierarchyPage,
    },
    {
      path: navigation.companyHierarchy(),
      component: CompanyHierarchyPage,
    },
  ],
}
