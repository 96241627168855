import { WebApiError } from 'types/general'
import { useMutation, useQueryClient } from 'react-query'
import updateUserSelf, {
  UpdateUserSelfData,
} from 'services/api/endpoints/backendAPI/updateUserSelf'
import { useSnackbar } from 'notistack'

export function useUpdateUserSelf() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation<Response, WebApiError, UpdateUserSelfData>(updateUserSelf, {
    onSuccess: () => {
      queryClient.refetchQueries('allUsers')
      queryClient.refetchQueries('userSelf')
    },
    onError: (err) => {
      enqueueSnackbar(`${err?.response?.data?.error?.message}`, {
        variant: 'error',
      })
    },
  })
}
