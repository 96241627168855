import { colors } from 'configs'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    maxWidth: 400,
    boxShadow: theme.shadows[1],
  },
  title: {
    fontWeight: 700,
  },
  spinner: {
    color: colors?.risikaContrast,
  },
  emailContainer: {
    fontWeight: 700,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#ddd',
    padding: theme.spacing(1, 2),
  },
}))
