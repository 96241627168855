import { poaListItemType } from './ObservationalListsPage.types'
import { initialFilters } from './ObservationalListsPage.model'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import { PolicyViolations } from 'services/api/endpoints/risikaAPI/filters/policyViolations'
import { HighlightsFilters } from 'services/api/endpoints/risikaAPI/filters/policyHighlights'

export const countActiveFilters = (tableFilters: any) => {
  return Object.keys(tableFilters).reduce((accumulator: number, key: string) => {
    if (tableFilters[key].checked) {
      accumulator += 1
    }
    return accumulator
  }, 0)
}

export const resetFilter = (originalFilter: any, resetValue = false) => {
  return Object.keys(originalFilter).reduce((acc: any, key) => {
    originalFilter[key].checked = resetValue
    acc[key] = originalFilter[key]
    return acc
  }, {})
}

export const createInitialFilters = (
  violationsList: PolicyViolations[] | undefined,
  poaList: HighlightsFilters[] | undefined,
  includeViolations?: boolean
) => {
  const createFilterViolations = violationsList?.reduce((acc, curr) => {
    acc = {
      ...acc,
      [curr.code]: { label: curr.title, color: 0, checked: false, value: curr.code },
    }
    return acc
  }, {})

  const createFilterPOA = poaList?.reduce(
    (accumulator: { [key: string]: {} }, poaItem: poaListItemType, index: number) => {
      accumulator[`${poaItem.category}-${index}`] = {
        label: poaItem.title,
        color: 0,
        checked: false,
        category: poaItem.category,
        value: poaItem.value,
      }
      return accumulator
    },
    {}
  )
  if (includeViolations) {
    return {
      [apiKeys.highlights]: createFilterPOA,
      [apiKeys.violations]: createFilterViolations,
      ...initialFilters,
    }
  } else {
    return {
      [apiKeys.highlights]: createFilterPOA,
      ...initialFilters,
    }
  }
}

export const adjustFilter = (filterState: any, category: string) => {
  // If any violation is checked, then COMPLIANT = false, NONCOMPLIANT = true
  if (category === apiKeys.violations) {
    filterState[apiKeys.compliance][apiKeys.compliant].checked = false
    filterState[apiKeys.compliance][apiKeys.nonCompliant].checked = true
  }
  // If COMPLIANT is checked true, reset all violations
  if (category === apiKeys.compliance) {
    // Compliant dropdown is accessed
    if (filterState[apiKeys.compliance][apiKeys.compliant].checked) {
      // Compliant is checked
      filterState[apiKeys.violations] = resetFilter(
        filterState[apiKeys.violations],
        false
      )
    }
  }
  return filterState
}
