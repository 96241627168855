import { useState, useEffect } from 'react'
import { Box, Stack, Dialog, DialogContent, Divider, Typography } from '@mui/material'
import { ButtonPrimary } from 'components-new'
import { filters, categories } from '../models/filtersModel'
import { useSelector, useDispatch } from 'react-redux'
import { transformFilterToDispatch } from '../controller/general'
import { addFilter, removeFilter } from 'store_deprecated/advanced-filters'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import useTotangoTracking from 'utils/totangoTracking'
import { FilterSection } from '../widgets'
import { FilterCheckboxOption } from '../widgets/'

import styles from '../styles/select-filters'
import { Mixpanel } from 'services/helpers/mixpanel'

const { dialog, divider, dialogContent } = styles

const ChooseFilters = ({ filters: visibleFilters, onChange }) => {
  const { selectedFilters, filterCountry } = useSelector((state) => ({
    selectedFilters: state.risika.advancedFilters.filters,
    filterCountry: state.risika.advancedFilters.filterCountry,
  }))

  const initialState = visibleFilters
    .filter((filter) => filter.countries.includes(filterCountry))
    .map((filter) =>
      selectedFilters.map((x) => x.filter).includes(filter.label)
        ? { ...filter, checked: true }
        : { ...filter, checked: false }
    )

  const [filters, setFilters] = useState(initialState)

  useEffect(() => {
    onChange({
      active: filters.filter((x) => x.checked),
      inactive: filters.filter((x) => !x.checked),
    })
  }, [filters, onChange])

  const toggleCheckbox = (filter) => {
    setFilters(
      filters.map((x) => (x.label === filter ? { ...x, checked: !x.checked } : x))
    )
  }

  const isIndeterminate = (filters) => {
    return ![0, filters.length].includes(filters.filter((x) => x.checked).length)
  }

  const isAllChecked = (filters) => {
    return filters.length === filters.filter((x) => x.checked).length
  }

  const handleCheckAll = (filters) => {
    isIndeterminate(filters) || isAllChecked(filters)
      ? setFilters(filters.map((filter) => ({ ...filter, checked: false })))
      : setFilters(filters.map((filter) => ({ ...filter, checked: true })))
  }

  return (
    <Stack spacing={5}>
      <Box>
        <FilterCheckboxOption
          dataCy="select-all"
          indeterminate={isIndeterminate(filters)}
          checked={isAllChecked(filters)}
          onChange={() => handleCheckAll(filters)}
          value="select-all"
          filter={{ label: 'select-all' }}
        />
        <Divider sx={divider} />
      </Box>
      {categories.map((category) => (
        <FilterSection
          key={category.category}
          title={category.title}
          filters={filters.filter((filter) => filter.category === category.category)}
          onChange={toggleCheckbox}
        />
      ))}
    </Stack>
  )
}

const SelectFilters = ({ render }) => {
  const [open, setOpen] = useState(false)
  const [changedFilters, setChangedFilters] = useState({})
  const dispatch = useDispatch()
  const { trackEvent } = useTotangoTracking()

  const handleClickOpen = () => {
    Mixpanel.track('User clicked add filters')
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const applyFilters = () => {
    trackEvent('Filtering', 'Added Filters')
    changedFilters?.active?.forEach((filter) => {
      dispatch(addFilter(transformFilterToDispatch(filter.label)))
    })

    changedFilters?.inactive?.forEach((filter) => {
      dispatch(removeFilter(filter.label))
    })

    handleClose()
  }

  return (
    <>
      {render(handleClickOpen)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={dialog}>
          <Typography variant="h5" color="inherit">
            <FormattedMessage id={intl.advancedSearch('all-filters')} />
          </Typography>
          <ButtonPrimary
            data-cy="apply-filters"
            size="large"
            startIcon={null}
            disabled={!changedFilters.active?.length}
            onClick={applyFilters}
          >
            <FormattedMessage id={intl.advancedSearch('apply-filters')} />
          </ButtonPrimary>
        </Box>
        <DialogContent sx={dialogContent}>
          <ChooseFilters filters={filters} onChange={setChangedFilters} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SelectFilters
