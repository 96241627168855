import * as React from 'react'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { CustomChildrenProps } from '../CustomizationHandler.types'
import { findSelectedMenu } from '../CustomizationHandler.types.controller'
import { Paper, Radio, Stack, Typography } from '@mui/material'
import { Show } from 'components'
import { APIKeyType, TemplateType, VariantType } from 'types/queries'

type ChangeRadioSelection = {
  index: number
  template: TemplateType
}

function RadioButtonForm({
  children,
}: {
  children: React.ReactElement<CustomChildrenProps>[]
}) {
  const { state, actions } = useBuilder()
  const [selectedMenu, setSelectedMenu] = React.useState(
    findSelectedMenu(state, children)
  )
  const handleChangeRadioSelection = ({ index, template }: ChangeRadioSelection) => {
    setSelectedMenu(index)
    actions.updateCategory({
      apiKey: template?.template_data?.api_key as APIKeyType,
      variant: (template?.template_data?.variant ?? null) as VariantType | null,
    })
  }

  const formatDataCy = (title: string) =>
    `radio-button-${title.replace(/ /g, '-').toLowerCase()}`

  return (
    <Stack height={1} overflow="scroll" spacing={6}>
      {React.Children.map(children, (child, i) => {
        return (
          // Key is the index, because the state also uses the index to determine which menu is viewed
          <Paper component="div" sx={{ p: 3, backgroundColor: 'grey.100' }} key={i}>
            <Stack spacing={4}>
              <Stack direction="row" alignItems="center">
                <Radio
                  data-cy={formatDataCy(child.props.template.title)}
                  checked={selectedMenu === i}
                  onChange={() =>
                    handleChangeRadioSelection({
                      index: i,
                      template: child.props.template,
                    })
                  }
                />
                {/* MUI documentation has a Label prop for the Radio button, but it does not work */}
                <Typography>{child.props.template.title}</Typography>
              </Stack>
              <Show when={selectedMenu === i}>{child}</Show>
            </Stack>
          </Paper>
        )
      })}
    </Stack>
  )
}
export default RadioButtonForm
