import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  emptyStateCTAContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100vh',
    flex: 1,
  },
})
