import React from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { colors } from 'configs'
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Divider,
  Box,
  Typography,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
} from '@mui/material'
import { ButtonPrimary, ButtonSecondary } from 'components-new'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faPlus, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ViewSwitch, RestrictedComponent } from 'components'
import { monitorVerify, monitorAddToList, monitorUnsubscribe } from 'services/api'
import { submitFetchListEntriesById } from 'store_deprecated'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import ConditionallyRender from './ConditionallyRender'
import { useAccountStatus, useMonitorShowList } from 'services/queries'
import useTotangoTracking from 'utils/totangoTracking'
import { useStyles } from '../styles/add-monitoring-list-button'
import { useMonitorListPost, useUserSelf } from 'services/queries'
import { Mixpanel } from 'services/helpers/mixpanel'
import NotificationsIcon from '@mui/icons-material/Notifications'

const AddToMonitoringListButton = React.forwardRef(
  (
    {
      company,
      ready = true,
      buttonIcon,
      buttonText = <FormattedMessage id={intl.creditCheck('monitor-company')} />,
      listView,
    },
    ref
  ) => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const reactIntl = useIntl()
    const messages = reactIntl.messages
    const { data: lists } = useMonitorShowList()
    const [monitoredIn, setMonitoredIn] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [isAddingNewList, setIsAddingNewList] = React.useState(false)
    const [listName, setListName] = React.useState('New list')
    const [loading, setLoading] = React.useState('')
    const [loadingData, setLoadingData] = React.useState(false)
    const [isPublic, setIsPublic] = React.useState(false)
    const accountStatusQuery = useAccountStatus()
    const { trackEvent } = useTotangoTracking()
    const { mutate: createList } = useMonitorListPost()
    const { data: currentUser } = useUserSelf()

    const tryToOpenModal = () => {
      Promise.all(lists.map((list) => monitorVerify(list.list_id, company)))
        .then((results) => {
          setMonitoredIn(
            results
              .filter((result) => result.monitoring === true)
              .map((x) => ({
                listName: lists.name,
                listId: x.listId,
              }))
          )
          setOpen(true)
        })
        .catch(() => {
          setOpen(false)
          enqueueSnackbar(
            `${messages[intl.riskMonitoringNew('country-restriction-error')]}`,
            {
              variant: 'error',
            }
          )
        })
        .finally(() => {
          setLoadingData(false)
        })
    }

    const verify = (listId) => {
      monitorVerify(listId, company)
        .then((res) => {
          const list = lists.find((x) => x.list_id === listId)
          res.monitoring
            ? setMonitoredIn([...monitoredIn, { listName: list.name, listId: listId }])
            : setMonitoredIn(monitoredIn.filter((x) => x.listId !== listId))
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoading('')
        })
    }

    const handleAddToMonitoringList = (listId) => {
      const list = lists.find((x) => x.listId === listId)
      setLoading(listId)
      !monitoredIn.filter((x) => x.listId === listId).length
        ? monitorAddToList(listId, [company])
            .then(() => {
              dispatch(submitFetchListEntriesById(listId))
              trackEvent('Monitoring', 'Company Added to Monitoring')
              Mixpanel.track('User added company to monitoring list', {
                company: company,
              })
              verify(listId)
            })
            .catch((err) => {
              if (err?.response) {
                enqueueSnackbar(
                  <FormattedMessage
                    id={intl.snackbar(err.response.data.error)}
                    values={{ list: list.name }}
                  />,
                  {
                    variant: 'failure',
                    className: 'snackbar-container snackbar__success',
                  }
                )
              }
              setLoading('')
            })
        : monitorUnsubscribe(listId, company)
            .then(() => {
              Mixpanel.track('User removed company from monitoring list', {
                company: company,
              })
              verify(listId)
            })
            .catch((err) => {
              console.log(err)
              setLoading('')
            })
    }
    const handleCreateNewList = () => {
      if (!listName) {
        enqueueSnackbar(
          <FormattedMessage id={intl.riskMonitoringNew('add-list-name-snackbar')} />
        )
      } else {
        createList({
          title: listName,
          ownerApiId: currentUser.api_user_id,
          users: isPublic ? null : [currentUser.api_user_id],
          isPublic,
        })
        setListName('')
        setIsAddingNewList(false)
      }
    }

    const handleEditModeKeyEvents = (event) => {
      switch (event.key) {
        case 'Escape':
          setIsAddingNewList(false)
          break
        case 'Enter':
          handleCreateNewList()
          break
        default:
          break // just to remove warning in console
      }
    }

    return (
      <Box ref={ref}>
        {accountStatusQuery.data?.subscription_plan !== 'FREEMIUM' ? (
          listView ? (
            <MuiListItem sx={{ paddingX: 0 }} key="share-report">
              <MuiListItemButton disabled={!ready} onClick={() => tryToOpenModal()}>
                <MuiListItemText
                  primary={<FormattedMessage id={intl.generic('monitor')} />}
                />
              </MuiListItemButton>
            </MuiListItem>
          ) : (
            <ButtonPrimary
              startIcon={
                buttonIcon ?? <NotificationsIcon sx={{ fontSize: '15px !important' }} />
              }
              disabled={!ready}
              onClick={() => tryToOpenModal()}
            >
              <FormattedMessage id={intl.generic('monitor')} />
            </ButtonPrimary>
          )
        ) : listView ? (
          <MuiListItem sx={{ paddingX: 0 }} key="share-report">
            <MuiListItemButton disabled={!ready} onClick={() => setOpen(true)}>
              <MuiListItemText primary={buttonText} />
            </MuiListItemButton>
          </MuiListItem>
        ) : (
          <ButtonPrimary
            startIcon={
              buttonIcon ?? <FontAwesomeIcon style={{ width: 12 }} icon={faBell} />
            }
            disabled={!ready}
            onClick={() => setOpen(true)}
          >
            {buttonText}
          </ButtonPrimary>
        )}

        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <DialogTitle style={{ backgroundColor: colors?.risika, color: 'white' }}>
            <Typography color="inherit" variant="h5">
              <FormattedMessage id={intl.creditCheck('select-list')} />
            </Typography>
          </DialogTitle>
          <List>
            {lists?.map((list) => (
              <ListItem
                paddingX={4}
                paddingY={2}
                style={{ display: 'flex' }}
                button
                key={list.list_id}
              >
                <ListItemText style={{ marginRight: '1rem' }}>
                  <Typography variant="body1">{list.name}</Typography>
                </ListItemText>
                {monitoredIn.filter((x) => x.listId === list.list_id).length ? (
                  <ButtonSecondary
                    disabled={loading === list.list_id || loadingData}
                    onClick={() => handleAddToMonitoringList(list.list_id)}
                  >
                    <FormattedMessage id={intl.generic('remove')} />
                  </ButtonSecondary>
                ) : (
                  <ButtonPrimary
                    disabled={loading === list.list_id || loadingData}
                    onClick={() => handleAddToMonitoringList(list.list_id)}
                  >
                    <FormattedMessage id={intl.generic('add')} />
                  </ButtonPrimary>
                )}
              </ListItem>
            ))}
          </List>
          <Divider />
          <div className={classes.addNewList}>
            <ConditionallyRender
              condition={isAddingNewList}
              when={
                <>
                  <div className={classes.newListField}>
                    <TextField
                      type="text"
                      value={listName}
                      onChange={(e) => setListName(e.target.value)}
                      autoFocus
                      fullWidth
                      label={<FormattedMessage id={intl.companyHierarchy('name')} />}
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      onKeyUp={handleEditModeKeyEvents}
                      margin="normal"
                    />
                    <IconButton
                      onClick={() => handleCreateNewList()}
                      className={classes.addRemoveButtons}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </IconButton>
                    <IconButton
                      onClick={() => setIsAddingNewList(false)}
                      className={classes.addRemoveButtons}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                  </div>
                  <div className={classes.labelPlacement}>
                    <ViewSwitch
                      actionChange={() => setIsPublic(!isPublic)}
                      checkedVar={isPublic}
                      textOff={
                        <FormattedMessage
                          id={intl.riskMonitoringNew('new-list-private')}
                        />
                      }
                      textOn={
                        <FormattedMessage
                          id={intl.riskMonitoringNew('new-list-public')}
                        />
                      }
                    />
                  </div>
                </>
              }
              otherwise={
                <RestrictedComponent component="create-new-list">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => setIsAddingNewList(true)}
                  >
                    {<FontAwesomeIcon icon={faPlus} />}
                  </IconButton>
                </RestrictedComponent>
              }
            />
          </div>
        </Dialog>
      </Box>
    )
  }
)

export default AddToMonitoringListButton
