// @flow

import * as React from 'react'

export const DefaultCheckbox = React.forwardRef((props, ref) => (
  <input type="checkbox" ref={ref} {...props} />
))

export function DefaultLabel(props) {
  return <p {...props} />
}

export function DefaultExpander({ isExpanded, ...props }) {
  return <button {...props}>{isExpanded ? 'Close' : 'Open'}</button>
}

export const defaultComponents = {
  Checkbox: DefaultCheckbox,
  Label: DefaultLabel,
  Expander: DefaultExpander,
}
