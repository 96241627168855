import { DropdownSelect } from 'components-new'
import React from 'react'
import { fromValueToLabel, fromLabelToValue } from '../controller'
import mapping from './model'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'

const options = [
  {
    label: <FormattedMessage id={intl.creditPolicyNew('not-registered')} />,
    value: 'not-registered',
  },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('should-be-registered')} />,
    value: 'should-be-registered',
  },
]

const CompanyVAT = ({ isAdvanced = true }) => {
  const { policy, setPolicy, isInferiorPlan } = useCountryPolicy()
  const defVal = fromValueToLabel(policy?.exclude_vat?.[0] ?? null, mapping)
  const [val, setVal] = React.useState(defVal)

  React.useEffect(() => {
    setVal(fromValueToLabel(policy?.exclude_vat?.[0] ?? null, mapping))
  }, [policy])

  const handleChange = (value: string): void => {
    setPolicy({ ...policy, exclude_vat: [fromLabelToValue(value, mapping) as string] })
  }

  return (
    <DropdownSelect
      placeholder={<FormattedMessage id={intl.creditPolicyNew('select')} />}
      options={options}
      value={val}
      disablePlaceholderClick={false}
      setValue={handleChange}
      disabled={isAdvanced && isInferiorPlan}
    />
  )
}

export default CompanyVAT
