import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import ReactDOM from 'react-dom'
import CloseIcon from '@mui/icons-material/Close'
import { Stack, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { useQueryClient } from 'react-query'

interface HubspotConversationsContextType {
  toggleWidget: (command?: 'open' | 'close') => void
  isOpen: boolean
  unreadMessagesCount: number
}

const HubspotConversationsContext = createContext<HubspotConversationsContextType | null>(
  null
)

const HUBSPOT_INLINE_EMBED_ELEMENT_ID = 'hubspot-conversations-inline-embed-selector'

export const HubspotConversationsProvider = ({ children }: { children: ReactNode }) => {
  const [isReady, setIsReady] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const queryClient = useQueryClient()

  const classes = useStyles(isOpen)
  const hideWidget = useCallback(() => {
    setIsOpen(false)
  }, [])

  const showWidget = useCallback(() => {
    if (!isReady) return

    const settingsQuery = queryClient.getQueryData<{ language: string }>(
      'getUserSettings'
    )

    const lang = settingsQuery?.language ?? 'en_UK'
    window.history.pushState({}, 'hs_lang', `?hs_lang=${lang}`)
    window.HubSpotConversations.widget.refresh()
    setIsOpen(true)
  }, [isReady, queryClient])

  const toggleWidget = useCallback(
    (command?: 'open' | 'close') => {
      if (isOpen || command === 'close') {
        hideWidget()
      } else {
        showWidget()
      }
    },
    [hideWidget, isOpen, showWidget]
  )

  const onConversationsReady = useCallback(() => {
    setIsReady(true)
  }, [])

  useEffect(
    function init() {
      if (window.HubSpotConversations) {
        onConversationsReady()
      } else {
        window.hsConversationsOnReady = [onConversationsReady]
      }
    },
    [onConversationsReady]
  )

  //   )
  useEffect(
    function addEventListeners() {
      if (!isReady) return

      function listener(payload: { unreadCount: number }) {
        setUnreadMessagesCount(payload.unreadCount)
      }

      window.HubSpotConversations.on('unreadConversationCountChanged', listener)

      return () => {
        window.HubSpotConversations.off('unreadConversationCountChanged', listener)
      }
    },
    [isReady]
  )

  useEffect(
    function refreshConversationsOnRouteChange() {
      if (!isReady) return

      window.HubSpotConversations.widget.refresh()
    },
    [isReady]
  )
  return (
    <HubspotConversationsContext.Provider
      value={{ isOpen, toggleWidget, unreadMessagesCount }}
    >
      {children}
      {ReactDOM.createPortal(
        <Box className={classes.chatWidgetContainer} id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}>
          <Stack
            justifyContent="center"
            alignItems="center"
            className={classes.closeIconContainer}
            onClick={() => {
              console.log('Exit chat')
              toggleWidget()
            }}
          >
            <CloseIcon data-cy="hubspot-conversation-close" />
          </Stack>
        </Box>,
        document.body
      )}
    </HubspotConversationsContext.Provider>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  closeIconContainer: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    position: 'absolute',
    top: '20px',
    right: '20px',
    display: (isOpen) => (isOpen ? 'inherit' : 'none'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chatWidgetContainer: {
    overflow: 'hidden',
    zIndex: 9999, // this is the max possible value
    position: 'fixed',
    bottom: 104,
    right: 30,
    height: 729,
    width: 350,
    display: (isOpen) => (isOpen ? 'block' : 'none'),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 5px 20px rgb(0 0 0 / 10%)',

    '& #hubspot-conversations-inline-parent': {
      width: '100%',
      height: '100%',
    },

    '& #hubspot-conversations-inline-iframe': {
      width: '100%',
      height: '100%',
      border: 'none',
    },
  },
}))

export function useHubspotConversations() {
  const context = useContext(HubspotConversationsContext)

  if (context === null) {
    throw new Error(
      'useHubspotConversations must be used within HubspotConversationsProvider'
    )
  }

  return context
}

declare global {
  interface Window {
    hsConversationsSettings: Record<string, any>
    hsConversationsOnReady: Array<() => void>
    HubSpotConversations: {
      on: any
      off: any
      widget: {
        status: () => { loaded: boolean; pending: boolean }
        load: (params?: { widgetOpen: boolean }) => void
        remove: () => void
        open: () => void
        close: () => void
        refresh: (openToNewThread?: boolean) => void
      }
    }
  }
}
