import { Box } from '@mui/material'
import { theme } from 'styles-new/mui5-transition/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const RedWarningTriangle = () => {
  return (
    <Box p={5}>
      <FontAwesomeIcon
        color={theme.palette.error.main}
        size="7x"
        icon={faExclamationTriangle as IconProp}
      />
    </Box>
  )
}

export default RedWarningTriangle
