import React from 'react'
import { Stack, Typography } from '@mui/material'
import { colors } from 'configs'

const main = (score: number) => {
  return {
    borderRadius: '0 6px 0 0   ',
    minWidth: '64px',
    position: 'relative',
    zIndex: 3,
    backgroundColor:
      colors[`score_rating_${score as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10}`],
    color: 'common.white',
  }
}

const SquareScore = ({ score }: { score: number }) => {
  return (
    <Stack p="20px" alignItems="center" justifyContent="center" sx={main(score)}>
      <Typography color="inherit" variant="h5">
        {score}
      </Typography>
    </Stack>
  )
}

export default SquareScore
