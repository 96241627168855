import { colors } from 'configs'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    '@media screen and (min-width: 600px)': {
      width: '64rem',
      margin: '0 auto',
    },
    '@media screen and (max-width: 600px)': {
      padding: '2rem',
    },
  },
  button: {
    backgroundColor: colors?.risikaContrast,
    textAlign: 'center',
    '&:hover': {
      backgroundColor: colors?.risikaContrast,
    },
    '@media screen and (min-width: 600px)': {
      margin: '1rem',
      marginTop: '3.5rem',
      height: '6rem',
      width: '30rem',
    },
    '@media screen and (max-width: 600px)': {
      marginTop: '2rem',
      width: '30rem',
      height: '5.5rem',
    },
  },
  risikaLogo: {
    '@media screen and (min-width: 600px)': {
      width: '29rem',
      marginLeft: '5rem',
    },
    '@media screen and (max-width: 600px)': {
      marginTop: '2rem',
      marginBottom: '3rem',
    },
  },
  container: {
    '@media screen and (min-width: 600px)': {
      marginBottom: '5rem',
    },
    '@media screen and (max-width: 600px)': {
      display: 'contents',
    },
  },
  links: {
    display: 'flex',
  },
}))
