import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { Box, Typography } from '@mui/material'
import { ColorLevelT } from 'types/general'
import classes from 'pages/credit-check/styles/credit-field'

type Props = {
  // eslint-disable-next-line prettier/prettier
  days: number | null,
  // eslint-disable-next-line prettier/prettier
  isError: boolean,
  // eslint-disable-next-line prettier/prettier
  colorLvl: ColorLevelT,
  isGrey: boolean
}

function CreditDays({ days = null, isError, colorLvl, isGrey }: Props) {
  const reactIntl = useIntl()

  return (
    <Box sx={classes.outerFrame(colorLvl, isGrey)}>
      <Typography>
        <FormattedMessage id={intl.mainRecommendation('credit-days')} />
      </Typography>
      <Box fontWeight="bold">
        <Typography variant="h5" sx={classes.coloredText(colorLvl, isGrey)}>
          {isError && (
            <FormattedMessage
              id={intl.mainRecommendation('recommendation-credit-unknown')}
            />
          )}
          {Number.isInteger(days) &&
            reactIntl.formatMessage(
              {
                id: intl.mainRecommendation('recommended-days'),
              },
              {
                amount: Math.floor(days ?? 0),
              }
            )}
        </Typography>
      </Box>
    </Box>
  )
}

export default CreditDays
