import { Collapse, Stack, Typography } from '@mui/material'
import RisikaScore from '..'
import { Show } from 'components'
import { isComponentRendered } from 'configs'
import ProbabilityOfDistress from 'components-new/elements/ProbabilityOfDistress/ProbabilityOfDistress'
import { getLastGazzeteReport } from 'utils-new/get-last-gazzete-report'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { RiskEstimationPropType } from './D3RisikaScore.types'
import { AvailableCountries } from 'globalTypes'
import RiskEstimationSkeleton from './RiskEstimation.skeleton'
import { LoadingSkeleton } from 'components-new'
import { WidgetWrapper } from '../../../WidgetWrapper/WidgetWrapper'

function RiskEstimation({
  widgetNameDND,
  statstidendeData,
  riskInfo,
  statusInfo,
  isReArrange,
  distress,
  isSoleProprietorship,
  localId,
  isLoading,
  isActive = true,
  setIsActive,
}: RiskEstimationPropType) {
  const lastReport = getLastGazzeteReport(statstidendeData)

  return (
    <LoadingSkeleton SkeletonComponent={RiskEstimationSkeleton} isLoading={isLoading}>
      <Show
        when={Boolean(
          isComponentRendered('risikasEstimation', localId?.country as AvailableCountries)
        )}
      >
        <WidgetWrapper
          isReArrange={isReArrange}
          widgetName={widgetNameDND}
          isActive={!!isActive}
          setIsActive={setIsActive}
          dataCy={widgetNameDND}
        >
          <>
            <Stack m={4} direction="row" justifyContent="space-between">
              <Typography variant="h4">
                <FormattedMessage id={intl.mainRecommendation('risika-estimation')} />
              </Typography>
              <Show when={!!distress}>
                <Collapse in={!isReArrange}>
                  <ProbabilityOfDistress
                    distress={distress}
                    isSoleProprietorship={isSoleProprietorship}
                  />
                </Collapse>
              </Show>
            </Stack>
            <Collapse in={!isReArrange}>
              <RisikaScore
                report={lastReport}
                riskInfo={riskInfo}
                statusInfo={statusInfo}
                localId={localId}
              />
            </Collapse>
          </>
        </WidgetWrapper>
      </Show>
    </LoadingSkeleton>
  )
}

export default RiskEstimation
