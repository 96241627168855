import { ReactNode } from 'react'
import { Typography } from '@mui/material'
import { Column } from 'components-new/data-display/SimpleTable/SimpleTable.types'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import Name from './columns/Name'
import Companies from './columns/Companies'
import { PeopleVisitResult } from 'services/api/endpoints/risikaAPI/peopleVisitsList'
import Roles from './columns/Roles'
import ActiveCompanies from './columns/ActiveCompanies'

const HeaderComponent = ({ label }: { label: ReactNode }) => (
  <Typography py={4} px={6} variant="subtitle2">
    {label}
  </Typography>
)

export const columns: Column<PeopleVisitResult>[] = [
  {
    id: 'name',
    width: '20%',
    bodyComponent: (item) => <Name item={item} />,
    headerComponent: () => (
      <HeaderComponent label={<FormattedMessage id={intl.generic('name')} />} />
    ),
  },
  {
    id: 'companies',
    width: '20%',
    bodyComponent: (item) => <Companies item={item} />,
    headerComponent: () => (
      <HeaderComponent label={<FormattedMessage id={intl.generic('companies')} />} />
    ),
  },
  {
    id: 'roles',
    width: '20%',
    bodyComponent: (item) => <Roles item={item} />,
    headerComponent: () => (
      <HeaderComponent label={<FormattedMessage id={intl.generic('roles')} />} />
    ),
  },
  {
    id: 'active-companies',
    width: '10%',
    bodyComponent: (item) => <ActiveCompanies item={item} />,
    headerComponent: () => (
      <HeaderComponent
        label={<FormattedMessage id={intl.generic('active-companies')} />}
      />
    ),
  },
]
