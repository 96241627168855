import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { HTMLAttributes } from 'react'
interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: 'primary' | 'secondary'
}

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: (props: Props) => (props.color === 'primary' ? '#fff' : '#232323'),
    margin: 0,
    fontWeight: 700,
  },
  description: {
    color: (props: Props) => (props.color === 'primary' ? '#fff' : '#232323'),
    margin: 0,
  },
  content: {
    backgroundColor: (props: Props) =>
      props.color === 'primary' ? theme.palette.primary.main : '#fff',
    padding: theme.spacing(1),
    borderRadius: '0.5rem',
    boxShadow: theme.shadows[2],
  },
  button: {
    padding: theme.spacing(1),
  },
}))
