import { CircularProgress } from '@mui/material'
import { colors } from 'configs'

// When you add isLoading if the condition is false it shows a spinner.

type ConditionallyRenderProps = {
  when: React.ReactNode
  otherwise?: React.ReactNode
  condition: boolean
  isLoading?: boolean
}

const ConditionallyRender = ({
  when,
  otherwise = null,
  condition,
  isLoading,
}: ConditionallyRenderProps) => {
  return (
    <>
      {isLoading ? (
        <CircularProgress sx={{ color: colors?.risikaContrast }} />
      ) : condition ? (
        when
      ) : (
        otherwise
      )}
    </>
  )
}

export default ConditionallyRender
