import { Typography } from '@mui/material'
import { riskCategories } from '../../../controller'
import { RestrictedComponent } from 'components'
import { classes } from '../../../styles/score-cell'

const ScoreCell = ({ localId, score, riskAssessment }) => {
  const displayScore = score?.toString() ?? riskAssessment?.toUpperCase() ?? '-'

  return (
    <RestrictedComponent local_id={localId} component="risika-score">
      <Typography variant="subtitle2" sx={classes.main(riskCategories[displayScore])}>
        {displayScore}
      </Typography>
    </RestrictedComponent>
  )
}

export default ScoreCell
