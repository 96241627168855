// @flow

import { DOMAIN as _ } from 'configs/constants/system'

/****************
 * Action Types *
 ****************/

export const SET_HIERARCHY_FILTERS = `${_} SET_HIERARCHY_FILTERS`
export const SET_ORDER = `${_} SET_ORDER`
export const SET_SELECTED_COMPANY = `${_} SET_SELECTED_COMPANY`
export const SET_HIERARCHY_DATA = `${_} SET_HIERARCHY_DATA`
export const SAVE_HIERARCHY_VIEW = `${_} SAVE_HIERARCHY_VIEW`
export const UPDATE_HIERARCHY_VIEW = `${_} UPDATE_HIERARCHY_VIEW`

/*****************
 * Initial state *
 *****************/

const initialState = {
  hierarchyData: [],
  filters: {
    isProfitLoss: true,
    isEquity: true,
    isCvr: true,
  },
  order: 'Name',
  selectedCompany: {},
  savedViews: {},
  d3HierarchyView: {},
}

/*******************
 * Action Creators *
 *******************/

export const setHierarchyFilter = (filter, value) => ({
  type: SET_HIERARCHY_FILTERS,
  filter,
  value,
})
export const saveHierarchyView = (data, name) => ({
  type: SAVE_HIERARCHY_VIEW,
  data,
  name,
})
export const updateHierarchyView = (data) => ({
  type: UPDATE_HIERARCHY_VIEW,
  data,
})

export const setOrderFilter = (value) => ({
  type: SET_ORDER,
  value,
})
export const setSelectedCompany = (localId, name) => ({
  type: SET_SELECTED_COMPANY,
  localId,
  name,
})
export const setCompanyHierarchyData = (data) => ({
  type: SET_HIERARCHY_DATA,
  data,
})

/************
 * Reducers *
 ************/

export const companyHierarchy = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_HIERARCHY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filter]: !action.value,
        },
      }
    case SET_ORDER:
      return {
        ...state,
        order: action.value,
      }
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          localId: action.localId,
          name: action.name,
        },
      }
    case SET_HIERARCHY_DATA:
      return {
        ...state,
        hierarchyData: action.data,
      }
    case SAVE_HIERARCHY_VIEW:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          [action.name]: action.data,
        },
      }
    case UPDATE_HIERARCHY_VIEW:
      return {
        ...state,
        d3HierarchyView: action.data,
      }
    default:
      return state
  }
}

export default companyHierarchy
