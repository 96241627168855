import { useMutation, useQueryClient } from 'react-query'
import { monitorAddNewList } from 'services/api'
import { useDispatch } from 'react-redux'
import { setSelectedList } from 'store_deprecated'
import { MonitorListPostResType, MonitorListPostType } from 'types/queries'
import { MONITOR_LIST } from './lists/constants'

export function useMonitorListPost() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation<MonitorListPostResType, Error, MonitorListPostType>(
    monitorAddNewList,
    {
      onSuccess: (res) => {
        queryClient.refetchQueries(MONITOR_LIST)
        dispatch(setSelectedList(+res.list_id))
      },
      onError: (err) => {
        console.log('Error ', err)
      },
    }
  )
}
