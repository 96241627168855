import { AvailableCountries } from 'globalTypes'
import { useQuery } from 'react-query'
import { searchCompany } from 'services/api'
import { useUserSettings } from './useUserSettings'
import { useCustomerSettingsField } from './useCustomerSettingsField'
import { CompanySearchResponse } from '../api/endpoints/risikaAPI/searchCompany'
import { ErrorResponse } from 'types/general'

export function useCompanySearch(searchText: string, country: AvailableCountries) {
  const userSettingsQuery = useUserSettings()
  const customerSettingsQuery = useCustomerSettingsField('user_model')
  const companySearchResult = useQuery<
    CompanySearchResponse,
    ErrorResponse,
    CompanySearchResponse,
    string[]
  >(
    ['search', 'company', searchText, country],
    () => {
      return searchCompany(
        {
          searchText,
          searchCountry: country,
          showInactive: true,
          model: customerSettingsQuery?.data?.user_model?.[country] ?? 'BOHR',
        },
        { currency: userSettingsQuery?.data?.selectedCurrency?.currency }
      )
    },

    {
      enabled:
        searchText.length >= 3
          ? (!userSettingsQuery.isLoading || userSettingsQuery.isError) &&
            (!customerSettingsQuery.isLoading || customerSettingsQuery.isError)
          : false,
      // staleTime: 5 * 60 * 10000,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  return { ...companySearchResult }
}
