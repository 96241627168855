import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
} from '@mui/material'
import { ButtonDestructive, ButtonSecondary, ButtonTertiary } from 'components-new'
import Show from 'components/Show'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { ReactNode } from 'react'

type CommunicationModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  title: string | ReactNode
  body: string | ReactNode
  mainButton: {
    isDestructive?: boolean
    content: string | ReactNode
    click: () => void
  }
  secondaryButton?: {
    content: string | ReactNode
    click: () => void
  }
  onClose?: () => void
}

function CommunicationModal({
  open,
  setOpen,
  title,
  body,
  mainButton,
  secondaryButton,
  onClose,
}: CommunicationModalProps) {
  const theme = useTheme()
  const handleClose = () => {
    onClose?.()
    setOpen(false)
  }
  return (
    <Dialog
      data-cy="dialog-modal"
      PaperProps={{
        sx: {
          px: 6,
          paddingBottom: 6,
          paddingTop: '56px',
          position: 'relative',
          gap: 4,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0, backgroundColor: 'common.white' }}>
        <Typography data-cy="dialog-title" variant="h5">
          {title}
        </Typography>
        <IconButton
          data-cy="close-button"
          onClick={handleClose}
          style={{
            position: 'absolute',
            padding: 0,
            top: 20,
            right: 20,
            fontSize: 28,
            color: theme.palette.primary.main,
          }}
        >
          <CloseIcon fontSize="inherit" color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent data-cy="dialog-content" sx={{ padding: 0 }}>
        <Typography variant="body1">{body}</Typography>
      </DialogContent>
      <DialogActions
        sx={{
          mt: 4,
          padding: 0,
          justifyContent: 'flex-start',
        }}
      >
        <Stack direction="row" gap={3} p={0}>
          {mainButton.isDestructive ? (
            <ButtonDestructive data-cy="destructive-button" onClick={mainButton.click}>
              {mainButton.content}
            </ButtonDestructive>
          ) : (
            <ButtonSecondary data-cy="modal-main-button" onClick={mainButton.click}>
              {mainButton.content}
            </ButtonSecondary>
          )}
          <Show when={!!secondaryButton?.content}>
            <ButtonTertiary
              data-cy="modal-secondary-button"
              onClick={secondaryButton?.click}
            >
              {secondaryButton?.content}
            </ButtonTertiary>
          </Show>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default CommunicationModal
