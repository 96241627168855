const main = {
  position: 'absolute',
  top: -30,
  left: 0,
  right: 0,
}

const ownersBtn = (isExpanded: boolean) => {
  return {
    paddingX: 3,
    paddingY: 1,
    borderRadius: '4px 4px 0px 0px',
    backgroundColor: isExpanded ? 'secondary.300' : 'primary.300',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'secondary.main',
    },
  }
}

const subsidiaryBtn = (isExpanded: boolean) => {
  return {
    paddingX: 3,
    paddingY: 1,
    backgroundColor: isExpanded ? 'secondary.300' : 'primary.300',
    borderRadius: '4px 4px 0px 0px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'secondary.main',
    },
  }
}

const subsidiariesBtnParentHie = {
  position: 'absolute',
  bottom: '0px',
  width: '100%',
}

const subsidiariesMain = {
  position: 'relative',
  bottom: '30px',
  '&:hover': {
    cursor: 'pointer',
  },
}

export default {
  main,
  ownersBtn,
  subsidiaryBtn,
  subsidiariesBtnParentHie,
  subsidiariesMain,
}
