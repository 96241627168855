import { Box, ListItemIcon, Theme, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { StyledListItemButton, StyledListItemText } from 'styles/components/sidebar.js'
import SupportMenuItem from './SupportMenuItem'
import { NavigationItemActionProps } from './types'

function NavigationItemAction({ title, action, icon }: NavigationItemActionProps) {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  if (title === 'chat') {
    return <SupportMenuItem data-cy={title} title={title} icon={icon} />
  }

  return (
    <StyledListItemButton onClick={action}>
      <ListItemIcon>
        {icon}
        <Box mt={isSmall ? 0 : 1} textAlign="center">
          <StyledListItemText>
            <FormattedMessage id={intl.navigation(title)} />
          </StyledListItemText>
        </Box>
      </ListItemIcon>
    </StyledListItemButton>
  )
}

export default NavigationItemAction
