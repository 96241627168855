import moment from 'moment'
import { SxProps } from '@mui/system'

const handleProgressBar = (validFrom?: string) => {
  const formattedValidFrom = moment(validFrom)
  const monthsPassed = moment().diff(formattedValidFrom, 'months')
  if (monthsPassed < 24) {
    return `linear-gradient(90deg, #DC172F ${monthsPassed}%, white ${monthsPassed}%)`
  }
  if (monthsPassed < 55) {
    return `linear-gradient(90deg, #FEBE0E ${monthsPassed}%, white ${monthsPassed}%)`
  }
  if (monthsPassed >= 55) {
    return `linear-gradient(90deg, #4EA206 ${monthsPassed}%, white ${monthsPassed}%)`
  }
}

export const expandRetractButton = {
  fontSize: '16px',
  color: 'primary.700',
  borderRadius: '0.5rem',
  cursor: 'pointer',
  marginLeft: '-3px', // There is some stupid invisible margin around the icon that was tripping me
  marginRight: 2,
}
export const nameTableCell = (level: number = 0) => {
  const childrenRowsPadding = `${(level + 1) * 22}px`
  return {
    paddingLeft: level === 0 ? '20px' : childrenRowsPadding,
    height: 50,
  }
}
const lineColor = '#E5EEF0'

export const centerLine = {
  height: '2rem',
  width: '2rem',
  position: 'absolute',
  left: '-2.9rem',
  borderBottom: `1px solid ${lineColor}`,
  top: 'calc(50% - 2rem)',
}
export const verticalLine = {
  height: '52px',
  width: '2rem',
  position: 'absolute',
  left: '-2.9rem',
  borderLeft: `1px solid ${lineColor}`,
}
export const lLine = {
  height: '3rem',
  width: '2rem',
  position: 'absolute',
  left: '-2.9rem',
  borderLeft: `1px solid ${lineColor}`,
  borderBottom: `1px solid ${lineColor}`,
  borderBottomLeftRadius: '0.5rem',
  top: 'calc(50% - 3rem)',
}
export const tinyLine = {
  height: '1rem',
  width: '1px',
  position: 'absolute',
  left: '1.1rem',
  bottom: '-1.2rem',
  backgroundColor: lineColor,
}

export const ownershipText = (align: string) => {
  return {
    backgroundColor: 'grey.300',
    display: 'inline-block',
    padding: '0.5rem',
    borderRadius: '10rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    alignSelf: align,
  }
}

export const progressBar = (validFrom?: string) => {
  return {
    width: '7rem',
    height: '1rem',
    border: '1px solid black',
    borderRadius: '1000rem',
    background: handleProgressBar(validFrom),
  }
}

export const cellPadding = {
  paddingX: '20px',
  borderBottom: '1px solid',
  borderColor: 'grey.300',
}

export const clickableText = {
  cursor: 'pointer',
}

export const tableCell = (cellWidth: number): SxProps => ({
  width: cellWidth,
  padding: '20px',
  border: 'none',
  borderRight: '1px solid',
  borderColor: 'grey.200',
})

export const classes = {
  expandRetractButton,
  nameTableCell,
  centerLine,
  verticalLine,
  lLine,
  tinyLine,
  ownershipText,
  progressBar,
  cellPadding,
  clickableText,
}
