import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  summaryContent: {
    margin: 0,
    alignItems: 'center',
  },
  deleteButton: {
    margin: 0,
    color: '#000',
  },
  subscriptionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  noSubscriptions: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    border: '1px dashed #232323',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))
