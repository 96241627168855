import { SyntheticEvent } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import colors from 'configs/colors'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import intl from 'localization/components'
import { HierarchyViewT } from 'pages/company-hierarchy/CompanyHierarchyPage'

const tabsStyle = {
  boxShadow: 'none',
  margin: 0,
  height: '100%',
  minHeight: 0,
  '& .MuiTabs-flexContainer': {
    height: '100%',
    alignItems: 'center',
  },
  '& .MuiButtonBase-root.MuiTab-root': {
    textTransform: 'none',
    minHeight: 0,
    fontFamily: 'Montserrat',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
    color: colors.risikaSecondary,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: colors.risikaSecondary,
  },
}

type Props = {
  selectedView: HierarchyViewT
  setSelectedView: (newView: HierarchyViewT) => void
}

const ListOrTreeSwitch = ({ selectedView, setSelectedView }: Props) => {
  const handleChange = (e: SyntheticEvent, newVal: HierarchyViewT) => {
    setSelectedView(newVal)
  }

  return (
    <Tabs
      data-cy="list-or-tree-switch"
      value={selectedView}
      onChange={handleChange}
      sx={tabsStyle}
    >
      <Tab
        disableRipple
        data-cy="tree-view"
        value="tree"
        sx={{ padding: 0 }}
        label={
          <Box sx={{ color: 'grey.800' }}>
            <Typography variant="subtitle2" color="inherit">
              <FormattedMessage id={intl.companyHierarchy('tree-view')} />
            </Typography>
          </Box>
        }
      />
      <Tab
        disableRipple
        data-cy="list-view"
        value="list"
        sx={{ padding: 0 }}
        label={
          <Box sx={{ color: 'grey.800' }}>
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.companyHierarchy('list-view')} />
            </Typography>
          </Box>
        }
      />
    </Tabs>
  )
}

export default ListOrTreeSwitch
