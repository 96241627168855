// import { MappingItem } from '../types'

type RisikaScoreMapping = {
  label: string
  value: number[] | null
}

const mapping: RisikaScoreMapping[] = [
  { label: '', value: null },
  { label: '1', value: [] },
  { label: '2', value: [1] },
  { label: '3', value: [1, 2] },
  { label: '4', value: [1, 2, 3] },
  { label: '5', value: [1, 2, 3, 4] },
  { label: '6', value: [1, 2, 3, 4, 5] },
  { label: '7', value: [1, 2, 3, 4, 5, 6] },
  { label: '8', value: [1, 2, 3, 4, 5, 6, 7] },
  { label: '9', value: [1, 2, 3, 4, 5, 6, 7, 8] },
  { label: '10', value: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
]

export default mapping
