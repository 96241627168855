import { SxProps } from '@mui/material'
import { theme } from 'styles-new/mui5-transition/theme'

const main = (border: string, canvas: string) => {
  return {
    position: 'relative',
    border: ` 1px solid`,
    borderColor: border,
    backgroundColor: canvas,
    my: 3,
    py: 3,
    px: 4,
    borderRadius: theme.shape,
    '&::first-child': {
      zIndex: 5,
    },
  }
}

const conclusion = (conclusionBg: string): SxProps => {
  return {
    mt: 3,
    minWidth: '50px',
    height: '28px',
    backgroundColor: conclusionBg,
    borderRadius: theme.shape,
    textTransform: 'uppercase',
    border: '1px solid',
    borderColor: conclusionBg !== 'grey.100' ? conclusionBg : 'grey.800',
    '& > p': {
      color: conclusionBg !== 'grey.100' ? 'common.white' : 'grey.800',
    },
  }
}

const connectionLine = {
  borderLeft: `1px dashed ${theme.palette.grey[400]}`,
  height: theme.spacing(5),
  margin: '0px !important',
  position: 'absolute',
  top: `calc(-1px - ${theme.spacing(5)})`,
  left: '50%',
}

export default {
  main,
  conclusion,
  connectionLine,
}
