import { SxProps } from '@mui/system'

const card: SxProps = {
  width: '16rem',
  height: '15rem',
  backgroundColor: '#F1F3F5',
}

export default {
  card,
}
