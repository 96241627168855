import { Typography } from '@mui/material'
import { useIntl, FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const AmountCell = ({ cell, currency, dataCy }) => {
  const reactIntl = useIntl()

  const text = {
    whiteSpace: 'nowrap',
  }

  return (
    <Typography data-cy={dataCy} variant="body2" sx={text}>
      {cell.value === null ? (
        <FormattedMessage id={intl.mainRecommendation('recommendation-credit-unknown')} />
      ) : (
        `${reactIntl.formatNumber(cell.value)} ${currency}`
      )}
    </Typography>
  )
}

export default AmountCell
