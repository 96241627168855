import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

export const classPortfolioScore: SxProps = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
}
