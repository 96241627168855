import { SxProps } from '@mui/system'
import { AddOnPositionType, AddonType } from './InputField.types'

export const inputStyles = (
  addOn: AddonType | undefined,
  addOnPosition: AddOnPositionType,
  disabled: boolean,
  extraStyles: SxProps
): SxProps => {
  const backgroundColor = disabled ? 'grey.50' : 'common.white'
  if (addOn && addOnPosition === 'start') {
    return {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      backgroundColor,
      ...extraStyles,
    }
  }
  if (addOn && addOnPosition === 'end') {
    return {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor,
      ...extraStyles,
    }
  }
  return { ...extraStyles, backgroundColor }
}

export default inputStyles
