import { Box, useMediaQuery, Divider } from '@mui/material'
import { useMonitoringStatistic } from 'services/queries'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ErrorOccurred from 'components-new/elements/ErrorOccurred'
import { classes } from './CreditPolicyViolation.styles'
import intl from 'localization/components'
import BoxContainerSmall from 'components-new/layouts/BoxContainerSmall'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import { useTheme } from '@mui/material/styles'
import { Monitor } from 'risika-api-response-types'

const CreditPolicyViolation = ({
  listId,
  handlePortfolioInsightsFilters,
}: {
  listId: string
  handlePortfolioInsightsFilters: (category: string, key: string | boolean) => void
}) => {
  let list = {} // Not me i just fixed the ts error
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const monitoringStatisticQuery =
    useMonitoringStatistic<Monitor.ListStatisticsCompliance>({
      parameters: { list_id: Number(listId) },
      endpoint: 'compliance',
      staleTime: 60000,
    })
  if (monitoringStatisticQuery?.data?.credit_policy_used === 'credit_automator') {
    return null
  }
  if (
    !monitoringStatisticQuery.isLoading &&
    !monitoringStatisticQuery.isIdle &&
    !monitoringStatisticQuery.isError
  ) {
    list = monitoringStatisticQuery.data.rejected_explanations
  }

  const boxData = {
    title: intl.portfolioInsights('credit-policy-violation'),
    status: {
      isLoading: monitoringStatisticQuery.isLoading,
      isError: monitoringStatisticQuery.isError,
      isIdle: monitoringStatisticQuery.isIdle,
    },
    height: 200,
    outlineBox: true,
    skeletonType: 'DEFAULT',
  }

  return (
    <Box sx={{ mt: 4 }}>
      <BoxContainerSmall boxData={boxData}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <Box sx={classes.CreditPolicyViolation.container}>
            {Object.keys(list).length ? (
              <>
                {Object.keys(list).map((key: string, index: number, elements) => {
                  const listItem: any = list[key as keyof typeof list] // When Typescript mess with you @ 23:59 then you do this. Refactor!
                  return (
                    <Box key={key}>
                      <Box
                        sx={classes.CreditPolicyViolation.grid}
                        onClick={() =>
                          handlePortfolioInsightsFilters(apiKeys.violations, key)
                        }
                      >
                        <Box sx={classes.CreditPolicyViolation.text}>
                          {listItem.title}
                        </Box>
                        <Box sx={classes.CreditPolicyViolation.wrapper}>
                          <Box sx={classes.CreditPolicyViolation.pill}>
                            <Box sx={classes.CreditPolicyViolation.number}>
                              {listItem.count}
                            </Box>
                          </Box>
                          {!isMobile && (
                            <ArrowForwardIosIcon
                              sx={classes.CreditPolicyViolation.arrow}
                              color="disabled"
                            />
                          )}
                        </Box>
                      </Box>
                      {index + 1 !== elements.length && (
                        <Divider
                          sx={classes.CreditPolicyViolation.divider}
                          variant="middle"
                          orientation="horizontal"
                          flexItem
                        />
                      )}
                    </Box>
                  )
                })}
              </>
            ) : (
              <ErrorOccurred />
            )}
          </Box>
        ) : null}
      </BoxContainerSmall>
    </Box>
  )
}

export default CreditPolicyViolation
