import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    padding: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  view: {
    maxWidth: 800,
    maxHeight: 400,
    overflowY: 'scroll',
  },
  chip: {
    display: 'flex',
    marginTop: '0.7rem',
    fontSize: '1.5rem',
  },
  smallButton: {
    padding: '6px 8px',
    fontSize: '14px',
    margin: '1rem',
    flexShrink: 0,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}))

export const chipStyles = {
  display: 'flex',
  marginTop: '0.7rem',
  fontSize: '1.5rem',
  backgroundColor: 'primary.main',
  color: 'common.white',
}
export const smallButtonStyles = {
  color: 'common.white',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '1px',
  padding: '6px 8px',
  height: 16,
  boxSizing: 'content-box',
}
