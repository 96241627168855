import { buttonFontStyles } from '../../inputs/Button/Button.styles'
import { theme } from 'styles-new/mui5-transition/theme'

export const styles = {
  boxContainer: {
    '& .single-country': {
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
    },
  },
  root: {
    flex: 'stretch',
    padding: 2,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    border: 'none',
    ' .MuiSelect-select': {
      padding: '4px !important',
      paddingRight: '12px !important',
      margin: 0,
      minHeight: 'auto',
      display: 'flex',
    },
    ' .MuiSvgIcon-root': {
      fill: `${theme.palette.grey[600]}`,
    },
  },
  menu: {
    left: '34px',
    padding: 0,
    '& .MuiMenu-list': { paddingY: 2 },
    '& .MuiMenu-paper': { padding: 0, border: 'none' },
  },
  menuItem: {
    '&.Mui-selected': { backgroundColor: 'grey.200' },
    '&:hover': { backgroundColor: 'grey.100' },
    paddingX: '20px',
  },
  flagRoot: {
    ...buttonFontStyles,
  },
  image: {
    height: '20px',
    borderRadius: 2,
  },
  open: {
    display: '',
    marginLeft: 1,
    ...buttonFontStyles,
  },
  closed: {
    display: 'none',
  },
}
