import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

async function registerCompanyVisit(localId: LocalId): Promise<void> {
  return risikaAPI.post(
    '/statistics/company_visits/register',
    { local_organization_id: localId },
    {
      microservice: 'data',
    }
  )
}

export default registerCompanyVisit
