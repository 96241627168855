import { Box, Paper, Typography, Divider } from '@mui/material/'
import LoadingScene from './scenes/LoadingScene'
import ErrorScene from './scenes/ErrorScene'

import { FormattedMessage } from 'react-intl'
import { BoxContainerPropsType } from './types'
import { classes } from './styles'
import NoDataScene from './scenes/NoDataScene'

const BoxContainer = ({ boxData, children }: BoxContainerPropsType) => {
  let content = children
  if (boxData.status.isNoData) {
    content = <NoDataScene height={boxData.height} />
  }
  if (boxData.status.isError) {
    content = <ErrorScene height={boxData.height} />
  }
  if (boxData.status.isLoading || boxData.status.isIdle) {
    content = (
      <Box m={4}>
        <LoadingScene height={boxData.height} skeletonType={boxData.skeletonType} />
      </Box>
    )
  }

  return (
    <Paper sx={classes.boxContainer}>
      <Box m={4} justifyContent="space-between" alignItems="center" display="flex">
        <Typography variant="h4">
          <FormattedMessage id={boxData.title} />
        </Typography>
        {boxData.headerElementRight ? boxData.headerElementRight : null}
      </Box>
      <Divider orientation="horizontal" />
      {content}
    </Paper>
  )
}

export default BoxContainer
