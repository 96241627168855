import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { Address } from 'types/general'

/******************
 * Fetch from API *
 ******************/

type ProductionUnit = {
  address: Address
  email: string
  employees_interval: string
  fte_interval: string
  industry_code: string
  industry_description: string
  local_organization_id: string
  name: string
  phone_number: string
  unit_id: string
  valid_from: string
  valid_to?: string
}

type ResponseData = {
  data: ProductionUnit[]
}

async function getProductionUnits(
  id: LocalId['id'],
  country: LocalId['country']
): Promise<ProductionUnit[]> {
  const { data }: ResponseData = await risikaAPI.get(
    `/company/production_unit?local_id=${id}`,
    {
      microservice: 'data',
      country,
    }
  )
  return data
}

export default getProductionUnits
