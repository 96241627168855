/**
 * Split an array into chunks of a certain size.
 *
 * @param chunkSize The amount of items per chunk.
 * @param arr The array to split into chunks.
 *
 * @example
 * chunk(3, [1, 2, 3, 4, 5, 6, 7, 8]) => [[1, 2, 3], [4, 5, 6], [7, 8]]
 */
export default function chunk(chunkSize, arr) {
  if (arr.length === 0) {
    return []
  } else if (arr.length <= chunkSize) {
    return [arr]
  }

  return [arr.slice(0, chunkSize)].concat(
    chunk(chunkSize, arr.slice(chunkSize, arr.length))
  )
}
