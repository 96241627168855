import { paginate } from 'services/helpers/paginate'
import risikaAPI from 'services/api/helpers/risikaAPI'

const PAGE_SIZE = 1000

function formatNotes(notes) {
  return notes.map(({ data, local_organization_id }) => ({
    data,
    localId: local_organization_id,
  }))
}

function fetchNotesList({ from = 0, to = PAGE_SIZE } = {}) {
  return risikaAPI
    .post(
      `/notes/list`,
      {
        rows: {
          from,
          to,
        },
      },
      {
        microservice: 'account',
      }
    )
    .then(({ data }) => {
      return {
        data: formatNotes(data.notes),
        count: data.count,
        rows: data.rows,
      }
    })
}

export default async function notesList() {
  const initialCall = await fetchNotesList({ from: 0, to: PAGE_SIZE })

  const pages = paginate(PAGE_SIZE, initialCall.count, { startPage: 1 })

  if (pages.length === 0) {
    return initialCall
  }

  const promises = pages.map((pageRange) => fetchNotesList(pageRange))

  return Promise.all(promises).then((promises) => {
    let result = initialCall.data

    promises?.forEach((promise) => {
      result = result.concat(promise.data)
    })

    return { data: result, count: initialCall.count }
  })
}
