import React from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Stack, Typography, Tabs, Tab, Box, useMediaQuery } from '@mui/material'
import UserInformation from './scenes/UserInformation'
import CompanyInformation from './scenes/CompanyInformation'
import UserManagement from './scenes/UserManagement/UserManagement'
import { ErrorBoundary, HistoryHeader } from 'components'
import { submitGetCompanyInformation, submitFetchPolicyValues } from 'store_deprecated'

import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import styles from './styles/settings-page'
import { useAllUsers, useCustomerData, useUserSettingsField } from 'services/queries'
import CreditOverride from './scenes/CreditOverride'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      style={{ display: 'flex' }}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ display: 'flex', flex: 1 }}>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const SettingsPage = () => {
  const { messages } = useIntl()
  const id = useParams()?.id
  const { company, user } = useSelector((state) => state.auth)
  const { data: creditCheckSidebar, isLoading: creditCheckSidebarLoading } =
    useUserSettingsField('credit_check_sidebar')
  const roles = user?.roles
  const dispatch = useDispatch()
  const { model } = useSelector((state) => state.auth.company)
  const modelObject = useSelector((state) => state.auth.company?.modelObject)

  const isMediumUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const {
    data: allUsers,
    isLoading: isAllUsersLoading,
    isError: isAllUsersError,
  } = useAllUsers()
  const { data: customerData, isLoading: isCustomerDataLoading } = useCustomerData()
  const isLoading =
    isCustomerDataLoading ?? isAllUsersLoading ?? creditCheckSidebarLoading
  const tabsData = [
    {
      text: 'general-information',
      panelContent: !isLoading ? (
        <Stack
          direction={isMediumUp ? 'row' : 'column'}
          spacing={isMediumUp ? 6 : 8}
          flex="1"
          p={5}
          mt={4}
          bgcolor="common.white"
        >
          <UserInformation />
          <CompanyInformation
            basicData={customerData}
            isLoading={isCustomerDataLoading}
          />
        </Stack>
      ) : (
        <>Loading</>
      ),
      enabled: true,
    },
    {
      text: 'user-management',
      panelContent: (
        <UserManagement
          users={allUsers}
          isLoading={isLoading}
          isError={isAllUsersError}
        />
      ),
      enabled: roles.includes('admin') || roles.includes('staff'),
    },
    {
      text: 'credit-override',
      panelContent: !isLoading ? <CreditOverride users={allUsers} /> : <>Loading</>,
      enabled: creditCheckSidebar?.find((setting) => setting.key === 'credit-override')
        ?.visible
        ? roles.includes('admin') || roles.includes('staff') // Staff role does not exist
        : false,
    },
  ]

  let matchIndex = 0
  tabsData.forEach((tab, index) => {
    if (tab.text === id) {
      matchIndex = index
    }
  })

  const [value, setValue] = React.useState(matchIndex)
  React.useEffect(() => {
    dispatch(submitFetchPolicyValues('dk', modelObject?.DK ?? model))
    if (!company) {
      dispatch(submitGetCompanyInformation(user.data.id))
    }
  }, [dispatch, user.data.id, company, model, modelObject])

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  return (
    <ErrorBoundary page="settings">
      <Stack
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          height: 'calc(100VH - 130px)',
          backgroundColor: 'grey.50',
        }}
        direction="column"
      >
        <HistoryHeader
          title={messages[intl.settings('title')]}
          historyKey={`settings`}
          location={'settings'}
        />
        <Tabs
          sx={{ ...styles.tabs(value) }}
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant="scrollable"
        >
          {tabsData.map((tab, i) => (
            <Tab
              to={`/settings/${tab.text}`}
              disabled={!tab.enabled}
              key={(tab.name, i)}
              sx={styles.tab}
              selected={location.pathname === `/settings/${tab.text}`}
              label={
                <Typography
                  variant="subtitle2"
                  sx={{ color: tab.enabled ? 'grey.800' : 'grey.600' }}
                >
                  <FormattedMessage id={intl.settings(tab.text)} />
                </Typography>
              }
              component={RouterLink}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
        <Box
          bgcolor="common.white"
          p={0}
          overflow="hidden"
          height="-webkit-fill-available"
        >
          {tabsData.map((tabPanel, i) => (
            <TabPanel
              sx={
                i === value
                  ? {
                      display: 'flex',
                      flex: 1,
                      overflow: 'scroll',
                      height: '100%',
                      padding: 0,
                    }
                  : { display: 'none' }
              }
              key={i}
              value={value}
              index={i}
            >
              {tabPanel.panelContent}
            </TabPanel>
          ))}
        </Box>
      </Stack>
    </ErrorBoundary>
  )
}

export default SettingsPage
