import callBackendAPI from 'services/api/helpers/backendAPI'
import { UseUserSelfResponse } from 'types/queries'

const userSelf = (): Promise<UseUserSelfResponse> =>
  callBackendAPI('/user')
    .then(({ data }) => {
      return data
    })
    .catch((err) => {
      return err
    })

export default userSelf
