import { useState, useEffect } from 'react'
import SideDrawer from 'components-new/layouts/SideDrawer'
import { Stack, Divider } from '@mui/material'
import styles from '../SidePanels.styles'
import {
  ButtonSecondary,
  ButtonTertiary,
  DropdownSelect,
  InputArea,
  InputField,
} from 'components-new'
import { selectCountriesOptions } from '../CreatePolicySidePanel/CreatePolicySidePanel.model'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useHistory } from 'react-router-dom'
import { useCreditPolicyCreate } from 'services/queries'
import { ImportPolicySidePanelProps } from './ImportPolicySidePanel.types'
import { navigation } from 'services/navigation'
import SidePanelHeader from '../../PolicyBuilder/widgets/ActionPanel/widgets/SidePanelHeader'

const { container, inputContainer } = styles

const ImportPolicySidePanel = ({
  isOpen,
  setIsOpen,
  importedPolicy,
}: ImportPolicySidePanelProps) => {
  const [name, setName] = useState('')
  const [country, setCountry] = useState('')
  const [description, setDescription] = useState('')
  const [getStartedButtonEnabled, setGetStartedButtonEnabled] = useState(false)
  const history = useHistory()
  const { mutate, data, isSuccess } = useCreditPolicyCreate()

  useEffect(() => {
    setGetStartedButtonEnabled(Boolean(name && country))
  }, [name, country])

  useEffect(() => {
    if (importedPolicy) {
      setName(importedPolicy.name + ' ' + '(imported)')
      setCountry(importedPolicy.country)
      setDescription(importedPolicy.description || '')
    }
  }, [importedPolicy])

  const handleClose = () => setIsOpen(false)

  const handleGetStarted = () => {
    mutate({
      name,
      description,
      country,
      enabled: false,
      policy: importedPolicy?.policy!,
    })
  }

  if (data && isSuccess) {
    if (data.policy_id) {
      history.push(
        navigation.creditPolicyBuilder({
          country,
          description,
          name,
          createActiveRule: false,
          policyId: data.policy_id,
        })
      )
    }
  }

  return (
    <SideDrawer
      drawerState={{
        drawerIsOpen: isOpen,
        setDrawerIsOpen: setIsOpen,
      }}
      side="right"
    >
      <Stack data-cy="import-policy-panel" sx={container}>
        <SidePanelHeader
          title={<FormattedMessage id={intl.creditPolicyNew('create-a-policy-button')} />}
          onClose={handleClose}
        />
        <Divider />
        <Stack sx={inputContainer}>
          <InputField
            data-cy="import-policy-name"
            value={name}
            handleChange={setName}
            label={<FormattedMessage id={intl.generic('name')} />}
          />
          <DropdownSelect
            data-cy="import-policy-country"
            disabled={Boolean(country)}
            options={selectCountriesOptions}
            value={country}
            setValue={setCountry}
            inputProps={{ label: <FormattedMessage id={intl.generic('country')} /> }}
          />
          <InputArea
            data-cy="import-policy-description"
            maxCharacters={300}
            value={description}
            setValue={setDescription}
            label={<FormattedMessage id={intl.creditPolicyNew('description-text')} />}
          />
        </Stack>
        <Stack
          direction="row"
          paddingY={4}
          paddingX={5}
          justifyContent="space-between"
          sx={{ marginTop: 'auto', backgroundColor: 'grey.50' }}
        >
          <ButtonTertiary
            data-cy="import-policy-cancel-button"
            onClick={() => setIsOpen(false)}
          >
            <FormattedMessage id={intl.creditPolicyNew('cancel')} />
          </ButtonTertiary>
          <ButtonSecondary
            data-cy="import-policy-get-started-button"
            disabled={!getStartedButtonEnabled}
            onClick={handleGetStarted}
          >
            <FormattedMessage id={intl.creditPolicyNew('get-started')} />
          </ButtonSecondary>
        </Stack>
      </Stack>
    </SideDrawer>
  )
}

export default ImportPolicySidePanel
