import { Stack, Box, Typography } from '@mui/material'
import styles from './DetailStep.styles'
import { VariantPolicy } from './CreditPolicy.types'
import { StepModels } from './EnterpriseCreditPolicy.model'

type Props = {
  title: string
  desc: string
  value: string
  variant: VariantPolicy
}

const DetailStep = ({ title, desc, value, variant }: Props) => {
  const { border, canvas, conclusionBg } = StepModels.find(
    (model) => model.variant === variant
  )!

  return (
    <Stack
      sx={styles.main(border, canvas)}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
      data-cy="policy-detailed-step"
    >
      <Box>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="body2">{desc}</Typography>
      </Box>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={styles.conclusion(conclusionBg)}
      >
        <Typography variant="body2">{value}</Typography>
      </Stack>
      {variant === 'neutral' ? <Box sx={styles.connectionLine} /> : null}
    </Stack>
  )
}

export default DetailStep
