import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  iconButton: {
    minWidth: '19.5rem',
    '@media screen and (max-width: 600px)': {
      width: '20rem',
    },
    color: 'white',
    fontSize: '2rem',
    border: '1px solid white',
    borderRadius: '3rem',
    marginBottom: '1rem',
  },
  text: {
    color: 'white',
    marginLeft: '0.5rem',
  },
}))
