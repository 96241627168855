import axios, { AxiosRequestConfig } from 'axios'
import { fetchAuthAccessToken } from './authService'
import { BACKEND_API_BASE_URL } from './config'

export const BackendApiAxiosInstance = axios

/**
 *
 * @param {string} endpoint The endpoint to call.
 * @param {*} request An optional request object.
 */
const callBackendAPI = async (endpoint: string, request?: AxiosRequestConfig) => {
  const method = request?.method || 'get'
  const data = request?.data
  const headers = request?.headers
  const url = `${BACKEND_API_BASE_URL}${endpoint}`

  const accessToken = await fetchAuthAccessToken()

  return axios({
    url,
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      ...headers,
    },
    data,
  })
}

export default callBackendAPI
