import callBackendAPI from 'services/api/helpers/backendAPI'
import { Role } from 'types/general'

/******************
 * Fetch from API *
 ******************/

export type CreateNewUserData = {
  name: string
  email: string
  phone: string
  role: Role
}

const createNewUser = ({ name, email, phone, role }: CreateNewUserData) => {
  return callBackendAPI('/user', {
    method: 'post',
    data: {
      name,
      email,
      phone_number: phone,
      role,
    },
  }).then(({ data }) => {
    return data
  })
}

export default createNewUser
