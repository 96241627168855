import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { MONITOR_LIST } from './constants'
import updateMonitorLists from 'services/api/endpoints/risikaAPI/newMonitoring/updateMonitorLists'
import { chunkArray } from 'utils'

type UpdateListsParams = {
  list_id: number
  local_ids: { country: string; id: string }[]
}

type UpdateListsMutationParams = {
  onSuccess?: (data?: any) => void
  onError?: (error: AxiosError, data?: any) => void
}

const CHUNK_SIZE = 500

const updateLists = async ({ list_id, local_ids }: UpdateListsParams) => {
  const chunkAndSend = async (array: { country: string; id: string }[]) => {
    const chunkData = chunkArray(array, CHUNK_SIZE)

    const data = {
      list_id: list_id,
      subscribe: chunkData.firstChunk.map(({ id, country, hash }: any) => ({
        local_organization_id: {
          id: hash?.toString() ?? id?.toString(),
          country,
        },
      })),
    }
    await updateMonitorLists(data)

    if (chunkData.remainingChunk.length) {
      await chunkAndSend(chunkData.remainingChunk)
    }
  }
  await chunkAndSend(local_ids)
}

const useUpdateLists = ({ onSuccess, onError }: UpdateListsMutationParams) => {
  const queryClient = useQueryClient()
  const mutation = useMutation((params: any) => updateLists(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(MONITOR_LIST)
      onSuccess?.()
    },
    onError: (error: AxiosError, params) => {
      onError?.(error, params)
    },
  })
  return { updateLists: mutation.mutate, ...mutation }
}

export { useUpdateLists }
