import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { IndustryCodeCategory } from 'types/queries'
import { useIndustryList } from 'services/queries'
import { parseIndustryTree } from 'pages/advanced-filters/scenes/NestedSelect/NestedIndustrySelect'
import { useParams } from 'react-router-dom'

type IndustryTypeContentPropsType = {
  data: IndustryCodeCategory
}
type ParsedIndustryTree = {
  [id: string]: {
    id: string
    label: string
    value: string
    count: number
    level: number
    state: 'checked' | 'unchecked'
    isRoot: boolean
    isLeaf: boolean
    children: string[]
    title: string | undefined
    parent: string | null
  }
}

const LIMIT = 5
function IndustryTypeContent({ data }: IndustryTypeContentPropsType) {
  const { country } = useParams<{ country: 'DK' | 'SE' | 'NO' }>()
  const { data: industryData, isLoading } = useIndustryList(
    country.toLowerCase() as 'dk' | 'se' | 'no'
  )

  if (isLoading || !data) {
    return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }

  const parsedData = parseIndustryTree(industryData) as ParsedIndustryTree

  const { category, include } = data

  try {
    const industryTypeList = include
      ?.map((item) => parsedData[item])
      ?.filter((item) => item.isLeaf)
      ?.map((item) => item.label ?? '')
    if (industryTypeList && industryTypeList.length > 0) {
      const sliced = `${industryTypeList.slice(0, LIMIT).join(', ')}${
        industryTypeList.length > LIMIT ? '...' : ''
      }`
      return (
        <FormattedMessage
          id={intl.creditPolicyNew(`${category}_node_content`)}
          values={{ industryList: sliced }}
        />
      )
    }
  } catch (err) {
    return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }

  return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
}

export default IndustryTypeContent
