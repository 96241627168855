import { Stack, Typography } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { useQueryClient } from 'react-query'
import { AccountStatusResponse } from 'services/api/endpoints/risikaAPI/accountStatus'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import badgeStyles from './Badge.styles'

const { container, textStyles } = badgeStyles

function Badge() {
  const queryClient = useQueryClient()
  const accountStatus: AccountStatusResponse = queryClient.getQueryData('accountStatus')!
  const disabled = accountStatus?.rights.allow_credit_policy.length < 10

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ ...container(disabled) }}
    >
      {disabled && <StarIcon fontSize="inherit" color="inherit" />}
      <Typography
        variant="caption"
        color={!disabled ? 'grey.600' : 'secondary.700'}
        sx={{ ...textStyles(disabled) }}
      >
        {<FormattedMessage id={intl.creditPolicyNew('advanced')} />}
      </Typography>
    </Stack>
  )
}

export default Badge
