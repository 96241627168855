import { theme } from 'styles-new/mui5-transition/theme'

const title = {
  mb: theme.spacing(4),
}

const listsAccordion = {
  bgcolor: theme.palette.grey[50],
}

const preferencesTitle = {
  mb: theme.spacing(5),
}
export const classes = { title, listsAccordion, preferencesTitle }
