import { Box, Stack, Typography } from '@mui/material'
import ButtonPrimary from 'components-new/inputs/Button/ButtonPrimary'
import Show from 'components/Show'
import { useHistory } from 'react-router-dom'
import { ReactElement } from 'react'

type ErrorPageProps = {
  errorCode?: number
  errorMessage: string | ReactElement
  buttonText?: string | ReactElement
  redirectLocation?: string
}
// TODO: Write documentation and link it here
function ErrorPage({
  errorCode,
  errorMessage,
  buttonText,
  redirectLocation,
}: ErrorPageProps) {
  const history = useHistory()
  function handleClick() {
    history.push(redirectLocation ?? '/home')
  }

  return (
    <Box display="flex" height={1} width={1} justifyContent="center" alignItems="center">
      <Stack sx={{ maxWidth: '40%' }} spacing={5}>
        <Stack spacing={5} direction="row">
          <Typography fontWeight="bold" variant="h2">
            {errorCode}
          </Typography>
          <img
            src="https://dashboard.risika.dk/risika/logo-black.svg"
            alt="Risika Logo"
          />
        </Stack>
        <Typography variant="h4">{errorMessage}</Typography>
        <Show when={!!buttonText}>
          <ButtonPrimary sx={{ alignSelf: 'start' }} onClick={handleClick}>
            {buttonText}
          </ButtonPrimary>
        </Show>
      </Stack>
    </Box>
  )
}

export default ErrorPage
