import { AvailableCountries } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { Mixpanel } from 'services/helpers/mixpanel'

export type PersonSearchResult = {
  personal_id: string | number
  country: string
  name: string
  aliases?: string[]
  functions: {
    active: boolean
    function: string
    title: string
  }[]
  active_company_relations: string[]
}

export type PersonSearch = {
  search_result: PersonSearchResult[]
  count: number
}

type ResponseData = {
  data: PersonSearch
}

type Props = {
  searchText: string
  country?: AvailableCountries
}

async function searchPerson({ searchText, country }: Props): Promise<PersonSearch> {
  const { data }: ResponseData = await risikaAPI.post(
    '/search/person',
    {
      query: searchText,
      country,
    },
    {
      microservice: 'data',
      country: country || 'dk',
      appendScoringModel: true,
    }
  )

  Mixpanel.track('User searched', { type: 'person', query: searchText, country })
  return data
}

export default searchPerson
