import { useQuery } from 'react-query'
import { companyHierarchyWalkdown } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useCompanyHierarchyWalkdown(loadInfo: LocalId & { maxDepth: number }) {
  return useQuery(
    ['companyHierarchyWalkdown', loadInfo.id, loadInfo.country, loadInfo.maxDepth],
    () => companyHierarchyWalkdown(loadInfo),
    {
      enabled: !!isCallAllowed('companyHierarchyWalkdown', loadInfo.country!),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
