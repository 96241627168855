import { colors } from 'configs'
import { defaultSimpleLineChartProperties } from 'configs/charts'
import intl from 'localization/components'

export const developmentInKeyFiguresData = {
  endPoint: 'timeline',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [
      { category: 'CURRENT_RATIO', function: 'AVERAGE' },
      { category: 'RETURN_ON_ASSETS', function: 'AVERAGE' },
      { category: 'SOLIDITY_RATIO', function: 'AVERAGE' },
    ],
    sample_type: 'END_OF_MONTH_INCOMPLETE',
    historic_monitor: true,
  },
  chartProperties: {
    ...defaultSimpleLineChartProperties,
    name: 'Development in selected key figures',
    colors: {
      strokes: [
        colors.risikaTertiaryDark,
        colors.risikaTertiaryDim,
        colors.risikaTertiary,
        colors.risikaTertiaryLight,
        colors.risikaBlueDark,
        colors.risikaBlue,
        colors.risikaBlueLight,
      ],
    },
    title: intl.observationalLists('development-selected-key-figures-title'),
    height: 390,
  },
}

export type DevelopmentInKeyFiguresData = typeof developmentInKeyFiguresData
// this chart needs to call the same endpoint two times
// because the score information is stored in different database
export const developmentInKeyFiguresScoreData = {
  ...developmentInKeyFiguresData,
  parameters: {
    ...developmentInKeyFiguresData.parameters,
    categories: [{ category: 'SCORE', function: 'AVERAGE' }],
  },
}
