import { SxProps } from '@mui/system'

const containerMain: SxProps = {
  fontSize: 21,
  color: 'primary.500',
  marginTop: 2,
  backgroundColor: 'info.100',
  borderRadius: 1,
  border: '1px solid',
  borderColor: 'info.dark',
}

const containerTitle: SxProps = {
  paddingX: 2,
  paddingY: 2,
  gap: 2,
  color: 'info.dark',
  alignContent: 'center',
  justifyContent: 'start',
  borderBottom: '1px solid',
  borderColor: 'info.dark',
}

const containerContent: SxProps = {
  color: 'primary.main',
  paddingX: 4,
  paddingY: 2,
}

export default { containerMain, containerTitle, containerContent }
