import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const errorMsg: SxProps = {
  color: theme.palette.error.main,
}

export default {
  errorMsg,
}
