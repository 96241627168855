import { Stack, Typography } from '@mui/material'
import { padding } from './columns.styles'
import { PeopleVisitResult } from 'services/api/endpoints/risikaAPI/peopleVisitsList'
import { useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'

type Props = {
  item: PeopleVisitResult
}

function Name({ item }: Props) {
  const history = useHistory()
  return (
    <Stack
      onClick={() => history.push(navigation.relationOverview(item.personal_id))}
      sx={{ ...padding, cursor: 'pointer', minWidth: 250 }}
    >
      <Typography color="grey.800" variant="subtitle1">
        {item.name}
      </Typography>
    </Stack>
  )
}

export default Name
