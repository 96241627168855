import {
  ADJUST_CREDIT_MAX,
  APPROVE_CREDIT_MAX,
  APPROVE_WITH_VALUE,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import * as React from 'react'
import { Limit, Method, Values } from './DetailedComponentView.types'

export const useApproveCreditValidator = ({
  method,
  values,
  limit,
}: {
  method: Method
  values: Values
  limit: Limit
}) => {
  return React.useMemo(() => {
    if (method === APPROVE_CREDIT_MAX) {
      return true
    }
    if (method === APPROVE_WITH_VALUE) {
      const { value } = values
      if (value === null) {
        return null
      }
      return true
    }
    if (method === ADJUST_CREDIT_MAX) {
      const { percentage, lower_limit, upper_limit } = values
      if (percentage === null) {
        return null
      }
      if (limit === 'not_lower_than' && lower_limit === null) {
        return null
      }
      if (limit === 'not_greater_than' && upper_limit === null) {
        return null
      }
      return true
    }
    return null
  }, [limit, method, values])
}
