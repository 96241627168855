import { ErrorPage } from 'components-new'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { navigation } from 'services/navigation'

function NoAccessToFeature() {
  return (
    <ErrorPage
      errorCode={403}
      errorMessage={
        <FormattedMessage
          id={intl.creditPolicyNew('only-admin-users-can-see-this-feature')}
        />
      }
      buttonText={<FormattedMessage id={intl.creditPolicyNew('back-to-credit-policy')} />}
      redirectLocation={navigation.enterpriseCreditPolicy()}
    />
  )
}

export default NoAccessToFeature
