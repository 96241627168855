import { authRoles } from 'components/auth'
import { navigation } from 'services/navigation'
import HomePage from './HomePage'

export const HomePageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: navigation.home(),
      component: HomePage,
    },
  ],
}
