import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Link, Breadcrumbs } from '@mui/material'
import { classBreadcrumbsIcon, classBreadcrumbsText } from 'styles-new/global/components'

const BreadCrumbs = ({
  breadcrumbs,
  handleBreadcrumb,
}: {
  breadcrumbs: any
  handleBreadcrumb: (crumbLevel: number) => void
}) => {
  if (breadcrumbs.length === 1) return <></>
  return (
    <Breadcrumbs separator="›" sx={{ mt: 2, ml: 2 }}>
      {breadcrumbs.map((crumbItem: any, index: number) => {
        return (
          <Box key={crumbItem.label}>
            <Link
              underline="hover"
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: index > 0 ? '' : 'pointer',
              }}
              onClick={() => handleBreadcrumb(crumbItem.level)}
            >
              {crumbItem.level === 1 ? (
                <MoreHorizIcon sx={classBreadcrumbsIcon} fontSize="large" />
              ) : (
                <Box sx={classBreadcrumbsText}>{crumbItem.groupName}</Box>
              )}
            </Link>
          </Box>
        )
      })}
    </Breadcrumbs>
  )
}

export default BreadCrumbs
