import { Box, Stack, Skeleton, Typography } from '@mui/material'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'

import intl from 'localization/components'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { InfoBoxPropsType } from './InfoBox.types'
import { classes } from './InfoBox.styles'

const InfoBox = ({ title, value, displayProps, error }: InfoBoxPropsType) => {
  const currency = displayProps?.currency || 'DKK'

  if (error) {
    return (
      <Stack sx={classes.infoBox} alignItems="center">
        <SentimentVeryDissatisfiedIcon sx={{ fontSize: '4rem' }} />
        <Typography variant="h6">
          <FormattedMessage id={intl.snackbar('account-activated-failed')} />
        </Typography>
      </Stack>
    )
  }

  return (
    <Box sx={classes.infoBox}>
      <Typography variant="h6" sx={classes.titleStyles}>
        {title !== '' ? <FormattedMessage id={title} /> : <Skeleton width="95%" />}
      </Typography>
      <Typography variant="h3" sx={classes.valueStyles}>
        {value ? (
          <FormattedNumber
            value={value}
            style={displayProps.style}
            currency={currency}
            maximumSignificantDigits={15}
          />
        ) : (
          <Skeleton width="95%" />
        )}
      </Typography>
    </Box>
  )
}

export default InfoBox
