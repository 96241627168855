import { riskIntelligenceApiKeys as apiKeys } from '../../../../configs/constants/api'
import numeral from 'numeral'
import lodash from 'lodash'

export const formatData = (data: any, columnsDefinition: any) => {
  return data.map((item: any) => {
    return columnsDefinition.reduce((accumulator: any, column: any) => {
      const currency = item.financial_key_figures.currency
      let value = item[column.key] ?? '*'

      if (column?.sub_key) {
        value = item?.[column.sub_key]?.[column.key] ?? '*'
      }

      if (typeof value === 'boolean') {
        value = value ? apiKeys.compliant : apiKeys.nonCompliant
      }

      if (column.type === 'number_currency') {
        const formattedValue = numeral(value).format('0,0')
        value = `${formattedValue} ${currency}`
      }

      if (column.key === apiKeys.employeesNumber) {
        value = numeral(value).format('0,0')
        accumulator.employment_trend = item.employment_trend
      } else if (column.key === apiKeys.profitLoss) {
        accumulator.profit_loss_trend = item.profit_loss_trend
      } else if (column.key === apiKeys.equity) {
        accumulator.equity_trend = item.equity_trend
      }

      accumulator[column.key] = value

      return accumulator
    }, {})
  })
}

export const calculateActiveFilters = (tableFilterData: any) => {
  const tableFilterClone = lodash.cloneDeep(tableFilterData)
  // Evaluate all dropdown filters
  const newFilter = Object.keys(tableFilterClone).reduce(
    (accumulator: any, category: string) => {
      const filterArray = Object.keys(tableFilterClone[category])
        .filter((categoryKey: string) => tableFilterClone[category][categoryKey].checked)
        .map((categoryKey: string) => {
          if (categoryKey === apiKeys.compliant) return true
          if (categoryKey === apiKeys.nonCompliant) return false
          if (category === apiKeys.highlights) {
            return {
              category: tableFilterClone[category][categoryKey].category,
              value: tableFilterClone[category][categoryKey].value,
            }
          }
          return categoryKey
        })
      if (filterArray.length > 0) {
        accumulator[category] = filterArray
      }
      return accumulator
    },
    {}
  )
  // Evaluate free search filter
  const searchTerm = tableFilterClone[apiKeys.freeSearch] ?? ''
  if (searchTerm.length > 0) newFilter[apiKeys.freeSearch] = searchTerm
  return newFilter
}
