import { useQuery } from 'react-query'
import companyVisitsList, {
  CompanyVisitsListParams,
} from 'services/api/endpoints/risikaAPI/companyVisitsList'

export function useCompanyVisitsList(data: CompanyVisitsListParams) {
  return useQuery(
    ['companyVisitsList', Object.values(data)],
    () => companyVisitsList(data),
    {
      refetchOnWindowFocus: false,
    }
  )
}
