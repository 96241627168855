import { makeStyles } from '@mui/styles'
import { colors } from 'configs'
export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  linearColorPrimary: {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  linearBarColorPrimary: {
    backgroundColor: colors?.risikaContrast,
  },
})
