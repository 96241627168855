import { Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

const BigNumbersSkeleton = ({ height }: { height: number }) => (
  <Box p={2}>
    <Box
      height={100}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Skeleton variant="circular" width={60} height={60} sx={{ mr: 6 }} />
      <Skeleton variant="rectangular" width={60} height={60} />
    </Box>
  </Box>
)
export default BigNumbersSkeleton
