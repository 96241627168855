import React, { useEffect, useImperativeHandle } from 'react'
import { Paper, Stack } from '@mui/material'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import {
  approveCredit,
  approveDropdownMethod,
  NOT_LOWER_THAN,
} from '../DetailedComponentView.model'
import { DropdownSelect, InputField } from 'components-new'
import { ApproveCreditProps } from './Actions.types'
import {
  APPROVE_CREDIT_MAX,
  APPROVE_WITH_VALUE,
  ADJUST_CREDIT_MAX,
  MANUAL_CHECK,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import { Show } from 'components'
import { useApproveCreditValidator } from '../DetailedComponentView.hooks'
import { Limit, Method, Values } from '../DetailedComponentView.types'
import {
  getDefaultLimit,
  getDefaultMethod,
  getDefaultValues,
} from '../DetailedComponentView.controller'
import { PolicyBuilderParams } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'
import { useBuilderParams } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.hooks'
import { CREDIT_POLICY_CURRENCIES } from 'globalVariables'

const {
  creditDefinitionPercentageOfRisikaScore,
  creditDefinitionSetLimit,
  creditDefinitionValue,
} = approveCredit
const ApproveCredit = (
  { setIsConfirmEnabled, apiData }: ApproveCreditProps,
  ref: React.ForwardedRef<SubmitRef>
) => {
  const params = useBuilderParams() as PolicyBuilderParams
  const country = params.country
  const { state, actions } = useBuilder()
  // const { data: currency } = useUserSettingsField<CurrencyObj>('selected_currency')
  const currency = CREDIT_POLICY_CURRENCIES[country]
  const [method, setMethod] = React.useState<Method>(getDefaultMethod(apiData))
  const [values, setValues] = React.useState<Values>(getDefaultValues(apiData))
  const [limit, setLimit] = React.useState<Limit>(getDefaultLimit(apiData))

  React.useEffect(() => {
    setValues(getDefaultValues(apiData))
  }, [apiData])
  const validator = useApproveCreditValidator({ method, values, limit })

  useEffect(
    () => setIsConfirmEnabled(Boolean(validator)),
    [setIsConfirmEnabled, method, values, limit, validator]
  )

  // This works as validator at the same time
  const pullValuesFromState = React.useMemo(() => {
    if (method === APPROVE_CREDIT_MAX) {
      return {}
    }
    if (method === APPROVE_WITH_VALUE) {
      return { value: values.value }
    }
    if (method === ADJUST_CREDIT_MAX) {
      return {
        percentage: values.percentage,
        lower_limit: values.lower_limit,
        upper_limit: values.upper_limit,
      }
    }
  }, [method, values])

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.setNodeApiData({
          nodeId: state.actionPanelData.nodeId,
          data: {
            outcome: method as typeof MANUAL_CHECK,
            ...pullValuesFromState,
          },
        })
      },
    }),
    [actions, method, pullValuesFromState, state.actionPanelData.nodeId]
  )

  const handleChange = (key: string, value: number) => {
    setValues((prevState) => ({ ...prevState, [key]: Math.abs(value) }))
  }

  const updateLimit = (val: Limit) => {
    setLimit(val)
    setValues((prevState) => ({ ...prevState, lower_limit: null, upper_limit: null }))
  }

  return (
    <Stack gap={6} overflow="scroll">
      <DropdownSelect
        label={approveDropdownMethod.title}
        options={approveDropdownMethod.options}
        value={method}
        setValue={(val: Method) => setMethod(val)}
      />
      <Show when={method === APPROVE_WITH_VALUE}>
        <InputField
          inputMode="numeric"
          value={`${values.value}`}
          label={creditDefinitionValue.title}
          addOn={currency}
          handleChange={(val) => handleChange('value', Number(val))}
        />
      </Show>
      <Show when={method === ADJUST_CREDIT_MAX}>
        <Paper elevation={0}>
          <Stack p={3} sx={{ backgroundColor: 'grey.100' }} direction="column" gap={4}>
            <InputField
              inputMode="numeric"
              // value={approveCreditState.percentageOfRisikaScore}
              value={`${values.percentage}`}
              label={creditDefinitionPercentageOfRisikaScore.title}
              addOn="%"
              addOnPosition="end"
              handleChange={(val) => handleChange('percentage', Number(val))}
            />
            <DropdownSelect
              label={creditDefinitionSetLimit.title}
              options={creditDefinitionSetLimit.options}
              value={`${limit}`}
              setValue={(val: string) => updateLimit(val as Limit)}
            />
            <Stack p={3} sx={{ backgroundColor: 'grey.100' }}>
              <InputField
                inputMode="numeric"
                // value={approveCreditState.percentageOfRisikaScoreValue}
                value={
                  limit === NOT_LOWER_THAN
                    ? `${values.lower_limit}`
                    : `${values.upper_limit}`
                }
                label={creditDefinitionValue.title}
                addOn={currency}
                handleChange={(val) =>
                  handleChange(
                    limit === NOT_LOWER_THAN ? 'lower_limit' : 'upper_limit',
                    Number(val)
                  )
                }
              />
            </Stack>
          </Stack>
        </Paper>
      </Show>
    </Stack>
  )
}

export default React.forwardRef(ApproveCredit)
