import { ComponentStateHandler, ErrorPageSimple } from 'components-new'
import CompanyVisitsLoading from './CompanyVisits.loading'
import { useCompanyVisitsList } from 'services/queries/useCompanyVisitsList'
import SearchesList from '../SearchesList'
import { CompanyVisit } from 'services/api/endpoints/risikaAPI/companyVisitsList'
import { columns } from './CompanyVisits.model'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import SavedSearch from '@mui/icons-material/SavedSearch'
function CompanyVisits() {
  const { data: companyVisitsList, isLoading, isError } = useCompanyVisitsList({})

  return (
    <ComponentStateHandler
      isLoading={isLoading}
      loading={<CompanyVisitsLoading />}
      isError={isError}
      error={<ErrorPageSimple content={''} />}
      isEmptyState={!companyVisitsList?.length}
      emptyState={
        <ErrorPageSimple
          title={<FormattedMessage id={intl.homePage('recent-searches-title')} />}
          content={<FormattedMessage id={intl.home('recent-searches-content')} />}
          icon={<SavedSearch sx={{ width: '6rem', height: '6rem', color: 'grey.600' }} />}
          sx={{ height: '520px' }}
        />
      }
    >
      {!!companyVisitsList && (
        <SearchesList<CompanyVisit> searches={companyVisitsList} columns={columns} />
      )}
    </ComponentStateHandler>
  )
}

export default CompanyVisits
