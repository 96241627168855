import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ProTipsPropsType } from './ProTips.types'
import { TipCard } from 'components-new'

/**
 * @name ProTips
 * @description ProTips component is used to display the tips for the user actions/rules/outcomes.
 * @param tip  The tip to be displayed.This is the same as the name of the rule/outcome. This value is used to get the translated tip from the intl file as well, so use the correct type.
 * @param show Boolean value to show/hide the tip.
 * @param props: ProTipsPropsType.
 * @returns {JSX.Element}
 * @example   <ProTips tip="equity"/>
 */

const ProTips = ({ tip, show = true }: ProTipsPropsType) => {
  if (!show) return null

  return (
    <TipCard
      title={<FormattedMessage id={intl.creditPolicyNew(`pro_tips_title`)} />}
      content={tip}
      extraStyles={{
        containerContent: { color: 'grey.800' },
        containerMain: { marginTop: 0 },
      }}
    />
  )
}
export default ProTips
