import axios, { AxiosRequestConfig } from 'axios'
import { fetchRisikaAPIAccessToken } from '../authService'
import { RISIKA_API_BASE_URL } from '../config'
import { store } from 'context/store'
import { buildURL } from './buildURL'
import { AvailableCountriesCapital, LocalId } from 'globalTypes'
import { buildData } from './buildData'
import { navigation } from 'services/navigation'
declare module 'axios' {
  export interface AxiosRequestConfig {
    microservice?: 'data' | 'monitor' | 'intel' | 'auth' | 'account' | 'subscription'
    country?: LocalId['country']
    appendScoringModelURL?: boolean
    appendScoringModelData?: boolean
    appendScoringModel?: boolean
    appendCurrencyURL?: boolean
    appendCurrencyData?: boolean
  }
}

const getLanguage = (): string => {
  try {
    return store.getState().auth.user.local.lang.replace('_', '-')
  } catch {
    return 'da-DK'
  }
}

const config = axios.create({
  baseURL: RISIKA_API_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
})

export type ModelObject = { [key in AvailableCountriesCapital]: string }

const handleAppendScoringModel = (
  location: 'url' | 'data',
  config: AxiosRequestConfig
) => {
  const isRequestGet = config.method === 'get'
  if (
    !config.appendScoringModel &&
    (config.appendScoringModelURL || config.appendScoringModelData)
  ) {
    // This is for backwards compatibility
    if (location === 'url') {
      return config.appendScoringModelURL
    }
    if (location === 'data') {
      return config.appendScoringModelData
    }
  }
  if (config.appendScoringModel) {
    // New case
    if (location === 'url') {
      return isRequestGet
    }
    if (location === 'data') {
      return !isRequestGet
    }
  }

  // Otherwise no
  return false
}

config.interceptors.request.use(async (axiosConfig) => {
  const accessToken = await fetchRisikaAPIAccessToken()
  const modelObject: ModelObject = store.getState().auth.company?.modelObject
  const model = store.getState().auth.company?.model
  const url = await buildURL(axiosConfig, {
    appendScoringModel: handleAppendScoringModel('url', axiosConfig),
    appendCurrency: axiosConfig.appendCurrencyURL,
    modelObject,
    model,
  })

  const data = await buildData(axiosConfig, {
    appendScoringModel: handleAppendScoringModel('data', axiosConfig),
    appendCurrency: axiosConfig.appendCurrencyData,
    modelObject,
    model,
  })

  if (axiosConfig.headers) {
    axiosConfig.headers.Authorization = accessToken
  }

  axiosConfig.url = url
  axiosConfig.data = data
  // Add some data to refer object
  axiosConfig.headers = {
    // Appending language from state
    ...axiosConfig.headers,
    'Accept-Language': getLanguage(),
  }

  return axiosConfig
})

config.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const redirectTo = `${window.location.origin}${navigation.auth.logout()}`
      window.location.href = redirectTo
    }
    return Promise.reject(error)
  }
)

export default config
