import intl from 'localization/components'

export const tabsDefinition = [
  {
    title: intl.portfolioInsights('tab-summary-label'),
    id: 0,
  },
  {
    title: intl.portfolioInsights('tab-compliance-label'),
    id: 1,
  },
  {
    title: intl.portfolioInsights('tab-scheduler-label'),
    id: 2,
  },
]
