import callBackendAPI from 'services/api/helpers/backendAPI'
import { CustomerSettingsFieldNameT, CustomerSettingsFieldValT } from 'types/queries'

type customerSettingsFieldUpdateT = {
  field: CustomerSettingsFieldNameT
  data: CustomerSettingsFieldValT
}

const customerSettingsFieldUpdate = ({ field, data }: customerSettingsFieldUpdateT) =>
  callBackendAPI(`/customer/settings/${field}`, {
    method: 'put',
    data,
  })
    .then(({ data }) => {
      return data
    })
    .catch((err) => {
      return err
    })

export default customerSettingsFieldUpdate
