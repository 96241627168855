import callBackendAPI from 'services/api/helpers/backendAPI'
import { UserType } from 'types/queries'
/******************
 * Fetch from API *
 ******************/

type ResponseData = {
  data: UserType[]
}

const users = () =>
  callBackendAPI('/user/all')
    .then(({ data }: ResponseData) => {
      return data
    })
    .catch((err) => {
      return err
    })

export default users
