import { removeZeroValuesObjArr } from './general'
import type { MonitoringValueQueryDataType } from 'types/queries'
import type { MonitoringValuesQueryDataType } from 'types/queries'
import type { TimelineResultType } from 'types/chart-data'
import type { GeographicalRisikaScoreQuery } from 'types/queries'
import type { GeographicalRisikaRawValuesType } from 'types/chart-data'
import type { DevelopmentInKeyFiguresQueryType } from 'types/queries'
import type { MapChartItemType } from 'types/chart-data'
import type { LanguageTypes } from 'types/general'
import { Monitor } from 'risika-api-response-types'
const sortedEmptyRisikaScoreGroups = {
  en_UK: { High: 0, Medium: 0, Low: 0, 'No Risk Assessment': 0 },
  da_DK: { Høj: 0, Middel: 0, Lav: 0, 'Ingen risikovurdering': 0 },
  nb_NO: { Høy: 0, Middels: 0, Lav: 0, 'No Risk Assessment': 0 },
  sv_SE: { Hög: 0, Medelhög: 0, Låg: 0, 'Ingen riskbedömning': 0 },
}

/**
 * Converts raw data from [geographicalRisikaScoreQuery]
 * to data format suitable for:
 *   - MapChart
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for chart/s:
 * [ { lng: number, lat: number, value: string, unit: string, scores: [{ name: string, value: number }]} ]
}
 */
export const convertToGeographicalValue = (
  data: GeographicalRisikaScoreQuery[],
  percents: { values: [{ value: number }] }[]
): MapChartItemType[] => {
  return data.map((dataItem: GeographicalRisikaScoreQuery, index: number) => {
    let percentage = String(percents[index].values[0].value).substring(0, 4)
    if (percents[index].values[0].value < 0.1) {
      percentage = '<0.1'
    }
    const scores = dataItem.values.map((value: GeographicalRisikaRawValuesType) => {
      return {
        name: value.group,
        value: value.values[0].value,
        region: dataItem.group_code,
        groupCode: value.group_code,
      }
    })
    return {
      lng: dataItem.group.lng,
      lat: dataItem.group.lat,
      value: percentage,
      unit: '%',
      length: 0,
      scores,
      group_code: dataItem.group_code,
      group: { geo_name: dataItem.group.geo_name },
    }
  })
}

/**
 * Converts raw data from useMonitoringStatistic()
 * to data format suitable for:
 *   - DoughnutChart
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for chart/s: [{name: string, value: number, code: string | number}]
 */
export const convertToDoughnut = (data: MonitoringValueQueryDataType[]) => {
  return data.map((item: MonitoringValueQueryDataType) => {
    const name = item.group.toString()
    return {
      name,
      value: item.values[0].value,
      code: item.group_code ?? 'NO_RISK_ASSESMENT',
    }
  })
}

/**
 * Converts raw data from useMonitoringStatistic()
 * to data format suitable for:
 *   - VerticalBarChart
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for chart/s: [{name: string, value: number}]
 */
export const convertToNameAndValue = (data: MonitoringValueQueryDataType[]) => {
  return (
    data
      // .filter((item: MonitoringValueQueryDataType) => item.group_code)
      .map((item: MonitoringValueQueryDataType) => {
        const name = item?.group?.toString() ?? 'N/A'
        return {
          name,
          value: item.values[0].value,
        }
      })
  )
}

/**
 * Converts raw data from [companyTypeQuery], [employeesQuery], [industryQuery]
 * to data format suitable for:
 *   - StackedBarChart
 *   - SimpleBarChart
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for chart/s:
 * [{name: string, value1: number, value2: number, value3: number}]
 */
export const convertToNameAndValues = (
  data: MonitoringValuesQueryDataType[],
  userLanguage: LanguageTypes = 'da_DK'
): { name: string }[] => {
  const sortedEmptyGroups = sortedEmptyRisikaScoreGroups[userLanguage]
  const convertedData = data?.map((item: MonitoringValuesQueryDataType) => {
    const populatedGroups: any = item.values.reduce(
      (accumulator: {}, field: { group: string; values: [{ value: number }] }) => {
        return { ...accumulator, [field.group]: field.values[0].value }
      },
      {}
    )
    return { name: item.group, ...sortedEmptyGroups, ...populatedGroups }
  })

  const keysArr = Object.keys(sortedEmptyGroups)

  return removeZeroValuesObjArr(keysArr, convertedData) as { name: string }[]
}

/**
 * Converts raw data from [keyFiguresByIndustryQuery]
 * to data format suitable for:
 *   - StackedBarChart
 *   - SimpleBarChart
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for chart/s:
 * [{name: string, value1: number, value2: number, value3: number}]
 */
export const convertToNameAndValuesKey = (data: MonitoringValueQueryDataType[]) => {
  return data
    .filter((item: MonitoringValueQueryDataType) => item.group_code)
    .flatMap((item: MonitoringValueQueryDataType) => {
      // <-- flatMap, check this out! Like map, but you can omit the iteration/s.
      const populatedGroups = item.values.reduce(
        (accumulator: {}, field: { category: string; value: number }) => {
          return {
            ...accumulator,
            [field.category]: field.value?.toFixed(2),
          }
        },
        {}
      )
      // If all values are 0, then return empty object, which you can do with flatMap!!
      const isEmpty = Object.values(populatedGroups).every((x) => !x)
      if (isEmpty) return []
      return { name: String(item.group), ...populatedGroups }
    })
}

/**
 * Converts raw data from [keyFiguresByCompanyTypeQuery]
 * to data format suitable for:
 *   - StackedBarChart
 *   - SimpleBarChart
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for chart/s:
 * [{name: string, value1: number, value2: number, value3: number}]
 */

export interface OuterCategory {
  group?:
    | string
    | number
    | {
        geoname?: string
        lat?: number
        long?: number
      }
  group_code?: string | number
  values: {
    category: string
    category_type: string
    value: number
  }[]
}

export const convertToNameAndValuesSimple = (data: OuterCategory[]) => {
  const convertedData = data.map((item) => {
    const populatedFields = item.values?.reduce((accumulator: {}, field) => {
      // multiplying the average number for each
      // category by 100 to get it's percent
      // except SCORE - (number from 0 to 10)
      const value =
        field.category_type !== 'SCORE'
          ? (field?.value * 100).toFixed() || 0
          : field?.value?.toFixed(1) || 0

      return {
        ...accumulator,
        [field.category as string]: Number(value),
      }
    }, {})
    return { name: item.group, ...populatedFields }
  }) as { [key: string]: string | number }[]

  const keysArr = data.map((item) => item.values.map((value: any) => value.category))[0]

  return removeZeroValuesObjArr(keysArr, convertedData)
}

/**
 * Converts raw data from [timelineQuery] to data format suitable for:
 *   - StackedAreaChart
 * Then sort the data by date
 * Then populate missing data/fields with the fields from previous column
 * (First and last columns always have all data/fields)
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for charts.
 */
export const convertTimelineToNameAndValues = (data: Monitor.ListStatisticsTimeline) => {
  // Convert raw data into the format suitable for manipulation
  let result: TimelineResultType = {}
  data.forEach((item) => {
    item.values.forEach((valueItem) => {
      result[valueItem?.date] = {
        ...result[valueItem?.date],
        [item.group]: valueItem?.value,
      }
    })
  })
  // Sort this data alphabetically (sort object by key)
  result = sortObject(result)
  // Extract all sorted keys (dates) from the object and put them in Array
  const keys = Object.keys(result)
  if (!keys.length) return []
  // first row always have all groups, so pick them (groups) all up
  const groups = Object.keys(result[keys[0]])
  // Set the value of previousEntry to first key
  let previousEntry = keys[0]
  // Populate missing data/fields with the fields from previous column
  keys.forEach((key) => {
    groups.forEach((group) => {
      if (!(group in result[key])) {
        result[key][group] = result[previousEntry][group]
      }
    })
    previousEntry = key
  })
  // Convert this new data into the format suitable for chart
  return keys.map((key: string) => {
    return { ...result[key], name: key }
  })
}

/**
 * Converts raw data from [developmentInKeyFiguresQuery] to data format suitable for:
 *   - LineChart
 * @param {Array} data - raw data format from the query.
 * @return {Array} - data format suitable for chart:
 * [{ name: string, 'A': number, 'B': number, 'C': number }]
 */
export const convertToDevelopmentKeyFigures = (
  data: DevelopmentInKeyFiguresQueryType[],
  scoreData?: DevelopmentInKeyFiguresQueryType[]
) => {
  const fittedObject: any = {}
  let newData = data
  if (scoreData) newData = [...scoreData, ...newData]

  newData.forEach((dataItem: DevelopmentInKeyFiguresQueryType) => {
    const group = dataItem.group
    if (!dataItem?.values?.length) return
    let lastYear = dataItem.values[0].date.substring(0, 4)
    let lastValue = 0
    dataItem.values.forEach((value: { date: string; value: number }, index: number) => {
      const currentYear = value.date.substring(0, 4)
      if (currentYear !== lastYear) {
        fittedObject[lastYear] = {
          ...fittedObject[lastYear],
          [group]: lastValue,
        }
      }
      if (index === dataItem.values.length - 1) {
        fittedObject[currentYear] = {
          ...fittedObject[currentYear],
          [group]: value.value,
        }
      }
      lastYear = currentYear
      lastValue = value.value
    })
  })
  const fittedData = Object.keys(fittedObject).map((objectKey: string) => {
    return { name: objectKey, ...fittedObject[objectKey] }
  })
  return fittedData
}

/**
 * This function sorts the Object alphabetically by the key:
 * @param {Object} obj - (unsorted) Object.
 * @return {Object} - alphabetically sorted object (by the key).
 */
function sortObject(obj: { [key: string]: any }) {
  return Object.keys(obj)
    .sort()
    .reduce((result: TimelineResultType, key: string) => {
      result[key] = obj[key]
      return result
    }, {})
}
