import { useEffect, useRef, useState } from 'react'
import { MenuItem, FormControl, Select, Typography, Box, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const Options = ({ options, render, onChange }) => {
  const handleChange = (event) => onChange(event.target.value)
  const [, setMenuWidth] = useState(null)

  const ref = useRef(null)

  const [selectedOption] = options.filter((x) => x.selected)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setMenuWidth(entry.contentRect.width)
      }
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(ref.current)
      }
    }
  }, [])

  return options.length > 1 ? (
    <Box ref={ref} height={44} sx={{ padding: 3, backgroundColor: 'grey.200' }}>
      <FormControl>
        <Select
          variant="standard"
          value={selectedOption.value}
          onChange={handleChange}
          disableUnderline
          size="small"
          sx={{
            svg: {
              margin: 0,
              fill: (theme) => theme.palette.grey[800],
              fontSize: '24px',
            },
            root: { padding: 0, height: 44 },
            ' &.MuiInputBase-root': {
              backgroundColor: 'grey.200',
              padding: 0,
              height: '100%',
              border: 'none',
              '&:hover': {
                border: 'none',
              },
            },
            ' .MuiSelect-select': {
              boxSizing: 'border-box',
              padding: 0,
              paddingRight: '28px !important',
              border: 'none',
              borderRadius: 0,
              backgroundColor: 'grey.200',
            },
          }}
          MenuProps={{
            sx: {
              top: 9,
              padding: 0,
              '& .MuiMenu-list': {
                paddingY: 1,
                boxSizing: 'content-box',
                width: ref?.current?.clientWidth ?? 'initial',
              },
              '& .MuiMenu-paper': { padding: 0, border: 'none', borderRadius: 0 },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              disableRipple
              sx={{ padding: 3 }}
              data-cy={option.value}
              key={option.value}
              value={option.value}
            >
              <Typography variant="body2">
                <FormattedMessage id={intl.advancedSearch(option.value)} />
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ) : (
    <Stack
      className="TARGET_STACK"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ padding: '0px 12px', height: '100%', backgroundColor: 'grey.200' }}
    >
      <Typography variant="body2">
        <FormattedMessage id={intl.advancedSearch(options[0].value)} />
      </Typography>
    </Stack>
  )
}

export default Options
