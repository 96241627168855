import { Typography } from '@mui/material'
import { colors } from 'configs'
import { CompanyVisit } from 'services/api/endpoints/risikaAPI/companyVisitsList'
import { padding } from './columns.styles'

type Props = {
  item: CompanyVisit
}

function RisikaScore({ item }: Props) {
  return (
    <Typography
      data-cy="risika-score"
      sx={padding}
      color={colors[`bg_rating_${item.risika_score}`]}
      variant="subtitle1"
    >
      {item.risika_score}
    </Typography>
  )
}

export default RisikaScore
