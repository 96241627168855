import { colors } from 'configs'
import intl from 'localization/components'
import { Monitor } from 'risika-api-response-types'

// Type monitoringStatistic request and use the type as data here
export const setCreditPolicyComplianceData = (data: Monitor.ListStatisticsCompliance) => {
  if (data?.credit_policy_used === 'credit_automator') {
    return [
      {
        label: intl.portfolioInsights('allowed-label'),
        value: data.allowed,
        color: 'success.dark',
      },
      {
        label: intl.portfolioInsights('manual_check-label'),
        value: data.manual_check,
        color: 'warning.main',
      },
      {
        label: intl.portfolioInsights('rejected-label'),
        value: data.rejected,
        color: 'error.main',
      },
      {
        label: intl.portfolioInsights('do_not_evaluate-label'),
        value: data.do_not_evaluate,
        color: 'grey.600',
      },
    ]
  }
  let allowed: number | null = null
  let rejected: number | null = null
  if (data) {
    allowed = data.allowed
    rejected = data.rejected
  }
  return [
    {
      label: intl.portfolioInsights('compliant-label'),
      value: allowed,
      color: colors.riskIntelligence.lowRisk,
    },
    {
      label: intl.portfolioInsights('non-compliant-label'),
      value: rejected,
      color: colors.riskIntelligence.highRisk,
    },
  ]
}
