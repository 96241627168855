import { Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { CountryAutocomplete } from 'components-new'
import { ArrowDropDown } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import ConditionallyRender from 'components/ui/ConditionallyRender'
import { cursorPointer } from 'styles-new/global/helpers'
import { useUserSettings } from 'services/queries'
import styles from './CurrencyInput.styles'
import { useUpdateUserSettingsField } from 'services/queries/useUpdateUserSettingsField'
import React from 'react'
import { CurrencyObj } from 'services/types'

const CurrencyInput = () => {
  const defCurrencyObj: CurrencyObj = { country: 'DK', currency: 'DKK' }

  const { formatMessage } = useIntl()
  const { data, error } = useUserSettings()
  const { mutate: updateUserSettingsField } = useUpdateUserSettingsField<CurrencyObj>({})
  const [isSelectingCurrency, setIsSelectingCurrency] = React.useState(false)
  const selectedCurrency = data?.selectedCurrency ?? defCurrencyObj

  const handleUpdateCurrency = (data: CurrencyObj) => {
    setIsSelectingCurrency(false)

    updateUserSettingsField({
      field: 'selected_currency',
      data,
    })
  }

  if (error) {
    return (
      <Stack sx={styles.errorMsg}>
        <Typography>Currency issue:</Typography>
        <Typography>{(error as string) ?? ''}</Typography>
      </Stack>
    )
  }

  if (data) {
    return (
      <Stack direction="column" spacing={1} sx={{ minWidth: '110px' }}>
        <Typography variant="subtitle1">
          <FormattedMessage id={intl.riskMonitoring('currency')} />
        </Typography>
        <ConditionallyRender
          condition={isSelectingCurrency}
          when={
            // When in selecting mode show the autocomplete
            <CountryAutocomplete
              selectedCurrency={selectedCurrency}
              handleChange={handleUpdateCurrency}
              handleClose={() => setIsSelectingCurrency(false)}
            />
          }
          otherwise={
            <Tooltip
              title={formatMessage({
                id: intl.settings('click-to-change-currency'),
              })}
              placement="top"
            >
              <Stack
                onClick={() => setIsSelectingCurrency(true)}
                sx={cursorPointer}
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <img
                  loading="lazy"
                  width="35"
                  src={`https://flagcdn.com/w20/${selectedCurrency?.country?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${selectedCurrency?.country?.toLowerCase()}.png`}
                  alt=""
                />
                <Typography variant="body1">{selectedCurrency?.currency}</Typography>
                <ArrowDropDown />
              </Stack>
            </Tooltip>
          }
        />
      </Stack>
    )
  }

  return <Skeleton variant="rectangular" height={50} />
}

export default CurrencyInput
