import React from 'react'
import { Stack } from '@mui/material'
import { InputField } from 'components-new'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import ProTips from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/ProTips/ProTips'
import intl from 'localization/components'
import { useIntl } from 'react-intl'

const AmountDueInput = ({ isAdvanced = true }) => {
  const { policy, setPolicy, isInferiorPlan } = useCountryPolicy()
  const reactIntl = useIntl()
  const defVal = policy.payment_remarks_total_amount ?? 0
  const [val, setVal] = React.useState<number>(defVal)

  // this is needed to set the initial value after it's been null
  React.useEffect(() => {
    if (typeof policy.payment_remarks_total_amount === 'number') {
      setVal(policy.payment_remarks_total_amount)
    }
  }, [policy.payment_remarks_total_amount])

  return (
    <Stack spacing={4}>
      <InputField
        value={val}
        handleChange={function (value: string): void {
          if (isNaN(Number(value))) return
          setVal(Number(value))
          setPolicy({ ...policy, payment_remarks_total_amount: Number(value) })
        }}
        disabled={isAdvanced && isInferiorPlan}
      />
      <ProTips
        tip={reactIntl.formatMessage({
          id: intl.creditPolicyNew('tips_payment_remarks'),
        })}
      />
    </Stack>
  )
}

export default AmountDueInput
