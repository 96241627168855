import { Box, Grid, Typography } from '@mui/material'
import BoxContainerSmall from 'components-new/layouts/BoxContainerSmall'
import { model } from './PortfolioRiskDistribution.model'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { RiskDistributionPropTypes } from './PortfolioRiskDistribution.types'
import { classJustifyContentCenter } from 'styles-new/global/helpers'
import { classOutlineBoxTarget } from 'styles-new/global/components'
import RadialPercentChart from 'components-new/charts/RadialPercentChart'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import { subLabel } from 'styles-new/global/typography'
import { useEffect, useMemo, useState } from 'react'
import { ShowListsType } from 'types/queries'

const PortfolioRiskDistribution = ({
  listId,
  handlePortfolioInsightsFilters,
  showListsData,
  withTitle = true,
  isLoading,
}: {
  listId: string
  handlePortfolioInsightsFilters?: (category: string, key: string) => void
  showListsData: ShowListsType | undefined
  withTitle?: boolean
  isLoading?: boolean
}) => {
  const reactIntl = useIntl()

  const [companyChartData, setCompanyChartData] = useState<RiskDistributionPropTypes[]>(
    []
  )

  const selectedList = useMemo(() => {
    return showListsData?.find(
      (list: { list_id: number }) => list.list_id === Number(listId)
    )
  }, [showListsData, listId])

  const boxData = {
    title: intl.portfolioInsights('portfolio-risk-distribution'),
    status: {
      isLoading,
    },
    height: 180,
    outlineBox: false,
    skeletonType: 'CHART_WITH_TEXT',
  }

  useEffect(() => {
    if (!selectedList?.risk_assessment) {
      return
    }

    const allCompaniesCount = Object.values(
      selectedList.risk_assessment as object
    ).reduce((a, b) => a + b)

    // building data using the model
    const data = model.map((item: RiskDistributionPropTypes) => {
      const riskCompanies =
        selectedList?.risk_assessment[
          item.risk as keyof typeof selectedList.risk_assessment
        ] || 0
      return {
        ...item,
        value: (riskCompanies / allCompaniesCount) * 100,
        subTitleText: reactIntl.formatMessage(
          {
            id: intl.portfolioInsights('portfolio-risk-distribution-subtitle'),
          },
          {
            currentCompaniesCount: riskCompanies,
            allCompaniesCount,
          }
        ),
      }
    })
    setCompanyChartData(data)
  }, [reactIntl, selectedList, selectedList?.risk_assessment])

  return (
    <Box minWidth={245}>
      <BoxContainerSmall boxData={boxData} withTitle={withTitle}>
        {!boxData.status.isLoading ? (
          <>
            {companyChartData?.map((risk) => {
              return (
                <Box
                  sx={{ ...classOutlineBoxTarget }}
                  key={risk.titleText}
                  data-cy={`risk-share-chart-${risk.risk}`}
                >
                  {
                    <Grid
                      container
                      onClick={() =>
                        handlePortfolioInsightsFilters?.(apiKeys.creditScore, risk.key)
                      }
                    >
                      <Grid item xs={4}>
                        <RadialPercentChart
                          value={risk.value}
                          fill={risk.fill}
                          outerRadius={50}
                          barSize={3}
                          cornerRadius={50}
                        />
                      </Grid>
                      <Grid item xs={8} sx={classJustifyContentCenter}>
                        <Typography component="div">
                          <Box sx={subLabel}>
                            {
                              <FormattedMessage
                                id={intl.portfolioInsights(risk.titleText)}
                              />
                            }
                          </Box>
                        </Typography>
                        <Typography variant="subtitle1">{risk.subTitleText}</Typography>
                      </Grid>
                    </Grid>
                  }
                </Box>
              )
            })}
          </>
        ) : null}
      </BoxContainerSmall>
    </Box>
  )
}

export default PortfolioRiskDistribution
