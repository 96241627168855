import AsyncSelect from 'react-select/async'
import { customStyles } from '../styles/searchable-async-select'

const SearchableAsyncSelect = ({
  onChange,
  loadOptions,
  placeholder,
  isDisabled = false,
}) => {
  return (
    <AsyncSelect
      isDisabled={isDisabled}
      placeholder={placeholder}
      onChange={(values) => onChange(values)}
      closeMenuOnSelect
      loadOptions={loadOptions}
      styles={customStyles}
      cachedOptions
    />
  )
}

export default SearchableAsyncSelect
