import {
  SHOW_HIDE_TABLE_FIELD,
  STORE_SETTING,
  SUBMIT_GET_ALL_USERS,
  SET_USERS_STATUS,
  SET_USERS_SUCCESS,
} from './constants'

// type SettingsInitialState = {
//   enforce_credit_policy: boolean,
//   options_for_regions: Array<any>,
//   options_for_municipalities: Array<any>,
//   detailed_view: boolean,
//   table: boolean,
//   advancedViewOwners: boolean,
//   advancedViewRelevantFinancialRatios: boolean,
//   userManagement: Object,
// }

export const initialState = {
  enforce_credit_policy: false,
  options_for_regions: [],
  options_for_municipalities: [],
  detailed_view: true,
  table: false,
  advancedViewOwners: false,
  advancedViewRelevantFinancialRatios: false,
  userManagement: {
    status: 'IDLE',
    users: [],
  },
}

const handleShowHideTableFields = (state, action) => {
  const { payload } = action
  const { key, value } = payload
  return {
    ...state,
    [key]: value,
  }
}

const handleStoreSettings = (state, action) => {
  const { payload } = action
  const { name, value } = payload
  return {
    ...state,
    [name]: value,
  }
}

const handleSetUsersStatus = (state, action) => {
  const { status } = action
  return {
    ...state,
    userManagement: {
      ...state.userManagement,
      status,
    },
  }
}
const handleSetUsersSuccess = (state, action) => {
  const { status, payload } = action
  return {
    ...state,
    userManagement: {
      ...state.userManagement,
      users: payload.data.map((x) => ({
        ...x,
        roles: [x.role],
      })),
      status,
    },
  }
}

export const reducerName = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SHOW_HIDE_TABLE_FIELD:
      return handleShowHideTableFields(state, action)
    case STORE_SETTING:
      return handleStoreSettings(state, action)
    case SUBMIT_GET_ALL_USERS:
      return handleSetUsersStatus(state, action)
    case SET_USERS_STATUS:
      return handleSetUsersStatus(state, action)
    case SET_USERS_SUCCESS:
      return handleSetUsersSuccess(state, action)
    default:
      return state
  }
}

export default reducerName
