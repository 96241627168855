import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Post to API *
 ******************/

const monitorVerify = (list_id, localId) => {
  const localIdOrHash = localId.hash ?? localId.id
  const country = localId.country?.toLowerCase()

  return risikaAPI
    .get(`/verify?list_id=${list_id}&local_id=${localIdOrHash}&country=${country}`, {
      microservice: 'monitor',
    })
    .then(({ data }) => {
      return { ...data, listId: list_id }
    })
    .catch((err) => Promise.reject(err.response.data.error))
}

export default monitorVerify
