import { useState } from 'react'
import { ClickAwayListener } from '@mui/base'
import { List, ListItem, ListItemButton, Typography, Skeleton } from '@mui/material'
import { SxProps } from '@mui/system'
import { styles } from './FloatingActionMenu.styles'
import {
  ActionsProp,
  FloatingActionMenuProps,
  MenuItem,
} from './FloatingActionMenu.types'

import { useSnackbar } from 'notistack'
import intl from 'localization/components'
import { CreditPolicyResponse } from 'types/queries'
import { downloadAs } from 'services/export'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCreditPolicyGet, useCreditPolicyCreate } from 'services/queries'
import { navigation } from 'services/navigation'
import { useHistory } from 'react-router-dom'
import { deleteCreditPolicy } from 'services/api'
import { useQueryClient } from 'react-query'
import { EditPolicySidePanel } from '../SidePanels'
import DeletePolicyDialog from './widgets/DeletePolicyDialog'

const FloatingActionMenu = ({
  actions,
  handleClickAway,
  containerStyles = {},
  policyId,
}: FloatingActionMenuProps) => {
  const { messages } = useIntl()
  const [editPolicyPanelIsOpen, setEditPolicyPanelIsOpen] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  // const [showEditActivePolicyDialog, setShowEditActivePolicyDialog] = useState(false)
  const showEditActivePolicyDialog = false
  const { enqueueSnackbar } = useSnackbar()
  const { data, isLoading } = useCreditPolicyGet(policyId)
  const { mutateAsync: creteCreditPolicy } = useCreditPolicyCreate()
  const queryClient = useQueryClient()
  const history = useHistory()

  if (!data || isLoading) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />
  }

  const handleExportPolicy = (policy: CreditPolicyResponse | undefined) => {
    if (!policy) {
      return enqueueSnackbar(
        <FormattedMessage id={intl.creditPolicyNew('export-error')} />
      )
    }
    downloadAs('json')!(policy, policy.name)
    enqueueSnackbar(
      <FormattedMessage
        id={intl.creditPolicyNew('export-success')}
        values={{ policyName: `'${policy.name}'` }}
      />
    )
  }

  const handleDuplicatePolicy = (policyData: CreditPolicyResponse | undefined) => {
    if (!policyData) return false
    const { description, policy, country, enabled } = policyData
    creteCreditPolicy({
      name: `${policyData.name} (${
        messages[intl.riskMonitoringNew('copy-button-text')]
      })`,
      description,
      policy,
      country,
      enabled,
    })
      .then((data) => {
        enqueueSnackbar(
          <FormattedMessage id={intl.creditPolicyNew('duplicate-success')} />
        )
        handleClickAway()
        return data
      })
      .catch(() => {
        enqueueSnackbar(<FormattedMessage id={intl.creditPolicyNew('duplicate-error')} />)
        handleClickAway()
      })
  }

  const handleEdit = (policyData: CreditPolicyResponse) => {
    if (policyData) {
      const { country, name, enabled, id } = policyData
      history.push(
        navigation.creditPolicyBuilder({
          country,
          name,
          createActiveRule: enabled,
          policyId: id,
        })
      )
    }
  }

  // const handleEditPolicyCopy = async (curentPolicyData: CreditPolicyResponse) => {
  //   const duplicated = await handleDuplicatePolicy(curentPolicyData)
  //   const newPolicy = await getCreditPolicy(duplicated?.policy_id)
  //   handleEdit(newPolicy)
  // }

  const openDeleteDialog = () => setShowDeleteDialog(true)

  const handleDeletePolicy = async (policyId: string) => {
    try {
      await deleteCreditPolicy(Number(policyId))
      await queryClient.refetchQueries('creditPolicyGetAll')
      history.push(navigation.enterpriseCreditPolicy())
      enqueueSnackbar(<FormattedMessage id={intl.creditPolicyNew('delete-success')} />)
    } catch (err) {
      enqueueSnackbar(<FormattedMessage id={intl.creditPolicyNew('delete-error')} />)
    }
  }

  const handleEditPolicySettings = () => {
    setEditPolicyPanelIsOpen(true)
  }

  const onClickAway = () =>
    editPolicyPanelIsOpen || showDeleteDialog || showEditActivePolicyDialog
      ? () => {}
      : handleClickAway()

  const menuItems: MenuItem[] = [
    {
      label: 'export',
      onClick: () => handleExportPolicy(data),
    },
    {
      label: 'duplicate',
      onClick: () => handleDuplicatePolicy(data),
    },
    {
      label: 'edit',
      onClick: () => handleEdit(data),
    },
    {
      label: 'edit-policy-settings',
      onClick: () => handleEditPolicySettings(),
    },
    {
      label: 'delete',
      onClick: () => openDeleteDialog(),
    },
    {
      label: 'print',
      onClick: () => console.log('print'),
    },
    {
      label: 'share',
      onClick: () => console.log('Share'),
    },
    {
      label: 'rename',
      onClick: () => console.log('Rename'),
    },
  ]
  const mapActionsToMenuItems = (actions: ActionsProp[]): MenuItem[] => {
    return actions.map((action) => menuItems.find((item) => item.label === action)!)
  }

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <List
          className="MENU-LIST"
          data-cy="floating-action-menu"
          sx={{ ...styles.menuContainer, ...containerStyles } as SxProps}
        >
          {mapActionsToMenuItems(actions).map(({ label, onClick }) => (
            <ListItem
              data-cy={`policy-card-action-${label}`}
              sx={styles.menuItem}
              key={label}
            >
              <ListItemButton sx={{ padding: 3, paddingRight: 7 }} onClick={onClick}>
                <Typography variant="body1">
                  <FormattedMessage id={intl.creditPolicyNew(label)} />
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </ClickAwayListener>
      {editPolicyPanelIsOpen && (
        <EditPolicySidePanel
          isOpen={editPolicyPanelIsOpen}
          setIsOpen={setEditPolicyPanelIsOpen}
          policyId={policyId}
        />
      )}
      <DeletePolicyDialog
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        policyId={policyId}
        policyName={data?.name ?? ''}
        action={handleDeletePolicy}
      />
    </>
  )
}

export default FloatingActionMenu
