// @flow

import { takeEvery, call, put, all } from 'redux-saga/effects'
import intl from 'localization/components'

import {
  userChangePassword,
  userChangeProfile,
  updateCompany,
  getCustomerData,
  getUser,
  accountStatus,
  updateCustomerSettings,
} from 'services/api'

import {
  LOGIN_USER_SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_COMPANY_DOMAIN_AND_PHONE_REQUEST,
  GET_USER_REQUEST,
} from './constants'

import {
  updatePasswordSuccess,
  updatePasswordFailed,
  updateUserFailed,
  updateUserSuccess,
  updateCompanyDomainAndPhoneSuccess,
  updateCompanyInformationSuccess,
  getUserSuccess,
  updateCompanyDomainAndPhoneRequest,
} from './actions'

/***************
 * Saga Worker *
 ***************/

function* loginUser(action) {
  try {
    const data = yield all({
      companyData: call(getCustomerData),

      companyStatus: call(accountStatus),
    })

    const allowedModels = data?.companyStatus?.rights?.allow_models
    const selectedModel = data?.companyData?.used_model
    const modelObject = data?.companyData?.user_model_json

    // If we have changed the model from the database we should automatically select the new one from here
    if (!allowedModels?.includes(selectedModel)) {
      yield all([
        put(
          updateCompanyDomainAndPhoneRequest({
            companyId: data?.companyData?.id,
            // model: selectedModel ?? 'BOHR',
            modelObject,
          })
        ),
        put(updateCompanyInformationSuccess(data?.companyData)),
      ])
    }
    yield put(updateCompanyInformationSuccess(data?.companyData))
  } catch (error) {
    /** no-op */
  }
}

function* changePassword(action) {
  const { password, confirmPassword, enqueueSnackbar, messages } = action.payload

  try {
    yield call(userChangePassword, password, confirmPassword)
    yield put(updatePasswordSuccess())
    enqueueSnackbar(messages[intl.settings('password-updated-successfully')])
  } catch (error) {
    yield put(updatePasswordFailed({ error }))
    enqueueSnackbar(messages[intl.snackbar('report-error-something-wrong')])
  }
}

function* changeUserInformation(action) {
  const { name, phoneNumber } = action.payload

  try {
    yield call(userChangeProfile, { name, phoneNumber }) // Start from here
    yield put(updateUserSuccess({ name, phoneNumber }))
  } catch (error) {
    yield put(updateUserFailed({ error }))
  }
}

function* changeCompanyDomainAndPhone(action) {
  const { companyId, domain, modelObject } = action.payload
  try {
    const { customerData, settingsData } = yield all({
      settingsData: call(updateCustomerSettings, {
        field: 'user_model',
        data: { user_model: modelObject },
      }),
      customerData: call(updateCompany, {
        companyId,
        domain,
      }),
    })
    yield put(
      updateCompanyDomainAndPhoneSuccess({
        domain: customerData.domain,
        modelObject: settingsData.user_model,
      })
    )
  } catch (error) {
    // Implement // Deprecate Lol :D
  }
}
function* getUserData(action) {
  const { id: userId } = action.payload

  try {
    const { data } = yield call(getUser, userId)
    yield put(getUserSuccess({ user: data }))
  } catch (error) {
    // Implement
  }
}

/****************
 * Saga Watcher *
 ****************/

function* authSaga() {
  yield takeEvery(LOGIN_USER_SUCCESS, loginUser)
  yield takeEvery(UPDATE_PASSWORD_REQUEST, changePassword)
  yield takeEvery(UPDATE_USER_REQUEST, changeUserInformation)
  yield takeEvery(UPDATE_COMPANY_DOMAIN_AND_PHONE_REQUEST, changeCompanyDomainAndPhone)
  yield takeEvery(GET_USER_REQUEST, getUserData)
}

export default authSaga
