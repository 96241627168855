import { default as cx } from 'classnames'
import { Paper, InputBase } from '@mui/material'
import { useStyles } from './styles/input'
import {
  ChangeEventHandler,
  ElementType,
  FocusEventHandler,
  KeyboardEventHandler,
  KeyboardEvent,
} from 'react'

type CustomizedInputBaseProps = {
  className?: string
  leftAdornment: ElementType
  rightAdornment: ElementType
  placeholder: string
  onKeyDown: KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onFocus:
    | FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | ((event: KeyboardEvent<HTMLInputElement>) => void)
  onChange:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | ((event: Event) => void)
  value: unknown
  sx?: Record<string, unknown>
}

const CustomizedInputBase = (props: CustomizedInputBaseProps) => {
  const {
    className = '',
    leftAdornment,
    rightAdornment,
    placeholder = '',
    onKeyDown,
    onFocus,
    onChange,
    value,
    ...rest
  } = props

  const classes = useStyles()
  const LeftAdornment = leftAdornment
  const RightAdornment = rightAdornment

  return (
    <Paper component="form" className={cx(classes.root, className)}>
      <LeftAdornment />
      <InputBase
        fullWidth
        className={classes.input}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onFocus={onFocus as FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>}
        value={value}
        onChange={onChange as ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>}
        {...rest}
      />
      <RightAdornment />
    </Paper>
  )
}

export default CustomizedInputBase
