import { authRoles } from 'components/auth'
import EnterpriseCreditPolicyPage from './EnterpriseCreditPolicyPage'
import PolicyBuilder from './widgets/PolicyBuilder'
import { BuilderProvider } from './widgets/PolicyBuilder/BuilderContext'

export const EnterpriseCreditPolicyPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/enterprise-credit-policy',
      component: EnterpriseCreditPolicyPage,
    },
    {
      path: '/credit-policy-builder/:country/:name/:createActiveRule/:policyId/:description?/',
      component: (props: React.ComponentProps<any>) => (
        <BuilderProvider>
          <PolicyBuilder {...props} />
        </BuilderProvider>
      ),
    },
  ],
}
