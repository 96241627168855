import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
  SelectChangeEvent,
} from '@mui/material'
import { IntlTypography } from 'components-new'
import { Scheduler } from 'services/api/endpoints/risikaAPI/new_subscription'
import { getDateOfNextWeekDay } from 'utils/dateUtils'

type SelectFrequencyProps = {
  value: Scheduler
  onChange: React.Dispatch<Scheduler>
}

const SelectFrequency = ({ value, onChange }: SelectFrequencyProps) => {
  const frequency = value?.mode
  const dayOfTheWeek = value?.days?.[0]

  const handleFrequencyChange = (event: SelectChangeEvent<string>) => {
    const mode = event.target.value
    onChange({ ...value, mode })
  }

  function handleDayOfTheWeekChange(event: SelectChangeEvent<string>) {
    const newDayOfTheWeek = event.target.value
    onChange({
      ...value,
      days: [newDayOfTheWeek],
      starting_date: getDateOfNextWeekDay(newDayOfTheWeek),
    })
  }

  return (
    <Box>
      <IntlTypography variant="subtitle1" group="monitorFrequency" labelKey="title" />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="monitor-frequency">
              <IntlTypography
                variant="caption"
                group="monitorFrequency"
                labelKey="how-often-label"
              />
            </InputLabel>
            <Select
              labelId="monitor-frequency"
              id="monitor-frequency-select"
              value={frequency}
              onChange={handleFrequencyChange}
            >
              <MenuItem value="daily">
                <IntlTypography group="monitorFrequency" labelKey="daily-select" />
              </MenuItem>
              <MenuItem value="weekly">
                <IntlTypography group="monitorFrequency" labelKey="weekly-select" />
              </MenuItem>
              <MenuItem value="monthly">
                <IntlTypography group="monitorFrequency" labelKey="monthly-select" />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {frequency === 'weekly' ? (
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="monitor-day-of-the-week">
                <IntlTypography
                  variant="caption"
                  group="monitorFrequency"
                  labelKey="which-day-label"
                />
              </InputLabel>
              <Select
                labelId="monitor-day-of-the-week"
                id="monitor-day-of-the-week-select"
                value={dayOfTheWeek}
                onChange={handleDayOfTheWeekChange}
              >
                <MenuItem value="monday">
                  <IntlTypography group="monitorFrequency" labelKey="monday-select" />
                </MenuItem>
                <MenuItem value="tuesday">
                  <IntlTypography group="monitorFrequency" labelKey="tuesday-select" />
                </MenuItem>
                <MenuItem value="wednesday">
                  <IntlTypography group="monitorFrequency" labelKey="wednesday-select" />
                </MenuItem>
                <MenuItem value="thursday">
                  <IntlTypography group="monitorFrequency" labelKey="thursday-select" />
                </MenuItem>
                <MenuItem value="friday">
                  <IntlTypography group="monitorFrequency" labelKey="friday-select" />
                </MenuItem>
                <MenuItem value="saturday">
                  <IntlTypography group="monitorFrequency" labelKey="saturday-select" />
                </MenuItem>
                <MenuItem value="sunday">
                  <IntlTypography group="monitorFrequency" labelKey="sunday-select" />
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Box />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default SelectFrequency
