import PhoneNumber from 'awesome-phonenumber'

/********************************
 * Definition of the validators *
 ********************************/

/**
 * Ensure the value is an email.
 */
const emailFormat = {
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

/**
 * Ensure the value is not empty.
 */
const notEmptyFormat = {
  length: {
    min: 1,
  },
}

/**
 * Ensure the value is a valid phone number.
 */
const phoneNumberFormat = {
  callback: (value) => {
    if (value == null || value === '') {
      return true
    }
    return new PhoneNumber(value).isValid()
  },
}

const matchValueFormat = (value) => ({
  matches: value,
})

const notMatchValueFormat = (value) => ({
  matchesNot: value,
})

/******************
 * The validators *
 ******************/

export const validationFormats = {
  email: emailFormat,
  notEmpty: notEmptyFormat,
  phoneNumber: phoneNumberFormat,
  matchValue: matchValueFormat,
  notMatchValue: notMatchValueFormat,
}
