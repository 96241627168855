import { useState, useImperativeHandle, forwardRef, ForwardedRef, useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import SwitchWithLabel from '../../../../../../../SwitchWithLabel'
import { DropdownSelect } from 'components-new'
import { useBuilder } from '../../../../../../BuilderContext'
import * as React from 'react'
import { NullRef } from '../../index'
import { nullHandlerOptions } from './NullHandler.model'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { RuleData } from '../../../../../../PolicyBuilder.types'
import { DefaultOnNullValue } from 'types/queries'

export const NullHandler = (props: any, ref: ForwardedRef<NullRef>) => {
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState<Exclude<DefaultOnNullValue, null>>('reject')

  const { actions, state } = useBuilder()

  useEffect(() => {
    const currentNodeId = state.actionPanelData.nodeId
    const currentNode = state.nodes.find((node) => node.id === currentNodeId)
    const rule = currentNode?.data as RuleData
    const currentNullPathValue = rule?.apiData?.default_on_null

    if (currentNullPathValue) {
      setChecked(true)
      setValue(currentNullPathValue)
      return
    }
    setChecked(false)
  }, [state])

  useImperativeHandle(
    ref,
    () => {
      const nodeId = state.actionPanelData.nodeId
      const payload = {
        nodeId,
        data: { default_on_null: checked ? value : null },
      }
      return {
        handleNull: () => {
          actions.addNodeApiData(payload)
        },
      }
    },
    [actions, checked, state.actionPanelData.nodeId, value]
  )
  const handleChangeSwitch = () => {
    setChecked((prevState) => !prevState)
  }

  return (
    <Stack gap={5}>
      <SwitchWithLabel
        checked={checked}
        onChange={handleChangeSwitch}
        lighterLabelColorWhenUnchecked
        labelPosition="end"
        labelChecked={
          <FormattedMessage
            id={intl.creditPolicyNew('or-else-if-no-data-is-available')}
          />
        }
      />
      {checked && (
        <Stack gap={4}>
          <Typography variant="body2">
            If there is no data available for a specific rule, the default outcome will be
            set to &apos;Manual check&apos;. You can customise your preferences by
            selecting an alternate outcome for this rule.
          </Typography>
          <DropdownSelect
            dataCy="null-handler-dropdown"
            options={nullHandlerOptions}
            value={value}
            setValue={setValue}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default forwardRef(NullHandler)
