import { CompanyChangesType } from 'services/queries/useCompanyChanges'
import { RecentChanges } from './CompanyChange'
import { ChangeType } from 'components-new/data-display/Table/elements/Changes/Change.types'

export const getNMostRecentChanges = (
  changesArray?: CompanyChangesType[],
  number: number = 4
) => {
  const recentChanges: RecentChanges[] = []

  let i = 0

  if (!changesArray?.length) {
    return []
  }

  // go through data and get changes array from each element
  // add them to recentChanges until lenth is 4 (or n passed in )
  while (recentChanges.length < number && i <= changesArray.length - 1) {
    if (!changesArray[i]) {
      return []
    }

    changesArray[i].changes.forEach((change: ChangeType) => {
      if (recentChanges.length >= number) {
        return
      }
      const changeObj = {
        name: changesArray[i]?.name,
        local_organization_id: changesArray[i]?.local_organization_id,
        date: changesArray[i]?.date,
        sentence: change?.sentence,
        change,
      }

      // reject if current change already exists in the array
      if (
        !recentChanges.some(
          (obj) => obj.name === changeObj.name && obj.sentence === changeObj.sentence
        )
      ) {
        recentChanges.push(changeObj)
      }
    })
    i++
  }
  return recentChanges
}

export const homePageSingleColumnWidth = 1200
