import { authRoles } from 'components/auth'
import AdvanceCreditPolicyPage from './AdvanceCreditPolicyPage'

export const AdvanceCreditPolicyPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/advance-policy',
      component: AdvanceCreditPolicyPage,
    },
  ],
}
