import Grid from '@mui/material/Grid'
// import InfoBoxWithChart from 'components-new/info/InfoBoxWithChart'
import InfoBox from 'components-new/info/InfoBox'
import { averageNumbersData } from './ListAveragesBox.model'
import { useMonitoringStatistic } from 'services/queries'
import { DisplayPropsType } from './ListAveragesBox.types'
import { InnerCategory } from 'risika-api-response-types/monitor/list/statistics/category'

const ListAveragesBox = ({ listId }: { listId: string }) => {
  const items = averageNumbersData.boxes
  averageNumbersData.parameters.list_id = Number(listId)
  const averageNumbersQuery = useMonitoringStatistic<InnerCategory[]>({
    parameters: averageNumbersData.parameters,
    endpoint: averageNumbersData.endPoint,
  })
  const averageNumbersQueryData = averageNumbersQuery.data

  const status = {
    isLoading: averageNumbersQuery.isLoading,
    isError: averageNumbersQuery.isError,
    isIdle: averageNumbersQuery.isIdle,
  }
  if (!averageNumbersQueryData) return null

  return (
    <>
      {items.map((item, index) => {
        let value = '0'
        let title = ''
        const displayProps: DisplayPropsType = {
          style: 'currency',
          currency: 'DKK',
        }
        let error = false
        if (!status.isLoading && !status.isError && !status.isIdle) {
          value =
            averageNumbersQueryData
              ?.find((x) => x.category_type === item.category_type)
              ?.value?.toString() ?? '0'
          title = item.title
          const style = item.displayProps.style
          // Type guard ahead
          if (
            style === 'currency' ||
            style === 'decimal' ||
            style === 'percent' ||
            style === 'unit'
          ) {
            displayProps.style = style
            displayProps.currency = item.displayProps.currency
          }
        }
        if (status.isError) {
          error = true
        }
        return (
          <Grid item xs={4} key={title + index} data-cy="list-averages-box">
            <InfoBox
              title={title}
              value={parseInt(value)}
              displayProps={displayProps}
              error={error}
            />
          </Grid>
        )
      })}
    </>
  )
}

export default ListAveragesBox
