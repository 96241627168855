import * as React from 'react'
import {
  DELETE_RULE,
  NEW_MONITORING_LIST,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import { ReactSetState } from 'types/general'
import { SubmitRef } from '..'
import DeleteRule from '../Actions/DeleteRule'
import NewMonitoringList from '../Actions/NewMonitoringList'
import { ActionPanelStates } from '../../../../../PolicyBuilder.types'

type ExtraPanelContentProps = {
  setIsConfirmEnabled: ReactSetState<boolean>
  panelKey: ActionPanelStates
}

function ExtraPanelContent(
  { setIsConfirmEnabled, panelKey }: ExtraPanelContentProps,
  ref: React.ForwardedRef<SubmitRef>
) {
  switch (panelKey) {
    case DELETE_RULE:
      return <DeleteRule ref={ref} setIsConfirmEnabled={setIsConfirmEnabled} />
    case NEW_MONITORING_LIST:
      return <NewMonitoringList ref={ref} setIsConfirmEnabled={setIsConfirmEnabled} />
    default:
      return null
  }
}

export default React.forwardRef(ExtraPanelContent)
