import { useState, useEffect } from 'react'
import {
  Typography,
  Stack,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Button,
  Paper,
  Checkbox,
  FormGroup,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { CalendarPicker, CalendarPickerView } from '@mui/x-date-pickers'
import { CalendarToday } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IntlTypography from 'components-new/elements/IntlTypography'
import { FormattedMessage, useIntl } from 'react-intl'
import { daysOfWeek, frequencyPeriods, monthlyPeriods } from './Frequency.model'
import { FrequencyPropsType } from './Frequency.types'
import { styles } from './Frequency.styles'
/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'
import {
  getLocalizedMonthFromNumber,
  getTodayDifferentYear,
  getTomorrow,
} from 'localization/utils'
import { DayOfMonth, DaysOfWeek, FrequencyType } from 'globalTypes'

const Frequency = ({ frequencyData, setFrequencyData }: FrequencyPropsType) => {
  const reactIntl = useIntl()
  const messages = reactIntl.messages

  const [frequency, setFrequency] = useState(frequencyData.frequency)
  const [frequencyPeriod, setFrequencyPeriod] = useState(frequencyData.frequencyPeriod)
  const [dayOfMonth, setDayOfMonth] = useState(frequencyData.dayOfMonth)
  const [weekDays, setWeekDays] = useState(frequencyData.weekDays)
  const [selectedDate, setSelectedDate] = useState(frequencyData.selectedDate)

  const datePickerButtonLabel = `${selectedDate.getDate()} ${getLocalizedMonthFromNumber(
    selectedDate.getMonth()
  )}`
  const [datePickerPaperVisible, setDatePickerPaperVisible] = useState(false)
  const [datePickerView, setDatePickerView] = useState<CalendarPickerView>('day')

  useEffect(() => {
    setFrequencyData({
      frequency,
      frequencyPeriod,
      dayOfMonth,
      weekDays,
      selectedDate,
    })
  }, [dayOfMonth, frequency, frequencyPeriod, selectedDate, weekDays, setFrequencyData])

  useEffect(() => {
    setFrequency(frequencyData.frequency)
    setFrequencyPeriod(frequencyData.frequencyPeriod)
    setDayOfMonth(frequencyData.dayOfMonth)
    setWeekDays(frequencyData.weekDays)
    setSelectedDate(frequencyData.selectedDate)
  }, [
    frequencyData.dayOfMonth,
    frequencyData.frequency,
    frequencyData.frequencyPeriod,
    frequencyData.selectedDate,
    frequencyData.weekDays,
  ])

  const toggleDatePickerPaper = () => {
    setDatePickerPaperVisible(!datePickerPaperVisible)
  }

  return (
    <Stack gap={2}>
      <IntlTypography
        variant="h5"
        group="portfolioInsights"
        labelKey="tab-scheduler-frequency-label"
      />

      {/* Send Every */}
      <Stack gap={1}>
        <IntlTypography
          variant="h6"
          group="portfolioInsights"
          labelKey="tab-scheduler-frequency-send-every"
        />
        <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
          <TextField
            sx={styles.numberInput}
            type="number"
            value={frequency}
            InputProps={{
              inputProps: {
                max: 52,
                min: 1,
              },
            }}
            onChange={(event) => {
              let value = parseInt(event.target.value)

              if (value < 1 || !value) {
                value = 1
              } else if (value > 52) {
                value = 52
              }

              setFrequency(value)
            }}
          />
          <Select
            value={frequencyPeriod}
            label="Frequency"
            onChange={(event) => setFrequencyPeriod(event.target.value as FrequencyType)}
            sx={styles.frequencySelect}
          >
            {frequencyPeriods.map((item) => (
              <MenuItem key={item.label} value={item.value}>
                <Typography sx={styles.selectItemContent}>
                  <FormattedMessage id={item.label} />
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>

      {/* Send On */}
      {frequencyPeriod === 'weekly' && (
        <Stack gap={1}>
          <IntlTypography
            variant="h6"
            group="portfolioInsights"
            labelKey="tab-scheduler-frequency-send-on"
          />
          <FormGroup sx={styles.radioGroup}>
            {daysOfWeek.map((day) => {
              const isChecked = weekDays.includes(day.value)

              return (
                <FormControlLabel
                  key={day.value}
                  sx={styles.formControlLabel}
                  label={messages[day.label] as string}
                  control={
                    <Checkbox
                      size="medium"
                      name={day.value}
                      checked={isChecked}
                      onChange={(event) => {
                        const isChecked = event.target.checked
                        const checkedDay = event.target.name as DaysOfWeek

                        if (isChecked) {
                          setWeekDays([...weekDays, checkedDay])
                        } else {
                          const newWeekDays = weekDays.filter(
                            (day: string) => day !== checkedDay
                          )

                          setWeekDays(newWeekDays)
                        }
                      }}
                    />
                  }
                />
              )
            })}
          </FormGroup>
        </Stack>
      )}

      {/* MONTH - Send On */}
      {frequencyPeriod === 'monthly' && (
        <Stack gap={1}>
          <IntlTypography
            variant="h6"
            group="portfolioInsights"
            labelKey="tab-scheduler-frequency-send-on"
          />
          <Select
            value={dayOfMonth}
            label="Frequency"
            onChange={(event) => setDayOfMonth([event.target.value as DayOfMonth])}
            sx={styles.frequencySelect}
          >
            {monthlyPeriods.map((item) => (
              <MenuItem key={item.label} value={item.value}>
                <Typography sx={styles.selectItemContent}>
                  <FormattedMessage id={item.label} />
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}

      {/* MONTH - Starting from */}
      {/* YEAR - Send on */}
      {frequencyPeriod !== 'weekly' && (
        <Stack sx={{ height: '60px', mt: 1 }}>
          <IntlTypography
            variant="h6"
            group="portfolioInsights"
            labelKey={
              frequencyPeriod === 'monthly'
                ? 'tab-scheduler-frequency-starting-from'
                : 'tab-scheduler-frequency-send-on'
            }
          />
          <Button
            variant="outlined"
            size="large"
            sx={{
              ...styles.datePickerButton,
              ...(datePickerPaperVisible && styles.datePickerButtonOpened),
            }}
            disableRipple
            startIcon={<CalendarToday />}
            endIcon={
              datePickerPaperVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
            onClick={toggleDatePickerPaper}
          >
            {datePickerButtonLabel}
          </Button>
          {datePickerPaperVisible && (
            <Paper variant="outlined" sx={styles.datePickerPaper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CalendarPicker
                  views={['day']}
                  minDate={getTomorrow()}
                  maxDate={getTodayDifferentYear(+1)}
                  date={selectedDate}
                  onChange={(newValue) => {
                    if (newValue) setSelectedDate(newValue)

                    toggleDatePickerPaper()
                  }}
                  onViewChange={(newView) => setDatePickerView(newView)}
                  onYearChange={() => setDatePickerView('day')}
                  onMonthChange={() => setDatePickerView('day')}
                  view={datePickerView}
                />
              </LocalizationProvider>
            </Paper>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default Frequency
