import { risikaPagesConfigs } from 'pages'

class FuseUtils {
  static setRoutes(config) {
    let routes = [...config.routes]

    if (config.settings || config.auth) {
      routes = routes.map((route) => {
        let auth = config.auth ? [...config.auth] : []
        auth = route.auth ? [...auth, ...route.auth] : auth
        return {
          ...route,
          settings: { ...config.settings, ...route.settings },
          auth,
        }
      })
    }

    return [...routes]
  }

  static generateRoutesFromConfigs(configs) {
    let allRoutes = []
    configs.forEach((config) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config)]
    })
    return allRoutes
  }
}

const routeConfigs = [
  ...risikaPagesConfigs, // Risika content
]

export const routes = [...FuseUtils.generateRoutesFromConfigs(routeConfigs)]

export default routes
