import React from 'react'
import { Box, SvgIcon, SvgIconProps } from '@mui/material'
import styles from './CardWithColorAndIcon.styles'
import { SxProps } from '@mui/system'

type Props = {
  color: string
  icon: React.ReactElement<SvgIconProps>
  children: React.ReactNode
  sx?: SxProps
}

const CardWithColorAndIcon = ({ color, icon, children, sx }: Props) => {
  return (
    <Box data-cy="policy-evaluation" sx={{ ...styles.main(color), ...sx } as SxProps}>
      {children}
      <SvgIcon sx={styles.middleIcon}>{icon}</SvgIcon>
    </Box>
  )
}

export default CardWithColorAndIcon
