import {
  ConsumptionApprovedOptionType,
  CreditOverrideCompanyData,
  CreditOverrideFormState,
  CreditOverrideGeneralFormState,
  PriorityOptionType,
} from './CreditOverrideForm.types'

export const getDefaultState = ({
  customerName,
  localId,
  industryType,
  consumptionApproved,
}: CreditOverrideCompanyData): CreditOverrideFormState => ({
  priority: 'medium',
  customerName: customerName,
  localId: localId,
  industryType: industryType,
  existingCustomer: '',
  customerNumber: '',
  energyType: '',
  product: '',
  contractStartDate: null,
  contractEndDate: null,
  annualConsumption: 0,
  annualMargin: 0,
  paymentTerms: '',
  collateral: '',
  consumptionApproved: consumptionApproved,
  customerRelations: '',
  mtmStatus: '',
  notes: '',
})

export const getDefaultGeneralState = ({
  customerName,
  localId,
  industryType,
}: CreditOverrideCompanyData): CreditOverrideGeneralFormState => ({
  priority: 'medium',
  customerName: customerName,
  localId: localId,
  industryType: industryType,
  existingCustomer: '',
  customerNumber: '',
  contractStartDate: null,
  contractEndDate: null,
  paymentTerms: '',
  notes: '',
})

export const priorityOptions: PriorityOptionType[] = [
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
  { value: 'low', label: 'Low' },
]

export const consumptionApprovedOptions: ConsumptionApprovedOptionType[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]

export const existingCustomerOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]
