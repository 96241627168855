import { colors } from 'configs'
import { defaultStackedBarChartProperties } from 'configs/charts'
import intl from 'localization/components'

export const keyFiguresByIndustryData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: {
      DK: 'NEWTON',
      SE: 'BOHR',
      NO: 'BOHR',
    },
    categories: [
      { category: 'SCORE', function: 'AVERAGE' },
      { category: 'CURRENT_RATIO', function: 'AVERAGE' },
      { category: 'RETURN_ON_ASSETS', function: 'AVERAGE' },
      { category: 'SOLIDITY_RATIO', function: 'AVERAGE' },
    ],
    group_by: [{ category: 'NACE', level: 1 }],
  },
  chartProperties: {
    ...defaultStackedBarChartProperties,
    colors: [
      colors.risikaTertiaryDark,
      colors.risikaTertiaryDim,
      colors.risikaTertiary,
      colors.risikaTertiaryLight,
      colors.risikaBlueDark,
      colors.risikaBlue,
      colors.risikaBlueLight,
    ],
    cartesianGrid: false,
    title: intl.observationalLists('key-figures-industry-title'),
    height: 390,
    tooltipWithPercents: false,
    valueType: 'KEY_FIGURES',
    bar: {
      label: false,
    },
  },
}

export type KeyFiguresByIndustryData = typeof keyFiguresByIndustryData
