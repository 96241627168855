import { colors } from 'configs'

export const styles = () => ({
  default: {
    color: '#fff',
    backgroundColor: colors?.risika,
  },
  inverse: {
    color: '#262626',
    backgroundColor: colors?.risikaLight,
  },
})
