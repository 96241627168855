export const getFinancialFigures = (getFinancialNumbers) => {
  return [
    {
      label: 'revenue',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('revenue'),
      isDash: false,
      type: 'array',
    },
    {
      label: 'grossResult',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('gross_result'),
      isDash: false,
      type: 'array',
    },
    {
      label: 'staffExpenses',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('staff_expenses'),
      isDash: true,
    },
    {
      label: 'EBITDA',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('ebitda'),
      isDash: false,
    },
    {
      label: 'depreciation',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('depreciation'),
      isDash: true,
    },
    {
      label: 'EBIT',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('ebit'),
      isDash: false,
    },
    {
      label: 'interestIncome',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('interest_income'),
      isDash: true,
    },
    {
      label: 'interestExpenses',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('interest_expenses'),
      isDash: true,
    },
    {
      label: 'otherNetFinancialIncome',
      availableIn: ['DK', 'SE', 'NO', 'FI'],
      data: getFinancialNumbers('other_net_financial_income'),
      isDash: true,
    },
    {
      label: 'ordinaryProfit',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('ordinary_profit'),
      isDash: false,
    },
    {
      label: 'taxExpenses',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('tax_expenses'),
      isDash: true,
    },
    {
      label: 'profitLoss',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('profit_loss'),
      isDash: false,
      type: 'array',
    },
    {
      label: '',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('tax_expenses'),
      isDash: false,
      isSpace: true,
    },
    {
      label: 'intangibleAssets',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('intangible_assets'),
      isDash: true,
    },
    {
      label: 'propertyPlantAndEquipment',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('property_plant_and_equipment'),
      isDash: true,
    },
    {
      label: 'noncurrentFinancialAssets',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('noncurrent_financial_assets'),
      isDash: true,
    },
    {
      label: 'noncurrentAssets',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('noncurrent_assets'),
      isDash: false,
      type: 'array',
    },
    {
      label: 'inventories',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('inventories'),
      isDash: true,
    },
    {
      label: 'shortTermReceivablesFromGroupEnterprises',
      availableIn: ['DK', 'SE', 'NO'],
      data: getFinancialNumbers('short_term_receivables_from_group_enterprises'),
      isDash: true,
    },
    {
      label: 'otherShortTermReceivables',
      availableIn: ['DK', 'SE', 'NO', 'FI'],
      data: getFinancialNumbers('other_short_term_receivables'),
      isDash: true,
    },
    {
      label: 'shortTermReceivablesFromSalesAndServices',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('short_term_receivables_from_sales_and_services'),
      isDash: true,
    },
    {
      label: 'cash',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('cash'),
      isDash: true,
    },
    {
      label: 'currentFinancialAssets',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('current_financial_assets'),
      isDash: true,
    },
    {
      label: 'currentAssets',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('current_assets'),
      isDash: false,
      type: 'array',
    },

    {
      label: 'assets',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('assets'),
      isDash: false,
    },
    {
      label: '',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('assets'),
      isDash: false,
      isSpace: true,
    },
    {
      label: 'contributedCapital',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('contributed_capital'),
      isDash: true,
    },
    {
      label: 'retainedEarnings',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('retained_earnings'),
      isDash: true,
    },
    {
      label: 'reserves',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('reserves'),
      isDash: true,
    },
    {
      label: 'dividend',
      availableIn: ['DK', 'SE', 'NO', 'FI'],
      data: getFinancialNumbers('dividend')?.map((x) => (x <= 0 ? null : x)),
      isDash: true,
    },
    {
      label: 'equity',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('equity'),
      isDash: false,
      type: 'array',
    },
    {
      label: 'provisions',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('provisions'),
      isDash: false,
    },
    {
      label: 'longTermDebt',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('long_term_debt'),
      isDash: false,
    },
    {
      label: 'shortTermDebtToBanks',
      availableIn: ['DK', 'SE', 'NO', 'FI'],
      data: getFinancialNumbers('short_term_debt_to_banks'),
      isDash: true,
    },
    {
      label: 'shortTermDebtToGroupEnterprises',
      availableIn: ['DK', 'SE', 'NO', 'FI'],
      data: getFinancialNumbers('short_term_debt_to_group_enterprises'),
      isDash: true,
    },
    {
      label: 'shortTermTradePayables',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('short_term_trade_payables'),
      isDash: true,
    },
    {
      label: 'shortTermTaxPayables',
      availableIn: ['DK', 'SE', 'NO', 'FI'],
      data: getFinancialNumbers('short_term_tax_payables'),
      isDash: true,
    },
    {
      label: 'otherShortTermDebt',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('other_short_term_debt'),
      isDash: true,
    },
    {
      label: 'shortTermDebt',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('short_term_debt'),
      isDash: false,
    },
    {
      label: 'total-debt',
      availableIn: ['DK', 'SE', 'NO', 'UK', 'FI'],
      data: getFinancialNumbers('debt'),
      isDash: false,
      type: 'array',
    },
    {
      label: 'balance',
      availableIn: ['DK', 'SE', 'NO', 'DE', 'UK', 'FI'],
      data: getFinancialNumbers('assets'),
      isDash: false,
      type: 'array',
    },
  ]
}
