import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    padding: theme.spacing(3),
    backgroundColor: '#ddd',
    borderRadius: 5,
    fontSize: '1.6rem',
    boxShadow: (props: { error: boolean }) =>
      props.error ? `0 0 0 2px ${theme.palette.error.main}` : '',
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },
  },
  label: {
    fontSize: '1.6rem',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  helperText: {
    fontSize: '1.4rem',
    marginTop: theme.spacing(1),
  },
}))
