import { AvailableCountries, LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

type ParentAndChildrenDataType = {
  local_organization_id: { country: AvailableCountries; id: string | null }
  personal_id: string
}

type HierarchySummary = {
  children_count: number
  name: string
  parents_count: number
  children: ParentAndChildrenDataType[]
  parents: ParentAndChildrenDataType[]
}
async function hierarchySummary({ id, country }: LocalId): Promise<HierarchySummary> {
  const { data }: { data: HierarchySummary } = await risikaAPI.get(
    `/company/hierarchy/summary/${id}`,
    {
      microservice: 'data',
      country,
    }
  )
  return data
}

export default hierarchySummary
