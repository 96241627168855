import ConditionallyRender from './ConditionallyRender'
import { Tooltip } from '@mui/material'
import { handleComponentRenderRestrictions } from 'services/helpers'
import { useAccountStatus } from 'services/queries'

// type RestrictedComponentProps = {
//   component: string,
//   children: any,
//   placeholder?: any,
//   tooltip?: any,
//   tooltipPlacement?: string,
//   local_id?: LocalId,
// }

const RestrictedComponent = ({
  component,
  children,
  placeholder = null,
  tooltip = '',
  tooltipPlacement = 'top',
  local_id = {},
}) => {
  const accountStatusQuery = useAccountStatus()

  return (
    <ConditionallyRender
      condition={handleComponentRenderRestrictions({
        component,
        local_id,
        plan: accountStatusQuery.data?.subscription_plan,
      })}
      when={
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          <>{children}</>
        </Tooltip>
      }
      otherwise={placeholder}
    />
  )
}

export default RestrictedComponent
