import { makeStyles } from '@mui/styles'
import { TextField } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const useStyles = makeStyles({
  root: {
    margin: '0.75rem',
  },
  textField: {
    marginTop: '1rem',
  },
})

const CustomText = (props) => {
  const classes = useStyles()
  const { messageValue, setMessageValue } = props

  return (
    <div className={classes.root}>
      <label htmlFor="custom-text">
        <FormattedMessage id={intl.creditCheck('share-report-message')} />
      </label>
      <TextField
        id="custom-text"
        className={classes.textField}
        autoFocus
        hintText="Message Field"
        fullWidth
        floatingLabelText="MultiLine and FloatingLabel"
        multiline
        rows={3}
        value={messageValue}
        variant="outlined"
        onChange={(e) => setMessageValue(e.target.value)}
      />
    </div>
  )
}

export default CustomText
