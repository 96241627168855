import { emphasize } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { theme } from 'styles-new/mui5-transition/theme'

const wrapper = {
  minHeight: 0,
  display: 'flex',
}

const wrapperSmall = {
  ...wrapper,
  marginBottom: 20,
}

const container = {
  display: 'flex',
  alignItems: 'center',
}

const containerSmall = {
  ...container,
  alignItems: 'start',
  flexDirection: 'column',
  gap: 10,
} as any

const type = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 12px',
  flexGrow: 1,
  minHeight: '100%',
  borderRadius: '5px 0 0 5px',
  color: theme.palette.grey[800],
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.grey[200]}`,
}

const typeSmall = {
  ...type,
  height: 44,
}

const options = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  color: theme.palette.grey[800],
  backgroundColor: theme.palette.grey[200],
  '&:focus-within': {
    backgroundColor: emphasize(theme.palette.grey[400]),
  },
}

const values = {
  display: 'flex',
  alignItems: 'center',
  padding: '0px 12px',
  height: '100%',
  borderRadius: '0 5px 5px 0',
  color: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[200]}`,
}

const valuesSmall = {
  ...values,
  padding: 0,
  border: 'none',
}

const deleteButton = {
  color: '#245157',
}

const extraMargin = {
  marginBottom: '1rem',
}

export const useStyles = makeStyles({
  wrapper,
  wrapperSmall,
  container,
  containerSmall,
  type,
  typeSmall,
  options,
  values,
  valuesSmall,
  deleteButton,
  extraMargin,
})
