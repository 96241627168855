import { useParams } from 'react-router-dom'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import numeral from 'numeral'
import {
  APPROVE_CREDIT_MAX,
  ADJUST_CREDIT_MAX,
  APPROVE_WITH_VALUE,
} from '../../PolicyBuilder.model'
import { CREDIT_POLICY_CURRENCIES } from 'globalVariables'

function ApproveCreditContent({ data }: { data: any }) {
  const { country } = useParams<{ country: 'DK' | 'FI' | 'SE' | 'NO' }>()
  const { outcome, percentage, lower_limit, upper_limit, value } = data
  const currency = CREDIT_POLICY_CURRENCIES[country]

  if (!data) {
    return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }
  switch (outcome) {
    case APPROVE_CREDIT_MAX:
      return (
        <FormattedMessage id={intl.creditPolicyNew('approve-credit-max-node-text')} />
      )
    case ADJUST_CREDIT_MAX:
      return (
        <>
          <FormattedMessage
            id={intl.creditPolicyNew('adjust-credit-max-node-text')}
            values={{
              percentage,
              lineBreak: <br />,
              creditMax: (
                <b>
                  <FormattedMessage
                    id={intl.creditPolicyNew('approve-credit-max-node-text-credit-max')}
                  />
                </b>
              ),
            }}
          />
          {(lower_limit || upper_limit) && (
            <FormattedMessage
              id={intl.creditPolicyNew('adjust-credit-max-node-text-currency')}
              values={{
                currency: currency,
                amount: lower_limit
                  ? numeral(lower_limit).format('0,0')
                  : numeral(upper_limit).format('0,0'),
                limit: lower_limit ? (
                  <b>
                    <FormattedMessage
                      id={intl.creditPolicyNew(
                        'approve-credit-max-node-text-not-lower-than'
                      )}
                    />
                  </b>
                ) : (
                  <b>
                    <FormattedMessage
                      id={intl.creditPolicyNew(
                        'approve-credit-max-node-text-not-greater-than'
                      )}
                    />
                  </b>
                ),
              }}
            />
          )}
        </>
      )
    case APPROVE_WITH_VALUE:
      return (
        <FormattedMessage
          id={intl.creditPolicyNew('approve-with-value-node-text')}
          values={{
            value: numeral(value).format('0,0'),
            currency: currency,
            creditMax: (
              <b>
                <FormattedMessage
                  id={intl.creditPolicyNew('approve-credit-max-node-text-credit-max')}
                />
              </b>
            ),
          }}
        />
      )
    default:
      return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }
}

export default ApproveCreditContent
