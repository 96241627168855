import React, { useEffect, useMemo, useState } from 'react'
import {
  Dialog,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  ButtonSecondary,
  ButtonTertiary,
  DateInputRange,
  DropdownSelect,
  InputArea,
  InputField,
} from 'components-new'
import CloseIcon from '@mui/icons-material/Close'
import RequestSuccessModal from '../RequestSuccessModal'
import RequestErrorModal from '../RequestErrorModal'
import { getLatestOverrideRequest, postData } from './airtableCreditOverrideRequests'
import moment from 'moment'
import {
  CreditOverrideFormProps,
  PriorityValue,
  CreditOverrideFormState,
  CreditOverrideFormStateKey,
} from './CreditOverrideForm.types'

import {
  consumptionApprovedOptions,
  existingCustomerOptions,
  priorityOptions,
  getDefaultState,
} from './CreditOverrideForm.model'
import { convertCurrency } from 'services/api'
import {
  availableCountriesAndCurrencies,
  eurCountries,
} from 'components-new/inputs/CurrencyAutocomplete/CurrencyAutocomplete.model'
import { useUserSettingsField } from 'services/queries'
import { CurrencyObj } from 'services/types'
import { formatMomentDate, isStartDateBeforeEndDate } from 'utils/dateUtils'

const CreditOverrideForm = ({
  open,
  onClose,
  companyBasicsData,
  policyOutcome,
  risikaRecommendation,
  risikaScore,
  userName,
  userEmail,
  currency,
  disableRequestButton,
}: CreditOverrideFormProps) => {
  const [state, setState] = useState<CreditOverrideFormState>(
    getDefaultState({ ...companyBasicsData, consumptionApproved: 'no' })
  )

  const { data: selectedCurrency } =
    useUserSettingsField<CurrencyObj>('selected_currency')
  const [isFormValid, setIsFormValid] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const isSmallScreen = useMediaQuery('(max-width: 600px)')
  const shouldFormShrink = useMediaQuery('(max-width: 1100px)')
  const disabledFields = useMemo(() => {
    return {
      customerName: !!companyBasicsData.customerName,
      localId: !!companyBasicsData.localId,
      industryType: !!companyBasicsData.industryType,
    }
  }, [companyBasicsData])

  useEffect(() => {
    if (companyBasicsData.localId) {
      const getDataFromPreviousRequest = async () => {
        const data = await getLatestOverrideRequest({
          localId: companyBasicsData.localId,
          latest: true,
        })
        if (data?.fields) {
          setState({
            priority: data.fields.priority,
            customerName: data.fields.customerName,
            localId: data.fields.localId,
            industryType: data.fields.industryType,
            existingCustomer: data.fields.existingCustomer,
            customerNumber: data.fields.customerNumber,
            energyType: data.fields.energyType,
            product: data.fields.product,
            contractStartDate: data.fields.contractStartDate,
            contractEndDate: data.fields.contractEndDate,
            annualConsumption: data.fields.annualConsumption,
            annualMargin: data.fields.annualMargin,
            paymentTerms: data.fields.paymentTerms,
            collateral: data.fields.collateral,
            consumptionApproved: data.fields.consumptionApproved,
            customerRelations: data.fields.customerRelations,
            mtmStatus: data.fields.mtmStatus,
            notes: data.fields.notes,
          })
        }
      }
      getDataFromPreviousRequest()
    }
  }, [companyBasicsData.localId])

  useEffect(() => {
    let isValid = true
    Object.keys(state)
      .filter((key) => key !== 'customerRelations' && key !== 'mtmStatus')
      .forEach((item) => {
        if (!state[item as CreditOverrideFormStateKey]) {
          isValid = false
        }
      })

    if (!isStartDateBeforeEndDate(state.contractStartDate, state.contractEndDate)) {
      isValid = false
    }
    setIsFormValid(isValid)
  }, [state])

  function handleChangeState<T = string>(key: keyof CreditOverrideFormState, value: T) {
    setState((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    const contractStartDate =
      typeof startDate === 'object' ? formatMomentDate(startDate) : startDate
    const contractEndDate =
      typeof endDate === 'object' ? formatMomentDate(endDate) : endDate

    setState((prevState) => ({
      ...prevState,
      contractStartDate,
      contractEndDate,
    }))
  }

  const getNumberInCountryCurrency = async (number: number | null) => {
    if (number === null) {
      return number
    }

    const countryCurrency = availableCountriesAndCurrencies.find(
      (country) =>
        country.country ===
        (eurCountries.includes(companyBasicsData.country.toUpperCase())
          ? 'EU'
          : companyBasicsData.country.toUpperCase())
    )?.currency
    if (!countryCurrency) {
      return risikaRecommendation
    }
    const response = await convertCurrency({
      base: selectedCurrency?.currency ?? 'DKK',
      to: countryCurrency,
      value: number,
    })
    return +Number.parseFloat(response.toString()).toFixed(2)
  }
  const handleSubmit = async () => {
    const data = {
      records: [
        {
          fields: {
            ...state,
            annualConsumption: +(state.annualConsumption ?? 0),
            annualMargin: +(state.annualMargin ?? 0),
            createdAt: new Date(),
            policyOutcome: policyOutcome.outcome,
            policyCreditLimit: await getNumberInCountryCurrency(
              +policyOutcome.creditLimit
            ),
            policyId: `${policyOutcome.policyId}`,
            risikaRecommendation: await getNumberInCountryCurrency(+risikaRecommendation),
            risikaScore: risikaScore ? risikaScore.toString() : '',
            currency,
            country: companyBasicsData.country.toLowerCase(),
            companyHash: companyBasicsData.companyHash,
            userName,
            userEmail,
            requestStatus: 'pending',
          },
        },
      ],
    }
    postData(data)
      .then(() => {
        setSuccessModal(true)
        disableRequestButton()
      })
      .catch((error) => {
        setErrorModal(true)
      })
  }

  const handleCancel = () => {
    setState(getDefaultState(companyBasicsData))
    onClose()
  }

  const handleSuccessModalClose = () => {
    setSuccessModal(false)
    onClose()
  }

  return (
    <Dialog
      data-cy="request-override-form"
      maxWidth={false}
      open={open}
      onClose={onClose}
    >
      <Paper elevation={3}>
        <Stack
          width={shouldFormShrink ? 'unset' : 736}
          gap={4}
          p={6}
          sx={{ margin: 'auto', backgroundColor: 'common.white' }}
        >
          <Stack>
            <Stack>
              <IconButton
                onClick={onClose}
                sx={{ alignSelf: 'flex-end', fontSize: 28, color: 'primary.main' }}
                data-cy="close-button"
              >
                <CloseIcon fontSize="inherit" color="inherit" />
              </IconButton>
            </Stack>
            <Typography variant="h5">Request an override</Typography>
          </Stack>
          <Stack
            width={isSmallScreen ? 1 : 0.5}
            gap={4}
            paddingRight={isSmallScreen ? 0 : 3}
          >
            <DropdownSelect<PriorityValue>
              label={'Priority'}
              options={priorityOptions}
              value={state.priority}
              setValue={(value: PriorityValue) => handleChangeState('priority', value)}
              data-cy="priority"
            />
          </Stack>
          <Stack direction={isSmallScreen ? 'column' : 'row'} gap={5}>
            <InputField
              label={'Customer name'}
              value={state.customerName}
              handleChange={(value) => handleChangeState('customerName', value)}
              disabled={disabledFields.customerName}
              data-cy="customer-name"
            />
            <InputField
              label={'Organisation number/CVR'}
              value={state.localId}
              handleChange={(value) => handleChangeState('localId', value)}
              disabled={disabledFields.localId}
              data-cy="localId"
            />
          </Stack>
          <Stack direction={isSmallScreen ? 'column' : 'row'} gap={5}>
            <InputField
              label={'Industry type'}
              value={state.industryType}
              handleChange={(value) => handleChangeState('industryType', value)}
              disabled={disabledFields.industryType}
              data-cy="industry-type"
            />
            <DropdownSelect
              label={'Existing Customer'}
              placeholder={'Please select'}
              options={existingCustomerOptions}
              value={state.existingCustomer}
              setValue={(value: string) => handleChangeState('existingCustomer', value)}
              data-cy="existing-customer"
            />
          </Stack>
          <Stack
            width={isSmallScreen ? 1 : 0.5}
            gap={5}
            paddingRight={isSmallScreen ? 0 : 3}
          >
            {}
            <InputField
              label={'Customer number'}
              value={state.customerNumber}
              handleChange={(value) => handleChangeState('customerNumber', value)}
              data-cy="customer-number"
            />
          </Stack>
          <Stack direction={isSmallScreen ? 'column' : 'row'} gap={5}>
            <InputField
              label={'Energy type'}
              value={state.energyType}
              handleChange={(value) => handleChangeState('energyType', value)}
              data-cy="energy-type"
            />
            <InputField
              label={'Product'}
              value={state.product}
              handleChange={(value) => handleChangeState('product', value)}
              data-cy="product"
            />
          </Stack>
          <DateInputRange
            startDate={state.contractStartDate as Date}
            endDate={state.contractEndDate as Date}
            onChange={handleDateChange}
            labelStart={'Contract start date'}
            labelEnd={'Contract end date'}
            data-cy="contract-date"
            isSmallScreen={isSmallScreen}
          />
          <Stack direction={isSmallScreen ? 'column' : 'row'} gap={5}>
            <InputField
              label={'Annual consumption (MWh/NM3)'}
              inputMode="numeric"
              value={state.annualConsumption ?? 0}
              handleChange={(value) => handleChangeState('annualConsumption', value)}
              data-cy="annual-consumption"
            />
            <InputField
              addOn={currency}
              inputMode="numeric"
              addOnPosition={'end'}
              label={'Annual margin'}
              value={state.annualMargin ?? 0}
              handleChange={(value) => handleChangeState('annualMargin', value)}
              data-cy="annual-margin"
            />
          </Stack>
          <Stack direction={isSmallScreen ? 'column' : 'row'} gap={5}>
            <InputField
              addOn={'Days'}
              inputMode="numeric"
              addOnPosition={'end'}
              label={'Payment terms'}
              value={state.paymentTerms}
              handleChange={(value) => handleChangeState('paymentTerms', value)}
              data-cy="payment-terms"
            />
            <InputField
              label={'Collateral'}
              value={state.collateral}
              handleChange={(value) => handleChangeState('collateral', value)}
              data-cy="collateral"
            />
          </Stack>
          <Stack
            width={isSmallScreen ? 1 : 0.5}
            gap={4}
            paddingRight={isSmallScreen ? 0 : 3}
          >
            <DropdownSelect
              placeholder={'Please select'}
              label={'Consumption approved'}
              options={consumptionApprovedOptions}
              value={state.consumptionApproved || 'no'}
              setValue={(value: string) =>
                handleChangeState('consumptionApproved', value)
              }
              data-cy="consumption-approved"
            />
          </Stack>
          <InputArea
            value={state.customerRelations}
            setValue={(value) => handleChangeState('customerRelations', value)}
            label={
              'Customer relations to other companies in Energi Danmark Group (optional)'
            }
            data-cy="customer-relations"
          />
          <InputArea
            value={state.mtmStatus}
            setValue={(value) => handleChangeState('mtmStatus', value)}
            label={'MTM status (optional)'}
            data-cy="mtm-status"
          />
          <InputArea
            placeholder={
              "Add any additional information to support your request and help get it approved. Detail out information about the customer, any previous relation, anything you know that could inform the Finance team's decision..."
            }
            value={state.notes}
            setValue={(value) => handleChangeState('notes', value)}
            label={'Notes'}
            data-cy="notes"
          />
          <Stack direction="row" gap={4}>
            <ButtonSecondary
              data-cy="submit-button"
              disabled={!isFormValid}
              onClick={handleSubmit}
            >
              Submit
            </ButtonSecondary>
            <ButtonTertiary data-cy="cancel-button" onClick={handleCancel}>
              Cancel
            </ButtonTertiary>
          </Stack>
          <RequestSuccessModal
            open={successModal}
            setOpen={setSuccessModal}
            onClose={() => onClose()}
            handleClose={handleSuccessModalClose}
          />
          <RequestErrorModal open={errorModal} setOpen={setErrorModal} />
        </Stack>
      </Paper>
    </Dialog>
  )
}

export default CreditOverrideForm
