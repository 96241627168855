import callBackendAPI from 'services/api/helpers/backendAPI'
import { CustomerData } from './getUser'

/******************
 * Fetch from API *
 ******************/

type UpdateCompany = {
  companyId: string | number
  domain?: string
  phoneNumber?: string
  model?: string
}

const updateCompany = ({ domain }: UpdateCompany): Promise<CustomerData> => {
  return callBackendAPI(`/customer`, {
    method: 'put',
    data: {
      domain,
    },
  }).then(({ data }) => {
    return data
  })
}

export default updateCompany
