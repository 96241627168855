import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { Box, Typography } from '@mui/material'
import { ColorLevelT } from 'types/general'
import classes from 'pages/credit-check/styles/credit-field'

type Props = {
  value: number | null
  isError: boolean
  currency: string
  colorLvl: ColorLevelT
  isGrey: boolean
}

function CreditValue({ value = null, currency = '', isError, colorLvl, isGrey }: Props) {
  const reactIntl = useIntl()
  return (
    <Box sx={classes.outerFrame(colorLvl, isGrey)}>
      <Typography>
        <FormattedMessage id={intl.mainRecommendation('credit-max')} />
      </Typography>
      <Box fontWeight="bold">
        <Typography
          data-cy="credit-max"
          variant="h5"
          sx={classes.coloredText(colorLvl, isGrey)}
        >
          {isError && (
            <FormattedMessage
              id={intl.mainRecommendation('recommendation-credit-unknown')}
            />
          )}
          {Number.isInteger(value) &&
            `${reactIntl.formatNumber(Math.floor(value || 0))} ${currency}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default CreditValue
