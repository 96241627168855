import {
  CLEAR_ERROR,
  RENAME_MONITORING_LIST,
  SAVE_ALL_MONITORING_LISTS_FAILED,
  SAVE_ALL_MONITORING_LISTS_SUCCESS,
  SAVE_DATA_FOR_LIST_SUCCESS,
  SAVE_ENTRIES_FOR_LIST_FAILED,
  SAVE_RELEVANT_CHANGES_FOR_LIST_FAILED,
  SAVE_RELEVANT_CHANGES_FOR_LIST_SUCCESS,
  SERVER_ERROR,
  SET_LIST_LOADING,
  SET_LIST_STATUS,
  SET_LOADING,
  SET_SELECTED_LIST,
  SET_TEXT,
  SUBMIT_ADD_TO_MONITORING_LIST,
  SUBMIT_EDIT_MONITORING_LIST,
  SUBMIT_FETCH_ALL_MONITORING_LISTS,
  SUBMIT_FETCH_LIST_DATA_BY_ID,
  SUBMIT_FETCH_LIST_ENTRIES_BY_ID,
  SUBMIT_REMOVE_COMPANY_FROM_MONITORING_LIST,
  SUBMIT_SHOW_ALL_MONITORING_LISTS,
  SUBMIT_UPDATE_LISTS_RISK_ASSESMENT_BY_ID,
  UPDATE_RISK_ASSESMENT_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FREQUENCY,
} from './constants'

// type dataTypeProp1 = {
// prop1: someCoolType,
// }

// type SaveRelevantChangesData = {
//   listId: string | number,
//   changes: Array<any>,
// }
// type ListLoading = {
//   listId: number,
//   isLoading: boolean,
// }
// type ListStatus = {
//   listId: number,
//   status: string,
// }
// type SaveData = {
//   listId: string | number,
//   entries: Array<Object>,
//   count: number,
//   changes: Array<any>,
// }
// type SaveEntries = {
//   listId: string | number,
//   entries: Array<Object>,
//   count: number,
// }

/**************
 * Saga calls *
 **************/
export const submitFetchAllMonitoringLists = (listsLength) => {
  return {
    type: SUBMIT_FETCH_ALL_MONITORING_LISTS,
    payload: listsLength,
    status: 'LOADING',
  }
}
export const submitShowAllMonitoringLists = () => {
  return {
    type: SUBMIT_SHOW_ALL_MONITORING_LISTS,
    payload: null,
    status: 'LOADING',
  }
}

export const submitFetchListDataById = (listId) => {
  return {
    type: SUBMIT_FETCH_LIST_DATA_BY_ID,
    payload: { listId },
    status: 'LOADING',
  }
}
export const submitFetchListEntriesById = (listId) => {
  return {
    type: SUBMIT_FETCH_LIST_ENTRIES_BY_ID,
    payload: { listId },
    status: 'LOADING',
  }
}

export const submitAddToMonitoringList = (listId, entries, text, textFail, refetch) => {
  return {
    type: SUBMIT_ADD_TO_MONITORING_LIST,
    payload: { listId, entries, text, textFail, refetch },
    status: 'LOADING',
  }
}
export const submitRemoveCompanyFromRiskMonitoringList = (
  listId,
  entries,
  isMoving,
  refetch
) => {
  return {
    type: SUBMIT_REMOVE_COMPANY_FROM_MONITORING_LIST,
    payload: { listId, entries, isMoving, refetch },
    status: 'LOADING',
  }
}
export const submitUpdateListsRiskAssesmentByID = (listId) => {
  return {
    type: SUBMIT_UPDATE_LISTS_RISK_ASSESMENT_BY_ID,
    payload: { listId },
    status: 'LOADING',
  }
}
export const submitEditMonitoringList = (listId, listName) => {
  return {
    type: SUBMIT_EDIT_MONITORING_LIST,
    payload: { listId, listName },
    status: 'LOADING',
  }
}
/*****************
 * Reducer calls *
 *****************/

export const saveAllListsSuccess = (lists) => {
  return {
    type: SAVE_ALL_MONITORING_LISTS_SUCCESS,
    payload: lists,
    status: 'SUCCESS',
  }
}

export const setListStatus = ({ listId, status }) => {
  return {
    type: SET_LIST_STATUS,
    payload: { listId, status },
    status: 'SUCCESS',
  }
}
export const setListLoading = ({ listId, isLoading }) => {
  return {
    type: SET_LIST_LOADING,
    payload: { listId, isLoading },
    status: 'SUCCESS',
  }
}
export const setSelectedList = (listId) => {
  return {
    type: SET_SELECTED_LIST,
    payload: listId,
    status: 'SUCCESS',
  }
}
export const saveRelevantChangesSuccess = ({ listId, changes }) => {
  return {
    type: SAVE_RELEVANT_CHANGES_FOR_LIST_SUCCESS,
    payload: { listId, changes },
    status: 'SUCCESS',
  }
}
export const saveDataForListSuccess = ({ listId, entries, count, changes }) => {
  return {
    type: SAVE_DATA_FOR_LIST_SUCCESS,
    payload: { listId, entries, count, changes },
    status: 'SUCCESS',
  }
}
export const saveEntriesForListSuccess = ({ listId, entries, count }) => {
  return {
    type: SAVE_DATA_FOR_LIST_SUCCESS,
    payload: { listId, entries, count },
    status: 'SUCCESS',
  }
}
export const setText = (text) => ({
  type: SET_TEXT,
  payload: { text },
})
export const updateListsRiskAssesmentSuccess = (data, listId) => ({
  type: UPDATE_RISK_ASSESMENT_SUCCESS,
  payload: { data, listId },
})
export const renameMonitoringList = (listId, listName) => ({
  type: RENAME_MONITORING_LIST,
  payload: { listName, listId },
})
/************
 * Failures *
 ************/

export const saveEntriesForListFailed = ({ listId }) => {
  return {
    type: SAVE_ENTRIES_FOR_LIST_FAILED,
    payload: { listId },
    status: 'FAILURE',
  }
}

export const saveRelevantChangesFailed = () => {
  return {
    type: SAVE_RELEVANT_CHANGES_FOR_LIST_FAILED,
    payload: null,
    status: 'FAILURE',
  }
}
export const saveAllListsFailed = () => {
  return {
    type: SAVE_ALL_MONITORING_LISTS_FAILED,
    payload: null,
    status: 'FAILURE',
  }
}
export const updateSubscription = (options, id) => ({
  type: UPDATE_SUBSCRIPTION,
  options,
  id,
})

export const updateSubscriptionFrequency = (frequency, id) => ({
  type: UPDATE_SUBSCRIPTION_FREQUENCY,
  frequency,
  id,
})

// export const  = (data: dataTypes): AsyncAction<dataTypes> => {
//   return {
//     type: CONSTANT_NAME_2,
//     payload: data,
//     status: 'SUCCESS',
//   }
// }
export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
})

// NEW ERROR HANDLING

export const serverError = (error) => ({
  type: SERVER_ERROR,
  payload: error,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})
