import { Box, ListItemIcon } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { NavigationItemTitle } from './types'
import { StyledListItemText, StyledListItemButton } from 'styles/components/sidebar'

type ListItemComponentProps = {
  title: NavigationItemTitle
  url: string
  icon: React.ReactElement
  disabled?: boolean
  externalLink?: boolean
  isFreemiumRestricted?: boolean
  handleOpenSearchInstead: (title: NavigationItemTitle) => void
  isSmall: boolean
  subUrls?: string[]
}

export const ListItemComponent = ({
  url,
  disabled,
  isFreemiumRestricted,
  handleOpenSearchInstead,
  title,
  icon,
  isSmall,
  subUrls = [],
}: ListItemComponentProps) => {
  const currentPath = location.pathname
  const checkSubUrls = (subUrl: string) => currentPath.includes(subUrl)
  return (
    <StyledListItemButton
      tabIndex={-1}
      selected={currentPath.includes(url) || subUrls.some(checkSubUrls)}
      onClick={() => (disabled ? handleOpenSearchInstead(title) : null)}
      disabled={isFreemiumRestricted}
      data-cy={`navigation-button-${title}`}
    >
      <ListItemIcon>
        {icon}
        <Box mt={isSmall ? 0 : 1} textAlign={isSmall ? 'left' : 'center'}>
          <StyledListItemText>
            <FormattedMessage id={intl.navigation(title)} />
          </StyledListItemText>
        </Box>
      </ListItemIcon>
    </StyledListItemButton>
  )
}
