import { colors } from 'configs'

export const model = [
  {
    risk: 'LOW',
    value: 0,
    fill: colors.riskIntelligence.lowRisk,
    titleText: 'Low risk',
    subTitleText: '',
    key: 'LOW',
  },
  {
    risk: 'MEDIUM',
    value: 0,
    fill: colors.riskIntelligence.mediumRisk,
    titleText: 'Medium risk',
    subTitleText: '',
    key: 'MEDIUM',
  },
  {
    risk: 'HIGH',
    value: 0,
    fill: colors.riskIntelligence.highRisk,
    titleText: 'High risk',
    subTitleText: '',
    key: 'HIGH',
  },
  {
    risk: 'NONE',
    value: 0,
    fill: 'darkgrey',
    titleText: 'Unknown risk',
    subTitleText: '',
    key: 'UNKNOWN',
  },
]
