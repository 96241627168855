import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Stack } from '@mui/material'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { ActionTemplatePropsType } from './Actions.types'
import { InputArea } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const ActionTemplate = (
  { setIsConfirmEnabled, label, apiData }: ActionTemplatePropsType,

  ref: React.ForwardedRef<SubmitRef>
) => {
  const [description, setDescription] = useState(apiData?.notes ?? '')
  const { state, actions } = useBuilder()

  useEffect(() => setIsConfirmEnabled(true), [setIsConfirmEnabled])

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.setNodeApiData({
          nodeId: state.actionPanelData.nodeId,
          data: { notes: description },
        })
      },
    }),
    [actions, description, state.actionPanelData.nodeId]
  )
  return (
    <Stack spacing={2}>
      <InputArea
        label={
          <FormattedMessage
            id={intl.creditPolicyNew('reject-credit-description-label')}
          />
        }
        value={description}
        setValue={setDescription}
      />
    </Stack>
  )
}

export default React.forwardRef(ActionTemplate)
