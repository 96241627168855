import { ButtonPrimary, DropdownSelect } from 'components-new'
import { Typography, Box } from '@mui/material'
import { RoleCellProps } from '../../UserTable.types'
import { roleOptions } from '../../UserTable.model'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { resendMail } from 'services/api'
import { useSnackbar } from 'notistack'

const RolesCell = ({
  user,
  selectedUser,
  isSelected,
  disabledButton,
  handleUpdateUserRole,
}: RoleCellProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const resendEmail = () => {
    resendMail(user.email)
      .then((res) => {
        enqueueSnackbar(<FormattedMessage id={intl.settings('mail-send')} />, {
          variant: 'success',
        })
      })
      .catch((err) => {
        enqueueSnackbar(<FormattedMessage id={intl.settings('mail-failed-to-send')} />, {
          variant: 'error',
        })
      })
  }
  if (selectedUser && isSelected) {
    return (
      <DropdownSelect
        options={roleOptions}
        value={selectedUser.roles[0].value}
        setValue={handleUpdateUserRole}
      />
    )
  }

  if (!user.isVerified) {
    return (
      <Box p={2}>
        <ButtonPrimary disabled={disabledButton} onClick={resendEmail}>
          <FormattedMessage id={intl.auth('resend-email')} />
        </ButtonPrimary>
      </Box>
    )
  }

  return <Typography variant="body2">{user.roles[0].label || '-'}</Typography>
}

export default RolesCell
