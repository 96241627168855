import callBackendAPI from 'services/api/helpers/backendAPI'
import { ResponseDataGetUser } from './getUser'

/******************
 * Fetch from API *
 ******************/

type RequestData = {
  name: string
  phoneNumber: string
}

const userChangeProfile = ({
  name,
  phoneNumber,
}: RequestData): Promise<ResponseDataGetUser> => {
  return callBackendAPI('/user', {
    method: 'put',
    data: {
      name,
      phone_number: phoneNumber ?? '',
    },
  })
}

export default userChangeProfile
