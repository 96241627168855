import { EnterpriseEvaluation } from 'services/api/endpoints/risikaAPI/evaluationPolicy'
import EnterpriseCreditPolicy from './EnterpriseCreditPolicy'
import { AxiosError } from 'axios'

type Props = {
  enterpriseEvaluation: EnterpriseEvaluation
  enterpriseEvaluationError: AxiosError
}
// Its needed because there are a few different conditions
const EcpFactory = ({ enterpriseEvaluation, enterpriseEvaluationError }: Props) => {
  if (
    enterpriseEvaluationError?.response?.status &&
    [402, 404].includes(enterpriseEvaluationError?.response?.status)
  ) {
    return null
  }
  // No policy found
  if (
    enterpriseEvaluationError?.response?.status &&
    [400].includes(enterpriseEvaluationError?.response?.status)
  ) {
    return (
      <EnterpriseCreditPolicy
        enterpriseEvaluation={{ credit_policy_action: 'default' }}
      />
    )
  }
  // Other errors (to be visualized)
  if (enterpriseEvaluationError) {
    return null
  }
  // Standard component visualization
  return <EnterpriseCreditPolicy enterpriseEvaluation={enterpriseEvaluation} />
}

export default EcpFactory
