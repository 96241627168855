// @flow

import { generateLocaleID as id } from './utils'

const intl = {
  generic: id('generic'),
  auth: id('auth'),
  navigation: id('navigation'),
  searchBusiness: id('search-business'),
  advancedFilters: id('advanced-filters'),
  creditCheck: id('credit-check'),
  companyHighlights: id('company-highlights'),
  companyRoles: id('company-roles'),
  companyInfo: id('company-info'),
  mainRecommendation: id('main-recommendation'),
  grading: id('grading'),
  financialRatio: id('financial-ratio'),
  relevantFinancialRatios: id('relevant-financial-ratios'),
  keyFinancialFigures: id('key-financial-figures'),
  visualScore: id('visual-score'),
  riskMonitoring: id('risk-monitoring'),
  riskMonitoringOverview: id('risk-monitoring-overview'),
  riskMonitoringNew: id('risk-monitoring-new'),
  relationOverview: id('relation-overview'),
  customerManagement: id('customer-management'),
  developer: id('developer'),
  settings: id('settings'),
  toolbar: id('toolbar'),
  snackbar: id('snackbar'),
  tooltip: id('tooltip'),
  table: id('table'),
  exportButton: id('export-button'),
  importButton: id('import-button'),
  relations: id('relations'),
  country: id('country'),
  auditorInfo: id('auditor-info'),
  confirmAction: id('confirm-action'),
  shareReport: id('share-report'),
  restriction: id('restriction'),
  notFound: id('not-found'),
  loginRegister: id('login-register'),
  userManagement: id('user-management'),
  riskMonitoringSubscription: id('risk-monitoring-subscription'),
  homePage: id('home-page'),
  home: id('home'),
  advancedSearch: id('advanced-search'),
  annualReportInformation: id('annual-report-information'),
  companyHierarchy: id('company-hierarchy'),
  reportBug: id('report-bug'),
  navigationTooltip: id('navigation-tooltip'),
  faq: id('faq'),
  news: id('news'),
  creditPolicy: id('credit-policy'),
  creditPolicyNew: id('enterprise-credit-policy'),
  firstTimeVisit: id('first-time-visit'),
  monitorFrequency: id('monitor-frequency'),
  popconfirm: id('popconfirm'),
  enforcedPasswordChange: id('enforced-password-change'),
  history: id('history'),
  financial: id('financial'),
  companyComparison: id('company-comparison'),
  observationalLists: id('observational-lists'),
  autoSelectCompanies: id('auto-select-companies'),
  keyFigures: id('key-figures'),
  keyFiguresDevelopment: id('key-figures-development'),
  charts: id('charts'),
  alikeCompanies: id('alike-companies'),
  portfolioInsights: id('portfolio-insights'),
  errors: id('errors'),
  paymentRemarks: id('payment-remarks'),
  newScorecard: id('new-scorecard'),
}

export default intl
