const creditPolicyCompliance = {
  container: {
    direction: 'row',
    justifyContent: 'space-evenly',
  },
  value: {
    paddingTop: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '4.45rem',
  },
  text: {
    paddingBottom: 1,
    fontWeight: 'light',
    textAlign: 'center',
  },
  divider: {
    background: 'grey.50',
  },
  target: {
    p: 1,
    width: 1,
    cursor: 'pointer',
    '&:hover': {
      background: 'grey.100',
    },
  },
}

export const classes = {
  creditPolicyCompliance,
}
