/*****************
 * Configuration *
 *****************/

// type Option = {|
//   key: string,
//   value: boolean,
//   important: boolean,
// |}

// type OptionShape = {
//   [key: string]: Option,
// }

/**
 * Standard options for monitoring.
 */
export const monitorOptions = {
  status: {
    key: 'status',
    value: true,
    important: true,
  },
  score: {
    key: 'score',
    value: true,
    important: true,
  },
  annual_reports: {
    key: 'annual_reports',
    value: true,
    important: true,
  },
  gazette: {
    key: 'gazette',
    value: true,
    important: true,
  },
  payment_remarks: {
    key: 'payment_remarks',
    value: true,
    important: true,
  },
  company_type: {
    key: 'company_type',
    value: false,
    important: false,
  },
  relations: {
    key: 'relations',
    value: false,
    important: false,
  },
  name: {
    key: 'name',
    value: false,
    important: false,
  },
  address: {
    key: 'address',
    value: false,
    important: false,
  },
  email: {
    key: 'email',
    value: false,
    important: false,
  },
  phone_number: {
    key: 'phone_number',
    value: false,
    important: false,
  },
  industry: {
    key: 'industry',
    value: false,
    important: false,
  },
  employees: {
    key: 'employees',
    value: false,
    important: false,
  },
  policy_outcome: {
    key: 'policy_outcome',
    value: false,
    important: false,
  },
}

export const defSubscription = {
  email_settings: {
    status: true,
    score: true,
    annual_reports: true,
    gazette: true,
    payment_remarks: true,
    company_type: false,
    relations: false,
    name: false,
    address: false,
    email: false,
    phone_number: false,
    industry: false,
    employees: false,
    policy_outcome: false,
  },
}
