import { SxProps } from '@mui/system'

const containerMain: SxProps = {
  width: '100%',
  fontSize: 20,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  '&:hover': {
    backgroundColor: 'grey.50',
  },
}

const containerLeft: SxProps = {
  gap: 3,
  alignItems: 'center',
  flexDirection: 'row',
}

const containerRight: SxProps = {
  paddingRight: 2,
}

const actionIcon: SxProps = {
  color: 'inherit',
  fontSize: 'inherit',
}

const divider: SxProps = {
  borderColor: 'grey.200',
}

const buttonStyles: SxProps = {
  textTransform: 'none',
  paddingX: 6,
  paddingY: 3,
  borderWidth: '1px 0',
  borderStyle: 'solid',
  borderColor: 'grey.200',
  backgroundColor: 'common.white',
  color: 'primary.main',
  fontSize: 24,
  '&:hover': {
    color: 'primary.900',
    boxShadow: 1,
    backgroundColor: 'grey.50',
  },
  '&:focus': {
    color: 'primary.main',
    boxShadow: 1,
    borderWidth: '2px',
    borderColor: 'primary.500',
    borderRadius: 0,
  },
  '&:active': {
    color: 'primary.900',
    boxShadow: 0,
    borderColor: 'grey.200',
    backgroundColor: 'grey.50',
  },
  '&:disabled': {
    color: 'grey.600',
    boxShadow: 0,
    backgroundColor: 'grey.300',
  },
}

export default {
  containerMain,
  containerLeft,
  containerRight,
  actionIcon,
  divider,
  buttonStyles,
}
