import { Stack } from '@mui/material'
import { Show } from 'components'
import { useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { classes } from '../../HierarchyListView.styles'
import { CompanyNameProps } from '../../HierarchyListView.types'
import ConnectingLines from '../ConnectingLines'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import styles from 'pages/company-hierarchy/styles/company-card'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Typography } from '@mui/material/'

function CompanyName({
  row,
  open,
  setOpen,
  isLastChild,
  increaseDepth,
  depth,
}: CompanyNameProps) {
  const history = useHistory()

  const handleClick = () => {
    setOpen(!open)
    increaseDepth(depth)
  }

  return (
    <Stack direction="row" alignItems="center">
      <Show when={!!row?.children?.length}>
        <>
          {open ? (
            <KeyboardArrowUpIcon
              onClick={handleClick}
              sx={classes.expandRetractButton}
              data-cy={`close-box-${row.name}`}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={handleClick}
              sx={classes.expandRetractButton}
              data-cy={`open-box-${row.name}`}
            />
          )}
        </>
      </Show>
      <ConnectingLines row={row} isLastChild={isLastChild} open={open} />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        onClick={() => history.push(navigation.creditCheck(row.local_id))}
        sx={{
          ml: row?.children?.length ? 1 : '',
          ...classes.clickableText,
          maxWidth: '90%',
        }}
      >
        {row?.type !== 'COMPANY' && (
          <AccountCircleIcon
            sx={{
              color: row?.type === 'BENEFICIAL_OWNER' ? 'grey.500' : 'grey.700',
              fontSize: '20px',
              strokeWidth: 0,
            }}
          />
        )}
        <Typography variant="subtitle1" sx={styles.ellipsis}>
          {row.name}
        </Typography>
      </Stack>
    </Stack>
  )
}
export default CompanyName
