import RadialHalfCircleChart from 'components-new/charts/RadialHalfCircleChart'
import { classPortfolioScore } from './PortfolioScore.styles'
import ErrorOccurred from 'components-new/elements/ErrorOccurred'
import { useMonitorShowList } from 'services/queries'
import { Box } from '@mui/material'
import RadialHalfCircleSkeleton from 'components-new/charts/RadialHalfCircleChart/skeletons/RadialHalfCircleSkeleton'
import { getFillColor } from './PortfolioScore.controller'
import { chartFillColors } from './PortfolioScore.model'

/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'

const PortfolioScore = ({ listId }: { listId: string }) => {
  const monitorShowListQuery = useMonitorShowList()

  if (monitorShowListQuery.isLoading) {
    return (
      <Box sx={{ pt: 4, pb: 6 }}>
        <RadialHalfCircleSkeleton />
      </Box>
    )
  }

  if (monitorShowListQuery.isError || monitorShowListQuery.isIdle) {
    return (
      <Box sx={{ pt: 4, pb: 6 }}>
        <ErrorOccurred />
      </Box>
    )
  }

  // finding the selected lists
  const selectedList = monitorShowListQuery.data?.find(
    (x) => x.list_id === Number(listId)
  )

  const allCompanies = Object.values(selectedList?.risk_assessment as object).reduce(
    (a, b) => a + b
  )

  // calculating the percent of low risk companies in the list (rounded)
  const percentLowRiskCompanies = Math.round(
    ((selectedList?.risk_assessment.LOW as number) / allCompanies) * 100
  )

  const fill = chartFillColors[getFillColor(percentLowRiskCompanies)]

  return (
    <Box className="MUI5" sx={classPortfolioScore}>
      <RadialHalfCircleChart
        value={isNaN(percentLowRiskCompanies) ? 0 : percentLowRiskCompanies}
        fill={fill}
        cornerRadius={50}
      />
    </Box>
  )
}

export default PortfolioScore
