import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/
export type Trend = 'up' | 'down'

export type CompanyVisitsSummary = {
  name: string
  count: number
  direction: Trend
  localized_name: string
}[]
export type CompanyVisitsParams = {
  year: number
  month: number
}

async function companyVisitsSummary(
  period: CompanyVisitsParams
): Promise<CompanyVisitsSummary> {
  const { data } = await risikaAPI.post(
    '/statistics/company_visits/summary',
    {
      period,
    },
    {
      microservice: 'data',
      appendCurrencyData: true,
    }
  )
  return data
}

export default companyVisitsSummary
