export const areQueriesLoading = (result) => {
  return result.reduce((accumulator, item) => {
    if (accumulator === true) return true
    return item.isLoading
  }, false)
}

export const getCompanyNames = (data) => {
  return data.map((companyBasics) => {
    return companyBasics.data.company_name
  })
}

/**
 * This function converts received company data into the format required from
 * D3 Linear Chart (for required chart format check @output).
 * @param {Array} result - raw results from queries.
 * @param {String} key - selected (chosen) "tab" (view) of the linear chart.
 * @param {Object} chartProperties - chart definitions.
 * @param {Array} companyNames - array of company names.
 * @output {Array} - [{name: "itemName", company1: value, company2: value}, {...}]
 */
export const setUpChartData = ({ result, key, chartProperties, companyNames }) => {
  const chart = []
  const chartData = {}
  const companiesNulled = {}
  const currentYear = new Date().getFullYear()
  const pastYearsLimit = 9
  for (let i = 0; i < result.length; i++) {
    companiesNulled[companyNames[i]] = null
  }
  result.forEach((company, companyIndex) => {
    company.data.forEach((entry) => {
      const year = entry.period.end.substring(0, 4)
      if (Number(year) > currentYear - pastYearsLimit) {
        let value = entry[key]
        if (chartProperties.chartType === 'ratio') {
          value *= 100
        }
        const companyName = companyNames[companyIndex]
        chartData[year] = {
          ...companiesNulled,
          ...chartData[year],
          [companyName]: value,
        }
      }
    })
  })
  Object.keys(chartData).forEach((key) => {
    chart.push({ name: key, ...chartData[key] })
  })
  return chart
}

/**
 * Data received from scores (ratingScores) query is in different format
 * from the data received from other queries (financialNumbers, financialRatios, etc).
 * This function will normalize (convert) data received from scores query to
 * match t he data received from the other queries, so it could be sent to
 * setUpChartData function.
 * @param {Array} scoresData - raw results from scores (ratingScores) queries.
 * @output {Array} - normalized (converted) data.
 */
export const normalizeScoresData = (scoresData) => {
  const normalizedScores = []
  scoresData.forEach((scoresItems) => {
    const normalized = scoresItems.data.historical_scores.reduce(
      (accumulator, scoresItem) => {
        const currentYear = scoresItem.date.substring(0, 4)
        const lastYears = accumulator.year
        if (!lastYears.includes(currentYear)) {
          accumulator.year.push(currentYear)
          accumulator.value.push({
            period: { end: scoresItem.date },
            score: scoresItem.score,
          })
        }
        return accumulator
      },
      { value: [], year: [] }
    )
    normalizedScores.push({ data: normalized.value })
  })
  return normalizedScores
}
