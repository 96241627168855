import * as React from 'react'

const CreditCheckContext = React.createContext(null)

export function CreditCheckProvider({ children, value }) {
  if (value === undefined) {
    throw new Error('A value must be provided to `CreditCheckProvider`')
  }

  return (
    <CreditCheckContext.Provider value={value}>{children}</CreditCheckContext.Provider>
  )
}

export function useCreditCheck() {
  const ctx = React.useContext(CreditCheckContext)

  if (ctx != null) {
    return ctx
  }

  throw new Error('`useCreditCheck` must be used inside of a `CreditCheckProvider`')
}
