import { Stack } from '@mui/material'
import { InputField } from 'components-new'
import { InputRangeProps } from './InputRange.types'
import styles from './InputRange.styles'
import useInputRange from 'services/hooks/useInputRange'

const { containerMain, singleFieldStyles } = styles

const InputRange = (props: InputRangeProps) => {
  const {
    value,
    setValue,
    customValidationFn,
    skipValidation = false,
    inputFieldProps,
    isValid,
    singleInputRangeField = false,
    placeholderA,
    placeholderB,
  } = props
  const {
    errorA,
    errorB,
    errorMessage,
    handleInputBChange,
    handleInputAChange,
    handleValidation,
  } = useInputRange({
    value,
    setValue,
    customValidationFn,
    skipAutoValidation: true,
    isValid,
  })

  return (
    <Stack sx={{ ...containerMain(singleInputRangeField) }}>
      <InputField
        value={value.a}
        handleChange={handleInputAChange}
        error={errorA}
        errorMessage={errorMessage}
        inputMode="numeric"
        clickAway={skipValidation ? undefined : handleValidation}
        enableRangeDash
        extraStyles={singleInputRangeField ? singleFieldStyles : {}}
        removeBorderOnFocus={singleInputRangeField}
        placeholder={placeholderA}
        {...inputFieldProps}
      />
      <InputField
        value={value.b}
        handleChange={handleInputBChange}
        error={errorB}
        errorMessage={errorMessage}
        inputMode="numeric"
        clickAway={skipValidation ? undefined : handleValidation}
        hideExtraText={true}
        extraStyles={singleInputRangeField ? singleFieldStyles : {}}
        removeBorderOnFocus={singleInputRangeField}
        placeholder={placeholderB}
        {...inputFieldProps}
      />
    </Stack>
  )
}

export default InputRange
