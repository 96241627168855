import { useState, useEffect } from 'react'
import { Skeleton, Typography, Box } from '@mui/material'
import { ConditionallyRender } from 'components'
import { FormattedMessage, useIntl } from 'react-intl'
import { animate } from 'framer-motion'
import intl from 'localization/components'
import { CompanyCreditCardPropTypes } from './CompanyCreditCard.types'
import moment from 'moment'
import { classes } from './CompanyCreditCard.styles'
import { useIsCompanyActive } from 'services/hooks'

const CompanyCreditCard = ({
  isCreditPolicy,
  creditMax,
  creditDays,
  currency = '',
  width,
  statusCode,
  status,
  statusActiveFrom,
  isStatstidende,
  isLoading,
}: CompanyCreditCardPropTypes) => {
  const reactIntl = useIntl()
  const isCompanyActive = useIsCompanyActive()
  const [displayedCreditMax, setDisplayedCreditMax] = useState(0)
  const [displayedCreditDays, setDisplayedCreditDays] = useState(0)

  useEffect(() => {
    if (typeof creditDays === 'number') {
      const controls = animate(0, creditDays, {
        duration: 1,
        onUpdate(value) {
          setDisplayedCreditDays(Math.ceil(value))
        },
      })

      return () => controls.stop()
    }
  }, [creditDays])
  useEffect(() => {
    if (typeof creditMax === 'number') {
      const controls = animate(0, creditMax, {
        duration: 1,
        onUpdate(value) {
          setDisplayedCreditMax(Math.ceil(value))
        },
      })

      return () => controls.stop()
    }
  }, [creditMax])

  return (
    <Box className="MUI5" sx={classes.container}>
      <Box sx={classes.textContainer}>
        <Typography variant="body2" sx={classes.description}>
          <ConditionallyRender
            condition={!!isCompanyActive && !isStatstidende}
            when={
              <>
                <i>
                  <FormattedMessage
                    id={intl.mainRecommendation('recommended-credit-description')}
                  />
                </i>
                <br />
                <i>
                  <FormattedMessage id={intl.mainRecommendation('currency')} /> {currency}
                </i>
              </>
            }
            otherwise={<FormattedMessage id={intl.mainRecommendation('status')} />}
          />
        </Typography>
        <Typography
          data-cy="credit-max"
          sx={{
            ...classes.title,
            textDecoration: !isCreditPolicy ? 'line-through' : '',
            alignSelf: 'flex-end',
          }}
          variant="h6"
        >
          {isLoading ? (
            <Skeleton sx={{ width: 100 }} />
          ) : (
            <ConditionallyRender
              condition={!!isCompanyActive && !isStatstidende}
              when={
                creditMax ? (
                  reactIntl.formatNumber(Math.floor(displayedCreditMax))
                ) : creditMax === 0 ? (
                  <FormattedMessage
                    id={intl.mainRecommendation('recommendation-credit')}
                  />
                ) : (
                  <FormattedMessage
                    id={intl.mainRecommendation('recommendation-credit-unknown')}
                  />
                )
              }
              otherwise={
                isStatstidende ? (
                  <FormattedMessage id={intl.mainRecommendation('statstidende-header')} />
                ) : (
                  status
                )
              }
            />
          )}
        </Typography>
      </Box>
      <Box sx={classes.textContainer}>
        <Typography variant="body2" sx={classes.description}>
          <ConditionallyRender
            condition={!!isCompanyActive && !isStatstidende}
            when={
              <i>
                <FormattedMessage
                  id={intl.mainRecommendation('recommended-days-description')}
                />
              </i>
            }
            otherwise={
              <FormattedMessage id={intl.mainRecommendation('status-active-from')} />
            }
          />
        </Typography>
        <Typography
          variant={width === 'xs' ? 'h5' : 'h6'}
          sx={{
            ...classes.title,
            textDecoration: !isCreditPolicy ? 'line-through' : '',
          }}
        >
          {isLoading ? (
            <Skeleton sx={{ width: 100 }} />
          ) : (
            <ConditionallyRender
              condition={!!isCompanyActive && !isStatstidende}
              when={
                creditDays !== undefined ? (
                  <>
                    {reactIntl.formatMessage(
                      {
                        id: intl.mainRecommendation('recommended-days'),
                      },
                      {
                        amount: Math.floor(displayedCreditDays),
                      }
                    )}
                  </>
                ) : (
                  <FormattedMessage
                    id={intl.mainRecommendation('recommendation-credit-unknown')}
                  />
                )
              }
              otherwise={moment(statusActiveFrom).format('DD.MM.YYYY')}
            />
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default CompanyCreditCard
