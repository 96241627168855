import { useQuery } from 'react-query'
import { users } from 'services/api'
import { UsersType } from 'types/queries'

export function useAllUsers() {
  // Note1: inappropriate name. It will be rewritten with new WebAPI
  // Note2: useQuery + TS
  //
  // export function useQuery<
  //   TQueryFnData = unknown,
  //   TError = unknown,
  //   TData = TQueryFnData,
  //   TQueryKey extends QueryKey = QueryKey
  // >
  // https://tkdodo.eu/blog/react-query-and-type-script

  return useQuery<UsersType, Error>('allUsers', () => users(), {
    refetchOnWindowFocus: false,
  })
}
