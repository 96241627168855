import { useMutation, useQueryClient } from 'react-query'
import { monitorRemoveList } from 'services/api'
import { setSelectedList } from 'store_deprecated/new-risk-monitoring/actions'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import { MONITOR_LIST } from './lists/constants'

export function useRemoveList() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(monitorRemoveList, {
    onSuccess: () => {
      queryClient.refetchQueries(MONITOR_LIST)
      // AKS There is list_id:0 every time
      dispatch(setSelectedList(0))
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(err?.response?.data?.error ?? 'Error deleting request', {
        variant: 'error',
      })
      // console.log('Error ', err.response.data.error)
    },
  })
}
