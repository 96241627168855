import { BuilderState } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'
import { typeGuard } from 'utils/general'
import { CustomChildrenProps } from './CustomizationHandler.types'
export const getSelectedOption = (
  state: BuilderState,
  children: React.ReactElement<CustomChildrenProps>[]
) => {
  const selectedNode = state.nodes.find(
    (node) => node.id === state.actionPanelData.nodeId
  )

  if (!selectedNode) return null
  const objectKey = selectedNode.type === 'rule' ? 'category' : 'outcome'
  const selectedMenu = children.find(
    (child) =>
      child?.props?.template?.template_data?.api_key ===
      selectedNode.data.apiData[objectKey as keyof typeof selectedNode.data.apiData]
  )
  return selectedMenu ? selectedMenu?.props?.template?.template_data?.api_key : ''
}
export const findSelectedMenu = (
  state: BuilderState,
  children: React.ReactElement<CustomChildrenProps>[]
) => {
  const selectedNode = state.nodes.find(
    (node) => node.id === state.actionPanelData.nodeId
  )
  if (!selectedNode) return 0
  const selectedMenu = children.findIndex(
    (child) =>
      child?.props?.template?.template_data?.variant ===
      typeGuard('variant', selectedNode.data.builderData)
  )

  return selectedMenu > 0 ? selectedMenu : 0
}
