import { useMediaQuery } from '@mui/material'
import { default as cx } from 'classnames'
import { useStyles } from '../styles/side-scroll'

/**
 * Added conditionalySidescroll when true
 * it will take in to account the max width passed to it and decide
 * whether the table should be scrollable or not.
 */
const Sidescroll = ({
  children,
  className = '',
  conditionalySidescroll = false,
  maxWidth,
  fixMaxContentForApple = false,
  ...props
}) => {
  const classes = useStyles()
  const isSmallDisplay = useMediaQuery(`(max-width: ${maxWidth})`)
  return (
    <div
      style={{ width: '100%' }}
      className={cx(
        !conditionalySidescroll
          ? classes.scrollableContainer
          : isSmallDisplay
          ? classes.scrollableContainer
          : '',
        className
      )}
      {...props}
    >
      <div
        style={fixMaxContentForApple ? { width: '100%' } : {}}
        className={
          !conditionalySidescroll
            ? classes.scrollable
            : isSmallDisplay
            ? classes.scrollable
            : ''
        }
      >
        {children}
      </div>
    </div>
  )
}

export default Sidescroll
