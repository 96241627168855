import { Palette, PaletteColorOptions } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { Shadows } from '@mui/material/styles/shadows'
export const themeObject = (() => {
  const breakpoints = {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  }
  //               0  1  2  3   4   5   6   7   8   9   10
  const spacing = [0, 4, 8, 12, 16, 24, 32, 48, 64, 80, 96]
  const shape = { borderRadius: 4 }
  const shadows = [
    'none',
    '0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
    '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08)',
    '0px 4px 6px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.1)',
    '0px 6px 8px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.12)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ] as Shadows

  const palette = {
    primary: {
      100: '#F6FBFC',
      300: '#ECFCFF',
      500: '#54BDCB',
      700: '#245157',
      900: '#0A373D',
      main: '#245157',
      light: '#ECFCFF',
      dark: '#0A373D',
      contrastText: '#FFFFFF',
    },
    secondary: {
      100: '#FFF3E2',
      300: '#FFCC89',
      500: '#FFB75A',
      700: '#F57C1F',
      900: '#E55319',
      main: '#FFB75A',
      light: '#FFCC89',
      dark: '#F57C1F',
      contrastText: '#FFFFFF',
    },
    error: {
      100: '#FFEAEE',
      300: '#EC6E74',
      500: '#FD3136',
      700: '#DC182F',
      900: '#AB0017',
      main: '#DC182F',
      light: '#EC6E74',
      dark: '#AB0017',
      contrastText: '#FFFFFF',
    },
    warning: {
      100: '#FFF8E1',
      300: '#FFD54F',
      500: '#FFC107',
      700: '#FFA000',
      900: '#DF6100',
      main: '#FFA000',
      light: '#FFD54F',
      dark: '#DF6100',
      contrastText: '#FFFFFF',
    },
    success: {
      100: '#E8F5E9',
      300: '#81C784',
      500: '#4CAF50',
      700: '#27882A',
      900: '#19581D',
      main: '#4CAF50',
      light: '#81C784',
      dark: '#27882A',
      contrastText: '#FFFFFF',
    },
    info: {
      100: '#F2FAFF',
      300: '#63B4F6',
      500: '#2196F3',
      700: '#1A75D2',
      900: '#0F3F93',
      main: '#2196F3',
      light: '#63B4F6',
      dark: '#1A75D2',
      contrastText: '#FFFFFF',
    },
    grey: {
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
      50: '#F8F9FA',
      100: '#F1F3F5',
      200: '#E9ECEF',
      300: '#DFE2E6',
      400: '#CFD4D9',
      500: '#AEB5BC',
      600: '#878E95',
      700: '#6A737B',
      800: '#353A3F',
      900: '#22252A',
      main: '#245157',
      light: '#54BDCB',
      dark: '#F1F3F5',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.58)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#F8F9FA',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
  } as Omit<Palette, MissingPropertiesFromPalette>

  type MissingPropertiesFromPalette =
    | 'mode'
    | 'augmentColor'
    | 'getContrastText'
    | 'contrastThreshold'
    | 'tonalOffset'
    | 'text'
    | 'divider'
    | 'action'
    | 'background'

  const typography = {
    allVariants: { color: palette.grey[800] },
    h1: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 44,
      fontStyle: 'normal',
      letterSpacing: -0.25,
      lineHeight: '48px',
      color: palette.common.black,
    },
    h2: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 32,
      letterSpacing: 0,
      lineHeight: '38px',
    },
    h3: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: '28px',
    },
    h4: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: 20,
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '26px',
      color: palette.common.black,
    },
    h5: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    h6: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '0.0075em',
      lineHeight: 1.6,
    },
    subtitle1: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 0.15,
      lineHeight: '24px',
    },
    subtitle2: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: 0.2,
      lineHeight: '22px',
    },
    body1: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body2: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0.2,
      lineHeight: '22px',
    },
    button: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: '20px',
    },
    checkboxSmallLabel: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0.2,
      lineHeight: '16px',
    },
    caption: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: '18px',
    },
    overline: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: 1.5,
      lineHeight: '21px',
    },
    link1: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: 0.2,
      textDecorationLine: 'underline',
      textUnderlineOffset: '4px',
      color: palette.primary.main,
    },
    link2: {
      fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: 0.2,
      textDecorationLine: 'underline',
      textUnderlineOffset: '4px',
      color: palette.primary.main,
    },
  }

  const components = {
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: '2px solid transparent',
          '&:focus': {
            border: '2px solid',
          },
          '&:disabled': {
            boxShadow: shadows[0],
            color: palette.grey[600],
            backgroundColor: palette.grey[300],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          '& .MuiSvgIcon-root': { fontSize: 20 },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              color: palette.common.white,
              '& + .MuiSwitch-track': {
                backgroundColor: palette.success.dark,
                opacity: 1,
              },
            },
          },
          '& .MuiSwitch-track': {
            backgroundColor: palette.grey[600],
            opacity: 1,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          '& .MuiSvgIcon-root': { fontSize: 18 },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginTop: spacing[2],
          backgroundColor: palette.common.white,
          boxShadow: shadows[1],
          '& .MuiButtonBase-root.MuiTab-root': {
            minWidth: '160px',
            height: spacing[7],
          },
          '& .MuiButtonBase-root.MuiTabScrollButton-root': {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[300],
          color: palette.grey[300],
          '&.MuiDivider-fullWidth': {
            backgroundColor: palette.grey[300],
            color: palette.grey[300],
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: '44px',
          borderRadius: shape.borderRadius,
          border: `1px solid ${palette.grey[200]}`,
          padding: spacing[3],
          backgroundColor: palette.common.white,
          ...typography.body1,
          '&.Mui-focused': {
            backgroundColor: palette.common.white,
          },
          ' .Mui-disabled': {
            backgroundColor: palette.grey[50],
            borderColor: palette.grey[200],
            color: palette.grey[600],
          },
          '.Mui-disabled': {
            backgroundColor: palette.grey[50],
            borderColor: palette.grey[200],
            color: palette.grey[600],
          },
          '&:hover': {
            borderColor: palette.grey[400],
          },
          '&.Mui-error': {
            border: `2px solid ${palette.error[700 as keyof PaletteColorOptions]}`,
            '&:after': {
              border: 'none',
            },
            '&:before': {
              border: 'none',
            },
          },
          '.MuiInput-input': {
            ...typography.body1,
          },
          svg: {
            color: palette.primary.main,
            marginRight: spacing[3],
          },
          input: {
            '&::placeholder': {
              color: palette.grey[600],
              opacity: 1,
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: shape.borderRadius,
          backgroundColor: palette.common.white,
          border: `1px solid ${palette.grey[200]}`,
          boxShadow: shadows[2],
          marginTop: spacing[1],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.body1,
          padding: spacing[3],
          '&:hover': {
            backgroundColor: palette.grey[200],
          },
          '&.Mui-selected': {
            backgroundColor: palette.grey[100],
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          paddingX: spacing[2],
          paddingY: spacing[1],
          backgroundColor: palette.primary[900 as keyof PaletteColorOptions],
          ...typography.caption,
          color: palette.common.white,
        },
        arrow: {
          color: palette.primary[900 as keyof PaletteColorOptions],
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingY: spacing[4],
          paddingX: spacing[5],
          backgroundColor: palette.common.white,
          color: palette.common.white,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingY: spacing[5],
          paddingBottom: spacing[4],
          paddingTop: `${spacing[3]}px !important`,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '1.375rem',
          fontWeight: 500,
        },
      },
    },
  }
  return {
    breakpoints,
    shadows,
    spacing,
    shape,
    palette,
    typography,
    components,
  }
})()

export const theme = createTheme(themeObject)
