import {
  SET_TAB_SELECTED,
  SUBMIT_FETCH_POLICY_VALUES,
  SET_POLICY_VALUES_SUCCESS,
  SET_POLICY_VALUES_FAILED,
  UPDATE_POLICY_VALUES,
  SET_STATUS,
  UPDATE_SINGLE_POLICY_VALUES,
  SET_IS_POLICY,
} from './constants'

/**************
 * Saga calls *
 **************/
export const submitFetchPolicyValues = (country, model) => {
  return {
    type: SUBMIT_FETCH_POLICY_VALUES,
    status: 'LOADING',
    payload: { country, model },
  }
}
/*****************
 * Reducer calls *
 *****************/

export const setPolicyStatus = (status, country) => {
  return {
    type: SET_STATUS,
    payload: { status, country },
    status: 'SUCCESS',
  }
}
export const setPolicyTabSelected = (country) => {
  return {
    type: SET_TAB_SELECTED,
    payload: country,
    status: 'SUCCESS',
  }
}
export const updatePolicyValues = ({ tabSelected, fieldType, values, isSingle }) => {
  if (isSingle) {
    return {
      type: UPDATE_SINGLE_POLICY_VALUES,
      payload: { tabSelected, fieldType, values },
      status: 'SUCCESS',
    }
  } else {
    return {
      type: UPDATE_POLICY_VALUES,
      payload: { tabSelected, fieldType, values },
      status: 'SUCCESS',
    }
  }
}
export const setIsPolicy = (isPolicy, tabSelected) => {
  return {
    type: SET_IS_POLICY,
    payload: { isPolicy, tabSelected },
    status: 'SUCCESS',
  }
}

export const setPolicyValuesSuccess = (policyValues, country, enabled) => {
  return {
    type: SET_POLICY_VALUES_SUCCESS,
    payload: { policyValues, country, enabled },
    status: 'SUCCESS',
  }
}
export const setPolicyValuesFailure = (policyValues, country) => {
  return {
    type: SET_POLICY_VALUES_FAILED,
    payload: { policyValues, country },
    status: 'FAILED',
  }
}
