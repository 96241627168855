import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { CompanyHierarchy } from 'types/general'

async function companyHierarchyWalkdown({
  id,
  country = 'dk',
  maxDepth,
}: LocalId & { maxDepth: number }): Promise<CompanyHierarchy> {
  const { data }: { data: CompanyHierarchy } = await risikaAPI.get(
    `/company/hierarchy/walkup/${id}?max_level=${maxDepth}`,
    {
      microservice: 'data',
      country,
      appendScoringModel: true,
      appendCurrencyURL: true,
    }
  )
  return data
}

export default companyHierarchyWalkdown
