import { PolicyNodesAndEdges } from 'types/queries'
import {
  BuilderDispatch,
  BuilderEdge,
  NodeApiTypes,
  ContentType,
  BuilderNode,
  HighlightedNodesType,
  UpdateNodeData,
  BuilderState,
  ActionPanelStates,
  UpdateCategoryType,
  ContentTypeWithtoutApiData as ContentTypeWithoutApiData,
  PayloadNodeBetweenTwoNodes,
} from '../PolicyBuilder.types'
import { ActionPanelToggleTypes } from './BuilderContext.types'
import {
  ON_EDGES_CHANGE,
  ACTION_PANEL_TOGGLE,
  SET_ACTION_PANEL_CONTENT,
  SET_NODE_API_DATA,
  ADD_NODE_API_DATA,
  CANCEL_NODE,
  DELETE_NODE,
  ADD_NODES_AND_EDGES,
  ADD_FIRST_NODE,
  ON_NODES_CHANGE,
  CREATE_EMPTY_NODE,
  ALLOW_ONLY_ACTIVE_COMPANIES,
  ADD_UPDATE_HIGHLIGHTED_NODE,
  DELETE_HIGHLIGHTED_NODE,
  UPDATE_CATEGORY,
  CREATE_NODE_BETWEEN_NODES,
} from './constants'

export const actions = (
  dispatch: BuilderDispatch,
  savePolicy: (state: BuilderState) => void
) => ({
  // Adds bulk nodes and edges generally used when loading a policy from the API
  addNodesAndEdges: (data: PolicyNodesAndEdges) =>
    dispatch({ type: ADD_NODES_AND_EDGES, payload: data }),
  /*********
   * Nodes *
   *********/

  // Each time a node is added React flow renders the node and gives it size and all the necessary data.
  // Then this action is called which positions it in the proper spot using dagre
  handleNodesChange: (data: BuilderNode[]) =>
    dispatch({ type: ON_NODES_CHANGE, payload: data }),

  // Sets the node data for the node that is currently created
  setNodeApiData: (payload: ContentTypeWithoutApiData) => {
    dispatch({ type: DELETE_HIGHLIGHTED_NODE, payload: payload.nodeId })
    return dispatch({
      type: SET_NODE_API_DATA,
      payload: { ...payload, savePolicy },
    })
  },
  /**
   * Insert additional data to the selected nodes api data and keep the current data.
   * @param {ContentType} payload - The data to add to the API.
   * @returns {void}
   */
  addNodeApiData: (payload: ContentType) => {
    dispatch({ type: ADD_NODE_API_DATA, payload })
  },
  // Sets the node data for the node that is currently created
  updateCategory: (payload: UpdateCategoryType) => {
    return dispatch({ type: UPDATE_CATEGORY, payload })
  },

  // Updates the node data for a node that is already edited
  createEmptyNode: (data: UpdateNodeData) =>
    dispatch({ type: CREATE_EMPTY_NODE, payload: data }),

  // When you press cancel on the action dialog it deletes the newly created node
  cancelNode: () => dispatch({ type: CANCEL_NODE, payload: null }),

  // When you press delete on the action dialog it deletes the selected node and all its children
  deleteNode: () => dispatch({ type: DELETE_NODE, payload: { savePolicy } }),
  /*********
   * Edges *
   *********/

  // Adds the newly rendered edge to the state
  handleEdgesChange: (edges: BuilderEdge[]) =>
    dispatch({ type: ON_EDGES_CHANGE, payload: edges }),

  actionPanelToggle: (payload: ActionPanelToggleTypes) => {
    return dispatch({ type: ACTION_PANEL_TOGGLE, payload: payload })
  },
  setActionPanelContent: (content: ActionPanelStates) => {
    return dispatch({ type: SET_ACTION_PANEL_CONTENT, payload: content })
  },

  addFirstNode: (nodeType: NodeApiTypes) =>
    dispatch({ type: ADD_FIRST_NODE, payload: nodeType }),

  allowOnlyActiveCompanies: () => {
    return dispatch({ type: ALLOW_ONLY_ACTIVE_COMPANIES, payload: {} })
  },
  addOrUpdateHighlight: ({ nodeId, type }: HighlightedNodesType) => {
    return dispatch({ type: ADD_UPDATE_HIGHLIGHTED_NODE, payload: { nodeId, type } })
  },
  deleteHighlight: (nodeId: string) => {
    return dispatch({ type: DELETE_HIGHLIGHTED_NODE, payload: nodeId })
  },
  createNodeBetweenNodes: ({
    parentNodeId,
    childNodeId,
    nodeLine,
  }: PayloadNodeBetweenTwoNodes) => {
    return dispatch({
      type: CREATE_NODE_BETWEEN_NODES,
      payload: { parentNodeId, childNodeId, nodeLine },
    })
  },
})
