import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { PersonSearchCategory } from 'types/queries'
import { usePersonBulkInformation } from 'services/queries'
import { useParams } from 'react-router-dom'
import { PolicyBuilderParams } from '../../PolicyBuilder.types'
import { Skeleton } from '@mui/material'
import { PersonBulkResponse } from 'services/api/endpoints/risikaAPI/getPersonBulkInformation'
import { useBuilderParams } from '../../PolicyBuilder.hooks'

type CompanySearchContentPropsType = {
  data: PersonSearchCategory
}
const LIMIT = 5
function PersonSearchContent({ data }: CompanySearchContentPropsType) {
  const params = useBuilderParams() as PolicyBuilderParams
  const { data: personBulkData, isLoading: useBulkInfoLoading } =
    usePersonBulkInformation({
      personIdList: data.include ?? [],
      country: params.country,
    })
  if (!data.include) {
    return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }

  if (!personBulkData) {
    return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }

  if (useBulkInfoLoading) {
    return (
      <>
        <Skeleton>
          <p>blablablabla</p>
        </Skeleton>
        <Skeleton>
          <p>blablablabla</p>
        </Skeleton>
      </>
    )
  }

  const formatData = (data: PersonBulkResponse | undefined) => {
    if (!data) return ''
    return (
      data
        .slice(0, LIMIT)
        ?.map((item) => item.name)
        .sort((a, b) => (a > b ? 1 : -1))
        .join(', ') + (data.length > LIMIT ? '...' : '')
    )
  }

  return (
    <FormattedMessage
      id={intl.creditPolicyNew('dropdown_multi_select')}
      values={{
        companyList: formatData(personBulkData),
      }}
    />
  )
}

export default PersonSearchContent
