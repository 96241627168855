import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAccountStatus } from 'services/queries'
import * as React from 'react'
import { totangoProxy } from 'services/api'

const trackActivity = (accountId, userId, module, activity) => {
  if (
    typeof accountId !== 'string' ||
    typeof userId !== 'string' ||
    typeof activity !== 'string' ||
    typeof module !== 'string'
  ) {
    console.log('totango-tracker.trackActivity: Invalid parameters')
    return
  }
  totangoProxy({
    accountId,
    userId,
    module,
    activity,
  }).catch((err) => {
    console.log('err', err)
  })
}

const useTotangoTracking = () => {
  const userEmail = useSelector((state) => state.auth?.user.data.email)
  const userCompanyId = useSelector((state) => state.auth?.company.localId)

  const { data } = useAccountStatus()
  const [plan, setPlan] = React.useState('')

  React.useEffect(() => {
    if (data) setPlan(data?.subscription_plan)
  }, [data])

  const trackEvent = useCallback(
    (module, action) => {
      if (!userCompanyId || !userEmail || !plan) return
      if (plan === 'FREEMIUM' || plan === 'TRIAL') return

      trackActivity(userCompanyId, userEmail, module, action)
    },
    [userCompanyId, userEmail, plan]
  )

  return { trackEvent }
}

export default useTotangoTracking
