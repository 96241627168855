import { colors } from 'configs'
import intl from 'localization/components'

export const chartData = [
  {
    type: 'ratio',
    name: 'solidity_ratio',
    key: intl.relevantFinancialRatios('solidity_ratio'),
    class: 'labelTop',
  },
  {
    type: 'score',
    name: 'current_score',
    key: intl.riskMonitoringSubscription('score'),
    class: 'labelRight',
  },

  {
    type: 'ratio',
    name: 'current_ratio',
    key: intl.relevantFinancialRatios('current_ratio'),
    class: 'labelBottom',
  },
  {
    type: 'ratio',
    name: 'return_on_assets',
    key: intl.relevantFinancialRatios('return_on_assets'),
    class: 'labelLeft',
  },
]

export const chartDefinition = {
  name: 'Key figures',
  properties: {
    polarGrid: true,
    polarAngleAxis: false,
    tooltip: true,
    legend: false,
  },
  colors: {
    stroke: colors.risikaTertiaryDim,
    fill: colors.risikaTertiary,
    fillOpacity: 0.6,
  },
}
