import { useEffect, useState } from 'react'
import { CssBaseline, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import Topbar from './Topbar'
import { navbarCloseMobile } from 'store_deprecated'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { AppShellProvider } from './context'
import AppBar from './AppBar'
import AppDrawer from './AppDrawer'
import { ReduxRootState } from '../../store_deprecated/types'
import Show from 'components/Show'
import {
  Content,
  DrawerStyled,
  Root,
  StyledNav,
  StyledToolbar,
} from 'styles/components/sidebar'

function Sidebar(props: any) {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const dispatch = useDispatch()
  const navbar = useSelector((state: ReduxRootState) => state.risika.navbar)

  const { roles } = useSelector((state: ReduxRootState) => state.auth.user, shallowEqual)

  const [chatElement, setChatElement] = useState<Element | null>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      if (chatElement != null) {
        clearInterval(interval)
      }

      const shadowContainer = document.querySelector('.shadow-container')

      if (shadowContainer != null) {
        setChatElement(shadowContainer)
      }
    }, 500)

    const timeout = setTimeout(() => {
      clearInterval(interval)
    }, 30000)

    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [chatElement])

  useEffect(() => {
    function listener(mutationList: MutationRecord[]) {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          // @ts-ignore
          if (mutation.target.classList.contains('active')) {
            // Do the stuff that has to happen when it's active.
            // @ts-ignore
            mutation.target.parentNode?.classList.add('Chat__active')
          } else {
            // Do the stuff that has to happen when it's NOT active.
            // @ts-ignore // f this
            mutation.target.parentNode.classList.remove('Chat__active')
          }
        }
      }
    }

    let observer: MutationObserver | undefined
    if (chatElement != null) {
      observer = new MutationObserver(listener)
      observer.observe(chatElement, {
        attributes: true,
      })
    }

    return () => {
      if (observer != null) {
        observer.disconnect()
      }
    }
  }, [chatElement])

  return (
    <AppShellProvider>
      <Root>
        <CssBaseline />
        <Topbar />
        <StyledNav aria-label="navigation">
          <Show when={!smUp}>
            <DrawerStyled
              variant="temporary"
              anchor={'left'}
              open={navbar.mobileOpen}
              onClose={() => dispatch(navbarCloseMobile())}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <AppBar />
              <AppDrawer roles={roles} navigation={props.navigation} />
            </DrawerStyled>
          </Show>
          <Show when={!xsDown && smUp}>
            <DrawerStyled variant="permanent" open>
              <AppBar />
              <AppDrawer roles={roles} navigation={props.navigation} />
            </DrawerStyled>
          </Show>
        </StyledNav>
        <Content>
          <StyledToolbar />
          {props.children}
        </Content>
      </Root>
    </AppShellProvider>
  )
}

export default Sidebar
