import {
  STORE_CREDIT_CHECK_INFORMATION,
  SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION,
  STORE_CREDIT_CHECK_INFORMATION_FAILED,
  STORE_CREDIT_INFORMATION,
  UPDATE_HIGHLIGHT_INFORMATION,
  SUBMIT_UPDATE_STALE_DATA,
  HANDLE_UPDATE_STALE_DATA_SUCCESS,
  HANDLE_FLUSH_CACHED_PAGES,
} from './constants'

// type FetchCompanyInformaion = {
//   localID: any,
//   language: string,
// }
// type UpdatedFields = {
//   company: Object,
//   highlights: Object,
//   language: string,
//   localID: any,
// }
// type FetchCreditInformaion = {
//   localID: any,
//   language: string,
//   enqueueSnackbar: any,
//   messages: any,
// }

// type CreditCheckResult = {
//   company: Object,
//   ratingCredit: Object | null,
//   ratingScores: Array<Object> | null,
//   highlights: Object | null,
//   financialRatios: Array<Object> | null,
//   financialNumbers: Array<Object> | null,
//   financialNumbersConsolidated: Array<Object> | null,
//   relations: Object | null,
//   financialStats: Array<Object> | null,
//   financialStatsConsolidated: Array<Object> | null,
//   ean: any | null,
//   financialPerformance: Object | null,
//   statstidende: any | null,
// }
// type CreditResult = {
//   ratingCredit: Object | null,
//   ratingScores: Array<Object> | null,
//   localID: any,
// }

// type StoreCompanyInformation = {
//   data: CreditCheckResult | null,
// }
// type StoreCreditInformation = {
//   data: CreditResult | null,
// }

/**************
 * Saga calls *
 **************/

export const submitFetchCompanyCreditInformaion = ({
  localID,
  language,
  enqueueSnackbar,
  messages,
}) => {
  return {
    type: SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION,
    payload: { localID, language, enqueueSnackbar, messages },
    status: 'LOADING',
  }
}

export const updateStaleData = ({ localID, language }) => {
  return {
    type: SUBMIT_UPDATE_STALE_DATA,
    payload: { localID, language },
    status: 'LOADING',
  }
}

/*****************
 * Reducer calls *
 *****************/

export const storeCreditCheckInformationSuccess = (data) => {
  return {
    type: STORE_CREDIT_CHECK_INFORMATION,
    payload: data,
    status: 'SUCCESS',
  }
}
export const storeCreditCheckInformationFailure = () => {
  return {
    type: STORE_CREDIT_CHECK_INFORMATION_FAILED,
    payload: null,
    status: 'FAILED',
  }
}
export const storeCreditInformationSuccess = (data) => {
  return {
    type: STORE_CREDIT_INFORMATION,
    payload: data,
    status: 'SUCCESS',
  }
}
export const updateHighlightInformationSuccess = (data, language, localId) => {
  return {
    type: UPDATE_HIGHLIGHT_INFORMATION,
    payload: { data, language, localId },
    status: 'SUCCESS',
  }
}
export const updateStaleDataSuccess = ({
  highlights,
  company,
  language,
  localID,
  financialRatios,
  financialNumbers,
  financialNumbersConsolidated,
  financialStats,
  financialStatsConsolidated,
  financialPerformance,
}) => {
  return {
    type: HANDLE_UPDATE_STALE_DATA_SUCCESS,
    payload: {
      highlights,
      company,
      language,
      localID,
      financialRatios,
      financialNumbers,
      financialNumbersConsolidated,
      financialStats,
      financialStatsConsolidated,
      financialPerformance,
    },
    status: 'SUCCESS',
  }
}
export const flushCachedPages = () => {
  return {
    type: HANDLE_FLUSH_CACHED_PAGES,
    payload: {},
    status: 'SUCCESS',
  }
}
