import { Stack, Typography } from '@mui/material'
import React from 'react'
import styles from 'pages/company-hierarchy/styles/company-card'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { navigation } from 'services/navigation'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

type Props = {
  displayState: any
  nodeDatum: any
  onOpenHierarchyWalkdown?: () => void
}

const HumanEntity = ({ displayState, nodeDatum, onOpenHierarchyWalkdown }: Props) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(
      navigation.relationOverview({
        country: nodeDatum?.local_id?.country.toLowerCase() ?? 'dk',
        id: nodeDatum?.personal_id,
      })
    )
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ ...styles.main, ...styles.humanTitle }}
    >
      <AccountCircleIcon sx={styles.accountIcon} />
      <Typography variant="h5" onClick={handleClick} sx={styles.hoverUnderline}>
        {nodeDatum.name}
      </Typography>
      <Typography
        sx={{ color: 'grey.700', opacity: displayState.role ? 1 : 0 }}
        variant="overline"
      >
        <FormattedMessage id={intl.companyHierarchy(nodeDatum?.type)} />
      </Typography>
    </Stack>
  )
}

export default HumanEntity
