export const adjustParameters = (
  rawParameters: {},
  crumb: { level: number; groupCode: string }[]
) => {
  const currentCrumb = crumb[crumb.length - 1]
  const parameterAdjusted = {
    ...rawParameters,
    group_by: [
      { category: 'NACE', level: currentCrumb.level },
      { category: 'RISK_ASSESSMENT' },
    ],
  }
  if (currentCrumb.level > 1) {
    return {
      ...parameterAdjusted,
      filter_on: {
        category: 'NACE',
        level: currentCrumb.level - 1,
        value: currentCrumb.groupCode,
      },
    }
  }
  return parameterAdjusted
}
