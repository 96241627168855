import { NodeHighlightOptions, NodeVariations } from '../PolicyBuilder.types'

export const centerIcon = (ref: any) => {
  if (ref) {
    return {
      position: 'absolute',
      right: `calc(0px - ${ref?.current?.offsetWidth / 2}px)`,
      top: `calc(50% - ${ref?.current?.offsetHeight / 2}px)`,
    }
  }
  return {
    position: 'absolute',
    right: 0,
    top: '50%',
  }
}
export const container = {
  border: 1,
  borderColor: 'grey.400',
  borderRadius: 1,
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor: 'grey.100',
  position: 'relative',
}
const getColor = (highlight?: NodeHighlightOptions) => {
  switch (highlight) {
    case 'error':
      return 'error.main'
    case 'warning':
      return 'warning.main'
    case 'success':
      return 'success.main'
    case 'info':
      return 'primary.500'
    default:
      return 'transparent'
  }
}

export const ruleStyle = (highlight?: NodeHighlightOptions) => ({
  backgroundColor: 'grey.100',
  position: 'relative',
  width: '280px',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 1,
  borderColor: getColor(highlight),
})
export const nodeStyle = (highlight?: NodeHighlightOptions) => ({
  backgroundColor: 'grey.100',
  position: 'relative',
  width: '280px',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 1,
  borderColor: getColor(highlight),
})
export const actionStyle = (highlight?: NodeHighlightOptions) => ({
  backgroundColor: 'grey.100',
  position: 'relative',
  width: '280px',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 1,
  borderColor: getColor(highlight),
})
export const plusStyle = (highlight?: NodeHighlightOptions) => ({
  backgroundColor: 'primary.main',
  width: 28,
  height: 28,
  padding: 0,
  borderStyle: 'solid',
  borderRadius: '20px',
  borderColor: getColor(highlight),
  fontSize: 21,
  color: 'common.white',
  boxShadow: 1,
  '&:hover': {
    backgroundColor: 'primary.dark',
    boxShadow: 2,
  },
  '&:focus': {
    borderWidth: '2px',
    borderColor: 'primary.500',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
})

export const titleContainer = (type?: string) => ({
  borderRadius: '4px 4px 0 0',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 2,
  backgroundColor: type === 'rule' ? 'primary.main' : 'info.dark',
  padding: 2,
  color: 'common.white',
  fontSize: 15,
})

export const contentContainer = {
  borderRadius: '0 0 4px 4px',
  padding: 2,
  backgroundColor: 'inherit',
  textAlign: 'left',
}

export const mainContainer = (highlight?: NodeHighlightOptions) => ({
  padding: 0,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: getColor(highlight),
  boxShadow: 1,
  borderRadius: '5px',
  color: 'primary.main',
  backgroundColor: 'grey.100',
  fontSize: 24,
  '&:hover': {
    boxShadow: 2,
    backgroundColor: 'grey.200',
  },
})

export const classes = {
  centerIcon,
  container,
  ruleStyle,
  nodeStyle,
  actionStyle,
  plusStyle,
}
