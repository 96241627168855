import * as React from 'react'

import { Stack, TableCell, Typography } from '@mui/material'
import { handleVisibility } from '../HierarchyListView.controller'
import { listViewTableHeaders } from '../HierarchyListView.model'
import { useHierarchySceneContext } from 'pages/company-hierarchy/context/hierarchySceneContext'
import { useIntl } from 'react-intl'
import { tableCell } from '../HierarchyListView.styles'

function HeaderCells() {
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const { displayState } = useHierarchySceneContext()
  const [tableHeaders, setTableHeaders] = React.useState(
    handleVisibility(listViewTableHeaders, displayState)
  )

  React.useEffect(() => {
    setTableHeaders(handleVisibility(listViewTableHeaders, displayState))
  }, [displayState])

  return (
    <>
      {tableHeaders
        .filter((x) => x.visible)
        .map((header) => (
          <TableCell sx={{ ...tableCell(header.width) }} key={header.label}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography sx={{ width: '100%', textAlign: header.align }}>
                {messages[header.label] as string}
              </Typography>
            </Stack>
          </TableCell>
        ))}
    </>
  )
}

export default HeaderCells
