import callBackendAPI from 'services/api/helpers/backendAPI'
import { ResponseDataGetUser } from './getUser'

/******************
 * Fetch from API *
 ******************/

const responsibleUser = (): Promise<ResponseDataGetUser> =>
  callBackendAPI('/customer/responsible-user')

export default responsibleUser
