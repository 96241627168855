import { IconButton } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export type IndustryTypeExpanderProps = {
  isExpanded: boolean
}

const IndustryTypeExpander = ({ isExpanded, ...props }: IndustryTypeExpanderProps) => (
  <IconButton {...props}>{isExpanded ? <ExpandLess /> : <ExpandMoreIcon />}</IconButton>
)

export default IndustryTypeExpander
