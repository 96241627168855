import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Stack } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScaleRight } from '@fortawesome/free-solid-svg-icons'
import { PropTypes } from './ProbabilityOfDistress.types'
import { Typography, Tooltip } from '@mui/material'

const ProbabilityOfDistress = ({ distress, isSoleProprietorship }: PropTypes) => {
  if (!distress) {
    return null
  }

  const percentDistress = (distress: number) => {
    const percentDistress = +(parseFloat(distress.toString()) * 100).toFixed(2)
    if (percentDistress < 0.01) return `< 0.01%`
    return `${percentDistress}%`
  }

  return (
    <Tooltip
      title={
        isSoleProprietorship ? (
          <FormattedMessage id={intl.financial('probability-of-closure')} />
        ) : (
          <FormattedMessage id={intl.financial('probability-of-distress')} />
        )
      }
      placement="left"
    >
      <Stack spacing={1} items="center">
        <FontAwesomeIcon icon={faBalanceScaleRight as any} />
        <Typography variant="h5">{percentDistress(distress)}</Typography>
      </Stack>
    </Tooltip>
  )
}

export default ProbabilityOfDistress
