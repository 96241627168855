import * as React from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Button } from 'components'
import { colors } from 'configs'
import { useDisclosure } from 'services/hooks'

import updateNote_dangerous from 'services/api/endpoints/risikaAPI/notes/resources/updateNote_dangerous'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { updateStaleData } from 'store_deprecated/credit-check/actions'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  button: {
    textDecoration: 'underline',
    color: colors.risika,
    cursor: 'pointer',
    fontWeight: 600,
  },
}))

const EditInternalId = ({ internalId, localId }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userLanguage = useSelector((state) => state.auth?.user?.local?.lang)
  const [internalIdValue, setInternalIdValue] = React.useState('')

  async function handleSave() {
    const note = {
      data: {
        _internal_id: internalIdValue === '' ? null : internalIdValue,
      },
      localId,
    }

    if (note) {
      await updateNote_dangerous(note)

      dispatch(
        updateStaleData({
          localID: localId,
          language: userLanguage,
        })
      )
    }

    onClose()
  }

  return (
    <>
      <Box className={styles.button} onClick={onOpen}>
        <FontAwesomeIcon icon={faPen} />
      </Box>
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="edit-internal-id">
        <DialogTitle id="edit-internal-id">
          <FormattedMessage id={intl.companyInfo('edit-internal-id-title')} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="edit-internal-id"
            label={<FormattedMessage id={intl.companyInfo('edit-internal-id-label')} />}
            type="text"
            fullWidth
            onChange={(event) => setInternalIdValue(event.target.value)}
            defaultValue={internalId}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose} color="primary">
            <FormattedMessage id={intl.generic('cancel')} />
          </Button>
          <Button onClick={handleSave} color="primary">
            <FormattedMessage id={intl.generic('edit')} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditInternalId
