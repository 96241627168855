import { Stack, Typography } from '@mui/material'
import numeral from 'numeral'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { CustomLegendWithPadding } from '../customizations'
import { AbbreviatedNumbersAxis } from '../customizations'
import {
  SimpleLineChartPropsType,
  SimpleLineChartCustomizedTooltip,
  SimpleLineChartTooltip,
} from './SimpleLineChart.types'
import { simpleLineChartCustomTooltip } from './SimpleLineChart.styles'
import { useUserSettings } from 'services/queries'
import ErrorScene from 'components-new/layouts/BoxContainer/scenes/ErrorScene'
import { theme } from 'styles-new/mui5-transition/theme'

const { fontFamily, fontWeight, fontSize, letterSpacing, color } =
  theme.typography.body2

const SimpleLineChart = ({ data, chartProperties }: SimpleLineChartPropsType) => {
  // Support currency converter (display the right user-selected currency)
  const userSettingsQuery = useUserSettings()

  if (!data?.length) {
    return <ErrorScene height={chartProperties.height} />
  }
  let selectedCurrency = 'DKK'
  if (userSettingsQuery.status === 'success') {
    selectedCurrency = userSettingsQuery.data?.selectedCurrency.currency
  }

  const keys = Object.keys(data?.[0] ?? [])
  keys.shift()
  const formatTooltipValue = (value: number, name: string) => {
    const type = chartProperties?.chartType ?? ''

    // if name is Credit Score
    if (name === keys[0] && type === 'ratio') return value.toFixed(1)
    if (type === 'compliance') return value
    if (type === 'ratio') return `${value.toFixed(2)}%`
    if (type === 'financial-number') {
      return `${numeral(value)
        .format('0 a')
        .replace(/\s/g, '')
        .toUpperCase()} ${selectedCurrency}`
    }
    return value
  }

  const CustomizedTooltip = (tooltipProps: SimpleLineChartCustomizedTooltip) => {
    if (tooltipProps.payload!.length === 0) return null
    return (
      <Stack spacing={1} sx={simpleLineChartCustomTooltip}>
        <Typography variant="subtitle1">{tooltipProps.label}</Typography>
        {tooltipProps.payload!.map((tooltip: SimpleLineChartTooltip) => {
          const value = formatTooltipValue(tooltip.value, tooltip.name)
          return (
            <Typography variant="body2" key={tooltip.name}>
              {tooltip.name}: {value}
            </Typography>
          )
        })}
      </Stack>
    )
  }

  return (
    <ResponsiveContainer height={400}>
      <LineChart width={730} height={250} data={data} margin={chartProperties.margin}>
        {chartProperties.cartesianGrid && <CartesianGrid strokeDasharray="3 3" />}
        {chartProperties.xAxis && (
          <XAxis
            stroke={color}
            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            letterSpacing={letterSpacing}
            fill={color}
            dataKey="name"
          />
        )}
        {chartProperties.yAxis && (
          <YAxis
            stroke={color}
            tick={<AbbreviatedNumbersAxis />}
            domain={chartProperties?.domain ?? ['auto', 'auto']}
          />
        )}
        {chartProperties.tooltip && <Tooltip content={<CustomizedTooltip />} />}
        {chartProperties.legend && <Legend content={CustomLegendWithPadding} />}
        {keys.map((key, index) => {
          return (
            <Line
              key={key}
              type={chartProperties.lineType ?? 'monotone'}
              dataKey={key}
              stroke={chartProperties.colors.strokes[index]}
              strokeWidth={chartProperties.strokeWidth ?? 1}
            />
          )
        })}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default SimpleLineChart
