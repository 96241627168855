import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

// type IndustryTree = {
//   [key: string | number]: {
//     group: IndustryTree,
//     title: string,
//     level: number,
//   },
// }

// type Industry = {
//   code: string,
//   description: string,
//   level: number,
// }

const industryList = (
  // country: SupportedCountry
  country
) => {
  return risikaAPI
    .get(`/list/industries`, {
      microservice: 'data',
      country,
    })
    .then(({ data }) => {
      const { industries } = data

      return industries
    })
}

export default industryList
