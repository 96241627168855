import { SxProps } from '@mui/system'

const menuItem = { paddingX: 4, paddingY: 2 }
const loadingMenuItem = { paddingX: 4, paddingY: 2, width: '100vw' }
const listItem = { marginLeft: 2 }

const buttonContainer: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: 4,
  paddingRight: 4,
  paddingY: 3,
  backgroundColor: 'grey.50',
  borderTop: '1px solid',
  borderTopColor: 'grey.200',
  borderBottomLeftRadius: 1,
  borderBottomRightRadius: 1,
  position: 'absolute',
  bottom: 0,
  width: '100%',
}

export default { buttonContainer, menuItem, listItem, loadingMenuItem }
