import {
  AlreadyActivePolicyDialog,
  IncompletePolicyDialog,
} from 'pages/enterprise-credit-policy/widgets/Communication'
import DeletePolicyDialog from 'pages/enterprise-credit-policy/widgets/FloatingActionMenu/widgets/DeletePolicyDialog'
import { EditPolicySidePanel } from 'pages/enterprise-credit-policy/widgets/SidePanels'
import TestPolicySidePanel from 'pages/enterprise-credit-policy/widgets/SidePanels/TestPolicySidePanel'
import { useHandleDeletePolicy } from './NavBarActions.controller'

type NavBarPanelsProps = {
  isTestPolicyPanelOpen: boolean
  setIsTestPolicyPanelOpen: (value: boolean) => void
  isDeletePolicyPanelOpen: boolean
  setIsDeletePolicyPanelOpen: (value: boolean) => void
  isEditPolicyPanelOpen: boolean
  setIsEditPolicyPanelOpen: (value: boolean) => void
  policyId: string
  params: any
  otherActivePolicyName: string
  showActiveDialog: boolean
  setShowActiveDialog: (value: boolean) => void
  savePolicy: () => void
  handleSave: () => void
  showIncompletePolicyModal: boolean
  setShowIncompletePolicyModal: (value: boolean) => void
  policyName?: string
}

function NavBarPanels({
  isTestPolicyPanelOpen,
  isDeletePolicyPanelOpen,
  isEditPolicyPanelOpen,
  setIsEditPolicyPanelOpen,
  setIsDeletePolicyPanelOpen,
  setIsTestPolicyPanelOpen,
  policyId,
  params,
  otherActivePolicyName,
  showActiveDialog,
  setShowActiveDialog,
  savePolicy,
  handleSave,
  showIncompletePolicyModal,
  setShowIncompletePolicyModal,
  policyName,
}: NavBarPanelsProps) {
  const { handleDeletePolicy } = useHandleDeletePolicy(policyId)
  return (
    <>
      <TestPolicySidePanel
        isOpen={isTestPolicyPanelOpen}
        setIsOpen={setIsTestPolicyPanelOpen}
        policyId={policyId}
        country={params.country}
        model="BOHR"
      />
      <AlreadyActivePolicyDialog
        currentName={params.name}
        activeName={otherActivePolicyName}
        country={params.country}
        open={showActiveDialog}
        setOpen={setShowActiveDialog}
        action={savePolicy}
      />
      <IncompletePolicyDialog
        action={handleSave}
        open={showIncompletePolicyModal}
        setOpen={setShowIncompletePolicyModal}
      />
      {isEditPolicyPanelOpen && (
        <EditPolicySidePanel
          isOpen={isEditPolicyPanelOpen}
          setIsOpen={setIsEditPolicyPanelOpen}
          policyId={policyId}
        />
      )}
      <DeletePolicyDialog
        open={isDeletePolicyPanelOpen}
        setOpen={setIsDeletePolicyPanelOpen}
        policyId={policyId}
        policyName={policyName ?? ''}
        action={handleDeletePolicy}
      />
    </>
  )
}

export default NavBarPanels
