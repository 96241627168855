import callBackendAPI from 'services/api/helpers/backendAPI'
import { AvailableCountriesCapital, UserModelType } from '../../../../globalTypes'

/******************
 * Fetch from API *
 ******************/

export type CustomerResponseData = {
  name: string
  local_id: string
  country: AvailableCountriesCapital
  responsible_user_id: number
  domain: string
  expires_at: Date
  is_revoked: boolean
  is_removed: boolean
  user_model: UserModelType
}

type Data = {
  data: CustomerResponseData
}

const getCustomerData = () => {
  return callBackendAPI('/customer').then(({ data }: Data) => ({
    ...data,
    user_model_json: data.user_model,
  }))
}

export default getCustomerData
