import risikaAPI from 'services/api/helpers/risikaAPI'
import { CompanyType } from 'types/general'

/******************
 * Fetch from API *
 ******************/

export type SupportedCountries = 'dk' | 'no' | 'se' | 'fi'
type ResponseData = CompanyType[]

const companyTypeList = (country: SupportedCountries): Promise<ResponseData> => {
  return risikaAPI
    .get(`/list/company_types`, {
      microservice: 'data',
      country,
    })
    .then(({ data }) => {
      return data.company_types
    })
}

export default companyTypeList
