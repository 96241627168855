import notesGet from '../notesGet'
import notesAdd from '../notesAdd'
import mergeNote from './mergeNote'

/**
 * Will overwrite their already existing notes.
 *
 * @param newNotes
 */
export default async function updateNote_dangerous(newNote) {
  const existingNote = await notesGet(newNote.localId)

  const mergedNote = mergeNote(existingNote, newNote)
  await notesAdd([mergedNote.value])

  return mergedNote
}
