import * as React from 'react'
import { Collapse } from '@mui/material'
import { HierarchyListViewProps } from './HierarchyListView.types'

import RecursiveTable from './widgets/RecursiveTable'
import { BodyCells } from './widgets/BodyCells'
import { TableListView } from 'components-new'
import HeaderCells from './widgets/HeaderCells'
import { CompanyHierarchy } from 'types/general'

function HierarchyListView({
  data,
  collapseAllCounter,
  increaseDepth,
  cacheDepth,
}: HierarchyListViewProps) {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    if (collapseAllCounter < 0) {
      setOpen(false)
    }
  }, [collapseAllCounter])

  const handleIncreaseDepth = (nodeDepth: number) => {
    if (nodeDepth + 1 >= cacheDepth) {
      increaseDepth()
    }
  }

  return (
    <>
      {data.map((row: CompanyHierarchy) => (
        <>
          <TableListView
            tableSize="small"
            headerCells={<HeaderCells />}
            bodyCells={
              <BodyCells
                row={row}
                open={open}
                setOpen={setOpen}
                increaseDepth={handleIncreaseDepth}
                depth={1}
              />
            }
          />
          <Collapse in={open}>
            <RecursiveTable
              collapseAllCounter={collapseAllCounter}
              key={row?.local_id?.id ?? '' + row?.local_id?.country ?? ''}
              data={row.children ?? []}
              increaseDepth={handleIncreaseDepth}
              depth={1}
            />
          </Collapse>
        </>
      ))}
    </>
  )
}

export default HierarchyListView
