import { SxProps } from '@mui/system'

export const buttonFontStyles: SxProps = {
  fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: 1,
  textTransform: 'none',
}

const commonButtonStyles: SxProps = {
  ...buttonFontStyles,
  boxShadow: 1,
  padding: '12px 16px',
  border: 'none',
}

export const buttonPrimaryStyles: SxProps = {
  ...commonButtonStyles,
  backgroundColor: 'secondary.300',
  color: 'grey.800',
  '&:hover': {
    boxShadow: 2,
    backgroundColor: 'secondary.500',
  },
  '&:focus': {
    boxShadow: 2,
    borderColor: 'secondary.500',
    backgroundColor: 'secondary.300',
  },
  '&:active': {
    boxShadow: 0,
    backgroundColor: 'secondary.500',
  },
  '&:disabled': {
    boxShadow: 0,
    color: 'grey.600',
    backgroundColor: 'grey.300',
  },
}

export const buttonSecondaryStyles: SxProps = {
  ...commonButtonStyles,
  backgroundColor: 'primary.700',
  color: 'common.white',
  textTransform: 'none',
  '&:hover': {
    boxShadow: 2,
    backgroundColor: 'primary.900',
  },
  '&:focus': {
    boxShadow: 1,
    borderColor: 'primary.500',
    backgroundColor: 'primary.700',
  },
  '&:active': {
    boxShadow: 0,
    backgroundColor: 'primary.700',
  },
  '&:disabled': {
    boxShadow: 0,
    color: 'grey.600',
    backgroundColor: 'grey.300',
  },
}

export const buttonTertiaryStyles: SxProps = {
  ...commonButtonStyles,
  backgroundColor: 'primary.100',
  boxShadow: 1,
  color: 'primary.700',
  '&:hover': {
    boxShadow: 2,
    backgroundColor: 'primary.300',
  },
  '&:focus': {
    boxShadow: 1,
    borderColor: 'primary.500',
    backgroundColor: 'primary.100',
  },
  '&:active': {
    boxShadow: 0,
    backgroundColor: 'primary.300',
  },
  '&:disabled': {
    boxShadow: 0,
    color: 'grey.600',
    backgroundColor: 'grey.300',
  },
}

export const buttonQuaternaryStyles: SxProps = {
  ...commonButtonStyles,
  backgroundColor: 'transparent',
  color: 'primary.700',
  boxShadow: 0,
  '&:hover': {
    backgroundColor: 'grey.100',
  },
  '&:focus': {
    borderColor: 'primary.500',
    backgroundColor: 'primary.100',
  },
  '&:active': {
    color: 'primary.900',
  },
  '&:disabled': {
    color: 'grey.600',
  },
}

export const buttonDestructiveStyles: SxProps = {
  ...commonButtonStyles,
  backgroundColor: 'error.700',
  color: 'common.white',
  '&:hover': {
    boxShadow: 2,
    backgroundColor: 'error.900',
  },
  '&:focus': {
    boxShadow: 1,
    borderColor: 'error.500',
    backgroundColor: 'error.700',
  },
  '&:active': {
    boxShadow: 0,
    backgroundColor: 'error.900',
  },
  '&:disabled': {
    boxShadow: 0,
    color: 'grey.600',
    backgroundColor: 'grey.300',
  },
}

export const buttonLinkStyles: SxProps = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 3,
  letterSpacing: 0.2,
  textDecorationLine: 'underline',
  textUnderlineOffset: 5,
  cursor: 'pointer',
  ' &:hover': { textDecoration: 'underline' },
}

export const buttonLinkTwoStyles: SxProps = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '22px',
  letterSpacing: 0.2,
  textDecorationLine: 'underline',
  textUnderlineOffset: 5,
  cursor: 'pointer',
  textDecoration: 'underline !important',
  ' &:focus': {
    borderColor: 'primary.500',
    borderWidth: 2,
    borderRadius: 1,
    borderStyle: 'solid',
  },
}
