import React, { ReactNode } from 'react'
import { Typography, Box, TypographyVariant } from '@mui/material'

type TableCellProps = {
  children: ReactNode
  align?: 'center' | 'right' | 'left' | 'inherit' | 'justify' | undefined
  isActive?: boolean
  dataCy?: string
  variant?: TypographyVariant
}

export const TableCell = ({
  align = 'center',
  children,
  isActive = true,
  dataCy = '',
  variant = 'body2',
}: TableCellProps) => (
  <Box data-cy={dataCy} className="table-cell-box" style={{ width: '100%' }}>
    <Typography
      align={align}
      variant={variant}
      sx={{ color: isActive ? 'grey.800' : 'grey.600' }}
    >
      {children}
    </Typography>
  </Box>
)

export default TableCell
