import { Typography } from '@mui/material'
import { padding } from './columns.styles'
import { PeopleVisitResult } from 'services/api/endpoints/risikaAPI/peopleVisitsList'

type Props = {
  item: PeopleVisitResult
}
function Roles({ item }: Props) {
  return (
    <Typography
      sx={{
        maxWidth: 300,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...padding,
        paddingRight: 0,
      }}
      color="grey.800"
      variant="body2"
    >
      {/* @ts-ignore  Ignoring this, because the type is wrong coming from the API. It should be fixed in teh future */}
      {item.functions.map((func) => func.title).join(', ')}
    </Typography>
  )
}

export default Roles
