import callBackendAPI from 'services/api/helpers/backendAPI'
import { Role } from 'types/general'

/**********************
 * Update User's Role *
 **********************/

export type UpdateUserData = {
  role: Role
  name: string
  userId: number
  phone: string
}

type ResponseDataUser = {
  data: {
    id: number
    role: Role
    email: string
    name?: string
    phone_number?: string
    api_user_id: number
    is_verified: boolean
    last_login: string
    customer_id: number
  }
}

// Currently this updates only user role
const updateUser = ({ role, name, phone, userId }: UpdateUserData) => {
  return callBackendAPI(`/user/${userId}`, {
    method: 'put',
    data: {
      role,
      name,
      phone_number: phone,
    },
  }).then(({ data }) => {
    return data
  })
}

export default updateUser
