import { Box, Checkbox, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { FilterCheckboxOptionProps } from './FilterCheckboxOption.types'

const FilterCheckboxOption = (props: FilterCheckboxOptionProps) => {
  const { dataCy, indeterminate, checked, filter, onChange } = props
  return (
    <Stack direction="row" alignItems="center" flexBasis="45%">
      <Box p={'6px'}>
        <Checkbox
          data-cy={dataCy}
          indeterminate={indeterminate}
          checked={checked}
          onChange={() => onChange()}
          value={filter.filter}
        />
      </Box>
      <Typography variant="body1">
        <FormattedMessage id={intl.advancedSearch(props.filter.label)} />
      </Typography>
    </Stack>
  )
}

export default FilterCheckboxOption
