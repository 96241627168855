import { SxProps } from '@mui/system'

export const classCenterDiv: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

export const classJustifyContentCenter: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

export const cursorPointer = {
  cursor: 'pointer',
}
