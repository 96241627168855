import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import intl from '../../localization/components'

export type InputRangeValue = { a: string | number; b: string | number }

type CustomValidationFn = (value: InputRangeValue) => {
  errorA: boolean
  errorB: boolean
  errorMessageA?: JSX.Element
}

type InputRangeProps = {
  value: InputRangeValue
  setValue: (value: InputRangeValue) => void
  customValidationFn?: CustomValidationFn
  skipValidation?: boolean
  skipAutoValidation?: boolean
  isValid?: (isValid: boolean) => void
}

const useInputRange = ({
  value,
  setValue,
  customValidationFn,
  skipValidation,
  skipAutoValidation,
  isValid,
}: InputRangeProps) => {
  const [errorA, setErrorA] = useState(false)
  const [errorB, setErrorB] = useState(false)
  const [errorMessage, setErrorMessage] = useState<JSX.Element | undefined>(undefined)

  const handleValidation = () => {
    if (skipValidation) return
    if (customValidationFn) {
      const { errorA, errorB, errorMessageA } = customValidationFn(value)
      setErrorA(errorA)
      setErrorB(errorB)
      setErrorMessage(errorMessageA)
      isValid?.(!errorA && !errorB)
      return
    }
    const { a, b } = value
    if (a && b && Number(a) > Number(b)) {
      setErrorA(true)
      setErrorB(true)
      setErrorMessage(<FormattedMessage id={intl.snackbar('account-activated-failed')} />)
      isValid?.(false)
      return
    }
    if (b && !a) {
      setErrorA(true)
      setErrorB(false)
      setErrorMessage(undefined)
      isValid?.(false)
      return
    }
    if (a && !b) {
      setErrorA(false)
      setErrorB(true)
      setErrorMessage(undefined)
      isValid?.(false)
      return
    }
    setErrorA(false)
    setErrorB(false)
    setErrorMessage(undefined)
    isValid?.(true)
  }

  // This dependency array is correct because we want to run this effect only when the value changes
  useEffect(() => {
    if (skipAutoValidation) return
    handleValidation()
  }, [value])

  const handleInputAChange = (val: string | number) => {
    const { b } = value
    setValue({ a: val, b })
  }

  const handleInputBChange = (val: string | number) => {
    const { a } = value
    setValue({ a, b: val })
  }

  // return { errorA, errorB, errorMessage }
  return {
    errorA,
    errorB,
    errorMessage,
    handleInputAChange,
    handleInputBChange,
    handleValidation,
  }
}

export default useInputRange
