import React from 'react'
import { makeStyles } from '@mui/styles'

import SimpleEntry from './SimpleEntry'

import { ConditionallyRender } from 'components'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  showAllButton: {
    marginTop: '0.75rem',
    paddingTop: '0.75rem',
    borderTop: '1px solid #979797',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '25%',
    },
  },
}))

const Purpose = ({ purpose }) => {
  const classes = useStyles()

  const [showAllPurpose, setShowAllPurpose] = React.useState(false)
  return (
    <ConditionallyRender
      condition={purpose?.length > 400}
      when={
        <ConditionallyRender
          condition={showAllPurpose}
          when={
            <div>
              <SimpleEntry
                condition={!!purpose}
                title={<FormattedMessage id={intl.companyInfo('purpose')} />}
                content={[purpose]}
              />
              <button
                className={classes.showAllButton}
                onClick={() => setShowAllPurpose(false)}
                type="button"
              >
                <FormattedMessage id={intl.companyRoles('show-less')} />
              </button>
            </div>
          }
          otherwise={
            <div>
              <SimpleEntry
                condition={!!purpose}
                title={<FormattedMessage id={intl.companyInfo('purpose')} />}
                classNameTitle={classes.marginTop}
                content={[`${purpose?.substring(0, 250)}...`]}
              />
              <button
                className={classes.showAllButton}
                onClick={() => setShowAllPurpose(true)}
                type="button"
              >
                <FormattedMessage id={intl.companyRoles('show-all')} />
              </button>
            </div>
          }
        />
      }
      otherwise={
        <SimpleEntry
          condition={!!purpose}
          title={<FormattedMessage id={intl.companyInfo('purpose')} />}
          classNameTitle={classes.marginTop}
          content={[purpose]}
        />
      }
    />
  )
}

export default Purpose
