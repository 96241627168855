function defaultReducer(state, { field, value }) {
  return {
    ...state,
    [field]: value,
  }
}

/**
 * Create the configuration for the `useReducer` hook.
 */
export function createReducer(initialState, reducer) {
  return [reducer ?? defaultReducer, initialState]
}
