import React, { useRef } from 'react'
import { SxProps } from '@mui/system'
import { Stack } from '@mui/material'
import { theme } from 'styles-new/mui5-transition/theme'
import { risikaBoxShadow } from 'styles/global-ng'

import { useShowButtons } from 'services/hooks'
import ResizableButtonContainer from 'components-new/layouts/ResizableButtonContainer'

type Props = {
  leftSide?: React.ReactElement[]
  rightSide: React.ReactElement[]
  // rightSide: React.ReactElement & { ref: React.RefObject<HTMLElement> }[]
  disabled: boolean
}

const main: SxProps = {
  borderRadius: 1,
  backgroundColor: theme.palette.common.white,
  height: theme.spacing(7),
  ...risikaBoxShadow,
  margin: '8px 0 0 0',
  paddingRight: 3,
}

const disabledStyle: SxProps = {
  opacity: '0.5',
  pointerEvents: 'none',
}

const MenuBar = ({ leftSide, rightSide, disabled }: Props) => {
  let style: SxProps = main
  const mainContainerRef = useRef(null)
  const rightSideRef = useRef(null)
  const leftSideRef = useRef(null)

  const shownButtons = useShowButtons({
    mainContainerRef,
    buttonsContainerRef: rightSideRef,
    extraRefs: [leftSideRef],
    children: rightSide,
  })

  if (disabled) {
    style = {
      ...disabledStyle,
      ...style,
    }
  }

  return (
    <Stack
      className="TEST_MENU_BAR"
      ref={mainContainerRef}
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={style}
    >
      <Stack
        ref={leftSideRef}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {leftSide?.map((comp) => comp)}
      </Stack>
      <Stack direction="row">
        <ResizableButtonContainer
          shownButtons={shownButtons}
          headerId="menu-bar"
          secondaryActions={[]}
        >
          {rightSide?.map((comp) => comp)}
        </ResizableButtonContainer>
      </Stack>
    </Stack>
  )
}

export default MenuBar
