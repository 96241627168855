import { useState } from 'react'
import {
  IconButton,
  Stack,
  Box,
  Typography,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import { ButtonLink, ButtonSecondary, InputField } from 'components-new'
import { useUpdateNoteDangerous } from 'services/queries/useUpdateNoteDangerous'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { InternalIdCellProps } from './InternalIdCell.types'
import styles from './InternalIdCell.styles'

export const InternalIdCell = ({ item, refetch }: InternalIdCellProps) => {
  const { local_organization_id } = item.original
  const textContent = item.internal_id === '*' ? 'N/A' : item.internal_id

  const [isEditing, setIsEditing] = useState(false)
  const [internalId, setInternalId] = useState(
    item.internal_id === '*' ? '' : item.internal_id
  )

  const { mutateAsync } = useUpdateNoteDangerous()
  const { enqueueSnackbar } = useSnackbar()

  const handleSave = () => {
    const data = {
      data: {
        _internal_id: internalId || null,
      },
      localId: local_organization_id,
    }

    mutateAsync(data)
      .then(() => {
        refetch()
        enqueueSnackbar(<FormattedMessage id={intl.generic('successful_changes')} />, {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(
          <FormattedMessage id={intl.snackbar('account-activated-failed')} />,
          {
            variant: 'error',
          }
        )
      })
  }

  const handleOpen = () => setIsEditing(true)
  const handleClose = () => setIsEditing(false)

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="body2">{textContent}</Typography>
      <Box>
        <IconButton onClick={handleOpen} sx={styles.editButtonStyles}>
          <CreateIcon fontSize="inherit" color="inherit" />
        </IconButton>
        {isEditing && (
          <ClickAwayListener onClickAway={handleClose}>
            <Paper component={Stack} spacing={2} elevation={2} sx={styles.inputBoxStyles}>
              <InputField autoFocus value={internalId} handleChange={setInternalId} />
              <Stack spacing={3} direction="row">
                <ButtonSecondary onClick={handleSave}>
                  <FormattedMessage id={intl.generic('save')} />
                </ButtonSecondary>
                <ButtonLink onClick={handleClose}>
                  <FormattedMessage id={intl.generic('cancel')} />
                </ButtonLink>
              </Stack>
            </Paper>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  )
}

export default InternalIdCell
