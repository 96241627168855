import { Stack, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/ErrorOutline'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const NoDataScene = ({ height }: { height: number }) => {
  return (
    <Stack justifyContent="center" alignItems="center" height={height}>
      <Stack spacing={2} alignItems="center" width="320px">
        <ErrorIcon
          sx={{
            color: 'grey.600',
            fontSize: '4rem',
          }}
        />
        <Typography align="center" variant="h5">
          <FormattedMessage id={intl.snackbar('report-error-no-data-available')} />
        </Typography>
        <Typography color="grey.700" align="center" variant="body1">
          <FormattedMessage id={intl.errors('observation-list-no-data-to-load')} />
        </Typography>
      </Stack>
    </Stack>
  )
}

export default NoDataScene
