import { useQuery } from 'react-query'
import { paymentRemarks } from 'services/api'
import { isCallAllowed } from 'configs'

export function usePaymentRemarks(localId, staleTime = 0) {
  return useQuery(
    ['paymentRemarks', localId.id, localId.country],
    () => paymentRemarks(localId),
    {
      staleTime,
      enabled: isCallAllowed('paymentRemarks', localId.country),
      retry: (failureCount, error) => {
        console.log('errp', error)
      },
    }
  )
}
