import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
  TypographyVariant,
} from '@mui/material'
import React from 'react'
import displayPropsModel from '../../models/DisplaySettingsModel'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useHierarchySceneContext } from 'pages/company-hierarchy/context/hierarchySceneContext'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const paperStyles = { padding: 0, boxShadow: 0, ul: { padding: 0 } }

const buttonActiveStyles = {
  boxShadow: 0,
  border: '2px solid',
  borderColor: 'primary.500',
}

const buttonStyles = (open: boolean) => ({
  color: 'grey.800',
  boxShadow: 0,
  fontSize: '32px',
  border: open ? buttonActiveStyles.border : '1px solid',
  borderColor: open ? buttonActiveStyles.borderColor : 'grey.200',
  borderRadius: 1,
  height: 48,
  padding: 3,
  textTransform: 'none',
  '&:active': buttonActiveStyles,
  '&:focus': { border: '1px solid', borderColor: 'grey.200' },
})

const DisplaySettings = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { displayState, toggleProperty } = useHierarchySceneContext()

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        disableRipple
        component={Stack}
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{ ...buttonStyles(open) }}
      >
        <Typography color="inherit" variant="body1">
          <FormattedMessage id={intl.companyHierarchy('display-props')} />
        </Typography>
        {open ? (
          <KeyboardArrowUpIcon fontSize="inherit" sx={{ color: 'primary.main' }} />
        ) : (
          <KeyboardArrowDownIcon fontSize="inherit" sx={{ color: 'primary.main' }} />
        )}
      </Button>
      <Menu
        slotProps={{
          paper: { sx: paperStyles },
          root: { sx: { padding: 0 } },
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FormGroup
          sx={{
            padding: 4,
            boxSizing: 'border-box',
            width: anchorEl?.offsetWidth || 200,
          }}
        >
          {Object.keys(displayState).map((item) => {
            const label =
              displayPropsModel[item as keyof typeof displayPropsModel]?.intlLabel

            return (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    checked={displayState[item]}
                    onChange={(e) => toggleProperty(e, item)}
                  />
                }
                label={
                  <Typography variant={'checkboxSmallLabel' as TypographyVariant}>
                    <FormattedMessage id={intl.companyHierarchy(label)} />
                  </Typography>
                }
              />
            )
          })}
        </FormGroup>
      </Menu>
    </React.Fragment>
  )
}

export default DisplaySettings
