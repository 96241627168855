import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  bold: {
    textStrokeWidth: 1,
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))
