import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const select: SxProps = {
  background: theme.palette.grey[100],
}

const button: SxProps = {
  background: theme.palette.grey[100],
  color: theme.palette.grey[700],
  boxShadow: 'none',
  height: '38px',
  '&:hover': {
    color: theme.palette.grey[100],
  },
}

const inputLabel: SxProps = {
  cursor: 'pointer',
}

const actionButton: SxProps = {
  height: '40px',
  paddingX: 3,
  textTransform: 'capitalize',
  background: theme.palette.grey[100],
  borderColor: theme.palette.grey[100],
}

const dropdownArrow: SxProps = {
  fontSize: '2rem',
  color: '#909294',
}

export const styles = {
  select,
  button,
  inputLabel,
  actionButton,
  dropdownArrow,
}
