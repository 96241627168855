import { useEffect, useMemo, useRef, useState } from 'react'
import { CommunicationModal, SimpleTable } from 'components-new'
import { Box, Stack, useMediaQuery } from '@mui/material'
import TablePaginationToolbar from 'components-new/data-display/Table/elements/TablePaginationToolbar'
import { useCreateNewUser } from 'services/queries/useCreateNewUser'
import { useRemoveUser, useUpdateUser } from 'services/queries'
import { Role } from 'types/general'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import {
  IsAddingNewUserState,
  ModalOpenState,
  PageState,
  PerPageLimitState,
  SearchState,
  SelectedRowState,
  SelectedUserState,
  User,
  UsertableProps,
  UserToBeDeletedState,
} from './UserTable.types'
import { UserTableHeader } from './widgets'
import { createUserTableColumns, validate } from './UserTable.controller'
import { newEmptyUser } from './UserTable.model'

const UserTable = ({ data, maxUsers, isLoading }: UsertableProps) => {
  const [open, setOpen] = useState<ModalOpenState>(false)
  const [search, setSearch] = useState<SearchState>('')

  const [page, setPage] = useState<PageState>(1)
  const [perPageLimit, setPerPageLimit] = useState<PerPageLimitState>(20)

  const [selectedRow, setSelectedRow] = useState<SelectedRowState>(null)
  const [selectedUser, setSelectedUser] = useState<SelectedUserState>(null)
  const [userToBeDeleted, setUserToBeDeleted] = useState<UserToBeDeletedState>(null)
  const [isAddingNewUser, setIsAddingNewUser] = useState<IsAddingNewUserState>(false)

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const mainContainerRef = useRef<HTMLDivElement>(null)
  const isSmallScreen = useMediaQuery('(max-width: 599px)')

  const { mutateAsync: createUser } = useCreateNewUser()
  const { mutateAsync: removeUser } = useRemoveUser()
  const { mutateAsync: updateUser } = useUpdateUser()

  const { enqueueSnackbar } = useSnackbar()

  const filteredResults = useMemo(() => {
    if (!search) return data
    setPage(1)
    return data.filter((user: User) => {
      return user.name.toLowerCase().includes(search.toLowerCase())
    })
  }, [data, search])

  const totalPages = useMemo(
    () => Math.ceil(filteredResults.length / perPageLimit),
    [filteredResults, perPageLimit]
  )

  const paginatedResults: User[] = useMemo(() => {
    const startIndex = (page - 1) * perPageLimit
    const endIndex = startIndex + perPageLimit
    return isAddingNewUser
      ? [newEmptyUser, ...filteredResults.slice(startIndex, endIndex)]
      : filteredResults.slice(startIndex, endIndex)
  }, [filteredResults, page, perPageLimit, isAddingNewUser])

  useEffect(() => {
    if (selectedRow !== null) {
      setSelectedUser(paginatedResults[selectedRow])
    }
  }, [paginatedResults, selectedRow])

  useEffect(() => {
    setOpen(() => userToBeDeleted !== null)
  }, [userToBeDeleted])

  // Set width of main container initially on mount
  useEffect(() => {
    const calculatedWidth = isSmallScreen ? window.innerWidth : window.innerWidth - 100
    mainContainerRef.current?.style.setProperty('width', `${calculatedWidth}px`)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // This is to set width of main container on resize so the table overflows on the side and scrolls
  useEffect(() => {
    function handleResize() {
      if (mainContainerRef.current) {
        const calculatedWidth = isSmallScreen
          ? window.innerWidth
          : window.innerWidth - 100
        mainContainerRef.current.style.width = `${calculatedWidth}px`
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSmallScreen])

  const handlePage = (nextPage: number) => {
    if (selectedUser !== null || selectedRow !== null) return
    setPage(nextPage)
    tableContainerRef.current?.scrollTo(0, 0)
  }

  const handleUpdateUser = () => {
    if (!selectedUser) return

    const data = {
      role: selectedUser.roles.map((x) => x.value)?.[0],
      name: selectedUser.name,
      phone: selectedUser.phoneNumber || '',
      email: selectedUser.email,
      userId: selectedUser.userId,
    }

    const updateUserSuccess = () => {
      setSelectedUser(null)
      setSelectedRow(null)
      enqueueSnackbar(<FormattedMessage id={intl.settings('user-update-success')} />, {
        variant: 'success',
      })
    }
    const updateUserError = () => () => {
      enqueueSnackbar(<FormattedMessage id={intl.settings('user-update-error')} />, {
        variant: 'error',
      })
    }

    const validationResult = validate(data)

    if (validationResult === '') {
      updateUser(data).then(updateUserSuccess).catch(updateUserError)
    } else {
      enqueueSnackbar(<FormattedMessage id={intl.settings(validationResult)} />, {
        variant: 'error',
      })
    }
  }

  const handleDeleteUser = (i: UserToBeDeletedState) => {
    if (i === null) return
    const user = paginatedResults[i]

    const deleteUserSuccess = () => {
      setSelectedUser(null)
      setSelectedRow(null)
      setUserToBeDeleted(null)
      enqueueSnackbar(<FormattedMessage id={intl.settings('user-delete-success')} />, {
        variant: 'success',
      })
    }

    const deleteUserError = () =>
      enqueueSnackbar(<FormattedMessage id={intl.settings('user-delete-error')} />, {
        variant: 'error',
      })

    removeUser({ id: user.userId }).then(deleteUserSuccess).catch(deleteUserError)
  }

  const handleUpdateUserData = (field: 'name' | 'roles' | 'email' | 'phoneNumber') => {
    if (!selectedUser) return
    if (field === 'roles') {
      return (value: Role) =>
        setSelectedUser({
          ...selectedUser,
          roles: [{ value, label: value === 'admin' ? 'Admin' : 'User' }],
        })
    }
    return (value: string) => setSelectedUser({ ...selectedUser, [field]: value })
  }

  const handleCreateUser = () => {
    if (!selectedUser) return

    const data = {
      name: selectedUser.name,
      email: selectedUser.email,
      phone: selectedUser.phoneNumber || '',
      role: selectedUser.roles[0].value,
    }

    const validationResult = validate(data)
    if (validationResult === '') {
      const createUserSuccess = () => {
        enqueueSnackbar(<FormattedMessage id={intl.settings('user-create-success')} />, {
          variant: 'success',
        })
        setSelectedUser(null)
        setSelectedRow(null)
        setIsAddingNewUser(false)
      }

      const createUserError = () =>
        enqueueSnackbar(<FormattedMessage id={intl.settings('user-create-error')} />, {
          variant: 'error',
        })

      createUser(data).then(createUserSuccess).catch(createUserError)
    } else {
      enqueueSnackbar(<FormattedMessage id={intl.settings(validationResult)} />, {
        variant: 'error',
      })
    }
  }

  const addUser = () => {
    setIsAddingNewUser(true)
    setSelectedRow(0)
  }

  return (
    <Stack ref={mainContainerRef} width="100%" justifyContent="space-between">
      <Stack width="100%" overflow="auto">
        <UserTableHeader
          search={search}
          setSearch={setSearch}
          addUser={addUser}
          canAddUser={data.length < maxUsers}
          disableInput={isAddingNewUser || selectedRow !== null}
        />
        <Box sx={{ overflowX: 'scroll' }}>
          <Box minWidth={1350} ref={tableContainerRef} flexShrink={1} overflow="scroll">
            <SimpleTable<User>
              columns={createUserTableColumns({
                selectedRow,
                setSelectedRow,
                selectedUser,
                setSelectedUser,
                handleUpdateUserData,
                handleUpdateUser,
                handleCreateUser,
                setUserToBeDeleted,
                isAddingNewUser,
                setIsAddingNewUser,
              })}
              data={paginatedResults}
              isLoading={isLoading}
            />
          </Box>
          <TablePaginationToolbar
            page={page}
            totalPages={totalPages}
            handlePage={handlePage}
            perPageLimit={perPageLimit}
            handlePageLimit={setPerPageLimit}
            title={<FormattedMessage id={intl.settings('user-table-results-per-page')} />}
          />
        </Box>
      </Stack>
      <CommunicationModal
        open={open}
        onClose={() => setUserToBeDeleted(null)}
        setOpen={setOpen}
        title={<FormattedMessage id={intl.userManagement('delete-dialog-title')} />}
        body={<FormattedMessage id={intl.userManagement('delete-dialog-content')} />}
        mainButton={{
          content: <FormattedMessage id={intl.confirmAction('delete')} />,
          click: () => handleDeleteUser(userToBeDeleted),
        }}
        secondaryButton={{
          content: <FormattedMessage id={intl.confirmAction('cancel')} />,
          click: () => setUserToBeDeleted(null),
        }}
      />
    </Stack>
  )
}

export default UserTable
