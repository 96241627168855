import React, { FC, ReactNode, useEffect, useState } from 'react'
import SupportContext from './SupportContext'

type SupportProviderProps = {
  children: ReactNode
}

const SupportProvider: FC<SupportProviderProps> = ({ children }) => {
  const isPromptDisplayFromLocalStorage = localStorage.getItem('isSupportPromptDisplay')
  const [isSupportPromptDisplay, setIsSupportPromptDisplay] = useState(
    isPromptDisplayFromLocalStorage ? JSON.parse(isPromptDisplayFromLocalStorage) : true
  )

  useEffect(() => {
    localStorage.setItem('isSupportPromptDisplay', JSON.stringify(isSupportPromptDisplay))
  })

  const setSupportDisplay = (value: boolean) => {
    setIsSupportPromptDisplay(value)
  }

  const contextValue = {
    isSupportPromptDisplay,
    setSupportDisplay,
  }

  return (
    <SupportContext.Provider value={contextValue}>{children}</SupportContext.Provider>
  )
}

export default SupportProvider
