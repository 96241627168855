import { Paper, Stack } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Typography } from '@mui/material/'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'

const SidebarWidgetError = ({
  title,
  customSelector,
}: {
  title: string
  customSelector?: keyof typeof intl
}) => {
  const selector = title === 'explanation' ? 'risika-score-calculation' : title
  return (
    <Paper
      component={Stack}
      elevation={2}
      spacing={2}
      m={4}
      p={4}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 36,
        color: 'grey.600',
      }}
    >
      <ErrorOutlineIcon fontSize="inherit" color="inherit" />
      <Typography variant="h5" component="div">
        Something went wrong
      </Typography>
      <Typography variant="body1" textAlign="center">
        Sorry we can&apos;t load{' '}
        {<FormattedMessage id={intl[customSelector ?? 'mainRecommendation'](selector)} />}
        &nbsp; at the moment, please try again later
      </Typography>
    </Paper>
  )
}

export default SidebarWidgetError
