import intl from 'localization/components'
import { CurrencyObj } from 'services/types'

export const TREND_UP = 'up'
export const TREND_DOWN = 'down'
export const TREND_NONE = 'none'

const defaultValues = { value: '', trend: TREND_NONE }

export const defaultCurrency = { currency: 'DKK', country: 'DK' } as CurrencyObj

export const financialHighlightsModel = [
  {
    key: 'revenue',
    intl: intl.keyFinancialFigures('revenue'),
    ...defaultValues,
  },
  {
    key: 'gross_result',
    intl: intl.advancedSearch('gross_result'),
    ...defaultValues,
  },
  {
    key: 'profit_loss',
    intl: intl.companyHighlights('profit-loss'),
    ...defaultValues,
  },
  {
    key: 'assets',
    intl: intl.advancedSearch('assets'),
    ...defaultValues,
  },
  {
    key: 'noncurrent_assets',
    intl: intl.advancedSearch('fixed_assets'),
    ...defaultValues,
  },
  {
    key: 'current_assets',
    intl: intl.keyFinancialFigures('currentAssets'),
    ...defaultValues,
  },
  {
    key: 'equity',
    intl: intl.advancedSearch('equity'),
    ...defaultValues,
  },
  {
    key: 'short_term_debt',
    intl: intl.keyFinancialFigures('shortTermDebt'),
    ...defaultValues,
  },
  {
    key: 'long_term_debt',
    intl: intl.keyFinancialFigures('longTermDebt'),
    ...defaultValues,
  },
  {
    key: 'employees_number',
    intl: intl.advancedSearch('employees_number'),
    ...defaultValues,
  },
]
