import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReduxRootState } from 'store_deprecated/types'
import { env } from 'services/env'
import { getAccountStatusData } from 'utils/getAccountStatusData'
const tableIdOrName = env.AIR_TABLE_TABLE_NAME
const baseId = env.AIR_TABLE_BASE_ID

function CreditOverride() {
  const status = getAccountStatusData()
  const { user } = useSelector((state: ReduxRootState) => state.auth)

  const params = useParams<{ requestId?: string }>()
  const appName = status?.credit_override_config?.app_name || baseId
  const requestId = params?.requestId
  const iframeSrc = requestId
    ? `https://override.risika.com/review-request?id=${requestId}&email=${user.data.email}&name=${user.data.name}&app_name=${appName}&table_name=${tableIdOrName}`
    : `https://override.risika.com/?email=${user.data.email}&name=${user.data.name}&app_name=${appName}&table_name=${tableIdOrName}`

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        margin: '0',
        padding: '0',
      }}
    >
      <iframe
        style={{
          flexGrow: '1',
          border: 'none',
          margin: '0',
          padding: '0',
        }}
        src={iframeSrc}
      ></iframe>
    </Box>
  )
}

export default CreditOverride
