import { MappingItem } from '../types'

const mapping: MappingItem[] = [
  { label: '1-year', value: 1 },
  { label: '2-years', value: 2 },
  { label: '3-years', value: 3 },
  { label: '4-and-more', value: 4 },
]

export default mapping
