import { ReactNode } from 'react'
import { Typography } from '@mui/material'
import { Column } from 'components-new/data-display/SimpleTable/SimpleTable.types'
import { CompanyVisit } from 'services/api/endpoints/risikaAPI/companyVisitsList'
import Company from './columns/Company'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import RisikaScore from './columns/RisikaScore'
import LocalId from './columns/LocalId'
import Status from './columns/Status'
import Type from './columns/Type'
import { AddToMonitoringListButton, Button } from 'components'

const HeaderComponent = ({ label }: { label: ReactNode }) => (
  <Typography py={4} px={6} variant="subtitle2">
    {label}
  </Typography>
)

export const columns: Column<CompanyVisit>[] = [
  {
    id: 'name',
    width: '20%',
    bodyComponent: (item) => <Company item={item} />,
    headerComponent: () => (
      <HeaderComponent label={<FormattedMessage id={intl.generic('company')} />} />
    ),
  },
  {
    id: 'risika_score',
    width: '6%',
    align: 'center',
    bodyComponent: (item) => <RisikaScore item={item} />,
    headerComponent: () => (
      <HeaderComponent
        label={<FormattedMessage id={intl.riskMonitoringSubscription('score')} />}
      />
    ),
  },
  {
    id: 'local_id',
    width: '5%',
    bodyComponent: (item) => <LocalId item={item} />,
    headerComponent: () => (
      <HeaderComponent
        label={<FormattedMessage id={intl.searchBusiness('local-id')} />}
      />
    ),
  },
  {
    id: 'status',
    width: '5%',
    bodyComponent: (item) => <Status item={item} />,
    headerComponent: () => (
      <HeaderComponent label={<FormattedMessage id={intl.searchBusiness('status')} />} />
    ),
  },
  {
    id: 'type',
    width: '5%',
    bodyComponent: (item) => <Type item={item} />,
    headerComponent: () => (
      <HeaderComponent label={<FormattedMessage id={intl.searchBusiness('type')} />} />
    ),
  },
  {
    id: 'monitoring-button',
    width: '5%',
    align: 'center',
    bodyComponent: (item) => (
      <AddToMonitoringListButton
        // @ts-ignore // Ignore, because the component is .js
        buttonText={<FormattedMessage id={intl.generic('monitor')} />}
        color="contrast"
        company={item.local_organization_id}
        ready={true}
        ButtonComponent={Button}
      />
    ),
    headerComponent: () => (
      <HeaderComponent
        label={<FormattedMessage id={intl.companyHierarchy('monitor')} />}
      />
    ),
  },
]
