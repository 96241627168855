// @flow
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SimpleEntry from './SimpleEntry'

const EntryWithSubtitle = ({ data, subtitle, title }) => {
  return subtitle ? (
    <SimpleEntry
      title={title}
      elementType="div"
      content={[
        <Box key={title}>
          <Typography variant="body2">{subtitle}</Typography>
          <Typography variant="body2">{data}</Typography>
        </Box>,
      ]}
    />
  ) : (
    ''
  )
}

export default EntryWithSubtitle
