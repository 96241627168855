import { Box, Stack, Typography } from '@mui/material'
import { classes } from '../../HierarchyListView.styles'
import { TableCellProps } from '../../HierarchyListView.types'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

function Ownership({ row, align }: TableCellProps) {
  return (
    <Stack
      width={1}
      justifyContent="center"
      alignItems="center"
      direction="row"
      spacing={1}
    >
      {row?.type !== 'COMPANY' && (
        <Box>
          <FormattedMessage id={intl.companyHierarchy(row?.type)} />
        </Box>
      )}
      {row?.shares_interval && (
        <Box sx={{ ...classes.ownershipText(align), whiteSpace: 'nowrap' }}>
          {row.shares_interval}
        </Box>
      )}
    </Stack>
  )
}
export default Ownership
