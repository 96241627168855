import React, { useState } from 'react'
import { Menu } from '@mui/material'

const Dropdown = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (fn) => () => {
    fn()
    handleClose()
  }

  /**
   * Registers a new onClick handler using the
   * children's original onClick handler.
   *
   * @param {MenuItem} children The component's children.
   */
  const childrenWithOnClickHandler = (children) =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        onClick: handleMenuItemClick(child.props.onClick),
      })
    )

  const { render, children } = props

  return (
    <React.Fragment>
      {render(handleOpen)}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {childrenWithOnClickHandler(children)}
      </Menu>
    </React.Fragment>
  )
}

export default Dropdown
