import * as React from 'react'

import { useMediaQuery } from '@mui/material'
import * as d3 from 'd3'

import { colors } from 'configs'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { theme } from 'styles-new/mui5-transition/theme'

const {
  palette: { grey },
} = theme

// type D3AnalyticsDoughnutProps = {
//   riskCounts: ?{
//     LOW: number,
//     MEDIUM: number,
//     HIGH: number,
//     NONE: number,
//   },
// }

const D3AnalyticsDoughnut = ({ riskCounts }) => {
  const analytics = React.useRef(null)
  const reactIntl = useIntl()
  const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const width = isSmallDisplay ? 240 : 340
  const height = isSmallDisplay ? 290 : 140

  React.useEffect(() => {
    let total = null

    if (riskCounts != null) {
      Object.keys(riskCounts).forEach((x) => {
        total = riskCounts[x] + (total ?? 0)
      })
      const data = [
        {
          amount: riskCounts?.HIGH ?? 0,
          color: colors?.highRisk,
          label: reactIntl.messages[intl.riskMonitoringOverview('high-risk')],
          key: 'high_risk',
        },
        {
          amount: riskCounts?.MEDIUM ?? 0,
          color: colors?.mediumRisk,
          label: reactIntl.messages[intl.riskMonitoringOverview('medium-risk')],
          key: 'medium_risk',
        },
        {
          amount: riskCounts?.LOW ?? 0,
          color: colors?.lowRisk,
          label: reactIntl.messages[intl.riskMonitoringOverview('low-risk')],
          key: 'low_risk',
        },
        {
          amount: riskCounts?.NONE ?? 0,
          color: colors?.noScore,
          label: reactIntl.messages[intl.riskMonitoringOverview('no-score')],
          key: 'no_score',
        },
      ]
      if (data && analytics.current) {
        const svg = d3.select(analytics.current)
        svg.selectAll('*').remove()

        const svgEnter = svg.append('g').attr('transform', `translate(80,70)`)

        const pieChart = d3
          .pie()
          .value((d) => d.amount)
          .sort(null)

        const pieData = svgEnter.selectAll('.pie').data(pieChart(data))
        const textNumber = svgEnter.selectAll('.centerText').data([total])
        const textWord = svgEnter.selectAll('.textWord').data([total])
        const legendDots = svgEnter.selectAll('.legendDots').data(data)
        const legendText = svgEnter.selectAll('.legendText').data(data)
        const amountText = svgEnter.selectAll('.amountText').data(data)
        const arcData = svgEnter.selectAll('.arcEmpty').data(pieChart([{ amount: 100 }]))
        // Just for space :D
        const arc = d3.arc().innerRadius(57).outerRadius(65)
        const emptyArc = d3.arc().innerRadius(57).outerRadius(65)

        arcData
          .enter()
          .append('path')
          .attr('d', emptyArc)
          .attr('class', 'arcEmpty')
          .style('fill', 'lightgrey')
          .attr('transform', isSmallDisplay ? `translate(45, -3)` : null)

        pieData
          .enter()
          .append('path')
          .attr('class', 'pie')
          .attr('fill', ({ data }) => {
            return data?.color
          })
          .attr('d', arc)
          .attr('transform', isSmallDisplay ? `translate(45, -3)` : null)
        // .attr('stroke', 'white')
        // .attr('stroke-width', '6px')

        legendDots
          .enter()
          .append('circle')
          .attr('class', 'legendDots')
          .attr('cx', isSmallDisplay ? -30 : 100)
          .attr('cy', function (d, i) {
            if (isSmallDisplay) {
              return 100 + i * 30
            } else {
              return -45 + i * 30
            }
          }) // 100 is where the first dot appears. 25 is the distance between dots
          .attr('r', 5)
          .style('fill', ({ color }) => color)

        legendText
          .enter()
          .append('text')
          .attr('class', 'legendText')
          .attr('dx', ({ amount }) =>
            isSmallDisplay
              ? 2 + amount?.toString()?.length * 6
              : 130 + amount?.toString()?.length * 6
          )
          .attr('dy', function (d, i) {
            if (isSmallDisplay) {
              return 105 + i * 30
            } else {
              return -40 + i * 30
            }
          }) // 100 is where the first dot appears. 25 is the distance between dots
          // body2 typography styles
          .style('fill', grey[800])
          .style('font-family', 'Lato')
          .style('font-size', '14px')
          .style('font-style', 'normal')
          .style('font-weight', '400')
          .style('line-height', '22px')
          .style('letter-spacing', '0.2px')
          .text(({ label }) => `- ${label}`)

        amountText
          .enter()
          .append('text')
          .attr('class', 'amountText')
          .attr('dx', isSmallDisplay ? -10 : 110)
          .attr('dy', function (d, i) {
            if (isSmallDisplay) {
              return 105 + i * 30
            } else {
              return -40 + i * 30
            }
          }) // 100 is where the first dot appears. 25 is the distance between dots
          .style('fill', 'black')
          .style('font-size', '1.5rem')
          .style('font-weight', '600')
          .text(({ amount }) => `${amount}`)

        textNumber
          .enter()
          .append('text')
          .attr('class', 'centerText')
          .attr('dx', isSmallDisplay ? 45 : 0)
          .attr('dy', 0)
          .style('text-anchor', 'middle')
          .style('cursor', 'default')
          .style(
            'font-family',
            '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif'
          )
          .style('text-align', 'center')
          .style('font-weight', '700')
          .style('font-style', 'normal')
          .style('font-size', '24px')
          .style('line-height', '28px')
          .style('fill', grey[800])
          .text(total ?? null)

        // H5 test styles
        textWord
          .enter()
          .append('text')
          .attr('class', 'textWord')
          .attr('dx', isSmallDisplay ? 45 : 0)
          .attr('dy', 20)
          .style('text-anchor', 'middle')
          .style('cursor', 'default')
          .style(
            'font-family',
            '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif'
          )
          .style('font-weight', '600')
          .style('font-size', '18px')
          .style('letter-spacing', '0')
          .style('line-height', '24px')
          .style('fill', grey[800])
          .text(reactIntl.messages[intl.riskMonitoringOverview('total')])

        // Remove old D3 elements
        svgEnter.exit().remove()
        pieData.exit().remove()
        textNumber.exit().remove()
        textWord.exit().remove()
      }
    }
  }, [riskCounts, reactIntl.messages, isSmallDisplay])

  return <svg className="d3-component" width={width} height={height} ref={analytics} />
}

export default D3AnalyticsDoughnut
