import * as React from 'react'
import Select from './Select'
import { searchSuggestions } from 'services/api'
import { compose } from 'ramda'
import { useQueryInvalidation } from 'services/hooks'
import { useStyles } from '../styles/company-search'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Flag } from 'components'
import { Stack } from '@mui/material'
// type Suggestion = {
//   name: string,
//   localId: any,
// }

/**
 * Convert the suggestions to options the
 * select component will understand.
 *
 * @param suggestions The suggestions from the request response.
 */
const convertToOptions = (suggestions) => {
  return suggestions.map(({ name, localId }) => ({
    label: `${name} (${localId.id})`,
    value: localId.id,
  }))
}

// type CompanySearchProps = {
//   onChange: (event: any) => any,
//   onBlur?: (event: any) => any,
//   error?: boolean,
//   placeholder?: any,
//   label?: any,
//   id?: string,
// }

const CompanySearch = ({ onChange, onBlur, error, placeholder, label, id }) => {
  const classes = useStyles()
  const [country, setCountry] = React.useState('dk')

  const queryInvalidation = useQueryInvalidation()

  /**
   * Check if the promise that was provided got
   * invalidated by the queryInvalidation.
   *
   * If it got invalidated, reject it completely.
   *
   * @param promise The promise to check.
   */
  const rejectInvalidated = (promise) => {
    return promise.then(([invalidated, result]) => {
      if (!invalidated) {
        return result
      }
    })
  }

  /**
   * Search for a company.
   *
   * @param inputValue The search term.
   */
  const companySearchRequest = (inputValue) => {
    return searchSuggestions(inputValue, country).then(convertToOptions)
  }

  /**
   * Search for company, but make sure requests
   * get invalidated to prevent stale results.
   */
  const searchForCompany = compose(
    rejectInvalidated,
    queryInvalidation,
    companySearchRequest
  )

  /**
   * Concert a value to a primitive "event" only
   * containing the target value.
   *
   * Useful when this format is expected, but not provided.
   */
  const eventify = (value) => ({ target: { value } })

  /**
   * Notify the onChange callback that an option
   * has been selected.
   *
   * @param option The selected option.
   */
  const handleSelectedOption = (option) => {
    onChange(eventify({ id: option?.value ?? '', country }))
  }

  return (
    <>
      {label ? (
        <label className={classes.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <Stack spacing={2}>
        <ToggleButtonGroup
          value={country}
          exclusive
          onChange={(_, value) => setCountry(value)}
          aria-label="country selector"
        >
          <ToggleButton value="dk" aria-label="Denmark">
            <Flag country="dk" />
            <FormattedMessage id={intl.country('dk')} />
          </ToggleButton>
          <ToggleButton value="no" aria-label="Norway">
            <Flag country="no" />
            <FormattedMessage id={intl.country('no')} />
          </ToggleButton>
          {/* <ToggleButton value="se" aria-label="Sweden">
            <Flag country="se" />
            <FormattedMessage id={intl.country('se')} />
          </ToggleButton> */}
        </ToggleButtonGroup>

        <Select
          placeholder={placeholder}
          id={id}
          onBlur={onBlur}
          isClearable
          selectedOption={handleSelectedOption}
          onChange={searchForCompany}
          error={error}
        />
      </Stack>
    </>
  )
}

export default CompanySearch
