import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { PropsType } from './SideDrawer.types'
import { classes } from './SideDrawer.styles'

const SideDrawer = ({ drawerState, side, children }: PropsType) => {
  return (
    <Box>
      <Drawer
        sx={classes.drawerStyles}
        anchor={side}
        open={drawerState.drawerIsOpen}
        onClose={() => drawerState.setDrawerIsOpen(false)}
        ModalProps={{
          keepMounted: false,
        }}
      >
        {children}
      </Drawer>
    </Box>
  )
}

export default SideDrawer
