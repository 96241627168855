import * as React from 'react'
import { Typography } from '@mui/material'
import { CommunicationModal } from 'components-new'
import { AvailableCountries } from 'globalTypes'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

type AlreadyActivePolicyDialogProps = {
  activeName: string
  currentName: string
  country: AvailableCountries
  action: () => void
  open: boolean
  setOpen: (open: boolean) => void
}
function AlreadyActivePolicyDialog({
  activeName,
  currentName,
  country,
  action,
  open,
  setOpen,
}: AlreadyActivePolicyDialogProps) {
  const { formatMessage } = useIntl()
  const mainButtonClick = () => {
    action()
    setOpen(false)
  }

  return (
    <CommunicationModal
      title={formatMessage({ id: intl.creditPolicyNew('only-one-active-policy-title') })}
      body={
        <Typography variant="body1">
          <FormattedMessage
            id={intl.creditPolicyNew('already-active-dialog')}
            values={{ country, activeName, currentName }}
          />
        </Typography>
      }
      open={open}
      setOpen={setOpen}
      mainButton={{
        content: formatMessage({ id: intl.creditPolicyNew('deactivate-and-publish') }),
        click: mainButtonClick,
      }}
      secondaryButton={{
        content: formatMessage({ id: intl.creditPolicyNew('save-as-draft') }),
        click: () => setOpen(false),
      }}
    />
  )
}

export default AlreadyActivePolicyDialog
