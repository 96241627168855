import { InputField } from 'components-new'
import { Typography } from '@mui/material'
import { EmailCellProps } from '../../UserTable.types'

const EmailCell = ({
  user,
  selectedUser,
  isAddingNewUser,
  isSelected,
  handleUpdateUserEmail,
}: EmailCellProps) => {
  if (isAddingNewUser && selectedUser && isSelected) {
    return <InputField value={selectedUser.email} handleChange={handleUpdateUserEmail} />
  }

  return <Typography variant="body2">{user.email}</Typography>
}

export default EmailCell
