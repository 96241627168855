import React, { useEffect } from 'react'
import { DropdownSelect } from 'components-new'
import mapping from './model'
import { getScoreArray, getScoreLabel } from '../controller'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { useIntl } from 'react-intl'

// This one definitely does not need translation :D.
const options = [
  { label: <FormattedMessage id={intl.creditPolicyNew('1')} />, value: '1' },
  { label: <FormattedMessage id={intl.creditPolicyNew('2')} />, value: '2' },
  { label: <FormattedMessage id={intl.creditPolicyNew('3')} />, value: '3' },
  { label: <FormattedMessage id={intl.creditPolicyNew('4')} />, value: '4' },
  { label: <FormattedMessage id={intl.creditPolicyNew('5')} />, value: '5' },
  { label: <FormattedMessage id={intl.creditPolicyNew('6')} />, value: '6' },
  { label: <FormattedMessage id={intl.creditPolicyNew('7')} />, value: '7' },
  { label: <FormattedMessage id={intl.creditPolicyNew('8')} />, value: '8' },
  { label: <FormattedMessage id={intl.creditPolicyNew('9')} />, value: '9' },
  { label: <FormattedMessage id={intl.creditPolicyNew('10')} />, value: '10' },
]

const RisikaScoreInput = ({ isAdvanced = true }) => {
  const reactIntl = useIntl()
  const { policy, setPolicy, isInferiorPlan } = useCountryPolicy()

  const [val, setVal] = React.useState<string>(
    getScoreLabel(policy.exclude_risika_scores, mapping)
  )

  useEffect(() => {
    setVal(getScoreLabel(policy.exclude_risika_scores, mapping))
  }, [policy])

  useEffect(() => {
    if (val !== getScoreLabel(policy.exclude_risika_scores, mapping)) {
      setPolicy({
        ...policy,
        exclude_risika_scores: getScoreArray(val, mapping) as number[],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val])

  return (
    <DropdownSelect
      placeholder={reactIntl.formatMessage({
        id: intl.creditPolicy('select'),
      })}
      options={options}
      value={val}
      disablePlaceholderClick={false}
      setValue={setVal}
    />
  )
}

export default RisikaScoreInput
