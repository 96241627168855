import {
  STORE_CREDIT_CHECK_INFORMATION,
  STORE_CREDIT_INFORMATION,
  UPDATE_HIGHLIGHT_INFORMATION,
  HANDLE_UPDATE_STALE_DATA_SUCCESS,
  HANDLE_FLUSH_CACHED_PAGES,
  SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION,
  SUBMIT_FETCH_COMPANY_INFORMATION,
  SUBMIT_UPDATE_STALE_DATA,
} from './constants'

const handleStoreCreditCheckInformation = (state, action) => {
  // Not sure how to fix this one.
  const { payload } = action
  return {
    ...state,
    cachedPages: {
      ...state.cachedPages,
      [payload.localID.id]: payload.result,
    },
  }
}
const handleUpdateHighlightInformation = (state, action) => {
  const { payload } = action
  const { data, language, localId } = payload
  return {
    ...state,
    cachedPages: {
      ...state.cachedPages,
      [localId.id]: {
        ...state.cachedPages[localId.id],
        highlights: {
          highlights: data,
          language,
        },
      },
    },
  }
}
const handleUpdateStaleDataSuccess = (state, action) => {
  const { payload } = action
  const {
    highlights,
    company,
    language,
    localID,
    financialRatios,
    financialNumbers,
    financialNumbersConsolidated,
    financialStats,
    financialStatsConsolidated,
    financialPerformance,
  } = payload
  return {
    ...state,
    cachedPages: {
      ...state.cachedPages,
      [localID.id]: {
        ...state.cachedPages[localID.id],
        company,
        financialRatios,
        financialNumbers,
        financialNumbersConsolidated,
        financialStats,
        financialStatsConsolidated,
        financialPerformance,
        highlights: {
          highlights,
          language,
        },
        status: 'SUCCESS',
      },
    },
  }
}
const handleStoreCreditInformation = (state, action) => {
  // Not sure how to fix this one.
  const { payload, status } = action
  return {
    ...state,
    cachedPages: {
      ...state.cachedPages,
      [payload.localID.id]: {
        ...state.cachedPages[payload.localID.id],
        ratingCredit: payload.ratingCredit,
        ratingScores: payload.ratingScores,
        status,
      },
    },
  }
}
const handleFlushCachedPages = (state, action) => {
  return {
    ...state,
    cachedPages: initialState.cachedPages,
  }
}
const handleSetLoadingStatus = (state, action) => {
  const { payload, status } = action

  return {
    ...state,
    cachedPages: {
      ...state.cachedPages,
      [payload.localID.id]: {
        ...state.cachedPages[payload.localID.id],
        status,
      },
    },
  }
}

export const initialState = {
  cachedPages: {},
}

export const creditCheck = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case STORE_CREDIT_CHECK_INFORMATION:
      return handleStoreCreditCheckInformation(state, action)
    case STORE_CREDIT_INFORMATION:
      return handleStoreCreditInformation(state, action)
    case UPDATE_HIGHLIGHT_INFORMATION:
      return handleUpdateHighlightInformation(state, action)
    case HANDLE_UPDATE_STALE_DATA_SUCCESS:
      return handleUpdateStaleDataSuccess(state, action)
    case HANDLE_FLUSH_CACHED_PAGES:
      return handleFlushCachedPages(state, action)
    case SUBMIT_FETCH_COMPANY_CREDIT_INFORMATION:
      return handleSetLoadingStatus(state, action)
    case SUBMIT_FETCH_COMPANY_INFORMATION:
      return handleSetLoadingStatus(state, action)
    case SUBMIT_UPDATE_STALE_DATA:
      return handleSetLoadingStatus(state, action)
    default:
      return state
  }
}

export default creditCheck
