import { colors } from 'configs'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
  icon: {
    color: 'grey.700',
  },
  showHistoryIcon: {
    width: '4rem',
  },
  crumb: {
    display: 'flex',
    color: 'black',
    fontSize: '2rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    justifyContent: 'center',
    backgroundColor: colors?.risikaLight,
    borderRadius: '0.5rem',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors?.risikaLightDark,
    },
  },
  iconStyle: {
    marginRight: '1rem',
    height: '1.8rem',
    color: 'grey.800',
  },
}))
