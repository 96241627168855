import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  iconButton: {
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
}))
