import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import { classOutlineBox } from 'styles-new/global/components'
import ErrorOccurred from 'components-new/elements/ErrorOccurred'
import DefaultSkeleton from './skeletons/DefaultSkeleton'
import ChartWithTextSkeleton from './skeletons/ChartWithTextSkeleton'
import BigNumbersSkeleton from './skeletons/BigNumbersSkeleton'
import HorizontalEqualBarSkeleton from './skeletons/HorizontalEqualBarSkeleton'

const BoxContainerSmall = ({
  boxData,
  children,
  withTitle = true,
}: {
  boxData: any
  children: React.ReactNode
  withTitle?: boolean
}) => {
  let content = children

  if (boxData.status.isLoading) {
    content = <DefaultSkeleton height={boxData.height} />

    if (boxData.skeletonType === 'CHART_WITH_TEXT') {
      content = <ChartWithTextSkeleton />
    } else if (boxData.skeletonType === 'HORIZONTAL_EQUAL_BAR') {
      content = <HorizontalEqualBarSkeleton height={boxData.height} />
    } else if (boxData.skeletonType === 'BIG_NUMBERS') {
      content = <BigNumbersSkeleton height={boxData.height} />
    }
  } else if (boxData.status.isError || boxData.status.isIdle) {
    content = <ErrorOccurred />
  }

  return (
    <Box className="MUI5 PortfolioRiskDistribution">
      <Box sx={{ mb: 1.5 }}>
        {withTitle && (
          <Typography variant="h5">
            <FormattedMessage id={boxData?.title} />
          </Typography>
        )}
      </Box>
      <Box sx={boxData.outlineBox ? classOutlineBox : null}>{content}</Box>
    </Box>
  )
}

export default BoxContainerSmall
