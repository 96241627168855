import SearchIcon from '@mui/icons-material/Search'
import { InputField } from '../../index'
import { InputFieldProps } from '../InputField/InputField.types'

const InputSearchText = (props: InputFieldProps) => {
  return (
    <InputField
      {...props}
      startAdornment={<SearchIcon sx={{ fontSize: 24, color: '#353A3F !important' }} />}
    />
  )
}

export default InputSearchText
