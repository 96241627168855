import * as React from 'react'
import { CircularProgress, Tooltip, Stack } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { useUserSettings } from 'services/queries'
import { updateUserSettings } from 'services/api'
import { useQueryClient } from 'react-query'
import { ButtonTertiary, InputField } from 'components-new'
const acceptedLocationKeys = ['exportOptionsMonitoring', 'exportOptionsFiltering']

/**
 * @param {Array<string>} selectedFields It contains the fields that are selected in the parent container.
 * Those fields will later be saved in the database
 * @param {Function} setSelectedFields A function that is used to update the selectedFields container
 * @param {string} persistedLocationKey A key that will be saved in the database. Be careful with this one.
 * To add a new key just update the "acceptedLocationKeys" array.
 */

type StoreExportOptionsProps = {
  selectedFields: string[]
  setSelectedFields: string
  persistedLocationKey: string
}
const StoreExportOptions = ({
  selectedFields,
  setSelectedFields,
  persistedLocationKey,
}: StoreExportOptionsProps) => {
  const userSettingsQuery = useUserSettings()
  const queryClient = useQueryClient()
  const { messages } = useIntl()

  const [newPresetName, setNewPresetName] = React.useState('')
  const [isShowTextField, setIsShowTextField] = React.useState(false)
  const [disabledButtonMessage, setDisabledButtonMessage] =
    React.useState<React.ReactNode>('')
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true)

  const userSettings = userSettingsQuery.data
  const exportOptions = React.useMemo(
    () =>
      (userSettingsQuery?.data?.[
        persistedLocationKey as keyof (typeof userSettingsQuery)['data']
      ] as Array<any>) ?? [],
    [persistedLocationKey, userSettingsQuery?.data]
  )
  // Handle when the field is Select and when an Input
  React.useEffect(() => {
    const isSelectionAlreadySaved = exportOptions.filter(
      (option) => option.selectedFields.sort().toString() === selectedFields.toString()
    )
    if (isSelectionAlreadySaved?.length) {
      setNewPresetName('')
      setIsShowTextField(false)
      return
    }
    if (selectedFields.length) {
      setIsShowTextField(true)
      return
    }
    setIsShowTextField(false)
  }, [exportOptions, selectedFields])

  // Handle the Save button states and message to customers
  React.useEffect(() => {
    const savedNames = exportOptions.map((option) => option.name)
    if (savedNames.includes(newPresetName)) {
      setDisabledButtonMessage(
        <FormattedMessage id={intl.riskMonitoring('you-already-have-preset-with-name')} />
      )
      setIsButtonDisabled(true)
      return
    }
    if (newPresetName?.length) {
      setDisabledButtonMessage('')
      setIsButtonDisabled(false)
    } else {
      setDisabledButtonMessage(
        <FormattedMessage id={intl.riskMonitoring('please-select-a-name')} />
      )
      setIsButtonDisabled(true)
    }
  }, [exportOptions, newPresetName, selectedFields.length])

  if (userSettingsQuery.isLoading) {
    return <CircularProgress />
  }

  const handleSave = () => {
    // This error handler protects the sanity of the database until we get typescript
    if (!acceptedLocationKeys.includes(persistedLocationKey)) {
      throw new Error(
        `Key does not match "${acceptedLocationKeys}". If you intended to use a new key for this setting please add it in the "acceptedLocationKeys" array in the top of the file.`
      )
    }
    // @ts-expect-error Phasing out Filepond
    updateUserSettings({
      ...userSettings,
      [persistedLocationKey]: [
        ...exportOptions,
        {
          name: newPresetName,
          selectedFields,
          loadDefault: false,
        },
      ],
    }).then(() => {
      queryClient.invalidateQueries('getUserSettings')
    })
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
      <InputField
        value={newPresetName}
        handleChange={setNewPresetName}
        autoFocus
        placeholder={messages[intl.generic('name')] as string}
      />
      <Tooltip placement="top" title={isShowTextField ? disabledButtonMessage : ''}>
        <ButtonTertiary disabled={isButtonDisabled} onClick={handleSave}>
          {<FormattedMessage id={intl.confirmAction('save')} />}
        </ButtonTertiary>
      </Tooltip>
    </Stack>
  )
}

export default StoreExportOptions
