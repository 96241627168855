import * as React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { EdgeLabelRenderer, getSmoothStepPath } from 'reactflow'
import { EdgeProps } from '../PolicyBuilder.types'
import { customEdgeStyle } from './Edges.styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Add } from '@mui/icons-material'
import { useBuilder } from '../BuilderContext'
import TreeSelectionActionPanel from './widgets/TreeSelectionActionPanel'

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  label,
  markerEnd,
  target,
  source,
}: EdgeProps) {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const canBeOpen = open && Boolean(anchorEl)
  const popoverId = canBeOpen ? 'transition-popper' : undefined

  const { state } = useBuilder()
  const [edgePath] = getSmoothStepPath({
    sourceX: sourceX - 60,
    sourceY,
    sourcePosition,
    targetX: targetX + 3.5,
    targetY,
    targetPosition,
  })

  const styles = label
    ? customEdgeStyle.policyBuilderEdgeStyles[label as 'Yes' | 'No']
    : {}

  if (!label) return null

  const isTargetValidNode = () => {
    const targetNode = state.nodes.find((node) => node.id === target)
    if (targetNode?.type !== 'plus') {
      return true
    }
    return false
  }
  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        style={style}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...customEdgeStyle.edge({ targetX, targetY }),
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography sx={styles} variant="subtitle2" color="inherit">
              <FormattedMessage
                id={intl.generic(
                  `${typeof label === 'string' ? label.toLowerCase() ?? '' : ''}`
                )}
              />
            </Typography>
          </Stack>
          {isTargetValidNode() ? (
            <IconButton onClick={handleClick} sx={customEdgeStyle.plusStyle}>
              <Add color="inherit" fontSize="inherit" />
            </IconButton>
          ) : null}
        </Stack>
      </EdgeLabelRenderer>

      <TreeSelectionActionPanel
        open={open}
        setOpen={setOpen}
        source={source}
        target={target}
      />
    </>
  )
}

export default CustomEdge
