import Box from '@mui/material/Box'
import { chartProperties, parameters } from './ComplianceHistory.model'
/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'
import intl from 'localization/components'
import { useMonitoringStatistic } from 'services/queries'
import BoxContainerSmall from 'components-new/layouts/BoxContainerSmall'
import { fitDataToChart } from './ComplianceHistory.controller'
import BarChartStackedBySign from 'components-new/charts/BarChartStackedBySign'
import { useSelector } from 'react-redux'
import { Monitor } from 'risika-api-response-types'
import { ComplianceHistoryDataType } from './ComplianceHistory.types'

const ComplianceHistory = ({ listId }: { listId: string }) => {
  const userLanguage = useSelector((state: any) => state.auth?.user?.local?.lang).slice(
    0,
    2
  )

  parameters.list_id = Number(listId)
  const monitoringStatistics = useMonitoringStatistic<Monitor.ListStatisticsTimeline>({
    parameters,
    endpoint: 'timeline',
  })

  if (monitoringStatistics.isError) {
    return null
  }

  const boxData = {
    title: intl.portfolioInsights('compliance-history'),
    status: {
      isLoading: monitoringStatistics.isLoading,
      isError: monitoringStatistics.isError,
      isIdle: monitoringStatistics.isIdle,
    },
    height: chartProperties.height,
    outlineBox: true,
    skeletonType: 'HORIZONTAL_EQUAL_BAR',
  }
  if (!monitoringStatistics.data) return null

  return (
    <Box sx={{ mt: 4 }}>
      <BoxContainerSmall boxData={boxData}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <BarChartStackedBySign
            data={fitDataToChart(
              monitoringStatistics.data as ComplianceHistoryDataType[],
              userLanguage
            )}
            chartProperties={chartProperties}
          />
        ) : null}
      </BoxContainerSmall>
    </Box>
  )
}

export default ComplianceHistory
