import { FinancialNumbersReturnType } from 'services/api/endpoints/risikaAPI/financialNumbers'
import { DetailedNumberOfEmployees } from 'services/api/endpoints/risikaAPI/getDetailedNumberOfEmployees'
import { FinancialData } from './HighlightsBox.types'
import {
  financialHighlightsModel,
  TREND_DOWN,
  TREND_UP,
  TREND_NONE,
} from './HighlightsBox.model'

/**
 * This function converts fetched raw company data (financialNumbers,
 * financialStats, etc.) into the array of objects that will be displayed as highlights.
 * @param {FinancialNumbersResponse} financialNumbers - raw results from financialNumbersQuery.
 * @param {DetailedNumberOfEmployees} employeesNumbers - raw results from detailedNumberOfEmployeesQuery.
 * @output {Array} - array of object that will be displayed as highlights
 */
export const createFinancialHighlights = (
  financialNumbers: FinancialNumbersReturnType,
  employeesNumbers: DetailedNumberOfEmployees[]
) => {
  const numbersData =
    financialNumbers !== undefined && financialNumbers.length > 0 ? financialNumbers : []
  const employeesData =
    employeesNumbers !== undefined && employeesNumbers.length > 0 ? employeesNumbers : []
  const financialData: FinancialData[] = []
  for (let i = 0; i < 2; i++) {
    const numbers = numbersData[i] ?? {}
    const stats = employeesData[i] ?? {}
    financialData.push({
      ...numbers,
      ...stats,
    })
  }
  return financialHighlightsModel.map((highlight) => {
    const currentYearValue =
      financialData[0][highlight.key as keyof FinancialNumbersReturnType[number]] ?? null
    const previousYearValue =
      financialData[1][highlight.key as keyof DetailedNumberOfEmployees] ?? null
    let trend = TREND_NONE
    if (financialData.length > 1) {
      if (currentYearValue && previousYearValue) {
        if (currentYearValue < previousYearValue) trend = TREND_DOWN
        if (currentYearValue > previousYearValue) trend = TREND_UP
      }
    }
    return { ...highlight, trend, value: currentYearValue }
  })
}
