import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { HistoryHeader, Show } from 'components'
import { ButtonPrimary, ButtonTertiary, ErrorPageSimple } from 'components-new'
import PolicyCards from './PolicyCards'
import { CreatePolicySidePanel } from './widgets/SidePanels'
import { useCreditPolicyGetAll, useUserSelf } from 'services/queries'
import ImportPolicySidePanel from './widgets/SidePanels/ImportPolicySidePanel'
import ImportPolicyFilePond from './widgets/SidePanels/ImportPolicySidePanel/widgets/ImportPolicyFilePond'
import { CreditPolicyResponse } from 'types/queries'
import intl from 'localization/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Mixpanel } from 'services/helpers/mixpanel'
import { useQueryClient } from 'react-query'

const EnterpriseCreditPolicyPage = () => {
  const { messages } = useIntl()
  const [isCreatePolicyPanelOpen, setIsCreatePolicyPanelOpen] = useState(false)
  const [isImportPanelOpen, setIsImportPanelOpen] = useState(false)
  const [importedPolicy, setImportedPolicy] = useState<CreditPolicyResponse | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const { data: userData, isLoading: isUserDataLoading } = useUserSelf()
  const role = userData?.role
  const creditPoliciesList = useCreditPolicyGetAll()

  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.removeQueries('useECPDataStructure')
  }, [])

  if (creditPoliciesList.isError) {
    return (
      <ErrorPageSimple
        content={<FormattedMessage id={intl.creditPolicyNew('error-loading-policies')} />}
      />
    )
  }

  const onFileLoaded = (file: CreditPolicyResponse) => {
    setImportedPolicy(file)
    setModalOpen(false)
    setIsImportPanelOpen(true)
  }

  const handleHeaderClick = (type: string) => {
    if (type === 'import') {
      setModalOpen(true)
    } else if (type === 'create') {
      setIsCreatePolicyPanelOpen(true)
    }

    Mixpanel.track(`User clicked ${type} policy`)
  }

  return (
    <>
      <HistoryHeader
        title={messages[intl.creditPolicyNew('credit-policy-page-title')] as string}
        location="enterprise-credit-policy"
        historyKey="enterprise-credit-policy"
      >
        <Show when={role === 'admin'}>
          <ButtonTertiary
            disabled={role === 'user'}
            onClick={() => handleHeaderClick('import')}
            data-cy="credit-policy-import-policy-button"
          >
            <FormattedMessage id={intl.creditPolicyNew('import-a-policy-button')} />
          </ButtonTertiary>
        </Show>
        <Show when={role === 'admin'}>
          <ButtonPrimary
            disabled={role === 'user'}
            data-cy="credit-policy-create-new-policy-button"
            onClick={() => handleHeaderClick('create')}
          >
            <FormattedMessage id={intl.creditPolicyNew('create-a-policy-button')} />
          </ButtonPrimary>
        </Show>
      </HistoryHeader>
      <Stack gap={8} padding={4} paddingTop={6} sx={{ height: '100%' }}>
        <PolicyCards
          data={creditPoliciesList.data?.policies}
          loading={creditPoliciesList.isLoading || isUserDataLoading}
          onCreateClick={handleHeaderClick}
        />
      </Stack>
      <CreatePolicySidePanel
        isOpen={isCreatePolicyPanelOpen}
        setIsOpen={setIsCreatePolicyPanelOpen}
      />
      <ImportPolicyFilePond
        open={modalOpen}
        setOpen={setModalOpen}
        onFileLoaded={onFileLoaded}
      />
      <ImportPolicySidePanel
        isOpen={isImportPanelOpen}
        setIsOpen={setIsImportPanelOpen}
        importedPolicy={importedPolicy}
      />
    </>
  )
}

export default EnterpriseCreditPolicyPage
