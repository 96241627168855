import intl from 'localization/components'
export const ceoKey = 'CHIEF EXECUTIVE OFFICER'

export const listViewTableHeaders = [
  {
    label: intl.generic('company'),
    align: 'left',
    visible: true,
    width: 450,
    componentName: 'companyName',
  },
  {
    label: intl.companyHierarchy('ownership'),
    align: 'center',
    visible: true,
    width: 250,
    componentName: 'ownership',
  },
  {
    label: intl.riskMonitoring('score'),
    align: 'center',
    visible: true,
    width: 100,
    componentName: 'risikaScore',
  },
  {
    label: intl.companyHierarchy('local-id'),
    align: 'left',
    visible: true,
    width: 140,
    componentName: 'localId',
  },
  {
    label: intl.relations('ceo'),
    align: 'left ',
    visible: true,
    width: 300,
    componentName: 'ceo',
  },
  {
    label: intl.companyHierarchy('equity'),
    align: 'left',
    visible: true,
    width: 200,
    componentName: 'equity',
  },
  {
    label: intl.companyHierarchy('profit_loss'),
    align: 'left',
    visible: true,
    width: 200,
    componentName: 'profitLoss',
  },
  {
    label: intl.companyHierarchy('monitor'),
    align: 'center',
    visible: true,
    width: 150,
    componentName: 'monitor',
  },
]
