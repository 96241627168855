import callBackendAPI from 'services/api/helpers/backendAPI'
/******************
 * Fetch from API *
 ******************/

/**
 * Save the user's filter with a name.
 */

type RequestData = {
  filterToBeDeleted: any
  savedFilters: any[]
}

type ResponseData = {
  data: any
}

const deleteFilter = ({
  filterToBeDeleted,
  savedFilters,
}: RequestData): Promise<ResponseData> => {
  const newFilters = savedFilters.filter(
    (x) => JSON.stringify(x) !== JSON.stringify(filterToBeDeleted)
  )
  return callBackendAPI(`/user/settings/filters`, {
    method: 'put',
    data: {
      data: newFilters,
    },
  })
}

export default deleteFilter
