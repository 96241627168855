import { useIntl } from 'react-intl'
import intl from 'localization/components'

const showFrequency = ({ frequency }: { frequency: string }) => {
  const reactIntl = useIntl()

  switch (frequency) {
    case 'daily':
      return reactIntl.formatMessage({ id: intl.monitorFrequency('daily') })
    case 'weekly:monday':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('weekly-monday'),
      })
    case 'weekly:tuesday':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('weekly-tuesday'),
      })
    case 'weekly:wednesday':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('weekly-wednesday'),
      })
    case 'weekly:thursday':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('weekly-thursday'),
      })
    case 'weekly:friday':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('weekly-friday'),
      })
    case 'weekly:saturday':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('weekly-saturday'),
      })
    case 'weekly:sunday':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('weekly-sunday'),
      })
    case 'monthly':
      return reactIntl.formatMessage({
        id: intl.monitorFrequency('monthly'),
      })
    default:
      return ''
  }
}

export default showFrequency
