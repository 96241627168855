import { Stack, Box, Skeleton, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const CompanyOwnersSkeleton = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      padding="20px 20px 0"
    >
      <Stack direction="row" ml={5} flexWrap="wrap">
        <Stack
          pb={6}
          width={290}
          height={290}
          justifyContent="center"
          alignItems="center"
        >
          <Skeleton variant="circular" width={150} height={150} />
        </Stack>
        <Stack mt={6} gap={4} pb={5} justifyContent="end">
          <Box>
            <Typography variant="subtitle1">
              <FormattedMessage id={intl.companyRoles('legal-owners')} />
            </Typography>
            <Skeleton variant="text" width={100} height={22} />
            <Skeleton variant="text" width={150} height={22} />
          </Box>
          <Box>
            <Typography variant="subtitle1">
              <FormattedMessage id={intl.companyRoles('real-owners')} />
            </Typography>
            <Skeleton variant="text" width={100} height={22} />
            <Skeleton variant="text" width={150} height={22} />
          </Box>
        </Stack>
      </Stack>
      <Stack gap={4}>
        <Box alignSelf="flex-end">
          <Skeleton variant="rectangular" width={160} height={150} />
        </Box>
        <Stack direction="row" gap={4}>
          <Skeleton variant="rectangular" width={160} height={150} />
          <Skeleton variant="rectangular" width={160} height={150} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CompanyOwnersSkeleton
