import { Stack, Skeleton } from '@mui/material'

const IndustryTypeInputSkeleton = () => {
  return (
    <Stack spacing={2} marginTop={2}>
      <Skeleton variant="rounded" width="20%" height={20} animation="wave" />
      <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
      <Skeleton
        variant="rounded"
        width="75%"
        height={30}
        animation="wave"
        sx={{ borderRadius: '2rem' }}
      />
      <Skeleton
        variant="rounded"
        width="50%"
        height={30}
        animation="wave"
        sx={{ borderRadius: '2rem' }}
      />
      <Skeleton
        variant="rounded"
        width="60%"
        height={30}
        animation="wave"
        sx={{ borderRadius: '2rem' }}
      />
    </Stack>
  )
}
export default IndustryTypeInputSkeleton
