import { ReactNode } from 'react'

type ShowProps = {
  children: ReactNode
  when: boolean
}

const Show = (props: ShowProps) => {
  const { children, when } = props

  if (when) {
    return children
  }
  return null
}

export default Show
