import risikaAPI from 'services/api/helpers/risikaAPI'
type ConvertCurrency = {
  base: string
  to: string
  value: number
}

type ConvertCurrencyReturn = {
  converted: number
}

async function convertCurrency({ base, to, value }: ConvertCurrency) {
  const { data }: { data: ConvertCurrencyReturn } = await risikaAPI.post(
    '/convert_currency',
    {
      base,
      to,
      value,
    },
    {
      microservice: 'data',
    }
  )
  return data.converted
}

export default convertCurrency
