import { Box } from '@mui/system'
import { skeleton, skeletonContainer } from './RecentChangesTable.styles'
import { Skeleton } from '@mui/material'

export const RecentChangesLoadingState = () =>
  Array.from({ length: 4 }, (_, i) => (
    <Box key={i} sx={skeletonContainer}>
      <Skeleton sx={skeleton} />
    </Box>
  ))
