import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { navbarCloseMobile } from 'store_deprecated'
import { useDispatch } from 'react-redux'
import { useAppShell } from './context'
import { useAccountStatus } from 'services/queries'
import { NavigationItemTitle, NavigationItemPropsType } from './types'
import { ListItemComponent } from './ListItemComponent'

// Create .model file and move it there
const freemiumRestricted = {
  home: false,
  'credit-check': false,
  'risk-monitoring': false,
  'company-comparison': true,
  'relation-overview': false,
  'credit-policy': true,
  'advanced-filters': true,
  'enterprise-credit-policy': true,
  'customer-management': true,
  faq: false,
}

function NavigationItem({
  title,
  url,
  icon,
  disabled = false,
  externalLink,
  subUrls,
  ...props
}: NavigationItemPropsType) {
  const [isFocused, setIsFocused] = useState(false)
  const dispatch = useDispatch()
  const appShell = useAppShell()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const accountStatusQuery = useAccountStatus()

  const isFreemiumRestricted =
    accountStatusQuery.data?.subscription_plan === 'FREEMIUM' && freemiumRestricted[title]

  function handleNavigationClose() {
    dispatch(navbarCloseMobile())
    appShell.setSearchActive(false)
  }

  const handleOpenSearchInstead = (title: NavigationItemTitle) => {
    if (title === 'credit-check') {
      appShell.setResultType('company')
    } else if (title === 'relation-overview') {
      appShell.setResultType('person')
    }
    dispatch(navbarCloseMobile())
    appShell.setSearchActive(true)
  }

  return (
    <Box data-cy={title}>
      {externalLink ? (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', display: 'block' }}
        >
          <ListItemComponent
            url={url}
            disabled={disabled}
            isFreemiumRestricted={isFreemiumRestricted}
            handleOpenSearchInstead={handleOpenSearchInstead}
            title={title}
            icon={icon}
            isSmall={isSmall}
            subUrls={subUrls}
          />
        </a>
      ) : (
        <Link
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          style={{
            textDecoration: 'none',
            display: 'block',
            backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
          }}
          to={url}
          key={title}
          onClick={(e) =>
            disabled || isFreemiumRestricted
              ? e.preventDefault()
              : handleNavigationClose()
          }
          {...props}
        >
          <ListItemComponent
            url={url}
            disabled={disabled}
            isFreemiumRestricted={isFreemiumRestricted}
            handleOpenSearchInstead={handleOpenSearchInstead}
            title={title}
            icon={icon}
            isSmall={isSmall}
            subUrls={subUrls}
          />
        </Link>
      )}
    </Box>
  )
}

export default NavigationItem
