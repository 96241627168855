import { Stack, Typography, SxProps } from '@mui/material'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { ReactElement } from 'react'

type ErrorPageSimpleProps = {
  icon?: JSX.Element
  title?: string | ReactElement
  content?: string | ReactElement
  sx?: SxProps
}
// TODO: Write documentation and link it here
function ErrorPageSimple({
  icon = <ReportProblemIcon sx={{ color: 'grey.500', fontSize: 48 }} />,
  title = 'Something went wrong',
  content = 'Sorry we can’t load this page at the moment, please try again later.',
  sx,
}: ErrorPageSimpleProps) {
  return (
    <Stack
      sx={sx}
      data-cy="error-page-simple"
      height={1}
      width={1}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        textAlign="center"
        gap={3}
        justifyContent="center"
        alignItems="center"
        maxWidth={320}
      >
        <Stack mb={3} direction="row">
          {icon}
        </Stack>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2">{content}</Typography>
      </Stack>
    </Stack>
  )
}

export default ErrorPageSimple
