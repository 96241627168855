import { useSelector, useDispatch } from 'react-redux'
import {
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from '@mui/material'
import { ActionDialog } from 'components'
import { setUserSettings } from 'store_deprecated'
import { updateUserSettings } from 'services/api'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { colors, monitorTableFields as monitorTableFieldsTemplate } from 'configs'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classes } from '../../../styles/table-settings'
import SettingsIcon from '@mui/icons-material/Settings'
import { standardIcon } from 'styles-new/global/components'

const defaultTableFields = [
  'companyName',
  'localId',
  'country',
  'score',
  'creditMax',
  'profitLoss',
  'equity',
  'employeeInterval',
]

const reorder = (list, startIndex, endIndex) => {
  const newList = list
  const [removed] = newList.splice(startIndex, 1)
  newList.splice(endIndex, 0, removed)

  return newList
}
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? colors?.risikaLight : 'white',
  marginRight: '2rem',
})
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  // change background colour if dragging
  background: isDragging ? colors?.risikaContrast : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
})
// Split it in 2 rows active and inactive and when clicked a field goes to active or inactive
const TableSettings = () => {
  const dispatch = useDispatch()
  // const [monitorTableFields, setMonitorTableFields] = React.useState([])
  const monitorTableFields = useSelector(
    (state) => state.auth?.settings?.monitorTableFields ?? defaultTableFields
  )
  const { settings } = useSelector((state) => state.auth)

  const handleDispatch = (newArr) => {
    dispatch(setUserSettings('monitorTableFields', newArr))
  }

  const handleChange = (event) => {
    // Update when order has changed!!!!!!
    const { checked, name } = event.target
    if (checked) {
      handleDispatch([...monitorTableFields, name])
    } else {
      handleDispatch(monitorTableFields.filter((x) => x !== name))
    }
  }

  const handleUpdate = () => {
    // Clone array so effects and memo realise that something has changed.
    // const newArr = [...monitorTableFieldsState]

    // dispatch(setUserSettings('monitorTableFields', newArr))
    // dispatch(setMonitorTableFields(newArr))
    updateUserSettings(settings)
  }

  const onDragEnd = (res) => {
    if (!res.destination) {
      return
    }
    const newState = reorder(monitorTableFields, res.source.index, res.destination.index)
    handleDispatch(newState)
  }

  // Move to controller when refactor the component
  const convertTableField = (localId) => {
    switch (localId) {
      case 'localId':
        return 'local-id'
      case 'creditMax':
        return 'credit-max'
      default:
        return localId
    }
  }

  return (
    <ActionDialog
      title={<FormattedMessage id={intl.riskMonitoringNew('settings-header')} />}
      content={(close) => (
        <Box sx={classes.container}>
          <Box sx={classes.columnWrapper}>
            <Typography variant="subtitle1" sx={classes.columnTitle}>
              <FormattedMessage id={intl.riskMonitoringNew('table-shown')} />
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <FormGroup
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={getListStyle(snapshot.isDraggingOver)}
                  >
                    {monitorTableFields.map((tableField, i) => (
                      <Draggable key={tableField} draggableId={tableField} index={i}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Box sx={classes.item}>
                              <Typography variant="h4" sx={classes.number}>
                                {i + 1}
                              </Typography>
                              <FormControlLabel
                                disabled={tableField === 'companyName'}
                                control={
                                  <Checkbox
                                    checked={monitorTableFields?.includes(tableField)}
                                    onChange={handleChange}
                                    name={tableField}
                                  />
                                }
                                label={
                                  <FormattedMessage
                                    id={intl.riskMonitoring(
                                      convertTableField(tableField)
                                    )}
                                  />
                                }
                              />
                            </Box>
                            <FontAwesomeIcon icon={faBars} style={classes.dragIcon} />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FormGroup>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box sx={classes.columnWrapper}>
            <Typography variant="subtitle1" sx={classes.columnTitle}>
              <FormattedMessage id={intl.riskMonitoringNew('table-hidden')} />
            </Typography>
            <FormGroup>
              {monitorTableFieldsTemplate
                .filter((x) => !monitorTableFields.includes(x))
                .map((tableField) => (
                  <FormControlLabel
                    sx={classes.item}
                    key={tableField}
                    disabled={tableField === 'companyName'}
                    control={
                      <Checkbox
                        checked={monitorTableFields?.includes(tableField)}
                        onChange={handleChange}
                        name={tableField}
                      />
                    }
                    label={
                      <FormattedMessage
                        id={intl.riskMonitoring(convertTableField(tableField))}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </Box>
        </Box>
      )}
      action={() => handleUpdate()}
      maxWidth="sm"
      fullWidth={true}
      render={(open) => (
        <Tooltip
          disableFocusListener={true}
          title={
            <FormattedMessage id={intl.riskMonitoringNew('handle-settings-button')} />
          }
        >
          <Box sx={classes.buttonWrapper}>
            <IconButton color="primary" onClick={open}>
              <SettingsIcon sx={standardIcon} />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      agreeText={<FormattedMessage id={intl.riskMonitoringNew('update')} />}
    />
  )
}

export default TableSettings
