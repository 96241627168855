import risikaAPI from 'services/api/helpers/risikaAPI'
import moment from 'moment'
import { paginate } from 'services/helpers/paginate'
/**
 * The amount of items to fetch at a time.
 */
const PAGE_SIZE = 1000

/**
 * Get a page of the monitor list.
 */
const fetchMonitorExport = (listId, { from, to }) => {
  const sevenDaysBack = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const now = moment().format('YYYY-MM-DD')

  return risikaAPI
    .post(
      `/export/${listId}`,
      {
        dates: {
          from: sevenDaysBack,
          to: now,
        },
        rows: {
          from,
          to,
        },
      },
      {
        microservice: 'monitor',
        appendScoringModel: true,
      }
    )
    .then(({ data }) => {
      return data
    })
}

/******************
 * Fetch from API *
 ******************/

const monitorExport = async (listId = 0) => {
  const initialCall = await fetchMonitorExport(listId, {
    from: 0,
    to: PAGE_SIZE,
  })

  const pages = paginate(PAGE_SIZE, initialCall.count, { startPage: 1 })

  if (pages.length === 0) {
    return initialCall.monitor_list
  }

  const promises = pages.map((pageRange) => fetchMonitorExport(listId, pageRange))

  /**
   * Using safePromises *here* is arguably a BAD PRACTICE, but we don't have a user friendly
   * way to handle when parts of the data is missing (at the moment). We could try and
   * refetch the data a few times, if parts of the list is missing.
   * But we would potentially have to stall it for quite a while.
   */
  return Promise.all(safePromises(promises))
    .then((promises) => {
      let result = initialCall.monitor_list

      promises?.forEach((promise) => {
        result = result.concat(promise?.monitor_list ?? [])
      })

      return { monitor_list: result, count: initialCall.count }
    })
    .then((data) => data.monitor_list)
}

export default monitorExport

/***********
 * Helpers *
 ***********/

function safePromises(promises) {
  return promises.map((promise) => promise.catch(() => undefined))
}
