import { useEffect } from 'react'
import {
  Box,
  Stack,
  Typography,
  MenuItem,
  Chip,
  TextField,
  Autocomplete,
  Select,
  Skeleton,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ClearIcon from '@mui/icons-material/Clear'
import intl from 'localization/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { subLabel } from 'styles-new/global/typography'
import { styles } from './Destination.styles'
import { DestinationPropsType } from './Destination.types'
/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'
import { isEmail } from 'utils-new/is-email'
import IntlTypography from 'components-new/elements/IntlTypography'

const menuItems = [
  {
    label: (
      <FormattedMessage id={intl.portfolioInsights('tab-scheduler-destination-email')} />
    ),
    icon: <MailOutlineIcon sx={styles.selectItemContent} />,
    value: 'email',
  },
]

const Destination = ({
  isLoadingSubscriptions,
  emailFieldInvalid,
  setEmailFieldInvalid,
  destination,
  setDestination,
  subscriptionsList,
  subscriptionsUpdate,
}: DestinationPropsType) => {
  const reactIntl = useIntl()

  const handleChangeDestination = (event: SelectChangeEvent) =>
    setDestination(event.target.value)

  const renderTags = (
    value: any[],
    getTagProps: (arg0: { index: number }) => JSX.IntrinsicAttributes
  ) =>
    value.map((option: string, index: number) => {
      const isStringEmail = isEmail(option)

      return (
        <Chip
          key={index}
          variant="outlined"
          sx={{
            ...styles.emailChip,
            ...(!isStringEmail && styles.invalidEmailChip),
          }}
          label={option}
          deleteIcon={<ClearIcon />}
          {...getTagProps({ index })}
        />
      )
    })

  const renderInput = (params: any) => {
    const inputContainsInvalidEmail = subscriptionsList?.find((email) => !isEmail(email))

    return inputContainsInvalidEmail || emailFieldInvalid ? (
      <TextField
        {...params}
        placeholder={
          reactIntl.messages[
            intl.portfolioInsights('tab-scheduler-emails-list-placeholder')
          ]
        }
        error
        helperText={
          reactIntl.messages[
            intl.portfolioInsights('tab-scheduler-emails-list-invalid-email-warning')
          ]
        }
      />
    ) : (
      <TextField
        {...params}
        placeholder={
          reactIntl.messages[
            intl.portfolioInsights('tab-scheduler-emails-list-placeholder')
          ]
        }
      />
    )
  }

  useEffect(() => {
    if (subscriptionsList?.length) {
      setEmailFieldInvalid(false)
    }
  }, [setEmailFieldInvalid, subscriptionsList])

  return (
    <Stack gap={2}>
      <IntlTypography
        variant="h5"
        group="portfolioInsights"
        labelKey="tab-scheduler-destination"
      />
      <Select
        sx={styles.selectContainer}
        value={destination}
        label="Destination"
        onChange={handleChangeDestination}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Box sx={styles.selectItem}>
              {item.icon}
              <Typography sx={styles.selectItemContent}>{item.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
      <Stack gap={1}>
        <Box sx={{ ...subLabel, fontSize: '1.6rem' }}>
          <FormattedMessage
            id={intl.portfolioInsights('tab-scheduler-destination-email-recipients')}
          />
        </Box>

        {/*
          Render the Autocomplete only when subscriptionsQuery is fetched,
          because it needs to be rendered with a pre-defined list
          of default values from the beginning,
          otherwise the chips will not render
        */}

        {isLoadingSubscriptions ? (
          <Skeleton height={200} />
        ) : (
          <Autocomplete
            multiple
            options={[]}
            value={subscriptionsList}
            freeSolo
            disableClearable
            onChange={subscriptionsUpdate}
            renderTags={renderTags}
            renderInput={renderInput}
          />
        )}
        <Typography color={'grey.600'} variant="body2">
          <FormattedMessage id={intl.observationalLists('press-enter-email')} />
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Destination
