import { Grid } from '@mui/material'
import { CompanyVisitsSummary } from 'services/api/endpoints/risikaAPI/companyVisitsSummary'
import SummaryItem from './SummaryItem'
import { addBorderToAllButTheLastItem } from './VisitsSummary.style'

type SearchesOverviewProps = {
  searches: CompanyVisitsSummary
}

function SearchesOverview({ searches }: SearchesOverviewProps) {
  const searchesLength = searches?.length
  return (
    <Grid container spacing={2}>
      {searches.map((search) => (
        <Grid
          sx={{ ...addBorderToAllButTheLastItem }}
          item
          xs={12}
          sm={12}
          lg={12 / searchesLength}
          md={6}
          key={search.name}
        >
          <SummaryItem
            direction={search.direction}
            value={search.count}
            text={search.localized_name}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default SearchesOverview
