import { DropdownSelect } from 'components-new'
import React from 'react'
import mapping from './model'
import { fromValueToLabel, fromLabelToValue } from '../controller'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { useIntl } from 'react-intl'

const options = [
  {
    label: <FormattedMessage id={intl.creditPolicyNew('less-than-year')} />,
    value: 'less-than-year',
  },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('1-year-old')} />,
    value: '1-year-old',
  },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('2-year-old')} />,
    value: '2-year-old',
  },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('3-year-old')} />,
    value: '3-year-old',
  },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('4-year-old')} />,
    value: '4-year-old',
  },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('5-and-more')} />,
    value: '5-and-more',
  },
]

const YearsOfExistenceInput = () => {
  const reactIntl = useIntl()
  const { policy, setPolicy } = useCountryPolicy()
  const defVal = fromValueToLabel(policy?.age_limit, mapping)
  const [val, setVal] = React.useState(defVal)

  React.useEffect(() => {
    if (defVal) setVal(defVal)
  }, [defVal])

  const handleChange = (value: string): void => {
    setVal(value)
    setPolicy({ ...policy, age_limit: fromLabelToValue(value, mapping) as number })
  }

  return (
    <DropdownSelect
      placeholder={reactIntl.formatMessage({
        id: intl.creditPolicy('select'),
      })}
      disablePlaceholderClick={false}
      options={options}
      value={val}
      setValue={handleChange}
    />
  )
}

export default YearsOfExistenceInput
