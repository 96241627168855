import AddIcon from '@mui/icons-material/Add'
import { Paper, Stack, Typography } from '@mui/material'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { useBuilder } from '../BuilderContext'

function StartHerePaper() {
  const { actions } = useBuilder()
  const handleClick = () => {
    actions.actionPanelToggle({ open: true, nodeId: '1' })
  }

  return (
    <Paper
      component={Stack}
      direction="row"
      alignItems="center"
      elevation={0}
      sx={{ height: '100%' }}
    >
      <Stack
        data-cy="start-here-paper"
        sx={{
          backgroundColor: 'primary.main',
          margin: 8,
          padding: 3,
          borderRadius: 1,
          cursor: 'pointer',
          color: 'common.white',
          fontSize: 21,
        }}
        onClick={handleClick}
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <AddIcon color="inherit" fontSize="inherit" />
        <Typography
          variant="subtitle1"
          color="inherit"
          sx={{ letterSpacing: 1, lineHeight: '20px' }}
        >
          {<FormattedMessage id={intl.creditPolicyNew('create-you-first-rule-button')} />}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default StartHerePaper
