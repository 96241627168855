import { useState } from 'react'
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl'
import { PieChart, Pie, Tooltip, Cell, Label } from 'recharts'
import { MapChartItemType } from 'types/chart-data'
import { NewCustomRegionalTooltip, SimpleChartTooltipRegion } from '../customizations'
import { getCorrectColor } from './MapChart.controller'
import { MapChartPropsType, CustomLabelPropsType } from './MapChart.types'

const MapChart = ({
  data,
  chartProperties,
  forceRender,
  handleMapClick,
  mapLevel,
  mapLevelLimit,
}: MapChartPropsType) => {
  const [viewport, setViewport] = useState({
    width: chartProperties.width,
    height: chartProperties.height,
    latitude: chartProperties.initialLatitude,
    longitude: chartProperties.initialLongitude,
    zoom: chartProperties.initialZoom,
  })

  type ToolTipData = {
    name: string
    value: number
    groupCode: string
    region: string
    fillColour: string
  }

  const [tooltipData] = useState<ToolTipData | {}>()
  const [tooltipCoords] = useState({ lat: 0, lng: 0 })

  const CustomizedLabel = (labelProps: CustomLabelPropsType) => {
    return (
      <g>
        <foreignObject x={30} y={30} width={50} height={50}>
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              height: '100%',
              paddingLeft: '2px',
              backgroundColor: 'white',
              borderRadius: '50%',
            }}
          >
            {labelProps.value}
          </div>
        </foreignObject>
      </g>
    )
  }

  return (
    <ReactMapGL
      key={forceRender}
      mapStyle={chartProperties.mapStyle}
      mapboxApiAccessToken={chartProperties.mapboxApiAccessToken}
      {...viewport}
      onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
      scrollZoom={false}
    >
      {data.map((item: MapChartItemType) => {
        const keyValue = item.lng + item.lat
        const pieCursor = mapLevel < mapLevelLimit ? 'pointer' : 'default'

        return (
          <Marker
            key={keyValue}
            latitude={item.lat}
            longitude={item.lng}
            // offsets are fixing the initial position
            // and keep makers in correct place when zooming
            offsetTop={-50}
            offsetLeft={-50}
          >
            <PieChart
              width={chartProperties.pie.width}
              height={chartProperties.pie.height}
            >
              <Pie
                key={keyValue}
                dataKey="value"
                data={item.scores}
                innerRadius={chartProperties.pie.innerRadius}
                style={{ cursor: pieCursor }}
                onClick={() => handleMapClick(item.group_code, item.group.geo_name)}
              >
                <Label
                  value={`${item.value}%`}
                  content={<CustomizedLabel />}
                  position="center"
                />
                {item.scores.map(
                  (entry: { name: string; value: number; groupCode: string }) => (
                    <Cell
                      key={entry.name}
                      fill={getCorrectColor(entry.groupCode ?? 'NO_RISK_ASSESMENT')}
                    />
                  )
                )}
              </Pie>
              <Tooltip content={<SimpleChartTooltipRegion />} />
            </PieChart>
          </Marker>
        )
      })}
      {!!tooltipData && (
        <Marker latitude={tooltipCoords?.lat} longitude={tooltipCoords?.lng}>
          <NewCustomRegionalTooltip {...tooltipData} />
        </Marker>
      )}
      <NavigationControl style={{ margin: '2rem' }} />
    </ReactMapGL>
  )
}

export default MapChart
