import { styled } from '@mui/system'
import { Input } from 'components'
import { Card, CardActionArea } from '@mui/material'

export const Root = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  zIndex: 0,
  height: 44,
  maxWidth: 900,
}))

export const SearchField = styled(Input)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1),
}))

export const CloseSearch = styled(Card)(() => ({
  display: 'flex',
  backgroundColor: '#ccc',
  alignItems: 'center',
  marginLeft: '-1rem',
  paddingLeft: 0,
  paddingRight: 0,
  zIndex: -1,
}))

export const CloseSearchIcon = styled(CardActionArea)(() => ({
  height: '100%',
  padding: '0 1rem 0 2rem',
}))
