import 'react-table/react-table.css'
import { Component } from 'react'
import { ReactTableDefaults } from 'react-table'
import { FormControl, FormHelperText, IconButton, Input } from '@mui/material'
import _ from 'lodash'
import { Stack } from 'components'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const filterTypes = [
  {
    value: 'contains',
    title: 'Contains',
  },
  {
    value: 'starts-with',
    title: 'Starts with',
  },
  {
    value: 'ends-with',
    title: 'Ends with',
  },
  {
    value: 'matches',
    title: 'Matches',
  },
  {
    value: 'greater-than',
    title: 'Greater than',
  },
  {
    value: 'less-than',
    title: 'Less than',
  },
]

class FilterComponent extends Component {
  state = {
    filterType: 'contains',
    filterValue: '',
    filterMenuEl: null,
  }

  changeFilterType = (filterType) => {
    const newState = {
      ...this.state,
      filterType,
    }
    // Update local state
    this.setState(newState)
    // Fire the callback to alert React-Table of the new filter
    this.props.onChange(newState)
    this.handleFilterMenuClose()
  }

  changeFilterValue = (event) => {
    const newState = {
      ...this.state,
      filterValue: event.target.value,
    }
    // Update local state
    this.setState(newState)
    // Fire the callback to alert React-Table of the new filter
    this.props.onChange(newState)
  }

  handleFilterMenuClick = (event) => {
    this.setState({ filterMenuEl: event.currentTarget })
  }

  handleFilterMenuClose = () => {
    this.setState({ filterMenuEl: null })
  }

  render() {
    return (
      <Stack>
        <FormControl style={{ flex: 1 }}>
          <Input
            type="text"
            onChange={this.changeFilterValue}
            value={this.state.filterValue}
            inputProps={{ placeholder: 'Filter' }}
          />
          <FormHelperText>
            {_.find(filterTypes, { value: this.state.filterType }).title}
          </FormHelperText>
        </FormControl>
      </Stack>
    )
  }
}

const defaultFilterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id
  // Pivoted rows won't contain the column.
  //  If that's the case, we set the to true (allowing us to only filter on the current column)
  const rowValue = row[id].toLowerCase()
  if (!rowValue) {
    return true
  }

  const filterValue = filter.value.filterValue.toLowerCase() || ''
  const filterType = filter.value.filterType

  switch (filterType) {
    case 'contains':
      return rowValue.indexOf(filterValue) > -1
    case 'starts-with':
      return rowValue.startsWith(filterValue)
    case 'ends-with':
      return rowValue.endsWith(filterValue)
    case 'matches':
      return rowValue === filterValue
    case 'greater-than':
      return rowValue > filterValue
    case 'less-than':
      return rowValue < filterValue
    default:
      return true
  }
}

/**
 * React Table Defaults
 */
Object.assign(ReactTableDefaults, {
  PreviousComponent: (props) => (
    <IconButton {...props}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </IconButton>
  ),
  NextComponent: (props) => (
    <IconButton {...props}>
      <FontAwesomeIcon icon={faArrowRight} />
    </IconButton>
  ),
  FilterComponent: (props) => <FilterComponent {...props} />,
  defaultFilterMethod,
})
