import { Typography } from '@mui/material'
import { useIntl, FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const PercentageCell = ({ cell }) => {
  const reactIntl = useIntl()

  return (
    <Typography variant="body2">
      {cell.value === null ? (
        <FormattedMessage id={intl.mainRecommendation('recommendation-credit-unknown')} />
      ) : (
        `${reactIntl.formatNumber(cell?.value ? cell.value * 100 : 0)}%`
      )}
    </Typography>
  )
}

export default PercentageCell
