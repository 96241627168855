import { LocalId, PersonId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

/**
 * Gets the _relations_ for a given person.
 *
 * @param {object} id _Person ID_ and _Country Code_ of the person.
 *
 * @returns {object} All the different _relations_ associated with the person.
 */

type CompanyType = {
  long: string
  short: string
}

type Function = {
  function: string
  title: string
  valid_from: string
  valid_to?: string
}

export type Relation = {
  company_name: string
  company_status: string
  company_status_code: string
  company_type: CompanyType
  functions: Function[]
  local_organization_id: LocalId
  risk_assessment: string
  risk_assessment_code: string
  score: number
}

export type PersonRelations = {
  local_organization_id?: string
  name: string
  politically_exposed_persons: null
  relations: Relation[]
  type: string
}

type ResponseData = {
  data: PersonRelations
}

async function personRelations({ person_id, country }: PersonId) {
  try {
    const { data }: ResponseData = await risikaAPI.get(`/person/relations/${person_id}`, {
      microservice: 'data',
      country,
      appendScoringModel: true,
    })
    return data
  } catch (error) {
    console.error('Error fetching person relations:', error)
  }
}

export default personRelations
