import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Fetch from API *
 ******************/

// type CompanyStatus = {
//   code: string,
//   title: string,
// }

const companyStatusList = (country) => {
  return risikaAPI
    .get(`/list/company_statuses`, {
      microservice: 'data',
      country,
    })
    .then(({ data }) => {
      return data.company_statuses
    })
}

export default companyStatusList
