import * as React from 'react'

function useDislosure(defaultValue = false) {
  const [isOpen, setIsOpen] = React.useState(defaultValue)

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onToggle = () => {
    setIsOpen(!isOpen)
  }

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}

export default useDislosure
