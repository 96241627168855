import { range } from 'ramda'

/**
 * Given a page size and the total amount of items,
 * return a list of "pages" defined by a `from` and `to`.
 *
 * @param pageSize The amount of items per page.
 * @param totalAmount The total amount of items.
 * @param options.startPage Change what page to start on (zero-indexed)
 *
 * @returns An array containing the `from` and `to` for each page.
 *
 * @example
 * paginate(1000, 3700) => [
 *   { from: 0, to: 1000 },
 *   { from: 1000, to: 2000 },
 *   { from: 2000, to: 3000 },
 *   { from: 3000, to: 3700 },
 * ]
 *
 * paginate(500, 2000, { startPage: 2 }) => [
 *   // { from: 0, to: 500 } <- page 0, skipped
 *   // { from: 500, to: 1000 } <- page 1, skipped
 *   { from: 1000, to: 1500 }, // page 2, start page
 *   { from: 1500, to: 2000 }, // page 3
 * ]
 */
export const paginate = (pageSize, totalAmount, options) => {
  const startPage = options?.startPage ?? 0
  const pagesToFetch = Math.ceil(totalAmount / pageSize)

  // If we don't have any pages to fetch.
  if (startPage > pagesToFetch) {
    return []
  }

  return range(startPage, pagesToFetch).map((page) => ({
    from: page * pageSize,
    to: Math.min((page + 1) * pageSize, totalAmount),
  }))
}
