import { colors } from 'configs'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
  },
  textField: {
    // margin: '1rem',
    maxWidth: '450px',
  },
  spinner: {
    color: colors?.risikaContrast,
  },
  contactInfo: {
    fontSize: '120%',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5rem',
  },
}))
