/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-string-refs */
import React from 'react'
import D3RisikaScore from './D3RisikaScore'
import { injectIntl } from 'react-intl'

class RisikaScoreWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.chart = React.createRef()
    this.data = {
      risikaScore: this.props.risikaScore,
      riskAssessmentCode: this.props.riskAssessmentCode,
    }
  }

  componentDidMount() {
    this.setState({
      chart: new D3RisikaScore(
        this.chart.current,
        {
          risikaScore: this.props.risikaScore,
          riskAssessmentCode: this.props.riskAssessmentCode,
        },
        this.props.intl.messages
      ),
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.risikaScore !== this.props.risikaScore ||
      prevProps.riskAssessmentCode !== this.props.riskAssessmentCode
    ) {
      this.props.setIsRendering(false)
    }
  }

  render() {
    return (
      <div
        style={{
          position: 'relative',
          margin: '0 auto',
        }}
        ref={this.chart}
      ></div>
    )
  }
}

export default injectIntl(RisikaScoreWrapper)
