const main = {
  position: 'relative',
  top: '3px',
}
const editListIcon = {
  position: 'relative',
  bottom: '3px',
  left: '3px',
  color: 'primary.main',
}

export const classes = {
  main,
  editListIcon,
}
