import React from 'react'
import { Modal } from '@mui/material'
import ModalCanvas from './ModalCanvas'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { useIntl } from 'react-intl'
import intl from 'localization/components'

const SaveModal = () => {
  const {
    newCountry,
    updatePolicy,
    setSelectedCountry,
    isSaveModalOpen: isOpen,
    setIsSaveModalOpen: setIsOpen,
  } = useCountryPolicy()
  const reactIntl = useIntl()
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} closeAfterTransition>
      <ModalCanvas
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('save_modal_title'),
        })}
        text={reactIntl.formatMessage({
          id: intl.creditPolicyNew('save_modal_text'),
        })}
        primaryBtnTitle={reactIntl.formatMessage({
          id: intl.generic('save_changes'),
        })}
        secondaryBtnTitle={reactIntl.formatMessage({
          id: intl.generic('discard'),
        })}
        primaryBtnFunc={() => {
          updatePolicy()
          setSelectedCountry(newCountry)
          setIsOpen(false)
        }}
        secondaryBtnFunc={() => {
          setSelectedCountry(newCountry)
          setIsOpen(false)
        }}
        closeFn={() => setIsOpen(false)}
      />
    </Modal>
  )
}

export default SaveModal
