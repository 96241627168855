// @flow

/**
 * Create a node in an expandable tree.
 */
export default function createNode({
  id,
  label,
  value,
  count,
  level,
  isRoot = false,
  isLeaf = false,
  state = 'unchecked',
  isExpanded = false,
  isHidden = false,
  children = [],
  parent,
  title,
}) {
  return {
    [id]: {
      id,
      label,
      value,
      level,
      count,
      isRoot,
      isLeaf,
      state,
      isExpanded,
      isHidden,
      children,
      parent,
      title,
    },
  }
}
