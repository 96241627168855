import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import ConditionallyRender from './ConditionallyRender'
import { ButtonLink2 } from 'components-new'

// type ShowHideAllWrapperProps = {
//   children: any,
//   amountOfShowedItems: number,
//   iteration: number,
//   dataLength: number,
//   setIsShowAll: Function,
//   isHistory?: boolean,
//   isShowAll: boolean,
// }

const ShowHideAllWrapper = ({
  children,
  amountOfShowedItems,
  iteration,
  dataLength,
  isShowAll,
  setIsShowAll,
  isHistory = false,
}) => {
  const divRef = React.useRef(null)
  const handleShowLess = () => {
    const clientRect = divRef.current.getBoundingClientRect()
    setIsShowAll(false)
    window.scrollTo(0, clientRect.y)
  }
  return (
    <div ref={divRef}>
      <ConditionallyRender
        condition={isShowAll}
        when={
          <>
            {children}
            <ConditionallyRender
              condition={iteration === dataLength - 1}
              when={
                <ConditionallyRender
                  condition={isHistory}
                  when={
                    <ButtonLink2 onClick={() => setIsShowAll(false)}>
                      <FormattedMessage id={intl.companyRoles('history-hide')} />
                    </ButtonLink2>
                  }
                  otherwise={
                    <ButtonLink2 onClick={handleShowLess}>
                      <FormattedMessage id={intl.companyRoles('show-less')} />
                    </ButtonLink2>
                  }
                />
              }
            />
          </>
        }
        otherwise={
          <ConditionallyRender
            condition={iteration <= amountOfShowedItems - 1}
            when={children}
            otherwise={
              <ConditionallyRender
                condition={iteration === amountOfShowedItems}
                when={
                  <ConditionallyRender
                    condition={isHistory}
                    when={
                      <ButtonLink2 key={Math.random()} onClick={() => setIsShowAll(true)}>
                        <FormattedMessage id={intl.companyRoles('history-show')} />{' '}
                      </ButtonLink2>
                    }
                    otherwise={
                      <ButtonLink2 key={Math.random()} onClick={() => setIsShowAll(true)}>
                        <FormattedMessage id={intl.companyRoles('show-all')} />{' '}
                        {`(${dataLength - amountOfShowedItems})`}
                      </ButtonLink2>
                    }
                  />
                }
              />
            }
          />
        }
      />
    </div>
  )
}

export default ShowHideAllWrapper
