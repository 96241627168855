import { LocalId } from 'globalTypes'
import callBackendAPI from 'services/api/helpers/backendAPI'

/******************
 * Fetch from API *
 ******************/

type Suggestions = {
  data: {
    name: string
    local_id: LocalId
  }[]
}

const searchSuggestions = (searchText: string, country: string = 'dk') => {
  return callBackendAPI(
    `/search-suggestions?search=${searchText}&country=${country}`
  ).then(({ data }: Suggestions) =>
    data.map(({ name, local_id }) => ({
      name,
      localId: local_id,
    }))
  )
}

export default searchSuggestions
