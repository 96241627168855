import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { colors } from 'configs'
import { makeStyles } from '@mui/styles'
import { HumanDate } from 'components'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  companyItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

// type ItemProps = {|
//   status: string,
//   validFrom: string,
//   validTo: string,
// |}

const Item = ({ status, validFrom, validTo }) => {
  const classes = useStyles()

  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        primary={<Typography variant="h6">{status}</Typography>}
        secondary={
          validTo ? (
            <Typography variant="body2">
              <HumanDate date={moment(validFrom)} format="LL" />
              {' – '}
              <HumanDate date={moment(validTo)} format="LL" />
            </Typography>
          ) : (
            <Typography variant="body2">
              <HumanDate date={moment(validFrom)} format="LL" />
            </Typography>
          )
        }
      />
    </ListItem>
  )
}

// type Props = {
//   open: boolean,
//   onClose: Function,
//   title: Node | string,
//   data: Array<Object>,
// }

const PriorDistressHighlight = ({ open, onClose, title, data }) => {
  // Prevent recalculating if the data doesn't change
  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => {
      return new Date(a.valid_from).getTime() < new Date(b.valid_from).getTime() ? 1 : -1
    })
  }, [data])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle style={{ backgroundColor: colors?.risika, color: 'white' }}>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          {sortedData?.map(({ status, valid_from, valid_to }) => (
            <Item
              key={`${status}${valid_from}${valid_to}`}
              status={status}
              validFrom={valid_from}
              validTo={valid_to}
            />
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default PriorDistressHighlight
