import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import AddNewList from 'pages/risk-monitoring/scenes/AddNewList'
import { UseUserSelfResponse } from 'types/queries'
import { ButtonSecondary } from 'components-new'
import AddIcon from '@mui/icons-material/Add'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
type CreateFirstMonitoringListProps = {
  currentUserInfo: UseUserSelfResponse
}
function CreateFirstMonitoringList({ currentUserInfo }: CreateFirstMonitoringListProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Stack width={360} spacing={2} alignItems="center">
        <Typography variant="h5">
          <FormattedMessage id={intl.riskMonitoringNew('no-monitoring-list-available')} />
        </Typography>
        <Typography color={'grey.700'} textAlign="center" variant="body1">
          <FormattedMessage
            id={intl.riskMonitoringNew('create-monitoring-list-text-empty')}
          />
        </Typography>
        <Box pt={1}>
          <AddNewList
            currentUserInfo={currentUserInfo}
            customListButton={(open: () => void) => (
              <ButtonSecondary
                data-cy="add-monitor-list"
                startIcon={<AddIcon />}
                onClick={open}
              >
                <FormattedMessage id={intl.riskMonitoringNew('add-new-list')} />
              </ButtonSecondary>
            )}
          />
        </Box>
      </Stack>
    </Box>
  )
}

export default CreateFirstMonitoringList
