import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { InputSearchText } from 'components-new'
import { styles } from './Tabs.styles'
import { TabsProps } from './Tabs.types'

const { container, selectedTab, tabsContainer } = styles

const Tabs = ({ tabs, selected, filterValue, setFilterValue }: TabsProps) => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isBelow1000px = useMediaQuery('(max-width:1000px)')
  if (!tabs?.length) {
    return null
  }
  return (
    <Stack
      sx={tabsContainer}
      direction={isSmallDevice ? 'column' : 'row'}
      spacing={2}
      alignItems={isSmallDevice ? 'stretch' : 'center'}
    >
      <Stack direction="row" spacing={2}>
        {tabs.map(({ onClick, number, category }) => (
          <Box
            paddingX={isBelow1000px ? 4 : 8}
            paddingY={4}
            key={category}
            onClick={() => onClick(category)}
            sx={{
              ...container,
              ...(selected === category && selectedTab),
            }}
          >
            <Typography variant="subtitle2">
              {number} {<FormattedMessage id={intl.generic(category)} />}
            </Typography>
          </Box>
        ))}
      </Stack>
      <Box width={300}>
        <InputSearchText
          value={filterValue}
          handleChange={setFilterValue}
          placeholder="Search for a company name"
        />
      </Box>
    </Stack>
  )
}

export default Tabs
