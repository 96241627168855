import {
  BuilderNode,
  UpdateParentNode,
  HandleUpdatePolicy,
  GetLayoutedElements,
  DeleteNodeLine,
  CancelNode,
  HandleSavePolicy,
  BuilderState,
  LoadPolicyIntoBuilder,
  ThereIsAnotherActivePolicyInThisCountry,
  BuilderEdge,
} from './PolicyBuilder.types'
import dagre from 'dagre'
import { APIPolicyEdge, APIPolicyNode } from 'types/queries'

/******************
 * Altering nodes *
 ******************/

/**
 *
 * @param nodes All nodes in the state.
 * @param newNodeId The ID of the new node that is being added
 * @param parentId The ID of the parent node
 * @returns Nodes the nodes array with a n updated parent node. The new child id is added to the childrenIds array.
 */
export const updateParentNode = ({ nodes, parentId, newNodeId }: UpdateParentNode) => {
  return nodes.map((x) => {
    if (parentId && x.id === parentId) {
      return {
        ...x,
        data: {
          ...x.data,
          builderData: {
            ...x.data.builderData,
            childrenIds: [...x.data.builderData.childrenIds, newNodeId],
          },
        },
      }
    }
    return x
  })
}

/**
 * This function turns nodes that have no API data into + signs.
 * In case there is data it will do nothing.
 * @param nodes all nodes in the state
 * @param nodeId the id of the node that is being canceled
 * @param forceCancel if true, the node will be canceled even if it has data
 * @returns BuilderNode[] the nodes array with the node that was canceled
 */

export const cancelNode = ({ nodes, nodeId, forceCancel = false }: CancelNode) => {
  return nodes.map((node) => {
    if (node.id === nodeId) {
      const nodeHasData = Object.keys(node.data.apiData ?? {}).length > 0
      if (!nodeHasData || forceCancel) {
        return {
          ...node,
          data: {
            ...node.data,
            apiData: {},
          },
          type: 'plus',
        }
      }
      return node
    }
    return node
  })
}

// Here we just reset the childrenIds array to an empty array
/**
 *
 * @param nodes all nodes in the state
 * @param nodeId the id of the node that is being reset
 * @returns The nodes with the updated node builderData
 */
export const resetChildrenIds = ({
  nodes,
  nodeId,
}: {
  nodes: BuilderNode[]
  nodeId: string
}) => {
  return nodes.map((node) => {
    if (node.id === nodeId) {
      return {
        ...node,
        data: {
          ...node.data,
          builderData: {
            ...node.data.builderData,
            childrenIds: [],
          },
          apiData: {},
        },
      }
    }
    return node
  })
}

/**
 * @param nodes all nodes in the state
 * @param nodeId The start point of the line that is being deleted
 * @param shouldCancelNode The first node of the line should be canceled to preserve the structure of the policy. All others should be deleted.
 * If you are calling this function, call it with shouldCancelNode = true which is also the default value.
 * @returns BuilderNode[] the nodes array without the line that was deleted
 */
// TODO: Test this

export const deleteNodeLine = ({
  nodes,
  nodeId,
  shouldCancelNode = true,
}: DeleteNodeLine): BuilderNode[] => {
  const node = nodes.find((x) => x.id === nodeId)
  if (!node) return nodes

  const childrenIds: string[] = node.data.builderData.childrenIds
  if (childrenIds.length === 0 && !shouldCancelNode) {
    return nodes.filter((x) => x.id !== nodeId)
  }

  const newNodes = shouldCancelNode
    ? resetChildrenIds({
        nodes: cancelNode({ nodes, nodeId, forceCancel: true }),
        nodeId,
      })
    : nodes.filter((x) => x.id !== nodeId)

  return childrenIds.reduce((acc: BuilderNode[], curr: string) => {
    return deleteNodeLine({
      nodes: acc,
      nodeId: curr,
      shouldCancelNode: false,
    })
  }, newNodes) as BuilderNode[]
}

/**
 * This is the function that organizes the nodes in a correct way so the look like a tree.
 * Up until this point the nodes are displayed in position 0,0.
 * @param nodes all nodes in the state
 * @param edges all edges in the state
 * @param direction the direction of the tree. Default is LR (left to right)
 * @returns The nodes and edges with the correct positions to save in the state.
 */
// TODO: Test this
export const getLayoutedElements = ({
  nodes,
  edges,
  direction = 'LR',
}: GetLayoutedElements) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  dagreGraph.setGraph({ rankdir: direction })
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.type === 'plus' ? (node.width ?? 1) * 1.3 : node.width,
      height: node.type === 'plus' ? (node.height ?? 1) * 3 : node.height,
    })
  })
  dagreGraph.graph().ranksep = 150
  // dagreGraph.graph().ranker = 'tight-tree'

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })
  dagre.layout(dagreGraph)

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)

    node.position = {
      x: nodeWithPosition.x - (node.width ?? 1) / 2,
      y: nodeWithPosition.y - (node.height ?? 1) / 2,
    }

    return node
  })

  return { nodes, edges }
}

/**************************
 * General Policy Helpers *
 **************************/

/**
 * This function takes the state or pretty much any object that contains nodes and edges and returns the nodes and edges in the correct format to save in the API
 * @param state The state of the builder or any other object that contains nodes and edges
 * @returns { nodes: APIPolicyNode[], edges: APIPolicyEdge[]} The nodes and edges in the correct format to save in the API
 * !!! This function is not used outside of this file. It is only exported, because it needs to be tested.
 */

export const fitNodesAndEdgesForApi = (state: BuilderState) => {
  const { nodes, edges } = state
  const apiNodes = nodes.map((node) => ({
    id: node.id,
    node_type: node.type,
    [node.type === 'rule' ? 'condition' : 'action']: {
      ...node.data.apiData,
    },
  })) as APIPolicyNode[]
  const apiEdges = edges.map((edge) => ({
    relation_type: edge.data?.relation_type,
    source_id: edge.source,
    target_id: edge.target,
  })) as APIPolicyEdge[]
  return { nodes: apiNodes, edges: apiEdges }
}
/**
 * This is the function that fits all the data in the necessary format to save the policy in the API
 * @param params Those are the URL parameters. Some of them are required to save the policy in the API (id, name, country)
 * And others are just nice to have (description)
 * @param enabled Whether the policy is enabled or not.
 * @param state The state of the policy. It contains the nodes and edges.
 * @param updateFunction This allows you to pass the API call that you want to use.
 * It is a parameter so you can use the react query hook or the axios call.
 * @returns Promise<T> You can pass the type of the response that you expect from the API call.
 * If you leave it blanc and it will default to void. The response from the axios call is: UpdateCreditPolicyResponse
 */
export const handleUpdatePolicy = async <T>({
  params,
  enabled,
  state,
  updateFunction,
}: HandleUpdatePolicy<T>) => {
  const { nodes, edges } = fitNodesAndEdgesForApi(state)
  return updateFunction({
    id: Number(params.policyId),
    policyData: {
      name: params.name,
      country: params.country?.toUpperCase(),
      policy: {
        nodes,
        edges,
      },
      // description: params.description ?? '',
      enabled,
    },
  })
}
/**
 * This is the function that fits all the data in the necessary format to save the policy in the API
 * @param params Those are the URL parameters. Some of them are required to save the policy in the API (id, name, country)
 * And others are just nice to have (description)
 * @param enabled Whether the policy is enabled or not.
 * @param state The state of the policy. It contains the nodes and edges.
 * @param saveFunction This allows you to pass the API call that you want to use.
 * It is a parameter so you can use the react query hook or the axios call.
 * @returns Promise<T> You can pass the type of the response that you expect from the API call.
 * If you leave it blanc and it will default to the return type of the save function. The response from the axios call is: SavePolicyResponseData
 */
export const handleCreatePolicy = async ({
  params,
  state,
  saveFunction,
  enabled = false,
}: HandleSavePolicy) => {
  const { nodes, edges } = fitNodesAndEdgesForApi(state)
  return saveFunction({
    name: params.name,
    country: params.country?.toUpperCase(),
    policy: {
      nodes,
      edges,
    },
    description: params.description ?? '',
    enabled,
  })
}

/**
 * This function takes the policy data from the API, sorts the nodes and loads them into the builder
 * @param policyData The policy data from the API
 * @param country The country from the params
 * @param actions The context actions
 */
export const loadPolicyIntoBuilder = ({ policyData, actions }: LoadPolicyIntoBuilder) => {
  const { policy } = policyData
  // TODO: This needs to be made performant and it should update only when necessary
  const nodes = policy.nodes.sort((a, b) => Number(a.id) - Number(b.id))
  const edges = policy.edges
  // Creates new nodes, adds the data to them and attaches them to the three
  actions.addNodesAndEdges({ nodes, edges })
}
/**
 * Checks if there is another active policy in the specified country.
 * @param {Object} options - The options object.
 * @param {string} options.actionedPolicyId - The ID of the policy being actioned.
 * @param {AvailableCountries} options.country - The country to check for active policies.
 * @param {Policy[]} options.policies - The list of policies to search for active policies.
 * @returns {boolean|Policy} Returns false if there is no active policy or the active policy object if found.
 */
export const thereIsAnotherActivePolicyInThisCountry = ({
  actionedPolicyId,
  country,
  policies,
}: ThereIsAnotherActivePolicyInThisCountry) => {
  if (!country || !policies) return false

  // Filter the policies to get the active ones
  const activePolicies = policies.filter((policy) => policy.enabled)

  // Find the active policy in the specified country
  const activePolicy = activePolicies.find((policy) => policy.country === country)

  if (!activePolicy) return false

  // Check if the active policy is the same as the actioned policy
  if (Number(activePolicy.id) === Number(actionedPolicyId)) return false

  return activePolicy
}

type FigureOutIfWeAreOnNegativeOrPositiveEdge = {
  edges: BuilderEdge[]
  parentNodeId: string
  childNodeId: string
}
export const figureOutIfWeAreOnNegativeOrPositiveEdge = ({
  edges,
  parentNodeId,
  childNodeId,
}: FigureOutIfWeAreOnNegativeOrPositiveEdge) => {
  const edge = edges.find(
    (edge) => edge.source === parentNodeId && edge.target === childNodeId
  )
  if (!edge) return undefined
  return edge.data?.relation_type === 'if_false' ? 'no' : 'yes'
}
