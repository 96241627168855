import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

/**
 * Gets the _financial performance_ for a given company.
 *
 * @param {object} id _Local ID_ and _Country Code_ of the company.
 * @param {string} language The langauge for translations.
 *
 * @returns {object} All the different _financial stats_ associated with the company.
 */

export type Performance = {
  performance?: number
  explanation?: string
}

export type FinancialPerformanceResponse = {
  gross_margin: Performance
  operating_margin: Performance
  profit_margin: Performance
  return_on_equity: Performance
  return_on_assets: Performance
  return_on_net_assets: Performance
  basic_earning_power: Performance
  current_ratio: Performance
  current_assets_to_equity: Performance
  cash_ratio: Performance
  capacity_ratio: Performance
  asset_turnover: Performance
  inventory_conversion_ratio: Performance
  debt_ratio: Performance
  debt_to_equity_ratio: Performance
  income_to_debt_ratio: Performance
  ebitda_to_debt_ratio: Performance
  interest_coverage: Performance
  solidity_ratio: Performance
  liabilities_to_equity_ratio: Performance
  one_year_change_in_equity: Performance
  fixed_assets_to_long_term_liabilities_ratio: Performance
  interest_rate_on_debt_with_average: Performance
  cash_conversion_rate: Performance
  interest_margin: Performance
  quick_ratio: Performance
  equity_to_contributed_capital_ratio: Performance
  one_year_change_in_debt: Performance
}
export type FinancialPerformanceData = FinancialPerformanceResponse

type ResponseData = {
  data: FinancialPerformanceResponse
}

async function financialPerformance({
  id,
  country,
}: LocalId): Promise<FinancialPerformanceData> {
  const { data }: ResponseData = await risikaAPI.get(`/financial/performance/${id}`, {
    microservice: 'data',
    country,
  })
  return data
}

export default financialPerformance
