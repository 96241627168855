import { Paper } from '@mui/material'
import React, { ReactNode } from 'react'
import classes from 'pages/credit-check/styles/creditcheck-widgit-frame'
import DraggableBox from 'widgets/charts/DraggableBox/DraggableBox'

type WidgetWrapperProps = {
  children: ReactNode
  isReArrange: boolean
  widgetName: string
  isActive: boolean
  setIsActive?: (key: string) => void
  stylesOverride?: Record<string, unknown>
  dataCy?: string
}

export const WidgetWrapper = (props: WidgetWrapperProps) => {
  const {
    children,
    isReArrange,
    widgetName,
    isActive,
    setIsActive,
    stylesOverride,
    dataCy,
  } = props

  const styles = {
    boxShadow: 2,
    padding: 0,
    ...classes.frame,
    ...stylesOverride,
  }

  return (
    <Paper sx={isActive || isReArrange ? styles : null} data-cy={dataCy}>
      {isReArrange ? (
        <DraggableBox title={widgetName} isActive={isActive} setIsActive={setIsActive} />
      ) : isActive ? (
        children
      ) : null}
    </Paper>
  )
}
