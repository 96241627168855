import { theme } from 'styles-new/mui5-transition/theme'

const root = {
  backgroundColor: theme.palette.grey[100],
  borderRadius: '0.5rem',
  padding: 4,
}

export const classes = {
  root,
}
