import * as React from 'react'
import { SimpleTable } from 'components-new'
import { Sidescroll } from 'components'
import { Column } from 'components-new/data-display/SimpleTable/SimpleTable.types'

type SearchesOverviewProps<T> = {
  searches: T[]
  isLoading?: boolean
  columns: Column<T>[]
}
function SearchesList<T>({ searches, isLoading, columns }: SearchesOverviewProps<T>) {
  return (
    <Sidescroll conditionalySidescroll maxWidth={'1199px'}>
      <SimpleTable<T> isLoading={isLoading} columns={columns} data={searches} />
    </Sidescroll>
  )
}

export default SearchesList
