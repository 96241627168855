import { Box, ListItemIcon, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { StyledListItemButton, StyledListItemText } from 'styles/components/sidebar.js'
import { useHubspotConversations } from 'services/hubSpot/HubspotConversationsProvider'
import { SupportMenuItemProps } from './types'

const SupportMenuItem = ({ title, icon }: SupportMenuItemProps) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const { toggleWidget } = useHubspotConversations()

  return (
    <StyledListItemButton onClick={() => toggleWidget()}>
      <ListItemIcon>
        {icon}
        <Box mt={isSmall ? 0 : 1} textAlign="center">
          <StyledListItemText>
            <FormattedMessage id={intl.navigation(title)} />
          </StyledListItemText>
        </Box>
      </ListItemIcon>
    </StyledListItemButton>
  )
}

export default SupportMenuItem
