import { SET_FILTER_SUGGESTIONS_FOR_COUNTRY_SUCCESS } from './constants'

import { generateTimestamp } from 'services/timestamp'

/*********
 * Types *
 *********/

// type ReducerState = {|
//   timestamps: {
//     dk: ?number,
//     no: ?number,
//     se: ?number,
//   },
//   dk: ?DanishSuggestions,
//   no: ?NorwegianSuggestions,
//   se: ?SwedishSuggestions,
// |}

/*****************
 * Initial state *
 *****************/

export const initialState = {
  timestamps: {
    dk: null,
    no: null,
    se: null,
  },
  dk: null,
  no: null,
  se: null,
}

/************
 * Reducers *
 ************/

const setFilterSuggestionsSuccess = (state, action) => {
  //   state: ReducerState,
  //   action: AsyncAction<any>,
  // ): ReducerState => {
  const { payload } = action
  switch (payload.country) {
    case 'dk':
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          dk: generateTimestamp(),
        },
        dk: {
          companyType: payload.suggestions.companyType,
          companyStatus: payload.suggestions.companyStatus,
          industryGroup: payload.suggestions.industryGroup,
          specificIndustry: payload.suggestions.specificIndustry,
          employee: payload.suggestions.employee,
          municipality: payload.suggestions.municipality,
          region: payload.suggestions.region,
          zipcode: payload.suggestions.zipcode,
          score: payload.suggestions.score,
        },
      }
    case 'se':
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          se: generateTimestamp(),
        },
        se: {
          companyType: payload.suggestions.companyType,
          companyStatus: payload.suggestions.companyStatus,
          industryGroup: payload.suggestions.industryGroup,
          specificIndustry: payload.suggestions.specificIndustry,
          employee: payload.suggestions.employee,
          municipality: payload.suggestions.municipality,
          region: payload.suggestions.region,
          zipcode: payload.suggestions.zipcode,
          score: payload.suggestions.score,
        },
      }
    case 'no':
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          no: generateTimestamp(),
        },
        no: {
          companyType: payload.suggestions.companyType,
          companyStatus: payload.suggestions.companyStatus,
          industryGroup: payload.suggestions.industryGroup,
          specificIndustry: payload.suggestions.specificIndustry,
          municipality: payload.suggestions.municipality,
          region: payload.suggestions.region,
          zipcode: payload.suggestions.zipcode,
          score: payload.suggestions.score,
        },
      }
    case 'fi':
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          no: generateTimestamp(),
        },
        fi: {
          companyType: payload.suggestions.companyType,
          specificIndustry: payload.suggestions.specificIndustry,
          industryGroup: payload.suggestions.industryGroup,
          score: payload.suggestions.score,
          companyStatus: payload.suggestions.companyStatus,
          municipality: payload.suggestions.municipality,
          region: payload.suggestions.region,
          zipcode: payload.suggestions.zipcode,
        },
      }
    default:
      return state
  }
}

/*****************************
 * Filter Suggestion Reducer *
 *****************************/

export const filterSuggestions = (
  state = initialState,
  action = { type: '', payload: {} }
) => {
  switch (action.type) {
    case SET_FILTER_SUGGESTIONS_FOR_COUNTRY_SUCCESS:
      return setFilterSuggestionsSuccess(state, action)
    default:
      return state
  }
}

export default filterSuggestions
