import { IconButton, Stack } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { ActionCellProps } from '../../UserTable.types'

const ActionCell = ({
  i,
  setSelectedRow,
  isSelected,
  disabledButton,
  handleSaveChanges,
  isAddingNewUser,
  setIsAddingNewUser,
  setSelectedUser,
  handleCreateUser,
  setUserToBeDeleted,
}: ActionCellProps) => {
  const handleEdit = () => setSelectedRow(i)
  const handleUnselect = () => {
    setSelectedRow(null)
    setIsAddingNewUser(false)
    setSelectedUser(null)
  }
  const handleDelete = () => setUserToBeDeleted(i)
  if (isSelected) {
    return (
      <Stack direction="row" justifyContent="center" sx={{ fontSize: 20 }}>
        <IconButton
          onClick={isAddingNewUser ? handleCreateUser : handleSaveChanges}
          sx={{ fontSize: '20px', color: 'common.black' }}
        >
          <CheckIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          onClick={handleUnselect}
          sx={{ fontSize: '20px', color: 'common.black' }}
        >
          <ClearIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    )
  }
  return (
    <Stack direction="row" justifyContent="center" sx={{ fontSize: 20 }}>
      <IconButton
        onClick={handleEdit}
        disabled={disabledButton}
        sx={{ fontSize: '20px', color: 'common.black' }}
      >
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        onClick={handleDelete}
        disabled={disabledButton}
        sx={{ fontSize: '20px', color: 'common.black' }}
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  )
}

export default ActionCell
