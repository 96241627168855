export default {
  risikaScore: {
    intlLabel: 'risika-score',
    image: null,
  },
  localId: {
    intlLabel: 'local-id',
    image: null,
  },
  role: {
    intlLabel: 'role',
    image: null,
  },
  profitLoss: {
    intlLabel: 'profit-loss',
    image: null,
  },
  equity: {
    intlLabel: 'equity',
    image: null,
  },
}
