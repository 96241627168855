import { theme } from 'styles-new/mui5-transition/theme'

export const localStorageName = 'companyComparisonList'

export const scrollThrottleTime = 20
export const columnWidth = 433
export const horizontalPadding = 5

const themeSpacing = Number(theme.spacing(horizontalPadding).replace('px', ''))

export const containerWidth = (numberOfCompanies: number) =>
  (numberOfCompanies + 1) * columnWidth + themeSpacing * 2
