import _ from 'lodash'
import { filters, filterOptions } from '../models/filtersModel'
import { isNil } from 'ramda'

/**
 * A map that doesn't fail if it's given something wrong.
 */
const safeMap = (fn, arr) => (Array.isArray(arr) ? arr.map(fn) : [])

/**
 * Determine output based on the operator and the values.
 *
 * @param {string} operator The operator used to decide how to output.
 * @param {number | string} value The value to be used with the operator.
 * @returns {object | any[]} The formatted filter and value.
 */
const fromOperator =
  (operator) =>
  (value, filterType = null) => {
    // Add extra operator for "Mixed inputs"
    switch (operator) {
      case '>':
        return !isNaN(value) ? { value, operator } : null
      case '<':
        return !isNaN(value) ? { value, operator } : null
      case 'array':
        return value ? value.flat(Infinity) : []
      case 'include':
        return value ? { include: value } : { include: [] }
      case 'exclude':
        return value ? { exclude: value } : { exlude: [] }
      case 'range':
        return fromRangeToArray(value)
      case 'range_from_to':
        return value && value.from !== '' && value.to !== ''
          ? { range_start: value.from, range_end: value.to }
          : null
      case 'match':
        return value
      case 'include_mixed':
        return value ? { include: handleMixedInput(value?.value ?? []) } : { include: [] }
      case 'exclude_mixed':
        return value ? { exclude: handleMixedInput(value?.value ?? []) } : { exclude: [] }
      default:
        return null
    }
  }

/**
 * Get filterType by label.
 */
export const getFilterType = (filter) => {
  const [filterType] = filters.filter((x) => x.label === filter)
  return filterType
}

/**
 * Turn a filter into a format the API understands.
 *
 * @param {object} filterType The definition of the filter.
 * @param {object} filterValues The values for the filter.
 * @returns {object} The filter formatted to a format the API understands.
 */
export const fromFilterType = _.curry((filterType, filterValues) => {
  if (isNil(filterType)) {
    return
  }
  const value = filterType.transformValue
    ? filterType.transformValue(filterValues.value)
    : filterValues.value

  const [option] = filterType.options.filter(({ value }) => value === filterValues.type)
  const convertFromOperator = fromOperator(option.operator)
  const convertedValue = convertFromOperator(value, filterType.filter)
  const valueWithCurrency = option.currency
    ? { ...convertedValue, currency: option.currency }
    : convertedValue

  if (
    filterValues.value === null ||
    convertedValue === null ||
    valueWithCurrency === null
  ) {
    return
  }

  return { [filterType.filter]: valueWithCurrency }
})

export const fromFilterOptionType = _.curry((filterOptionType, filterOptionValue) => {
  const value = filterOptionType.transformValue
    ? filterOptionType.transformValue(filterOptionValue.value)
    : filterOptionValue.value

  if (value === null) {
    return
  }

  return { [filterOptionType.filter]: value }
})

/**
 * Transform all filters into the final format for the API request.
 *
 * @param {object[]} filters The filters to transform.
 * @returns {object} The object containing all the filters.
 */
export const transformFilters = (filters, filterOptions, sortBy) => {
  return combineFilters([
    ...safeMap(
      (filter) => fromFilterOptionType(getFilterOptionType(filter.filter), filter),
      filterOptions
    ),
    ...safeMap((filter) => fromFilterType(getFilterType(filter.filter), filter), filters),
    {
      sortBy,
    },
  ])
}

/**
 * Merge all the filter objects into a single object.
 *
 * @param {filters[]} filters The filters to merge together.
 * @returns {object} The combined filters.
 */
export const combineFilters = (filters) => _.assign(...filters)

/**
 * Get the suggestions to use for the filter.
 *
 * @param {string} filter The name of the filter.
 * @returns {object[]} The suggestions.
 */
export const loadSuggestions = (suggestions) => (filter) => {
  const maybeSuggestions = getFilterType(filter)?.suggestions

  if (maybeSuggestions) {
    return suggestions?.[maybeSuggestions] ?? []
  }

  return null
}

const isNullOrEmpty = (value) => value === '' || value == null

export const fromRangeToArray = (range) => {
  const { from, to } = range || {}

  if (isNullOrEmpty(from) || isNullOrEmpty(to)) {
    return null
  }

  // $FlowFixMe (Flow, Fix Yourself)
  const entries = Math.max(to - from + 1, from - to + 1)

  return Array(entries)
    .fill(Math.min(from, to))
    .map((x, i) => Number(x) + i)
}

export const handleMixedInput = (values) => {
  if (isNullOrEmpty(values)) {
    return null
  }
  let chipArray = []
  values.map((value) => {
    if (value.includes('-')) {
      const rangeValues = value.split('-')
      const from = parseInt(rangeValues[0])
      const to = parseInt(rangeValues[1])

      const entries = Math.max(to - from + 1, from - to + 1)
      chipArray = [
        ...chipArray,
        ...Array(entries)
          .fill(Math.min(from, to))
          .map((x, i) => Number(x) + i),
      ]
    } else {
      chipArray = [...chipArray, parseInt(value)]
    }
  })
  return chipArray
}

export const transformFilterToDispatch = (filterName) => {
  const [filterToAdd] = filters.filter((x) => x.label === filterName)
  if (filterToAdd) {
    const { label, options, explanation } = filterToAdd

    return { value: null, filter: label, type: options[0].value, explanation }
  }

  return null
}

/**
 * Get filterOptionType by label
 */
export const getFilterOptionType = (filter) => {
  const [filterOptionType] = filterOptions.filter((x) => x.label === filter)

  return filterOptionType
}

/**
 * Allow users to enter a percentage sign (%) in the end
 * without returning the wrong values.
 *
 * @param {string} value The ratio to transform.
 * @returns {numbr | null} A ratio the API will understand.
 */
export const transformRatio = (value) => {
  if (value) {
    return Number(value.replace('%', ''))
  }
  return null
}

export const transformFinancialNumber = (value) => {
  if (typeof value !== 'string') {
    return {
      from: Number(value?.from),
      to: Number(value?.to),
    }
  } else {
    return Number(value)
  }
}

/**
 * Get the Input Label for the given filter.
 *
 * @param {any} filter The filter to get the label for.
 * @returns {string | null} Returns the label localization key if it exists.
 */
export const getInputLabelByFilter = (filter) => {
  const foundFilter = filters.find((f) => f.filter === filter.filter)
  return foundFilter?.inputLabel ?? null
}
