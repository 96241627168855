import { AvailableCountries } from './../../globalTypes'
import { useMutation, useQueryClient } from 'react-query'
import { setSingleCreditPolicy } from 'services/api'
import { RisikaApiError } from 'types/general'
import { useSnackbar } from 'notistack'
import { SetSinglePolicyPayloadT } from 'services/api/endpoints/risikaAPI/setSingleCreditPolicy'
import { useIntl } from 'react-intl'
import intl from 'localization/components'

export function useSetSinglePolicy(country: AvailableCountries) {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const reactIntl = useIntl()

  return useMutation<Response, RisikaApiError, SetSinglePolicyPayloadT>(
    setSingleCreditPolicy,
    {
      onSuccess: () => {
        queryClient.refetchQueries(['singlePolicy', country])

        enqueueSnackbar(
          reactIntl.formatMessage({
            id: intl.generic('successful_changes'),
          })
        )
      },
      onError: (err) => {
        enqueueSnackbar(`${err?.response?.data?.error}`, {
          variant: 'error',
        })
      },
    }
  )
}
