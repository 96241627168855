import Box from '@mui/material/Box'
import { PicTextSkeleton } from './skeletons/SkeletonDefinitions'
import { PieSkeleton } from './skeletons/SkeletonDefinitions'
import { HorizontalBarSkeleton } from './skeletons/SkeletonDefinitions'
import { VerticalBarSkeleton } from './skeletons/SkeletonDefinitions'
import { LoadingScenePropsType } from '../types'

const LoadingScene = ({ height, skeletonType }: LoadingScenePropsType) => {
  return (
    <Box p={2} height={height}>
      {{
        PIE: <PieSkeleton />,
        HORIZONTAL_BAR: <HorizontalBarSkeleton />,
        VERTICAL_BAR: <VerticalBarSkeleton />,
        PIC_TEXT: <PicTextSkeleton />,
      }[skeletonType] || <PicTextSkeleton />}
    </Box>
  )
}

export default LoadingScene
