import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { Show } from 'components'
import { classes } from './TableListView.styles'
import { TableListViewProps } from './TableListView.types'

function TableListView({
  headerCells,
  bodyCells,
  tableSize = 'medium',
}: TableListViewProps) {
  return (
    <Table sx={classes.tableStyle} size={tableSize}>
      <TableHead>
        <Show when={!!headerCells}>
          <TableRow sx={classes.tableRow}>{headerCells}</TableRow>
        </Show>
      </TableHead>
      <TableBody sx={{ backgroundColor: 'common.white' }}>{bodyCells}</TableBody>
    </Table>
  )
}

export default TableListView
