// @flow

export function setSelectNode(dispatch, id, state) {
  dispatch({
    type: 'SET_SELECT',
    id,
    state,
  })
}

export function selectNode(dispatch, id) {
  dispatch({
    type: 'TOGGLE_SELECT',
    id,
  })
}

export function selectNodeDeep(dispatch, parentId, selectState) {
  dispatch({
    type: 'SET_SELECT_ALL_CHILDREN',
    parentId,
    selectState,
  })
}

export function expandNode(dispatch, id) {
  dispatch({
    type: 'TOGGLE_EXPAND',
    id,
  })
}

export function setNodesToShow(dispatch, nodeIds) {
  dispatch({
    type: 'SET_SHOW_NODES',
    ids: nodeIds,
  })
}

export function setInitialState(dispatch, state) {
  dispatch({
    type: 'SET_INITIAL_STATE',
    state,
  })
}

export function clearSelected(dispatch) {
  dispatch({
    type: 'CLEAR_SELECTED',
  })
}

// Deletes single selection.
// Only use if you render selected in a different component and want to delete it from there.
export function deleteSelection(dispatch, payload) {
  dispatch({
    type: 'DELETE_SELECTION',
    payload,
  })
}

export function setState(dispatch, payload) {
  dispatch({
    type: 'SET_STATE',
    payload,
  })
}
