import risikaAPI from 'services/api/helpers/risikaAPI'

import { store } from 'context/store'
/**************
 * Set to API *
 **************/

const parseData = (country, policyForm, enabled, product) => {
  return {
    country: country?.toUpperCase(),
    enabled: enabled ?? true,
    policy: {
      ...policyForm[country]?.policyValues,
      exclude_companies: policyForm?.[country]?.policyValues?.exclude_companies?.length
        ? policyForm?.[country]?.policyValues?.exclude_companies?.map((entry) => ({
            local_organization_id: {
              id: entry.id,
              country: country?.toUpperCase(),
            },
          }))
        : null,
      exclude_persons: policyForm?.[country]?.policyValues?.exclude_persons?.length
        ? policyForm?.[country]?.policyValues?.exclude_persons?.map((entry) => ({
            local_person_id: {
              id: entry?.id?.toString(),
              country: country?.toUpperCase(),
            },
          }))
        : null,
      exclude_company_types: policyForm?.[country]?.policyValues?.exclude_company_types
        ?.length
        ? policyForm?.[country]?.policyValues?.exclude_company_types
        : null,
      exclude_level_of_auditor_assistances: policyForm?.[country]?.policyValues
        ?.exclude_level_of_auditor_assistances?.length
        ? policyForm?.[country]?.policyValues?.exclude_level_of_auditor_assistances
        : null,
      exclude_risika_scores: policyForm?.[country]?.policyValues?.exclude_risika_scores
        ?.length
        ? policyForm?.[country]?.policyValues?.exclude_risika_scores
        : null,
      exclude_industries: policyForm?.[country]?.policyValues?.exclude_industries?.length
        ? policyForm?.[country]?.policyValues?.exclude_industries
        : null,
    },
    product,
  }
}

const creditPolicySet = (country, enabled, product) => {
  const policyForm = store.getState()?.risika?.creditPolicy?.policyForm
  const { modelObject } = store.getState()?.auth?.company

  if (!product) {
    product = modelObject?.[country?.toUpperCase()]
  }

  return risikaAPI
    .post(`/credit_policy/set`, parseData(country, policyForm, enabled, product), {
      microservice: 'account',
      appendScoringModel: Boolean(!product),
    })
    .then(({ data }) => {
      return {
        data,
      }
    })
}

export default creditPolicySet
