import callBackendAPI from 'services/api/helpers/backendAPI'

/******************
 * Fetch from API *
 ******************/

type RequestData = {
  sdr_s: string
  sdr_o: number
  sdr_u: number
  sdr_m: any
  sdr_a: any
}

const totangoProxy = (data: RequestData): Promise<any> => {
  return callBackendAPI('/report-totango', {
    method: 'post',
    data,
  })
}

export default totangoProxy
