import { ErrorPage } from 'components-new'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { navigation } from 'services/navigation'

function InvalidParameters() {
  return (
    <ErrorPage
      errorCode={404}
      errorMessage={<FormattedMessage id={intl.creditPolicyNew('page-does-not-exist')} />}
      buttonText={<FormattedMessage id={intl.creditPolicyNew('back-to-credit-policy')} />}
      redirectLocation={navigation.enterpriseCreditPolicy()}
    />
  )
}

export default InvalidParameters
