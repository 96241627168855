import React from 'react'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Box, Typography } from '@mui/material'
import { ButtonSecondary } from 'components-new'
import { useStyles } from './PolicyCards.styles'
import { useQueryClient } from 'react-query'
import { UseUserSelfResponse } from 'types/queries'
import { UserTypes } from 'services/queries'

type EmptyStateCTAProps = {
  onCreateClick: (data: string) => void
}

export const EmptyStateCTA = (props: EmptyStateCTAProps) => {
  const { onCreateClick } = props

  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData('userSelf') as UseUserSelfResponse
  const role = userData?.role

  const classes = useStyles()

  return (
    <Box className={classes.emptyStateCTAContainer}>
      {role === UserTypes.ADMIN ? (
        <>
          <Typography variant="h5" color="inherit">
            <FormattedMessage id={intl.creditPolicyNew('create-first-policy')} />
          </Typography>
          <Typography variant="body2" sx={{ width: 290 }} mt={2}>
            <FormattedMessage id={intl.creditPolicyNew('create-first-policy-cta')} />
            <a
              onClick={() => onCreateClick('import')}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              <FormattedMessage id={intl.creditPolicyNew('import')} />
            </a>
            <FormattedMessage id={intl.creditPolicyNew('existing-one')} />
          </Typography>
          <ButtonSecondary
            sx={{
              mt: 4,
            }}
            size="small"
            color="secondary"
            data-cy="credit-policy-create-new-policy-button"
            onClick={() => onCreateClick('create')}
          >
            <FormattedMessage id={intl.creditPolicyNew('create-a-policy-button')} />
          </ButtonSecondary>
        </>
      ) : (
        <Typography variant="body1" color="inherit">
          <FormattedMessage id={intl.creditPolicyNew('you-have-no-policies')} />
        </Typography>
      )}
    </Box>
  )
}
