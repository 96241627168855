import {
  validateMonitorTableFields,
  validateCreditCheckFields,
  validateCompanyInfoFields,
} from './validators'

import {
  LOGIN_USER_SUCCESS,
  VERIFY_USER_SUCCESS,
  LOGOUT_USER,
  UPDATE_USER_CURRENCY_SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_COMPANY_DOMAIN_AND_PHONE_SUCCESS,
  UPDATE_MODEL_SUCCESS,
  UPDATE_COMPANY_DOMAIN_AND_PHONE_REQUEST, // Add more states
  UPDATE_COMPANY_INFORMATION_SUCCESS,
  ACTIVATE_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  FORCE_USER_PASSWORD_CHANGE,
  SET_USER_SETTINGS,
  SET_DASHBOARD_LANG,
} from './constants'

// type UserCredentials = {
//   email: string,
//   password: string,
// }

// type UserResponse = {
//   user: any,
//   settings?: Object,
// }

// type UpdatePassword = {
//   password: string,
//   confirmPassword: string,
// }

// type UpdateUser = {
//   firstName: string,
//   lastName: string,
//   phoneNumber: string,
// }

// type CompanyResponse = {
//   country: string,
//   created_at: string,
//   customer_type: string,
//   domain: ?string,
//   expires_at: string,
//   id: number,
//   is_revoked: boolean,
//   local_id: string,
//   name: string,
//   phone_number: ?string,
//   responsible_user_id: ?number,
//   updated_at: string,
// }

/**************
 * LOGIN USER *
 **************/

export const loginUserSuccess = ({ user, settings }) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { user, settings },
  status: 'SUCCESS',
})

/************************
 * ACCOUNT VERIFICATION *
 ************************/

export const verifyUserSuccess = ({ user, auth }, verifyToken) => ({
  type: VERIFY_USER_SUCCESS,
  payload: { user, auth, verifyToken },
  status: 'SUCCESS',
})

export const activateUserSuccess = () => ({
  type: ACTIVATE_USER_SUCCESS,
  payload: null,
  status: 'SUCCESS',
})

/*************************
 * FORCE PASSWORD CHANGE *
 *************************/

export const forceUserPasswordChange = () => ({
  type: FORCE_USER_PASSWORD_CHANGE,
  payload: null,
  status: 'SUCCESS',
})

/***************
 * LOGOUT USER *
 ***************/

export const logoutUser = () => ({
  type: LOGOUT_USER,
})

/*******************
 * UPDATE CURRENCY *
 *******************/

export const updateUserCurrencySuccess = ({ currency }) => ({
  type: UPDATE_USER_CURRENCY_SUCCESS,
  payload: { currency },
  status: 'SUCCESS',
})

/*******************
 * UPDATE PASSWORD *
 *******************/

export const updatePasswordRequest = ({
  password,
  confirmPassword,
  enqueueSnackbar,
  messages,
}) => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload: { password, confirmPassword, enqueueSnackbar, messages },
  status: 'LOADING',
})

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: null,
  status: 'SUCCESS',
})

export const updatePasswordFailed = ({ error }) => ({
  type: UPDATE_PASSWORD_FAILED,
  payload: { error },
  status: 'FAILED',
})

/***************
 * UPDATE USER *
 ***************/

export const updateUserRequest = ({ name, phoneNumber }) => ({
  type: UPDATE_USER_REQUEST,
  payload: { name, phoneNumber },
  status: 'LOADING',
})

export const updateUserSuccess = ({ name, phoneNumber }) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { name, phoneNumber },
  status: 'SUCCESS',
})

export const updateUserFailed = ({ error }) => ({
  type: UPDATE_USER_FAILED,
  payload: { error },
  status: 'FAILED',
})

/******************
 * UPDATE COMPANY *
 ******************/

export const updateCompanyDomainAndPhoneRequest = ({
  companyId,
  domain,
  phoneNumber,
  model,
  modelObject,
}) => ({
  type: UPDATE_COMPANY_DOMAIN_AND_PHONE_REQUEST,
  payload: {
    companyId,
    domain,
    phoneNumber,
    model,
    modelObject,
  },
  status: 'LOADING',
})

export const updateCompanyDomainAndPhoneSuccess = ({
  domain,
  phoneNumber,
  model,
  modelObject,
}) => ({
  type: UPDATE_COMPANY_DOMAIN_AND_PHONE_SUCCESS,
  payload: {
    domain,
    phoneNumber,
    model,
    modelObject,
  },
  status: 'SUCCESS',
})

export const updateModelSuccess = ({ modelObject }) => ({
  type: UPDATE_MODEL_SUCCESS,
  payload: {
    modelObject,
  },
  status: 'SUCCESS',
})

export const updateCompanyInformationSuccess = ({
  id,
  name,
  local_id,
  country,
  expires_at,
  is_revoked,
  domain,
  responsible_user_id,
}) => ({
  type: UPDATE_COMPANY_INFORMATION_SUCCESS,
  payload: {
    companyId: id,
    companyName: name,
    localId: local_id,
    country: country.toLowerCase(),
    isRevoked: is_revoked,
    expiresAt: expires_at,
    responsibleUserId: responsible_user_id,
    domain: domain,
  },
  status: 'SUCCESS',
})

/************************
 * UPDATE USER SETTINGS *
 ************************/

export const setUserSettings = (field, newValues) => {
  if (field === 'monitorTableFields') {
    validateMonitorTableFields(newValues)
    return {
      type: SET_USER_SETTINGS,
      payload: { field: 'monitorTableFields', newValues },
      status: 'SUCCESS',
    }
  }
  if (field === 'creditCheckFields') {
    validateCreditCheckFields(newValues)
    return {
      type: SET_USER_SETTINGS,
      payload: { field: 'creditCheckFields', newValues },
      status: 'SUCCESS',
    }
  }
  if (field === 'companyInfoFields') {
    validateCompanyInfoFields(newValues)
    return {
      type: SET_USER_SETTINGS,
      payload: { field: 'companyInfoFields', newValues },
      status: 'SUCCESS',
    }
  }

  // If you are adding to this array, dont forget to add the value to the initial state in the reducer as well
  throw new Error(
    'Wrong field value!!! Either add it to the array or check your spelling!'
  )
}

/************
 * GET USER *
 ***********/

export const getUserRequest = (id) => ({
  type: GET_USER_REQUEST,
  payload: { id },
  status: 'LOADING',
})

export const getUserSuccess = ({ user }) => ({
  type: GET_USER_SUCCESS,
  payload: { user },
  status: 'SUCCESS',
})

// trying to extinguish redux
export const setDashboardLang = (lang) => ({
  type: SET_DASHBOARD_LANG,
  payload: lang,
})
