import { SxProps } from '@mui/system'

const boxContainer: SxProps = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  boxShadow: 2,
}

export const classes = {
  boxContainer,
}
