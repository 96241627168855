import { CircularProgress } from '@mui/material'
import { useStyles } from '../styles/async-loader'
import React, { ReactNode } from 'react'

type AsyncLoaderProps = {
  children: ReactNode
  style: React.CSSProperties
  ready: boolean
}

const AsyncLoader = ({ children, style, ready }: AsyncLoaderProps) => {
  const classes = useStyles()

  return ready ? (
    children
  ) : (
    <div className={classes.container} style={style}>
      <CircularProgress className={classes.spinner} />
    </div>
  )
}

export default AsyncLoader
