import { ActionDialog } from 'components'
import { Typography, IconButton, Tooltip } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import DeleteIcon from '@mui/icons-material/Delete'
import { standardIcon } from 'styles-new/global/components'

const DeleteSelectedList = ({ deleteList, selectedListName, disabled }) => {
  const tooltipText = disabled ? 'no-delete-permissions' : 'delete-list'

  return (
    <ActionDialog
      title={<FormattedMessage id={intl.riskMonitoringNew('delete-dialog-title')} />}
      content={(close) => (
        <Typography>
          {
            <FormattedMessage
              id={intl.riskMonitoringNew('are-you-sure-delete')}
              values={{ text: selectedListName }}
            />
          }
        </Typography>
      )}
      action={deleteList}
      maxWidth="sm"
      fullWidth={true}
      render={(open) => (
        <Tooltip title={<FormattedMessage id={intl.riskMonitoringNew(tooltipText)} />}>
          <IconButton
            data-cy="delete-monitor-list"
            color="primary"
            disabled={disabled}
            onClick={open}
          >
            <DeleteIcon sx={standardIcon} />
          </IconButton>
        </Tooltip>
      )}
      agreeText={<FormattedMessage id={intl.riskMonitoringNew('delete')} />}
      danger
    />
  )
}

export default DeleteSelectedList
