import * as React from 'react'
import { Stack } from '@mui/material'
import { DropdownSelect, InputField, InputRange } from 'components-new'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { useActionPanelStateValidator } from 'services/hooks'
import { equity, tolerance } from '../DetailedComponentView.model'
import { stateValidators } from '../DetailedComponentView.controller'
import { ToleranceAPIData, ToleranceInputProps } from './Actions.types'
import { AddOnPositionType } from 'components-new/inputs/InputField/InputField.types'
import { InputRangeValue } from 'components-new/inputs/InputRange/InputRange.types'
import { ToleranceOptions } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'
const handleFitAPIdataToFormState = (apiData: ToleranceAPIData) => {
  const { value_a, value_b, tolerance: apiTolerance } = apiData
  return {
    tolerance: (apiTolerance || tolerance.options[0].value) as ToleranceOptions,
    value_a,
    value_b,
  }
}

const ToleranceInput = (
  { setIsConfirmEnabled, apiData, inputData, label }: ToleranceInputProps,
  ref: React.ForwardedRef<SubmitRef>
) => {
  const { state, actions } = useBuilder()
  const [formState, setFormState] = React.useState(handleFitAPIdataToFormState(apiData))
  useActionPanelStateValidator(
    formState,
    stateValidators.toleranceInputValidator,
    setIsConfirmEnabled
  )

  React.useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.setNodeApiData({
          nodeId: state.actionPanelData.nodeId,
          data: {
            ...formState,
            value_a: Number(formState.value_a),
            value_b: formState?.value_b ? Number(formState.value_b) : undefined,
          },
        })
      },
    }),
    [actions, formState, state.actionPanelData.nodeId]
  )

  const handleChange = (key: string, value: string) => {
    if (key === 'tolerance' && value !== 'between') {
      setFormState((prevState) => ({ ...prevState, value_b: undefined }))
    }
    setFormState((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleUpdateRange = ({ a, b }: InputRangeValue) => {
    handleChange('value_a', a as string)
    handleChange('value_b', b as string)
  }

  const addonData = (() => {
    const fieldType = inputData?.field_type
    if (fieldType === 'currency' || fieldType === 'distance') {
      return {
        addOn: inputData?.unit,
        addOnPosition: fieldType === 'currency' ? null : 'end',
      }
    }

    return {
      addOn: null,
      addOnPosition: 'start',
    }
  })()

  return (
    <Stack spacing={6}>
      <DropdownSelect
        dataCy="tolerance-dropdown"
        inputProps={{
          label: tolerance.title,
        }}
        options={tolerance.options}
        value={formState.tolerance}
        setValue={(value: string | number) => handleChange('tolerance', value.toString())}
      />
      <Stack direction="row">
        {formState.tolerance !== 'between' ? (
          <InputField
            inputMode="numeric"
            addOn={addonData.addOn}
            addOnPosition={addonData.addOnPosition as AddOnPositionType}
            title={equity.value.title}
            value={formState.value_a}
            handleChange={(value: string) => handleChange('value_a', value)}
            label={label}
          />
        ) : (
          <InputRange
            value={{ a: formState.value_a, b: formState.value_b ?? '' }}
            setValue={handleUpdateRange}
            inputFieldProps={{
              inputMode: 'numeric',
              addOn: addonData.addOn,
              title: equity.value.title,
              label,
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default React.forwardRef(ToleranceInput)
