import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { CountryPolicyContextType } from 'pages/advanced-credit-policy/types'

type RegisteredPhoneNumberProps = {
  policyInject: () => CountryPolicyContextType
  isAdvanced?: boolean
}

const RegisteredPhoneNumber = ({
  policyInject,
  isAdvanced = true,
}: RegisteredPhoneNumberProps) => {
  const { policy, setPolicy, isInferiorPlan, selectedCountry } = policyInject()
  const reactIntl = useIntl()

  const [val, setVal] = React.useState<boolean>(!policy.phone_number_registry)

  // this is needed to set the initial value
  useEffect(() => {
    if (typeof policy.phone_number_registry === 'boolean') {
      setVal(!policy.phone_number_registry)
    }
  }, [policy.phone_number_registry])

  return (
    <FormControlLabel
      sx={{ marginRight: 0 }}
      control={
        <Checkbox
          data-cy={`registered-phone-checkbox-${selectedCountry}`}
          checked={val}
          onChange={function (e: React.ChangeEvent<HTMLInputElement>): void {
            setVal(!val)
            if (isAdvanced && !isInferiorPlan) {
              setPolicy({ ...policy, phone_number_registry: val })
            }
          }}
          disabled={isAdvanced && isInferiorPlan}
          sx={{ margin: 3, padding: 0 }}
        />
      }
      label={reactIntl.formatMessage({
        id: intl.creditPolicyNew('reject_registered_phone'),
      })}
    />
  )
}

export default RegisteredPhoneNumber
