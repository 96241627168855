import { useState } from 'react'
import { DateInput } from 'components-new'
import { Stack, Typography } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { DateInputRangeProps } from './DateInputRange.types'
import styles from './DateInputRange.styles'

const DateInputRange = ({
  startDate,
  endDate,
  onChange,
  labelStart,
  labelEnd,
  isSmallScreen = false,
  gap = 5,
  singleDateRangeField = false,
  containerStyles = {},
}: DateInputRangeProps) => {
  const [minDateError, setMinDateError] = useState<Boolean>(false)

  const handleStartDateChange = (date: Date | null) => {
    onChange(date, endDate)
  }
  const handleEndDateChange = (date: Date | null) => {
    onChange(startDate, date)
  }

  return (
    <>
      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        gap={gap}
        alignItems={singleDateRangeField ? 'center' : 'flex-end'}
        sx={{ ...styles.container(singleDateRangeField, containerStyles) }}
      >
        <DateInput
          value={startDate}
          onChange={handleStartDateChange}
          label={labelStart}
          dataCy="date-input-range-start"
          removeBorder={singleDateRangeField}
        />
        {singleDateRangeField && <RemoveIcon />}
        <DateInput
          value={endDate}
          onChange={handleEndDateChange}
          label={labelEnd}
          minDate={startDate}
          dataCy="date-input-range-end"
          removeBorder={singleDateRangeField}
          onError={(error) => {
            if (error === 'minDate') {
              setMinDateError(true)
            } else {
              setMinDateError(false)
            }
          }}
        />
      </Stack>
      {minDateError && (
        <Stack
          sx={{ color: 'error.700' }}
          mt={1}
          gap={1}
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <ErrorOutlineIcon fontSize="large" />
          <Typography color="inherit" variant="body2">
            <FormattedMessage id={intl.errors('contract-min-date-error')} />
          </Typography>
        </Stack>
      )}
    </>
  )
}

export default DateInputRange
