import * as React from 'react'
import { Option, ReactSetState } from 'types/general'
import { SelectAPIData } from './Actions.types'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { AsyncSearchMultiSelect } from 'components-new'
import { useCompanyBulkInformation } from 'services/queries'
import { PolicyBuilderParams } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'
import { LocalId } from 'globalTypes'
import { useActionPanelStateValidator, useDebounceText } from 'services/hooks'
import { stateValidators } from '../DetailedComponentView.controller'
import { useCompanySearch } from 'services/queries/useCompanySearch'
import { SearchResultsType } from 'services/api/endpoints/risikaAPI/searchCompany'
import { CompanyBulkResponse } from 'services/api/endpoints/risikaAPI/getCompanyBulkInformation'
import intl from 'localization/components'
import { useIntl } from 'react-intl'
import { SubmitRef } from '..'
import { useBuilderParams } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.hooks'
import { OptionOption } from 'components-new/inputs/SearchMultiSelect/SearchMultiSelect.types'
import { OptionsType } from 'components-new/inputs/SearchMultiSelectOld/SearchMultiSelect.types'

type CompanySearchProps = {
  apiData: SelectAPIData
  setIsConfirmEnabled: ReactSetState<boolean>
}
function CompanySearch(
  { apiData, setIsConfirmEnabled }: CompanySearchProps,
  ref: React.ForwardedRef<SubmitRef>
) {
  const { state, actions } = useBuilder()
  const params = useBuilderParams() as PolicyBuilderParams
  const [searchText, debounceValue, setSearchText] = useDebounceText(200)
  const [companiesList, setCompaniesList] = React.useState<LocalId['id'][]>(
    apiData.include ?? []
  )
  const [options, setOptions] = React.useState<Option<string>[]>([])
  const { data: companyBulkData, isLoading: useBulkInfoLoading } =
    useCompanyBulkInformation({
      localIdList: companiesList,
      country: params.country,
    })
  const {
    data: searchData,
    isLoading: searchLoading,
    isError: searchError,
  } = useCompanySearch(debounceValue as string, params.country)

  const messages = useIntl().messages

  React.useEffect(() => {
    function formatSearchData(
      data: SearchResultsType[],
      existingOptions: Option<string>[]
    ) {
      if (!data) return []

      const newOptions = data
        .map((company) => ({
          value: company.local_organization_id.id,
          label: company.company_name,
        }))
        .filter((option) => {
          // Check if option with the same value already exists in existingOptions
          return !existingOptions.some(
            (existingOption) => existingOption.value === option.value
          )
        })

      return newOptions as Option<string>[]
    }

    if (searchData?.search_result) {
      setOptions((prevState) => [
        ...prevState,
        ...formatSearchData(searchData.search_result, prevState),
      ])
    }
  }, [searchData])

  useActionPanelStateValidator(
    companiesList,
    stateValidators.companyTypeValidator,
    setIsConfirmEnabled
  )
  React.useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        if (state.actionPanelData.nodeId) {
          actions.setNodeApiData({
            nodeId: state.actionPanelData.nodeId,
            data: { include: companiesList as string[] },
          })
        }
      },
    }),
    [actions, companiesList, state.actionPanelData.nodeId]
  )

  function handleSetCompaniesList(value: string[]) {
    setCompaniesList(value)
    setSearchText('')
    setOptions([])
  }

  const formatBulkData = (data: CompanyBulkResponse[] | undefined) => {
    if (!data) return []
    return data
      .map((company) => ({
        value: company.local_id,
        label: company.name,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  }

  return (
    <AsyncSearchMultiSelect
      // title={label}
      placeholder={
        messages[intl.creditPolicyNew('search-company-type-placeholder')] as string
      }
      searchText={searchText}
      setSearchText={setSearchText as ReactSetState<string | number>}
      options={options}
      chipData={formatBulkData(companyBulkData) as OptionsType[] | Option<OptionOption>[]}
      isLoading={useBulkInfoLoading || searchLoading}
      values={companiesList as string[]}
      setValues={handleSetCompaniesList as ReactSetState<(string | OptionOption)[]>}
      inputProps={{
        error: searchError,
      }}
    />
  )
}

export default React.forwardRef(CompanySearch)
