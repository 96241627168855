import * as React from 'react'
import { Stack, Typography, Checkbox } from '@mui/material'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { stateValidators } from '../DetailedComponentView.controller'
import { useActionPanelStateValidator } from 'services/hooks'
import { RisikaScoreProps } from './Actions.types'

import { RISIKA_SCORE_MENU, RISK_LEVEL_MENU } from './Actions.model'

export type SelectedMenu = typeof RISIKA_SCORE_MENU | typeof RISK_LEVEL_MENU

const MultiSelect = (
  { setIsConfirmEnabled, apiData, data }: RisikaScoreProps,
  ref: React.ForwardedRef<SubmitRef>
) => {
  const [formState, setFormState] = React.useState<(string | number)[]>([])

  useActionPanelStateValidator(
    formState,
    stateValidators.multiSelect,
    setIsConfirmEnabled
  )
  const { state, actions } = useBuilder()
  React.useEffect(() => {
    if (apiData?.include) {
      setFormState(apiData.include)
    }
  }, [apiData, data])
  const handleRiskLevelChange = (value: string | number) => {
    if (formState.includes(value)) {
      setFormState((prevState) => prevState.filter((item) => item !== value))
    } else {
      setFormState((prevState) => [...prevState, value])
    }
  }

  React.useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        if (state.actionPanelData.nodeId) {
          actions.setNodeApiData({
            nodeId: state.actionPanelData.nodeId,
            data: { include: formState as string[] },
          })
        }
      },
    }),
    [actions, formState, state.actionPanelData.nodeId]
  )
  // If you want to add a title in the future, just make it optional
  // TODO: Add padding to this component
  return (
    <Stack data-cy="company-type-multi-select" gap={4} height={1}>
      {data.map((option) => (
        <Stack direction="row" alignItems="center" key={option.value}>
          <Checkbox
            key={option.value}
            checked={formState.includes(option.value)}
            onChange={() => handleRiskLevelChange(option.value)}
            data-cy={`multi-select-${option.value}`}
          />
          <Typography>{option.label}</Typography>
        </Stack>
      ))}
    </Stack>
  )
}

export default React.forwardRef(MultiSelect)
