import { useQuery } from 'react-query'
import { financialStats } from 'services/api'
import { isCallAllowed } from 'configs'
import { AvailableCountries, LocalId } from 'globalTypes'

export function useFinancialStats(localId: LocalId) {
  return useQuery(
    ['financialStats', localId.id, localId.country],
    () => financialStats(localId),
    {
      enabled: !!isCallAllowed('financialStats', localId.country as AvailableCountries),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
