import { Box } from '@mui/material'
import React from 'react'
import GreenTrendArrow from 'components-new/elements/GreenTrendArrow'
import RedTrendArrow from 'components-new/elements/RedTrendArrow'

type Props = {
  trend: 'UP' | 'DOWN'
}

const VisualizedArrowTrend = ({ trend }: Props) => {
  switch (trend) {
    case 'UP':
      return <GreenTrendArrow />
    case 'DOWN':
      return <RedTrendArrow />
    default:
      return (
        <Box component="span" sx={{ visibility: 'hidden' }}>
          <GreenTrendArrow />
        </Box>
      )
  }
}

export default VisualizedArrowTrend
