import { Stack, Typography } from '@mui/material'
import { CompanyVisit } from 'services/api/endpoints/risikaAPI/companyVisitsList'
import { padding } from './columns.styles'
import { useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'

type Props = {
  item: CompanyVisit
}

function Company({ item }: Props) {
  // Use history
  const history = useHistory()
  return (
    <Stack
      onClick={() => history.push(navigation.creditCheck(item.local_organization_id))}
      data-cy="company-name"
      sx={{ ...padding, cursor: 'pointer' }}
    >
      <Typography color="grey.800" variant="subtitle1">
        {item.name}
      </Typography>
      <Typography color="grey.800" variant="body2">
        {item.name}
      </Typography>
    </Stack>
  )
}

export default Company
