import Box from '@mui/material/Box'
import { chartProperties, parameters } from './PortfolioRiskHistory.model'
import intl from 'localization/components'
import FittedStackedBarChart from './widgets/FittedStackedBarChart'
import { useMonitoringStatistic } from 'services/queries'
import { convertToStackedBarChartData } from './PortfolioRiskHistory.controller'
import BoxContainerSmall from 'components-new/layouts/BoxContainerSmall'
import { useSelector } from 'react-redux'

/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'
import { Monitor } from 'risika-api-response-types'

const PortfolioRiskHistory = ({ listId }: { listId: string }) => {
  const userLanguage = useSelector((state: any) => state.auth?.user?.local?.lang).slice(
    0,
    2
  )

  parameters.list_id = Number(listId)
  const monitoringStatistics = useMonitoringStatistic<Monitor.ListStatisticsTimeline>({
    parameters,
    endpoint: 'timeline',
  })

  const boxData = {
    title: intl.portfolioInsights('portfolio-risk-history'),
    status: {
      isLoading: monitoringStatistics.isLoading,
      isError: monitoringStatistics.isError,
      isIdle: monitoringStatistics.isIdle,
    },
    height: chartProperties.height,
    outlineBox: true,
    skeletonType: 'HORIZONTAL_EQUAL_BAR',
  }

  return (
    <Box sx={{ mt: 4 }}>
      <BoxContainerSmall boxData={boxData}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <FittedStackedBarChart
            data={convertToStackedBarChartData(
              monitoringStatistics.data ?? [],
              userLanguage
            )}
            chartProperties={chartProperties}
          />
        ) : null}
      </BoxContainerSmall>
    </Box>
  )
}

export default PortfolioRiskHistory
