import { useState, ReactNode, useMemo } from 'react'
import {
  Modal,
  Box,
  Typography,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ButtonSecondary, InputField, ButtonTertiary } from '../../components-new'

// TS Logo
function TSLogo(props: { onClick: () => void }) {
  return (
    <Box
      onClick={props.onClick}
      sx={{
        position: 'fixed',
        bottom: '2%',
        right: '2%',
        zIndex: 99,
        height: 37.5,
        borderRadius: 1,
        backgroundColor: 'transparent',
        '&:hover': {
          cursor: 'pointer',
          boxShadow: '0 0 10px 0px #007acc',
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="37.5"
        viewBox="0 0 512 512"
        width="37.5"
      >
        <rect fill="#3178c6" height="512" rx="50" width="512" />
        <rect fill="#3178c6" height="512" rx="50" width="512" />
        <path
          clipRule="evenodd"
          d="m316.939 407.424v50.061c8.138 4.172 17.763 7.3 28.875 9.386s22.823 3.129 35.135 3.129c11.999 0 23.397-1.147 34.196-3.442 10.799-2.294 20.268-6.075 28.406-11.342 8.138-5.266 14.581-12.15 19.328-20.65s7.121-19.007 7.121-31.522c0-9.074-1.356-17.026-4.069-23.857s-6.625-12.906-11.738-18.225c-5.112-5.319-11.242-10.091-18.389-14.315s-15.207-8.213-24.18-11.967c-6.573-2.712-12.468-5.345-17.685-7.9-5.217-2.556-9.651-5.163-13.303-7.822-3.652-2.66-6.469-5.476-8.451-8.448-1.982-2.973-2.974-6.336-2.974-10.091 0-3.441.887-6.544 2.661-9.308s4.278-5.136 7.512-7.118c3.235-1.981 7.199-3.52 11.894-4.615 4.696-1.095 9.912-1.642 15.651-1.642 4.173 0 8.581.313 13.224.938 4.643.626 9.312 1.591 14.008 2.894 4.695 1.304 9.259 2.947 13.694 4.928 4.434 1.982 8.529 4.276 12.285 6.884v-46.776c-7.616-2.92-15.937-5.084-24.962-6.492s-19.381-2.112-31.066-2.112c-11.895 0-23.163 1.278-33.805 3.833s-20.006 6.544-28.093 11.967c-8.086 5.424-14.476 12.333-19.171 20.729-4.695 8.395-7.043 18.433-7.043 30.114 0 14.914 4.304 27.638 12.912 38.172 8.607 10.533 21.675 19.45 39.204 26.751 6.886 2.816 13.303 5.579 19.25 8.291s11.086 5.528 15.415 8.448c4.33 2.92 7.747 6.101 10.252 9.543 2.504 3.441 3.756 7.352 3.756 11.733 0 3.233-.783 6.231-2.348 8.995s-3.939 5.162-7.121 7.196-7.147 3.624-11.894 4.771c-4.748 1.148-10.303 1.721-16.668 1.721-10.851 0-21.597-1.903-32.24-5.71-10.642-3.806-20.502-9.516-29.579-17.13zm-84.159-123.342h64.22v-41.082h-179v41.082h63.906v182.918h50.874z"
          fill="#fff"
          fillRule="evenodd"
        />
      </svg>
    </Box>
  )
}

type ListItemProps = {
  items: Output
}

function ErrorListItem({ title, content }: { title: string; content: string }) {
  return (
    <Stack ml={1} gap={1}>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography ml={1} variant="body1">
        {content}
      </Typography>
      <Divider />
    </Stack>
  )
}

const ErrorList = ({ items }: ListItemProps) => {
  return (
    <Stack height={500} overflow="scroll">
      {items.map((item) => (
        <Accordion key={item._original}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1">
              {item.component?.replace('.tsx', '').replace('.ts', '')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
            <ErrorListItem title="Component: " content={item.component ?? 'N/A'} />
            <ErrorListItem title="Line: " content={item.line ?? 'N/A'} />
            <ErrorListItem title="Error message: " content={item.errorMessage ?? 'N/A'} />
            <ErrorListItem title="Path: " content={item.path ?? 'N/A'} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  )
}

type SelectSortingProps<T> = {
  title: string
  options: { value: T; label: string }[]
  value: T
  setValue: (value: T) => void
}

function SelectSorting<T>({ title, options, value, setValue }: SelectSortingProps<T>) {
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{title}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={(e) => setValue(e.target.value as T)}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value as string}
            value={value}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

// Main Component

const containerMain = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  backgroundColor: '#f6f4f4f2',
  border: '2px solid #245157',
  boxShadow: 24,
  paddingY: 4,
  paddingX: 6,
}

type PropsType = {
  children: ReactNode
}

type Output = {
  path?: string
  line: string | null
  errorMessage: string
  component?: string
  _original: string
}[]

const sortingOptions = [
  { value: 'component', label: 'Component' },
  { value: 'path', label: 'Path' },
  { value: 'errorMessage', label: 'Error Message' },
  { value: 'line', label: 'Line' },
] as const

type SortingOptionType = (typeof sortingOptions)[number]['value']

const TS_ERRORS = ({ children }: PropsType) => {
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState('')
  const [output, setOutput] = useState<Output>([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [sortBy, setSortBy] = useState<SortingOptionType>('component')
  const [filterBy, setFilterBy] = useState<SortingOptionType>('component')
  const [filterText, setFilterText] = useState('')

  // You can swap the order of execution of sorting and filtering for better performance
  const sortedOutput = useMemo(() => {
    setIsProcessing(true)
    const sorted = output.sort((a, b) => {
      if ((a[sortBy] || '') < (b[sortBy] || '')) return -1
      if ((a[sortBy] || '') > (b[sortBy] || '')) return 1
      return 0
    })
    setIsProcessing(false)
    return sorted
  }, [sortBy, output])

  const filteredOutput = useMemo(() => {
    setIsProcessing(true)
    if (!filterText) {
      setIsProcessing(false)
      return sortedOutput
    }
    setIsProcessing(false)
    return sortedOutput.filter((item) =>
      item[filterBy]?.toLowerCase().includes(filterText.toLowerCase())
    )
  }, [filterText, filterBy, sortedOutput])

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const processData = (data: string) => {
    const removedWhiteSpace = data.replace(/\s{2,}/g, ' ')

    const outputArray = (() => {
      setIsProcessing(true)
      const pathRegex = /src\/.*?\.(?:tsx|ts)/g
      const lineRegex = /(?<=\.(?:tsx|ts):)\d+:\d+/g
      const errorMessageRegex = /TS\d+:\s.*?\.\s*\d+\s*\|/g

      const arr = removedWhiteSpace
        .split('WARNING in ')
        .map((item) => item.replace('. >', '.'))
        .map((item) => item.replace('?', '.'))
        .filter((item) => !item.includes('[eslint]'))
        .filter((item) => !item.includes('node_modules'))

      // if (arr.length > 1) arr.shift()

      const processedArray = arr
        .map((item) => {
          const pathMatches = item.match(pathRegex)
          const path = pathMatches ? pathMatches[0] : ''

          const lineMatches = item.match(lineRegex)
          const line = lineMatches ? lineMatches[0] : ''

          const errorMessageMatches = item.match(errorMessageRegex)
          const errorMessage = errorMessageMatches ? errorMessageMatches[0] : ''

          const component = path.split('/').pop()

          return { path, line, errorMessage, component, _original: item }
        })
        .map((item) => {
          if (!item.line && !item.errorMessage) {
            return {
              ...item,
              line: null,
              errorMessage: item._original.substring(item._original.indexOf('TS')),
            }
          }
          return item
        })
      setIsProcessing(false)
      return processedArray
    })()
    // Remove duplicates by _original
    const uniqueOutputArray = outputArray.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t._original === item._original)
    )
    setOutput(uniqueOutputArray)
  }
  if (process.env.NODE_ENV !== 'development') return <>{children}</>

  return (
    <>
      <TSLogo onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack gap={3} sx={containerMain}>
          <Box>
            <InputField
              label="Input"
              placeholder="Paste all TypeScript errors from the console..."
              value={input}
              handleChange={setInput}
            />
            {output.length > 0 && (
              <Stack mt={1} direction="row" justifyContent="flex-end">
                <Typography sx={{ color: 'error.300' }} variant="caption">
                  {output.length} errors found !
                </Typography>
              </Stack>
            )}
          </Box>
          {output.length > 0 ? (
            <>
              <SelectSorting<SortingOptionType>
                title="Filter by: "
                options={sortingOptions as any}
                value={filterBy}
                setValue={setFilterBy}
              />
              <Box>
                <InputField value={filterText} handleChange={setFilterText} />
                <Stack height={18} mt={1} direction="row" justifyContent="flex-end">
                  {filteredOutput.length > 0 && (
                    <Typography sx={{ color: 'info.main' }} variant="caption">
                      {filteredOutput.length} results !
                    </Typography>
                  )}
                </Stack>
              </Box>
              <SelectSorting<SortingOptionType>
                title="Sort by: "
                options={sortingOptions as any}
                value={sortBy}
                setValue={setSortBy}
              />
              {filteredOutput.length > 0 ? (
                <ErrorList items={filteredOutput} />
              ) : (
                <Box height={500}>
                  <Typography variant="subtitle1">No results...</Typography>
                </Box>
              )}
            </>
          ) : (
            <Typography variant="body2">No errors !!!</Typography>
          )}
          <Stack marginTop="auto" direction="row-reverse" spacing={2}>
            {isProcessing ? (
              <Stack direction="row" justifyContent="center" width={98}>
                <CircularProgress />
              </Stack>
            ) : (
              <ButtonSecondary disabled={!input} onClick={() => processData(input)}>
                Process
              </ButtonSecondary>
            )}
            <ButtonTertiary onClick={handleClose}>Close</ButtonTertiary>
          </Stack>
        </Stack>
      </Modal>
      {children}
    </>
  )
}

export default TS_ERRORS
