import { useQuery } from 'react-query'
import getUserSettingsField from 'services/api/endpoints/backendAPI/getUserSettingsField'
import { AllowedFields } from 'services/types'

export function useUserSettingsField<T>(field: AllowedFields) {
  return useQuery('getUserSettingsField' + field, () => getUserSettingsField<T>(field), {
    retry: 1,
    refetchOnWindowFocus: false,
  })
}
