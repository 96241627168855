import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const grid: SxProps = {
  padding: '1rem 2rem',
}

const analytics: SxProps = {
  borderRadius: theme.spacing(1),
  margin: '2rem',
}

const analyticsItem: SxProps = {
  [theme.breakpoints.down('md')]: {
    borderLeft: 'none',
  },
  [theme.breakpoints.up('xs')]: {
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: 0,
  },
}

const analyticsItemSpecial: SxProps = {
  ...analyticsItem,
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
    borderLeft: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    marginBottom: 0,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
}

const analyticsButtonGrid: SxProps = {
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up('lg')]: {
    margin: '-1rem -2rem',
  },
}

const analyticsButtonContainer: SxProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    height: '80%',
  },
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
}

const analyticsButtonDisabled: SxProps = {
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[100],
  cursor: 'not-allowed',
}

export const classes = {
  grid,
  analytics,
  analyticsItem,
  analyticsItemSpecial,
  analyticsButtonGrid,
  analyticsButtonContainer,
  analyticsButtonDisabled,
}
