import risikaAPI from 'services/api/helpers/risikaAPI'
import { Monitor } from 'risika-api-response-types'
import { ScoreByIndustryData } from 'widgets/charts/ListCreditScoreByIndustryBox/ListCreditScoreByIndustryBox.model'
import { CompanyTypeData } from 'widgets/charts/ListCreditScoreByCompanyTypeBox/ListCreditScoreByCompanyTypeBox.model'
import { EmployeesData } from 'widgets/charts/ListCreditScoreByEmployeesBox/ListCreditScoreByEmployeesBox.model'
import { DistributionData } from 'widgets/charts/ListCreditScoreDistributionBox/ListCreditScoreDistributionBox.model'
import { KeyFiguresByCompanyTypeData } from 'widgets/charts/ListKeyFiguresByCompanyTypeBox/ListKeyFiguresByCompanyTypeBox.model'
import { KeyFiguresByIndustryData } from 'widgets/charts/ListKeyFiguresByIndustryBox/ListKeyFiguresByIndustryBox.model'
import { DevelopmentInKeyFiguresData } from 'widgets/charts/ListKeyFiguresDevelopmentBox/ListKeyFiguresDevelopmentBox.model'
import { DevelopmentInPolicyCompliancesData } from 'widgets/charts/ListPolicyComplianceDevelopmentBox/ListPolicyComplianceDevelopmentBox.model'
import { MonitoringStatisticQueryParams } from 'services/queries'

export type MonitoringStatisticsData =
  | Monitor.ListStatisticsAggregatedHighlights
  | Monitor.ListStatisticsTimeline
  | Monitor.ListStatisticsCompliance
  | Monitor.ListStatisticsCategory

// type Parameters =
//   | ScoreByIndustryData['parameters']
//   | CompanyTypeData['parameters']
//   | EmployeesData['parameters']
//   | DistributionData['parameters']
//   | KeyFiguresByCompanyTypeData['parameters']
//   | KeyFiguresByIndustryData['parameters']
//   | DevelopmentInKeyFiguresData['parameters']
//   | DevelopmentInPolicyCompliancesData['parameters']

export type StatisticEndpoints =
  | ScoreByIndustryData['endPoint']
  | CompanyTypeData['endPoint']
  | EmployeesData['endPoint']
  | DistributionData['endPoint']
  | KeyFiguresByCompanyTypeData['endPoint']
  | KeyFiguresByIndustryData['endPoint']
  | DevelopmentInKeyFiguresData['endPoint']
  | DevelopmentInPolicyCompliancesData['endPoint']

type MonitoringStatisticType = {
  parameters: MonitoringStatisticQueryParams
  endPoint: StatisticEndpoints
  scoreModel?: boolean | {}
}
const monitoringStatistic = ({
  parameters,
  endPoint,
  scoreModel = true,
}: MonitoringStatisticType) => {
  const score_model = typeof scoreModel === 'boolean' ? null : scoreModel
  return risikaAPI
    .post(
      `/list/statistics/${endPoint}`,
      {
        ...parameters,
        ...(score_model && {
          score_model: scoreModel,
        }),
      },
      {
        microservice: 'monitor',
        timeout: 90000,
        appendScoringModelData: typeof scoreModel === 'boolean' ? scoreModel : false,
      }
    )
    .then(({ data }: { data: MonitoringStatisticsData }) => {
      return data
    })
}

export default monitoringStatistic
