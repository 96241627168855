import * as React from 'react'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import {
  Stack,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

import SelectFieldsContent from './SelectFieldsContent'
import { exportSearch } from 'services/api'
import { DropdownMenu, withRestrictions, StoreExportOptions } from 'components'
import { downloadAs, downloadFormat } from 'services/export'
import { join, isNil } from 'ramda'
import { useAccountStatus } from 'services/queries'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import useTotangoTracking from 'utils/totangoTracking'
import { RISIKA_PRICING_PAGE } from 'configs/constants/system'
import { ButtonSecondary, ButtonTertiary } from 'components-new'
/****************
 * TRANSLATIONS *
 ****************/

const formatEmployees = (employeesInterval) => {
  if (employeesInterval === '1000-999999') {
    return '1000+'
  }

  return employeesInterval
}

const formatBank = (bankName, bankLocalId) => {
  if (isNil(bankName) || isNil(bankLocalId)) {
    return '-'
  }

  return `${bankName} (${bankLocalId})`
}

const toDanish = (rows) => {
  return rows.map((row) => ({
    'Virksomheds ID': row.local_company_id,
    Navn: row.name,
    Reklamebeskyttet: row.advertisement_protected ? 'Ja' : 'Nej',
    Stiftelsesdato: row.date_of_incorporation,
    Virksomhedstype: row.type,
    Vejnavn: row.street,
    Vejnummer: row.number,
    Postnummer: row.zipcode,
    By: row.city,
    Kommune: row.municipality,
    Region: row.region,
    Land: row.country,
    'E-mail': row.email,
    'E-mail skjult': row.email_hidden ? 'Ja' : 'Nej',
    Telefon: row.phone,
    'Telefon skjult': row.phone_hidden ? 'Ja' : 'Nej',
    Hjemmeside: row.webpage,
    'Antal ansatte': formatEmployees(row.employees_interval),
    Branchekode: row.industry_code,
    'Branchekode navn': row.industry_name,
    Bank: formatBank(row.bank_name, row.bank_local_id),
    Tegningsregel: row.powers_to_bind,
    Ledelse: row.management,
    'Regnskabsår start': row.report_start,
    'Regnskabsår slut': row.report_end,
    Revisionspåtegning: row.type_of_auditor_assistance,
    Revisornavn: row.name_and_surname_of_auditor,
    Valuta: row.currency,
    Omsætning: row.revenue,
    Bruttofortjeneste: row.gross_result,
    EBITDA: row.ebitda,
    Årsresultat: row.profit_loss,
    'Immaterielle aktiver': row.intangible_assets,
    'Materialle anlægsaktiver': row.property_plant_and_equipment,
    'Anlægsaktiver i alt': row.noncurrent_assets,
    Varelager: row.inventories,
    'Tilgodehavender fra salg og tjenesteydesler':
      row.short_term_receivables_from_sales_and_services,
    Likvider: row.cash,
    Aktiver: row.assets,
    Egenkapital: row.equity,
    Selskabskapital: row.capital,
    Hensættelser: row.provisions,
    'Langfristet gæld': row.long_term_debt,
    'Kortfristet gæld': row.short_term_debt,
    Balancesum: row.liabilities_and_equity,
    Bruttomargin: row.gross_margin,
    Egenkapitalforrentning: row.return_on_equity,
    Soliditetsgrad: row.solidity_ratio,
    Værdipapirer: row.current_financial_assets,
  }))
}

const toEnglish = (rows) => {
  return rows.map((row) => ({
    'Company ID': row.local_company_id,
    Name: row.name,
    'Advertisement Protected': row.advertisement_protected ? 'Yes' : 'No',
    'Date of Incorporation': row.date_of_incorporation,
    'Company Type': row.type,
    'Street Name': row.street,
    'Street Number': row.number,
    Zipcode: row.zipcode,
    City: row.city,
    Municipality: row.municipality,
    Region: row.region,
    Country: row.country,
    Email: row.email,
    'Email Hidden': row.email_hidden ? 'Yes' : 'No',
    Phone: row.phone,
    'Phone Hidden': row.phone_hidden ? 'Yes' : 'No',
    Webpage: row.webpage,
    'Number of Employees': formatEmployees(row.employees_interval),
    'Industry Code': row.industry_code,
    'Industry Name': row.industry_name,
    Bank: formatBank(row.bank_name, row.bank_local_id),
    'Powers to Bind': row.powers_to_bind,
    Management: row.management,
    'Report start': row.report_start,
    'Report end': row.report_end,
    'Type of Auditor Assistance': row.type_of_auditor_assistance,
    'Name of Auditor': row.name_and_surname_of_auditor,
    Currency: row.currency,
    Revenue: row.revenue,
    'Gross Result': row.gross_result,
    EBITDA: row.ebitda,
    'Profit and Loss': row.profit_loss,
    'Intangible Assets': row.intangible_assets,
    'Property Plant and Equipment': row.property_plant_and_equipment,
    'Noncurrenct Assets': row.noncurrent_assets,
    Inventories: row.inventories,
    'Short Term Receivables From Sales and Services':
      row.short_term_receivables_from_sales_and_services,
    Cash: row.cash,
    Assets: row.assets,
    Equity: row.equity,
    Capital: row.capital,
    Provisions: row.provisions,
    'Long Term Debt': row.long_term_debt,
    'Short Term Debt': row.short_term_debt,
    'Liabilities and Equity': row.liabilities_and_equity,
    'Gross Margin': row.gross_margin,
    'Return on Equity': row.return_on_equity,
    'Solidity Ratio': row.solidity_ratio,
    'Current Financial Assets': row.current_financial_assets,
  }))
}

/**************************
 * Map API data to fields *
 **************************/

const mapExportFields = (rows) => {
  return rows.map((row) => ({
    local_company_id: row.local_organization_id?.id,
    name: row.company_name?.trim(),
    advertisement_protected: row.advertisement_protected,
    date_of_incorporation: row.date_of_incorporation,
    type: row.company_type,
    street: row.address?.street,
    number: row.address?.number,
    zipcode: row.address?.zipcode,
    city: row.address?.city,
    municipality: row.address?.municipality,
    region: row.address?.local_region,
    country: row.address?.country,
    email: row.email?.email,
    email_hidden: row.email?.hidden,
    phone: row.phone?.phone,
    phone_hidden: row.phone?.hidden,
    webpage: row.webpage,
    employees_interval: row.employees_interval,
    industry_code: row.main_industry_code?.code,
    industry_name: row.main_industry_code?.description,
    bank_name: row.bank_name,
    bank_local_id: row.bank_local_id,
    powers_to_bind: row.powers_to_bind,
    management: join(', ', row.management ?? []),
    report_start: row.report_start,
    report_end: row.report_end,
    type_of_auditor_assistance: row.type_of_auditor_assistance,
    name_and_surname_of_auditor: row.name_and_surname_of_auditor,
    currency: row.currency,
    revenue: row.revenue,
    gross_result: row.gross_result,
    ebitda: row.ebitda,
    profit_loss: row.profit_loss,
    intangible_assets: row.intangible_assets,
    property_plant_and_equipment: row.property_plant_and_equipment,
    noncurrent_assets: row.noncurrent_assets,
    inventories: row.inventories,
    short_term_receivables_from_sales_and_services:
      row.short_term_receivables_from_sales_and_services,
    cash: row.cash,
    assets: row.assets,
    equity: row.equity,
    provisions: row.provisions,
    long_term_debt: row.long_term_debt,
    short_term_debt: row.short_term_debt,
    liabilities_and_equity: row.liabilities_and_equity,
    gross_margin: row.gross_margin,
    return_on_equity: row.return_on_equity,
    solidity_ratio: row.solidity_ratio,
    capital: row.capital,
    current_financial_assets: row.current_financial_assets,
  }))
}

/**********
 * Button *
 **********/

// type ButtonRequestExportProps = {
//   filename?: string,
//   disabled: boolean,
//   filters: any,
//   className?: any,
//   text?: any,
//   companyResultsLength: number,
//   style: Record<String, unknown>,
// }

const ButtonRequestExport = ({
  filename,
  disabled,
  filters,
  className,
  companyResultsLength,
  text = <FormattedMessage id={intl.exportButton('text')} />,
}) => {
  const { lang } = useSelector((state) => state.auth.user.local)

  const accountStatusQuery = useAccountStatus()
  const exportLimit = accountStatusQuery.data?.rights.search_exports_limit ?? 0

  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [data, setData] = React.useState({})
  const [filetype, setFiletype] = React.useState('')
  const [selectedFields, setSelectedFields] = React.useState([])
  const { trackEvent } = useTotangoTracking()
  const { enqueueSnackbar } = useSnackbar()

  const now = moment().format('YYYY-MM-DD hh-mm-ss')
  if (!filename) filename = `Risika export ${now}`

  const fetchDataToExport = () => {
    if (exportLimit < companyResultsLength) {
      enqueueSnackbar(
        <FormattedMessage
          id={intl.snackbar('maximum-export-exceded-exporting-less')}
          values={{ exportLimit }}
        />
      )
    }

    return exportSearch(filters, exportLimit)
      .then(({ search_result }) => {
        const mappedData = mapExportFields(search_result)
        if (lang === 'da_DK') {
          setData(toDanish(mappedData))
        } else {
          setData(toEnglish(mappedData))
        }
      })
      .catch((err) => {
        console.log('Error in fetching company data', err)
        return []
      })
  }

  /**
   * Determine which file type should be
   * downloaded and delegate the work.
   *
   * @param {string} fileType The file type to export.
   */
  const handleFileDownload = async (fileType) => {
    setLoading(true)
    setFiletype(fileType)
    setOpen(true)
    fetchDataToExport()
  }

  const keepOnlySelectedFields = (array) =>
    array.map((o) =>
      selectedFields.reduce((acc, curr) => {
        acc[curr] = o[curr]
        return acc
      }, {})
    )

  const handleFinishDownload = () => {
    trackEvent('Filtering', 'Export Filtering')
    const download = downloadAs(filetype)
    setOpen(false)
    const newData = keepOnlySelectedFields(data)
    download(filename, newData)
    if (newData?.length) {
      enqueueSnackbar(<FormattedMessage id={intl.snackbar('export-success')} />)
    } else {
      enqueueSnackbar(<FormattedMessage id={intl.snackbar('export-failed')} />)
    }
    setLoading(false)
  }

  const RestrictedDropdownMenu = withRestrictions('allow_company_export', {
    title: <FormattedMessage id={intl.restriction('export-title')} />,
    content: <FormattedMessage id={intl.restriction('export-description')} />,
    button: {
      text: <FormattedMessage id={intl.restriction('export-button')} />,
      link: { RISIKA_PRICING_PAGE },
    },
  })(DropdownMenu)

  const handleClose = () => {
    setOpen(false)
    setLoading(false)
  }

  return (
    <>
      <RestrictedDropdownMenu
        icon={<FileUploadIcon />}
        text={text}
        disabled={disabled}
        loading={loading}
        className={className}
      >
        <MenuItem onClick={() => handleFileDownload(downloadFormat.EXCEL)}>
          Excel
        </MenuItem>
        <MenuItem onClick={() => handleFileDownload(downloadFormat.CSV)}>CSV</MenuItem>
      </RestrictedDropdownMenu>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ backgroundColor: 'primary.dark' }}>
          <Typography color="inherit" variant="h5">
            {<FormattedMessage id={intl.advancedFilters('select-columns')} />}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <SelectFieldsContent
            data={data}
            lang={lang}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Stack
            direction="row"
            width={1}
            justifyContent="space-between"
            alignItems="center"
            paddingX={5}
            pb={4}
            pt={5}
          >
            <Stack direction="row">
              <StoreExportOptions
                selectedFields={selectedFields?.sort()}
                setSelectedFields={setSelectedFields}
                persistedLocationKey="exportOptionsFiltering"
              />
            </Stack>
            <Stack direction="row" gap={4}>
              <ButtonTertiary onClick={handleClose}>
                {<FormattedMessage id={intl.confirmAction('cancel')} />}
              </ButtonTertiary>
              <ButtonSecondary
                disabled={!Object.keys(selectedFields).length}
                loading={!Object.keys(data).length}
                onClick={handleFinishDownload}
                autoFocus
              >
                {<FormattedMessage id={intl.restriction('export-title')} />}
              </ButtonSecondary>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ButtonRequestExport
