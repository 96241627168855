import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const main: SxProps = {
  px: theme.spacing(1),
  py: theme.spacing(2),
  bgcolor: theme.palette.grey[100],
  borderRadius: '10px',
}

const shieldWarningIcon: SxProps = {
  width: '50px',
  height: '50px',
  border: '3px solid',
  borderColor: theme.palette.error.main,
  borderRadius: '50px',
  p: theme.spacing(1),
}

const hoverPointerCursor: SxProps = {
  '&:hover': {
    cursor: 'pointer',
  },
}

export default {
  main,
  shieldWarningIcon,
  hoverPointerCursor,
}
