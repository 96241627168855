import { ECPAvailableCountries } from 'globalTypes'
import { useQuery } from 'react-query'
import { getECPDataStructure } from 'services/api'

export function useECPDataStructure(country: ECPAvailableCountries) {
  return useQuery('useECPDataStructure', () => getECPDataStructure(country), {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  })
}
