import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { sortBy, prop, pipe, map, head } from 'ramda'

import { expirationPolicy } from 'services/timestamp'

import {
  companyTypeList,
  companyStatusList,
  employeeList,
  industryList,
  municipalityList,
  regionList,
  zipcodeList,
} from 'services/api'

import { SET_FILTER_SUGGESTIONS_FOR_COUNTRY_REQUEST } from './constants'

import { getFilterSuggestionsSuccess } from './actions'

/***********
 * Helpers *
 ***********/

function getFromInRange(rangeStr) {
  return Number(head(rangeStr.split('-')))
}

function sortByLabel(suggestions) {
  return sortBy(prop('label'))(suggestions)
}

function sortByLabelRange(suggestions) {
  return sortBy(pipe(prop('label'), getFromInRange))(suggestions)
}

/**************
 * Converters *
 **************/

function convertCompanyType(data) {
  return pipe(
    map(({ code, title }) => ({ label: `(${code}) ${title}`, value: code })),
    sortByLabel
  )(data)
}

function convertCompanyStatus(data) {
  return pipe(
    map(({ code, title }) => ({ label: title, value: code })),
    sortByLabel
  )(data)
}

function convertEmployee(data) {
  return pipe(
    map((employee) => ({ label: employee, value: employee })),
    sortByLabelRange
  )(data)
}

function convertSpecificIndustry(data) {
  const { specificIndustry } = data

  return pipe(
    map(({ code, description }) => ({
      label: `(${code}) ${description}`,
      value: code,
    })),
    sortByLabel
  )(specificIndustry)
}

function convertIndustryGroup(data) {
  const { industryGroup } = data

  return pipe(
    map(({ code, description }) => ({
      label: `(${code}) ${description}`,
      value: code,
    })),
    sortByLabel
  )(industryGroup)
}

function convertMunicipality(data) {
  return pipe(
    map((municipality) => ({
      label: municipality,
      value: municipality,
    })),
    sortByLabel
  )(data)
}

function convertRegion(data) {
  return pipe(
    map(({ region, value }) => ({
      label: region,
      value: region,
    })),
    sortByLabel
  )(data)
}

function convertZipcode(data) {
  return pipe(
    map((zipcode) => ({
      label: zipcode.toString(),
      value: zipcode,
    })),
    sortByLabel
  )(data)
}

function convertScore(data) {
  return pipe(
    map((score) => ({
      label: score.toString(),
      value: score,
    })),
    sortByLabel
  )(data)
}

/***************
 * Saga Worker *
 ***************/

function getTimestamp(country) {
  return function (state) {
    return state.risika.filterSuggestions.timestamps[country.toLowerCase()]
  }
}

/**
 * Create expiration policy for the suggestions.
 */
const hasTimestampExpired = expirationPolicy({
  seconds: 60,
})

function* fetchFilterSuggestions(action) {
  const { country } = action.payload

  const timestamp = yield select(getTimestamp(country))

  if (!hasTimestampExpired(timestamp)) {
    return
  }
  try {
    if (country === 'dk') {
      const [
        companyType,
        companyStatus,
        employee,
        industry,
        municipality,
        region,
        zipcode,
      ] = yield all([
        call(companyTypeList, country),
        call(companyStatusList, country),
        call(employeeList, country),
        call(industryList, country),
        call(municipalityList, country),
        call(regionList, country),
        call(zipcodeList, country),
      ])
      yield put(
        getFilterSuggestionsSuccess(
          {
            companyType: convertCompanyType(companyType),
            companyStatus: convertCompanyStatus(companyStatus),
            employee: convertEmployee(employee),
            specificIndustry: convertSpecificIndustry(industry),
            industryGroup: convertIndustryGroup(industry),
            municipality: convertMunicipality(municipality),
            region: convertRegion(region),
            zipcode: convertZipcode(zipcode),
            score: convertScore([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
          },
          country
        )
      )
    } else if (country === 'se') {
      const [
        companyType,
        companyStatus,
        employee,
        industry,
        municipality,
        region,
        zipcode,
      ] = yield all([
        call(companyTypeList, country),
        call(companyStatusList, country),
        call(employeeList, country),
        call(industryList, country),
        call(municipalityList, country),
        call(regionList, country),
        call(zipcodeList, country),
      ])

      yield put(
        getFilterSuggestionsSuccess(
          {
            companyType: convertCompanyType(companyType),
            companyStatus: convertCompanyStatus(companyStatus),
            specificIndustry: convertSpecificIndustry(industry),
            industryGroup: convertIndustryGroup(industry),
            employee: convertEmployee(employee),
            municipality: convertMunicipality(municipality),
            zipcode: convertZipcode(zipcode),
            region: convertRegion(region),
            score: convertScore([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
          },
          country
        )
      )
    } else if (country === 'fi') {
      const [
        companyType,
        industry,
        companyStatus,
        employee,
        municipality,
        region,
        zipcode,
      ] = yield all([
        call(companyTypeList, country),
        call(industryList, country),
        call(companyStatusList, country),
        call(employeeList, country),
        call(municipalityList, country),
        call(regionList, country),
        call(zipcodeList, country),
      ])
      yield put(
        getFilterSuggestionsSuccess(
          {
            companyType: convertCompanyType(companyType),
            specificIndustry: convertSpecificIndustry(industry),
            industryGroup: convertIndustryGroup(industry),
            score: convertScore([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
            companyStatus: convertCompanyStatus(companyStatus),
            employee: convertEmployee(employee),
            municipality: convertMunicipality(municipality),
            zipcode: convertZipcode(zipcode),
            region: convertRegion(region),
          },
          country
        )
      )
    } else if (country === 'no') {
      const [
        companyType,
        companyStatus,
        industry,
        municipality,
        employee,
        region,
        zipcode,
      ] = yield all([
        call(companyTypeList, country),
        call(companyStatusList, country),
        call(industryList, country),
        call(municipalityList, country),
        call(employeeList, country),
        call(regionList, country),
        call(zipcodeList, country),
      ])
      yield put(
        getFilterSuggestionsSuccess(
          {
            companyType: convertCompanyType(companyType),
            companyStatus: convertCompanyStatus(companyStatus),
            specificIndustry: convertSpecificIndustry(industry),
            industryGroup: convertIndustryGroup(industry),
            employee: convertEmployee(employee),
            municipality: convertMunicipality(municipality),
            zipcode: convertZipcode(zipcode),
            region: convertRegion(region),
            score: convertScore([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
          },
          country
        )
      )
    }
  } catch (error) {
    yield console.log('Cannot fetch filter suggestions from Risika APIs')
  }
}

/****************
 * Saga Watcher *
 ****************/

function* filterSuggestionsSaga() {
  yield takeLeading(SET_FILTER_SUGGESTIONS_FOR_COUNTRY_REQUEST, fetchFilterSuggestions)
}

export default filterSuggestionsSaga
