import notesList from '../notesList'
import notesAdd from '../notesAdd'
import mergeNotes from './mergeNotes'

import chunk from 'utils/chunk'

export default async function createOrUpdateNotes(newNotes) {
  const CHUNK_SIZE = 500

  const { data: existingNotes } = await notesList()

  const mergedNotes = mergeNotes(existingNotes, newNotes)

  const notesToAdd = mergedNotes
    .filter((note) => note.hasChange && !note.hasConflict)
    .map((note) => note.value)

  if (notesToAdd.length > 0) {
    for (const notes of chunk(CHUNK_SIZE, notesToAdd)) {
      await notesAdd(notes)
    }
  }

  return mergedNotes
}
