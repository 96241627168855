import { combineReducers } from 'redux'
import * as reduxModule from 'redux'
import { applyMiddleware, compose, createStore } from 'redux'
import risika, { rootSaga } from 'store_deprecated'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import auth from 'store_deprecated/auth/reducer'
import { LOGOUT_USER } from 'store_deprecated/auth/constants'

const reducers = combineReducers({
  auth,
  risika,
})

const createReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined
  }

  return reducers(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['risika'],
}

const persistedReducer = persistReducer(persistConfig, createReducer)

/*
 * Fix for Firefox redux dev tools extension
 * https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = '@@redux/INIT'

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension's options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const saga = createSagaMiddleware()

// Register middleware
const middleware = [saga, thunk]

const enhancer = composeEnhancers(applyMiddleware(...middleware))
const store = createStore(persistedReducer, enhancer)
const persistor = persistStore(store)

saga.run(rootSaga)

export { store, persistor }
