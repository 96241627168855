import { AddToMonitoringListButton } from 'components'
import { TableCellProps } from '../../HierarchyListView.types'

function Monitor({ row, align }: TableCellProps) {
  if (row.type !== 'COMPANY') {
    return null
  }

  return (
    <AddToMonitoringListButton
      // @ts-ignore // Sorry, that component is ancient. Could not be bothered to look in to that at the moment
      company={row.local_id}
      color="contrast"
      hierarchy
    />
  )
}
export default Monitor
