import { SxProps } from '@mui/system'
import { colors } from 'configs'
import { theme } from 'styles-new/mui5-transition/theme'

const frame: SxProps = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  margin: theme.spacing(4),
}

const draggingStyles = (isDraggingVar: boolean) => ({
  bgcolor: isDraggingVar ? colors?.risikaContrast : 'common.white',
})

const paymentRemarksContentStyle: SxProps = {
  bgcolor: 'white',
  '&:hover': {
    cursor: 'pointer',
    bgcolor: theme.palette.grey[100],
  },
}

export default {
  frame,
  draggingStyles,
  paymentRemarksContentStyle,
}
