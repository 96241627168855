import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import classes from './toggle-menu'
import { SxProps } from '@mui/system'
import { ButtonLink2 } from 'components-new'

type Props = {
  isMenuExpanded: boolean
  setIsMenuExpanded: Dispatch<SetStateAction<boolean>>
  firstLabelGenericKey: string
  secondLabelGenericKey: string
  sx?: SxProps
}

function ToggleMenu({
  isMenuExpanded,
  setIsMenuExpanded,
  firstLabelGenericKey,
  secondLabelGenericKey,
  sx,
}: Props) {
  const [label, setLabel] = useState(firstLabelGenericKey)

  useEffect(() => {
    setLabel(isMenuExpanded ? secondLabelGenericKey : firstLabelGenericKey)
  }, [firstLabelGenericKey, isMenuExpanded, secondLabelGenericKey])

  return (
    <Stack
      direction="row"
      sx={{ ...classes.container, ...sx }}
      onClick={() => setIsMenuExpanded((prev) => !prev)}
    >
      <ButtonLink2>
        <Typography variant="body1">
          <FormattedMessage id={intl.generic(label)} />
        </Typography>
      </ButtonLink2>
    </Stack>
  )
}

export default ToggleMenu
