import { Typography } from '@mui/material'
import { ReactNode } from 'react'

type CompanyProps = {
  primary: ReactNode
  secondary: ReactNode
  isActive: boolean
  dataCy?: string
}

export const Company = (props: CompanyProps) => {
  const { primary, secondary, isActive, dataCy } = props
  return (
    <div data-cy={dataCy}>
      <Typography variant="subtitle1" sx={{ color: isActive ? 'gry.800' : 'grey.600' }}>
        {primary}
      </Typography>
      {secondary ? (
        <Typography variant="body2" sx={{ color: isActive ? 'grey.800' : 'grey.600' }}>
          {secondary}
        </Typography>
      ) : null}
    </div>
  )
}

export default Company
