import { useEffect, useState } from 'react'
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material/'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import {
  useDetailedNumberOfEmployees,
  useFinancialNumbers,
  useUserSettings,
} from 'services/queries'
import { createFinancialHighlights } from './HighlightsBox.controller'
import { LocalId } from 'globalTypes'
import SidebarWidgetError from 'pages/credit-check/scenes/MainRecommendation/components/SidebarWidgetError'
import HighlightsBoxSkeleton from './widgets/HighlightsBoxSkeleton'
import { CurrencyObj } from 'services/types'
import styles from './HighlightsBox.styles'
import { defaultCurrency, TREND_DOWN, TREND_NONE, TREND_UP } from './HighlightsBox.model'
import { FinancialHighlightsState, Trend } from './HighlightsBox.types'
import { GreenTrendArrow, RedTrendArrow } from 'components-new'
import RemoveIcon from '@mui/icons-material/Remove'

const { columnContainer, leftColumn, rightColumn, titleBorder } = styles

const HighlightsBox = ({ localId }: { localId: LocalId }) => {
  const reactIntl = useIntl()
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyObj>(defaultCurrency)
  const [financialHighlights, setFinancialHighlights] =
    useState<FinancialHighlightsState>([])

  const financialNumbersQuery = useFinancialNumbers(localId, {}, true, 60000)
  const detailedNumberOfEmployees = useDetailedNumberOfEmployees(localId, 60000)
  const userSettingsQuery = useUserSettings()

  const isLoading =
    financialNumbersQuery.isLoading &&
    detailedNumberOfEmployees.isLoading &&
    userSettingsQuery.isLoading
  const isError =
    financialNumbersQuery.isError &&
    detailedNumberOfEmployees.isError &&
    userSettingsQuery.isError

  useEffect(() => {
    if (userSettingsQuery.status === 'success' && userSettingsQuery.data) {
      if (
        userSettingsQuery.status === 'success' &&
        userSettingsQuery.data &&
        selectedCurrency.currency !== userSettingsQuery.data.selectedCurrency.currency
      ) {
        setSelectedCurrency(userSettingsQuery.data.selectedCurrency)
      }
    }
  }, [selectedCurrency, userSettingsQuery])

  useEffect(() => {
    if (financialNumbersQuery.data && detailedNumberOfEmployees.data) {
      const newHighlights = createFinancialHighlights(
        financialNumbersQuery.data,
        detailedNumberOfEmployees.data
      )
      if (JSON.stringify(financialHighlights) !== JSON.stringify(newHighlights)) {
        setFinancialHighlights(newHighlights as FinancialHighlightsState)
      }
    }
  }, [detailedNumberOfEmployees, financialHighlights, financialNumbersQuery])

  if (financialHighlights.length === 0) {
    return <CircularProgress />
  }

  if (isError) {
    return (
      <Paper component={Stack} alignItems="center">
        <SidebarWidgetError customSelector="companyHighlights" title="title" />
      </Paper>
    )
  }

  if (isLoading) {
    return <HighlightsBoxSkeleton />
  }

  const getTrendIcon = (trend: Trend) => {
    switch (trend) {
      case TREND_UP:
        return <GreenTrendArrow />
      case TREND_DOWN:
        return <RedTrendArrow />
      case TREND_NONE:
        return <RemoveIcon />
      default:
        return <RemoveIcon />
    }
  }

  return (
    <Paper elevation={2} component={Box}>
      <Stack sx={titleBorder} p={4} direction="row">
        <Typography variant="h4">
          <FormattedMessage
            id={intl.companyHighlights('title-with-currency')}
            values={{ currency: selectedCurrency.currency }}
          />
        </Typography>
      </Stack>
      <Box px={4} py={5}>
        {financialHighlights.map(({ value, key, intl, trend }, i) => (
          <Stack key={key as unknown as string} sx={{ ...columnContainer(i) }}>
            <Typography variant="subtitle2" sx={leftColumn}>
              <FormattedMessage id={intl} />
            </Typography>
            <Stack sx={rightColumn}>
              <Typography variant="subtitle2">
                {value ? reactIntl.formatNumber(Math.floor(value as number)) : '-'}
              </Typography>
              {getTrendIcon(trend)}
            </Stack>
          </Stack>
        ))}
      </Box>
    </Paper>
  )
}

export default HighlightsBox
