import { MenuItem, Select as MUISelect } from '@mui/material'

type Value = {
  value: string | number
  label: string
}

type SelectProps = {
  onChange: () => void
  value: Value
  values: Value[]
  noOptionText: string
}

const Select = (props: SelectProps) => {
  const { onChange, value = '', values, noOptionText, ...rest } = props

  return (
    <MUISelect
      variant="standard"
      value={value}
      onChange={onChange}
      displayEmpty
      {...rest}
    >
      {noOptionText ? <MenuItem value="">{noOptionText}</MenuItem> : null}

      {values.map((val, index) => (
        <MenuItem key={index} value={val.value}>
          {val.label}
        </MenuItem>
      ))}
    </MUISelect>
  )
}

export default Select
