import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { authLoginUsingSso } from 'services/api/helpers/authService'
import { useDispatch } from 'react-redux'
import { loginUserSuccess } from 'store_deprecated/auth/actions'
import { CircularProgress } from '@mui/material'
import { useStyles } from '../styles/single-sign-on'

export default function SingleSignOn() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const maybeAccessToken = location.state?.accessToken

  useEffect(() => {
    authLoginUsingSso(maybeAccessToken)
      .then((data) => {
        dispatch(loginUserSuccess(data))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, maybeAccessToken])

  if (loading) {
    return <CircularProgress className={classes.spinner} />
  }

  // This is where we tell the backend about the user that just signed in.
  // If the user doesn't exist, it will create it and connect it to the correct company.
  // Othwerwise, it will just return the user that it represents in our backend.
  return null
}
