import { Paper, Skeleton, Stack, Box, Typography } from '@mui/material'
import TitleMainRecommendation from 'pages/credit-check/scenes/MainRecommendation/elements/TitleMainRecommendation'
import classes from 'pages/credit-check/styles/credit-field'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const RecommendationSkeleton = () => {
  return (
    <Paper elevation={2} component={Stack} m={4} p={4} gap={4}>
      <TitleMainRecommendation labelKey="recommendation" />
      <Stack gap={2}>
        <Box sx={classes.outerFrame('success', true)}>
          <Typography>
            <FormattedMessage id={intl.mainRecommendation('credit-max')} />
          </Typography>
          <Skeleton variant="text" width="100%" height={24} />
        </Box>
        <Box sx={classes.outerFrame('success', true)}>
          <Typography>
            <FormattedMessage id={intl.mainRecommendation('credit-days')} />
          </Typography>
          <Skeleton variant="text" width="100%" height={24} />
        </Box>
      </Stack>
    </Paper>
  )
}

export default RecommendationSkeleton
