import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const header: SxProps = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0.5rem',
  position: 'relative',
}
const title: SxProps = {
  fontWeight: theme.typography.fontWeightBold,
}
const icon: SxProps = {
  lineHeight: 0,
  marginRight: 1,
}
const iconContainer: SxProps = {
  position: 'absolute',
  left: '-24px',
  top: '40%',
}
const description: SxProps = {
  margin: '1rem',
}

export const classes = {
  header,
  title,
  icon,
  iconContainer,
  description,
}
