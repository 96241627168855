import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

export const classHalfCircleText: SxProps = {
  position: 'absolute',
  top: '65%',
  left: '50%',
  width: '200px',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  lineHeight: theme.spacing(0),
}

export const classRadialValue: SxProps = {
  fontSize: '6rem',
  fontWeight: 'bold',
  lineHeight: theme.spacing(10),
}
