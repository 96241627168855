type InputObject = {
  key: string
  visible: boolean
}

type OutputObject = {
  [key: string]: boolean
}

export const formatVisibilityData = (input: InputObject[]): OutputObject => {
  const output: OutputObject = {}

  input?.forEach((item) => {
    output[item.key] = item.visible
  })

  return output
}
