import React from 'react'
import RisikaScoreWrapper from './RisikaScoreWrapper'
import Statstidende from './components/Statstidende'
import { GazzeteReportType } from 'types/general'
import { Stack } from '@mui/material'
import { DECREE } from 'configs/constants/system'
import StatusWarning from './components/StatusWarning'
import { RiskInfoType, StatusInfoType } from './components/D3RisikaScore.types'
import { useIsCompanyActive } from 'services/hooks'
import { LocalId } from 'globalTypes'

type Props = {
  statusInfo: StatusInfoType['statusInfo']
  riskInfo: RiskInfoType
  report: GazzeteReportType | null
  localId?: LocalId
}
const RisikaScore = ({ riskInfo, statusInfo, report = null, localId }: Props) => {
  const [isRendering, setIsRendering] = React.useState(false)
  const isCompanyActive = useIsCompanyActive(localId)

  React.useEffect(() => {
    if (!isRendering) {
      setIsRendering(true)
    }
  }, [isRendering])

  if (!isRendering) return null

  return (
    <Stack>
      {/*  if the report type is DECREE/BANKRUPT we are displaying statstidende/Gazette with the report */}
      {report?.type === DECREE ? (
        <Statstidende report={report} statusInfo={statusInfo} />
      ) : !isCompanyActive ? (
        /* if the report type is NOT DECREE and statusInfo.code is !== ACTIVE we are displaying statusWarning with statusInfo */
        <StatusWarning statusInfo={statusInfo} />
      ) : (
        /* if the company works normally we are showing risika score  */

        <RisikaScoreWrapper
          risikaScore={riskInfo.risikaScore}
          riskAssessmentCode={riskInfo.riskAssessmentCode}
          setIsRendering={setIsRendering}
        />
      )}
    </Stack>
  )
}

export default RisikaScore
