import DefaultCell from './DefaultCell'

const DefaultHeaderCell = ({ text, sx, ...props }) => {
  const root = {
    whiteSpace: 'nowrap',
  }

  return <DefaultCell cell={{ value: text }} sx={{ ...root, sx }} {...props} />
}

export default DefaultHeaderCell
