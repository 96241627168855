import React, { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'

type PropTypes = {
  mainContainerRef: React.RefObject<HTMLElement>
  buttonsContainerRef: React.RefObject<HTMLElement>
  extraRefs: React.RefObject<HTMLElement>[]
  children?: React.ReactElement[] | React.ReactNode[]
}

const useShowButtons = ({
  mainContainerRef,
  buttonsContainerRef,
  extraRefs,
  children,
}: PropTypes) => {
  const [shownButtons, setShownButtons] = useState(0)
  const { width } = useWindowSize()
  const [windowWidth, setWindowWidth] = React.useState(0)
  useEffect(() => {
    if (width !== windowWidth) {
      setWindowWidth(width)
    }

    if (!mainContainerRef || !buttonsContainerRef || !children) {
      return
    }

    const buttonsWidth = buttonsContainerRef?.current?.clientWidth
    const wholeWidth = mainContainerRef?.current?.clientWidth
    let extraWidth = 0

    if (extraRefs) {
      extraRefs?.forEach((ref) => {
        extraWidth += ref.current?.clientWidth || 0
      })
    }
    const nextButtonWidth = children?.length
      ? // @ts-ignore
        children[shownButtons]?.ref?.current?.clientWidth ?? 350
      : 0

    if (wholeWidth && buttonsWidth) {
      if (shownButtons < children?.length) {
        if (wholeWidth - (buttonsWidth + extraWidth) > nextButtonWidth + 50) {
          setShownButtons(shownButtons + 1)
        }
      }

      if (shownButtons > 0) {
        if (wholeWidth - (buttonsWidth + extraWidth) < 30) {
          setShownButtons(shownButtons - 1)
        }
      }
    }
  }, [
    buttonsContainerRef,
    children,
    extraRefs,
    mainContainerRef,
    shownButtons,
    width,
    windowWidth,
  ])
  return shownButtons
}

export default useShowButtons
