import { DOMAIN as _ } from 'configs/constants/system'

/**************
 * Saga calls *
 **************/
export const SUBMIT_FETCH_POLICY_VALUES = `${_} SUBMIT_FETCH_POLICY_VALUES`

/****************
 * Action calls *
 ****************/

export const SET_TAB_SELECTED = `${_} SET_TAB_SELECTED`
export const SET_POLICY_VALUES_SUCCESS = `${_} SET_POLICY_VALUES_SUCCESS`
export const SET_POLICY_VALUES_FAILED = `${_} SET_POLICY_VALUES_FAILED`
export const UPDATE_POLICY_VALUES = `${_} UPDATE_POLICY_VALUES`
export const UPDATE_SINGLE_POLICY_VALUES = `${_} UPDATE_SINGLE_POLICY_VALUES`
export const SET_STATUS = `${_} SET_STATUS`
export const SET_IS_POLICY = `${_} SET_IS_POLICY`
