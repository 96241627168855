import { useQuery } from 'react-query'
import { getCustomerData } from 'services/api'
import { useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import intl from 'localization/components'

export function useCustomerData() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const reactIntl = useIntl()
  const messages = reactIntl.messages

  return useQuery(['customerData'], () => getCustomerData(), {
    // Keep the query in memory for 11 minutes
    cacheTime: 11 * 60 * 1000,
    // Refetches on 10 min
    refetchInterval: 10 * 60 * 1000,
    // refetchInterval: 10 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      if (
        res?.is_revoked ||
        (res?.expires_at && new Date(res.expires_at).getTime() < new Date().getTime())
      ) {
        enqueueSnackbar(messages[intl.navigation('revoked-warning')] as string, {
          variant: 'error',
        })
        setTimeout(() => {
          return history.push(navigation.auth.logout())
        }, 10 * 1000)
      }
    },
    onError: (err) => {
      console.error('useCustomerData() throws:')
      console.error(err)
    },
  })
}
