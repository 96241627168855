import { useState, useEffect, FormEvent } from 'react'
import { Stack, Typography, TypographyVariant } from '@mui/material'
import { Link, useParams, useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { ButtonPrimary, InputField } from 'components-new'
import { updatePassword } from 'services/api'
import { useSnackbar } from 'notistack'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const ChangePassword = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { token } = useParams<{ token: string }>()

  const [status, setStatus] = useState('IDLE')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  const errorMessage = passwordError ? (
    <FormattedMessage id={intl.auth('passwords-do-not-match')} />
  ) : undefined

  const handleUpdatePassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (password !== confirmPassword) {
      setPasswordError(true)
      return
    }
    updatePassword({
      password,
      confirmPassword,
      token,
    })
      .then(() => {
        setStatus('SUCCESS')
      })
      .catch(() => {
        setStatus('FAILED')
      })
      .finally(() => {
        setStatus('IDLE')
      })
  }

  useEffect(() => {
    if (status === 'SUCCESS') {
      enqueueSnackbar(<FormattedMessage id={intl.auth('change-password-success')} />)
      history.push(navigation.auth.login())
    }

    if (status === 'FAILED') {
      enqueueSnackbar(<FormattedMessage id={intl.auth('change-password-failed')} />)
    }
  }, [status, enqueueSnackbar, history])

  const handleSetPassword = (value: string) => {
    setPassword(value)
    setPasswordError(false)
  }

  const handleSetConfirmPassword = (value: string) => {
    setConfirmPassword(value)
    setPasswordError(false)
  }

  return (
    <Stack py={7} px={6} width={400} gap={5} borderRadius={1} bgcolor="common.white">
      <Typography variant="h5">
        <FormattedMessage id={intl.auth('change-password-title')} />
      </Typography>
      <Stack gap={5} component="form" onSubmit={handleUpdatePassword}>
        <InputField
          id="password"
          value={password}
          handleChange={handleSetPassword}
          type="password"
          label={<FormattedMessage id={intl.auth('new-password-label')} />}
          error={passwordError}
          errorMessage={errorMessage}
        />
        <InputField
          id="password-confirm"
          value={confirmPassword}
          handleChange={handleSetConfirmPassword}
          type="password"
          label={<FormattedMessage id={intl.auth('confirm-new-password-label')} />}
          error={passwordError}
          errorMessage={errorMessage}
        />
        <ButtonPrimary type="submit">
          <FormattedMessage id={intl.auth('save-password')} />
        </ButtonPrimary>
        <Typography
          component={Link}
          to={navigation.auth.login()}
          variant={'link1' as TypographyVariant}
        >
          <FormattedMessage id={intl.auth('back-to-sign-in')} />
        </Typography>
      </Stack>
    </Stack>
  )
}

export default ChangePassword
