import { SxProps } from '@mui/system'

const card: SxProps = {
  padding: 4,
  backgroundColor: '#FFFFFF',
  boxShadow: 1,
  borderRadius: '6px',
  position: 'relative',
}

const textInactive: SxProps = {
  color: 'grey.600',
}

const containerLeft: SxProps = {
  gap: 3,
}
const containerRight: SxProps = {
  gap: 1,
  alignSelf: 'flex-start',
}

const PolicyCardStyles = {
  card,
  textInactive,
  containerLeft,
  containerRight,
}

export default PolicyCardStyles
