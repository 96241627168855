const content = {
  mt: '1rem',
}

const rowActions = (isHovered: boolean) => {
  return {
    position: 'absolute',
    right: 0,
    marginRight: '2rem',
    padding: 0,
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    visibility: isHovered ? 'visible' : 'hidden',
  }
}

export const classes = {
  content,
  rowActions,
}
