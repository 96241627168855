import { Box } from '@mui/material'
import { ReactNode } from 'react'

type ContainerProps = {
  children: ReactNode
  spacing?: number
  display?: string
  density?: string
  position?: string
}

function Container({
  children,
  spacing = 4,
  display = 'flex',
  density = 'normal',
  position = 'relative',
  ...props
}: ContainerProps) {
  const spacingX = (() => {
    if (density === 'normal') return spacing
    if (density === 'dense') return spacing / 2
    return 0
  })()

  return (
    <Box {...props} sx={{ position, display, px: spacing, py: spacingX }}>
      {children}
    </Box>
  )
}

export default Container
