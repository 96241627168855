import callBackendAPI from 'services/api/helpers/backendAPI'
import { AvailableCountriesLower } from '../../../../globalTypes'
import { ResponseDataUserSettingsFilters } from './loadFilters'

/******************
 * Fetch from API *
 ******************/

/**
 * Save the user's filter with a name.
 */

export type Filter = {
  value: {
    from: string
    to: string
  }
  filter: string
  type: string
  options: {
    value: string
    operator: string
    input: string
    selected: boolean
  }[]
}

type RequestParams = {
  name: string
  filters: Filter[]
  country: AvailableCountriesLower
  savedFilters: any
}

const saveFilters = ({
  name,
  filters,
  country = 'dk',
  savedFilters,
}: RequestParams): Promise<ResponseDataUserSettingsFilters> =>
  callBackendAPI('/user/settings/filters', {
    method: 'put',
    data: {
      data: [
        ...savedFilters,
        {
          name,
          filters,
          country,
        },
      ],
    },
  })

export default saveFilters
