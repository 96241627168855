import React from 'react'
import { Stack, Box, Typography } from '@mui/material'
import classes from 'pages/credit-check/styles/remark-card'
import { RemarkCardT } from './types'

function RemarkCard({ title, text, url, isBlue = true }: RemarkCardT) {
  return (
    <Stack direction="row" alignItems="center" sx={classes.card}>
      <Box component="img" src={url} sx={classes.icon} />
      <Stack>
        <Typography sx={classes.colorTitle(isBlue)}>{title}</Typography>
        <Typography variant="subtitle1">{text}</Typography>
      </Stack>
    </Stack>
  )
}

export default RemarkCard
