import * as React from 'react'
import { FilePond } from 'react-filepond'
import * as fp from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { isNil } from 'ramda'
import { useDebounce } from 'react-use'
import Papa from 'papaparse'
import xlsx from 'xlsx/dist/xlsx.core.min.js'
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import './filepond.css'
import UploadFileIcon from '@mui/icons-material/UploadFile'

fp.registerPlugin(FilePondPluginFileValidateType)

const styles = {
  container: {
    position: 'relative',
    '.filepond--root': {
      border: '1px dashed',
      borderRadius: '6px',
      backgroundColor: 'grey.50',
      height: 90,
    },
    '.filepond--panel-root': {
      border: 'none !important',
      backgroundColor: '#F8F9FA !important',
    },
    '.filepond--drop-label': {
      backgroundColor: 'transparent',
      top: 24,
      label: {
        padding: 0,
        fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: 0.2,
        lineHeight: '22px',
      },
    },
    '.filepond--label-action': {
      color: 'primary.main',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.2,
      textDecorationLine: 'underline',
      textUnderlineOffset: 5,
      cursor: 'pointer',
      textDecoration: 'underline !important',
    },
    '.filepond--credits': {
      display: 'none',
    },
  },
  icon: {
    fontSize: 29,
    color: 'grey.500',
    position: 'absolute',
    top: 16,
    left: 'calc(50% - 14.5px)',
    zIndex: 1,
  },
}

const acceptedFileTypes = [
  'text/csv', // Mac CSV
  'application/csv', // Mac CSV
  'application/vnd.ms-excel', // Windows CSV
  'text/comma-separated-values, application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

/**
 * Check if the file is a CSV file
 */
const isFileCSV = (file) => file.name.split('.').pop().toLowerCase() === 'csv'

/**
 * Converts an Excel sheet to a CSV file.
 *
 * @param str The binary string to convert
 */
const convertExcelToCSV = (str) => {
  const workbook = xlsx.read(str, { type: 'binary' })
  const sheetName = workbook.SheetNames[0]

  return xlsx.utils.sheet_to_csv(workbook.Sheets[sheetName])
}

const ImportDropzone = ({ onFileLoaded, secondaryText }) => {
  const filePondRef = React.useRef(null)
  const [files, setFiles] = React.useState([])
  const [debouncedFiles, setDebouncedFiles] = React.useState([])

  useDebounce(
    () => {
      setFiles(debouncedFiles)
    },
    300,
    [debouncedFiles]
  )

  const handleSetFiles = ([fileItem]) => {
    if (fileItem?.file) {
      setDebouncedFiles([fileItem.file])
    }
  }

  React.useEffect(() => {
    const [file] = files

    if (!isNil(file) && acceptedFileTypes?.includes(file.type)) {
      const fileReader = new FileReader()
      fileReader.readAsBinaryString(file)

      fileReader.onloadend = () => {
        const { result } = fileReader

        // Guard against data we can't use to stop Flow from complaining;
        // I think neatCSV has wrong typing in this instance.
        if (result == null || result instanceof ArrayBuffer) {
          onFileLoaded(null)
          return
        }

        const csv = isFileCSV(file) ? result : convertExcelToCSV(result)

        const results = Papa.parse(csv)
        onFileLoaded(results.data)
      }
    } else {
      onFileLoaded(null)
    }
  }, [files, onFileLoaded])

  return (
    <Stack spacing={2}>
      <Stack>
        <Stack sx={styles.container}>
          <UploadFileIcon sx={styles.icon} />
          <FormattedMessage id={intl.riskMonitoringNew('drag-drop')}>
            {(label) => (
              <FilePond
                ref={filePondRef}
                files={files}
                allowMultiple={false}
                acceptedFileTypes={acceptedFileTypes}
                onupdatefiles={handleSetFiles}
                labelIdle={label}
              />
            )}
          </FormattedMessage>
        </Stack>
        {!files.length && (
          <Stack direction="row" spacing={5} marginBottom={2} alignItems="center">
            {!!secondaryText && (
              <Typography variant="caption">
                <FormattedMessage id={intl.riskMonitoringNew(secondaryText)} />
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default ImportDropzone
