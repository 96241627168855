import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  content: {
    paddingBottom: '2rem',
  },
  text: { paddingTop: '1rem', paddingBottom: 0 },
  title: {
    paddingBottom: 0,
  },
})
