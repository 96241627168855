import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  addNewList: {
    display: 'flex',
    flexDirection: 'column',
  },
  newListField: {
    display: 'flex',
    margin: '0 1rem 0 1rem',
    alignItems: 'center',
  },
  labelPlacement: {
    margin: '0 1rem 1rem 1rem',
  },
  addRemoveButtons: {
    height: '5rem',
    width: '5rem',
  },
  iconButton: {
    alignSelf: 'flex-end',
    marginRight: '3.3rem',
    backgroundColor: theme.palette.secondary.main,
    marginBottom: '1rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: 'rgb(204, 163, 109)',
    },
  },
}))
