import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  snackbar: {
    fontSize: '1.3rem',
    fontWeight: 500,
  },
  snackbarButton: {
    fontWeight: 700,
    fontSize: '1rem',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    marginRight: '1rem',
    color: 'inherit',
    transition: 'background-color 200ms ease-in-out',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
})
