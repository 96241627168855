import { Chip, Stack } from '@mui/material'
import styles from './styles/hierarchy-box-company'
import { useHierarchySceneContext } from 'pages/company-hierarchy/context/hierarchySceneContext'
import { HierarchyParentBoxProps } from './types'
import OwnersToggle from './scenes/UI/OwnersToggle'
import CardParentFactory from 'pages/company-hierarchy/CardParentFactory'
import { Typography } from '@mui/material/'

const HierarchyParentBox = ({
  props,
  onOpenHierarchyWalkdown,
  onUpdateParents,
  direction,
  selectDepth,
  currentDepth,
}: HierarchyParentBoxProps) => {
  const { nodeDatum, toggleNode, hierarchyPointNode } = props
  const { displayState } = useHierarchySceneContext()

  const shouldUpdateDepth = hierarchyPointNode.depth === currentDepth
  const isCollapsed = nodeDatum?.__rd3t?.collapsed
  const isNodeToggled = !isCollapsed

  const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return 'Android'
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ) {
      return 'iOS'
    }
    return 'Other'
  }

  return (
    <foreignObject
      width="420"
      height="320"
      style={{
        pointerEvents: 'none',
        display: 'flex',
        transform:
          getMobileOS() === 'iOS' ? 'translate(-150px, -140px)' : 'translate(0, -15px)',
        overflow: 'visible',
        padding: '12px 16px',
        cursor: 'grab',
      }}
    >
      <section
        data-cy={`hierarchy-parent-box-${nodeDatum.name}`}
        style={{
          pointerEvents: 'auto',
          position: 'fixed',
          width: '100%',
          transform:
            getMobileOS() === 'iOS'
              ? `scale(${props.treeZoomLevel}) translate(-50%, -50%)`
              : 'translate(-53%, -50%)',
        }}
      >
        <OwnersToggle
          nodeDatum={nodeDatum}
          isNodeToggled={isNodeToggled}
          toggleNode={toggleNode}
          onOpenParents={onUpdateParents}
          direction={direction}
          selectDepth={selectDepth}
          shouldUpdateDepth={shouldUpdateDepth}
        />
        <CardParentFactory
          displayState={displayState}
          nodeDatum={nodeDatum}
          onOpenHierarchyWalkdown={() => {
            onOpenHierarchyWalkdown?.()
          }}
        />

        <Stack>
          {!!nodeDatum?.shares_interval && (
            <Chip
              sx={{
                ...styles.chipSharesInterval,
                marginTop: '20px',
              }}
              label={
                <Typography variant="body2">{nodeDatum?.shares_interval}</Typography>
              }
            />
          )}
        </Stack>
      </section>
    </foreignObject>
  )
}

export default HierarchyParentBox
