import { authRoles } from 'components/auth'
import NotFoundPage from './NotFoundPage'

export const NotFoundConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/:token',
      component: NotFoundPage,
    },
  ],
}
