import { Stack, TextField, TextFieldProps, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateInputProps } from './DateInput.types'
import styles from './DateInput.styles'

const DateInput = ({
  value,
  label,
  onChange,
  dataCy = 'date-input',
  removeBorder = false,
  ...rest
}: DateInputProps) => {
  const renderDateInput = (params: TextFieldProps) => {
    const { inputProps, ...rest } = params
    return (
      <TextField
        contextMenu={undefined}
        fullWidth
        variant="standard"
        sx={{ ...styles.inputStyles(removeBorder) }}
        inputProps={{
          ...inputProps,
          placeholder: 'DD.MM.YYYY',
          'data-cy': dataCy,
        }}
        {...rest}
      />
    )
  }

  const handleChange = (date: any) => {
    onChange(date)
  }

  return (
    <Stack width={1} gap={label ? 1 : 0}>
      <Typography variant="subtitle2">{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={handleChange}
          renderInput={renderDateInput}
          inputFormat={'DD/MM/YYYY'}
          {...rest}
        />
      </LocalizationProvider>
    </Stack>
  )
}

export default DateInput
