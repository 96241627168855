import { Box, useMediaQuery } from '@mui/material'
import { withStyles } from '@mui/styles'
import { default as cx } from 'classnames'
import { Container, Stack } from 'components'
import { styles } from '../styles/header'

export const Header = ({ children, classes, title, inverse = false }) => {
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  return (
    <Container
      density="dense"
      className={cx(inverse ? classes.inverse : classes.default)}
    >
      <Stack justify="space-between" items="center" flex="1" wrap="wrap">
        <Box
          fontWeight="fontWeightLight"
          fontSize={isSmallDevice ? 'h3.fontSize' : 'h2.fontSize'}
          color="inherit"
        >
          {title}
        </Box>
        <div>{children}</div>
      </Stack>
    </Container>
  )
}

export default withStyles(styles, { withTheme: true })(Header)
