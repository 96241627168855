import risikaAPI from 'services/api/helpers/risikaAPI'
import { SubscriptionType } from './subscriptionUpdate'

/******************
 * Fetch from API *
 ******************/

/**
 * Add a subscription with the fields the user wants to hear about.
 */
type ResponseData = {
  data: {}
}

const subscriptionRemove = (type: SubscriptionType, id: number): Promise<ResponseData> =>
  risikaAPI.post(
    `/remove?type=${type}&list_id=${id}`,
    { type, list_id: id },
    {
      microservice: 'subscription',
    }
  )

export default subscriptionRemove
