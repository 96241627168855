import { useSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { updateCreditPolicy } from 'services/api'
import { UpdatePolicyProps } from 'services/api/endpoints/risikaAPI/updateCreditPolicy'
import intl from 'localization/components'
import { useIntl } from 'react-intl'

export function useCreditPolicyUpdate() {
  const { enqueueSnackbar } = useSnackbar()
  const { messages } = useIntl()
  const queryClient = useQueryClient()
  return useMutation<any, unknown, UpdatePolicyProps>(updateCreditPolicy, {
    onMutate: async ({ id, policyData }: UpdatePolicyProps) => {
      await queryClient.cancelQueries(`creditPolicy-${id}`)
      const previousData = queryClient.getQueryData('creditPolicyGetAll')

      // This will possibly trigger re-renders of components that are using this query which is not ideal in all cases
      queryClient.setQueryData(`creditPolicy-${id}`, () => policyData)

      return { previousData }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('creditPolicyGetAll')
    },
    onError: async (err) => {
      // const { response } = err as UpdateCreditPolicyError
      // if (response.data?.errors?.length) {
      //   response?.data?.errors?.forEach((error) => {
      //     actions.addOrUpdateHighlight({ nodeId: error.node_id, type: 'error' })
      //   })
      // }
      return enqueueSnackbar(
        messages[intl.creditPolicyNew('we-where-not-able-to-save-your-policy')] as string,
        {
          variant: 'error',
        }
      )
    },
  })
}
