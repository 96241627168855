import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  ListSubheader,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme) => ({
  dialog: {},
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  subheader: {
    padding: 0,
    fontSize: theme.typography.h6.fontSize,
    color: 'black',
  },
  dialogActions: {
    marginLeft: 0,
    marginRight: 0,
  },
  button: {
    marginLeft: 0,
    marginRight: 0,
  },
  list: {
    // marginTop: '36px',
  },
}))

const SelectRecipient = (props) => {
  const classes = useStyles()
  const { emailValue, setEmailValue, handleShareReport, coworkers } = props
  return (
    <DialogContent className={classes.dialog}>
      <DialogContentText id="alert-dialog-description">
        <FormattedMessage id={intl.creditCheck('share-report-description')} />
      </DialogContentText>
      <div style={{ marginTop: '1rem' }}>
        <TextField
          autoFocus
          margin="dense"
          label={<FormattedMessage id={intl.creditCheck('subheader-placeholder')} />}
          type="email"
          fullWidth
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
        />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.button}
            disabled={!emailValue}
            onClick={() => handleShareReport(emailValue)}
            variant="contained"
            color="primary"
          >
            {<FormattedMessage id={intl.creditCheck('share')} />}
          </Button>
        </DialogActions>
      </div>
      <List
        className={classes.list}
        subheader={
          <ListSubheader className={classes.subheader} disableSticky component="div">
            <FormattedMessage id={intl.creditCheck('share-internal-header')} />
          </ListSubheader>
        }
      >
        {coworkers.map((person) => (
          <ListItemButton
            className={classes.listItem}
            onClick={() => handleShareReport(person.email)}
            key={person.id}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faPaperPlane} />
            </ListItemIcon>
            <ListItemText
              primary={
                person.first_name && person.last_name
                  ? person.first_name + ' ' + person.last_name
                  : person.email
              }
              secondary={person.first_name && person.last_name ? person.email : false}
            />
          </ListItemButton>
        ))}
      </List>
    </DialogContent>
  )
}

export default SelectRecipient
