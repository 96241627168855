import { useMutation, useQueryClient } from 'react-query'
import { customerSettingsFieldUpdate } from 'services/api'
import { useDispatch } from 'react-redux'
import { updateModelSuccess } from 'store_deprecated'

export function useCustomerSettingsFieldUpdate() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(customerSettingsFieldUpdate, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['customerSettingsField'])
      dispatch(updateModelSuccess({ modelObject: res.user_model }))
    },
    onError: (err) => {
      console.log('Error ', err)
    },
  })
}
