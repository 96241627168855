import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { breadcrumbStringLimit } from '../ListCreditScoreByIndustryBox.model'
import { limitString } from 'utils-new/general'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Tooltip } from '@mui/material'
import { classBreadcrumbsIcon, classBreadcrumbsText } from 'styles-new/global/components'

const BreadCrumbs = ({
  breadcrumbs,
  handleBreadcrumb,
}: {
  breadcrumbs: { label: string; level: number; groupCode: string }[]
  handleBreadcrumb: (crumbLevel: number) => void
}) => {
  if (breadcrumbs.length <= 1) return <></>
  return (
    <Breadcrumbs separator="›" sx={{ mb: 2 }}>
      {breadcrumbs.map((crumbItem) => {
        return (
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            key={crumbItem.label}
            onClick={() => handleBreadcrumb(crumbItem.level)}
          >
            {crumbItem.level === 1 ? (
              <MoreHorizIcon sx={classBreadcrumbsIcon} fontSize="large" />
            ) : (
              <Tooltip title={crumbItem.label}>
                <Box sx={classBreadcrumbsText}>
                  {limitString(crumbItem.label, breadcrumbStringLimit)}
                </Box>
              </Tooltip>
            )}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default BreadCrumbs
