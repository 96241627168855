// @flow

import { DOMAIN as _ } from 'configs/constants/system'

/****************
 * Action Types *
 ****************/

export const SET_MAPS_CACHE = `${_} SET_MAPS_CACHE`

/*****************
 * Initial state *
 *****************/

const initialState = {
  maps: {},
}

/*******************
 * Action Creators *
 *******************/

export const setMapsCache = (address, lat, lng) => ({
  type: SET_MAPS_CACHE,
  address,
  lat,
  lng,
})

/************
 * Reducers *
 ************/

export const cache = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_MAPS_CACHE:
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.address]: { lat: action.lat, lng: action.lng },
        },
      }
    default:
      return state
  }
}

export default cache
