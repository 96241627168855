import { useEffect } from 'react'
import { useMonitorShowList, useUserSelf } from 'services/queries'
import { initializeHubSpot } from './HomePage.controller'
import { Box, Stack } from '@mui/material'
import VisitsSummary from './widgets/VisitsSummary'
import VisitsList from './widgets/VisitsList'
import { ErrorBoundary } from 'components'
import { RecentChangesTable } from 'components-new/RecentChangesTable/RecentChangesTable'
import { PortfolioRiskHome } from 'components-new/PortfolioRiskHome/PortfolioRiskHome'
import useWindowDimensions from 'components-new/hooks/userWindowDimensions'
import { homePageSingleColumnWidth } from 'components-new/RecentChangesTable/utils'

const HomePage = () => {
  const { data } = useUserSelf()
  const { data: showListsData, isLoading: isShowListsLoading } = useMonitorShowList()

  // HubSpot survey
  useEffect(() => {
    initializeHubSpot(data)
  }, [data])

  const { width } = useWindowDimensions()

  return (
    // @ts-expect-error
    <ErrorBoundary page="hope-page">
      <Stack py={6} px={7} spacing={5}>
        <VisitsSummary />
        <VisitsList />
        <Box
          display="flex"
          flexDirection={width > homePageSingleColumnWidth ? 'row' : 'column'}
          justifyContent="space-between"
        >
          <RecentChangesTable
            data={showListsData}
            isShowListsLoading={isShowListsLoading}
          />
          <PortfolioRiskHome
            data={showListsData}
            isShowListsLoading={isShowListsLoading}
          />
        </Box>
      </Stack>
    </ErrorBoundary>
  )
}

export default HomePage
