/**
 * Generate the ID for the given element.
 */
export const generateLocaleID = (component: string) => (element: string) => {
  return `${component}.${element}`
}

/**
 * Get's a localized readable month's name (17 August)
 * @param monthNumber month from 0 to 11
 * @returns Month name string
 */
export const getLocalizedMonthFromNumber = (monthNumber: number) => {
  const date = new Date()

  date.setDate(1)
  date.setMonth(monthNumber)

  const locale = navigator.language
  const month = date.toLocaleString(locale, { month: 'long' })

  return month
}

/**
 * Gets the today's date in another year, based off today
 * @param offsetYear Plus or Minus years, relative to today. +1 will return the next year, -1 will return the last year
 * @returns Date object of today's day in next year
 */
export const getTodayDifferentYear = (offsetYear: number) => {
  const today = new Date()
  const nextYear = today.getFullYear() + offsetYear
  const todaysMonth = today.getMonth() + 1
  const todaysMonthFormatted = todaysMonth < 10 ? `0${todaysMonth}` : `${todaysMonth}`
  const todaysDate = today.getDate()
  const todaysDateFormatted = todaysDate < 10 ? `0${todaysDate}` : `${todaysDate}`

  return new Date(`${nextYear}-${todaysMonthFormatted}-${todaysDateFormatted}`)
}

/**
 * Gets the tomorrow date
 * @returns Date object of tomorrow's date
 */
export const getTomorrow = () => {
  const date = new Date()
  const tomorrowDate = date.setDate(date.getDate() + 1)

  return new Date(tomorrowDate)
}

/**
 *  Gets a string of formatted date to send to the backend.
 * It needs to be in the format with dashes, and to have zeroes,
 * if one of the numbers is below 10
 * @param rawDate Date object
 * @returns Formatted date string with zeroes, like '2022-11-01'
 */
export const getFormattedDateForScheduler = (rawDate: Date) => {
  const month = rawDate.getMonth() + 1
  const monthFormatted = month < 10 ? `0${month}` : `${month}`
  const date = rawDate.getDate()
  const dateFormatted = date < 10 ? `0${date}` : `${date}`

  return `${rawDate.getFullYear()}-${monthFormatted}-${dateFormatted}`
}
