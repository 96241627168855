import { PaletteColorOptions } from '@mui/material'
import { theme } from 'styles-new/mui5-transition/theme'
import { CSSProperties } from 'react'

const {
  typography: { body1 },
  palette: { grey, primary, error },
  shape: { borderRadius },
} = theme

export const inputAreaStyles = (
  disabled: boolean,
  isError: boolean,
  isFocused: boolean,
  isHovered: boolean,
  extraStyles?: CSSProperties
): CSSProperties => {
  const backgroundColor = disabled ? grey[100] : 'white'
  const getBorder = () => {
    if (isFocused) {
      return `2px solid ${primary[500 as keyof PaletteColorOptions]}`
    }
    if (isError) {
      return `2px solid ${error.main}`
    }
    if (isHovered) {
      return `1px solid ${grey[400]}`
    }
    return `1px solid ${grey[200]}`
  }

  return {
    padding: '12px 12px 18px 12px',
    width: '100%',
    borderRadius,
    backgroundColor,
    border: getBorder(),
    ...body1,
    ...extraStyles,
  }
}
