import React from 'react'
import { makeStyles } from '@mui/styles'
import { ConditionallyRender, ShowHideAllWrapper, HumanDate } from 'components'
import SimpleEntry from './SimpleEntry'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { formatToUniversalDate } from 'utils/dateUtils'

const useStyles = makeStyles((theme) => ({
  numberOfEmployees: {
    display: 'flex',
  },
  space: {
    marginLeft: '0.4rem',
  },
}))

const NumberOfEmployees = ({
  detailedNumberOfEmployees,
  number_of_employees,
  average_number_of_employees,
}) => {
  const classes = useStyles()

  const [isShowAllEmployees, setIsShowAllEmployees] = React.useState(false)

  return (
    <ConditionallyRender
      condition={!!detailedNumberOfEmployees?.length}
      when={
        <SimpleEntry
          title={<FormattedMessage id={intl.companyInfo('number-of-employees')} />}
          elementType="div"
          classNameTitle={classes.marginTop}
          content={[
            detailedNumberOfEmployees?.map((entry, i) => (
              <ShowHideAllWrapper
                setIsShowAll={setIsShowAllEmployees}
                isShowAll={isShowAllEmployees}
                key={i}
                amountOfShowedItems={3}
                iteration={i}
                dataLength={detailedNumberOfEmployees?.length}
              >
                <div className={classes.numberOfEmployees}>
                  <Typography variant="body2">
                    <FormattedMessage
                      id={intl.companyInfo('number-of-employees-data-display')}
                      values={{
                        date: formatToUniversalDate(entry?.date),
                        employeesInterval:
                          entry?.employees_interval === '1000-999999'
                            ? '1000+'
                            : entry?.employees_interval,
                        employeesNumber: entry?.employees_number,
                        fte: entry?.fte_number,
                      }}
                    />
                  </Typography>
                </div>
              </ShowHideAllWrapper>
            )),
          ]}
        />
      }
      otherwise={
        <>
          <SimpleEntry
            condition={number_of_employees?.interval}
            title={<FormattedMessage id={intl.companyInfo('number-of-employees')} />}
            elementType="div"
            classNameTitle={classes.marginTop}
            content={[
              number_of_employees?.interval === '1000-999999'
                ? '1000+'
                : number_of_employees?.interval,
            ]}
          />
          <ConditionallyRender
            condition={average_number_of_employees?.value}
            when={
              <SimpleEntry
                condition={average_number_of_employees?.value}
                title={
                  <FormattedMessage
                    id={intl.annualReportInformation('averageNumberOfEmployees')}
                  />
                }
                elementType="div"
                classNameTitle={classes.marginTop}
                content={[
                  <>
                    <Typography variant="body2">
                      {average_number_of_employees?.value}
                    </Typography>
                    <Typography className="gray italic">
                      {
                        <HumanDate
                          date={moment(average_number_of_employees?.date)}
                          format="LL"
                        />
                      }
                    </Typography>
                  </>,
                ]}
              />
            }
          />
        </>
      }
    />
  )
}

export default NumberOfEmployees
