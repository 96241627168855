import notesList from '../notesList'
import notesAdd from '../notesAdd'
import mergeNotesRight from './mergeNotesRight'

import chunk from 'utils/chunk'

/**
 * Will overwrite their already existing notes.
 *
 * @param newNotes
 */
export default async function updateNotes_dangerous(newNotes) {
  const CHUNK_SIZE = 500
  const { data: existingNotes } = await notesList()

  const mergedNotes = mergeNotesRight(existingNotes, newNotes)
  const notesToUpdate = mergedNotes.map((note) => note.value)

  if (mergedNotes.length > 0) {
    for (const notes of chunk(CHUNK_SIZE, notesToUpdate)) {
      await notesAdd(notes)
    }
  }

  return mergedNotes
}
