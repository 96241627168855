import { useHistory } from 'react-router-dom'
import { useBuilder } from '../../BuilderContext'
import {
  handleUpdatePolicy,
  thereIsAnotherActivePolicyInThisCountry,
} from '../../PolicyBuilder.controller'
import { useBuilderParams } from '../../PolicyBuilder.hooks'
import { PolicyBuilderParams } from '../../PolicyBuilder.types'
import {
  useCreditPolicyCreate,
  useCreditPolicyGetAll,
  useCreditPolicyUpdate,
} from 'services/queries'
import { navigation } from 'services/navigation'
import { SnackbarMessage, useSnackbar } from 'notistack'
import { UpdateCreditPolicyError } from 'services/api/endpoints/risikaAPI/updateCreditPolicy'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { CreditPolicyResponse } from 'types/queries'
import { deleteCreditPolicy } from 'services/api'
import { useQueryClient } from 'react-query'
import { downloadAs } from 'services/export'

export const useHandleSave = () => {
  const history = useHistory()
  const { state } = useBuilder()
  const params = useBuilderParams() as PolicyBuilderParams
  const { mutate: updatePolicy } = useCreditPolicyUpdate()
  const handleSave = () => {
    handleUpdatePolicy({
      params,
      enabled: false,
      state,
      updateFunction: updatePolicy,
    })
    // TODO: Clean the state of the builder
    history.push(navigation.enterpriseCreditPolicy())
  }
  return { handleSave }
}
type UseHandleSaveAndActivateProps = {
  setShowIncompletePolicyModal: (value: boolean) => void
  setOtherActivePolicyName: (value: string) => void
  setShowActiveDialog: (value: boolean) => void
  policyId: string
}

export const useHandleSaveAndActivate = ({
  setShowIncompletePolicyModal,
  setOtherActivePolicyName,
  setShowActiveDialog,
  policyId,
}: UseHandleSaveAndActivateProps) => {
  const history = useHistory()
  const { messages } = useIntl()
  const { state, actions } = useBuilder()
  const { enqueueSnackbar } = useSnackbar()
  const params = useBuilderParams() as PolicyBuilderParams
  const { mutateAsync: updatePolicyAsync } = useCreditPolicyUpdate()
  const { data: allPolicyData } = useCreditPolicyGetAll()

  const handleSaveAndUpdate = () => {
    if (state.nodes.findIndex((node) => node.type === 'plus') > -1) {
      setShowIncompletePolicyModal(true)
    } else {
      const otherActivePolicy = thereIsAnotherActivePolicyInThisCountry({
        actionedPolicyId: policyId,
        country: params?.country,
        policies: allPolicyData?.policies,
      })
      if (!otherActivePolicy) {
        // ractionseturn useSavePolicy()
        handleUpdatePolicy({
          params,
          enabled: true,
          state,
          updateFunction: updatePolicyAsync,
        })
          .then(() => {
            history.push(navigation.enterpriseCreditPolicy())
          })
          .catch((err) => {
            const { response } = err as UpdateCreditPolicyError
            if (response.data?.errors?.length) {
              response?.data?.errors?.forEach((error) => {
                actions.addOrUpdateHighlight({ nodeId: error.node_id, type: 'error' })
              })
            }
            enqueueSnackbar(
              messages[
                intl.creditPolicyNew('we-where-not-able-to-save-your-policy')
              ] as SnackbarMessage,
              {
                variant: 'error',
              }
            )
          })
      } else {
        setOtherActivePolicyName(otherActivePolicy.name)
        setShowActiveDialog(true)
      }
    }
  }
  return { handleSaveAndUpdate }
}

export const useSavePolicy = () => {
  const history = useHistory()
  const { state, actions } = useBuilder()
  const params = useBuilderParams() as PolicyBuilderParams
  const { enqueueSnackbar } = useSnackbar()
  const { messages } = useIntl()
  const { mutateAsync: updatePolicyAsync } = useCreditPolicyUpdate()
  const savePolicy = () => {
    return handleUpdatePolicy({
      params,
      enabled: true,
      state,
      updateFunction: updatePolicyAsync,
    })
      .then(() => {
        history.push(navigation.enterpriseCreditPolicy())
      })
      .catch((err) => {
        const { response } = err as UpdateCreditPolicyError
        if (response.data?.errors?.length) {
          response?.data?.errors?.forEach((error) => {
            actions.addOrUpdateHighlight({ nodeId: error.node_id, type: 'error' })
          })
        }
        enqueueSnackbar(
          messages[
            intl.creditPolicyNew('we-where-not-able-to-save-your-policy')
          ] as SnackbarMessage,
          {
            variant: 'error',
          }
        )
      })
  }
  return { savePolicy }
}

export const handleTest = async (setIsOpen: (value: boolean) => void) => {
  // setIsTestPolicyPanelOpen(true)
  setIsOpen(true) // const data = await evaluateMonitoringList({
  //   listId: 163,
  //   country: 'DK',
  //   policyId: 1057,
  //   model: 'BOHR',
  // })
}

export const useHandleEdit = (policyData: CreditPolicyResponse) => {
  const history = useHistory()
  const handleEdit = () => {
    if (policyData) {
      const { country, name, enabled, id } = policyData
      history.push(
        navigation.creditPolicyBuilder({
          country,
          name,
          createActiveRule: enabled,
          policyId: id,
        })
      )
    }
  }

  return { handleEdit }
}
export const useHandleDuplicatePolicy = (
  policyData: CreditPolicyResponse | undefined
) => {
  const { mutateAsync: creteCreditPolicy } = useCreditPolicyCreate()
  const { enqueueSnackbar } = useSnackbar()
  const { messages } = useIntl()
  const handleDuplicatePolicy = () => {
    if (!policyData) return false
    const { description, policy, country, enabled } = policyData
    creteCreditPolicy({
      name: `${policyData.name} (${
        messages[intl.riskMonitoringNew('copy-button-text')]
      })`,
      description,
      policy,
      country,
      enabled,
    })
      .then((data) => {
        enqueueSnackbar(
          messages[intl.creditPolicyNew('duplicate-success')] as SnackbarMessage
        )
        return data
      })
      .catch(() => {
        enqueueSnackbar(
          messages[intl.creditPolicyNew('duplicate-error')] as SnackbarMessage
        )
      })
  }
  return { handleDuplicatePolicy }
}

export const useHandleDeletePolicy = (policyId: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const { messages } = useIntl()
  const queryClient = useQueryClient()
  const history = useHistory()

  const handleDeletePolicy = async () => {
    try {
      await deleteCreditPolicy(Number(policyId))
      await queryClient.refetchQueries('creditPolicyGetAll')
      history.push(navigation.enterpriseCreditPolicy())
      enqueueSnackbar(messages[intl.creditPolicyNew('delete-success')] as SnackbarMessage)
    } catch (err) {
      enqueueSnackbar(messages[intl.creditPolicyNew('delete-error')] as SnackbarMessage)
    }
  }
  return { handleDeletePolicy }
}

// const handleEditPolicySettings = () => {
//   setEditPolicyPanelIsOpen(true)
// }

export const useHandleExportPolicy = (policy: CreditPolicyResponse | undefined) => {
  const { enqueueSnackbar } = useSnackbar()
  const { messages } = useIntl()
  const handleExportPolicy = () => {
    if (!policy) {
      return enqueueSnackbar(
        messages[intl.creditPolicyNew('export-error')] as SnackbarMessage
      )
    }
    downloadAs('json')!(policy, policy.name)
    enqueueSnackbar(
      <FormattedMessage
        id={intl.creditPolicyNew('export-success')}
        values={{ policyName: `'${policy.name}'` }}
      />
    )
  }
  return { handleExportPolicy }
}
