import ReactTable from 'react-table'
import { MessageFormatElement, injectIntl } from 'react-intl'
import intl from 'localization/components'
import TablePaginationToolbar from 'components-new/data-display/Table/elements/TablePaginationToolbar'

type PaginatedProps = {
  page: number
  pages: number
  pageSize: number
  onPageChange: (number: number) => void
  onPageSizeChange: (number: number) => void
  pageSizeOptions?: number[]
}

const IntlReactTable = (
  props: Record<
    string,
    {
      messages: Record<string, string> | Record<string, MessageFormatElement[]>
    }
  >
) => {
  const {
    intl: { messages },
  } = props

  const intlProps = {
    previousText: messages[intl.table('previous')],
    nextText: messages[intl.table('next')],
    loadingText: messages[intl.table('loading')],
    noDataText: messages[intl.table('no-rows')],
    pageText: messages[intl.table('page')],
    ofText: messages[intl.table('of')],
    rowsText: messages[intl.table('rows')],
  }

  const handlePagination = (paginationProps: PaginatedProps) => {
    const { page, pages, pageSize, onPageChange, onPageSizeChange, pageSizeOptions } =
      paginationProps
    const normalizedPage = page + 1 // react-table page starts from 0
    return (
      <TablePaginationToolbar
        page={normalizedPage}
        totalPages={pages}
        handlePage={(newPage) => onPageChange(newPage - 1)} // react-table page starts from 0
        perPageLimit={pageSize}
        handlePageLimit={(limit) => onPageSizeChange(limit)}
        pageLimits={pageSizeOptions}
      />
    )
  }

  // @ts-ignore: need to update the way this table is called (CHORE)
  // should now be making use of the useTable hook rather than calling directly
  // may be worth overhauling completely to new table model anyway
  return <ReactTable PaginationComponent={handlePagination} {...props} {...intlProps} />
}

export default injectIntl(IntlReactTable)
