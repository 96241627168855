import intl from 'localization/components'
import { colors } from 'configs'

export const getRatingByAmount = (amount: number, messages: any) => {
  if (amount <= 20) {
    return messages[intl.grading('very-weak')]
  }
  if (amount <= 40) {
    return messages[intl.grading('weak')]
  }
  if (amount <= 60) {
    return messages[intl.grading('average')]
  }
  if (amount <= 80) {
    return messages[intl.grading('strong')]
  }
  if (amount > 80) {
    return messages[intl.grading('very-strong')]
  }
  return messages[intl.grading('not-available')]
}

export const getColorByAmount = (amount: number) => {
  if (amount <= 20) {
    return colors?.rating_1
  }
  if (amount <= 40) {
    return colors?.rating_1
  }
  if (amount <= 60) {
    return colors?.rating_5
  }
  if (amount <= 80) {
    return colors?.rating_7
  }
  if (amount > 80) {
    return colors?.rating_10
  }
  return colors?.rating_10
}
