import { RulesListProps } from './RuleList.types'
import { NavButton } from 'components-new'
import { actionPanelIconsByKeys } from '../../ActionPanelRuleList.model'

const RulesList = ({ rulesList, onSelect, category }: RulesListProps) => {
  return (
    <>
      {rulesList.map((rule) => (
        <NavButton
          key={rule}
          content={rule}
          icon={actionPanelIconsByKeys[category as keyof typeof actionPanelIconsByKeys]}
          onClick={() => onSelect(rule)}
        />
      ))}
    </>
  )
}

export default RulesList
