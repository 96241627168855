import { SxProps } from '@mui/system'

const PerformanceBarChartContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: 150,
  margin: '2rem',
}

export const classes = {
  PerformanceBarChartContainer,
}
