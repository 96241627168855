import { InputField } from 'components-new'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import React from 'react'

const NumberOfEmployeesInput = ({ isAdvanced = true }) => {
  const { policy, setPolicy, isInferiorPlan } = useCountryPolicy()
  const defVal = policy.number_of_employees ?? 0
  const [val, setVal] = React.useState<number>(defVal)

  // this is needed to set the initial value after it's been null
  React.useEffect(() => {
    if (typeof policy.number_of_employees === 'number') {
      setVal(policy.number_of_employees)
    }
  }, [policy.number_of_employees])

  return (
    <InputField
      value={val}
      handleChange={function (value: string): void {
        if (isNaN(Number(value))) return
        setVal(Number(value))
        setPolicy({ ...policy, number_of_employees: Number(value) })
      }}
      disabled={isInferiorPlan && isAdvanced}
    />
  )
}

export default NumberOfEmployeesInput
