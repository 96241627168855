import { useQuery } from 'react-query'
import { companyHistory } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useCompanyHistory(localId: LocalId) {
  return useQuery(
    ['companyHistory', localId.id, localId.country],
    () => companyHistory(localId),
    {
      enabled: !!isCallAllowed('companyHistory', localId.country),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
