import React, { useMemo } from 'react'
import { UserTable } from './widgets'
import { useAccountStatus } from 'services/queries'
import { ErrorPageSimple } from 'components-new'
import { normalizeUsers } from './UserManagement.controller'
import { UserManagementProps } from './Usermanagement.types'

const UserManagement = ({ users, isLoading, isError }: UserManagementProps) => {
  const {
    data,
    isLoading: isAccountStatusLoading,
    isError: isAccountStatusError,
  } = useAccountStatus()
  const normalizedData = useMemo(() => {
    return normalizeUsers(users)
  }, [users])

  if (isError || isAccountStatusError) {
    return <ErrorPageSimple />
  }

  return (
    <UserTable
      data={normalizedData}
      maxUsers={data?.max_users || 0}
      isLoading={isLoading || isAccountStatusLoading}
    />
  )
}

export default UserManagement
