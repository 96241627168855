import { CommunicationModal } from 'components-new'

export type RequestErrorModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

const RequestErrorModal = ({ open, setOpen }: RequestErrorModalProps) => {
  return (
    <CommunicationModal
      open={open}
      setOpen={setOpen}
      title={'Something went wrong'}
      body={'We could send your request, please try again.'}
      mainButton={{
        content: 'Close',
        click: () => setOpen(false),
      }}
    />
  )
}

export default RequestErrorModal
