import { Paper, Skeleton, Stack, Box } from '@mui/material'
import TitleMainRecommendation from 'pages/credit-check/scenes/MainRecommendation/elements/TitleMainRecommendation'
import Highlight from './Highlight'

const CompanyHighlightsSkeleton = () => {
  return (
    <Paper elevation={2} component={Stack} m={4} p={4} gap={5}>
      <TitleMainRecommendation labelKey="company-highlights" />
      {Array.from(Array(2)).map((_, index) => (
        <Highlight
          key={index}
          title={<Skeleton variant="text" width="50%" height={24} />}
          message={
            <Box>
              <Skeleton variant="text" width="100%" height={22} />
              <Skeleton variant="text" width="100%" height={22} />
            </Box>
          }
          classification={'loading'}
          blurred={false}
        />
      ))}
    </Paper>
  )
}

export default CompanyHighlightsSkeleton
