// @flow

import { List, ListItemButton, ListItemText } from '@mui/material'
import { useStyles } from '../styles/field-list'
type FieldListProps = {
  fields: string[]
  onSelectField: (key: string) => any
}

const FieldList = ({ fields, onSelectField }: FieldListProps) => {
  const classes = useStyles()

  return (
    <List className={classes.list}>
      {Object.keys(fields).map((item) => (
        <ListItemButton
          className={classes.listItem}
          onClick={() => onSelectField(item)}
          key={item}
        >
          <ListItemText primary={item} secondary={fields[+item]} />
        </ListItemButton>
      ))}
    </List>
  )
}

export default FieldList
