import matchSorter from 'match-sorter'

export const localIdFilterFn = (rows, id, filterValue) => {
  return rows.filter((row) => {
    return row.values[id].id?.includes(filterValue)
  })
}

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, {
    keys: [(row) => row.values[id]],
  })
}

export const groupSelectFilterFn = (rows, id, filterValue) => {
  const { values } = filterValue

  if (!values.length) {
    return rows
  }

  return rows.filter((row) => values.includes(row.values[id]))
}
