import React from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateCompanyDomainAndPhoneRequest,
  fetchUsers,
  submitShowAllMonitoringLists,
} from 'store_deprecated'

import { Box, Stack, Typography } from '@mui/material'
import { ButtonSecondary, InputField, ButtonLink2 } from 'components-new'
import { Skeleton } from '@mui/material'
import { isURL } from 'services/helpers'
import { handleAddressField } from 'utils'

import { FormattedMessage, FormattedNumber } from 'react-intl'
import intl from 'localization/components'
import { useAccountStatus } from 'services/queries'
import { creditPolicySet } from 'services/api'
import ModelSection from '../ModelSection'

const buttonColor = { backgroundColor: 'primary.dark' }

const fillUpSelectedModel = ({ objectModel, allowedModels, selectedModelOLD }) => {
  let newObject = {}
  if (allowedModels) {
    allowedModels.forEach((item) => {
      const country = item.country
      const availableModels = item.score_models.map((x) => x.model)
      if (objectModel?.[country]) {
        newObject = {
          ...newObject,
          [country]: objectModel[country],
        }
        return
      }
      const inferredModel = availableModels.includes(selectedModelOLD)
        ? selectedModelOLD
        : availableModels[0]
      if (inferredModel) {
        newObject = {
          ...newObject,
          [country]: inferredModel,
        }
      }
    })
    return newObject
  }
  return {}
}

const CompanyInformation = ({ basicData, isLoading }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const accountStatusQuery = useAccountStatus()
  const maxUsers = accountStatusQuery.data?.max_users
  const subscriptionPlan = accountStatusQuery.data?.subscription_plan
  const caps = accountStatusQuery.data?.caps
  const counters = accountStatusQuery.data?.counters
  const rights = accountStatusQuery.data?.rights

  const { company } = useSelector((state) => state.auth)
  const users = useSelector((state) => state.risika.settings?.userManagement?.users)
  const monitoringCount = caps?.monitoring_count
  const monitoringUsed = counters?.monitoring_count

  const companyId = company?.companyId
  const domain = company?.domain
  const [domainValue, setDomainValue] = React.useState(domain)
  const isPolicy = useSelector(
    (state) => state.risika.creditPolicy?.policyForm?.dk?.enabled
  )
  const selectedModelOLD = company?.model ?? 'BOHR'
  const [selectedModelObject, setSelectedModelObject] = React.useState({})

  const [isSomethingChanged, setIsSomethingChanged] = React.useState(false)

  React.useEffect(() => {
    if (!selectedModelObject.DK) {
      setSelectedModelObject(() =>
        fillUpSelectedModel({
          objectModel: company.modelObject,
          allowedModels: rights?.allow_models,
          selectedModelOLD: company?.model ?? 'BOHR',
        })
      )
    }
  }, [company?.model, company.modelObject, rights, selectedModelObject.DK])

  if (!users?.length) {
    dispatch(fetchUsers())
  }

  React.useEffect(() => {
    if (domainValue !== domain || selectedModelOLD !== company.model) {
      setIsSomethingChanged(true)
    } else {
      setIsSomethingChanged(false)
    }
  }, [domainValue, domain, selectedModelOLD, company.model])

  // const isDisabled = () => {
  //   if (user?.roles?.includes('admin') || user?.roles?.includes('staff')) {
  //     return false
  //   }
  //   return true
  // }

  // const handleUpdateModelObject = ({ value, country }) => {
  //   setSelectedModelObject({
  //     ...selectedModelObject,
  //     [country.toUpperCase()]: value,
  //   })
  //   setIsSomethingChanged(true)
  // }

  const handleUpdate = (e) => {
    e.preventDefault()
    const isURLValid = isURL(domainValue)
    // const isPhoneValid = phoneValue.isValid()
    if (isURLValid) {
      creditPolicySet('dk', isPolicy, selectedModelObject?.DK ?? 'BOHR') // This seems broken
      dispatch(
        updateCompanyDomainAndPhoneRequest({
          companyId,
          domain: domainValue,
          model: 'BOHR',
          modelObject: selectedModelObject,
        })
      )
      dispatch(submitShowAllMonitoringLists())
      enqueueSnackbar(
        <FormattedMessage id={intl.settings('company-information-updated-success')} />,
        { variant: 'success' }
      )
    } else {
      if (!isURLValid) {
        enqueueSnackbar(<FormattedMessage id={intl.settings('invalid-phone-url')} />)
        return
      }
      if (!isURLValid) {
        enqueueSnackbar(<FormattedMessage id={intl.settings('invalid-url')} />)
      }
    }
  }
  return (
    <Stack justifyContent="flex-start" flex="1" gap={8}>
      <Stack direction="column" gap={5}>
        <Typography variant="h4">
          <FormattedMessage id={intl.settings('company-information-title')} />
        </Typography>
        <InputField
          name="first"
          label={<FormattedMessage id={intl.settings('domain-label')} />}
          value={domainValue}
          handleChange={(val) => setDomainValue(val)}
        />
        <Box mt={1}>
          <ButtonSecondary
            onClick={handleUpdate}
            disabled={!isSomethingChanged}
            type="submit"
            sx={buttonColor}
          >
            {<FormattedMessage id={intl.settings('update-company')} />}
          </ButtonSecondary>
        </Box>
      </Stack>

      <ModelSection />

      <Stack gap={2}>
        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
          <FormattedMessage id={intl.settings('company-details-title')} />
        </Typography>
        <Stack direction="column" gap={2}>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id={intl.settings('name')} />
            </Typography>
            {!isLoading ? (
              <Typography>{basicData.name || 'N/A'}</Typography>
            ) : (
              <Skeleton width={200} variant="text" animation="wave" />
            )}
          </Box>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id={intl.settings('address')} />
            </Typography>
            {!isLoading ? (
              <Typography>{handleAddressField(basicData.address) || 'N/A'}</Typography>
            ) : (
              <Skeleton width={200} variant="text" animation="wave" />
            )}
          </Box>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id={intl.settings('customerType')} />
            </Typography>
            {subscriptionPlan != null ? (
              <Typography variant="body1">
                {subscriptionPlan === 'FREEMIUM' ? 'FREE' : subscriptionPlan}
              </Typography>
            ) : (
              <Skeleton width={200} variant="text" animation="wave" />
            )}
          </Box>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id={intl.settings('users-used')} />
            </Typography>
            {users != null && maxUsers != null ? (
              <Typography variant="body1">
                <FormattedNumber value={users?.length ?? 0} />/
                <FormattedNumber value={maxUsers} />
              </Typography>
            ) : (
              <Skeleton width={200} variant="text" animation="wave" />
            )}
          </Box>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id={intl.settings('monitoring-used')} />
            </Typography>
            {monitoringCount != null ? (
              <Typography variant="body1">
                <FormattedNumber value={monitoringUsed} />/
                <FormattedNumber value={monitoringCount} />
              </Typography>
            ) : (
              <Skeleton width={200} variant="text" animation="wave" />
            )}
          </Box>
          <Box mb={5}>
            <Typography variant="h5">
              <FormattedMessage id={intl.settings('termsOfService')} />
            </Typography>
            <ButtonLink2
              target="_blank"
              rel="noopener noreferrer"
              href="https://risika.dk/da/conditions"
            >
              https://risika.dk/da/conditions
            </ButtonLink2>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CompanyInformation
