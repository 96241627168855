import { Box, Typography, Stack } from '@mui/material'
import Badge from 'pages/advanced-credit-policy/elements/Badge'

type Props = {
  title: string
  desc?: string
  isAdvanced?: boolean
  isDisabled?: boolean
}

const titleStyle = (isDisabled: boolean) => {
  return {
    color: isDisabled ? 'grey.600' : 'grey.800',
  }
}

const subtitleStyle = {
  marginBottom: 1,
  color: 'grey.600',
}

const FieldTitle = ({ title, desc, isAdvanced = false, isDisabled = false }: Props) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="subtitle2" sx={titleStyle(isDisabled)}>
          {title}
        </Typography>
        {isAdvanced && <Badge />}
      </Stack>
      {desc && (
        <Typography sx={subtitleStyle} variant="body2">
          {desc}
        </Typography>
      )}
    </Box>
  )
}

export default FieldTitle
