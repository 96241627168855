import { List, ListItemText, MenuItem, Skeleton } from '@mui/material'
import styles from './SearchMultiSelect.styles'
import { OptionsType } from './SearchMultiSelect.types'

function SearchMultiSelectLoading() {
  const { loadingMenuItem, listItem } = styles
  const options = [
    {
      value: '1',
      label: 'Option 1',
    },
    {
      value: '2',
      label: 'Option 2',
    },
    {
      value: '3',
      label: 'Option 3',
    },
  ]
  return (
    <List sx={{ maxHeight: 524, overflow: 'scroll', padding: 0 }}>
      <Skeleton>
        <MenuItem sx={loadingMenuItem}>
          <ListItemText sx={listItem} primary={'Select all'} />
        </MenuItem>
      </Skeleton>
      {options.map(({ value, label }: OptionsType) => (
        <Skeleton key={value}>
          <MenuItem sx={loadingMenuItem} key={value} value={value}>
            <ListItemText sx={listItem} primary={label} />
          </MenuItem>
        </Skeleton>
      ))}
    </List>
  )
}

export default SearchMultiSelectLoading
