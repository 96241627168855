import { useState, useRef } from 'react'
import { Stack, Box, Divider } from '@mui/material'
import { useDetailedComponentView } from '../../../../PolicyBuilder.hooks'
import ViewContent from './ViewContent'
import DetailedComponentViewHeader from './widgets/DetailedComponentViewHeader'
import DeleteRuleButton from './widgets/DeleteRuleButton'
import DetailedComponentViewActionButtons from './widgets/DetailedComponentViewActionButtons'

export type SubmitRef = {
  handleSubmit: () => void
}

export type NullRef = {
  handleNull: () => void
}

function DetailedComponentView() {
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false)
  const submitRef = useRef<SubmitRef>({ handleSubmit: () => {} })
  const nullRef = useRef<NullRef>({ handleNull: () => {} })
  const {
    handleCancel,
    handleDelete,
    handleBackButton,
    confirmButtonText,
    showDeleteButton,
  } = useDetailedComponentView()
  const handleSubmit = () => {
    // The order of these functions is very important, please do not swap :)
    submitRef.current?.handleSubmit()
    nullRef.current?.handleNull()
  }

  return (
    <Stack height={1} gap={6} justifyContent="space-between">
      <Box overflow="hidden" height={1}>
        <DetailedComponentViewHeader handleBack={handleBackButton} />
        <Divider />
        <ViewContent
          refs={{ submitRef, nullRef }}
          setIsConfirmEnabled={setIsConfirmEnabled}
        />
      </Box>
      <DeleteRuleButton showDeleteButton={showDeleteButton} handleDelete={handleDelete} />
      <DetailedComponentViewActionButtons
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        confirmEnabled={isConfirmEnabled}
        confirmButtonText={confirmButtonText}
      />
    </Stack>
  )
}

export default DetailedComponentView
