// @flow

import * as React from 'react'
import { useSnackbar } from 'notistack'
import { Link, useLocation } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { navigation } from 'services/navigation'
import { Card, CardHeader, CardContent, Typography } from '@mui/material'

import { Button, Stack } from 'components'
import { resendAccountActivation } from 'services/api'
import { useStyles } from '../styles/account-deactivated'

const AccountDeactivated = () => {
  const classes = useStyles()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [isResendLoading, setIsResendLoading] = React.useState(false)
  const [hasResend, setHasResend] = React.useState(false)

  const { email } = location.state

  const ARTIFICIAL_DELAY = 2000

  const handleResendAccountActivation = () => {
    setIsResendLoading(true)
    setTimeout(() => {
      resendAccountActivation(email)
        .then(() => {
          setHasResend(true)
          enqueueSnackbar(<FormattedMessage id={intl.auth('new-email-sent')} />, {
            variant: 'success',
          })
        })
        .catch(() => {
          enqueueSnackbar(<FormattedMessage id={intl.auth('error-reach-us-on-chat')} />, {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsResendLoading(false)
        })
    }, ARTIFICIAL_DELAY)
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0}>
        <CardHeader
          title={
            <Typography className={classes.title} variant="h5">
              <FormattedMessage id={intl.auth('account-deactivated-title')} />
            </Typography>
          }
          subheader={
            <Typography className={classes.subtitle} variant="body1">
              <FormattedMessage id={intl.auth('account-deactivated-subtitle')} />
            </Typography>
          }
        />
        <CardContent>
          <Stack spacing={3} direction="column">
            <div>
              <Typography variant="body1">
                <FormattedMessage id={intl.auth('email-sent-to')} />:{' '}
                <span className={classes.emailContainer}>{email}</span>
              </Typography>
            </div>
            <Stack spacing={1} direction="column">
              <Typography variant="body1">
                {hasResend ? (
                  <FormattedMessage id={intl.auth('email-should-have-been-resend')} />
                ) : (
                  <FormattedMessage id={intl.auth('do-not-think-email-received')} />
                )}
              </Typography>
              <Button
                loading={isResendLoading}
                disabled={hasResend}
                color="secondary"
                onClick={handleResendAccountActivation}
              >
                <FormattedMessage id={intl.auth('resend-email')} />
              </Button>
            </Stack>
            <Link to={navigation.auth.login()} className={classes.link}>
              <FormattedMessage id={intl.auth('go-back-to-log-in')} />
            </Link>
          </Stack>
        </CardContent>
      </Card>
    </div>
  )
}

export default AccountDeactivated
