import { DK, SE, NO, FI } from 'country-flag-icons/react/3x2'
import { TableColumn } from './Table.types'
const style = {
  height: '1.9rem',
  borderRadius: 2,
}
const flags = {
  DK: <DK data-cy={'policy-card-flag-DK'} style={style} />,
  SE: <SE data-cy={'policy-card-flag-SE'} style={style} />,
  NO: <NO data-cy={'policy-card-flag-NO'} style={style} />,
  FI: <FI data-cy={'policy-card-flag-FI'} style={style} />,
}
export const displayCountryFlag = (country: string) => {
  return flags[country?.toUpperCase() as keyof typeof flags]
}

/**
 * Reorder the header cells in a list of table columns by moving the item at a specified
 * startIndex to a new position defined by the endIndex. This function ensures that the
 * 'checkbox' column remains at the beginning of the list.
 *
 * @param {TableColumn[]} list - Array of table columns to reorder.
 * @param {number} startIndex - The index of the column to be moved.
 * @param {number} endIndex - The index at which the column should be placed.
 *
 * @returns {TableColumn[]} The updated list of table columns with the reordered items.
 */

export const reorderHeaderCells = (
  list: TableColumn[],
  startIndex: number,
  endIndex: number
): TableColumn[] => {
  const result = Array.from(list)
  const checkbox = result.find((col) => col.key === 'checkbox')
  const [removed] = result.splice(startIndex, 1)
  const reordered = result.toSpliced(endIndex, 0, removed)
  const removedCheckbox = reordered.filter((item) => item.key !== 'checkbox')
  return [checkbox, ...removedCheckbox] as TableColumn[]
}
