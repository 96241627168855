import callBackendAPI from 'services/api/helpers/backendAPI'

export type UpdateUserSelfData = {
  name?: string
  phone?: string
  password?: string
  confirmPassword?: string
}

const updateUserSelf = async ({
  name,
  phone,
  password,
  confirmPassword,
}: UpdateUserSelfData) => {
  const { data } = await callBackendAPI(`/user`, {
    method: 'put',
    data: {
      name,
      phone_number: phone,
      password,
      confirm_password: confirmPassword,
    },
  })
  return data
}

export default updateUserSelf
