import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { ErrorPage } from 'components-new'
import { navigation } from 'services/navigation'
import { UseUserSelfResponse } from 'types/queries'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

type PropTypes = {
  userData?: UseUserSelfResponse
  children: React.ReactNode
}

type State = {
  hasError: boolean
}

class CreditPolicyBuilderErrorBoundary extends React.Component<PropTypes, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    if (this.props.userData) {
      Sentry.captureException(error, {
        contexts: {
          tags: {
            customerId: this.props.userData.customer_id,
            userId: this.props.userData.id,
            apiUserId: this.props.userData.api_user_id,
            role: this.props.userData.role,
            email: this.props.userData.email,
            component: 'credit-policy-builder',
            error,
            errorInfo,
          },
        },
      })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          errorMessage={
            <FormattedMessage
              id={intl.creditPolicyNew('something-went-wrong-credit-policy')}
            />
          }
          buttonText={
            <FormattedMessage id={intl.creditPolicyNew('back-to-credit-policy')} />
          }
          redirectLocation={navigation.enterpriseCreditPolicy()}
        />
      )
    }

    return this.props.children as JSX.Element
  }
}

export default CreditPolicyBuilderErrorBoundary
