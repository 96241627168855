import { useQuery } from 'react-query'
import { companyBasics } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useCompanyBasics(localId: LocalId, staleTime = 0) {
  return useQuery(
    ['companyBasics', localId.id, localId.country],
    () => companyBasics(localId),
    {
      staleTime,
      enabled: !!isCallAllowed('companyBasics', localId.country),
      refetchOnWindowFocus: false,
    }
  )
}
