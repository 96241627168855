import { Option } from 'components'
import { Typography, Divider, Box } from '@mui/material'
import { classes } from 'components/styles/selected-fields-content'

const Section = ({
  title,
  selectAllLabel,
  data,
  handleChecked,
  handleSelectSection,
  selectedFields,
  groupSelected,
}) => {
  return (
    <Box sx={classes.wrapper}>
      <Typography variant="subtitle1" sx={classes.sectionTitle}>
        {title}
      </Typography>
      <Option
        checked={groupSelected}
        onChange={handleSelectSection}
        label={selectAllLabel}
      />
      <Divider sx={classes.divider} />
      {data.map((key) => {
        return (
          <Option
            sx={classes.label}
            key={key}
            checked={selectedFields.includes(key)}
            onChange={() => handleChecked(key)}
            name={key}
            label={key}
          />
        )
      })}
    </Box>
  )
}

export default Section
