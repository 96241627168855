// @flow

import { getChildrenDeep, getParentAndDetermineState } from './utils'

export default function nodeReducer(state, action) {
  switch (action.type) {
    case 'SET_SELECT_ALL_CHILDREN': {
      const item = state[action.parentId]
      const childrenDeep = getChildrenDeep(state, item)
      const updatedChildrenDeep = Object.assign(
        {},
        ...childrenDeep.map((child) => ({
          [child.id]: { ...child, state: item.state },
        }))
      )
      return Object.assign({}, state, updatedChildrenDeep)
    }
    case 'SET_SELECT': {
      const item = state[action.id]
      return {
        ...state,
        [item.id]: {
          ...item,
          state: action.state,
        },
      }
    }
    // case 'TOGGLE_EXPAND_ALL': {
    //   return {
    //     ...state.map(x => x.isExpanded),

    //   }
    // }
    // case 'TOGGLE_COLLAPSE_ALL': {
    //   return {
    //     ...state,
    //     [item.id]: {
    //       ...item,
    //       state: action.state,
    //     },
    //   }
    // }
    case 'TOGGLE_SELECT': {
      const item = state[action.id]
      return {
        ...state,
        [item.id]: {
          ...item,
          state: item.state === 'unchecked' ? 'checked' : 'unchecked',
        },
      }
    }
    case 'TOGGLE_EXPAND': {
      const item = state[action.id]
      return {
        ...state,
        [item.id]: {
          ...item,
          isExpanded: !item.isExpanded,
        },
      }
    }
    case 'SET_SHOW_NODES': {
      return Object.assign(
        {},
        ...Object.keys(state).map((key) => {
          const item = state[key]
          return {
            [item.id]: {
              ...item,
              isHidden:
                action.ids.length === Object.keys(state).length
                  ? false
                  : !action.ids.includes(item.id) || item.value === 'ALL',
              // We don't expand if all nodes are shown.
              // This is to collapse the tree when user isn't searching.
              isExpanded:
                action.ids.length === Object.keys(state).length
                  ? false
                  : action.ids.includes(item.id),
            },
          }
        })
      )
    }
    case 'CLEAR_SELECTED': {
      return Object.fromEntries(
        Object.entries(state).map(([key, value]) => [
          key,
          Object.assign({}, value, { state: 'unchecked' }),
        ])
      )
    }
    case 'DELETE_SELECTION': {
      const newState = Object.fromEntries(
        Object.entries(state).map(([key, value]) => [
          key,
          {
            ...value,
            state: value.label === action.payload.label ? 'unchecked' : value.state,
          },
        ])
      )

      getParentAndDetermineState(action.payload.value, newState)

      return newState
    }

    case 'SET_STATE': {
      const newState = Object.fromEntries(
        Object.entries(state).map(([key, value]) => [
          key,
          {
            ...value,
            state: action.payload.labels.includes(value.label) ? 'checked' : 'unchecked',
          },
        ])
      )
      const leafItems = action.payload.values
        ?.map((item) => newState[item])
        .filter((item) => item.isLeaf)
        .map((item) => item.value)

      leafItems?.forEach((item) => (newState[item].state = 'checked'))
      leafItems?.forEach((item) => getParentAndDetermineState(item, newState))
      return newState
    }
    case 'SET_INITIAL_STATE': {
      return action.state
    }
    default:
      return state
  }
}
