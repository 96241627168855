import * as React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Handle, NodeProps, Position } from 'reactflow'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import IndustryTypeContent from './content/IndustryTypeContent'
import { useBuilder } from '../BuilderContext'
import {
  ACTION_TEMPLATE,
  ADJUST_CREDIT_MAX,
  APPROVE_CREDIT,
  APPROVE_CREDIT_MAX,
  APPROVE_WITH_VALUE,
  BOOLEAN,
  COMPANY_SEARCH,
  CONNECTED_BANKRUPTCIES_IN_THE_LAST_10_YEARS,
  CONNECTED_BANKRUPTCIES_IN_THE_LAST_50_YEARS,
  DROPDOWN_MULTI_SELECT,
  EQUITY,
  EQUITY_WITHOUT_DIVIDEND,
  INDUSTRY_TYPE,
  LOCAL_ID_IN_MONITORING_LIST,
  MULTI_SELECT,
  PERSON_SEARCH,
  TOLERANCE_DROPDOWN,
  TOLERANCE_INPUT,
} from '../PolicyBuilder.model'
import {
  ActionPanelStates,
  ActionTemplateData,
  DropdownMultiselectData,
  NodeData,
  ToleranceDropdownData,
  ToleranceInputData,
} from '../PolicyBuilder.types'
import numeral from 'numeral'
import CloseIcon from '@mui/icons-material/Close'
import {
  exceptions,
  findTemplateFromCategory,
  findVariableFromLabel,
} from '../BuilderContext/BuilderContext.controller'
import { contentContainer, mainContainer, titleContainer } from './Node.styles'
import CompanySearchContent from './content/CompanySearchContent'
import PersonSearchContent from './content/PersonSearchContent'
import { Show } from 'components'
import ApproveCreditContent from './content/ApproveCreditContent'
import CompanyListContent from './content/CompanyListContent'
import {
  CompanySearchCategory,
  ConnectedBankruptciesCategory,
  EquityCategory,
  IndustryCodeCategory,
  LocalIdInMonitoringListCategory,
  PersonSearchCategory,
  TemplateType,
} from 'types/queries'
import { typeGuard } from 'utils/general'
import StartHerePaper from '../widgets/StartHerePaper'
import ConnectedBankruptciesContent from './content/ConnectedBankruptciesContent'
import EquityContent from './content/EquityContent'
import { ellipsis } from 'globalStyles'

export const handleUnit = (text: string, template: TemplateType) => {
  const backAppending = ['distance']
  const frontAppending = ['currency']
  const normal = ['number']

  if (text === '0') {
    return 0
  }

  if (normal.includes(template?.template_data?.input_data?.field_type ?? '')) {
    return text
  }
  if (frontAppending.includes(template?.template_data?.input_data?.field_type ?? '')) {
    return `${template?.template_data?.input_data?.unit} ${text}`
  }
  if (backAppending.includes(template?.template_data?.input_data?.field_type ?? '')) {
    return `${text} ${template?.template_data?.input_data?.unit}`
  }
}

const getAPIData = (data: NodeData['apiData']) => {
  if ('category' in data) {
    return data.category
  }
  if ('outcome' in data) {
    return data.outcome
  }
  return ''
}

function Rule(props: NodeProps) {
  const { data } = props

  const { formatMessage } = useIntl()

  const { actions, state, variableIcons } = useBuilder()
  const variable = findVariableFromLabel('label' in data ? data.label : '')
  const apiDataKey = getAPIData(data.apiData)

  if (apiDataKey === '' || !variable) {
    return (
      <StartHerePaper />
      // <Box>
      //   <Typography variant="body2">Rule not built</Typography>
      // </Box>
    )
  }
  const template = variable?.template?.find((template) => {
    return template?.template_data?.api_key === exceptions(apiDataKey)
  })

  const editNode = () => {
    // TODO: Move this to a controller
    actions.actionPanelToggle({ open: true, nodeId: props.id })

    actions.setActionPanelContent(typeGuard('label', data) as ActionPanelStates)
  }
  if (!variable && !template) {
    window.location.reload()
    // TODO: This is absolutely discusting. Please fix this when you have time!!!
    // It seems like the data from props is outdated so you need to find out why and fix it
    return null
  }

  const renderTextContent = (data: NodeData) => {
    // Switch for custom cases
    switch (apiDataKey) {
      case CONNECTED_BANKRUPTCIES_IN_THE_LAST_10_YEARS:
      case CONNECTED_BANKRUPTCIES_IN_THE_LAST_50_YEARS:
        return (
          <ConnectedBankruptciesContent
            apiData={data.apiData as ConnectedBankruptciesCategory}
            template={template}
          />
        )
      case EQUITY:
      case EQUITY_WITHOUT_DIVIDEND:
        return (
          <EquityContent apiData={data.apiData as EquityCategory} template={template} />
        )
      case INDUSTRY_TYPE:
        return <IndustryTypeContent data={data.apiData as IndustryCodeCategory} />
      case COMPANY_SEARCH:
        return <CompanySearchContent data={data.apiData as CompanySearchCategory} />
      case PERSON_SEARCH:
        return <PersonSearchContent data={data.apiData as PersonSearchCategory} />
      case APPROVE_CREDIT:
      case APPROVE_CREDIT_MAX:
      case ADJUST_CREDIT_MAX:
      case APPROVE_WITH_VALUE:
        return <ApproveCreditContent data={data.apiData} />
      case LOCAL_ID_IN_MONITORING_LIST:
        return (
          <CompanyListContent data={data.apiData as LocalIdInMonitoringListCategory} />
        )
    }

    // Everything else
    switch (typeGuard('variant', data.builderData)) {
      case DROPDOWN_MULTI_SELECT:
      case MULTI_SELECT: {
        const { apiData: dropdownData } = data as DropdownMultiselectData
        const localTemplate = findTemplateFromCategory(dropdownData.category)
        if (!localTemplate) return null
        const formattedData = (localTemplate.options ?? [])
          // @ts-ignore // Here we dont have a choice. We need to wait for the backend types
          .filter((option) => dropdownData.include?.includes(option.value))
          .map((option) => option.node_text)
          .join(', ')
        const categoryList = ['risk_assessment']

        return (
          <>
            {categoryList.includes(dropdownData.category) ? (
              <FormattedMessage id={intl.creditPolicyNew(dropdownData.category)} />
            ) : null}{' '}
            <FormattedMessage
              id={intl.creditPolicyNew(DROPDOWN_MULTI_SELECT)}
              values={{
                companyList: formattedData,
              }}
            />
          </>
        )
      }
      case TOLERANCE_INPUT: {
        const { apiData: toleranceData } = data as ToleranceInputData
        if (template) {
          return (
            <FormattedMessage
              id={intl.creditPolicyNew(TOLERANCE_INPUT)}
              values={{
                tolerance: formatMessage({
                  id: intl.creditPolicyNew(toleranceData.tolerance),
                }),
                min: handleUnit(numeral(toleranceData.value_a).format('0,0'), template),
                max: handleUnit(numeral(toleranceData.value_b).format('0,0'), template),
              }}
            />
          )
        }
        return ''
      }
      case TOLERANCE_DROPDOWN: {
        const { apiData: toleranceDropdownData } = data as ToleranceDropdownData
        const categoryList = ['risika_score']

        const toleranceDropdownTemplate = findTemplateFromCategory(
          toleranceDropdownData.category
        )
        if (!toleranceDropdownTemplate) return null
        const valueA = toleranceDropdownTemplate.options?.find(
          (value) => value.value === toleranceDropdownData.value_a
        )
        if (!valueA) return null

        const valueB = toleranceDropdownTemplate.options?.find(
          (value) => value.value === toleranceDropdownData.value_b
        )

        return (
          <>
            {categoryList.includes(toleranceDropdownData.category) ? (
              <FormattedMessage
                id={intl.creditPolicyNew(toleranceDropdownData.category)}
              />
            ) : null}{' '}
            <FormattedMessage
              id={intl.creditPolicyNew(TOLERANCE_INPUT)}
              values={{
                tolerance: formatMessage({
                  id: intl.creditPolicyNew(toleranceDropdownData.tolerance),
                }),
                min: valueA.node_text,
                max: valueB
                  ? valueB?.node_text
                  : numeral(toleranceDropdownData.value_b).format('0,0'),
              }}
            />
          </>
        )
      }
      case BOOLEAN:
        return (
          <FormattedMessage
            id={intl.creditPolicyNew('reject-credit-node-text')}
            values={{ description: 'false' }} // This will be the description of the action template when available
          />
        )

      case ACTION_TEMPLATE: {
        const { apiData: actionData } = data as ActionTemplateData
        return (
          <FormattedMessage
            id={intl.creditPolicyNew('reject-credit-node-text')}
            values={{ description: actionData.notes || 'false' }} // This will be the description of the action template when available
          />
        )
      }
      default:
        return (
          <Box>
            <Typography variant="body2">Rule not built</Typography>
          </Box>
        )
    }
  }

  const getIcon = (data: any, icons: any) => {
    const appproveCreditVariants = [
      'approve_credit_max',
      'adjust_credit_max',
      'approve_with_value',
    ]
    if (data.apiData.outcome && appproveCreditVariants.includes(data.apiData.outcome)) {
      return icons.approve
    }
    return (
      icons[data.apiData.outcome || data.apiData.category] || (
        <CloseIcon color="inherit" fontSize="inherit" />
      )
    )
  }

  const renderData = renderTextContent(data)
  return (
    <Box width={220}>
      <Box
        onClick={editNode}
        sx={() => mainContainer(state.highlightedNodes?.[Number(props.id)]?.type)}
      >
        <Handle type="target" position={Position.Left} />
        <Handle
          style={{ background: 'white', width: '100rem', height: '100rem' }}
          type="source"
          position={Position.Right}
        />
        <>
          <Stack width={1} sx={titleContainer(variable?.data?.type)}>
            {getIcon(data, variableIcons)}
            <Typography variant="subtitle2" color="inherit">
              {data.label}
            </Typography>
          </Stack>
          <Show when={renderData !== null}>
            <Box width={1} sx={contentContainer}>
              <Typography sx={ellipsis()} variant="body2">
                {renderData}
              </Typography>
            </Box>
          </Show>
        </>
      </Box>
    </Box>
  )
}

export default Rule
