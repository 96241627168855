import risikaAPI from 'services/api/helpers/risikaAPI'

const monitorRenameList = async (list_id: number, title: string) => {
  const { data } = await risikaAPI.post(
    `/list/rename`,
    {
      list_id,
      title,
    },
    {
      microservice: 'monitor',
    }
  )
  return data
}

export default monitorRenameList
