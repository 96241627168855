/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-string-refs */
import React from 'react'
import D3PieChart from './D3PieChart'
import './D3Style.css'
import * as d3 from 'd3'

const PieChart = ({ data }) => {
  const chartRef = React.useRef(null)

  React.useEffect(() => {
    const svgEl = d3.select(chartRef.current)
    svgEl.selectAll('*').remove() // Clear svg content before adding new elements
    const chart = new D3PieChart(chartRef.current, data)
    return chart.destroy
  }, [data])
  return (
    <>
      <div ref={chartRef}></div>
      <div id="mainTooltip" className="hidden">
        <p>
          <span id="value"></span>
        </p>
      </div>
    </>
  )
}

export default PieChart
