import { useQuery } from 'react-query'
import { getDetailedNumberOfEmployees } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useDetailedNumberOfEmployees(localId: LocalId, staleTime = 0) {
  return useQuery(
    ['detailedNumberOfEmployees', localId.id, localId.country],
    () => getDetailedNumberOfEmployees(localId.id ?? '', localId.country ?? undefined),
    {
      staleTime,
      enabled: !!isCallAllowed('getDetailedNumberOfEmployees', localId.country!),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
