import { forwardRef, ForwardedRef } from 'react'
import { Checkbox } from '@mui/material'
import * as React from 'react'
import { checkboxStyles } from './IndustryTypeView.styles'

export type IndustryTypeCheckboxProps = {
  checked: boolean
  indeterminate: boolean
}

const IndustryTypeCheckbox = forwardRef(
  (props: IndustryTypeCheckboxProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Checkbox
        ref={ref}
        inputProps={{ className: 'MY-CUSTOM-CHECKBOX-REF' }}
        {...props}
        sx={checkboxStyles}
      />
    )
  }
)

export default IndustryTypeCheckbox
