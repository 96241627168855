import { Tooltip, Box, Typography } from '@mui/material'

type Props = {
  text: string
  sx: Object
}

const CustomTooltip = ({ text, sx = {} }: Props) => {
  return (
    <Tooltip
      title={
        <Typography variant="subtitle2" sx={{ color: 'common.white' }}>
          {text}
        </Typography>
      }
      placement="top"
      sx={{ minWidth: 'max-content', ...sx }}
    >
      <Box component="img" src="/risika/info-icon.svg" />
    </Tooltip>
  )
}

export default CustomTooltip
