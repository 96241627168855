// @flow

export { default as createNode } from './create-node'

import * as React from 'react'
import TreeSelectProvider, { useTreeContext } from './context'
import nodeReducer from './reducer'
import * as action from './actions'
import { getChildren, getParentsDeep } from './utils'
import { defaultComponents } from './default-components'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { Stack } from 'components'
import { Stack as MUI5Stack, Typography, Box, IconButton, Tooltip } from '@mui/material'
import { labelStyles } from 'components-new/elements/IndustryTypeView/IndustryTypeView.styles'

function handleCheck(event, dispatch, nodeId) {
  action.selectNode(dispatch, nodeId)
  action.selectNodeDeep(dispatch, nodeId)
}

function TreeView({ node, spacing }) {
  const { reducer, components } = useTreeContext()
  const [state, dispatch] = reducer
  const reactIntl = useIntl()
  const { Expander, Label } = components

  const styles = {
    item: {
      display: 'flex',
      alignItems: 'center',
    },
    itemContainer: {
      marginLeft: spacing,
    },
  }

  const children = getChildren(state, node)

  if (node.isHidden) {
    return null
  }
  return (
    <div style={styles.itemContainer}>
      <div style={styles.item}>
        <CheckboxWrapper
          value={node.state}
          onClick={(event) => handleCheck(event, dispatch, node.id)}
          node={node}
        />
        <Label style={{ fontFamily: 'Helvetica' }}>{node.label}</Label>
        <Label style={{ fontFamily: 'Helvetica', flexShrink: 0 }}>
          ({reactIntl.formatNumber(node.count)}
          <FontAwesomeIcon style={{ marginLeft: '0.2rem' }} icon={faBuilding} />)
        </Label>
        <Expander
          isExpanded={node.isExpanded}
          disabled={children.length === 0}
          style={!children.length ? { display: 'none' } : {}}
          onClick={() => action.expandNode(dispatch, node.id)}
        />
      </div>
      {node.isExpanded
        ? children.map((child) => (
            <TreeView node={child} key={child.id} spacing={spacing} />
          ))
        : null}
    </div>
  )
}

function TreeViewIndustryTypePolicyBuilder({ node, spacing }) {
  const { reducer, components } = useTreeContext()
  const [state, dispatch] = reducer
  const { Expander } = components

  const children = getChildren(state, node)

  if (node.isHidden) {
    return null
  }
  return (
    <Box>
      <MUI5Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ color: 'grey.800' }}
      >
        <MUI5Stack direction="row" alignItems="center" justifyContent="start">
          <CheckboxWrapper
            value={node.state}
            onClick={(event) => handleCheck(event, dispatch, node.id)}
            node={node}
          />
          <Typography
            sx={labelStyles}
            data-cy={node.label}
            variant={'checkboxSmallLabel'}
          >
            {node.label}
          </Typography>
        </MUI5Stack>
        {node.id !== 'ALL' && (
          <Expander
            data-cy={`expander-${node.label}`}
            isExpanded={node.isExpanded}
            disabled={children.length === 0}
            style={!children.length ? { display: 'none' } : {}}
            onClick={() => action.expandNode(dispatch, node.id)}
          />
        )}
      </MUI5Stack>
      {node.isExpanded
        ? children.map((child) => (
            <TreeViewIndustryTypePolicyBuilder
              node={child}
              key={child.id}
              spacing={spacing}
            />
          ))
        : null}
    </Box>
  )
}

function defaultSearchFn(collection, searchTerm) {
  return collection.filter((x) =>
    x.label.toLowerCase().includes(searchTerm.toLowerCase())
  )
}

/**
 * Create the component given some state (and optionally some custom components).
 */
export function useTreeSelect(state, config) {
  const reducer = React.useReducer(nodeReducer, state)
  const [reducerState, reducerDispatch] = reducer
  if (reducerState === null && state !== null) {
    action.setInitialState(reducerDispatch, state)
  }

  if (config.shouldReInitiateState) {
    action.setInitialState(reducerDispatch, state)
    config.setShouldReInitiateState(false)
  }

  const searchFn = config?.searchFn ?? defaultSearchFn
  const spacing = config?.spacing ?? 24

  const Checkbox = config?.components?.Checkbox ?? defaultComponents.Checkbox
  const Label = config?.components?.Label ?? defaultComponents.Label
  const Expander = config?.components?.Expander ?? defaultComponents.Expander
  const components = {
    Checkbox,
    Label,
    Expander,
  }

  const value = {
    reducer,
    components,
    searchFn,
    spacing,
  }

  function handleSearch(searchTerm) {
    const [state, dispatch] = reducer
    const matchedNodes = searchFn(
      Object.keys(state).map((key) => state[key]),
      searchTerm
    )

    const matchedNodeIds = new Set(matchedNodes.map((node) => node.id))

    matchedNodes.forEach((node) => {
      if (!matchedNodeIds.has(node.parent)) {
        const parents = getParentsDeep(state, node)

        parents.forEach((parent) => {
          matchedNodeIds.add(parent.id)
        })
      }
    })

    action.setNodesToShow(dispatch, [...matchedNodeIds])
  }

  const rootNodes = Object.entries(reducerState ?? {})
    .map(([k, v]) => v)
    .filter((node) => node.isRoot)

  const handleAll = (pair) => {
    const newState = {}
    Object.keys(state).forEach((key) => {
      const node = state[key]
      Object.assign(newState, {
        [key]: {
          ...node,
          ...pair,
        },
      })
    })
    action.setInitialState(reducerDispatch, newState)
  }

  function View() {
    return (
      <TreeSelectProvider value={value}>
        <>
          <Stack spacing={2}>
            <Tooltip
              title={<FormattedMessage id={intl.companyHierarchy('expand-all-d3')} />}
              placement="top"
            >
              <IconButton onClick={() => handleAll({ isExpanded: true })}>
                <FontAwesomeIcon icon={faExpandAlt} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={<FormattedMessage id={intl.companyHierarchy('collapse-all')} />}
              placement="top"
            >
              <IconButton onClick={() => handleAll({ isExpanded: false })}>
                <FontAwesomeIcon icon={faCompressAlt} />
              </IconButton>
            </Tooltip>
          </Stack>
          <div
            style={{
              marginLeft:
                typeof spacing === 'number' ? -spacing : `calc(-1 * ${spacing})`,
            }}
          >
            {rootNodes.map((node) => (
              <TreeView node={node} key={node.id} spacing={spacing} />
            ))}
          </div>
        </>
      </TreeSelectProvider>
    )
  }

  function ViewIndustryTypePolicyBuilder() {
    return (
      <TreeSelectProvider value={value}>
        <>
          <MUI5Stack spacing={4}>
            {rootNodes.map((node) => (
              <TreeViewIndustryTypePolicyBuilder
                node={node}
                key={node.id}
                spacing={spacing}
              />
            ))}
          </MUI5Stack>
        </>
      </TreeSelectProvider>
    )
  }

  const selectedNodes = Object.keys(reducerState ?? {})
    .map((key) => reducerState[key])
    .filter(
      (node) =>
        node.state === 'checked' && !node.isRoot && node.id !== 'ALL' && node.title
    )

  // Removes single selection.
  // Use it only if you render selected in a different component and want to delete it from there.
  const removeSelection = (label, value) => {
    action.deleteSelection(reducerDispatch, { label, value })
  }

  const setState = (labelsArray, valuesArray) => {
    action.setState(reducerDispatch, { labels: labelsArray, values: valuesArray })
  }

  const selectedValues = selectedNodes.map((node) => node.value)
  const selectedLeafValues = selectedNodes
    .filter((node) => node.isLeaf)
    .map((node) => node.value)
  const selectedTextValues = selectedNodes
    .filter((node) => node.isLeaf)
    .map((node) => node.label)

  return {
    View,
    ViewIndustryTypePolicyBuilder,
    search: handleSearch,
    reset: () => action.clearSelected(reducerDispatch),
    selectedValues,
    selectedLeafValues,
    selectedTextValues,
    removeSelection,
    setInitialState: (newState) => action.setInitialState(reducerState, newState),
    setState,
  }
}

/**
 * Allows you to control the checked, unchecked, and indeterminate
 * from the `value` prop. It also reacts on the state around it,
 * e.g. go into indeterminate if the child it "indeterminate" or
 * go into "checked" if all its children are "checked".
 */
function CheckboxWrapper({ value, onClick, ...props }) {
  const { reducer, components } = useTreeContext()
  const [state, dispatch] = reducer
  const Checkbox = components.Checkbox

  const ref = React.useRef()

  React.useEffect(() => {
    switch (value) {
      case 'checked':
        ref.current.indeterminate = false
        ref.current.checked = true
        break
      case 'unchecked':
        ref.current.indeterminate = false
        ref.current.checked = false
        break
      case 'indeterminate':
        ref.current.indeterminate = true
        break
      default:
        throw new Error(`'${value}' is not a valid value.`)
    }
  })

  React.useEffect(() => {
    const children = getChildren(state, props.node)

    if (children.length === 0) {
      return
    }

    const hasIndeterminateChild =
      children.find((child) => child.state === 'indeterminate') != null
    let childStates = 0
    let newState = null

    children.forEach((child) => {
      if (child.state === 'checked') {
        childStates += 1
      }
    })

    if (hasIndeterminateChild) {
      newState = 'indeterminate'
    } else if (childStates > 0 && childStates < children.length) {
      newState = 'indeterminate'
    } else if (childStates === 0) {
      newState = 'unchecked'
    } else {
      newState = 'checked'
    }

    if (newState !== props.node.state) {
      action.setSelectNode(dispatch, props.node.id, newState)
    }
  })

  return (
    <Checkbox
      ref={ref}
      onClick={onClick}
      indeterminate={value === 'indeterminate'}
      checked={value === 'checked'}
    />
  )
}
