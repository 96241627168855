import { DataType, groupScoresByGroupCode } from 'utils-new/group-scores-by-group_code'
import {
  FittedSBCConvertedItemType,
  FittedSBCConvertedValueType,
} from './PortfolioRiskHistory.types'

import moment from 'moment'
import { Monitor } from 'risika-api-response-types'

export const convertToStackedBarChartData = (
  data: Monitor.ListStatisticsTimeline,
  userLanguage: string
) => {
  const highScores = groupScoresByGroupCode(data as DataType[], 'HIGH').slice(-6)
  const mediumScores = groupScoresByGroupCode(data as DataType[], 'MEDIUM').slice(-6)
  const lowScores = groupScoresByGroupCode(data as DataType[], 'LOW').slice(-6)
  return lowScores.reduce(
    (
      accumulator: FittedSBCConvertedValueType[],
      item: FittedSBCConvertedItemType,
      index: number
    ) => {
      if (highScores[index].value || mediumScores[index].value || item.value) {
        const sum = item.value + mediumScores[index].value + highScores[index].value
        accumulator.push({
          name: moment(item.date).locale(userLanguage).format('MMM, YY'),
          High: Number(((highScores[index].value / sum) * 100).toFixed(0)),
          Medium: Number(((mediumScores[index].value / sum) * 100).toFixed(0)),
          Low: Number(((item.value / sum) * 100).toFixed(0)),
        })
      }
      return accumulator
    },
    []
  )
}
