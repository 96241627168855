import * as React from 'react'
import { Stack, Skeleton } from '@mui/material'
import { CompanyType as CompanyTypes } from 'types/general'
import { useCompanyTypeList } from 'services/queries'
import { CompanyTypeState } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/DetailedComponentView/Actions/Actions.types'
import { SELECT_ALL } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/DetailedComponentView/Actions/Actions.model'
import { convertCompanyTypeStateToApiData } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/DetailedComponentView/Actions/Actions.controller'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { SearchMultiSelectOld } from 'components-new'

type CompanyType = { code: string; title: string }

const fromRawToFormat = (
  excludeCompanies: string[],
  companyTypeList: CompanyType[] | undefined
): CompanyTypeState => {
  if (!companyTypeList) return []

  return excludeCompanies.map((code) => {
    const companyType = companyTypeList?.find((item) => item.code === code)
    if (!companyType) {
      throw new Error(`ERROR Company code ${code} not found`)
    }
    return { value: companyType.code, label: companyType.title }
  })
}

const convertFromCompanyType = (
  selectedCompanyTypes: CompanyTypeState | [],
  companyTypeList: CompanyType[] | undefined
): string[] | null => {
  if (!companyTypeList) return null
  if (!selectedCompanyTypes || selectedCompanyTypes.length === 0) return null

  selectedCompanyTypes = selectedCompanyTypes.filter((item) => item.value !== SELECT_ALL)
  return selectedCompanyTypes.map((selectedType) => {
    const companyType = companyTypeList.find((item) => {
      return item.code === selectedType.value
    })
    if (!companyType) throw new Error('Company type not found')
    return companyType.code
  })
}

const CompanyTypeInput = () => {
  const reactIntl = useIntl()

  const { selectedCountry, policy, setPolicy } = useCountryPolicy()
  const { data: dataExternalOptions, isFetching: isFetchingExternalOptions } =
    useCompanyTypeList(selectedCountry)
  const excludeCodes = policy?.exclude_company_types ?? []
  const [searchText, setSearchText] = React.useState('')
  const [companyTypeList, setCompanyTypeList] = React.useState<CompanyTypeState>(
    fromRawToFormat(excludeCodes, dataExternalOptions)
  )

  React.useEffect(() => {
    if (dataExternalOptions && !isFetchingExternalOptions) {
      const newExcludeCodes = fromRawToFormat(excludeCodes, dataExternalOptions)
      setCompanyTypeList(newExcludeCodes)
    }
  }, [policy, dataExternalOptions])

  React.useEffect(() => {
    const newExcludeCompanyTypes = convertFromCompanyType(
      companyTypeList,
      dataExternalOptions
    )
    if (
      JSON.stringify(newExcludeCompanyTypes?.sort()) !==
      JSON.stringify(policy.exclude_company_types?.sort())
    ) {
      setPolicy({
        ...policy,
        exclude_company_types: newExcludeCompanyTypes,
      })
    }
  }, [companyTypeList])

  convertCompanyTypeStateToApiData(
    companyTypeList.filter((item) => item.value !== SELECT_ALL)
  )

  if (isFetchingExternalOptions) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
        <Skeleton
          variant="rounded"
          width="50%"
          height={30}
          animation="wave"
          sx={{ borderRadius: '2rem' }}
        />
        <Skeleton variant="rounded" width="25%" height={30} animation="wave" />
      </Stack>
    )
  }

  const handleConvertData = (data: CompanyTypes[]) => {
    if (data?.length === companyTypeList?.length) {
      setCompanyTypeList((prevState) => [
        {
          value: SELECT_ALL,
          label: reactIntl.formatMessage({
            id: intl.advancedSearch('select-all'),
          }),
        },
        ...prevState,
      ])
    }
    const mapped = data
      .map((item) => ({ value: item.code, label: item.title }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
    return [
      {
        value: SELECT_ALL,
        label: reactIntl.formatMessage({
          id: intl.advancedSearch('select-all'),
        }),
      },
      ...mapped,
    ]
  }

  return (
    <Stack data-cy="company-type-multi-select">
      {!isFetchingExternalOptions && (
        <SearchMultiSelectOld
          searchText={searchText}
          setSearchText={setSearchText}
          options={handleConvertData(dataExternalOptions ?? [])}
          selectedOptions={companyTypeList.sort((a, b) => (a.label > b.label ? 1 : -1))}
          setSelectedOptions={setCompanyTypeList}
        />
      )}
    </Stack>
  )
}

export default CompanyTypeInput
