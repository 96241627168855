const borderRight = {
  borderRight: '1px solid',
  borderRightColor: 'grey.200',
}

const columnContainer = (index: number) => ({
  backgroundColor: index % 2 === 0 ? 'common.white' : 'grey.50',
  flexDirection: 'row',
})

const leftColumn = {
  flex: 1,
  padding: 3,
  ...borderRight,
}

const rightColumn = {
  flex: 1,
  padding: 3,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  ...borderRight,
}

export const titleBorder = { borderBottom: '1px solid', borderColor: 'grey.100' }

export default { columnContainer, leftColumn, rightColumn, titleBorder }
