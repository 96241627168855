import { Stack, Typography } from '@mui/material'
import { padding } from './columns.styles'
import { PeopleVisitResult } from 'services/api/endpoints/risikaAPI/peopleVisitsList'

type Props = {
  item: PeopleVisitResult
}

function ActiveCompanies({ item }: Props) {
  return (
    <Stack sx={{ ...padding, minWidth: 200 }}>
      <Typography color="grey.800" variant="body2">
        {item.active_company_relations.length}
      </Typography>
    </Stack>
  )
}

export default ActiveCompanies
