import Filter from './Filter'
import { getFilterType } from '../controller/general'
import { isNil } from 'ramda'
import { ConditionallyRender } from 'components'
import { Stack, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

import { useStyles } from '../styles/filter-list'

const FilterList = ({ filters, onRemove, onChange }) => {
  const classes = useStyles()
  const enhancedFilters = filters
    .map((filter) => {
      const filterType = getFilterType(filter.filter)
      if (isNil(filterType)) {
        return null
      }

      const options = filterType.options.map((option) =>
        option.value === filter.type ? { ...option, selected: true } : { ...option }
      )

      return { ...filter, options }
    })
    .filter((x) => x !== null)

  return (
    <Stack spacing={4}>
      {enhancedFilters.map((filter) => (
        <ConditionallyRender
          key={filter.filter}
          condition={filter.explanation}
          when={
            <>
              <Filter
                onRemove={onRemove}
                onChange={onChange}
                className={classes.filter}
                key={filter.filter}
                filter={filter}
              />
              <Typography variant="body2">
                <FormattedMessage id={intl.advancedFilters(filter.explanation)} />{' '}
              </Typography>
            </>
          }
          otherwise={
            <Filter
              onRemove={onRemove}
              onChange={onChange}
              className={classes.filter}
              key={filter.filter}
              filter={filter}
            />
          }
        />
      ))}
    </Stack>
  )
}

export default FilterList
