import { useQuery } from 'react-query'
import { companyRelations } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useCompanyRelations(localId: LocalId, staleTime = 0) {
  return useQuery(
    ['companyRelations', localId.id, localId.country],
    () => companyRelations(localId),
    {
      staleTime,
      enabled: !!isCallAllowed('companyRelations', localId.country),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
