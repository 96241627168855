import ComplianceHistory from './widgets/ComplianceHistory'
import CreditPolicyCompliance from './widgets/CreditPolicyCompliance'
import CreditPolicyViolation from './widgets/CreditPolicyViolation'

const Compliance = ({
  listId,
  handlePortfolioInsightsFilters,
}: {
  listId: string
  handlePortfolioInsightsFilters: (category: string, key: string | boolean) => void
}) => {
  return (
    <>
      <CreditPolicyCompliance
        listId={listId}
        handlePortfolioInsightsFilters={handlePortfolioInsightsFilters}
      />
      <CreditPolicyViolation
        listId={listId}
        handlePortfolioInsightsFilters={handlePortfolioInsightsFilters}
      />
      <ComplianceHistory listId={listId} />
    </>
  )
}

export default Compliance
