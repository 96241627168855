import { Stack } from '@mui/material'
import { useCompanyBasics } from 'services/queries'
import { useRatingScores } from 'services/queries'
import { useStatstidende } from 'services/queries'
import Recommendation from 'pages/credit-check/scenes/MainRecommendation/components/Recommendation/Recommendation'
import RiskEstimation from 'pages/credit-check/scenes/MainRecommendation/components/RiskEstimation/D3RisikaScore/components/RiskEstimation'
import { getLastGazzeteReport } from '../../../utils-new/get-last-gazzete-report'
import { DECREE } from 'configs/constants/system'
import { paperStyles } from './EstimationBox.styles'
import { EstimationBoxProps } from './EstimationBox.types'
import SidebarWidgetError from '../../../pages/credit-check/scenes/MainRecommendation/components/SidebarWidgetError'
import EstimationBoxLoading from './EstimationBox.loading'

const EstimationBox = ({ localId }: EstimationBoxProps) => {
  const companyBasicsQuery = useCompanyBasics(localId, 60000)
  const ratingScoresQuery = useRatingScores(localId, 60000)
  const statstidendeQuery = useStatstidende(localId, 60000)

  const isLoading =
    companyBasicsQuery.isLoading ||
    ratingScoresQuery.isLoading ||
    statstidendeQuery.isLoading

  const isError =
    companyBasicsQuery.isError || ratingScoresQuery.isError || statstidendeQuery.isError

  if (isLoading) {
    return <EstimationBoxLoading />
  }
  if (isError) {
    return (
      <Stack mb={2} mr={2} ml="2px" spacing={2}>
        <SidebarWidgetError
          customSelector="mainRecommendation"
          title="risika-estimation"
        />
      </Stack>
    )
  }

  // ! is used frequently for typescript, because the error and data handling from requests is handled by the BoxContainer component
  return (
    <Stack spacing={2}>
      <RiskEstimation
        localId={localId}
        widgetNameDND="estimation"
        statstidendeData={statstidendeQuery.data!}
        statusInfo={{
          text: companyBasicsQuery.data?.status!,
          code: companyBasicsQuery.data?.status_code!,
          validFrom: companyBasicsQuery.data?.status_valid_from!,
        }}
        riskInfo={{
          riskAssessmentCode:
            ratingScoresQuery.data?.current_score?.risk_assessment_code!,
          risikaScore: ratingScoresQuery.data?.current_score?.score!,
        }}
        isSoleProprietorship={companyBasicsQuery.data?.company_type?.short === 'ENK'}
        paperStyles={paperStyles}
        distress={ratingScoresQuery.data?.current_score?.probability_of_distress!}
        isDragging={false}
        isReArrange={false}
      />
      <Recommendation
        paperStyles={paperStyles}
        isReArrange={false}
        isDragging={false}
        riskAssessmentCode={'LOW'}
        widgetNameDND="none"
        companyType={'A/S'}
        isGazzete={Boolean(
          getLastGazzeteReport(statstidendeQuery.data!)?.type === DECREE
        )}
        localId={localId}
        columnDirection
      />
    </Stack>
  )
}

export default EstimationBox
