import { SxProps } from '@mui/system'

const countryPicker: SxProps = {
  width: 'fit-content',
}

const scrollable: SxProps = {
  width: 'max-content',
}

const scrollableContainer: SxProps = {
  maxWidth: '100%',
  scrollSnapType: 'x mandatory',
  webkitOverflowScrolling: 'touch',
  overflowX: 'scroll',
}

const cover: SxProps = {
  backgroundColor: 'white',
}

const exportBar: SxProps = {
  backgroundColor: 'grey.200',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 6,
  paddingY: 4,
  paddingLeft: 5,
  paddingRight: 6,
}

const exportGeneral: SxProps = {
  alignSelf: 'center',
}

const exportBarText: SxProps = {
  color: 'grey.800',
  marginRight: 2,
}

const noFilters: SxProps = {
  marginTop: '2rem',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '5px',
  border: `1px dashed`,
  borderColor: 'grey.800',
}

const noFiltersText: SxProps = {
  marginTop: '0.7rem',
  fontSize: '1.5rem',
}

const buttonIcon: SxProps = {
  fontSize: '1.5rem',
  marginRight: '0.5rem',
}

export const classes = {
  scrollable,
  scrollableContainer,
  cover,
  exportBar,
  exportGeneral,
  exportBarText,
  noFilters,
  noFiltersText,
  buttonIcon,
  countryPicker,
}
