import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip, IconButton, Stack, Switch, Box, Typography } from '@mui/material'
import { ActionDialog } from 'components'
import SelectUsers from '../../AddNewList/SelectUsers'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useMonitorShowList, useMonitorVisibilityUpdate } from 'services/queries'
import { useSnackbar } from 'notistack'
import useApiIds from 'pages/risk-monitoring/hooks/useApiIds'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { standardIcon } from 'styles-new/global/components'
import { ReduxRootState } from 'store_deprecated/types'

type Props = {
  disabled: boolean
}

const HandleVisibility = ({ disabled }: Props) => {
  const { selectedList } = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring
  )
  const { id } = useSelector((state: ReduxRootState) => state.auth.user.data)

  const { data: usersList } = useMonitorShowList()

  const [selectedUsers, setSelectedUsers] = React.useState<number[]>([])
  const [isPublic, setIsPublic] = React.useState(false)

  const tooltipText = disabled ? 'no-visibility-permissions' : 'handle-visibility-button'

  useEffect(() => {
    if (usersList?.length) {
      const selectedUsersList = usersList.find((list) => list.list_id === selectedList)

      if (!selectedUsersList) return

      if (!selectedUsersList.list_users) {
        setIsPublic(true)
      } else {
        setSelectedUsers(selectedUsersList.list_users)
      }
    }
  }, [selectedList, usersList])

  const { currentUserRisikaId } = useApiIds()

  const {
    mutate: postVisibility,
    error: visibilityError,
    isError: isVisibilityError,
  } = useMonitorVisibilityUpdate()

  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    if (isVisibilityError) {
      enqueueSnackbar(visibilityError.response.data.error, {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, isVisibilityError, visibilityError])

  const visibilityUpdate = (
    selectedList: number,
    selectedUsers: number[],
    isPublic: boolean
  ) => {
    const selectedUniqueUsers = !isPublic
      ? Array.from(new Set([currentUserRisikaId as number, ...selectedUsers]))
      : null
    const data = {
      listId: selectedList,
      users: isPublic ? null : selectedUniqueUsers,
    }
    postVisibility(data)
  }
  return (
    <ActionDialog
      title={<FormattedMessage id={intl.riskMonitoringNew('visibility-header')} />}
      content={() => (
        <>
          <Stack direction="row" alignItems="center">
            <Typography>
              <FormattedMessage id={intl.riskMonitoringNew('new-list-private')} />
            </Typography>
            <Switch checked={isPublic} onChange={() => setIsPublic(!isPublic)} />
            <Typography>
              <FormattedMessage id={intl.riskMonitoringNew('new-list-public')} />
            </Typography>
          </Stack>
          {!isPublic && (
            <SelectUsers
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              ownerIdWebApi={id}
            />
          )}
        </>
      )}
      action={() => visibilityUpdate(selectedList, selectedUsers, isPublic)}
      maxWidth="sm"
      fullWidth={true}
      render={(open: React.MouseEventHandler<HTMLButtonElement>) => (
        <Tooltip
          disableFocusListener={true}
          title={<FormattedMessage id={intl.riskMonitoringNew(tooltipText)} />}
        >
          <Box>
            <IconButton color="primary" disabled={disabled} onClick={open}>
              {!isPublic ? (
                <VisibilityOffIcon sx={standardIcon} data-cy="is-private-icon" />
              ) : (
                <VisibilityIcon sx={standardIcon} data-cy="is-public-icon" />
              )}
            </IconButton>
          </Box>
        </Tooltip>
      )}
      agreeText={<FormattedMessage id={intl.riskMonitoringNew('update')} />}
    />
  )
}

export default HandleVisibility
