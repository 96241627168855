import numeral from 'numeral'
import intl from 'localization/components'
import { FinancialRatioData, PerformanceKey } from './FinancialRatioChartData.types'
import { MessageFormatElement } from 'react-intl'

export const convertFinancialRatioData = (
  data: FinancialRatioData[],
  fieldKey: PerformanceKey,
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
) =>
  data.map((item) => {
    const year = item.period.end.substring(0, 4)
    if (item?.value) {
      let performance

      if (fieldKey === 'debt_to_equity_ratio') {
        if (Number(item.value.toFixed(2)) > 10000) {
          performance = numeral(item.value.toFixed(2)).format('0a')
        } else {
          performance = item.value.toFixed(2)
        }
      } else {
        let percentageText

        if (item.value * 100 > 1000) {
          percentageText = '>1000'
        } else if (item.value * 100 < -1000) {
          percentageText = '<-1000'
        } else {
          percentageText = Math.round(item.value * 100)
        }

        performance = `${percentageText}%`
      }

      return { year, performance }
    } else {
      return { year, performance: messages[intl.grading('not-available')] }
    }
  })
