import { Stack, Typography, Box, Paper } from '@mui/material'
import BlockIcon from '@mui/icons-material/Block'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { classes } from 'components-new/layouts/BoxContainer/styles'

const main = { py: 5, borderTop: '1px solid', borderColor: 'grey.300' }
const icon = { fontSize: 48, color: 'grey.600' }

const ErrorScene = () => {
  return (
    <Paper sx={classes.boxContainer}>
      <Box m={4}>
        <Typography variant="h4">
          {<FormattedMessage id={intl.mainRecommendation('performance')} />}
        </Typography>
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={main}
      >
        <BlockIcon sx={icon} />
        <Typography textAlign="center">
          {<FormattedMessage id={intl.mainRecommendation('performance-no-data')} />}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default ErrorScene
