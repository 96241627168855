// @flow
import React, { useMemo, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Tooltip } from '@mui/material'
import { isNil } from 'ramda'
import { ButtonPrimary, ButtonTertiary } from 'components-new'
import RequestReportButton from 'components/ui/ButtonRequestCreditReport' // DownloadReportButton
import { navigation } from 'services/navigation'
import CustomCreditCheckWrapper from './scenes/CustomCreditCheckWrapper'
import { CreditCheckProvider } from './context'
import GeneralCompanyInformation from './scenes/GeneralCompanyInformation'
import ExportCompanyInfoTo from './scenes/GeneralCompanyInformation/components/ExportCompanyInfoTo'
import ExportFinancialFigures from './scenes/KeyFinancialFigures/ExportFinancialFigures'
import KeyFinancialFigures from './scenes/KeyFinancialFigures'
import SceneWrapper from './scenes/SceneWrapper'
import RelevantFinancialRatios from './scenes/RelevantFinancialRatios'
import CompanyOwners from './scenes/CompanyOwners'
import CompanyRoles from './scenes/CompanyRoles'
import Map from './scenes/Map'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faSave } from '@fortawesome/free-solid-svg-icons'
import useTotangoTracking from 'utils/totangoTracking'
import { styles } from './styles/credit-check-page'
import { useTheme } from '@mui/material/styles'
import { useUserSettingsField } from 'services/queries/useUserSettingsField'
import { isComponentRendered } from 'configs'
import { Mixpanel } from 'services/helpers/mixpanel'
import CompareIcon from '@mui/icons-material/Compare'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import SaveIcon from '@mui/icons-material/Save'
import { useHistory } from 'react-router-dom'
import { useRegisterVisit } from 'services/queries/useRegisterVisit'
import { useIsCompanyActive } from 'services/hooks'
import { DragDropContext } from 'react-beautiful-dnd'
import { dndReorder } from 'utils/dragAndDropReorder'
import { formatVisibilityData } from 'utils/formatVisibilityData'
import { mergeVisibilityData } from 'utils/mergeVisibilityData'
import { EDIT_VIEW_BUTTON, INITIAL_SIDEBAR_DATA } from './CreditCheckPage.model'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import {
  useFinancialPerformance,
  useFinancialRatios,
  useUpdateUserSettingsField,
} from 'services/queries'
import {
  useCompanyBasics,
  useFinancialNumbersConsolidated,
  useAccountStatus,
  useHierarchySummary,
} from 'services/queries'
import {
  HistoryHeader,
  Button,
  AddToMonitoringListButton,
  ConditionallyRender,
  Loader,
  ErrorBoundary,
} from 'components'

const { root } = styles

function CreditCheckPage() {
  const id = useParams()?.id
  const isCompanyActive = useIsCompanyActive()
  const history = useHistory()
  const country = useParams()?.country
  const theme = useTheme()
  const localId = useMemo(() => {
    return { id, country: country?.toUpperCase() ?? 'DK' }
  }, [country, id])
  useRegisterVisit({
    type: 'company',
    data: { id, country: country?.toUpperCase() },
  })
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { messages } = useIntl()

  const onSuccess = () => {
    return enqueueSnackbar(messages[intl.snackbar('settings-saved')], {
      variant: 'success',
      onClick: () => closeSnackbar(),
      preventDuplicate: true,
    })
  }

  const onError = () => {
    return enqueueSnackbar(messages[intl.snackbar('report-error-something-wrong')], {
      variant: 'error',
      onClick: () => closeSnackbar(),
      preventDuplicate: true,
    })
  }

  const hierarchyButtonRef = useRef(null)
  const generateReportButtonRef = useRef(null)
  const addToMonitoringListButtonRef = useRef(null)
  const reArrangeButtonRef = useRef(null)
  const saveButtonRef = useRef(null)

  // Queries
  const accountStatusQuery = useAccountStatus()
  const hierarchySummary = useHierarchySummary(localId)
  const companyBasicsQuery = useCompanyBasics({ id, country })
  const financialNumbersConsolidatedQuery = useFinancialNumbersConsolidated(localId)
  const { mutate: updateCreditCheckFields } = useUpdateUserSettingsField({
    onSuccess,
    onError,
  })
  const { data: creditCheckSidebarFields, isLoading: isCreditCheckSidebarLoading } =
    useUserSettingsField('credit_check_sidebar')
  const { data: creditCheckFields, isLoading: isCreditCheckFieldsLoading } =
    useUserSettingsField('credit_check')
  // Misc
  const financialNumbersConsolidated = financialNumbersConsolidatedQuery?.data
  const financialRatiosQuery = useFinancialRatios(localId)
  const financialPerformanceQuery = useFinancialPerformance(localId)

  const [isReArrange, setIsRearrange] = useState(false)
  const [isElementActive, setIsElementActive] = useState()
  const [isSidebarElementActive, setIsSidebarElementActive] =
    useState(INITIAL_SIDEBAR_DATA)
  const [isConsolidated, setIsConsolidatedParent] = useState(
    !isNil(financialNumbersConsolidated) &&
      financialNumbersConsolidated?.length &&
      ['DK', 'NO'].includes(country?.toUpperCase())
  )
  const [localCreditCheckData, setLocalCreditCheckData] = useState(null)
  const [localSidebarData, setLocalSidebarData] = useState(null)

  useEffect(() => {
    if (creditCheckFields !== undefined) {
      setLocalCreditCheckData(creditCheckFields)
    }
  }, [creditCheckFields])

  useEffect(() => {
    if (creditCheckSidebarFields !== undefined) {
      setLocalSidebarData(creditCheckSidebarFields)
    }
  }, [creditCheckSidebarFields])

  // This and removing accountStatusQuery.isLoading saves 400-500ms in page load times
  const isFreemium = (() => {
    if (accountStatusQuery.isLoading || !accountStatusQuery.data) {
      return true
    }
    return accountStatusQuery.data?.subscription_plan === 'FREEMIUM'
  })()
  const { trackEvent } = useTotangoTracking()

  useEffect(() => {
    setIsElementActive(formatVisibilityData(creditCheckFields))
    // we want this to initialise with the original data only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCheckFields])

  useEffect(() => {
    trackEvent('Credit Check', 'Visit Credit Check Page')
  }, [id, trackEvent])

  // Loading
  if (
    companyBasicsQuery.isLoading ||
    isCreditCheckFieldsLoading ||
    isCreditCheckSidebarLoading
  ) {
    return <Loader redirect={false} />
  }

  // Misc
  const companyChildrenCount = hierarchySummary?.data?.children_count ?? 0
  const companyParentCount = hierarchySummary?.data?.parents_count ?? 0
  const creditCheckKeys = localCreditCheckData
    ? localCreditCheckData?.map((field) => field.key)
    : creditCheckFields?.map((field) => field.key)

  // Handlers

  const handleTriggerFieldReArrange = () => {
    setIsRearrange(true)

    //= ==============
    trackEvent('Credit Check', 'Edit View')
    Mixpanel.track('User clicked Edit View')
    //= ==============
  }

  const handleUpdateFields = () => {
    const creditCheckData = mergeVisibilityData(localCreditCheckData, isElementActive)
    const creditCheckSidebarData = mergeVisibilityData(
      localSidebarData,
      isSidebarElementActive
    )

    updateCreditCheckFields({
      field: 'credit_check',
      data: creditCheckData,
    })
    updateCreditCheckFields({
      field: 'credit_check_sidebar',
      data: creditCheckSidebarData,
    })

    setIsRearrange(false)
  }

  const onDragEnd = (res) => {
    if (!isReArrange) {
      return
    }

    const newCreditCheckFields = dndReorder(
      localCreditCheckData,
      res.source.index,
      res.destination.index
    )

    setLocalCreditCheckData(newCreditCheckFields)
  }

  return (
    <ErrorBoundary page="credit-check" localId={localId}>
      <CreditCheckProvider value={{ localId }}>
        <Box sx={root}>
          <HistoryHeader
            title={companyBasicsQuery.data?.company_name}
            historyKey={id}
            location="creditcheck"
            country={country}
          >
            {isReArrange ? (
              <ButtonPrimary
                key="save-button"
                data-cy="save-button"
                ref={reArrangeButtonRef}
                startIcon={
                  <SaveIcon sx={{ color: 'inherit', fontSize: '16px !important' }} />
                }
                onClick={handleUpdateFields}
              >
                {<FormattedMessage id={intl.confirmAction('save')} />}
              </ButtonPrimary>
            ) : (
              <ButtonTertiary
                key={EDIT_VIEW_BUTTON}
                data-cy={EDIT_VIEW_BUTTON}
                ref={saveButtonRef}
                startIcon={
                  <SettingsIcon sx={{ color: 'inherit', fontSize: '16px !important' }} />
                }
                onClick={handleTriggerFieldReArrange}
              >
                {<FormattedMessage id={intl.mainRecommendation('edit-view')} />}
              </ButtonTertiary>
            )}

            {isCompanyActive && (
              <ButtonTertiary
                key="companyComparisonButton"
                onClick={() =>
                  history.push(
                    isFreemium ? '#' : navigation.companyComparison({ id, country })
                  )
                }
                data-cy="companyComparisonButton"
                startIcon={
                  <CompareIcon
                    fontSize="inherit"
                    sx={{ fontSize: '13px !important', color: 'inherit' }}
                  />
                }
                disabled={isFreemium}
              >
                <FormattedMessage
                  id={intl.companyComparison('add-to-comparison-button')}
                />
              </ButtonTertiary>
            )}

            <ButtonTertiary
              key="hierarchyButton"
              onClick={() =>
                history.push(
                  isFreemium
                    ? '#'
                    : navigation.companyHierarchy({
                        id: companyBasicsQuery?.data?.local_organization_id?.id,
                        country,
                      })
                )
              }
              data-cy="hierarchyButton"
              ref={hierarchyButtonRef}
              loading={hierarchySummary?.isLoading}
              disabled={!(companyChildrenCount || companyParentCount) || isFreemium}
              startIcon={
                <AccountTreeIcon sx={{ color: 'inherit', fontSize: '16px !important' }} />
              }
            >
              <Tooltip
                placement="top"
                title={
                  companyChildrenCount || companyParentCount ? (
                    ''
                  ) : (
                    <FormattedMessage id={intl.creditCheck('no-subsidiaries')} />
                  )
                }
              >
                <FormattedMessage id={intl.companyHierarchy('header')} />
              </Tooltip>
            </ButtonTertiary>
            {isComponentRendered('shareReport', localId?.country) ? (
              <RequestReportButton
                complexComponent={true}
                key="RequestReportButton"
                localId={{ id, country }}
                ref={generateReportButtonRef}
                text={<FormattedMessage id={intl.creditCheck('generate-report')} />}
                companyCVR={id}
                companyName={companyBasicsQuery.data?.company_name}
                disabled={companyBasicsQuery.isLoading || isFreemium}
              />
            ) : null}
            {isComponentRendered('monitorCompany', localId?.country) ? (
              <AddToMonitoringListButton
                complexComponent={true}
                key="AddToMonitoringListButton"
                text={<FormattedMessage id={intl.creditCheck('monitor-company')} />}
                color="contrast"
                ref={addToMonitoringListButtonRef}
                company={localId}
                ready={!companyBasicsQuery.isLoading}
                ButtonComponent={Button}
              />
            ) : null}
          </HistoryHeader>
          <DragDropContext onDragEnd={onDragEnd}>
            <CustomCreditCheckWrapper
              isReArrange={isReArrange}
              isElementActive={isElementActive}
              setIsElementActive={setIsElementActive}
              isSidebarElementActive={isSidebarElementActive}
              setIsSidebarElementActive={setIsSidebarElementActive}
              localSidebarData={localSidebarData}
              setLocalSidebarData={setLocalSidebarData}
              localCreditCheckData={localCreditCheckData}
            >
              {/* General Company Information */}
              <SceneWrapper
                renderId="generalCompanyInformation"
                dataCy="general-company-info-"
                restrictionsId="general-company-info"
                title={<FormattedMessage id={intl.companyInfo('title')} />}
                draggable_id="general-company-information"
                order={1 + creditCheckKeys?.indexOf('general-company-information')}
                dropdownContent={
                  <React.Fragment key="general company information dropdown content">
                    <ExportCompanyInfoTo />
                    <ConditionallyRender
                      condition={isReArrange}
                      when={
                        <Button
                          key="save-button"
                          color="contrast"
                          icon={<FontAwesomeIcon icon={faSave} />}
                          onClick={() => setIsRearrange(false)}
                        >
                          {<FormattedMessage id={intl.confirmAction('save')} />}
                        </Button>
                      }
                      otherwise={
                        <Button
                          data-cy={EDIT_VIEW_BUTTON}
                          key={EDIT_VIEW_BUTTON}
                          icon={<FontAwesomeIcon icon={faCog} />}
                          onClick={() => setIsRearrange(true)}
                        >
                          {<FormattedMessage id={intl.mainRecommendation('edit-view')} />}
                        </Button>
                      }
                    />
                  </React.Fragment>
                }
              >
                <GeneralCompanyInformation isReArrange={isReArrange} />
              </SceneWrapper>
              {/* Key Financial Figures */}

              <SceneWrapper
                renderId="keyFinancialFigures"
                dataCy="key-financial-figures-"
                title={<FormattedMessage id={intl.keyFinancialFigures('title')} />}
                draggable_id="key-financial-figures"
                order={1 + creditCheckKeys?.indexOf('key-financial-figures')}
                dropdownContent={
                  <ExportFinancialFigures isConsolidated={isConsolidated} />
                }
              >
                <KeyFinancialFigures setIsConsolidatedParent={setIsConsolidatedParent} />
              </SceneWrapper>

              {/* Relevant Financial Ratios */}
              <SceneWrapper
                renderId="relevantFinancialRatios"
                dataCy="relevant-financial-ratios-"
                title={<FormattedMessage id={intl.relevantFinancialRatios('title')} />}
                draggable_id="relevant-financial-ratios"
                order={1 + creditCheckKeys?.indexOf('relevant-financial-ratios')}
              >
                <RelevantFinancialRatios
                  isConsolidated={financialNumbersConsolidated?.length}
                  financialRatiosQuery={financialRatiosQuery}
                  financialPerformanceQuery={financialPerformanceQuery}
                />
              </SceneWrapper>
              {/* Management */}
              <SceneWrapper
                renderId="management"
                dataCy="management-"
                title={<FormattedMessage id={intl.companyRoles('title')} />}
                draggable_id="management"
                order={1 + creditCheckKeys?.indexOf('management')}
              >
                <CompanyRoles />
              </SceneWrapper>
              {/* Owners */}
              <SceneWrapper
                renderId="owners"
                dataCy="owners-"
                title={<FormattedMessage id={intl.companyRoles('title-owners')} />}
                draggable_id="owners"
                order={1 + creditCheckKeys?.indexOf('owners')}
              >
                <CompanyOwners
                  subsidiaryCount={companyChildrenCount}
                  hasHierarchy={companyChildrenCount || companyParentCount}
                />
              </SceneWrapper>
              {/* Map */}
              <SceneWrapper
                renderId="map"
                order={`${1 + creditCheckKeys?.indexOf('map')}`}
                title={<FormattedMessage id={intl.companyRoles('title-map')} />}
                draggable_id="map"
              >
                <div
                  style={{
                    order: `${1 + creditCheckKeys?.indexOf('map')}`,
                    paddingBottom: theme.spacing(2),
                  }}
                  // eslint-disable-next-line react/no-unknown-property
                >
                  <div>
                    {companyBasicsQuery.data?.address ? (
                      <Map address={companyBasicsQuery.data.address} />
                    ) : null}
                  </div>
                </div>
              </SceneWrapper>
            </CustomCreditCheckWrapper>
          </DragDropContext>
        </Box>
      </CreditCheckProvider>
    </ErrorBoundary>
  )
}

export default CreditCheckPage
