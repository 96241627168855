const main = (color: string) => {
  return {
    color,
    textAlign: 'center',
  }
}

export const classes = {
  main,
}
