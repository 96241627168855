import { LocalId } from 'globalTypes'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCompanyBasics } from 'services/queries'

/**
 * Pass the localId if you want to test a custom company
 * If you don't the hook will take the company you are currently viewing
 * @param localId {id, country}
 * @returns boolean || null
 * If you get null, it means that there is an error and you should show an error state
 */
const useIsCompanyActive = (localId?: LocalId) => {
  const { country: paramsCountry, id: paramsId } = useParams() as {
    country?: string
    id?: string
  }
  let country
  let id

  if (localId) {
    country = localId.country
    id = localId.id
  } else {
    country = paramsCountry
    id = paramsId
  }

  const queryClient = useQueryClient()

  if (!country || !id) return null

  const companyBasics = queryClient.getQueryData([
    'companyBasics',
    id,
    country,
  ]) as ReturnType<typeof useCompanyBasics>['data']

  if (companyBasics && companyBasics?.active_not_distress === undefined) {
    return companyBasics?.status_code === 'ACTIVE'
  }
  if (companyBasics?.active_not_distress !== null) {
    return !!companyBasics?.active_not_distress
  }
  return null
}
export default useIsCompanyActive
