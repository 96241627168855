import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const subSection: SxProps = {
  margin: theme.spacing(2),
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
}

export const classes = {
  subSection,
}
