import { Box, Skeleton } from '@mui/material'

const DefaultSkeleton = ({ height }: { height: number }) => (
  <Box p={2}>
    {Array.from(Array(3)).map((item, i) => (
      <Box key={i} sx={{ mb: 2 }}>
        <Skeleton variant="rectangular" height={20} width="100%" />
      </Box>
    ))}
    <Skeleton variant="rectangular" height={20} width="100%" />
  </Box>
)

export default DefaultSkeleton
