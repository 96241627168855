import risikaAPI from 'services/api/helpers/risikaAPI'
import { monitorVisibilityUpdate } from 'services/api'
/******************
 * Post to API *
 ******************/

const monitorAddNewList = ({ title, ownerApiId, users, isPublic }) => {
  let list_id

  return risikaAPI
    .post(
      `/list/add`,
      { title: title, private: !isPublic },
      {
        microservice: 'monitor',
      }
    )
    .then(({ data }) => {
      list_id = data.list_id
      if (!isPublic) {
        return monitorVisibilityUpdate({
          listId: data.list_id,
          users: [ownerApiId, ...users],
        })
      }

      return data
    })
    .then(() => ({ list_id }))
}

export default monitorAddNewList
