import React from 'react'
import { useAllUsers } from 'services/queries'
import { Box, Typography, Checkbox, FormControlLabel, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { UserType } from 'types/queries'
import { classes } from 'pages/risk-monitoring/styles/select-users'

type Props = {
  setSelectedUsers: (selectedUsers: number[]) => void
  selectedUsers: Array<number>
  ownerIdWebApi: number
}

const SelectUsers = ({ setSelectedUsers, selectedUsers, ownerIdWebApi }: Props) => {
  const [allUsers, setAllUsers] = React.useState<UserType[]>([])
  const { data: usersData, isSuccess } = useAllUsers()
  React.useEffect(() => {
    if (isSuccess) {
      setAllUsers(usersData.filter((user) => user.id !== ownerIdWebApi))
    }
  }, [ownerIdWebApi, usersData, isSuccess])

  const handleSelect = (userRisikaId: number) => {
    selectedUsers?.includes(userRisikaId)
      ? setSelectedUsers(selectedUsers.filter((x) => x !== userRisikaId))
      : setSelectedUsers([...selectedUsers, userRisikaId])
  }
  return (
    <Box sx={classes.main}>
      <Typography variant="h4" sx={classes.title}>
        <FormattedMessage id={intl.riskMonitoringNew('new-list-subtitle')} />
      </Typography>
      <Stack>
        {allUsers.map((user) => (
          <FormControlLabel
            sx={classes.user}
            control={<Checkbox checked={selectedUsers?.includes(user.api_user_id)} />}
            label={user.email}
            onClick={() => handleSelect(user.api_user_id)}
            key={user.id}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default SelectUsers
