import { DropdownSelect, SingleSelect } from 'components-new'
import React from 'react'
import { fromValueToLabel, fromLabelToValue } from '../controller'
import mapping from './model'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { useIntl } from 'react-intl'

const options = [
  { label: <FormattedMessage id={intl.creditPolicyNew('1-year')} />, value: '1-year' },
  { label: <FormattedMessage id={intl.creditPolicyNew('2-years')} />, value: '2-years' },
  { label: <FormattedMessage id={intl.creditPolicyNew('3-years')} />, value: '3-years' },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('4-and-more')} />,
    value: '4-and-more',
  },
]
const EquityInput = () => {
  const { policy, setPolicy } = useCountryPolicy()
  const [val, setVal] = React.useState<string>(
    fromValueToLabel(policy.negative_equity_limit, mapping)
  )
  const reactIntl = useIntl()
  React.useEffect(() => {
    if (fromLabelToValue(val, mapping) !== policy?.negative_equity_limit) {
      setVal(fromValueToLabel(policy?.negative_equity_limit, mapping))
    }
  }, [policy])

  const handleChange = (value: string): void => {
    const newEquity = fromLabelToValue(value, mapping)
    setPolicy({ ...policy, negative_equity_limit: newEquity as number })
  }

  return (
    <DropdownSelect
      placeholder={reactIntl.formatMessage({
        id: intl.creditPolicyNew('select'),
      })}
      options={options}
      value={val}
      disablePlaceholderClick={false}
      setValue={handleChange}
    />
  )
}

export default EquityInput
