import React, { useEffect } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import styles from '../../styles/toggle-owner'
import { SubsidiaryToggleProps } from 'pages/company-hierarchy/types'

const SubsidiariesToggle = ({
  nodeDatum,
  isNodeToggled,
  toggleNode,
  onOpenChildren,
  direction,
  selectDepth,
  shouldUpdateDepth,
}: SubsidiaryToggleProps) => {
  useEffect(() => {
    selectDepth({ type: direction })
  }, [direction, selectDepth])

  const handleOnClick = () => {
    toggleNode()
    if (shouldUpdateDepth) {
      onOpenChildren?.()
    }
  }

  return (
    <Stack
      data-cy={`subsidiaries-toggle-${nodeDatum?.name}`}
      direction="column"
      alignItems="center"
      sx={{ ...styles.subsidiariesMain }}
    >
      {nodeDatum?.children?.length ? (
        <Stack
          alignItems="center"
          data-cy={`${nodeDatum.name}-subsidiaries-toggle`}
          justifyContent="center"
          sx={{ ...styles.subsidiaryBtn(!isNodeToggled) }}
          onClick={handleOnClick}
        >
          <Typography variant="subtitle2">
            <FormattedMessage
              id={intl.companyHierarchy('subsidiaries')}
              values={{
                number: nodeDatum.children?.length,
                toggled: isNodeToggled ? 'x' : '+',
              }}
            />
          </Typography>
        </Stack>
      ) : (
        <Box>&nbsp;</Box>
      )}
    </Stack>
  )
}

export default SubsidiariesToggle
