// @flow

/* eslint-disable no-case-declarations */
import { DOMAIN as _ } from 'configs/constants/system'

/*
  Actions types
*/
export const SET_SEARCH_TEXT = `${_} SET_SEARCH_TEXT`
export const SET_SEARCH_SELECTED = `${_} SET_SEARCH_SELECTED`
export const SET_SEARCH_COUNTRY = `${_} SET_SEARCH_COUNTRY`
//
export const SET_SEARCH = `${_} SET_SEARCH`
//
export const SET_OPTION = `${_} SET_OPTION`
export const TOGGLE_OPTION = `${_} TOGGLE_OPTION`
//
export const RESET_SEARCH = `${_} RESET_SEARCH`
//
export const SET_TABLE_CONTENT = `${_} SET_TABLE_CONTENT`
export const CLEAN_SEARCH_RESULTS = `${_} CLEAN_SEARCH_RESULTS`
export const SET_SEARCH_RESULTS = `${_} SET_SEARCH_RESULTS`
export const SET_SEARCH_PERSON = `${_} SET_SEARCH_PERSON`
//
export const ADD_QUERY = `${_} ADD_QUERY`
export const RESET_QUERIES = `${_} RESET_QUERIES`
export const API_STATUS = `${_} API_STATUS`

/*
  Initial state
*/
const initialState = {
  queries: [],
  apiStatus: { online: true, message: '' },
  //
  searchText: '',
  searchCountry: 'DK',
  //
  showAdvancedFilters: false,
  //
  companyType: [],
  employees: [],
  equity: { operator: '<', value: 0 },
  grades: [],
  // new industry groups
  industryGroup: [],
  subIndustry: [],
  specificIndustry: [],
  // Regions & municipalities
  regions: [],
  municipalities: [],
  //
  showInactive: false,
  hideNoToAdvertisement: false,
  //
  tableContent: 'company',
  companyCount: 0,
  elapsedTime: null,
  companyResults: [],
  companyRows: {},
  peopleCount: 0,
  peopleResults: [],
  peopleRows: {},
  //
  options_for_industry: [],
  options_for_municipalities: [],
  options_for_regions: [],
  searchSelected: false,
}
export const initialStateSearchBusiness = initialState

/*
  Action creators
*/
export const setSearchText = (text) => ({
  type: SET_SEARCH_TEXT,
  text,
})
export const setSearchSelected = (searchSelected) => ({
  type: SET_SEARCH_SELECTED,
  searchSelected,
})
export const setSearchCountry = (country) => ({
  type: SET_SEARCH_COUNTRY,
  country,
})
//
export const setSearch = (event) => ({
  type: SET_SEARCH,
  event,
})
//
export const setOption = (option) => (value) => ({
  type: SET_OPTION,
  option,
  value,
})
export const toggleOption = (option) => ({
  type: TOGGLE_OPTION,
  option,
})
export const resetSearch = () => ({
  type: RESET_SEARCH,
})
export const cleanSearchResults = () => ({
  type: CLEAN_SEARCH_RESULTS,
})
export const setTableContent = (tableContent) => ({
  type: SET_TABLE_CONTENT,
  tableContent,
})
export const setSearchResults = (payload) => ({
  type: SET_SEARCH_RESULTS,
  payload,
})
export const setSearchPerson = (payload) => ({
  type: SET_SEARCH_PERSON,
  payload,
})
export const addQuery = (now) => ({
  type: ADD_QUERY,
  now,
})
export const resetQueries = () => ({
  type: RESET_QUERIES,
})
export const setApiStatus = (apiStatus) => ({
  type: API_STATUS,
  apiStatus,
})

/*
  Reducer
*/
export const searchBusiness = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.text,
      }
    case SET_SEARCH_SELECTED:
      return {
        ...state,
        searchSelected: action.searchSelected,
      }
    case SET_SEARCH_COUNTRY:
      return {
        ...state,
        searchCountry: action.country,
      }
    case SET_SEARCH:
      const { name, value } = action.event
      return {
        ...state,
        [name]: value,
      }
    case SET_OPTION:
      return {
        ...state,
        [action.option]: action.value,
      }
    case TOGGLE_OPTION:
      return {
        ...state,
        [action.option]: !state[action.option],
      }
    case RESET_SEARCH:
      return {
        ...state,
        companyType: initialState.companyType,
        employees: initialState.employees,
        equity: initialState.equity,
        grades: initialState.grades,
        industryGroup: initialState.industryGroup,
        subIndustry: initialState.subIndustry,
        specificIndustry: initialState.specificIndustry,
        regions: initialState.regions,
        municipalities: initialState.municipalities,
        showInactive: initialState.showInactive,
        hideNoToAdvertisement: initialState.hideNoToAdvertisement,
        searchText: initialState.searchText,
      }
    case CLEAN_SEARCH_RESULTS:
      return {
        ...state,
        companyCount: initialState.companyCount,
        elapsedTime: initialState.elapsedTime,
        peopleCount: initialState.peopleCount,
        companyResults: initialState.companyResults,
        peopleResults: initialState.peopleResults,
        rows: initialState.rows,
      }
    case SET_SEARCH_RESULTS:
      const {
        count: companyCount,
        search_result: companyResults,
        rows: companyRows,
        time_elapsed: timeElapsed,
      } = action.payload
      return {
        ...state,
        companyCount,
        companyResults,
        companyRows,
        timeElapsed,
      }
    case SET_TABLE_CONTENT:
      return {
        ...state,
        tableContent: action.tableContent,
      }
    case SET_SEARCH_PERSON:
      const {
        search_result: peopleResults,
        count: peopleCount,
        rows: peopleRows,
      } = action.payload
      return {
        ...state,
        peopleResults,
        peopleCount,
        peopleRows,
      }
    case ADD_QUERY:
      const queries = state.queries || [] // Compatibility with previous state where queries is stored as undefined
      return {
        ...state,
        queries: [
          action.now,
          ...queries,
          // ...state.queries // spread queries assuming is an array
        ].sort((a, b) => b - a),
      }
    case RESET_QUERIES:
      return {
        ...state,
        queries: [],
      }
    case API_STATUS:
      return {
        ...state,
        apiStatus: action.apiStatus,
      }
    default:
      return { ...state }
  }
}

/*
  Default export
*/
export default searchBusiness
