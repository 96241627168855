import { ChangeEvent } from 'react'
import { Stack, Switch, Typography } from '@mui/material'
import { styles } from './SwitchWithLabel.styles'

type SwitchLabel = JSX.Element | string | undefined

type Props = {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  disabled?: boolean
  labelChecked?: SwitchLabel
  labelUnChecked?: SwitchLabel
  labelPosition?: 'start' | 'end'
  lighterLabelColorWhenUnchecked?: boolean
}

const SwitchWithLabel = ({
  checked,
  disabled,
  onChange,
  labelChecked,
  labelUnChecked,
  labelPosition = 'start',
  lighterLabelColorWhenUnchecked = false,
  ...rest
}: Props) => {
  const getLabel = (
    labelChecked: SwitchLabel,
    labelUnchecked: SwitchLabel,
    checked: boolean
  ) => {
    if (labelChecked && labelUnchecked) {
      return checked ? labelChecked : labelUnchecked
    }
    if (labelChecked) {
      return labelChecked
    }
    return null
  }
  return (
    <Stack
      sx={styles.containerStyles(labelPosition, lighterLabelColorWhenUnchecked, checked)}
      {...rest}
    >
      <Typography variant="body1" color="inherit">
        {getLabel(labelChecked, labelUnChecked, checked)}
      </Typography>
      <Stack>
        <Switch
          data-cy="switch-with-label"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          sx={styles.switchStyles}
        />
      </Stack>
    </Stack>
  )
}

export default SwitchWithLabel
