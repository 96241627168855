import { Box, Stack, Collapse, Typography, useMediaQuery } from '@mui/material'
import { Show } from 'components'
import { isComponentRendered } from 'configs'
import { useRatingCredit } from 'services/queries'
import { generateColorFromRisk } from 'utils-new/generate-color-from-risk'
import { RiskAssessmentCodeT } from 'types/general'
import CreditValue from './CreditValue'
import CreditDays from './CreditDays'
import { LocalId } from 'globalTypes'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { SxProps, styled } from '@mui/system'
import { LoadingSkeleton } from 'components-new'
import RecommendationSkeleton from './Recommendation.skeleton'
import { WidgetWrapper } from '../WidgetWrapper/WidgetWrapper'

type Props = {
  isReArrange: boolean
  isDragging: boolean
  riskAssessmentCode: RiskAssessmentCodeT
  companyType: string
  isGazzete: boolean
  localId: LocalId
  paperStyles?: SxProps
  columnDirection?: boolean
  isLoading?: boolean
  widgetNameDND: string
  isActive?: boolean
  setIsActive?: (key: string) => void
}

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
})) as typeof Box

function Recommendation({
  widgetNameDND,
  riskAssessmentCode,
  isReArrange,
  companyType,
  isGazzete,
  localId,
  columnDirection = false,
  isLoading,
  isActive = true,
  setIsActive,
}: Props) {
  const { data, isLoading: isLoadingRatingCredit, isError } = useRatingCredit(localId)
  const layoutFix = useMediaQuery('(min-width:960px) and (max-width:1550px)')

  const colorLvl = generateColorFromRisk(riskAssessmentCode)
  if (isError) {
    return null
  }

  if (!isLoadingRatingCredit && !data) {
    return null
  }

  const isGrey: boolean = data?.credit_max === undefined || data?.credit_max === 0

  return (
    <LoadingSkeleton
      SkeletonComponent={RecommendationSkeleton}
      isLoading={isLoadingRatingCredit || isLoading}
    >
      <Show
        when={
          Boolean(isComponentRendered('risikasEstimation', localId?.country)) &&
          companyType !== 'ENK' &&
          Boolean(!isGazzete)
        }
      >
        <WidgetWrapper
          isReArrange={isReArrange}
          widgetName={widgetNameDND}
          isActive={!!isActive}
          setIsActive={setIsActive}
        >
          <StyledBox>
            <Typography variant="h4">
              <FormattedMessage id={intl.mainRecommendation('recommendation')} />
            </Typography>

            <Collapse in={!isReArrange}>
              <Stack
                mt={4}
                direction={columnDirection ? 'column' : layoutFix ? 'column' : 'row'}
                justifyContent="space-between"
                spacing={2}
              >
                <CreditValue
                  value={data?.credit_max ?? null}
                  isError={isError}
                  colorLvl={colorLvl}
                  isGrey={isGrey}
                  currency={data?.currency ?? 'DKK'}
                />
                <CreditDays
                  days={data?.credit_days ?? null}
                  isError={isError}
                  colorLvl={colorLvl}
                  isGrey={isGrey}
                />
              </Stack>
            </Collapse>
          </StyledBox>
        </WidgetWrapper>
      </Show>
    </LoadingSkeleton>
  )
}

export default Recommendation
