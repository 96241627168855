import { useState, useRef, useEffect, useReducer, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Box, Stack } from '@mui/material'
import { setHierarchyFilter, submitFetchAllMonitoringLists } from 'store_deprecated'
import { HistoryHeader, ConditionallyRender } from 'components'
import MonitorAllButton from './scenes/MonitorAllButton'
import {
  useCompanyHierarchyWalkdown,
  useCompanyHierarchyWalkup,
  useHierarchySummary,
} from 'services/queries'
import { SelectedCompanyProvider } from './context/selectedCompanyContext'
import useTotangoTracking from 'utils/totangoTracking'
import HierarchyScene from './HierarchyScene'
import MainSection from 'components-new/layouts/MainSection/MainSection'
import MenuBar from 'components-new/layouts/MenuBar/MenuBar'
import ListOrTreeSwitch from './scenes/UI/ListOrTreeSwitch'
import DisplayPropsBtn from './scenes/UI/DisplaySettings'
import { HierarchySceneContext } from './context/hierarchySceneContext'
import { formatWalkupData } from './tree-helpers'
import HierarchyListView from './scenes/HierarchyListView'
import ListSkeleton from './scenes/HierarchyListView/widgets/ListSkeleton'
import { CompanyHierarchy } from 'types/general'
import { AvailableCountries } from '../../globalTypes'
import { CustomLoader } from 'components/loaders/Loader'
import { ButtonTertiary } from 'components-new'

export type HierarchyViewT = 'tree' | 'list'

const INITIAL_DEPTH = 1
const INITIAL_COUNTER = 0

const CompanyHierarchyPage = () => {
  const { id, country } = useParams<{ id: string; country: AvailableCountries }>()
  const treeScenePrintRef = useRef(null)
  const listScenePrintRef = useRef(null)
  const monitorAllBtnRef = useRef<any>(null)

  const [walkupData, setWalkupData] = useState<CompanyHierarchy>()
  const [walkdownData, setWalkdownData] = useState<CompanyHierarchy>()
  const [collapseAllCounter, setCollapseAllCounter] = useState(INITIAL_COUNTER)
  const [selectedView, setSelectedView] = useState<HierarchyViewT>('tree')
  const [isLoading, setIsLoading] = useState(true)
  const [maxChildLevel, setMaxChildLevel] = useState(INITIAL_DEPTH)
  const [maxParentLevel, setMaxParentLevel] = useState(INITIAL_DEPTH)

  // this handles the depth depending which way you are going as each direction can have a different depth
  const reducer = (depthState: { maxDepth: number }, action: { type: string }) => {
    switch (action.type) {
      case 'walkup':
        return { maxDepth: maxParentLevel }
      case 'walkdown':
        return { maxDepth: maxChildLevel }
      default:
        return depthState
    }
  }

  const [depthState, dispatch] = useReducer(reducer, { maxDepth: maxChildLevel })

  const { data: summaryData, isLoading: isSummaryLoading } = useHierarchySummary({
    id,
    country,
  })

  // Get all ids of parent or child companies from the tree -> this goes to Monitor all button
  const monitorAllLocalIds = useMemo(() => {
    if (!summaryData) return []
    const { children, parents } = summaryData

    const childCompanies = children.filter((child) => child.local_organization_id.id)
    const parentCompanies = parents.filter((parent) => parent.local_organization_id.id)

    const allCompanies = [...childCompanies, ...parentCompanies].map(
      (company) => company.local_organization_id
    )

    return [{ country, id: id.toUpperCase() }, ...allCompanies]
  }, [country, id, summaryData])

  const walkdownQuery = useCompanyHierarchyWalkdown({
    id,
    country,
    maxDepth: maxChildLevel,
  })
  // Load the /walkup only after the /walkdown is successful
  const walkupQuery = useCompanyHierarchyWalkup(walkdownQuery.isSuccess, {
    id,
    country,
    maxDepth: maxParentLevel,
  })
  const isWalkLoading = walkdownQuery.isLoading || walkupQuery.isLoading
  const { trackEvent } = useTotangoTracking()
  const handleUpdateChildren = () => {
    setMaxChildLevel(maxChildLevel + 1)
  }

  const handleUpdateParents = () => {
    setMaxParentLevel(maxParentLevel + 1)
  }

  useEffect(() => {
    trackEvent('Credit Check', 'Company Hierarchy')
  }, [trackEvent])

  useEffect(() => {
    if (walkupQuery.isSuccess && walkdownQuery.data) {
      const currentCompany = walkdownQuery.data
      currentCompany.level = 0 // To indicate the company is the Current Company

      if (walkupQuery.data?.parents?.length) {
        currentCompany.has_parents = walkupQuery.data.parents.length

        const formattedWalkupData = formatWalkupData(walkupQuery.data)

        formattedWalkupData.has_children = currentCompany.children?.length || 0

        setWalkupData(formattedWalkupData)
      }

      setWalkdownData(currentCompany)
      setIsLoading(false)
    }
  }, [walkupQuery.isSuccess, isLoading, walkupQuery.data, walkdownQuery.data])

  if (!Number.parseInt(id)) {
    if (isWalkLoading) {
      return <CustomLoader redirect />
    }
  }

  return (
    <SelectedCompanyProvider
      defaultState={{
        selectedCompany: {
          localId: { id, country },
          name: walkdownData?.name,
        },
      }}
    >
      <Stack sx={{ backgroundColor: 'grey.50', height: '95%' }}>
        <HierarchySceneContext>
          <HistoryHeader
            title={walkdownData?.name || ''}
            historyKey={walkdownData?.local_id?.id || ''}
            location="companyhierarchy"
            country={country}
          >
            <DisplayPropsBtn key="btn-1" />
            <MonitorAllButton
              key="btn-2"
              companies={monitorAllLocalIds}
              ready={!isSummaryLoading}
              // @ts-ignore // We need the 2 imports and i think that is the issue
              ButtonComponent={(props) => (
                <ButtonTertiary startIcon={props.icon} {...props}>
                  {props.text}
                </ButtonTertiary>
              )}
              ref={monitorAllBtnRef}
            />
          </HistoryHeader>
          <MenuBar
            disabled={walkdownQuery.isLoading || walkupQuery.isLoading}
            leftSide={[
              <ListOrTreeSwitch
                key="item-1"
                selectedView={selectedView}
                setSelectedView={setSelectedView}
              />,
            ]}
            rightSide={[]}
          />

          <ConditionallyRender
            condition={selectedView === 'list'}
            when={
              <MainSection>
                <Box sx={{ overflow: 'auto' }} ref={listScenePrintRef}>
                  <ConditionallyRender
                    condition={!!walkdownData}
                    when={
                      <HierarchyListView
                        collapseAllCounter={collapseAllCounter}
                        data={[walkdownData!]}
                        increaseDepth={handleUpdateChildren}
                        cacheDepth={maxChildLevel}
                      />
                    }
                    otherwise={<ListSkeleton />}
                  />
                </Box>
              </MainSection>
            }
            otherwise={
              <MainSection>
                <Box
                  ref={treeScenePrintRef}
                  sx={{
                    height: 1,
                  }}
                >
                  <HierarchyScene
                    walkdownData={walkdownData}
                    walkupData={walkupData}
                    expandDepthProp={depthState.maxDepth - 1}
                    onUpdateChildren={handleUpdateChildren}
                    onUpdateParents={handleUpdateParents}
                    setSelectedMaxDepthLevel={dispatch}
                  />
                </Box>
              </MainSection>
            }
          />
        </HierarchySceneContext>
      </Stack>
    </SelectedCompanyProvider>
  )
}

const mapStateToProps = (state: any) => ({
  filters: state.risika.companyHierarchy.filters,
  selectedFilter: state.risika.companyHierarchy.order,
  lists: state.risika.newRiskMonitoring.lists,
  savedViews: state.risika.companyHierarchy.savedViews,
})

const mapDispatchToProps = (dispatch: any) => ({
  hierarchyFilter: (filter: any, value: any) =>
    dispatch(setHierarchyFilter(filter, value)),
  fetchAllMonitoringLists: (listLength: any) =>
    dispatch(submitFetchAllMonitoringLists(listLength)),
})

export default connect(
  // My eyes are burning
  mapStateToProps,
  mapDispatchToProps
)(CompanyHierarchyPage)
