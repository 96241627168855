import * as React from 'react'
import { makeStyles } from '@mui/styles'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'red',
  },
}))

const Highlights = ({ connectedBancrupcies, content }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      {content(open, handleClose)}
      <IconButton onClick={handleOpen}>
        <FontAwesomeIcon className={classes.icon} fixedWidth icon={faExclamation} />
      </IconButton>
    </>
  )
}

export default Highlights
