import { UsersType } from 'types/queries'
import { User } from './widgets/UserTable/UserTable.types'

function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const normalizeUsers: (users: UsersType) => User[] = (users: UsersType) => {
  if (!users) return []
  return users.map((user) => ({
    userId: user.id,
    lastLogin: user.last_login,
    name: user.name ?? '',
    email: user.email,
    phoneNumber: user.phone_number,
    isVerified: user.is_verified,
    roles: [
      {
        value: user.role,
        label: capitalize(user.role),
      },
    ],
  }))
}
