import { useQuery } from 'react-query'
import subscriptionShow from 'services/api/endpoints/risikaAPI/subscription/subscriptionShow'

export function useSubscriptionShow(
  listId: string,
  type: 'CREDIT_COMPLIANCE' | 'MONITOR'
) {
  return useQuery(`subscriptionShow${listId}`, () => subscriptionShow(listId, type), {
    cacheTime: 0,
  })
}
