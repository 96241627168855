import risikaAPI from 'services/api/helpers/risikaAPI'

/**************
 * Types *
 **************/
export type ExcludeCompanies = {
  local_organization_id: {
    country: string
    id: string
    name: string
  }
}

export type ExcludePersons = {
  local_person_id: {
    country: string
    id: string
    name: string
  }
}

export type ExcludeRisikaScores = {
  BOHR?: number[]
  NEWTON?: number[]
}

export type AdvancedPolicyGetT = {
  exclude_risika_scores: ExcludeRisikaScores | null
  exclude_company_types: string[] | null
  exclude_level_of_auditor_assistances: string[] | null
  exclude_industries: string[] | null
  exclude_companies: ExcludeCompanies[] | null
  exclude_persons: ExcludePersons[] | null
  exclude_vat: string[] | null
  bankruptcy_limit: number | null
  bankruptcy_limit_long: number | null
  age_limit: number | null
  negative_equity_limit: number | null
  prepaid_phone_number: boolean | null
  phone_number_registry: boolean | null
  distance_to_ceo: number | null
  payment_remarks_count: number | null
  payment_remarks_creditors: number | null
  payment_remarks_total_amount: number | null
  payment_remarks_max_repayment_time: number | null
  debt: number | null
  short_term_debt: number | null
  profit_loss: number | null
  auditor_has_id: boolean | null
  number_of_employees: number | null
}

export type CreditPolicyT = {
  created: string
  enabled: boolean
  updated: string
  product: string
  policy: AdvancedPolicyGetT
}

async function creditPolicyGet(country: string): Promise<CreditPolicyT> {
  return risikaAPI
    .get(`/credit_policy/get?country=${country}`, {
      microservice: 'account',
    })
    .then(({ data }) => {
      if (
        data.policy.exclude_risika_scores &&
        !Array.isArray(data.policy.exclude_risika_scores.BOHR)
      ) {
        data.policy.exclude_risika_scores.BOHR =
          data.policy.exclude_risika_scores.BOHR.BOHR
      }
      return data
    })
}

export default creditPolicyGet
