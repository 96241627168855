import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)

const RedTrendArrow = () => {
  if (isSafari) {
    return (
      <ArrowDownwardIcon
        fontSize="inherit"
        data-cy="red-trend-arrow"
        sx={{
          color: 'error.main',
          stroke: 'none',
        }}
      />
    )
  } else {
    return (
      <ArrowForwardIcon
        data-cy="red-trend-arrow"
        fontSize="inherit"
        sx={{
          color: 'error.main',
          transform: 'rotate(45deg) ',
          position: 'relative',
          top: '3px',
          stroke: 'none', // Must be due to the case of using the arrow in <svg>
        }}
      />
    )
  }
}

export default RedTrendArrow
