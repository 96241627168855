import Typography from '@mui/material/Typography'
import { infoBoxColors } from 'configs/constants/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { classes } from './Conflict.styles'
import { Box } from '@mui/material'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type Props = {
  text: string
  code: string
  isNoConflicts?: boolean
}

const Conflict = ({ text, code, isNoConflicts = false }: Props) => {
  return (
    <Box
      sx={classes.header}
      style={{
        border: isNoConflicts
          ? `1px solid ${infoBoxColors.positive}`
          : `1px solid ${infoBoxColors.negative}`,
        borderLeft: isNoConflicts
          ? `3rem solid ${infoBoxColors.positive}`
          : `3rem solid ${infoBoxColors.negative}`,
      }}
    >
      <Box
        style={isNoConflicts ? { top: '31%' } : { top: '40%' }}
        sx={classes.iconContainer}
      >
        <FontAwesomeIcon
          icon={isNoConflicts ? (faThumbsUp as IconProp) : (faThumbsDown as IconProp)}
          style={{
            color: 'white',
            height: '1.8rem',
            width: '1.8rem',
            lineHeight: 0,
            marginRight: 1,
          }}
        />
      </Box>
      <Box sx={classes.description}>
        <Typography variant="subtitle1" sx={classes.title}>
          {<FormattedMessage id={intl.creditPolicy(code)} />}
        </Typography>
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Box>
  )
}

export default Conflict
