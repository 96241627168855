import { Stack } from '@mui/material'
import { CellWrapperProps } from '../../UserTable.types'

const CellWrapper = ({
  children,
  contentPosition = 'flex-start',
  removePadding = false,
  isBlurred = false,
}: CellWrapperProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={contentPosition}
      height={46}
      paddingX={removePadding ? 0 : 6}
      paddingY={removePadding ? 0 : 3}
      sx={{ opacity: isBlurred ? 0.3 : 1, boxSizing: 'content-box' }}
    >
      {children}
    </Stack>
  )
}

export default CellWrapper
