import { LinearProgress } from '@mui/material'
import { useStyles } from '../styles/search-loader'

export const SearchLoader = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgress
        size={30}
        thickness={50}
        className={classes.progress}
        classes={{
          colorPrimary: classes.linearColorPrimary,
          barColorPrimary: classes.linearBarColorPrimary,
        }}
      />
    </div>
  )
}

export default SearchLoader
