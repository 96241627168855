import * as React from 'react'
import { loadPolicyIntoBuilder } from './PolicyBuilder.controller'
import {
  ActionPanelStates,
  ActionPanelVariables,
  BuilderNode,
  NodeApiTypes,
  PolicyBuilderParams,
  UseLoadPolicyIntoBuilder,
  UseLoadPredefinedPolicyStructure,
} from './PolicyBuilder.types'
import { useBuilder } from './BuilderContext'
import {
  DELETE_RULE,
  NEW_MONITORING_LIST,
  onlyActiveCompaniesStructure,
} from './PolicyBuilder.model'
import { navigation } from 'services/navigation'
import { useHistory, useParams } from 'react-router-dom'
import { postCreditPolicy } from 'services/api'
import { useECPDataStructure } from 'services/queries'
import { ECPDataType } from 'services/api/endpoints/risikaAPI/getECPDataStructure'

// This just abstracts the logic of loading a policy into the builder.
export const useLoadPolicyIntoBuilder = ({
  policyId,
  policyData,
  state,
  actions,
  params,
}: UseLoadPolicyIntoBuilder) => {
  const { data: dataStructure, isLoading: isECPDataStructureLoading } =
    useECPDataStructure(params.country)
  React.useEffect(() => {
    if (
      policyId !== 'null' &&
      policyData?.policy &&
      state?.nodes?.length === 0 &&
      !isECPDataStructureLoading
    ) {
      loadPolicyIntoBuilder({
        policyData,
        actions,
      })
    }
  }, [
    actions,
    params?.country,
    policyData,
    policyId,
    state?.nodes?.length,
    params.createActiveRule,
    isECPDataStructureLoading,
    dataStructure,
  ])
}

/*
  This is when we start creating a new policy and select reject inactive companies
  we start at
  /credit-policy-builder/DK/eng2-1172-testing-3/true/null/eng2-1172-testing-3
  which create a new policy and goes to
  /credit-policy-builder/DK/eng2-1172-testing-3/false/1805/eng2-1172-testing-3

  this the path for policybuilder
  '/credit-policy-builder/:country/:name/:createActiveRule/:policyId/:description?/'
*/
export const useLoadPredefinedPolicyStructure = ({
  params,
  policyId,
  structureType,
}: UseLoadPredefinedPolicyStructure) => {
  const history = useHistory()
  React.useEffect(() => {
    if (policyId === 'null' && structureType) {
      switch (structureType) {
        case 'create-active-rule':
          postCreditPolicy({
            name: params.name,
            country: params.country.toUpperCase(),
            policy: {
              nodes: onlyActiveCompaniesStructure.nodes,
              edges: onlyActiveCompaniesStructure.edges,
            },
            description: params.description ?? '',
            enabled: false,
          }).then((res) => {
            history.push(
              navigation.creditPolicyBuilder({
                country: params.country,
                name: params.name,
                description: params.description,
                policyId: res.policy_id,
                createActiveRule: false,
              })
            )
          })
          break

        default:
          break
      }
    }
  }, [history, params.country, params.description, params.name, policyId, structureType])
}

export const useDetailedComponentView = () => {
  const [showDeleteButton, setShowDeleteButton] = React.useState(true)
  const { state, actions } = useBuilder()
  const { actionPanelContent, actionPanelData, nodes } = state
  const [prevContent, setPrevContent] = React.useState(actionPanelContent)
  const selectedNode = nodes.find(
    (node) => node.id === actionPanelData.nodeId
  ) as BuilderNode
  const isDelete = actionPanelContent === DELETE_RULE
  const isNewMonitoringList = actionPanelContent === NEW_MONITORING_LIST

  const confirmButtonText = React.useMemo(() => {
    if (isDelete) return 'delete-rule-button-label'
    return 'action-panel-confirm-button'
  }, [isDelete])

  const prevActionPanelContent = React.useMemo(() => {
    const doNotSaveToPrevContentList = [DELETE_RULE, NEW_MONITORING_LIST]
    let prevContent = ''

    return (newContent: ActionPanelStates): NodeApiTypes | '' => {
      if (!doNotSaveToPrevContentList.includes(newContent)) {
        prevContent = newContent
      }
      return prevContent as NodeApiTypes | ''
    }
  }, [])

  React.useEffect(
    () => setPrevContent(prevActionPanelContent(actionPanelContent)),
    [prevActionPanelContent, actionPanelContent]
  )

  React.useEffect(() => {
    if (Object.keys(selectedNode?.data?.apiData).length < 1) {
      setShowDeleteButton(false)
    }
  }, [selectedNode?.data?.apiData])

  const handleCancel = (() => {
    if (isDelete) {
      return () => {
        actions.setActionPanelContent(prevContent)
        setShowDeleteButton(true)
      }
    }
    return () => actions.cancelNode()
  })()

  const handleBackButton = (() => {
    if (isDelete || isNewMonitoringList) {
      return () => {
        actions.setActionPanelContent(prevContent)
        setShowDeleteButton(true)
      }
    }
    return () => actions.setActionPanelContent('')
  })()

  const handleDelete = () => {
    setShowDeleteButton(false)
    actions.setActionPanelContent(DELETE_RULE)
  }

  return {
    handleCancel,
    handleDelete,
    handleBackButton,
    confirmButtonText,
    showDeleteButton,
  }
}

type AccItemType = {
  variable_category: string
  variable_category_key: string
  rules: ActionPanelVariables[]
}

/**
 * Returns an array of available actions grouped by their `variable_category` property.
 *
 * @param dataStructure An array of data objects representing the available actions.
 * Each object has the following properties:
 * - `label` (type: `string`): The label or name of the action.
 * - `data` (type: `Record<string, any>`): Additional data associated with the action,
 * such as its category or type.
 *
 * @param filterText A string used to filter the available actions. The function will
 * only include actions whose `label` property contains this string (case-insensitive).
 *
 * @returns An array of `ActionType` objects, where each object represents a category of
 * available actions and has the following properties:
 * - `variable_category` (type: `string`): The category of the actions.
 * - `rules` (type: `string[]`): An array of action labels belonging to this category.
 */
export function useAvailableActions(dataStructure: ECPDataType, filterText: string) {
  const availableActions = React.useMemo(() => {
    // Create a Map to store the categories as keys and their items as values
    const categoriesMap = new Map<string, AccItemType>()
    // Iterate through each item in the dataStructure array
    for (const item of dataStructure) {
      // Get the variable_category and label of the current item
      const variable_category = item?.data?.variable_category
      const variable_category_key = item?.data?.variable_category_key

      const key = item?.label

      // Check if the label matches the filter text
      if (key?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())) {
        // Get the category object from the categoriesMap, or create a new one if it doesn't exist
        let category = categoriesMap.get(variable_category ?? '')

        if (!category) {
          category = {
            variable_category: variable_category ?? '',
            variable_category_key: variable_category_key ?? '',
            rules: [],
          }
          categoriesMap.set(variable_category ?? '', category)
        }

        // Add the item's label to the category's rules array
        category.rules.push(key as ActionPanelVariables)
      }
    }
    // Convert the values of the categoriesMap to an array and filter out any categories with no rules
    return Array.from(categoriesMap.values()).filter(
      (category) => category.rules.length > 0
    )
  }, [dataStructure, filterText])

  return availableActions
}

export function useBuilderParams() {
  const params = useParams() as PolicyBuilderParams
  return {
    ...params,
    name: decodeURIComponent(params?.name ?? ''),
  }
}
