import { transformRatio, transformFinancialNumber } from '../controller/general'

/*********
 * Types *
 *********/

// export type FilterCategory =
//   | 'financial_numbers'
//   | 'financial_ratios'
//   | 'company_info'
//   | 'credit'
//
// export type OptionForFilter = {
//   value: string,
//   operator: string,
//   input: string,
// }
//
// export interface BaseFilter {
//   label: string;
//   filter: string;
// }
//
// export interface Filter extends BaseFilter {
//   category: FilterCategory;
//   options: OptionForFilter[];
//   countries: SupportedCountry[];
//   suggestions?: string;
//   inputLabel?: string;
//   transformValue?: (value: any) => any;
// }
//
// export interface FilterOption extends BaseFilter {
//   defaultValue: any;
//   transformValue?: (value: any) => any;
// }

/***********
 * Helpers *
 ***********/

function isNilOrEmpty(x) {
  return x == null || x === ''
}

/*******************
 * Data Structures *
 *******************/

/**
 * All the filter categories.
 */
export const category = {
  FINANCIAL_NUMBERS: 'financial_numbers',
  FINANCIAL_RATIOS: 'financial_ratios',
  COMPANY_INFO: 'company_info',
  CREDIT: 'credit',
}

export const categories = [
  { category: category.CREDIT, title: 'credit' },
  { category: category.FINANCIAL_NUMBERS, title: 'financial-numbers' },
  { category: category.FINANCIAL_RATIOS, title: 'financial-ratios' },
  { category: category.COMPANY_INFO, title: 'company-info' },
]

export const inputType = {
  TREE_SELECT: 'tree_select',
  SELECT: 'select',
  NUMBER: 'number',
  RANGE: 'range',
  RANGE_DATE: 'range_date',
  TEXT: 'text',
  ADVANCED_ZIPCODE: 'advanced_zipcode',
}

/**
 * Options containing the definitions for the different
 * filterting options e.g. above a certain amount or
 * must include the specified values.
 */
export const options = {
  NUMBER: [
    { value: 'over', operator: '>', input: inputType.NUMBER },
    { value: 'under', operator: '<', input: inputType.NUMBER },
  ],
  FINANCIAL_NUMBER: [
    { value: 'over', operator: '>', input: inputType.NUMBER, currency: 'DKK' }, // Hardcoded currency for now
    { value: 'under', operator: '<', input: inputType.NUMBER, currency: 'DKK' }, // Hardcoded currency for now
    {
      value: 'between',
      operator: 'range_from_to',
      input: inputType.RANGE,
      currency: 'DKK',
    },
  ],
  RANGE: [
    { value: 'between', operator: 'range', input: inputType.RANGE },
    // { value: 'outside', operator: 'range', input: inputType.RANGE },
  ],
  RANGE_FROM_TO: [
    {
      value: 'between',
      operator: 'range_from_to',
      input: inputType.RANGE,
    },
  ],
  RANGE_FROM_TO_DATE: [
    {
      value: 'between',
      operator: 'range_from_to',
      input: inputType.RANGE_DATE,
    },
  ],
  NESTED_OPTIONS: [
    { value: 'includes', operator: 'include', input: inputType.TREE_SELECT },
    { value: 'excludes', operator: 'exclude', input: inputType.TREE_SELECT },
  ],
  OPTIONS: [
    { value: 'includes', operator: 'include', input: inputType.SELECT },
    { value: 'excludes', operator: 'exclude', input: inputType.SELECT },
  ],
  TEXT: [{ value: 'match', operator: 'match', input: inputType.TEXT }],
  MIXED_INPUT: [
    {
      value: 'includes',
      operator: 'include_mixed',
      input: inputType.ADVANCED_ZIPCODE,
    },
    {
      value: 'excludes',
      operator: 'exclude_mixed',
      input: inputType.ADVANCED_ZIPCODE,
    },
  ],
}

/***********
 * Filters *
 ***********/

/**
 * The definition of all the filters.
 */
export const filters = [
  {
    label: 'equity',
    filter: 'equity',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'share_capital',
    filter: 'share_capital',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'total_debt',
    filter: 'total_debt',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'fixed_assets',
    filter: 'fixed_assets',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'assets',
    filter: 'assets',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'gross_result',
    filter: 'gross_result',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'profit_loss',
    filter: 'profit_loss',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'cash',
    filter: 'cash',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'current_financial_assets',
    filter: 'current_financial_assets',
    category: category.FINANCIAL_NUMBERS,
    options: options.FINANCIAL_NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformFinancialNumber(value),
  },
  {
    label: 'liquidity',
    inputLabel: 'enter-percentage',
    filter: 'liquidity',
    category: category.FINANCIAL_RATIOS,
    options: options.NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformRatio(value),
  },
  {
    label: 'solidity',
    inputLabel: 'enter-percentage',
    filter: 'solidity',
    category: category.FINANCIAL_RATIOS,
    options: options.NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformRatio(value),
  },
  {
    label: 'return_on_assets',
    inputLabel: 'enter-percentage',
    filter: 'return_on_assets',
    category: category.FINANCIAL_RATIOS,
    options: options.NUMBER,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => transformRatio(value),
  },
  {
    label: 'company_type',
    filter: 'company_type',
    suggestions: 'companyType',
    category: category.COMPANY_INFO,
    options: options.OPTIONS,
    countries: ['dk', 'no', 'se', 'fi'],
  },
  {
    label: 'zipcode',
    filter: 'zipcode',
    category: category.COMPANY_INFO,
    options: [...options.MIXED_INPUT],
    countries: ['dk', 'no', 'se', 'fi'],
    explanation: 'advanced-zipcodes-helper-text',
  },
  {
    label: 'region',
    filter: 'local_region',
    suggestions: 'region',
    category: category.COMPANY_INFO,
    options: options.OPTIONS,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (value) => value?.flat(Infinity),
  },
  {
    label: 'municipality',
    filter: 'municipality',
    suggestions: 'municipality',
    category: category.COMPANY_INFO,
    options: options.OPTIONS,
    countries: ['dk', 'no', 'se', 'fi'],
  },
  {
    label: 'date_of_incorporation',
    filter: 'date_of_incorporation',
    category: category.COMPANY_INFO,
    options: options.RANGE_FROM_TO_DATE,
    countries: ['dk', 'no', 'se', 'fi'],
  },
  {
    label: 'industry_group',
    filter: 'industry',
    suggestions: 'industryGroup',
    category: category.COMPANY_INFO,
    options: options.OPTIONS,
    countries: ['dk', 'no', 'se', 'fi'],
  },
  // {
  //   label: 'specific_industry',
  //   filter: 'industry',
  //   suggestions: 'specificIndustry',
  //   category: category.COMPANY_INFO,
  //   options: options.OPTIONS,
  //   countries: ['dk', 'no', 'se', 'fi'],
  //   transformValue: (industries) => {
  //     return industries?.map((industry) => industry.toString())
  //   },
  // },
  {
    label: 'tree-industry',
    filter: 'industry',
    category: category.COMPANY_INFO,
    options: options.NESTED_OPTIONS,
    countries: ['dk', 'no', 'se', 'fi'],
  },
  {
    label: 'score',
    filter: 'score',
    category: category.CREDIT,
    options: options.RANGE,
    countries: ['dk', 'no', 'se', 'fi'],
    transformValue: (range) => {
      let { from, to } = range ?? {}

      if (isNilOrEmpty(from) && isNilOrEmpty(to)) {
        return null
      }

      from = isNilOrEmpty(from) ? 1 : from
      to = isNilOrEmpty(to) ? 10 : to

      return {
        from: Math.max(Number(from), 1),
        to: Math.min(Number(to), 10),
      }
    },
  },
  {
    label: 'employees',
    filter: 'employees',
    suggestions: 'employee',
    category: category.COMPANY_INFO,
    options: options.OPTIONS,
    countries: ['dk', 'se', 'no', 'fi'],
  },
  {
    label: 'auditor',
    filter: 'auditor',
    category: category.COMPANY_INFO,
    options: options.TEXT,
    countries: ['dk'],
  },
  {
    label: 'employees_number',
    filter: 'employees_number',
    category: category.COMPANY_INFO,
    options: options.RANGE_FROM_TO,
    countries: ['dk', 'no'],
    transformValue: (range) => {
      const { from, to } = range ?? {}

      if (isNilOrEmpty(from) && isNilOrEmpty(to)) {
        return null
      }

      return {
        from: Number(from),
        to: Number(to),
      }
    },
  },
  {
    label: 'status_valid_from',
    filter: 'status_valid_from',
    category: category.COMPANY_INFO,
    options: options.RANGE_FROM_TO_DATE,
    countries: ['dk', 'no', 'se', 'fi'],
  },
  {
    label: 'company_status',
    filter: 'company_status',
    suggestions: 'companyStatus',
    category: category.COMPANY_INFO,
    options: options.OPTIONS,
    countries: ['dk', 'no', 'se', 'fi'],
  },
]

/******************
 * Filter Options *
 ******************/

/**
 * THESE NEEDS TO BE ADDED TO REDUX AS WELL!
 */
export const filterOptions = [
  {
    label: 'hide_advertisement_protected',
    filter: 'advertising',
    defaultValue: false,
    transformValue: (value) => (value === true ? false : null),
  },
  {
    label: 'has_email',
    filter: 'has_email',
    defaultValue: false,
    transformValue: (value) => (value === true ? true : null),
  },
  {
    label: 'has_webpage',
    filter: 'has_webpage',
    defaultValue: false,
    transformValue: (value) => (value === true ? true : null),
  },
  {
    label: 'has_phone_number',
    filter: 'has_phone_number',
    defaultValue: false,
    transformValue: (value) => (value === true ? true : null),
  },
  {
    label: 'show_inactive',
    filter: 'company_status',
    defaultValue: false,
    transformValue: (value) => (value === true ? null : { include: 'ACTIVE' }),
  },
  {
    label: 'has_auditor',
    filter: 'has_auditor',
    defaultValue: false,
    transformValue: (value) => (value === true ? true : null),
  },
  {
    label: 'hide_holding_company',
    filter: 'industry',
    defaultValue: false,
    transformValue: () => null,
    // transformValue: (value) =>
    //   value === true
    //     ? suggestions
    //         .filter((x) => ![642010, 642020, 642030].includes(x.value))
    //         .map((x) => x.value)
    //     : null,
  },
]
