// @flow

import { Tooltip } from '@mui/material'
import { Button } from 'components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faLock } from '@fortawesome/free-solid-svg-icons'

import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

import PhoneNumber from 'awesome-phonenumber'
import { handleAddressField } from 'utils'

import { handleComponentRenderRestrictions } from 'services/helpers'

import { useAccountStatus, useCompanyBasics } from 'services/queries'
import { useCreditCheck } from '../../../context'
import { downloadAs } from 'services/export'

const ExportCompanyInfoTo = () => {
  const { localId } = useCreditCheck()
  const companyBasicsQuery = useCompanyBasics(localId)
  const data = companyBasicsQuery.data
  const accountStatusQuery = useAccountStatus()

  const reactIntl = useIntl()
  const disabled = !handleComponentRenderRestrictions({
    component: 'export_key_figures',
    plan: accountStatusQuery.data?.subscription_plan,
  })
  const formatted_phone =
    data?.phone?.phone_number && !data?.phone.hidden
      ? new PhoneNumber(data?.phone?.phone_number, data?.local_organization_id?.country)
      : null
  const simplifiedData = [
    {
      type: reactIntl.messages[intl.companyInfo('company-type')],
      data: data?.company_type ? data?.company_type?.long : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('address')],
      data: data?.address ? handleAddressField(data?.address) : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('ad-protection')],
      data: data?.advertisement_protection ? 'Ja' : 'Nej',
    },
    {
      type: reactIntl.messages[intl.mainRecommendation('auditor_selected')],
      data: data?.auditor_selected ? 'Ja' : 'Nej',
    },
    {
      type: reactIntl.messages[intl.companyInfo('number-of-employees')],
      data:
        data?.average_number_of_employees?.value &&
        data?.average_number_of_employees?.date
          ? `${data?.average_number_of_employees?.value}: ${data?.average_number_of_employees?.date}`
          : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('bank')],
      data: data?.bank?.company_name,
    },
    {
      type: reactIntl.messages[intl.companyInfo('share-capital')],
      data: `${data?.registered_capital?.currency} ${reactIntl.formatNumber(
        data?.registered_capital?.value
      )} ${'⠀'}`,
    },
    {
      type: reactIntl.messages[intl.companyInfo('company-name')],
      data: data?.company_name,
    },
    {
      type: reactIntl.messages[intl.companyInfo('secondary-names')],
      data: data?.company_secondary_names
        ? data?.company_secondary_names?.map((y) => `${y?.name}, `)
        : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('date-of-establishment')],
      data: data?.date_of_incorporation,
    },
    {
      type: reactIntl.messages[intl.companyInfo('ean')],
      data: data?.ean,
    },
    {
      type: reactIntl.messages[intl.companyInfo('email')],
      data: data?.email?.email,
    },
    {
      type: reactIntl.messages[intl.companyInfo('financial_year')],
      data:
        data?.financial_year?.start && data?.financial_year?.end
          ? `${data?.financial_year?.start
              ?.toString()
              ?.substring(2)
              ?.split('-')
              ?.reverse()
              ?.join('.')} - ${data?.financial_year?.end
              ?.toString()
              ?.substring(2)
              ?.split('-')
              ?.reverse()
              ?.join('.')}`
          : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('company-id')],
      data: data?.local_organization_id ? data?.local_organization_id?.id + '⠀' : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('industry')],
      data: data?.main_industry_code
        ? data?.main_industry_code?.code + '⠀' + data?.main_industry_code?.description
        : '',
    },
    {
      type: 'Nace',
      data: data?.main_industry_code?.nace
        ? data?.main_industry_code?.nace?.code +
          '⠀' +
          data?.main_industry_code?.nace?.description
        : '',
    },
    {
      type: 'Isic',
      data: data?.main_industry_code?.isic
        ? data?.main_industry_code?.isic?.code +
          '⠀' +
          data?.main_industry_code?.isic?.description
        : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('webpage')],
      data: data?.webpage,
    },
    {
      type: reactIntl.messages[intl.companyInfo('phone')],
      data: data?.phone ? formatted_phone?.getNumber('international') : '',
    },
    {
      type: reactIntl.messages[intl.companyInfo('powers-to-bind')],
      data: data?.powers_to_bind,
    },
    {
      type: reactIntl.messages[intl.companyInfo('listed')],
      data: data?.listed,
    },
    {
      type: reactIntl.messages[intl.companyInfo('webpage')],
      data: data?.webpage,
    },
  ]

  const handleClick = () => {
    const download = downloadAs('excel')
    download(
      `${reactIntl.messages[intl.companyInfo('title')]} for ${data?.company_name
        ?.replace('/', '')
        ?.replace('?', '')
        ?.replace('*', '')
        ?.replace('[', '')
        ?.replace(']', '')}`,
      simplifiedData.filter((x) => x?.data)
    )
  }
  return (
    <Tooltip
      placement="top"
      title={
        disabled ? (
          <FormattedMessage id={intl.restriction('create-new-list-content')} />
        ) : (
          ''
        )
      }
    >
      <Button
        onClick={handleClick}
        disabled={disabled}
        style={{ flex: 1 }}
        icon={<FontAwesomeIcon icon={disabled ? faLock : faFileExport} />}
      >
        {<FormattedMessage id={intl.riskMonitoringNew('export-companies-button')} />}
      </Button>
    </Tooltip>
  )
}

export default ExportCompanyInfoTo
