type DataItem = {
  key: string
  visible: boolean
}

export const mergeVisibilityData = (
  originalData: DataItem[],
  visibilityData: { [key: string]: boolean }
): DataItem[] => {
  return originalData.map((item) => {
    const visible = visibilityData[item.key]
    return { ...item, visible: visible !== undefined ? visible : item.visible }
  })
}
