import { Theme } from '@mui/material/styles'

const root = {
  width: '100%',
}

const item = {
  padding: '0.5rem 1.5rem 0.5rem 2.5rem',
}

const nested = {
  paddingLeft: '3rem',
}

const iconButton = {
  padding: '0.5rem',
  color: 'primary.700',
}

const textField = {
  '.MuiInputBase-root': { border: 'none', padding: 0, height: '24px' },
  input: { padding: 0 },
}

const flagIcon = { height: 12, borderRadius: 0 }

const dialogTitle = { backgroundColor: 'primary.dark', color: 'common.white' }

const dialogContent = {
  paddingX: 5,
  paddingTop: (theme: Theme) => `${theme.spacing(3)} !important`,
  paddingBottom: 3,
}

export const classes = {
  root,
  item,
  nested,
  iconButton,
  textField,
  flagIcon,
  dialogTitle,
  dialogContent,
}
