import { default as cx } from 'classnames'

import { Typography, Button } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from '../styles/not-supported'

const NotSupported = () => {
  const classes = useStyles()
  return (
    <div
      className={cx(
        classes.mainContainer,
        'flex w-screen h-screen justify-center items-center bg-risika flex-col'
      )}
    >
      <div className={cx(classes.container, 'flex mb-6')}>
        <Typography variant="h1" className="font-bold text-super-large text-white mr-16">
          {<FormattedMessage id={intl.notFound('not-supported-header')} />}
        </Typography>
        <img
          alt=""
          className={classes.risikaLogo}
          src="https://dashboard.risika.dk/risika/logo-blue-white.svg"
        />
      </div>
      <p className="text-white max-w-xl text-center text-lg">
        {<FormattedMessage id={intl.notFound('not-supported-text')} />}
      </p>
      <div className={classes.links}>
        <div>
          <Button
            className={cx(classes.button, 'mr-8 px-6 py-3 rounded text-lg')}
            target="_blank"
            href="https://www.google.com/chrome/"
          >
            Google Chrome
          </Button>
          <Typography className="text-white max-w-xl text-center text-lg">
            {<FormattedMessage id={intl.notFound('best-experience')} />}
          </Typography>
        </div>
        <div>
          <Button
            className={cx(classes.button, 'mr-8 px-6 py-3 rounded text-lg')}
            target="_blank"
            href="https://www.mozilla.org/en-US/firefox/new/"
          >
            Mozilla Firefox
          </Button>
          <Typography className="text-white max-w-xl text-center text-lg">
            {<FormattedMessage id={intl.notFound('better-performance')} />}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default NotSupported
