import {
  SUBMIT_GET_COMPANY_INFORMATION,
  SHOW_HIDE_TABLE_FIELD,
  STORE_SETTING,
  SUBMIT_GET_ALL_USERS,
  SET_USERS_SUCCESS,
  SET_USERS_STATUS,
} from './constants'

/**************
 * Saga calls *
 **************/
// export const submitFetchSomeData = ({
//   prop1,
// }: dataTypeProp1): AsyncAction<dataTypeProp1> => {
//   return {
//     type: CONSTANT_NAME_1,
//     payload: { prop1 },
//     status: 'LOADING',
//   }
// }
export const submitGetCompanyInformation = (userId) => {
  return {
    type: SUBMIT_GET_COMPANY_INFORMATION,
    payload: userId,
    status: 'LOADING',
  }
}
export const fetchUsers = () => ({
  type: SUBMIT_GET_ALL_USERS,
  status: 'LOADING',
})

/*****************
 * Reducer calls *
 *****************/

// export const storeSomeData = (data: dataTypes): AsyncAction<dataTypes> => {
//   return {
//     type: CONSTANT_NAME_2,
//     payload: data,
//     status: 'SUCCESS',
//     a,
//   }
// }
export const showHideTableField = (key, value) => ({
  type: SHOW_HIDE_TABLE_FIELD,
  payload: { key, value },
  status: 'SUCCESS',
})
export const storeSetting = (name, value) => ({
  type: STORE_SETTING,
  payload: { name, value },
  status: 'SUCCESS',
})
export const setUsersSuccess = (data) => ({
  type: SET_USERS_SUCCESS,
  payload: { data },
  status: 'SUCCESS',
})
export const setUsersFailed = (data) => ({
  type: SET_USERS_STATUS,
  payload: {},
  status: 'FAILURE',
})
