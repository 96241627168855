import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import PaperContainer from '../PaperContainer'
import intl from 'localization/components'
import { SingleSelect } from 'components-new'
import { Box } from '@mui/material'
import CompanyVisits from './CompanyVisits'
import PeopleVisits from './PeopleVisits'

function VisitsList() {
  const [view, setView] = React.useState(() => {
    const storedView = localStorage.getItem('view')
    return storedView ?? 'companies'
  })

  React.useEffect(() => {
    localStorage.setItem('view', view)
  }, [view])

  return (
    <PaperContainer
      title={<FormattedMessage id={intl.home('recently-viewed')} />}
      customHeaderComponent={
        <Box data-cy="visits-list-view">
          <SingleSelect
            options={['companies', 'people']}
            value={view}
            handleChange={(value) => setView(value)}
            sx={{ height: '48px' }}
          />
        </Box>
      }
    >
      {view === 'companies' ? <CompanyVisits /> : <PeopleVisits />}
    </PaperContainer>
  )
}
export default VisitsList
