import { Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

const ChartWithTextSkeleton = () => (
  <Box pr={4} pl={4}>
    {Array.from(Array(4)).map((_, i) => (
      <Box height={85} mb={4} sx={{ display: 'flex', alignItems: 'center' }} key={i}>
        <Skeleton variant="circular" width={70} height={60} sx={{ mr: 2 }} />
        <Skeleton width="100%" height={130} />
      </Box>
    ))}
  </Box>
)
export default ChartWithTextSkeleton
