/**
 * Authorization Roles
 */
const authRoles = {
  staff: ['staff'],
  admin: ['staff', 'admin'],
  developer: ['developer'],
  user: ['staff', 'admin', 'user', 'developer'],
  guest: ['staff', 'admin', 'user', 'guest', 'developer'],
  onlyGuest: ['guest'],
}

export default authRoles
