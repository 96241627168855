import moment from 'moment'
import { riskColors } from 'configs/constants/theme'
import { colors } from 'configs'

export const extendedScoreColors = {
  1: riskColors.high,
  2: riskColors.high,
  3: riskColors.mediumHigh,
  4: riskColors.mediumHigh,
  5: riskColors.medium,
  6: riskColors.medium,
  7: riskColors.mediumLow,
  8: riskColors.mediumLow,
  9: riskColors.low,
  10: riskColors.low,
}

const formatYears = (date) => {
  return moment(date).format('YYYY')
}

const formatDates = (acc, current) => {
  // Handle the first 2
  if (!acc.length) {
    if (formatYears(acc.name) === formatYears(current.name)) {
      return [
        {
          ...acc,
          name: formatYears(acc.name),
        },
        {
          ...current,
          name: formatYears(current.name),
        },
      ]
    }
    return [
      {
        ...acc,
        name: formatYears(acc.name),
      },
      {
        ...current,
        name: formatYears(current.name),
      },
    ]
  }

  // In case there are 2 changes in the same year add the month otherwise leave just the year
  const last = acc[acc.length - 1]
  if (formatYears(last.from) === formatYears(current.from)) {
    const allButLast = acc.slice(0, acc.length - 1)

    return [
      ...allButLast,
      {
        ...last,
        name: formatYears(last.name),
      },
      {
        ...current,
        name: formatYears(current.name),
      },
    ]
  }
  return [
    ...acc,
    {
      ...current,
      name: formatYears(current.name),
    },
  ]
}

const removeSameScores = (acc, current, index, array) => {
  // Add the 'from' and 'to' to the first one and make it an array
  if (array.length === 1) {
    return [array]
  }
  if (!acc?.length) {
    if (current?.score !== acc.score) {
      return [
        {
          ...acc,
          from: acc.name,
          to: acc.name,
        },
        {
          ...current,
          from: acc.name,
        },
      ]
    }
    return [
      {
        ...acc,
        from: acc.name,
      },
    ]
  }

  const lastItem = acc?.length ? acc.length - 1 : 0
  const allButLast = acc.slice(0, -1)
  const last = acc[acc.length - 1]

  // If the year changes it adds a new entry
  if (moment(acc[lastItem].name).format('YYYY') !== moment(current.name).format('YYYY')) {
    return [
      ...allButLast,
      { ...last, to: current.name },
      { ...current, from: current.name },
    ]
  }

  // If nothing changes return the acc
  if (acc[lastItem].score === current.score) {
    return acc
  }

  // If the score changes then update the last one with the 'to' date and add the next one
  return [
    ...allButLast,
    { ...last, to: current.name },
    { ...current, from: current.name },
  ]
}

export const fitDataToRequirements = (scores) => {
  if (scores?.length < 2) {
    return scores
  }

  return (
    scores
      ?.map((item) => {
        return {
          name: item.date,
          score: item.score,
          probability_of_distress: item?.probability_of_distress
            ? Number(item?.probability_of_distress * 100)
            : null,
        }
      })
      ?.reverse()
      // Removes redundant scores
      ?.reduce(removeSameScores)
      // Adds the month in cases where it is needed
      ?.reduce(formatDates)
  )
}

export const removeUnusedData = (scores) => {
  // Removes null scores and entries older than 5 years including the current one
  return (
    scores
      ?.filter((x) => !x.error)
      ?.filter((x) => x.score)
      ?.filter((x) => moment().diff(x.date, 'years', false) < 4) ?? []
  )
}

export const getScore = (score) => {
  if (!score) return riskColors.unknown
  return extendedScoreColors[score]
}

export const getDarkerScore = (score) => {
  return colors?.[`score_rating_${score}`] ?? colors?.noScoreDark
}

export const calculateTiltOptionsAngle = (width, data) => {
  if (data?.length <= 3) {
    return 0
  }
  if (width > 400) {
    return 0
  }
  return 40
}

export const calculateTiltOptionsFontSize = (width, data) => {
  if (data?.length >= 8) {
    return undefined
  }
  if (width > 400) {
    return undefined
  }
  return '1.2rem'
}
