// @flow

import * as React from 'react'

const TreeSelectContext = React.createContext(null)

/**
 * Get the tree context.
 *
 * @returns {React.Context<any>} The context.
 */
export function useTreeContext() {
  const ctx = React.useContext(TreeSelectContext)

  if (ctx != null) {
    return ctx
  }

  throw new Error("'useTreeSelect' must be used within the 'TreeSelectProvider'")
}

export default function TreeSelectProvider({ value, children }) {
  return <TreeSelectContext.Provider value={value}>{children}</TreeSelectContext.Provider>
}
