import { Theme } from '@mui/material/styles'

const styles = {
  containerMain: (singleInputRangeField: boolean) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
    position: 'relative',
    width: '100%',
    backgroundColor: singleInputRangeField ? 'white' : 'transparent',
    overflow: 'hidden',
    borderRadius: singleInputRangeField ? 1 : 0,
    borderTopLeftRadius: singleInputRangeField ? 0 : 1,
    borderBottomLeftRadius: singleInputRangeField ? 0 : 1,
    height: 44,
    border: (theme: Theme) =>
      singleInputRangeField ? `1px solid ${theme.palette.grey[200]}` : 'none',
    '&:hover': {
      border: (theme: Theme) =>
        singleInputRangeField ? `1px solid ${theme.palette.grey[400]}` : 'none',
    },
  }),
  singleFieldStyles: {
    borderStyle: 'none',
    input: {
      textAlign: 'center',
    },
    '& .MuiInputBase-input::placeholder': {
      textAlign: 'center',
    },
  },
}

export default styles
