import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))
export const TableRowTitle = ({ children, variant = 'subtitle1', isDash, isSpace }) => {
  const classes = useStyles()

  return (
    // <td style={{ whiteSpace: 'nowrap' }}>
    <td>
      <Typography
        sx={{ width: '108%' }}
        variant={variant}
        className={!isDash ? classes.bold : null}
      >
        {isDash && '- '}
        {children}
      </Typography>
    </td>
  )
}
