import { colors } from 'configs'
import { defaultVerticalBarChartProperties } from 'configs/charts'

import intl from 'localization/components'
export const distributionData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [{ category: 'SCORE', function: 'COUNT' }],
    group_by: [{ category: 'SCORE' }],
  },
  chartProperties: {
    ...defaultVerticalBarChartProperties,
    tooltipProgressBar: true,
    valueType: 'CREDIT_SCORE',
    title: intl.observationalLists('risika-score-distribution-title'),
    height: 400,
  },
}

export type DistributionData = typeof distributionData

export const barColors = [
  colors.riskIntelligence.highRisk,
  colors.riskIntelligence.highRisk,
  colors.riskIntelligence.highRisk,
  colors.riskIntelligence.mediumRisk,
  colors.riskIntelligence.mediumRisk,
  colors.riskIntelligence.mediumRisk,
  colors.riskIntelligence.lowRisk,
  colors.riskIntelligence.lowRisk,
  colors.riskIntelligence.lowRisk,
  colors.riskIntelligence.lowRisk,
  colors.riskIntelligence.lowRisk,
  colors.riskIntelligence.noScore,
]
