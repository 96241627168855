const buttonStyles = {
  padding: '12px',
  borderStyle: 'solid',
  borderWidth: '0 1px 0 1px',
  borderColor: 'grey.200',
  borderRadius: '0 0 4px 4px',
  backgroundColor: 'primary.100',
  display: 'flex',
  justifyContent: 'flex-start',
  color: 'primary.700',
  '&:hover': {
    backgroundColor: 'primary.300',
  },
  '&:focus': {
    borderColor: 'primary.500',
    borderRadius: '2px',
  },
  '&:active': {
    border: 'none',
    backgroundColor: 'primary.300',
  },
  '&:disabled': {
    backgroundColor: 'grey.300',
    color: 'grey.600',
  },
}

const labelStyles = {
  fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 600,
  fontSize: 16,
  letterSpacing: 1,
  lineHeight: '20px',
  textTransform: 'none',
}

export default { buttonStyles, labelStyles }
