import { useState } from 'react'
import { uniq } from 'ramda'
import {
  ClickAwayListener,
  List,
  Paper,
  Stack,
  ListItemText,
  MenuItem,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ChipStack } from 'components-new'
import * as React from 'react'
import {
  AsyncSearchMultiSelectPropsType,
  OptionsType,
} from './AsyncSearchMultiSelect.types'
import { InputField } from 'components-new'
import styles from './AsyncSearchMultiSelect.styles'
import AsyncSearchMultiSelectLoader from './AsyncSearchMultiSelectLoader'

const { menuItem, listItem } = styles

const AsyncSearchMultiSelect = ({
  title,
  searchText,
  setSearchText,
  options,
  values,
  setValues,
  chipData,
  placeholder,
  inputProps,
  isLoading = false,
}: AsyncSearchMultiSelectPropsType) => {
  const [isListActive, setIsListActive] = useState(false)

  const handleCheck = (value: string) => {
    setValues((prevState) => {
      if (prevState.includes(value)) {
        return prevState
      } else {
        return [...prevState, value]
      }
    })
    setIsListActive(false)
  }
  const handleChipDelete = (value: string) => {
    setValues(values.filter((v: string) => v !== value))
  }
  const handleClickAway = () => {
    const prevValues = chipData.map((val) => val.value)
    setValues(prevValues)
    setIsListActive(false)
  }

  return (
    <Stack height={1} sx={{ width: '100%' }} spacing={3}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack gap={1}>
          <InputField
            label={title}
            placeholder={placeholder as string}
            value={searchText}
            handleChange={setSearchText}
            startAdornment={<SearchIcon />}
            extraStyles={{ '.MuiInput-input': { paddingLeft: 2 } }}
            fullWidth
            clickIn={() => setIsListActive(true)}
            {...inputProps}
          />
          {isListActive && (
            <Paper>
              {isLoading ? (
                <AsyncSearchMultiSelectLoader />
              ) : (
                <List sx={{ maxHeight: 524, overflow: 'scroll', padding: 0 }}>
                  {options &&
                    uniq(options)
                      .filter((item: OptionsType) =>
                        item.label.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .map(({ value, label, secondaryLabel }: OptionsType) => (
                        <MenuItem
                          onClick={() => handleCheck(value)}
                          sx={menuItem}
                          key={value}
                          value={value}
                        >
                          <ListItemText
                            sx={listItem}
                            primary={label}
                            secondary={secondaryLabel}
                            secondaryTypographyProps={{
                              whiteSpace: 'normal',
                            }}
                          />
                        </MenuItem>
                      ))}
                </List>
              )}
            </Paper>
          )}
        </Stack>
      </ClickAwayListener>
      {chipData?.length ? (
        <ChipStack
          isLoading={isLoading}
          chipData={chipData}
          setValues={setValues}
          handleChipDelete={handleChipDelete}
        />
      ) : null}
    </Stack>
  )
}

export default AsyncSearchMultiSelect
