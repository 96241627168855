import { AvailableCountries } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

const updateMonitorLists = (data?: any) => {
  return risikaAPI.post(`/add`, data, {
    microservice: 'monitor',
    country: '' as AvailableCountries,
  })
}

export default updateMonitorLists
