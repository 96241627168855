import { store } from 'context/store'
/**
 * Copy the given string to the user's clipboard.
 *
 * This uses an intermediate container,
 * a hidden textarea, in order to copy.
 *
 * @param {string} str The string to copy.
 */
export const copyToClipboard = (str) => {
  const el = document.createElement('textarea')

  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'

  document.body.appendChild(el)

  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false

  el.select()

  document.execCommand('copy')
  document.body.removeChild(el)

  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

/*
 *  Prop is the item of the array of objects you want to be unique [ {id: 1, name: Jack}, { id: 2, name: Jack} ]
 *  in this case if you pass 'name' as prop it will remove one of the entries.
 */

export const isURL = (str) => {
  const urlregex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
  if (urlregex.test(str)) {
    return true
  } else {
    return false
  }
}

export const handleComponentRenderRestrictions = ({ component, local_id, plan }) => {
  const { roles } = store.getState().auth.user
  if (component === 'not-restricted') {
    return true
  }
  const creditPolicyFields = [
    'exclude_risika_scores',
    'exclude_company_types',
    'negative_equity_limit',
    'age_limit',
    'exclude_level_of_auditor_assistances',
    'bankruptcy_limit',
    'exclude_companies',
    'exclude_persons',
    'exclude_industries',
    'monitor_add_to_policy_list_button',
    'credit_policy',
  ]
  // Check if a company is stored by the local id
  // const customerType = 'free'
  const checkUsed =
    !!store.getState()?.risika?.creditCheck?.cachedPages?.[local_id?.id]?.ratingCredit
  if (plan === 'FREEMIUM') {
    const freeTierBlacklist = [
      'map',
      'credit-report',
      'hierarchy-list',
      'owners-advanced',
      'ratios-advanced',
      'historical-scores',
      'view-switch',
      'main-recommendation',
      'main-recommendation-overview',
      'rating-credit',
      'create-new-list',
      'company-highlights',
      'auditor-information',
      'key-figures',
      'risika-score',
      'score',
      'export',
      'exclude_level_of_auditor_assistances',
      'bankruptcy_limit',
      'exclude_companies',
      'exclude_persons',
      'exclude_industries',
      'advanced_fields_copy_policy',
      'export_company_info',
      'export_key_figures',
    ]
    const creditActivated = [
      'main-recommendation',
      'main-recommendation-overview',
      'company-highlights',
      'auditor-information',
      'risika-score',
    ]
    if (creditActivated.includes(component) && checkUsed) {
      return true
    }
    if (freeTierBlacklist.includes(component)) {
      return false
    } else {
      // // Comment this out for fremium credit pocisy
      // if (creditPolicyFields.includes(component)) {
      //   if (roles.includes('admin') || plan === 'FREEMIUM') {
      //     return false
      //   } else {
      //     // Uncoment this when you roll out the feature
      //     // return false
      //     return true
      //   }
      // }
      // Untill here
      return true
    }
  } else {
    // Handle credit policy
    if (creditPolicyFields.includes(component)) {
      if (roles?.includes('admin') || plan === 'FREEMIUM') {
        return true
      } else {
        // Uncoment this when you roll out the feature
        return false
        // return true
      }
    }
  }
  if (plan) {
    if (plan !== 'FREEMIUM') {
      return true
    } else {
      return false
    }
  }

  return false
}
