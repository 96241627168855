const container = {
  width: '100%',
  flexDirection: 'row' as 'row',
  display: 'flex',
  justifyContent: 'space-between',
}

const companyDetails = {
  overflow: 'hidden',
  height: '80px',
}

const dropdownContainer = {
  minWidth: '150px',
}

const selectContainer = {
  height: '22rem',
  overflow: 'scroll',
}

const primaryDropdown = {
  width: '150px',
  marginTop: '18px',
  marginBottom: '18px',
}

export default {
  container,
  companyDetails,
  dropdownContainer,
  selectContainer,
  primaryDropdown,
}
