import BoxContainer from 'components-new/layouts/BoxContainer'
import { useRatingScores } from 'services/queries'
import intl from 'localization/components'
import PerformanceBarChart from 'components-new/charts/PerformanceBarChart/PerformanceBarChart'
import {
  fitDataToRequirements,
  removeUnusedData,
} from 'components-new/charts/PerformanceBarChart/PerformanceBarChart.controller'
import { Collapse } from '@mui/material'
import ErrorScene from './ErrorScene'
import { LoadingSkeleton } from 'components-new'
import HistoricalScoresBoxSkeleton from './HistoricalScoresBox.skeleton'

import { WidgetWrapper } from 'pages/credit-check/scenes/MainRecommendation/components/WidgetWrapper/WidgetWrapper'

const HistoricalScoresBox = (props) => {
  const {
    localId,
    isReArrange,
    comparison,
    widgetNameDND,
    isActive = true,
    setIsActive,
  } = props

  const ratingScoresQuery = useRatingScores(localId, 60000)

  const hasHistoricalScores =
    fitDataToRequirements(removeUnusedData(ratingScoresQuery.data?.historical_scores))
      .length > 1
  const boxData = {
    title: intl.mainRecommendation('performance'),
    status: {
      isLoading: ratingScoresQuery.isLoading,
      isError: !hasHistoricalScores && !ratingScoresQuery.isLoading,
    },
    height: 190,
    skeletonType: 'HORIZONTAL_BAR',
  }

  const styles = { margin: comparison ? 0 : 4 }

  return (
    <LoadingSkeleton
      SkeletonComponent={HistoricalScoresBoxSkeleton}
      isLoading={ratingScoresQuery.isLoading}
    >
      <WidgetWrapper
        isReArrange={isReArrange}
        widgetName={widgetNameDND}
        isActive={!!isActive}
        setIsActive={setIsActive}
        stylesOverride={styles}
        dataCy={widgetNameDND}
      >
        <Collapse in={!isReArrange}>
          {hasHistoricalScores ? (
            <BoxContainer boxData={boxData}>
              {!boxData.status.isLoading && !boxData.status.isError ? (
                <PerformanceBarChart
                  historicalScores={ratingScoresQuery.data?.historical_scores}
                />
              ) : null}
            </BoxContainer>
          ) : (
            <ErrorScene />
          )}
        </Collapse>
      </WidgetWrapper>
    </LoadingSkeleton>
  )
}

export default HistoricalScoresBox
