// @flow
const OPEN_FOLDED_NAVBAR = '[NAVBAR] OPEN FOLDED'
const CLOSE_FOLDED_NAVBAR = '[NAVBAR] CLOSE FOLDED'
const TOGGLE_FOLDED_NAVBAR = '[NAVBAR] TOGGLE FOLDED'
const TOGGLE_MOBILE_NAVBAR = '[NAVBAR] TOGGLE MOBILE'
const OPEN_MOBILE_NAVBAR = '[NAVBAR] OPEN MOBILE'
const CLOSE_MOBILE_NAVBAR = '[NAVBAR] CLOSE MOBILE'

export function navbarToggleFolded() {
  return {
    type: TOGGLE_FOLDED_NAVBAR,
  }
}

export function navbarOpenFolded() {
  return {
    type: OPEN_FOLDED_NAVBAR,
  }
}

export function navbarCloseFolded() {
  return {
    type: CLOSE_FOLDED_NAVBAR,
  }
}

export function navbarToggleMobile() {
  return {
    type: TOGGLE_MOBILE_NAVBAR,
  }
}

export function navbarOpenMobile() {
  return {
    type: OPEN_MOBILE_NAVBAR,
  }
}

export function navbarCloseMobile() {
  return {
    type: CLOSE_MOBILE_NAVBAR,
  }
}

const initialState = {
  foldedOpen: true,
  mobileOpen: false,
}

const navbar = function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FOLDED_NAVBAR: {
      return {
        ...state,
        foldedOpen: !state.foldedOpen,
      }
    }
    case OPEN_FOLDED_NAVBAR: {
      return {
        ...state,
        foldedOpen: true,
      }
    }
    case CLOSE_FOLDED_NAVBAR: {
      return {
        ...state,
        foldedOpen: false,
      }
    }
    case TOGGLE_MOBILE_NAVBAR: {
      return {
        ...state,
        mobileOpen: !state.mobileOpen,
      }
    }
    case OPEN_MOBILE_NAVBAR: {
      return {
        ...state,
        mobileOpen: true,
      }
    }
    case CLOSE_MOBILE_NAVBAR: {
      return {
        ...state,
        mobileOpen: false,
      }
    }
    default: {
      return state
    }
  }
}

export default navbar
