import { UseUserSelfResponse } from '../../types/queries'
import { WindowWithHubspot } from './HomePage.types'

export const initializeHubSpot = (data: UseUserSelfResponse | undefined) => {
  const _hsq = ((window as WindowWithHubspot)._hsq =
    (window as WindowWithHubspot)._hsq || [])
  if (data) {
    _hsq.push([
      'identify',
      {
        email: data.email,
      },
    ])
  }
  _hsq.push(['trackPageView'])
}
