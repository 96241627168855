type Coordinate = {
  radius: number
  cx: number
  x: number
  y: number
}

type Coordinates = {
  top: Coordinate
  left: Coordinate
  right: Coordinate
  bottom: Coordinate
}

type CustomShapeProps = {
  points: number[]
  stroke: string
  fill: string
  fillOpacity: string
}

const renderCurve = ({ top, left, right, bottom }: Coordinates) => {
  const roundness = 50 // Maximum distance for control point (bigger === rounder)
  const numberTop = (top.radius / top.cx) * roundness
  const numberRight = (right.radius / right.cx) * roundness
  const numberBottom = (bottom.radius / bottom.cx) * roundness
  const numberLeft = (left.radius / left.cx) * roundness
  return `M${top.x} ${top.y}
      C ${top.x + numberTop} ${top.y}, ${right.x} ${right.y - numberRight}, ${right.x} ${
    right.y
  }
      C ${right.x} ${right.y + numberRight}, ${bottom.x + numberBottom} ${bottom.y}, ${
    bottom.x
  } ${bottom.y}
      C ${bottom.x - numberBottom} ${bottom.y}, ${left.x} ${left.y + numberLeft}, ${
    left.x
  } ${left.y}
       C ${left.x} ${left.y - numberLeft}, ${top.x - numberTop} ${top.y}, ${top.x} ${
    top.y
  }`
}
const directionText = (i: number) => {
  if (i === 0) {
    return 'top'
  }
  if (i === 1) {
    return 'right'
  }
  if (i === 2) {
    return 'bottom'
  }
  return 'left'
}

const CustomShape = (props: CustomShapeProps) => {
  const { points, stroke, fill, fillOpacity } = props

  const pointObject = points.reduce((acc, curr, index) => {
    return { ...acc, [directionText(index)]: curr }
  }, {} as Coordinates)

  return (
    <path
      d={renderCurve(pointObject)}
      stroke={stroke}
      strokeWidth="2"
      fillOpacity={fillOpacity}
      fill={fill}
      strokeLinejoin="round"
    />
  )
}
export default CustomShape
