import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'
import { PaymentRemarksData } from 'types/general'
/******************
 * Fetch from API *
 ******************/
async function paymentRemarks({ id, country }: LocalId): Promise<PaymentRemarksData> {
  const { data } = await risikaAPI.get(`/company/events/payment_remarks/${id}`, {
    microservice: 'data',
    country,
  })
  return data
}

export default paymentRemarks
