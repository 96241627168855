import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const container: SxProps = {
  flexDirection: 'column',
  margin: '2rem',
  flex: 1,
  alignItems: 'center',
  minHeight: '110px',
  [theme.breakpoints.down('xs')]: {
    width: 'auto',
  },
  '@media screen and (max-width: 1075px)': {
    // Had to be done it brakes in a weird time
    display: 'flex',
  },
  '& .MuiTypography-h6': {
    fontWeight: '600 !important',
  },
}

const textContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  whiteSpace: 'nowrap',
  marginBottom: '1rem',
  '@media screen and (max-width: 1535px)': {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const title: SxProps = {
  fontSize: '1.6rem',
  textAlign: 'left',
  '@media screen and (max-width: 1535px)': {
    alignSelf: 'flex-start !important',
  },
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'center !important',
  },
}

const description: SxProps = {
  textAlign: 'left',
  marginBottom: '0.2rem',
}

export const classes = {
  container,
  textContainer,
  title,
  description,
}
