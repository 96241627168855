import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

const container = (singleField: boolean, extraStyles: SxProps) =>
  !singleField
    ? extraStyles
    : {
        backgroundColor: 'common.white',
        border: (theme: Theme) => `1px solid ${theme.palette.grey[200]}`,
        borderRadius: 1,
        overflow: 'hidden',
        height: 44,
        '&:hover': {
          border: (theme: Theme) => `1px solid ${theme.palette.grey[400]}`,
        },
        ...extraStyles,
      }

export default { container }
