import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

export const singleSelect: SxProps = {
  height: 48,
  maxWidth: 350,
  margin: 4,
}

export const skeletonContainer: SxProps = {
  marginLeft: 4,
  marginRight: 4,
  marginTop: -6,
  marginBottom: 0,
}

export const skeleton: SxProps = {
  height: 140,
  width: '100%',
}

export const companyChange: SxProps = {
  padding: 4,
  paddingBottom: 3,
  paddingTop: 3,
  margin: 4,
  marginTop: 1,
  border: `0.5px solid ${theme.palette.grey[400]}`,
  borderRadius: '6px',
}

export const textBox: SxProps = {
  flexDirection: 'row',
  display: 'flex',
  marginBottom: 2,
}

export const fallbackText: SxProps = {
  margin: 'auto',
}

export const link = {
  textDecorationColor: theme.palette.primary.dark,
}

export const container = {
  marginBottom: theme.spacing(5),
}

export const flag = { marginTop: 2, height: 18 }
