import * as React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Divider, Popover, Typography, Stack } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import { navigation } from 'services/navigation'
import { setSearchSelected, navbarCloseMobile } from 'store_deprecated'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { UserMenuProps } from './types'
import { CurrencyInput, FlagInput } from 'components-new'
import { useAppShell } from './context'

const buttonFontStyle = {
  color: 'common.white',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}

const capitalizeFirstLetter = (string?: string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : ''

const UserMenu = ({ name, role }: UserMenuProps) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const isMenuOpen = Boolean(anchorEl)
  const appShell = useAppShell()

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClick = () => {
    appShell.setSearchActive(false)
    dispatch(setSearchSelected(false))
    dispatch(navbarCloseMobile())
    setAnchorEl(null)
  }

  const menuId = 'primary-search-account-menu'

  return (
    <Box>
      <Stack
        onClick={handleProfileMenuOpen}
        sx={{
          '&:hover': { backgroundColor: 'primary.dark' },
          '&:active': { backgroundColor: 'primary.dark' },
          border: 'none',
          cursor: 'pointer',
        }}
        bgcolor="primary.main"
        color="common.white"
        direction="row"
        alignItems="center"
        spacing={2}
        fontSize={16}
        p={2}
        borderRadius={1}
        data-cy="user-menu"
      >
        <AccountCircleIcon fontSize="inherit" color="inherit" />
        <Typography color="inherit" sx={buttonFontStyle}>
          {name}
        </Typography>
        <ExpandMoreIcon sx={{ fontSize: '28px' }} color="inherit" />
      </Stack>
      <Popover
        elevation={3}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
      >
        <Stack direction="column" spacing={4} p={4}>
          <Box>
            <Typography variant="subtitle1">
              <FormattedMessage id={intl.generic('roles')} />
            </Typography>
            <Typography variant="body1">{capitalizeFirstLetter(role)}</Typography>
          </Box>
          <FlagInput />
          <CurrencyInput />
        </Stack>
        <Box>
          <Divider />
          <Stack paddingX={4} paddingY={2} onClick={handleMenuClick}>
            <Link to={navigation.settings()}>
              <Stack
                direction="row"
                alignItems="center"
                color="#000000"
                fontSize={18}
                spacing={2}
                marginY="6px"
                data-cy="user-menu-settings"
              >
                <SettingsIcon color="inherit" fontSize="inherit" />
                <Typography variant="body1" color="inherit">
                  <FormattedMessage id={intl.navigation('settings')} />
                </Typography>
              </Stack>
            </Link>
            <Link to={navigation.auth.logout()}>
              <Stack
                direction="row"
                alignItems="center"
                color="#000000"
                fontSize={18}
                spacing={2}
                marginY="6px"
              >
                <LogoutIcon color="inherit" fontSize="inherit" />
                <Typography variant="body1" color="inherit">
                  <FormattedMessage id={intl.navigation('logout')} />
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </Box>
      </Popover>
    </Box>
  )
}

export default UserMenu
