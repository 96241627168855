import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Stack, Checkbox, Typography, Tooltip, Divider, Skeleton } from '@mui/material'
import SideDrawer from 'components-new/layouts/SideDrawer'
import { ButtonSecondary, DropdownSelect, InputArea } from 'components-new'
import styles from '../SidePanels.styles'
import { selectCountriesOptions } from './CreatePolicySidePanel.model'
import { PropsType } from './CreatePolicySidePanel.types'
import { navigation } from 'services/navigation'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { InfoOutlined } from '@mui/icons-material'
import { InputField } from 'components-new'
import SidePanelHeader from '../../PolicyBuilder/widgets/ActionPanel/widgets/SidePanelHeader'
import { useAccountStatus } from 'services/queries'
import { AvailableCountries } from 'globalTypes'
import { Mixpanel } from 'services/helpers/mixpanel'

const { container, inputContainer, checkBoxContainer, checkbox } = styles

const CreatePolicySidePanel = ({ isOpen, setIsOpen }: PropsType) => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [country, setCountry] = useState<string>('DK')
  const [checked, setChecked] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const { data, isFetching } = useAccountStatus({ refetchOnMount: true })

  const history = useHistory()

  useEffect(() => {
    if (name && country && description.length <= 300) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [name, country, description.length])

  const handleCheck = () => setChecked((prevState) => !prevState)

  const handleClose = () => setIsOpen(false)

  const handleButtonClick = () => {
    const policyList = 'null' // This should be null in case we are not loading an already existing policy

    history.push(
      navigation.creditPolicyBuilder({
        country,
        name: name,
        createActiveRule: checked,
        policyId: policyList,
        description,
      })
    )
    Mixpanel.track('User created new policy')
  }

  return (
    <SideDrawer
      drawerState={{
        drawerIsOpen: isOpen,
        setDrawerIsOpen: setIsOpen,
      }}
      side="right"
    >
      <Stack data-cy="credit-policy-panel" sx={container}>
        <SidePanelHeader
          title={<FormattedMessage id={intl.creditPolicyNew('create-a-policy-button')} />}
          onClose={handleClose}
        />
        <Divider />
        <Stack data-cy="create-policy-panel" sx={inputContainer}>
          <InputField
            data-cy="create-policy-name"
            label={<FormattedMessage id={intl.generic('name')} />}
            value={name}
            handleChange={setName}
          />
          {isFetching ? (
            <Stack spacing={2}>
              <Skeleton variant="rounded" width="25%" height={20} animation="wave" />
              <Skeleton variant="rounded" width="100%" height={42} animation="wave" />
            </Stack>
          ) : (
            <DropdownSelect
              data-cy="create-policy-select-country"
              options={selectCountriesOptions.filter((country) =>
                data?.legacy_rights.allow_countries.includes(
                  country.value.toUpperCase() as AvailableCountries
                )
              )}
              value={country}
              setValue={setCountry}
              inputProps={{ label: <FormattedMessage id={intl.generic('country')} /> }}
            />
          )}
          <InputArea
            data-cy="create-policy-description"
            maxCharacters={300}
            value={description}
            setValue={setDescription}
            label={<FormattedMessage id={intl.creditPolicyNew('description-text')} />}
          />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              alignItems="center"
              data-cy="create-policy-checkbox-container"
              sx={checkBoxContainer}
            >
              <Checkbox
                data-cy="create-policy-checkbox"
                color="primary"
                sx={checkbox}
                checked={checked}
                onClick={handleCheck}
              />
              <Typography variant="body1">
                <FormattedMessage
                  id={intl.creditPolicyNew('always-reject-inactive-companies')}
                />
              </Typography>
            </Stack>
            <Tooltip
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'primary.900',
                  },
                },
              }}
              placement="bottom"
              title={
                'Inactive companies are companies that have been ceased, dissolved, are under bankruptcy or under enforced closure... It is recommended to exclude such companies from your policy to ensure you don’t grant them credit'
              }
            >
              <InfoOutlined sx={{ width: '1.7rem', height: '1.7rem' }} />
            </Tooltip>
          </Stack>
        </Stack>
        <Stack
          p={4}
          alignItems="flex-end"
          sx={{ marginTop: 'auto', backgroundColor: 'grey.50' }}
        >
          <ButtonSecondary
            data-cy="create-policy-get-started-button"
            disabled={disabled}
            onClick={handleButtonClick}
          >
            <FormattedMessage id={intl.creditPolicyNew('get-started-text')} />
          </ButtonSecondary>
        </Stack>
      </Stack>
    </SideDrawer>
  )
}

export default CreatePolicySidePanel
