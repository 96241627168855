import { Modal } from '@mui/material'
import ModalCanvas from './ModalCanvas'
import { useIntl } from 'react-intl'
import intl from 'localization/components'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  policyInject: any
}

const ActivationPolicyModal = ({ isOpen, setIsOpen, policyInject }: Props) => {
  const { isEnabled, setIsEnabled } = policyInject()
  const reactIntl = useIntl()

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} closeAfterTransition>
      {isEnabled ? (
        <ModalCanvas
          title={reactIntl.formatMessage({
            id: intl.creditPolicyNew('deactivation_modal_title'),
          })}
          text={reactIntl.formatMessage({
            id: intl.creditPolicyNew('deactivation_modal_text'),
          })}
          primaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('continue'),
          })}
          secondaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('cancel'),
          })}
          primaryBtnFunc={() => {
            setIsEnabled(false)
            setIsOpen(false)
          }}
          secondaryBtnFunc={() => setIsOpen(false)}
          closeFn={() => setIsOpen(false)}
        />
      ) : (
        <ModalCanvas
          title={reactIntl.formatMessage({
            id: intl.creditPolicyNew('activation_modal_title'),
          })}
          text={reactIntl.formatMessage({
            id: intl.creditPolicyNew('activation_modal_text'),
          })}
          primaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('continue'),
          })}
          secondaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('cancel'),
          })}
          primaryBtnFunc={() => {
            setIsEnabled(true)
            setIsOpen(false)
          }}
          secondaryBtnFunc={() => setIsOpen(false)}
          closeFn={() => setIsOpen(false)}
        />
      )}
    </Modal>
  )
}

export default ActivationPolicyModal
