import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { colors } from 'configs'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 0,
    position: 'relative',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  containerNoData: {
    // backgroundColor: 'orange',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  content: {
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${colors?.light_gray}`,
  },
  col: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 10,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {},
    '&:last-child': {
      marginRight: 0,
    },
  },
  section_title: {
    fontWeight: 600,
  },
  subtitle: {
    fontWeight: 700,
  },
  companyHierarchyButton: {
    height: '4rem',
  },
  listRoleContainer: {
    marginTop: '16px',
  },
  listRoleContainerNoData: {
    marginTop: '16px',
  },
  pieContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
    // maxHeight: '25rem',
    [theme.breakpoints.down('sm')]: {
      margin: '3rem',
    },
    '@media screen and (max-width: 800px)': {
      justifyContent: 'center',
    },
  },
  pieContainerNoData: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
    // maxHeight: '25rem',
    [theme.breakpoints.down('sm')]: {
      margin: '3rem',
    },
    '@media screen and (max-width: 800px)': {
      justifyContent: 'center',
      marginLeft: 0,
    },
  },

  typographyContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    borderRadius: '.25rem',
    alignSelf: 'flex-start',
    maxWidth: 350,
    gap: 16,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      justifyContent: 'center',
    },
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  numberText: {
    fontSize: '3rem',
    fontWeight: 500,
    margin: '1rem',
  },
  textText: {
    // Lol
    fontSize: '96%',
    fontWeight: 500,
    margin: '1rem',
  },

  paperCardHierarchy: {
    padding: '16px 0',
    width: '16rem',
    height: '15rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: `${colors?.risika} !important`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardItemContainer: {
    gap: 16,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
}))
