import React, { Dispatch, SetStateAction } from 'react'
import { AvailableLanguages } from 'globalTypes'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Box,
  Divider,
} from '@mui/material'
import AddSubscriptionOptions from './AddSubscriptionOptions'
import SelectFrequency from './SelectFrequency'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Stack } from 'components'
import { LanguageRadioButton } from 'components-new'
import { faChevronDown, faBackspace } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classes } from 'pages/risk-monitoring/styles/add-new-list-subscription'
import {
  Scheduler,
  Subscription,
} from 'services/api/endpoints/risikaAPI/new_subscription'
import { RiskMonitorListObject } from 'store_deprecated/types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { defSubscription } from '../../model'

type FormattedList = {
  name: string
  listId: number
  checked: boolean
}

const checkIfSubscribed = (
  item: FormattedList,
  subscriptions: Subscription[] | undefined
) => {
  return (
    subscriptions?.length &&
    subscriptions?.some((sub) => sub?.list_id === item?.listId && sub?.disabled === false)
  )
}

type Props = {
  setIsAddingNewSubscription: Function
  subscriptions: Subscription[] | undefined
  lists: RiskMonitorListObject
  listCheckBoxes: Record<string, unknown>[]
  setListCheckBoxes: Function
  selectedItems: Record<string, unknown>
  setSelectedItems: Function
  setCanSubscribe: Function
  frequency: Scheduler
  setFrequency: Dispatch<SetStateAction<Scheduler>>
  subscriptionLang?: AvailableLanguages
  setSubscriptionLang: React.Dispatch<
    React.SetStateAction<AvailableLanguages | undefined>
  >
}

const AddNewListToSubscription = ({
  setIsAddingNewSubscription,
  subscriptions,
  lists,
  listCheckBoxes,
  setListCheckBoxes,
  selectedItems,
  setSelectedItems,
  setCanSubscribe,
  frequency,
  setFrequency,
  subscriptionLang,
  setSubscriptionLang,
}: Props) => {
  React.useEffect(() => {
    setListCheckBoxes(
      Object.keys(lists)
        .map((list) => {
          return {
            name: lists[+list].name,
            listId: lists[+list].list_id,
            checked: false,
          }
        })
        .filter((formattedList) => !checkIfSubscribed(formattedList, subscriptions))
    )
  }, [lists, setListCheckBoxes])

  const checkIfListsAreSelected = React.useCallback(() => {
    return listCheckBoxes.filter((list) => list.checked === true).length
  }, [listCheckBoxes])

  React.useEffect(() => {
    setCanSubscribe(checkIfListsAreSelected)
  }, [listCheckBoxes, checkIfListsAreSelected, setCanSubscribe])

  const setChecked = (item: number, value: boolean) => {
    setListCheckBoxes(
      Object.assign([...listCheckBoxes], {
        [item]: Object.assign({}, listCheckBoxes[item], { checked: value }),
      })
    )
  }

  const handleSubscriptionOptionChanges = (
    options: Record<keyof typeof defSubscription, unknown>
  ) => {
    setSelectedItems(options)
  }

  return (
    <Box>
      <Tooltip
        title={<FormattedMessage id={intl.riskMonitoringNew('back')} />}
        placement="top"
      >
        <IconButton onClick={() => setIsAddingNewSubscription(false)}>
          <FontAwesomeIcon icon={faBackspace as IconProp} />
        </IconButton>
      </Tooltip>
      <Stack direction="column" spacing={4}>
        <Box>
          <Typography variant="h4" sx={classes.title}>
            <FormattedMessage id={intl.riskMonitoringNew('select-subscription-lists')} />
          </Typography>
          <Accordion defaultExpanded sx={classes.listsAccordion}>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {!listCheckBoxes.length ? (
                  <FormattedMessage id={intl.riskMonitoringNew('subscribed-to-all')} />
                ) : (
                  <FormattedMessage id={intl.riskMonitoringNew('your-lists')} />
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails data-cy="monitor-lists">
              <Stack direction="column">
                {listCheckBoxes.map((list, i) => (
                  <FormControlLabel
                    key={list.listId as string}
                    control={
                      <Checkbox
                        onChange={() => setChecked(i, !list.checked)}
                        value={list.checked}
                      />
                    }
                    label={list.name as string}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          {listCheckBoxes.length ? (
            <Box>
              <Typography variant="h4" sx={classes.preferencesTitle}>
                <FormattedMessage
                  id={intl.riskMonitoringNew('select-subscription-options')}
                />
              </Typography>
              <AddSubscriptionOptions
                selectedOptions={selectedItems}
                onError={(err: unknown) => console.log(err)}
                onChange={handleSubscriptionOptionChanges}
              />
              <Divider />

              <LanguageRadioButton
                subscriptionLang={subscriptionLang || 'en_UK'}
                setSubscriptionLang={setSubscriptionLang}
              />
              <SelectFrequency value={frequency} onChange={setFrequency} />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Stack>
    </Box>
  )
}

export default AddNewListToSubscription
