import companyComparisonPageStyles from '../../CompanyComparison.styles'
import {
  columnWidth,
  containerWidth,
  horizontalPadding,
} from '../../CompanyComparison.model'

const mainContainer = (
  numberOfCompanies: number,
  pageWidth: number,
  showHeader: boolean
) => ({
  width:
    containerWidth(numberOfCompanies) > pageWidth
      ? containerWidth(numberOfCompanies)
      : pageWidth,
  flexDirection: 'row',
  position: 'sticky',
  top: 67,
  backgroundColor: 'common.white',
  overflow: 'visible',
  paddingX: horizontalPadding,
  paddingY: 3,
  boxShadow: 1,
  zIndex: 1,
  ...companyComparisonPageStyles.movePositionStyles(showHeader),
})

const companyInfoContainer = {
  boxSizing: 'border-box',
  width: columnWidth,
  alignItems: 'center',
  position: 'relative',
  paddingTop: 4,
}

const iconButtonStyles = (showHeader: boolean) => ({
  position: 'absolute',
  top: showHeader ? '-30px' : '-25px',
  zIndex: 1,
})

const autoCompleteCompanyContainerStyles = {
  width: columnWidth,
  justifyContent: 'center',
  paddingX: '58px',
}

export default {
  mainContainer,
  companyInfoContainer,
  iconButtonStyles,
  autoCompleteCompanyContainerStyles,
}
