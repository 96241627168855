import React, { useState, useImperativeHandle, useEffect, useMemo } from 'react'
import { Stack, Typography, FormControl, Skeleton } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ReactSetState } from 'types/general'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { LocalIdInMonitoringListCategory } from 'types/queries'
import { useMonitorShowList } from 'services/queries'
import {
  COMPANY_LIST,
  NEW_MONITORING_LIST,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import { DropdownSelect } from 'components-new'
import { DropdownSelectAddButton } from 'components-new/inputs/DropdownSelect/widgets'

export type CompanyListPropsType = {
  setIsConfirmEnabled: ReactSetState<boolean>
  apiData: LocalIdInMonitoringListCategory
}

const CompanyList = (
  { setIsConfirmEnabled, apiData }: CompanyListPropsType,
  ref: React.ForwardedRef<SubmitRef>
) => {
  const [chosenListId, setChosenListId] = useState<number>(apiData?.list_id)
  const { state, actions } = useBuilder()
  const { data: showListsData, isLoading: isShowListsLoading } = useMonitorShowList()

  useEffect(
    () => setIsConfirmEnabled(Boolean(chosenListId)),
    [chosenListId, setIsConfirmEnabled]
  )

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.setNodeApiData({
          nodeId: state.actionPanelData.nodeId,
          data: {
            category: COMPANY_LIST,
            list_id: Number(chosenListId),
          },
        })
      },
    }),
    [actions, state.actionPanelData.nodeId, chosenListId]
  )

  const options = useMemo(() => {
    if (!showListsData || isShowListsLoading) return []
    return showListsData
      .filter((x) => !x.owner_id)
      .map((item) => ({
        value: item.list_id.toString(),
        label: item.name,
      }))
  }, [isShowListsLoading, showListsData])

  const extraOptions = useMemo(() => {
    return [
      {
        key: 'new',
        value: 'new',
        styles: {
          padding: 0,
          '& .MuiStack-root': { width: '100%' },
        },
        menuItemProps: {},
        button: true,
        content: (
          <DropdownSelectAddButton
            label={
              <FormattedMessage id={intl.creditPolicyNew('company-list-create-new')} />
            }
          />
        ),
      },
    ]
  }, [])

  const handleChange = (value: string) => {
    if (value === 'new') {
      actions.setActionPanelContent(NEW_MONITORING_LIST)
    } else {
      setChosenListId(Number(value))
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="subtitle2">
          <FormattedMessage id={intl.creditPolicyNew('company-list-description-label')} />
        </Typography>
        <FormControl fullWidth>
          {options?.length ? (
            <DropdownSelect
              options={options}
              value={chosenListId?.toString()}
              setValue={handleChange}
              extraOptions={extraOptions}
              overrideRenderValue={(value) =>
                options.filter((x) => +x.value === +value)[0]?.label ?? value
              }
            />
          ) : (
            <Skeleton height={48} />
          )}
        </FormControl>
      </Stack>
    </>
  )
}

export default React.forwardRef(CompanyList)
