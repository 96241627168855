import { Box, Stack } from '@mui/material'
import { HumanDate, Show } from 'components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { ceoKey } from '../../HierarchyListView.model'
import { classes } from '../../HierarchyListView.styles'
import { TableCellProps } from '../../HierarchyListView.types'
import BlankDashField from '../BlankDashField'
import { Typography } from '@mui/material/'

function Ceo({ row, align }: TableCellProps) {
  const history = useHistory()
  const CEO = row.functions?.[ceoKey]

  if (row?.type !== 'COMPANY' || !row?.functions['CHIEF EXECUTIVE OFFICER']) {
    return <BlankDashField align={align} />
  }

  return (
    <Show when={!!row?.functions?.[ceoKey]?.name}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box
          onClick={() => history.push(navigation.relationOverview(CEO?.personal_id))}
          sx={classes.clickableText}
        >
          <Typography variant="body2">{row?.functions?.[ceoKey]?.name}</Typography>
        </Box>
        <Stack spacing="2px" alignItems="flex-start">
          <HumanDate date={moment(CEO?.valid_from)} format="duration" />
          <Box sx={classes.progressBar(CEO?.valid_from)} />
        </Stack>
      </Stack>
    </Show>
  )
}
export default Ceo
