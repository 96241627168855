import { colors } from 'configs'

export const styles = {
  root: {
    backgroundColor: 'grey.50',
  },
  category: {
    textTransform: 'uppercase',
  },
  category_active: {
    color: colors?.lowRisk,
  },
  category_inactive: {
    color: colors?.orange,
  },
  h5: {
    fontSize: '2.3rem',
    fontWeight: 400,
  },
}
