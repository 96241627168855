const container = {
  margin: '1rem',
  display: 'flex',
  alignItems: 'center',
}

const buttonProgress = {
  color: 'secondary.light',
}
const divider = {
  height: 18,
  width: 2,
  margin: '0 0.5rem',
  backgroundColor: 'grey.300',
}
const paper = {
  position: 'absolute',
  right: '1%',
  zIndex: 1,
}

export const classes = {
  container,
  buttonProgress,
  divider,
  paper,
}
