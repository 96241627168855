import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId, RisikaScore, RiskAssessment } from 'globalTypes'
import moment from 'moment'

/******************
 * Fetch from API *
 ******************/

export type CompanyVisit = {
  local_organization_id: LocalId
  risika_score: RisikaScore
  risk_assessment: RiskAssessment
  status: string
  name: string
  city: string
  company_type: string
  count: number
  last_visit: string
}
export type CompanyVisitsList = CompanyVisit[]

export type CompanyVisitsListParams = {
  from?: string
  to?: string
  sortBy?: 'count' | 'last_ts'
  min?: number
}

async function companyVisitsList({
  from = moment().subtract(30, 'days').format('YYYY-MM-DD'),
  to = moment().format('YYYY-MM-DD'),
  sortBy = 'last_ts',
  min = 5,
}: CompanyVisitsListParams): Promise<CompanyVisitsList> {
  const { data } = await risikaAPI.post(
    '/statistics/company_visits/list',
    {
      date_from: from,
      date_to: to,
      sort_by: sortBy,
      min_count: min,
    },
    {
      microservice: 'data',
    }
  )
  return data
}

export default companyVisitsList
