import numeral from 'numeral'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { classTooltip } from 'styles-new/global/components'
import { theme } from 'styles-new/mui5-transition/theme'

const { fontFamily, fontWeight, fontSize, letterSpacing, color } = theme.typography.body2

/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { colors } from 'configs'

const normalizeText = (text: string) => {
  return text.toLowerCase().replace(/^./, text[0].toUpperCase()).replaceAll('_', ' ')
}

const toPercent = (num: number, sum: number) => {
  const percent = Number(((num / sum) * 100).toFixed(1))
  if (isNaN(Number(percent))) return 0
  return percent
}

/*
Unfortunately, props types here must be any, because
props are injected by ReCharts
EDIT: I found the way how this could be overcome (by destructuring the values)
but I do not have to implement it now.
*/

export const AbbreviatedNumbersAxis = (props: any) => {
  let value = props.payload.value
  if (value > 999 || value < -999) {
    value = numeral(value).format('0.0 a').replace(/\s/g, '').toUpperCase()
  }
  return (
    <text
      x={props.x}
      y={props.y + (props.index === props.visibleTicksCount - 1 ? 0 : 5)}
      fill={color}
      height={props.height}
      width={props.width}
      orientation={props.orientation}
      textAnchor={props.textAnchor}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      fontSize={fontSize}
      letterSpacing={letterSpacing}
    >
      {value}
    </text>
  )
}

export const StackedChartTooltipWithRiskPercent = (props: any) => {
  return StackedTooltipWithPercent(props, 'RISK')
}

export const StackedChartTooltipWithPercent = (props: any) => {
  return StackedTooltipWithPercent(props, 'RAW')
}

export const StackedTooltipWithPercent = (props: any, valueType: string) => {
  const reactIntl = useIntl()
  const messages = reactIntl.messages

  const employeesLabel = props.employeesLabel ? messages[intl.generic('employees')] : ''

  const totalSum = props.payload.reduce(
    (accumulator: number, item: { value: number }) => accumulator + item.value,
    0
  )

  return (
    <Box className="MUI5" sx={classTooltip}>
      <Box sx={{ mb: 1 }}>
        <Typography component={'span'} variant="body2">
          <Box sx={{ fontWeight: 'bold' }}>
            {props.label} {employeesLabel}
          </Box>
        </Typography>
      </Box>
      {props.payload.map((item: any, index: number) => {
        const name = valueType === 'RISK' ? `${item.name} risk` : item.name
        return (
          <span key={item.name + index + item.value}>
            <Box sx={{ mb: 1 }}>
              <Typography component={'span'} variant="body2" color={item.fill}>
                {name}: {item.value}{' '}
                {messages[intl.generic(item.value === 1 ? 'company' : 'companies')]} (
                {toPercent(item.value, totalSum)}%)
              </Typography>
            </Box>
          </span>
        )
      })}
    </Box>
  )
}

export const SimpleChartTooltipRaw = (props: any) => {
  return CustomTooltip(props, 'RAW')
}
export const SimpleChartTooltipRisk = (props: any) => {
  return CustomTooltip(props, 'RISK')
}
export const SimpleChartTooltipCreditScore = (props: any) => {
  return CustomTooltip(props, 'CREDIT_SCORE')
}
export const SimpleChartTooltipRegion = (props: any) => {
  return CustomTooltip(props, 'REGION')
}

export const CustomTooltip = (props: any, valueType: string) => {
  const reactIntl = useIntl()

  if (!props.payload.length) return <></>

  const messages = reactIntl.messages
  const item = props.payload[0]
  const textColor = item.payload.fill

  const companiesText = messages[intl.generic(item.value === 1 ? 'company' : 'companies')]
  const sumPercentText = `(${toPercent(item.value, props.totalSum)}%)`
  const creditScoreText = messages[intl.observationalLists('tab-risika-credit-score')]
  const regionText = valueType === 'REGION' ? item.payload.payload.region : ''

  let tooltipText = ''

  switch (valueType) {
    case 'RAW':
      tooltipText = `${item.name}: ${item.value} ${sumPercentText} ${companiesText}`
      break
    case 'RISK':
      tooltipText = `${item.name} risk: ${item.value} ${companiesText} ${sumPercentText}`
      break
    case 'CREDIT_SCORE':
      tooltipText = `${creditScoreText} ${props.label}: ${item.value} ${companiesText} ${sumPercentText}`
      break
    case 'REGION':
      tooltipText = `${regionText} ${item.name}: ${item.value} ${companiesText}`
      break
    case 'KEY_FIGURES':
      tooltipText = `${item.name}: ${item.value}`
      break
    default:
      tooltipText = `${item.name}: ${item.value}`
  }

  return (
    <Box
      className="MUI5"
      sx={{ ...classTooltip, overflow: 'hidden', whiteSpace: 'nowrap' }}
    >
      <Typography component={'span'} color={textColor} variant="body2">
        {tooltipText}
      </Typography>
    </Box>
  )
}
export const CustomPieLegendWithPaddingRisk = (props: any) => {
  const { payload } = props
  return CustomLegend(payload, 'RISK', 'PIE')
}

export const CustomPieLegendWithPadding = (props: any) => {
  const { payload } = props
  return CustomLegend(payload, 'RAW', 'PIE')
}

export const CustomLegendWithPaddingRisk = (props: any) => {
  const { payload } = props
  return CustomLegend(payload, 'RISK', 'BAR')
}

export const CustomLegendWithPadding = (props: any) => {
  const { payload } = props
  return CustomLegend(payload, 'RAW', 'BAR')
}

const CustomLegend = (payload: any, valueType: string, chartType: string) => {
  const leftMargin = chartType === 'PIE' ? '0rem' : '6rem'
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const creditScoreText = messages[intl.observationalLists('credit-score')]

  return (
    <Box
      sx={{
        padding: '16px 0',
        marginLeft: leftMargin,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {payload.map((entry: any, index: any) => {
        const text = normalizeText(entry.value)

        const creditScoreColor =
          entry.dataKey === creditScoreText
            ? `linear-gradient(315deg, ${colors.riskIntelligence.lowRisk}, ${colors.riskIntelligence.mediumRisk}, ${colors.riskIntelligence.highRisk})`
            : ''

        return (
          <Box
            key={`item-${index}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '1rem',
            }}
          >
            <Box
              sx={{
                width: theme.spacing(4),
                height: theme.spacing(4),
                borderRadius: '2px',
                mr: '0.75rem',
                background: entry.color,
                backgroundImage: creditScoreColor,
              }}
            />
            <Box>{valueType === 'RISK' ? `${text} risk` : text}</Box>
          </Box>
        )
      })}
    </Box>
  )
}

export const getCellColor = (
  entry: { name: string | number },
  barKey: string,
  creditScoreString: string,
  index: number,
  chartColors: string[]
) => {
  // if current cell is credit score
  // return appropriate color
  if (barKey === creditScoreString) {
    const value = Number(entry[creditScoreString as keyof typeof entry])

    if (value <= 3) return colors.riskIntelligence.highRisk
    if (value > 3 && value <= 7) {
      return colors.riskIntelligence.mediumRisk
    }
    if (value > 7) return colors.riskIntelligence.lowRisk
  }
  // otherwise return color from chartProperties
  return chartColors[index]
}

export const CustomTooltipWithCreditScore = (props: any) => {
  const reactIntl = useIntl()
  if (!props.payload.length) return <></>

  const messages = reactIntl.messages
  const creditScoreText = messages[intl.observationalLists('credit-score')]
  const item = props.payload[0]

  const tooltipContent = (item: { name: string }, key: string, index: number) => {
    const numberToPercentText = (number: number) => `${number.toFixed(2)}%`

    const tooltipColors: { [key: string]: string } = {}
    props.payload.forEach(({ name }: { name: string; color: string }) => {
      tooltipColors[name] = getCellColor(
        item,
        name,
        creditScoreText as string,
        index,
        props.chartColors
      )
    })

    switch (key) {
      case 'name':
        return (
          <Box key={key + index} sx={{ fontWeight: 700 }}>
            {item[key as keyof typeof item]}
          </Box>
        )

      case creditScoreText:
        return (
          <Box key={key + index} sx={{ color: tooltipColors[key] }}>
            <>
              {key}: {Number(item[key as keyof typeof item]).toFixed(2)}
              {messages[intl.portfolioInsights('avg.')]}
            </>
          </Box>
        )

      default:
        return (
          <Box key={key + index} sx={{ color: tooltipColors[key] }}>
            {key}: {numberToPercentText(Number(item[key as keyof typeof item]))}
          </Box>
        )
    }
  }

  return (
    <Box className="MUI5" sx={classTooltip}>
      {Object.keys(item.payload).map((key, index) =>
        tooltipContent(item.payload, key, index - 1)
      )}
    </Box>
  )
}

type NewCustomToolTipProps = {
  name?: string
  value?: number
  region?: string // SE FI etc
  fillColour?: string
}

export const NewCustomRegionalTooltip = (props: NewCustomToolTipProps) => {
  const { region, name, value, fillColour } = props
  const reactIntl = useIntl()

  if (!region || !name || !value) {
    return null
  }

  const messages = reactIntl.messages
  const companiesText = messages[intl.generic(value === 1 ? 'company' : 'companies')]
  const tooltipText = `${region} ${name}: ${value} ${companiesText}`

  return (
    <Box
      className="MUI5"
      sx={{ ...classTooltip, overflow: 'hidden', whiteSpace: 'nowrap' }}
    >
      <Typography component={'span'} variant="body2" color={fillColour}>
        {tooltipText}
      </Typography>
    </Box>
  )
}
