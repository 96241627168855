import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: '1.6rem',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}))
