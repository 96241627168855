import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'

/******************
 * Fetch from API *
 ******************/
export type PersonBulkResponse = PersonResponse[]

export type PersonResponse = {
  entity_id: number | string
  name: string
}

export type GetPersonBulkInformation = {
  personIdList: string[]
  country: LocalId['country']
}

const getPersonBulkInformation = async ({
  personIdList,
  country,
}: GetPersonBulkInformation): Promise<PersonBulkResponse> => {
  const idList = personIdList
    .map((x) => ({
      local_person_id: { id: x, country: country?.toUpperCase() },
    }))
    .filter((x) => x.local_person_id.id !== '')
  const { data } = await risikaAPI.post(
    `/person/relations_list`,
    {
      local_person_ids: idList,
    },
    {
      microservice: 'data',
    }
  )
  return data.map((person: PersonResponse) => ({
    entity_id: person.entity_id,
    name: person.name,
  }))
}

export default getPersonBulkInformation
