import React from 'react'
import CompanyChildrenCard from './scenes/UI/CompanyChildrenCard'
import HumanEntity from './scenes/UI/HumanEntity'

type Props = {
  displayState: any
  nodeDatum: any
  onOpenHierarchyWalkdown?: () => void
}

const CardChildrenFactory = ({
  displayState,
  nodeDatum,
  onOpenHierarchyWalkdown,
}: Props) => {
  switch (nodeDatum?.type) {
    case 'COMPANY':
      return <CompanyChildrenCard displayState={displayState} nodeDatum={nodeDatum} />
    case 'LEGAL_OWNER':
    case 'BENEFICIAL_OWNER':
    case 'BENEFICIAL_OWNER_AND_LEGAL_OWNER':
      return (
        <HumanEntity
          displayState={displayState}
          nodeDatum={nodeDatum}
          onOpenHierarchyWalkdown={onOpenHierarchyWalkdown}
        />
      )
    default:
      return <div>No such type</div>
  }
}

export default CardChildrenFactory
