const card = {
  mt: 5,
  mb: 4,
}

const toggle = {
  mb: 2,
}

const policyLink = {
  maxWidth: 'fit-content',
  textDecoration: 'underline !important',
  fontWeight: 600,
  mt: 3,
  '&:hover': {
    cursor: 'pointer',
  },
}

const creditMax = {
  mt: 2,
}

const wrapper = {
  p: 4,
}

const title = {
  mb: 4,
}

const noOverrideText = {
  mb: 4,
}

export default {
  card,
  toggle,
  policyLink,
  creditMax,
  wrapper,
  title,
  noOverrideText,
}
