import { Button, Box, Tooltip } from '@mui/material'
import IntlTypography from 'components-new/elements/IntlTypography'
import moment from 'moment'
import { styles } from './styles'
import { paginatedMonitoringList } from 'services/api'
import { downloadAs } from 'services/export'
import { Company, SelectedType } from '../../Table.types'
import { getFormattedDateForScheduler, getTodayDifferentYear } from 'localization/utils'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'

const mapExportFields = (row: Company) => ({
  companyId: row.local_organization_id?.id ?? '',
  companyName: row.company_name ?? '',
  country: row.address?.country ?? '',
  score: row.score ?? '',
  creditMax: row.credit_max ?? '',
  equity: row.financial_key_figures?.equity ?? '',
  profitLoss: row.financial_key_figures?.profit_loss ?? '',
  employees: row.employee_interval ?? '',
  status: row.status ?? '',
})

const ActionMenu = ({
  listId,
  selectedCompanies,
}: {
  listId: string
  selectedCompanies: SelectedType[]
}) => {
  const handleExport = async () => {
    const lastYear = getTodayDifferentYear(-1)
    const params = {
      page: 1,
      dates: {
        from: getFormattedDateForScheduler(lastYear),
        to: getFormattedDateForScheduler(new Date()),
      },
      limit: 999,
      filter_on: {
        local_organization_id: selectedCompanies,
      },
      sort_by: {
        field: 'local_organization_id.id',
        direction: 'ASCENDING',
      },
    }

    paginatedMonitoringList(Number(listId), params).then(({ data }) => {
      if (data.length) {
        const mappedData = data?.map(mapExportFields)
        const now = moment().format('YYYY-MM-DD hh-mm-ss')
        const exportFilename = `Risika export ${now}`
        const download = downloadAs('excel')!

        download(exportFilename, mappedData)
      }
    })
  }

  const tooltipExportText = !selectedCompanies.length ? (
    <FormattedMessage id={intl.observationalLists('export-button-tooltip')} />
  ) : (
    ''
  )

  return (
    <Tooltip title={tooltipExportText} arrow>
      <Box sx={{ display: 'flex' }}>
        <Button
          size="large"
          variant="contained"
          onClick={handleExport}
          disabled={!selectedCompanies.length}
          sx={styles.button}
        >
          <IntlTypography variant="subtitle1" group="exportButton" labelKey="text" />
        </Button>
      </Box>
    </Tooltip>
  )
}

export default ActionMenu
