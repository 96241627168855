import { Stack } from '@mui/material'
import React from 'react'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import styles from './ToggleArrow.styles'

type Props = {
  isExpanded: boolean
  handleClick: React.Dispatch<React.SetStateAction<boolean>>
  isDisabled: boolean
}

const ToggleArrow = ({ isExpanded, handleClick, isDisabled }: Props) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      onClick={() => {
        if (isDisabled) return
        handleClick(!isExpanded)
      }}
      sx={styles.main}
    >
      {isDisabled ? (
        <ExpandMore sx={styles.disabledArrow} />
      ) : isExpanded ? (
        <ExpandLess />
      ) : (
        <ExpandMore />
      )}
    </Stack>
  )
}

export default ToggleArrow
