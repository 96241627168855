import risikaAPI from 'services/api/helpers/risikaAPI'

export default function notesGet(localId) {
  return risikaAPI
    .post(
      `/notes/get`,
      { local_organization_id: localId },
      {
        microservice: 'account',
      }
    )
    .then(({ data }) => {
      return {
        data,
        localId,
      }
    })
}
