import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const hierarchyContainer: SxProps = {
  height: '100%',
  position: 'relative',
  '& .rd3t-link': {
    stroke: '#e6e6e6',
    strokeWidth: '2px',
  },
}

const buttonsContainer: SxProps = {
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },

  position: 'absolute',
  top: 20,
  left: 20,
  gap: '16px',
  '& span': {
    margin: 0,
  },
}

const controlButton: SxProps = {
  padding: 0,
  color: '#212529',
  borderColor: '#e5eef0',
  background: 'white',
  minWidth: '40px',
  height: '40px',
  '&:hover': {
    background: 'white',
    boxShadow: '#e5eef0',
  },
}

export default {
  hierarchyContainer,
  buttonsContainer,
  controlButton,
}
