import React from 'react'
import {
  ErrorSharp,
  CheckCircle,
  RemoveCircleSharp,
  CancelSharp,
} from '@mui/icons-material'
import styles from './PolicyOutcomePill.styles'
import { Box } from '@mui/material'
import { Skeleton } from '@mui/material'
import { PolicyOutcomePillProps } from './PolicyOutcomePill.types'

const PolicyOutcomePill = ({ text }: PolicyOutcomePillProps) => {
  const { container, icon, message } = styles
  const iconMap = {
    approved: { title: 'Approved', icon: <CheckCircle fontSize="small" /> },
    reject: { title: 'Rejected', icon: <CancelSharp fontSize="small" /> },
    manual_check: { title: 'Manual check', icon: <ErrorSharp fontSize="small" /> },
    no_policy_set: {
      title: 'No policy',
      icon: <RemoveCircleSharp fontSize="small" />,
    },
  }
  return (
    <>
      {!text ? (
        <Skeleton />
      ) : (
        <Box sx={container}>
          <>
            <Box sx={icon}>{iconMap[text]?.icon}</Box>
            <Box sx={message}>{iconMap[text]?.title}</Box>
          </>
        </Box>
      )}
    </>
  )
}

export default PolicyOutcomePill
