import risikaAPI from 'services/api/helpers/risikaAPI'
/**************
 * Add to API *
 **************/

const monitorAddToList = (list_id, ...local_ids) => {
  const data = {
    list_id: parseInt(list_id),
    subscribe: local_ids[0].map(({ id, country, hash }) => ({
      local_organization_id: {
        id: hash?.toString() ?? id?.toString(),
        country,
      },
    })),
  }

  return risikaAPI
    .post('/add', data, {
      microservice: 'monitor',
      country: '',
    })
    .then(({ data }) => {
      return data
    })
}

export default monitorAddToList
