import * as React from 'react'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { CustomChildrenProps } from '../CustomizationHandler.types'
import { getSelectedOption } from '../CustomizationHandler.types.controller'
import { Stack } from '@mui/material'
import { DropdownSelect } from 'components-new'
import { Show } from 'components'
import { ECPDataType } from 'services/api/endpoints/risikaAPI/getECPDataStructure'
import { APIKeyType } from 'types/queries'

const DropdownTemplates = ({
  children,
  selectedVariable,
}: {
  children: React.ReactElement<CustomChildrenProps>[]
  selectedVariable: ECPDataType[0]
}) => {
  const { state, actions } = useBuilder()
  const [selectedOption, setSelectedOption] = React.useState(
    getSelectedOption(state, children)
  )
  const getDropdownOptionsFromChildren = (): {
    value: string
    label: string | number | React.ReactElement
    flag?: string
  }[] => {
    return children.map((child) => ({
      label: child.props.template.title,
      value: child?.props?.template?.template_data?.api_key ?? '',
    }))
  }

  const handleUpdateOption = (value: string | number) => {
    setSelectedOption(value as string)
    const template = children.filter(
      (child) => child?.props?.template?.template_data?.api_key === value
    )[0].props.template
    actions.updateCategory({
      apiKey: template?.template_data?.api_key as APIKeyType,
      variant: null, // Does not change the already selected variant
    })
  }
  return (
    <Stack height={1} overflow="scroll" spacing={6}>
      <DropdownSelect
        inputProps={{
          label: selectedVariable?.label || children[0].props.template.title,
        }}
        options={getDropdownOptionsFromChildren()}
        value={selectedOption as string}
        setValue={(value: string | number) => handleUpdateOption(value)}
      />
      {React.Children.map(children, (child, i) => {
        return (
          // Key is the index, because the state also uses the index to determine which menu is viewed
          <Show when={selectedOption === child?.props?.template?.template_data?.api_key}>
            <Stack spacing={5}>{child}</Stack>
          </Show>
        )
      })}
    </Stack>
  )
}
export default DropdownTemplates
