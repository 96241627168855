import { DisplayState } from 'pages/company-hierarchy/types'
import { listViewTableHeaders } from './HierarchyListView.model'

const checkIfVisible = (componentName: string, displayState: DisplayState) => {
  const displayingState = displayState[componentName as keyof DisplayState]
  return displayingState !== false
}

export const handleVisibility = (
  availableHeaders: typeof listViewTableHeaders,
  displayState: DisplayState,
) => {
  return availableHeaders.map((header) => ({
    ...header,
    visible: checkIfVisible(header.componentName, displayState),
  }))
}
