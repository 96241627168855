import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { CompanySearchCategory } from 'types/queries'
import { useCompanyBulkInformation } from 'services/queries'
import { PolicyBuilderParams } from '../../PolicyBuilder.types'
import { Skeleton } from '@mui/material'
import { CompanyBulkResponse } from 'services/api/endpoints/risikaAPI/getCompanyBulkInformation'
import { useBuilderParams } from '../../PolicyBuilder.hooks'

type CompanySearchContentPropsType = {
  data: CompanySearchCategory
}
const LIMIT = 5
function CompanySearchContent({ data }: CompanySearchContentPropsType) {
  const params = useBuilderParams() as PolicyBuilderParams
  const { data: companyBulkData, isLoading: useBulkInfoLoading } =
    useCompanyBulkInformation({
      localIdList: data.include ?? [],
      country: params.country,
    })
  if (!data.include) {
    return <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  }

  if (useBulkInfoLoading) {
    return (
      <>
        <Skeleton>
          <p>blablablabla</p>
        </Skeleton>
        <Skeleton>
          <p>blablablabla</p>
        </Skeleton>
      </>
    )
  }

  const formatCompanyData = (data: CompanyBulkResponse[] | undefined) => {
    if (!data) return ''
    return (
      data
        .slice(0, LIMIT)
        ?.map((item) => item.name)
        .sort((a, b) => (a > b ? 1 : -1))
        .join(', ') + (data.length > LIMIT ? '...' : '')
    )
  }

  return (
    <FormattedMessage
      id={intl.creditPolicyNew('dropdown_multi_select')}
      values={{
        companyList: formatCompanyData(companyBulkData),
      }}
    />
  )
}

export default CompanySearchContent
