import risikaAPI from 'services/api/helpers/risikaAPI'
import { AxiosPromise } from 'axios'

export type PolicyViolations = {
  code: string
  title: string
}

type PolicyViolationResponse = {
  policy_violations: PolicyViolations[]
}

export const getPolicyViolations = (): AxiosPromise<PolicyViolationResponse> => {
  return risikaAPI.get(`list/policy_violations`, { microservice: 'data' })
}
