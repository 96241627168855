import { PaletteColorOptions, Theme } from '@mui/material'

const inputStyles = (removeBorder: boolean) => ({
  '& .MuiInputBase-root': {
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '&:hover': {
      border: (theme: Theme) =>
        removeBorder ? 'none' : `1px solid ${theme.palette.grey[400]}`,
    },
    border: (theme: Theme) =>
      removeBorder ? 'none' : `1px solid ${theme.palette.grey[200]}`,
  },
  '& .Mui-focused': {
    borderWidth: removeBorder ? 0 : '2px',
    border: (theme: Theme) =>
      removeBorder
        ? 'none'
        : `2px solid ${
            theme.palette.primary[500 as keyof PaletteColorOptions]
          } !important`,
    borderStyle: removeBorder ? 'none' : 'solid',
  },
  '& .Mui-InputBase-root': {
    border: 'none',
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: 0,
    svg: {
      marginRight: 0,
    },
  },
})

export default { inputStyles }
