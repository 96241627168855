// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  CircularProgress,
  Card,
  CardContent,
  Typography,
  TextField,
  Link,
  Box,
} from '@mui/material'
import { SearchableAsyncSelect, Stack, Button, Flag } from 'components'
import { searchSuggestions } from 'services/api'
import { authFrictionlessRegister, authLogin } from 'services/api/helpers/authService'
import { loginUserSuccess } from 'store_deprecated'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import throttle from 'lodash/throttle'
import { useSnackbar } from 'notistack'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useStyles } from '../styles/frictionless-account'

const defaultState = {
  password: '',
  confirmPassword: '',
  firstName: '',
  company: {},
}

const FrictionlessAccount = () => {
  const classes = useStyles()
  const { email } = useParams()
  const reactIntl = useIntl()
  const dispatch = useDispatch()

  const [state, setState] = React.useState(defaultState)
  const [country, setCountry] = React.useState('dk')
  const [loading, setLoading] = React.useState(false)
  const { password, confirmPassword, firstName, company } = state
  const [helperText, setHelperText] = React.useState(defaultState)
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = React.useState('')
  const [formValidation, setFormValidation] = React.useState(false)
  const messages = reactIntl.messages

  const ARTIFICIAL_DELAY = 2000

  React.useEffect(() => {
    if (password.length && confirmPassword.length) {
      if (password === confirmPassword) {
        setHelperText(defaultState)
      } else {
        setHelperText({
          password: reactIntl.formatMessage({
            id: intl.loginRegister('register-popup-password'),
          }),
          confirmPassword: reactIntl.formatMessage({
            id: intl.loginRegister('register-popup-password'),
          }),
        })
      }
    } else {
      setHelperText(defaultState)
    }
    if (password.length && confirmPassword.length) {
      if (password === confirmPassword) {
        if (firstName.length) {
          if (company?.companyName?.length) {
            setFormValidation(true)
            return
          }
        }
      }
    }
    setFormValidation(false)
  }, [password, confirmPassword, reactIntl, firstName, company])

  const handleInputChange = (key, value) => {
    if (key === 'firstName') {
      setState({
        ...state,
        [key]: value.charAt(0).toUpperCase() + value.slice(1),
      })
      return
    }
    setState({
      ...state,
      [key]: value,
    })
  }
  const handleCompanyNameChange = (values) => {
    setState({
      ...state,
      company: {
        companyName: values.label,
        local_organization_id: { id: values.value, country }, // Handle country cahnge when needed
      },
    })
  }

  React.useEffect(() => {
    if (error !== '') {
      enqueueSnackbar(messages[intl.errors(error)], {
        variant: 'error',
      })
      setError('')
    }
  }, [enqueueSnackbar, error, messages])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    authFrictionlessRegister({
      email,
      firstName,
      localId: company.local_organization_id,
      password,
      confirmPassword,
    })
      .then((res) => {
        authLogin(email, password)
          .then((res) => {
            setTimeout(() => {
              dispatch(loginUserSuccess(res))
              setLoading(false)
            }, ARTIFICIAL_DELAY)
          })
          .catch((err) => {
            console.log('err', err)
          })
      })
      .catch((err) => {
        const handledErrorCodes = [
          'hubspot_error',
          'companies_local_id_country_unique',
          'company_already_exists',
          'invalid_local_id',
          'error_creating_new_customer',
        ]
        const errorCode = err.response.data.code
        if (handledErrorCodes.includes(errorCode)) {
          setError(errorCode)
        }
        setLoading(false)
      })
  }
  const fetchCompanyValues = throttle((inputValue, call) => {
    if (inputValue?.length > 3) {
      searchSuggestions(inputValue, country)
        .then((data) => {
          call(
            data.map((x) => ({
              label: `${x.name} (${x.localId.id})`,
              value: x.localId.id,
            }))
          )
        })
        .catch((err) => {
          console.log('something falied', err)
        })
    }
  }, 600)

  function handleCountryChange(value) {
    setCountry(value)
    setState((state) => ({
      ...state,
      company: {},
    }))
  }

  if (loading) {
    return <CircularProgress className={classes.spinner} />
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0}>
        <CardContent>
          <Box mb={2}>
            <Typography className={classes.title} variant="h5">
              <FormattedMessage id={intl.auth('finalize-account')} />
            </Typography>
          </Box>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Stack spacing={2} direction="column">
              <Typography variant="h6">
                <FormattedMessage id={intl.auth('your-company')} />
              </Typography>
              <ToggleButtonGroup
                value={country}
                exclusive
                onChange={(_, value) => handleCountryChange(value)}
                aria-label="country selector"
              >
                <ToggleButton value="dk" aria-label="Denmark">
                  <Flag country="dk" />
                  <FormattedMessage id={intl.country('dk')} />
                </ToggleButton>
                <ToggleButton value="no" aria-label="Norway">
                  <Flag country="no" />
                  <FormattedMessage id={intl.country('no')} />
                </ToggleButton>
                <ToggleButton value="se" aria-label="Sweden">
                  <Flag country="se" />
                  <FormattedMessage id={intl.country('se')} />
                </ToggleButton>
              </ToggleButtonGroup>
              <SearchableAsyncSelect
                key={country}
                onChange={handleCompanyNameChange}
                placeholder={
                  <FormattedMessage id={intl.companyInfo('company-name-required')} />
                }
                loadOptions={fetchCompanyValues}
              />
              <Typography variant="h6">
                <FormattedMessage id={intl.auth('personal-information')} />
              </Typography>
              <TextField
                className={classes.textField}
                type="email"
                disabled
                value={email}
                label={<FormattedMessage id={intl.companyInfo('email')} />}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                type="name"
                required
                value={firstName}
                label={<FormattedMessage id={intl.generic('first-name')} />}
                variant="outlined"
              />
              <TextField
                error={helperText.password.length}
                helperText={helperText.password}
                required
                className={classes.textField}
                onChange={(e) => handleInputChange('password', e.target.value)}
                type="password"
                label={<FormattedMessage id={intl.settings('password-title')} />}
                variant="outlined"
              />
              <TextField
                error={helperText.confirmPassword.length}
                helperText={helperText.confirmPassword}
                className={classes.textField}
                required
                type="password"
                onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                label={
                  <FormattedMessage id={intl.firstTimeVisit('confirm-password-label')} />
                }
                variant="outlined"
              />
              <Button
                loading={loading}
                type="submit"
                color="secondary"
                disabled={!formValidation}
              >
                <FormattedMessage id={intl.auth('get-me-in')} />
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
      <div className={classes.contactInfo}>
        <Typography>
          <FormattedMessage id={intl.auth('contact-us')} />:
        </Typography>
        <Typography>
          <FormattedMessage id={intl.auth('phone')} />: +45 42 90 57 57
        </Typography>
        <Typography>
          <Link href="mailto:contact@risika.dk" color="inherit">
            <FormattedMessage id={intl.auth('email')} />: contact@risika.dk
          </Link>
        </Typography>
      </div>
    </div>
  )
}

export default FrictionlessAccount
