import React from 'react'
import * as Sentry from '@sentry/browser'
import { CircularProgress } from '@mui/material'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, { componentStack }) {
    // You can also log the error to an error reporting service
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      Sentry.captureException(error, {
        contexts: { react: { componentStack } },
      })
      window.location = `${window.location.origin}/auth/logout`
    }
  }

  render() {
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#0A373D',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            alt="here alt you happy ? "
            style={{ marginBottom: '5rem' }}
            src={'/risika/logo-risika.svg'}
          />
          <CircularProgress />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
