import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  sub_title: {
    fontWeight: 700,
    flex: 1,
    whiteSpace: 'nowrap',
    alignSelf: 'baseline',
    [theme.breakpoints.down('md')]: {
      flex: '',
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
  border: {
    borderBottom: '1px solid gray',
  },
  displayNull: {
    display: 'none',
  },
  iconButton: {
    cursor: 'default',
    alignSelf: 'flex-end',
    fontSize: 19.21,
    color: 'primary.main',
  },
}))
