import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const buttonSaveChanges: SxProps = {
  fontFamily: '"Montserrat",sans-serif',
  fontWeight: 600,
  fontSize: '1.5rem',
  mx: 0.2,
  padding: theme.spacing(2),
  background: theme.palette.success.main,
}

export const styles = {
  buttonSaveChanges,
}
