import { Box, Typography } from '@mui/material'
import React from 'react'
import { classes } from './styles'
import ClickableOption from './Options'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'

const options = [
  {
    label: 'add-company-monitoring-list',
    link: 'https://help.risika.dk/en/monitoring-1',
  },
  { label: 'set-up-monitoring-emails', link: 'https://help.risika.dk/en/monitoring' },
  {
    label: 'adjust-credit-recommendation',
    link: 'https://help.risika.dk/en/creditcheck#Estimation',
  },
  {
    label: 'view-companies-subsidiaries',
    link: 'https://help.risika.dk/en/creditcheck#Grouphierarchy',
  },
]

const QuickAnswers = () => {
  return (
    <Box sx={classes.quickAnswers}>
      <Typography variant="subtitle1" color="#353A3F">
        <FormattedMessage id={intl.faq('find-answers')} />
      </Typography>
      <Box sx={classes.optionContainer}>
        {options.map((option) => {
          return (
            <a href={option.link} target="_blank" key={option.label} rel="noreferrer">
              <ClickableOption key={option.label} option={option.label} />
            </a>
          )
        })}
      </Box>
    </Box>
  )
}

export default QuickAnswers
