import { SearchResults, SearchLoader } from 'components'
import { useIsFetching } from 'react-query'
import { useAppShell } from 'components/AppShell/context'
import { classGlobalSearch } from 'styles-new/global/search'

const GlobalSearch = () => {
  const isSearching = useIsFetching(['search'])
  const appShell = useAppShell()
  const searchSelected = appShell?.searchActive

  if (!searchSelected) return null

  return (
    <div style={classGlobalSearch}>
      {isSearching ? <SearchLoader /> : null}
      <SearchResults />
    </div>
  )
}

export default GlobalSearch
