import { Box, MenuItem, Select, Stack, Typography } from '@mui/material'
import { InputField } from '../../index'
import { DropdownSelectPropsType } from './DropdownSelect.types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { Key } from 'react'

function DropdownSelect<T extends Key = string | number>(
  props: DropdownSelectPropsType<T>
) {
  const {
    disablePlaceholderClick = true,
    overrideRenderValue,
    label,
    value,
    disabled = false,
    placeholder,
    setValue,
    options,
    inputProps,
    error,
    errorMessage,
    hideExtraText = false,
    enableRangeDash,
    extraOptions,
    dataCy,
    ...extraProps
  } = props

  const handleChange = (value: T) => {
    setValue(value)
  }

  const renderValue = (value: T) => {
    if (value !== 0 && !value) {
      return (
        <Typography sx={{ color: 'grey.600' }} variant="body1">
          <FormattedMessage id={intl.creditPolicyNew('please-select')} />
        </Typography>
      )
    }

    return (
      options.find(
        (item: { value: T; label: string | JSX.Element | number }) => item.value === value
      )?.label || value.toString()
    )
  }

  return (
    <Stack spacing={1} width={1}>
      <Stack position="relative" spacing={1} width={1}>
        {label && (
          <Typography
            sx={hideExtraText ? { visibility: 'hidden' } : {}}
            variant="subtitle2"
          >
            {label}
          </Typography>
        )}

        <Select
          data-cy={dataCy || 'dropdown-select'}
          error={error}
          renderValue={overrideRenderValue ?? renderValue}
          disabled={disabled}
          input={
            <InputField value={value as string} handleChange={() => {}} {...inputProps} />
          }
          disableUnderline
          value={value}
          displayEmpty
          onChange={(e) => handleChange(e.target.value as T)}
          variant="standard"
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            disableScrollLock: true,
            sx: {
              ul: { maxHeight: 450 },
            },
          }}
          {...extraProps}
        >
          {placeholder && (
            <MenuItem disableRipple disabled={disablePlaceholderClick} value={''}>
              <Typography sx={{ color: 'grey.600' }} variant="body1">
                {placeholder || 'Please select'}
              </Typography>
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem
              disableRipple
              key={option.value?.toString()}
              data-cy={option.value}
              sx={{
                paddingX: 4,
                paddingY: 2,
              }}
              value={option.value?.toString()}
            >
              <Stack direction="row" alignItems="center">
                {option.flag && (
                  <img
                    style={{ width: 28, height: '100%', marginRight: 12 }}
                    alt={''}
                    src={option.flag}
                  />
                )}
                <Typography variant="body1">{option.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
          {extraOptions?.map((option) => (
            <MenuItem
              disableRipple
              key={option.key}
              sx={option.styles}
              value={option.value as string}
              {...option.menuItemProps}
            >
              <Stack direction="row" alignItems="center">
                {option.button ? (
                  option.content
                ) : (
                  <Typography variant="body1">{option.content}</Typography>
                )}
              </Stack>
            </MenuItem>
          ))}
        </Select>
        {enableRangeDash && (
          <Box
            className="RANGE_DASH"
            sx={{
              width: '8px',
              height: 0,
              border: '1px solid',
              borderColor: 'grey.600',
              position: 'absolute',
              right: -16,
              top: '45%',
            }}
          ></Box>
        )}
      </Stack>
      {errorMessage && (
        <Stack
          sx={{ color: 'error.700', visibility: hideExtraText ? 'hidden' : 'visible' }}
          mt={1}
          gap={1}
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <ErrorOutlineIcon fontSize="large" />
          <Typography
            sx={hideExtraText ? { visibility: 'hidden' } : {}}
            color="inherit"
            variant="body2"
          >
            {errorMessage}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default DropdownSelect
