import SearchesList from '../SearchesList'
import { PeopleVisitResult } from 'services/api/endpoints/risikaAPI/peopleVisitsList'
import { columns } from './PeopleVisits.model'
const response = {
  search_result: [
    {
      personal_id: '4008562483',
      country: 'DK',
      name: 'Tsvetomir Batinov',
      aliases: ['Tsvetomir Batinov'],
      functions: [
        {
          active: true,
          function: 'FOUNDER',
          title: 'Founder',
        },
        {
          active: false,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: ['RIEV ApS'],
      active_relations: [
        {
          name: 'RIEV ApS',
          local_organization_id: {
            id: '42832812',
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4007579683',
      country: 'DK',
      name: 'Tsvetomira Stefanova Kulicheva-Hansen',
      aliases: ['Tsvetomira Stefanova Kulicheva-Hansen'],
      functions: [
        {
          active: true,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: ['MK Bryllup'],
      active_relations: [
        {
          name: 'MK Bryllup',
          local_organization_id: {
            id: '39443325',
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4000674072',
      country: 'DK',
      name: 'Tsvetomir Stoychev Boyanov',
      aliases: ['Tsvetomir Stoychev Boyanov'],
      functions: [
        {
          active: false,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: [],
      active_relations: [
        {
          name: null,
          local_organization_id: {
            id: null,
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4000762576',
      country: 'DK',
      name: 'Tsvetomil Stefanov Mladenov',
      aliases: ['Tsvetomil Stefanov Mladenov'],
      functions: [
        {
          active: false,
          function: 'STAKEHOLDER',
          title: 'Stakeholder',
        },
      ],
      active_company_relations: [],
      active_relations: [
        {
          name: null,
          local_organization_id: {
            id: null,
            country: 'DK',
          },
        },
      ],
    },
    {
      personal_id: '4004174412',
      country: 'DK',
      name: 'Ilona Tsvetomirova Bonkovski',
      aliases: ['Ilona Tsvetomirova Bonkovski'],
      functions: [
        {
          active: false,
          function: 'FOUNDER',
          title: 'Founder',
        },
        {
          active: false,
          function: 'MANAGEMENT',
          title: 'Management',
        },
      ],
      active_company_relations: [],
      active_relations: [
        {
          name: null,
          local_organization_id: {
            id: null,
            country: 'DK',
          },
        },
      ],
    },
  ],
  count: 5,
}

function PeopleVisitsLoading() {
  return (
    <SearchesList<PeopleVisitResult>
      isLoading={true}
      searches={response.search_result}
      columns={columns}
    />
  )
}

export default PeopleVisitsLoading
