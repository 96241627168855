import * as React from 'react'
import { Typography, Popper } from '@mui/material'
import { Button, Stack } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from '../styles/pop-confirm'

// type PopconfirmProps = {
//   onConfirm: Function,
//   children: (toggleOpen: Function) => any,
//   color?: 'primary' | 'secondary',
//   placement?:
//     | 'top'
//     | 'top-start'
//     | 'top-end'
//     | 'bottom'
//     | 'bottom-start'
//     | 'bottom-end'
//     | 'left'
//     | 'left-start'
//     | 'left-end'
//     | 'right'
//     | 'right-start'
//     | 'right-end',
//   title?: any,
//   description?: any,
//   confirmIcon?: React.ReactNode,
//   confirmColor?: 'primary' | 'secondary' | 'error' | 'contrast',
//   confirmText?: any,
//   cancelText?: any,
// }

const PopConfirmation = ({
  onConfirm,
  children,
  color = 'primary',
  placement = 'top',
  title = <FormattedMessage id={intl.popconfirm('title')} />,
  description,
  confirmColor = 'secondary',
  confirmIcon,
  confirmText = <FormattedMessage id={intl.popconfirm('confirm')} />,
  cancelText = <FormattedMessage id={intl.popconfirm('cancel')} />,
}) => {
  const classes = useStyles({ color })

  const [open, setOpen] = React.useState(false)
  const ref = React.useRef(null)

  function toggleOpen() {
    return setOpen((prevOpen) => !prevOpen)
  }

  function handleCancel() {
    setOpen(false)
  }

  function handleConfirm() {
    setOpen(false)
    onConfirm()
  }

  return (
    <div ref={ref}>
      {children(toggleOpen)}
      <Popper placement={placement} disablePortal open={open} anchorEl={ref.current}>
        <div className={classes.content}>
          <Stack direction="column" spacing={1}>
            <div>
              <Typography variant="body1" className={classes.title}>
                {title}
              </Typography>
              {description ? (
                <Typography variant="body1" className={classes.description}>
                  {description}
                </Typography>
              ) : null}
            </div>
            <Stack spacing={1} justify="flex-end">
              <Button
                color="primary"
                variant={color === 'primary' ? 'contained' : 'text'}
                className={classes.button}
                onClick={handleCancel}
              >
                {cancelText}
              </Button>
              <Button
                color={confirmColor}
                icon={confirmIcon}
                className={classes.button}
                onClick={handleConfirm}
              >
                {confirmText}
              </Button>
            </Stack>
          </Stack>
        </div>
      </Popper>
    </div>
  )
}

export default PopConfirmation
