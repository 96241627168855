import { ComponentStateHandler, ErrorPageSimple } from 'components-new'
// import CompanyVisitsLoading from './PersonVisits.loading'
import SearchesList from '../SearchesList'
import { columns } from './PeopleVisits.model'
import { usePeopleVisitsList } from 'services/queries/usePeopleVisitsList'
import { PeopleVisitResult } from 'services/api/endpoints/risikaAPI/peopleVisitsList'
import PeopleVisitsLoading from './PeopleVisits.loading'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { SavedSearch } from '@mui/icons-material'

function PeopleVisits() {
  const { data: personVisitsList, isLoading, isError } = usePeopleVisitsList({})
  return (
    <ComponentStateHandler
      isLoading={isLoading}
      loading={<PeopleVisitsLoading />}
      isError={isError}
      error={<ErrorPageSimple content={''} />}
      isEmptyState={!personVisitsList?.length}
      emptyState={
        <ErrorPageSimple
          title={<FormattedMessage id={intl.homePage('recent-searches-title')} />}
          content={<FormattedMessage id={intl.home('recent-searches-content')} />}
          icon={<SavedSearch sx={{ width: '6rem', height: '6rem', color: 'grey.600' }} />}
        />
      }
    >
      {personVisitsList?.length && (
        <SearchesList<PeopleVisitResult> searches={personVisitsList} columns={columns} />
      )}
    </ComponentStateHandler>
  )
}

export default PeopleVisits
