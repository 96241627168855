import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component'

import { useStyles } from '../../../styles/country-cell'
const getCorrectFlag = (country) => {
  switch (country) {
    case 'DK':
      return 'da_DK'
    case 'SE':
      return 'sv_SE'
    case 'NO':
      return 'nb_NO'
    case 'FI':
      return 'fi_FI'
    default:
      return null
  }
}

const CountryCell = ({ localId, scrollPosition }) => {
  const classes = useStyles()
  const flag = getCorrectFlag(localId.country)
  return (
    <div className={classes.root}>
      <LazyLoadImage
        scrollPosition={scrollPosition}
        effect="blur"
        className={classes.lazyLoadImage}
        alt={`Country ${flag ?? ''}`}
        src={`risika/flags/${flag ?? 'da_DK'}.png`}
      />
    </div>
  )
}

export default trackWindowScroll(CountryCell)
