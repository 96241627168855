import { useQuery } from 'react-query'
import { getProductionUnits } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useProductionUnits(localId: LocalId) {
  return useQuery(
    ['getProductionUnits', localId.id, localId.country],
    () => getProductionUnits(localId.id ?? '', localId.country),
    {
      enabled: !!isCallAllowed('getProductionUnits', localId.country),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
