// @flow

import { LocalId } from 'globalTypes'
import moment from 'moment'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

type Options = {
  consolidated?: boolean
}

export interface FinancialStats {
  period: {
    start: string
    end: string
  }
  pdf_link?: string
  type?: string
  class_of_reporting_entity?: string
  approval_date?: string
  general_meeting_date?: string
  auditor: {
    company_name: string
    name: string
    description: string
    description_code: string
    type_of_assistance: string
    type_of_assistance_code: string
    local_organization_id: LocalId
  } | null
  currency: string
  ifrs: boolean
  average_number_of_employees?: number
}

export type FinancialStatsResponse = FinancialStats[]

export type FinancialStatsData = FinancialStatsResponse

/***********
 * Helpers *
 ***********/

/**
 * Sort financial statements by newest.
 */
function sortByNewest(a: FinancialStats, b: FinancialStats) {
  return moment(b.period.end).diff(a.period.end)
}

/**
 * Gets all the _financial stats_ for a given company.
 *
 * @param {object} id _Local ID_ and _Country Code_ of the company.
 * @returns {object} All the different _financial stats_ associated with the company.
 */
type ResponseData = {
  data: FinancialStats[]
}
async function financialStats(
  { id, country }: LocalId,
  options?: Options,
): Promise<FinancialStatsData> {
  const { consolidated = false } = options ?? {}

  const consolidatedText = consolidated ? 'true' : 'false'

  /**
   * Only use consolidated if it's a Danish company.
   */
  const endpoint = `/financial/stats/${id}?consolidated_statement=${consolidatedText}`

  const { data }: ResponseData = await risikaAPI.get(endpoint, {
    microservice: 'data',
    country,
  })

  return data.sort(sortByNewest).map((x) => ({
    period: x.period,
    pdf_link: x.pdf_link,
    type: x.type,
    class_of_reporting_entity: x.class_of_reporting_entity,
    approval_date: x.approval_date,
    general_meeting_date: x.general_meeting_date,
    auditor: x.auditor,
    currency: x.currency,
    ifrs: x.ifrs,
    average_number_of_employees: x.average_number_of_employees,
  }))
}

export default financialStats
