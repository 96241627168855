import { Paper, Skeleton, Stack } from '@mui/material'
import TitleMainRecommendation from 'pages/credit-check/scenes/MainRecommendation/elements/TitleMainRecommendation'

const CreditOverrideSkeleton = () => {
  return (
    <Paper elevation={2} component={Stack} m={4} p={4} gap={5}>
      <TitleMainRecommendation labelKey="credit-override" />
      <Skeleton variant="text" width="100%" height={22} />
      <Skeleton variant="rectangular" width="100%" height={44} />
    </Paper>
  )
}

export default CreditOverrideSkeleton
