import { Typography, Stack, Switch } from '@mui/material'
import intl from 'localization/components'
import { StatusPropsType } from './Status.types'
import { styles } from './Status.styles'
import { FormattedMessage } from 'react-intl'
/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'

const Status = ({ status, handleChangeStatus }: StatusPropsType) => {
  return (
    <Stack gap={3}>
      <Typography variant="h5">
        <FormattedMessage id={intl.portfolioInsights('tab-scheduler-status')} />
      </Typography>
      <Stack direction="row" alignItems="center" gap={2}>
        <Switch checked={status} onChange={handleChangeStatus} sx={styles.switchStyles} />
        <Typography sx={styles.switchLabel}>
          <FormattedMessage
            id={intl.portfolioInsights(
              status ? 'tab-scheduler-status-active' : 'tab-scheduler-status-disabled'
            )}
          />
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Status
