const text = {
  color: 'common.white',
}

const bulletWrapper = {
  pt: 2,
}

const bullet = {
  color: 'common.white',
}

export const classes = {
  text,
  bullet,
  bulletWrapper,
}
