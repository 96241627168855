import { Typography } from '@mui/material'
import { LastLoginProps } from '../../UserTable.types'
import moment from 'moment/moment'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { HumanDate } from 'components'

const LastLogin = ({ user }: LastLoginProps) => {
  const { locale } = useIntl()
  const { lastLogin } = user
  const daysSinceLastLogin = moment().locale(locale).diff(lastLogin, 'days')

  if (daysSinceLastLogin < 30) {
    return <HumanDate date={moment(lastLogin)} />
  }

  if (lastLogin) {
    return (
      <Typography variant="body2">
        <FormattedMessage id={intl.userManagement('more-than-30')} />
      </Typography>
    )
  }

  return (
    <Typography variant="body2">
      <FormattedMessage id={intl.userManagement('no-data-yet')} />
    </Typography>
  )
}

export default LastLogin
