const main = {
  mt: 6,
}

const title = {
  mb: 4,
}

const user = {
  pl: 2,
  pb: 1,
}

export const classes = {
  main,
  title,
  user,
}
