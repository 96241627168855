import { ChevronRight } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { classes } from './styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const ClickableOption = (props: any) => {
  const { option } = props
  return (
    <Box sx={classes.clickableOption}>
      <Typography variant="body2" color="#353A3F">
        <FormattedMessage id={intl.faq(option)} />
      </Typography>
      <ChevronRight color="primary" fontSize="large" sx={classes.chevron} />
    </Box>
  )
}

export default ClickableOption
