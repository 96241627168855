import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { useSnackbar } from 'notistack'
import { Stack, IconButton } from '@mui/material'
import { ChipStack, InputField } from 'components-new'
import DeleteIcon from '@mui/icons-material/Delete'

const AdvancedZipcodeInput = ({ value, onChange, onRemove }) => {
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const { enqueueSnackbar } = useSnackbar()
  const [chipValue, setChipValue] = React.useState(value ?? [])
  const [error, setError] = React.useState({})
  const [textValue, setTextValue] = React.useState('')
  React.useEffect(() => {
    if (error?.error) {
      enqueueSnackbar(error.error, { variant: 'error' })
      setError({})
    }
  }, [enqueueSnackbar, error])
  // Fix: Ensure the onChange is stable.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableOnChange = React.useCallback(onChange, [])
  React.useEffect(() => {
    stableOnChange({ value: chipValue })
  }, [chipValue, stableOnChange])

  const chipData = chipValue.map((chip) => ({ value: chip, label: chip }))

  const handleChipDelete = (chipToDelete) => {
    setChipValue(chipValue.filter((chip) => chip !== chipToDelete))
  }

  const updateChip = (chip) => {
    if (!chip) {
      return
    }
    // All numbers and "-"
    if (!chip.match(/^[0-9-]*$/)) {
      //  Same but the opposite
      const IllegalCharacters = chip.replace(/[0-9-]/g, '')
      setError({
        type: 'illegal-character',
        error: reactIntl.formatMessage(
          {
            id: intl.advancedFilters('illegal-character'),
          },
          {
            char: IllegalCharacters,
            count: IllegalCharacters.length,
          }
        ),
      })
      return
    }

    if (chip.includes('-')) {
      const values = chip
        ?.split('-')
        ?.sort()
        ?.map((x) => parseInt(x))
      const reverted = values.filter((val) => !isNaN(val)).join('-') // remove NaN (this is in case the string starts with "-" at index 0)
      if (!reverted.length) return
      if (!values?.length) {
        enqueueSnackbar(
          <FormattedMessage id={intl.snackbar('report-error-something-wrong')} />
        )
      }
      setChipValue([...chipValue, reverted])
      return
    }

    setChipValue([...chipValue, chip])
  }

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      if (!textValue) {
        return
      }
      if (chipValue?.length && chipValue.includes(textValue)) {
        setTextValue('')
        return
      }
      updateChip(textValue)
      setTextValue('')
    }
  }

  return (
    <Stack direction="column" minHeight={44}>
      <Stack direction="row" maxWidth={300}>
        <InputField
          value={textValue}
          placeholder={messages[intl.advancedFilters('values-or-ranges')]}
          handleChange={setTextValue}
          onKeyDown={handleEnterPress}
          extraStyles={{ width: 300 }}
        />
        <IconButton onClick={() => onRemove()}>
          <DeleteIcon color="primary" sx={{ fontSize: '21.5px' }} />
        </IconButton>
      </Stack>
      <ChipStack
        chipData={chipData}
        setValues={() => {}}
        handleChipDelete={handleChipDelete}
      />
    </Stack>
  )
}

export default AdvancedZipcodeInput
