import { useDispatch } from 'react-redux'
import { navbarOpenMobile } from 'store_deprecated'
import { useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import SearchBar from './SearchBar'
import UserMenu from './UserMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {
  StyledContainer,
  StyledToolbar,
  StyledAppbar,
  StyledMenuButton,
  StyledSearchContainer,
} from '../styles/top-bar'
import { useAccountStatus, useUserSelf } from 'services/queries'
import { Mixpanel } from 'services/helpers/mixpanel'
import { Icon } from '@fortawesome/fontawesome-svg-core'

export default function Topbar() {
  const dispatch = useDispatch()
  const { data } = useUserSelf()
  const { data: accountStatus } = useAccountStatus()

  Mixpanel.identify(data?.id?.toString() || '')
  Mixpanel.people.set({
    name: data?.name || '',
    email: data?.email || '',
    $email: data?.email,
    role: data?.role,
    customerID: data?.customer_id,
    apiUserID: data?.api_user_id,
    subscription: accountStatus?.subscription_plan,
  })

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <StyledContainer>
      <StyledAppbar position="fixed">
        <StyledToolbar>
          {isSmallScreen && (
            <StyledMenuButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(navbarOpenMobile())}
            >
              <FontAwesomeIcon icon={faBars as Icon} />
            </StyledMenuButton>
          )}
          <StyledSearchContainer>
            <SearchBar />
          </StyledSearchContainer>
          {!isSmallScreen && <UserMenu name={data?.name} role={data?.role} />}
        </StyledToolbar>
      </StyledAppbar>
    </StyledContainer>
  )
}
