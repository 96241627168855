import { authRoles } from 'components/auth'
import CreditCheckPage from './CreditCheckPage'
import { navigation } from 'services/navigation'
export const CreditCheckPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: `${navigation.creditCheck()}/:country/:id`,
      component: CreditCheckPage,
    },
    {
      path: `${navigation.creditCheck()}/:id/`,
      component: CreditCheckPage,
    },
    {
      path: navigation.creditCheck(),
      component: CreditCheckPage,
    },
  ],
}
