import { SxProps } from '@mui/system'

const stack: SxProps = {
  flexDirection: 'column-reverse',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  height: '325px',
  width: '220px',
}

const typography: SxProps = {
  position: 'absolute',
  textAlign: 'center',
  width: '100%',
  top: 0,
  display: 'flex',
  flexDirection: 'column-reverse',
  height: '20px',
}

const iconButton: SxProps = {
  cursor: 'default',
  alignSelf: 'flex-end',
  fontSize: 19.21,
  color: 'primary.main',
}

export default {
  stack,
  typography,
  iconButton,
}
