import { useState, KeyboardEvent } from 'react'
import { IconButton, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import useMonitoringListRename from 'services/queries/useMonitoringListRename'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type EditSelectedListProps = {
  selectedList: number
  setIsEditing: (isEditing: boolean) => void
  name: string
}

const EditSelectedList = ({
  selectedList,
  setIsEditing,
  name,
}: EditSelectedListProps) => {
  const [listName, setListName] = useState(name ?? '')

  const handleOnSuccess = () => {
    setListName('')
    setIsEditing(false)
  }

  const { renameList } = useMonitoringListRename({
    onSuccess: handleOnSuccess,
    onError: () => {
      setIsEditing(false)
    },
  })

  const editList = () => {
    renameList({ listId: selectedList, title: listName })
  }

  const handleEditModeKeyEvents = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Escape':
        setIsEditing(false)
        break
      case 'Enter':
        editList()
        break
      default:
        // just to remove warning in console
        break
    }
  }

  return (
    <>
      <TextField
        type="text"
        value={listName}
        onChange={(e) => setListName(e.target.value)}
        autoFocus
        fullWidth
        onFocus={(event) => {
          event.target.select()
        }}
        onKeyUp={handleEditModeKeyEvents}
        margin="normal"
      />
      <IconButton onClick={() => editList()} className="ml-6">
        <FontAwesomeIcon icon={faCheck as IconProp} />
      </IconButton>
      <IconButton onClick={() => setIsEditing(false)} className="ml-6">
        <FontAwesomeIcon icon={faTimes as IconProp} />
      </IconButton>
    </>
  )
}

export default EditSelectedList
