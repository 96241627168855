export const styles = {
  root: {
    maxWidth: '2000px',
  },
  linkEnabled: {
    textDecoration: 'none !important',
  },
  linkDisabled: {
    textDecoration: 'none !important',
    pointerEvents: 'none',
  },
}
