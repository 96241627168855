import { useEffect, useState } from 'react'
import { usePaginatedMonitoringList } from 'services/queries'
import { shallowCloneArray } from 'utils-new/general'
import {
  ColumnsDefinition,
  TableDataAPIType,
} from 'components-new/data-display/Table/Table.types'
import { SelectedType } from 'components-new/data-display/Table/Table.types'
import TableToolbar from 'components-new/data-display/Table/elements/TableToolbar'
import { apiParameters, blankTableAPIData, columnsDefinition } from './Companies.model'
import { TableWithToolbar } from 'components-new'
import { calculateActiveFilters, formatData } from './Companies.controller'
import { useCreditAutomatorTableContext } from 'pages/risk-monitoring/widgets/CreditAutomatorMonitoringTable/context'

const Companies = ({
  listId,
  tableFilters,
  isLoading,
  handleFilters,
  handleSearch,
  creditPolicy,
}: {
  listId: string
  tableFilters: {} | null
  isLoading: boolean
  handleFilters: (category: string, key: string) => void
  handleSearch: (searchTerm: string) => void
  creditPolicy: string
}) => {
  const [columns, setColumns] = useState<any[]>([])
  const { state, actions } = useCreditAutomatorTableContext()
  const { page, perPageLimit, sortBy } = state

  const [tableData, setTableData] = useState<TableDataAPIType>(blankTableAPIData)
  const [selected, setSelected] = useState<SelectedType[]>([])
  const paginatedMonitoringListsQuery = usePaginatedMonitoringList(
    Number(listId),
    {
      ...apiParameters,
      page: page,
      limit: perPageLimit,
      sort_by: sortBy,
      filter_on: calculateActiveFilters(tableFilters),
    },
    60000
  )

  useEffect(() => {
    actions.updatePage(1)
  }, [tableFilters])

  useEffect(() => {
    if (paginatedMonitoringListsQuery.isSuccess) {
      if (
        shallowCloneArray(tableData!.data) !==
        shallowCloneArray(paginatedMonitoringListsQuery.data.data)
      ) {
        setTableData(paginatedMonitoringListsQuery.data)
      }
    }
  }, [
    paginatedMonitoringListsQuery,
    paginatedMonitoringListsQuery.data,
    paginatedMonitoringListsQuery.isSuccess,
    tableData,
  ])

  // Check the type of CP used and removes allowed column which is not supported by ECP
  // This should be changed once we introduce the new columnsDefinition model for ECP data
  useEffect(() => {
    if (paginatedMonitoringListsQuery.data) {
      if (creditPolicy === 'credit_policy') {
        setColumns(
          columnsDefinition.filter(
            (column) => column.key !== 'value' && column.key !== 'credit_automator_result'
          )
        )
      }
      if (creditPolicy === 'credit_automator') {
        setColumns(columnsDefinition.filter((column) => column.key !== 'allowed'))
      }
    }
  }, [paginatedMonitoringListsQuery.data, creditPolicy])

  return (
    <TableWithToolbar
      tableData={tableData}
      formatData={formatData}
      columnsDefinition={columns as ColumnsDefinition}
      page={page}
      perPageLimit={perPageLimit}
      setPerPageLimit={actions.updatePerPageLimit}
      setSortBy={actions.updateSortBy}
      setPage={actions.updatePage}
      getSelected={setSelected}
    >
      <TableToolbar
        listId={listId}
        tableFilters={tableFilters}
        isLoading={isLoading}
        selectedCompanies={selected}
        handleSearch={handleSearch}
        handleFilters={handleFilters}
      />
    </TableWithToolbar>
  )
}

export default Companies
