import { Stack, Typography } from '@mui/material'
import { CompanyVisit } from 'services/api/endpoints/risikaAPI/companyVisitsList'
import { padding } from './columns.styles'

type Props = {
  item: CompanyVisit
}

function Type({ item }: Props) {
  return (
    <Stack sx={padding}>
      <Typography color="grey.800" variant="body2">
        {item.company_type}
      </Typography>
    </Stack>
  )
}

export default Type
