import { difference, intersection } from 'ramda'

function createLocalIdKey({ id, country }) {
  return `${country.toUpperCase()}${id}`
}

function removeKeysWithNullValue(note) {
  // Done this way to maintain type information.
  const removedKeys = { ...note.value.data }
  for (const key in removedKeys) {
    if (removedKeys[key] === null) {
      delete removedKeys[key]
    }
  }

  return {
    ...note,
    value: {
      ...note.value,
      data: removedKeys,
    },
  }
}

/**
 * Merge notes.
 *
 * Will mark each note with information on whether
 * it contains a change or a conflict.
 *
 * If at least one note contains a change or a conflict,
 * then the entire "merge notes" will show it.
 *
 * @param existingNotes
 * @param newNotes
 */
function mergeNotes(existingNotes, newNotes) {
  // In case there aren't any existing notes,
  // then we don't have to compare anything.
  if (existingNotes.length === 0) {
    if (newNotes.length === 0) {
      return []
    }
    return newNotes.map((note) => ({
      value: note,
      hasChange: true,
      hasConflict: false,
    }))
  }

  // We have to compare the keys of the data in each.
  const mergedNotes = new Map()
  const existingNotesMap = new Map()

  for (const existingNote of existingNotes) {
    mergedNotes.set(createLocalIdKey(existingNote.localId), {
      value: existingNote,
      hasChange: false,
      hasConflict: false,
    })

    existingNotesMap.set(createLocalIdKey(existingNote.localId), existingNote)
  }

  for (const note of newNotes) {
    // If it doesn't have a Local Id, then we can't use it for anything.
    if (note.localId === null) {
      continue
    }

    let noteHasChange = false
    let noteHasConflict = false

    const existingNote = existingNotesMap.get(createLocalIdKey(note.localId))

    if (existingNote != null) {
      // The new changes
      const noteChange = difference(
        Object.keys(note.data),
        Object.keys(existingNote.data)
      )

      if (noteChange.length > 0) {
        noteHasChange = true
      }

      // Potential conflicts (conflict or no change)
      const noteMaybeConflict = intersection(
        Object.keys(existingNote.data),
        Object.keys(note.data)
      )

      for (const conflictKey of noteMaybeConflict) {
        if (existingNote.data[conflictKey] !== note.data[conflictKey]) {
          noteHasChange = true
          noteHasConflict = true
        }
      }

      mergedNotes.set(createLocalIdKey(note.localId), {
        value: {
          ...note,
          data: Object.assign({}, existingNote.data, note.data),
        },
        hasChange: noteHasChange,
        hasConflict: noteHasConflict,
      })
    } else {
      mergedNotes.set(createLocalIdKey(note.localId), {
        value: note,
        hasChange: true,
        hasConflict: false,
      })
    }
  }

  const mergedNotesData = []
  for (const [, mergedNote] of mergedNotes) {
    mergedNotesData.push(mergedNote)
  }

  return mergedNotesData.map(removeKeysWithNullValue)
}

export default mergeNotes
