import { useState, useEffect, FormEvent } from 'react'
import { Stack, Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ButtonSecondary, InputField } from 'components-new'
import { useUpdateUserSelf, useUserSelf } from 'services/queries'

const buttonColor = { backgroundColor: 'primary.dark' }

const initialState = {
  name: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
}

export type StateKey = keyof typeof initialState

export type SubmitAction = 'updateUser' | 'updatePassword'

const UserInformation = () => {
  const [state, setState] = useState(initialState)
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: updateUser } = useUpdateUserSelf()
  const { data: currentUser } = useUserSelf()

  useEffect(() => {
    if (currentUser) {
      const { name, email, phone_number } = currentUser
      setState({
        ...initialState,
        name,
        email,
        phone: phone_number,
      })
    }
  }, [currentUser])

  const handleChange = (name: StateKey, value: string) => {
    setState((state) => ({
      ...state,
      [name]: value,
    }))
  }

  const handleUpdatePassword = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      enqueueSnackbar(
        <FormattedMessage id={intl.settings('confirm-password-requirements')} />,
        {
          variant: 'error',
        }
      )
      return
    }

    if (password.length < 8) {
      enqueueSnackbar(<FormattedMessage id={intl.settings('password-requirements')} />, {
        variant: 'error',
      })
      return
    }

    updateUser({ password, confirmPassword }).then(() => {
      enqueueSnackbar(<FormattedMessage id={intl.settings('user-password-updated')} />, {
        variant: 'success',
      })
      setState({
        ...state,
        password: '',
        confirmPassword: '',
      })
    })
  }

  // Handle form submission based on the action.
  const handleSubmit = (action: SubmitAction) => (event: FormEvent) => {
    event.preventDefault()
    if (!currentUser) return

    const { name, phone, password, confirmPassword } = state

    if (action === 'updateUser') {
      updateUser({ name, phone }).then(() => {
        enqueueSnackbar(
          <FormattedMessage id={intl.settings('user-information-updated')} />,
          {
            variant: 'success',
          }
        )
      })
    } else if (action === 'updatePassword') {
      handleUpdatePassword(password, confirmPassword)
    }
  }

  const { name, email, phone, password, confirmPassword } = state

  return (
    <Stack spacing={8} flex="1">
      <form style={{ width: '100%' }} onSubmit={handleSubmit('updateUser')}>
        <Stack spacing={5}>
          <Typography variant="h4">
            <FormattedMessage id={intl.settings('user-information-title')} />
          </Typography>
          <InputField
            name="name"
            label={<FormattedMessage id={intl.companyHierarchy('name')} />}
            value={name ?? ''}
            handleChange={(val) => handleChange('name', val)}
          />
          <InputField
            name="email"
            label={<FormattedMessage id={intl.generic('email')} />}
            value={email ?? ''}
            handleChange={() => {}}
            disabled
          />
          <InputField
            name="phone"
            label={<FormattedMessage id={intl.generic('phone')} />}
            value={phone ?? ''}
            handleChange={(val) => handleChange('phone', val)}
          />
        </Stack>
        <Box mt={4}>
          <ButtonSecondary type="submit" sx={buttonColor}>
            <FormattedMessage id={intl.settings('update-user')} />
          </ButtonSecondary>
        </Box>
      </form>

      <form onSubmit={handleSubmit('updatePassword')}>
        <Stack spacing={5}>
          <Typography variant="h4">
            <FormattedMessage id={intl.settings('password-title')} />
          </Typography>
          <InputField
            name="password"
            type="password"
            label={<FormattedMessage id={intl.settings('new-password')} />}
            value={password ?? ''}
            handleChange={(val) => handleChange('password', val)}
          />
          <InputField
            name="confirmPassword"
            type="password"
            label={<FormattedMessage id={intl.settings('confirm-new-password')} />}
            value={confirmPassword ?? ''}
            handleChange={(val) => handleChange('confirmPassword', val)}
          />
        </Stack>
        <Box mt={4}>
          <ButtonSecondary type="submit" sx={buttonColor}>
            <FormattedMessage id={intl.settings('update-password')} />
          </ButtonSecondary>
        </Box>
      </form>
    </Stack>
  )
}

export default UserInformation
