import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import TableLoading from './SimpleTable.loading'
import { SimpleTableProps } from './SimpleTable.types'
import { StyledTableRow } from './SimpleTable.styles'

function SimpleTable<T>({ columns, data, isLoading }: SimpleTableProps<T>) {
  const theme = useTheme()

  if (isLoading) {
    return <TableLoading data={data} columns={columns} />
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell
                size="small"
                sx={{
                  borderRight: `1px solid ${theme.palette.grey[200]}`,
                  width: `${column.width ?? 'auto'}`,
                  padding: 0,
                }}
                key={column.id}
              >
                {column.headerComponent ? column.headerComponent() : column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              {columns.map((column) => (
                <TableCell
                  align={column.align ?? 'inherit'}
                  size="small"
                  sx={{
                    padding: 0,
                    borderRight: `1px solid ${theme.palette.grey[200]}`,
                  }}
                  key={column.id}
                >
                  {column.bodyComponent(item, index)}
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SimpleTable
