import intl from 'localization/components'

export const averageNumbersData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [
      { category: 'ASSETS', function: 'AVERAGE' },
      { category: 'GROSS_RESULT', function: 'AVERAGE' },
      { category: 'EMPLOYEES_NUMBER', function: 'AVERAGE' },
    ],
    group_by: [],
  },
  boxes: [
    {
      category_type: 'ASSETS',
      title: intl.observationalLists('annual-result-average-title'),
      displayProps: { style: 'currency', currency: 'DKK' },
    },
    {
      category_type: 'GROSS_RESULT',
      title: intl.observationalLists('equity-average-title'),
      displayProps: { style: 'currency', currency: 'DKK' },
    },
    {
      category_type: 'EMPLOYEES_NUMBER',
      title: intl.observationalLists('employees-average-title'),
      displayProps: { style: 'decimal', currency: '' },
    },
  ],
}
