import { Box, Tooltip, Checkbox, Typography, Stack, Skeleton } from '@mui/material'
import { tableStringLimit } from '../Table.model'
import { displayCountryFlag } from '../Table.controller'
import { limitString } from 'utils-new/general'
import GreenTrendArrow from 'components-new/elements/GreenTrendArrow'
import RedTrendArrow from 'components-new/elements/RedTrendArrow'
import PolicyOutcomePill from 'components-new/elements/PolicyOutcomePill/PolicyOutcomePill'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import {
  classes,
  getScoreStyle,
  getBadgeColor,
  getColorStyle,
  compliantStyle,
} from '../Table.styles'
import {
  HandleCheckRowFunction,
  SelectedType,
  TableColumn,
  TableRowData,
  TrendProperty,
} from '../Table.types'
import { Link } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { PolicyOutcomePillText } from '../../../elements/PolicyOutcomePill/PolicyOutcomePill.types'
import InternalIdCell from './InternalIdCell/InternalIdCell'

const TableBodyCell = ({
  headCell,
  item,
  handleCheck,
  selected,
  refetch,
}: {
  headCell: TableColumn
  item: TableRowData
  handleCheck: HandleCheckRowFunction
  selected: SelectedType[]
  refetch: () => void
}) => {
  let tooltipText = ''
  let extraStyles = {}
  const cellText = item[headCell.key as keyof TableRowData] as string
  let DisplayContent = () => (
    <Typography>{limitString(cellText, tableStringLimit)}</Typography>
  )
  let trendProperty: TrendProperty | null = null
  let TrendArrow = () => <></>
  if (headCell.key === 'company_name') {
    tooltipText = item.company_name
  } else if (headCell.key === 'country') {
    tooltipText = item.country
  }
  if (headCell.key === 'credit_automator_result') {
    DisplayContent = () => (
      <Typography sx={{ ...getBadgeColor(cellText) }}>
        <PolicyOutcomePill key={headCell.key} text={cellText as PolicyOutcomePillText} />
      </Typography>
    )
  }

  if (headCell.key === 'internal_id') {
    extraStyles = { width: '100%' }
    DisplayContent = () => <InternalIdCell item={item} refetch={refetch} />
  }

  if (headCell.key === 'allowed') {
    const CompliantIcon = cellText === 'COMPLIANT' ? CheckIcon : CloseIcon

    DisplayContent = () => (
      <Typography sx={{ ...compliantStyle(cellText) }}>
        <CompliantIcon sx={{ fontSize: '2.4rem', padding: '0.4rem' }} />
      </Typography>
    )
  }

  if (headCell.key === 'score') {
    DisplayContent = () => (
      <Typography sx={{ ...getScoreStyle(Number(cellText)) }}>
        {limitString(cellText, tableStringLimit)}
      </Typography>
    )
  }

  if (headCell.key === 'checkbox') {
    DisplayContent = () => (
      <Typography>
        <Checkbox
          data-cy="monitor-select-all-toggle"
          sx={{ padding: 0, color: '#E5EEF0' }}
          onClick={() => handleCheck(item.id, item.country, item.original)}
          checked={selected.findIndex((x) => x.id === item.id) > -1}
        />
      </Typography>
    )
  }

  if (headCell.key === 'company_name') {
    DisplayContent = () =>
      item.original ? (
        <Link to={navigation.creditCheck(item.original.local_organization_id)}>
          <Typography sx={{ fontWeight: '600' }}>
            {limitString(cellText, tableStringLimit)}
          </Typography>
        </Link>
      ) : (
        <Typography sx={{ fontWeight: '600' }}>
          {limitString(cellText, tableStringLimit)}
        </Typography>
      )
  }

  if (headCell.key === 'status_code') {
    DisplayContent = () => (
      <Typography
        sx={{
          width: 'max-content',
          textTransform: 'capitalize',
          ...getColorStyle(cellText),
        }}
      >
        {cellText.toLowerCase()}
      </Typography>
    )
  }
  if (headCell.type === 'loading') {
    DisplayContent = () => (
      <Skeleton
        variant="rectangular"
        width={headCell.width}
        sx={{ borderRadius: 5 }}
        height={25}
      />
    )
  }

  if (headCell.key === 'country') {
    DisplayContent = () => <Typography>{displayCountryFlag(cellText)}</Typography>
  }

  if (headCell.key === 'equity') {
    trendProperty = 'equity_trend'
  } else if (headCell.key === 'profit_loss') {
    trendProperty = 'profit_loss_trend'
  } else if (headCell.key === 'employees_number') {
    trendProperty = 'employment_trend'
  }

  if (trendProperty) {
    const trend = item[trendProperty]
    if (trend === 'UP') {
      TrendArrow = () => (
        <Stack sx={{ ml: 0.6, '& > svg': { fontSize: '2rem', top: 0 } }}>
          <GreenTrendArrow />
        </Stack>
      )
    } else if (trend === 'DOWN') {
      TrendArrow = () => (
        <Stack sx={{ ml: 0.6, '& > svg': { fontSize: '2rem', top: 0 } }}>
          <RedTrendArrow />
        </Stack>
      )
    }
  }

  return (
    <Stack
      data-cy={`table-body-cell-${headCell.key}`}
      key={item.company_name}
      direction="row"
      position="relative"
      sx={{
        padding: '2rem',
        borderBottom: '1px solid lightgray',
        backgroundColor: 'white',
        justifyContent: headCell.justifyContent,
      }}
    >
      <Tooltip title={tooltipText}>
        <Box sx={{ ...classes.table.cell, ...extraStyles }}>
          <DisplayContent />
        </Box>
      </Tooltip>
      <TrendArrow />
    </Stack>
  )
}

export default TableBodyCell
