import { useState, useRef, useEffect } from 'react'
import Tree from 'react-d3-tree'
import HierarchyChildrenBox from './HierarchyChildrenBox'
import { Stack, Button as MuiButton } from '@mui/material/'
// Uncomment if "Center Tree" functionality is needed
// import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import styles from './styles/hierarchy-scene'
import { getLinePath, getMockDataForSkeleton } from './tree-helpers'
import { HierarchySceneProps } from './types'
import HierarchyParentBox from './HierarchyParentBox'
import { Skeleton } from '@mui/material'
import { CompanyHierarchy } from 'types/general'
import { ErrorPage } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { navigation } from 'services/navigation'

const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)

const WALKUP = 'walkup'
const WALKDOWN = 'walkdown'

const maxZoom = 1
const minZoom = isSafari ? 1 : 0.2
const initialZoomLevel = isSafari ? 1 : 0.6
const zoomStep = 0.2

const HierarchyScene = ({
  walkdownData,
  walkupData,
  expandDepthProp,
  onUpdateChildren,
  onUpdateParents,
  setSelectedMaxDepthLevel,
}: HierarchySceneProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [treeZoomLevel, setTreeZoomLevel] = useState<number>(initialZoomLevel)
  const [treeDepthFactor, setTreeDepthFactor] = useState(320)
  const [treeData, setTreeData] = useState<CompanyHierarchy | undefined>(walkdownData)
  const [treeExpandDepth, setTreeExpandDepth] = useState(expandDepthProp)
  const [hierarchyDirection, setHierarchyDirection] = useState<'walkdown' | 'walkup'>(
    WALKDOWN
  )

  const [treeTransitionStyle, setTreeTransitionStyle] = useState({})
  const [hierarchyContainerDimensions, setHierarchyContainerDimensions] = useState({
    width: 0,
    height: 0,
  })
  const [walkdownExpandClicked, setWalkdownExpandClicked] = useState(false)
  const [walkupExpandClicked, setWalkupExpandClicked] = useState(false)

  useEffect(() => {
    setSelectedMaxDepthLevel({ type: hierarchyDirection })
  }, [hierarchyDirection, setSelectedMaxDepthLevel])

  // Forces a tree update if the "Expand All" or "Collapse All" buttons are pressed
  if (expandDepthProp !== treeExpandDepth) {
    const newTreeData = JSON.parse(JSON.stringify(treeData))

    setTreeData(newTreeData)
    setTreeExpandDepth(expandDepthProp)
  }

  const [treePosition, setTreePosition] = useState({
    x: 0,
    y: 0,
  })

  const hierarchyContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isLoading && (walkdownData || walkupData)) {
      setIsLoading(false)

      if (!walkdownData?.children?.length) {
        setHierarchyDirection(WALKUP)
        setTreeData(walkupData)
      } else {
        setTreeData(walkdownData)
      }
    }
  }, [hierarchyDirection, isLoading, walkdownData, walkupData])

  useEffect(() => {
    setTreeData(hierarchyDirection === WALKUP ? walkupData : walkdownData)
  }, [hierarchyDirection, walkdownData, walkupData])

  useEffect(() => {
    if (hierarchyContainerRef) {
      const containerWidth = hierarchyContainerRef?.current?.offsetWidth || 0
      const containerHeight = hierarchyContainerRef?.current?.offsetHeight || 0

      setTreePosition({
        x: containerWidth / 2,
        y: containerHeight / 4,
      })

      setHierarchyContainerDimensions({
        width: containerWidth,
        height: containerHeight,
      })
    }
  }, [hierarchyContainerRef])

  useEffect(() => {
    if (hierarchyContainerDimensions.width) {
      const isWalkup = hierarchyDirection === WALKUP

      setTreeDepthFactor(isWalkup ? -320 : 320) // The negative value makes the tree go upwards

      setTreePosition({
        x: hierarchyContainerDimensions.width / 2,
        y: hierarchyContainerDimensions.height / (isWalkup ? 2 : 4),
      })
    }
  }, [
    hierarchyContainerDimensions.height,
    hierarchyContainerDimensions.width,
    hierarchyDirection,
  ])
  /*
    This function makes the tree change direction with a transition animation.
    After half a second, the transition is removed, because it would affect the mouse drag speed
  */
  const onChangingTreeDirection = () => {
    const transitionStyle = {
      '& .rd3t-g': {
        transition: 'transform 0.5s ease-in-out',
        pointer: 'cursor',
      },
    }

    setTreeTransitionStyle(transitionStyle)

    setTimeout(() => {
      setTreeTransitionStyle({})
    }, 1000)
  }

  const handleHierarchyWalkdown = () => {
    if (!walkdownExpandClicked) {
      onUpdateChildren()
      setWalkdownExpandClicked(true)
    }

    onChangingTreeDirection()
    setHierarchyDirection(WALKDOWN)
  }

  const handleHierarchyWalkup = () => {
    if (!walkupExpandClicked) {
      onUpdateParents()
      setWalkupExpandClicked(true)
    }

    onChangingTreeDirection()
    setHierarchyDirection(WALKUP)
  }

  const zoomIn = () => {
    setTreeZoomLevel(
      Number(treeZoomLevel) + zoomStep >= maxZoom
        ? maxZoom
        : Number(treeZoomLevel) + zoomStep
    )
  }

  const zoomOut = () => {
    setTreeZoomLevel(
      Number(treeZoomLevel) - zoomStep <= minZoom
        ? minZoom
        : Number(treeZoomLevel) - zoomStep
    )
  }

  return (
    <Stack
      sx={{ ...styles.hierarchyContainer, ...treeTransitionStyle }}
      ref={hierarchyContainerRef}
      onDoubleClickCapture={(e) => e.stopPropagation()}
    >
      {isLoading ? (
        isSafari ? (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          // mock tree
          <Tree
            data={getMockDataForSkeleton()}
            zoom={treeZoomLevel}
            pathFunc={(linkDatum) => getLinePath(linkDatum, true)}
            orientation="vertical"
            depthFactor={1}
            translate={treePosition}
            separation={{
              nonSiblings: 4,
              siblings: 3.5,
            }}
            renderCustomNodeElement={() => (
              <foreignObject
                width="420"
                height="300"
                style={{
                  display: 'flex',
                  transform: 'translate(-210px, -150px)',
                  padding: '12px 16px',
                }}
              >
                <Skeleton variant="rectangular" width="100%" height="100%" />
              </foreignObject>
            )}
          />
        )
      ) : treeData ? (
        <Tree
          data={treeData}
          zoom={treeZoomLevel}
          initialDepth={treeExpandDepth} // Levels of expanded children
          pathFunc={(linkDatum) => getLinePath(linkDatum, false)}
          orientation="vertical"
          depthFactor={treeDepthFactor}
          dimensions={hierarchyContainerDimensions} // This property allows the view to be centered on the clicked node. The container width and height need to be provided in order for it to work
          translate={treePosition}
          scaleExtent={{
            max: maxZoom,
            min: minZoom,
          }}
          separation={{
            nonSiblings: 4,
            siblings: 3.5,
          }}
          renderCustomNodeElement={(props) =>
            hierarchyDirection === WALKDOWN ? (
              <HierarchyChildrenBox
                props={{ ...props, treeZoomLevel }}
                onOpenHierarchyWalkup={handleHierarchyWalkup}
                onUpdateChildren={onUpdateChildren}
                direction={hierarchyDirection}
                selectDepth={setSelectedMaxDepthLevel}
                currentDepth={treeExpandDepth}
              />
            ) : (
              <HierarchyParentBox
                props={{ ...props, treeZoomLevel }}
                onOpenHierarchyWalkdown={handleHierarchyWalkdown}
                onUpdateParents={onUpdateParents}
                direction={hierarchyDirection}
                selectDepth={setSelectedMaxDepthLevel}
                currentDepth={treeExpandDepth}
              />
            )
          }
        />
      ) : (
        <ErrorPage
          errorMessage={<FormattedMessage id={intl.creditPolicyNew('export-error')} />}
          buttonText={<FormattedMessage id={intl.creditPolicyNew('industry-type')} />}
          redirectLocation={navigation.home()}
        />
      )}

      <Stack sx={styles.buttonsContainer}>
        {/* Uncomment if "Center Tree" functionality is needed */}
        {/* <MuiButton
          variant="outlined"
          sx={styles.controlButton}
          onClick={() => {
            setTreePosition({
              x: ((hierarchyContainerRef?.current?.offsetWidth || 0) / 2) + 1,
              y: ((hierarchyContainerRef?.current?.offsetHeight || 0) / 4) + 1,
            })
          }}
          startIcon={<ControlCameraIcon />}
        /> */}
        {/* Uncomment if "Center Tree" functionality is needed */}
        <MuiButton
          variant="outlined"
          sx={styles.controlButton}
          onClick={zoomIn}
          startIcon={<AddIcon />}
        />
        <MuiButton
          variant="outlined"
          sx={styles.controlButton}
          onClick={zoomOut}
          startIcon={<RemoveIcon />}
        />
      </Stack>
    </Stack>
  )
}
export default HierarchyScene
