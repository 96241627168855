import { colors } from 'configs'
import { classTabButtons } from 'styles-new/global/inputs'
import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const observationalLists: SxProps = {
  position: 'relative',
  minWidth: '960px',
}

const observationalListsTabs = {
  marginLeft: theme.spacing(6),
  marginRight: theme.spacing(6),
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(0),
  width: 'auto',
  borderRadius: '5px',
  backgroundColor: colors.risikaLight,
  '& button': {
    ...classTabButtons,
  },
}

const observationalListsToolbar: SxProps = {
  minHeight: 'auto',
}

const observationalListNoIdError: SxProps = {
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

const observationalListsTabContainer: SxProps = {
  margin: 0,
  backgroundColor: 'transparent',
  boxShadow: 'none',
}

export const classes = {
  observationalLists,
  observationalListsTabs,
  observationalListsToolbar,
  observationalListNoIdError,
  observationalListsTabContainer,
}
