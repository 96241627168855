import { Stack, Typography } from '@mui/material'
import { InputLabelProps, AddOnPositionType, AddonType } from './InputField.types'

function InputLabel({ addOn, addOnPosition, extraStyles = {} }: InputLabelProps) {
  const getExtraStyles = (addOn: AddonType, addOnPosition: AddOnPositionType) => {
    if (addOn && addOnPosition === 'start') {
      return { borderBottomLeftRadius: 4, borderTopLeftRadius: 4, ...extraStyles }
    }
    if (addOn && addOnPosition === 'end') {
      return { borderBottomRightRadius: 4, borderTopRightRadius: 4, ...extraStyles }
    }
    return extraStyles
  }
  return (
    <Stack
      height={1}
      sx={{
        padding: '10px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'grey.200',
        overflow: 'hidden',
        minWidth: '58px',
        ...getExtraStyles(addOn, addOnPosition),
      }}
    >
      <Typography variant="body1">{addOn}</Typography>
    </Stack>
  )
}

export default InputLabel
