import { useQuery, UseQueryResult } from 'react-query'
import { monitoringStatistic } from 'services/api'
import { useAccountStatus } from './useAccountStatus'
import {
  MonitoringStatisticsData,
  StatisticEndpoints,
} from 'services/api/endpoints/risikaAPI/monitoringStatistic'
import { adjustParameters } from 'widgets/charts/ListCreditScoreByIndustryBox/ListCreditScoreByIndustryBox.controller'
export type MonitoringStatisticQueryParams =
  | {
      list_id: number
    }
  | ReturnType<typeof adjustParameters>
export type MonitoringStatisticParameters = {
  parameters: MonitoringStatisticQueryParams

  endpoint: StatisticEndpoints
  staleTime?: number
  cacheTime?: number
  scoreModel?: boolean | {}
}

export function useMonitoringStatistic<T = MonitoringStatisticsData>({
  parameters,
  endpoint,
  staleTime = 0,
  cacheTime = 50000,
  scoreModel = true,
}: MonitoringStatisticParameters): UseQueryResult<T> {
  const accountStatusQuery = useAccountStatus()

  return useQuery(
    ['monitoringStatistic', JSON.stringify(parameters), endpoint],
    () => monitoringStatistic({ parameters, endPoint: endpoint, scoreModel }),
    {
      staleTime: 600000,
      cacheTime: 600000,
      refetchOnWindowFocus: false,
      enabled: !accountStatusQuery.isLoading || accountStatusQuery.isError,
    }
  )
}
