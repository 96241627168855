import { Typography, Stack, CircularProgress } from '@mui/material'
import { useCompanyBasics } from 'services/queries'
import { LocalId } from 'globalTypes'

const CompanyBasicsHeader = (props: { localId: LocalId }) => {
  const companyBasicsQuery = useCompanyBasics(props.localId, 60000)

  if (companyBasicsQuery.isLoading || !companyBasicsQuery.data) {
    return <CircularProgress />
  }

  return (
    <Stack
      data-cy={`company-basics-header-${companyBasicsQuery.data.local_organization_id.id}`}
      spacing={1}
    >
      <Typography variant="subtitle1">{companyBasicsQuery.data.company_name}</Typography>
      <Typography variant="body2">
        {companyBasicsQuery.data.local_organization_id.id}
      </Typography>
      <Typography variant="body2">
        {`${companyBasicsQuery.data.address.street} ${companyBasicsQuery.data.address.number}, ${companyBasicsQuery.data.address.city}, ${companyBasicsQuery.data.address.country}`}
      </Typography>
    </Stack>
  )
}

export default CompanyBasicsHeader
