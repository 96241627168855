export const DATA = 'data'
export const MONITOR = 'monitor'
export const INTEL = 'intel'
export const AUTH = 'auth'
export const ACCOUNT = 'account'
export const SUBSCRIPTION = 'subscription'

import { RISIKA_API_VERSION } from './config'

const handleVersion = (isProduction, version) => {
  return isProduction ? `v${version}` : RISIKA_API_VERSION
}

export const getApiVersion = (type) => {
  const isProduction = RISIKA_API_VERSION === 'production'
  const versions = {
    [DATA]: handleVersion(isProduction, '1'),
    [MONITOR]: handleVersion(isProduction, '1'),
    [INTEL]: handleVersion(isProduction, '1'),
    [AUTH]: handleVersion(isProduction, '1'),
    [ACCOUNT]: handleVersion(isProduction, '1'),
    [SUBSCRIPTION]: handleVersion(isProduction, '1'),
    'docks/': '',
  }
  return versions[type]
}
