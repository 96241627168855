import { useState, useEffect } from 'react'
import { MenuItem, Select, Box, Skeleton, Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import { useAppShell } from 'components/AppShell/context'
import { styles } from './FlagSelect.styles'
import { useAccountStatus, useCustomerData } from 'services/queries'
import { AvailableCountries, AvailableCountriesCapital } from 'globalTypes'
import Show from 'components/Show'
import { countries } from './FlagSelect.model'
import { useUpdateUserSettingsField } from 'services/queries/useUpdateUserSettingsField'
import { useUserSettingsField } from 'services/queries/useUserSettingsField'
import { SelectedCountry } from 'services/types'
import { SelectChangeEvent } from '@mui/material/Select'
import { Typography } from '@mui/material/'
import { CountryFlagIcon } from 'components-new'

const { boxContainer, root, open, closed, menuItem, menu } = styles

const inferUserPreferredSearchCountry = (
  customerCountry?: AvailableCountries,
  allowedCountries?: AvailableCountries[]
) => {
  if (customerCountry && allowedCountries?.includes(customerCountry)) {
    return customerCountry?.toUpperCase() as AvailableCountriesCapital
  }
  return allowedCountries?.[0]?.toUpperCase() as AvailableCountriesCapital
}

function FlagSelect() {
  const { formatMessage } = useIntl()
  const appShell = useAppShell()
  const [isOpen, setIsOpen] = useState(false)

  const { data: selectedCountry, isLoading: isUserSettingsLoading } =
    useUserSettingsField<AvailableCountries>('selected_country')
  const { mutate: updateUserSettingsField } = useUpdateUserSettingsField<SelectedCountry>(
    {}
  )

  const { data: accountStatus, isLoading: isAccountStatusLoading } = useAccountStatus()
  const { data: customerData, isLoading: isCustomerDataLoading } = useCustomerData()

  const allowedCountries = countries.filter((x) =>
    accountStatus?.legacy_rights.allow_countries?.includes(
      x.value.toUpperCase() as AvailableCountries
    )
  )

  useEffect(() => {
    if (
      !selectedCountry ||
      !accountStatus?.legacy_rights.allow_countries.includes(
        selectedCountry as AvailableCountries
      )
    ) {
      if (customerData) {
        const inferredCountry = inferUserPreferredSearchCountry(
          customerData?.country,
          accountStatus?.legacy_rights.allow_countries
        )
        updateUserSettingsField({ field: 'selected_country', data: inferredCountry })
      }
    }
  }, [
    isCustomerDataLoading,
    accountStatus?.legacy_rights.allow_countries,
    customerData,
    customerData?.country,
    selectedCountry,
    updateUserSettingsField,
  ])

  if (isUserSettingsLoading || isAccountStatusLoading || isCustomerDataLoading) {
    return <Skeleton sx={{ height: '38px', width: '30px' }} />
  }
  const canUserChangeCountry = allowedCountries.length > 1

  const handleCountryChange = (event: SelectChangeEvent<AvailableCountries>) => {
    const country = event.target.value as AvailableCountries

    appShell.setCountry(country)
    appShell.setResultType('company')

    updateUserSettingsField({
      field: 'selected_country',
      data: country?.toUpperCase() as AvailableCountriesCapital,
    })
  }

  return (
    <Show when={!!selectedCountry}>
      <Box sx={boxContainer}>
        <Select
          data-cy="flag-dropdown-form-control"
          sx={root}
          variant="standard"
          className={!canUserChangeCountry ? 'single-country' : ''}
          disableUnderline
          value={selectedCountry}
          onChange={handleCountryChange}
          disabled={!canUserChangeCountry}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => <CountryFlagIcon country={selected} />}
          MenuProps={{
            sx: { ...menu },
          }}
        >
          {allowedCountries.map(({ label, value }) => (
            <MenuItem sx={menuItem} key={value} value={value} data-cy={`flag-${value}`}>
              <Stack direction="row" spacing={2}>
                <CountryFlagIcon country={value as AvailableCountries} />
                {/* <Box sx={isOpen ? open : closed}>{formatMessage({ id: label })}</Box> */}
                <Typography color={'primary'} sx={isOpen ? open : closed}>
                  {formatMessage({ id: label })}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Show>
  )
}

export default FlagSelect
