import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { addBorderToAllButTheLastItem, iconSize } from './VisitsSummary.style'
import { ArrowOutward } from '@mui/icons-material'

const searches = [
  {
    name: 'view_count',
    count: 1000,
    direction: 'up',
    localized_name: 'Company page viewed',
  },
  {
    name: 'reports_downloaded',
    count: 598823,
    direction: 'up',
    localized_name: 'Reports downloaded',
  },
  {
    name: 'low_risk_companies',
    count: 125,
    direction: 'down',
    localized_name: 'Low Risk Companies',
  },
] as const

function SearchesOverviewLoading() {
  const searchesLength = searches.length
  return (
    <Grid container spacing={2}>
      {searches.map((search) => (
        <Grid
          sx={addBorderToAllButTheLastItem}
          item
          xs={12}
          sm={12}
          lg={12 / searchesLength}
          md={6}
          key={search.name}
        >
          <Stack
            alignItems={{ xs: 'center', sm: 'center', md: 'inherit' }}
            py={4}
            px={5}
            pr={0}
            width={1}
          >
            <Stack py={5} px={6} pr={0}>
              <Box className="summary-item-box">
                <Skeleton>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h2">{search.count}</Typography>
                    <ArrowOutward sx={iconSize} color="error" />
                  </Stack>
                </Skeleton>
                <Skeleton>
                  <Typography variant="body1">{search.localized_name}</Typography>
                </Skeleton>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}

export default SearchesOverviewLoading
