import { columnWidth } from '../../CompanyComparison.model'

const columnContainer = {
  paddingX: 1,
  gap: 2,
  width: columnWidth,
  boxSizing: 'border-box',
}

export default { columnContainer }
