import * as React from 'react'
import { BarChart, Bar, XAxis, Tooltip, Cell, ResponsiveContainer } from 'recharts'
import CustomTooltip from './components/CustomTooltip/CustomTooltip'
import { Show } from 'components'
import {
  removeUnusedData,
  fitDataToRequirements,
  getScore,
  getDarkerScore,
  calculateTiltOptionsAngle,
} from './PerformanceBarChart.controller'
import { useMeasure } from 'react-use'
import { dataPropType, PerformanceBarChartPropTypes } from './PerformanceBarChart.types'
import { Box } from '@mui/material'
import { classes } from './PerformanceBarChart.styles'
import { theme } from 'styles-new/mui5-transition/theme'
import { ImplicitLabelType } from 'recharts/types/component/Label'

const { lineHeight, color, ...subtitle2Styles } = theme.typography.subtitle2
const textColor = theme.palette.grey[800]
const body2Styles = (() => {
  const { fontFamily, fontWeight, fontSize, letterSpacing } = theme.typography.body2
  return { fontFamily, fontWeight, fontSize, letterSpacing }
})()
const PerformanceBarChart = ({ historicalScores }: PerformanceBarChartPropTypes) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const [data, setData] = React.useState([])
  // set the focus bar using onMouseMove in the BarChart if you want to highlight a bar
  const [focusBar] = React.useState<number | undefined | null>(null)

  React.useEffect(() => {
    const usefulScores = removeUnusedData(historicalScores)

    // If data is less than 2 length the component will not be rendered
    setData(fitDataToRequirements(usefulScores))

    // Removes the chart from the dom, so it can reset the animation
    return () => {
      setData([])
    }
  }, [historicalScores])

  return (
    <Box ref={ref} sx={classes.PerformanceBarChartContainer}>
      <Show when={Boolean(data.length)}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 25,
              bottom: 10,
              left: 10,
              right: 10,
            }}
          >
            <XAxis
              interval={data.length - 2} // get the first and last
              tick={{ ...body2Styles, fill: textColor, color: textColor }}
              tickFormatter={(tick: string) => tick}
              dataKey="name"
              angle={calculateTiltOptionsAngle(width, data)}
              dx={0}
              dy={10}
              minTickGap={-10000}
              axisLine={false}
            />
            <Tooltip
              offset={30}
              cursor={{ fill: 'white' }}
              content={<CustomTooltip payload={data} />}
            />
            <Bar
              animationBegin={600}
              animationDuration={1000}
              maxBarSize={60}
              dataKey="score"
              fill="#8884d8"
              label={{ position: 'top', ...subtitle2Styles } as ImplicitLabelType}
            >
              {data?.map((entry: dataPropType, index: number) => {
                return (
                  <Cell
                    key={index}
                    fill={getScore(entry.score)}
                    stroke={focusBar === index ? getDarkerScore(entry.score) : undefined}
                  />
                )
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Show>
    </Box>
  )
}

export default PerformanceBarChart
