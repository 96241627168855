import { SAVE_ACCOUNT_STATUS_SUCCESS } from './constants'

/*********
 * Types *
 *********/

// type CompanyTypes = {
//   plan: string,
//   max_users: number,
//   rights: Object,
//   counters: Object,
//   caps: Object,
// }

// type PermissionsReducerState = {
//   company: CompanyTypes,
// }

/*****************
 * Initial state *
 *****************/

export const initialStatePermissions = {
  company: {
    plan: '',
    max_users: 0,
    rights: {},
    counters: {},
    caps: {},
  },
}

/************
 * Reducers *
 ************/

const handleSaveAccountStatus = (state, action) => {
  const { permissions } = action.payload
  const { data } = permissions
  return {
    ...state,
    company: {
      plan: data.subscription_plan,
      max_users: data.max_users,
      rights: data.rights,
      counters: data.counters,
      caps: data.caps,
    },
  }
}

/****************
 * Auth Reducer *
 ****************/

export const permissions = (state = initialStatePermissions, action = { type: '' }) => {
  switch (action.type) {
    case SAVE_ACCOUNT_STATUS_SUCCESS:
      return handleSaveAccountStatus(state, action)
    default:
      return state
  }
}

export default permissions
