import * as React from 'react'
import { CommunicationModal } from 'components-new'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

type IncompletePolicyDialogProps = {
  action: () => void
  open: boolean
  setOpen: (open: boolean) => void
}
function IncompletePolicyDialog({ action, open, setOpen }: IncompletePolicyDialogProps) {
  const { formatMessage } = useIntl()
  const mainButtonClick = () => {
    action()
    setOpen(false)
  }

  return (
    <CommunicationModal
      title={formatMessage({ id: intl.creditPolicyNew('save-incomplete-policy-title') })}
      body={<FormattedMessage id={intl.creditPolicyNew('save-incomplete-policy-body')} />}
      open={open}
      setOpen={setOpen}
      mainButton={{
        content: formatMessage({ id: intl.creditPolicyNew('save-as-draft') }),
        click: mainButtonClick,
      }}
      secondaryButton={{
        content: formatMessage({ id: intl.creditPolicyNew('cancel') }),
        click: () => setOpen(false),
      }}
    />
  )
}

export default IncompletePolicyDialog
