export const styles = {
  root: {
    width: '2.4rem',
  },
  flagActive: {
    border: '1.873px solid ',
    borderColor: 'primary.500',
    boxShadow: '0 0 2px 2px rgba(81, 203, 238, 1)',
    borderRadius: '1.536px',
  },
}
