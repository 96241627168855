import { getFilterOptionType } from '../controller/general'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Stack, FormControlLabel, Checkbox } from '@mui/material'
import { theme } from 'styles-new/mui5-transition/theme'
import { useStyles } from '../styles/filter-options'

const FilterOptions = ({ options, onChange }) => {
  const classes = useStyles()

  const handleOnChange = (label) => () => onChange(label)

  if (!options) return null

  return (
    <Stack direction="row" gap={4} flexWrap="wrap">
      {options.map((option) => {
        const formattedOption = getFilterOptionType(option.filter)
        return (
          <FormControlLabel
            style={{ color: theme.palette.grey[800] }}
            className={classes.option}
            label={<FormattedMessage id={intl.advancedSearch(formattedOption.label)} />}
            key={formattedOption.label}
            size="medium"
            control={
              <Checkbox
                sx={{ padding: '12px !important' }}
                checked={option.value}
                onChange={handleOnChange(formattedOption.label)}
                value={option.value}
              />
            }
          />
        )
      })}
    </Stack>
  )
}

export default FilterOptions
