import {
  useMemo,
  useReducer,
  useState,
  useEffect,
  useCallback,
  FormEvent,
  FocusEvent,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { motion } from 'framer-motion'
import { validate, pipeValidators, validationFormats } from 'utils/validate'

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Button, Stack } from 'components'
import { activateAccount } from 'services/api'
import { activateUserSuccess } from 'store_deprecated/auth/actions'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from './ChangePasswordFlow.styles'
import { useQueryClient } from 'react-query'
import { ReduxRootState } from 'store_deprecated/types'

type ErrorState = {
  password: string
  confirmPassword: string
}

type SetErrorAction = {
  type?: 'setError' | 'clearError'
  field: keyof ErrorState
  error?: string
  value?: boolean
}

type FormState = {
  password: string
  confirmPassword: string
}

type UpdateFieldAction = {
  type?: 'update'
  field: keyof FormState
  value: string
}

const reducerFunction = (state: FormState, action: UpdateFieldAction) => {
  switch (action?.type) {
    case 'update':
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

const errorReducerFunction = (state: ErrorState, action: SetErrorAction) => {
  switch (action.type) {
    case 'setError':
      return { ...state, [action.field]: action.error }
    case 'clearError':
      return { ...state, [action.field]: '' }
    default:
      return state
  }
}

const PASSWORD = 'password'
const CONFIRM_PASSWORD = 'confirmPassword'
const initialErrorState = {
  password: '',
  confirmPassword: '',
}

const initialState = {
  password: '',
  confirmPassword: '',
}

const passwordForm: [typeof reducerFunction, typeof initialState] = [
  reducerFunction,
  initialState,
]

const passwordErrorForm: [typeof errorReducerFunction, typeof initialErrorState] = [
  errorReducerFunction,
  initialErrorState,
]

export default function ChangePassword(props: { isOpen: boolean }) {
  const { isOpen } = props

  const classes = useStyles()
  const reactIntl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { verifyToken } = useSelector((state: ReduxRootState) => state?.auth?.login)

  const [formState, formDispatch] = useReducer(...passwordForm)
  const [formErrorState, formErrorDispatch] = useReducer(...passwordErrorForm)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  /** Validators */
  const passwordValidation = useMemo(() => ({ length: { min: 8 } }), [])
  const confirmPasswordValidation = useCallback(
    (passwordToMatch: string) =>
      pipeValidators(passwordValidation, validationFormats.matchValue(passwordToMatch)),
    [passwordValidation]
  )

  useEffect(() => {
    const allFieldsValid = [
      validate(formState.password, passwordValidation),
      validate(formState.confirmPassword, confirmPasswordValidation(formState.password)),
    ]

    const isValid = allFieldsValid.filter((item) => !item).length === 0

    setIsFormValid(isValid)
  }, [formState, confirmPasswordValidation, passwordValidation])

  const handleOnChange =
    (field: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      if (field === 'password') {
        const isValid = validate(value, passwordValidation)

        if (isValid) {
          formErrorDispatch({ field, value: !isValid })
        }
      } else if (field === 'confirmPassword') {
        const isValid = validate(value, confirmPasswordValidation(formState.password))

        if (isValid) {
          formErrorDispatch({ field, value: !isValid })
        }
      }
      formDispatch({ type: 'update', field, value })
    }

  const handleOnLeave =
    (field: string) =>
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      const { value } = event.target

      if (field === 'password') {
        const isValid = validate(value, passwordValidation)

        formErrorDispatch({ field, value: !isValid })
      } else if (field === 'confirmPassword') {
        const isValid = validate(value, confirmPasswordValidation(formState.password))

        formErrorDispatch({ field, value: !isValid })
      }
    }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { password, confirmPassword } = formState

    const ARTIFICIAL_DELAY = 2000

    setIsLoading(true)

    setTimeout(() => {
      activateAccount({ password, confirmPassword, verifyToken: verifyToken || null })
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage id={intl.snackbar('account-activated-success')} />,
            { variant: 'success' }
          )
          queryClient.refetchQueries('userSelf')
          dispatch(activateUserSuccess())
        })
        .catch((e) => {
          switch (e?.response?.data?.code) {
            case 'password_not_allowed':
              enqueueSnackbar(
                <FormattedMessage id={intl.snackbar('password-not-allowed')} />,
                { variant: 'error' }
              )
              break
            default:
              enqueueSnackbar(
                <FormattedMessage id={intl.snackbar('account-activated-failed')} />,
                { variant: 'error' }
              )
              break
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, ARTIFICIAL_DELAY)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <motion.div
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        initial="visible"
        animate={isOpen ? 'visible' : 'hidden'}
        className="here"
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <FormattedMessage id={intl.firstTimeVisit('title')} />
        </DialogTitle>
        <DialogTitle style={{ paddingTop: 0, paddingBottom: 0 }}>
          <>
            <DialogContentText>
              <FormattedMessage id={intl.firstTimeVisit('description')} />
            </DialogContentText>
          </>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2} direction="column">
              <TextField
                autoFocus
                value={formState.password}
                onChange={handleOnChange(PASSWORD)}
                onBlur={handleOnLeave(PASSWORD)}
                type={PASSWORD}
                variant="outlined"
                fullWidth
                label={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('password-label'),
                })}
                helperText={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('min-characters'),
                })}
                error={!!formErrorState.password}
              />
              <TextField
                value={formState.confirmPassword}
                onChange={handleOnChange(CONFIRM_PASSWORD)}
                onBlur={handleOnLeave(CONFIRM_PASSWORD)}
                type={PASSWORD}
                variant="outlined"
                fullWidth
                label={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('confirm-password-label'),
                })}
                helperText={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('must-match-password'),
                })}
                error={!!formErrorState.confirmPassword}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!isFormValid}
                type="submit"
                loading={isLoading}
              >
                <FormattedMessage id={intl.firstTimeVisit('submit')} />
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </motion.div>
    </Dialog>
  )
}
