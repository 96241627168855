import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

type Props = {
  labelKey: string
  isActive?: boolean
}

function TitleMainRecommendation({ labelKey, isActive }: Props) {
  return (
    <Typography variant="h4" color={isActive ? 'inherit' : 'grey'}>
      <FormattedMessage id={intl.mainRecommendation(labelKey)} />
    </Typography>
  )
}

export default TitleMainRecommendation
