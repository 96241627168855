import callBackendAPI from 'services/api/helpers/backendAPI'
import { UpdateUserSettingsRequestProps } from 'services/types'

// To call this function you need to use one of the allowed types.
// Import the one you need from this file and pass it as a generic type
/**
 * Language
 * CreditCheck
 * CompanyInfo
 * MonitorTable
 * CreditCheckSidebar
 * SelectedCountry
 * CurrencyObj
 * Filters
 * ExportOptionsFiltering
 * ExportOptionsMonitoring
 */
function updateUserSettingsField<T>({ field, data }: UpdateUserSettingsRequestProps<T>) {
  return callBackendAPI(`/user/settings/${field}`, {
    method: 'put',
    data: {
      data,
    },
  }).then(({ data }) => data)
}

export default updateUserSettingsField
