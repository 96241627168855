import * as React from 'react'
import { Stack, Skeleton, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import ArticleIcon from '@mui/icons-material/Article'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { TableRowTitle } from './TableRowTitle'
import { default as cx } from 'classnames'
import { ButtonSecondary } from 'components-new'

// Mocked data
const fourItemArray = Array.from(new Array(4))
const periods = fourItemArray
const annualReportLinks = fourItemArray
const keyFinancialFigures = [
  {
    label: 'revenue',
    data: fourItemArray,
  },
  {
    label: 'grossResult',
    data: fourItemArray,
  },
  {
    label: 'profitLoss',
    data: fourItemArray,
  },
  {
    label: 'noncurrentAssets',
    data: fourItemArray,
  },
  {
    label: 'currentAssets',
    data: fourItemArray,
  },
  {
    label: 'equity',
    data: fourItemArray,
  },
  {
    label: 'debt',
    data: fourItemArray,
  },
  {
    label: 'liabilitiesAndEquity',
    data: fourItemArray,
  },
]

const useStyles = makeStyles((theme: Theme) => ({
  th: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '4rem',
    },
  },
  textRight: {
    textAlign: 'right',
  },
}))

const KeyFinancialFiguresSkeleton = () => {
  const isSmallDisplay = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()
  return (
    <Stack padding="20px 20px 0" mb={4}>
      <table
        style={{ width: isSmallDisplay ? 'initial' : '100%' }}
        className="table table-alternate"
      >
        <thead className={cx(classes.textRight)}>
          <tr>
            <th></th>
            {periods.map((_, i) => (
              <th key={i}>
                <Stack alignItems="flex-end">
                  <Skeleton variant="text" width={100} height={24} />
                </Stack>
              </th>
            ))}
          </tr>
          <tr>
            <th></th>
            {periods.map((_, i) => (
              <th key={i} className={classes.textRight}>
                <Stack alignItems="flex-end">
                  <Skeleton variant="text" width={20} />
                </Stack>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {keyFinancialFigures?.map((figure, i) => (
            <tr key={i}>
              <FormattedMessage id={intl.keyFinancialFigures(figure.label)}>
                {(text) => (
                  <TableRowTitle variant={'subtitle2'} isDash={false} isSpace={false}>
                    {text}
                  </TableRowTitle>
                )}
              </FormattedMessage>
              {figure.data?.map((_, i) => (
                <td key={i}>
                  <Stack alignItems="flex-end">
                    <Skeleton key={i} variant="text" height={16} width={100} />
                  </Stack>
                </td>
              ))}
            </tr>
          ))}
          <tr className="table-nobg">
            <td></td>
            {annualReportLinks?.map((_, i) => (
              <td key={i}>
                <Stack alignItems="flex-end" mt={4}>
                  <ButtonSecondary
                    startIcon={<ArticleIcon sx={{ fontSize: '16px !important' }} />}
                    disabled={true}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <FormattedMessage
                      id={intl.keyFinancialFigures('annual-report-button')}
                    />
                  </ButtonSecondary>
                </Stack>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </Stack>
  )
}

export default KeyFinancialFiguresSkeleton
