import { Typography } from '@mui/material'
import { isNil } from 'ramda'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const CompanyNameCell = ({ cell }) => {
  return (
    <Typography variant="body2">
      {isNil(cell.value) ? (
        <FormattedMessage id={intl.mainRecommendation('recommendation-credit-unknown')} />
      ) : (
        cell.value
      )}
    </Typography>
  )
}

export default CompanyNameCell
