import { Stack, Typography } from '@mui/material'
import { Show } from 'components'
import { TableCellProps } from '../../HierarchyListView.types'
import BlankDashField from '../BlankDashField'
import colors from 'configs/colors'

function RisikaScore({ row, align }: TableCellProps) {
  if (row?.type !== 'COMPANY') {
    return <BlankDashField align={align} />
  }

  const textColor = colors[`score_rating_${row?.score}`]

  return (
    <Show when={!!row?.score}>
      <Stack alignItems={align}>
        <Typography variant="subtitle1" sx={{ color: textColor }}>
          {row?.score}
        </Typography>
      </Stack>
    </Show>
  )
}
export default RisikaScore
