import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  parentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
  },
  titleCluster: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  dots: { marginRight: 10 },
}))
