/**
 * Perform the action safely.
 *
 * This is useful when you want to perform an action, but you don't
 * have control on what goes in: e.g. you could get `null` sometimes,
 * which would break deep trees or member functions.
 *
 * @param {callback} action The action to perform safely.
 * @param {any} data The data the action should be performed on.
 * @returns {any} The new data or `null`.
 */
export const safely =
  (action) =>
  (...args) => {
    let result = null
    try {
      result = action(...args)
    } catch (error) {
      /** no-op */
    }
    return result
  }
