const paper = {
  margin: '2rem',
  overflow: 'scroll',
  overflowY: 'hidden',
}

const changesButton = {
  margin: 0,
  padding: 0,
}
const rowActions = {
  position: 'absolute',
  right: 0,
  marginRight: '2rem',
  padding: 0,
  paddingLeft: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
}
const centerCell = {
  textAlign: 'center',
}

const tableTopMenu = {
  width: '100%',
}
const listName = { width: '100%', px: 5 }

const monitoringTable = { maxWidth: '2810px' }

export const classes = {
  monitoringTable,
  paper,
  changesButton,
  rowActions,
  centerCell,
  tableTopMenu,
  listName,
}
