import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import VerticalBarChart from 'components-new/charts/VerticalBarChart'
import { convertToNameAndValue } from 'utils-new/api-to-chart-data-converters'
import { adjustChartColors } from './ListCreditScoreDistributionBox.controller'
import { distributionData } from './ListCreditScoreDistributionBox.model'
import { useMonitoringStatistic } from 'services/queries'
import { MonitoringValueQueryDataType } from 'types/queries'
import { Monitor } from 'risika-api-response-types'

const ListCreditScoreDistributionBox = ({ listId }: { listId: string }) => {
  distributionData.parameters.list_id = Number(listId)

  const distributionQuery = useMonitoringStatistic<Monitor.ListStatisticsCategory>({
    parameters: distributionData.parameters,
    endpoint: distributionData.endPoint,
  })

  if (!distributionQuery.isSuccess || !distributionQuery.data) {
    return null
  }

  const boxData = {
    title: distributionData.chartProperties.title,
    status: {
      isLoading: distributionQuery.isLoading,
      isError:
        distributionQuery.isError ||
        (distributionQuery.data &&
          !distributionQuery.data?.length &&
          !distributionQuery.isLoading),
      isIdle: distributionQuery.isIdle,
      isNoData: !distributionQuery.data?.length,
    },
    height: distributionData.chartProperties.height,
    skeletonType: 'VERTICAL_BAR',
  }

  const distributionQueryData = distributionQuery.data as MonitoringValueQueryDataType[]
  const componentIsReady =
    !boxData.status.isLoading && !boxData.status.isIdle && !boxData.status.isError

  if (componentIsReady && convertToNameAndValue(distributionQueryData).length) {
    distributionData.chartProperties.colors = adjustChartColors(
      convertToNameAndValue(distributionQueryData)
    )
  }

  if (!convertToNameAndValue(distributionQueryData).length) {
    return null
  }
  return (
    <BoxContainer boxData={boxData}>
      <Box data-cy="list-credit-score-distribution-chart" m={2} p={1}>
        {componentIsReady ? (
          <VerticalBarChart
            data={convertToNameAndValue(distributionQueryData)}
            chartProperties={distributionData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListCreditScoreDistributionBox
