import { createContext, useContext, useEffect, useState } from 'react'
import {
  AdvancedPolicySetT,
  SetSinglePolicyPayloadT,
} from 'services/api/endpoints/risikaAPI/setSingleCreditPolicy'
import { useSetSinglePolicy, useSinglePolicy } from 'services/queries'
import { SupportedCountry } from 'types/general'
import { DefPolicyModel } from './models/DefPolicyModel'
import { complyToSetFormat } from './widgets/controller'
import { useQueryClient } from 'react-query'
import { AccountStatusResponse } from 'services/api/endpoints/risikaAPI/accountStatus'
import { CreditPolicyT } from 'services/api/endpoints/risikaAPI/creditPolicyGet'
import { CountryPolicyContextType } from './types'
import lodash from 'lodash'

const BOHR = 'BOHR'

const CountryPolicyContext = createContext<CountryPolicyContextType>({
  policy: DefPolicyModel,
  setPolicy: () => {},
  selectedCountry: 'dk',
  setSelectedCountry: () => {},
  newCountry: 'dk',
  setNewCountry: () => {},
  isEnabled: false,
  setIsEnabled: () => {},
  updatePolicy: () => {},
  isInferiorPlan: false,
  isRestrictionModalOpen: false,
  setIsRestrictionModalOpen: () => {},
  isPolicyLoading: false,
  isPolicyFetching: true,
  isPolicyError: false,
  isSaveModalOpen: false,
  setIsSaveModalOpen: () => {},
  hasPolicyChanged: false,
})

export const useCountryPolicy = () => useContext(CountryPolicyContext)

type Props = {
  children: React.ReactNode
}

const CountryPolicyProvider = ({ children }: Props) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<SupportedCountry>('dk')
  const [newCountry, setNewCountry] = useState<SupportedCountry>('dk')
  const { data, isFetching, isLoading, isError, error } = useSinglePolicy(selectedCountry)
  const { mutate: setSinglePolicy } = useSetSinglePolicy(selectedCountry)
  const [isRestrictionModalOpen, setIsRestrictionModalOpen] = useState(false)
  const [isEnabled, setIsEnabled] = useState(data?.enabled || false)
  const [policy, setPolicy] = useState<AdvancedPolicySetT>(
    !isError ? complyToSetFormat(data?.policy) ?? DefPolicyModel : DefPolicyModel
  )

  const queryClient = useQueryClient()
  const accountStatus: AccountStatusResponse = queryClient.getQueryData('accountStatus')!

  const isInferiorPlan = accountStatus?.rights.allow_credit_policy.length < 10

  const [isSinglePolicyError, setIsSinglePolicyError] = useState(isError)

  useEffect(() => {
    if (
      error?.response?.status === 400 &&
      error?.response.data.error === 'No credit policy is set'
    ) {
      setIsSinglePolicyError(false)
    }
  }, [error?.response.data.error, error?.response?.status])

  useEffect(() => {
    const newPolicy = !isSinglePolicyError
      ? complyToSetFormat(data?.policy) ?? DefPolicyModel
      : DefPolicyModel
    setPolicy(newPolicy)
    setIsEnabled(data?.enabled || false)
  }, [data, isSinglePolicyError])

  const updatePolicy = () => {
    const updatePayload = {
      country: selectedCountry.toUpperCase(),
      policy,
      enabled: isEnabled,
      product: BOHR,
    } as SetSinglePolicyPayloadT
    setSinglePolicy(updatePayload)
  }

  const checkHasPolicyChanged = (): boolean => {
    const initialPolicy: CreditPolicyT | undefined = queryClient.getQueryData([
      'singlePolicy',
      selectedCountry,
    ])
    if (!initialPolicy) return false

    const initialPayload = {
      enabled: initialPolicy.enabled,
      product: initialPolicy.product,
      policy: complyToSetFormat(initialPolicy?.policy),
      country: selectedCountry.toUpperCase(),
    } as SetSinglePolicyPayloadT

    const currentPayload = {
      enabled: isEnabled,
      product: BOHR,
      policy,
      country: selectedCountry.toUpperCase(),
    } as SetSinglePolicyPayloadT

    const res = !lodash.isEqual(initialPayload, currentPayload)
    return res
  }

  return (
    <CountryPolicyContext.Provider
      value={{
        policy,
        setPolicy,
        selectedCountry,
        setSelectedCountry,
        newCountry,
        setNewCountry,
        isEnabled,
        setIsEnabled,
        updatePolicy,
        isInferiorPlan,
        isRestrictionModalOpen,
        setIsRestrictionModalOpen,
        isPolicyFetching: isFetching,
        isPolicyLoading: isLoading,
        isPolicyError: isSinglePolicyError,
        isSaveModalOpen,
        setIsSaveModalOpen,
        hasPolicyChanged: checkHasPolicyChanged(),
      }}
    >
      {children}
    </CountryPolicyContext.Provider>
  )
}

export default CountryPolicyProvider
