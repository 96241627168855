import { useQuery } from 'react-query'
import { monitorShowList } from 'services/api'
import { ShowListsType } from 'types/queries'
import { MONITOR_LIST } from './lists/constants'

export function useMonitorShowList() {
  return useQuery<ShowListsType, Error>(MONITOR_LIST, monitorShowList, {
    onError: (err) => {
      console.error(err)
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}
