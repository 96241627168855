import Change from './Change'
import { isNil } from 'ramda'
import { Stack } from '@mui/system'

const extractChange = (change) => {
  if (isNil(change)) {
    return null
  }

  const { value } = change

  if (value?.name) {
    const { name, function: role } = value
    return { ...value, name: `${name} (${role})` }
  }

  return value
}

const restructureChanges = (changes) => {
  return changes.map((change) => {
    const { new: _new, old } = change

    const from = extractChange(old)
    const to = extractChange(_new)
    const date = change.registration_date

    return {
      ...change,
      from,
      to,
      date,
    }
  })
}

const Changes = ({ row }) => {
  const changes = restructureChanges(row.original.changes)
  return (
    <Stack direction="row" spacing={6}>
      {changes.map((change, i) => (
        <Change key={`${row.original.localId.id}-${i}`} change={change} />
      ))}
    </Stack>
  )
}

export default Changes
