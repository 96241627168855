import mergeNotes from './mergeNotes'

function createLocalIdKey({ id, country }) {
  return `${country.toUpperCase()}${id}`
}

function mergeNotesRight(existingNotes, newNotes) {
  const newNotesSet = new Set([
    ...newNotes
      .filter(({ localId }) => localId != null)
      .map(({ localId }) => createLocalIdKey(localId)),
  ])

  const existingNotesAlsoInNewNotes = existingNotes.filter(
    ({ localId }) => localId != null && newNotesSet.has(createLocalIdKey(localId))
  )

  return mergeNotes(existingNotesAlsoInNewNotes, newNotes)
}

export default mergeNotesRight
