import { useMutation, useQueryClient } from 'react-query'
import { CreateNewUserData } from './../api/endpoints/backendAPI/createNewUser'
import { createNewUser } from 'services/api'
import { WebApiError } from 'types/general'
import { useSnackbar } from 'notistack'

export function useCreateNewUser() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation<Response, WebApiError, CreateNewUserData>(createNewUser, {
    onSuccess: () => {
      queryClient.refetchQueries('allUsers')
    },
    onError: (err) => {
      enqueueSnackbar(`${err?.response?.data?.error?.message}`, {
        variant: 'error',
      })
    },
  })
}
