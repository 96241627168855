import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { classDropdown } from 'styles-new/global/components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const SelectIndustry = ({
  industries,
  handleIndustryChange,
  level,
  levelLimit,
}: {
  industries: { group: string }[]
  handleIndustryChange: (groupName: string) => void
  level: number
  levelLimit: number
}) => {
  if (level >= levelLimit) return <></>
  if (!industries || !industries.length) return null

  return (
    <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 300 }} size="small">
      <Select
        sx={classDropdown}
        value={industries[0].group}
        onChange={(event: { target: { value: string } }) =>
          handleIndustryChange(event.target.value)
        }
        renderValue={() => (
          <InputLabel>
            <FormattedMessage id={intl.observationalLists('select-industry')} />
          </InputLabel>
        )}
      >
        {industries.map((industry: { group: string }) => {
          return (
            <MenuItem key={industry.group} value={industry.group}>
              {industry.group}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SelectIndustry
