import { Stack, Typography, Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import Skeleton from '@mui/material/Skeleton'
import styles from './scenes/FinancialRatioChart/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const fourItemArray = Array.from(new Array(4))
const years = fourItemArray
const charts = [
  'solidity_ratio',
  'current_ratio',
  'debt_to_equity_ratio',
  'return_on_assets',
]

const RelevantFinancialRatiosSkeleton = () => {
  return (
    <Grid
      container
      gap="36px"
      justifyContent="space-between"
      padding="0 43.5px 0 43.5px"
      height={380}
      overflow="hidden"
    >
      {charts.map((chart, i) => (
        <Grid item xs={2} minWidth={240} padding={'24px 0 12px 0'} key={i}>
          <Stack gap={4} alignItems="center">
            <Typography variant="subtitle1">
              <FormattedMessage id={intl.relevantFinancialRatios(chart)} />
            </Typography>
            <Skeleton variant="circular" height={150} width={150} />
            <Stack width={150} justifyContent="end">
              <InfoOutlinedIcon sx={{ ...styles.iconButton, color: 'grey.600' }} />
            </Stack>
            <Stack width={150}>
              {years.map((_, i) => (
                <Stack key={i} direction="row" width={1} justifyContent="space-between">
                  <Skeleton variant="text" width="30%" height={22} />
                  <Skeleton variant="text" width="30%" height={22} />
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}

export default RelevantFinancialRatiosSkeleton
