import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const label: SxProps = {
  position: 'absolute',
  textAlign: 'center',
}

const labelTop: SxProps = {
  ...label,
  top: '10px',
  width: '430px',
}
const labelBottom: SxProps = {
  ...label,
  bottom: '10px',
  width: '430px',
}
const labelLeft: SxProps = {
  ...label,
  marginLeft: '20px',
  top: '10px',
  width: '20px',
  height: '380px',
  writingMode: 'vertical-lr',
  textOrientation: 'mixed',
  transform: 'rotate(180deg)',
}
const labelRight: SxProps = {
  ...label,
  marginLeft: '390px',
  top: '10px',
  width: '20px',
  height: '380px',
  writingMode: 'vertical-lr',
  textOrientation: 'mixed',
}
const companyLogo: SxProps = {
  width: theme.spacing(8),
  height: theme.spacing(8),
}
const infoTooltip: SxProps = {
  cursor: 'default',
  fontSize: '20px',
  color: 'primary.main',
  padding: 0,
}

export const classes = {
  labelTop,
  labelBottom,
  labelLeft,
  labelRight,
  companyLogo,
  infoTooltip,
}
