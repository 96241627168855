import { List, ListItem, ListItemText } from '@mui/material'
import { ActionDialog } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const ListActionDialog = ({ handleAction, selectedCompanies, render, lists, title }) => {
  const handleClick = (listId, close) => {
    handleAction(listId, selectedCompanies)
    close()
  }

  return (
    <ActionDialog
      title={<FormattedMessage id={intl.riskMonitoringNew(title)} />}
      removeActions
      content={(close) => (
        <List>
          {Object.keys(lists).map((listId) => (
            <ListItem button key={listId} onClick={() => handleClick(listId, close)}>
              <ListItemText>{lists[listId].name}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}
      maxWidth="sm"
      fullWidth={true}
      render={render}
    />
  )
}

export default ListActionDialog
