import {
  HANDLE_ADD_HISTORY_ENTRY,
  HANDLE_REMOVE_LAST_HISTORY_ENTRY,
  HANDLE_CLEAR_HISTORY,
} from './constants'

// type AddHistoryData = {
//   historyKey: string | number,
//   location: string,
//   country: string,
//   title: string | number,
// }

// /**************
// * Saga calls *
// **************/
// export const submitFetchSomeData = ({
// prop1,
// }: dataTypeProp1): AsyncAction<dataTypeProp1> => {
// return {
// type: CONSTANT_NAME_1,
// payload: { prop1 },
// status: 'LOADING',
// }
// }

/*****************
 * Reducer calls *
 *****************/

export const addHistoryEntry = ({ historyKey, location, country, title }) => {
  return {
    type: HANDLE_ADD_HISTORY_ENTRY,
    payload: { historyKey, location, country, title },
    status: 'SUCCESS',
  }
}
export const goBackInHistory = (history) => {
  return {
    type: HANDLE_REMOVE_LAST_HISTORY_ENTRY,
    status: 'SUCCESS',
    history,
  }
}
export const clearHistory = (location) => {
  return {
    type: HANDLE_CLEAR_HISTORY,
    status: 'SUCCESS',
    location,
  }
}
