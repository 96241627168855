import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

const employeeList = (country) => {
  return risikaAPI
    .get(`/list/employee_interval`, {
      microservice: 'data',
      country,
    })
    .then(({ data }) => {
      return data.employee_interval
    })
}

export default employeeList
