import { getFormattedDateForScheduler } from 'localization/utils'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { SubscriptionProps } from 'types/queries'

async function subscriptionUpsert({
  listId,
  emails,
  mode,
  weekDays,
  dayOfMonth,
  starting_date,
  frequency,
  disabled,
}: SubscriptionProps) {
  const list_id = parseInt(listId)

  const dataToSend = {
    list_id,
    type: 'CREDIT_COMPLIANCE',
    emails,
    disabled,
    scheduler: {
      mode,
      days: mode === 'weekly' ? weekDays : dayOfMonth,
      frequency,
      starting_date: getFormattedDateForScheduler(starting_date),
    },
  }
  const data = await risikaAPI.post(`/upsert/${listId}`, dataToSend, {
    microservice: 'subscription',
  })

  return data
}

export default subscriptionUpsert
