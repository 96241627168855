import {
  equityDefaultState,
  numberOfEmployeesInitialState,
  RISIKA_SCORE_MENU,
  RISK_LEVEL_MENU,
  ToleranceDefaultState,
} from './Actions/Actions.model'
import {
  CreditRatingStateValidatorArguments,
  DistanceToCeoState,
} from './Actions/Actions.types'
import {
  ApiData,
  BuilderState,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'
import {
  APPROVE_CREDIT_MAX,
  ADJUST_CREDIT_MAX,
  APPROVE_CREDIT,
  APPROVE_WITH_VALUE,
  CREDIT_RATING,
  EQUITY,
  EQUITY_WITHOUT_DIVIDEND,
  RISIKA_SCORE,
  RISK_ASSESSMENT,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import { Option } from 'types/general'
import { ApproveCreditMaxOutcome, ApproveCreditType } from 'types/queries'
import {
  approveValuesDefaultState,
  NOT_HIGHER_THAN,
  NOT_LOWER_THAN,
} from './DetailedComponentView.model'

export const stateValidators = {
  numberOfEmployeesStateValidator: ({
    tolerance,
    valueMin,
    valueMax,
  }: typeof numberOfEmployeesInitialState) => {
    return tolerance && tolerance !== 'between'
      ? Boolean(tolerance && valueMin)
      : Boolean(tolerance && valueMin && valueMax && +valueMin < +valueMax)
  },

  companyTypeValidator: (state: Option<string | number>[]) => {
    return Boolean(state.length)
  },
  dropdownMultiSelectValidator: (state: any) => {
    const { companyTypeList } = state
    return Boolean(companyTypeList.length)
  },

  distanceToCeoValidator: ({ tolerance, valueMin, valueMax }: DistanceToCeoState) => {
    return tolerance !== 'between'
      ? Boolean(tolerance && valueMin)
      : Boolean(tolerance && valueMin && valueMax && +valueMin < +valueMax)
  },

  equityValidator: ({
    type,
    tolerance,
    valueMin,
    valueMax,
  }: typeof equityDefaultState) => {
    tolerance !== 'between'
      ? Boolean(type && tolerance && valueMin)
      : Boolean(type && tolerance && valueMin && valueMax && +valueMin < +valueMax)
  },
  toleranceInputValidator: ({ tolerance, value_a, value_b }: ToleranceDefaultState) => {
    // TODO: Take into account the  that is passed by the api from the data structure
    return tolerance !== 'between'
      ? Boolean(tolerance && value_a)
      : Boolean(tolerance && value_a && value_b && +value_a < +value_b)
  },

  creditRating: ({
    risikaScoreState,
    riskLevelState,
    selectedMenu,
  }: CreditRatingStateValidatorArguments) => {
    if (selectedMenu === RISIKA_SCORE_MENU) {
      const { tolerance, valueMin, valueMax } = risikaScoreState
      return tolerance && tolerance !== 'between'
        ? Boolean(tolerance && valueMin)
        : Boolean(tolerance && valueMin && valueMax && +valueMin < +valueMax)
    } else if (selectedMenu === RISK_LEVEL_MENU) {
      return riskLevelState.length > 0
    }
  },
  multiSelect: (data: (string | number)[]) => {
    return data.length > 0
  },
}
export const getDataFromSelectedNode = (state: BuilderState): ApiData | null => {
  const selectedNode = state.nodes.find(
    (node) => node.id === state.actionPanelData.nodeId
  )
  if (!selectedNode) return null
  return selectedNode.data.apiData
}

export const getDefaultMethod = (apiData: ApproveCreditMaxOutcome) => {
  if (apiData.outcome === APPROVE_CREDIT) {
    return APPROVE_CREDIT_MAX
  }
  if (apiData.outcome) {
    return apiData.outcome
  }
  return APPROVE_CREDIT_MAX
}

export const getDefaultValues = (apiData: ApproveCreditType) => {
  if (apiData.outcome === APPROVE_CREDIT_MAX) {
    return approveValuesDefaultState
  }
  if (apiData.outcome === APPROVE_WITH_VALUE) {
    return {
      ...approveValuesDefaultState,
      value: apiData.value,
    }
  }
  if (apiData.outcome === ADJUST_CREDIT_MAX) {
    return {
      ...approveValuesDefaultState,
      percentage: apiData.percentage,
      lower_limit: apiData.lower_limit ?? null,
      upper_limit: apiData.upper_limit ?? null,
    }
  }
  return approveValuesDefaultState
}

export const getDefaultLimit = (apiData: ApproveCreditType) => {
  if (apiData.outcome === ADJUST_CREDIT_MAX) {
    if (apiData.lower_limit) {
      return NOT_LOWER_THAN
    }
    if (apiData.upper_limit) {
      return NOT_HIGHER_THAN
    }
  }
  return ''
}
