import { useQuery } from 'react-query'
import { SUBSCRIPTIONS } from './constants'
import { getAxiosData } from '../useAccountStatus'
import { subscriptionStatus } from 'services/api'
import { AxiosError } from 'axios'
import { Subscription } from 'services/api/endpoints/risikaAPI/new_subscription'

const useSubscriptions = () => {
  const subscriptions = useQuery<Subscription[], AxiosError<Error>>(
    SUBSCRIPTIONS,
    () => getAxiosData(subscriptionStatus()),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  return { ...subscriptions }
}

export { useSubscriptions }
