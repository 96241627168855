import { injectIntl } from 'react-intl'
import moment from 'moment'
import { DateInputRange } from 'components-new'

const DateRangeInput = ({ value, onChange }) => {
  const from = value?.from ?? null
  const to = value?.to ?? null

  const handleChange = (startDate, endDate) => {
    const from = startDate ? moment(new Date(startDate)).format('YYYY-MM-DD') : ''
    const to = endDate ? moment(new Date(endDate)).format('YYYY-MM-DD') : ''
    onChange({ from, to })
  }

  return (
    <DateInputRange
      startDate={from}
      endDate={to}
      onChange={handleChange}
      singleDateRangeField
      containerStyles={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
    />
  )
}

export default injectIntl(DateRangeInput)
