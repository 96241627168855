import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import CancelIcon from '@mui/icons-material/Cancel'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Policy, StepStyle } from './CreditPolicy.types'

export const CreditPolicyModels: Policy[] = [
  {
    name: 'approve',
    color: 'success.700',
    icon: <CheckCircleIcon />,
    variant: 'positive',
  },
  {
    name: 'approve_with_value',
    color: 'success.700',
    icon: <CheckCircleIcon />,
    variant: 'positive',
  },
  {
    name: 'adjust_credit_max',
    color: 'success.700',
    icon: <CheckCircleIcon />,
    variant: 'positive',
  },
  {
    name: 'manual_check',
    color: 'warning.main',
    icon: <ErrorIcon />,
    variant: 'warning',
  },
  {
    name: 'undefined',
    color: 'warning.main',
    icon: <ErrorIcon />,
    variant: 'warning',
  },
  {
    name: 'reject',
    color: 'error.main',
    icon: <CancelIcon />,
    variant: 'negative',
  },
  {
    name: 'default',
    color: 'grey.600',
    icon: <RemoveCircleIcon />,
    variant: 'neutral',
  },
]

export const StepModels: StepStyle[] = [
  {
    variant: 'positive',
    border: ' success.300',
    canvas: 'success.100',
    conclusionBg: 'success.700',
  },
  {
    variant: 'warning',
    border: 'warning.300',
    canvas: 'warning.100',
    conclusionBg: 'warning.700',
  },
  {
    variant: 'negative',
    border: 'error.300',
    canvas: 'error.100',
    conclusionBg: 'error.main',
  },
  {
    variant: 'neutral',
    border: 'grey.300',
    canvas: 'grey.100',
    conclusionBg: 'grey.100',
  },
]
