const main = {
  width: '400px',
  height: '237px',
  backgroundColor: 'common.white',
  boxShadow: 3,
  borderRadius: '6px',
}

const header = {
  backgroundColor: 'primary.main',
  borderRadius: '6px 6px 0 0',
}
const score = {
  minWidth: 'max-content',
  height: '100%',
}

const leftPart = {
  px: 4,
  py: 2,
  width: '80%',
  overflow: 'hidden',
  '& .MuiTypography-root': {
    color: 'common.white',
  },
}

const ellipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const localId = {
  opacity: 0.5,
}

const humanTitle = {
  '& > .MuiTypography-root.MuiTypography-subtitle1': {
    color: 'grey.600',
  },
  '& > .MuiTypography-root.MuiTypography-h5': {
    textAlign: 'center',
    px: 7,
  },
}

const subsidiariesContainer = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: 0,
  height: '24px',
}

const ceo = { px: 6, pt: 4, textAlign: 'center' }

const ceoTitleColor = {
  color: 'grey.700',
}

const hoverUnderline = {
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}

const accountIcon = {
  color: 'grey.500',
  fontSize: '120px',
  strokeWidth: 0,
}

export default {
  main,
  header,
  score,
  leftPart,
  ellipsis,
  localId,
  humanTitle,
  subsidiariesContainer,
  ceo,
  ceoTitleColor,
  hoverUnderline,
  accountIcon,
}
