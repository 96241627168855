import { Checkbox, Skeleton, Stack } from '@mui/material'

type TableHeaderCellLoadingProps = {
  headCell: any
}

function TableHeaderCellLoading({ headCell }: TableHeaderCellLoadingProps) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      className="container"
      sx={{ height: 50, width: 1, minWidth: headCell.minWidth }}
    >
      {headCell.key !== 'checkbox' ? (
        <>
          <Skeleton
            variant="rectangular"
            width={headCell.width}
            sx={{ borderRadius: 5 }}
            height={25}
          />
        </>
      ) : (
        <Stack sx={{ width: 1, height: 1 }}>
          <Checkbox
            sx={{ padding: 0, width: 1, height: '90%' }}
            checked={false}
            indeterminate={false}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default TableHeaderCellLoading
