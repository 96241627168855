export const profilePictures = {
  'nr@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/nicolai.jpg',
  'kg@risika.com':
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/kristoffer.jpg',
  'tj@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/timm.jpg',
  'bb@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/BB.jpeg',
  'bs@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Bjarki.jpeg',
  'gh@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Gustav.jpeg',
  'ip@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Ivaylo.jpeg',
  'cl@risika.dk':
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Lj%C3%B8rring.jpeg',
  'ms@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/mads.jpeg',
  'ops@risika.com':
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Ole+petter.jpeg',
  'ja@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Thomas.jpeg',
  'tv@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Tim.jpeg',
  'tb@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Tsveto.jpeg',
  'dw@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Wagner.jpeg',
  'ttn@risika.com': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/tobias.png',
  'abk@risika.com"':
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/alexander.jpg',
  'cat@risika.com': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/can.jpg',
  'cms@risika.com':
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/christoffer_m.jpg',
  'showcase@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/jens.jpg',
  'mikkel.egelund@gmail.com':
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/mikkel_e.png',
  'nml@risika.com': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/niels.png',
  'rls@risika.com': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/rikke.png',
  'sli@risika.com': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/susan.jpg',
  'zg@risika.dk': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/zilas.png',
  'jfo@risika.com': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/jeanette.jpg',
  'ol@risika.com': 'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Olivia.png',

  dummy_blue:
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/Employee+dummy.jpeg',
  dummy_orange:
    'https://risika-dashboard.s3.eu-central-1.amazonaws.com/employee_dummy_orange.jpeg',
}
