import { intersection } from 'ramda'

/*************
 * Constants *
 *************/

const DEFAULT_ROLE = 'user'

/********
 * Core *
 ********/

/**
 * Ensure the user is assigned a role if none is present.
 *
 * @param maybeRoles The user's roles (if they have any)
 *
 * @returns The user's roles or fallback to the default role.
 */
export function fallbackRoles(maybeRoles) {
  // If the user doesn't have a role, or it is incompatible
  if (!maybeRoles?.length) {
    return [DEFAULT_ROLE]
  }

  return maybeRoles
}

/**
 * Check if the user has the required roles.
 *
 * @param requiredRoles The roles that the user is required to have.
 * @param maybeRoles The user's roles.
 *
 * @returns If the user has the right roles.
 */
export function isRoleAllowed(requiredRoles, userRoles) {
  const roles = fallbackRoles(userRoles)

  // If no roles are specified, then we assume everyone is allowed.
  if (!requiredRoles?.length) {
    return true
  }

  return Boolean(intersection(requiredRoles, roles)?.length)
}

/**
 * Define the roles the user must have upfront.
 */
export function ensureRoles(requiredRoles) {
  return function (userRoles) {
    return isRoleAllowed(requiredRoles, userRoles)
  }
}
