import { renderRoutes } from 'react-router-config'
import { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { routes, navigation } from 'configs'
import { Authorization, ChangePasswordFlow } from 'components/auth'
import Wootric from 'configs/wootric'
import { Route, Redirect } from 'react-router-dom'
import { navigation as navigationService } from 'services/navigation'
import Sidebar from './Sidebar'
import GlobalSearch from './GlobalSearch'
import { useUserSelf } from 'services/queries'
import { Mixpanel } from 'services/helpers/mixpanel'
import { ReduxRootState } from 'store_deprecated/types'

const Dashboard = () => {
  const user = useSelector((state: ReduxRootState) => state.auth?.user, shallowEqual)
  const { pathname } = useLocation()

  useEffect(() => {
    Mixpanel.pageview(pathname)
  }, [pathname])

  const { data, isLoading: isUserSelfLoading } = useUserSelf()
  if (isUserSelfLoading) {
    return null
  }

  return (
    <>
      {data?.should_change_password && <ChangePasswordFlow />}
      <Authorization routes={routes}>
        <Route path="/" exact>
          <Redirect to={navigationService.home()} />
        </Route>
        <Route path="/login" exact>
          {/** Fallback for old navigation */}
          <Redirect to={navigationService.auth.login()} />
        </Route>
        <Sidebar navigation={navigation}>
          <GlobalSearch />
          {renderRoutes(routes)}
        </Sidebar>
        <Wootric user={user} />
      </Authorization>
    </>
  )
}

export default Dashboard
