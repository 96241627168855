import { theme } from 'styles-new/mui5-transition/theme'

const number = {
  border: '1px solid',
  borderColor: 'grey.800',
  color: 'grey.800',
  width: '30px',
  height: '30px',
  borderRadius: 1,
  mr: 3,
  display: 'inline',
  textAlign: 'center',
}
const container = {
  display: 'flex',
  minHeight: '65rem',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    minHeight: 0,
    flexWrap: 'wrap',
  },
}
const columnWrapper = {
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '1rem',
  },
}
const columnTitle = {
  py: 4,
}

const item = {
  display: 'flex',
  alignItems: 'center',
  mb: 1,
}

const dragIcon = {
  color: theme.palette.primary.main,
}

export const classes = {
  number,
  container,
  columnWrapper,
  columnTitle,
  item,
  dragIcon,
}
