import { LinearProgress } from '@mui/material'

import { useStyles } from '../styles/loader'

const Loader = () => {
  const classes = useStyles()

  return (
    <LinearProgress
      size={30}
      thickness={5}
      className={classes.root}
      classes={{
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary,
      }}
    />
  )
}

export default Loader
