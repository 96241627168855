// @flow

import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'

import { Paper, Divider, CircularProgress } from '@mui/material'
import { ConditionallyRender, UseCheckButton } from 'components'
import { useCreditCheck } from 'pages/credit-check/context'
import { useCompanyBasics, useAccountStatus } from 'services/queries'
import CompanyCreditCard from 'components-new/elements/CompanyCreditCard'
import MainRecommendation from '../MainRecommendation/index.js'

const useStyles = makeStyles((theme) => ({
  frame: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '1rem',
    margin: theme.spacing(2),
  },
  title: {
    margin: '1rem 2rem',
    fontWeight: 600,
  },
}))

const FreeMainRecommendation = ({ isReArrange }) => {
  const classes = useStyles()

  const { localId } = useCreditCheck()
  const cachedPages = useSelector((state) => state.risika?.creditCheck?.cachedPages)

  const companyBasicsQuery = useCompanyBasics(localId)
  const accountStatusQuery = useAccountStatus()

  if (companyBasicsQuery.isLoading || accountStatusQuery.isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <Paper className={classes.frame}>
        <Divider orientation="horizontal" />
        {/** Don't show this for freemium! */}
        <ConditionallyRender
          condition={cachedPages?.[localId?.id]}
          when={<MainRecommendation isReArrange={isReArrange} />}
          otherwise={
            <>
              <UseCheckButton
                component="credit"
                className={classes.foldIconCheck}
                localId={localId}
              />
              <div style={{ filter: 'blur(3px' }}>
                <Divider orientation="horizontal" />
                <CompanyCreditCard />
              </div>
            </>
          }
        />
      </Paper>
    </>
  )
}

export default FreeMainRecommendation
