import { useQuery } from 'react-query'
import { companyHierarchyWalkup } from 'services/api'
import { isCallAllowed } from 'configs'
import { AvailableCountries, LocalId } from 'globalTypes'

export function useCompanyHierarchyWalkup(
  shouldLoad: boolean,
  loadInfo: LocalId & { maxDepth: number }
) {
  const isEnabled =
    !!isCallAllowed('companyHierarchyWalkup', loadInfo.country as AvailableCountries) &&
    shouldLoad

  return useQuery(
    ['companyHierarchyWalkup', loadInfo.id, loadInfo.country, loadInfo.maxDepth],
    () => companyHierarchyWalkup(loadInfo),
    {
      enabled: isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
