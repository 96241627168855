// Login user
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
// Verify/activate user
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS'
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'

// Logout user
export const LOGOUT_USER = 'LOGOUT_USER'
export const UPDATE_USER_CURRENCY_SUCCESS = 'UPDATE_USER_CURRENCY_SUCCESS'

// Change password
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED'

// Change user information
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'

// Change company information
export const UPDATE_COMPANY_DOMAIN_AND_PHONE_REQUEST =
  'UPDATE_COMPANY_DOMAIN_AND_PHONE_REQUEST'
export const UPDATE_COMPANY_DOMAIN_AND_PHONE_SUCCESS =
  'UPDATE_COMPANY_DOMAIN_AND_PHONE_SUCCESS'

export const UPDATE_COMPANY_INFORMATION_SUCCESS = 'UPDATE_COMPANY_INFORMATION_SUCCESS'

export const UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS'

// Change user settings
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
// Get user
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'

export const FORCE_USER_PASSWORD_CHANGE = 'FORCE_USER_PASSWORD_CHANGE'

// trying to extinguish redux
export const SET_DASHBOARD_LANG = `SET_DASHBOARD_LANG`
