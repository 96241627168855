type ValuesType = {
  value: number
  date: string
}
export type DataType = {
  group_code: string
  values: ValuesType[]
}
type ItemType = {
  group_code: string
  values: ValuesType[]
}

/**
 * Grouping scores by group_code
 * @param {DataType[]} data Array which has group_code property inside
 * @param {string} key String that is going to be compared with group_code
 */
export const groupScoresByGroupCode = (data: DataType[], key: string) => {
  return data.reduce((accumulator: ValuesType[], item: ItemType) => {
    if (item.group_code === key) accumulator = item.values
    return accumulator
  }, [])
}
