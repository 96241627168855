import { defaultDoughnutChartProperties } from 'configs/charts'

import intl from 'localization/components'

export const creditScoreData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [{ category: 'RISK_ASSESSMENT', function: 'COUNT' }],
    group_by: [{ category: 'RISK_ASSESSMENT' }],
  },
  chartProperties: {
    ...defaultDoughnutChartProperties,
    title: intl.observationalLists('risika-score-title'),
    height: 400,
    valueType: 'RISK',
  },
}
