import { LocalId } from 'globalTypes'

export const allowedApiCalls = {
  DK: [
    'alikeCompanies',
    'companyBasics',
    'getDetailedNumberOfEmployees',
    'getProductionUnits',
    'financialNumbers',
    'financialNumbersConsolidated',
    'financialRatios',
    'financialStats',
    'financialStatsConsolidated',
    'financialPerformance',
    'companyRelations',
    'highlights',
    'ratingCredit',
    'ratingScores',
    'getStatstidende',
    'companyHierarchyWalkdown',
    'companyHierarchyWalkup',
    'getPropertiesForCompany',
    'companyHistory',
    'searchPerson',
    'hierarchySummary',
  ],
  SE: [
    'alikeCompanies',
    'companyBasics',
    'financialStats',
    'financialStatsConsolidated',
    'getDetailedNumberOfEmployees',
    'financialNumbers',
    'getProductionUnits',
    'financialNumbersConsolidated',
    'financialPerformance',
    'companyRelations',
    'financialRatios',
    'highlights',
    'companyHistory',
    'ratingCredit',
    'ratingScores',
    'hierarchySummary',
    'searchPerson',
    'companyHierarchyWalkdown',
    'companyHierarchyWalkup',
  ],
  NO: [
    'alikeCompanies',
    'companyBasics',
    'getStatstidende',
    'getProductionUnits',
    'financialNumbers',
    'getDetailedNumberOfEmployees',
    'financialNumbersConsolidated',
    'companyHistory',
    'financialStats',
    'financialStatsConsolidated',
    'financialRatios',
    'financialPerformance',
    'highlights',
    'companyRelations',
    'companyHierarchy',
    'ratingCredit',
    'ratingScores',
    'companyHierarchyWalkdown',
    'companyHierarchyWalkup',
    'paymentRemarks',
    'hierarchySummary',
    'searchPerson',
  ],
  UK: [
    'companyBasics',
    'financialNumbers',
    'getDetailedNumberOfEmployees',
    'financialStats',
    // 'financialNumbersConsolidated',
    'ratingScores',
    'companyHistory',
  ],
  FI: [
    'alikeCompanies',
    'companyBasics',
    'financialNumbers',
    'financialNumbersConsolidated',
    'financialRatios',
    'financialStats',
    'financialStatsConsolidated',
    'financialPerformance',
    'getDetailedNumberOfEmployees',
    'companyRelations',
    'highlights',
    'ratingCredit',
    'ratingScores',
    'companyHierarchyWalkdown',
    'companyHierarchyWalkup',
    'companyHistory',
    'searchPerson',
    'hierarchySummary',
  ],
  DE: ['companyBasics', 'companyRelations', 'financialNumbers', 'financialStats'],
} as const
export const isCallAllowed = (key: string, country: LocalId['country']) => {
  const countries = ['DK', 'SE', 'NO', 'UK', 'FI', 'DE']
  if (country) {
    if (!countries?.includes(country.toUpperCase())) {
      const err = new Error('Could not find country')
      return err
    }
    return Boolean(
      allowedApiCalls[country.toUpperCase() as keyof typeof allowedApiCalls]?.includes(
        key as 'companyBasics' | 'financialStats' | 'financialNumbers'
      )
    )
  }
  return true
}
