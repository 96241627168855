const removeResizableObserverError = (isDev: boolean) => {
  window.addEventListener('error', (e) => {
    console.log('Error: ', e.message)
    if (
      e.message.includes('ResizeObserver loop') ||
      e.message.includes('Script error.')
    ) {
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
      )
      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
      )
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none')
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none')
      }
    }
  })
}

export default removeResizableObserverError
