import { useQuery } from 'react-query'
import { getPropertiesForCompany } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function usePropertiesForCompany(localId: LocalId) {
  return useQuery(
    ['getPropertiesForCompany', localId.id, localId.country],
    () => getPropertiesForCompany(localId),
    {
      enabled: !!isCallAllowed('getPropertiesForCompany', localId.country),
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
