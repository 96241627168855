import { useMutation, useQueryClient } from 'react-query'
import { monitorRenameList } from '../api'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import intl from '../../localization/components'
import { MONITOR_LIST } from './lists/constants'

type RenameListType = {
  listId: number
  title: string
}

type MonitoringListRenameProps = {
  onSuccess?: () => void
  onError?: () => void
}

const useMonitoringListRename = ({ onSuccess, onError }: MonitoringListRenameProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { messages } = useIntl()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    ({ listId, title }: RenameListType) => monitorRenameList(listId, title),
    {
      onError: (err) => {
        onError?.()
        enqueueSnackbar(
          messages[intl.riskMonitoringNew('list-name-update-fail')] as string,
          {
            variant: 'error',
          }
        )
        console.log('Error updating list name: ', err)
      },
      onSuccess: () => {
        onSuccess?.()
        queryClient.invalidateQueries(MONITOR_LIST)
        enqueueSnackbar(
          messages[intl.riskMonitoringNew('list-name-update-success')] as string,
          {
            variant: 'success',
          }
        )
      },
    }
  )
  return { renameList: mutation.mutate, ...mutation }
}

export default useMonitoringListRename
