import { theme } from 'styles-new/mui5-transition/theme'
import { PaletteColorOptions } from '@mui/material'

const {
  palette: { success, error, warning, grey },
} = theme

export default {
  risikaWarning: '#FF684F',
  risikaBlack: '#010B0C',
  risika: '#0A373D',
  risikaSecondary: '#245157',
  risikaContrastDark: '#91580D',
  risikaContrastDim: '#C99755',
  risikaContrast: '#FFCC89',
  risikaTertiaryDark: '#0A5E68',
  risikaTertiaryDim: '#238C99',
  risikaTertiary: '#54BDCB',
  risikaTertiaryLight: '#71DEEC',
  risikaBlue: '#55A1C9',
  risikaBlueDark: '#285F7D',
  risikaBlueLight: '#85D4FF',
  risikaLight: '#F6FBFC',
  risikaLightDark: '#E5EEF0',

  // What originality are we seeing here!!!
  risikaWhite: '#FFFFFF',

  // Notifications
  // TODO: Pick better colors, especially for negative/blue background
  default: '#F5F5F5',
  info: '#F8E71C',
  warning: 'orange',
  danger: 'red',
  success: 'green',
  // Basic colors
  gray: 'gray',
  lightest_gray: '#F5F5F5',
  light_gray: '#979797',
  purple: '#822A85',
  yellow: '#F8E71C',
  orange: 'orange',
  dark_orange: 'darkorange',
  red: 'red',

  // Colors for Performance in Financial Ratios
  rating_1: '#DC172F', // Very weak
  rating_2: '#DC172F', // Very weak
  rating_3: '#F26825', // Weak
  rating_4: '#F26825', // Weak
  rating_5: '#FEBE0E', // Average
  rating_6: '#FEBE0E', // Average
  rating_7: '#90C210', // Strong
  rating_8: '#90C210', // Strong
  rating_9: '#4EA206', // Very strong
  rating_10: '#4EA206', // Very strong

  // New risk colors used in
  // - Performance chart
  // - Score in Monitoring, Search, Filter, People and RI tables
  // - Score in RI charts
  // - Score in Company Hierarchy
  score_rating_1: error[700 as keyof PaletteColorOptions], // score 1
  score_rating_2: error[700 as keyof PaletteColorOptions], // score 2
  score_rating_3: warning[900 as keyof PaletteColorOptions], // score 3
  score_rating_4: warning[900 as keyof PaletteColorOptions], // score 4
  score_rating_5: warning[700 as keyof PaletteColorOptions], // score 5
  score_rating_6: warning[700 as keyof PaletteColorOptions], // score 6
  score_rating_7: success[500 as keyof PaletteColorOptions], // score 7
  score_rating_8: success[500 as keyof PaletteColorOptions], // score 8
  score_rating_9: success[700 as keyof PaletteColorOptions], // score 9
  score_rating_10: success[700 as keyof PaletteColorOptions], // score 10

  // Alternative background colors
  bg_rating_1: '#DC172F',
  bg_rating_2: '#DC172F',
  bg_rating_3: '#F26825',
  bg_rating_4: '#F26825',
  bg_rating_5: '#FEBE0E',
  bg_rating_6: '#FEBE0E',
  bg_rating_7: '#90C210',
  bg_rating_8: '#90C210',
  bg_rating_9: '#4EA206',
  bg_rating_10: '#4EA206',
  bg_rating_null: '#555555',
  bg_rating_undefined: '#555555',

  negative: '#D0021B',
  neutral: '#039BE5',
  positive: '#77B267',

  lowRisk: success.main,
  mediumRisk: warning[700 as keyof PaletteColorOptions],
  highRisk: error.main,
  noScore: grey[600 as keyof PaletteColorOptions],
  noScoreDark: '#5b5a5a',

  newOrange: '#E73434',
  newBlue: '#57BECA',
  riskIntelligence: {
    lowRisk: '#17BEBB',
    mediumRisk: '#F9A96E',
    highRisk: '#FF684F',
    noScore: '#878E95',
    'N/A': '#878E95',
  },
}

export const muiTheme = {
  colors: {
    grey: {
      50: '#fafafa',
      200: '#eeeeee',
    },
  },
}
