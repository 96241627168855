import risikaAPI from 'services/api/helpers/risikaAPI'
import { CreditPolicyResponse } from 'types/queries'

const getCreditPolicy = async (
  policyId: number | string | null
): Promise<CreditPolicyResponse> => {
  if (!policyId) throw new Error('Policy ID is required')
  const { data } = await risikaAPI.get(`enterprise_credit_policies/${policyId}`, {
    microservice: 'account',
  })
  return data
}

export default getCreditPolicy
