const saveSection = {
  boxShadow:
    ' -3px 3px 6px 0px rgba(0, 0, 0, 0.10), 0px -2px 8px 0px rgba(0, 0, 0, 0.08), 0px -4px 6px 0px rgba(0, 0, 0, 0.08)',
  backgroundColor: 'common.white',
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  py: 4,
  px: 6,
}

const policyContainer = {
  backgroundColor: 'common.white',
  marginTop: 4,
  marginX: '20px !important',
  padding: 6,
  paddingBottom: '166px !important',
  gap: '96px',
}

const parentContainer = {
  display: 'flex',
  paddingRight: '30px',
}

export default {
  saveSection,
  policyContainer,
  parentContainer,
}
