import { useCallback, useMemo } from 'react'
import { Box, Stack, Toolbar, TextField, Grid, Typography } from '@mui/material'
import TableToolbarSkeleton from './skeletons/TableToolbarSkeleton'
import FilterMultiSelect from './inputs/FilterMultiSelect'
import ActionMenu from './inputs/ActionMenu'
import _debounce from 'lodash/debounce'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { TableHeaderCellProps } from './TableToolbar.types'
import SearchIcon from '@mui/icons-material/Search'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import { tableGreyField, searchIconStyle } from './TableToolbar.styles'

const TableToolbar = ({
  listId,
  tableFilters,
  isLoading,
  handleSearch,
  handleFilters,
  selectedCompanies,
}: TableHeaderCellProps) => {
  const reactIntl = useIntl()
  const messages = reactIntl.messages

  // ---- TYPESCRIPT FRIENDLY DEBOUNCE (START)
  const debounce = useMemo(
    () =>
      _debounce((fn, val: string) => {
        fn(val)
      }, 500),
    []
  )

  const handleChange = useCallback(
    (fn: (searchTerm: string) => void, value: string) => {
      debounce(fn, value)
    },
    [debounce]
  )
  // ---- TYPESCRIPT FRIENDLY DEBOUNCE (END)
  return (
    <Toolbar sx={{ marginBottom: 1, overflow: 'auto' }}>
      <Grid container spacing={0} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} xl={4}>
          <Box sx={{ marginRight: 'auto' }}>
            <TextField
              onChange={(event) => handleChange(handleSearch, event.target.value)}
              placeholder={messages[intl.searchBusiness('search-field')] as string}
              value={tableFilters.searchTerm}
              size="small"
              variant="outlined"
              sx={{
                width: 400,
                ...tableGreyField,
              }}
              InputProps={{
                endAdornment: <SearchIcon fontSize="large" sx={searchIconStyle} />,
              }}
              data-testid="table-search"
            />
          </Box>
        </Grid>
        <Grid item xs={12} xl={8}>
          <Box sx={{ marginLeft: 'auto' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              {isLoading && <TableToolbarSkeleton />}
              {Object.keys(tableFilters).map((filterCategory: any, index: number) => {
                if (filterCategory !== apiKeys.freeSearch) {
                  return (
                    <FilterMultiSelect
                      key={filterCategory}
                      id={filterCategory}
                      filterData={tableFilters[filterCategory]}
                      handleFilters={handleFilters}
                      tag={filterCategory}
                      dataCy={`${filterCategory}-filter`}
                    />
                  )
                }
              })}
              <ActionMenu listId={listId} selectedCompanies={selectedCompanies} />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

export default TableToolbar
