import {
  CreateColumnsProps,
  User,
  UserFieldUpdateFunction,
  UserRoleUpdateFunction,
} from './UserTable.types'
import { Column } from 'components-new/data-display/SimpleTable/SimpleTable.types'
import {
  ActionCell,
  CellWrapper,
  EmailCell,
  HeaderCellWrapper,
  LastLogin,
  NameCell,
  PhoneNumberCell,
  RolesCell,
} from './widgets/cells'
import { Typography } from '@mui/material'
import { Role } from 'types/general'

export const createUserTableColumns = ({
  selectedRow,
  setSelectedRow,
  selectedUser,
  setSelectedUser,
  handleUpdateUserData,
  handleUpdateUser,
  handleCreateUser,
  setUserToBeDeleted,
  isAddingNewUser,
  setIsAddingNewUser,
}: CreateColumnsProps): Column<User>[] => {
  const isSelected = (i: number | null) => selectedRow !== null && selectedRow === i
  const isBlurred = (i: number | null) => selectedRow !== null && selectedRow !== i
  const isDisabled = (i: number | null) => selectedRow !== null && selectedRow !== i
  return [
    {
      id: 'actions',
      label: 'Actions',
      width: '1%',
      headerComponent: () => (
        <HeaderCellWrapper>
          <Typography variant="subtitle2">Action</Typography>
        </HeaderCellWrapper>
      ),
      bodyComponent: (user: User, i: number) => (
        <CellWrapper removePadding contentPosition="center" isBlurred={isBlurred(i)}>
          <ActionCell
            i={i}
            setSelectedRow={setSelectedRow}
            isSelected={isSelected(i)}
            disabledButton={isDisabled(i)}
            handleSaveChanges={handleUpdateUser}
            isAddingNewUser={isAddingNewUser}
            setIsAddingNewUser={setIsAddingNewUser}
            setSelectedUser={setSelectedUser}
            handleCreateUser={handleCreateUser}
            setUserToBeDeleted={setUserToBeDeleted}
          />
        </CellWrapper>
      ),
    },
    {
      id: 'name',
      label: 'Name',
      headerComponent: () => (
        <HeaderCellWrapper>
          <Typography variant="subtitle2">Name</Typography>
        </HeaderCellWrapper>
      ),
      bodyComponent: (user: User, i: number) => (
        <CellWrapper isBlurred={isBlurred(i)}>
          <NameCell
            user={user}
            selectedUser={selectedUser}
            isSelected={isSelected(i)}
            handleUpdateUserName={handleUpdateUserData('name') as UserFieldUpdateFunction}
            handleSaveChanges={handleUpdateUser}
            isAddingNewUser={isAddingNewUser}
          />
        </CellWrapper>
      ),
    },
    {
      id: 'email',
      label: 'Email',
      headerComponent: () => (
        <HeaderCellWrapper>
          <Typography variant="subtitle2">Email</Typography>
        </HeaderCellWrapper>
      ),
      bodyComponent: (user: User, i: number) => (
        <CellWrapper isBlurred={isBlurred(i)}>
          <EmailCell
            user={user}
            selectedUser={selectedUser}
            isSelected={isSelected(i)}
            isAddingNewUser={isAddingNewUser}
            handleUpdateUserEmail={
              handleUpdateUserData('email') as UserFieldUpdateFunction
            }
          />
        </CellWrapper>
      ),
    },
    {
      id: 'phoneNumber',
      label: 'Phone Number',
      headerComponent: () => (
        <HeaderCellWrapper>
          <Typography variant="subtitle2">Phone</Typography>
        </HeaderCellWrapper>
      ),
      bodyComponent: (user: User, i: number) => (
        <CellWrapper isBlurred={isBlurred(i)}>
          <PhoneNumberCell
            user={user}
            selectedUser={selectedUser}
            isSelected={isSelected(i)}
            isAddingNewUser={isAddingNewUser}
            handleSaveChanges={handleUpdateUser}
            handleUpdateUserPhone={
              handleUpdateUserData('phoneNumber') as UserFieldUpdateFunction
            }
          />
        </CellWrapper>
      ),
    },
    {
      id: 'roles',
      label: 'Roles',
      headerComponent: () => (
        <HeaderCellWrapper>
          <Typography variant="subtitle2">Roles</Typography>
        </HeaderCellWrapper>
      ),
      bodyComponent: (user: User, i: number) => (
        <CellWrapper isBlurred={isBlurred(i)}>
          <RolesCell
            user={user}
            selectedUser={selectedUser}
            isSelected={isSelected(i)}
            disabledButton={isDisabled(i)}
            handleUpdateUserRole={handleUpdateUserData('roles') as UserRoleUpdateFunction}
          />
        </CellWrapper>
      ),
    },
    {
      id: 'lastLogin',
      label: 'Last Login',
      headerComponent: () => (
        <HeaderCellWrapper>
          <Typography variant="subtitle2">Last activity</Typography>
        </HeaderCellWrapper>
      ),
      bodyComponent: (user: User, i: number) => (
        <CellWrapper isBlurred={isBlurred(i)}>
          <LastLogin user={user} />
        </CellWrapper>
      ),
    },
  ]
}

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePhone = (phone: string) => {
  // Checks for numbers + and -
  const re = /^[0-9 ()+-]+$/
  if (!phone) {
    return true
  }
  return re.test(String(phone).toLowerCase())
}

export const validate = (data: {
  name: string
  email: string
  phone: string
  role: Role
}) => {
  if (data.name === '') {
    return 'name-validation'
  }
  if (!validateEmail(data.email)) {
    return 'email-validation'
  }
  if (!validatePhone(data.phone)) {
    return 'phone-validation'
  }
  return ''
}
