import { Stack, Collapse, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isNil } from 'ramda'
import { Show } from 'components'
import Highlight from './Highlight'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import InteractiveHighlight from './InteractiveHighlight'
import ConnectedBankruptcies from './ConnectedBankruptcies'
import PriorDistressHighlight from './PriorDistressHighlights'
import { colors } from 'configs'
import { isComponentRendered } from 'configs'
import { useCreditCheck } from '../../../../context'
import { LoadingSkeleton } from 'components-new'
import CompanyHighlightsSkeleton from './CompanyHighlights.skeleton'
import { WidgetWrapper } from '../WidgetWrapper/WidgetWrapper'

const useStyles = makeStyles((theme) => ({
  frame: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (isDragging) => (isDragging ? colors?.risikaContrast : 'inherit'),
  },
  outerStack: {
    padding: theme.spacing(4),
  },
  main: {
    marginTop: theme.spacing(6),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

function sortHighlights(highlights) {
  const copyHighlights = [...highlights]

  function classificationNumeric(classification) {
    switch (classification) {
      case 'negative':
        return 2
      case 'positive':
        return 1
      case 'neutral':
        return 0
      default:
        return 0
    }
  }

  copyHighlights.sort((a, b) => {
    const classificationOfA = classificationNumeric(a.classification)
    const classificationOfB = classificationNumeric(b.classification)

    const diffClassification = classificationOfB - classificationOfA

    if (diffClassification !== 0) {
      return diffClassification
    }

    return a.weight - b.weight
  })

  return copyHighlights
}

function parseHighlights(highlights) {
  const parsedHighlights = []

  for (const key in highlights) {
    parsedHighlights.push({
      key,
      classification: highlights[key].classification,
      title: highlights[key].title,
      description: highlights[key].description,
      message: highlights[key].message,
      weight: highlights[key].weight,
      data: highlights[key].data,
    })
  }

  return sortHighlights(parsedHighlights)
}

export const CompanyHighlights = ({
  widgetNameDND,
  highlightsQueryData = {},
  isReArrange = false,
  isDragging = false,
  isLoading,
  isActive,
  setIsActive,
}) => {
  const localClasses = useStyles(isDragging)
  const allParsedHighlights = parseHighlights(highlightsQueryData)
  let highlightsToShow = []

  highlightsToShow = allParsedHighlights.map((x) =>
    Object.assign({}, x, { blurred: false })
  )

  const { localId } = useCreditCheck()

  if (!highlightsToShow?.length) {
    return null
  }

  return (
    <LoadingSkeleton SkeletonComponent={CompanyHighlightsSkeleton} isLoading={isLoading}>
      <Show when={isComponentRendered('companyHighlights', localId?.country)}>
        <WidgetWrapper
          isReArrange={isReArrange}
          widgetName={widgetNameDND}
          isActive={isActive}
          setIsActive={setIsActive}
        >
          <Stack direction="column" className={localClasses.outerStack}>
            <Typography variant="h4">
              <FormattedMessage id={intl.companyHighlights('title')} />
            </Typography>
            <Collapse in={!isReArrange}>
              <Stack direction="column" spacing={4} className={localClasses.main}>
                {highlightsToShow.map(
                  ({ title, message, classification, key: highlight, data, blurred }) => {
                    return (
                      <div key={highlight} data-cy="highlights-container">
                        {highlight === 'connected_bankruptcies' && !isNil(data) ? (
                          <InteractiveHighlight
                            blurred={blurred}
                            text={<FormattedMessage id={intl.generic('show-more')} />}
                            content={(open, onClose) => (
                              <ConnectedBankruptcies
                                open={open}
                                onClose={onClose}
                                title={title}
                                data={data}
                              />
                            )}
                          >
                            <Highlight
                              blurred={blurred}
                              highlight={highlight}
                              title={title}
                              message={message}
                              classification={classification}
                            />
                          </InteractiveHighlight>
                        ) : highlight === 'prior_distress' && !isNil(data) ? (
                          <InteractiveHighlight
                            blurred={blurred}
                            text={<FormattedMessage id={intl.generic('show-more')} />}
                            content={(open, onClose) => (
                              <PriorDistressHighlight
                                open={open}
                                onClose={onClose}
                                title={title}
                                data={data}
                              />
                            )}
                          >
                            <Highlight
                              blurred={blurred}
                              highlight={highlight}
                              title={title}
                              message={message}
                              classification={classification}
                            />
                          </InteractiveHighlight>
                        ) : (
                          <Highlight
                            blurred={blurred}
                            highlight={highlight}
                            title={title}
                            message={message}
                            classification={classification}
                          />
                        )}
                      </div>
                    )
                  }
                )}
              </Stack>
            </Collapse>
          </Stack>
        </WidgetWrapper>
      </Show>
    </LoadingSkeleton>
  )
}

export default CompanyHighlights
