import { Box, Typography } from '@mui/material'
import { RadialBarChart, RadialBar } from 'recharts'
// import { RadialHalfCircleChartProps } from './RadialHalfCircleChart.types'
import { classHalfCircleText, classRadialValue } from './RadialHalfCircleChart.styles'
import { PropTypes } from './RadialHalfCircleChart.types'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
/* DELETE after transition to MUI5 is complete */
import 'styles-new/mui5-transition/overrides.css'
import { colors } from 'configs'

export default function RadialHalfCircleChart({
  value,
  fill = colors.riskIntelligence.lowRisk,
  outerRadius = 250,
  barSize = 15,
  cornerRadius = 0,
}: PropTypes) {
  const data = [
    {
      name: 'model',
      value: 100,
      fill: 'white',
    },
    {
      name: 'target',
      value,
      fill,
    },
  ]

  /*
  PLEASE NOTE: Those two strange lines:
    innerRadius={6}
    outerRadius={outerRadius - 6}
  Instead od normal:
    innerRadius={0}
    outerRadius={outerRadius}
  are there to prevent Recharts bug: Error: <path> attribute d: Expected number,
  */

  return (
    <Box
      className="MUI5"
      sx={{
        display: 'block',
        position: 'relative',
        width: `${outerRadius + barSize}px`,
      }}
    >
      <RadialBarChart
        cy={outerRadius / 2 + barSize}
        width={outerRadius + barSize}
        height={outerRadius / 2 + barSize}
        innerRadius={6}
        outerRadius={outerRadius - 6}
        barSize={barSize}
        data={data}
        startAngle={180}
        endAngle={0}
      >
        <RadialBar background dataKey="value" cornerRadius={cornerRadius} />
      </RadialBarChart>
      <Box sx={classHalfCircleText}>
        <Typography component="div" sx={{ color: fill }}>
          <Box sx={classRadialValue}>{value}</Box>
        </Typography>
        <Typography variant="subtitle1" sx={{ color: fill }}>
          <FormattedMessage id={intl.portfolioInsights('portfolio-score')} />
        </Typography>
      </Box>
    </Box>
  )
}
