import {
  AdvancedPolicyGetT,
  ExcludeRisikaScores,
} from 'services/api/endpoints/risikaAPI/creditPolicyGet'
import {
  AdvancedPolicySetT,
  ExcludeCompaniesSet,
  ExcludePersonsSet,
  SetSinglePolicyPayloadT,
} from 'services/api/endpoints/risikaAPI/setSingleCreditPolicy'
import { MappingItem, RisikaScoreMapping } from './types'
import intl from 'localization/components'

const fromValueToLabel = (
  value: number | string | null,
  mapping: MappingItem[]
): string => {
  if (value === null) return ''
  const label = mapping.find((item) => item.value.toString() === value.toString())?.label
  if (!label) throw new Error('No label found')
  return label
}

const fromLabelToValue = (
  label: string,
  mapping: MappingItem[]
): number | string | null => {
  if (label === '') return null
  const item = mapping.find((item) => {
    // console.log(item.label, label)
    return item.label === label
  })
  if (!item) throw new Error('No value found')
  return item.value
}

function extractScoreNumberArray(obj: ExcludeRisikaScores | null): number[] | null {
  if (obj?.BOHR) {
    return obj.BOHR
  } else if (obj?.NEWTON) {
    return obj.NEWTON
  } else {
    return null
  }
}

function getScoreArray(label: string, mapping: RisikaScoreMapping[]) {
  const mappingItem = mapping.find((item: RisikaScoreMapping) => item.label === label)
  if (!mappingItem) {
    throw new Error(`No value array found for label '${label}'`)
  }
  return mappingItem.value
}

function getScoreLabel(value: number[] | null, mapping: RisikaScoreMapping[]): string {
  if (value === null) return ''
  const mappingItem = mapping.find(
    (item: RisikaScoreMapping) => item.value?.toString() === value?.toString()
  )
  if (!mappingItem) {
    throw new Error(`No label found for value array '${value}'`)
  }
  return mappingItem.label
}

const removeNameFromCompanies = (
  excludeCompanies: ExcludeCompaniesSet[] | null
): ExcludeCompaniesSet[] | null => {
  if (excludeCompanies === null) return null
  return excludeCompanies?.map((company) => {
    return {
      local_organization_id: {
        country: company.local_organization_id.country,
        id: company.local_organization_id.id,
      },
    }
  })
}
const removeNameFromPeople = (
  exclude_persons: ExcludePersonsSet[] | null
): ExcludePersonsSet[] | null => {
  if (exclude_persons === null) return null
  return exclude_persons?.map((company) => {
    return {
      local_person_id: {
        country: company.local_person_id.country,
        id: company.local_person_id.id,
      },
    }
  })
}

const convertToSetObject = (policySet: SetSinglePolicyPayloadT | undefined) => {
  if (!policySet) return undefined
  return {
    ...policySet,
    policy: {
      ...policySet?.policy,
      exclude_companies: removeNameFromCompanies(policySet.policy.exclude_companies)
        ?.length
        ? removeNameFromCompanies(policySet.policy.exclude_companies)
        : null,
      exclude_persons: removeNameFromPeople(policySet.policy.exclude_persons)?.length
        ? removeNameFromPeople(policySet.policy.exclude_persons)
        : null,
      exclude_risika_scores: policySet.policy.exclude_risika_scores?.length
        ? policySet.policy.exclude_risika_scores
        : null,
      exclude_vat: policySet.policy.exclude_vat?.length
        ? policySet.policy.exclude_vat
        : null,
      exclude_industries: policySet.policy.exclude_industries?.length
        ? policySet.policy.exclude_industries
        : null,
      exclude_level_of_auditor_assistances: policySet.policy
        .exclude_level_of_auditor_assistances?.length
        ? policySet.policy.exclude_level_of_auditor_assistances
        : null,
    },
  }
}

const complyToSetFormat = (
  policyGet: AdvancedPolicyGetT | undefined
): AdvancedPolicySetT | undefined => {
  if (!policyGet) return undefined
  return {
    ...policyGet,
    exclude_risika_scores: extractScoreNumberArray(policyGet.exclude_risika_scores),
  }
}

const getLabels = (rawData: { labelKey: string; value: string | number }[]) => {
  const newOne = rawData.map((item) => {
    return {
      label: intl.creditPolicyNew(item.labelKey),
      value: item.value,
    }
  })

  return newOne
}

export {
  fromValueToLabel,
  fromLabelToValue,
  getScoreArray,
  getScoreLabel,
  extractScoreNumberArray,
  removeNameFromCompanies,
  removeNameFromPeople,
  complyToSetFormat,
  convertToSetObject,
  getLabels,
}
