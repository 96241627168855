import { SxProps, Typography, TypographyVariant } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ReactNode } from 'react'

type Props = {
  labelKey: string
  group?: keyof typeof intl
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  variant?: TypographyVariant
  sx?: SxProps
  values?: Record<string, ReactNode>
  onClick?: () => void
}

function IntlTypography({
  labelKey,
  group = 'generic',
  align = 'inherit',
  variant = 'body1',
  sx,
  values,
  onClick,
}: Props) {
  return (
    <Typography variant={variant} align={align} sx={sx} onClick={onClick}>
      <FormattedMessage id={intl[group](labelKey)} values={values} />
    </Typography>
  )
}

export default IntlTypography
