import intl from 'localization/components'

export const countries = [
  {
    label: intl.country('dk'),
    value: 'DK',
  },
  {
    label: intl.country('se'),
    value: 'SE',
  },
  {
    label: intl.country('no'),
    value: 'NO',
  },
  {
    label: intl.country('fi'),
    value: 'FI',
  },
  {
    label: intl.country('uk'),
    value: 'UK',
  },
  {
    label: intl.country('de'),
    value: 'DE',
  },
]
