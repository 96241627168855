import { defaultStackedAreaChartProperties } from 'configs/charts'

import intl from 'localization/components'

export const timelineData = {
  endPoint: 'timeline',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [{ category: 'RISK_ASSESSMENT', function: 'COUNT' }],
    sample_type: 'END_OF_MONTH_INCOMPLETE',
    historic_monitor: true,
  },
  chartProperties: {
    ...defaultStackedAreaChartProperties,
    cartesianGrid: false,
    title: intl.observationalLists('risika-score-by-time-title'),
    height: 390,
    valueType: 'RISK',
  },
}
