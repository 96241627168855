import {
  CreditAutomatorContextState,
  CreditAutomatorContextActionTypes,
} from './CreditAutomatorTableContext.types'
import {
  SET_PAGE,
  SET_PAGE_LIMIT,
  SET_SORT_BY,
  SET_PAGE_COUNT,
  SET_LIST_ID,
} from './constants'

export function creditAutomatorTableReducer(
  state: CreditAutomatorContextState,
  { type, payload }: CreditAutomatorContextActionTypes
) {
  switch (type) {
    case SET_PAGE: {
      return {
        ...state,
        page: payload.newPage,
      }
    }
    case SET_PAGE_LIMIT: {
      return {
        ...state,
        perPageLimit: Number(payload.limit),
      }
    }
    case SET_SORT_BY: {
      return {
        ...state,
        sortBy: payload.sorting,
      }
    }
    case SET_PAGE_COUNT: {
      return {
        ...state,
        pageCount: payload.pageCount,
      }
    }
    case SET_LIST_ID: {
      return {
        ...state,
        listId: payload.listId,
      }
    }

    default: {
      throw new Error('Unhandled type: ' + type)
    }
  }
}
