import { useCreditCheck } from 'pages/credit-check/context'
import RiskEstimation from './components/RiskEstimation/D3RisikaScore/components/RiskEstimation'
import NewScorecardExplanation from './components/NewScorecardExplanatioin/NewScorecardExplanation'
import Recommendation from './components/Recommendation/Recommendation'
import HistoricalScoresBox from 'widgets/charts/HistoricalScoresBox'
import CreditPolicy from './components/CreditPolicy'
import CompanyHighlights from './components/CompanyHighlights'
import NorwegianPaymentRemarks from './components/NorwegianPaymentRemarks/NorwegianPaymentRemarks'
import SidebarWrapperDND from './components/SidebarWrapperDND'
import CreditOverride from './components/CreditOverride'
import { getLastGazzeteReport } from 'utils-new/get-last-gazzete-report'
import { DECREE } from 'configs/constants/system'
import { useQueryClient } from 'react-query'
import {
  useCompanyBasics,
  useRatingScores,
  useStatstidende,
  useHighlights,
  useAccountStatus,
  usePaymentRemarks,
  useEvaluationPolicySingle,
  useRatingCredit,
  useUserSelf,
} from 'services/queries'

const MainRecommendation = ({
  isReArrange,
  isElementActive,
  setIsElementActive,
  setLocalRes,
  localSidebarData,
  setLocalSidebarData,
}) => {
  const queryClient = useQueryClient()
  const { localId } = useCreditCheck()
  const companyBasicsQuery = useCompanyBasics(localId)
  const ratingScoresQuery = useRatingScores(localId)
  const statstidendeQuery = useStatstidende(localId)
  const highlightsQuery = useHighlights(localId)
  const paymentRemarksQuery = usePaymentRemarks(localId)
  const accountStatusQuery = useAccountStatus()
  const customerData = queryClient.getQueryData('customerData')
  const userQuery = useUserSelf()
  const ratingCredit = useRatingCredit(localId)
  const evaluationQuery = useEvaluationPolicySingle(
    localId,
    customerData?.user_model?.[localId?.country?.toUpperCase()] ?? null
  )

  const isLoading = {
    creditOverride: Boolean(
      companyBasicsQuery.isLoading ||
        evaluationQuery.isLoading ||
        ratingCredit.isLoading ||
        userQuery.isLoading
    ),
    riskEstimation: Boolean(
      statstidendeQuery.isLoading ||
        companyBasicsQuery.isLoading ||
        ratingScoresQuery.isLoading
    ),
    newScorecardExplanation: Boolean(
      ratingScoresQuery.isLoading ||
        accountStatusQuery.isLoading ||
        statstidendeQuery.isLoading
    ),
    recommendation: Boolean(
      ratingScoresQuery?.isLoading ||
        companyBasicsQuery?.isLoading ||
        statstidendeQuery?.isLoading
    ),
    norwegianPaymentRemarks: Boolean(paymentRemarksQuery.isLoading),
    performance: Boolean(ratingScoresQuery.isLoading),
    creditPolicy: Boolean(companyBasicsQuery.isLoading || evaluationQuery.isLoading),
    companyHighlights: Boolean(highlightsQuery.isLoading),
  }

  const lastReport = getLastGazzeteReport(statstidendeQuery.data)
  const isGazzete = Boolean(lastReport?.type === DECREE)

  return (
    <SidebarWrapperDND
      isReArrange={isReArrange}
      isElementActive={isElementActive}
      setIsElementActive={setIsElementActive}
      setLocalRes={setLocalRes}
      localSidebarData={localSidebarData}
      setLocalSidebarData={setLocalSidebarData}
      dataCy="credit-override"
    >
      {/* // Credit override  */}
      <CreditOverride
        isLoading={isLoading.creditOverride}
        widgetNameDND="credit-override"
        isError={companyBasicsQuery.isError || ratingCredit.isError || userQuery.isError}
        companyBasicsData={{
          customerName: companyBasicsQuery.data?.company_name,
          localId: companyBasicsQuery.data?.local_organization_id.id,
          companyHash: companyBasicsQuery.data?.local_organization_id.hash,
          industryType: companyBasicsQuery.data?.main_industry_code.description,
          country: companyBasicsQuery.data?.local_organization_id.country,
        }}
        evaluation={evaluationQuery?.data}
        ratingCredit={ratingCredit?.data ?? {}}
        risikaRecommendation={ratingCredit?.data?.credit_max ?? null}
        risikaScore={
          companyBasicsQuery.data?.score || companyBasicsQuery.data?.risk_assessment
        }
        userName={userQuery.data?.name}
        userEmail={userQuery.data?.email}
        companyId={userQuery.data?.customer_id}
        currency={evaluationQuery?.data?.currency}
      />

      {/* // Risika's estimation */}
      <RiskEstimation
        isLoading={isLoading.riskEstimation}
        localId={localId}
        widgetNameDND="estimation"
        isError={
          statstidendeQuery.isError ||
          companyBasicsQuery.isError ||
          ratingScoresQuery.isError
        }
        statstidendeData={statstidendeQuery.data}
        statusInfo={{
          text: companyBasicsQuery.data?.status,
          code: companyBasicsQuery.data?.status_code,
          validFrom: companyBasicsQuery.data?.status_valid_from,
        }}
        riskInfo={{
          riskAssessmentCode: ratingScoresQuery.data?.current_score?.risk_assessment_code,
          risikaScore: ratingScoresQuery.data?.current_score?.score,
        }}
        isSoleProprietorship={companyBasicsQuery.data?.company_type?.short === 'ENK'}
        distress={ratingScoresQuery.data?.current_score?.probability_of_distress}
      />

      <NewScorecardExplanation
        isLoading={isLoading.newScorecardExplanation}
        widgetNameDND="explanation"
        isError={
          ratingScoresQuery.isError ||
          accountStatusQuery.isError ||
          statstidendeQuery.isError
        }
        scoresInfo={ratingScoresQuery.data?.current_score?.score_information}
        plan={accountStatusQuery.data?.subscription_plan}
        isGazzete={isGazzete}
      />
      {/* // Recommendation  */}
      <Recommendation
        isLoading={isLoading.recommendation}
        columnDirection
        localId={localId}
        widgetNameDND="recommendation"
        isError={
          ratingScoresQuery.isError ||
          companyBasicsQuery.isError ||
          statstidendeQuery.isError
        }
        riskAssessmentCode={ratingScoresQuery.data?.current_score?.risk_assessment_code}
        companyType={companyBasicsQuery.data?.company_type?.short}
        isGazzete={isGazzete}
      />
      <NorwegianPaymentRemarks
        isLoading={isLoading.norwegianPaymentRemarks}
        widgetNameDND="norwegian-payment-remarks"
        isError={paymentRemarksQuery.isError}
        data={paymentRemarksQuery.data}
      />

      {/* // Performance  */}
      <HistoricalScoresBox // This component handles the loading state itself
        widgetNameDND="performance"
        localId={localId}
        isError={ratingScoresQuery.isError}
      />
      <CreditPolicy
        isLoading={isLoading.creditPolicy}
        widgetNameDND="credit-policy"
        isError={companyBasicsQuery.isError}
        creditPolicy={companyBasicsQuery.data?.credit_policy}
        companyStatus={companyBasicsQuery.data?.status_code}
        enterpriseEvaluation={evaluationQuery?.data}
        enterpriseEvaluationError={evaluationQuery?.error}
      />

      {/* // Company highlights */}
      <CompanyHighlights
        isLoading={isLoading.companyHighlights}
        widgetNameDND="company-highlights"
        isError={highlightsQuery.isError}
        highlightsQueryData={highlightsQuery?.data}
      />
    </SidebarWrapperDND>
  )
}

export default MainRecommendation
