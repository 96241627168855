import * as React from 'react'
import { useAccountStatus } from 'services/queries'
import { useSelector } from 'react-redux'
import {
  Action,
  AppShellContextValue,
  AppShellProviderProps,
  ResultType,
  ContextState,
} from './types'
import { ReduxRootState } from '../../store_deprecated/types'

const AppShellContext = React.createContext<AppShellContextValue | null>(null)

function reducer(state: ContextState, action: Action) {
  switch (action.type) {
    case 'countries': {
      return {
        ...state,
        countries: action.payload,
      }
    }
    case 'country': {
      return {
        ...state,
        country: action.payload,
      }
    }
    case 'resultType': {
      return {
        ...state,
        resultType: action.payload,
      }
    }
    case 'searchActive': {
      return {
        ...state,
        searchActive: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export function AppShellProvider({ children }: AppShellProviderProps) {
  const accountStatusQuery = useAccountStatus()
  const userLanguage = useSelector(
    (selectorState: ReduxRootState) =>
      selectorState.auth.user.local?.lang?.split('_')?.pop()?.toLowerCase() || ''
  )
  const initialState = {
    countries: [userLanguage],
    country: userLanguage,
    resultType: 'company',
    searchActive: false,
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    const countries = accountStatusQuery.data?.rights.allow_countries?.map(
      (country: string) => country.toLowerCase()
    )

    dispatch({ type: 'countries', payload: countries || [userLanguage] })
  }, [accountStatusQuery.data, userLanguage])

  const handlers = {
    setCountry: (country: string) =>
      dispatch({
        type: 'country',
        payload: country,
      }),
    setResultType: (resultType: ResultType) =>
      dispatch({
        type: 'resultType',
        payload: resultType,
      }),
    setSearchActive: (isActive: boolean) =>
      dispatch({
        type: 'searchActive',
        payload: isActive,
      }),
  }

  const value = Object.assign({}, state, handlers)

  if (value === undefined) {
    throw new Error('A value must be provided to `AppShellProvider`')
  }

  return <AppShellContext.Provider value={value}>{children}</AppShellContext.Provider>
}

export function useAppShell(): AppShellContextValue {
  const ctx = React.useContext(AppShellContext)

  if (ctx != null) {
    return ctx
  }

  throw new Error('`useAppShell` must be used inside of a `AppShellProvider`')
}
