import * as React from 'react'
import { useBuilder } from '../../BuilderContext'
import SideDrawer from 'components-new/layouts/SideDrawer'
import { Stack } from '@mui/material'
import DetailedComponentView from './widgets/DetailedComponentView'
import ActionPanelRuleListWithSearch from './widgets/ActionPanelRuleListWithSearch'
import { Show } from 'components'

const ActionPanel = () => {
  const { state, actions } = useBuilder()
  const handleClose = (open: boolean) => {
    actions.actionPanelToggle({ open })
  }

  return (
    <SideDrawer
      drawerState={{
        drawerIsOpen: state.actionPanelData.open,
        setDrawerIsOpen: (open) => handleClose(open),
      }}
      side="right"
    >
      <Stack sx={{ height: '100%' }} width={405}>
        {state.actionPanelContent === '' ? (
          <ActionPanelRuleListWithSearch handleClose={handleClose} />
        ) : (
          <Show when={state?.nodes?.length > 0}>
            <DetailedComponentView />
          </Show>
        )}
      </Stack>
    </SideDrawer>
  )
}

export default ActionPanel
