import SideDrawer from 'components-new/layouts/SideDrawer'
import { CircularProgress, Divider, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import React, { useEffect, useState } from 'react'
import { useMonitorListPost, useMonitorShowList } from 'services/queries'
import ImportCompaniesFromFile from 'components/ImportCompanies/ImportCompaniesFromFile'
import { ButtonSecondary, DropdownSelect } from 'components-new'
import { evaluateMonitoringList } from '../../../../../services/api'
import { useDispatch } from 'react-redux'
import { submitAddToMonitoringList } from 'store_deprecated'
import { monitorRemoveList } from 'services/api'
import { downloadAs } from '../../../../../services/export'
import { useSnackbar } from 'notistack'
import { ShowListsType } from 'types/queries'
import SidePanelHeader from '../../PolicyBuilder/widgets/ActionPanel/widgets/SidePanelHeader'
import { store } from 'context/store'
import { ModelObject } from 'services/api/helpers/risikaAPI'
import { AllowedModel } from 'globalTypes'

const dowloadAsCSV = downloadAs('csv')!

export type Company = {
  country: string
  id: string
  name?: string
}

export type internalId = {
  internalId: string
  localId: string
}
const TestPolicySidePanel = ({ isOpen, setIsOpen, policyId, country, model }: any) => {
  const [listId, setListId] = useState<string | number>('')
  const [isTempList, setIsTempList] = useState(false)
  const [importValues, setImportValues] = useState<Company[]>([])
  const [internalIds, setInternalIds] = useState<internalId[]>([])
  const [isDownloading, setIsDownloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const modelObject: ModelObject = store.getState().auth.company?.modelObject

  const { data: showListsData } = useMonitorShowList()
  const { mutate: createList, isLoading } = useMonitorListPost()

  const dispatch = useDispatch()

  useEffect(() => {
    const handleCreateList = async (importValueList: any[]) => {
      await createList(
        {
          title: 'temp list',
          ownerApiId: 0,
          users: [],
          isPublic: true,
        },
        {
          onSuccess: (data) => {
            setListId(data.list_id)
            dispatch(
              submitAddToMonitoringList(
                data.list_id,
                importValueList,
                'items-imported',
                'items-failed-to-import'
              )
            )
          },
        }
      )
    }
    if (importValues.length > 0) {
      handleCreateList(importValues).then(() => {
        setImportValues([])
        setIsTempList(true)
      })
    }
  }, [createList, dispatch, importValues, internalIds])

  const convertListData = (
    listData: ShowListsType | undefined = []
  ): { value: number; label: string }[] => {
    return listData.map((item: any) => {
      return {
        value: item.list_id,
        label: item.name,
      }
    })
  }

  const setValues = (importValueList: Company[], internalIdList: internalId[]) => {
    setImportValues(importValueList)
    setInternalIds(internalIdList)
  }

  const handleSubmit = async () => {
    if (listId !== 0 && !listId) return
    setIsDownloading(true)
    try {
      const data = await evaluateMonitoringList({
        listId,
        country,
        policyId,
        model:
          (modelObject?.[country as keyof typeof modelObject] as AllowedModel) ?? 'BOHR',
      })
      const mappedData = data.data.map((item: any) => {
        return {
          companyId: item.local_id,
          name: item.name || 'N/A',
          type: item.company_type || 'N/A',
          outcome: item.details.outcome_name,
          details: item.details.outcome_description,
        }
      })
      const jsonData = JSON.stringify(mappedData)
      dowloadAsCSV('test-policy-results', jsonData)
    } catch (error) {
      enqueueSnackbar(
        <FormattedMessage id={intl.snackbar('report-error-something-wrong')} />,
        { variant: 'error' }
      )
      console.log(error)
    } finally {
      if (isTempList) {
        monitorRemoveList(listId).then(() => {
          setListId('')
        })
      }
      setIsDownloading(false)
      setListId('')
      setIsOpen(false)
    }
  }

  const handleSetListId = (id: string | number) => {
    setListId(id)
    setIsTempList(false)
  }

  const setDrawerIsOpen = (isOpen: boolean) => {
    setListId('')
    setIsOpen(isOpen)
  }

  const isDisabled = (listId: string | number, isLoading: boolean) => {
    if (!listId && listId !== 0) return true
    return +listId === 0 ? false : !listId || isLoading
  }

  return (
    <SideDrawer
      drawerState={{ drawerIsOpen: isOpen, setDrawerIsOpen: setDrawerIsOpen }}
      side="right"
    >
      {/* Header */}
      <Stack width={400} height={1}>
        <SidePanelHeader
          title={<FormattedMessage id={intl.creditPolicyNew('test-policy-title')} />}
          onClose={() => setIsOpen(false)}
        />
        <Divider />

        {/* Description */}
        <Stack p={5} gap={3}>
          <Typography variant="h5">
            <FormattedMessage
              id={intl.creditPolicyNew('select-a-list-to-test-policy-on')}
            />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id={intl.creditPolicyNew('your-policy-will-remain-unpublished')}
            />
          </Typography>
        </Stack>

        {/* Dropdown select */}
        <Stack p={5} gap={3}>
          <Typography variant="subtitle2">
            <FormattedMessage id={intl.creditPolicyNew('test-policy-side-panel-label')} />
          </Typography>
          <DropdownSelect<string | number>
            options={convertListData(showListsData)}
            value={listId}
            setValue={handleSetListId}
          />

          {/* Import area */}
          <Stack>
            {/* <Typography variant="subtitle2">or Upload a new list</Typography> */}
            <ImportCompaniesFromFile
              hideManualEntry={true}
              onSuccess={setValues}
              importValues={importValues}
              setImportValues={setImportValues}
            />
          </Stack>
        </Stack>

        {/* Action button */}
        <Stack sx={{ alignSelf: 'flex-end', marginTop: 'auto !important', padding: 4 }}>
          {isDownloading ? (
            <CircularProgress />
          ) : (
            <ButtonSecondary
              disabled={isDisabled(listId, isLoading)}
              loading={isLoading}
              onClick={handleSubmit}
            >
              <FormattedMessage id={intl.creditPolicyNew('test-and-download-button')} />
            </ButtonSecondary>
          )}
        </Stack>
      </Stack>
    </SideDrawer>
  )
}

export default TestPolicySidePanel
