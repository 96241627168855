import callBackendAPI from 'services/api/helpers/backendAPI'
import { AvailableCountriesLower } from '../../../../globalTypes'
/******************
 * Fetch from API *
 ******************/

/**
 * Load the user's saved filters.
 */

export type ResponseDataUserSettingsFilters = {
  data: {
    filters: {
      name: string
      country: AvailableCountriesLower
      user_id: number
      filters: {
        type: string
        value: {} | null
        filter: string
      }[]
    }[]
  }
}

const loadFilters = () =>
  callBackendAPI('/user/settings/filters').then(
    (res): ResponseDataUserSettingsFilters => {
      return res
    }
  )
export default loadFilters
