import * as React from 'react'
import { Button } from '@mui/material'
import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from './styles/notification-provider'
import { ReactNode } from 'react'

type NotificationProviderProps = {
  children: ReactNode
}

const NotificationProvider = (props: NotificationProviderProps) => {
  const { children } = props
  const classes = useStyles()
  const notistackRef = React.useRef<ReturnType<typeof useSnackbar>>()

  const onClickDismiss = (key: SnackbarKey) => () => {
    if (notistackRef?.current) {
      notistackRef.current.closeSnackbar(key)
    }
  }

  return (
    <SnackbarProvider
      ref={notistackRef as React.Ref<any>}
      maxSnack={1}
      action={(key) => (
        <Button
          className={classes.snackbarButton}
          onClick={onClickDismiss(key)}
          color="secondary"
          size="small"
        >
          <FormattedMessage id={intl.snackbar('dismiss')} />
        </Button>
      )}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={classes.snackbar}
    >
      {children}
    </SnackbarProvider>
  )
}

export default NotificationProvider
