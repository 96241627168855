import risikaAPI from 'services/api/helpers/risikaAPI'
import { AxiosPromise } from 'axios'

export type HighlightsFilters = {
  category: string
  title: string
  value: string
  header: string
}

type HighlightsFiltersResponse = {
  highlights: HighlightsFilters[]
}

export const getHighlightsFilters = (): AxiosPromise<HighlightsFiltersResponse> => {
  return risikaAPI.get(`list/highlights`, { microservice: 'data' })
}
