const defaultPositionAmount = 20

function slideIn(direction = 'top', amount = defaultPositionAmount) {
  const initialPosition = (() => {
    switch (direction) {
      case 'left':
        return { x: -amount }
      case 'right':
        return { x: amount }
      case 'top':
        return { y: -amount }
      case 'bottom':
        return { y: amount }
      default:
        throw new Error('You need to specify a direction')
    }
  })()

  return {
    initial: {
      opacity: 0,
      ...initialPosition,
    },
    animate: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }
}

function fadeIn() {
  return {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  }
}

export const animations = {
  slideIn,
  fadeIn,
}
