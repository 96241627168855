export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedTab: {
    borderBottom: '2px solid',
    borderColor: 'primary.700',
  },
  tabsContainer: {
    marginTop: 2,
    paddingRight: 2,
    justifyContent: 'space-between',
    flex: 1,
    backgroundColor: 'common.white',
  },
}
