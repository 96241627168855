// @flow

import { combineReducers } from 'redux'
import { fork } from 'redux-saga/effects'
import creditCheck from './credit-check/reducer'
import searchBusiness from './search-business'
import settings from './settings/reducer'
// import userManagement from './user-management'
import advancedFilters from './advanced-filters'
import insight from './insight'
import companyHierarchy from './company-hierarchy'
import cache from './cache'
import navbar from './navbar'
import welcome from './welcome'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import permissions from './permissions/reducer'
import newRiskMonitoring from './new-risk-monitoring/reducer'
import filterSuggestions from './filter-suggestions/reducer'
import creditPolicy from './credit-policy/reducer'
import history from './history/reducer'

/****************
 * Import sagas *
 ****************/

import { creditCheckSaga } from './credit-check/sagas'
import authSaga from './auth/sagas'
import permissionsSaga from './permissions/sagas'
import filterSuggestionsSaga from './filter-suggestions/sagas'
import { riskMonitoringSaga } from './new-risk-monitoring/sagas'
import { creditPolicySaga } from './credit-policy/sagas'
import { settingsSaga } from './settings/sagas'

/******************
 * Export Actions *
 ******************/

export * from './auth/actions'
export * from './permissions/actions'
export * from './permissions'
export * from './credit-check/actions'
export * from './new-risk-monitoring/actions'
export * from './search-business'
// export * from './user-management'
export * from './advanced-filters'
export * from './insight'
export * from './company-hierarchy'
export * from './cache'
export * from './navbar'
export * from './welcome'
export * from './credit-policy/actions'
export * from './settings/actions'
export * from './history/actions'

/*********************************
 * Export Risika Redux enhancers *
 *********************************/

export * from './enhancers'

/****************
 * Export sagas *
 ****************/

export * from './settings/sagas'
export * from './credit-check/sagas'
export * from './new-risk-monitoring/sagas'
export * from './credit-policy/sagas'

/**************************
 * Configuration & export *
 **************************/

const monitoringConfig = {
  key: 'newRiskMonitoring',
  storage,
  blacklist: ['lists'],
}

/******************
 * Register Sagas *
 ******************/

// $FlowFixMe (FlowFixYourself ¯\_(ツ)_/¯ )
export function* rootSaga() {
  yield fork(authSaga)
  yield fork(settingsSaga)
  yield fork(creditCheckSaga)
  yield fork(permissionsSaga)
  yield fork(filterSuggestionsSaga)
  yield fork(riskMonitoringSaga)
  yield fork(creditPolicySaga)
}

/*******************
 * Risika Reducers *
 ******************/

const risika = combineReducers({
  permissions,
  creditCheck,
  searchBusiness,
  settings,
  // userManagement,
  advancedFilters,
  insight,
  companyHierarchy,
  newRiskMonitoring: persistReducer(monitoringConfig, newRiskMonitoring),
  creditPolicy,
  filterSuggestions,
  cache,
  navbar,
  welcome,
  history,
})

const persistConfig = {
  key: 'risika',
  storage,
  blacklist: ['newRiskMonitoring', 'creditPolicy'],
}

// $FlowFixMe (FlowFixYourself)
const persistedReducer = persistReducer(persistConfig, risika)

export default persistedReducer
