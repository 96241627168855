import { Stack, Typography, Checkbox } from '@mui/material'

const Option = ({
  checked,
  onChange,
  value,
  label,
  indeterminate,
  className,
  color = 'default',
  ...props
}) => {
  return (
    <Stack direction="row" alignItems="center">
      <Checkbox
        sx={{ padding: '11px !important' }}
        indeterminate={indeterminate}
        checked={checked}
        onChange={onChange}
        value={value}
        color={color}
      />
      <Typography variant="body1">{label}</Typography>
    </Stack>
  )
}

export default Option
