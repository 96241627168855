import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { CompanyHierarchy } from 'types/general'

function attachLevel(data: CompanyHierarchy[], number = 1): CompanyHierarchy[] {
  return data.map((x) => ({
    ...x,
    children: attachLevel(x.children ?? [], number + 1),
    level: number,
  }))
}

async function companyHierarchyWalkdown({
  id,
  country = 'dk',
  maxDepth,
}: LocalId & { maxDepth: number }): Promise<CompanyHierarchy> {
  const { data }: { data: CompanyHierarchy } = await risikaAPI.get(
    `/company/hierarchy/walkdown/${id}?max_level=${maxDepth}`,
    {
      microservice: 'data',
      country,
      appendScoringModel: true,
      appendCurrencyURL: true,
    }
  )
  return {
    ...data,
    children: attachLevel(data?.children ?? []),
    level: 0,
  }
}

export default companyHierarchyWalkdown
