import { Stack } from '@mui/material'
import { HeaderCellWrapperProps } from '../../UserTable.types'

const HeaderCellWrapper = ({ children }: HeaderCellWrapperProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      height={46}
      paddingX={6}
      paddingY={4}
    >
      {children}
    </Stack>
  )
}

export default HeaderCellWrapper
