import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import StackedAreaChart from 'components-new/charts/StackedAreaChart'
import { convertTimelineToNameAndValues } from 'utils-new/api-to-chart-data-converters'
import { timelineData } from './ListCreditScoreOverTimeBox.model'
import { useMonitoringStatistic } from 'services/queries'
import { Monitor } from 'risika-api-response-types'

const ListCreditScoreOverTimeBox = ({ listId }: { listId: string }) => {
  timelineData.parameters.list_id = Number(listId)
  timelineData.chartProperties.tooltipWithPercents = true

  const timelineQuery = useMonitoringStatistic<Monitor.ListStatisticsTimeline>({
    parameters: timelineData.parameters,
    endpoint: timelineData.endPoint,
  })
  const hasValues = timelineQuery.data?.some((item) => item?.values?.length > 0)
  const boxData = {
    title: timelineData.chartProperties.title,
    status: {
      isLoading: timelineQuery.isLoading,
      isError: timelineQuery.isError,
      isIdle: timelineQuery.isIdle,
      isNoData: !timelineQuery.data?.length || !hasValues,
    },
    height: timelineData.chartProperties.height,
    skeletonType: 'HORIZONTAL_BAR',
  }

  return (
    <BoxContainer boxData={boxData}>
      <Box data-cy="list-credit-score-over-time-chart" m={2} pt={1}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <StackedAreaChart
            data={convertTimelineToNameAndValues(timelineQuery.data!)}
            chartProperties={timelineData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListCreditScoreOverTimeBox
