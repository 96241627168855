import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts'
import {
  CustomLegendWithPadding,
  CustomTooltipWithCreditScore,
  getCellColor,
} from '../customizations'
import { SimpleBarChartPropsType } from '../types'
import colors, { muiTheme } from 'configs/colors'
import { useIntl } from 'react-intl'
import intl from 'localization/components'

const SimpleBarChart = ({ data, chartProperties }: SimpleBarChartPropsType) => {
  // This should prevent ReCharts bug of not displaying top labels
  // by preventing the animation of the chart
  // https://github.com/recharts/recharts/issues/829
  const animationActive = !chartProperties.bar.label

  const reactIntl = useIntl()

  if (!data.length) {
    return null
  }

  const messages = reactIntl.messages
  const creditScoreText = messages[intl.observationalLists('credit-score')]

  const barKeys = data.length ? Object.keys(data[0]).filter((key) => key !== 'name') : []

  const CustomizedLabel = (props: any) => {
    const { x, y, fill, value } = props
    let xPosition = x
    // if credit score is whole number position
    // the label in the middle of the cell
    if (props.barKey === creditScoreText && value % 1 === 0) {
      xPosition = x + 4
    }

    return (
      <text x={xPosition} y={y} dy={-4} fontSize="14" fontFamily="sans-serif" fill={fill}>
        {props.barKey !== creditScoreText ? `${value}%` : value}
      </text>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={chartProperties.height}>
      <BarChart
        data={data}
        margin={chartProperties.margin}
        barGap={chartProperties.barGap}
      >
        {chartProperties.cartesianGrid && (
          <CartesianGrid strokeDasharray={chartProperties.strokeDasharray} />
        )}
        {chartProperties.xAxis && <XAxis dataKey={chartProperties.dataKey} />}
        {chartProperties.yAxis && <YAxis />}
        {chartProperties.tooltip && (
          <Tooltip
            content={
              <CustomTooltipWithCreditScore chartColors={chartProperties.colors} />
            }
            cursor={{ fill: muiTheme.colors.grey[200] }}
          />
        )}
        {chartProperties.legend && <Legend content={CustomLegendWithPadding} />}
        {barKeys.map((barKey, index) => {
          return (
            <Bar
              key={barKey}
              isAnimationActive={animationActive}
              dataKey={barKey}
              fill={
                barKey === creditScoreText
                  ? colors.riskIntelligence.mediumRisk
                  : chartProperties.colors[index]
              }
              label={<CustomizedLabel barKey={barKey} />}
            >
              {data.map((entry) => (
                <Cell
                  key={barKey + index}
                  fill={getCellColor(
                    entry,
                    barKey,
                    creditScoreText as string,
                    index,
                    chartProperties.colors
                  )}
                />
              ))}
            </Bar>
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SimpleBarChart
