import callBackendAPI from 'services/api/helpers/backendAPI'
import { CustomerSettingsFieldNameT } from 'types/queries'

const customerSettingsField = (field: CustomerSettingsFieldNameT) =>
  callBackendAPI(`/customer/settings/${field}`)
    .then(({ data }) => {
      return data
    })
    .catch((err) => {
      return err
    })

export default customerSettingsField
