import React from 'react'
import { useDebounce } from 'react-use'

const scoreOptions = {
  ALL: 'all',
  HIGH: 'high-risk',
  MEDIUM: 'medium-risk',
  LOW: 'low-risk',
  NO_SCORE: 'no-score',
}

/**
 * Filter the specified column by selecting a group.
 *
 * @param {React.Component<{value, onchange}>} InputComponent The InputComponent to use as input.
 * @param {array} preFilteredRows The rows before the filtering was applied.marked
 * @param {callback} setFilter Callback that sets the filter.
 */
const ScoreFilter = (props) => {
  const {
    InputComponent,
    debounceTime = 300,
    column: { preFilteredRows, setFilter },
  } = props
  const count = preFilteredRows.length
  const [debounceValue, setDebounceValue] = React.useState(scoreOptions.ALL)

  const getValuesForScoreGroups = (score) => {
    switch (score) {
      case scoreOptions.ALL:
        return []
      case scoreOptions.HIGH:
        return ['HIGH']
      case scoreOptions.MEDIUM:
        return ['MEDIUM']
      case scoreOptions.LOW:
        return ['LOW']
      default:
        return [null]
    }
  }

  useDebounce(
    () => {
      setFilter({
        name: debounceValue,
        values: getValuesForScoreGroups(debounceValue),
      })
    },
    debounceTime,
    [debounceValue]
  )

  return (
    <InputComponent
      data={{ count }}
      value={debounceValue ?? scoreOptions.ALL}
      values={Object.values(scoreOptions)}
      onChange={(e) => {
        setDebounceValue(e.target.value ?? '')
      }}
    />
  )
}

export default ScoreFilter
