import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

const RadialHalfCircleSkeleton = () => {
  return (
    <Box
      height={100}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        m: 2,
      }}
    >
      <Skeleton variant="circular" width={100} height={100} />
    </Box>
  )
}

export default RadialHalfCircleSkeleton
