import { store } from 'context/store'

/**
 * Create a filter from the data that got passed.
 *
 * @param {object} data The data that should be mapped to a filter
 * @returns {object} The filter object.
 *
 */
export const createFilters = (data) => {
  const {
    searchText,
    companyType: type = [],
    employees = [],
    equity: eq = {},
    grades = [],
    industryGroup = [],
    subIndustry = [],
    specificIndustry = [],
    regions = [],
    municipalities = [],
    showInactive,
    hideNoToAdvertisement,
    searchCountry,
  } = data

  let industry = []
  if (industryGroup.length || subIndustry.length || specificIndustry.length) {
    industry = [
      ...(!subIndustry.length && !specificIndustry.length ? industryGroup : []),
      ...(!specificIndustry.length ? subIndustry.map((value) => Number(value)) : []),
      ...specificIndustry.map((value) => Number(value)),
    ]
  }

  const { options_for_regions } = store.getState().risika.settings

  let zipcodes = []
  if (regions.length && !municipalities.length) {
    zipcodes = options_for_regions
      .filter((group) => regions.find((value) => value === group.value))
      .map(({ json }) => JSON.parse(json))
      .reduce((acc, list) => [...acc, ...list], [])
  }
  const handleSearchText = (text) => {
    if (searchCountry?.toLowerCase() === 'se') {
      // Does the string start with SE has 10 numbers and ends with 01
      const expression = new RegExp(/^([Ss][Ee]\d{10}[0][1])$/, '')
      if (expression.test(text)) {
        return text ? text.substring(0, text.length - 2) : ''
      }
    }
    return text
  }

  const filters = {}
  if (searchText) filters.free_search = handleSearchText(searchText)
  if (grades.length) filters.score = grades
  if (type.length) filters.company_type = type
  if (employees.length) filters.employees = employees
  if (eq.value > 0) filters.equity = { operator: eq.operator, value: +eq.value }
  if (industry.length) filters.industry = industry
  if (zipcodes.length) filters.zipcode = zipcodes
  if (municipalities.length) filters.municipality = municipalities
  if (!showInactive) filters.active = true
  if (hideNoToAdvertisement) filters.advertising = false

  return filters
}
