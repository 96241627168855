const appBar = {
  backgroundColor: 'white',
  zIndex: 0,
}
const tabs = (tabIndex: number) => ({
  color: 'black',
  minHeight: '48px',
  marginTop: 2,
  marginBottom: tabIndex !== 1 ? 4 : 0,
  ' .MuiTabs-scroller': { overflow: 'scroll !important' },
})

const tab = {
  '&:hover': {
    textDecoration: 'none !important',
  },
}

export default {
  appBar,
  tabs,
  tab,
}
