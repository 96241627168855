import { useRef, useState, useEffect } from 'react'
import D3FinancialRatioChart from './D3FinancialRatioChart'
import { injectIntl, FormattedMessage, useIntl } from 'react-intl'
import { Stack, Typography } from '@mui/material'
import intl from 'localization/components'
import styles from './styles'
import FinancialRatioChartData from './widgets/FinancialRatioChartData'

const FinancialRatioChart = ({ selectedItem, fieldKey, performances, data }) => {
  const [d3Chart, setD3Chart] = useState(null)
  const { messages } = useIntl()
  const chartRef = useRef()

  useEffect(() => {
    if (!d3Chart) {
      setD3Chart(
        new D3FinancialRatioChart(
          chartRef.current,
          data,
          selectedItem,
          fieldKey,
          messages
        )
      )
    }
  }, [d3Chart, data, fieldKey, messages, selectedItem])

  return (
    <Stack sx={styles.stack} ref={chartRef}>
      <Typography variant="subtitle1" sx={styles.typography}>
        <FormattedMessage id={intl.relevantFinancialRatios(fieldKey)} />
      </Typography>
      <FinancialRatioChartData
        data={data}
        fieldKey={fieldKey}
        performances={performances}
      />
    </Stack>
  )
}

export default injectIntl(FinancialRatioChart)
