import { DOMAIN as _ } from 'configs/constants/system'

/**************
 * Saga calls *
 **************/
export const SUBMIT_GET_COMPANY_INFORMATION = `${_} SUBMIT_GET_COMPANY_INFORMATION`
export const SUBMIT_GET_ALL_USERS = `${_} SUBMIT_GET_ALL_USERS`

/****************
 * Action calls *
 ****************/
export const SHOW_HIDE_TABLE_FIELD = `${_} SHOW_HIDE_TABLE_FIELD`
export const STORE_SETTING = `${_} STORE_SETTING`
export const SET_USERS_STATUS = `${_} SET_USERS_STATUS`
export const SET_USERS_SUCCESS = `${_} SET_USERS_SUCCESS`
