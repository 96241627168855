import * as React from 'react'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_COMPANY':
      return {
        ...state,
        selectedCompany: {
          localId: action.value.localId,
          name: action.value.name,
        },
      }
    default:
      return state
  }
}

const initialState = {
  selectedCompany: '',
}

const Context = React.createContext()

export const SelectedCompanyProvider = ({ children, defaultState }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...defaultState,
    initialState,
  })
  const value = {
    selectedCompany: state.selectedCompany,
    setSelectedCompany: (value) => {
      dispatch({ type: 'SET_SELECTED_COMPANY', value })
    },
  }
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useSelectedCompanyContext = () => {
  const ctx = React.useContext(Context)

  if (ctx != null) {
    return ctx
  }

  throw new Error(
    '`useCompanyHiearchy` must be used inside of a `CompanyHierarchyProvider`'
  )
}
