import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: '0%',
    width: '100%',
    zIndex: 10,
  },
  progress: {
    color: '#00695c',
  },
  linearColorPrimary: {
    backgroundColor: 'transparent',
  },
  linearBarColorPrimary: {
    backgroundColor: theme.palette.secondary.main,
  },
  placeholder: {
    height: 5,
    backgroundColor: 'transparent',
  },
}))
