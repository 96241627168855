import { ReactNode, useEffect, useState } from 'react'
import { RouteConfig, matchRoutes } from 'react-router-config'
import { head, intersection } from 'ramda'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getUserRequest } from 'store_deprecated'
import { CustomLoader } from 'components/loaders/Loader'
import { ReduxRootState } from 'store_deprecated/types'

type AuthorizationProps = {
  children: ReactNode
  routes: RouteConfig[]
}

const Authorization = ({ children, routes }: AuthorizationProps) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(
    (state: ReduxRootState) => state.auth.login.authenticated
  )
  const userRoles = useSelector((state: ReduxRootState) => state.auth.user.roles)
  const userId = useSelector((state: ReduxRootState) => state.auth.user.data.id)
  const location = useLocation()
  const history = useHistory()
  const [hasCheckedUser, setHasCheckedUser] = useState(false)

  useEffect(() => {
    const matched = head(matchRoutes(routes, location.pathname))

    if (matched && matched.route.auth && matched.route.auth.length > 0) {
      if (isAuthenticated && (userRoles?.length === 0 || userRoles == null)) {
        dispatch(getUserRequest(userId))
      } else {
        if (!intersection(matched.route.auth, userRoles ?? [])?.length) {
          history.push(`/auth/login/${location.search}`)
        }
      }
    }

    setHasCheckedUser(true)
  }, [location, userRoles, dispatch, history, isAuthenticated, routes, userId])

  if (!hasCheckedUser) return <CustomLoader />

  return <>{children}</>
}

export default Authorization
