import { authRoles } from 'components/auth'
import CompanyComparisonPage from './CompanyComparisonPage'
import { navigation } from 'services/navigation'
export const CompanyComparisonPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: `${navigation.companyComparison()}/:country/:id`,
      component: CompanyComparisonPage,
    },
    {
      path: navigation.companyComparison(),
      component: CompanyComparisonPage,
    },
  ],
}
