import { motion } from 'framer-motion'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Stack, Button } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { CurrencyInput, FlagInput } from 'components-new'
import { useStyles } from './ChangePasswordFlow.styles'

type Props = {
  isOpen: boolean
  handleNextStep: () => void
}

export default function ChooseLanguageAndCurrency({ isOpen, handleNextStep }: Props) {
  const classes = useStyles()

  const buttonProps = {
    onClick: handleNextStep,
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
  } as const

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <motion.div
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        initial="visible"
        animate={isOpen ? 'visible' : 'hidden'}
        className="here"
      >
        <DialogTitle className={classes.title}>
          <FormattedMessage id={intl.firstTimeVisit('preferences')} />
        </DialogTitle>
        <DialogTitle className={classes.text}>
          <DialogContentText>
            <FormattedMessage id={intl.firstTimeVisit('description')} />
          </DialogContentText>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Stack
            direction="row"
            justify="space-evenly"
            items="center"
            style={{ height: '70px', marginBottom: '30px' }}
          >
            <FlagInput />
            <CurrencyInput />
          </Stack>
          <Button {...buttonProps}>
            <FormattedMessage id={intl.firstTimeVisit('submit')} />
          </Button>
        </DialogContent>
      </motion.div>
    </Dialog>
  )
}
