import React from 'react'
import { Modal } from '@mui/material'
import PlanRestrictionModalCanvas from './PlanRestrictionModalCanvas'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'

const PlanRestrictionModal = () => {
  const { isRestrictionModalOpen: isOpen, setIsRestrictionModalOpen: setIsOpen } =
    useCountryPolicy()

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} closeAfterTransition>
      <PlanRestrictionModalCanvas closeFn={() => setIsOpen(false)} />
    </Modal>
  )
}

export default PlanRestrictionModal
