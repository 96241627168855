import { defaultStackedBarChartProperties } from 'configs/charts'
import intl from 'localization/components'

export const employeesData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [{ category: 'RISK_ASSESSMENT', function: 'COUNT' }],
    group_by: [
      { category: 'EMPLOYEES', buckets: [0, 5, 10, 100, 250, 500, 1000] },
      { category: 'RISK_ASSESSMENT' },
    ],
  },
  chartProperties: {
    ...defaultStackedBarChartProperties,
    cartesianGrid: false,
    title: intl.observationalLists('risika-score-employees-title'),
    height: 390,
    valueType: 'RISK',
    bar: {
      label: true,
      labelEmployees: true,
    },
  },
}

export type EmployeesData = typeof employeesData
