import { Typography } from '@mui/material'

const TextCell = ({ cell, sx }) => {
  return (
    <Typography variant="body2" sx={sx}>
      {cell.value}
    </Typography>
  )
}

export default TextCell
