import { AxiosRequestConfig } from 'axios'
import { ModelObject } from '.'
import { getScoringModel } from './getModel'
import getUserSettings from 'services/api/endpoints/backendAPI/getUserSettings'
import { getAccountStatusData } from 'utils/getAccountStatusData'

type buildTypes = {
  modelObject: ModelObject
  model: string
  appendScoringModel?: boolean
  appendCurrency?: boolean
}

/**
 *  Adds properties to the DATA object of the requests. Attach either the scoring model (BOHR | NEWTON) using the boolean values bellow
 *
 * @param config AxiosRequestConfig. This is the request object provided by Axios along with some modifications described in the type declaration
 * @param modelObject Either BOHR or NEWTON for each country supported by the customer
 * @param model This is also either BOHR or NEWTON, but in the legacy format and it should be phased out at some point
 * @param appendScoringModel Appends the scoring model (BOHR | NEWTON) to the DATA object. Can be invoked in the parent function using appendScoringModelData
 * @param appendCurrency Appends the user selected currency to the DATA object. Can be invoked in the parent function using appendCurrencyData
 * @returns
 */

export const buildData = async (
  config: AxiosRequestConfig,
  { modelObject, model, appendScoringModel = false, appendCurrency = false }: buildTypes
): Promise<any> => {
  const { data } = config
  let newData = data
  if (appendScoringModel) {
    const status = getAccountStatusData()
    newData = {
      ...newData,
      score_model: await getScoringModel(status),
    }
  }
  if (appendCurrency) {
    const userSettings = await getUserSettings()
    const currency = userSettings?.selectedCurrency?.currency ?? 'DKK'
    newData = {
      ...newData,
      currency,
    }
  }
  return newData
}
