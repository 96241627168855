import { defaultMapChartProperties } from 'configs/charts'

import intl from 'localization/components'

export const geographicalRisikaScoreData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: { DK: 'NEWTON', SE: 'NEWTON', NO: 'NEWTON', UK: 'BOHR' },
    group_by: [{ category: 'COUNTRY' }, { category: 'RISK_ASSESSMENT' }],
    categories: [{ category: 'RISK_ASSESSMENT', function: 'COUNT' }],
  },
  percentageParameters: {
    list_id: 0,
    score_model: {},
    categories: [{ category: 'RISK_ASSESSMENT', function: 'PERCENTAGE' }],
    group_by: [{ category: 'COUNTRY' }],
  },
  chartProperties: {
    ...defaultMapChartProperties,
    title: intl.observationalLists('geographical-risika-score-title'),
    height: 600,
  },
}

export const mapLevelLimit = 2
