import risikaAPI from 'services/api/helpers/risikaAPI'

import { AvailableCountries, AvailableCountriesInScoringModels } from 'globalTypes'

type Caps = {
  credit_policy: { exclude_companies: number; exclude_persons: number }
  credit_ratings: number
  credit_reports: number
  lookups: number
  monitoring_count: number
  monitoring_exports: number
  search_exports: number
}

type Counters = {
  lookups: number
  credit_ratings: number
  credit_reports: number
  search_exports: number
  monitoring_exports: number
  monitoring_count: number
  credit_policy: {
    DK: {
      exclude_companies?: number
      exclude_persons?: number
    }
    NO: {
      exclude_companies?: number
      exclude_persons?: number
    }
    SE: {
      exclude_companies?: number
      exclude_persons?: number
    }
  }
}

export type LegacyRights = {
  allow_api: boolean
  allow_basic: boolean
  allow_browser_extension: boolean
  allow_company_export: boolean
  allow_company_search: boolean
  allow_countries: AvailableCountries[]
  allow_credit: boolean
  allow_credit_policy: string[]
  allow_ean: boolean
  allow_financial: boolean
  allow_hierarchy: boolean
  allow_highlights: boolean
  allow_monitoring: boolean
  allow_monitoring_export: boolean
  allow_notes: boolean
  allow_pdf_reports: boolean
  allow_person_export: boolean
  allow_person_search: boolean
  allow_relations: boolean
  search_exports_limit: number
  tier: number
}

export type ArrayLegacyRight = Pick<
  LegacyRights,
  'allow_countries' | 'allow_credit_policy'
>

export type ScoreModel = {
  model: 'BOHR' | 'NEWTON'
  explanation?: boolean
}

type AllowModels = {
  country: 'DK' | 'SE' | 'NO' | 'UK' | 'FI'
  score_models: ScoreModel[]
}

export type Rights = {
  DK: { score_models: ScoreModel[] }
  NO: { score_models: ScoreModel[] }
  SE: { score_models: ScoreModel[] }
  UK: { score_models: ScoreModel[] }
  FI: { score_models: ScoreModel[] }
  allow_api: boolean
  allow_basic: boolean
  allow_browser_extension: boolean
  allow_company_export: boolean
  allow_company_search: boolean
  allow_countries: AvailableCountries[]
  allow_credit: boolean
  allow_credit_policy: string[]
  allow_ean: boolean
  allow_financial: boolean
  allow_hierarchy: boolean
  allow_highlights: boolean
  allow_models: AllowModels[]
  allow_monitoring: boolean
  allow_monitoring_export: boolean
  allow_notes: boolean
  allow_pdf_reports: boolean
  allow_person_export: boolean
  allow_person_search: boolean
  allow_relations: boolean
  global?: { observational_lists?: boolean; enterprise_credit_policy?: boolean }
  allow_risika_score?: boolean
  search_exports_limit: number
}

export type SubscriptionPlan =
  | 'FREEMIUM'
  | 'BASIC'
  | 'STANDARD'
  | 'PRO'
  | 'ENTERPRISE'
  | 'TRIAL'

export type AccountStatusResponse = {
  caps: Caps
  counters: Counters
  legacy_rights: LegacyRights
  max_users: number
  rights: Rights
  subscription_plan: SubscriptionPlan
  credit_override_config?: {
    app_name?: string
  }
}

function formatAllowedModels(
  rights: Rights,
  allowedCountries: AvailableCountries[]
): AllowModels[] {
  return allowedCountries
    .map((item) => {
      const country = <AvailableCountriesInScoringModels>item.toUpperCase()
      const score_models = rights[country]?.score_models
      return { country, score_models }
    })
    .filter((x) => x.score_models?.length)
}

type ResponseData = {
  data: AccountStatusResponse
}

async function accountStatus(): Promise<AccountStatusResponse> {
  const { data }: ResponseData = await risikaAPI.get(`/status`, {
    microservice: 'account',
  })

  const allow_countries = data.legacy_rights.allow_countries.map(
    (x) => <AvailableCountries>x.toLowerCase()
  )
  return {
    ...data,
    // Temporary fix until the Beta "restriction" API is complete [START]
    rights: {
      ...data.rights,
      ...data.legacy_rights,
      allow_countries,
      allow_models: formatAllowedModels(data.rights, allow_countries),
    },
    // Temporary fix until the Beta "restriction" API is complete [END]
    /* ORIGINAL CODE [START]
      rights: {
        ...data.rights,
        allow_countries: data.rights.allow_countries.map((x) =>
          x?.toLowerCase(),
        ),
      },
      ORIGINAL CODE [END] */
  } as AccountStatusResponse
}

export default accountStatus
