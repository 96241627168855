import { Box, Collapse } from '@mui/material'
import { ConditionallyRender, Show } from 'components'
import { classes } from '../HierarchyListView.styles'
import { ConnectingLinesProps } from '../HierarchyListView.types'

const OrdinaryChild = () => {
  return (
    <>
      <Box sx={{ ...classes.centerLine }} />
      <Box sx={{ ...classes.verticalLine }} />
    </>
  )
}
const LastChild = () => {
  return <Box sx={{ ...classes.lLine }} />
}

const TinyLineUnderIcon = () => {
  return <Box sx={{ ...classes.tinyLine }} />
}

function ConnectingLines({ row, isLastChild, open }: ConnectingLinesProps) {
  const hasChildren = !!row?.children?.length
  return (
    <>
      <Show when={hasChildren}>
        <Collapse in={open}>
          <TinyLineUnderIcon />
        </Collapse>
      </Show>
      <Show when={row.level > 0}>
        <ConditionallyRender
          condition={isLastChild}
          when={<LastChild />}
          otherwise={<OrdinaryChild />}
        />
      </Show>
    </>
  )
}

export default ConnectingLines
