import React, { ReactNode } from 'react'
import * as Sentry from '@sentry/browser'
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDizzy } from '@fortawesome/free-regular-svg-icons'
import { faRocket } from '@fortawesome/free-solid-svg-icons'
import Stack from './layout/Stack'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { withRouter } from 'react-router-dom'
import { CompanyBasicsResponse } from 'services/api/endpoints/risikaAPI/companyBasics'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { AuthUserResponse, UserRole } from 'services/api/flow-typed'
import { Context } from 'vm'
import { Button } from 'components'
import { History } from 'history'

/**
 * The error boundary will show the user "Custom message" saying that their issue is received and we are working on fixing it
 * The more data you pass to the component the easier it will be for us to take it from sentry and fix it
 *
 * Params:
 *
 * @param {Object} user User details, name, email, id, roles
 * @param {Object} company Company details, name, companyId, localId, country, customerType, model etc..
 * @param {string} subscriptionPlan The plan of said company
 *
 * @param {string} page the page the boundary is in or surrounds
 * @param {string} component the component the boundary surrounds
 * @param {Object} localId {id, country} the page's local organization id
 *
 */

type SimpleUserProps = {
  data: AuthUserResponse
  local: { lang: string }
  roles: UserRole[]
}

type ErrorBoundaryProps = {
  children: ReactNode
  user: AuthUserResponse
  company: CompanyBasicsResponse
  subscriptionPlan: string
  page: string
  component: ReactNode
  localId: number
  state: ErrorBoundaryState
  history: History
}

type ErrorBoundaryState = {
  hasError: boolean
  errorMessage: string
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  user: AuthUserResponse

  company: CompanyBasicsResponse

  subscriptionPlan: string

  page: string

  component: ReactNode

  localId: number

  constructor(props: ErrorBoundaryProps) {
    super(props)
    // User information
    this.user = props.user
    this.company = props.company
    this.subscriptionPlan = props.subscriptionPlan

    // Where did the error happen
    this.page = props.page
    this.component = props.component
    this.localId = props.localId
    this.state = { hasError: false, errorMessage: '' }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.toString() }
  }

  componentDidCatch(error: Error, { componentStack }: { componentStack: any }) {
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      Sentry.captureException(error, {
        contexts: {
          company: this.company as Context,
          user: this.user,
          localId: this.localId as unknown as Context,
          subscriptionPlan: this.subscriptionPlan as unknown as Context,
          page: this.page as unknown as Context,
          component: this.component as Context,
          stack: componentStack,
        },
      })
    }
  }

  handleOnClick = () => {
    this.props.history.push('/home')
  }

  render() {
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      return (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          m={3}
          marginTop={'30vh'}
        >
          <FontAwesomeIcon
            style={{ width: '10rem', height: '10rem' }}
            icon={faDizzy as IconProp}
          />
          <Typography variant="h2">
            <FormattedMessage id={intl.snackbar('report-error-something-wrong')} />
          </Typography>
          <Box display="flex" flexDirection="column" m={2}>
            <Typography>
              <FormattedMessage id={intl.creditCheck('boundary-line-1')} />
            </Typography>
            <Typography>
              <FormattedMessage id={intl.creditCheck('boundary-line-2')} />
            </Typography>
            <Stack items="center" spacing={1}>
              <Typography>
                <FormattedMessage id={intl.creditCheck('boundary-line-3')} />
              </Typography>
              <FontAwesomeIcon icon={faRocket as IconProp} />
            </Stack>
          </Box>
          <Button
            onClick={this.handleOnClick}
            style={{ padding: '2rem', width: '10rem' }}
            // @ts-expect-error This is some old button. We should replace it
            color="contrast"
          >
            <FormattedMessage id={intl.history('home')} />
          </Button>
        </Box>
      )
    }

    return this.props.children as JSX.Element
  }
}

const formatUser = (user: SimpleUserProps) => {
  const data = user?.data
  const local = user?.local
  const roles = user?.roles

  return {
    id: data?.id,
    email: data?.email,
    phone: data?.phone_number,
    name: `${data?.first_name} ${data?.last_name}`,
    platformLanguage: local?.lang,
    roles,
  }
}

const mapStateToProps = (state: any) => {
  const { user, company } = state.auth
  return { user: formatUser(user), company }
}

// this typescript error is about 12 layers deep, hence the 'any'
// if anyone would like to give it a go please go ahead
export default withRouter(connect(mapStateToProps)(ErrorBoundary as any))
