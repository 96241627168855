import { AdvancedPolicySetT } from 'services/api/endpoints/risikaAPI/setSingleCreditPolicy'

export const DefPolicyModel: AdvancedPolicySetT = {
  age_limit: null,
  bankruptcy_limit: null,
  bankruptcy_limit_long: null,
  distance_to_ceo: null,
  exclude_companies: [],
  exclude_company_types: [],
  exclude_industries: [],
  exclude_level_of_auditor_assistances: [],
  exclude_persons: [],
  exclude_risika_scores: [],
  exclude_vat: [],
  negative_equity_limit: null,
  phone_number_registry: null,
  prepaid_phone_number: null,
  payment_remarks_count: null,
  payment_remarks_creditors: null,
  payment_remarks_total_amount: null,
  payment_remarks_max_repayment_time: null,
  debt: null,
  short_term_debt: null,
  profit_loss: null,
  auditor_has_id: null,
  number_of_employees: null,
}

export const countryMap = {
  DK: 'DENMARK',
  SE: 'SWEDEN',
  NO: 'NORWAY',
  FI: 'FINLAND',
}
