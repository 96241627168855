import { Stack, TextareaAutosize, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { inputAreaStyles } from './InputArea.styles'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { InputAreaProps } from './InputArea.types'

const InputArea = (props: InputAreaProps) => {
  const {
    value,
    setValue,
    label,
    placeholder = '',
    disabled = false,
    error = false,
    errorMessage = '',
    maxCharacters = 200,
    extraStyles = {},
    ...extraProps
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [maxCharAlert, setMaxCharAlert] = useState('')

  const isError = !!error || !!maxCharAlert

  const styles = inputAreaStyles(disabled, isError, isFocused, isHovered, extraStyles)

  const handleChange = (event: { target: { value: string } }) => {
    const val = event.target.value
    if (value.length >= maxCharacters) {
      if (val < value) {
        setValue(val)
        return
      }
    }
    if (setValue) {
      if (maxCharAlert) setMaxCharAlert('')
      setValue(val)
    }
  }

  useEffect(() => {
    if (value?.length > maxCharacters) {
      setMaxCharAlert(`Max ${maxCharacters} characters`)
    }
    if (!!maxCharAlert && value?.length <= maxCharacters) {
      setMaxCharAlert('')
    }
  }, [maxCharacters, setValue, value, maxCharAlert])

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleFocusOut = () => {
    setIsFocused(false)
  }

  return (
    <Stack gap={1}>
      {label && <Typography variant="subtitle2">{label}</Typography>}
      {/* @ts-ignore */}
      <TextareaAutosize
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        style={styles}
        disabled={disabled}
        onFocus={() => handleFocus()}
        onBlur={() => handleFocusOut()}
        {...extraProps}
      />
      <Typography
        sx={{ color: 'grey.600' }}
        variant="body2"
        data-cy="description-character-count"
      >{`${value?.length}/${maxCharacters} characters`}</Typography>
      {(errorMessage || maxCharAlert) && (
        <Stack
          sx={{ color: 'error.700' }}
          mt={1}
          gap={1}
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <ErrorOutlineIcon fontSize="large" />
          <Typography color="inherit" variant="body2">
            {maxCharAlert || errorMessage}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default InputArea
