import { useQuery } from 'react-query'
import { getUserSettings } from 'services/api'
import { useDispatch } from 'react-redux'
import { setDashboardLang } from 'store_deprecated'

export function useUserSettings() {
  const dispatch = useDispatch()

  return useQuery('getUserSettings', () => getUserSettings(), {
    onSuccess: (res) => {
      dispatch(setDashboardLang(res?.language ?? 'en_UK'))
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}
