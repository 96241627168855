import { Typography } from '@mui/material'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { LocalIdInMonitoringListCategory } from 'types/queries'
import { useMonitorShowList } from 'services/queries'

const CompanyListContent = ({ data }: { data: LocalIdInMonitoringListCategory }) => {
  const showListsQuery = useMonitorShowList()
  const { list_id } = data
  const listName = showListsQuery.data?.find((list) => list.list_id === +list_id)?.name

  return listName ? (
    <Typography variant="body2">{listName}</Typography>
  ) : (
    <FormattedMessage id={intl.creditPolicyNew('no-data-yet')} />
  )
}

export default CompanyListContent
