// import { newtonAllowedCounties } from 'configs'
import { countries } from 'configs/constants/system'

/**
 * This function receives country ('DK', 'SE', etc.) and model ('NEWTON', 'BOHR', etc.)
 * as parameters then returns string representing allowed model for that country.
 *
 * @param {string} country Country ('DK', 'SE', etc.)
 * @param {string} model Model ('NEWTON', 'BOHR', etc.)
 * @returns {any} String representing allowed model for that country
 */
// export const scoreModel = (country: string, model: string) => {
//   return newtonAllowedCounties.includes(country.toLowerCase())
//     ? model ?? 'BOHR'
//     : 'BOHR'
// }

type scoreModelTypes = {
  country: string
  modelOLD: string
  modelObject: any
}
export const scoreModel = ({ country, modelOLD, modelObject }: scoreModelTypes) => {
  if (modelObject?.[country.toUpperCase()]) {
    return modelObject?.[country.toUpperCase()]
  }
  return ['dk', 'no'].includes(country.toLowerCase()) ? modelOLD ?? 'BOHR' : 'BOHR'
}

/**
 * This function receives default model ('NEWTON', 'BOHR', etc.) as a parameter
 * then returns object with countries (as keys) and possible (allowed) model
 * for each country as value. For example:
 * { DK: 'NEWTON', SE: 'BOHR', NO: 'NEWTON' }
 *
 * @param {string} model Model ('NEWTON', 'BOHR', etc.)
 * @returns {any} Object with countries (as keys) and possible (allowed) model
 * for each country as value.
 */
// export const countriesScoreModel = (model: string) => {
//   return countries.reduce((accumulator, country): {} => {
//     const countryScoreModel = scoreModel(country, model)
//     return {
//       ...accumulator,
//       [country]: countryScoreModel,
//     }
//   }, {})
// }

type countriesScoreModelTypes = {
  modelOLD: string
  modelObject: any
  rights: any
}

export const countriesScoreModel = ({
  modelOLD,
  modelObject,
  rights,
}: countriesScoreModelTypes) => {
  // return rights.allow_countries.reduce( // Bring this back when the API is fixed
  return countries.reduce((accumulator, country): {} => {
    const countryScoreModel = scoreModel({ country, modelOLD, modelObject })
    return {
      ...accumulator,
      [country]: countryScoreModel,
    }
  }, {})
}

// Tsveto from the future here. This function is absolute garbage. You should not use it unless you are absolutely desperate!
// Better just git gut at TS!!!
export const typeGuard = <TObject extends Object>(key: string, object: TObject) => {
  // Do you want to make your type issues to go away? Use this function!
  // If you have a disputed type "It can be a object or a string". You can just use this to destructure it in a type safe way
  if (key in object) {
    return object[key as keyof TObject]
  }
  return null
}
