import { useState, useRef, useEffect, useMemo } from 'react'
import { uniq } from 'ramda'
import {
  Checkbox,
  ClickAwayListener,
  List,
  Paper,
  Stack,
  ListItemText,
  MenuItem,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ButtonLink, ButtonSecondary, ChipStack } from 'components-new'
import * as React from 'react'
import intl from 'localization/components'
import {
  SearchMultiSelectPropsType,
  OptionsType,
  OptionOption,
  OptionsOption,
} from './SearchMultiSelect.types'
import { InputField } from 'components-new'
import styles from './SearchMultiSelect.styles'
import { FormattedMessage } from 'react-intl'
import SearchMultiSelectLoading from './SearchMultiSelectLoader'

const { buttonContainer, menuItem, listItem } = styles

const SearchMultiSelect = ({
  title,
  searchText,
  setSearchText,
  options,
  values,
  setValues,
  isLoading = false,
  placeholder,
  setIsValid,
  inputProps,
  endAddon,
  containerStyles = {},
}: SearchMultiSelectPropsType) => {
  const [isListActive, setIsListActive] = useState(false)
  const buttonContainerRef = useRef<HTMLDivElement>(null)

  const [chipData, setChipData] = useState<OptionOption>([])
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (!chipData.length) setIsValid?.(false)
  }, [chipData, setIsValid])

  useEffect(() => {
    if (options.length && values.length && isFirstRender) {
      setChipData(
        options.filter((o) => values.indexOf(o.value as string) > -1) as OptionOption
      )
      setIsValid?.(Boolean(values.length))
      setIsFirstRender(false)
    }
  }, [isFirstRender, options, setIsValid, values])

  const isSelectAllChecked = useMemo(() => {
    const allValues = options.map((option) => option.label)
    if (searchText) {
      return (
        values.length ===
        allValues.filter((val) =>
          val?.toString()?.toLowerCase().includes(searchText?.toLowerCase())
        ).length
      )
    }
    return values.length === options.length
  }, [options, searchText, values])

  const isSelectAllIndeterminate = useMemo(() => {
    const allValues = options.map((option) => option.label)
    if (searchText) {
      return (
        values.length > 0 &&
        values.length <
          allValues.filter((val) =>
            val?.toString()?.toLowerCase().includes(searchText?.toLowerCase())
          ).length
      )
    }
    return values.length > 0 && values.length < options.length
  }, [options, searchText, values])

  const handleCheck = (value: OptionsOption) => {
    setValues((prevState: OptionsOption[]) => {
      if (prevState.includes(value)) {
        return prevState.filter((item: OptionsOption) => item !== value)
      } else {
        return [...prevState, value]
      }
    })
  }

  const isChecked = (values: string[], value: string) => values.some((x) => x === value)

  const handleChipDelete = (value: OptionsOption) => {
    setChipData(chipData.filter((c) => c.value !== value) as OptionOption)
    setValues(values.filter((v: string) => v !== value))
  }

  const handleApply = () => {
    setChipData(
      options.filter((o) => values.indexOf(o.value as string) > -1) as OptionOption
    )
    setIsListActive(false)
    setIsValid?.(Boolean(values.length))
  }

  const handleClearAll = () => {
    setValues([])
    setChipData([])
    setIsListActive(false)
    setIsValid?.(false)
  }

  const handleClickAway = () => {
    const prevValues = chipData.map((val) => val.value) as OptionsOption[]
    setValues(prevValues)
    setIsListActive(false)
  }

  const handleSelectAll = () => {
    if (searchText) {
      const filteredValues = options
        .filter((option) =>
          option.label?.toString()?.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((option) => option.value)
      if (filteredValues.length === values.length) {
        setValues([])
        return
      }
      setValues(filteredValues as OptionsOption[])
      return
    }
    const allValues = options.map((option) => option.value)
    if (values.length === allValues.length) {
      setValues([])
      return
    }
    setValues(allValues as OptionsOption[])
  }

  const handleExpand = () => {
    setIsListActive(true)
    setIsFirstRender(false)
  }

  return (
    <Stack height={1} sx={{ width: '100%' }} spacing={4}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack
          gap={1}
          sx={{
            position: 'relative',
            ...containerStyles,
          }}
        >
          <Stack direction="row" sx={containerStyles}>
            <InputField
              label={title}
              placeholder={placeholder}
              value={searchText}
              handleChange={setSearchText}
              startAdornment={<SearchIcon />}
              extraStyles={{ '.MuiInput-input': { paddingLeft: 2 } }}
              fullWidth
              clickIn={handleExpand}
              inputProps={{ 'data-cy': 'search-multi-select-input' }}
              {...inputProps}
            />
            {endAddon ?? null}
          </Stack>
          {isListActive && (
            <Paper>
              {isLoading ? (
                <SearchMultiSelectLoading />
              ) : (
                <List
                  sx={{
                    maxHeight: 524,
                    overflow: 'scroll',
                    padding: '0 0 75px 0',
                  }}
                >
                  {options && (
                    <MenuItem onClick={handleSelectAll} sx={menuItem}>
                      <Checkbox
                        size="small"
                        checked={isSelectAllChecked}
                        indeterminate={isSelectAllIndeterminate}
                      />
                      <ListItemText sx={listItem} primary={'Select all'} />
                    </MenuItem>
                  )}
                  {options &&
                    uniq(options as OptionsType[])
                      .filter((item: OptionsType) =>
                        item.label.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .map(({ value, label }: OptionsType) => (
                        <MenuItem
                          onClick={() => handleCheck(value)}
                          sx={menuItem}
                          key={value}
                          value={value}
                          data-value={label}
                        >
                          <Checkbox size="small" checked={isChecked(values, value)} />
                          <ListItemText sx={listItem} primary={label} />
                        </MenuItem>
                      ))}
                </List>
              )}
              <Stack ref={buttonContainerRef} sx={buttonContainer}>
                <ButtonLink
                  data-cy="search-multi-select-clear-all"
                  onClick={handleClearAll}
                >
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-clear-all')}
                  />
                </ButtonLink>
                <ButtonSecondary
                  data-cy="search-multi-select-apply"
                  disabled={!values.length && !chipData.length}
                  onClick={handleApply}
                >
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-apply')}
                  />
                </ButtonSecondary>
              </Stack>
            </Paper>
          )}
        </Stack>
      </ClickAwayListener>
      {!isListActive && (
        <ChipStack
          chipData={chipData}
          setValues={setValues}
          handleChipDelete={handleChipDelete}
        />
      )}
    </Stack>
  )
}

export default SearchMultiSelect
