import { DOMAIN as _ } from 'configs/constants/system'
import moment from 'moment'

/****************
 * Action Types *
 ****************/

export const ADD_SEARCHED_COMPANY = `${_} ADD_SEARCHED_COMPANY`

/*****************
 * Initial State *
 *****************/

const initialState = {
  searched: [],
}

/*******************
 * Action Creators *
 *******************/

/**
 * Add company to list of searched companies.
 *
 * @param {obj} company The name and local ID of the company.
 */
export const addSearchedCompany = ({ companyName, localID: { country, id, hash } }) => ({
  type: ADD_SEARCHED_COMPANY,
  company: {
    companyName,
    localID: {
      country,
      id: id.toString(),
      hash,
    },
    timestamp: moment().unix(),
  },
})

/***********
 * Reducer *
 ***********/

/**
 * Handle dispatched actions.
 *
 * @param {*} state Initial state.
 * @param {*} action Actions.
 */
export const insight = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_SEARCHED_COMPANY:
      return {
        ...state,
        searched: [action.company, ...state.searched],
      }
    default:
      return state
  }
}

export default insight
