import React from 'react'
import { Button, Menu, MenuItem, Fade } from '@mui/material'

export default function ButtonMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const buttonAction = (action) => {
    action()
    handleClose()
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClick}>
        {props.menuData.label}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {props.menuData.menuItems.map((item) => {
          return (
            <MenuItem key={item.label} onClick={() => buttonAction(item.action)}>
              {item.label}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
