import { colors } from 'configs'
import { defaultSimpleBarChartProperties } from 'configs/charts'

import intl from 'localization/components'

export const keyFiguresByCompanyTypeData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [
      { category: 'SCORE', function: 'AVERAGE' },
      { category: 'CURRENT_RATIO', function: 'AVERAGE' },
      { category: 'RETURN_ON_ASSETS', function: 'AVERAGE' },
      { category: 'SOLIDITY_RATIO', function: 'AVERAGE' },
    ],
    group_by: [{ category: 'COMPANY_TYPE_INTL' }],
  },
  chartProperties: {
    ...defaultSimpleBarChartProperties,
    cartesianGrid: false,
    colors: [
      colors.risikaTertiaryDark,
      colors.risikaTertiaryDim,
      colors.risikaTertiary,
      colors.risikaTertiaryLight,
      colors.risikaBlueDark,
      colors.risikaBlue,
      colors.risikaBlueLight,
    ],
    title: intl.observationalLists('risika-key-figures-by-company-type-title'),
    height: 390,
    barGap: 10,
  },
}

export type KeyFiguresByCompanyTypeData = typeof keyFiguresByCompanyTypeData
