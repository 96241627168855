import { Box, Stack, Skeleton } from '@mui/material'
import { classes } from '../styles'

export const PicTextSkeleton = () => {
  return (
    <>
      <Box height="70%">
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
      <Box pt={2} height="30%">
        <Skeleton height="40%" />
        <Skeleton height="40%" width="60%" />
      </Box>
    </>
  )
}

export const PieSkeleton = () => {
  return (
    <>
      <Box pt={2} height="70%" sx={classes.titleChartBoxContainer}>
        <Skeleton variant="circular" height={200} width={200} />
      </Box>
      <Box pt={2} height="30%">
        <Skeleton height="40%" />
        <Skeleton height="40%" />
      </Box>
    </>
  )
}

export const HorizontalBarSkeleton = () => {
  const rows = [20, 50, 60, 100, 60, 30, 20]
  return (
    <>
      <Box pt={2} height="70%" sx={classes.titleChartBoxContainer}>
        <Stack direction="row" spacing={4} height="100%">
          {rows.map((item, index) => {
            const heightInPercents = `${item}%`
            return (
              <Box key={index} width={20} display="flex" alignItems="end">
                <Skeleton variant="rectangular" height={heightInPercents} width="100%" />
              </Box>
            )
          })}
        </Stack>
      </Box>
      <Box pt={2} height="30%">
        <Skeleton height="40%" />
        <Skeleton height="40%" />
      </Box>
    </>
  )
}

export const VerticalBarSkeleton = () => {
  const rows = [30, 20, 50, 60, 100, 60, 30, 20]
  return (
    <>
      <Box pt={2} height="70%" sx={classes.titleChartBoxVerticalContainer}>
        {rows.map((item, index) => {
          const widthInPercents = `${item}%`
          return (
            <Skeleton
              key={index}
              variant="rectangular"
              height={15}
              width={widthInPercents}
            />
          )
        })}
      </Box>
      <Box pt={2} height="30%">
        <Skeleton height="40%" />
        <Skeleton height="40%" />
      </Box>
    </>
  )
}
