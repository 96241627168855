import { DOMAIN as _ } from 'configs/constants/system'

/**************
 * Saga calls *
 **************/

export const SUBMIT_FETCH_ALL_MONITORING_LISTS = `${_} SUBMIT_FETCH_ALL_MONITORING_LISTS`
export const SUBMIT_SHOW_ALL_MONITORING_LISTS = `${_} SUBMIT_SHOW_ALL_MONITORING_LISTS`
export const SUBMIT_GET_RELEVANT_CHANGES_FOR_LIST = `${_} SUBMIT_GET_RELEVANT_CHANGES_FOR_LIST`
export const SUBMIT_FETCH_LIST_ENTRIES_BY_ID = `${_} SUBMIT_FETCH_LIST_ENTRIES_BY_ID`
export const SUBMIT_FETCH_ALL_SUBSCRIPTION_DETAILES = `${_} SUBMIT_FETCH_ALL_SUBSCRIPTION_DETAILES`
export const SUBMIT_FETCH_LIST_DATA_BY_ID = `${_} SUBMIT_FETCH_LIST_DATA_BY_ID`
export const SUBMIT_ADD_TO_MONITORING_LIST = `${_} SUBMIT_ADD_TO_MONITORING_LIST`
export const SUBMIT_REMOVE_COMPANY_FROM_MONITORING_LIST = `${_} SUBMIT_REMOVE_COMPANY_FROM_MONITORING_LIST`
export const SUBMIT_UPDATE_LISTS_RISK_ASSESMENT_BY_ID = `${_} SUBMIT_UPDATE_LISTS_RISK_ASSESMENT_BY_ID`

/****************
 * Action calls *
 ****************/
export const SAVE_ALL_MONITORING_LISTS_SUCCESS = `${_} SAVE_ALL_MONITORING_LISTS_SUCCESS`
export const SAVE_RELEVANT_CHANGES_FOR_LIST_SUCCESS = `${_} SAVE_RELEVANT_CHANGES_FOR_LIST_SUCCESS`
export const SAVE_DATA_FOR_LIST_SUCCESS = `${_} SAVE_DATA_FOR_LIST_SUCCESS`
export const SET_SUBSCRIPTIONS_SUCCESS = `${_} SET_SUBSCRIPTIONS_SUCCESS`
export const SET_LIST_LOADING = `${_} SET_LIST_LOADING`
export const SET_SELECTED_LIST = `${_} SET_SELECTED_LIST`
export const SET_LIST_STATUS = `${_} SET_LIST_STATUS`
export const SAVE_ENTRIES_FOR_LIST_SUCCESS = `${_} SAVE_ENTRIES_FOR_LIST_SUCCESS`
export const ADD_MONITORING_LIST_TO_REDUX = `${_} ADD_MONITORING_LIST_TO_REDUX`
export const SET_TEXT = `${_} SET_TEXT`
export const UPDATE_RISK_ASSESMENT_SUCCESS = `${_} UPDATE_RISK_ASSESMENT_SUCCESS`
export const SUBMIT_EDIT_MONITORING_LIST = `${_} SUBMIT_EDIT_MONITORING_LIST`
export const RENAME_MONITORING_LIST = `${_} RENAME_MONITORING_LIST`

/************
 * Failures *
 ************/
export const SET_SUBSCRIPTIONS_FAILED = `${_} SET_SUBSCRIPTIONS_FAILED`
export const SAVE_ENTRIES_FOR_LIST_FAILED = `${_} SAVE_ENTRIES_FOR_LIST_FAILED`
export const SAVE_RELEVANT_CHANGES_FOR_LIST_FAILED = `${_} SAVE_RELEVANT_CHANGES_FOR_LIST_FAILED`
export const SAVE_ALL_MONITORING_LISTS_FAILED = `${_} SAVE_ALL_MONITORING_LISTS_FAILED`
export const SAVE_DATA_FOR_LIST_FAILED = `${_} SAVE_DATA_FOR_LIST_FAILED`
// Old calls
export const UPDATE_SUBSCRIPTION = `${_} UPDATE_SUBSCRIPTION`
export const UPDATE_SUBSCRIPTION_FREQUENCY = `${_} UPDATE_SUBSCRIPTION_FREQUENCY`

export const SET_LOADING = `${_} SET_LOADING`

// NEW HANDLING ERRORS
export const SERVER_ERROR = `${_} SERVER_ERROR`
export const CLEAR_ERROR = `${_} CLEAR_ERROR`
