import { Stack, Skeleton } from '@mui/material'

const TableToolbarSkeleton = () => {
  return (
    <Stack direction="row" spacing={2} sx={{ ml: 1 }}>
      <Skeleton variant="rounded" width={170} height={38} />
      <Skeleton variant="rounded" width={170} height={38} />
      <Skeleton variant="rounded" width={170} height={38} />
      <Skeleton variant="rounded" width={170} height={38} />
    </Stack>
  )
}

export default TableToolbarSkeleton
