import { useQuery } from 'react-query'
import { userSelf } from 'services/api'

export enum UserTypes {
  ADMIN = 'admin',
  USER = 'user',
  // this is not an exhaustive list
}
export function useUserSelf() {
  return useQuery('userSelf', () => userSelf(), {
    refetchOnWindowFocus: false,
  })
}
