import { Stack, Typography } from '@mui/material'
import { CompanyVisit } from 'services/api/endpoints/risikaAPI/companyVisitsList'
import { padding } from './columns.styles'

type Props = {
  item: CompanyVisit
}

function LocalId({ item }: Props) {
  return (
    <Stack sx={padding}>
      <Typography color="grey.800" variant="body2">
        {`${item.local_organization_id.id}(${item.local_organization_id.country})`}
      </Typography>
    </Stack>
  )
}

export default LocalId
