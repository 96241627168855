import { Button, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import React from 'react'
import styles from './DropdownSelectAddButton.styles'

export type DropdownSelectAddButtonPropsType = {
  icon?: JSX.Element
  label: string | JSX.Element
}

const DropdownSelectAddButton = ({
  icon = <Add fontSize="large" color="inherit" />,
  label,
}: DropdownSelectAddButtonPropsType) => {
  return (
    <Button disableRipple fullWidth sx={styles.buttonStyles}>
      <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
      <ListItemText color="inherit" sx={{ flex: 0 }}>
        <Typography color="inherit" sx={styles.labelStyles}>
          {label}
        </Typography>
      </ListItemText>
    </Button>
  )
}

export default DropdownSelectAddButton
