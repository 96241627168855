import { useState } from 'react'
import { Typography, Stack, TextField } from '@mui/material'
import { CommunicationModal } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
type AlreadyActivePolicyDialogProps = {
  action: (id: string) => void
  open: boolean
  setOpen: (open: boolean) => void
  policyName: string
  policyId: string
}
function DeletePolicyDialog({
  action,
  open,
  setOpen,
  policyName,
  policyId,
}: AlreadyActivePolicyDialogProps) {
  const [confirmDeleteInput, setConfirmDeleteInput] = useState('')
  const handleDelete = () => {
    if (confirmDeleteInput.toUpperCase().trim() === policyName.toUpperCase().trim()) {
      action(policyId)
      setOpen(false)
    } else {
      console.log('Wrong name...')
    }
  }
  return (
    <CommunicationModal
      title={
        <FormattedMessage
          id={intl.creditPolicyNew('are-you-sure-you-want-to-delete-this-policy')}
          values={{
            name: policyName,
          }}
        />
      }
      body={
        <Stack gap={3}>
          <Typography variant="body2">
            <FormattedMessage id={intl.creditPolicyNew('delete-policy-description')} />
          </Typography>
          <Typography>
            <FormattedMessage
              id={intl.creditPolicyNew('delete-policy-action')}
              values={{
                // @ts-ignore // This works. But there is issue with TS
                b: (chunks: any) => <b>{chunks}</b>,
                name: policyName.toUpperCase(),
              }}
            />
          </Typography>
          <TextField
            data-cy="delete-policy-input-field"
            // fullWidth
            value={confirmDeleteInput}
            onChange={(e) => setConfirmDeleteInput(e.target.value)}
          />
        </Stack>
      }
      open={open}
      setOpen={setOpen}
      mainButton={{
        isDestructive: true,
        content: <FormattedMessage id={intl.creditPolicyNew('delete')} />,
        click: handleDelete,
      }}
      secondaryButton={{
        content: <FormattedMessage id={intl.creditPolicyNew('cancel')} />,
        click: () => setOpen(false),
      }}
    />
  )
}

export default DeletePolicyDialog
