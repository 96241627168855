import { SxProps } from '@mui/material/styles'
const root = {
  width: 'inherit',
}

const conflictColor = (conflicts: boolean): SxProps => {
  return {
    width: 'inherit',
    cursor: 'pointer',
    '& > *': {
      color: conflicts ? 'grey.800' : 'grey.600',
    },
  } as SxProps
}

const popperProps = {
  sx: {
    '& .MuiTooltip-tooltip': {
      bgcolor: 'primary.dark',
    },
  },
}

export const classes = {
  root,
  conflictColor,
  popperProps,
}
