import { colors } from 'configs'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: colors?.risika,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      width: '100vw',
      height: '100vh',
    },
  },
  leftContainer: {
    padding: theme.spacing(3, 3, 0, 3),
    [theme.breakpoints.up('sm')]: {
      flex: 4,
      padding: theme.spacing(9),
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors?.risika,
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 2, 2, 2),
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: colors?.risikaSecondary,
      padding: theme.spacing(0, 2),
      flexBasis: 360,
      flexGrow: 1,
      width: 'initial',
      overflow: 'auto',
      alignItems: 'center',
    },
  },
  overflowContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      padding: '50px 0',
      margin: 'auto 0',

      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
    },
  },
}))
