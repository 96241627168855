import {
  SET_TAB_SELECTED,
  SET_POLICY_VALUES_SUCCESS,
  SET_POLICY_VALUES_FAILED,
  UPDATE_POLICY_VALUES,
  SET_STATUS,
  UPDATE_SINGLE_POLICY_VALUES,
  SET_IS_POLICY,
} from './constants'

const handleSetTabSelected = (state, action) => {
  const { payload } = action
  return {
    ...state,
    tabSelected: payload,
  }
}
const handleUpdateIsPolicy = (state, action) => {
  const { payload } = action
  const { isPolicy, tabSelected } = payload
  return {
    ...state,
    policyForm: {
      ...state.policyForm,
      [tabSelected]: {
        ...state.policyForm[tabSelected],
        enabled: isPolicy,
      },
    },
  }
}
const handleSetStatus = (state, action) => {
  const { payload } = action
  const { status, country } = payload
  return {
    ...state,
    policyForm: {
      ...state.policyForm,
      [country]: {
        ...state.policyForm[country],
        status,
      },
    },
  }
}
const handleSetPolicyValues = (state, action) => {
  const { payload, status } = action
  const { policyValues, country, enabled } = payload
  return {
    ...state,
    policyForm: {
      ...state.policyForm,
      [country]: {
        policyValues,
        enabled,
        status,
      },
    },
  }
}
const handleUpdatePolicyValues = (state, action) => {
  const { payload } = action
  const { tabSelected, fieldType, values } = payload

  return {
    ...state,
    policyForm: {
      ...state.policyForm,
      [tabSelected]: {
        ...state.policyForm[tabSelected],
        policyValues: {
          ...state.policyForm[tabSelected].policyValues,
          [fieldType]: values?.[0]?.value
            ? values?.map((x) => x?.value)
            : values?.value != null
            ? values?.value
            : values,
        },
      },
    },
  }
}
const updateSinglePolicyValues = (state, action) => {
  const { payload } = action
  const { tabSelected, fieldType, values } = payload
  return {
    ...state,
    policyForm: {
      ...state.policyForm,
      [tabSelected]: {
        ...state.policyForm[tabSelected],
        policyValues: {
          ...state.policyForm[tabSelected].policyValues,
          [fieldType]: values.value,
        },
      },
    },
  }
}

export const initialState = {
  policyForm: {},
  tabSelected: 'dk',
}

export const creditPolicy = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SET_TAB_SELECTED:
      return handleSetTabSelected(state, action)
    case SET_POLICY_VALUES_SUCCESS:
      return handleSetPolicyValues(state, action)
    case SET_POLICY_VALUES_FAILED:
      return handleSetPolicyValues(state, action)
    case UPDATE_POLICY_VALUES:
      return handleUpdatePolicyValues(state, action)
    case UPDATE_SINGLE_POLICY_VALUES:
      return updateSinglePolicyValues(state, action)
    case SET_STATUS:
      return handleSetStatus(state, action)
    case SET_IS_POLICY:
      return handleUpdateIsPolicy(state, action)
    default:
      return state
  }
}

export default creditPolicy
