import React from 'react'

import SimpleEntry from './SimpleEntry'

import { ShowHideAllWrapper } from 'components'

import { Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const SecondaryNames = ({ company_secondary_names }) => {
  const [showAllSecondaryNames, setShowAllSecondaryNames] = React.useState(false)
  return (
    <SimpleEntry
      condition={
        company_secondary_names &&
        !!company_secondary_names?.filter((x) => x.valid_to === null)?.length
      }
      title={<FormattedMessage id={intl.companyInfo('secondary-names')} />}
      elementType="div"
      content={[
        company_secondary_names
          ?.filter((x) => x.valid_to === null)
          ?.map((company, i) => (
            <ShowHideAllWrapper
              setIsShowAll={setShowAllSecondaryNames}
              isShowAll={showAllSecondaryNames}
              key={i}
              amountOfShowedItems={3}
              iteration={i}
              dataLength={
                company_secondary_names?.filter((x) => x.valid_to === null)?.length
              }
            >
              <div key={i + company.name}>
                <Typography id={i} key={i} variant="body2">
                  {company.name}
                </Typography>
              </div>
            </ShowHideAllWrapper>
          )),
      ]}
    />
  )
}

export default SecondaryNames
