import React, { ReactNode, useState } from 'react'
import RisikaSupportLogo from '../../assets/RisikaSupportLogo.svg'
import ContactResponsible from 'pages/home/scenes/ContactResponsible'
import { Box, Typography } from '@mui/material'
import { classes } from './styles'
import { useUserSelf } from 'services/queries'
import ChatWithUs from './ChatWithUs'
import QuickAnswers from './QuickAnswers'
import { useHubspotConversations } from 'services/hubSpot/HubspotConversationsProvider'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import InitialChatPrompt from './InitialChatPrompt'
import { useSupportContext } from './SupportContext'
import * as process from 'process'

type PropsType = {
  children: ReactNode
}

const SupportAndChat = ({ children }: PropsType) => {
  const { data } = useUserSelf()
  const { toggleWidget } = useHubspotConversations()
  const { isSupportPromptDisplay, setSupportDisplay } = useSupportContext()

  const [isSupportOpen, setIsSupportOpen] = useState(false)

  const handleLogoClick = () => {
    setIsSupportOpen(!isSupportOpen)
    setSupportDisplay(false)
    toggleWidget('close')
  }

  const handleChatClick = () => {
    toggleWidget()
  }

  if (process.env.REACT_APP_ENV === 'development' && !window.Cypress) {
    return <Box>{children}</Box>
  }
  return (
    <Box>
      {isSupportOpen && (
        <Box sx={classes.floatingParent}>
          <Box sx={classes.parentContainer}>
            <Box sx={classes.topContainer}>
              <Typography variant="h3" color="white" lineHeight={2}>
                <FormattedMessage
                  id={intl.faq('welcome')}
                  values={{ name: data?.name }}
                />
              </Typography>
              <Typography variant="body1" color="white">
                <FormattedMessage id={intl.faq('how-can-we-help')} />
              </Typography>
            </Box>
            <Box sx={classes.contact}>
              <ContactResponsible />
            </Box>
            <Box sx={classes.lowerContainer}>
              <ChatWithUs onClick={handleChatClick} />
              <Box>
                <QuickAnswers />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isSupportPromptDisplay && (
        <Box sx={classes.promptParent}>
          <InitialChatPrompt />
        </Box>
      )}
      {
        <img
          alt="Risika Support Chat Logo"
          src={RisikaSupportLogo}
          height={60}
          width={60}
          style={{ position: 'fixed', bottom: 24, right: 24, zIndex: 100 }}
          onClick={handleLogoClick}
          data-cy="support-and-chat"
        />
      }
      {children}
    </Box>
  )
}

export default SupportAndChat
