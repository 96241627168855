import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { classes } from './styles'
import SendIcon from '../../assets/SendIcon.svg'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { theme } from 'styles-new/mui5-transition/theme'

type ChatWithUsProps = {
  onClick: () => void
}

const ChatWithUs = (props: ChatWithUsProps) => {
  const { onClick } = props
  const [margin, setMargin] = useState(theme.spacing(2))

  const handleAnimation = (direction: 'enter' | 'leave') => {
    if (direction === 'enter') {
      setMargin(theme.spacing(1))
    } else {
      setMargin(theme.spacing(2))
    }
  }

  return (
    <Box
      style={classes.chatWithUs}
      onClick={onClick}
      data-cy="chat-with-us"
      onMouseEnter={() => handleAnimation('enter')}
      onMouseLeave={() => handleAnimation('leave')}
    >
      <div>
        <Typography variant="subtitle1" color="#353A3F">
          <FormattedMessage id={intl.faq('chat-cta')} />
        </Typography>
        <Typography variant="body2" color="#353A3F">
          <FormattedMessage id={intl.faq('response-time')} />
        </Typography>
      </div>
      <div style={{ marginRight: margin, transition: '0.3s ease' }}>
        <img src={SendIcon} height={17} width={17} />
      </div>
    </Box>
  )
}

export default ChatWithUs
