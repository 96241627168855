import { useState } from 'react'
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import moment from 'moment'
import SwitchWithLabel from '../../../widgets/SwitchWithLabel'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PolicyCardStyles from './PolicyCard.styles'
import { PropsType } from './PolicyCard.types'
const { card, textInactive, containerLeft, containerRight } = PolicyCardStyles

const PolicyCardLoading = ({ cardData }: PropsType) => {
  const { name, owner_id, updated, enabled, id } = cardData
  const [checked, setChecked] = useState(enabled)

  const handleChangeStatus = () => setChecked((prevState: Boolean) => !prevState)

  return (
    <Box data-cy="policy-card" key={id} sx={card}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack sx={containerLeft}>
          <Skeleton>
            <Typography sx={!checked ? textInactive : {}} variant="h5">
              {name}
            </Typography>
          </Skeleton>
          <Skeleton>
            <Typography variant="body2" color="grey.700">
              {`by ${owner_id}, last updated on: ${moment(updated).format('DD/MM/YYYY')}`}
            </Typography>
          </Skeleton>
        </Stack>
        <Stack sx={containerRight} direction="row">
          <Skeleton>
            <SwitchWithLabel checked={checked} onChange={handleChangeStatus} />
          </Skeleton>
          <Skeleton>
            <IconButton data-cy="policy-card-actions" sx={{ fontSize: 24 }}>
              <MoreVertIcon sx={{ color: 'primary.main' }} fontSize="inherit" />
            </IconButton>
          </Skeleton>
        </Stack>
      </Stack>
    </Box>
  )
}

export default PolicyCardLoading
