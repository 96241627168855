import React from 'react'
import { useDebounce } from 'react-use'

const countryOptions = {
  ALL: 'all',
  DENMARK: 'da_DK',
  SWEDEN: 'sv_SE',
  NORWAY: 'no_NO',
  FINLAND: 'fi_FI',
}

/**
 * Filter the specified column by selecting a group.
 *
 * @param {React.Component<{value, onchange}>} InputComponent The InputComponent to use as input.
 * @param {array} preFilteredRows The rows before the filtering was applied.marked
 * @param {callback} setFilter Callback that sets the filter.
 */
const CountryFilter = (props) => {
  const {
    InputComponent,
    debounceTime = 300,
    column: { preFilteredRows, setFilter },
  } = props
  const count = preFilteredRows.length
  const [debounceValue, setDebounceValue] = React.useState(countryOptions.ALL)

  const getValuesForCountryGroups = (country) => {
    switch (country) {
      case countryOptions.ALL:
        return []
      case countryOptions.DENMARK:
        return 'da_DK'
      case countryOptions.SWEDEN:
        return 'sv_SE'
      case countryOptions.NORWAY:
        return 'no_NO'
      case countryOptions.FINLAND:
        return 'fi_FI'
      default:
        return []
    }
  }

  useDebounce(
    () => {
      setFilter({
        name: debounceValue,
        values: getValuesForCountryGroups(debounceValue),
      })
    },
    debounceTime,
    [debounceValue]
  )

  return (
    <InputComponent
      data={{ count }}
      value={debounceValue ?? countryOptions.ALL}
      values={Object.values(countryOptions)}
      onChange={(e) => {
        setDebounceValue(e.target.value ?? '')
      }}
    />
  )
}

export default CountryFilter
