import { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Stack,
  ClickAwayListener,
  Chip,
} from '@mui/material'
import { ButtonLink, ButtonSecondary, InputSearchText } from 'components-new'
import { useTreeSelect } from 'pages/advanced-filters/scenes/NestedSelect'
import IndustryTypeCheckbox from './IndustryTypeCheckbox'
import IndustryTypeExpander from './IndustryTypeExpander'
import CloseIcon from '@mui/icons-material/Close'
import * as React from 'react'
import { footerStyles } from './IndustryTypeView.styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ViewPropsType } from './IndustryTypeView.types'
import { useDebounce } from 'react-use'

const View = ({
  data,
  industryTypeList,
  setIndustryTypeList,
  setApiData,
  expanded,
  setExpanded,
  isDisabled = false,
}: ViewPropsType) => {
  const [searchText, setSearchText] = useState('')
  const [prevTextValues, setPrevTextValues] = useState<any>([])
  const [prevValues, setPrevValues] = useState<any>([])

  useDebounce(
    () => {
      search(searchText)
    },
    300,
    [searchText]
  )

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  useEffect(() => {
    if (!expanded) {
      setPrevTextValues(industryTypeList)
      setPrevValues(selectedValues)
    }
  }, [industryTypeList])

  const {
    ViewIndustryTypePolicyBuilder,
    search,
    reset,
    selectedValues,
    selectedTextValues,
    removeSelection,
    setState,
  } = useTreeSelect(data, {
    components: {
      Checkbox: IndustryTypeCheckbox,
      Expander: IndustryTypeExpander,
    },
    spacing: 32,
  })

  useEffect(() => {
    if (
      selectedTextValues.length !== industryTypeList.length &&
      selectedValues.length !== 0
    ) {
      setIndustryTypeList(selectedTextValues)
      setApiData(selectedValues)
    }
    // Please do not add new dependencies, because it will not work as expected
  }, [selectedTextValues])

  useEffect(() => {
    if (selectedTextValues.length === 0 && industryTypeList.length) {
      reset()
      setIndustryTypeList([])
      setApiData([])
    }
    // Please do not add new dependencies, because it will not work as expected
  }, [selectedTextValues])

  const handleClearAll = () => {
    reset()
    setExpanded(false)
  }

  const handleApply = () => {
    setExpanded(false)
    setSearchText('')
    setPrevTextValues(industryTypeList)
    setPrevValues(selectedValues)
  }

  const handleDeleteChip = (label: string) => {
    const value = selectedValues.find((val) => label.includes(val))
    removeSelection(label, value)
  }

  const handleClickAway = () => {
    if (expanded) {
      setState(prevTextValues, prevValues)
      setIndustryTypeList(prevTextValues)
      setExpanded(false)
    }
  }

  const handleFocus = () => setExpanded(true)

  return (
    <Stack height={1} spacing={3} sx={{ width: '100%' }}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack justifyContent="space-between" width={1} maxHeight={0.97}>
          <Stack height={1} justifyContent="space-between" maxHeight={500}>
            <InputSearchText
              label={<FormattedMessage id={intl.creditPolicyNew('industry_type')} />}
              value={searchText}
              handleChange={handleSearch}
              disabled={isDisabled}
              clickIn={handleFocus}
            />
            <Paper
              sx={{
                padding: 0,
                minHeight: 0,
                marginTop: 1,
                overflow: 'scroll',
              }}
              elevation={expanded ? 1 : 0}
            >
              <Box maxHeight={1} overflow="auto">
                <Accordion
                  className="ACCORDION_CONTAINER"
                  sx={{ '&.MuiPaper-root': { borderRadius: 0 } }}
                  expanded={expanded}
                >
                  <AccordionSummary
                    className="ACCORDION_SUMMARY"
                    sx={{
                      height: 0,
                      '&.Mui-expanded.Mui-expanded': {
                        minHeight: 0,
                        overflow: 'scroll',
                      },
                      '&.MuiAccordionSummary-root': {
                        minHeight: 0,
                      },
                    }}
                  />
                  <AccordionDetails
                    className="ACCORDION_DETAILS"
                    sx={{
                      borderRadius: 0,
                      padding: 4,
                    }}
                  >
                    <ViewIndustryTypePolicyBuilder />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Paper>

            {expanded && (
              <Stack sx={footerStyles}>
                <ButtonLink
                  data-cy="search-multi-select-clear-all"
                  onClick={handleClearAll}
                >
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-clear-all')}
                  />
                </ButtonLink>
                <ButtonSecondary
                  data-cy="search-multi-select-apply"
                  sx={{ color: '#FFF !important' }}
                  onClick={handleApply}
                >
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-apply')}
                  />
                </ButtonSecondary>
              </Stack>
            )}
          </Stack>
        </Stack>
      </ClickAwayListener>
      {!expanded && industryTypeList.length > 0 && (
        <Stack gap={2} flexWrap="wrap" width={1} direction="row" justifyContent="start">
          {industryTypeList.length > 0 &&
            selectedTextValues.map((label: string) => {
              return (
                <Chip
                  key={label}
                  label={label}
                  deleteIcon={<CloseIcon />}
                  onDelete={() => handleDeleteChip(label)}
                  color="primary"
                  sx={{
                    maxWidth: '100%',
                    marginRight: 2,
                    '.MuiChip-label': { fontSize: 16, fontWeight: 'normal' },
                  }}
                />
              )
            })}
        </Stack>
      )}
    </Stack>
  )
}

export default View
