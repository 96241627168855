import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useBuilder } from '../../../../../../BuilderContext'
import { DeleteRuleButtonPropsType } from '../../../../ActionPanel.types'
import ButtonLink2 from 'components-new/inputs/Button/ButtonLink2'

function DeleteRuleButton({ handleDelete, showDeleteButton }: DeleteRuleButtonPropsType) {
  const { state, actions } = useBuilder()

  if (!showDeleteButton) return null

  const onDelete = () => {
    const selectedNode = state.nodes.find(
      (node) => node.id === state.actionPanelData.nodeId
    )

    const childrenIds = selectedNode?.data?.builderData?.childrenIds
    const selectedNodeHasChildren = state.nodes
      .filter((node) => node.type !== 'plus')
      .map((node) => node.id)
      .some((id) => childrenIds?.includes(id))

    if (!selectedNode) return handleDelete()
    if (selectedNodeHasChildren) {
      return handleDelete()
    }
    return actions.deleteNode()
  }
  return (
    <ButtonLink2
      onClick={onDelete}
      sx={{
        color: 'error.main',
        margin: 'auto auto 0 auto',
        '&:hover': {
          color: 'error.900',
        },
        '&:active': {
          color: 'error.900',
          border: 'none',
        },
      }}
    >
      <FormattedMessage id={intl.creditPolicyNew('delete-this-rule')} />
    </ButtonLink2>
  )
}

export default DeleteRuleButton
