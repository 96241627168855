import { forwardRef, ForwardedRef } from 'react'
import Link from '@mui/material/Link'
import { buttonLinkStyles } from './Button.styles'
import { LinkProps } from './Button.types'
import { SxProps } from '@mui/system'

/**
 * ButtonLink
 * @description This is not and actual Button nor a Link.This is more like a button mimicking link.
 * @description Use with caution.It will not take the full width of the container, nor it has default hover styles typical for button component.
 **/

const ButtonLink = forwardRef(
  (
    { children, loading = false, sx = {}, ...extraProps }: LinkProps,
    ref: ForwardedRef<any>
  ) => {
    return (
      <Link
        role="button"
        ref={ref}
        sx={{ ...buttonLinkStyles, ...sx } as SxProps}
        {...extraProps}
      >
        {children}
      </Link>
    )
  }
)

export default ButtonLink
