import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  title: {
    flex: 1,
    fontWeight: 700,
  },
}))

export const Entry = (props) => {
  const styles = useStyles()

  return <div className={styles.root}>{props.children}</div>
}

export const EntryTitle = (props) => {
  const styles = useStyles()

  return (
    <Typography className={styles.title} variant="subtitle2">
      {props.children}
    </Typography>
  )
}

export const EntryContent = (props) => {
  return (
    <div
      style={{
        flex: '3 1 0%',
      }}
    >
      {props.children}
    </div>
  )
}

export const EntryText = (props) => {
  return <Typography variant="body2">{props.children}</Typography>
}
