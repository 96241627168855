import { Box, Stack, Skeleton } from '@mui/material'

const HorizontalEqualBarSkeleton = ({ height }: { height: number }) => {
  const rows = [100, 100, 100, 100, 100, 100, 100]

  return (
    <Box p={2}>
      <Stack direction="row" justifyContent="center" spacing={4} height={height * 0.7}>
        {rows.map((item, index) => {
          const heightInPercents = `${item}%`

          return (
            <Box key={index} width={20} display="flex" alignItems="center">
              <Skeleton variant="rectangular" height={heightInPercents} width="100%" />
            </Box>
          )
        })}
      </Stack>
    </Box>
  )
}

export default HorizontalEqualBarSkeleton
