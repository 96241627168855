import { authRoles } from 'components/auth'
import RelationOverviewPage from './RelationOverviewPage'
import { navigation } from 'services/navigation'

export const RelationOverviewPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: `${navigation.relationOverview()}/:country/:id`,
      component: RelationOverviewPage,
    },
    {
      path: `${navigation.relationOverview()}/:id/`,
      component: RelationOverviewPage,
    },
    {
      path: navigation.relationOverview(),
      component: RelationOverviewPage,
    },
  ],
}
