import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId, AllowedModel, AvailableCountriesCapital } from 'globalTypes'
import { type } from 'ramda'

type Args = {
  listId: string | number
  model: AllowedModel
  policyId: string | number
  country: AvailableCountriesCapital
}

const evaluateMonitoringList = async ({
  listId,
  country,
  policyId,
  model,
}: Args): Promise<any> => {
  try {
    const { data } = await risikaAPI.post(
      'enterprise_credit_policies/evaluate_monitoring_list',
      {
        list_id: +listId,
        columns: ['company_type', 'name'],
        policy_id: +policyId,
        score_model: { [country]: model },
        country,
      },
      {
        microservice: 'account',
        // appendScoringModel: true,
        // country
      }
    )
    return data
  } catch (err) {
    console.log('Error: ', err)
  }
}

export default evaluateMonitoringList
