import { forwardRef, ForwardedRef, SyntheticEvent } from 'react'
import Link from '@mui/material/Link'
import { buttonLinkTwoStyles } from './Button.styles'
import { LinkProps } from './Button.types'
import { SxProps } from '@mui/system'

/**
 * ButtonLink aka. Link2 in Figma
 * @description This is not and actual Button nor a Link.This is more like a button mimicking link.
 * @description Use with caution.It will not take the full width of the container, nor it has default hover styles typical for button component.
 **/

const ButtonLink2 = forwardRef(
  (
    { children, loading = false, sx = {}, ...extraProps }: LinkProps,
    ref: ForwardedRef<any>
  ) => {
    const { onClick, ...linkProps } = extraProps
    const handleClick = (e: SyntheticEvent) => {
      if (linkProps.role !== 'link') e.preventDefault()
      onClick?.(e)
    }
    return (
      <Link
        role="button"
        ref={ref}
        href={''}
        onClick={handleClick}
        sx={{ ...buttonLinkTwoStyles, ...sx } as SxProps}
        {...linkProps}
      >
        {children}
      </Link>
    )
  }
)

export default ButtonLink2
