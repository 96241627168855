import React from 'react'
import { Box } from '@mui/material/'

type Props = {
  children: React.ReactNode
}

const msStyles = {
  backgroundColor: 'common.white',
  margin: '20px',
  flex: 1,
  borderRadius: 1,
}

const MainSection = ({ children }: Props) => {
  return <Box sx={msStyles}>{children}</Box>
}

export default MainSection
