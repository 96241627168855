import React from 'react'
import {
  Box,
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from '@mui/material'
import IntlTypography from 'components-new/elements/IntlTypography'
import { useAccountStatus } from 'services/queries'
import { Show } from 'components'
import { useQueryClient } from 'react-query'
import { UserSelfType } from 'types/queries'
import {
  useCustomerSettingsField,
  useCustomerSettingsFieldUpdate,
} from 'services/queries'

const ModelSection = () => {
  const accountStatusQuery = useAccountStatus()
  const allowModels = accountStatusQuery.data?.rights?.allow_models
  const queryClient = useQueryClient()
  const user: UserSelfType | undefined = queryClient.getQueryData('userSelf')
  const { data, isSuccess } = useCustomerSettingsField('user_model')
  const { mutate: updateField } = useCustomerSettingsFieldUpdate()
  const userModel = data?.user_model

  React.useEffect(() => {
    if (userModel.length !== (allowModels?.length ?? userModel.length)) {
      let newUserModel = {}
      allowModels?.forEach(({ country }) => {
        newUserModel = {
          ...newUserModel,
          [country]: userModel?.[country] || allowModels?.[0]?.score_models?.[0]?.model,
        }
      })
      updateField({
        field: 'user_model',
        data: newUserModel,
      })
    }
  }, [allowModels, updateField, userModel])
  return (
    <Show when={!!allowModels?.length && isSuccess}>
      <Box data-cy="model-section">
        <Stack direction="column" justifyContent="center" alignItems="flex-start" gap={5}>
          {allowModels?.map(({ country, score_models }) => (
            <Box key={country}>
              <IntlTypography
                variant="subtitle2"
                group="settings"
                labelKey={`score-model-${country.toLocaleLowerCase()}`}
              />
              <RadioGroup
                value={userModel?.[country]}
                onChange={(e) =>
                  updateField({
                    field: 'user_model',
                    data: { ...userModel, [country]: e.target.value },
                  })
                }
              >
                {score_models?.map(({ model }) => (
                  <FormControlLabel
                    key={model + country}
                    disabled={user?.role !== 'admin'}
                    value={model}
                    control={<Radio sx={{ marginTop: 1 }} />}
                    label={<Typography variant="body1">{model} </Typography>}
                    data-cy={`${country} ${model} model`}
                  />
                ))}
              </RadioGroup>
            </Box>
          ))}
        </Stack>
      </Box>
    </Show>
  )
}

export default ModelSection
