import { Box, Stack, Typography, Collapse } from '@mui/material'
import IntlTypography from 'components-new/elements/IntlTypography'
import CustomTooltip from './CustomTooltip'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { useState } from 'react'
import ToggleArrow from './ToggleArrow'
import styles from './FactorGroup.styles'
import { ScoreItem } from 'services/api/endpoints/risikaAPI/ratingScores'

type Props = {
  name: string
  factors: ScoreItem[]
  color: string
}
const FactorGroup = ({ name, factors, color }: Props) => {
  const reactIntl = useIntl()

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Box>
      <Box>
        <Box sx={styles.leftBorder(color)} />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.header}
        >
          <Stack direction="row" alignItems="center">
            <IntlTypography variant="subtitle2" group="newScorecard" labelKey={name} />
            {name === 'minor_impact' && (
              <CustomTooltip
                sx={styles.minorImpactTooltip}
                text={
                  reactIntl.messages[intl.newScorecard('minor-impact-tooltip')] as string
                }
              />
            )}
          </Stack>

          <Stack direction="row" alignItems="center">
            <IntlTypography
              variant="body2"
              group="newScorecard"
              labelKey="factor-counter"
              values={{
                count: factors.length,
              }}
            />
            <ToggleArrow
              isExpanded={isExpanded}
              handleClick={setIsExpanded}
              isDisabled={factors.length === 0}
            />
          </Stack>
        </Stack>
      </Box>

      <Collapse in={isExpanded}>
        {factors.map(({ feature }) => (
          <Box key={feature} sx={styles.item}>
            <Typography variant="body2">{feature}</Typography>
          </Box>
        ))}
      </Collapse>
    </Box>
  )
}

export default FactorGroup
