import { Stack } from '@mui/material'
import { ButtonSecondary, ButtonTertiary } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { DetailedComponentViewActionButtonsPropsType } from '../../../../ActionPanel.types'

function DetailedComponentViewActionButtons({
  handleCancel,
  handleSubmit,
  confirmEnabled,
  confirmButtonText,
}: DetailedComponentViewActionButtonsPropsType) {
  return (
    <Stack
      className="BUTTON_CONTAINER"
      spacing={9}
      paddingX={5}
      paddingY={4}
      sx={{ backgroundColor: 'grey.50' }}
      justifyContent="space-around"
      direction="row"
    >
      <ButtonTertiary
        data-cy="action-panel-cancel-button"
        sx={{ width: 1 }}
        onClick={handleCancel}
      >
        <FormattedMessage id={intl.creditPolicyNew('action-panel-cancel-button')} />
      </ButtonTertiary>
      <ButtonSecondary
        onClick={handleSubmit}
        sx={{ width: 1 }}
        disabled={!confirmEnabled}
        data-cy="action-panel-confirm-button"
      >
        <FormattedMessage id={intl.creditPolicyNew(confirmButtonText)} />
      </ButtonSecondary>
    </Stack>
  )
}

export default DetailedComponentViewActionButtons
