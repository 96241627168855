import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import { HeaderButtonHandler, ConditionallyRender, ErrorBoundary } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faLock } from '@fortawesome/free-solid-svg-icons'
import { motion, AnimateSharedLayout } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useAccountStatus } from 'services/queries'
import { useCreditCheck } from '../context'
import { isComponentRendered } from 'configs'
import useTotangoTracking from 'utils/totangoTracking'
import Switch from '@mui/material/Switch'
import ClickabilityDots from '../../../assets/ClickabilityDots.svg'
import { SCENE_WRAPPER_STYLES } from '../CreditCheckPage.model'

const useStyles = makeStyles((theme) => SCENE_WRAPPER_STYLES)

const SceneWrapper = ({
  renderId,
  title,
  children,
  dropdownContent,
  isAdvanced,
  isReArrange,
  order,
  restrictionsId,
  dataCy,
  draggable_id,
  isActive = true,
  setIsActive,
}) => {
  const classes = useStyles()
  const [isShowMore, setIsShowMore] = React.useState(false)
  const [isRendering, setIsRendering] = React.useState(true)
  const [notExpandable, setNotExpandable] = React.useState(false)
  const accountStatusQuery = useAccountStatus()
  const { trackEvent } = useTotangoTracking()
  const { localId } = useCreditCheck()

  // Helps reset the state of the page between re-renders
  React.useEffect(() => {
    setIsRendering(isComponentRendered(renderId, localId?.country))
  }, [localId, renderId])

  const variants = {
    open: { height: '100%' },
    closed: { height: '0' },
  }

  const handleExpand = () => {
    if (!isShowMore) {
      switch (renderId) {
        case 'generalCompanyInformation':
          trackEvent('Credit Check', 'Expand General Company Information')
          break
        case 'keyFinancialFigures':
          trackEvent('Credit Check', 'Expand Key Financial Figures')
          break
        case 'relevantFinancialRatios':
          trackEvent('Credit Check', 'Expand Relevant Financial Ratios')
          break
        case 'owners':
          trackEvent('Credit Check', 'Expand Owners')
          break
      }
    }
    setIsShowMore(!isShowMore)
  }

  return isRendering ? (
    <motion.div
      animate={{
        opacity: 1,
      }}
      style={{
        order: order,
        opacity: 0,
        overflowAnchor: isShowMore ? 'none' : null,
      }}
    >
      <ErrorBoundary page="credit-check" component={restrictionsId} localId={localId}>
        <Paper data-cy={dataCy} sx={{ backgroundColor: 'grey.200' }}>
          {isReArrange && (
            <>
              <div className={classes.isReArrangeTop} data-cy={dataCy}>
                <div className={classes.titleCluster}>
                  <img src={ClickabilityDots} height={16} width={16} />
                  <Typography
                    className={classes.title}
                    variant="h4"
                    color={isActive ? 'inherit' : 'grey'}
                  >
                    {title}
                  </Typography>
                </div>
                <Switch
                  checked={isActive}
                  onChange={() => setIsActive(draggable_id)}
                  data-cy={`${dataCy}switch`}
                />
              </div>
            </>
          )}

          {!isReArrange && isActive && (
            <>
              <div className={classes.topSection} data-cy={dataCy}>
                <div className={classes.titleCluster}>
                  <Typography className={classes.title} variant="h4">
                    {title}
                  </Typography>
                </div>
                <ConditionallyRender
                  condition={!isReArrange && Boolean(dropdownContent)}
                  when={
                    <HeaderButtonHandler headerId={restrictionsId} isColumn>
                      {dropdownContent ?? null}
                    </HeaderButtonHandler>
                  }
                />
              </div>
              <div className={classes.contentContainer}>
                <AnimateSharedLayout>
                  <motion.div variants={variants} animate={'open'}>
                    {React.cloneElement(children, {
                      isShowMore: isShowMore,
                      setIsRendering: setIsRendering,
                      setNotExpandable: setNotExpandable,
                    })}
                  </motion.div>
                </AnimateSharedLayout>

                <ConditionallyRender
                  condition={!notExpandable}
                  when={
                    <div
                      data-cy={`${dataCy ?? ''}expand-more-icon`}
                      className={classes.expandMoreIcon}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          isAdvanced ? (
                            isShowMore ? (
                              <FormattedMessage id={intl.companyRoles('simple-view')} />
                            ) : (
                              <FormattedMessage id={intl.companyRoles('advanced-view')} />
                            )
                          ) : (
                            ''
                          )
                        }
                      >
                        <div>
                          <ConditionallyRender
                            condition={
                              restrictionsId === 'general-company-info' ||
                              'relevant-financial-ratios' ||
                              accountStatusQuery.data?.subscription_plan !== 'FREEMIUM'
                            }
                            when={
                              <ConditionallyRender
                                condition={!isReArrange}
                                when={
                                  <IconButton sx={{ padding: 4 }} onClick={handleExpand}>
                                    {isShowMore ? (
                                      <FontAwesomeIcon icon={faChevronUp} />
                                    ) : (
                                      <FontAwesomeIcon icon={faChevronDown} />
                                    )}
                                  </IconButton>
                                }
                              />
                            }
                            otherwise={
                              <div>
                                <Tooltip
                                  placement="top"
                                  title={
                                    <FormattedMessage
                                      id={intl.generic('advanced-views-not-allowed')}
                                    />
                                  }
                                >
                                  <IconButton
                                    onClick={() =>
                                      window.open('https://risika.dk/prices')
                                    }
                                  >
                                    <FontAwesomeIcon icon={faLock} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                        </div>
                      </Tooltip>
                    </div>
                  }
                />
              </div>
            </>
          )}
        </Paper>
      </ErrorBoundary>
    </motion.div>
  ) : null
}

export default SceneWrapper
