import risikaAPI from 'services/api/helpers/risikaAPI'
/******************
 * Post to API *
 ******************/

const monitorRemoveList = (list_id) => {
  return risikaAPI
    .post(
      `/list/remove`,
      { list_id },
      {
        microservice: 'monitor',
      }
    )
    .then(({ data }) => {
      return data
    })
}

export default monitorRemoveList
