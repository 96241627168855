import { Box, Typography, Divider } from '@mui/material'
import { Stack } from 'components'
import { ButtonPrimary } from 'components-new'
import InfoBox from './InfoBox'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from '../../styles/update-existing-subscriptions'
import EditSubscriptionOptions from './EditSubscriptionOptions'
import { Subscription } from 'services/api/endpoints/risikaAPI/new_subscription'
import { Dispatch, SetStateAction } from 'react'
import { Icon } from '@fortawesome/fontawesome-svg-core'
import { RiskMonitorListObject } from 'store_deprecated/types'

type Props = {
  setIsAddingNewSubscription: React.Dispatch<React.SetStateAction<boolean>>
  subscriptions: Subscription[] | undefined
  lists: RiskMonitorListObject
  deleteSubscription: (list_id: number) => void
  deleteButtonLoading: boolean
  setSubscriptions: Dispatch<SetStateAction<Subscription[] | undefined>>
}

const UpdateExistingSubscriptions = ({
  setIsAddingNewSubscription,
  subscriptions,
  setSubscriptions,
  lists,
  deleteSubscription,
  deleteButtonLoading,
}: Props) => {
  const classes = useStyles()

  const handleChangeSubscription = (data: Subscription, index: number) => {
    const nextSubscriptions = subscriptions?.map((subscription, i) => {
      if (i === index) {
        return data
      } else {
        return subscription
      }
    })
    setSubscriptions(nextSubscriptions)
  }
  return (
    <Stack direction="column" spacing={2}>
      <Box mt={1}>
        <InfoBox>
          <Typography variant="subtitle1">
            <FormattedMessage
              id={intl.riskMonitoringSubscription('receive-updates-title')}
            />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id={intl.riskMonitoringSubscription('receive-updates-description')}
            />
          </Typography>
        </InfoBox>
      </Box>
      <Box>
        <ButtonPrimary
          data-cy="add-notification-btn"
          startIcon={<FontAwesomeIcon icon={faBell as Icon} />}
          onClick={() => setIsAddingNewSubscription(true)}
        >
          <FormattedMessage id={intl.riskMonitoringNew('add-new-subscription')} />
        </ButtonPrimary>
      </Box>
      <Divider className={classes.divider} />
      <Typography variant="h4">
        <FormattedMessage id={intl.riskMonitoringNew('existing-subscriptions')} />
      </Typography>
      {subscriptions?.length ? (
        <Box data-cy="subscription-list">
          {subscriptions?.map(
            (subscription: Subscription, index) =>
              !subscription.disabled && (
                <EditSubscriptionOptions
                  key={subscription.list_id}
                  index={index}
                  subscription={subscription}
                  onChange={handleChangeSubscription}
                  name={lists?.[subscription.list_id]?.name}
                  deleteSubscription={deleteSubscription}
                  deleteButtonLoading={deleteButtonLoading}
                />
              )
          )}
        </Box>
      ) : (
        <Box className={classes.noSubscriptions}>
          <Typography>
            <FormattedMessage id={intl.riskMonitoringNew('no-subscriptions-text')} />
          </Typography>
        </Box>
      )}
    </Stack>
  )
}

export default UpdateExistingSubscriptions
