import { CommunicationModal } from 'components-new'
import { CreditPolicyResponse } from 'types/queries'
import intl from 'localization/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCreditPolicyCreate } from 'services/queries'
import { useSnackbar } from 'notistack'
import { navigation } from 'services/navigation'
import { getCreditPolicy } from 'services/api'
import { useHistory } from 'react-router-dom'
import { useBuilder } from '../../PolicyBuilder/BuilderContext'

type AlreadyActivePolicyDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  policyData: CreditPolicyResponse
  confirmSelection: () => void
  onClose: () => void
}

function EditActivePolicyDialog({
  open,
  setOpen,
  policyData,
  confirmSelection,
  onClose,
}: AlreadyActivePolicyDialogProps) {
  const { formatMessage, messages } = useIntl()
  const { mutateAsync: createCreditPolicy } = useCreditPolicyCreate()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const { state } = useBuilder()

  const handleEdit = (policyData: CreditPolicyResponse) => {
    if (policyData) {
      const { country, name, enabled, id } = policyData
      history.push(
        navigation.creditPolicyBuilder({
          country,
          name,
          createActiveRule: enabled,
          policyId: id,
        })
      )
    }
  }

  const handleEditPolicyCopy = (curentPolicyData: CreditPolicyResponse) => {
    const { description, policy, country } = curentPolicyData
    createCreditPolicy({
      name: `${policyData.name} (${
        messages[intl.riskMonitoringNew('copy-button-text')]
      })`,
      description,
      policy,
      country,
      enabled: false,
    })
      .then((data) => {
        enqueueSnackbar(
          <FormattedMessage id={intl.creditPolicyNew('duplicate-success')} />
        )
        getCreditPolicy(data.policy_id)
          .then((data) => {
            handleEdit(data)
            confirmSelection()
            // Not sure why we are mutating state directly when using the reducer hook here
            state.actionPanelData = {
              ...state.actionPanelData,
              open: false,
            }
            setOpen(false)
          })
          .catch((err) => console.log('error: ', err))
      })
      .catch(() => {
        enqueueSnackbar(<FormattedMessage id={intl.creditPolicyNew('duplicate-error')} />)
      })
  }

  const handleEditThisPolicy = () => {
    setOpen(false)
    confirmSelection()
  }

  return (
    <CommunicationModal
      title={'Edit an active policy'}
      body={
        <FormattedMessage
          id={intl.creditPolicyNew('edit-an-active-policy-body')}
          values={{
            lineBreak: <br />,
          }}
        />
      }
      open={open}
      setOpen={setOpen}
      mainButton={{
        content: formatMessage({
          id: intl.creditPolicyNew('edit-an-active-policy-edit-a-copy'),
        }),
        click: () => handleEditPolicyCopy(policyData),
      }}
      secondaryButton={{
        content: formatMessage({
          id: intl.creditPolicyNew('edit-an-active-policy-edit-this-version'),
        }),
        click: handleEditThisPolicy,
      }}
      onClose={onClose}
    />
  )
}

export default EditActivePolicyDialog
