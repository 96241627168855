import { AxiosRequestConfig } from 'axios'
import getUserSettings from 'services/api/endpoints/backendAPI/getUserSettings'
import { ModelObject } from '.'
import { getApiVersion } from '../getApiVersion'
import { getAvailableModelsInCountry, getModel } from './getModel'
import { getAccountStatusData } from 'utils/getAccountStatusData'

type buildTypes = {
  modelObject: ModelObject
  model: string
  appendScoringModel?: boolean
  appendCurrency?: boolean
}

function guessTheSign(url: string) {
  return url.includes('?') ? '&' : '?'
}

/**
 *  Builds the URL of each request to the Risika API. Adding microservice, version, country and the requested query params
 *
 * @param config AxiosRequestConfig. This is the request object provided by Axios along with some modifications described in the type declaration
 * @param modelObject Either BOHR or NEWTON for each country supported by the customer
 * @param model This is also either BOHR or NEWTON, but in the legacy format and it should be phased out at some point
 * @param appendScoringModel Appends the scoring model (BOHR | NEWTON) to the URL. Can be invoked in the parent function using appendScoringModelURL
 * @param appendCurrency Appends the user selected currency to the URL. Can be invoked in the parent function using appendCurrencyURL
 * @returns
 */

export const buildURL = async (
  config: AxiosRequestConfig,
  { modelObject, model, appendScoringModel = false, appendCurrency = false }: buildTypes
): Promise<string> => {
  const { baseURL, microservice, url: endpoint } = config
  const country = config?.country || undefined
  const version = getApiVersion(microservice)

  let url = `${baseURL}/${microservice ?? ''}/${version}/${
    country?.toLowerCase() ?? ''
  }/${endpoint}`

  if (appendCurrency) {
    const userSettings = await getUserSettings()
    const currencyForURL = `currency=${userSettings?.selectedCurrency?.currency ?? 'DKK'}`
    url += guessTheSign(url) + currencyForURL
  }

  if (appendScoringModel) {
    const status = getAccountStatusData()

    url += `${guessTheSign(url)}
      product=${getModel({
        country,
        availableModelsInCountry: getAvailableModelsInCountry(status?.rights),
        modelObject,
        model: model ?? 'BOHR',
      })}`
  }

  const indexOfFirstSlash = url.indexOf('//') + 2
  const firstPart = url.substring(0, indexOfFirstSlash)
  const restOfURL = url.substring(indexOfFirstSlash)

  return (
    firstPart + restOfURL.replaceAll(' ', '').replaceAll('///', '/').replaceAll('//', '/')
  )
}
