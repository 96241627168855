import { HumanDate } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import moment from 'moment'
import { Typography, Link, Stack, Divider } from '@mui/material'
import { classes } from 'pages/credit-check/styles/statstidende'
import RedWarningTriangle from 'pages/credit-check/scenes/MainRecommendation/elements/RedWarningTriangle'
import { StatstidendePropType } from './D3RisikaScore.types'

const Statstidende = ({ report, statusInfo }: StatstidendePropType) => {
  return (
    <Stack direction="column" alignItems="center">
      <RedWarningTriangle />
      <Divider flexItem />
      <Stack direction="column" sx={classes.subSection}>
        <Typography variant="h4" mb={3} textAlign="center">
          <FormattedMessage id={intl.mainRecommendation('statstidende-title')} />
        </Typography>
        <Stack direction="column" spacing={1}>
          <Typography>
            <b>
              <FormattedMessage id={intl.mainRecommendation('status-label')} />
            </b>
            {statusInfo.text}
          </Typography>
          <Typography>
            <b>
              <FormattedMessage id={intl.mainRecommendation('date')} />
            </b>
            <HumanDate date={moment(report?.date)} format="LL" />
          </Typography>
          {report?.content?.text && <Typography>{report?.content?.text}</Typography>}
          <Typography>
            <b>
              <FormattedMessage id={intl.mainRecommendation('case-id')} />
            </b>
            {report?.content?.case_id}
          </Typography>
          <Typography>
            <b>
              <FormattedMessage id={intl.mainRecommendation('statstidende-link')} />
            </b>
            <Link target="_blank" rel="noopener" href={report?.content?.link}>
              {report?.content?.link}
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Statstidende
