import {
  Box,
  Select,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  FormControl,
  Pagination,
  PaginationItem,
  useMediaQuery,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { defaultPageLimits } from '../Table.model'
import { TablePaginationToolbarProps } from './TableToolbar.types'
import { paginationStyle, pageLimitStyle } from './TableToolbar.styles'
import './TablePaginationToolbar.css'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const TablePaginationToolbar = ({
  page,
  totalPages,
  handlePage,
  perPageLimit,
  handlePageLimit,
  pageLimits = defaultPageLimits,
  title = <FormattedMessage id={intl.observationalLists('company-per-page')} />,
}: TablePaginationToolbarProps) => {
  const isSmallScreen = useMediaQuery('(max-width: 920px)')

  return (
    <Toolbar
      className="pagination-toolbar"
      sx={{ padding: '2rem', display: 'flex', flexDirection: 'column', gap: 3 }}
    >
      <Box sx={paginationStyle}>
        <Pagination
          variant="outlined"
          shape="rounded"
          size="large"
          count={totalPages}
          page={page}
          onChange={(event, page) => handlePage(page)}
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </Box>
      <Box sx={{ ...pageLimitStyle, position: isSmallScreen ? 'relative' : 'absolute' }}>
        <Box sx={{ mr: 2 }}>
          <Typography>{title}</Typography>
        </Box>
        <Stack width={100} direction="row">
          <FormControl fullWidth>
            <Select
              size="small"
              value={perPageLimit}
              onChange={(event) => handlePageLimit(event.target.value as number)}
              sx={{ '& .MuiSelect-select': { paddingRight: 0 } }}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon {...props} sx={{ mr: 2, fontSize: '2rem' }} />
              )}
            >
              {pageLimits.map((pageLimit: number) => (
                <MenuItem key={pageLimit} value={pageLimit}>
                  {pageLimit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </Toolbar>
  )
}

export default TablePaginationToolbar
