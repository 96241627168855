import * as React from 'react'
import { Stack } from '@mui/material'
import { DropdownRange, DropdownSelect } from 'components-new'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { useActionPanelStateValidator } from 'services/hooks'
import { equity, tolerance } from '../DetailedComponentView.model'
import { stateValidators } from '../DetailedComponentView.controller'
import { ToleranceAPIData, ToleranceDropdownProps } from './Actions.types'
import { InputRangeValue } from 'components-new/inputs/InputRange/InputRange.types'
import { ToleranceOptions } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.types'

const handleFitAPIdataToFormState = (apiData: ToleranceAPIData) => {
  const { value_a, value_b, tolerance: apiTolerance } = apiData
  return {
    tolerance: (apiTolerance || tolerance.options[0].value) as ToleranceOptions,
    value_a,
    value_b,
  }
}

// const handleTypeCasting = (apiData: ToleranceAPIData, value: string | number) => {
//   const { value_a } = apiData
//   if (typeof value_a === 'number') {
//     return Number(value)
//   }
//   return value
// }

const ToleranceDropdown = (
  { setIsConfirmEnabled, apiData, data, label }: ToleranceDropdownProps,
  ref: React.ForwardedRef<SubmitRef>
) => {
  const { state, actions } = useBuilder()

  const [formState, setFormState] = React.useState(handleFitAPIdataToFormState(apiData))
  useActionPanelStateValidator(
    formState,
    stateValidators.toleranceInputValidator,
    setIsConfirmEnabled
  )

  React.useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.setNodeApiData({
          nodeId: state.actionPanelData.nodeId,
          data: {
            ...formState,
            value_a: Number(formState.value_a),
            value_b: formState?.value_b ? Number(formState.value_b) : undefined,
          },
        })
      },
    }),
    [actions, formState, state.actionPanelData.nodeId]
  )

  const handleChange = (key: string, value: string) => {
    if (key === 'tolerance' && value !== 'between') {
      setFormState((prevState) => ({ ...prevState, value_b: null }))
    }
    setFormState((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleUpdateRange = ({ a, b }: InputRangeValue) => {
    handleChange('value_a', a as string)
    handleChange('value_b', b as string)
  }

  return (
    <Stack spacing={6}>
      <DropdownSelect
        inputProps={{
          label: tolerance.title,
        }}
        options={tolerance.options}
        value={formState.tolerance}
        setValue={(value: string | number) => handleChange('tolerance', value.toString())}
      />
      <Stack direction="row">
        <Stack direction="row" flex={1}>
          {formState.tolerance !== 'between' ? (
            <DropdownSelect
              inputProps={{
                label,
              }}
              options={data}
              value={formState.value_a}
              setValue={(value: string | number) =>
                handleChange('value_a', value.toString())
              }
            />
          ) : (
            <DropdownRange
              value={{ a: formState.value_a, b: formState.value_b ?? '' }}
              setValue={handleUpdateRange}
              options={data}
              // @ts-ignore // TODO: Ask Shtono about this
              inputFieldProps={{
                inputMode: 'numeric',
                // addOn: addonData.addOn,
                title: equity.value.title,
                label,
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default React.forwardRef(ToleranceDropdown)
