import risikaAPI from 'services/api/helpers/risikaAPI'

function localIdFormat({ id, country }) {
  return {
    id,
    country: country.toUpperCase(),
  }
}

// type NotesAddOptions = {
//   mode: 'overwrite' | 'append',
// }

export default function notesAdd(
  notes,
  options = { mode: 'overwrite' } // Change this to "append" later
  // notes: Note[],
  // options: NotesAddOptions = { mode: 'overwrite' } // Change this to "append" later
) {
  const alreadySeenLocalId = new Set()

  const { mode } = options
  const apiFormattedNotes = notes
    .filter((n) => n.localId != null)
    .map((note) => ({
      local_organization_id: localIdFormat(note.localId),
      data: note.data,
    }))
    .filter((note) => {
      const { id, country } = note.local_organization_id
      const localId = `${country}${id}`
      if (alreadySeenLocalId.has(localId)) {
        return false
      } else {
        alreadySeenLocalId.add(localId)
        return true
      }
    })

  return risikaAPI
    .post(
      `/notes/add`,
      {
        notes: apiFormattedNotes,
        mode,
      },
      {
        microservice: 'account',
      }
    )
    .then(({ data }) => {
      return data
    })
}
