import { useState, useEffect, Fragment, SyntheticEvent } from 'react'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Paper,
  Typography,
  TextField,
  Stack,
  ListItem,
  PaperProps,
  InputProps,
} from '@mui/material'
import FlagSelect from 'components-new/elements/FlagSelect'
import { searchCompany } from 'services/api'
import useTotangoTracking from 'utils/totangoTracking'
import { styles } from './AutocompleteCompany.styles'
import { useTheme } from '@mui/material/styles'
import { CompanyOptionType, AutocompleteCompanyProps } from './types'
import { useUserSettings } from 'services/queries'
import { CountryFlagIcon } from 'components-new'

const AutocompleteCompany = ({
  action,
  disableCountrySelect,
}: AutocompleteCompanyProps) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Array<any>>([])
  const [inputIsEmpty, setInputIsEmpty] = useState(true)
  const [focused, setFocused] = useState(false)
  const loading = open && options.length > 0
  const { trackEvent } = useTotangoTracking()
  const theme = useTheme()
  const { data } = useUserSettings()

  const selectedCountry = data?.selectedCountry ?? 'dk'

  const fetchCompanyValues = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      setInputIsEmpty(false)

      const companySearch = await searchCompany({
        searchText: inputValue,
        searchCountry: selectedCountry,
        active: true,
      })

      return companySearch.search_result.map((company) => ({
        name: company.company_name,
        id: company.local_organization_id.id,
        city: company.address.city,
        country: company.local_organization_id.country,
      }))
    }

    setInputIsEmpty(true)
  }

  const onChangeOptionsHandle = async (value: string) => {
    const fetchedOptions = await fetchCompanyValues(value)

    if (fetchedOptions) setOptions(fetchedOptions)
  }

  const onChangeHandle = (
    event: SyntheticEvent<any>,
    newValue: CompanyOptionType | null
  ) => {
    if (newValue) {
      trackEvent('Comparison', 'Manually Added Company')
      action(newValue)
    }
  }

  useEffect(() => {
    if (!open) setOptions([])
  }, [open])

  const CustomPaper = (props: PaperProps) => (
    <Paper {...props} elevation={1} sx={styles.paper} />
  )

  return (
    <Box sx={{ ...styles.autocompleteCompany(focused, disableCountrySelect) }}>
      {disableCountrySelect ? (
        <Stack p={3}>
          <CountryFlagIcon country={selectedCountry} />
        </Stack>
      ) : (
        <FlagSelect />
      )}
      <Autocomplete
        sx={styles.autocomplete}
        fullWidth
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(event, newValue) => onChangeHandle(event, newValue)}
        getOptionLabel={(option: CompanyOptionType) => `${option.name} (${option.id})`}
        options={options}
        loading={loading}
        PaperComponent={CustomPaper}
        renderOption={(props, option: CompanyOptionType) => (
          <ListItem {...props}>
            <Stack sx={{ fontSize: '1.4rem' }}>
              <Box sx={{ width: 1 }}>
                <Typography variant="h5">{option.name}</Typography>
              </Box>
              <Box sx={{ width: 1, color: theme.palette.grey[600] }}>
                <Typography variant="h5" component="span">
                  {option.city}
                </Typography>
                {',\u00A0'}
                <Typography variant="h5" component="span">
                  {option.id}
                </Typography>
                {'\u00A0('}
                <Typography variant="h5" component="span">
                  {option.country}
                </Typography>
                {')'}
              </Box>
            </Stack>
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            sx={{ margin: 'auto' }}
            variant="outlined"
            onChange={(event) => {
              if (event.target.value) {
                onChangeOptionsHandle(event.target.value)
              }
            }}
            InputProps={
              {
                ...params.InputProps,
                'data-cy': 'autocomplete-company-input',
                sx: styles.input,
                endAdornment: (
                  <Fragment>
                    {open && options.length > 0 && !inputIsEmpty ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                  </Fragment>
                ),
              } as InputProps
            }
          />
        )}
      />
    </Box>
  )
}

export default AutocompleteCompany
