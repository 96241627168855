import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { Column } from './SimpleTable.types'

type SimpleTableProps<T> = {
  columns: Column<T>[]
  data: T[]
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[50],
  },
})) as typeof TableRow

function TableLoading<T>({ data, columns }: SimpleTableProps<T>) {
  const theme = useTheme()
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell
                size="small"
                sx={{
                  borderRight: `1px solid ${theme.palette.grey[200]}`,
                  width: `${column.width ?? 'auto'}`,
                }}
                key={column.id}
              >
                {column.headerComponent ? column.headerComponent() : column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              {columns.map((column, i) => (
                <TableCell
                  align={column.align ?? 'inherit'}
                  size="small"
                  sx={{
                    borderRight: `1px solid ${theme.palette.grey[200]}`,
                  }}
                  key={column.id}
                >
                  <Skeleton>{column.bodyComponent(item, i)}</Skeleton>
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableLoading
