import { CSSProperties } from 'react'
import { SxProps } from '@mui/system'

const panelPaddingX = 5

const container: SxProps = {
  width: 390,
  height: '100%',
}
const titleContainer: SxProps = {
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingX: panelPaddingX,
  paddingY: 4,
  flexDirection: 'row',
}
const title: SxProps = {}

const closeButton: SxProps = {
  alignSelf: 'flex-end',
  fontSize: 29,
  color: 'common.black',
}
const inputContainer: SxProps = {
  gap: 6,
  padding: panelPaddingX,
  justifyContent: 'start',
}
const inputSelect: SxProps = {
  backgroundColor: 'transparent',
  borderRadius: 1,
  border: '1px solid',
  borderColor: 'grey.200',
  '.MuiSelect-filled': {
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
}

const loadingIcon: SxProps = {
  animation: '1s linear infinite rotateIt',
  '@keyframes rotateIt': {
    '100%': {
      rotate: '-360deg',
    },
  },
}

const inputListItemSelect: SxProps = {
  backgroundColor: 'primary.100',
  borderColor: 'transparent',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: ' 0px 0px 4px 4px',
  '& .MuiListItemIcon-root': {
    color: 'primary.700',
    minWidth: 30,
  },
  '& .MuiTypography-root ': {
    color: 'primary.700',
  },
  '&:hover': {
    backgroundColor: 'primary.300',
  },
  '&:focus': {
    backgroundColor: 'primary.100',
    borderColor: 'primary.500',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: ' 0px 0px 4px 4px',
  },
  '&.Mui-disabled': {
    backgroundColor: 'grey.300',
    opacity: 1,
    '& .MuiTypography-root ': {
      color: 'grey.600',
    },
    '& .MuiListItemIcon-root': {
      color: 'grey.600',
    },
  },
}

const inputText: SxProps = {
  backgroundColor: 'white',
  borderWidth: 1,
  borderColor: 'grey.200',
  borderRadius: 1,
  '.MuiInputBase-input': { padding: 0 },
  '.MuiInput-input': { padding: 0 },
}
// class="MuiInputBase-input MuiInput-input css-1x51dt5-MuiInputBase-input-MuiInput-input"

const menuItem = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
}

const textArea: CSSProperties = {
  backgroundColor: 'white',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#E9ECEF',
  padding: 12,
  borderRadius: 4,
  fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  fontSize: 16,
  letterSpacing: 0,
  lineHeight: '24px',
  color: '#000000',
}
const checkBoxContainer: SxProps = {
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
}
const checkbox: SxProps = {
  padding: 1,
  '& .MuiSvgIcon-root': { fontSize: 21.33 },
}

const styles = {
  container,
  titleContainer,
  title,
  closeButton,
  inputContainer,
  menuItem,
  inputSelect,
  inputListItemSelect,
  inputText,
  textArea,
  checkBoxContainer,
  checkbox,
  loadingIcon,
}

export default styles
