import { InputField } from 'components-new'
import { availableCountriesAndCurrencies } from 'components-new/inputs/CurrencyAutocomplete/CurrencyAutocomplete.model'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import React from 'react'

const ResultAfterTaxInput = ({ isAdvanced = true }) => {
  const { policy, setPolicy, isInferiorPlan, selectedCountry } = useCountryPolicy()
  const defVal = policy.profit_loss ?? 0
  const [val, setVal] = React.useState<number>(defVal)
  const currency = availableCountriesAndCurrencies?.filter(
    (countryCurr) => countryCurr.country === selectedCountry?.toUpperCase()
  )[0]

  // this is needed to set the initial value after it's been null
  React.useEffect(() => {
    if (typeof policy.profit_loss === 'number') {
      setVal(policy.profit_loss)
    }
  }, [policy.profit_loss])

  React.useEffect(() => {
    if (isAdvanced && !isInferiorPlan) {
      setPolicy({ ...policy, profit_loss: Number(val) })
    }
  }, [val])

  return (
    <InputField
      value={val}
      addOn={currency?.currency}
      handleChange={function (value: string): void {
        if (isNaN(Number(value))) return
        setVal(Number(value))
      }}
      disabled={isAdvanced && isInferiorPlan}
    />
  )
}

export default ResultAfterTaxInput
