import { colors } from 'configs'
import { defaultStackedBarChartProperties } from 'configs/charts'

export const chartProperties = {
  ...defaultStackedBarChartProperties,
  width: 300,
  height: 200,
  margin: { top: 30, right: 20, bottom: 5, left: 20 },
  cartesianGrid: false,
  xAxis: true,
  yAxis: false,
  tooltip: true,
  referenceLineStrokeWidth: 0.1,
  bar: { stackId: 'a', radius: [0, 0, 0, 0], label: true, barSize: 10 },
  colors: [
    colors.riskIntelligence.highRisk,
    colors.riskIntelligence.mediumRisk,
    colors.riskIntelligence.lowRisk,
  ],
}

export const parameters = {
  list_id: 0,
  score_model: {
    DK: 'NEWTON ',
    NO: 'BOHR',
    SE: 'BOHR',
  },
  categories: [{ category: 'RISK_ASSESSMENT', function: 'COUNT' }],
  sample_type: 'END_OF_MONTH_INCOMPLETE',
  historic_monitor: true,
}
