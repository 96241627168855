import React from 'react'

import { TableRow, TableCell } from '@mui/material'
import Typography from '@mui/material/Typography'
import { removeDuplicates } from 'utils'
import moment from 'moment'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const stripFunctions = (array, roles) => {
  return array?.map((person) => ({
    ...person,
    functions: person.functions.filter((x) => roles.includes(x.function)),
  }))
}

const ManagementTableFooter = ({ data, managementRoles }) => {
  const [years, setYears] = React.useState(0)
  const [months, setMonths] = React.useState(0)

  React.useEffect(() => {
    let localTotal = 0
    stripFunctions(removeDuplicates(data, 'name'), managementRoles)?.forEach((x) => {
      x?.functions?.forEach((func) => {
        localTotal = localTotal + moment().diff(func.valid_from, 'months')
      })
    })
    setYears(parseInt(localTotal / 12))
    setMonths(localTotal % 12)
  }, [data, managementRoles])

  return (
    <TableRow>
      <TableCell rowSpan={1} />
      <TableCell rowSpan={1} />
      <TableCell rowSpan={1} />
      <TableCell colSpan={4}>
        <Typography variant="body2">
          <FormattedMessage id={intl.companyRoles('total')} />
          {years !== 0 ? (
            years > 1 ? (
              <FormattedMessage id={intl.companyRoles('years')} values={{ years }} />
            ) : (
              <FormattedMessage id={intl.companyRoles('year')} />
            )
          ) : (
            ''
          )}
          {months !== 0 ? (
            months > 1 ? (
              <FormattedMessage id={intl.companyRoles('months')} values={{ months }} />
            ) : (
              <FormattedMessage id={intl.companyRoles('month')} />
            )
          ) : (
            ''
          )}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default ManagementTableFooter
