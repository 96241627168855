export type LevelOfAuditorStateT = {
  noAuditorAssistance: boolean
  otherNonAssuranceReports: boolean
  review: boolean
  extendedReview: boolean
  auditorsReport: boolean
  compilationFinancialStatements: boolean
}

export type LevelOfAuditorMappingItem = {
  stateField: keyof LevelOfAuditorStateT
  label: string
}

export const defState: LevelOfAuditorStateT = {
  noAuditorAssistance: false,
  otherNonAssuranceReports: false,
  review: false,
  extendedReview: false,
  auditorsReport: false,
  compilationFinancialStatements: false,
}

export const mapping: LevelOfAuditorMappingItem[] = [
  { stateField: 'noAuditorAssistance', label: 'NO AUDITOR ASSISTANCE' },
  {
    stateField: 'otherNonAssuranceReports',
    label: "AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)",
  },
  { stateField: 'review', label: "THE INDEPENDENT AUDITOR'S REPORTS (REVIEW)" },
  { stateField: 'extendedReview', label: "AUDITOR'S REPORT ON EXTENDED REVIEW" },
  { stateField: 'auditorsReport', label: "AUDITOR'S REPORT" },
  {
    stateField: 'compilationFinancialStatements',
    label: 'COMPILATION OF FINANCIAL STATEMENTS',
  },
]
