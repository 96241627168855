import callBackendAPI from 'services/api/helpers/backendAPI'
import { AllowedFields } from 'services/types'
// To call this function you need to use one of the allowed types.
// Import the one you need from this file and pass it as a generic type
/**
 * LanguageResponse
 * CreditCheckResponse
 * CompanyInfoResponse
 * MonitorTableResponse
 * CreditCheckSidebarResponse
 * SelectedCountryResponse
 * SelectedCurrencyResponse
 * FiltersResponse
 * ExportOptionsFilteringResponse
 * ExportOptionsMonitoringResponse
 * MonitorTableEcpOrderType
 */

function getUserSettingsField<T>(field: AllowedFields): Promise<T> {
  return callBackendAPI(`/user/settings/${field}`, {
    method: 'get',
  }).then(({ data }) => data[field])
}

export default getUserSettingsField
