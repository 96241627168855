import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '3rem',
  },
  lazyLoadImage: {
    marginLeft: '2rem',
    borderRadius: '0.25rem',
    width: '2.5rem',
  },
}))
