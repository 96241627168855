import { Box, Checkbox, IconButton, Stack } from '@mui/material'
import { classes } from '../Table.styles'
import { useIntl } from 'react-intl'
import { ReactSetState } from '../../../../types/general'
import TableHeaderCellLoading from './TableHeaderCell.loading'

const TableHeaderCell = ({
  headCell,
  handleSortBy,
  toggleAllCheckboxes,
  isSelectedAll,
  setIsSelectedAll,
  isIndeterminate,
}: {
  headCell: any
  handleSortBy: any
  toggleAllCheckboxes: Function
  isSelectedAll: boolean
  setIsSelectedAll: ReactSetState<boolean>
  isIndeterminate: boolean
}) => {
  const { messages: translate } = useIntl()

  if (headCell.type === 'loading') {
    return <TableHeaderCellLoading headCell={headCell} />
  }

  return (
    <Box sx={classes.table.header}>
      <Stack
        alignItems="center"
        direction="row"
        className="container"
        sx={{ height: 1, width: 1, minWidth: headCell.minWidth }}
      >
        <Box data-cy={`header-cell-${headCell.key}`} sx={{ flex: 1 }}>
          {translate[headCell?.label] as React.ReactNode}
        </Box>
        {headCell.key !== 'checkbox' && (
          <Box sx={classes.table.columnArrangement}>
            <IconButton
              sx={classes.table.sortingArrow}
              onClick={() => handleSortBy(headCell.key, 'ASCENDING')}
              data-testid={`${headCell.label}-header`}
            >
              <img width="10" src="risika/triangle.svg" />
            </IconButton>

            <IconButton
              sx={{ ...classes.table.sortingArrow, transform: 'rotate(180deg)' }}
              onClick={() => handleSortBy(headCell.key, 'DESCENDING')}
            >
              <img width="10" src="risika/triangle.svg" />
            </IconButton>
          </Box>
        )}
        {headCell.key === 'checkbox' && (
          <Stack sx={{ width: 1, height: 1 }}>
            <Checkbox
              sx={{ padding: 0, width: 1, height: '90%' }}
              checked={isSelectedAll}
              indeterminate={isIndeterminate}
              onClick={() => {
                toggleAllCheckboxes(!isSelectedAll)
                setIsSelectedAll(!isSelectedAll)
              }}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  )
}

export default TableHeaderCell
