import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const HighlightsBoxSkeleton = () => (
  <Paper component={Box} px={4} py={5}>
    <Stack p={4} direction="row">
      <Typography variant="h4">
        <FormattedMessage id={intl.companyHighlights('title')} />
      </Typography>
    </Stack>
    <Box>
      {Array(10)
        .fill(null)
        .map((_item, i) => (
          <Stack
            bgcolor={i % 2 === 0 ? 'common.white' : 'grey.50'}
            direction="row"
            alignItems="center"
            key={i}
          >
            <Box flex={1}>
              <Skeleton />
            </Box>
            <Stack
              flex={1}
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              sx={{ borderRight: '1px solid', borderRightColor: 'grey.200' }}
            >
              <Box flex={1} p={3}>
                <Skeleton />
              </Box>
            </Stack>
          </Stack>
        ))}
    </Box>
  </Paper>
)

export default HighlightsBoxSkeleton
