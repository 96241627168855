import { useQuery } from 'react-query'
import { getAxiosData } from './useAccountStatus'
import { getHighlightsFilters } from 'services/api/endpoints/risikaAPI/filters/policyHighlights'

const HIGHLIGHTS = 'highlights'

export function useHighlightsFilters() {
  return useQuery([HIGHLIGHTS], () => getAxiosData(getHighlightsFilters()), {
    enabled: true,
    refetchOnWindowFocus: false,
  })
}
