import { WebApiError } from 'types/general'
import { useMutation, useQueryClient } from 'react-query'
import updateUser, { UpdateUserData } from 'services/api/endpoints/backendAPI/updateUser'
import { useSnackbar } from 'notistack'

export function useUpdateUser() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation<Response, WebApiError, UpdateUserData>(updateUser, {
    onSuccess: () => {
      queryClient.refetchQueries('allUsers')
      queryClient.refetchQueries('userSelf')
    },
    onError: (err) => {
      enqueueSnackbar(`${err?.response?.data?.error?.message}`, {
        variant: 'error',
      })
    },
  })
}
