import * as React from 'react'

import { useState, useEffect } from 'react'

import { Stack, ConditionallyRender, Sidescroll } from 'components'
import { ButtonSecondary } from 'components-new'

import { Box, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { setManagementData } from './components/setManagementData'
import { setHistoricalManagementData } from './components/setHistoricalManagementData'
import ManagementTableFooter from './components/ManagementTableFooter'

import { default as cx } from 'classnames'

import RolesTable from 'pages/credit-check/scenes/RolesTable'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useCreditCheck } from 'pages/credit-check/context'
import { useCompanyRelations, useHighlights } from 'services/queries'
import useTotangoTracking from 'utils/totangoTracking'
import { isCallAllowed } from 'configs'
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 0,
    position: 'relative',
  },
  content: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  historicalButton: {
    alignSelf: 'flex-end',
  },
  col: {
    marginRight: theme.spacing(4),
    '&:last-child': {
      marginRight: 0,
    },
  },
  section_title: {
    fontWeight: 600,
  },
  subtitle: {
    fontWeight: 700,
  },
  companyHierarchyButton: {
    height: '4rem',
  },
  progressBar: {
    width: '7rem',
    height: '1rem',
    border: '1px solid black',
    borderRadius: '1000rem',
  },
  safari: {
    '@media screen and (max-width: 1593px)': {
      width: '150vw',
    },
  },
}))

const managementHeaders = [
  { text: 'name' },
  { text: 'position' },
  { text: 'from' },
  { text: 'seniority' },
  { text: 'otherPosition' },
  { text: 'pep' },
  { text: 'highlights' },
]
const managementHeadersDe = [
  { text: 'name', size: '20.5%' },
  { text: 'position', size: '21%' },
  { text: 'from', size: '13%' },
  { text: 'seniority', size: '13%' },
  { text: 'otherPosition', size: '8%' },
]
const managementHeadersNo = [
  { text: 'name' },
  { text: 'position' },
  { text: 'from' },
  { text: 'seniority' },
  { text: 'otherPosition' },
]
const managementHeadersSe = [
  { text: 'name' },
  { text: 'position' },
  { text: 'from' },
  { text: 'seniority' },
  { text: 'otherPosition' },
]

const historicalManagementHeaders = [
  { text: 'name' },
  { text: 'position' },
  { text: 'from' },
  { text: 'to' },
  { text: 'otherPosition' },
]

const managementRoles = [
  'MANAGEMENT',
  'ADMINISTRATION',
  'CHIEF EXECUTIVE OFFICER',
  'BOARD OF DIRECTORS',
  'CHAIRMAN',
  'DEPUTY CHAIRMAN',
  'DEPUTY',
  'STAKEHOLDER',
]

const getCorrectManagementHeaders = (c) => {
  const country = c.toLowerCase()
  if (country === 'dk') {
    return managementHeaders
  }
  if (country === 'se') {
    return managementHeadersSe
  }
  if (country === 'no') {
    return managementHeadersNo
  }
  if (country === 'de') {
    return managementHeadersDe
  }
  return managementHeaders
}
//
// type Props = {
//   isHierarchyAvailable: boolean,
//   isShowMore: boolean,
//   setIsRendering: Function, // Handles the rendering of the parent component (SceneWrapper)
//   setNotExpandable: Function,
// }

export const CompanyRoles = ({ isShowMore, setIsRendering, setNotExpandable }) => {
  const classes = useStyles()
  const { localId } = useCreditCheck()
  const { country } = localId ?? 'dk'
  const companyRelationsQuery = useCompanyRelations(localId)
  const companyHighlightsQuery = useHighlights(localId)
  const data = companyRelationsQuery.data
  const boardOfDirectors = data?.boardOfDirectors ?? []
  const management = data?.management ?? []
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const { trackEvent } = useTotangoTracking()

  const managementData = setManagementData(
    managementRoles,
    [...management, ...boardOfDirectors],
    companyHighlightsQuery.data?.connected_bankruptcies?.data,
    classes,
    country
  )

  React.useEffect(() => {
    setNotExpandable(managementData?.length <= 10)
  }, [managementData?.length, setNotExpandable])

  const historicalManagementData = setHistoricalManagementData(
    managementRoles,
    [...management, ...boardOfDirectors],
    classes,
    country
  )

  const [isHistoricalData, setIsHistoricalData] = useState(false)

  const handleSeeHistoricalData = () => {
    if (!isHistoricalData) {
      trackEvent('Credit Check', 'Expand Historical Management')
    }
    setIsHistoricalData(!isHistoricalData)
  }

  useEffect(() => {
    if (!managementData.length && historicalManagementData.length) {
      setIsHistoricalData(true)
    }
  }, [historicalManagementData, managementData])

  if (companyRelationsQuery.isLoading) {
    return <CircularProgress />
  }

  if (!managementData?.length && !historicalManagementData.length) {
    setIsRendering(false)
    return null
  }

  if (managementData.length || historicalManagementData.length) {
    return (
      <Stack
        data-cy="management-component"
        direction="column"
        flex="1"
        style={{ overflow: 'hidden', padding: '28px 20px 0 20px' }}
      >
        <div className={cx(classes.content, 'content')}>
          <Box display="flex" justifyContent="space-between">
            <ConditionallyRender
              condition={managementData.length && historicalManagementData.length}
              when={
                <ButtonSecondary onClick={() => handleSeeHistoricalData()}>
                  {
                    <FormattedMessage
                      id={intl.companyRoles(
                        !isHistoricalData ? 'see-historical-data' : 'hide-historical-data'
                      )}
                    />
                  }
                </ButtonSecondary>
              }
            />
          </Box>

          <ConditionallyRender
            condition={Boolean(historicalManagementHeaders.length) && isHistoricalData}
            // Shows historical management
            when={
              <Sidescroll conditionalySidescroll maxWidth={'1000px'}>
                <RolesTable
                  title={'historical-management'}
                  tableHeaders={historicalManagementHeaders}
                  data={historicalManagementData}
                  isShowAll={true}
                />
              </Sidescroll>
            }
            //
            // Shows present management
            otherwise={
              <ConditionallyRender
                condition={Boolean(managementData.length)}
                when={
                  <Sidescroll conditionalySidescroll maxWidth={'1593px'} data-scroll>
                    <div className={isSafari ? classes.safari : ''}>
                      <RolesTable
                        title={'management'}
                        tableHeaders={getCorrectManagementHeaders(country) ?? []}
                        data={managementData}
                        isShowAll={isShowMore}
                        footer={
                          isCallAllowed('companyRelations', country.toUpperCase()) ? (
                            <ManagementTableFooter
                              data={[...management, ...boardOfDirectors]}
                              managementRoles={managementRoles}
                            />
                          ) : null
                        }
                      />
                    </div>
                  </Sidescroll>
                }
              />
            }
          />
        </div>
      </Stack>
    )
  } else {
    return null
  }
}

export default CompanyRoles
