import { useMutation, useQueryClient } from 'react-query'
import { monitorVisibilityUpdate } from 'services/api'
import { MonitorVisibilityUpdateType } from 'services/api/endpoints/risikaAPI/newMonitoring/monitorVisibilityUpdate'
import { RisikaApiError } from 'types/general'
import { MONITOR_LIST } from './lists/constants'

// way to go TS + useMutation
// https://stackoverflow.com/questions/65032226/how-to-declare-usemutation-return-type-in-react-query
// <Response, Error, Variables>
export function useMonitorVisibilityUpdate() {
  const queryClient = useQueryClient()

  return useMutation<Response, RisikaApiError, MonitorVisibilityUpdateType>(
    monitorVisibilityUpdate,
    {
      onSuccess: () => {
        queryClient.refetchQueries(MONITOR_LIST)
      },
    }
  )
}
