import React, { useEffect } from 'react'
import { DropdownSelect } from 'components-new'
import mapping from './model'
import { fromLabelToValue, fromValueToLabel } from '../controller'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { CountryPolicyContextType } from 'pages/advanced-credit-policy/types'

const options = [
  { label: <FormattedMessage id={intl.creditPolicyNew('none')} />, value: 'none' },
  { label: <FormattedMessage id={intl.creditPolicyNew('1')} />, value: '1' },
  { label: <FormattedMessage id={intl.creditPolicyNew('2')} />, value: '2' },
  { label: <FormattedMessage id={intl.creditPolicyNew('3')} />, value: '3' },
  { label: <FormattedMessage id={intl.creditPolicyNew('4')} />, value: '4' },
  { label: <FormattedMessage id={intl.creditPolicyNew('5')} />, value: '5' },
  { label: <FormattedMessage id={intl.creditPolicyNew('6')} />, value: '6' },
  { label: <FormattedMessage id={intl.creditPolicyNew('7')} />, value: '7' },
  {
    label: <FormattedMessage id={intl.creditPolicyNew('8-and-more')} />,
    value: '8-and-more',
  },
]
type ConnectedBankruptciesLongProps = {
  policyInject: () => CountryPolicyContextType
  isAdvanced?: boolean
}

const ConnectedBankruptciesLong = ({
  policyInject,
  isAdvanced = true,
}: ConnectedBankruptciesLongProps) => {
  const { policy, setPolicy, isInferiorPlan } = policyInject()
  const defVal = fromValueToLabel(policy.bankruptcy_limit_long, mapping)
  const [val, setVal] = React.useState(defVal)

  useEffect(() => {
    setVal(fromValueToLabel(policy?.bankruptcy_limit_long, mapping))
  }, [policy])

  useEffect(() => {
    const newBankruptcyLimit = fromLabelToValue(val, mapping)
    if (val !== defVal) {
      setPolicy({ ...policy, bankruptcy_limit_long: newBankruptcyLimit as number })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPolicy, val])

  return (
    <DropdownSelect
      placeholder={<FormattedMessage id={intl.creditPolicyNew('please-select')} />}
      options={options}
      value={val}
      setValue={setVal}
      disabled={isAdvanced && isInferiorPlan}
    />
  )
}

export default ConnectedBankruptciesLong
