import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Select, MenuItem, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { useAppShell } from './context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons'
import { ReduxRootState } from 'store_deprecated/types'
import { ResultType } from './types'
import { useTheme } from '@mui/material/styles'
import { Icon } from '@fortawesome/fontawesome-svg-core'
import { SelectChangeEvent } from '@mui/material/Select'

const Selector = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const appShell = useAppShell()
  const { companyCount, peopleCount } = useSelector(
    (state: ReduxRootState) => ({
      companyCount: state.risika.searchBusiness.companyCount,
      peopleCount: state.risika.searchBusiness.peopleCount,
    }),
    shallowEqual
  )
  const reactIntl = useIntl()

  const options = useMemo(
    () => [
      {
        value: 'company',
        label: {
          id: 'selector-companies',
          values: {
            count: companyCount,
            countDisplay: `${reactIntl.formatNumber(companyCount)}${
              companyCount >= 50000 ? '+' : ''
            }`,
          },
        },
        icon: faBuilding,
      },
      {
        value: 'person',
        label: {
          id: 'selector-people',
          values: { count: peopleCount },
        },
        icon: faUser,
      },
    ],
    [companyCount, peopleCount, reactIntl]
  )

  const handleResultType = (event: SelectChangeEvent<'company' | 'person'>) => {
    appShell.setResultType(event.target.value as ResultType)
  }

  return (
    <Select
      data-cy="searchBarSelect"
      variant="standard"
      sx={{
        border: 'none',
        paddingRight: '0',
        svg: { marginRight: 0, fill: `${theme.palette.grey[600]}` },
      }}
      disableUnderline
      value={appShell.resultType}
      onChange={handleResultType}
    >
      {options.map((option) => (
        <MenuItem
          key={option.label.id}
          sx={{
            '&.Mui-selected': { backgroundColor: 'grey.200' },
            '&:hover': {
              backgroundColor: `${theme.palette.grey[100]} !important`,
            },
          }}
          value={option.value}
        >
          {isSmallScreen ? (
            <FontAwesomeIcon icon={option.icon as Icon} />
          ) : (
            <Typography variant="body1">
              <FormattedMessage
                id={intl.searchBusiness(option.label.id)}
                values={option.label.values}
              />
            </Typography>
          )}
        </MenuItem>
      ))}
    </Select>
  )
}
export default Selector
