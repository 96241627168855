import * as React from 'react'
import { IconButton, TableCell, TableRow } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Collapse from '@mui/material/Collapse'
import { ExpandableRowProps } from './ExpandableRow.types'
import { classes } from '../../Table.styles'
import { getRestructureChanges } from '../Changes/Changes.controller'

const ExpandableRow = ({ content, colSpan, children, dataCy }: ExpandableRowProps) => {
  const [open, setOpen] = React.useState(false)

  if (!content) return null
  const restructuredChanges = getRestructureChanges(
    (content as JSX.Element).props.changes
  )
  return (
    <React.Fragment>
      <TableRow data-cy={dataCy} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={classes.table.tableCell}>
          <IconButton
            // @ts-ignore // This is library error
            sx={!restructuredChanges?.length && { visibility: 'hidden' }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {/* This is the expandable row */}
      <TableRow>
        <TableCell sx={classes.table.tableCell} colSpan={colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {content}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default ExpandableRow
