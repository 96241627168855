import moment from 'moment'

import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'
import { Numbers } from 'risika-api-response-types/financial/numbers'
/******************
 * Fetch from API *
 ******************/

export interface FinancialNumbers {
  period: {
    start: string
    end: string
  }
  assets?: number
  cash?: number
  contributed_capital?: number
  costs?: number
  current_assets?: number
  current_financial_assets?: number
  current_prepayments?: number
  debt?: number
  deferred_tax?: number
  depreciation?: number
  dividend?: number
  ebit?: number
  ebitda?: number
  equity?: number
  equity_before_minority_interests?: number
  equity_loan?: number
  extraordinary_item?: number
  goodwill?: number
  gross_result?: number
  intangible_assets?: number
  interest_expenses?: number
  interest_income?: number
  inventories?: number
  land_and_buildings?: number
  liabilities_and_equity?: number
  long_term_debt?: number
  long_term_debt_to_banks?: number
  long_term_debt_to_group_enterprises?: number
  long_term_mortgage_debt?: number
  minority_interests?: number
  net_financial_income?: number
  noncurrent_assets?: number
  noncurrent_financial_assets?: number
  noncurrent_investments?: number
  noncurrent_receivables?: number
  operating_costs?: number
  ordinary_profit?: number
  other_income?: number
  other_intangible_assets?: number
  other_long_term_debt?: number
  other_net_financial_income?: number
  other_noncurrent_financial_assets?: number
  other_operating_income?: number
  other_property_plant_and_equipment?: number
  other_short_term_debt?: number
  other_short_term_receivables?: number
  plant_equipment_and_fixtures?: number
  profit_loss?: number
  profit_loss_before_tax?: number
  property_plant_and_equipment?: number
  provisions?: number
  reserves?: number
  retained_earnings?: number
  revenue?: number
  short_term_debt?: number
  short_term_debt_to_banks?: number
  short_term_debt_to_group_enterprises?: number
  short_term_mortgage_debt?: number
  short_term_receivables?: number
  short_term_receivables_from_group_enterprises?: number
  short_term_receivables_from_sales_and_services?: number
  short_term_tax_payables?: number
  short_term_trade_payables?: number
  staff_expenses?: number
  tax_expenses?: number
}

export type FinancialNumbersResponse = Numbers

export type FinancialNumbersData = FinancialNumbersResponse

type Options = {
  consolidated?: boolean
  currency?: string
  appendCurrencyURL?: boolean
}

type ResponseData = {
  data: Numbers
}

/***********
 * Helpers *
 ***********/

/**
 * Sort financial statements by newest.
 */
function sortByNewest(a: FinancialNumbers, b: FinancialNumbers) {
  return moment(b.period.end).diff(a.period.end)
}

export type FinancialNumbersReturnType = Awaited<ReturnType<typeof financialNumbers>>

/**
 * Gets all the _financial numbers_ for a given company.
 *
 * @param {object} id _Local ID_ and _Country Code_ of the company.
 * @param country
 * @param options
 * @returns {object} All the different _financial numbers_ associated with the company.
 */
async function financialNumbers(
  { id, country }: LocalId,
  options?: Options,
  appendCurrencyURL: boolean = true
) {
  const { consolidated = false } = options ?? {}

  const consolidatedText = consolidated ? 'true' : 'false'

  let endpoint = `/financial/numbers/${id}`
  if (country && ['dk', 'no', 'se', 'fi'].includes(country?.toLowerCase())) {
    endpoint += `?consolidated_statement=${consolidatedText}`
  }

  const { data }: ResponseData = await risikaAPI.get(endpoint, {
    microservice: 'data',
    country,
    appendCurrencyURL,
  })

  const parseNumber = (number?: number) => {
    if (number) {
      return parseInt(number.toString())
    }
    return number
  }

  return Array.isArray(data)
    ? data.sort(sortByNewest).map((x: FinancialNumbers) => ({
        period: x.period,
        assets: parseNumber(x.assets),
        cash: parseNumber(x.cash),
        contributed_capital: parseNumber(x.contributed_capital),
        costs: parseNumber(x.costs),
        current_assets: parseNumber(x.current_assets),
        current_financial_assets: parseNumber(x.current_financial_assets),
        current_prepayments: parseNumber(x.current_prepayments),
        debt: parseNumber(x.debt),
        deferred_tax: parseNumber(x.deferred_tax),
        depreciation: parseNumber(x.depreciation),
        dividend: parseNumber(x.dividend),
        ebit: parseNumber(x.ebit),
        ebitda: parseNumber(x.ebitda),
        equity: parseNumber(x.equity),
        equity_before_minority_interests: parseNumber(x.equity_before_minority_interests),
        equity_loan: parseNumber(x.equity_loan),
        extraordinary_item: parseNumber(x.extraordinary_item),
        goodwill: parseNumber(x.goodwill),
        gross_result: parseNumber(x.gross_result),
        intangible_assets: parseNumber(x.intangible_assets),
        interest_expenses: parseNumber(x.interest_expenses),
        interest_income: parseNumber(x.interest_income),
        inventories: parseNumber(x.inventories),
        land_and_buildings: parseNumber(x.land_and_buildings),
        liabilities_and_equity: parseNumber(x.liabilities_and_equity),
        long_term_debt: parseNumber(x.long_term_debt),
        long_term_debt_to_banks: parseNumber(x.long_term_debt_to_banks),
        long_term_debt_to_group_enterprises: parseNumber(
          x.long_term_debt_to_group_enterprises
        ),
        long_term_mortgage_debt: parseNumber(x.long_term_mortgage_debt),
        minority_interests: parseNumber(x.minority_interests),
        net_financial_income: parseNumber(x.net_financial_income),
        noncurrent_assets: parseNumber(x.noncurrent_assets),
        noncurrent_financial_assets: parseNumber(x.noncurrent_financial_assets),
        noncurrent_investments: parseNumber(x.noncurrent_investments),
        noncurrent_receivables: parseNumber(x.noncurrent_receivables),
        operating_costs: parseNumber(x.operating_costs),
        ordinary_profit: parseNumber(x.ordinary_profit),
        other_income: parseNumber(x.other_income),
        other_intangible_assets: parseNumber(x.other_intangible_assets),
        other_long_term_debt: parseNumber(x.other_long_term_debt),
        other_net_financial_income: parseNumber(x.other_net_financial_income),
        other_noncurrent_financial_assets: parseNumber(
          x.other_noncurrent_financial_assets
        ),
        other_operating_income: parseNumber(x.other_operating_income),
        other_property_plant_and_equipment: parseNumber(
          x.other_property_plant_and_equipment
        ),
        other_short_term_debt: parseNumber(x.other_short_term_debt),
        other_short_term_receivables: parseNumber(x.other_short_term_receivables),
        plant_equipment_and_fixtures: parseNumber(x.plant_equipment_and_fixtures),
        profit_loss: parseNumber(x.profit_loss),
        profit_loss_before_tax: parseNumber(x.profit_loss_before_tax),
        property_plant_and_equipment: parseNumber(x.property_plant_and_equipment),
        provisions: parseNumber(x.provisions),
        reserves: parseNumber(x.reserves),
        retained_earnings: parseNumber(x.retained_earnings),
        revenue: parseNumber(x.revenue),
        short_term_debt: parseNumber(x.short_term_debt),
        short_term_debt_to_banks: parseNumber(x.short_term_debt_to_banks),
        short_term_debt_to_group_enterprises: parseNumber(
          x.short_term_debt_to_group_enterprises
        ),
        short_term_mortgage_debt: parseNumber(x.short_term_mortgage_debt),
        short_term_receivables: parseNumber(x.short_term_receivables),
        short_term_receivables_from_group_enterprises: parseNumber(
          x.short_term_receivables_from_group_enterprises
        ),
        short_term_receivables_from_sales_and_services: parseNumber(
          x.short_term_receivables_from_sales_and_services
        ),
        short_term_tax_payables: parseNumber(x.short_term_tax_payables),
        short_term_trade_payables: parseNumber(x.short_term_trade_payables),
        staff_expenses: parseNumber(x.staff_expenses),
        tax_expenses: parseNumber(x.tax_expenses),
      }))
    : []
}

export default financialNumbers
