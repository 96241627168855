import { SxProps } from '@mui/system'
import { colors } from 'configs'
import { theme } from 'styles-new/mui5-transition/theme'

const card: SxProps = {
  border: '2px solid',
  borderColor: theme.palette.grey[200],
  px: 2,
  py: 1,
  borderRadius: '10px',
  width: '24%',
  [theme.breakpoints.down('lg')]: {
    width: '49%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}

const icon = {
  mr: 2,
}

const colorTitle = (isBlue: boolean): SxProps => {
  return {
    color: isBlue ? colors.newBlue : colors.newOrange,
  }
}

export default {
  card,
  colorTitle,
  icon,
}
