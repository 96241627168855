import { MappingItem } from '../types'

const mapping: MappingItem[] = [
  { label: 'less-than-year', value: 0 },
  { label: '1-year-old', value: 1 },
  { label: '2-year-old', value: 2 },
  { label: '3-year-old', value: 3 },
  { label: '4-year-old', value: 4 },
  { label: '5-and-more', value: 5 },
]

export default mapping
