import axios from 'axios'
import { BACKEND_API_BASE_URL } from './config'
type UpdatePasswordProps = {
  password: string
  confirmPassword: string
  token: string
}

export const resetPassword = (email: string) => {
  return axios({
    method: 'get',
    url: `${BACKEND_API_BASE_URL}/auth/password/${email}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const updatePassword = ({
  password,
  confirmPassword,
  token,
}: UpdatePasswordProps) => {
  return axios({
    method: 'put',
    url: `${BACKEND_API_BASE_URL}/auth/password`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      password,
      confirm_password: confirmPassword,
      verify_token: token,
    },
  })
}
