import { SxProps } from '@mui/system'

const container = {
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingX: 5,
  paddingY: 4,
  flexDirection: 'row',
}

const closeButton: SxProps = {
  fontSize: 29,
  color: 'common.black',
  padding: 0,
}

export default {
  container,
  closeButton,
}
