import * as React from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import SideDrawer from 'components-new/layouts/SideDrawer'
import { useBuilder } from '../../BuilderContext'
import SidePanelHeader from '../../widgets/ActionPanel/widgets/SidePanelHeader'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import DetailedComponentViewActionButtons from '../../widgets/ActionPanel/widgets/DetailedComponentView/widgets/DetailedComponentViewActionButtons'
import { DropdownSelect } from 'components-new'

type TreeSelectionActionPanelProps = {
  open: boolean
  setOpen: (open: boolean) => void
  source: string
  target: string
}

function TreeSelectionActionPanel({
  open,
  setOpen,
  source,
  target,
}: TreeSelectionActionPanelProps) {
  const { actions } = useBuilder()
  const [nodeLine, setNodeLine] = React.useState<'yes' | 'no' | ''>('')
  const options = [
    {
      label: (
        <FormattedMessage id={intl.creditPolicyNew('node-between-2-nodes-option-yes')} />
      ),
      value: 'yes',
    },
    {
      label: (
        <FormattedMessage id={intl.creditPolicyNew('node-between-2-nodes-option-no')} />
      ),
      value: 'no',
    },
  ] as { label: JSX.Element; value: 'yes' | 'no' | '' }[]
  const handleAddExtraNode = () => {
    if (!nodeLine) return
    actions.createNodeBetweenNodes({
      parentNodeId: source,
      childNodeId: target,
      nodeLine,
    })
  }

  return (
    <SideDrawer
      drawerState={{
        drawerIsOpen: open,
        setDrawerIsOpen: (open) => setOpen(open),
      }}
      side="right"
    >
      <Stack sx={{ height: '100%' }} width={400}>
        <Stack>
          <SidePanelHeader
            title={<FormattedMessage id={intl.creditPolicyNew('select-action')} />}
            onClose={() => setOpen(false)}
          />
          <Divider />
        </Stack>
        <Stack height={1} justifyContent="space-between">
          <Stack p={5} height={1} spacing={6}>
            <Stack spacing={4}>
              <Typography>
                <FormattedMessage
                  id={intl.creditPolicyNew('node-between-2-nodes-text-1')}
                />
              </Typography>
              <Typography>
                <FormattedMessage
                  id={intl.creditPolicyNew('node-between-2-nodes-text-2')}
                />
              </Typography>
            </Stack>
            <DropdownSelect
              label={<FormattedMessage id={intl.creditPolicyNew('select-action')} />}
              placeholder={
                <FormattedMessage id={intl.creditPolicyNew('please-select')} />
              }
              options={options}
              value={nodeLine}
              disablePlaceholderClick={false}
              setValue={(value: 'yes' | 'no' | '') => setNodeLine(value)}
            />
          </Stack>
          <DetailedComponentViewActionButtons
            handleCancel={() => setOpen(false)}
            handleSubmit={() => handleAddExtraNode()}
            confirmEnabled={!!nodeLine}
            confirmButtonText={'action-panel-confirm-button'}
          />
        </Stack>
      </Stack>
    </SideDrawer>
  )
}

export default TreeSelectionActionPanel
