import { ErrorPage } from 'components-new'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { navigation } from 'services/navigation'

function InvalidCountry() {
  return (
    <ErrorPage
      errorCode={500}
      errorMessage={
        <FormattedMessage id={intl.creditPolicyNew('invalid-country-message')} />
      }
      buttonText={<FormattedMessage id={intl.creditPolicyNew('back-to-credit-policy')} />}
      redirectLocation={navigation.enterpriseCreditPolicy()}
    />
  )
}

export default InvalidCountry
