import { SAVE_ACCOUNT_STATUS_SUCCESS, SAVE_ACCOUNT_STATUS_FAILED } from './constants'
/*****************
 * Reducer Calls *
 *****************/

export const updateAccountStatusSuccess = (permissions) => ({
  type: SAVE_ACCOUNT_STATUS_SUCCESS,
  payload: { permissions },
  status: 'SUCCESS',
})
export const updateAccountStatusFailed = (error) => ({
  type: SAVE_ACCOUNT_STATUS_FAILED,
  payload: { error },
  status: 'FAILED',
})
