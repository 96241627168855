import * as d3 from 'd3'
import intl from 'localization/components'
import { riskColors } from 'configs/constants/theme'
import { theme } from 'styles-new/mui5-transition/theme'
import numeral from 'numeral'

const width = 220
const height = 200
const innerCircleRadius = 73

export default class D3FinancialRatioChart {
  constructor(element, data, selectedItem, key, messages) {
    this.animationSpeed = 1000
    this.circleData = data
    this.selectedItem = selectedItem

    if (this.circleData.length) {
      this.prevSelected = this.circleData[this.selectedItem]
      this.scale = d3.scaleLinear().domain([0, 1]).range([0, 2])
      this.color = d3.scaleOrdinal(['black'])
      this.pointerData = this.circleData[this.selectedItem]
      this.svg = d3
        .select(element)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(80, 80)`)

      /* ----------------------------------- CIRCLE AREA ------------------------------------------ */

      // Arc initial setup
      this.arc = d3
        .arc()
        .innerRadius(innerCircleRadius + 3)
        .outerRadius(innerCircleRadius - 3)

      // Create inner circle
      this.innerCircle = this.svg
        .append('circle')
        .attr('cx', 30)
        .attr('cy', 30)
        .attr('r', innerCircleRadius)
        .attr('fill', 'white')
        .attr('stroke', 'lightgray')

      // Inner circle path
      this.innerPath = this.svg
        .append('path')
        .attr('transform', 'translate(' + [30, 30] + ')')
        .attr('class', 'innerPath')
        .attr(
          'd',
          this.arc({
            innerRadius: 0,
            outerRadius: innerCircleRadius,
            startAngle: 0,
            endAngle: 180,
          })
        )
        .style('fill', 'transparent')
        .style('stroke', 'transparent')

      this.color = d3.scaleOrdinal().range()

      this.pie = d3
        .pie()
        .sort(null)
        .value((d) => {
          return d.performance
        })

      /* -------------------------------------- TEXT AREA ----------------------------------- */

      this.textCenterTop = this.svg
        .selectAll('.textCenterTop')
        .data([this.circleData[this.selectedItem]])

      this.textCenterMid = this.svg
        .selectAll('.textCenterMid')
        .data([this.circleData[this.selectedItem]])

      this.textCenterBot = this.svg
        .selectAll('.textCenterBot')
        .data([this.circleData[this.selectedItem]])

      this.textKey = this.svg
        .selectAll('.textKey')
        .data([this.circleData[this.selectedItem]])

      // Add data to the arc
      this.g = this.svg.selectAll('.arc').data(this.pie([this.pointerData]))

      this.pointer = this.svg.selectAll('.pointer').data([this.pointerData])

      this.update(this.pointerData, key, messages, this.prevSelected, this.selectedItem)
    }
  }

  update = (data, key, messages, prevSelected, selectedItem) => {
    this.textCenterTop
      .enter()
      .append('text')
      .attr('class', 'textCenterTop')
      .attr('dx', (d, i) => 30)
      .attr('dy', (d, i) => -5)
      .attr('fill', theme.palette.common.black)
      .style('opacity', '0')
      .style('font-family', theme.typography.body2.fontFamily)
      .style('font-size', theme.typography.body2.fontSize)
      .style('font-weight', theme.typography.body2.fontWeight)
      .style('letter-spacing', theme.typography.body2.letterSpacing)
      .style('line-height', theme.typography.body2.lineHeight)
      .transition()
      .duration(this.animationSpeed)
      .style('opacity', '1')
      .text((d) => `${d?.period?.end?.substring(0, 4)}`)
      .style('text-anchor', 'middle')
      .style('cursor', 'default')

    this.textCenterMid
      .enter()
      .append('text')
      .style('text-anchor', 'middle')
      .style('cursor', 'default')
      .style('font-family', theme.typography.h3.fontFamily)
      .style('font-size', theme.typography.h3.fontSize)
      .style('font-weight', theme.typography.h3.fontWeight)
      .attr('class', 'textCenterMid')
      .attr('dx', (d, i) => 30)
      .attr('dy', (d, i) => 40)
      .attr('fill', (d) =>
        d.performance ? this.getColorByAmount(d.performance) : 'black'
      )
      .style('opacity', '0')
      .style('font-family', theme.typography.h2.fontFamily)
      .style('font-size', theme.typography.h2.fontSize)
      .style('font-weight', theme.typography.h2.fontWeight)
      .transition()
      .duration(this.animationSpeed)
      .style('opacity', '1')
      .tween('text', function (d) {
        if (d?.value) {
          let extraSymbol = ''
          let maxRange

          if (d.value * 100 > 1000 || d.value * 100 < -1000) {
            if (d.value * 100 > 1000) {
              extraSymbol = '>'
            } else if (d.value * 100 < -1000) {
              extraSymbol = '<-'
            }

            if (d.value < -1000) {
              maxRange = -1000
            } else {
              maxRange = 1000
            }
          } else {
            maxRange = d.value * 100
          }

          const textScale = d3.scaleLinear().domain([0, 1]).range([0, maxRange])

          return function (t) {
            if (key === 'debt_to_equity_ratio') {
              if (d.value.toFixed(2) > 10000) {
                this.textContent = numeral(d.value.toFixed(2)).format('0a')
              } else {
                this.textContent = d.value.toFixed(2)
              }
            } else {
              this.textContent = `${extraSymbol}${Math.round(textScale(t))}%`
            }
          }
        } else {
          return function () {
            this.textContent = messages[intl.grading('not-available')]
          }
        }
      })

    this.textCenterBot
      .enter()
      .append('text')
      .style('text-anchor', 'middle')
      .style('cursor', 'default')
      .attr('class', 'textCenterBot')
      .attr('dx', (d, i) => 30)
      .attr('dy', (d, i) => 70)
      .attr('fill', theme.palette.common.black)
      .style('opacity', '0')
      .style('font-family', theme.typography.body1.fontFamily)
      .style('font-size', theme.typography.body1.fontSize)
      .style('font-weight', theme.typography.body1.fontWeight)
      .style('letter-spacing', theme.typography.body1.letterSpacing)
      .style('line-height', theme.typography.body1.lineHeight)
      .transition()
      .duration(this.animationSpeed)
      .style('opacity', '1')
      .text((d) => {
        return d.performance
          ? `${this.getTextByAmount(parseInt(d.performance), messages)}`
          : messages[intl.grading('not-available')]
      })
    /***********************
     * Click functionality *
     ***********************/

    // .on('click', d => {
    //   this.pointerData = d
    //   this.pointer.exit().remove()
    //   this.pointer = this.svg.selectAll('.pointer').data([this.pointerData])
    //   this.g = this.svg.selectAll('.arc').data(this.pie([this.pointerData]))

    //   this.selectedItem = this.circleData.findIndex(
    //     x => x.period.end === d.period.end,
    //   )
    //   this.textCenterTop = this.svg
    //     .selectAll('.textCenterTop')
    //     .data([this.circleData[this.selectedItem]])
    //   this.textCenterMid = this.svg
    //     .selectAll('.textCenterMid')
    //     .data([this.circleData[this.selectedItem]])
    //   this.textCenterBot = this.svg
    //     .selectAll('.textCenterBot')
    //     .data([this.circleData[this.selectedItem]])

    //   this.textKey = this.svg
    //     .selectAll('.textKey')
    //     .data([this.circleData[this.selectedItem]])
    //   this.textHistorical = this.svg
    //     .selectAll('.textHistorical')
    //     .data(this.circleData)

    //   this.g.exit().remove()
    //   this.textHistorical.exit().remove()
    //   this.update(d, key, messages, this.prevSelected, this.selectedItem)
    //   this.prevSelected = d
    // })

    const pieTween = (b) => {
      this.angle = d3.scaleLinear().domain([0, 100]).range([0, b.data.performance])

      const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, b)

      return (t) => this.arc(i(this.angle(t)))
    }

    // Arc enter
    this.g
      .enter()
      .append('path')
      .attr('class', 'arc')
      .attr('d', this.arc)
      .attr('transform', 'translate(' + [30, 30] + ')')
      .style('fill', (d) => this.getColorByAmount(d.data.performance))
      .style('stroke', (d) => this.getColorByAmount(d.data.performance))
      .transition()
      .duration(this.animationSpeed)
      .attrTween('d', pieTween)
  }

  getTextByAmount = (amount, messages) => {
    switch (true) {
      case amount <= 20:
        return messages[intl.grading('very-weak')]

      case amount <= 40:
        return messages[intl.grading('weak')]

      case amount <= 60:
        return messages[intl.grading('average')]

      case amount <= 80:
        return messages[intl.grading('strong')]

      case amount > 80:
        return messages[intl.grading('very-strong')]

      default:
        return messages[intl.grading('not-available')]
    }
  }

  getColorByAmount = (amount) => {
    switch (true) {
      case amount <= 40:
        return riskColors.high

      case amount <= 60:
        return riskColors.medium

      case amount <= 80:
        return riskColors.mediumLow

      default:
        return riskColors.low
    }
  }
}
