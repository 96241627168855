import { useState, useMemo } from 'react'
import { Stack, Skeleton } from '@mui/material'
import { useIndustryList } from 'services/queries'
import { parseIndustryTree } from 'pages/advanced-filters/scenes/NestedSelect/NestedIndustrySelect'
import { IndustryTypeView } from 'components-new'
import { addSelectAllToIndustryTypeData } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/DetailedComponentView/Actions/Actions.controller'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { useIntl } from 'react-intl'
import { getParentAndDetermineState } from '../../../advanced-filters/scenes/NestedSelect/utils'
import IndustryTypeInputSkeleton from './IndustryTypeInputSkeleton'

const IndustryTypeInput = ({ isAdvanced = true }) => {
  const { policy, setPolicy, selectedCountry, isInferiorPlan } = useCountryPolicy()
  const apiData = { include: policy.exclude_industries }
  const [industryTypeList, setIndustryTypeList] = useState<string[]>([])
  const [industryTypeListApiData, setIndustryTypeListApiData] = useState<string[]>([])
  const [expanded, setExpanded] = useState(false)
  const { data, isFetching } = useIndustryList(selectedCountry)
  const { messages } = useIntl()
  // use this to convert the data to the correct format before sending it to the API
  // You may need to send include or exclude depending on the API data structure you have,
  //      in our case we only have include as an option so play with is if you need
  const include = useMemo(() => {
    return industryTypeListApiData.filter((item) => item !== 'ALL')
  }, [industryTypeListApiData])

  // From here down do not change anything, because it will explode :)
  const parsedData = useMemo(
    () => addSelectAllToIndustryTypeData(parseIndustryTree(data), messages),
    [data, messages]
  )

  const leafItems = apiData?.include
    ?.map((item) => parsedData[item])
    .filter((item) => item?.isLeaf)
    .map((item) => item.value)

  leafItems?.forEach((item) => (parsedData[item].state = 'checked'))
  leafItems?.forEach((leaf) => getParentAndDetermineState(leaf, parsedData))

  const handleChange = (industries: string[]) => {
    setIndustryTypeListApiData(industries)
    setPolicy({
      ...policy,
      exclude_industries: industries.length === 0 ? null : industries,
    })
  }

  if (isFetching && !data) {
    return <IndustryTypeInputSkeleton />
  }
  return parsedData ? (
    <IndustryTypeView
      data={parsedData}
      industryTypeList={industryTypeList}
      setIndustryTypeList={setIndustryTypeList}
      setApiData={handleChange}
      expanded={expanded}
      setExpanded={setExpanded}
      isDisabled={isInferiorPlan && isAdvanced}
    />
  ) : (
    <IndustryTypeInputSkeleton />
  )
}
export default IndustryTypeInput
