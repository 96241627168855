import risikaAPI from 'services/api/helpers/risikaAPI'
/**
 * Search for a company.
 *
 * @param {string} search The search.
 * @returns {object} The search results.
 */
const exportSearch = (search, maxExport) => {
  const { filters, country } = search

  const data = {
    to: maxExport,
    filters,
  }

  return risikaAPI
    .post('/export/company', data, {
      microservice: 'data',
      country,
      timeout: 0,
    })
    .then(({ data }) => {
      return data
    })
}

export default exportSearch
