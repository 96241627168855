import { defaultStackedBarChartProperties } from 'configs/charts'

import intl from 'localization/components'

export const scoreByIndustryData = {
  endPoint: 'category',
  parameters: {
    list_id: 0,
    score_model: {},
    categories: [{ category: 'RISK_ASSESSMENT', function: 'COUNT' }],
    group_by: [{ category: 'NACE', level: 1 }, { category: 'RISK_ASSESSMENT' }],
  },
  chartProperties: {
    ...defaultStackedBarChartProperties,
    cartesianGrid: false,
    title: intl.observationalLists('risika-score-industry-title'),
    height: 390,
    valueType: 'RISK',
  },
  levelLimit: 4,
  rootLevelLabel: 'root',
}

export const breadcrumbStringLimit = 20
export type ScoreByIndustryData = typeof scoreByIndustryData
