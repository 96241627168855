export const selectCountriesOptions = [
  {
    label: 'Denmark',
    value: 'DK',
    flag: 'risika/flags/da_DK@2x.png',
  },
  {
    label: 'Norway',
    value: 'NO',
    flag: 'risika/flags/nb_NO@2x.png',
  },
  {
    label: 'Sweden',
    value: 'SE',
    flag: 'risika/flags/sv_SE@2x.png',
  },
  {
    label: 'Finland',
    value: 'FI',
    flag: 'risika/flags/fi_FI.png',
  },
]
