import { riskColors } from 'configs/constants/theme'
import { RiskAssessmentCodeT, ColorLevelT } from 'types/general'

/**
 * Transforms  riskAssessmentCode from [useRatingCredit] <-- [`/rating/credit/${id}?product= BOHR / NEWTON `]
 * to color level
 *
 *      'LOW', 'MEDIUM', 'HIGH'  -->   riskColors.low, riskColors.medium, riskColors.high
 *
 * @param {RiskAssessmentCodeT} riskAssessmentCode - data format from the query.
 * @return {colorLevel} - newest report
 */

export const generateColorFromRisk = (
  riskAssessmentCode: RiskAssessmentCodeT
): ColorLevelT => {
  const colorStr: ColorLevelT =
    riskAssessmentCode === 'LOW'
      ? riskColors.low
      : riskAssessmentCode === 'MEDIUM'
      ? riskColors.medium
      : riskColors.high

  return colorStr
}

export const generateColorFromRiskScore = (riskScore?: number) => {
  if (!riskScore) {
    return riskColors.unknown
  }

  if (riskScore <= 2) return riskColors.high
  if (riskScore > 2 && riskScore <= 4) return riskColors.mediumHigh
  if (riskScore > 4 && riskScore <= 6) return riskColors.medium
  if (riskScore > 6 && riskScore <= 8) return riskColors.mediumLow
  if (riskScore > 8) return riskColors.low
  return riskColors.unknown
}
