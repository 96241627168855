import { useUserSelf } from 'services/queries'
import useListOwner from 'pages/risk-monitoring/hooks/useListOwner'

const useApiIds = () => {
  const { owner } = useListOwner()
  const { data: self } = useUserSelf()

  const currentUserRisikaId = self?.api_user_id

  const isCurrentUserSameAsListOwner =
    owner === null || owner?.risikaId === currentUserRisikaId

  return {
    currentUserRisikaId,
    isCurrentUserSameAsListOwner,
  }
}

export default useApiIds
