import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import StackedBarChart from 'components-new/charts/StackedBarChart'
import { convertToNameAndValues } from 'utils-new/api-to-chart-data-converters'
import { companyTypeData } from './ListCreditScoreByCompanyTypeBox.model'
import { useSelector } from 'react-redux'
import { useMonitoringStatistic } from 'services/queries'
import { MonitoringValuesQueryDataType } from 'types/queries'

const ListCreditScoreByCompanyTypeBox = ({ listId }: { listId: string }) => {
  companyTypeData.parameters.list_id = Number(listId)
  companyTypeData.chartProperties.tooltipWithPercents = true
  const userLanguage = useSelector((state: any) => state.auth?.user?.local?.lang)

  const companyTypeQuery = useMonitoringStatistic({
    parameters: companyTypeData.parameters,
    endpoint: companyTypeData.endPoint,
    cacheTime: 0,
  })

  const companyTypeQueryData = companyTypeQuery.data as MonitoringValuesQueryDataType[]
  const boxData = {
    title: companyTypeData.chartProperties.title,
    status: {
      isLoading: companyTypeQuery.isLoading,
      isError:
        companyTypeQuery.isError ||
        (companyTypeQuery.data &&
          !companyTypeQueryData?.length &&
          !companyTypeQuery.isLoading),
      isIdle: companyTypeQuery.isIdle,
      isNoData: !companyTypeQueryData?.length,
    },
    height: companyTypeData.chartProperties.height,
    skeletonType: 'HORIZONTAL_BAR',
  }

  if (
    companyTypeQueryData?.length &&
    !companyTypeQuery.isLoading &&
    !convertToNameAndValues(companyTypeQueryData, userLanguage).length
  ) {
    boxData.status.isError = true
  }

  return (
    <BoxContainer boxData={boxData}>
      <Box data-cy="list-credit-score-by-company-type-chart" m={2} pt={1}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <StackedBarChart
            data={convertToNameAndValues(companyTypeQueryData, userLanguage)}
            chartProperties={companyTypeData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListCreditScoreByCompanyTypeBox
