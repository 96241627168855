import { IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './SidePanelHeader.styles'
import { SidePanelHeaderProps } from './SidePanelHeader.types'
import { ChevronLeft } from '@mui/icons-material'

const { container, closeButton } = styles

function SidePanelHeader(props: SidePanelHeaderProps) {
  const { title, onClose, onBack } = props
  return (
    <Stack sx={container}>
      <Stack direction="row" alignItems="center" justifyContent="start">
        {onBack && (
          <IconButton data-cy="side-panel-close-button" sx={closeButton} onClick={onBack}>
            <ChevronLeft color="inherit" fontSize="inherit" />
          </IconButton>
        )}
        <Typography variant="h4">{title}</Typography>
      </Stack>
      <IconButton data-cy="side-panel-close-button" sx={closeButton} onClick={onClose}>
        <CloseIcon color="inherit" fontSize="inherit" />
      </IconButton>
    </Stack>
  )
}

export default SidePanelHeader
