import { SxProps } from '@mui/system'
import { colors } from 'configs'
import { theme } from 'styles-new/mui5-transition/theme'

const CreditPolicyViolation: { [key: string]: SxProps } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  pill: {
    width: '100%',
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    background: '#EE6055',
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    color: colors.risikaWhite,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.subtitle2.fontSize,
  },
  text: {
    fontWeight: theme.typography.fontWeightLight,
  },
  divider: {
    background: theme.palette.grey[50],
  },
}

export const classes = {
  CreditPolicyViolation,
}
