/**
 * Check if the value has a certain length.
 *
 * @param value The value to validate.
 * @param lengthConfig The length to check against.
 */
function validateLength(value, lengthConfig) {
  const length = value.length
  if (lengthConfig.exact) {
    return length === lengthConfig.exact
  }

  // $FlowFixMe (Flow Fix Yourself)
  if (lengthConfig.min && length < lengthConfig.min) {
    return false
  }

  // $FlowFixMe (Flow Fix Yourself)
  if (lengthConfig.max && length > lengthConfig.max) {
    return false
  }

  return true
}

/**
 * Check the value against a regular expression.
 *
 * @param value The value to validate.
 * @param pattern The pattern to use.
 */
function validatePattern(value, pattern) {
  if (value == null || value === '') {
    return true
  }

  const regex = typeof pattern === 'string' ? new RegExp(pattern) : pattern

  if (value.match(regex) === null) {
    return false
  }

  return true
}

/**
 * A generic way to validate a field.
 * Useful for doing non-trivial validation.
 *
 * @param value The value to validate.
 * @param callback The callback that checks if the value is valid.
 */
function validateCallback(value, callback) {
  return callback(value)
}

/**
 * Check if the value matches the one
 * that was provided.
 *
 * @param value The value to validate.
 * @param matches The value it should match.
 */
function validateMatches(value, matches) {
  if (Array.isArray(matches)) {
    return matches.includes(value)
  }

  return value === matches
}

/**
 * Check the value against the validation config.
 *
 * @param value The value to validate.
 * @param validationConfig The definition of valid.
 */
export function validate(value, validationConfig) {
  let isValid = true

  if (validationConfig.length != null && isValid) {
    isValid = validateLength(value, validationConfig.length)
  }

  if (validationConfig.pattern != null && isValid) {
    isValid = validatePattern(value, validationConfig.pattern)
  }

  if (validationConfig.callback != null && isValid) {
    isValid = validateCallback(value, validationConfig.callback)
  }

  if (validationConfig.matches != null && isValid) {
    isValid = validateMatches(value, validationConfig.matches)
  }

  if (validationConfig.matchesNot != null && isValid) {
    isValid = !validateMatches(value, validationConfig.matchesNot)
  }

  return isValid
}

export { validationFormats } from './validators'
export { pipeValidators } from './helpers'
