import { Typography, Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { generateColorFromRiskScore } from 'utils-new/generate-color-from-risk'
import { SubscriptionPlan } from 'types/general'

type RisikaScoreProps = {
  value?: number
  displayNumericScore: boolean
  plan?: SubscriptionPlan
  dataCy?: string
}

const highRisk = [1, 2, 3]
const mediumRisk = [4, 5, 6]
const lowRisk = [7, 8, 9, 10]

const showRisk = (score?: number) => {
  if (!score) {
    return '_'
  }
  if (highRisk.includes(score)) {
    return <FormattedMessage id={intl.mainRecommendation('risk-level-high-short')} />
  } else if (mediumRisk.includes(score)) {
    return <FormattedMessage id={intl.mainRecommendation('risk-level-medium-short')} />
  } else if (lowRisk.includes(score)) {
    return <FormattedMessage id={intl.mainRecommendation('risk-level-low-short')} />
  } else {
    return '-'
  }
}

export const RisikaScore = (props: RisikaScoreProps) => {
  const { value, displayNumericScore, plan, dataCy } = props
  const score = displayNumericScore === false ? showRisk(value) : value

  return plan === 'FREEMIUM' ? null : (
    <Box data-cy={dataCy} className="table-cell-box" style={{ width: '100%' }}>
      <Typography
        align="center"
        variant="subtitle1"
        style={{ color: generateColorFromRiskScore(value) }}
      >
        {(value && score) || '-'}
      </Typography>
    </Box>
  )
}

export default RisikaScore
