// import { addAppLocaleData } from './localeData'
import english from './languages/english'
import danish from './languages/danish'
import norwegian from './languages/norwegian'
import swedish from './languages/swedish'

// Register the locales
// addAppLocaleData()
// Export the translations for the supported languages
export default {
  en: english,
  da: danish,
  nb: norwegian,
  sv: swedish,
}
