import { Typography, Chip, Stack } from '@mui/material'
import styles from './styles/hierarchy-box-company'
import toggleStyles from './styles/toggle-owner'
import { useHierarchySceneContext } from 'pages/company-hierarchy/context/hierarchySceneContext'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { HierarchyChildBoxProps } from './types'
import CardChildrenFactory from './CardChildrenFactory'
import SubsidiariesToggle from './scenes/UI/SubsidiariesToggle'

const HierarchyChildrenBox = ({
  props,
  onOpenHierarchyWalkup,
  onUpdateChildren,
  direction,
  selectDepth,
  currentDepth,
}: HierarchyChildBoxProps) => {
  const { nodeDatum, toggleNode, hierarchyPointNode } = props
  const { displayState } = useHierarchySceneContext()

  const shouldUpdateDepth = hierarchyPointNode.depth === currentDepth
  const isCollapsed = nodeDatum?.__rd3t?.collapsed
  const isNodeToggled = !isCollapsed

  const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return 'Android'
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ) {
      return 'iOS'
    }
    return 'Other'
  }

  return (
    <foreignObject
      width="420"
      height="320"
      style={{
        display: 'flex',
        transform:
          getMobileOS() === 'iOS' ? 'translate(-150px, -133px)' : 'translate(0, -4px)',
        padding: '12px 16px',
        overflow: 'visible',
        cursor: 'grab',
      }}
    >
      <section
        data-cy={`hierarchy-children-box-${nodeDatum.name}`}
        style={{
          position: 'fixed',
          width: '100%',
          transform:
            getMobileOS() === 'iOS'
              ? `scale(${props.treeZoomLevel}) translate(-50%, -50%)`
              : 'translate(-53%, -50%)',
        }}
      >
        <Stack>
          {!!nodeDatum?.shares_interval && (
            <Chip
              sx={{
                ...styles.chipSharesInterval,
                marginBottom: '10px',
              }}
              label={
                <Typography variant="body2">{nodeDatum?.shares_interval}</Typography>
              }
            />
          )}
        </Stack>
        <Stack direction="column" alignItems="center" sx={toggleStyles.main}>
          {nodeDatum?.has_parents && (
            <Stack
              data-cy={`${nodeDatum.name}-owners-toggle`}
              alignItems="center"
              justifyContent="center"
              sx={toggleStyles.ownersBtn(true)}
              onClick={() => {
                onOpenHierarchyWalkup?.()
              }}
            >
              <Typography variant="subtitle2">
                {nodeDatum?.has_parents} <FormattedMessage id={intl.generic('owners')} />{' '}
                +
              </Typography>
            </Stack>
          )}
        </Stack>
        <CardChildrenFactory displayState={displayState} nodeDatum={nodeDatum} />
        <SubsidiariesToggle
          nodeDatum={nodeDatum}
          isNodeToggled={isNodeToggled}
          toggleNode={toggleNode}
          onOpenChildren={onUpdateChildren}
          direction={direction}
          selectDepth={selectDepth}
          shouldUpdateDepth={shouldUpdateDepth}
        />
      </section>
    </foreignObject>
  )
}

export default HierarchyChildrenBox
