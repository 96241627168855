// @flow

import * as React from 'react'
import { useSnackbar } from 'notistack'
import { Link, useParams, useHistory } from 'react-router-dom'

import { verifyAccount } from 'services/api'
import * as Sentry from '@sentry/browser'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { navigation } from 'services/navigation'
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import { verifyUserSuccess } from 'store_deprecated/auth/actions'
import { useStyles } from '../styles/verify-account'

const VerifyAccount = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { token } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  const ARTIFICIAL_DELAY = 2500

  const isAuthenticated = useSelector((state) => state?.auth?.login?.authenticated)

  React.useEffect(() => {
    if (isAuthenticated) {
      return
    }
    setTimeout(() => {
      verifyAccount(token)
        .then((data) => {
          dispatch(verifyUserSuccess(data, token))
        })
        .catch((error) => {
          const errorResponseCode = error?.response?.code
          if (errorResponseCode === 'token_already_verified') {
            enqueueSnackbar(
              <FormattedMessage id={intl.auth('account-already-verified')} />
            )
            return history.push(navigation.auth.login())
          }
          Sentry.captureException(error, {
            contexts: {
              error: error,
              page: 'Verification',
              text: 'This is the verification error that you where looking for',
            },
          })
          enqueueSnackbar(
            <FormattedMessage id={intl.auth('account-verified-failed')} />,
            {
              variant: 'error',
            }
          )

          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }, ARTIFICIAL_DELAY)
  }, [dispatch, enqueueSnackbar, history, isAuthenticated, token])

  if (loading) {
    return <CircularProgress className={classes.spinner} />
  }

  if (error) {
    return (
      <div className={classes.root}>
        <Card className={classes.card} elevation={0}>
          <CardHeader
            title={
              <Typography className={classes.title} variant="h5">
                <FormattedMessage id={intl.auth('account-verified-failed-title')} />
              </Typography>
            }
            subheader={
              <Typography className={classes.subtitle} variant="body1">
                <FormattedMessage id={intl.auth('account-verified-failed-subtitle')} />
              </Typography>
            }
          />
          <CardContent>
            <Link to={navigation.auth.login()} className={classes.link}>
              <FormattedMessage id={intl.auth('go-back-to-log-in')} />
            </Link>
          </CardContent>
        </Card>
      </div>
    )
  }

  return null
}

export default VerifyAccount
