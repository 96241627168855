import { makeStyles } from '@mui/styles'
import { colors } from 'configs'

export const useGlobalStyles = makeStyles((theme) => ({
  // Default box
  defaultBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  defaultBoxTitle: {
    fontSize: '1.8rem',
    fontFamily: `"Montserrat","Roboto","Helvetica","Arial",sans-serif`,
    fontWeight: '500',
    lineHeight: '1.334',
    letterSpacing: '0em',
  },
  // Avatar
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatarMedium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  // Tooltips
  d3CustomTooltip: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    border: '1px solid lightgray',
  },
  // Helpers
  capitalizedSentence: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  bigExclamationIcon: {
    width: '10rem !important',
    height: '10rem',
    color: colors?.rating_1,
  },
  pointer: {
    cursor: 'pointer',
  },
}))
