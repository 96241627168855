import { Stack } from '@mui/material'
import React from 'react'
import { AlignType } from '../HierarchyListView.types'
type BlankDashFieldProps = {
  align?: AlignType
}

const BlankDashField = ({ align }: BlankDashFieldProps) => {
  return (
    <Stack justifyContent="center" alignItems={align ?? 'center'}>
      -
    </Stack>
  )
}

export default BlankDashField
