import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Typography, Stack } from '@mui/material'
import { ActionDialog, ConditionallyRender, Hide } from 'components'
import ListActionDialog from './ListActionDialog'
import {
  submitRemoveCompanyFromRiskMonitoringList,
  submitAddToMonitoringList,
  submitFetchListDataById,
  setText,
  updatePolicyValues,
  flushCachedPages,
} from 'store_deprecated'
import { take } from 'ramda'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { creditPolicySet } from 'services/api'
import { handleComponentRenderRestrictions } from 'services/helpers'
import { isNil } from 'ramda'
import updateNotes_dangerous from 'services/api/endpoints/risikaAPI/notes/resources/updateNotes_dangerous'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser } from '@fortawesome/free-solid-svg-icons'
import { useAccountStatus } from 'services/queries'
import { classes } from '../../../styles/row-actions'
import { ButtonDestructive, ButtonTertiary } from 'components-new'
import DeleteIcon from '@mui/icons-material/Delete'

const extractLocalIds = (companyRows) => {
  return companyRows?.map((company) => company.original.localId)
}

const extractInternalIds = (companyRows) => {
  return companyRows.filter((company) => company.original.internalId != null)
}
const RowActions = ({ rows }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const accountStatusQuery = useAccountStatus()

  const { lists, selectedList } = useSelector((state) => state.risika.newRiskMonitoring)
  const { policyForm } = useSelector((state) => state.risika.creditPolicy)
  const { text } = useSelector((state) => state.risika.newRiskMonitoring)

  React.useEffect(() => {
    if (text !== '' && !isNil(text)) {
      enqueueSnackbar(
        <FormattedMessage
          id={intl.riskMonitoringNew(text)}
          values={{
            count: rows.length,
          }}
        />
      )
      dispatch(setText(''))
    }
  }, [rows, text, dispatch, enqueueSnackbar])

  const handleMove = (listId, selectedCompanies) => {
    dispatch(
      submitAddToMonitoringList(
        listId,
        selectedCompanies,
        'items-moved',
        'items-failed-to-move'
      )
    )
    const isMoving = true
    handleDelete(selectedCompanies, isMoving)
  }

  const handleCopy = (listId, selectedCompanies) => {
    dispatch(
      submitAddToMonitoringList(
        listId,
        selectedCompanies,
        'items-copied',
        'items-failed-to-copy'
      )
    )
  }

  const handleDelete = (rows, isMoving) => {
    if (rows.length > 50) {
      dispatch(setText('deleting-companies-wait'))
    }
    if (isMoving === true) {
      dispatch(submitRemoveCompanyFromRiskMonitoringList(selectedList, rows, true))
    } else {
      dispatch(submitRemoveCompanyFromRiskMonitoringList(selectedList, rows, false))
    }
  }

  const handleRejectSelectedComapnies = () => {
    const entriesByCountry = {}
    const fieldType = 'exclude_companies'
    rows?.forEach(({ original: row }) => {
      const country = row?.country?.toLowerCase()
      Object.assign(entriesByCountry, {
        ...entriesByCountry,
        [country]: entriesByCountry?.[country]
          ? [
              ...entriesByCountry?.[country],
              { id: row.localId.id, name: row.companyName },
            ]
          : [{ id: row.localId.id, name: row.companyName }],
      })
    })
    Object.keys(entriesByCountry)?.forEach((country) => {
      const newValues = [
        ...(policyForm?.[country]?.policyValues?.[fieldType]?.length
          ? policyForm?.[country]?.policyValues?.[fieldType]
          : []),
        ...entriesByCountry?.[country],
      ]
      dispatch(
        updatePolicyValues({
          tabSelected: country,
          fieldType,
          // Add the new values to redux and remove dublicates
          // Limit 500
          values: take(
            500,
            Array.from(new Set(newValues.map((a) => a.id))).map((id) => {
              return newValues.find((a) => a.id === id)
            })
          ),
          isSingle: false,
        })
      )
    })
    Object.keys(entriesByCountry)?.forEach((country) => {
      creditPolicySet(country)
        .then((res) => {
          dispatch(flushCachedPages())
          if (entriesByCountry[country].length > 500) {
            enqueueSnackbar(
              <FormattedMessage
                id={intl.creditPolicy('too-many-values')}
                values={{
                  country: <FormattedMessage id={intl.creditPolicy(country)} />,
                }}
              />
            )
          }
          enqueueSnackbar(
            <FormattedMessage
              id={intl.creditPolicy('save-success')}
              values={{
                country: <FormattedMessage id={intl.creditPolicy(country)} />,
              }}
            />
          )
        })
        .catch((err) => {
          enqueueSnackbar(
            <FormattedMessage
              id={intl.creditPolicy('save-failed')}
              values={{
                country: <FormattedMessage id={intl.creditPolicy(country)} />,
              }}
            />
          )
        })
    })
  }

  const removeInternalIds = (localIds) => {
    const notes = localIds.map((localId) => ({
      data: {
        _internal_id: null,
      },
      localId,
    }))

    updateNotes_dangerous(notes).then(() => {
      dispatch(submitFetchListDataById(selectedList))
    })
  }

  return (
    <Stack direction="row" spacing={2} sx={classes.main}>
      <ConditionallyRender
        condition={handleComponentRenderRestrictions({
          component: 'monitor_add_to_policy_list_button',
          plan: accountStatusQuery.data?.subscription_plan,
        })}
        when={
          <ButtonTertiary onClick={handleRejectSelectedComapnies}>
            {<FormattedMessage id={intl.riskMonitoring('reject-companies')} />}
          </ButtonTertiary>
        }
      />
      <ListActionDialog
        handleAction={handleMove}
        selectedCompanies={extractLocalIds(rows)}
        lists={lists}
        title={'move-to-another-list-title'}
        render={(open) => (
          <ButtonTertiary onClick={open}>
            <FormattedMessage id={intl.riskMonitoringNew('move-button-text')} />
          </ButtonTertiary>
        )}
      />
      <ListActionDialog
        handleAction={handleCopy}
        selectedCompanies={extractLocalIds(rows)}
        lists={lists}
        title={'copy-to-another-list-title'}
        render={(open) => (
          <ButtonTertiary onClick={open}>
            <FormattedMessage id={intl.riskMonitoringNew('copy-button-text')} />
          </ButtonTertiary>
        )}
      />
      <ActionDialog
        title={
          <FormattedMessage
            id={intl.riskMonitoringNew('delete-companies-title')}
            values={{
              count: rows.length,
            }}
          />
        }
        content={(close) => (
          <Typography>
            <FormattedMessage
              id={intl.riskMonitoringNew('are-you-sure-delete-entries')}
              values={{
                count: rows.length,
              }}
            />
          </Typography>
        )}
        maxWidth="sm"
        fullWidth={true}
        action={() => handleDelete(extractLocalIds(rows), false)}
        agreeText={<FormattedMessage id={intl.confirmAction('delete')} />}
        danger
        render={(open) => (
          <ButtonDestructive
            data-cy="delete-all-selected"
            startIcon={<DeleteIcon />}
            onClick={open}
          >
            <FormattedMessage id={intl.riskMonitoringNew('delete-items')} />
          </ButtonDestructive>
        )}
      />
      <Hide when={!extractInternalIds(rows).length}>
        <ActionDialog
          title={
            <FormattedMessage
              id={intl.riskMonitoringNew('remove-internal-id-title')}
              values={{
                count: extractInternalIds(rows).length,
              }}
            />
          }
          content={(close) => (
            <Typography>
              <FormattedMessage
                id={intl.riskMonitoringNew('remove-internal-id-description')}
                values={{
                  count: extractInternalIds(rows).length,
                }}
              />
            </Typography>
          )}
          maxWidth="sm"
          fullWidth={true}
          action={() => removeInternalIds(extractLocalIds(rows))}
          agreeText={<FormattedMessage id={intl.confirmAction('delete')} />}
          danger
          render={(open) => (
            <ButtonDestructive
              data-cy="remove-internal-id-selected"
              startIcon={<FontAwesomeIcon icon={faEraser} />}
              onClick={open}
            >
              <FormattedMessage id={intl.riskMonitoringNew('remove-internal-id')} />
            </ButtonDestructive>
          )}
        />
      </Hide>
    </Stack>
  )
}

export default RowActions
