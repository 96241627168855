import AsyncSelect from 'react-select/async'
import { colors } from 'configs'
import { emphasize } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// type StyleOptions = {
//   error: boolean,
// }

const useCustomStyles = (theme, styleOptions) => ({
  input: (provided, state) => ({
    ...provided,
    fontSize: '1.6rem',
    padding: 0,
    margin: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    border: 'none',
    fontSize: '1.6rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused
      ? theme.palette.primary.main
      : emphasize(theme.palette.primary.main, 0.4),
    '&:hover': {
      color: emphasize(theme.palette.primary.main, 0.2),
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused
      ? theme.palette.primary.main
      : emphasize(theme.palette.primary.main, 0.4),
    '&:hover': {
      color: emphasize(theme.palette.primary.main, 0.2),
    },
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  loadingIndicator: (provided, state) => ({
    ...provided,
    color: theme.palette.primary.main,
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    padding: theme.spacing(1, 2),
    backgroundColor: '#ddd',
    boxShadow: state.isFocused
      ? `0 0 0 2px ${theme.palette.primary.main}`
      : styleOptions.error
      ? `0 0 0 2px ${theme.palette.error.main}`
      : '',
  }),
  container: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: 5,
    border: 'none',
  }),
})

// type SelectProps = {
//   selectedOption?: (option: any) => any,
//   onChange: (searchInput: string) => Promise,
//   onBlur?: (event: any) => any,
//   isClearable?: boolean,
//   error?: boolean,
//   placeholder?,
// }

const Select = ({
  onChange,
  selectedOption,
  onBlur,
  isClearable,
  placeholder,
  error,
}) => {
  const theme = useTheme()
  const customStyles = useCustomStyles(theme, { error: error ?? false })

  return (
    <AsyncSelect
      styles={customStyles}
      cacheOptions
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={selectedOption}
      isClearable={isClearable}
      loadOptions={onChange}
      theme={(reactSelectTheme) => ({
        ...reactSelectTheme,
        colors: {
          ...reactSelectTheme.colors,
          primary: theme.palette.primary.main,
          primary25: emphasize(colors.risikaTertiary, 0.5),
        },
      })}
    />
  )
}
export default Select
