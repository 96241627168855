import { useMutation, useQueryClient } from 'react-query'
import { updateUserSettingsField } from 'services/api'
import { UpdateUserSettingsRequestProps } from 'services/types'
import { useDispatch } from 'react-redux'
import { updateUserCurrencySuccess } from 'store_deprecated'

type UpdateUserSettingsField = {
  onSuccess?: () => void
  onError?: () => void
}

export function useUpdateUserSettingsField<T>({
  onSuccess,
  onError,
}: UpdateUserSettingsField) {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation<any, unknown, UpdateUserSettingsRequestProps<T>>(
    updateUserSettingsField,
    {
      onMutate: async ({ field, data }: UpdateUserSettingsRequestProps<T>) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries('getUserSettingsField' + field)
        // Snapshot the previous value
        const previousSettings = queryClient.getQueryData('getUserSettings')
        // Optimistically update to the new value
        queryClient.setQueryData(`getUserSettingsField${field}`, () => data)

        // Return a context object with the snapshotted value
        return { previousSettings }
      },
      onError: (_error, _hero) => {
        onError?.()
      },
      onSuccess: (res) => {
        onSuccess?.()
        queryClient.invalidateQueries('getUserSettings')

        const currency = res?.selected_currency?.currency
        if (currency) {
          dispatch(updateUserCurrencySuccess({ currency }))
          window.location.reload()
        }
      },
    }
  )
}
