import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

const { body1 } = theme.typography

const autocompleteCompany = (focused?: boolean, disabledCountrySelect?: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: focused ? '2px' : '1px',
  borderColor: focused ? 'primary.500' : 'grey.200',
  borderRadius: 1,
  padding: 0,
})

const autocomplete: SxProps = {
  padding: 0,
  '& fieldset': {
    border: 'none',
  },
}

const select: SxProps = {
  '&::before': {
    border: 'none',
  },
}

const paper: SxProps = {
  width: '340px',
  margin: '20px 0 !important',
  fontSize: '1.6rem !important',
  fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important',
}

const input = {
  padding: '0 !important',
  width: '95%',
  ...body1,
  input: {
    padding: '0 !important',
  },
}

export const styles = {
  autocompleteCompany,
  autocomplete,
  select,
  paper,
  input,
}
