import { Typography } from '@mui/material'
import { DropdownSelect } from 'components-new'
import { Company } from './ImportCompaniesFromFile'
import styles from './ImportCompanies.styles'

type SelectCompanyDropDownProps = {
  companyPair: Company[]
  companyId: number
  selectedOption: string
  setValue: any
  selectAllOption?: { label: string; value: string }
}

const OPTIONS = [
  { label: 'Finland', value: 'Finland', flag: 'risika/flags/fi_FI.png' },
  { label: 'Denmark', value: 'Denmark', flag: 'risika/flags/da_DK@2x.png' },
]

const SelectCompanyDropDown = ({
  setValue,
  companyPair,
  selectedOption,
  companyId,
}: SelectCompanyDropDownProps) => {
  const selectedCompany = companyPair.find(
    (company) => company.country === selectedOption
  )?.name
  const selectedCountry = selectedOption
    ? OPTIONS.find((country) => country.flag.includes(selectedOption))?.label
    : ''
  const handleChange = (label: string) => {
    setValue(label, companyId)
  }

  // divs used here to avoid MUI Box issues
  // https://github.com/mui/material-ui/issues/21797

  return (
    <div style={styles.container}>
      <div style={styles.companyDetails}>
        <Typography variant="subtitle1">{selectedCompany}</Typography>
        <Typography variant="body2">{companyId}</Typography>
      </div>
      <div style={styles.dropdownContainer}>
        <DropdownSelect<any>
          options={OPTIONS}
          value={selectedCountry || ''}
          setValue={(label) => {
            handleChange(label)
          }}
        />
      </div>
    </div>
  )
}

export default SelectCompanyDropDown
