import React from 'react'
import { useDebounce } from 'react-use'
import { compose } from 'ramda'
import { useDispatch } from 'react-redux'
import { filterCompany } from 'services/api'
import { transformFilters } from '../controller/general'
import {
  setFilterResults,
  // resetFilters
} from 'store_deprecated'
import useQueryInvalidation from 'services/hooks/useQueryInvalidation'
import { useUserSettings } from 'services/queries'

const FetchFilterData = ({
  filterOptions,
  country,
  sortBy = null, // example: {"company_name": "asc"}
  filters = [],
  onError,
  placeholder,
  pagination,
}) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const queryInvalidation = useQueryInvalidation()
  const userSettingsQuery = useUserSettings()
  const { from, to, pageSize } = pagination
  const handleFilters = (filters, filterOptions, sort) => {
    return transformFilters(filters, filterOptions, sort)
  }

  useDebounce(
    () => {
      if (!filters.length) return

      setLoading(true)

      compose(queryInvalidation, filterCompany)(
        handleFilters(filters, filterOptions, sortBy),
        country,
        { currency: userSettingsQuery?.data?.selectedCurrency?.currency, pagination }
      )
        .then(([invalidated, response]) => {
          if (!invalidated) {
            const { search_result: results, count, time_elapsed } = response
            dispatch(setFilterResults({ count, results, time_elapsed }))
            setLoading(false)
          }
        })
        .catch((error) => {
          onError?.(error)
          setLoading(false)
        })
    },
    1000,
    [filters, filterOptions, sortBy, from, to, pageSize]
  )

  return loading ? placeholder : null
}

export default FetchFilterData
