import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Stack } from '@mui/material'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ReactSetState } from 'types/general'
import { SubmitRef } from '../index'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { useMonitorListPost } from 'services/queries'
import useTotangoTracking from 'utils/totangoTracking'
import { submitAddToMonitoringList } from 'store_deprecated'
import ImportCompaniesFromFile, {
  Company,
} from 'components/ImportCompanies/ImportCompaniesFromFile'
import { COMPANY_LIST } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import { InputField } from 'components-new'

export type NewMonitoringListPropsType = {
  setIsConfirmEnabled: ReactSetState<boolean>
  // apiData: RejectOutcome
}

export type internalId = {
  internalId: string
  localId: string
}
const NewMonitoringList = (
  { setIsConfirmEnabled }: NewMonitoringListPropsType,
  ref: React.ForwardedRef<SubmitRef>
) => {
  const dispatch = useDispatch()
  const { state, actions } = useBuilder()
  const [name, setName] = useState('')
  const [importValues, setImportValues] = useState<Company[]>([])
  const [internalIds, setInternalIds] = useState<internalId[]>([])
  const { mutate: createList } = useMonitorListPost()
  const { trackEvent } = useTotangoTracking()

  useEffect(() => setIsConfirmEnabled(false), [setIsConfirmEnabled])

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit: async () => {
        await createList(
          {
            title: name,
            ownerApiId: 0,
            users: [],
            isPublic: true,
          },
          {
            onSuccess: (data) => {
              trackEvent('Monitoring', 'New List From ECP')

              dispatch(
                submitAddToMonitoringList(
                  data.list_id,
                  importValues,
                  'items-imported',
                  'items-failed-to-import'
                )
              )
              trackEvent('Monitoring', 'Import List from ECP')

              actions.setNodeApiData({
                nodeId: state.actionPanelData.nodeId,
                data: {
                  category: COMPANY_LIST,
                  list_id: data.list_id,
                },
              })
            },
          }
        )
      },
    }),
    [
      createList,
      name,
      trackEvent,
      dispatch,
      importValues,
      actions,
      state.actionPanelData.nodeId,
    ]
  )

  const setValues = (importValueList: Company[], internalIdList: internalId[]) => {
    setImportValues(importValueList)
    setInternalIds(internalIdList)
    if ((importValues.length && name) || (internalIds.length && name)) {
      setIsConfirmEnabled(true)
    } else {
      setIsConfirmEnabled(false)
    }
  }

  return (
    <Stack p={5} spacing={2}>
      <InputField
        value={name}
        handleChange={setName}
        label={
          <FormattedMessage id={intl.creditPolicyNew('new-monitoring-list-label')} />
        }
      />
      <Stack>
        <ImportCompaniesFromFile
          hideManualEntry={true}
          listName={name}
          onSuccess={setValues}
          importValues={importValues}
          setImportValues={setImportValues}
        />
      </Stack>
    </Stack>
  )
}

export default React.forwardRef(NewMonitoringList)
