import React from 'react'
import { useSelector } from 'react-redux'
import Button from './Button'
import { handleComponentRenderRestrictions } from 'services/helpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as ButtonComponent,
  Typography,
} from '@mui/material'
import { colors } from 'configs'
import { useAccountStatus } from 'services/queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useStyles } from '../styles/with-restrictions'

const withRestrictions = (feature, restriction) => (WrappedComponent) => {
  const classes = useStyles()
  const permissions = useSelector((state) => state.risika.permissions)
  const [open, setOpen] = React.useState(false)
  const { rights } = permissions.company
  const accountStatusQuery = useAccountStatus()
  const locked =
    rights?.[feature] !== undefined
      ? !rights?.[feature]
      : !handleComponentRenderRestrictions({
          component: feature,
          plan: accountStatusQuery.data?.subscription_plan,
        })
  const handleOnClick = () => setOpen(true)
  const handleAction = () => {
    window.open(restriction.button.link, '_blank')
  }

  return (props) =>
    locked ? (
      <React.Fragment>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle style={{ backgroundColor: colors?.risika, color: 'white' }}>
            {restriction.title}
          </DialogTitle>
          <DialogContent>{restriction.content}</DialogContent>
          <DialogActions>
            <Button className="mr-8" onClick={handleAction} color="primary" autoFocus>
              {restriction.button.text}
            </Button>
          </DialogActions>
        </Dialog>
        {!restriction.hierarchy ? (
          <Button
            color={restriction.color ?? ''}
            onClick={handleOnClick}
            icon={<FontAwesomeIcon icon={faLock} />}
          >
            {props.text}
          </Button>
        ) : (
          <ButtonComponent onClick={handleOnClick} className={classes.iconButton}>
            <FontAwesomeIcon icon={faLock} />
            <Typography className={classes.text}> {props.text} </Typography>
          </ButtonComponent>
        )}
      </React.Fragment>
    ) : (
      <WrappedComponent {...props} />
    )
}

export default withRestrictions
