import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import StackedBarChart from 'components-new/charts/StackedBarChart'
import { convertToNameAndValues } from 'utils-new/api-to-chart-data-converters'
import { employeesData } from './ListCreditScoreByEmployeesBox.model'
import { useSelector } from 'react-redux'
import { useMonitoringStatistic } from 'services/queries'
import { MonitoringValuesQueryDataType } from 'types/queries'

const ListCreditScoreByEmployeesBox = ({ listId }: { listId: string }) => {
  employeesData.parameters.list_id = Number(listId)
  employeesData.chartProperties.tooltipWithPercents = true
  const userLanguage = useSelector((state: any) => state.auth?.user?.local?.lang)

  const employeesQuery = useMonitoringStatistic({
    parameters: employeesData.parameters,
    endpoint: employeesData.endPoint,
    cacheTime: 0,
  })

  const employeesQueryData = employeesQuery.data as MonitoringValuesQueryDataType[]
  const boxData = {
    title: employeesData.chartProperties.title,
    status: {
      isLoading: employeesQuery.isLoading,
      isError:
        employeesQuery.isError ||
        (employeesQueryData && !employeesQueryData?.length && !employeesQuery.isLoading),
      isIdle: employeesQuery.isIdle,
      isNoData: !employeesQueryData?.length,
    },
    height: employeesData.chartProperties.height,
    skeletonType: 'HORIZONTAL_BAR',
  }
  if (
    employeesQueryData?.length &&
    !convertToNameAndValues(employeesQueryData, userLanguage).length
  ) {
    boxData.status.isError = true
  }

  return (
    <BoxContainer boxData={boxData}>
      <Box data-cy="list-credit-score-by-employee-chart" m={2} pt={1}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <StackedBarChart
            data={convertToNameAndValues(employeesQueryData, userLanguage)}
            chartProperties={employeesData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListCreditScoreByEmployeesBox
