import { ConditionallyRender, Sidescroll } from 'components'
import RolesTable from 'pages/credit-check/scenes/RolesTable'
import OwnersSimpleView from './components/OwnersSimpleView'
import { setOwnerData } from './components/setOwnerData'
import { useCreditCheck } from 'pages/credit-check/context'
import { useCompanyRelations } from 'services/queries'
import { isComponentRendered } from 'configs'
import { Stack, Box } from '@mui/material'
import { Owner } from 'types/general'
import { LocalId } from 'globalTypes'
import CompanyOwnersSkeleton from './CompanyOwners.skeleton'

const ownerHeaders = [
  { text: 'name', size: '36%' },
  { text: 'position', size: '19.5%' },
  { text: 'ownership', size: '17.5%' },
  { text: 'votingRights', size: '14%' },
  { text: 'specialRights', size: '15%' },
  { text: 'from', size: '' },
]

const ownerRoles = ['BENEFICIAL OWNER', 'LEGAL OWNER']

type Props = {
  isShowMore: boolean
  subsidiaryCount: number
  hasHierarchy: boolean
  setIsRendering: Function // Handles the rendering of the parent component (SceneWrapper)
}

const CompanyOwners = ({
  isShowMore,
  subsidiaryCount = 0,
  hasHierarchy,
  setIsRendering,
}: Props) => {
  const { localId } = useCreditCheck() as { localId: LocalId }
  const companyRelationsQuery = useCompanyRelations(localId)

  if (companyRelationsQuery.isLoading) {
    return <CompanyOwnersSkeleton />
  }

  const data = companyRelationsQuery.data
  const legalOwners = data?.legalOwners ?? []
  const realOwners = data?.realOwners ?? []
  const sortByFunction = (owner: Owner) => (owner.type === 'legal' ? -1 : 1)
  const unsortedOwnerData = setOwnerData(
    ownerRoles,
    [...legalOwners, ...realOwners],
    localId?.country
  )

  const ownerData = unsortedOwnerData.sort((a) => sortByFunction(a))
  const stripFunctionsForOwners = (array: Array<Owner>, roles: string[]) => {
    return array?.map((person) => ({
      ...person,
      functions: person.functions.filter((x) => roles?.includes(x.function)),
      shares: person.shares === '100.00%' ? '100%' : person.shares,
    }))
  }

  if (!isComponentRendered('owners', localId?.country)) {
    setIsRendering(false)
    return null
  }

  return (
    <Stack data-cy="owners-component" spacing={2} style={{ padding: '20px 20px 0 20px' }}>
      <OwnersSimpleView
        ownerData={ownerData}
        localId={localId}
        subsidiaries={subsidiaryCount}
        hasHierarchy={hasHierarchy}
        stripFunctionsForOwners={stripFunctionsForOwners}
        legalOwners={legalOwners}
        realOwners={realOwners}
        country={localId?.country as string}
      />
      <ConditionallyRender
        condition={isShowMore}
        when={
          <Box sx={{ marginTop: '60px !important' }}>
            <Sidescroll conditionalySidescroll maxWidth={'1400px'}>
              <RolesTable
                // @ts-expect-error - The component is JS
                title={'management'}
                tableHeaders={ownerHeaders}
                data={ownerData}
                isShowAll={true}
              />
            </Sidescroll>
          </Box>
        }
      />
    </Stack>
  )
}

export default CompanyOwners
