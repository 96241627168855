import { riskColors } from 'configs/constants/theme'

/**
 * This simple function limits the string to X (charLimit) characters, adding
 * three dots that replaces all content after X (charLimit) characters
 * @param {string} stringToLimit String you want to limit
 * @param {string} charLimit How many characters of the string will be visible before ...
 */
export const limitString = (stringToLimit: string, charLimit: number) => {
  return stringToLimit?.length > charLimit
    ? stringToLimit?.substring(0, charLimit) + '...'
    : stringToLimit
}

/**
 * This simple function returns the shallow clone of an array
 * @param {Array} array array to be shallow cloned
 * @return { Array } shallow cloned array
 */
export const shallowCloneArray = (array: any[]) => {
  return array.map((a) => {
    return { ...a }
  })
}

/**
 * This function filters array of objects
 * and removes objects where all values are 0
 * @param {Array} objKeys keys of the objects
 * @param {Array} arr array of objects to be filtered
 * @return { Array } new array without any objects that contain only 0 values
 */
export const removeZeroValuesObjArr = (
  objKeys: string[],
  arr: { [key: string]: number | string }[]
) => {
  const newData = arr.filter((obj) => {
    for (let i = 0; i < objKeys.length; i++) {
      if (obj[objKeys[i] as keyof typeof obj] !== 0) return obj
    }
  })
  return newData
}

/**
 * This simple function returns the appropriate color based on the Risika score
 * @param {number} Risika score represented with a number
 * @return { string } hex code of the color
 */
