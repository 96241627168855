import React, { useState } from 'react'
import { useCountryPolicy } from '../../CountryPolicyProvider'
import { Box, Stack } from '@mui/material'
import classes from '../../AdvanceCreditPolicyPage.styles'
import SwitchWithLabel from '../../../enterprise-credit-policy/widgets/SwitchWithLabel'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ButtonSecondary } from 'components-new'
import ActivationPolicyModal from '../Modals/ActivationPolicyModal'
import PlanRestrictionModal from '../Modals/PlanRestrictionModal'

const BottomBar = () => {
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false)
  const { isEnabled, updatePolicy } = useCountryPolicy()
  return (
    <>
      <Stack
        className="TEST_BAR+!@"
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        sx={classes.saveSection}
      >
        <Box sx={classes.parentContainer}>
          <SwitchWithLabel
            labelChecked={<FormattedMessage id={intl.creditPolicyNew('active')} />}
            labelUnChecked={<FormattedMessage id={intl.creditPolicyNew('inactive')} />}
            checked={isEnabled}
            onChange={() => setIsActivationModalOpen(true)}
          />
          <ButtonSecondary data-cy="acp-save" onClick={() => updatePolicy()}>
            <FormattedMessage id={intl.creditPolicyNew('save-changes')} />
          </ButtonSecondary>
        </Box>
      </Stack>

      <ActivationPolicyModal
        isOpen={isActivationModalOpen}
        setIsOpen={setIsActivationModalOpen}
        policyInject={useCountryPolicy}
      />
      <PlanRestrictionModal />
    </>
  )
}

export default BottomBar
