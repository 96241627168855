import type { GazzeteReportType } from 'types/general'

/**
 * Uses transformed data from [useStatstidende] <-- [`/company/events/${id}`]
 * and returns the newest report (most relevant report)
 *
 * @param {GazzeteReportType[]} data - data format from the query.
 * @return {GazzeteReportType || null} - newest report
 */

export const getLastGazzeteReport = (data: GazzeteReportType[]) => {
  if (!data) return null
  if (data?.length === 0) return null

  const latestReport = data.reduce((oldestReport, currentReport) => {
    if (currentReport.date === oldestReport.date) {
      if (currentReport.type === 'DECREE CANCELLED') return currentReport
      if (oldestReport.type === 'DECREE CANCELLED') return oldestReport
      return oldestReport
    }
    return currentReport.date <= oldestReport.date ? oldestReport : currentReport
  }, data[0])

  return latestReport
}
