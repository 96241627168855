const ScorecardModel = [
  {
    name: 'high_positive_impact',
    color: 'success.700',
  },
  {
    name: 'moderate_positive_impact',
    color: 'success.300',
  },
  {
    name: 'minor_impact',
    color: 'warning.500',
  },
  {
    name: 'moderate_negative_impact',
    color: 'error.300',
  },
  {
    name: 'high_negative_impact',
    color: 'error.700',
  },
]

export default ScorecardModel
