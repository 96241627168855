const container = (disabled: boolean) => ({
  paddingY: 1,
  paddingX: 2,
  backgroundColor: disabled ? 'secondary.100' : 'grey.100',
  border: '1px solid',
  borderColor: disabled ? 'secondary.700' : 'grey.600',
  borderRadius: '20px',
  fontSize: 12,
  color: 'secondary.700',
})

const textStyles = (disabled: boolean) => ({
  fontFamily: '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '11px',
  letterSpacing: '0.5px',
  marginLeft: disabled ? '4px!important' : 0,
  color: disabled ? 'secondary.700' : 'grey.600',
})

export default { container, textStyles }
