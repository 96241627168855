import * as React from 'react'
import { useBuilder } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext'
import { SubmitRef } from '..'
import { BooleanProps } from './Actions.types'

function Boolean(
  { setIsConfirmEnabled }: BooleanProps,
  ref: React.ForwardedRef<SubmitRef>
) {
  const { state, actions } = useBuilder()

  setIsConfirmEnabled(true)

  React.useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.setNodeApiData({
          nodeId: state.actionPanelData.nodeId,
          data: {
            equals: true,
          },
        })
      },
    }),
    [actions, state.actionPanelData.nodeId]
  )

  return null
}

export default React.forwardRef(Boolean)
