import React, { useEffect, useMemo, useState } from 'react'
import SingleSelect from 'components-new/inputs/SingleSelect'
import PaperContainer from 'pages/home/widgets/PaperContainer'
import { useUserSettingsField } from 'services/queries'
import { useCompanyChanges } from 'services/queries/useCompanyChanges'
import { CompanyChangeComponent } from './CompanyChange'
import { getNMostRecentChanges, homePageSingleColumnWidth } from './utils'
import { container, fallbackText, singleSelect } from './RecentChangesTable.styles'
import { RecentChangesLoadingState } from './RecentchangesSkeleton'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { AvailableLanguages } from 'globalTypes'
import { Box, Typography } from '@mui/material'
import { ShowListsType } from 'types/queries'
import useWindowDimensions from 'components-new/hooks/userWindowDimensions'

const defaultListStateTranslations = {
  en_UK: 'All lists',
  da_DK: 'Alle lister',
  nb_NO: 'Alle lister',
  sv_SE: 'Alla listor',
}

type RecentChangesProps = {
  data: ShowListsType | undefined
  isShowListsLoading: boolean
}

export const RecentChangesTable = (props: RecentChangesProps) => {
  const { data: showListsData, isShowListsLoading } = props
  const { data: language = 'en_UK' } =
    useUserSettingsField<AvailableLanguages>('language')
  const listNames = showListsData?.map((list) => list.name) || []
  const listIds = showListsData?.map((list) => list.list_id) || []
  const { width } = useWindowDimensions()

  const { data: companyChangesData, isLoading: isCompanyChangesLoading } =
    useCompanyChanges(listIds)

  const isLoading = isShowListsLoading || isCompanyChangesLoading

  const allListData = companyChangesData
    ?.flat()
    ?.flat()
    .sort((a, b) => +new Date(b.date) - +new Date(a.date))

  const [dropdownValue, setDropdownValue] = useState(
    defaultListStateTranslations[language]
  )
  const [dataToParse, setDataToParse] = useState(allListData)

  useEffect(() => {
    if (!dataToParse?.length && !dataToParse && allListData?.length) {
      setDataToParse(allListData)
    }
  }, [allListData, dataToParse])

  useEffect(() => {
    setDropdownValue(defaultListStateTranslations[language])
  }, [language])

  const handleDropDownChange = (value: string) => {
    setDropdownValue(value)
    const selectedCompanyIndex =
      showListsData?.findIndex((list) => list.name === value) || 0
    setDataToParse(companyChangesData?.[selectedCompanyIndex])
  }

  const mostRecentChanges = useMemo(() => {
    return getNMostRecentChanges(dataToParse)
  }, [dataToParse])

  const defaultListSelection = defaultListStateTranslations[language]

  const displayFallback = !mostRecentChanges?.length && !isLoading

  return (
    <Box width={width > homePageSingleColumnWidth ? '70%' : '100%'} sx={container}>
      <PaperContainer
        title={<FormattedMessage id={intl.homePage('portfolio-recent-changes')} />}
        minHeight={550}
        minWidth={330}
      >
        <SingleSelect
          options={[defaultListSelection, ...listNames]}
          value={dropdownValue}
          handleChange={handleDropDownChange}
          sx={singleSelect}
          optionIsRawString
          dataCy="recent-changes"
          isLoading={isShowListsLoading}
        />
        {isLoading && <RecentChangesLoadingState />}
        {displayFallback ? (
          <Box sx={fallbackText}>
            <Typography variant="body2">
              <FormattedMessage id={intl.homePage('no-data')} />
            </Typography>
          </Box>
        ) : (
          mostRecentChanges?.map((data, i) => {
            return <CompanyChangeComponent data={data} key={i} />
          })
        )}
      </PaperContainer>
    </Box>
  )
}
