import React from 'react'
import { makeStyles } from '@mui/styles'
import { Paper } from '@mui/material'
import { Stack } from 'components'
import MainRecommendation from './MainRecommendation/index.js'
import FreeMainRecommendation from '../scenes/FreeMainRecommendation'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { colors } from 'configs'
import { useAccountStatus } from 'services/queries'
import { useUserSettingsField } from 'services/queries'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  mainContent: {
    overflow: 'hidden',
    flexGrow: 2,
    padding: '0.3rem',
    margin: '2rem',
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
  sideContent: {
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 0,
    width: 500,
    [theme.breakpoints.down('1535')]: {
      width: 350,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}))

function CustomCreditCheckWrapper({
  children,
  isReArrange,
  isElementActive,
  setIsElementActive,
  isSidebarElementActive,
  setIsSidebarElementActive,
  localSidebarData,
  setLocalSidebarData,
  localCreditCheckData,
}) {
  const classes = useStyles()
  const { data: creditCheckFields, isLoading } = useUserSettingsField('credit_check')
  const accountStatusQuery = useAccountStatus()

  const setVisibilityByKey = (key) => {
    setIsElementActive({ ...isElementActive, [key]: !isElementActive[key] })
  }

  const getListStyle = () => ({
    background: colors?.risikaLight,
    display: 'flex',
    flexDirection: 'column',
  })
  const getItemStyle = (isDragging, draggableStyle, order) => ({
    borderRadius: '0.5rem',
    marginBottom: '1rem',
    order: order,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // change background colour if dragging
    background: isDragging ? colors?.risikaContrast : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  if (accountStatusQuery.isLoading) {
    return null
  }

  const getCorrectChild = (id) => {
    return children.filter((x) => x.props.draggable_id === id)?.[0]
  }

  return (
    <div className={classes.container}>
      <Stack spacing={2} direction="column" className={classes.mainContent}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              data-cy="main-droppable-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {!isLoading &&
                localCreditCheckData &&
                creditCheckFields?.map(({ key }, i) => (
                  <Draggable
                    key={key}
                    draggableId={key}
                    index={i}
                    isDragDisabled={!isReArrange}
                  >
                    {(provided, snapshot) => (
                      <div
                        data-cy={`draggable-${key}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {React.cloneElement(getCorrectChild(key), {
                          isReArrange: isReArrange,
                          isActive: isElementActive[key],
                          setIsActive: setVisibilityByKey,
                        })}
                      </div>
                    )}
                  </Draggable>
                ))}
              {window.Cypress && (
                <div data-cy="droppable-container-bottom" style={{ height: 1 }} />
              )}
            </div>
          )}
        </Droppable>
      </Stack>
      <Paper className={classes.sideContent} elevation={1}>
        {accountStatusQuery.data?.subscription_plan === 'FREEMIUM' ? (
          <FreeMainRecommendation
            isReArrange={isReArrange}
            accountStatus={accountStatusQuery}
          />
        ) : (
          <MainRecommendation
            isReArrange={isReArrange}
            isElementActive={isSidebarElementActive}
            setIsElementActive={setIsSidebarElementActive}
            localSidebarData={localSidebarData}
            setLocalSidebarData={setLocalSidebarData}
          />
        )}
      </Paper>
    </div>
  )
}

export default CustomCreditCheckWrapper
