import { CommunicationModal } from 'components-new'

export type RequestSuccessModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  handleClose: () => void
  onClose?: () => void
}

const RequestSuccessModal = ({
  open,
  setOpen,
  handleClose,
  onClose,
}: RequestSuccessModalProps) => {
  return (
    <CommunicationModal
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      title={'Your request has been submitted'}
      body={
        'A member of the Financial team will get back to you with a response as soon as possible'
      }
      mainButton={{
        content: 'Close',
        click: handleClose,
      }}
    />
  )
}

export default RequestSuccessModal
