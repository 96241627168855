import { default as cx } from 'classnames'

import React from 'react'
import { Card, CardActionArea, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { link1 } from 'styles-new/global/typography'

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(0),
  },
  cardActionArea: {
    padding: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(2),
  },
  text: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
  },
  blurred: {
    filter: 'blur(3px)',
    userSelect: 'none',
  },
  notBlurred: {},
}))

// type Props = {
//   children: Node,
//   content: (open: boolean, handleClose: Function) => Node,
//   text: Node,
//   blurred: boolean,
// }

const InteractiveHighlight = ({ children, content, text, blurred }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    if (blurred !== true) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {content(open, handleClose)}
      <Card
        className={cx(blurred ? classes.blurred : classes.notBlurred, classes.card)}
        sx={{ boxShadow: 3, p: 2, mb: 1 }}
      >
        <CardActionArea className={classes.cardActionArea} onClick={handleOpen}>
          {children}
          <div className={classes.footer}>
            {/* TODO: The sx props down bellow are temporary solution.After Can gives it any typography */}
            {/* category line h1, h2, h3 subtitle1 or anything we'll just add variant and remove the sx */}
            {/* For now, it seems to have custom styles in the design */}
            <Typography sx={{ ...link1 }}>{text}</Typography>
          </div>
        </CardActionArea>
      </Card>
    </>
  )
}

export default InteractiveHighlight
