import { Box, Skeleton, Stack } from '@mui/material'
import FilterMultiSelect from 'components-new/data-display/Table/elements/inputs/FilterMultiSelect'

const tableFilters = {
  highlights: {
    'industry_risk-0': {
      label: 'Industry risk',
      color: 0,
      checked: false,
      category: 'industry_risk',
      value: 'INCREASED RISK',
    },
    'profit_loss-1': {
      label: 'More than 20% profit or loss decline',
      color: 0,
      checked: false,
      category: 'profit_loss',
      value: 'DECREASE',
    },
    'change_in_revenue-2': {
      label: 'More than 20% drop in revenue',
      color: 0,
      checked: false,
      category: 'change_in_revenue',
      value: 'DECREASE IN REVENUE',
    },
    'equity-3': {
      label: 'Negative equity',
      color: 0,
      checked: false,
      category: 'equity',
      value: 'NEGATIVE EQUITY',
    },
    'profit_loss-4': {
      label: 'Generated loss',
      color: 0,
      checked: false,
      category: 'profit_loss',
      value: 'LOSS',
    },
    'three_years_profit_loss-5': {
      label: '3-year loss streak',
      color: 0,
      checked: false,
      category: 'three_years_profit_loss',
      value: 'TRIPLE NEGATIVE',
    },
    'return_on_assets-6': {
      label: 'Low return on assets',
      color: 0,
      checked: false,
      category: 'return_on_assets',
      value: 'WEAK',
    },
    'solidity_ratio-7': {
      label: 'Low solidity',
      color: 0,
      checked: false,
      category: 'solidity_ratio',
      value: 'WEAK',
    },
    'intangible_assets-8': {
      label: 'Intangible assets',
      color: 0,
      checked: false,
      category: 'intangible_assets',
      value: 'MANY INTANGIBLE ASSETS',
    },
    'current_ratio-9': {
      label: 'Low liquidity',
      color: 0,
      checked: false,
      category: 'current_ratio',
      value: 'WEAK',
    },
    'connected_bankruptcies-10': {
      label: 'More than 4 related bankruptcies',
      color: 0,
      checked: false,
      category: 'connected_bankruptcies',
      value: '>=4',
    },
    'politically_exposed_person-11': {
      label: 'Politically exposed person',
      color: 0,
      checked: false,
      category: 'politically_exposed_person',
      value: 'HAS_PEP',
    },
  },
  'credit_policy.violations': {},
  free_search: '',
  'risk_assessment.code': {
    LOW: {
      label: 'main-recommendation.risk-level-low',
      color: '#17BEBB',
      value: 'LOW',
      checked: true,
    },
    MEDIUM: {
      label: 'main-recommendation.risk-level-medium',
      color: '#F9A96E',
      value: 'MEDIUM',
      checked: true,
    },
    HIGH: {
      label: 'main-recommendation.risk-level-high',
      color: '#FF684F',
      value: 'HIGH',
      checked: true,
    },
    UNKNOWN: {
      label: 'main-recommendation.risk-level-unknown',
      color: '#010B0C',
      value: 'UNKNOWN',
      checked: true,
    },
  },
}
function MonitoringTableFiltersLoading() {
  return (
    <Stack direction="row" flexWrap="wrap" spacing={2}>
      {Object.keys(tableFilters).map((filterCategory: any, index: number) => {
        return (
          <Skeleton key={filterCategory}>
            <Box sx={{ height: '6.8rem' }}>
              <FilterMultiSelect
                key={filterCategory}
                dataCy={'loading filter multi select'}
                id={filterCategory}
                // @ts-ignore // This is just a loading component. TS should not be so strict unless it breaks something and it doesnt
                filterData={tableFilters[filterCategory]}
                handleFilters={() => {}}
                tag={filterCategory}
              />
            </Box>
          </Skeleton>
        )
      })}
    </Stack>
  )
}

export default MonitoringTableFiltersLoading
