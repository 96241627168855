import { SxProps } from '@mui/system'
import { colors } from 'configs'
import { tabButtons } from 'styles-new/global/inputs'
import { theme } from 'styles-new/mui5-transition/theme'

const portfolioInsights: SxProps = {
  overflow: 'hidden',
  height: 1,
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}

const portfolioInsightsTabs: SxProps = {
  backgroundColor: colors.risikaLight,
  '& button': {
    ...tabButtons,
  },
}

const tabContentContainer: SxProps = {
  width: 425,
  height: '80%',
  padding: 4,
  overflow: 'scroll',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}

const tabButtonsContainer: SxProps = {
  background: theme.palette.grey['100'],
  px: 3,
  py: 2,
  boxShadow: '0 0 10px lightgrey',
}

const buttonGroup: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}

const tabButton: SxProps = {
  flex: 1,
  minHeight: '40px',
  textTransform: 'capitalize',
  fontSize: '1.4rem',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  background: 'white',
  borderColor: theme.palette.grey['400'],
  color: theme.palette.primary.main,
  transition: 'font-weight 0.1s',
  '&:hover': {
    borderColor: theme.palette.grey['400'],
    borderRightColor: `${theme.palette.grey['400']} !important`,
  },
}

const activeTabButton: SxProps = {
  color: '#1876d2',
  fontWeight: 600,
}

const mobileCloseButton: SxProps = {
  textTransform: 'capitalize',
  fontSize: '1.4rem',
  color: theme.palette.common.black,
  marginBottom: 2,
  fontFamily: '"Montserrat",sans-serif',
  fontWeight: 400,
}

export const styles = {
  portfolioInsights,
  portfolioInsightsTabs,
  tabContentContainer,
  tabButton,
  tabButtonsContainer,
  buttonGroup,
  activeTabButton,
  mobileCloseButton,
}
