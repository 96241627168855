import { CurrencyObj } from 'services/types'

export const availableCountriesAndCurrencies: CurrencyObj[] = [
  {
    currency: 'DKK',
    country: 'DK',
  },
  {
    currency: 'NOK',
    country: 'NO',
  },
  {
    currency: 'SEK',
    country: 'SE',
  },
  {
    currency: 'EUR',
    country: 'EU',
  },
  {
    currency: 'USD',
    country: 'US',
  },
  {
    currency: 'GBP',
    country: 'GB',
  },
  {
    currency: 'ARS',
    country: 'AR',
  },
  {
    currency: 'AUD',
    country: 'AU',
  },
  {
    currency: 'CAD',
    country: 'CA',
  },
  {
    currency: 'CHF',
    country: 'CH',
  },
  {
    currency: 'CZK',
    country: 'CZ',
  },
  {
    currency: 'INR',
    country: 'IN',
  },
  {
    currency: 'JPY',
    country: 'JP',
  },
  {
    currency: 'PLN',
    country: 'PL',
  },
  {
    currency: 'QAR',
    country: 'QA',
  },
  //   { // Just in case :D
  //     currency: 'RUB',
  //   country: "RU",
  //   },
]

export const eurCountries = [
  'DE',
  'FI',
  'FR',
  'IT',
  'NL',
  'ES',
  'PT',
  'AT',
  'BE',
  'IE',
  'LU',
  'MC',
  'SM',
  'VA',
]
