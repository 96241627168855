// @flow

import { useQuery } from 'react-query'
import { financialNumbers } from 'services/api'
import { isCallAllowed } from 'configs'
import { useUserSettings } from './useUserSettings'
import { LocalId } from 'globalTypes'

export function useFinancialNumbersConsolidated(
  localId: LocalId,
) {
  const userSettingsQuery = useUserSettings()
  return useQuery(
    ['financialNumbersConsolidated', localId.id, localId.country],
    () =>
      financialNumbers(localId, {
        consolidated: true,
      }),
    {
      enabled: isCallAllowed('financialNumbersConsolidated', localId.country)
        ? !userSettingsQuery.isLoading || userSettingsQuery.isError
        : false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
