import { ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Stack, Typography } from '@mui/material'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { ReactSetState } from 'types/general'
import { SubmitRef } from '../index'
import { useBuilder } from '../../../../../BuilderContext'

export type DeleteNodeProps = {
  setIsConfirmEnabled: ReactSetState<boolean>
}

const DeleteRule = (
  { setIsConfirmEnabled }: DeleteNodeProps,
  ref: ForwardedRef<SubmitRef>
) => {
  const { actions } = useBuilder()

  setIsConfirmEnabled(true)

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.deleteNode()
      },
    }),
    [actions]
  )

  return (
    <Stack height={1} p={5} justifyContent="center" alignItems="flex-start" gap={4}>
      <Typography>
        <b>
          <FormattedMessage id={intl.creditPolicyNew('delete-rule-are-you-sure')} />
        </b>
      </Typography>
      <Typography>
        <FormattedMessage id={intl.creditPolicyNew('delete-rule-explanation')} />
      </Typography>
    </Stack>
  )
}

export default forwardRef(DeleteRule)
