import { CreditCheckPageConfig } from './credit-check'
import { CreditPolicyPageConfig } from './credit-policy'
import { EnterpriseCreditPolicyPageConfig } from './enterprise-credit-policy'
import { AdvanceCreditPolicyPageConfig } from './advanced-credit-policy'
import { RelationOverviewPageConfig } from './relation-overview'
import { SettingsPageConfig } from './settings'
import { HomePageConfig } from './home'
import { NotFoundConfig } from './not-found-page'
import { CompanyHierarchyConfig } from './company-hierarchy'
import { AdvancedFiltersPageConfig } from './advanced-filters'
import { RiskMonitoringPageConfig } from './risk-monitoring'
import { CompanyComparisonPageConfig } from './company-comparison'
import { ObservationalListsPageConfig } from './observational-lists/index.ts'

export const risikaPagesConfigs = [
  CreditCheckPageConfig,
  CreditPolicyPageConfig,
  AdvanceCreditPolicyPageConfig,
  EnterpriseCreditPolicyPageConfig,
  RelationOverviewPageConfig,
  SettingsPageConfig,
  HomePageConfig,
  CompanyHierarchyConfig,
  AdvancedFiltersPageConfig,
  RiskMonitoringPageConfig,
  CompanyComparisonPageConfig,
  ObservationalListsPageConfig,
  NotFoundConfig, // THIS MUST BE THE LAST ENTRY IN THE LIST
]
