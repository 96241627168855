import { ButtonSecondary } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import ArticleIcon from '@mui/icons-material/Article'

const handleUrl = (url) => {
  return url?.replace('http://regnskaber.virk.dk', 'https://api.risika.dk/dk/report')
}

export const AnnualReport = ({ url }) => (
  <a
    href={handleUrl(url)}
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: 'none' }}
  >
    <ButtonSecondary
      startIcon={<ArticleIcon sx={{ fontSize: '16px !important' }} />}
      disabled={!url}
      style={{ whiteSpace: 'nowrap' }}
    >
      <FormattedMessage id={intl.keyFinancialFigures('annual-report-button')} />
    </ButtonSecondary>
  </a>
)
