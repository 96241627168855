import { useState, FormEvent } from 'react'
import { Box, Stack, Typography, TypographyVariant } from '@mui/material'
import { Link } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { resetPassword } from 'services/api'
import { useSnackbar } from 'notistack'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ButtonPrimary, InputField } from 'components-new'

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [email, setEmail] = useState<string>('')
  const [emailSent, setEmailSent] = useState(false)

  const handleSendEmail = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    resetPassword(email)
      .then(() => {
        setEmailSent(true)
      })
      .catch((error) => {
        enqueueSnackbar(<FormattedMessage id={intl.auth('forgot-password-failed')} />)
      })
  }

  if (emailSent) {
    return (
      <Stack py={7} px={6} width={400} gap={5} borderRadius={1} bgcolor="common.white">
        <Stack gap={3}>
          <Typography variant="h3">
            <FormattedMessage id={intl.auth('forgot-password-email-title')} />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id={intl.auth('forgot-password-email-subtitle')} />
          </Typography>
        </Stack>
        <ButtonPrimary component={Link} to={navigation.auth.login()}>
          <FormattedMessage id={intl.auth('back-to-sign-in')} />
        </ButtonPrimary>
        <Box alignSelf="start">
          <Typography
            variant={'link2' as TypographyVariant}
            component="button"
            onClick={() => setEmailSent(false)}
          >
            <FormattedMessage id={intl.auth('send-instructions-again')} />
          </Typography>
        </Box>
      </Stack>
    )
  }

  return (
    <Stack py={7} px={6} width={400} gap={5} borderRadius={1} bgcolor="common.white">
      <Stack gap={2}>
        <Typography variant="h3">
          <FormattedMessage id={intl.auth('forgot-password-title')} />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id={intl.auth('forgot-password-subtitle')} />
        </Typography>
      </Stack>
      <Stack component="form" gap={5} mb={2} onSubmit={handleSendEmail}>
        <InputField
          id="email"
          handleChange={setEmail}
          value={email}
          type="email"
          label={<FormattedMessage id={intl.auth('email-label')} />}
          autoFocus
        />
        <ButtonPrimary type="submit">
          <FormattedMessage id={intl.auth('send-instructions')} />
        </ButtonPrimary>
      </Stack>
      <Typography
        variant={'link2' as TypographyVariant}
        component={Link}
        to={navigation.auth.login()}
      >
        <FormattedMessage id={intl.auth('back-to-sign-in')} />
      </Typography>
    </Stack>
  )
}

export default ForgotPassword
