import { ExportToCsv } from 'export-to-csv'

/**
 * Create options for exporting as CSV.
 */
const downloadCSV = (filename, data) => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    title: filename,
    filename,
  }

  const csvExporter = new ExportToCsv(options)
  if (data?.length) csvExporter.generateCsv(data)
}

export default downloadCSV
