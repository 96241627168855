import { Stack, Typography, IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import IntlTypography from 'components-new/elements/IntlTypography'
import useListOwner from 'pages/risk-monitoring/hooks/useListOwner'
import { classes } from 'pages/risk-monitoring/styles/table-name'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

type Props = {
  setIsEditing: (isEditing: boolean) => void
  listName: string
}

const TableName = ({ setIsEditing, listName }: Props) => {
  const { owner } = useListOwner()

  return (
    <Stack spacing={1} direction="row" sx={classes.main}>
      <IconButton sx={classes.editListIcon} onClick={() => setIsEditing(true)}>
        <FontAwesomeIcon icon={faPen as IconDefinition} />
      </IconButton>
      <Typography>{`${listName} |`}</Typography>
      {owner ? (
        <Stack direction="row">
          {owner?.name && <Typography>{`\u00A0${owner?.name},`}</Typography>}
          <Typography>{`  ${owner.email}`}</Typography>
        </Stack>
      ) : (
        <IntlTypography group="riskMonitoringNew" labelKey="new-list-public" />
      )}
    </Stack>
  )
}

export default TableName
