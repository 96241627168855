import { Stack } from '@mui/material'
import { DropdownSelect } from 'components-new'
import { DropdownRangeProps } from './DropdownRange.types'
import useInputRange from 'services/hooks/useInputRange'
import styles from '../InputRange/InputRange.styles'

const { containerMain } = styles

const DropdownRange = (props: DropdownRangeProps) => {
  const {
    value,
    setValue,
    customValidationFn,
    skipValidation = false,
    options,
    singleInputRangeField = false,
  } = props

  const { errorA, errorB, errorMessage, handleInputBChange, handleInputAChange } =
    useInputRange({
      value,
      setValue,
      customValidationFn,
      skipValidation,
    })

  return (
    <Stack sx={containerMain(singleInputRangeField)}>
      <DropdownSelect
        options={options}
        value={value.a}
        setValue={handleInputAChange}
        error={errorA}
        errorMessage={errorMessage}
        enableRangeDash
      />
      <DropdownSelect
        options={options}
        value={value.b}
        setValue={handleInputBChange}
        error={errorB}
        errorMessage={errorMessage}
        hideExtraText
      />
    </Stack>
  )
}

export default DropdownRange
