import { useState, SyntheticEvent, useEffect, ReactNode } from 'react'
import Destination from './widgets/Destination'
import Frequency from './widgets/Frequency'
import Status from './widgets/Status'
import { Stack, Button, Snackbar, Alert, Skeleton } from '@mui/material'
import { useSubscriptionShow } from 'services/queries'
import { isEmail } from 'utils-new/is-email'
import { FrequencyData } from './widgets/Frequency/Frequency.types'
import { FormattedMessage, MessageFormatElement, useIntl } from 'react-intl'
import intl from 'localization/components'
import { styles } from './Scheduler.styles'
import { SubscriptionProps } from 'types/queries'
import subscriptionUpsert from 'services/api/endpoints/risikaAPI/subscription/subscriptionUpsert'
import { defaultFrequencyData } from './widgets/Frequency/Frequency.model'
import { mapFrequencyDataFromRequestToState } from './Scheduler.controller'
import { isEmpty } from 'lodash'

const Scheduler = ({ listId }: { listId: string }) => {
  const { messages: translation } = useIntl()
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<
    string | MessageFormatElement[]
  >()
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success')
  // Destination
  const subscriptionsQuery = useSubscriptionShow(listId, 'CREDIT_COMPLIANCE')
  const [destination, setDestination] = useState<string>('email')
  const [subscriptionsList, setSubscriptionsList] = useState<string[]>([])
  const [subscriptionExistsInDatabase, setSubscriptionExistsInDatabase] = useState(false)
  const [emailFieldInvalid, setEmailFieldInvalid] = useState(false)
  // Frequency
  const [frequencyData, setFrequencyData] = useState<FrequencyData | {}>({})

  // Status
  const [subscriptionActive, setSubscriptionActive] = useState(false)
  const [subscriptionsContainInvalidEmail, setSubscriptionsContainInvalidEmail] =
    useState(false)

  const [saveChangesDisabled, setSaveChangesDisabled] = useState(true)

  // Set email recipients
  useEffect(() => {
    if (subscriptionsQuery.isSuccess && !isEmpty(subscriptionsQuery.data)) {
      const { emails } = subscriptionsQuery.data
      if (emails.length && !subscriptionsList.length) setSubscriptionsList(emails)
    }
  }, [subscriptionsQuery])

  // State cleanup
  useEffect(() => {
    return () => {
      setSubscriptionsList([])
      setFrequencyData({})
      setSubscriptionActive(false)
    }
  }, [])

  // Set Frequency data from request or default
  useEffect(() => {
    if (subscriptionsQuery.isSuccess) {
      if (!isEmpty(subscriptionsQuery.data)) {
        if (isEmpty(frequencyData)) {
          const mappedData = mapFrequencyDataFromRequestToState(
            subscriptionsQuery.data.scheduler
          )
          setFrequencyData(mappedData)
          setSubscriptionActive(true)
        }
      } else {
        if (isEmpty(frequencyData)) {
          setFrequencyData(defaultFrequencyData)
          setSubscriptionActive(false)
        }
      }
    }
  }, [subscriptionsQuery])

  const handleSave = async () => {
    if (subscriptionsList?.length && !subscriptionsContainInvalidEmail) {
      setSaveChangesDisabled(true)

      const { frequencyPeriod, weekDays, dayOfMonth, selectedDate, frequency } =
        frequencyData as FrequencyData

      const data: SubscriptionProps = {
        listId,
        weekDays,
        dayOfMonth,
        frequency,
        mode: frequencyPeriod,
        starting_date: selectedDate,
        emails: subscriptionsList,
        disabled: !subscriptionActive,
      }
      const response = await subscriptionUpsert(data)
      const isSuccess = `${response?.status}`?.startsWith('20')
      const method = subscriptionExistsInDatabase ? 'update' : 'create'

      if (isSuccess) {
        setSubscriptionExistsInDatabase(true)
        setSnackbarMessage(
          translation[
            intl.portfolioInsights(`tab-scheduler-subscription-${method}-successful`)
          ]
        )
      } else {
        setSnackbarMessage(
          translation[intl.portfolioInsights(`tab-scheduler-subscription-${method}-fail`)]
        )
      }

      setSnackbarSeverity(isSuccess ? 'success' : 'error')
      setSnackbarVisible(true)
      setSaveChangesDisabled(false)
    } else if (subscriptionsList && !subscriptionsList?.length) {
      setEmailFieldInvalid(true)
    }
  }

  const onUpdateSubscriptions = (
    event: SyntheticEvent<Element, Event>,
    emailList: string[]
  ) => {
    const invalidEmail = emailList.find((email: string) => !isEmail(email))

    setSubscriptionsContainInvalidEmail(Boolean(invalidEmail))
    setSubscriptionsList(emailList)
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return

    setSnackbarVisible(false)
  }

  if (subscriptionsQuery.isSuccess && !subscriptionsList) {
    if (subscriptionsQuery.data?.emails?.length) {
      const scheduler = subscriptionsQuery.data.scheduler

      setSubscriptionsList(subscriptionsQuery.data.emails)
      setSubscriptionExistsInDatabase(true)
      setSubscriptionActive(!subscriptionsQuery.data.disabled)
      setSaveChangesDisabled(false)
      setFrequencyData({
        frequency: scheduler.frequency,
        frequencyPeriod: scheduler.mode,
        dayOfMonth: scheduler.mode === 'monthly' ? scheduler.days : ['first_day_month'],
        weekDays: scheduler.days,
        selectedDate: new Date(scheduler.starting_date),
      })
    } else {
      setSubscriptionsList([])
    }
  }

  // Disable the "Save Changes" button, if the subscription does not exist,
  // and "Status" is not toggled to "Active"
  useEffect(() => {
    if (!subscriptionExistsInDatabase) {
      setSaveChangesDisabled(!subscriptionActive)
    }
  }, [subscriptionExistsInDatabase, subscriptionActive])

  return (
    <Stack gap={5}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarVisible}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
          {snackbarMessage as ReactNode}
        </Alert>
      </Snackbar>
      <Destination
        isLoadingSubscriptions={Boolean(
          subscriptionsQuery.isFetching && !subscriptionsList
        )}
        emailFieldInvalid={emailFieldInvalid}
        setEmailFieldInvalid={setEmailFieldInvalid}
        destination={destination}
        setDestination={setDestination}
        subscriptionsList={subscriptionsList}
        subscriptionsUpdate={onUpdateSubscriptions}
      />
      {isEmpty(frequencyData) ? (
        <Skeleton width={200} />
      ) : (
        <Frequency
          frequencyData={frequencyData as FrequencyData}
          setFrequencyData={setFrequencyData}
        />
      )}
      <Status
        status={subscriptionActive}
        handleChangeStatus={() => setSubscriptionActive(!subscriptionActive)}
      />
      <Button
        sx={styles.buttonSaveChanges}
        variant="contained"
        onClick={handleSave}
        disabled={saveChangesDisabled}
      >
        <FormattedMessage id={intl.portfolioInsights('tab-scheduler-save-changes')} />
      </Button>
    </Stack>
  )
}

export default Scheduler
