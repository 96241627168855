import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined'
import BarChartIcon from '@mui/icons-material/BarChart'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'

export const actionPanelIcons = {
  'Company Information': <BusinessOutlinedIcon color="inherit" fontSize="inherit" />,
  'Risk Indicators': <VerifiedUserOutlinedIcon color="inherit" fontSize="inherit" />,
  'Fraud Indicators': <ThumbDownAltOutlinedIcon color="inherit" fontSize="inherit" />,
  'Financial Indicators': <BarChartIcon color="inherit" fontSize="inherit" />,
  'People and Company search': <ListAltOutlinedIcon color="inherit" fontSize="inherit" />,
  Outcome: <FactCheckOutlinedIcon color="inherit" fontSize="inherit" />,
}
export const actionPanelIconsByKeys = {
  variable_category_company_information: (
    <BusinessOutlinedIcon color="inherit" fontSize="inherit" />
  ),
  variable_category_risk_indicators: (
    <VerifiedUserOutlinedIcon color="inherit" fontSize="inherit" />
  ),
  variable_category_fraud_indicators: (
    <ThumbDownAltOutlinedIcon color="inherit" fontSize="inherit" />
  ),
  variable_category_financial_information: (
    <BarChartIcon color="inherit" fontSize="inherit" />
  ),
  variable_category_people_and_company_search: (
    <ListAltOutlinedIcon color="inherit" fontSize="inherit" />
  ),
  variable_category_outcome: <FactCheckOutlinedIcon color="inherit" fontSize="inherit" />,
}
