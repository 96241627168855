const dialog = {
  backgroundColor: 'primary.dark',
  display: 'flex',
  justifyContent: 'space-between',
  paddingY: 4,
  paddingX: 6,
  alignItems: 'center',
  color: 'common.white',
}

const divider = { background: 'grey.500', marginTop: 2 }

const dialogContent = { paddingTop: 4, paddingRight: 5, paddingBottom: 4, paddingLeft: 3 }

export default { dialog, divider, dialogContent }
