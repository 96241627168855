// @flow

/**
 * Find the direct children of a parent.
 */
export function getChildren(state, parent) {
  const childIds = state[parent.id].children
  return childIds.map((childId) => state[childId])
}

/**
 * Find all descendants of a parent.
 */
export function getChildrenDeep(state, parent) {
  const children = []

  function getChildrenDeepRec(state, parent) {
    getChildren(state, parent).forEach((child) => {
      children.push(child)
      getChildrenDeepRec(state, child)
    })
  }

  getChildrenDeepRec(state, parent)

  return children
}

/**
 * Find all the ancestors of a child.
 */
export function getParentsDeep(state, child, parents = []) {
  const { parent } = child

  if (parent != null) {
    const parentNode = state[parent]
    return getParentsDeep(state, parentNode, parents.concat(parentNode))
  }

  return parents
}

/**
 * @description Determines the state of a checkbox component and updates the parent checkboxes
 * recursively.
 * If all children of a parent are checked, the parent will be checked as well, otherwise it will be
 * indeterminate.This is done recursively until the root is reached.This function is meant to
 * mutate the state object (the new one you created and planning to return) and does not return
 * a new one.This is due to performance reasons.When using it in a reducer, make sure to return
 * a new state object, which this function will mutate.
 *
 * @param {string} item - The key of the object representing a checkbox component.
 * @param {Object} state - The state object containing all checkbox components.
 * @returns {void} The updated state object.
 */

export const getParentAndDetermineState = (item, state) => {
  if (!item || !state) return

  const current = state[item]

  if (!current) return

  let isChecked = false

  if (!current.isRoot) {
    const siblings = state[current.parent].children

    if (!siblings || !siblings.length) {
      isChecked = current.state === 'checked'
    } else {
      isChecked = siblings.every((child) => state[child].state === 'checked')
    }
  }

  if (current.parent) {
    const parent = state[current.parent]
    parent.state = isChecked ? 'checked' : 'indeterminate'

    return getParentAndDetermineState(parent.value, state)
  }
}
