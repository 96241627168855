import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

import { default as cx } from 'classnames'

import { Tooltip, Typography, Stack as MUIStack, Skeleton } from '@mui/material'
import { Stack, Button, ActionDialog } from 'components'

import { submitFetchCompanyCreditInformaion } from 'store_deprecated'

import { colors } from 'configs'

import { useAccountStatus } from 'services/queries'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import ConditionallyRender from './ConditionallyRender'
import { useStyles } from '../styles/use-check-button'
import { RISIKA_PRICING_PAGE } from 'configs/constants/system'

// type UseCheckButtonProps = {
//   data: Object,
//   localID: LocalId,
//   className?: string,
//   component?: string,
// }

const handleText = (component) => {
  if (component === 'credit') {
    return 'get-credit-information'
  }
  if (component === 'highlight') {
    return 'get-highlight-information'
  }
  if (component === 'auditor') {
    return 'get-auditor-information'
  }
}

const UseCheckButton = ({ data, localId, className, component }) => {
  const classes = useStyles()
  const reactIntl = useIntl()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const status = useSelector(
    (state) => state.risika?.creditCheck?.cachedPages?.[localId?.id]?.status
  )

  const { language } = useSelector((state) => state.auth.user.local.lang)

  const accountStatusQuery = useAccountStatus({ refetchOnMount: true })

  if (accountStatusQuery.isFetching) {
    return (
      <MUIStack spacing={2} marginTop={4} alignItems="center" flexDirection="column">
        <Skeleton variant="rounded" width="50%" height={30} animation="wave" />
        <Skeleton variant="rounded" width="75%" height={30} animation="wave" />
      </MUIStack>
    )
  }

  const credit_ratings = accountStatusQuery.data?.counters?.credit_ratings
  const credit_rating_cap = accountStatusQuery.data?.caps?.credit_ratings

  const handleButtonClick = () => {
    dispatch(
      submitFetchCompanyCreditInformaion({
        localID: localId,
        language,
        enqueueSnackbar,
        messages: reactIntl.messages,
      })
    )
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      justify="center"
      items="center"
      className={cx(classes.getCheck, className)}
    >
      <Tooltip
        title={
          !data?.score ? (
            <FormattedMessage id={intl.generic('no-credit-information')} />
          ) : (
            ''
          )
        }
        placement="top"
      >
        <Stack direction="column" spacing={1} items="center">
          <ConditionallyRender
            condition={credit_ratings === 0 && component === 'credit'}
            when={
              <div>
                <Typography style={{ color: colors?.risika }}>
                  {
                    <FormattedMessage
                      id={intl.generic('more-checks-on-monday')}
                      values={{ credit_rating_cap }}
                    />
                  }
                </Typography>
                <Typography style={{ color: colors?.risika }}>
                  {<FormattedMessage id={intl.generic('do-not-want-to-wait')} />}
                </Typography>
              </div>
            }
          />
          <ConditionallyRender
            condition={credit_ratings > 0}
            when={
              <ConditionallyRender
                condition={component === 'auditor'}
                when={
                  <ActionDialog
                    fullWidth
                    title={
                      <FormattedMessage
                        id={intl.creditCheck('use-check-for-auditor-title')}
                      />
                    }
                    content={<></>}
                    action={handleButtonClick}
                    render={(open) => (
                      <Button
                        color="secondary"
                        // disabled={!data?.score}
                        loading={status === 'LOADING'}
                        onClick={open}
                      >
                        {<FormattedMessage id={intl.generic(handleText(component))} />}
                      </Button>
                    )}
                    agreeText={<FormattedMessage id={intl.creditCheck('use-credit')} />}
                  />
                }
                otherwise={
                  <Button
                    color="secondary"
                    // disabled={!data?.score}
                    loading={status === 'LOADING'}
                    onClick={handleButtonClick}
                  >
                    {<FormattedMessage id={intl.generic(handleText(component))} />}
                  </Button>
                }
              />
            }
            otherwise={
              <ConditionallyRender
                condition={component === 'credit'}
                when={
                  <Button
                    className="mr-8"
                    onClick={() => window.open({ RISIKA_PRICING_PAGE }, '_blank')}
                    color="secondary"
                    autoFocus
                  >
                    {<FormattedMessage id={intl.restriction('risk-monitoring-button')} />}
                  </Button>
                }
              />
            }
          />
        </Stack>
      </Tooltip>
      {/* <ConditionallyRender
        condition={component === 'credit' && !data?.score}
        when={
          <Typography>
            {<FormattedMessage id={intl.creditCheck('cant-score-company')} />}
          </Typography>
        }
      /> */}
      <ConditionallyRender
        condition={credit_ratings > 0 || component === 'credit'}
        when={
          <div
            style={{
              display: 'flex',
              color: colors?.risikaSecondary,
            }}
          >
            <Typography className={classes.text_white}>
              {<FormattedMessage id={intl.generic('you-have')} />}
            </Typography>
            <Typography style={{ fontWeight: 500 }} className={classes.text_white}>
              &nbsp;{credit_ratings}&nbsp;
            </Typography>
            <Typography className={classes.text_white}>
              {<FormattedMessage id={intl.generic('checks-left')} />}
            </Typography>
          </div>
        }
      />
    </Stack>
  )
}

export default UseCheckButton
