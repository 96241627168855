import React from 'react'
import { Box } from '@mui/material'
import { useHubspotConversations } from 'services/hubSpot/HubspotConversationsProvider'
import RisikaSupportLogo from '../../../assets/RisikaSupportLogo.svg'

const SupportBubbleLogin = () => {
  const { toggleWidget } = useHubspotConversations()

  return (
    <Box>
      <img
        alt="Risika Support Chat"
        src={RisikaSupportLogo}
        height={60}
        width={60}
        style={{ position: 'fixed', bottom: 24, right: 24, zIndex: 100 }}
        onClick={() => toggleWidget()}
        data-cy={'support-and-chat'}
      />
    </Box>
  )
}

export default SupportBubbleLogin
