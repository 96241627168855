import { Stack } from '@mui/material'
import { HistoryHeader } from 'components'
import intl from 'localization/components'
import SaveModal from './widgets/Modals/SaveModal'
import CountryPolicyProvider from './CountryPolicyProvider'
import AdvancedPolicyTabs from './widgets/AdvancedPolicyTabs/AdvancedPolicyTabs'
import BottomBar from './widgets/BottomBar'
import AdvancedCreditPolicy from './widgets/AdvancedCreditPolicy'
import { useIntl } from 'react-intl'

const AdvanceCreditPolicyPage = () => {
  const { messages } = useIntl()
  return (
    <CountryPolicyProvider>
      <Stack>
        <HistoryHeader
          title={messages[intl.navigation('credit-policy')] as string}
          location="advance-credit-policy"
          historyKey="advance-credit-policy"
        />
        <AdvancedPolicyTabs />
        <AdvancedCreditPolicy />
        <BottomBar />
        <SaveModal />
      </Stack>
    </CountryPolicyProvider>
  )
}

export default AdvanceCreditPolicyPage
