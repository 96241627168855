import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

export const paginationStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}

export const pageLimitStyle: SxProps = {
  right: 0,
  width: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(3),
}

export const searchIconStyle: SxProps = {
  color: theme.palette.grey[500],
}

export const tableGreyField: SxProps = {
  margin: 1,
  paddingX: 1,
  background: theme.palette.grey[100],
  borderColor: theme.palette.grey[100],
  borderRadius: '0.5rem',
  '& .MuiOutlinedInput-root:hover': {
    '& fieldset': {
      borderColor: theme.palette.grey[100],
    },
  },
  '& fieldset': {
    borderColor: theme.palette.grey[100],
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
  },
}
