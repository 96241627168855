import React from 'react'
import { useSnackbar } from 'notistack'
import { ConditionallyRender } from 'components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

import { copyToClipboard } from 'services/helpers'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from '../styles/copy-item'

const CopyItem = ({ item, iconStyle }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [coppied, setCoppied] = React.useState(false)
  const classes = useStyles()
  const handleCopy = () => {
    copyToClipboard(item)
    enqueueSnackbar(<FormattedMessage id={intl.settings('content-copied')} />, {
      variant: 'success',
      className: 'snackbar-container snackbar__success',
      preventDuplicate: true,
    })

    setCoppied(true)
    setTimeout(() => {
      closeSnackbar()
      setCoppied(false)
    }, 3000)
  }
  return (
    <ConditionallyRender
      condition={coppied}
      when={
        <FontAwesomeIcon
          style={iconStyle}
          className={classes.iconChecked}
          icon={faClipboardCheck}
        />
      }
      otherwise={
        <FontAwesomeIcon
          style={iconStyle}
          className={classes.iconNotChecked}
          onClick={handleCopy}
          icon={faClipboard}
        />
      }
    />
  )
}

export default CopyItem
