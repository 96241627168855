export const EDIT_VIEW_BUTTON = 'edit-view-button'

export const INITIAL_SIDEBAR_DATA = {
  recommendation: true,
  'norwegian-payment-remarks': true,
  explanation: true,
  performance: true,
  estimation: true,
  'credit-override': true,
  'company-highlights': true,
  'credit-policy': true,
}

export const SCENE_WRAPPER_STYLES = {
  topSection: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1rem 1rem 2rem',
    marginBottom: '1px !important',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    paddingBottom: 0,
    backgroundColor: 'white',
    transition: 'all .5s ease-in-out',
    zIndex: 5,
  },
  expandMoreIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  isReArrange: {
    visibility: 'hidden',
  },
  isReArrangeTop: {
    height: '5.8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1rem 1rem 2rem',
  },
  titleCluster: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    padding: '7px 0px',
  },
}
