import { Subscription } from 'services/api/endpoints/risikaAPI/new_subscription'

export const appendOldSubscriptioWithNewKey = (subscription: Subscription) => {
  let tempSub = subscription
  const emailSettings = subscription.email_settings

  if (!('policy_outcome' in tempSub.email_settings)) {
    tempSub = {
      ...tempSub,
      email_settings: { ...emailSettings, policy_outcome: false },
    }
    return tempSub
  } else {
    return subscription
  }
}
