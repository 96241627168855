import { Box, Stack, Typography } from '@mui/material'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import styles from './TipCard.styles'
import { TipCardPropsType } from './TipCard.types'
import { extraStylesDefault } from './TipCard.model'

const { containerMain, containerTitle, containerContent } = styles

const TipCard = (props: TipCardPropsType) => {
  const { title, content, icon, extraStyles = extraStylesDefault } = props
  return (
    <Stack
      data-cy="tip-card"
      direction="column"
      sx={{ ...containerMain, ...extraStyles.containerMain }}
    >
      <Stack direction="row" sx={{ ...containerTitle, ...extraStyles.containerTitle }}>
        {icon || <LightbulbOutlinedIcon color="inherit" fontSize="inherit" />}
        <Typography variant="subtitle2" color="inherit" gutterBottom>
          {title}
        </Typography>
      </Stack>
      <Box sx={{ ...containerContent, ...extraStyles.containerContent }}>
        <Typography variant="body2" color="inherit">
          {content}
        </Typography>
      </Box>
    </Stack>
  )
}

export default TipCard
