import intl from 'localization/components'
import { DaysOfWeekArray } from './Frequency.types'

export const daysOfWeek: DaysOfWeekArray = [
  {
    value: 'monday',
    label: intl.portfolioInsights('tab-scheduler-frequency-day-mon'),
  },
  {
    value: 'tuesday',
    label: intl.portfolioInsights('tab-scheduler-frequency-day-tue'),
  },
  {
    value: 'wednesday',
    label: intl.portfolioInsights('tab-scheduler-frequency-day-wed'),
  },
  {
    value: 'thursday',
    label: intl.portfolioInsights('tab-scheduler-frequency-day-thu'),
  },
  {
    value: 'friday',
    label: intl.portfolioInsights('tab-scheduler-frequency-day-fri'),
  },
  {
    value: 'saturday',
    label: intl.portfolioInsights('tab-scheduler-frequency-day-sat'),
  },
  {
    value: 'sunday',
    label: intl.portfolioInsights('tab-scheduler-frequency-day-sun'),
  },
]

export const monthlyPeriods = [
  {
    value: 'first_day_month',
    label: intl.portfolioInsights('tab-scheduler-month-first-day'),
  },
  {
    value: 'first_week_day_month',
    label: intl.portfolioInsights('tab-scheduler-month-first-week-day'),
  },
  {
    value: 'last_day_month',
    label: intl.portfolioInsights('tab-scheduler-month-last-day'),
  },
  {
    value: 'last_week_day_month',
    label: intl.portfolioInsights('tab-scheduler-month-last-week-day'),
  },
]

export const frequencyPeriods = [
  {
    value: 'weekly',
    label: intl.portfolioInsights('tab-scheduler-frequency-week'),
  },
  {
    value: 'monthly',
    label: intl.portfolioInsights('tab-scheduler-frequency-month'),
  },
  {
    value: 'yearly',
    label: intl.portfolioInsights('tab-scheduler-frequency-year'),
  },
]

export const defaultFrequencyData = {
  frequency: 1,
  frequencyPeriod: 'weekly',
  dayOfMonth: ['first_day_month'],
  weekDays: ['monday'],
  selectedDate: new Date(),
}
