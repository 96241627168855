import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'
import { colors } from 'configs'

const infoBox: SxProps = {
  backgroundColor: `${colors.risikaWhite}`,
  borderRadius: theme.spacing(2),
  paddingTop: theme.spacing(6),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(6),
  boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)',
}

const titleStyles: SxProps = {
  fontWeight: 400,
  mb: 1,
  fontSize: { sm: '1.4rem', lg: '1.8rem' },
  color: colors.risikaSecondary,
}
const valueStyles: SxProps = {
  fontSize: { sm: '2.3rem', lg: '2.8rem' },
  color: `${colors.risikaSecondary}`,
}

export const classes = {
  infoBox,
  titleStyles,
  valueStyles,
}
