import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { GazzeteReportType } from 'types/general'

/**
 * Search for a company.
 *
 * @returns {object} The search results.
 */
type ResponseData = {
  data: []
}
async function getStatstidende({ id, country }: LocalId): Promise<GazzeteReportType[]> {
  const { data }: ResponseData = await risikaAPI.get(`/company/events/${id}`, {
    microservice: 'data',
    country,
  })
  return data.map((x) => ({
    type: x[0] || null,
    source: x[1] || null,
    date: x[2] || null,
    content: x[3] || null,
    messageTime: x[4] || null,
  }))
}

export default getStatstidende
