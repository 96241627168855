import { Theme } from '@mui/material/styles'
import { theme } from 'styles-new/mui5-transition/theme'

const dialogTitle = { backgroundColor: 'primary.dark', color: 'common.white' }

const dialogContent = {
  padding: 5,
  paddingTop: (theme: Theme) => `${theme.spacing(5)} !important`,
}

const inputLabel = {
  ...theme.typography.body1,
  color: theme.palette.grey[600],
}

const textField = {
  margin: 0,
  '& .focused': {
    color: theme.palette.common.white,
  },
  '.MuiInputLabel-shrink': {
    color: '#202020 !important',
  },
  '.MuiOutlinedInput-notchedOutline': { borderColor: 'grey.600' },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#202020',
    color: 'yellow',
  },
}

export default {
  dialogTitle,
  dialogContent,
  inputLabel,
  textField,
}
