import { PieChart, Pie, Legend, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import {
  CustomPieLegendWithPadding,
  CustomPieLegendWithPaddingRisk,
  SimpleChartTooltipRaw,
  SimpleChartTooltipRisk,
} from '../customizations'
import { DoughnutChartPropsType } from '../types'

const DoughnutChart = ({ data, chartProperties }: DoughnutChartPropsType) => {
  const totalSum = data.reduce(
    (accumulator: number, item: { value: number }) => accumulator + item.value,
    0
  )
  return (
    <ResponsiveContainer height={chartProperties.height} width="100%">
      <PieChart height={chartProperties.height}>
        {chartProperties.pie.tooltipWithPercent &&
          chartProperties.valueType === 'RISK' && (
            <Tooltip content={<SimpleChartTooltipRisk totalSum={totalSum} />} />
          )}
        {chartProperties.pie.tooltipWithPercent &&
          chartProperties.valueType !== 'RISK' && (
            <Tooltip content={<SimpleChartTooltipRaw totalSum={totalSum} />} />
          )}
        {chartProperties.pie.tooltip && !chartProperties.pie.tooltipWithPercent ? (
          <Tooltip />
        ) : null}
        <Pie
          data={data}
          dataKey={chartProperties.pie.dataKey}
          innerRadius={chartProperties.pie.innerRadius}
          outerRadius={chartProperties.pie.outerRadius}
          paddingAngle={chartProperties.pie.paddingAngle}
          label={chartProperties.pie.label}
        >
          {data.map((entry, index: number) => (
            <Cell key={`cell-${index}`} fill={chartProperties.colors[entry.code]} />
          ))}
        </Pie>
        {chartProperties.pie.legend && chartProperties.valueType === 'RISK' ? (
          <Legend content={CustomPieLegendWithPaddingRisk} />
        ) : null}
        {chartProperties.pie.legend && chartProperties.valueType !== 'RISK' ? (
          <Legend content={CustomPieLegendWithPadding} />
        ) : null}
      </PieChart>
    </ResponsiveContainer>
  )
}

export default DoughnutChart
