import { useMonitorShowList, useAllUsers } from 'services/queries'
import { useSelector } from 'react-redux'
import { ListOwnerInfoT } from '../types'

const useListOwner = () => {
  const usersQuery = useAllUsers()
  const showListsQuery = useMonitorShowList()
  const { selectedList } = useSelector(
    // @ts-ignore: Unreachable code error
    (state) => state.risika.newRiskMonitoring
  )

  const getListOwnerInfo = () => {
    if (!usersQuery.isSuccess || !showListsQuery.isSuccess) return null

    const ownerRisikaId =
      showListsQuery.data?.find(
        (listObj: { list_id: any }) => listObj.list_id === selectedList
      )?.owner_id ?? null

    if (ownerRisikaId === null) return null

    const owner =
      usersQuery.data?.find(
        (userObj: { api_user_id: any }) => userObj.api_user_id === ownerRisikaId
      ) ?? null

    if (!owner?.email) return null

    const listOwnerInfo: ListOwnerInfoT = {
      name: owner?.name ?? null,
      email: owner.email,
      risikaId: ownerRisikaId,
    }
    return listOwnerInfo
  }

  return { owner: getListOwnerInfo() }
}

export default useListOwner
