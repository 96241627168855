import xlsx from 'xlsx/dist/xlsx.core.min.js'

/**
 * Create Excel Workbook and trick
 * the browser into downloading it.
 */
const downloadExcel = (filename, data) => {
  // There is a worksheet title limit in Excel.
  if (data?.length) {
    const _filename = filename.substr(0, 30)

    const wb = xlsx.utils.book_new()
    const ws = xlsx.utils.json_to_sheet(data)
    xlsx.utils.book_append_sheet(wb, ws, _filename)

    xlsx.writeFile(wb, `${filename}.xlsx`)
  }
}

export default downloadExcel
