import { colors } from 'configs'
import { barColors } from './ListCreditScoreDistributionBox.model'
import { chartDataType } from './ListCreditScoreDistributionBox.types'

/**
 * This function returns array of color codes, so that risk categories 1,2 and 3
 * always have color code #FF684F, 4,5 and 6 always have code #F9A96E, and
 * categories 7,8,9 and 10 always have color code #17BEBB
 * @param chartData [chartDataType[]] - chart data
 * @returns array of color codes
 */
export const adjustChartColors = (chartData: chartDataType[]) => {
  const adjustedColors = chartData.map((chartItem: chartDataType) => {
    if (isNaN(Number(chartItem.name))) {
      return colors.riskIntelligence[
        chartItem.name as keyof typeof colors.riskIntelligence
      ]
    }
    return barColors[Number(chartItem.name) - 1]
  })
  return adjustedColors
}
