import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import StackedBarChart from 'components-new/charts/StackedBarChart'
import { convertToNameAndValuesKey } from 'utils-new/api-to-chart-data-converters'
import { keyFiguresByIndustryData } from './ListKeyFiguresByIndustryBox.model'
import { useMonitoringStatistic } from 'services/queries'
import { MonitoringValueQueryDataType } from 'types/queries'

const ListKeyFiguresByIndustryBox = ({ listId }: { listId: string }) => {
  keyFiguresByIndustryData.parameters.list_id = Number(listId)

  const keyFiguresByIndustryQuery = useMonitoringStatistic({
    parameters: keyFiguresByIndustryData.parameters,
    endpoint: keyFiguresByIndustryData.endPoint,
    cacheTime: 0,
  })

  const keyFiguresByIndustryQueryData =
    keyFiguresByIndustryQuery.data as MonitoringValueQueryDataType[]
  const boxData = {
    title: keyFiguresByIndustryData.chartProperties.title,
    status: {
      isLoading: keyFiguresByIndustryQuery.isLoading,
      isError:
        keyFiguresByIndustryQuery.isError ||
        (keyFiguresByIndustryQuery.data &&
          !keyFiguresByIndustryQueryData.length &&
          !keyFiguresByIndustryQuery.isLoading),
      isIdle: keyFiguresByIndustryQuery.isIdle,
      isNoData: !!(
        keyFiguresByIndustryQueryData?.length &&
        !convertToNameAndValuesKey(keyFiguresByIndustryQueryData).length
      ),
    },
    height: keyFiguresByIndustryData.chartProperties.height,
    skeletonType: 'HORIZONTAL_BAR',
  }
  return (
    <BoxContainer boxData={boxData} data-cy="key-figures-by-industry">
      <Box m={4} pt={2}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <StackedBarChart
            data={convertToNameAndValuesKey(keyFiguresByIndustryQueryData)}
            chartProperties={keyFiguresByIndustryData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListKeyFiguresByIndustryBox
