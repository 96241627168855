import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

const municipalityList = (country) => {
  return risikaAPI
    .get(`/list/municipalities`, {
      microservice: 'data',
      country,
    })
    .then(({ data }) => {
      return data.municipalities
    })
}

export default municipalityList
