import { Box } from '@mui/material'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts'
import { classTooltip } from 'styles-new/global/components'
import { PropTypes, CustomLabelTypes } from './BarChartStackedBySign.types'

const BarChartStackedBySign = ({ data, chartProperties }: PropTypes) => {
  // This should prevent ReCharts bug of not displaying top labels
  // by preventing the animation of the chart
  // https://github.com/recharts/recharts/issues/829
  const animationActive = !chartProperties

  const lowValues = Object.values(data).map((value) => value.low)
  const highValues = Object.values(data).map((value) => -value.high)
  const sumValues = Object.values(data).map((value) => value.low - value.high)

  // we feed the chart percent data in order to
  // scale the bars according to the percent instead of value
  const percentData = data.map((item, index) => {
    return {
      name: item.name,
      low: ((item.low / sumValues[index]) * 100).toFixed(),
      high: ((item.high / sumValues[index]) * 100).toFixed(),
    }
  })

  const DualPercentageCustomLabel = ({
    x,
    y,
    stroke,
    index,
    height,
  }: CustomLabelTypes) => {
    const displayValue = (value: number[]) => {
      if (chartProperties.labelType === 'PERCENT') {
        return `${((value[index] / sumValues[index]) * 100).toFixed()}%`
      }
      return value[index]
    }

    if (height === 0) return <text></text>

    return (
      <text
        x={x + 8}
        y={height > 0 ? y - 8 : y + 14}
        fill={chartProperties.colors[height > 0 ? 1 : 0]}
        fontSize={13}
        textAnchor="middle"
      >
        {height > 0 ? displayValue(lowValues) : displayValue(highValues)}
      </text>
    )
  }

  const CustomTooltipDataValues = (props: any) => {
    if (!props.payload.length) return <></>

    const currentValues = data.filter((item) => item.name === props.label)[0]

    return (
      <Box sx={{ ...classTooltip, fontWeight: 700 }}>
        {props.label}

        {props.payload.map((entry: any) => (
          <Box key={entry.name} sx={{ color: entry.color, fontWeight: 400 }}>
            <Box>
              {entry.dataKey}:{' '}
              {currentValues[entry.dataKey as keyof typeof currentValues]}
            </Box>
          </Box>
        ))}
      </Box>
    )
  }
  return (
    <BarChart
      width={chartProperties.width}
      height={chartProperties.height}
      data={percentData}
      stackOffset="sign"
      margin={chartProperties.margin}
    >
      {chartProperties.cartesianGrid && (
        <CartesianGrid strokeDasharray={chartProperties.strokeDasharray} />
      )}
      {chartProperties.XAxis && (
        <XAxis
          dy={chartProperties.XAxis.dy}
          tickLine={chartProperties.XAxis.tickLine}
          interval={chartProperties.XAxis.interval}
          axisLine={chartProperties.XAxis.axisLine}
          dataKey="name"
        />
      )}
      {chartProperties.YAxis && <YAxis />}
      {chartProperties.tooltip && (
        <Tooltip content={<CustomTooltipDataValues payload={data} />} />
      )}
      {chartProperties.legend && <Legend />}
      {chartProperties.referenceLine && (
        <ReferenceLine
          y={0}
          stroke={chartProperties.referenceLineStroke}
          strokeWidth={chartProperties.referenceLineStrokeWidth}
        />
      )}
      <Bar
        barSize={chartProperties.barSize}
        dataKey="low"
        fill={chartProperties.colors[1]}
        stackId="stack"
        radius={chartProperties.radius}
        isAnimationActive={
          animationActive /* ReCharts bug prevention, explained in comment above. */
        }
        label={chartProperties.label && DualPercentageCustomLabel}
      />
      <Bar
        barSize={chartProperties.barSize}
        dataKey="high"
        fill={chartProperties.colors[0]}
        stackId="stack"
        radius={chartProperties.radius}
        isAnimationActive={
          animationActive /* ReCharts bug prevention, explained in comment above. */
        }
        label={chartProperties.label && DualPercentageCustomLabel}
      />
    </BarChart>
  )
}

export default BarChartStackedBySign
