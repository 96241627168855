import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    width: '50%',
  },
}))
