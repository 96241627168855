import { Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

const EnterpriseCreditPolicy = () => {
  return (
    <Stack py={4}>
      <Skeleton width={'40%'} />
      <Skeleton />
      <Skeleton />
    </Stack>
  )
}

export default EnterpriseCreditPolicy
