import * as React from 'react'
import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useHierarchySceneContext } from 'pages/company-hierarchy/context/hierarchySceneContext'
import { handleVisibility } from '../HierarchyListView.controller'
import { listViewTableHeaders } from '../HierarchyListView.model'
import { classes } from '../HierarchyListView.styles'

import {
  Ceo,
  CompanyName,
  FinancialNumber,
  Monitor,
  Ownership,
  RisikaScore,
} from './TableCells'
import { BodyCellsProps, ListViewHeaders } from '../HierarchyListView.types'

export function BodyCells({
  row,
  open,
  setOpen,
  isLastChild = false,
  increaseDepth,
  depth,
}: BodyCellsProps) {
  // Get the whole companyName cell outside
  const { displayState } = useHierarchySceneContext()

  const [tableHeaders, setTableHeaders] = React.useState(
    handleVisibility(listViewTableHeaders, displayState)
  )
  React.useEffect(() => {
    setTableHeaders(handleVisibility(listViewTableHeaders, displayState))
  }, [displayState])

  const handleGetCorrectTable = ({
    width,
    componentName,
    align,
  }: Pick<ListViewHeaders, 'width' | 'componentName' | 'align'>) => {
    switch (componentName) {
      case 'companyName':
        return (
          <TableCell
            sx={{
              ...classes.cellPadding,
              ...classes.nameTableCell(row.level),
              width,
              position: 'relative',
            }}
          >
            <CompanyName
              row={row}
              open={open}
              setOpen={setOpen}
              isLastChild={isLastChild}
              increaseDepth={increaseDepth}
              depth={depth}
            />
          </TableCell>
        )
      case 'ownership':
        return (
          <TableCell sx={{ ...classes.cellPadding, width }}>
            <Ownership align={align} row={row} />
          </TableCell>
        )
      case 'risikaScore':
        return (
          <TableCell sx={{ ...classes.cellPadding, width }}>
            {<RisikaScore align={align} row={row} />}
          </TableCell>
        )
      case 'localId':
        return (
          <TableCell sx={{ ...classes.cellPadding, width }}>
            <Typography variant="body2">
              {row?.type === 'COMPANY' ? row.local_id.id : '-'}
            </Typography>
          </TableCell>
        )
      case 'ceo':
        return (
          <TableCell sx={{ ...classes.cellPadding, width }}>
            <Ceo align={align} row={row} />
          </TableCell>
        )
      case 'equity':
        return (
          <TableCell sx={{ ...classes.cellPadding, width }}>
            <FinancialNumber align={'left'} row={row} numberKey={'equity'} />
          </TableCell>
        )
      case 'profitLoss':
        return (
          <TableCell sx={{ ...classes.cellPadding, width }}>
            <FinancialNumber align={'left'} row={row} numberKey={'profit_loss'} />
          </TableCell>
        )
      case 'monitor':
        return (
          <TableCell sx={{ ...classes.cellPadding, width }}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                maxHeight: '40px',
                overflow: 'hidden',
                width: 'min-content',
                borderRadius: '4px',
              }}
            >
              <Monitor align={align} row={row} />
            </Stack>
          </TableCell>
        )

      default:
        return <p>Error</p>
    }
  }

  return (
    <TableRow
      sx={{ height: 70 }}
      key={row?.local_id?.id ?? 'null' + row?.local_id?.country}
    >
      {tableHeaders
        .filter((x) => x.visible)
        .map((item) => {
          return handleGetCorrectTable(item)
        })}
    </TableRow>
  )
}
