/**
 * This simple function create list of companies for comparison from two
 * parameters: newCompany and CurrentList. Both parameters can be null.
 * if newCompany already exist in newList, then it shouldn't be added again.
 * @param {Object} newCompany - newCompany that pretend to be added to list.
 * @param {Array} currentList - list of companies.
 */
export const createComparisonList = (newCompany, currentList) => {
  const isNewCompanyAlreadyInTheList = currentList.some(
    (company) => company.id === newCompany?.id && company.country === newCompany?.country
  )
  return !newCompany.id || isNewCompanyAlreadyInTheList
    ? currentList
    : [...currentList, newCompany]
}

/**
 * This function adds company to the list of companies if it's not
 * already on the list.
 * @param {Object} company - company to be added to the list.
 * @param {Array} companies - list of companies.
 */
export const addCompanyToTheList = (company, companies) => {
  const newCompany = { id: company.id, country: company.country }
  const currentList = JSON.parse(JSON.stringify(companies))
  return createComparisonList(newCompany, currentList)
}

/**
 * This function removes company to the list of companies.
 * @param {Object} companyToRemove - company to be removed from the list.
 * @param {Array} companies - list of companies.
 */
export const removeCompanyFromTheList = (companyToRemove, companies) => {
  return companies.filter((company) => {
    return !(
      company.id === companyToRemove.id && company.country === companyToRemove.country
    )
  })
}

/**
 * This function will get the data from the local storage, and return it.
 * If the local storage doesn't exist it will create it and return [].
 * @param {String} storageName - name says it all.
 */
export const getLocalStorageList = (storageName) => {
  if (storageName in localStorage) {
    const localStorageList = localStorage.getItem(storageName)
    return localStorageList.length > 0 ? JSON.parse(localStorageList) : []
  }
  localStorage.setItem(storageName, [])
  return []
}

/**
 * This function will save the data (list of companies) to the local storage.
 * @param {Array} newList - the data (list of companies) to be saved in local storage.
 * @param {String} storageName - name says it all.
 */
export const setLocalStorage = (storageName, newList) => {
  localStorage.setItem(storageName, JSON.stringify(newList))
}
