import { APIKeyType, APIPolicyEdge } from 'types/queries'
import { updateParentNode } from '../PolicyBuilder.controller'
import {
  ActionPanelVariables,
  BuilderEdge,
  BuilderNode,
  BuilderState,
  NodeApiTypes,
  NodeData,
  NodeVariations,
  PlusData,
  VariableDataLabel,
} from '../PolicyBuilder.types'

import {
  ActionPanelToggleTypes,
  AddPositiveAndNegativeEdge,
  NodeWithDataType,
  UpdateNodeInNodes,
  UpdateNodeInState,
} from './BuilderContext.types'
import {
  ADJUST_CREDIT_MAX,
  APPROVE_CREDIT,
  APPROVE_CREDIT_MAX,
  APPROVE_WITH_VALUE,
  availableVariables,
} from '../PolicyBuilder.model'
import { queryFind } from 'services/queries'
import { ECPDataType } from 'services/api/endpoints/risikaAPI/getECPDataStructure'

/**
 * This function creates an empty node with the correct structure for its type.
 * It turns the nodes from ApiPolicyNode to BuilderNode.
 * It is built to be used in a reduce function.
 * @param nodes (acc) All nodes provided by the API.
 * @param node (curr) The current node in the iteration,
 * @param Edges The edges are required to find the parent node. Which is then attached to the node data.
 * @returns BuilderNode[] All the nodes in the appropriate format positioned at 0, 0.
 */

export const nodeWithData = ({ nodes, node, edges }: NodeWithDataType): BuilderNode[] => {
  const parentId = edges.find((edge) => edge.target_id === node.id)?.source_id
  const newAcc = updateParentNode({
    nodes,
    parentId,
    newNodeId: node.id,
  })

  if (node.node_type === 'rule') {
    const variable = findVariableFromCategory(node.condition.category)
    if (!variable) {
      throw new Error(node.condition.category + ' Node neither an action or a rule')
    }
    return [
      ...newAcc,
      {
        id: node.id,
        type: 'rule',
        position: { x: 0, y: 0 },
        data: {
          label: variable.label,
          apiData: node.condition,
          builderData: {
            childrenIds: [],
            parentId: parentId ?? null,
            variant: findVariantByApiKey(node.condition.category),
          },
        } as NodeData,
      },
    ]
  }
  if (node.node_type === 'action') {
    const variable = findVariableFromCategory(node.action.outcome)
    if (!variable) {
      throw new Error(node.action.outcome + ' Node neither an action or a rule')
    }
    return [
      ...newAcc,
      {
        id: node.id,
        type: 'action',
        position: { x: 0, y: 0 },
        data: {
          label: variable.label,
          apiData: node.action,
          builderData: {
            childrenIds: [],
            parentId: parentId ?? null,
            variant: findVariantByApiKey(node.action.outcome),
          },
        } as NodeData,
      },
    ]
  }
  if (node.node_type === 'plus') {
    return [
      ...newAcc,
      {
        id: node.id,
        type: 'plus',
        position: { x: 0, y: 0 },
        data: {
          apiData: {},
          builderData: {
            childrenIds: [] as string[],
            parentId: parentId ?? null,
          },
        } as PlusData,
      },
    ]
  }
  throw new Error('Node neither an action or a rule')
}

/**
 * Here we transform the edges from the API to the correct format for the builder.
 * @param edge APIPolicyEdge
 * @returns BuilderEdge
 */
export const edgeWithData = (edge: APIPolicyEdge): BuilderEdge => {
  return {
    id: `${edge.source_id}-${edge.target_id}-${edge.relation_type ?? 'initial'}`,
    source: edge.source_id,
    target: edge.target_id,
    type: edge.relation_type ?? 'initial',
    label: edge.relation_type === 'if_true' ? 'Yes' : 'No',
    data: {
      relation_type: edge.relation_type ?? 'initial',
    },
  }
}
// export const evaluateCategory = (
//   data:
//     | RuleData['apiData']
//     | OutcomeData['apiData']
//     | { default_on_null: DefaultOnNullValue }
// ) => {
//   const valuesToConvertCategory = [
//     EQUITY_WITHOUT_DIVIDEND,
//     APPROVE_CREDIT_MAX,
//     ADJUST_CREDIT_MAX,
//     APPROVE_WITH_VALUE,
//     RISK_ASSESSMENT,
//     RISIKA_SCORE,
//   ]

//   const category = typeGuard('category', data) ?? null
//   const outcome = typeGuard('outcome', data) ?? null

//   console.log('data', data)
//   if (category && valuesToConvertCategory.includes(category)) {
//     return { category }
//   }
//   if (outcome && valuesToConvertCategory.includes(outcome)) {
//     return { outcome }
//   }
//   return {}
// }

// use this
export const updateNodeInState = ({
  state,
  nodeId,
  newData,
  type,
  returnWholeState = true,
}: UpdateNodeInState): BuilderState | BuilderNode[] => {
  const updatedNodes = state.nodes.map((node) => {
    if (node.id === nodeId) {
      return {
        ...node,
        type: type ?? node.type,
        data: newData,
      }
    }
    return node
  })
  if (returnWholeState) {
    return {
      ...state,
      nodes: updatedNodes,
    }
  }
  return updatedNodes
}

export const updateNodeInNodes = ({
  nodes,
  nodeId,
  newData,
  type,
}: UpdateNodeInNodes): BuilderNode[] => {
  const updatedNodes = nodes.map((node) => {
    if (node.id === nodeId) {
      return {
        ...node,
        type: type ?? node.type,
        data: newData,
      }
    }
    return node
  })
  return updatedNodes
}

type HandleAddEdges = {
  positive: unknown
  negative: unknown
  addOnlyOneEdge?: 'yes' | 'no'
}
const handleAddEdges = ({ positive, negative, addOnlyOneEdge }: HandleAddEdges) => {
  if (!addOnlyOneEdge) {
    return [positive, negative]
  }
  if (addOnlyOneEdge === 'yes') {
    return [positive]
  }
  if (addOnlyOneEdge === 'no') {
    return [negative]
  }
  return []
}
/**
 * When a node is complete with data we automatically spawn two new nodes. One for the positive edge and one for the negative edge.
 * This also updates the currHighestId in the state.
 * @param state The current state of the builder.
 * @param selectedNode The node that has been completed.
 * @returns The state with the new nodes and edges added.
 */
export const addPositiveAndNegativeEdge = ({
  state,
  selectedNode,
  addOnlyOneEdge,
}: AddPositiveAndNegativeEdge) => {
  if (selectedNode.data.builderData.childrenIds.length > 0 && !addOnlyOneEdge) {
    // The node already has two children, so we don't need to add more
    return state
  }
  const positiveNode = {
    id: `${state.currHighestId + 1}`,
    type: 'plus',
    position: { x: 0, y: 0 },
    data: {
      builderData: {
        childrenIds: [],
        parentId: selectedNode.id,
      },
    },
  }
  const negativeNode = {
    id: `${state.currHighestId + (addOnlyOneEdge ? 1 : 2)}`,
    type: 'plus',
    position: { x: 0, y: 0 },
    data: {
      builderData: {
        childrenIds: [],
        parentId: selectedNode.id,
      },
    },
  }
  const positiveEdge = edgeWithData({
    relation_type: 'if_true',
    source_id: selectedNode.id,
    target_id: positiveNode.id,
  })
  const negativeEdge = edgeWithData({
    relation_type: 'if_false',
    source_id: selectedNode.id,
    target_id: negativeNode.id,
  })

  const stateWithNewEdges = {
    ...state,
    edges: [
      ...state.edges,
      ...handleAddEdges({
        positive: positiveEdge,
        negative: negativeEdge,
        addOnlyOneEdge,
      }),
    ],
    nodes: [
      ...state.nodes,
      ...handleAddEdges({
        positive: positiveNode,
        negative: negativeNode,
        addOnlyOneEdge,
      }),
    ],
    currHighestId: state.currHighestId + (addOnlyOneEdge ? 1 : 2),
  } as BuilderState

  // Here we add the new nodes as children IDS to the selected node unless we are adding only 1 node. In which case we just add the id of that
  const newNodeData = addOnlyOneEdge
    ? ({
        ...selectedNode.data,
        builderData: {
          ...selectedNode.data.builderData,
          childrenIds: [
            ...selectedNode.data.builderData.childrenIds,
            `${state.currHighestId + 1}`,
          ],
        },
      } as NodeData)
    : ({
        ...selectedNode.data,
        builderData: {
          ...selectedNode.data.builderData,
          childrenIds: [
            ...selectedNode.data.builderData.childrenIds,
            positiveNode.id,
            negativeNode.id,
          ],
        },
      } as NodeData)

  return updateNodeInState({
    state: stateWithNewEdges,
    nodeId: selectedNode.id,
    newData: newNodeData,
  }) as BuilderState
}
export const findVariableFromLabel = (label: VariableDataLabel) => {
  const dataStructure = queryFind('useECPDataStructure') as ECPDataType
  if (!dataStructure) return null
  return dataStructure.find((d) => d?.label === label)
}
export const exceptions = (category: APIKeyType) => {
  switch (category) {
    // Exceptions
    case APPROVE_CREDIT_MAX:
    case ADJUST_CREDIT_MAX:
    case APPROVE_WITH_VALUE:
      return APPROVE_CREDIT
    default:
      return category
  }
}
export const findVariableFromCategory = (category: APIKeyType) => {
  const dataStructure = queryFind('useECPDataStructure') as ECPDataType
  if (!dataStructure) return null
  return dataStructure.find(
    (d) =>
      (d.template ?? []).filter((t) => t?.template_data?.api_key === exceptions(category))
        .length > 0
  )
}
export const findTemplateFromCategory = (category: APIKeyType) => {
  const dataStructure = queryFind('useECPDataStructure') as ECPDataType
  if (!dataStructure) return null
  // Get the correct template
  const template = dataStructure
    ?.find((d) => {
      return (
        (
          d.template?.filter((t) => {
            return t?.template_data?.api_key === exceptions(category)
          }) ?? []
        )?.length > 0
      )
    })
    ?.template?.filter((t) => {
      return t?.template_data?.api_key === exceptions(category)
    })[0]
  return template
}
export const findVariantByApiKey = (category: APIKeyType) => {
  const dataStructure = queryFind('useECPDataStructure') as ECPDataType
  // Why do i do stuff like this?
  // Wtf is wrong with me?
  if (!dataStructure) return null

  let variant
  dataStructure.forEach((d) => {
    ;(d.template ?? []).forEach((t) => {
      if (t?.template_data?.api_key === category) {
        variant = t.template_data.variant
      }
    })
  })
  return variant
}

/**
 * This function will tell you if a node is a rule, an action ot a plus.
 * @param content The content of the node.
 * @returns rule | action | plus
 */

export const getNodeType = (
  content: NodeApiTypes | ActionPanelVariables
): NodeVariations | undefined => {
  const variable = findVariableFromLabel(content)
  if (variable) {
    return variable?.data?.type as NodeVariations
  }
  return 'plus'
}
export const getKeyBasedOnNodeType = (type?: NodeVariations) => {
  if (type === 'rule') {
    return 'category'
  }
  if (type === 'action') {
    return 'outcome'
  }
  return ''
}
type HandleHighlightedNodesFromActionPanel = {
  actionPanelData: ActionPanelToggleTypes
  state: BuilderState
}
export const handleHighlightedNodesFromActionPanel = ({
  actionPanelData,
  state,
}: HandleHighlightedNodesFromActionPanel) => {
  const { highlightedNodes } = state
  if (actionPanelData.open && actionPanelData.nodeId) {
    return {
      ...highlightedNodes,
      [actionPanelData.nodeId]: { type: 'info' },
    }
  }
  if (!actionPanelData.open) {
    // Immutably remove the highlighted node with the id of the node that was just edited
    const { [Number(state.actionPanelData.nodeId)]: _, ...newHighlightedNodes } =
      state.highlightedNodes
    return newHighlightedNodes
  }
  return highlightedNodes
}
type GetPreservedValues = {
  apiKey: APIKeyType
  selectedNode: BuilderNode
}

/**
 * Returns an object containing any preserved values for a given selected node based on a new node's API key.
 * @param {object} options - The options object containing the API key and selected node.
 * @param {string} options.apiKey - The API key of the new node.
 * @param {object} options.selectedNode - The selected node from which to extract preserved values.
 * @returns {object} - An object containing any preserved values for the selected node.
 */
export const getPreservedValues = ({ apiKey, selectedNode }: GetPreservedValues) => {
  // Get the variant of the new node based on its API key.
  const variantOfNewNode = findVariantByApiKey(apiKey)

  // If there is no variant for the new node, return an empty object.
  if (!variantOfNewNode) {
    return {}
  }

  // Check if the selected node has a builderData property with a variant value.
  if ('variant' in selectedNode.data.builderData) {
    // Get the variant of the selected node.
    const variantOfSelectedNode = selectedNode.data.builderData.variant
    // If the selected node doesn't have a variant, or its variant doesn't match that of the new node,
    // or its variant doesn't include "tolerance", return an empty object.
    if (
      !variantOfSelectedNode ||
      variantOfNewNode !== variantOfSelectedNode ||
      !variantOfSelectedNode.includes('tolerance')
    ) {
      return {}
    }

    // If the selected node's variant includes "tolerance", return an object with the "tolerance" property
    // and its value (if it exists) from the selected node's apiData property.
    return {
      tolerance:
        'tolerance' in selectedNode.data.apiData
          ? selectedNode.data.apiData.tolerance
          : '',
    }
  }

  // If the selected node doesn't have a builderData property with a variant value, return an empty object.
  return {}
}

/**
 * Recursively finds the rest of the array of BuilderNodes based on the parent and child IDs.
 *
 * @param {string} parentId - The ID of the parent BuilderNode.
 * @param {string} [childId] - Optional ID of the child BuilderNode.
 * @param {BuilderNode[]} allNodes - Array of all BuilderNodes.
 * @param {BuilderNode[]} accNodes - Accumulated array of BuilderNodes.
 * @returns {BuilderNode[]} - The rest of the array of BuilderNodes.
 */
export const findTheRestOfTheArray = ({
  parentId,
  childId,
  allNodes,
  accNodes,
}: {
  parentId: string
  childId?: string
  allNodes: BuilderNode[]
  accNodes: BuilderNode[]
}): BuilderNode[] => {
  let tempNodes = [...accNodes] // Create a temporary array to store accumulated nodes

  const parent = allNodes.find((n) => n.id === parentId) // Find the parent node with the given ID

  if (!parent) return accNodes // If parent node doesn't exist, return the accumulated nodes

  if (childId) {
    // If child ID is provided. This is usually the first time when we want to take only the first child
    const child = allNodes.find((n) => n.id === childId) // Find the child node with the given ID

    if (!child) return accNodes // If child node doesn't exist, return the accumulated nodes

    tempNodes.push(child) // Add the child node to the temporary array

    if (child.data.builderData.childrenIds.length === 0) return tempNodes // If child node has no children, return the temporary array

    // Recursively call the function with child node as the new parent
    return findTheRestOfTheArray({
      parentId: child.id,
      allNodes,
      accNodes: tempNodes,
    })
  } else {
    const childIds = parent.data.builderData.childrenIds // Get the children IDs of the parent node

    if (childIds.length === 0) return tempNodes // If parent node has no children, return the temporary array

    childIds.forEach((childId) => {
      const child = allNodes.find((n) => n.id === childId) // Find the child node with the current ID

      if (child) {
        // If child node exists
        tempNodes.push(child) // Add the child node to the temporary array

        // Recursively call the function with child node as the new parent
        tempNodes = findTheRestOfTheArray({
          parentId: child.id,
          allNodes,
          accNodes: tempNodes,
        })
      }
    })
  }

  return tempNodes
}

type HandleAddRestOfNodes = {
  state: BuilderState
  restOfNodes: BuilderNode[]
  parentNode: BuilderNode
  nodeLine: 'yes' | 'no'
}
export const handleAddRestOfNodes = ({
  state,
  restOfNodes,
  parentNode,
  nodeLine,
}: HandleAddRestOfNodes) => {
  const plusNode = {
    id: `${state.currHighestId + 2}`,
    type: 'plus',
    position: { x: 0, y: 0 },
    data: {
      builderData: {
        childrenIds: [],
        parentId: parentNode.id,
      },
    },
  }

  const positiveEdge = edgeWithData({
    relation_type: 'if_true',
    source_id: parentNode.id,
    target_id: nodeLine === 'yes' ? restOfNodes[0].id : plusNode.id,
  })
  const negativeEdge = edgeWithData({
    relation_type: 'if_false',
    source_id: parentNode.id,
    target_id: nodeLine === 'yes' ? plusNode.id : restOfNodes[0].id,
  })

  const newNodeData = {
    ...parentNode.data,
    builderData: {
      ...parentNode.data.builderData,
      childrenIds: [restOfNodes[0].id, plusNode.id],
    },
  }

  const stateWithNewEdges = {
    ...state,
    edges: [...state.edges, positiveEdge, negativeEdge],
    nodes: [...state.nodes, ...restOfNodes, plusNode],
    currHighestId: state.currHighestId + 2,
  } as BuilderState

  return updateNodeInState({
    state: stateWithNewEdges,
    nodeId: parentNode.id,
    newData: newNodeData,
  }) as BuilderState
}

export const sortEdges = (edges: BuilderEdge[]) => {
  return edges.sort((a, b) => {
    // Sort by source in ascending order
    if (Number(a.source) < Number(b.source)) {
      return -1
    }
    if (Number(a.source) > Number(b.source)) {
      return 1
    }
    if (Number(a.target) < Number(b.target)) {
      return -1
    }
    if (Number(a.target) > Number(b.target)) {
      return 1
    }
    if (a.type === 'if_true' && b.type === 'if_false') {
      return -1 // if_true comes before if_false
    }
    if (a.type === 'if_false' && b.type === 'if_true') {
      return 1 // if_false comes after if_true
    }

    return 0 // If all factors are equal, maintain original order
  })
}

export const sortEdgesByNodes = ({
  nodes,
  edges,
}: {
  nodes: BuilderNode[]
  edges: BuilderEdge[]
}) => {
  let sortedEdges = [] as BuilderEdge[]
  nodes.forEach((node) => {
    const edgesComingFromNode = sortEdges(edges).filter((edge) => edge.source === node.id)
    sortedEdges = [...sortedEdges, ...edgesComingFromNode]
  })
  return sortedEdges
}

export const sortNodesByIssue = ({
  nodes,
  accNodes,
  childrenIds,
}: {
  nodes: BuilderNode[]
  accNodes: BuilderNode[]
  childrenIds: string[]
}): BuilderNode[] => {
  if (accNodes.length === 0) {
    return sortNodesByIssue({
      nodes,
      accNodes: [nodes?.[0]],
      childrenIds: nodes?.[0]?.data?.builderData?.childrenIds,
    })
  }
  return childrenIds.reduce((acc, curr) => {
    const node = nodes.find((n) => n.id === curr)
    if (!node) return acc
    return sortNodesByIssue({
      nodes,
      accNodes: [...acc, node],
      childrenIds: node.data.builderData.childrenIds,
    })
  }, accNodes)
}

type SubstituteEntryInArray = {
  array: unknown[]
  substitutes: {
    index: number
    newEntry: unknown
  }[]
}
const substituteEntryInArray = ({ array, substitutes }: SubstituteEntryInArray) => {
  return array.map((entry, i) => {
    const substitute = substitutes.find((s) => s.index === i)
    if (substitute) {
      return substitute.newEntry
    }
    return entry
  })
}

export const makeSureYesNodesHaveLowerIdsThanNoNodesOnTheSameLine = ({
  nodes,
  edges,
}: {
  nodes: BuilderNode[]
  edges: BuilderEdge[]
}) => {
  // Welcome to hell.
  /**
   * 1. For each parent node we should go in and check if the children are in the correct order. (Yes no)
   * 2. If so we return all good
   * 3. If not we should swap their IDs
   * 4. And we should swap the parent IDs of each of their children
   */
  const newNodesAndEdges = nodes.reduce(
    (acc: { nodes: BuilderNode[]; edges: BuilderEdge[] }, curr: BuilderNode, _) => {
      const accCurrNode = acc.nodes.find((node) => node.id === curr.id) ?? curr

      // We find the first child of the current node
      const child1 = acc.nodes.find(
        (node) => node.id === accCurrNode.data.builderData.childrenIds[0]
      )
      // In case it doesn't exist we return the accumulator. Because we dont need to swap anything
      if (!child1) return acc

      // Same for the second child. If the second child does not exist we dont need to swap anything
      const child2 = acc.nodes.find(
        (node) => node.id === accCurrNode.data.builderData.childrenIds[1]
      )
      if (!child2) return acc

      // We get the yes and no edges for those nodes
      const yesEdge = acc.edges.find(
        (edge) => edge.source === accCurrNode.id && edge.type === 'if_true'
      )
      const yesEdgeIndex = acc.edges.findIndex(
        (edge) => edge.source === accCurrNode.id && edge.type === 'if_true'
      )
      const noEdge = acc.edges.find(
        (edge) => edge.source === accCurrNode.id && edge.type === 'if_false'
      )
      const noEdgeIndex = acc.edges.findIndex(
        (edge) => edge.source === accCurrNode.id && edge.type === 'if_false'
      )

      // This happens only in error cases
      if (!yesEdge || !noEdge) return acc

      // We find the Yes and no edge and their indexes so we can swap them later
      const yesNode = yesEdge.target === child1.id ? child1 : child2
      const yesNodeIndex = acc.nodes.findIndex((node) => node.id === yesNode.id)
      const noNode = noEdge.target === child1.id ? child1 : child2
      const noNodeIndex = acc.nodes.findIndex((node) => node.id === noNode.id)

      // We check if the yes node id is lower than the no node id.
      // If yes all good we return the acc and change nothing
      if (Number(yesNode.id) < Number(noNode.id)) {
        return acc
      }

      /**
       * If not however we need to
       * 1. Swap the IDs of the yes and no nodes
       * 2. Swap the source of the edges coming from the yes and no nodes
       * 3. Swap the parent IDs of the children of the yes and no nodes
       */

      // First we define the new nodes and edges
      const newYesNode = {
        ...yesNode,
        id: noNode.id,
      }
      const newNoNode = {
        ...noNode,
        id: yesNode.id,
      }

      const newYesEdge = {
        ...yesEdge,
        target: newYesNode.id,
        id: `${yesEdge.source}-${newYesNode.id}-${yesEdge.type}`,
      }
      const newNoEdge = {
        ...noEdge,
        target: newNoNode.id,
        id: `${noEdge.source}-${newNoNode.id}-${noEdge.type}`,
      }

      // Start working on the edges first

      // Here we flip the yes and no edges with the previously created ones
      const edgesWithSwappedYesAndNoEdges = substituteEntryInArray({
        array: acc.edges,
        substitutes: [
          { index: yesEdgeIndex, newEntry: newYesEdge },
          { index: noEdgeIndex, newEntry: newNoEdge },
        ],
      }) as BuilderEdge[]

      // We find the edges that come from the old yes edge and the old no edge
      const edgesComingFromYesEdge = edgesWithSwappedYesAndNoEdges.filter(
        (edge) => edge.source === yesNode.id
      )
      const edgesComingFromNoEdge = edgesWithSwappedYesAndNoEdges.filter(
        (edge) => edge.source === noNode.id
      )

      // Here we update the edges coming from the yes and no nodes so they reflect the new node IDs
      const edgesWithUpdatedSource = substituteEntryInArray({
        array: edgesWithSwappedYesAndNoEdges,
        substitutes: [
          ...edgesComingFromYesEdge.map((edge) => ({
            index: edgesWithSwappedYesAndNoEdges.findIndex((e) => e.id === edge.id),
            newEntry: {
              ...edge,
              source: newYesEdge.target,
              id: `${newYesEdge.target}-${edge.target}-${edge.type}`,
            },
          })),
          ...edgesComingFromNoEdge.map((edge) => ({
            index: edgesWithSwappedYesAndNoEdges.findIndex((e) => e.id === edge.id),
            newEntry: {
              ...edge,
              source: newNoEdge.target,
              id: `${newNoEdge.target}-${edge.target}-${edge.type}`,
            },
          })),
        ],
      }) as BuilderEdge[]

      // Now we are all done with the edges so we start looking at the nodes

      // Here we swap the old yes and no edge for the new ones
      const nodesWithSwappedIds = substituteEntryInArray({
        array: acc.nodes,
        substitutes: [
          { index: yesNodeIndex, newEntry: newYesNode },
          { index: noNodeIndex, newEntry: newNoNode },
        ],
      }) as BuilderNode[]

      /**
       * Now all that is left is to update the parent IDs of the children of the yes and no nodes.
       * Example: If the old yes edge id was 5 and the old no id was 4.
       * Then we will need to update the children of node with id 5 to have parent id 4 and vice versa.
       */

      // Now we need to sort the nodes by issue so we keep the order of the nodes similar to what it is if we generate nodes in any other way
      const nodesSortedByIssue = sortNodesByIssue({
        nodes: nodesWithSwappedIds,
        accNodes: [],
        childrenIds: [],
      })

      // We just add some order to the edges as well
      const edgesSortedByNodes = sortEdgesByNodes({
        nodes: nodesSortedByIssue,
        edges: edgesWithUpdatedSource,
      })

      /**
       * This is the most important step
       * So here we update the parent and children ids of each node so they reflect the edges we have in the other array
       * This function is needed, because we need to be darn sure that the nodes have the correct builder data otherwise the three just disappears
       */
      const nodesSortedByEdges = updateNodeBuilderDataByEdges({
        nodes: nodesWithSwappedIds,
        edges: edgesSortedByNodes,
      })

      return {
        nodes: nodesSortedByEdges,
        edges: edgesSortedByNodes,
      }
    },
    { nodes, edges }
  )

  return { nodes: newNodesAndEdges.nodes, edges: newNodesAndEdges.edges }
}

/**
 * Finds the active node ID based on the parent node ID and child node ID.
 * @param {Object} params - The parameters for finding the active node.
 * @param {string} params.parentNodeId - The ID of the parent node.
 * @param {string} params.childNodeId - The ID of the child node.
 * @param {BuilderNode[]} params.nodes - The array of nodes.
 * @returns {string | null} - The ID of the active node, or null if not found.
 */
export const findTheActiveNodeId = ({
  parentNodeId,
  childNodeId,
  nodes,
}: {
  parentNodeId: string
  childNodeId: string
  nodes: BuilderNode[]
}): string | null => {
  const parentNode = nodes.find((node) => node.id === parentNodeId)
  if (!parentNode) return null
  let activeNodeId = null
  parentNode.data.builderData.childrenIds.forEach((id) => {
    const node = nodes.find((node) => node.id === id)
    if (!node) return
    node.data.builderData.childrenIds.forEach((childId) => {
      if (childId === childNodeId) {
        activeNodeId = node.id
      }
    })
  })
  return activeNodeId
}

/**
 * Updates node builder data (children and parentIds) by edges.
 * @param {Object} params - The parameters for updating.
 * @param {BuilderNode[]} params.nodes - The array of nodes.
 * @param {BuilderEdge[]} params.edges - The array of edges.
 * @returns {BuilderNode[]} - The updated array of nodes.
 */
export const updateNodeBuilderDataByEdges = ({
  nodes,
  edges,
}: {
  nodes: BuilderNode[]
  edges: BuilderEdge[]
}) => {
  let updatedNodes = [...nodes]
  nodes.forEach((node) => {
    const edgesComingFromNode = edges.filter((edge) => edge.source === node.id)
    updatedNodes = updateNodeInNodes({
      nodes: updatedNodes,
      nodeId: node.id,
      newData: {
        ...node.data,
        builderData: {
          ...node.data.builderData,
          childrenIds: edgesComingFromNode.map((edge) => edge.target),
          parentId: edges.find((edge) => edge.target === node.id)?.source ?? null,
        },
      },
    })
  })
  return updatedNodes
}
