import React from 'react'
import ClickabilityDots from '../../../assets/ClickabilityDots.svg'
import TitleMainRecommendation from 'pages/credit-check/scenes/MainRecommendation/elements/TitleMainRecommendation'
import { Paper, Switch } from '@mui/material'
import { useStyles } from './DraggableBox.styles'

type DraggableBoxProps = {
  title: string
  isActive: boolean
  setIsActive?: (key: string) => void
}

const DraggableBox = (props: DraggableBoxProps) => {
  const { title, isActive, setIsActive } = props
  const classes = useStyles()

  return (
    <Paper className={classes.parentContainer} sx={{ backgroundColor: '#E9ECEF' }}>
      <div className={classes.titleCluster}>
        <img src={ClickabilityDots} height={16} width={16} className={classes.dots} />
        <TitleMainRecommendation labelKey={title} isActive={isActive} />
      </div>
      <Switch
        checked={isActive}
        onChange={() => setIsActive?.(title)}
        data-cy={`${title}-switch`}
      />
    </Paper>
  )
}

export default DraggableBox
