import { styled } from '@mui/system'
import { colors } from 'configs'
import { Drawer, ListItemButton } from '@mui/material'

const drawerWidth = 100
const drawerWidthMobile = 220

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  display: 'flex',
  '& a:not([role=button])': {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidthMobile,
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },
}))

export const StyledNav = styled('nav')(({ theme }) => ({
  '& ::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '5px',
    height: '5px',
  },
  '& ::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '& ::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '16px',
  },
  '& ::-webkit-scrollbar-button': {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}))

export const Content = styled('main')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  [theme.breakpoints.up('sm')]: {
    maxWidth: `calc(100% - 100px)`,
  },
}))

export const StyledToolbar = styled('div')(({ theme }) => theme.mixins.toolbar)

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${colors.risikaSecondary} !important`,
    borderLeft: '4px solid #FFCC88',
    '& .MuiListItemIcon-root': {
      color: '#FFCC88',
      marginLeft: -4,
      [theme.breakpoints.up('sm')]: {
        marginLeft: -2,
      },
    },
  },
  '& .MuiListItemIcon-root': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    color: '#fff',
    alignItems: 'center',
    padding: theme.spacing(1, 1),
    [theme.breakpoints.down('sm')]: {
      '& > *:first-child': {
        marginRight: theme.spacing(4),
      },
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(3, 0),
    },
  },
  '&& .MuiTouchRipple-child': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}))

export const StyledListItemText = styled('p')(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  fontFamily: 'Montserrat',
  lineHeight: 1.43,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
}))

export const StyledNavbarWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: '0 1 auto',
  flexDirection: 'row',
  height: 64,
  minHeight: 64,
}))

export const StyledImage = styled('img')(({ theme }) => ({
  margin: '0 auto',
  width: 70,
  [theme.breakpoints.down('sm')]: {
    width: 80,
    marginLeft: 12,
  },
}))
