import { Box } from '@mui/material'
import { classes } from '../../styles/info-box'

type InfoBoxProps = {
  children: React.ReactNode
}

const InfoBox = ({ children }: InfoBoxProps) => {
  return <Box sx={classes.root}>{children}</Box>
}

export default InfoBox
