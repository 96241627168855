import React from 'react'
import { Button, Typography, Box, Stack } from '@mui/material'
import styles from 'pages/company-hierarchy/styles/company-card'
import toggleStyles from 'pages/company-hierarchy/styles/toggle-owner'
import VisualizedArrowTrend from './VisualizedArrowTrend'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'

import SquareScore from './SquareScore'
import { navigation } from 'services/navigation'
import { useHistory } from 'react-router-dom'

type Props = {
  displayState: any
  nodeDatum: any
  onOpenHierarchyWalkdown?: () => void
}

const CompanyParentCard = ({
  displayState,
  nodeDatum,
  onOpenHierarchyWalkdown,
}: Props) => {
  const history = useHistory()

  const handleCompanyClick = () => {
    history.push(
      navigation.creditCheck({
        country: nodeDatum?.local_id?.country.toLowerCase() ?? 'dk',
        id: nodeDatum?.local_id?.id,
      })
    )
  }

  const handleCeoClick = () => {
    history.push(
      navigation.relationOverview({
        country: nodeDatum?.local_id?.country.toLowerCase() ?? 'dk',
        id: nodeDatum?.functions?.['CHIEF EXECUTIVE OFFICER']?.personal_id,
      })
    )
  }

  return (
    <Box sx={styles.main}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        sx={styles.header}
      >
        <Box sx={styles.leftPart}>
          <Typography
            variant="subtitle1"
            sx={{ ...styles.ellipsis, ...styles.hoverUnderline }}
            onClick={handleCompanyClick}
          >
            {nodeDatum?.name}
          </Typography>
          {displayState.localId && (
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1" sx={styles.localId}>
                <FormattedMessage id={intl.companyHierarchy('local-id')} />
              </Typography>
              <Typography variant="subtitle1">{nodeDatum?.local_id?.id}</Typography>
            </Stack>
          )}
          {!displayState.localId && !displayState.risikaScore && <Box height={24}></Box>}
        </Box>
        {displayState.risikaScore && <SquareScore score={nodeDatum?.score} />}
      </Stack>
      <Box sx={styles.ceo}>
        {displayState.ceo &&
        Boolean(nodeDatum?.functions?.['CHIEF EXECUTIVE OFFICER']?.name) ? (
          <Box pb={3}>
            <Typography variant="h5" sx={styles.hoverUnderline} onClick={handleCeoClick}>
              {nodeDatum?.functions?.['CHIEF EXECUTIVE OFFICER']?.name}
            </Typography>
            <Typography variant="overline" sx={styles.ceoTitleColor}>
              CEO
            </Typography>
          </Box>
        ) : (
          <Box sx={{ visibility: 'hidden' }}>
            <Typography variant="h5">hidden CEO placeholder</Typography>
            <Typography variant="h5">hidden name placeholder</Typography>
          </Box>
        )}

        <Stack>
          {displayState?.profitLoss && (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">
                <FormattedMessage id={intl.advancedSearch('profit_loss')} />
              </Typography>
              <Stack direction="row" fontSize="21px" spacing={2}>
                <Typography variant="subtitle1">
                  {nodeDatum?.profit_loss ? nodeDatum?.profit_loss.toLocaleString() : '-'}
                </Typography>
                {displayState.trend && (
                  <VisualizedArrowTrend
                    trend={nodeDatum?.financial_trend?.profit_loss?.trend}
                  />
                )}
              </Stack>
            </Stack>
          )}
          {displayState.equity && (
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography variant="subtitle1">
                <FormattedMessage id={intl.advancedFilters('equity')} />
              </Typography>

              <Stack direction="row" fontSize="21px" spacing={2}>
                <Typography variant="subtitle1">
                  {nodeDatum?.equity ? nodeDatum?.equity.toLocaleString() : '-'}
                </Typography>
                {displayState.trend && (
                  <VisualizedArrowTrend
                    trend={nodeDatum?.financial_trend?.profit_loss?.trend}
                  />
                )}
              </Stack>
            </Stack>
          )}
          {displayState.creditPolicy && (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">Credit policy</Typography>

              <Typography variant="h5">TODO</Typography>
            </Stack>
          )}
        </Stack>
      </Box>

      {!!nodeDatum?.has_children && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ ...toggleStyles.subsidiariesBtnParentHie }}
        >
          <Stack
            data-cy="subsidiaries-toggle"
            alignItems="center"
            justifyContent="center"
            onClick={onOpenHierarchyWalkdown}
            sx={toggleStyles.subsidiaryBtn(true)}
          >
            <Typography variant="subtitle2">
              <FormattedMessage
                id={intl.companyHierarchy('subsidiaries')}
                values={{
                  number: nodeDatum?.has_children,
                  toggled: '+',
                }}
              />
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}

export default CompanyParentCard
