import { default as cx } from 'classnames'
import { Tooltip, Box, Typography, Skeleton } from '@mui/material'
import { Stack } from 'components'
import { handleComponentRenderRestrictions } from 'services/helpers'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useAccountStatus } from 'services/queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useStyles } from '../styles/view-switch'
import SwitchWithLabel from '../../pages/enterprise-credit-policy/widgets/SwitchWithLabel'

// type ViewSwitchProps = {
//   actionChange: Function,
//   textOff: string | Object,
//   textOn: string | Object,
//   classes?: Object,
//   checkedVar: boolean,
//   className?: string,
//   view?: string,
//   gridStyle?: Object,
//   switchColor?: boolean,
//   restrictionsString?: string,
//   disabled?: string,
// }
const ViewSwitch = ({
  actionChange,
  checkedVar,
  textOff,
  textOn,
  classes,
  className,
  view,
  gridStyle,
  switchColor = false,
  restrictionsString = 'view-switch',
  disabled,
}) => {
  const localClasses = useStyles()
  const accountStatusQuery = useAccountStatus({ refetchOnMount: true })

  if (accountStatusQuery.isFetching) {
    return <Skeleton variant="rounded" width="25%" height={30} animation="wave" />
  }

  const handleDisabled = () => {
    if (disabled === 'true') {
      return true
    } else {
      return !handleComponentRenderRestrictions({
        component: restrictionsString,
        plan: accountStatusQuery.data?.subscription_plan,
      })
    }
  }

  const isDisabled = disabled
    ? handleDisabled
    : !handleComponentRenderRestrictions({
        component: restrictionsString,
        plan: accountStatusQuery.data?.subscription_plan,
      })

  const handleClick = () => {
    if (isDisabled && restrictionsString === 'view-switch') {
      window.open('https://risika.dk/prices')
    }
  }
  return (
    <Tooltip
      title={
        isDisabled && restrictionsString === 'view-switch' ? (
          <FormattedMessage id={intl.generic('feature-not-available')} />
        ) : (
          ''
        )
      }
      placement="top"
    >
      <Box onClick={handleClick}>
        <Stack
          style={gridStyle ?? null}
          items="center"
          className={
            isDisabled
              ? cx(localClasses.pointer, localClasses.consolidatedSwitch)
              : localClasses.consolidatedSwitch
          }
          spacing={1}
        >
          {isDisabled ? <FontAwesomeIcon icon={faLock} /> : null}
          <Typography variant="body1" color={checkedVar ? 'grey.600' : 'initial'}>
            {textOff}
          </Typography>
          <SwitchWithLabel
            checked={checkedVar}
            onChange={actionChange}
            disabled={isDisabled}
          />
          <Typography variant="body1" color={!checkedVar ? 'grey.600' : 'initial'}>
            {textOn}
          </Typography>
        </Stack>
      </Box>
    </Tooltip>
  )
}

export default ViewSwitch
