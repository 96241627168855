import { SxProps } from '@mui/system'

const mainContainer: SxProps = {
  paddingTop: 2,
  gap: 3,
  overflow: 'scroll',
}

const secondaryContainer: SxProps = { overflow: 'scroll', flexGrow: 1, gap: 4 }

const divider: SxProps = {
  borderColor: 'grey.200',
}

export default {
  mainContainer,
  secondaryContainer,
  divider,
}
