import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts'
import {
  AbbreviatedNumbersAxis,
  CustomTooltipWithCreditScore,
  getCellColor,
} from '../customizations'
import { StackedChartTooltipWithPercent } from '../customizations'
import { StackedChartTooltipWithRiskPercent } from '../customizations'
import { CustomLegendWithPadding } from '../customizations'
import { CustomLegendWithPaddingRisk } from '../customizations'
import { StackedBarChartPropsType } from '../types'
import colors, { muiTheme } from 'configs/colors'
import { useIntl } from 'react-intl'
import intl from 'localization/components'

const StackedBarChart = ({
  data,
  chartProperties,
  handleBarClick = null,
}: StackedBarChartPropsType) => {
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const creditScoreText = messages[intl.observationalLists('credit-score')]

  if (!data || !data.length) return null

  const barKeys = Object.keys(data[0]).filter((key) => key !== 'name')

  // This should prevent ReCharts bug of not displaying top labels
  // by preventing the animation of the chart
  // https://github.com/recharts/recharts/issues/829
  const animationActive = !chartProperties.bar.label

  return (
    <ResponsiveContainer height={chartProperties.height}>
      <BarChart
        data={data}
        barCategoryGap={chartProperties.barCategoryGap}
        margin={chartProperties.margin}
      >
        {chartProperties.cartesianGrid && (
          <CartesianGrid strokeDasharray={chartProperties.strokeDasharray} />
        )}
        {chartProperties.xAxis && <XAxis dataKey={chartProperties.dataKey} />}
        {chartProperties.yAxis && <YAxis tick={<AbbreviatedNumbersAxis />} />}
        {chartProperties.tooltipWithPercents && chartProperties.valueType === 'RISK' ? (
          <Tooltip
            cursor={{ fill: muiTheme.colors.grey[200] }}
            content={
              <StackedChartTooltipWithRiskPercent
                employeesLabel={chartProperties.bar.label.labelEmployees}
              />
            }
          />
        ) : null}
        {chartProperties.tooltipWithPercents && chartProperties.valueType !== 'RISK' ? (
          <Tooltip
            cursor={{ fill: muiTheme.colors.grey[200] }}
            content={<StackedChartTooltipWithPercent />}
          />
        ) : null}
        {chartProperties.tooltip && !chartProperties.tooltipWithPercents ? (
          <Tooltip
            cursor={{ fill: muiTheme.colors.grey[200] }}
            content={
              <CustomTooltipWithCreditScore chartColors={chartProperties.colors} />
            }
          />
        ) : null}
        {chartProperties.legend && chartProperties.valueType === 'RISK' ? (
          <Legend content={CustomLegendWithPaddingRisk} />
        ) : null}
        {chartProperties.legend && chartProperties.valueType !== 'RISK' ? (
          <Legend content={CustomLegendWithPadding} />
        ) : null}
        {barKeys.map((barKey, index) => {
          return (
            <Bar
              key={barKey}
              dataKey={barKey}
              maxBarSize={chartProperties.bar.maxBarSize}
              stackId={chartProperties.bar.stackId}
              fill={
                barKey === creditScoreText
                  ? colors.riskIntelligence.mediumRisk
                  : chartProperties.colors[index]
              }
              radius={chartProperties.bar.radius}
              barSize={chartProperties.bar.barSize}
              isAnimationActive={animationActive /* ReCharts bug prevention */}
              onClick={handleBarClick}
            >
              {data.map((entry) => (
                <Cell
                  key={barKey + index}
                  fill={getCellColor(
                    entry,
                    barKey,
                    creditScoreText as string,
                    index,
                    chartProperties.colors
                  )}
                />
              ))}
            </Bar>
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default StackedBarChart
