import { LocalId } from 'globalTypes'
import moment from 'moment'
import risikaAPI from 'services/api/helpers/risikaAPI'
/*********
 * Types *
 ********? */

export type ScoreItem = {
  feature: string
  [key: string]: string | number
}

export type ScoreInformation = {
  high_positive_impact: ScoreItem[]
  moderate_positive_impact: ScoreItem[]
  minor_impact: ScoreItem[]
  moderate_negative_impact: ScoreItem[]
  high_negative_impact: ScoreItem[]
} | null

export type RatingScore = {
  date: string
  error?: string
  probability_of_distress?: number
  risk_assessment?: string
  risk_assessment_code?: string
  score?: number
  score_information: ScoreInformation
}

export type RatingScoresResponse = RatingScore[]

export type RatingScoresData = {
  current_score: RatingScore
  historical_scores: RatingScore[]
}

type ResponseData = {
  data: RatingScoresResponse
}

/***********
 * Helpers *
 ***********/

/**
 * Sort the scores by date, newest first.
 */
const sortByNewest = (a: RatingScore, b: RatingScore): number => {
  return moment(b.date).diff(a.date)
}

/******************
 * Fetch from API *
 ******************/

async function ratingScores({ id, country }: LocalId): Promise<RatingScoresData> {
  const { data }: ResponseData = await risikaAPI.get(`/rating/scores/${id}`, {
    microservice: 'data',
    country,
    appendScoringModel: true,
  })
  const sortedScores = [...data].sort(sortByNewest)
  return {
    current_score: sortedScores?.[0],
    historical_scores: sortedScores,
  }
}

export default ratingScores
