import { Stack } from '@mui/material'
import { LocalId } from 'globalTypes'
import Change from './Change'
import { ChangeType, RestructuredChangeType } from './Change.types'
import { getRestructureChanges } from './Changes.controller'

type ChangeProps = {
  changes: ChangeType[]
  localId: LocalId
}
const Changes = ({ changes, localId }: ChangeProps) => {
  const restructuredChanges = getRestructureChanges(changes) ?? []
  return (
    <Stack
      sx={{
        backgroundColor: 'grey.200',
        padding: 2,
      }}
      direction="row"
      spacing={6}
    >
      {restructuredChanges.map((change, i) => (
        <Change key={`${localId.id}-${i}`} change={change as RestructuredChangeType} />
      ))}
    </Stack>
  )
}

export default Changes
