import React, { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Box } from '@mui/material'
import { useUserSettingsField } from 'services/queries'
import SidebarWidgetError from './SidebarWidgetError'
import { formatVisibilityData } from 'utils/formatVisibilityData'
import { dndReorder } from 'utils/dragAndDropReorder'

function SidebarWrapperDND({
  children,
  isReArrange,
  isElementActive,
  setIsElementActive,
  localSidebarData,
  setLocalSidebarData,
}) {
  const { data: creditCheckSidebar } = useUserSettingsField('credit_check_sidebar')

  useEffect(() => {
    setIsElementActive(formatVisibilityData(creditCheckSidebar))
    // we want this to initialise with the original data only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setVisibilityByKey = (key) => {
    setIsElementActive({ ...isElementActive, [key]: !isElementActive[key] })
  }

  const onDragEndSidebar = (res) => {
    if (!res.destination) return

    const newCreditCheckSidebarPos = dndReorder(
      localSidebarData,
      res.source.index,
      res.destination.index
    )

    setLocalSidebarData(newCreditCheckSidebarPos)
  }

  const getCorrectChild = (widgetNameDND) => {
    const correctChild = children.find(
      (child) => child?.props?.widgetNameDND === widgetNameDND
    )
    if (correctChild?.props?.isError) {
      return <SidebarWidgetError title={widgetNameDND} />
    }
    return correctChild
  }

  if (isReArrange) {
    return (
      <DragDropContext onDragEnd={onDragEndSidebar}>
        <Droppable droppableId="sidebarDND">
          {(provided) => (
            <Box
              data-cy="sidebar-droppable-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {localSidebarData?.map(({ key }, index) => (
                <Draggable key={key} draggableId={key} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      data-cy={`draggable-${key}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {getCorrectChild(key)
                        ? React.cloneElement(getCorrectChild(key), {
                            isDragging: snapshot.isDragging,
                            isReArrange: isReArrange,
                            isActive: isElementActive[key],
                            setIsActive: setVisibilityByKey,

                            // here we can add the local state for rendering correct switch until loaded
                          })
                        : null}
                    </Box>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
              {window.Cypress && (
                <div data-cy="droppable-container-bottom-sidebar" style={{ height: 1 }} />
              )}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return creditCheckSidebar.map(({ key }) => (
    <Box data-cy={`sidebar-component-${key}`} key={key}>
      {getCorrectChild(key)
        ? React.cloneElement(getCorrectChild(key), {
            isReArrange: isReArrange,
            isActive: isElementActive[key],
          })
        : null}
    </Box>
  ))
}

export default SidebarWrapperDND
