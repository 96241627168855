import Box from '@mui/material/Box'
import BoxContainer from 'components-new/layouts/BoxContainer'
import DoughnutChart from 'components-new/charts/DoughnutChart'
import { convertToDoughnut } from 'utils-new/api-to-chart-data-converters'
import { creditScoreData } from './ListCreditScoreDoughnutChartBox.model'
import { useMonitoringStatistic } from 'services/queries'
import { MonitoringValueQueryDataType } from 'types/queries'

const ListCreditScoreDoughnutChartBox = ({ listId }: { listId: string }) => {
  creditScoreData.parameters.list_id = Number(listId)
  creditScoreData.chartProperties.pie.tooltipWithPercent = true

  const creditScoreQuery = useMonitoringStatistic({
    parameters: creditScoreData.parameters,
    endpoint: creditScoreData.endPoint,
  })
  const creditScoreQueryData = creditScoreQuery.data as MonitoringValueQueryDataType[]

  const boxData = {
    title: creditScoreData.chartProperties.title,
    status: {
      isLoading: creditScoreQuery.isLoading,
      isError: creditScoreQuery.isError,
      isIdle: creditScoreQuery.isIdle,
      isNoData: !creditScoreQueryData?.length,
    },
    height: creditScoreData.chartProperties.height,
    skeletonType: 'PIE',
  }

  return (
    <BoxContainer boxData={boxData}>
      <Box data-cy="lists-credit-score-doughnut-chart" m={2}>
        {!boxData.status.isLoading &&
        !boxData.status.isError &&
        !boxData.status.isIdle ? (
          <DoughnutChart
            data={convertToDoughnut(creditScoreQueryData)}
            chartProperties={creditScoreData.chartProperties}
          />
        ) : null}
      </Box>
    </BoxContainer>
  )
}

export default ListCreditScoreDoughnutChartBox
