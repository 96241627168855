import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Box, Typography } from '@mui/material'

const PropertyWrapper = ({ titleIntlId, children }) => {
  return (
    <Box>
      <Typography variant="subtitle1">
        <FormattedMessage id={titleIntlId} />
      </Typography>
      <Typography variant="body2" component="div">
        {children ? (
          <>{children}</>
        ) : (
          <FormattedMessage id={intl.annualReportInformation('no_information')} />
        )}
      </Typography>
    </Box>
  )
}

export default PropertyWrapper
