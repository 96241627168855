import { AppBar, IconButton, Toolbar } from '@mui/material'
import { styled } from '@mui/system'

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 4),
    marginLeft: 100,
  },
  // Make this more readable, it looks like a regex
  '& > * + *': {
    marginLeft: theme.spacing(4),
  },
})) as typeof Toolbar

export const StyledAppbar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
})) as typeof AppBar

export const StyledMenuButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
})) as typeof IconButton

export const StyledContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
}))

export const StyledSearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: 0,
  flex: 1,
  width: '100%',
  maxWidth: '70rem',
  zIndex: 0,
}))
